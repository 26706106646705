import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { useAppSelector, useAppDispatch } from "../../../framework/customStore";
import { Translations } from "../../../models/translations";
import { TransportPlanDetailsDto } from "../../../models/transport/transportPlan";
import { setNewPlan, addPlanToGrid, setGridDataChanged } from "../../../store/transport/transportVehiclesSlice";
import { TransportPlanForm } from "../transportPlan/transportPlanMain";

export const NewPlanDialog = () => {
    const newPlan = useAppSelector((state) => state.transportVehicles.newPlan);
    const gridDataChanged = useAppSelector((state) => state.transportVehicles.gridDataChanged);
    const dispatch = useAppDispatch();

    const handleOnSaved = (newPlan: TransportPlanDetailsDto) => {
        dispatch(setNewPlan(null));
        dispatch(setGridDataChanged(!gridDataChanged));
        dispatch(addPlanToGrid(newPlan));
    };

    const handleRecurringOnSaved = () => {
        dispatch(setNewPlan(null));
        dispatch(setGridDataChanged(!gridDataChanged));
    };

    return (
        <>
            {newPlan && (
                <Dialog open>
                    <DialogTitle>
                        {Translations.NewTransportPlan}
                        <IconButton
                            onClick={() => dispatch(setNewPlan(null))}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <TransportPlanForm
                            autoFocus
                            item={newPlan}
                            onSaved={handleOnSaved}
                            onSavedRecurring={handleRecurringOnSaved}
                            onRemoved={() => null}
                            onlyBasics
                        />
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};
