import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertProps } from "@mui/material/Alert";
import {
    Button,
    SnackbarContent as MuiSnackbarContent,
    styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import {
    AlertType,
    AppUpdateState,
    removeAlert,
    setAppUpdate,
} from "../../store/appSlice";

const MuiAlert = React.forwardRef<HTMLDivElement, AlertProps>(function MuiAlert(
    props,
    ref
) {
    return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarContent = styled(MuiSnackbarContent)(({ theme }) => ({
    color: theme.palette.common.white,
    backgroundColor: theme.palette.info.main,
}));

export const MuiSnackBar = () => {
    const alert = useAppSelector(({ app }) => app.alerts[0]);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        dispatch(removeAlert());
    };

    if (!alert) {
        return null;
    }

    const { message, severity, duration = 3000, dialog, type } = alert;

    const handleOk = () => {
        if (type === AlertType.AppUpdate) {
            dispatch(setAppUpdate(AppUpdateState.Confirmed));
        }
        handleClose();
    };

    const actions = dialog ? (
        <>
            <Button color="white" onClick={handleOk}>
                {t("common.ok")}
            </Button>
            <Button color="white" onClick={handleClose}>
                {t("common.cancel")}
            </Button>
        </>
    ) : null;

    return (
        <Snackbar
            open={true}
            autoHideDuration={duration}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            sx={{ marginTop: "50px" }}
        >
            {dialog ? (
                <SnackbarContent message={message} action={actions} />
            ) : (
                <MuiAlert
                    onClose={handleClose}
                    severity={severity}
                    sx={{ width: "100%" }}
                >
                    {message}
                </MuiAlert>
            )}
        </Snackbar>
    );
};
