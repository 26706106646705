// WorkListLineShort
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "../../framework/base";
import { Translations } from "../../models/translations";
import { WorkOrderDragData, WorkOrderDragDataType } from "../../models/work/workOrderDragData";
import { ToolButton } from "../framework/toolButton";

// WorkListLineShort
export interface IWorkListLineShortItem {
    id: string;
    number: number;
    parentNumber: number;
    state: number;
    category: number;
    startTime: number;
    endTime: number;
    hasChildren: boolean;
    name: string;
    description: string;
    customerName: string;
    siteName: string;
    siteAddress: string;
    canceled: boolean;
    comment: string;
    latitude: number;
    longitude: number;
    isTask(): boolean;
}

export interface IWorkListLineShortProp {
    classes?: string;
    item: IWorkListLineShortItem;
    draggable?: boolean;
    employeeId: string;
    vehicleId: string;
    isSelected: boolean;
    removeTitle?: string;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
    onRemoveFromFavorites?: (id: string) => void;
}

export class WorkListLineShort extends React.Component<IWorkListLineShortProp, {}> {
    handleClick = (e) => {
        const props = this.props;
        e.preventDefault();
        e.stopPropagation();
        props.onClick(props.item.id);
    };

    handleDoubleClick = (e) => {
        const props = this.props;
        e.preventDefault();
        e.stopPropagation();
        props.onDoubleClick(props.item.id);
    };

    onDragStart = (ev, id: string, state: number, category: number, employeeId: string, vehicleId: string, startTime: number, hasChildren: boolean) => {
        WorkOrderDragData.setWorkOrderDragData(ev, WorkOrderDragDataType.WorkOrder, id, state, category, employeeId, vehicleId, startTime, hasChildren, 0);
    };

    handleRemoveFromFavorites = () => {
        const props = this.props;
        props.onRemoveFromFavorites(props.item.id);
    };

    render() {
        const props = this.props;
        const item = props.item;
        const rowClass = "workListLineShort state" + item.state + (props.classes ? " " + props.classes : "") + (props.isSelected ? " selected" : "") + (item.canceled ? " canceled" : "");
        return (
            <div className={rowClass} onClick={this.handleClick} onDoubleClick={props.onDoubleClick ? this.handleDoubleClick : null} onDragStart={(ev) => this.onDragStart(ev, item.id, item.state, item.category, props.employeeId, props.vehicleId, item.startTime, item.hasChildren)} draggable={props.draggable} title={Base.getStringWithSeparators([item.customerName, item.name, item.description, item.siteName, item.siteAddress, Base.timeToDateStr(item.startTime)], "\n")}>
                <div>
                    <div className="workOrderStateEndContainer" onClick={props.onDoubleClick ? () => props.onDoubleClick(item.id) : null}>
                        <div className={"stateEnd active state" + item.state.toString(10)}>
                            <span className={"icon state" + item.state.toString(10)} aria-hidden="true" />
                        </div>
                    </div>
                    <div>
                        {props.onRemoveFromFavorites &&
                            <ToolButton
                                classes={"close smallButton right"}
                                enabled={true}
                                stopPropagation={true}
                                title={props.removeTitle}
                                onClick={this.handleRemoveFromFavorites}
                            />
                        }
                        <div className="right">
                            {!!item.longitude && !!item.latitude &&
                                <div className="mapMarker green smallerMarker left" title={Translations.LocationSuccess} />
                            }
                            {(!item.longitude || !item.latitude) &&
                                <div className="mapMarker red smallerMarker left" title={Translations.LocationError} />
                            }
                        </div>
                        <div className="row">
                            <div className="col-12 listLineTitle">{item.customerName}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 listLineLeft">{item.name}</div>
                        <div className="col-6 listLineLeft">{item.siteAddress}</div>
                    </div>
                </div>
            </div>
        );
    }
}
