import React, { useState } from "react";
import { useTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Translations } from "../../models/translations";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import {
    clearFilters,
    setSelectedCostCenters,
    setSelectedEmployeeGroups,
    setSelectedEmployees,
    setSelectedVehicles,
    setTimeRange
} from "../../store/reportOptionsSlice";
import { MuiDateRangePicker } from "../framework/muiDatepicker";
import { ICostCenterItem } from "../../models/costCenter/costCenterItem";
import { Base } from "../../framework/base";
import { VehicleListItemDto } from "../../models/transport/vehicle";
import { EmployeeListItemDto } from "../../models/employee/employee";
import { employeeGroupListItemDto } from "../../models/employeeGroup/employeeGroup";
import { useFetch } from "../../hooks/useFetch";
import { getEmployeeGroups } from "../../services/employeeGroupService";

export const ReportingFilters = ({
    vehicles,
    costCenters,
    employees,
}: {
    vehicles: VehicleListItemDto[];
    costCenters: ICostCenterItem[];
    employees: EmployeeListItemDto[];
}) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const [employeeGroups, setEmployeeGroups] = useState<employeeGroupListItemDto[]>();

    const timeRange = useAppSelector(
        (state) => state.reportOptions.filters.timeRange
    );
    const selectedVehicles = useAppSelector(
        (state) => state.reportOptions.filters.selectedVehicles
    );
    const selectedCostCenters = useAppSelector(
        (state) => state.reportOptions.filters.selectedCostCenters
    );
    const selectedEmployees = useAppSelector(
        (state) => state.reportOptions.filters.selectedEmployees
    );
    const selectedEmployeeGroups = useAppSelector(
        (state) => state.reportOptions.filters.selectedEmployeeGroups
    );

    useFetch(
        (signal) => getEmployeeGroups(true, signal),
        {
            onSuccess: (res) =>
                setEmployeeGroups(
                    res.data.filter((d) => !!d.salaryPeriodTypeId)
                ),
        },
        []
    );

    return (
        <Box className="work-time-beta-top-bar">
            {costCenters && (
                <Grid2 container alignItems="center" rowSpacing={1}>
                    <Grid2 lg={3} md={12} sm={12} xs={12}>
                        <Grid2>
                            <MuiDateRangePicker
                                labels={["Aikaväli alkaa", "Aikaväli loppuu"]}
                                value={[
                                    new Date(timeRange[0]),
                                    new Date(timeRange[1]),
                                ]}
                                onChange={(val) =>
                                    dispatch(
                                        setTimeRange([
                                            Base.dayjsToJsonDate(val[0]),
                                            Base.dayjsToJsonDate(val[1]),
                                        ])
                                    )
                                }
                            />
                        </Grid2>
                    </Grid2>
                    <Grid2
                        md={4}
                        sm={12}
                        xs={12}
                        maxWidth={280}
                        marginRight={3}
                    >
                        <FormControl variant="outlined" fullWidth={true}>
                            <Autocomplete
                                isOptionEqualToValue={(a, b) => a.id === b.id}
                                value={
                                    employeeGroups?.filter((i) =>
                                        selectedEmployeeGroups.includes(i.id)
                                    ) ?? []
                                }
                                multiple={true}
                                limitTags={2}
                                fullWidth={true}
                                disableClearable={true}
                                noOptionsText={Translations.NoOptions}
                                options={employeeGroups || []}
                                getOptionLabel={(
                                    option: employeeGroupListItemDto
                                ) => option.name}
                                onChange={(
                                    _,
                                    value: employeeGroupListItemDto[]
                                ) =>
                                    dispatch(
                                        setSelectedEmployeeGroups(
                                            value.map((v) => v.id)
                                        )
                                    )
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={Translations.EmployeeGroup}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid2>
                    <Grid2
                        md={4}
                        sm={12}
                        xs={12}
                        maxWidth={280}
                        marginRight={3}
                    >
                        <FormControl variant="outlined" fullWidth={true}>
                            <Autocomplete
                                isOptionEqualToValue={(a, b) => a.id === b.id}
                                value={
                                    vehicles?.filter((i) =>
                                        selectedVehicles.includes(i.id)
                                    ) ?? []
                                }
                                fullWidth={true}
                                multiple
                                limitTags={2}
                                noOptionsText={Translations.NoOptions}
                                options={vehicles || []}
                                getOptionLabel={(option: VehicleListItemDto) =>
                                    `${option.registerNumber} - ${option.brand}`
                                }
                                onChange={(_, value: VehicleListItemDto[]) => {
                                    dispatch(
                                        setSelectedVehicles(
                                            value.map((v) => v.id)
                                        )
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={Translations.Vehicle}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid2>
                    <Grid2
                        md={4}
                        sm={12}
                        xs={12}
                        maxWidth={280}
                        marginRight={3}
                    >
                        <FormControl variant="outlined" fullWidth={true}>
                            <Autocomplete
                                isOptionEqualToValue={(a, b) => a.id === b.id}
                                value={
                                    costCenters?.filter((i) =>
                                        selectedCostCenters.includes(i.id)
                                    ) ?? []
                                }
                                fullWidth={true}
                                multiple
                                limitTags={2}
                                noOptionsText={Translations.NoOptions}
                                options={costCenters || []}
                                getOptionLabel={(option: ICostCenterItem) =>
                                    option.name
                                }
                                onChange={(_, value: ICostCenterItem[]) => {
                                    dispatch(
                                        setSelectedCostCenters(
                                            value.map((v) => v.id)
                                        )
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={Translations.CostCenter}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid2>
                    <Grid2
                        md={4}
                        sm={12}
                        xs={12}
                        maxWidth={280}
                        marginRight={3}
                    >
                        <FormControl variant="outlined" fullWidth={true}>
                            <Autocomplete
                                isOptionEqualToValue={(a, b) => a.id === b.id}
                                value={
                                    employees?.filter((i) =>
                                        selectedEmployees.includes(i.id)
                                    ) ?? []
                                }
                                fullWidth={true}
                                multiple
                                limitTags={2}
                                noOptionsText={Translations.NoOptions}
                                options={employees || []}
                                getOptionLabel={(option: EmployeeListItemDto) =>
                                    option.fullName
                                }
                                onChange={(_, value: EmployeeListItemDto[]) => {
                                    dispatch(
                                        setSelectedEmployees(
                                            value.map((v) => v.id)
                                        )
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={Translations.Employee}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid2>
                    <Grid2 xsOffset="auto" alignItems="self-end">
                        <Button
                            color="secondary"
                            sx={{ color: theme.palette.common.white }}
                            onClick={() => dispatch(clearFilters())}
                            variant={"contained"}
                        >
                            {Translations.Clear}
                        </Button>
                    </Grid2>
                </Grid2>
            )}
        </Box>
    );
};
