// SettingsEmployeeDialog - MODULE
// ***********************************************************************************************************************
import downloadjs from "downloadjs";
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import * as documentService from "../../services/documentService";
import { IEmployeeEdit } from "../../models/employee/employeeEdit";
import { SettingsEmployeeDialogDayBookingList } from "./settingsEmployeeDialogDayBookingList";
import { DocumentList } from "../document/documentList";
import { SettingsEmployeeDialogEmployeeCompetencyListReadOnlyView } from "./settingsEmployeeDialogEmployeeCompetencyList";
import { ScrollSpy, IScrollSpyItem, ScrollSpyItem } from "../framework/scrollSpy";
import { ImageSelector } from "../framework/imageSelector";
import { IDayBookingEditItem } from "../../models/dayBooking/dayBookingEditItem";
import { SettingsEmployeeDialogSsoIntegrationList } from "./settingsEmployeeDialogSsoIntegrationList";
import { SettingsEmployeeWorkHourAccountTransactions } from "./settingsEmployeeWorkHourAccountTransactions";
import { IWorkTimeAccountTransaction } from "../../models/workTimeAccount/workTimeAccountTransaction";
import { SettingsEmployeeDialogEmployeeGroupList } from "./settingsEmployeeDialogEmployeeGroupList";
import { IEmployeeEditEmployeeGroupItem } from "../../models/employee/employeeEditEmployeeGroupItem";
import { t } from "i18next";

// SettingsEmployeeDialog
// ***********************************************************************************************************************
export interface ISettingsEmployeeDialogReadOnlyViewProp {
    classes?: string;
    employeeEdit: IEmployeeEdit;
    dayBookings: IDayBookingEditItem[];
    employeeEmployeeGroups: IEmployeeEditEmployeeGroupItem[];
    onDayBookingRemoved: (id: string) => void;
    onAddDayBooking: (id: string, dayBookingTypeId: string, date: number, duration: number, dayUsageType: number, comment: string, rowId: string) => void;
    onEditDayBooking: (id: string, dayBookingTypeId: string, date: number, duration: number, dayUsageType: number, comment: string, rowId: string) => void;
    onEmployeeEmployeeGroupRemoved: (id: string) => void;
    onAddEmployeeEmployeeGroup: (id: string, employeeGroupId: string, groupName: string) => void;
    workHourTransactions: IWorkTimeAccountTransaction[];
    workHourBalance: number;
}

export interface ISettingsEmployeeDialogReadOnlyViewState {
    vehicleTitle: string;
    userGroupTitle: string;
    salaryTypeTitle: string;
    employeeGroupTitle: string;
    salaryEmployeeGroupTitle: string;
    costCenterTitle: string;
    scrollSpyItems: IScrollSpyItem[];
}

export class SettingsEmployeeDialogReadOnlyView extends React.Component<ISettingsEmployeeDialogReadOnlyViewProp, ISettingsEmployeeDialogReadOnlyViewState> {
    private employeeContentAreaDiv: HTMLDivElement;

    private static getScrollSpyItems(workTimeAccountInUse: boolean): IScrollSpyItem[] {
        const result: IScrollSpyItem[] = [];
        result.push(ScrollSpyItem.createScrollSpyItem("general", Translations.General, "title"));
        result.push(ScrollSpyItem.createScrollSpyItem("employeeGroups", Translations.SecondaryEmployeeGroups, "title"));
        result.push(ScrollSpyItem.createScrollSpyItem("dayBookings", Translations.DayBookings, "title"));
        if (workTimeAccountInUse) {
            result.push(ScrollSpyItem.createScrollSpyItem("workHourTransactions", Translations.WorkhourBank, "title"));
        }
        result.push(ScrollSpyItem.createScrollSpyItem("competencies", Translations.Competencies, "title"));
        result.push(ScrollSpyItem.createScrollSpyItem("documents", Translations.Documents, "title"));
        result.push(ScrollSpyItem.createScrollSpyItem("integrations", Translations.SsoIntegrations, "title"));
        return result;
    }

    private getStateFromProps(props: ISettingsEmployeeDialogReadOnlyViewProp): ISettingsEmployeeDialogReadOnlyViewState {
        const employee = props.employeeEdit.employee;
        const vehicle = props.employeeEdit.vehicles.find(i => i.id === employee.vehicleId);
        const userGroup = props.employeeEdit.userGroups.find(i => i.id === employee.userGroupId);
        const salaryType = props.employeeEdit.salaryTypes.find(i => i.id === employee.salaryTypeId);
        const employeeGroup = props.employeeEdit.employeeGroups.find(i => i.id === employee.employeeGroupId);
        const salaryEmployeeGroup = props.employeeEdit.employeeGroups.find(i => i.id === employee.salaryEmployeeGroupId);
        const costCenter = props.employeeEdit.costCenters.find(i => i.id === employee.costCenterId);
        return {
            vehicleTitle: vehicle ? vehicle.getTitle() : "",
            userGroupTitle: userGroup ? userGroup.name : "",
            salaryTypeTitle: salaryType ? salaryType.title : "",
            employeeGroupTitle: employeeGroup ? employeeGroup.name : "",
            salaryEmployeeGroupTitle: salaryEmployeeGroup ? salaryEmployeeGroup.name : "",
            costCenterTitle: costCenter ? costCenter.title : "",
            scrollSpyItems: SettingsEmployeeDialogReadOnlyView.getScrollSpyItems(employee.workTimeAccountInUse)
        };
    }

    constructor(props) {
        super(props);
        this.state = this.getStateFromProps(props);
    }

    componentDidUpdate(prevProps: ISettingsEmployeeDialogReadOnlyViewProp, prevState: ISettingsEmployeeDialogReadOnlyViewState): void {
        const props = this.props;
        const prevId = prevProps.employeeEdit && prevProps.employeeEdit.employee ? prevProps.employeeEdit.employee.id : "";
        const id = props.employeeEdit && props.employeeEdit.employee ? props.employeeEdit.employee.id : "";
        const prevRowId = prevProps.employeeEdit && prevProps.employeeEdit.employee ? prevProps.employeeEdit.employee.rowId : "";
        const rowId = props.employeeEdit && props.employeeEdit.employee ? props.employeeEdit.employee.rowId : "";
        if (prevId === id && prevRowId === rowId) return;
        this.setState(this.getStateFromProps(props));
    }

    // #region Documents
    handleDocumentDownload = (id: string) => {
        if (!id) return;
        const employeeEdit = this.props.employeeEdit;
        const document = employeeEdit.employee.documents.find(i => i.id === id);
        if (!document) return;
        if (document.file) {
            downloadjs(document.file, document.reference); //, "image/jpeg"
            return;
        }
        if (document.isNew()) return;
        documentService.getDocument(id).catch(error => {
            store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
        });
    };
    // #endregion Documents

    render() {
        const props = this.props;
        const state = this.state;
        const employeeEdit = this.props.employeeEdit;
        const employee = employeeEdit.employee;
        return (
            <div className="row">
                <div className="sideMenu col-3">
                    <div className="scrollSpyArea">
                        <ScrollSpy
                            dataContainer={this.employeeContentAreaDiv}
                            items={state.scrollSpyItems}
                            offset={0}
                        />
                    </div>
                </div>
                <div className="employeeContentArea employeeContainer col-9">
                    <div className="row">
                        <div id="general" className="col-3">
                            <ImageSelector
                                fileInputName="picture"
                                disabled={true}
                                image={employee.picture}
                                aspectRatio={null}
                                onChange={null}
                                onError={(message) => { store.customStore.dispatch(storeActions.showErrorMessage(message)); }}
                            />
                        </div>
                        <div className="col-9">
                            <div className="row">
                                <div className="col-2">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.Number}</label>
                                        <div className="readOnlyText">{employee.number.toString(10)}</div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.FirstName}</label>
                                        <div className="readOnlyText">{employee.firstName}</div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.LastName}</label>
                                        <div className="readOnlyText">{employee.lastName}</div>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.Abbreviation}</label>
                                        <div className="readOnlyText">{employee.abbreviation}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.UserGroup}</label>
                                        <div className="readOnlyText">{state.userGroupTitle}</div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.Username}</label>
                                        <div className="readOnlyText">{employee.username }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.Phone}</label>
                                        <div className="readOnlyText">{employee.phone}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.EmployeeGroupForDesign}</label>
                                <div className="readOnlyText">{state.employeeGroupTitle}</div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.EmployeeGroupForSalaryCalculation}</label>
                                <div className="readOnlyText">{state.salaryEmployeeGroupTitle}</div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.SalaryType}</label>
                                <div className="readOnlyText">{state.salaryTypeTitle}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.Email}</label>
                                <div className="readOnlyText">{employee.email}</div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label className="control-label smallFont">{t("employee.maponId")}</label>
                                <div className="readOnlyText">{employee.mapOnId}</div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.Vehicle}</label>
                                <div className="readOnlyText">{state.vehicleTitle}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.ErpReference}</label>
                                <div className="readOnlyText">{employee.erp}</div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.InUse}</label>
                                <div className="readOnlyText">{employee.activeState ? Translations.Yes : Translations.No}</div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.CostCenter}</label>
                                <div className="readOnlyText">{state.costCenterTitle}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.WorkhourBankInUse}</label>
                                <div className="readOnlyText">{employee.workTimeAccountInUse ? Translations.Yes : Translations.No}</div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.WorkQueuePostalCodeRangeStart}</label>
                                <div className="readOnlyText">{employee.postalCodeRangeStart}</div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.WorkQueuePostalCodeRangeEnd}</label>
                                <div className="readOnlyText">{employee.postalCodeRangeEnd}</div>
                            </div>
                        </div>
                    </div>
                    <div id="employeeGroups">
                        <SettingsEmployeeDialogEmployeeGroupList
                            title={Translations.SecondaryEmployeeGroups}
                            employeeId={employee.id}
                            reservedEmployeeGroupIds={[employee.employeeGroupId, employee.salaryEmployeeGroupId]}
                            readOnly={employee.isNew()}
                            items={props.employeeEmployeeGroups}
                            onRemoveEmployeeGroup={props.onEmployeeEmployeeGroupRemoved}
                            onAddEmployeeGroup={props.onAddEmployeeEmployeeGroup}
                        />
                    </div>
                    <div id="dayBookings">
                        <SettingsEmployeeDialogDayBookingList
                            title={Translations.DayBookings}
                            isReadOnly={employee.isNew()}
                            employeeId={employee.id}
                            dayBookingTypes={employeeEdit.dayBookingTypes}
                            items={props.dayBookings}
                            onDayBookingRemoved={props.onDayBookingRemoved}
                            onAddDayBooking={props.onAddDayBooking}
                            onEditDayBooking={props.onEditDayBooking}
                        />
                    </div>
                    {employee.workTimeAccountInUse &&
                        <div id="workHourTransactions">
                            <SettingsEmployeeWorkHourAccountTransactions
                                title={Translations.WorkhourBank + ", " + Translations.WorkHourBalance + " " + props.workHourBalance}
                                items={props.workHourTransactions}
                            />
                        </div>
                    }
                    <div id="competencies">
                        <SettingsEmployeeDialogEmployeeCompetencyListReadOnlyView
                            title={Translations.Competencies}
                            competencies={employeeEdit.competencies}
                            items={employee.competencies}
                            competencyWarningLimitInDays={employeeEdit.competencyWarningLimitInDays}
                        />
                    </div>
                    <div id="documents">
                        <DocumentList
                            title={Translations.Documents}
                            relatedObjectId={employee.id}
                            documents={employee.documents}
                            isReadOnly={true}
                            canShowCamera={false}
                            changeDocumentTypeEnabled={true}
                            onEditDocument={null}
                            onRemoveDocument={null}
                            onDownloadDocument={this.handleDocumentDownload}
                            onAddDocuments={null}
                            onAddPhoto={null}
                        />
                    </div>
                    <div id="integrations">
                        <SettingsEmployeeDialogSsoIntegrationList
                            title={Translations.SsoIntegrations}
                            isReadOnly={true}
                            items={employee.ssoIntegrations}
                            onEditSsoIntegration={null}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
