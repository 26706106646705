export interface ISalaryRowCalculated {
    employeeId: string;
    salaryRowTypeId: string;
    amount: number;
    startDate: number;
    endDate: number;
    comment: string;
    dayHoursRatio: number;
}

export class SalaryRowCalculated implements ISalaryRowCalculated {
    employeeId: string;
    salaryRowTypeId: string;
    amount: number;
    startDate: number;
    endDate: number;
    comment: string;
    dayHoursRatio: number;

    constructor();
    constructor(obj: ISalaryRowCalculated);
    constructor(obj?: any) {
        this.employeeId = obj && obj.employeeId || "";
        this.salaryRowTypeId = obj && obj.salaryRowTypeId || "";
        this.amount = obj && obj.amount || 0;
        this.startDate = obj && obj.startDate || null;
        this.endDate = obj && obj.endDate || null;
        this.comment = obj && obj.comment || "";
        this.dayHoursRatio = obj && obj.dayHoursRatio || null;
    }
}
