import { RefObject } from "react";
import { useDebounce } from "./useDebounce";
import { useEventListener } from "usehooks-ts";

type EventListenerCallback = (event: Event) => void;

type EventType = keyof DocumentEventMap;

type ElementRefType = RefObject<Document>;

export const useDebouncedEventListener = (
    eventType: EventType,
    handler: EventListenerCallback,
    delay: number,
    elementRef?: ElementRefType
) => {
    const debouncedHandler = useDebounce(handler, delay);
    useEventListener(eventType, debouncedHandler, elementRef);
};
