import { Translations } from "../../../models/translations";
import { TransportOrderDetailsDto } from "../../../models/transport/transportOrder";
import { RepeatEnabledDays } from "../../../models/transport/transportPlanTemplate";

export const getEnabledDays = (v: number, getAsNumbers: boolean = false) => {
    const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);
    const mapDay = (active: string, i: number) => {
        if(active !== "1") return;
        const str = RepeatEnabledDays[`${1 << i}`];
        if(str) return { week: str[str.length-1], day: str.replace(/\d/g, "") };
    };
    const dec2bin = Array.from((v >>> 0).toString(2)).reverse();
    const days = dec2bin.map((active, i) => mapDay(active, i));

    if (getAsNumbers) {
        const mapDayForButtons = (active: number) => {
            if ((v & active) !== 0) return active;
            return 0;
        };

        const buttonDays: number[] = [];
        for (let i = 1; i <= 14; i++) {
            const day = mapDayForButtons(1 << (i - 1));
            if (day !== 0) {
                buttonDays.push(day);
            }
        }

        const buttonResult = {
            [1]: buttonDays.filter(d => d <= RepeatEnabledDays.Sunday1).map(d => d),
            [2]: buttonDays.filter(d => d >= RepeatEnabledDays.Monday2).map(d => d)
        };

        return Object.fromEntries(Object.entries(buttonResult).map(([key, value]) => [Number(key), value]));
    } else {
        const enabledDays = {
            week1: days.filter(d => d?.week === "1").map(d => d?.day && capitalize(`${Translations[d.day]}`)),
            week2: days.filter(d => d?.week === "2").map(d => d?.day && capitalize(`${Translations[d.day]}`))
        };

        return enabledDays;
    }
};

export const convertDuration = (duration: number) => {
    const minutesPerDay = 24 * 60;
    const minutesPerHour = 60;
    const days = Math.floor(duration / minutesPerDay);
    const remainingDaysMinutes = duration % minutesPerDay;
    const hours = Math.floor(remainingDaysMinutes / minutesPerHour);
    const remainingHoursMinutes = remainingDaysMinutes % minutesPerHour;
    const remainingMinutes = remainingHoursMinutes;
    return {
        days: days,
        hours: hours,
        minutes: remainingMinutes
    };
};

export const setTimeForCurrentDate = (dateTime: string) => {
    if (dateTime) {
        const currentDate = new Date();
        currentDate.setHours(new Date(dateTime).getHours());
        currentDate.setMinutes(new Date(dateTime).getMinutes());
        currentDate.setSeconds(new Date(dateTime).getSeconds());
        return currentDate.toISOString();
    } else return null;
};

export const getCopyDataToTransportOrder = (order: TransportOrderDetailsDto) => {
    const copyData: TransportOrderDetailsDto = { ...order };
    copyData.deliveryStartDateTime = setTimeForCurrentDate(copyData.deliveryStartDateTime);
    copyData.deliveryEndDateTime = setTimeForCurrentDate(copyData.deliveryEndDateTime);
    copyData.pickUpStartDateTime = setTimeForCurrentDate(copyData.pickUpStartDateTime);
    copyData.pickUpEndDateTime = setTimeForCurrentDate(copyData.pickUpEndDateTime);
    const { id, state, waybillNumber, orderNumber, invoicingState, invoiceNewId, driverComments, ...restOrderDetails } = copyData;
    return (restOrderDetails as TransportOrderDetailsDto);
};
