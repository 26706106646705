import { Base } from "../../framework/base";
import { IWorkOrderTypeListItem, WorkOrderTypeListItem } from "./workOrderTypeListItem";

export interface IWorkOrderTypeListItems {
    items: IWorkOrderTypeListItem[];
    hasMore: boolean;
    page: number;
}

export class WorkOrderTypeListItems implements IWorkOrderTypeListItems {
    items: WorkOrderTypeListItem[];
    hasMore: boolean;
    page: number;

    constructor();
    constructor(obj: IWorkOrderTypeListItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = Base.getTypedArray<WorkOrderTypeListItem>(obj ? obj.items : null, WorkOrderTypeListItem);
    }
}