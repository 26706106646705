// WorkMainMap
// ***********************************************************************************************************************
import * as React from "react";
import { Point } from "leaflet";
import { Translations } from "../../models/translations";
import { Base } from "../../framework/base";
import { RadioButton } from "../framework/radio";
import { CalendarAccuracyType } from "../../models/common/enums";
import { IWorkOrderItem } from "../../models/work/workOrderItem";
import { Button } from "../framework/button";
import { ButtonDropdown } from "../framework/dropdown";
import { ToolButton } from "../framework/toolButton";
import { WorkMainWorkOrderGroup } from "./workMainWorkOrderGroup";
import { LocationMap } from "../framework/locationMap";
import { IVehicleItem } from "../../models/vehicle/vehicleItem";
import { WorkMainViewMode } from "./workMain";

interface IWorkMainMapProps {
    startDate: number;
    endDate: number;
    selectedWorkOrderId: string;
    selectedWorkOrderIds: string[];
    calendarAccuracy: CalendarAccuracyType;
    workOrders: IWorkOrderItem[];
    workOrderTemplates: IWorkOrderItem[];
    dataId: number;
    autoFitToBoundsId: number;

    onPreviousClick: () => void;
    onNextClick: () => void;
    onNewWorkOrder: () => void;
    onNewWorkOrderBasedOnTemplate: () => void;
    onNewProject: () => void;
    onNewProjectBasedOnTemplate: () => void;
    onOpenSelectedWorkOrder: () => void;
    onEditSelectedWorkOrder: () => void;
    onRemoveWorkOrder: () => void;
    onCopyWorkOrder: () => void;
    onAddCustomer: () => void;
    onAddContact: () => void;

    onSetSelectedWorkOrderId: (id: string) => void;
    onSetSelectedWorkOrderIds: (ids: string[]) => void;
    onOpenWorkOrder: (id: string) => void;
    onChangeViewMode: (viewMode: WorkMainViewMode) => void;
}

interface IWorkMainMapState {
    dataId: number;
    mapHeight: number;
    yOffset: number;
    mainContentHeight: number;
    showWorkOrderGroupMenu: boolean;
    workOrderGroupMenuTop: number;
    workOrderGroupMenuLeft: number;
    workOrderGroupMenuItems: IWorkOrderItem[];
}

export class WorkMainMap extends React.Component<IWorkMainMapProps, IWorkMainMapState> {
    private weekDiv: HTMLDivElement;
    private titleRowDiv: HTMLDivElement;

    constructor(props: IWorkMainMapProps) {
        super(props);
        this.state = {
            dataId: props.dataId,
            mainContentHeight: null,
            yOffset: 0,
            mapHeight: 0,
            showWorkOrderGroupMenu: false,
            workOrderGroupMenuTop: 0,
            workOrderGroupMenuLeft: 0,
            workOrderGroupMenuItems: []
        };
    }

    handleMapResize = Base.debounce((obj: WorkMainMap) => {
        obj.setState({ dataId: obj.state.dataId + 1 });
    }, 100);

    updateHeight = () => {
        if (!this.titleRowDiv) return;
        const obj = this;
        const state = this.state;
        const yOffset = this.titleRowDiv.clientHeight;
        const mapHeight = this.weekDiv.clientHeight - yOffset;
        if (state.mapHeight !== mapHeight) {
            this.setState({ mapHeight: mapHeight, yOffset: yOffset });
            obj.handleMapResize(obj);
        }
    };

    componentDidMount(): void {
        this.updateHeight();
        window.addEventListener("resize", this.updateHeight);
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.updateHeight);
    }

    handleSetSelectedIdsWithAllParameters = (ids: string[], layerPoint: Point, containerPoint: Point, originalEvent: MouseEvent) => {
        const props = this.props;
        const state = this.state;
        const workOrderGroupMenuItems = props.workOrders.filter(i => ids.indexOf(i.id) > -1);
        this.props.onSetSelectedWorkOrderIds(ids);
        if (workOrderGroupMenuItems.length < 2) return;
        this.setState({
            showWorkOrderGroupMenu: true,
            workOrderGroupMenuTop: containerPoint.y + 250 < state.mapHeight ? containerPoint.y : containerPoint.y - 250 + 36,
            workOrderGroupMenuLeft: containerPoint.x,
            workOrderGroupMenuItems: workOrderGroupMenuItems
        });
    };

    handleCancelWorkOrderGroupMenu = () => {
        this.setState({
            showWorkOrderGroupMenu: false
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        const workOrderIsSelected = !!props.selectedWorkOrderId;
        return (
            <div className="week" ref={(elem) => { this.weekDiv = elem; }}>
                <LocationMap
                    style={{ height: state.mapHeight ? state.mapHeight + "px" : null }}
                    points={props.workOrders}
                    refreshId={state.dataId}
                    autoFitToBoundsId={props.autoFitToBoundsId}
                    selectedIds={[props.selectedWorkOrderId]}
                    onSetSelectedIdsWithAllParameters={this.handleSetSelectedIdsWithAllParameters}
                />
                <div className="headerBackground">
                    <div className="titleRow" ref={(elem) => { this.titleRowDiv = elem; }}>
                        <div className="aRow">
                            <div className="titleCol">
                                <Button
                                    classes="btn-primary"
                                    title={Translations.NewWork}
                                    enabled={true}
                                    onClick={props.onNewWorkOrder}
                                    secondaryActions={[
                                        { title: Translations.NewProject, onClick: props.onNewProject },
                                        { title: Translations.NewWorkBasedOnWorkOrderTemplate, onClick: props.workOrderTemplates.filter(i => !i.isProject()).length > 0 ? props.onNewWorkOrderBasedOnTemplate : null },
                                        { title: Translations.NewProjectBasedOnWorkOrderTemplate, onClick: props.workOrderTemplates.filter(i => i.isProject()).length > 0 ? props.onNewProjectBasedOnTemplate : null }
                                    ]}
                                />
                                <ButtonDropdown
                                    selectedTitle={""}
                                    actions={[
                                        { disabled: !workOrderIsSelected, title: (Translations.Open), onClick: props.onOpenSelectedWorkOrder },
                                        { disabled: !workOrderIsSelected, title: (Translations.Edit), onClick: props.onEditSelectedWorkOrder },
                                        { disabled: !workOrderIsSelected, title: (Translations.Remove), onClick: props.onRemoveWorkOrder },
                                        { disabled: !workOrderIsSelected, title: (Translations.Copy), onClick: props.onCopyWorkOrder },
                                        { title: (Translations.NewCustomer), onClick: props.onAddCustomer },
                                        { title: (Translations.NewContactPerson), onClick: props.onAddContact },
                                    ]}
                                />
                            </div>
                            <div className="contentCol">
                                <div>
                                    <ToolButton
                                        title={Translations.Previous}
                                        classes="previous noFrame left"
                                        enabled={true}
                                        onClick={props.onPreviousClick}
                                    />
                                    <ToolButton
                                        title={Translations.Next}
                                        classes="next noFrame left"
                                        enabled={true}
                                        onClick={props.onNextClick}
                                    />
                                </div>
                                <div className="mainTitle">{props.startDate && props.endDate ? Base.timeToDateStr(props.startDate) + "-" + Base.timeToDateStr(props.endDate - 1) : ""}</div>
                                <div className="btn-group btn-group-toggle states">
                                    <RadioButton
                                        key={1}
                                        classes={"btn-secondary"}
                                        title={Translations.TimeTable}
                                        enabled={true}
                                        checked={false}
                                        onRadioClick={() => { props.onChangeViewMode(WorkMainViewMode.Calendar); }}
                                    />
                                    <RadioButton
                                        key={2}
                                        classes={"btn-secondary"}
                                        title={Translations.List}
                                        enabled={true}
                                        checked={false}
                                        onRadioClick={() => { props.onChangeViewMode(WorkMainViewMode.List); }}
                                    />
                                    <RadioButton
                                        key={0}
                                        classes={"btn-secondary"}
                                        title={Translations.Map}
                                        enabled={true}
                                        checked={true}
                                        onRadioClick={() => { props.onChangeViewMode(WorkMainViewMode.Map); }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {state.showWorkOrderGroupMenu &&
                    <WorkMainWorkOrderGroup
                        top={state.workOrderGroupMenuTop + 18}
                        left={state.workOrderGroupMenuLeft + 18}
                        items={state.workOrderGroupMenuItems}
                        onItemClick={props.onSetSelectedWorkOrderId}
                        onEditWorkOrderItem={props.onOpenWorkOrder}
                        onCancel={this.handleCancelWorkOrderGroupMenu}
                    />
                }
            </div>
        );
    }
}
