import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import Grid2 from "@mui/material/Unstable_Grid2";
import { TextField, debounce } from "@mui/material";
import { Translations } from "../../../models/translations";
import { TransportOrderInvoicingState, TransportOrderListItemDto, TransportOrderListQueryParameters } from "../../../models/transport/transportOrder";
import { MuiDateRangePicker } from "../../framework/muiDatepicker";
import { CustomerSelect } from "../components/customerSelect";
import { getTransportOrders } from "../../../services/transportOrderService";
import { getCustomerDetails } from "../../../services/customerService";
import { showApiError } from "../../framework/formUtils";
import { ICustomerDetails } from "../../../models/customer/customerDetails";

export interface TransportOrderFiltersProps {
    setOrderItems: (data: TransportOrderListItemDto[]) => void;
    showOnlyInvoiceable: boolean;
    disabledCustomer?: boolean;
    selectedInvoiceCustomerId?: string;
}

interface FormQueryParamsProps {
    showOnlyInvoiceable: boolean;
    filterCustomer: string;
    timeRange: [Date, Date];
    keyword: string;
}

const formQueryParams = ({ showOnlyInvoiceable, filterCustomer, timeRange, keyword }: FormQueryParamsProps) => {
    let qsp: TransportOrderListQueryParameters = {
        ...(filterCustomer) && { billingCustomerId: filterCustomer },
        ...(keyword) && { keyword: keyword },
    };
    if (showOnlyInvoiceable) {
        qsp = {
            ...(timeRange[0]) && { actualDeliveryStart: timeRange[0].toISOString() },
            ...(timeRange[1]) && { actualDeliveryEnd: timeRange[1].toISOString() },
            ...(showOnlyInvoiceable && { invoicingState: TransportOrderInvoicingState.Invoiceable }),
            ...qsp
        };
    } else {
        qsp = {
            ...(timeRange[0]) && { timeFrameStartDate: timeRange[0].toISOString() },
            ...(timeRange[1]) && { timeFrameEndDate: timeRange[1].toISOString() },
            ...qsp
        };
    }
    return qsp;
};

export const TransportOrderFilters = ({ setOrderItems, disabledCustomer, showOnlyInvoiceable, selectedInvoiceCustomerId }: TransportOrderFiltersProps) => {
    const [filterCustomer, setFilterCustomer] = useState<string>(selectedInvoiceCustomerId ? selectedInvoiceCustomerId : "");
    const DEFAULT_TIMERANGE = 14;
    const [timeRange, setTimeRange] = useState<[Date, Date]>([
        dayjs().startOf("date").subtract(DEFAULT_TIMERANGE, "days").toDate(),
        dayjs().endOf("date").toDate(),
    ]);
    const [keyword, setKeyword] = useState<string>("");
    const [selectedCustomer, setSelectedCustomer] = useState<ICustomerDetails>();

    useEffect(() => {
        const fetchOrders = async() => {
            const res = await getTransportOrders(
                formQueryParams({ showOnlyInvoiceable, filterCustomer, timeRange, keyword })
            );
            if (res?.data) setOrderItems(res.data);
        };
        if (timeRange) {
            fetchOrders()
                .catch((e) => showApiError(`${Translations.TransportOrdersFetchFail}`));
        }
    }, [timeRange, showOnlyInvoiceable, keyword, filterCustomer, formQueryParams]);

    const debouncedSetKeyword = debounce((value: string) => {
        setKeyword(value);
    }, 1000);

    useEffect(() => {
        if (selectedInvoiceCustomerId) {
            getCustomerDetails(selectedInvoiceCustomerId)
                .then((res) => {
                    setSelectedCustomer(res.data);
                })
                .catch((error) => {
                    showApiError(error.message);
                });
        }
    }, [selectedInvoiceCustomerId]);

    return (
        <div>
            <Grid2 container spacing={2}>
                <Grid2>
                    <MuiDateRangePicker
                        labels={[Translations.DateRangeStart, Translations.DateRangeEnd]}
                        value={timeRange}
                        onChange={(val) => setTimeRange(val)}
                    />
                </Grid2>
                <Grid2>
                    {disabledCustomer ?
                        <TextField
                            value={selectedCustomer?.name}
                            label={Translations.Customer}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                readOnly: true,
                            }}
                            disabled
                        /> :
                        <CustomerSelect
                            value={filterCustomer}
                            onChange={(val: string) => setFilterCustomer(val)}
                            disabled={disabledCustomer}
                        />
                    }
                </Grid2>
                <Grid2>
                    <TextField
                        label={Translations.Search}
                        onChange={(e) => debouncedSetKeyword(e.currentTarget.value)}
                    />
                </Grid2>
            </Grid2>
        </div>
    );
};