import * as React from "react";
import { ReactNode } from "react";
import { Box, Stack, lighten } from "@mui/material";

interface Props {
    children: ReactNode;
    className?: string;
    bgClass?: string;
    onClick?: () => void;
}

export const TimelinePlannerItem = ({ children, className, bgClass, onClick }: Props) => {
    return (
        <div
            className={"w-full rounded-sm p-1 border-gray-light border-1 transition-bg "
                + (className ? className : "")
                + (onClick ? " cursor-pointer " : " ") + " "
                + (bgClass ? bgClass : "bg-light hover:bg-white")}
            onClick={onClick}
        >
            <Stack spacing={1}>
                {children}
            </Stack>
        </div>
    );
};

interface PillProps {
    content: string | ReactNode;
    color: string;
    actions?: ReactNode;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const Pill = (props: PillProps) => {

    const { content, actions, onClick } = props;

    return (
        <Box
            sx={{
                width: "100%",
                backgroundColor: props.color,
                "&:hover": {
                    backgroundColor: lighten(props.color, 0.2)
                }
            }}
            className="rounded-pill overflow-ellipsis font-medium transition-bg"
            onClick={onClick}
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                {content}
                <Stack direction="row">
                    {actions}
                </Stack>
            </Stack>
        </Box>
    );
};
