import { Base } from "../../framework/base";
import { AppUtils } from "../common/appUtils";
import { IEditItem, EditItem } from "../common/editItem";

export interface IRoutePointWorkShiftTimeSlotItem extends IEditItem {
    workShiftTimeSlotTypeId: string;
    employeeId: string;
    routePointId: string;
    vehicleId: string;
    state: number;
    startTime: number;
    endTime: number;
    startLatitude: number;
    startLongitude: number;
    startLocationName: string;
    endLatitude: number;
    endLongitude: number;
    endLocationName: string;
    description: string;
    editTime: number;
    editUser: string;

    getDurationMin(): number;
    getDurationStr(): string;
}

export class RoutePointWorkShiftTimeSlotItem extends EditItem implements IRoutePointWorkShiftTimeSlotItem {
    workShiftTimeSlotTypeId: string;
    employeeId: string;
    routePointId: string;
    vehicleId: string;
    state: number;
    startTime: number;
    endTime: number;
    startLatitude: number;
    startLongitude: number;
    startLocationName: string;
    endLatitude: number;
    endLongitude: number;
    endLocationName: string;
    description: string;
    editTime: number;
    editUser: string;

    constructor();
    constructor(obj: IRoutePointWorkShiftTimeSlotItem);
    constructor(obj?: any) {
        super(obj);
        this.workShiftTimeSlotTypeId = obj && obj.workShiftTimeSlotTypeId || "";
        this.employeeId = obj && obj.employeeId || "";
        this.routePointId = obj && obj.routePointId || "";
        this.vehicleId = obj && obj.vehicleId || "";
        this.state = obj && obj.state || 0;
        this.startTime = obj && obj.startTime || 0;
        this.startLatitude = obj && obj.startLatitude || 0;
        this.startLongitude = obj && obj.startLongitude || 0;
        this.startLocationName = obj && obj.startLocationName || "";
        this.endTime = obj && obj.endTime || null;
        this.endLatitude = obj && obj.endLatitude || 0;
        this.endLongitude = obj && obj.endLongitude || 0;
        this.endLocationName = obj && obj.endLocationName || "";
        this.description = obj && obj.description || "";
        this.editTime = obj && obj.editTime || 0;
        this.editUser = obj && obj.editUser || "";
    }

    getDurationMin(): number {
        if (!this.startTime || !this.endTime) return 0;
        return Base.dateDiffInMinutes(new Date(this.startTime), new Date(this.endTime));
    }

    getDurationStr(): string {
        return AppUtils.getDurationStrByDurationMin(this.getDurationMin());
    }

    static sortRoutePointWorkShiftTimeSlotItems(items: IRoutePointWorkShiftTimeSlotItem[]) {
        if (Base.isNullOrUndefined(items) || items.length < 2) return;
        items.sort((a: IRoutePointWorkShiftTimeSlotItem, b: IRoutePointWorkShiftTimeSlotItem) => { return (!a || !b) ? 0 : Base.numberCompare(a.startTime, b.startTime); });
    }
}

export class RoutePointSaveWorkShiftTimeSlotItem {
    id: string;
    workShiftTimeSlotTypeId: string;
    routePointId: string;
    employeeId: string;
    vehicleId: string;
    state: number;
    startTime: number;
    endTime: number;
    description: string;
    rowId: string;

    constructor();
    constructor(obj: IRoutePointWorkShiftTimeSlotItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.workShiftTimeSlotTypeId = obj && obj.workShiftTimeSlotTypeId || "";
        this.routePointId = obj && obj.routePointId || "";
        this.employeeId = obj && obj.employeeId || "";
        this.vehicleId = obj && obj.vehicleId || "";
        this.state = obj && obj.state || 0;
        this.startTime = obj && obj.startTime || null;
        this.endTime = obj && obj.endTime || null;
        this.description = obj && obj.description || "";
        this.rowId = obj && obj.rowId || "";
    }
}
