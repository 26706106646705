import { CalendarLineType, EnumHelper } from "../common/enums";

export interface IWorkOrderCalendarLineItem {
    id: string;
    groupId: string;
    name: string;
    lineType: CalendarLineType;
    abbreviation: string;
    picture: string;
    number: number;
    customerName: string;
    description: string;
    childLineIds: string[];

    isProject(): boolean;
    isRecurringProject(): boolean;
    isTeam(): boolean;
    isEmployee(): boolean;
    isVehicle(): boolean;
}

export class WorkOrderCalendarLineItem implements IWorkOrderCalendarLineItem {
    id: string;
    groupId: string;
    name: string;
    lineType: CalendarLineType;
    abbreviation: string;
    picture: string;
    number: number;
    customerName: string;
    description: string;
    childLineIds: string[];

    constructor();
    constructor(obj: IWorkOrderCalendarLineItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.groupId = obj && obj.groupId || "";
        this.name = obj && obj.name || "";
        this.lineType = obj && obj.lineType || CalendarLineType.Employee;
        this.abbreviation = obj && obj.abbreviation || "";
        this.picture = obj && obj.picture || "";
        this.number = obj && obj.number || 0;
        this.customerName = obj && obj.customerName || "";
        this.description = obj && obj.description || "";
        this.childLineIds = obj && obj.childLineIds || [];
    }

    isProject(): boolean {
        return EnumHelper.isEqual(this.lineType, CalendarLineType.Project) || EnumHelper.isEqual(this.lineType, CalendarLineType.RecurringProject);
    }

    isRecurringProject(): boolean {
        return EnumHelper.isEqual(this.lineType, CalendarLineType.RecurringProject);
    }

    isTeam(): boolean {
        return EnumHelper.isEqual(this.lineType, CalendarLineType.Team);
    }

    isEmployee(): boolean {
        return EnumHelper.isEqual(this.lineType, CalendarLineType.Employee);
    }

    isVehicle(): boolean {
        return EnumHelper.isEqual(this.lineType, CalendarLineType.Vehicle);
    }
}