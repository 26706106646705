export interface IToolEditItem {
    id: string;
    number: number;
    vehicleId: string;
    name: string;
    amount: number;
    description: string;
    comment: string;
    activeState: number;
    rowId: string;

    isNew():boolean;
}

export class ToolEditItem implements IToolEditItem {
    id: string;
    number: number;
    vehicleId: string;
    name: string;
    amount: number;
    description: string;
    comment: string;
    activeState: number;
    rowId: string;

    constructor();
    constructor(obj: IToolEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.vehicleId = obj && obj.vehicleId || "";
        this.name = obj && obj.name || "";
        this.amount = obj && obj.amount || 0;
        this.description = obj && obj.description || "";
        this.comment = obj && obj.comment || "";
        this.activeState = obj && obj.activeState || 0;
        this.rowId = obj && obj.rowId || "";
    }

    isNew():boolean {
        return !this.rowId;
    }
}