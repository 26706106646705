import { SalaryItem } from "../models/workHourSalary/salaryItem";
import { apiCall } from "./apiClient";

export interface SalariesParams {
    salaryPeriodId: string;
    employeeGroupId: string;
}

export const getWorkHourSalaries = (params: SalariesParams, signal?: AbortSignal) => {
    return apiCall<SalaryItem[]>("WorkHourSalary", "GET", params, signal);
};
