import { ISalaryEditItem, SalaryEditItem } from "./salaryEditItem";
import { Base } from "../../framework/base";

export interface ISalaryEdit {
    salaryFileGenerationInUse: boolean;
    salaries: ISalaryEditItem[];
}

export class SalaryEdit implements ISalaryEdit {
    salaryFileGenerationInUse: boolean;
    salaries: SalaryEditItem[];

    constructor();
    constructor(obj: ISalaryEdit);
    constructor(obj?: any) {
        this.salaryFileGenerationInUse = obj && obj.salaryFileGenerationInUse || 0;
        this.salaries = Base.getTypedArray<SalaryEditItem>(obj ? obj.salaries : null, SalaryEditItem);
    }
}