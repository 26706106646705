import { ApiSuccess, SaveSuccess } from "./baseService";
import { getApiCall, postFormApiCall, postApiCall } from "./tokenService";
import { ContactItems } from "../models/contact/contactItems";
import { ContactEdit } from "../models/contact/contactEdit";
import { IWorkOrderEditContacts, WorkOrderEditContacts } from "../models/work/workOrderEditContacts";

export const getContactItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean, showNotInUse: boolean): Promise<ContactItems> => {
    return getApiCall<ContactItems>("api/contact/?page=" + (page || "") + "&pageSize=" + (pageSize || "") +
        "&sortColumn=" + (sortColumn ? encodeURIComponent(sortColumn) : "") + "&sortOrder=" + (sortOrderIsAsc ? "asc" : "desc") +
        "&filter=" + (filter ? encodeURIComponent(filter) : "") + "&showNotInUse=" + (showNotInUse ? "1" : "0"), ContactItems);
};

export const getContactEdit = (id: string): Promise<ContactEdit> => {
    return getApiCall<ContactEdit>("api/contact/edit/?id=" + (id || ""), ContactEdit);
};

export const saveContactEdit = (data: FormData): Promise<SaveSuccess> => {
    return postFormApiCall<SaveSuccess>("api/contact/save", data, SaveSuccess);
};

export const removeContact = (id: string): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/contact/remove/?id=" + (id || ""), ApiSuccess);
};

export const getWorkOrderEditContacts = (ignoreIds: string[]): Promise<IWorkOrderEditContacts> => {
    return postApiCall<WorkOrderEditContacts>("api/contact/workordercontact", WorkOrderEditContacts, JSON.stringify(ignoreIds));
};