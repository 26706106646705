export interface ICalendarDayItem {
    day: number;
    dayType: number;
    description: string;
}

export class CalendarDayItem implements ICalendarDayItem {
    day: number;
    dayType: number;
    description: string;

    constructor();
    constructor(obj: ICalendarDayItem);
    constructor(obj?: any) {
        this.day = obj && obj.day || 0;
        this.dayType = obj && obj.dayType || 0;
        this.description = obj && obj.description || "";
    }
}