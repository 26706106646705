import AddIcon from "@mui/icons-material/Add";
import { Alert, Button, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import { Translations } from "../../models/translations";
import { StorageState, updateStoragesFilters } from "../../store/storageSlice";
import { useDebounce } from "../hooks/useDebounce";
import { MainLayout } from "../layout/mainLayout";
import { StorageSearchField } from "./components/storageSearchField";
import { StorageSelect } from "./components/storageSelect";
import { StorageDialog } from "./storages/storageDialog";
import { StorageList } from "./storages/storageList";
import { STORAGE_SEARCH_DELAY } from "./storageUtils";
import { StorageEditItem } from "../../models/storage/storage";
import { getStorage, getStorages } from "../../services/storageService";
import { StorageListItem } from "../../models/storage/storageListiItem";
import { extraFilterValue } from "./storageProductsMain";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";

export const StorageStoragesMain = () => {
    const [storages, setStorages] = useState<StorageListItem[]>([]);
    const [showStorageDialog, setShowStorageDialog] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<StorageEditItem | null>();
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const state = useAppSelector(state => state.storage);
    const filters = state.storagesTab.filters;
    const dispatch = useAppDispatch();

    const openCreateDialog = () => {
        setSelectedItem(new StorageEditItem());
        setShowStorageDialog(true);
    };

    const handleShowDetails = (id: string) => {
        void getStorage(id).then(item => {
            setSelectedItem(item);
            setShowStorageDialog(true);
        });
    };

    const handleSearch = useDebounce((filters: StorageState["storagesTab"]["filters"]) => {
        store.customStore.dispatch(storeActions.fetchStart());
        getStorages({
            filter: filters.searchTerm,
            extraFilterObject: {
                storage: extraFilterValue(filters.selectedStorages),
            }
        }).then(storageData => {
            setError(null);

            const rowData = storageData.map(v => {
                const routePoint = v.routePoints[0];

                return {
                    name: v.name,
                    id: v.id,
                    customerId: v.customerId,
                    rowId: v.rowId,
                    routePointId: routePoint?.id ?? "",
                    street: routePoint?.street ?? "",
                    postalCode: routePoint?.postalCode ?? "",
                    city: routePoint?.city ?? "",
                    countryCode: routePoint?.countryCode ?? "",
                    routePointRowId: routePoint?.rowId ?? "",
                    convertToIStorageSiteSaveRequest: null,
                };
            });
            setStorages(rowData);
        }).catch((err: string) => {
            setError(err);
        }).finally(() => {
            setLoading(false);
            store.customStore.dispatch(storeActions.fetchEnd());
        });
    }, STORAGE_SEARCH_DELAY);

    const handleClose = () => {
        setSelectedItem(null);
        setShowStorageDialog(false);
        handleSearch(filters);
    };

    const handleRemove = () => handleClose();

    useEffect(() => {
        setLoading(true);
        handleSearch(filters);
    }, [filters, handleSearch]);

    return (
        <MainLayout
            className="storage-products"
            topComponent={<div>
                <Typography variant="h3">{Translations.SearchConditions}</Typography>
                <Grid2 container spacing={2}>
                    <Grid2>
                        <StorageSelect value={filters.selectedStorages} onChange={(val) => dispatch(updateStoragesFilters({ selectedStorages: val }))}/>
                    </Grid2>
                    <Grid2>
                        <StorageSearchField value={filters.searchTerm} onChange={(val) => dispatch(updateStoragesFilters({ searchTerm: val }))}/>
                    </Grid2>
                </Grid2>
            </div>}
        >
            <>
                <Grid2 container justifyContent="space-between">
                    <Typography variant="h3">{Translations.Storages}</Typography>

                    <span>
                        <Button
                            variant="save" startIcon={<AddIcon/>}
                            onClick={openCreateDialog}
                        >{Translations.CreateNewStorage}
                        </Button>
                    </span>
                </Grid2>

                <StorageList
                    storages={storages}
                    onShowDetails={handleShowDetails}
                />

                {error &&
                    <Alert severity="error">{Translations.FetchFailed}</Alert>
                }
                {(!loading && !error && storages.length === 0) &&
                    <Alert severity="info">{Translations.NoMatchesFound}</Alert>
                }

                {showStorageDialog &&
                    <StorageDialog
                        item={selectedItem}
                        hasStorageLocations={selectedItem?.hasStorageLocations}
                        open={showStorageDialog}
                        onClose={handleClose}
                        onRemove={handleRemove}
                    />
                }
            </>
        </MainLayout>
    );
};
