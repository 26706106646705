export class SaveData {
    formData: FormData;
    hash: string;

    constructor() {
        this.formData = new FormData();
        this.hash = "";
    }

    append(name: string, value: string | Blob, fileName?: string) {
        if (typeof value === "string") {
            this.formData.append(name, value as string);
            this.hash = this.hash + JSON.stringify({ n: name, v: value });
        } else {
            this.formData.append(name, value as Blob, fileName);
            this.hash = this.hash + JSON.stringify({ n: name, v: value, f: fileName });
        }
    }
}
