import { CostCenterEditItem, ICostCenterEditItem } from "./costCenterEditItem";

export interface ICostCenterEdit {
    costCenter: ICostCenterEditItem;
}

export class CostCenterEdit implements ICostCenterEdit {
    costCenter: CostCenterEditItem;

    constructor();
    constructor(obj: ICostCenterEdit);
    constructor(obj?: any) {
        this.costCenter = null;
        if (obj && obj.costCenter) {
            this.costCenter = new CostCenterEditItem(obj.costCenter);
        }
    }
}