import * as React from "react";
import { IRoutePointItem } from "../../models/routePoint/routePointItem";
import { IStorageProductBooking } from "../../models/storage/storageProductBooking";
import Box from "@mui/material/Box";
import { DataGridPremium, GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { Translations } from "../../models/translations";
import { Redirect, withRouter } from "react-router-dom";
import { infiniteListPageSize } from "../../models/common/consts";
import { Stack, TextField } from "@mui/material";
import { Button } from "../framework/button";
import { removeStorageBookings, saveStorageBooking } from "../../services/workOrderService";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import { IStorageProductSave } from "../../models/work/StorageProductBooking/StorageProductSave";
import { StorageProductBookingResponse } from "../../models/work/StorageProductBooking/StorageProductBookingResponse";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { RoutePointTypeEnum } from "../../models/common/enums";
import DeleteIcon from "@mui/icons-material/Delete";
import { Base } from "../../framework/base";
import { CheckBox } from "../framework/checkbox";
import { showError } from "../storage/storageUtils";

interface IWorkOrderStorageProductsProps {
    routePoints: IRoutePointItem[];
    storageProductBookings: IStorageProductBooking[];
    isPreliminary: boolean;
    isReadOnly: boolean;
    workOrderId: string;
    showCollectionListInMobile: boolean;
    onChange: (storageProductBookings: IStorageProductBooking[]) => void;
    onCloseDialog: () => void;
    onSetShowCollectionListInMobile: (value: boolean) => void;
}
interface IWorkOrderStorageProductsState {
    routeItems: RouteItemClass[];
    storageBookings: IStorageProductBooking[];
    valueSelection: boolean;
    removeSelectedActive: boolean;
    originalStorageBookingHash: string;
    moveToStorage: boolean;
}
class RouteItemClass {
    constructor(id, name) {
        this.value = id;
        this.label = name;
    }

    value: string;
    label: string;
}

export class WorkOrderStorageProducts extends React.Component<IWorkOrderStorageProductsProps, IWorkOrderStorageProductsState> {
    constructor(props) {
        super(props);
        const route = this.props.routePoints
            .filter(i => i.routePointTypeCode === RoutePointTypeEnum.Delivery)
            .map(i => new RouteItemClass(i.id, i.name))
            .slice(0);

        this.props.storageProductBookings.forEach((i: IStorageProductBooking) => {
            if ((!i.deliveryRoutePointId || i.deliveryRoutePointId === Base.emptyGuid) && route.length > 0) {
                i.deliveryRoutePointId = route[0].value;
            }
        });

        const storageProductBookings = this.props.storageProductBookings;
        const hash = JSON.stringify(storageProductBookings);
        this.state =
        {
            routeItems: route,
            storageBookings: storageProductBookings,
            valueSelection: false,
            removeSelectedActive: false,
            originalStorageBookingHash: hash,
            moveToStorage: false
        };
    }
    public componentDidUpdate() {
        const route = this.props.routePoints
            .filter(i => i.routePointTypeCode === RoutePointTypeEnum.Delivery)
            .map(i => new RouteItemClass(i.id, i.name))
            .slice(0);

        if (JSON.stringify(this.state.routeItems) !== JSON.stringify(route)) {
            this.setState(
                {
                    routeItems: route,
                });
        }
    }

    private removeLine(params: GridRenderCellParams): void {
        if (this.props.isReadOnly) {
            return;
        }
        store.customStore.dispatch(storeActions.setConfirmation(
            ConfirmationDialogType.Warning,
            Translations.Warning,
            Translations.AreYouSureWantDelete,
            () => {
                store.customStore.dispatch(storeActions.fetchStart());
                const state = this.state;
                const item = params.row;
                const storage = state.storageBookings.filter(itm => itm.id === item.id);
                const ids: [] = storage.map((i) => i.id) as [];

                if (ids && ids.length > 0) {
                    removeStorageBookings(ids)
                        .then((item: StorageProductBookingResponse) => {
                            this.setState({
                                storageBookings: item.items
                            });
                            this.props.onChange(item.items);
                        })
                        .catch(error => {
                            showError(error);
                            store.customStore.dispatch(storeActions.fetchEnd());
                        })
                        .finally(() => {
                            store.customStore.dispatch(storeActions.fetchEnd());
                            store.customStore.dispatch(storeActions.clearConfirmation());
                        });
                }
            },
            null,
            () => {
                store.customStore.dispatch(storeActions.clearConfirmation());
            }, null, null, null, "hidden"));
    }

    private removeSelected() {
        store.customStore.dispatch(storeActions.setConfirmation(
            ConfirmationDialogType.Warning,
            Translations.Warning,
            Translations.AreYouSureWantDelete,
            () => {
                store.customStore.dispatch(storeActions.fetchStart());
                const state = this.state;
                const ids: [] = state.storageBookings
                    .filter(i => i.isRowSelected)
                    .map((i) => i.id) as [];
                if (ids && ids.length > 0) {
                    removeStorageBookings(ids)
                        .then((item: StorageProductBookingResponse) => {
                            this.setState({
                                storageBookings: item.items
                            });
                            this.props.onChange(item.items);
                        })
                        .catch(error => {
                            showError(error);
                            store.customStore.dispatch(storeActions.clearConfirmation());
                        })
                        .finally(() => {
                            store.customStore.dispatch(storeActions.fetchEnd());
                            store.customStore.dispatch(storeActions.clearConfirmation());
                        });
                }
            }, () => {
                store.customStore.dispatch(storeActions.clearConfirmation());
            }, () => {
                store.customStore.dispatch(storeActions.clearConfirmation());
            }));
    }

    private save(moveToShoppingCardIfOk: boolean) {
        store.customStore.dispatch(storeActions.fetchStart());
        const state = this.state;
        if (state.storageBookings.some(i => i.availableAmount < 0 || i.bookedAmount <= 0)) {
            storeActions.showErrorMessage(Translations.CheckAmounts);
            return;
        }
        state.storageBookings.forEach(item => item.deliveryRoutePointId === "" ? null : item.deliveryRoutePointId);
        const saveValues = { items: state.storageBookings } as IStorageProductSave;
        saveStorageBooking(saveValues)
            .then((item: StorageProductBookingResponse) => {
                if (item.notValidAmountLines.length > 0) {
                    storeActions.showErrorMessage(
                        Translations.BookingContainsNotValidLines
                    );
                }
                let moveToStorage = false;
                const hash = JSON.stringify(item.items);
                if (moveToShoppingCardIfOk) {
                    store.customStore.dispatch(storeActions.shoppingCartSelectOrder(this.props.workOrderId));
                    moveToStorage = true;
                }
                this.setState({
                    storageBookings: item.items,
                    originalStorageBookingHash: hash,
                    moveToStorage: moveToStorage
                });

                this.props.onChange(item.items.slice(0));
            })
            .catch(error => {
                showError(error);
            })
            .finally(() => {
                store.customStore.dispatch(storeActions.fetchEnd());
            });
    }

    private handleAmount = (params: any): void => {
        const state = this.state;
        const val = +String(params.value).replace(",", ".");
        const item = params.row as IStorageProductBooking;
        const storage = state.storageBookings.slice(0);
        let setThis = false;
        let availableCo = 0;

        storage.forEach((itm: IStorageProductBooking) => {
            if (itm.id === item.id && itm.bookedAmount !== val) {
                if ((itm.availableAmount + (itm.bookedAmount > val ? val : -val)) <= itm.totalAmount) {
                    availableCo = itm.bookedAmount - val;
                    itm.bookedAmount = val;
                    setThis = true;
                }
            }
        });

        if (setThis) {
            storage.forEach((itm: IStorageProductBooking) => {
                if (itm.storageProductId === item.storageProductId) {
                    itm.availableAmount = itm.availableAmount + availableCo;

                    if (itm.bookedAmount <= 0) {
                        itm.state = Translations.ErrorState;
                    } else {
                        itm.state = "";
                    }
                }
            });

            this.setState({
                storageBookings: storage
            });
        }
    };

    private setSelected(event, id: string) {
        const value = event.target.checked;
        const state = this.state;
        const item = state.storageBookings.find(i => i.id === id);

        if (value !== item.isRowSelected) {
            item.isRowSelected = value;
            let selectedMode;
            let removeActive = false;
            if (state.storageBookings.every(i => i.isRowSelected === true)) {
                selectedMode = true;
            } else {
                selectedMode = false;
            }
            if (state.storageBookings.some(i => i.isRowSelected === true)) {
                removeActive = true;
            }
            this.setState({
                storageBookings: state.storageBookings,
                valueSelection: selectedMode,
                removeSelectedActive: removeActive
            });
        }
    }

    /**
     * Fill first check box value.
     * @param event
     */
    private fillSelectCheckBox(event) {
        const state = this.state;
        state.storageBookings.forEach(i => {
            i.isRowSelected = event.target.checked;
        });

        this.setState({
            storageBookings: state.storageBookings,
            valueSelection: event.target.checked,
            removeSelectedActive: event.target.checked
        });
    }

    /**
     * On Change event for the delivery select box.
     *
     * @param value
     * @param id
     */
    private onChangeDeliverySelectBox(value: any, id) {
        const storageProductBookings = this.state.storageBookings;

        storageProductBookings.forEach(i => {
            if (i.id === id) {
                i.deliveryRoutePointId = value;
            }
        });

        this.setState(
            {
                storageBookings: storageProductBookings
            }
        );
    }

    //Todo somehow put this work.
    private moveToShoppingCart() {
        const state = this.state;
        if (JSON.stringify(state.storageBookings) !== state.originalStorageBookingHash) {
            store.customStore.dispatch(storeActions.setConfirmation(
                ConfirmationDialogType.Warning,
                Translations.Warning,
                Translations.DoYouWantSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    this.save(true);

                }, () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));

        } else {
            store.customStore.dispatch(storeActions.shoppingCartSelectOrder(this.props.workOrderId));
            this.setState(
                {
                    moveToStorage: true
                }
            );
        }
    }

    /* eslint-disable */
    /**
     * Fill columns for the data grid.
     *
     * @param state
     */
    private getColums(state: IWorkOrderStorageProductsState): GridColDef<IStorageProductBooking>[] {
        const props = this.props;
        const cols: GridColDef<IStorageProductBooking>[] = [
            {
                field: "isRowSelected",
                width: 100,
                renderCell: (params) => (
                    <input
                        type="checkbox"
                        checked={params.value}
                        onClick={(event) => this.setSelected(event, params.row.id)}
                        disabled={props.isReadOnly}
                    />
                ),
                renderHeader: (params: GridColumnHeaderParams) => (
                    <input
                        type="checkbox"
                        checked={state.valueSelection}
                        onClick={(event) => this.fillSelectCheckBox(event)}
                        disabled={props.isReadOnly}
                    />
                ),
                editable: false,
                sortable: false
            },
            {
                field: "storageName",
                headerName: Translations.Storage,
                width: 150,
                type: "string",
                editable: false,
            },
            {
                field: "storageLocationName",
                headerName: Translations.Location,
                width: 150,
                type: "string",
                editable: false,
            },

            {
                field: "productName",
                headerName: Translations.ProductName,
                width: 200,
                type: "string",
                editable: false,
            },
            {
                field: "deliveryRoutePointId",
                headerName: Translations.DeliveryPoint,
                editable: false,
                renderCell: (params: GridRenderCellParams) => {
                    if (props.isReadOnly) {
                        return params.value;
                    }

                    return <div><select style={{ width: "100%" }}
                        value={params.value}
                        onChange={(event) => this.onChangeDeliverySelectBox(event.target.value, params.row.id)}
                    >
                        <option value=""> - </option>
                        {state.routeItems.map((item) => {
                            return <option value={item.value}>{item.label}</option>;
                        })}
                    </select>
                        <button title={Translations.Remove} className="btn btn-tool round  trashbox" onClick={() => this.onChangeDeliverySelectBox("", params.row.id)}><span className="icon"></span></button></div>;
                },
                width: 200,
            },
            {
                field: "bookedAmount",
                headerName: Translations.Amount,
                width: 100,
                type: "number",
                editable: !props.isReadOnly,
                // @ts-expect-error: handleAmount should return a new version of the row, instead of updating component state
                valueSetter: (params) => this.handleAmount(params),
                renderCell: (params: GridRenderCellParams) => {
                    if (props.isReadOnly) {
                        return params.value;
                    }

                    return <TextField
                        value={params.value}
                        style={{ width: "100%" }}/>
                }
            },
            {
                field: "unit",
                headerName: Translations.MeasureUnit,
                width: 100,
                type: "string",
                editable: false,
            },
            {
                field: "availableAmount",
                headerName: Translations.AvailableForBooking,
                width: 100,
                type: "number",
                editable: false,
            },

            {
                field: "action",
                headerName: "",
                sortable: false,
                width: 60,
                renderCell: (params) => {
                    if (props.isReadOnly) {
                        return <></>;
                    }
                    return (
                        <button title={Translations.Edit} className="btn btn-tool auto-size round" onClick={() => this.removeLine(params)}>
                            <DeleteIcon color="error"/>
                        </button>
                    )
                },
                editable: false,
            }
        ];

        return cols;
    }

    render() {
        const state = this.state;
        const props = this.props;

        return (
            <div>
                <div className="row">
                    <Button
                        classes="btn-danger mb-2 ml-3"
                        onClick={() => this.removeSelected()}
                        title={Translations.RemoveSelected}
                        enabled={state.removeSelectedActive}>
                        {Translations.RemoveSelected}
                    </Button>
                    <Button
                        classes="btn-primary mb-2 ml-5"
                        onClick={() => this.save(false)}
                        title={Translations.Save}
                        enabled={!props.isReadOnly}>
                        {Translations.Save}
                    </Button>
                    <Button
                        onClick={() => this.moveToShoppingCart()}
                        classes="btn btn-primary mb-2 ml-2"
                        title={Translations.ToShoppingCart}
                        enabled={!props.isReadOnly || !props.isPreliminary}>
                        {Translations.ToShoppingCart}
                    </Button>
                    <CheckBox
                        classes="ml-auto mr-3"
                        title={Translations.UseCollectionListInMobile}
                        enabled={true}
                        checked={props.showCollectionListInMobile}
                        onCheckboxClickBoolean={props.onSetShowCollectionListInMobile}
                    />
                    {state.moveToStorage &&
                        <Redirect to="/storage" />
                    }
                </div>
                <Box sx={{
                    height: 500, width: "100%", minHeight: 500
                }}>
                    <DataGridPremium
                        rows={state.storageBookings}
                        getRowClassName={(params) => {
                            if (params.row.state && params.row.state !== "") {
                                return "errorgrid";
                            }
                        }}
                        columns={this.getColums(state)}
                        checkboxSelection={false}
                        pageSizeOptions={[infiniteListPageSize]}
                        components={{
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    {Translations.NoRows}
                                </Stack>
                            )
                        }}
                        rowSelectionModel={state.storageBookings.filter(i => i.isRowSelected).map((i) => i.id)}
                    />
                </Box>
            </div>
        );
    }
}
export default withRouter(WorkOrderStorageProducts);
