import { Card } from "@mui/material";
import React from "react";

export const TransportSideBarEntitiesContainer = (props: {children: React.ReactNode}) => {
    return (
        <Card variant="outlined" sx={{
            position: "relative",
            backgroundColor: "white.main",
            p: 1,
            minHeight: "40px"
        }}
        >
            {props.children}
        </Card>
    );
};
