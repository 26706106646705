export interface IProductGroupItem {
    id: string;
    code: string;
    name: string;
    mainGroup: number;
}

export class ProductGroupItem implements IProductGroupItem {
    id: string;
    code: string;
    name: string;
    mainGroup: number

    constructor();
    constructor(obj: IProductGroupItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.code = obj && obj.code || "";
        this.name = obj && obj.name || "";
        this.mainGroup = obj && obj.mainGroup || "0"
    }
}
