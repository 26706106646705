export class StorageEditItem {
    id: string = null;
    siteId: string = null;
    name = "";
    street = "";
    postalCode = "";
    city = "";
    countryCode = "";
    hasStorageLocations = false;
    rowId: string = null;
}
