import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { Base, maxBy, minBy } from "../../framework/base";
import { WorkShiftTimeSlotItem } from "../../models/workShitTimeSlot/workShiftTimeSlotItem";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import { toggleSelectedId } from "../../store/workShiftTimeSlotSlice";
import Tooltip from "@mui/material/Tooltip";
import { useDebouncedEventListener } from "../hooks/useDebouncedEventListener";
import { WorkTimeTimelineMenu } from "./workTimeTimelineMenu";
import { getWorkTimeTypeColorClass, getWorkTimeTypeContrastColorClass } from "../../models/workShitTimeSlot/workTimeType";

function getHourLabels(start: Date, end: Date): Date[] {
    const d = new Date(start);
    const labels: Date[] = [];
    while(d < end) {
        labels.push(new Date(d));
        d.setHours(d.getHours()+1);
    }
    labels.push(new Date(d));
    return labels;
}

interface WorkTimeTimelineProps {
    workShiftTimeSlotItems: WorkShiftTimeSlotItem[];
    reloadList: () => void;
}

const ROW_HEIGHT = "20px";
const ROW_GAP = "8px";

export const WorkTimeTimeline = memo(({ workShiftTimeSlotItems, reloadList }: WorkTimeTimelineProps) => {
    const workTimeTypes = useAppSelector(state => state.workShiftTimeSlot.workTimeTypes);
    const timelineRef = useRef<HTMLDivElement>();

    const timeSlotItems = useMemo(
        () =>
            workShiftTimeSlotItems
                .filter((t) => !t.isCustomType)
                .map(
                    (t) =>
                        new WorkShiftTimeSlotItem({
                            ...t,
                            endDate:
                                t.endDate ||
                                Base.dayjsToJsonDateTimeOffset(
                                    Base.dateAtTz(t.timeZoneName)
                                ),
                        } as WorkShiftTimeSlotItem)
                ),
        [workShiftTimeSlotItems]
    );

    const rangeStart = minBy(timeSlotItems, t => new Date(t.startDate).valueOf())?.startDate;
    const rangeEnd = maxBy(timeSlotItems, t => new Date(t.endDate).valueOf())?.endDate;
    
    // Get present timezone and sort by offset string.
    const timeZones = useMemo(
        () =>
            Base.getUniqueStringItems(timeSlotItems.map((i) => i.timeZoneName))
                .map((tz) => ({
                    tz,
                    offset: Base.dateTzOffset(rangeStart, tz),
                }))
                .sort((a, b) => a.offset.localeCompare(b.offset)),
        [timeSlotItems, rangeStart]
    );

    if (!rangeStart || !rangeEnd || !workTimeTypes) {
        return null;
    }

    const offsets = Base.groupArray(timeZones, "offset");

    // Round to nearest hour
    const min = new Date(rangeStart);
    const max = new Date(rangeEnd);
    min.setMinutes(0, 0, 0);
    if (max.getMinutes() > 0) {
        max.setHours(max.getHours() + 1, 0, 0, 0);
    }

    const timelineStart = min.getTime();
    const timelineEnd = max.getTime();
    const timelineDuration = timelineEnd - timelineStart;

    const getLeftPos = (time: Date): string => {
        return `${(time.getTime() - timelineStart) / timelineDuration * 100}%`;
    };
    const getRightPos = (time: Date): string => {
        const pos = Math.max((timelineEnd - time.getTime()) / timelineDuration * 100, 0);
        return `${pos}%`;
    };

    const labels = getHourLabels(min, max);
    const shownWorkTimeTypes = workTimeTypes.map((t) => t.name);
    const timeSlotsForType: { [key: string]: WorkShiftTimeSlotItem[] } = {};

    timeSlotItems.forEach((i) => {
        const type = i.getWorkTimeTypeName();
        if (!shownWorkTimeTypes.includes(type)) {
            shownWorkTimeTypes.push(type);
        }

        timeSlotsForType[type] ||= [];
        timeSlotsForType[type].push(i);
    });

    return (
        <div>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "auto 1fr",
                    columnGap: "16px",
                }}
            >
                {/* x labels */}
                {Object.keys(offsets).map((k) => (
                    <React.Fragment key={k}>
                        <div />
                        <WorkTimeLineTimeLabels
                            labels={labels}
                            getLeftPos={getLeftPos}
                            timeZone={offsets[k][0].tz}
                            showOffset={Object.keys(offsets).length > 1}
                        />
                    </React.Fragment>
                ))}

                {/* y labels */}
                <div style={{ marginTop: "8px" }}>
                    {shownWorkTimeTypes.map((t) => (
                        <div
                            key={t}
                            style={{
                                marginBottom: ROW_GAP,
                                marginTop: ROW_GAP,
                                height: ROW_HEIGHT,
                                display: "flex",
                                alignItems: "center",
                            }}
                            className="font-medium"
                        >
                            {t ? `${t}:` : ""}
                        </div>
                    ))}
                </div>

                {/* Data container */}
                <div
                    ref={timelineRef}
                    style={{
                        marginTop: "8px",
                        marginRight: "16px",
                        position: "relative",
                        cursor: "pointer",
                    }}
                >
                    {/* vertical lines */}
                    {labels.map((t) => (
                        <span
                            key={t.getTime()}
                            style={{
                                position: "absolute",
                                left: getLeftPos(t),
                                width: "1px",
                                height: "100%",
                                backgroundColor: "#e3e3e3",
                            }}
                        />
                    ))}

                    {/* WorkShiftTimeSlot data */}
                    {shownWorkTimeTypes.map((type) => (
                        <div
                            key={type}
                            style={{
                                position: "relative",
                                height: ROW_HEIGHT,
                                marginBottom: ROW_GAP,
                                marginTop: ROW_GAP,
                            }}
                        >
                            {timeSlotsForType[type]?.map((t) => (
                                <WorkTimeLineSlot
                                    key={t.id}
                                    slot={t}
                                    leftPos={getLeftPos(new Date(t.startDate))}
                                    rightPos={getRightPos(new Date(t.endDate))}
                                />
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <WorkTimeTimelineMenu
                timelineRef={timelineRef}
                timeZones={timeZones.map((t) => t.tz)}
                timelineStart={timelineStart}
                timelineEnd={timelineEnd}
                timeSlotItems={timeSlotItems}
                reloadList={reloadList}
            />
        </div>
    );
});

const debouncedEventDelay = 300;

interface WorkTimeLineSlotProps {
    slot: WorkShiftTimeSlotItem;
    leftPos: string;
    rightPos: string;
}

const WorkTimeLineSlot = ({
    slot,
    leftPos,
    rightPos,
}: WorkTimeLineSlotProps) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const labelRef = useRef<HTMLSpanElement>();
    const label = slot.getDurationStr();
    const selectedId = useAppSelector(
        (state) => state.workShiftTimeSlot.selectedId
    );
    const dispatch = useAppDispatch();

    const toggleSelectedItem = (id: string, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        dispatch(toggleSelectedId(id));
    };

    const toggleTooltip = () => {
        if (labelRef.current) {
            setShowTooltip(
                labelRef.current.offsetWidth >
                labelRef.current.parentElement.offsetWidth
            );
        }
    };

    useEffect(toggleTooltip);
    useDebouncedEventListener("resize", toggleTooltip, debouncedEventDelay);

    const labelEl = (
        <div className="text-truncate">
            <span ref={labelRef}>{label}</span>
        </div>
    );

    return (
        <div
            style={{
                borderRadius: "4px",
                position: "absolute",
                left: leftPos,
                right: rightPos,
                height: "100%",
                minWidth: "3px",
                textAlign: "center",
            }}
            className={`worktimeline-item font-medium cursor-pointer ${
                selectedId === slot.id ? "selected" : ""
            } bg-${getWorkTimeTypeColorClass(
                slot.workTimeTypeType
            )} text-${getWorkTimeTypeContrastColorClass(
                slot.workTimeTypeType
            )}`}
            onClick={(e) => toggleSelectedItem(slot.id, e)}
        >
            {showTooltip ? <Tooltip title={label}>{labelEl}</Tooltip> : labelEl}
        </div>
    );
};

interface WorkTimeLineTimeLabelsProps {
    labels: Date[];
    getLeftPos: (time: Date) => string;
    timeZone: string;
    showOffset: boolean;
}

const WorkTimeLineTimeLabels = ({
    labels,
    getLeftPos,
    timeZone,
    showOffset
}: WorkTimeLineTimeLabelsProps) => {
    const rootRef = useRef<HTMLDivElement>();
    const [labelDensity, setLabelDensity] = useState<number>(1);

    const toggleLabelDensity = () => {
        if (rootRef.current) {
            setLabelDensity(
                Base.findNonOverlappingDensity(rootRef.current.children, 2)
            );
        }
    };

    useEffect(toggleLabelDensity);
    useDebouncedEventListener(
        "resize",
        toggleLabelDensity,
        debouncedEventDelay
    );

    return (
        <div
            ref={rootRef}
            style={{
                position: "relative",
                height: "20px",
                marginRight: "16px",
            }}
        >
            {labels.map((t, i) => (
                <span
                    key={t.getTime()}
                    style={{
                        position: "absolute",
                        left: getLeftPos(t),
                        transform: "translate(-50%, 0)",
                        visibility:
                            i % labelDensity === 0 ? "visible" : "hidden",
                    }}
                    className="font-medium no-wrap"
                >
                    {`${Base.dayjsToTimeStr(Base.dateAtTz(timeZone, t))}${
                        showOffset && i == 0
                            ? ` ${Base.dateTzOffsetShort(t, timeZone)}`
                            : ""
                    }`}
                </span>
            ))}
        </div>
    );
};
