import { Base } from "../../framework/base";
import { ISalaryEditItemSalaryRow, SalaryEditItemSalaryRow, ISalaryEditItemSalaryRowSave, SalaryEditItemSalaryRowSave } from "./salaryEditItemSalaryRow";
import { ISalaryRowTypeItem } from "./salaryRowTypeItem";
import { IEmployeeItem } from "../employee/employeeItem";

export interface ISalaryEditItem {
    id: string;
    date: number;
    comment: string;
    employeeId: string;
    transferDate: number;
    rowId: string;
    rows: ISalaryEditItemSalaryRow[];

    employeeName: string;
    isNew(): boolean;
    getHash(): string;
    addMissingRows(salaryRowTypes: ISalaryRowTypeItem[]);
    removeDuplicateSalaryRowTypeRows();
}

export class SalaryEditItem implements ISalaryEditItem {
    id: string;
    date: number;
    comment: string;
    employeeId: string;
    transferDate: number;
    rowId: string;
    rows: SalaryEditItemSalaryRow[];

    employeeName: string;

    constructor();
    constructor(obj: ISalaryEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.employeeId = obj && obj.employeeId || "";
        this.date = obj && obj.date || 0;
        this.comment = obj && obj.comment || "";
        this.transferDate = obj && obj.transferDate || null;
        this.rowId = obj && obj.rowId || "";
        this.rows = Base.getTypedArray<SalaryEditItemSalaryRow>(obj ? obj.rows : null, SalaryEditItemSalaryRow);
        this.employeeName = obj && obj.employeeName || "";
    }

    isNew(): boolean {
        return !this.rowId;
    }

    addMissingRows(salaryRowTypes: ISalaryRowTypeItem[]) {
        for (const salaryRowType of salaryRowTypes) {
            const row = this.rows.find(j => j.salaryRowTypeId === salaryRowType.id) ?? SalaryEditItemSalaryRow.createSalaryEditItemSalaryRow(salaryRowType.id, 0, "", null, null, null);
            row.rowType = salaryRowType;
            if (!row.id) {
                this.rows.push(row);
            }
        }
        SalaryEditItemSalaryRow.sortSalaryEditItemSalaryRows(this.rows);
    }

    removeDuplicateSalaryRowTypeRows() {
        const handledSalaryRowTypeIds: string[] = [];
        for (let i = this.rows.length - 1; i >= 0; i --) {
            const salaryRowTypeId = this.rows[i].salaryRowTypeId;
            if (handledSalaryRowTypeIds.indexOf(salaryRowTypeId) > -1) {
                this.rows.splice(i, 1);
            } else {
                handledSalaryRowTypeIds.push(salaryRowTypeId);
            }
        }
    }

    getHash(): string {
        let result = Base.getStringWithSeparators([this.id, this.employeeId, this.comment, this.date.toString(10), this.transferDate ? this.transferDate.toString(10) : "", this.rowId], "#");
        for (const row of this.rows) {
            result = result + row.getHash();
        }
        return result;
    }

    static addMissingSalaries(salaryRowTypes: ISalaryRowTypeItem[], employees: IEmployeeItem[], salaries: ISalaryEditItem[]): ISalaryEditItem[] {
        const result = salaries.slice(0);
        for (const employee of employees) {
            let salary = result.find(i => i.employeeId === employee.id);
            if (!salary) {
                salary = new SalaryEditItem();
                salary.employeeId = employee.id;
                result.push(salary);
            }
            salary.employeeName = employee.name;
            salary.addMissingRows(salaryRowTypes);
        }
        SalaryEditItem.sortSalaryEditItem(result);
        return result.filter(r => !!r.employeeName);
    }

    static sortSalaryEditItem(items: ISalaryEditItem[]) {
        if (!items || items.length < 2) return;
        items.sort((a: ISalaryEditItem, b: ISalaryEditItem) => {
            if (!a || !b) return 0;
            return Base.stringCompare(a.employeeName, b.employeeName);
        });
    }
}

//SalaryEditItemSave
export interface ISalaryEditItemSave {
    id: string;
    employeeId: string;
    date: number;
    comment: string;
    rowId: string;
    rows: ISalaryEditItemSalaryRowSave[];
}

export class SalaryEditItemSave implements ISalaryEditItemSave {
    id: string;
    employeeId: string;
    date: number;
    comment: string;
    rowId: string;
    rows: SalaryEditItemSalaryRowSave[];

    constructor();
    constructor(obj: ISalaryEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.employeeId = obj && obj.employeeId || "";
        this.date = obj && obj.date || 0;
        this.comment = obj && obj.comment || "";
        this.rowId = obj && obj.rowId || "";
        const rows = Base.getTypedArray<SalaryEditItemSalaryRow>(obj ? obj.rows : null, SalaryEditItemSalaryRow);
        this.rows = [];
        for (const row of rows) {
            if (!row.rowType.readOnly) {
                this.rows.push(new SalaryEditItemSalaryRowSave(row));
                continue;
            }
            for (const groupedRow of row.groupedRows) {
                this.rows.push(new SalaryEditItemSalaryRowSave(groupedRow));
            }
        }
    }
}
