import { getApiCall } from "./tokenService";
import { WorkOrderAcknowledgementTypeItems } from "../models/workOrderAcknowledgementType/workOrderAcknowledgementTypeItems";

export const getWorkOrderAcknowledgementTypeItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean): Promise<WorkOrderAcknowledgementTypeItems> => {
    return getApiCall<WorkOrderAcknowledgementTypeItems>("api/workorderacknowledgementtype/?page=" + (page || "") + "&pageSize=" + (pageSize || "") +
        "&sortColumn=" + (sortColumn ? encodeURIComponent(sortColumn) : "") + "&sortOrder=" + (sortOrderIsAsc ? "asc" : "desc") +
        "&filter=" + (filter ? encodeURIComponent(filter) : ""), WorkOrderAcknowledgementTypeItems);
};

//export const getToolEdit = (id: string): Promise<ToolEdit> => {
//    return getApiCall<ToolEdit>("api/workorderacknowledgementtype/edit/?id=" + (id || ""), ToolEdit);
//};

//export const saveToolEdit = (data: FormData): Promise<ApiSuccess> => {
//    return postFormApiCall<ApiSuccess>("api/workorderacknowledgementtype/save", data, ApiSuccess);
//};

//export const removeTool = (id: string): Promise<ApiSuccess> => {
//    return postApiCall<ApiSuccess>("api/workorderacknowledgementtype/remove/?id=" + (id || ""), ApiSuccess);
//};