import React, { useMemo, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid2 from "@mui/material/Unstable_Grid2";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import Tooltip from "@mui/material/Tooltip";

import { MuiSwitch } from "../../framework/muiSwitch";
import { InvoiceStateIndicator } from "./InvoiceStateIndicator";
import { TableDef, TableWrapper } from "../../framework/tableWrapper";
import { LoadingIndicator } from "../../framework/loadingIndicator";
import { InvoiceDetailsModal } from "./InvoiceDetailsModal";

import { Translations } from "../../../models/translations";
import { RootState, useAppDispatch, useAppSelector } from "../../../framework/customStore";
import { InvoiceListItemDto } from "../../../models/transportOrderInvoice/invoiceListItemDto";
import { TransportOrderInvoiceState, transportOrderInvoiceStates } from "../../../models/transportOrderInvoice/transportOrderInvoice";
import { Base } from "../../../framework/base";
import { customHistory } from "../../../framework/customHistory";
import { showErrorMessage, showSuccessMessage } from "../../../models/store/storeActions";
import { showApiError, showConfirm } from "../../framework/formUtils";

import { approveInvoice, removeInvoice, sendInvoiceToIntegration } from "../../../services/newInvoiceService";
import { formInvoiceQueryParams } from "./InvoiceListFilters";
import {
    fetchTransportOrderInvoice,
    setSelectedInvoice,
    setFilterDisplaySent,
    fetchTransportOrderInvoices,
} from "../../../store/transportOrderInvoiceSlice";

export const TransportOrderInvoiceList = () => {
    const dispatch = useAppDispatch();
    const formatDate = (time: string) => Base.timeToDateTimeStr(new Date(time).getTime());
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const {
        selectedInvoice: invoiceDetails,
        filters
    } = useAppSelector((state: RootState) => state.transportOrderInvoice);

    const stateSentToIntegration = transportOrderInvoiceStates[TransportOrderInvoiceState.SentToIntegration];
    const allInvoices = useAppSelector((state: RootState) => state.transportOrderInvoice.transportOrderInvoices);
    const unsentInvoices = allInvoices.filter(invoice => invoice.state !== stateSentToIntegration?.value);

    const openModal = async(invoiceId: string) => {
        try {
            const resultAction = await (dispatch(fetchTransportOrderInvoice(invoiceId) as any));
            if (fetchTransportOrderInvoice.fulfilled.match(resultAction)) {
                if (invoiceDetails) setSelectedInvoice(invoiceDetails);
                setIsOpen(true);
            }
        } catch (error) {
            dispatch(showErrorMessage(Translations.FetchFailed));
        }
    };

    const closeModal = () => {
        setSelectedInvoice(null);
        setIsOpen(false);
    };

    const handleToggleState = () => {
        dispatch(setFilterDisplaySent(!filters?.displaySent));
    };

    const handleApproveInvoice = (id: string, invoiceState: number) => {
        approveInvoice(id, invoiceState)
            .then(() => {
                dispatch(fetchTransportOrderInvoices(
                    formInvoiceQueryParams(filters)
                ) as any);
                dispatch(fetchTransportOrderInvoice(id) as any);
            })
            .catch((error) => {
                showApiError(error.message);
            });
    };

    const handleRemoveInvoice = (id: string) => {
        const submitRemove = () => {
            removeInvoice(id)
                .then(() => {
                    dispatch(showSuccessMessage(Translations.DeleteWasSuccess));
                    dispatch(fetchTransportOrderInvoices(
                        formInvoiceQueryParams(filters)
                    ) as any);
                    isOpen && closeModal();
                })
                .catch(showApiError);
        };
        showConfirm(Translations.AreYouSureWantDelete, submitRemove);
    };

    const handleRevokeApproval = (id: string) => {
        const submitRevokeApproval = () => {
            approveInvoice(id, TransportOrderInvoiceState.NotApproved)
                .then(() => {
                    dispatch(fetchTransportOrderInvoices(
                        formInvoiceQueryParams(filters)
                    ) as any);
                })
                .catch((error) => {
                    showApiError(error.message);
                });
        };
        showConfirm(Translations.AreYouSureWantRevokeApproval, submitRevokeApproval);
    };

    const sendToIntegration = (id: string) => {
        sendInvoiceToIntegration(id)
            .then((res) => {
                if (res) dispatch(showSuccessMessage(Translations.InvoiceSent));
            })
            .catch(() => {
                dispatch(showErrorMessage(Translations.InvoiceSendFailed));
            });
    };

    const invoiceTable = useMemo<TableDef<InvoiceListItemDto>>(() => ({
        columns: [
            {
                label: Translations.State,
                accessor: "state",
                renderCell: (val: TransportOrderInvoiceState) => {
                    return (
                        <InvoiceStateIndicator state={val} />
                    );
                }
            },
            {
                label: Translations.InvoiceNumber,
                accessor: "invoiceNumber",
            },
            {
                label: Translations.PayersName,
                accessor: "customerName"
            },
            {
                label: Translations.CreationDate,
                disabledSort: true,
                accessor: row => formatDate(row.createdDateTime) || "",
            },
            {
                label: Translations.SentDate,
                disabledSort: true,
                renderCell: (_, row) => (
                    row.sentDateTime
                        ? formatDate(row.sentDateTime)
                        : (
                            <Typography style={{ fontStyle: "italic", color: "gray" }}>
                                {Translations.NotSent}
                            </Typography>
                        )
                )
            },
            {
                label: `${Translations.TaxExclusiveTotal} (EUR)`,
                disabledSort: true,
                accessor: row => row?.totalVatExclusiveSum && row.totalVatExclusiveSum.toFixed(2),
            },
            {
                label: `${Translations.TotalTax} (EUR)`,
                disabledSort: true,
                accessor: row => row?.totalVatSum && row.totalVatSum.toFixed(2),
            },
            {
                label: `${Translations.TotalInvoiceAmount} (EUR)`,
                disabledSort: true,
                accessor: row => row?.totalVatInclusiveSum && row.totalVatInclusiveSum.toFixed(2),
            },
            {
                label: "",
                renderCell: (_, row) => (
                    row.state !== TransportOrderInvoiceState.NotApproved &&
                    <Button
                        color="success"
                        variant="contained"
                        onClick={() => sendToIntegration(row.id)}
                        fullWidth
                        disabled={row.state === TransportOrderInvoiceState.SentToIntegration}
                    >
                        {row.state === TransportOrderInvoiceState.Approved ? Translations.Send : Translations.Sent}
                    </Button>
                )
            },
            {
                label: "",
                renderCell: (_, row) => (
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        {row.state === TransportOrderInvoiceState.NotApproved &&
                            <>
                                <IconButton color="primary" onClick={() => customHistory.push(
                                    `/invoicingbeta/transportorderinvoice/${row.id}`,
                                    { from: "/invoicingbeta/transportorderinvoices" }
                                )}
                                >
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    color="error"
                                    onClick={() => handleRemoveInvoice(row.id)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </>
                        }
                        {row.state === TransportOrderInvoiceState.Approved &&
                            <Tooltip title={Translations.RevokeApproval} disableInteractive>
                                <IconButton color="primary" onClick={() => { handleRevokeApproval(row.id); }}>
                                    <LockIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        <IconButton color="primary" onClick={() => void openModal(row.id)}>
                            <VisibilityIcon />
                        </IconButton>
                    </div>
                )
            },
        ],
    }), []);

    if (!allInvoices && !unsentInvoices) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
            >
                <LoadingIndicator />
            </Box>
        );
    }

    return (
        <>
            <Grid2>
                <div style={{ padding: "1rem" }}>
                    <Grid2 container justifyContent="space-between">
                        <Grid2 xs={2}>
                            <Typography variant="h3">{Translations.TransportOrderInvoices}</Typography>
                        </Grid2>
                        <Grid2>
                            <MuiSwitch
                                key={stateSentToIntegration.value}
                                checked={filters?.displaySent}
                                onChange={() => handleToggleState()}
                                color={stateSentToIntegration.colorName}
                                label={Translations.DisplaySentInvoices}
                            />
                        </Grid2>
                    </Grid2>
                    <Grid2>
                        <TableWrapper tableDef={invoiceTable} data={filters?.displaySent ? allInvoices : unsentInvoices} />
                    </Grid2>
                </div>
            </Grid2>
            {isOpen && invoiceDetails &&
                <InvoiceDetailsModal
                    id={invoiceDetails.id}
                    invoiceDetails={invoiceDetails}
                    open={isOpen}
                    handleClose={closeModal}
                    handleRemoveInvoice={handleRemoveInvoice}
                    handleApproveInvoice={handleApproveInvoice}
                />
            }
        </>
    );
};
