import Grid2 from "@mui/material/Unstable_Grid2";
import { Alert, Button, useTheme } from "@mui/material";
import { Translations } from "../../models/translations";
import * as React from "react";
import { useEffect } from "react";
import { OwnerParameters } from "../../models/owner/ownerParameters";
import { useTranslation } from "react-i18next";

interface IWorkTimeBottomBar {
    acceptAllButtonPress: () => void;
    acceptDisabled: boolean;
    roundAllButtonPress: () => void;
    costCenterFilterActive: boolean;
}

export function WorkTimeBottomBar(props: IWorkTimeBottomBar) {
    const { t } = useTranslation();
    const theme = useTheme();
    const [ownerHasWorkShiftRoundingEnabled, setOwnerHasWorkShiftRoundingEnabled] = React.useState(false);

    useEffect(() => {
        const ownerParameters = new OwnerParameters();
        const ownerHasWorkShiftRoundingEnabled = ownerParameters.getOwnerHasWorkShiftRoundingEnabled();
        setOwnerHasWorkShiftRoundingEnabled(ownerHasWorkShiftRoundingEnabled);
    }, []);

    return (
        <>
            <Grid2 xs={12}
                container
                spacing={2}
                alignItems="center"
                justifyContent="flex-end"
            >
                {props?.costCenterFilterActive &&
                    <Grid2>
                        <Alert variant="filled" severity="info">
                            {t("workTime.costCenterAcceptDisabled")}
                        </Alert>
                    </Grid2>
                }
                {ownerHasWorkShiftRoundingEnabled &&
                    <Grid2>

                        <Button
                            color="primary"
                            sx={{ color: theme.palette.common.white }}
                            variant={"contained"}
                            onClick={props.roundAllButtonPress}
                            disabled={props.acceptDisabled}
                        >
                            {Translations.RoundAllWorkShiftStartAndEnds}
                        </Button>
                    </Grid2>
                }
                <Grid2>

                    <Button
                        color="secondary"
                        sx={{ color: theme.palette.common.white }}
                        variant={"contained"}
                        onClick={props.acceptAllButtonPress}
                        disabled={props.acceptDisabled}
                    >
                        {t("workTime.saveSalaries")}
                    </Button>
                </Grid2>
            </Grid2>
        </>
    );
}
