import moment from "moment";
import { Moment } from "moment";

export const getDateStringFromTimeInterval = (startTime: Moment | null, endTime: Moment | null) => {
    if (startTime === null && endTime === null) {
        return "<— —>";
    }
    if (startTime !== null && endTime !== null) {
        return `${startTime.format("hh:mm")} - ${endTime.format("hh:mm")}`;
    }
    if (startTime !== null) {
        return `${startTime.format("hh:mm")} —>`;
    }
    return `<— ${endTime?.format("hh:mm")}`;
};

export const getTimeIntervalForDay = (date: Date, startTime: Date, endTime: Date) => {
    const d = moment(date);
    const st = moment(startTime);
    const et = moment(endTime);

    let start = "<<";
    let end = ">>";
    if (d.isSame(st, "day")) {
        start = st.format("hh:mm");
    }
    if (d.isSame(et, "day")) {
        end = et.format("hh:mm");
    }
    return `${start} — ${end}`;
};

export function getDatesForRange(start: Date | undefined, end: Date | undefined): Date[] {
    if (typeof start === "undefined" || typeof end === undefined) {
        return [];
    }
    const dates: Date[] = [];
    const endDate = end;
    const currentDate = new Date(start);
    while (currentDate <= endDate) {
        currentDate.setHours(0, 0, 0, 0);
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
}
