import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik, Form, FieldArray, FormikProps } from "formik";
import { useAppDispatch } from "../../../../framework/customStore";

import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Grid2 from "@mui/material/Unstable_Grid2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";

import { ParcelRows } from "./parcelRows";
import { ParcelErrors } from "./parcelErrors";
import {
    parcelTableSx,
    emptyRow,
    validationSchema,
    getChangedParcelData,
    handleParcelReqs
} from "./parcelUtils";
import { selectParcels, getParcels } from "../../../../store/parcelSlice";
import { ParcelDetailsDto as ParcelDetails } from "../../../../models/transport/parcel";

export interface ParcelForm {
    rows: ParcelDetails[];
}

export interface ParcelTableProps {
    orderId?: string;
    changesDisabled?: boolean;
}

export const ParcelTable = ({ orderId, changesDisabled } : ParcelTableProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const parcels = useSelector(selectParcels);
    const initialValues: ParcelForm = {
        rows: parcels.map((p) => ({ ...p, key: crypto.randomUUID() }))
    };

    useEffect(() => {
        if(orderId) {
            dispatch(getParcels(orderId) as any);
        }
    }, [orderId]);

    const handleSubmit = (formData: ParcelForm) => {
        if(!changesDisabled) {
            const changes = getChangedParcelData(formData, initialValues.rows);
            handleParcelReqs(orderId, changes, dispatch, t);
        }
    };

    return (
        <Box>
            <Typography variant="h3">{ t("transport.order.parcels") }</Typography>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                enableReinitialize={true}
            >
                { (formikProps: FormikProps<ParcelForm>) => ParcelForm(formikProps, changesDisabled) }
            </Formik>
        </Box>
    );
};

const ParcelForm = (formikProps: FormikProps<ParcelForm>, changesDisabled: boolean) => {
    const { t } = useTranslation();
    const { values, errors, isValid } = formikProps;
    return (
        <Form>
            <FieldArray
                name="rows"
                render={(arrayHelpers) => {
                    return (
                        <>
                            <TableContainer>
                                <Table {...parcelTableSx}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell />
                                            <TableCell>{ t("transport.parcel.type") }</TableCell>
                                            <TableCell>{ t("transport.parcel.amount") }</TableCell>
                                            <TableCell>{ t("transport.parcel.weight") }</TableCell>
                                            <TableCell>{ t("transport.parcel.height") }</TableCell>
                                            <TableCell>{ t("transport.parcel.width") }</TableCell>
                                            <TableCell>{ t("transport.parcel.length") }</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <ParcelRows
                                            formikProps={formikProps}
                                            arrayHelpers={arrayHelpers}
                                            changesDisabled={changesDisabled}
                                        />
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <>
                                { values?.rows.length > 0 && !isValid && <ParcelErrors errors={errors} /> }
                                { !changesDisabled &&
                                    <Grid2 container>
                                        <Grid2>
                                            <Fab
                                                data-testid={"parcelTable-add"}
                                                color="primary"
                                                size="small" sx={{ p: 1, m: 1 }}
                                                onClick={() => arrayHelpers.push(emptyRow)}
                                            >
                                                <AddIcon />
                                            </Fab>
                                        </Grid2>
                                    </Grid2>
                                }
                            </>
                        </>
                    );
                }}
            />
        </Form>
    );
};
