// ListSearchFilter
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";

export interface IListSearchFilterProp {
    classes?: string;
    autoFilter?: boolean; //!!! NOTE DO NOT SET THIS TRUE FOR DATABASE SEARCH OPERATIONS !!!
    searchFilter: string;
    onSearchClick: (searchFilter: string) => void;
}

export interface IListSearchFilterState {
    searchFilter: string;
}

export class ListSearchFilter extends React.Component<IListSearchFilterProp, IListSearchFilterState> {
    constructor(props) {
        super(props);
        this.state = { searchFilter: props.searchFilter };
    }

    handleChange = (event) => {
        this.setState({ searchFilter: event.target.value });
        if (this.props.autoFilter) {
            this.props.onSearchClick(event.target.value);
        }
    };

    handleClick = () => {
        this.props.onSearchClick(this.state.searchFilter);
    };

    handleKeyPress = (e) => {
        if (e.key === "Enter") {
            this.props.onSearchClick(this.state.searchFilter);
        }
    };

    render() {
        const props = this.props;
        return (
            <div className={"row listSearchFilter" + (props.classes ? " " + props.classes : "")}>
                <div className="col-auto p-0 inputContainer">
                    <input type="text" className="form-control searchCondition" placeholder={Translations.SearchCondition} value={this.state.searchFilter} onChange={this.handleChange} onKeyPress={this.handleKeyPress} autoFocus={true} />
                </div>
                {!props.autoFilter &&
                    <div className="col-auto pl-0 buttonContainer">
                        <button className="btn blue upper searchFilter" type="button" title={Translations.Search} onClick={this.handleClick}>{Translations.Search}</button>
                    </div>
                }
            </div>
        );
    }
}