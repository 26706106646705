import { Base } from "../../framework/base";
import { IDocument, Document } from "../document/document";
import { EmployeeEditCompetencyItem, IEmployeeEditCompetencyItem } from "./employeeEditCompetencyItem";

export interface IEmployeeEditItemEmployeeCompetencyItem {
    id: string;
    competencyId: string;
    date: number;
    expireDate: number;
    comment: string;
    rowId: string;
    documents: IDocument[];

    competencyName: string;
    competencyTypeName: string;

    isNew():boolean;
}

export class EmployeeEditItemEmployeeCompetencyItem implements IEmployeeEditItemEmployeeCompetencyItem {
    id: string;
    competencyId: string;
    date: number;
    expireDate: number;
    comment: string;
    rowId: string;
    documents: Document[];

    competencyName: string;
    competencyTypeName: string;

    constructor();
    constructor(obj: IEmployeeEditItemEmployeeCompetencyItem);
    constructor(obj?: any) {
        this.competencyName = "";
        this.competencyTypeName = "";
        this.id = obj && obj.id || "";
        this.competencyId = obj && obj.competencyId || "";
        this.date = obj && obj.date || null;
        this.expireDate = obj && obj.expireDate || null;
        this.comment = obj && obj.comment || "";
        this.rowId = obj && obj.rowId || "";
        this.documents = Base.getTypedArray<Document>(obj ? obj.documents : null, Document);
        Document.sortDocuments(this.documents, "title", true);
    }

    isNew():boolean {
        return !this.rowId;
    }

    static setCompetencyName(item: IEmployeeEditItemEmployeeCompetencyItem, competencies: IEmployeeEditCompetencyItem[]): IEmployeeEditItemEmployeeCompetencyItem {
        const value = EmployeeEditCompetencyItem.getNameAndCompetencyNameById(competencies, item.competencyId);
        item.competencyName = value.name;
        item.competencyTypeName = value.competencyTypeName;
        return item;
    }

    static setCompetencyNames(items: IEmployeeEditItemEmployeeCompetencyItem[], competencies: IEmployeeEditCompetencyItem[]): IEmployeeEditItemEmployeeCompetencyItem[] {
        for (let i = 0; i < items.length; i++) {
            items[i] = this.setCompetencyName(items[i], competencies);
        }
        return items;
    }

    static sortEmployeeEditItemEmployeeCompetencyItems(items: IEmployeeEditItemEmployeeCompetencyItem[], column: string, asc: boolean) {
        if (Base.isNullOrUndefined(items) || items.length < 2) return;
        let sortFunc: (a: IEmployeeEditItemEmployeeCompetencyItem, b: IEmployeeEditItemEmployeeCompetencyItem) => number;
        if (column === "date") sortFunc = (a: IEmployeeEditItemEmployeeCompetencyItem, b: IEmployeeEditItemEmployeeCompetencyItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.date, b.date)); };
        else if (column === "expireDate") sortFunc = (a: IEmployeeEditItemEmployeeCompetencyItem, b: IEmployeeEditItemEmployeeCompetencyItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.expireDate, b.expireDate)); };
        else if (column === "comment") sortFunc = (a: IEmployeeEditItemEmployeeCompetencyItem, b: IEmployeeEditItemEmployeeCompetencyItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.comment, b.comment)); };
        else sortFunc = (a: IEmployeeEditItemEmployeeCompetencyItem, b: IEmployeeEditItemEmployeeCompetencyItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.competencyName, b.competencyName)); };
        items.sort(sortFunc);
    }
}

export class EmployeeEditItemSaveEmployeeCompetencyItem {
    id: string;
    competencyId: string;
    date: number;
    expireDate: number;
    comment: string;
    rowId: string;

    constructor();
    constructor(obj: IEmployeeEditItemEmployeeCompetencyItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.competencyId = obj && obj.competencyId || "";
        this.date = obj && obj.date || null;
        this.expireDate = obj && obj.expireDate || null;
        this.comment = obj && obj.comment || "";
        this.rowId = obj && obj.rowId || "";
    }
}