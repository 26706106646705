export interface IDayBookingTypeItem {
    id: string;
    number: number;
    name: string;
    salaryRowTypeName: string;
}

export class DayBookingTypeItem implements IDayBookingTypeItem {
    id: string;
    number: number;
    name: string;
    salaryRowTypeName: string;

    constructor();
    constructor(obj: IDayBookingTypeItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.name = obj && obj.name || "";
        this.salaryRowTypeName = obj && obj.salaryRowTypeName || "";
    }
}
