import { Stack } from "@mui/system";
import * as React from "react";
import { StorageShoppingCartItem } from "../../../../store/storageSlice";
import ShoppingCartListItem from "./shoppingCartListItem";

type Props = {
    addedStorageProducts: StorageShoppingCartItem[];
    onRemoveFromShoppingCart: (index: number) => void;
    onAmountChange: (index: number, amount: any) => void;
}

const ShoppingCartItemList = (props: Props) => {
    return (
        <Stack spacing={1}>
            {
                props.addedStorageProducts.map((item, i) => (
                    <ShoppingCartListItem
                        key={i}
                        index={i}
                        item={item}
                        onRemoveFromShoppingCart={props.onRemoveFromShoppingCart}
                        onAmountChange={props.onAmountChange}
                    />
                ))
            }
        </Stack>
    );
};

export default ShoppingCartItemList;
