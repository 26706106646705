import * as React from "react";
import { Dispatch } from "react";
import { connect } from "react-redux";
import { Base } from "../../framework/base";
import { IndexedDbManager } from "../../models/indexedDb/indexedDbManager";
import * as storeActions from "../../models/store/storeActions";
import { IAlertState, IApplicationState, IBeforeInstallPromptEvent, IConfirmationState, IInstallState } from "../../models/store/storeTypes";
import { Translations } from "../../models/translations";
import { Button } from "../framework/button";
import { ConfirmationDialog } from "../framework/confirmationDialog";
import "./../../array.js";
import "./../../date.js";
import "./../../number.js";
import "./../../string.js";

export interface StateProps {
    fetchCount: number;
    install: IInstallState;
    alert: IAlertState;
    confirmation: IConfirmationState;
}

export interface DispatchProps {
    setInstallEvent: (event: IBeforeInstallPromptEvent) => void;
    clearInstallEvent: () => void;
    clearAlerts: () => void;
    clearSuccess: () => void;
    clearInfo: () => void;
    clearConfirmation: () => void;
}

type Props = StateProps & DispatchProps;

// This component was separated from App to fix performance issues, but the code was unchanged. It could use further refactoring.
export class AlertsAndPrompts extends React.Component<Props> {
    private successTimeOut: number;
    private infoTimeOut: number;
    private errorTimeOut: number;

    componentDidMount(): void {
        const appInstallThresholdDate = new Date().addDays(-60);
        void IndexedDbManager.getAppInstallPromptDate()
            .then(appInstallPrompt => {
                if (!appInstallPrompt || appInstallPrompt.date < appInstallThresholdDate.getTime()) {
                    window.addEventListener("beforeinstallprompt", this.showInstallPrompt);
                }
            });
    }

    showInstallPrompt = (e: IBeforeInstallPromptEvent) => {
        e.preventDefault();
        this.props.setInstallEvent(e);
        window.removeEventListener("beforeinstallprompt", this.showInstallPrompt);
    };

    handleSuccessClick = () => {
        clearTimeout(this.successTimeOut);
        this.props.clearSuccess();
    };

    handleInfoClick = () => {
        this.props.clearInfo();
    };

    handleErrorClick = () => {
        clearTimeout(this.errorTimeOut);
        this.props.clearAlerts();
    };

    handleCloseAddToHomeScreenClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.clearInstallEvent();
    };

    handleAddToHomeScreenClick = () => {
        const proposalEvent = this.props.install.proposalEvent;
        // Show the prompt
        void proposalEvent.prompt();
        // Wait for the user to respond to the prompt
        void proposalEvent.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === "accepted") {
                console.log("[App] User accepted the A2HS prompt");
            } else {
                console.log("[App] User dismissed the A2HS prompt");
            }
            this.props.clearInstallEvent();
        });
    };

    render() {
        const props = this.props;
        const showSuccessMessage = props.alert.message;
        if (showSuccessMessage) {
            clearTimeout(this.successTimeOut);
            this.successTimeOut = window.setTimeout(() => {
                props.clearSuccess();
            }, 2500);
        }
        const showInfoMessage = props.alert.info;
        if (showInfoMessage) {
            clearTimeout(this.infoTimeOut);
            this.infoTimeOut = window.setTimeout(() => {
                props.clearInfo();
            }, 7500);
        }
        const showErrorMessage = props.alert.error;
        if (showErrorMessage) {
            clearTimeout(this.errorTimeOut);
            this.errorTimeOut = window.setTimeout(() => {
                props.clearAlerts();
            }, 7500);
        }
        return (
            <>
                {showSuccessMessage &&
                    <div className="alertContainer">
                        <div className="globalSuccess alert alert-success" role="alert" onClick={this.handleSuccessClick}>
                            {!Base.isFormattedText(props.alert.message)
                                ? props.alert.message
                                : <div dangerouslySetInnerHTML={{ __html: Base.getFormattedText(props.alert.message) }}/>
                            }
                        </div>
                    </div>
                }

                {showInfoMessage &&
                    <div className="alertContainer info">
                        <div className="globalInfo alert alert-info" role="alert" onClick={this.handleInfoClick}>
                            {!Base.isFormattedText(props.alert.info)
                                ? props.alert.info
                                : <div dangerouslySetInnerHTML={{ __html: Base.getFormattedText(props.alert.info) }}/>
                            }
                        </div>
                    </div>
                }

                {showErrorMessage &&
                    <div className="alertContainer">
                        <div className="globalError alert alert-danger" role="alert" onClick={this.handleErrorClick}>
                            {props.alert.error}
                        </div>
                    </div>
                }

                {/*Loading icon is here to reduce extra re-renders*/}
                {!Base.isNullOrUndefined(props.fetchCount) && props.fetchCount > 0 &&
                    <img className="loadingImage" alt="Loading..." src={appConfig.ownRoot + "spinner-loader.gif"} />
                }

                {props.confirmation.show &&
                    <ConfirmationDialog
                        title={props.confirmation.title}
                        text={props.confirmation.text}
                        content={props.confirmation.content}
                        show={props.confirmation.show}
                        yesTitle={props.confirmation.yesTitle}
                        yesClasses={props.confirmation.yesClasses}
                        noTitle={props.confirmation.noTitle}
                        noClasses={props.confirmation.noClasses}
                        onYes={props.confirmation.onYes}
                        onNo={props.confirmation.onNo}
                        onCancel={props.confirmation.onCancel}
                    />
                }

                {props.install && props.install.proposalEvent &&
                    <div className="installView" onClick={this.handleCloseAddToHomeScreenClick}>
                        <div className="installPrompt">
                            <div className="top">
                                <div className="text">{Translations.DoYouWantToAddThisApplicationToHomeScreen}</div>
                                <div className="close" title={Translations.Close} onClick={this.handleCloseAddToHomeScreenClick}>
                                    <span className="icon close"/>
                                </div>
                            </div>
                            <div className="bottom">
                                <Button
                                    title={Translations.AddToHomeScreen}
                                    classes={"btn-primary"}
                                    enabled={true}
                                    onClick={this.handleAddToHomeScreenClick}
                                />
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}

export function mapStateToProps(state: IApplicationState): StateProps {
    return {
        fetchCount: state.fetchCount,
        install: state.install,
        alert: state.alert,
        confirmation: state.confirmation,
    };
}

export function mapDispatchToProps(dispatch: Dispatch<any>): DispatchProps {
    return {
        setInstallEvent: (event: IBeforeInstallPromptEvent) => dispatch(storeActions.setInstallProposalEvent(event)),
        clearInstallEvent: () => dispatch(storeActions.clearInstallProposalEvent()),
        clearAlerts: () => dispatch(storeActions.clearMessages()),
        clearSuccess: () => dispatch(storeActions.clearSuccessMessage()),
        clearInfo: () => dispatch(storeActions.clearInfoMessage()),
        clearConfirmation: () => dispatch(storeActions.clearConfirmation()),
    };
}

export const AlertsAndPromptsConnected = connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(AlertsAndPrompts);
