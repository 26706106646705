import * as React from "react";
import { Base } from "../../framework/base";
import * as store from "../../framework/customStore";
import * as baseService from "../../services/baseService";
import * as invoiceService from "../../services/invoiceService";
import * as workOrderService from "../../services/workOrderService";
import * as storeActions from "../../models/store/storeActions";
import { Translations } from "../../models/translations";
import { IIdTitle, IdTitle } from "../../models/common/idTitle";
import { WorkOrderState, EnumHelper, WorkOrderAcknowledgementTypeSendingType, TimeFormat } from "../../models/common/enums";
import { IWorkOrderEditItemHourBooking } from "../../models/work/workOrderEditItemHourBooking";
import { IWorkOrderEditItemRideBooking } from "../../models/work/workOrderEditItemRideBooking";
import { SaveData } from "../../framework/saveData";
import { IWorkOrderEditSite, WorkOrderEditSite } from "../../models/work/workOrderEditSite";
import { IWorkOrderEditContact, WorkOrderEditContact } from "../../models/work/workOrderEditContact";
import { IWorkOrderEditItemWorkOrderTask } from "../../models/work/workOrderEditItemWorkOrderTask";
import { IInvoiceEdit } from "../../models/invoice/invoiceEdit";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { WorkOrderEditAcknowledgementType, IWorkOrderEditAcknowledgementType } from "../../models/work/workOrderEditAcknowledgementType";
import { DSetContactSiteAndAcknowledgementType } from "./workOrderDialog";
import { Autocomplete } from "../framework/autocomplete";
import { DatePicker } from "../framework/datePicker";
import { TimeSelector } from "../framework/timeSelector";
import { CheckBox } from "../framework/checkbox";
import { ImageSelector } from "../framework/imageSelector";
import { INumberTitle } from "../../models/common/numberTitle";
import { WorkOrderDialogState } from "./workOrderDialogState";
import { WorkOrderDialogRecurrenceDialog } from "./workOrderDialogRecurrenceDialog";
import { WorkOrderOperations } from "../../models/work/workOrderOperations";
import { CheckBoxDropdown } from "../framework/dropdown";
import { AppUtils } from "../../models/common/appUtils";
import { WorkOrderEditItem } from "../../models/work/workOrderEditItem";
import { IWorkOrderEditWorkOrderTypeItem, WorkOrderEditWorkOrderTypeItem } from "../../models/work/workOrderEditWorkOrderTypeItem";
import { IVehicleItem } from "../../models/vehicle/vehicleItem";
import { WorkOrderEditItemRoutePointBooking } from "../../models/work/workOrderEditItemRoutePointBooking";
import { IEmployeeItem } from "../../models/employee/employeeItem";
import { OwnerParameters } from "../../models/owner/ownerParameters";
import { WorkOrderPlannedTimeEditor } from "./workOrderPlannedTimeEditor";
import { DayBookingEdit } from "../../models/dayBooking/dayBookingEdit";

//WorkOrderDialogGeneralEdit
export interface IWorkOrderDialogGeneralEditProp {
    workTimeFormat: TimeFormat;
    //WorkOrder
    id: string;
    rowId: string;
    contactId: string;
    customerId: string;
    employeeIds: string[];
    vehicleIds: string[];
    siteId: string;
    workOrderAcknowledgementTypeId: string;
    workOrderTypeId: string;
    category: number;
    description: string;
    endTime: number;
    fullDay: boolean;
    name: string;
    startTime: number;
    state: number;
    canceled: boolean;
    comment: string;
    estimatedHours: number;
    sortOrder: number;
    //Parent
    parentId: string;
    parentName: string;
    parentNumber: number;
    parentCustomerName: string;
    parentSite: IWorkOrderEditSite;
    parentContact: IWorkOrderEditContact;
    hasSequentialTasks: boolean;
    //Category
    isProject: boolean;
    isTask: boolean;
    isWork: boolean;
    isRecurringTask: boolean;
    //State
    isGreaterThanPlanned: boolean;
    isGreaterThanDone: boolean;
    isPreliminary: boolean;
    enabledStates: number[];
    //Recurrence
    isRecurring: boolean;
    recurrenceType: number;
    recurrenceInterval: number;
    recurrenceIntervalDetail: number;
    recurrenceOccurrenceAmount: number;
    recurrenceEndDate: number;
    //Invoice
    invoiceId: string;
    invoiceNumber: number;
    //Lists
    contacts: IWorkOrderEditContact[];
    customers: IIdTitle[];
    employees: IEmployeeItem[];
    vehicles: IVehicleItem[];
    projectTasks: IWorkOrderEditItemWorkOrderTask[];
    projectStates: number[];
    sites: IWorkOrderEditSite[];
    workOrderAcknowledgementTypes: IWorkOrderEditAcknowledgementType[];
    workOrderStates: INumberTitle[];
    workOrderTypes: IWorkOrderEditWorkOrderTypeItem[];
    //Logo
    customerLogo: string;
    //Project
    openedFromProject: boolean;
    //Bookings
    hourBookings: IWorkOrderEditItemHourBooking[];
    rideBookings: IWorkOrderEditItemRideBooking[];
    routePointBookings: WorkOrderEditItemRoutePointBooking[];
    //Operations
    removeWorkOrder: (id: string) => void;
    onUpdateCustomerDataByCustomerId: (customerId: string, callback: DSetContactSiteAndAcknowledgementType) => void;
    onAddCustomer: (name: string, callback: (customerId: string) => void) => void;
    onEditCustomer: (customerId: string, callback: (customerId: string) => void) => void;
    onAddSite: (customerId: string, name: string, callback: (siteId: string) => void) => void;
    onEditSite: (customerId: string, siteId: string) => void;
    onAddContact: (customerId: string, name: string, callback: (contactId: string) => void) => void;
    onAttachContact: (customerId: string, name: string, callback: (contactId: string) => void) => void;
    onEditContact: (customerId: string, siteId: string) => void;
    openMapLink: (mapLink: string) => void;
    onSetRecurrenceType: (recurrenceType: number) => void;
    onEditParentProject: () => void;
    onSwitchHasSequentialTasks: (newState: boolean) => void;
    onWorkOrderTypeChange: (workOrderType: WorkOrderEditWorkOrderTypeItem) => void;
    onSubmitEnd: () => void;
}

interface IWorkOrderDialogGeneralEditState {
    customerId: string;
    workOrderTypeId: string;
    workOrderAcknowledgementTypeId: string;
    siteId: string;
    contactId: string;
    employeeIds: string[];
    vehicleIds: string[];
    name: string;
    description: string;
    orgStartDateStr: string;
    orgStartTimeStr: string;
    startDateStr: string;
    startTimeStr: string;
    fullDay: boolean;
    endDateStr: string;
    endTimeStr: string;
    state: number;
    comment: string;
    estimatedHoursStr: string;
    recurrenceType: number;
    recurrenceInterval: number;
    recurrenceIntervalDetail: number;
    recurrenceOccurrenceAmount: number;
    recurrenceEndDate: number;

    showInvoiceDialog: boolean;
    invoiceDialogItem: IInvoiceEdit;
    invoiceId: string;
    invoiceNumber: number;
    showRecurrenceDialog: boolean;
    //Project
    projectTime: { startTime: number; endTime: number; fullDay: boolean };
    hasSequentialTasks: boolean;
    //Task
    workOrderType: IWorkOrderEditWorkOrderTypeItem | null;
    sortOrder: number;
}

export class WorkOrderDialogGeneralEdit extends React.Component<IWorkOrderDialogGeneralEditProp, IWorkOrderDialogGeneralEditState> {
    private orgStateHash: string = "";

    constructor(props: IWorkOrderDialogGeneralEditProp) {
        super(props);
        this.state = {
            customerId: props.customerId,
            workOrderTypeId: props.isProject ? null : WorkOrderEditWorkOrderTypeItem.getWorkOrderEditWorkOrderTypeItemById(props.workOrderTypes, props.workOrderTypeId)?.id,
            workOrderAcknowledgementTypeId: props.workOrderAcknowledgementTypeId,
            siteId: props.siteId,
            contactId: props.contactId,
            employeeIds: props.employeeIds,
            vehicleIds: props.vehicleIds,
            name: props.name,
            description: props.description,
            orgStartDateStr: Base.timeToDateStr(props.startTime),
            orgStartTimeStr: Base.timeToTimeStr(props.startTime),
            startDateStr: Base.timeToDateStr(props.startTime),
            startTimeStr: Base.timeToTimeStr(props.startTime),
            fullDay: props.fullDay,
            endDateStr: Base.timeToDateStr(props.endTime),
            endTimeStr: Base.timeToTimeStr(props.endTime),
            state: props.state,
            comment: props.comment,
            estimatedHoursStr: props.estimatedHours ? AppUtils.getDurationStrByDurationMinShort(60 * props.estimatedHours) : "",
            recurrenceType: props.recurrenceType,
            recurrenceInterval: props.recurrenceInterval,
            recurrenceIntervalDetail: props.recurrenceIntervalDetail,
            recurrenceOccurrenceAmount: props.recurrenceOccurrenceAmount,
            recurrenceEndDate: props.recurrenceEndDate,
            showInvoiceDialog: false,
            invoiceDialogItem: null,
            invoiceId: props.invoiceId,
            invoiceNumber: props.invoiceNumber,
            showRecurrenceDialog: false,
            //Project
            projectTime: WorkOrderEditItem.getProjectTime(props.projectTasks),
            hasSequentialTasks: props.hasSequentialTasks,
            //Task
            workOrderType: props.isProject
                ? null
                : WorkOrderEditWorkOrderTypeItem.getWorkOrderEditWorkOrderTypeItemById(props.workOrderTypes, props.workOrderTypeId),
            sortOrder: props.sortOrder
        };
        const saveData = this.getSaveData();
        this.orgStateHash = saveData.hash;
    }

    componentDidUpdate(prevProps: IWorkOrderDialogGeneralEditProp, prevState: IWorkOrderDialogGeneralEditState): void {
        const props = this.props;
        // Sets new hash after saving the work order
        if (prevProps.rowId !== props.rowId) {
            const saveData = this.getSaveData();
            this.orgStateHash = saveData.hash;
        }
        if (prevProps.projectTasks.map(i => i.rowId).join("#") === props.projectTasks.map(i => i.rowId).join("#") && prevProps.workOrderTypeId === props.workOrderTypeId) return;
        this.setState({
            projectTime: WorkOrderEditItem.getProjectTime(props.projectTasks),
            workOrderType: props.isProject
                ? null
                : WorkOrderEditWorkOrderTypeItem.getWorkOrderEditWorkOrderTypeItemById(props.workOrderTypes, props.workOrderTypeId),
        });
    }

    // #region Calculated properties
    isNew = (): boolean => {
        return !this.props.rowId;
    };
    // #enregion Calculated properties

    // #region General
    handleChange = (event) => {
        const props = this.props;
        const state = this.state;
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "site") {
            this.setState({ siteId: value });
        } else if (name === "contact") {
            this.setState({ contactId: value });
        } else if (name === "workOrderType") {
            let name = state.name;
            const currentWorkOrderType = props.workOrderTypes.find(i => i.title === name);
            const newWorkOrderType = props.workOrderTypes.find(i => i.id === value);
            if (newWorkOrderType && (!name || currentWorkOrderType)) {
                name = newWorkOrderType.title;
            }
            this.setState({ workOrderTypeId: value, workOrderType: newWorkOrderType, name: name });
            props.onWorkOrderTypeChange(newWorkOrderType);
        } else if (name === "workOrderAcknowledgementType") {
            this.setState({ workOrderAcknowledgementTypeId: value });
        } else if (name === "Nm") {
            this.setState({ name: value });
        } else if (name === "description") {
            this.setState({ description: value });
        } else if (name === "comment") {
            this.setState({ comment: value });
        } else if (name === "estimatedHours") {
            this.setState({ estimatedHoursStr: value });
        } else if (name === "hasSequentialTasks") {
            this.setState({ hasSequentialTasks: target.checked }, () => {
                props.onSwitchHasSequentialTasks(target.checked);
            });
        }
    };

    handleStateChange = (newState: number) => {
        this.setState({ state: newState });
    };

    handleEmployeesChange = (employeeIds: string[]) => {
        this.setState({ employeeIds: employeeIds });
    };

    handleVehiclesChange = (vehicleIds: string[]) => {
        this.setState({ vehicleIds: vehicleIds });
    };

    handleEstimatedHoursBlur = (event) => {
        const target = event.target;
        const value: string = target.value;
        const estimatedHours = value ? AppUtils.strDurationToHours(value) : 0;
        this.setState({ estimatedHoursStr: estimatedHours ? AppUtils.getDurationStrByDurationMinShort(60 * estimatedHours) : "" });
    };

    handleStartDateChange = (value: string) => {
        this.setState({ startDateStr: value });
    };

    handleStartDateBlur = (value: string) => {
        const state = this.state;
        this.setState(WorkOrderPlannedTimeEditor.getWorkOrderStartDateTimeStateChange(state.orgStartDateStr, state.orgStartTimeStr, value, state.startTimeStr, state.endDateStr, state.endTimeStr, state.fullDay));
    };

    handleStartTimeChange = (value: string) => {
        const state = this.state;
        this.setState(WorkOrderPlannedTimeEditor.getWorkOrderStartDateTimeStateChange(state.orgStartDateStr, state.orgStartTimeStr, state.startDateStr, value, state.endDateStr, state.endTimeStr, state.fullDay));
    };

    handleEndDateChange = (value: string) => {
        this.setState({ endDateStr: value });
    };

    handleEndTimeChange = (value: string) => {
        this.setState({ endTimeStr: value });
    };

    handleRecurrenceClick = () => {
        const state = this.state;
        if (state.startDateStr && state.startTimeStr && state.endDateStr && state.endTimeStr) {
            this.setState({ showRecurrenceDialog: true });
        } else {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.StartDateAndEndDateMustBeSpecifiedBeforeRecurrenceCanBeDefined));
        }
    };

    handleFullDayChange = (value: boolean) => {
        const state = this.state;
        let startTimeStr = state.startTimeStr;
        let endDateStr = state.endDateStr;
        let endTimeStr = state.endTimeStr;
        if (value) {
            if (state.startDateStr) {
                if (!startTimeStr) {
                    startTimeStr = Base.timeToTimeStr(Base.getNowDate().getTime());
                }
                if (!endDateStr) {
                    endDateStr = state.startDateStr;
                    endTimeStr = startTimeStr;
                } else if (!endTimeStr) {
                    endTimeStr = startTimeStr;
                }
            }
        }
        this.setState({ fullDay: value, startTimeStr: startTimeStr, endDateStr: endDateStr, endTimeStr: endTimeStr });
    };
    // #endregion General

    // #region Customer
    setContactSiteAndAcknowledgementType = (contactIds: string[], siteIds: string[], workOrderAcknowledgementTypeId: string) => {
        const state = this.state;
        const siteId = siteIds.indexOf(state.siteId) >= 0
            ? state.siteId
            : (siteIds.length > 0 ? siteIds[0] : "");
        const contactId = state.contactId
            ? contactIds.indexOf(state.contactId) >= 0 ? state.contactId : ""
            : "";
        this.setState({
            workOrderAcknowledgementTypeId: workOrderAcknowledgementTypeId,
            siteId: siteId,
            contactId: contactId
        });
    };

    handleSetCustomer = (customerId: string) => {
        if (!customerId) return;
        this.setState({
            customerId: customerId,
        });
        this.props.onUpdateCustomerDataByCustomerId(customerId, this.setContactSiteAndAcknowledgementType);
    };

    handleChangeCustomerAutoComplete = (ids: string[]) => {
        if (!ids || ids.length < 1) return;
        const id = ids[0];
        this.handleSetCustomer(id);
    };

    handleAddCustomer = (name: string) => {
        this.props.onAddCustomer(name, this.handleSetCustomer);
    };

    handleEditCustomer = (customerId: string) => {
        this.props.onEditCustomer(customerId, this.handleSetCustomer);
    };
    // #endregion Customer

    // #region Site
    handleChangeSiteAutoComplete = (ids: string[]) => {
        const obj = this;
        if (!ids) return;
        const id = ids.length < 1 ? "" : ids[0];
        obj.setState({
            siteId: id
        });
    };

    handleSetSiteId = (siteId: string) => {
        this.setState({
            siteId: siteId
        });
    };

    handleAddSite = (name: string) => {
        this.props.onAddSite(this.state.customerId, name, this.handleSetSiteId);
    };
    // #endregion Site

    // #region Contact
    handleChangeContactAutoComplete = (ids: string[]) => {
        const id = !ids || ids.length < 1 ? "" : ids[0];
        this.setState({
            contactId: id
        });
    };

    handleSetContactId = (contactId: string) => {
        this.setState({
            contactId: contactId
        });
    };

    handleAddContact = (name: string) => {
        this.props.onAddContact(this.state.customerId, name, this.handleSetContactId);
    };

    handleAttachContact = (name: string) => {
        this.props.onAttachContact(this.state.customerId, name, this.handleSetContactId);
    };
    // #endregion Contact

    handleInvoiceClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!this.state.invoiceId) return;
        const obj = this;
        invoiceService.getInvoiceEdit(this.state.invoiceId).then(editItem => {
            obj.setState({
                showInvoiceDialog: true,
                invoiceDialogItem: editItem
            });
        });
    };

    handleInvoiceDialogCancel = () => {
        this.setState({
            showInvoiceDialog: false
        });
        const obj = this;
        workOrderService.getWorkOrderEdit(this.props.id, null, null, null).then(workOrderEdit => {
            obj.setState({
                invoiceNumber: workOrderEdit.workOrder.invoiceNumber,
                invoiceId: workOrderEdit.workOrder.invoiceId
            });
        });
    };

    handleRecurrenceDialogOk = (employeeIds: string[], vehicleIds: string[], name: string, description: string, fullDay: boolean, startDateStr: string, startTimeStr: string, endDateStr: string, endTimeStr: string,
        recurrenceType: number, recurrenceInterval: number, recurrenceIntervalDetail: number, recurrenceOccurrenceAmount: number, recurrenceEndDate: number) => {
        const props = this.props;
        this.setState({
            startDateStr: startDateStr,
            startTimeStr: startTimeStr,
            endDateStr: endDateStr,
            endTimeStr: endTimeStr,
            recurrenceType: recurrenceType,
            recurrenceInterval: recurrenceInterval,
            recurrenceIntervalDetail: recurrenceIntervalDetail,
            recurrenceOccurrenceAmount: recurrenceOccurrenceAmount,
            recurrenceEndDate: recurrenceEndDate,
            showRecurrenceDialog: false
        });
        props.onSetRecurrenceType(recurrenceType);
    };

    handleRecurrenceDialogCancel = () => {
        this.setState({
            showRecurrenceDialog: false
        });
    };

    private static checkErrors = (props: IWorkOrderDialogGeneralEditProp, state: IWorkOrderDialogGeneralEditState): Promise<boolean> => {
        return new Promise<boolean>((resolve) => {
            if (!state.customerId || state.customerId === Base.emptyGuid) {
                store.customStore.dispatch(storeActions.showErrorMessage(Translations.CustomerMustBeDefined));
                return resolve(false);
            }
            //if (!state.siteId || state.siteId === Base.emptyGuid) {
            //    store.customStore.dispatch(storeActions.showErrorMessage(Translations.SiteMustBeDefined));
            //    return resolve(false);
            //}
            if (state.siteId !== props.siteId && props.routePointBookings.length > 0) {
                store.customStore.dispatch(storeActions.showErrorMessage(Translations.SiteCannotBeChangedWhenRoutePointBookingsAreDefined));
                return resolve(false);
            }
            if (!state.name) {
                store.customStore.dispatch(storeActions.showErrorMessage(Translations.WorkTitleMustBeDefined));
                return resolve(false);
            }
            const contactEmail = WorkOrderEditContact.getEmailById(props.contacts, state.contactId);
            const workOrderAcknowledgementTypeSendingType = WorkOrderEditAcknowledgementType.getSendingTypeById(props.workOrderAcknowledgementTypes, state.workOrderAcknowledgementTypeId);
            if (!props.isProject && !contactEmail && !EnumHelper.isEqual(workOrderAcknowledgementTypeSendingType, WorkOrderAcknowledgementTypeSendingType.DoNotSend)) {
                store.customStore.dispatch(storeActions.showErrorMessage(Translations.ContactWithEmailMustBeDefinedWhenAcknowledementTypeIsNotDoNotSend));
                return resolve(false);
            }
            if (!props.isProject && EnumHelper.isGreaterOrEqual(state.state, WorkOrderState.Planned)) {
                if (state.employeeIds.length < 0.5) {
                    store.customStore.dispatch(storeActions.showErrorMessage(Translations.EmployeesMustBeDefined));
                    return resolve(false);
                }
                // UMP
                //    const startTime = state.startTimeStr.toDate();
                //    const endTime = state.endTimeStr.toDate().addMinute(-0.02);
                //    if (Base.getWeekNumber(startTime) !== Base.getWeekNumber(endTime)) {
                //        store.customStore.dispatch(storeActions.showErrorMessage(Translations.WorkOrderCannotCrossWeekBoundary));
                //        return resolve(false);
                //    }
            }
            if (!props.isProject && EnumHelper.isGreaterOrEqual(state.state, WorkOrderState.Done)) {
                const site = props.sites.find(i => i.id === state.siteId);
                if (site && site.rideBookingRequired && props.rideBookings.length < 1) {
                    store.customStore.dispatch(storeActions.showErrorMessage(Translations.RideBookingsMustBeDefined));
                    return resolve(false);
                }
            }
            const ownerParameters = new OwnerParameters();
            if (!props.isProject && EnumHelper.isGreaterOrEqual(state.state, WorkOrderState.Checked)) {
                if (ownerParameters.getRequireWorkOrderHourBookings() && props.hourBookings.length < 1) {
                    store.customStore.dispatch(storeActions.showErrorMessage(Translations.HourBookingsMustBeDefined));
                    return resolve(false);
                }
            }
            return resolve(true);
        }).then(result => {
            if (result && !props.isProject) {
                return WorkOrderPlannedTimeEditor.checkWorkOrderPlannedTimeErrors(state, state.fullDay, state.state);
            } else {
                return result;
            }
        });
    };

    private static getWarningMessage = (props: IWorkOrderDialogGeneralEditProp, state: IWorkOrderDialogGeneralEditState): Promise<string> => {
        return WorkOrderPlannedTimeEditor.getWorkOrderPlannedTimeWarningMessage(state, state.state, state.employeeIds, props.hourBookings);
    };

    validate = (saveCallback: () => void): Promise<void> => {
        return WorkOrderDialogGeneralEdit.checkErrors(this.props, this.state).then(success => {
            if (success) {
                return WorkOrderDialogGeneralEdit.getWarningMessage(this.props, this.state).then(warnings => {
                    if (!warnings) {
                        saveCallback();
                        return new Promise<void>((resolve) => { resolve(); });
                    }
                    return new Promise<void>((resolve) => {
                        store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, warnings + Base.lf + (EnumHelper.isEqual(this.state.state, WorkOrderState.Planned) ? Translations.DoYouReallyWantToSetWorkOrderPlanned : Translations.DoYouReallyWantToSetWorkOrderDone),
                            () => {
                                store.customStore.dispatch(storeActions.clearConfirmation());
                                saveCallback();
                                resolve();
                            },
                            () => {
                                store.customStore.dispatch(storeActions.clearConfirmation());
                                resolve();
                                this.props.onSubmitEnd();
                            }, null));
                    });
                });
            } else {
                this.props.onSubmitEnd();
                return new Promise<void>((resolve) => { resolve(); });
            }
        });
    };

    getSaveData = (): SaveData => {
        const props = this.props;
        const state = this.state;
        const data = new SaveData();
        // Common
        data.append("id", props.id);
        data.append("rowId", props.rowId);
        data.append("category", props.category.toString(10));
        data.append("parentId", props.parentId ? props.parentId : String(null));
        // General
        data.append("state", state.state.toString(10));
        data.append("workOrderTypeId", state.workOrderTypeId ?? String(null));
        data.append("workOrderAcknowledgementTypeId", state.workOrderAcknowledgementTypeId);
        data.append("siteId", state.siteId ? state.siteId : String(null));
        data.append("customerId", state.customerId);
        data.append("contactId", state.contactId !== Base.emptyGuid ? state.contactId : String(null));
        data.append("employeeIds", JSON.stringify(state.employeeIds));
        data.append("vehicleIds", JSON.stringify(state.vehicleIds));
        data.append("name", state.name);
        data.append("description", state.description);
        data.append("comment", state.comment);
        const estimatedHours = state.estimatedHoursStr ? AppUtils.strDurationToHours(state.estimatedHoursStr) : 0;
        data.append("estimatedHours", estimatedHours ? estimatedHours.toString(10) : "");
        const startDateTime = AppUtils.getDateTime(state.startDateStr, state.startTimeStr, state.fullDay);
        let endDateTime = AppUtils.getDateTime(state.endDateStr, state.endTimeStr, state.fullDay);
        if (state.fullDay) {
            endDateTime = endDateTime.addDays();
        }
        data.append("startTime", startDateTime ? startDateTime.getTime().toString(10) : "");
        data.append("endTime", endDateTime ? endDateTime.getTime().toString(10) : "");
        data.append("fullDay", state.fullDay ? "1" : "0");
        // Recurrence
        data.append("recurrenceType", state.recurrenceType.toString(10));
        data.append("recurrenceInterval", state.recurrenceInterval.toString(10));
        data.append("recurrenceIntervalDetail", state.recurrenceIntervalDetail.toString(10));
        data.append("recurrenceOccurrenceAmount", state.recurrenceOccurrenceAmount.toString(10));
        data.append("recurrenceEndDate", state.recurrenceEndDate ? state.recurrenceEndDate.toString(10) : "");
        // Project
        data.append("hasSequentialTasks", props.hasSequentialTasks.toString());
        return data;
    };

    saveValidatedWorkOrder = (sendWorkOrderAcknowledgement: boolean, makeProductBookingsAfterSave: boolean, afterSaveCallback: (saved: boolean, workOrderId: string, makeProductBookingsAfterSave: boolean) => void) => {
        const obj = this;
        //Set SaveData
        const saveData = obj.getSaveData();
        if (Base.isNullOrUndefined(saveData)) return;
        saveData.append("sendWorkOrderAcknowledgement", sendWorkOrderAcknowledgement ? "1" : "0");
        // Call server
        store.customStore.dispatch(storeActions.fetchStart());
        workOrderService.saveWorkOrderGeneral(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                if (success.errorMessage) {
                    store.customStore.dispatch(storeActions.showErrorMessage(success.errorMessage));
                }
                if (afterSaveCallback) {
                    afterSaveCallback(true, success.id, makeProductBookingsAfterSave);
                }
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                this.props.onSubmitEnd();
                return null;
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    saveWorkOrder = (afterSaveCallback: (saved: boolean, workOrderId: string, makeProductBookingsAfterSave: boolean) => void = null) => {
        const props = this.props;
        const obj = this;
        this.validate(() => {
            // WorkOrder acknowledgement
            const contactEmail = WorkOrderEditContact.getEmailById(obj.props.contacts, obj.state.contactId);
            if (EnumHelper.isLessThan(props.state, WorkOrderState.Done) && EnumHelper.isGreaterThan(obj.state.state, WorkOrderState.InProgress) && EnumHelper.isLessThan(obj.state.state, WorkOrderState.Checked) && !!obj.state.contactId && obj.state.contactId !== Base.emptyGuid && !!contactEmail) {
                const workOrderAcknowledgementTypeSendingType = WorkOrderEditAcknowledgementType.getSendingTypeById(props.workOrderAcknowledgementTypes, obj.state.workOrderAcknowledgementTypeId);
                if (EnumHelper.isEqual(workOrderAcknowledgementTypeSendingType, WorkOrderAcknowledgementTypeSendingType.SendManually)) {
                    store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Information, Translations.Information, String.format(Translations.DoYouWantToSendWorkOrderAcknowledgementToContact, contactEmail),
                        () => {
                            store.customStore.dispatch(storeActions.clearConfirmation());
                            obj.saveValidatedWorkOrder(true, false, afterSaveCallback);
                        },
                        () => {
                            store.customStore.dispatch(storeActions.clearConfirmation());
                            obj.saveValidatedWorkOrder(false, false, afterSaveCallback);
                        },
                        () => {
                            store.customStore.dispatch(storeActions.clearConfirmation());
                            props.onSubmitEnd();
                        }));
                } else {
                    obj.saveValidatedWorkOrder(EnumHelper.isEqual(workOrderAcknowledgementTypeSendingType, WorkOrderAcknowledgementTypeSendingType.SendAlways), false, afterSaveCallback);
                }
            } else {
                obj.saveValidatedWorkOrder(false, false, afterSaveCallback);
            }
        });
    };

    hasChanges = () => {
        return this.getSaveData().hash !== this.orgStateHash;
    };

    // #region Cancel Editing
    private cancelWorkOrderEditSub = (onCancelEditCallback: (saved: boolean, workOrderId: string, makeProductBookingsAfterSave: boolean) => void) => {
        const props = this.props;
        const obj = this;
        const saveData = obj.getSaveData();
        if (!Base.isNullOrUndefined(saveData) && saveData.hash !== this.orgStateHash) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.saveWorkOrder(onCancelEditCallback);
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    onCancelEditCallback(false, props.id, false);
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));
        } else {
            onCancelEditCallback(false, props.id, false);
        }
    };

    cancelWorkOrderEdit = (onCancelEditCallback: (saved: boolean, workOrderId: string, makeProductBookingsAfterSave: boolean) => void) => {
        const obj = this;
        const props = this.props;
        if (!this.isNew() && props.isProject && props.projectTasks.length < 1) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.ProjectDoesNotHaveAnyTasksDoYouWantToRemoveProject,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.props.removeWorkOrder(props.id);
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.cancelWorkOrderEditSub(onCancelEditCallback);
                }));
        } else {
            obj.cancelWorkOrderEditSub(onCancelEditCallback);
        }
    };
    // #endregion Cancel Editing

    render() {
        const props = this.props;
        const state = this.state;
        const site = WorkOrderEditSite.getWorkOrderEditSiteBySiteId(props.sites, state.siteId);
        const contact = Base.getItemById(props.contacts, state.contactId);
        const isNew = this.isNew();
        const recursInfo = AppUtils.getRecurrenceInfo(state.recurrenceType, state.recurrenceInterval, state.recurrenceIntervalDetail);

        return (
            <div className="editView">
                <input type="hidden" name="hasSequentialTasks" value={props.hasSequentialTasks ? 1 : 0} />
                <div className="row">
                    <div className="col-6">
                        <div className={"form-group" + (props.isProject ? "" : " required")}>
                            <label className="control-label smallFont">{Translations.State}</label>
                            <WorkOrderDialogState
                                isReadOnly={false}
                                isProject={props.isProject}
                                state={state.state}
                                projectStates={props.projectStates}
                                workOrderStates={props.workOrderStates}
                                enabledStates={props.enabledStates}
                                onStateChange={this.handleStateChange}
                            />
                        </div>
                    </div>
                    {!props.isProject &&
                        <div className="col-6">
                            <div className="form-group required">
                                <label className="control-label smallFont">{Translations.WorkOrderType}</label>
                                <select
                                    className="custom-select"
                                    name="workOrderType"
                                    title={Translations.WorkOrderType}
                                    value={state.workOrderTypeId}
                                    onChange={this.handleChange}
                                    autoFocus={!props.isPreliminary}
                                    disabled={props.isGreaterThanDone}
                                >
                                    {props.workOrderTypes.map((workOrderType) =>
                                        <option key={workOrderType.id} value={workOrderType.id}>{workOrderType.title}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    }
                </div>
                {!props.isTask &&
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group required">
                                <label className="control-label smallFont">{Translations.Customer}</label>
                                <Autocomplete
                                    autoFocus={isNew}
                                    disabled={!props.isPreliminary}
                                    placeholder={Translations.Customer}
                                    selectedItems={props.customers.filter(i => i.id === state.customerId)}
                                    required={true}
                                    items={props.customers}
                                    onChange={this.handleChangeCustomerAutoComplete}
                                    onEditClick={this.handleEditCustomer}
                                    onAddClick={props.isPreliminary ? this.handleAddCustomer : null}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.Site}</label>
                                <div className="input-group">
                                    <Autocomplete
                                        disabled={props.isGreaterThanPlanned}
                                        placeholder={Translations.Site}
                                        selectedItems={props.sites.filter(i => i.id === state.siteId)}
                                        required={false}
                                        items={props.sites}
                                        onChange={this.handleChangeSiteAutoComplete}
                                        onAddClick={EnumHelper.isLessThan(props.state, WorkOrderState.InProgress) ? this.handleAddSite : null}
                                        onEditClick={() => { props.onEditSite(state.customerId, state.siteId); }}
                                    />
                                </div>
                                {site != null && site.siteHasLocation() &&
                                    <div className="readOnlyText small">
                                        <div className="left">
                                            {Base.getStringWithSeparators([site.street, Base.getStringWithSeparators([site.postalCode, site.city], " ")], ", ")}
                                        </div>
                                        {Base.isValidMapLink(site.mapLink) &&
                                            <div className="left">
                                                <div className="mapMarker green smallerMarker left" title={Translations.LocationSuccess} />
                                                <a className="readOnlyLink" onClick={() => props.openMapLink(site.mapLink)}>
                                                    {Translations.OpenMapLink}
                                                </a>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        {/*    {!!props.customerLogo &&*/}
                        {/*        <div className="col-3">*/}
                        {/*            <ImageSelector*/}
                        {/*                fileInputName="logo"*/}
                        {/*                classes="workOrder right"*/}
                        {/*                disabled={true}*/}
                        {/*                image={props.customerLogo}*/}
                        {/*                aspectRatio={null}*/}
                        {/*                onChange={null}*/}
                        {/*                onError={null}*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    }*/}
                    </div>
                }
                {!props.isTask &&
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group required">
                                <label className="control-label smallFont">{Translations.WorkTitle}</label>
                                <input type="text" className="form-control" name="Nm" title={Translations.WorkTitle} value={state.name} onChange={this.handleChange} maxLength={100} readOnly={props.isGreaterThanDone} disabled={props.isGreaterThanDone} />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.Contact}</label>
                                <Autocomplete
                                    placeholder={!props.isPreliminary ? "" : Translations.Contact}
                                    selectedItems={props.contacts.filter(i => i.id === state.contactId)}
                                    items={props.contacts}
                                    onChange={this.handleChangeContactAutoComplete}
                                    onAddClick={EnumHelper.isLessThan(props.state, WorkOrderState.Checked) ? this.handleAddContact : null}
                                    onAttachClick={EnumHelper.isLessThan(props.state, WorkOrderState.Checked) ? this.handleAttachContact : null}
                                    onEditClick={() => { props.onEditContact(state.customerId, state.contactId); }}
                                />
                                {!!contact &&
                                    <div className="readOnlyText small">
                                        {Base.getStringWithSeparators([contact.phone, contact.email], ", ")}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
                {props.isTask &&
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className="control-label listTitle smallFont">{Translations.AttachedToProject}</label>
                                <div className="row">
                                    <div className={props.customerLogo ? "col-9" : "col-12"}>
                                        {!this.isNew() &&
                                            <a className="readOnlyLink" onClick={props.onEditParentProject}>
                                                <div className="parentInfoLine">{props.parentNumber.toString(10) + " " + props.parentName}</div>
                                            </a>
                                        }
                                        {this.isNew() &&
                                            <div className="readOnlyText small">
                                                {Base.getStringWithSeparators([props.parentNumber.toString(10), props.parentName], " ")}
                                            </div>
                                        }
                                        <div className="readOnlyText small">
                                            {Base.getStringWithSeparators([props.parentCustomerName, props.parentSite ? props.parentSite.name : "", props.parentSite ? props.parentSite.name2 : "",
                                                props.parentSite ? props.parentSite.street : "", props.parentSite ? props.parentSite.postalCode : "", props.parentSite ? props.parentSite.city : ""], ", ")}
                                        </div>
                                        {props.parentContact &&
                                            <div className="readOnlyText small">
                                                {Base.getStringWithSeparators([props.parentContact.name, props.parentContact.phone, props.parentContact.email], ", ")}
                                            </div>
                                        }
                                    </div>
                                    {!!props.customerLogo &&
                                        <div className="col-3">
                                            {props.customerLogo &&
                                                <ImageSelector
                                                    fileInputName="logo"
                                                    classes="workOrder right"
                                                    disabled={true}
                                                    image={props.customerLogo}
                                                    aspectRatio={null}
                                                    onChange={null}
                                                    onError={null}
                                                />}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {props.isTask && !props.isRecurringTask &&
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group required">
                                <label className="control-label smallFont">{Translations.WorkTitle}</label>
                                <input type="text" className="form-control" name="Nm" title={Translations.WorkTitle} value={state.name} onChange={this.handleChange} maxLength={100} readOnly={props.isGreaterThanDone} disabled={props.isGreaterThanDone} />
                            </div>
                        </div>
                    </div>
                }
                {state.workOrderType?.showDescription &&
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.WorkDescription}</label>
                                <textarea className="form-control" name="description" title={Translations.WorkDescription} value={state.description} onChange={this.handleChange} maxLength={1000} readOnly={!props.isProject && props.isGreaterThanDone} disabled={!props.isProject && props.isGreaterThanDone} />
                            </div>
                        </div>
                    </div>
                }
                {state.workOrderType?.showComment &&
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.Comment}</label>
                                <textarea className="form-control" name="comment" title={Translations.Comment} value={state.comment} onChange={this.handleChange} maxLength={1000} />
                            </div>
                        </div>
                    </div>
                }
                {!props.isProject && state.invoiceNumber > 0 &&
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className="control-label smallFont">&nbsp;</label>
                                <div className="linkText" onClick={this.handleInvoiceClick}>{String.format(Translations.IsRelatedToInvoiceParameter, state.invoiceNumber.toString(10))}</div>
                            </div>
                        </div>
                    </div>
                }
                {!props.isProject &&
                    <div className="row">
                        <div className="col-3">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.StartTime}</label>
                                <DatePicker
                                    disabled={props.isGreaterThanPlanned}
                                    titleClasses={"smallFont"}
                                    value={state.startDateStr}
                                    onChange={this.handleStartDateChange}
                                    onBlur={this.handleStartDateBlur}
                                />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <label className="control-label smallFont">{""}</label>
                                <TimeSelector
                                    disabled={props.isGreaterThanPlanned || state.fullDay}
                                    disabledValue={state.fullDay ? "0:00" : state.startTimeStr}
                                    date={state.startDateStr}
                                    value={state.startTimeStr}
                                    onChange={this.handleStartTimeChange}
                                />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.EndDate}</label>
                                <DatePicker
                                    disabled={props.isGreaterThanPlanned}
                                    titleClasses={"smallFont"}
                                    value={state.endDateStr}
                                    onChange={this.handleEndDateChange}
                                    onBlur={this.handleEndDateChange}
                                />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <label className="control-label smallFont">{""}</label>
                                <TimeSelector
                                    date={state.endDateStr}
                                    disabled={props.isGreaterThanPlanned || state.fullDay}
                                    disabledValue={state.fullDay ? "0:00" : state.endTimeStr}
                                    startDate={state.startDateStr}
                                    startTime={state.startTimeStr}
                                    value={state.endTimeStr}
                                    onChange={this.handleEndTimeChange}
                                />
                            </div>
                        </div>
                    </div>
                }
                {!props.isProject &&
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.Employee}</label>
                                <CheckBoxDropdown
                                    disabled={props.isGreaterThanPlanned}
                                    selectedIds={state.employeeIds}
                                    values={props.employees}
                                    dropup={true}
                                    onChange={this.handleEmployeesChange}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.Vehicle}</label>
                                <CheckBoxDropdown
                                    disabled={props.isGreaterThanPlanned}
                                    selectedIds={state.vehicleIds}
                                    values={props.vehicles}
                                    dropup={true}
                                    onChange={this.handleVehiclesChange}
                                />
                            </div>
                        </div>
                    </div>
                }
                {!props.isProject &&
                    <div className="row">
                        {!props.isProject &&
                            <div className="col-3">
                                <div className="form-group">
                                    <CheckBox
                                        title={Translations.Allday}
                                        enabled={true}
                                        checked={state.fullDay}
                                        onCheckboxClickBoolean={this.handleFullDayChange}
                                    />
                                </div>
                            </div>
                        }
                        {isNew && props.isWork &&
                            <div className="col-3">
                                <div className="form-group">
                                    <div className="readOnlyLink" onClick={this.handleRecurrenceClick}>
                                        <span className="icon recurrence" />
                                        {" " + (state.recurrenceType < 0.5 ? Translations.MakeRecurring : Translations.EditRecurrence)}
                                    </div>
                                </div>
                            </div>
                        }
                        {props.isRecurring &&
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Occurs}</label>
                                    <div className="readOnlyText">
                                        {recursInfo}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                {((props.isProject && state.projectTime.startTime > 0) || (state.workOrderType?.showWorkOrderAcknowledgementType && !props.isProject) || (state.workOrderType?.showEstimatedHours && (props.isRecurring || props.isWork || props.isTask))) &&
                    <div className="row">
                        {props.isProject && state.projectTime.startTime > 0 &&
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Time}</label>
                                    <div className="readOnlyText">
                                        {props.isRecurring &&
                                            <span>
                                                {AppUtils.getTimeDurationStrByDurationMin(state.projectTime.startTime, state.projectTime.endTime, state.projectTime.fullDay, 0)}<br />
                                                {Translations.Occurs} {recursInfo}
                                            </span>
                                        }
                                        {!props.isRecurring &&
                                            <span>{AppUtils.getTimeDurationStr(state.projectTime.startTime, state.projectTime.endTime, state.projectTime.fullDay)}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {state.workOrderType?.showWorkOrderAcknowledgementType && props.workOrderAcknowledgementTypes.length && !props.isProject &&
                            <div className="col-6">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.WorkOrderAcknowledgementType}</label>
                                    <select className="custom-select" name="workOrderAcknowledgementType" title={Translations.WorkOrderAcknowledgementType} value={state.workOrderAcknowledgementTypeId} onChange={this.handleChange} disabled={EnumHelper.isGreaterThan(props.state, WorkOrderState.InProgress)}>
                                        {props.workOrderAcknowledgementTypes.map((workOrderAcknowledgementType) =>
                                            <option key={workOrderAcknowledgementType.id} value={workOrderAcknowledgementType.id}>{workOrderAcknowledgementType.name}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        }
                        {state.workOrderType?.showEstimatedHours && (props.isRecurring || props.isWork || props.isTask) &&
                            <div className="col-3">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.EstimatedHours}</label>
                                    <input type="text" className="form-control" name="estimatedHours" title={Translations.EstimatedHours} value={state.estimatedHoursStr} onChange={this.handleChange} onBlur={this.handleEstimatedHoursBlur} maxLength={10}/>
                                </div>
                            </div>
                        }
                    </div>
                }
                {state.showRecurrenceDialog &&
                    <WorkOrderDialogRecurrenceDialog
                        addMode={false}
                        addSourceIsTask={false}
                        name={state.name}
                        description={state.description}
                        employees={props.employees}
                        vehicles={props.vehicles}
                        employeeIds={state.employeeIds}
                        vehicleIds={state.vehicleIds}
                        fullDay={state.fullDay}
                        startDateStr={state.startDateStr}
                        startTimeStr={state.startTimeStr}
                        endDateStr={state.endDateStr}
                        endTimeStr={state.endTimeStr}
                        interval={30}
                        recurrenceType={state.recurrenceType}
                        recurrenceInterval={state.recurrenceInterval}
                        recurrenceIntervalDetail={state.recurrenceIntervalDetail}
                        recurrenceOccurrenceAmount={state.recurrenceOccurrenceAmount}
                        recurrenceEndDate={state.recurrenceEndDate}
                        onOk={this.handleRecurrenceDialogOk}
                        onCancel={this.handleRecurrenceDialogCancel}
                    />
                }
            </div>);
    }
}

//WorkOrderDialogGeneralReadOnly
export interface IWorkOrderDialogGeneralReadOnlyProp {
    workTimeFormat: TimeFormat;
    //WorkOrder
    id: string;
    rowId: string;
    contactId: string;
    customerId: string;
    employeeIds: string[];
    vehicleIds: string[];
    siteId: string;
    workOrderAcknowledgementTypeId: string;
    workOrderTypeId: string;
    description: string;
    endTime: number;
    fullDay: boolean;
    name: string;
    startTime: number;
    state: number;
    canceled: boolean;
    comment: string;
    estimatedHours: number;
    recurrenceType: number;
    recurrenceInterval: number;
    recurrenceIntervalDetail: number;
    recurrenceOccurrenceAmount: number;
    recurrenceEndDate: number;
    //Parent
    parentName: string;
    parentNumber: number;
    //Category
    isProject: boolean;
    isTask: boolean;
    isWork: boolean;
    isRecurringTask: boolean;
    isRecurring: boolean;
    //State
    isGreaterThanDone: boolean;
    //Lists
    contacts: IWorkOrderEditContact[];
    customers: IIdTitle[];
    employees: IEmployeeItem[];
    vehicles: IVehicleItem[];
    projectTasks: IWorkOrderEditItemWorkOrderTask[];
    projectStates: number[];
    sites: IWorkOrderEditSite[];
    workOrderAcknowledgementTypes: IWorkOrderEditAcknowledgementType[];
    workOrderStates: INumberTitle[];
    workOrderTypes: IWorkOrderEditWorkOrderTypeItem[];
    //Logo
    customerLogo: string;
    openMapLink: (mapLink: string) => void;
    onEditCustomer: (customerId: string, callback: (customerId: string) => void) => void;
    onEditSite: (customerId: string, siteId: string) => void;
    onRefreshWorkOrder: () => void;
    onUpdateCustomerDataByCustomerId: (customerId: string, callback: DSetContactSiteAndAcknowledgementType) => void;
    onEditParentProject: () => void;
    sortOrder: number;
}

interface IWorkOrderDialogGeneralReadOnlyState {
    customerName: string;
    employeeNames: string;
    vehicleNames: string;
    workOrderAcknowledgementTypeName: string;
    contact: IWorkOrderEditContact;
    site: IWorkOrderEditSite;
    workOrderType: IWorkOrderEditWorkOrderTypeItem;
    siteMapLink: string;
    startDateStr: string;
    startTimeStr: string;
    endDateStr: string;
    endTimeStr: string;
    isNew: boolean;
    //Task
    parentCustomerName: string;
    parentSite: IWorkOrderEditSite;
    parentContact: IWorkOrderEditContact;
    //Project
    projectTime: { startTime: number; endTime: number; fullDay: boolean };
    sortOrder: number;
}

export class WorkOrderDialogGeneralReadOnly extends React.Component<IWorkOrderDialogGeneralReadOnlyProp, IWorkOrderDialogGeneralReadOnlyState> {
    private getStateFromProps(props: IWorkOrderDialogGeneralReadOnlyProp): IWorkOrderDialogGeneralReadOnlyState {
        const workOrderType = props.isProject ? null : WorkOrderEditWorkOrderTypeItem.getWorkOrderEditWorkOrderTypeItemById(props.workOrderTypes, props.workOrderTypeId);
        const site = WorkOrderEditSite.getWorkOrderEditSiteBySiteId(props.sites, props.siteId);
        return {
            customerName: IdTitle.getTitleById(props.customers, props.customerId),
            employeeNames: props.employeeIds.map(i => IdTitle.getTitleById(props.employees, i)).join(", "),
            vehicleNames: props.vehicleIds.map(i => IdTitle.getTitleById(props.vehicles, i)).join(", "),
            workOrderAcknowledgementTypeName: WorkOrderEditAcknowledgementType.getNameById(props.workOrderAcknowledgementTypes, props.workOrderAcknowledgementTypeId),
            contact: Base.getItemById(props.contacts, props.contactId),
            workOrderType: workOrderType,
            site: site,
            siteMapLink: site != null ? site.mapLink : "",
            startDateStr: Base.timeToDateStr(props.startTime),
            startTimeStr: Base.timeToTimeStr(props.startTime),
            endDateStr: Base.timeToDateStr(props.endTime),
            endTimeStr: Base.timeToTimeStr(props.endTime),
            isNew: !props.rowId,
            //Task
            parentCustomerName: IdTitle.getTitleById(props.customers, props.customerId),
            parentSite: props.sites.find(i => i.id === props.siteId),
            parentContact: props.contactId ? props.contacts.find(i => i.id === props.contactId) : null,
            //Project
            projectTime: WorkOrderEditItem.getProjectTime(props.projectTasks),
            sortOrder: props.sortOrder
        };
    }

    constructor(props: IWorkOrderDialogGeneralReadOnlyProp) {
        super(props);
        this.state = this.getStateFromProps(props);
    }

    componentDidUpdate(prevProps: IWorkOrderDialogGeneralReadOnlyProp, prevState: IWorkOrderDialogGeneralReadOnlyState): void {
        const props = this.props;
        if (prevProps.id === props.id && prevProps.rowId === props.rowId && prevProps.state === props.state && prevProps.projectTasks.map(i => i.rowId).join("#") === props.projectTasks.map(i => i.rowId).join("#")) return;
        this.setState(this.getStateFromProps(props));
    }

    handleSetCustomer = (customerId: string) => {
        if (!customerId) return;
        this.props.onUpdateCustomerDataByCustomerId(customerId, null);
    };

    handleEditCustomer = (customerId: string) => {
        this.props.onEditCustomer(customerId, this.handleSetCustomer);
    };

    setWorkOrderState = (state: number) => {
        const props = this.props;
        WorkOrderOperations.setWorkOrderState([props.id], state, () => {
            props.onRefreshWorkOrder();
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        const recursInfo = AppUtils.getRecurrenceInfo(props.recurrenceType, props.recurrenceInterval, props.recurrenceIntervalDetail);

        return (<div className="readOnlyView">
            <div className="row">
                <div className="col-6">
                    <div className={"form-group"}>
                        <label className="control-label smallFont">{Translations.State}</label>
                        <WorkOrderDialogState
                            isReadOnly={true}
                            isProject={props.isProject}
                            state={props.state}
                            projectStates={props.projectStates}
                            workOrderStates={props.workOrderStates}
                            onStateChange={this.setWorkOrderState}
                        />
                    </div>
                </div>
                {!props.isProject &&
                    <div className="col-6">
                        <div className="form-group">
                            <label className="control-label smallFont">{Translations.WorkOrderType}</label>
                            <div className="readOnlyText">
                                {state.workOrderType?.getTitle()}
                            </div>
                        </div>
                    </div>
                }
            </div>
            {!props.isTask &&
                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <label className="control-label smallFont">{Translations.Customer}</label>
                            <div>
                                <a className="readOnlyLink" onClick={() => { this.handleEditCustomer(props.customerId); }}>
                                    {state.customerName}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label className="control-label smallFont">{Translations.Site}</label>
                            {state.site != null &&
                                <div>
                                    <a className="readOnlyLink" onClick={() => { props.onEditSite(props.customerId, props.siteId); }}>
                                        {state.site.name + (state.site.name2 ? ", " + state.site.name2 : "") + (state.site.familiarizationType > 0 ? Translations.SiteSamiliarizationType1Suffix : "")}
                                    </a>
                                </div>
                            }
                            {state.site != null && state.site.siteHasLocation() &&
                                <div className="readOnlyText small">
                                    <div className="left">
                                        {Base.getStringWithSeparators([state.site.street, Base.getStringWithSeparators([state.site.postalCode, state.site.city], " ")], ", ")}
                                    </div>
                                    {Base.isValidMapLink(state.siteMapLink) &&
                                        <div className="left">
                                            <div className="mapMarker green smallerMarker left" title={Translations.LocationSuccess} />
                                            <a className="readOnlyLink" onClick={() => props.openMapLink(state.siteMapLink)}>
                                                {Translations.OpenMapLink}
                                            </a>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    {/*    {!!props.customerLogo &&*/}
                    {/*        <div className="col-3">*/}
                    {/*            <ImageSelector*/}
                    {/*                fileInputName="logo"*/}
                    {/*                classes="workOrder right"*/}
                    {/*                disabled={true}*/}
                    {/*                image={props.customerLogo}*/}
                    {/*                aspectRatio={null}*/}
                    {/*                onChange={null}*/}
                    {/*                onError={null}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    }*/}
                </div>
            }
            {!props.isTask &&
                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <label className="control-label smallFont">{Translations.WorkTitle}</label>
                            <div className="readOnlyText">
                                {props.name}
                            </div>
                        </div>
                    </div>
                    {!!state.contact &&
                        <div className="col-6">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.Contact}</label>
                                <div className="readOnlyText ">
                                    {state.contact.name}
                                </div>
                                <div className="readOnlyText small">
                                    {Base.getStringWithSeparators([state.contact.phone, state.contact.email], ", ")}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
            {props.isTask &&
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label className="control-label listTitle smallFont">{Translations.AttachedToProject}</label>
                            <div className="row">
                                <div className={props.customerLogo ? "col-9" : "col-12"}>
                                    {(!state.isNew) &&
                                        <a className="readOnlyLink" onClick={props.onEditParentProject}>
                                            <div className="parentInfoLine">{props.parentNumber.toString(10) + " " + props.parentName}</div>
                                        </a>
                                    }
                                    {state.isNew &&
                                        <div className="readOnlyText small">
                                            {Base.getStringWithSeparators([props.parentNumber.toString(10), props.parentName], " ")}
                                        </div>
                                    }
                                    <div className="readOnlyText small">
                                        {Base.getStringWithSeparators([state.parentCustomerName, state.parentSite ? state.parentSite.name : "", state.parentSite ? state.parentSite.name2 : "", state.parentSite ? state.parentSite.street : "",
                                            state.parentSite ? state.parentSite.postalCode : "", state.parentSite ? state.parentSite.city : ""], ", ")}
                                    </div>
                                    {state.parentContact &&
                                        <div className="readOnlyText small">
                                            {Base.getStringWithSeparators([state.parentContact.name, state.parentContact.phone, state.parentContact.email], ", ")}
                                        </div>
                                    }
                                </div>
                                {!!props.customerLogo &&
                                    <div className="col-3">
                                        {props.customerLogo &&
                                            <ImageSelector
                                                fileInputName="logo"
                                                classes="workOrder right"
                                                disabled={true}
                                                image={props.customerLogo}
                                                aspectRatio={null}
                                                onChange={null}
                                                onError={null}
                                            />}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            {props.isTask && !props.isRecurringTask &&
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label className="control-label smallFont">{Translations.WorkTitle}</label>
                            <div className="readOnlyText">
                                {props.name}
                            </div>
                        </div>
                    </div>
                </div>
            }
            {!!props.description &&
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label className="control-label smallFont">{Translations.WorkDescription}</label>
                            <div className="textArea">
                                {props.description}
                            </div>
                        </div>
                    </div>
                </div>
            }
            {!!props.comment &&
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label className="control-label smallFont">{Translations.Comment}</label>
                            <div className="textArea">
                                {props.comment}
                            </div>
                        </div>
                    </div>
                </div>
            }
            {!props.isProject &&
                <div className="row">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.StartTime}</label>
                                    <div className="readOnlyText">
                                        {(state.startDateStr ? state.startDateStr : "") + (props.isProject || !props.fullDay ? " " + (state.startTimeStr ? state.startTimeStr : "") : "")}
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.EndDate}</label>
                                    <div className="readOnlyText">
                                        {(state.endDateStr ? state.endDateStr : "") + " " + (props.isProject || !props.fullDay ? (state.endTimeStr ? state.endTimeStr : "") : "")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {props.employeeIds.length > 0 &&
                        <div className="col-6">
                            <div className="form-group">
                                <label className="control-label smallFont">{props.employeeIds.length > 1 ? Translations.Employees : Translations.Employee}</label>
                                <div className="readOnlyText">
                                    {state.employeeNames}
                                </div>
                            </div>
                        </div>
                    }
                    {props.vehicleIds.length > 0 &&
                        <div className="col-6">
                            <div className="form-group">
                                <label className="control-label smallFont">{props.vehicleIds.length > 1 ? Translations.Vehicles : Translations.Vehicle}</label>
                                <div className="readOnlyText">
                                    {state.vehicleNames}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
            {((props.isProject && state.projectTime.startTime > 0) || (state.workOrderType?.showWorkOrderAcknowledgementType && !props.isProject) || (state.workOrderType?.showEstimatedHours && (props.isRecurring || props.isWork || props.isTask))) &&
                <div className="row">
                    {props.isProject && state.projectTime.startTime > 0 &&
                        <div className="col-6">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.Time}</label>
                                <div className="readOnlyText">
                                    {props.isRecurring &&
                                        <span>
                                            {AppUtils.getTimeDurationStrByDurationMin(state.projectTime.startTime, state.projectTime.endTime, state.projectTime.fullDay, 0)}<br />
                                            {Translations.Occurs} {recursInfo}
                                        </span>
                                    }
                                    {!props.isRecurring &&
                                        AppUtils.getTimeDurationStr(state.projectTime.startTime, state.projectTime.endTime, state.projectTime.fullDay)
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {state.workOrderType?.showWorkOrderAcknowledgementType && props.workOrderAcknowledgementTypes.length && !props.isProject &&
                        <div className="col-6">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.WorkOrderAcknowledgementType}</label>
                                <div className="readOnlyText">
                                    {state.workOrderAcknowledgementTypeName}
                                </div>
                            </div>
                        </div>
                    }
                    {state.workOrderType?.showEstimatedHours && (props.isRecurring || props.isWork || props.isTask) &&
                        <div className="col-3">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.EstimatedHours}</label>
                                <div className="readOnlyText">
                                    {props.estimatedHours ? AppUtils.getDurationStrByDurationMinShort(60 * props.estimatedHours) : ""}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>);
    }
}

//WorkOrderDialogGeneral
export interface IWorkOrderDialogGeneralProp {
    workTimeFormat: TimeFormat;
    //WorkOrder
    id: string;
    rowId: string;
    contactId: string;
    customerId: string;
    employeeIds: string[];
    vehicleIds: string[];
    siteId: string;
    workOrderAcknowledgementTypeId: string;
    workOrderTypeId: string;
    category: number;
    description: string;
    endTime: number;
    fullDay: boolean;
    name: string;
    startTime: number;
    state: number;
    canceled: boolean;
    comment: string;
    estimatedHours: number;
    sortOrder: number;
    hasSequentialTasks: boolean;
    //Parent
    parentId: string;
    parentName: string;
    parentNumber: number;
    parentCustomerName: string;
    parentSite: IWorkOrderEditSite;
    parentContact: IWorkOrderEditContact;
    //Category
    isProject: boolean;
    isTask: boolean;
    isWork: boolean;
    isRecurringTask: boolean;
    //State
    isGreaterThanPlanned: boolean;
    isGreaterThanDone: boolean;
    isPreliminary: boolean;
    isReadOnly: boolean;
    enabledStates: number[];
    //Recurrence
    isRecurring: boolean;
    recurrenceType: number;
    recurrenceInterval: number;
    recurrenceIntervalDetail: number;
    recurrenceOccurrenceAmount: number;
    recurrenceEndDate: number;
    //Invoice
    invoiceId: string;
    invoiceNumber: number;
    //Lists
    contacts: IWorkOrderEditContact[];
    customers: IIdTitle[];
    employees: IEmployeeItem[];
    vehicles: IVehicleItem[];
    projectTasks: IWorkOrderEditItemWorkOrderTask[];
    projectStates: number[];
    sites: IWorkOrderEditSite[];
    workOrderAcknowledgementTypes: IWorkOrderEditAcknowledgementType[];
    workOrderStates: INumberTitle[];
    workOrderTypes: IWorkOrderEditWorkOrderTypeItem[];
    //Logo
    customerLogo: string;
    //Project
    openedFromProject: boolean;
    //Bookings
    hourBookings: IWorkOrderEditItemHourBooking[];
    rideBookings: IWorkOrderEditItemRideBooking[];
    routePointBookings: WorkOrderEditItemRoutePointBooking[];
    //Operations
    onAddContact: (customerId: string, name: string, callback: (contactId: string) => void) => void;
    onAttachContact: (customerId: string, name: string, callback: (contactId: string) => void) => void;
    onEditContact: (customerId: string, siteId: string) => void;
    onAddCustomer: (name: string, callback: (customerId: string) => void) => void;
    onEditCustomer: (customerId: string, callback: (customerId: string) => void) => void;
    onEditParentProject: () => void;
    onAddSite: (customerId: string, name: string, callback: (siteId: string) => void) => void;
    onEditSite: (customerId: string, siteId: string) => void;
    openMapLink: (mapLink: string) => void;
    onRefreshWorkOrder: () => void;
    onSetRecurrenceType: (recurrenceType: number) => void;
    onUpdateCustomerDataByCustomerId: (customerId: string, callback: DSetContactSiteAndAcknowledgementType) => void;
    removeWorkOrder: (id: string) => void;
    onSwitchHasSequentialTasks: (newState: boolean) => void;
    onWorkOrderTypeChange: (workOrderType: WorkOrderEditWorkOrderTypeItem) => void;
    onSubmitEnd: () => void;
}

export class WorkOrderDialogGeneral extends React.Component<IWorkOrderDialogGeneralProp, {}> {
    private workOrderDialogGeneralEditRef = React.createRef<WorkOrderDialogGeneralEdit>();

    saveWorkOrderAndCloseGeneral = (afterSaveCallback: (saved: boolean, workOrderId: string, makeProductBookingsAfterSave: boolean) => void) => {
        this.workOrderDialogGeneralEditRef.current.saveWorkOrder(afterSaveCallback);
    };

    cancelWorkOrderEdit = (afterSaveCallback: (saved: boolean, workOrderId: string, makeProductBookingsAfterSave: boolean) => void) => {
        this.workOrderDialogGeneralEditRef.current.cancelWorkOrderEdit(afterSaveCallback);
    };

    hasChanges = () => {
        return this.workOrderDialogGeneralEditRef.current?.hasChanges();
    };

    render() {
        const props = this.props;
        return (
            <div id="workOrderGeneral" className="workOrderGeneral">
                {props.isReadOnly
                    ? <WorkOrderDialogGeneralReadOnly
                            id={props.id}
                            workTimeFormat={props.workTimeFormat}
                            rowId={props.rowId}
                            contactId={props.contactId}
                            customerId={props.customerId}
                            employeeIds={props.employeeIds}
                            vehicleIds={props.vehicleIds}
                            siteId={props.siteId}
                            workOrderAcknowledgementTypeId={props.workOrderAcknowledgementTypeId}
                            workOrderTypeId={props.workOrderTypeId}
                            description={props.description}
                            endTime={props.endTime}
                            fullDay={props.fullDay}
                            name={props.name}
                            startTime={props.startTime}
                            state={props.state}
                            canceled={props.canceled}
                            comment={props.comment}
                            estimatedHours={props.estimatedHours}
                            recurrenceType={props.recurrenceType}
                            recurrenceInterval={props.recurrenceInterval}
                            recurrenceIntervalDetail={props.recurrenceIntervalDetail}
                            recurrenceOccurrenceAmount={props.recurrenceOccurrenceAmount}
                            recurrenceEndDate={props.recurrenceEndDate}
                            parentName={props.parentName}
                            parentNumber={props.parentNumber}
                            isProject={props.isProject}
                            isTask={props.isTask}
                            isWork={props.isWork}
                            isRecurringTask={props.isRecurringTask}
                            isRecurring={props.isRecurring}
                            isGreaterThanDone={props.isGreaterThanDone}
                            contacts={props.contacts}
                            customers={props.customers}
                            employees={props.employees}
                            vehicles={props.vehicles}
                            projectTasks={props.projectTasks}
                            projectStates={props.projectStates}
                            sites={props.sites}
                            workOrderAcknowledgementTypes={props.workOrderAcknowledgementTypes}
                            workOrderStates={props.workOrderStates}
                            workOrderTypes={props.workOrderTypes}
                            customerLogo={props.customerLogo}
                            onUpdateCustomerDataByCustomerId={props.onUpdateCustomerDataByCustomerId}
                            onEditCustomer={props.onEditCustomer}
                            onEditSite={props.onEditSite}
                            onRefreshWorkOrder={props.onRefreshWorkOrder}
                            openMapLink={props.openMapLink}
                            onEditParentProject={props.onEditParentProject}
                            sortOrder={props.sortOrder}
                      />
                    : <WorkOrderDialogGeneralEdit
                            ref={this.workOrderDialogGeneralEditRef}
                            workTimeFormat={props.workTimeFormat}
                            id={props.id}
                            rowId={props.rowId}
                            contactId={props.contactId}
                            customerId={props.customerId}
                            employeeIds={props.employeeIds}
                            vehicleIds={props.vehicleIds}
                            siteId={props.siteId}
                            workOrderAcknowledgementTypeId={props.workOrderAcknowledgementTypeId}
                            workOrderTypeId={props.workOrderTypeId}
                            category={props.category}
                            description={props.description}
                            endTime={props.endTime}
                            fullDay={props.fullDay}
                            name={props.name}
                            startTime={props.startTime}
                            state={props.state}
                            canceled={props.canceled}
                            comment={props.comment}
                            estimatedHours={props.estimatedHours}
                            parentId={props.parentId}
                            parentName={props.parentName}
                            parentNumber={props.parentNumber}
                            parentCustomerName={props.parentCustomerName}
                            parentSite={props.parentSite}
                            parentContact={props.parentContact}
                            isProject={props.isProject}
                            isTask={props.isTask}
                            isWork={props.isWork}
                            isRecurringTask={props.isRecurringTask}
                            isGreaterThanPlanned={props.isGreaterThanPlanned}
                            isGreaterThanDone={props.isGreaterThanDone}
                            isPreliminary={props.isPreliminary}
                            isRecurring={props.isRecurring}
                            recurrenceType={props.recurrenceType}
                            recurrenceInterval={props.recurrenceInterval}
                            recurrenceIntervalDetail={props.recurrenceIntervalDetail}
                            recurrenceOccurrenceAmount={props.recurrenceOccurrenceAmount}
                            recurrenceEndDate={props.recurrenceEndDate}
                            invoiceId={props.invoiceId}
                            invoiceNumber={props.invoiceNumber}
                            contacts={props.contacts}
                            customers={props.customers}
                            employees={props.employees}
                            vehicles={props.vehicles}
                            projectTasks={props.projectTasks}
                            projectStates={props.projectStates}
                            sites={props.sites}
                            workOrderAcknowledgementTypes={props.workOrderAcknowledgementTypes}
                            workOrderStates={props.workOrderStates}
                            enabledStates={props.enabledStates}
                            workOrderTypes={props.workOrderTypes}
                            customerLogo={props.customerLogo}
                            openedFromProject={props.openedFromProject}
                            hourBookings={props.hourBookings}
                            rideBookings={props.rideBookings}
                            routePointBookings={props.routePointBookings}
                            removeWorkOrder={props.removeWorkOrder}
                            onUpdateCustomerDataByCustomerId={props.onUpdateCustomerDataByCustomerId}
                            onAddCustomer={props.onAddCustomer}
                            onEditCustomer={props.onEditCustomer}
                            onAddSite={props.onAddSite}
                            onEditSite={props.onEditSite}
                            onAddContact={props.onAddContact}
                            onAttachContact={props.onAttachContact}
                            onEditContact={props.onEditContact}
                            openMapLink={props.openMapLink}
                            onSetRecurrenceType={props.onSetRecurrenceType}
                            onEditParentProject={props.onEditParentProject}
                            sortOrder={props.sortOrder}
                            hasSequentialTasks={props.hasSequentialTasks}
                            onSwitchHasSequentialTasks={props.onSwitchHasSequentialTasks}
                            onWorkOrderTypeChange={props.onWorkOrderTypeChange}
                            onSubmitEnd={props.onSubmitEnd}
                      />
                }
            </div>);
    }
}
