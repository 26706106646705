// WorkOrderDialogRecurrenceDialog
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import { PropertyDialog } from "../framework/dialog";
import { DatePicker } from "../framework/datePicker";
import { Radio } from "../framework/radio";
import { TimeSelector } from "../framework/timeSelector";
import { Base } from "../../framework/base";
import { IdTitle, IIdTitle } from "../../models/common/idTitle";
import { EnumHelper, RecurrenceType } from "../../models/common/enums";
import { CheckBox } from "../framework/checkbox";
import { AppUtils } from "../../models/common/appUtils";
import { IVehicleItem } from "../../models/vehicle/vehicleItem";
import { IEmployeeItem } from "../../models/employee/employeeItem";

// WorkOrderDialogRecurrenceDialog
// ***********************************************************************************************************************
export interface IWorkOrderDialogRecurrenceDialogProp {
    classes?: string;
    //Adding recurrence
    addMode: boolean;
    addSourceIsTask: boolean;
    name: string;
    description: string;
    employees: IEmployeeItem[];
    vehicles: IVehicleItem[];
    employeeIds: string[];
    vehicleIds: string[];
    fullDay: boolean;
    //General options
    startDateStr: string;
    startTimeStr: string;
    endDateStr: string;
    endTimeStr: string;
    interval: number;
    recurrenceType: number;
    recurrenceInterval: number;
    recurrenceIntervalDetail: number;
    recurrenceOccurrenceAmount: number;
    recurrenceEndDate: number;
    onOk: (employeeIds: string[], vehicleIds: string[], name: string, description: string, fullDay: boolean, startDateStr: string, startTimeStr: string, endDateStr: string, endTimeStr: string,
        recurrenceType: number, recurrenceInterval: number, recurrenceIntervalDetail: number, recurrenceOccurrenceAmount: number, recurrenceEndDate: number) => void;
    onCancel: () => void;
}

export interface IWorkOrderDialogRecurrenceDialogState {
    //Adding recurrence
    employeeNames: string;
    vehicleNames: string;
    //General options
    orgStartDateStr: string;
    startDateStr: string;
    startTimeStr: string;
    endDateStr: string;
    endTimeStr: string;
    durationStr: string;
    durationItems: IIdTitle[];
    recurrenceType: number;
    recurrenceIntervalStr: string;
    recurrenceIntervalDetail: number;
    dayStr: string;
    monthStr: string;
    recurrenceEndByDate: boolean;
    recurrenceOccurrenceAmountStr: string;
    recurrenceEndDateStr: string;
}

export class WorkOrderDialogRecurrenceDialog extends React.Component<IWorkOrderDialogRecurrenceDialogProp, IWorkOrderDialogRecurrenceDialogState> {
    private defaultDurationDailyInDays = 30;
    private defaultDurationWeeklyInWeeks = 5;
    private defaultDurationMonthlyInMonths = 6;
    private defaultDurationYearlyInYears = 6;

    private getDurationItems = (props: IWorkOrderDialogRecurrenceDialogProp, currentDurationMin: number): IIdTitle[] => {
        const interval = props.interval ?? 30;
        const intervalSequence: number[] = [];
        if (currentDurationMin < 24 * 60) {
            for (let i = 0; i < 24 * 60 / interval; i++) {
                intervalSequence.push((i + 1) * interval);
            }
        } else {
            if (!props.fullDay) {
                intervalSequence.push(interval);
                for (let i = 0; i < 12; i++) {
                    intervalSequence.push((i + 1) * 60);
                }
            }
            if (!props.fullDay) {
                intervalSequence.push(24 * 60);
            }
            for (let i = 1; i < 4; i++) {
                intervalSequence.push((i + 1) * 24 * 60);
            }
            if (props.fullDay) {
                for (let i = 4; i < 6; i++) {
                    intervalSequence.push((i + 1) * 24 * 60);
                }
            }
            for (let i = 0; i < 3; i++) {
                intervalSequence.push((i + 1) * 7 * 24 * 60);
            }
        }
        const values: IIdTitle[] = [];
        let durationMin = !props.fullDay ? 0 : 24 * 60;
        for (let i = 0; i < intervalSequence.length; i++) {
            //Add duration
            values.push(IdTitle.createIdTitle(durationMin.toString(10), AppUtils.getDurationStrByDurationMin(durationMin, durationMin <= 0)));
            //Add current value that does not fit in interval
            if (currentDurationMin > durationMin && currentDurationMin < intervalSequence[i]) {
                values.push(IdTitle.createIdTitle(currentDurationMin.toString(10), AppUtils.getDurationStrByDurationMin(currentDurationMin)));
            }
            //Next duration
            durationMin = intervalSequence[i];
        }
        //Add current value that does not fit in interval
        if (currentDurationMin > durationMin) {
            values.push(IdTitle.createIdTitle(currentDurationMin.toString(10), AppUtils.getDurationStrByDurationMin(currentDurationMin)));
        }
        return values;
    };

    private getRecurringWorkOrderStartDates(recurrenceType: RecurrenceType, recurrenceInterval: number, recurrenceIntervalDetail: number, recurrenceOccurrenceAmount: number, startDateTime: Date, recurrenceEndDate: Date): Date[] {
        const result: Date[] = [];
        let workOrderDate = new Date(startDateTime.getFullYear(), startDateTime.getMonth(), startDateTime.getDate());
        let amount = 0;
        recurrenceOccurrenceAmount = Math.min(recurrenceOccurrenceAmount, 1000);
        if (recurrenceEndDate) {
            const maxEndDate = Base.getNowDate().addYears(10);
            recurrenceEndDate = recurrenceEndDate > maxEndDate ? maxEndDate : recurrenceEndDate;
        }
        if (EnumHelper.isEqual(recurrenceType, RecurrenceType.Daily)) {
            //Every Nth day
            if (recurrenceIntervalDetail === 0) {
                while (recurrenceEndDate == null ? amount < recurrenceOccurrenceAmount : workOrderDate <= recurrenceEndDate) {
                    result.push(workOrderDate);
                    amount++;
                    workOrderDate = workOrderDate.addDays(recurrenceInterval);
                }
                return result;
            }
            //Every weekday
            while (recurrenceEndDate == null ? amount < recurrenceOccurrenceAmount : workOrderDate <= recurrenceEndDate) {
                const dayOfWeek = workOrderDate.getDay();
                if (dayOfWeek > 0.5 && dayOfWeek < 5.5) {
                    result.push(workOrderDate);
                    amount++;
                }
                workOrderDate = workOrderDate.addDays(1);
            }
            return result;
        }
        if (EnumHelper.isEqual(recurrenceType, RecurrenceType.Weekly)) {
            //Every Nth week on certain weekdays
            while (recurrenceEndDate == null ? amount < recurrenceOccurrenceAmount : workOrderDate <= recurrenceEndDate) {
                const dayOfWeek = (workOrderDate.getDay() + 6) % 7;
                if (Math.floor(recurrenceIntervalDetail / Math.pow(10, dayOfWeek)) % 2 > 0.5) {
                    result.push(workOrderDate);
                    amount++;
                }
                workOrderDate = workOrderDate.addDays(1);
                if (workOrderDate.getDay() === 1 && recurrenceInterval > 1) {
                    workOrderDate = workOrderDate.addDays((recurrenceInterval - 1) * 7);
                }
            }
            return result;
        }
        if (EnumHelper.isEqual(recurrenceType, RecurrenceType.Monthly)) {
            //Every Nth month on certain day of the month
            while (recurrenceEndDate == null ? amount < recurrenceOccurrenceAmount : workOrderDate <= recurrenceEndDate) {
                if (workOrderDate.getDate() === recurrenceIntervalDetail) {
                    result.push(workOrderDate);
                    amount++;
                }
                workOrderDate = workOrderDate.addDays(1);
                if (workOrderDate.getDate() === 1 && recurrenceInterval > 1) {
                    workOrderDate = workOrderDate.addMonths(recurrenceInterval - 1);
                }
            }
        } else {
            //Every Nth year on certain day of certain month
            const month = Math.floor(recurrenceIntervalDetail / 100);
            const day = recurrenceIntervalDetail % 100;
            while (recurrenceEndDate == null ? amount < recurrenceOccurrenceAmount : workOrderDate <= recurrenceEndDate) {
                if (workOrderDate.getMonth() === month - 1 && workOrderDate.getDate() === day) {
                    result.push(workOrderDate);
                    amount++;
                }
                workOrderDate = workOrderDate.addDays(1);
                if (workOrderDate.getMonth() === 0 && workOrderDate.getDate() === 1 && recurrenceInterval > 1) {
                    workOrderDate = workOrderDate.addYears(recurrenceInterval - 1);
                }
            }
        }
        if (result.length < 1) {
            result.push(new Date(startDateTime.getFullYear(), startDateTime.getMonth(), startDateTime.getDate()));
        }
        return result;
    }

    constructor(props: IWorkOrderDialogRecurrenceDialogProp) {
        super(props);
        const startDate = props.startDateStr ? props.startDateStr.toDate() : null;
        const startTime = props.startTimeStr ? props.startTimeStr.toTime() : null;
        const endDate = props.endDateStr ? (props.fullDay ? props.endDateStr.toDate().addDays() : props.endDateStr.toDate()) : null;
        const endTime = props.endTimeStr ? props.endTimeStr.toTime() : null;
        const currentDurationMin = Base.dateDiffInMinutesFromParts(startDate ? startDate.getFullYear() : 2000, startDate ? startDate.getMonth() : 0, startDate ? startDate.getDate() : 1, startTime ? startTime.getHours() : 0, startTime ? startTime.getMinutes() : 0, 0,
            endDate ? endDate.getFullYear() : 2000, endDate ? endDate.getMonth() : 0, endDate ? endDate.getDate() : 1, endTime ? endTime.getHours() : 0, endTime ? endTime.getMinutes() : 0, 0);
        const recurrenceInUse = props.recurrenceType > 0.5;
        const defaultRecurrenceWeekday = ((startDate ? startDate.getDay() : 1) + 6) % 7;
        const recurrenceType = recurrenceInUse ? props.recurrenceType : RecurrenceType.Weekly;
        const recurrenceInterval = recurrenceInUse ? props.recurrenceInterval : 1;
        const recurrenceIntervalDetail = recurrenceInUse ? props.recurrenceIntervalDetail : Math.pow(10, defaultRecurrenceWeekday);
        const recurrenceOccurrenceAmount = recurrenceInUse ? (props.recurrenceEndDate ? 0 : props.recurrenceOccurrenceAmount) : 0;
        const recurrenceEndDate = recurrenceInUse ? (props.recurrenceEndDate ? new Date(props.recurrenceEndDate) : null) : startDate.addDays((this.defaultDurationWeeklyInWeeks - 1) * 7);
        const recurrenceDates = this.getRecurringWorkOrderStartDates(recurrenceType, recurrenceInterval, recurrenceIntervalDetail, recurrenceOccurrenceAmount, startDate, recurrenceEndDate);
        this.state = {
            //Adding recurrence
            employeeNames: props.employeeIds.map(i => IdTitle.getTitleById(props.employees, i)).join(", "),
            vehicleNames: props.vehicleIds.map(i => IdTitle.getTitleById(props.vehicles, i)).join(", "),
            //General options
            orgStartDateStr: props.startDateStr,
            startDateStr: props.startDateStr,
            startTimeStr: props.startTimeStr,
            endDateStr: props.endDateStr,
            endTimeStr: props.endTimeStr,
            durationStr: currentDurationMin.toString(10),
            durationItems: this.getDurationItems(props, currentDurationMin),
            recurrenceType: recurrenceType,
            recurrenceIntervalStr: recurrenceInterval.toString(10),
            recurrenceIntervalDetail: recurrenceIntervalDetail,
            dayStr: EnumHelper.isEqual(recurrenceType, RecurrenceType.Monthly) ? recurrenceIntervalDetail.toString(10) : (EnumHelper.isEqual(recurrenceType, RecurrenceType.Yearly) ? (recurrenceIntervalDetail % 100).toString(10) : "1"),
            monthStr: EnumHelper.isEqual(recurrenceType, RecurrenceType.Yearly) ? Math.floor(recurrenceIntervalDetail / 100).toString(10) : "1",
            recurrenceEndByDate: !!recurrenceEndDate,
            recurrenceOccurrenceAmountStr: (recurrenceEndDate ? recurrenceDates.length : recurrenceOccurrenceAmount).toString(10),
            recurrenceEndDateStr: Base.dateToDateStr(recurrenceEndDate ?? recurrenceDates[recurrenceDates.length - 1])
        };
    }

    handleCancelAndCloseDialog = () => {
        const obj = this;
        obj.props.onCancel();
    };

    handleSaveAndCloseEdit = () => {
        const props = this.props;
        const state = this.state;
        props.onOk(props.employeeIds, props.vehicleIds, props.name, props.description, props.fullDay, state.startDateStr, state.startTimeStr, state.endDateStr, state.endTimeStr, state.recurrenceType, state.recurrenceIntervalStr.toInteger(),
            EnumHelper.isEqual(state.recurrenceType, RecurrenceType.Daily) || EnumHelper.isEqual(state.recurrenceType, RecurrenceType.Weekly)
                ? state.recurrenceIntervalDetail
                : (EnumHelper.isEqual(state.recurrenceType, RecurrenceType.Monthly)
                    ? state.dayStr.toInteger(false, 1)
                    : state.monthStr.toInteger(false, 1) * 100 + state.dayStr.toInteger(false, 1)),
            state.recurrenceEndByDate ? 0 : state.recurrenceOccurrenceAmountStr.toInteger(false, 1),
            state.recurrenceEndByDate ? state.recurrenceEndDateStr.toDate().getTime() : null);
    };

    handleRemoveRecurrence = () => {
        const props = this.props;
        props.onOk(null, null, null, null, null, props.startDateStr, props.startTimeStr, props.endDateStr, props.endTimeStr, RecurrenceType.NoRecurrence, 1, 0, 1, null);
    };

    changeStartDate = (value: string) => {
        const props = this.props;
        const state = this.state;
        if (!value || !state.orgStartDateStr) {
            this.setState({ startDateStr: state.orgStartDateStr });
            return;
        }
        const orgStartDateTime = AppUtils.getDateTime(state.orgStartDateStr, null, props.fullDay);
        const newStartDateTime = AppUtils.getDateTime(value, null, props.fullDay);
        const differenceInMin = Base.dateDiffInMinutes(orgStartDateTime, newStartDateTime);
        let recurrenceEndDateStr = state.recurrenceEndDateStr;
        let recurrenceEndDate = AppUtils.getDateTime(recurrenceEndDateStr, null, props.fullDay);
        if (recurrenceEndDate) {
            recurrenceEndDate = recurrenceEndDate.addMinutes(differenceInMin);
            recurrenceEndDateStr = Base.timeToDateStr(recurrenceEndDate.getTime());
        }
        let endDateStr = state.endDateStr;
        let endDateTime = AppUtils.getDateTime(endDateStr, null, props.fullDay);
        if (endDateTime) {
            endDateTime = endDateTime.addMinutes(differenceInMin);
            endDateStr = Base.timeToDateStr(endDateTime.getTime());
        }
        this.setState({ startDateStr: value, orgStartDateStr: value, endDateStr: endDateStr, recurrenceEndDateStr: recurrenceEndDateStr });
    };

    handleStartDateChange = (value: string) => {
        this.setState({ startDateStr: value });
    };

    handleStartDateBlur = (value: string) => {
        this.changeStartDate(value);
    };

    setDurationByStartTimeAndEndTime = (startDateStr: string, startTimeStr: string, endDateStr: string, endTimeStr: string) => {
        const props = this.props;
        const startDateTime = AppUtils.getDateTime(startDateStr, startTimeStr, props.fullDay);
        let endDateTime = AppUtils.getDateTime(endDateStr, endTimeStr, props.fullDay);
        if (endDateTime && props.fullDay) {
            endDateTime = endDateTime.addDays();
        }
        if (startDateTime && endDateTime) {
            const duration = Math.max(0, Base.dateDiffInMinutes(startDateTime, endDateTime));
            this.setState({
                durationStr: duration.toString(10),
                durationItems: this.getDurationItems(props, duration)
            });
        }
    };

    handleStartTimeChange = (value: string) => {
        const props = this.props;
        const state = this.state;
        let endDateStr = state.endDateStr;
        let endTimeStr = state.endTimeStr;
        if (value && state.startDateStr && state.startTimeStr && endDateStr && endTimeStr) {
            const orgStartDateTime = AppUtils.getDateTime(state.startDateStr, state.startTimeStr, props.fullDay);
            const newStartDateTime = AppUtils.getDateTime(state.startDateStr, value, props.fullDay);
            const differenceInMin = Base.dateDiffInMinutes(orgStartDateTime, newStartDateTime);
            const newEndDateTime = AppUtils.getDateTime(endDateStr, endTimeStr, props.fullDay).addMinutes(differenceInMin);
            endDateStr = Base.timeToDateStr(newEndDateTime.getTime());
            endTimeStr = Base.timeToTimeStr(newEndDateTime.getTime());
        }
        this.setState({
            startTimeStr: value,
            endDateStr: endDateStr,
            endTimeStr: endTimeStr
        });
        this.setDurationByStartTimeAndEndTime(state.startDateStr, value, endDateStr, endTimeStr);
    };

    changeRecurrenceEndDate = (value: string) => {
        const state = this.state;
        if (state.startDateStr && value) {
            const startDate = state.startDateStr.toDate();
            let endDate = value.toDate();
            endDate = endDate >= startDate ? endDate : startDate;
            value = Base.timeToDateStr(endDate.getTime());
        }
        let recurrenceOccurrenceAmountStr = state.recurrenceOccurrenceAmountStr;
        if (state.startDateStr && value) {
            const recurrenceDates = this.getRecurringWorkOrderStartDates(state.recurrenceType, state.recurrenceIntervalStr.toInteger(false, 1), state.recurrenceIntervalDetail, 0, state.startDateStr.toDate(), value.toDate());
            recurrenceOccurrenceAmountStr = recurrenceDates.length.toString(10);
        }
        this.setState({
            recurrenceEndByDate: true,
            recurrenceEndDateStr: value,
            recurrenceOccurrenceAmountStr: recurrenceOccurrenceAmountStr
        });
    };

    handleRecurrenceEndDateChange = (value: string) => {
        this.setState({ recurrenceEndDateStr: value });
    };

    handleRecurrenceEndDateBlur = (value: string) => {
        this.changeRecurrenceEndDate(value);
    };

    handleEndTimeChange = (value: string) => {
        const state = this.state;
        this.setState({
            endTimeStr: value,
        });
        this.setDurationByStartTimeAndEndTime(state.startDateStr, state.startTimeStr, state.endDateStr, value);
    };

    handleDurationChange = (event) => {
        const state = this.state;
        const target = event.target;
        const value: string = target.value;
        const currentDurationMin = value ? value.toInteger() : 0;
        const startDate = state.startDateStr ? state.startDateStr.toDate() : null;
        const startTime = state.startTimeStr ? state.startTimeStr.toTime() : null;
        const startDateTime = new Date(startDate ? startDate.getFullYear() : 2000, startDate ? startDate.getMonth() : 0, startDate ? startDate.getDate() : 1, startTime ? startTime.getHours() : 0, startTime ? startTime.getMinutes() : 0);
        const endDateTime = startDateTime.addMinutes(currentDurationMin).getTime();
        this.setState({ endDateStr: Base.timeToDateStr(endDateTime), endTimeStr: Base.timeToTimeStr(endDateTime), durationStr: currentDurationMin.toString(10), durationItems: this.getDurationItems(this.props, currentDurationMin) });
    };

    handleRecurrenceTypeChange = (value: RecurrenceType) => {
        const state = this.state;
        const startDate = state.startDateStr ? state.startDateStr.toDate() : null;
        const day = startDate ? startDate.getDate() : 1;
        const month = (startDate ? startDate.getMonth() : 0) + 1;
        const weekday = ((startDate ? startDate.getDay() : 1) + 6) % 7;
        const recurrenceInterval = 1;
        const recurrenceIntervalDetail = EnumHelper.isEqual(value, RecurrenceType.Daily)
            ? 0
            : (EnumHelper.isEqual(value, RecurrenceType.Weekly)
                ? Math.pow(10, weekday)
                : (EnumHelper.isEqual(value, RecurrenceType.Monthly) ? day : (100 * month + day)));
        let recurrenceEndDate = startDate ?? Base.getNowDate();
        if (EnumHelper.isEqual(value, RecurrenceType.Daily)) {
            recurrenceEndDate = recurrenceEndDate.addDays(this.defaultDurationDailyInDays - 1);
        } else if (EnumHelper.isEqual(value, RecurrenceType.Weekly)) {
            recurrenceEndDate = recurrenceEndDate.addDays((this.defaultDurationWeeklyInWeeks - 1) * 7);
        } else if (EnumHelper.isEqual(value, RecurrenceType.Monthly)) {
            recurrenceEndDate = recurrenceEndDate.addMonths((this.defaultDurationMonthlyInMonths - 1));
        } else {
            recurrenceEndDate = recurrenceEndDate.addYears((this.defaultDurationYearlyInYears - 1));
        }
        const recurrenceDates = this.getRecurringWorkOrderStartDates(value, recurrenceInterval, recurrenceIntervalDetail, 0, startDate, recurrenceEndDate);
        this.setState({
            recurrenceType: value,
            recurrenceIntervalStr: recurrenceInterval.toString(10),
            recurrenceIntervalDetail: recurrenceIntervalDetail,
            recurrenceOccurrenceAmountStr: recurrenceDates.length.toString(10),
            recurrenceEndDateStr: Base.dateToDateStr(recurrenceEndDate)
        });
    };

    handleRecurrenceIntervalDetailChange = (value: number) => {
        const state = this.state;
        let recurrenceOccurrenceAmountStr = state.recurrenceOccurrenceAmountStr;
        let recurrenceEndDateStr = state.recurrenceEndDateStr;
        const recurrenceDates = this.getRecurringWorkOrderStartDates(
            state.recurrenceType,
            state.recurrenceIntervalStr.toInteger(false, 1),
            value,
            state.recurrenceEndByDate ? 0 : recurrenceOccurrenceAmountStr.toInteger(false, 1),
            state.startDateStr.toDate(),
            state.recurrenceEndByDate ? state.recurrenceEndDateStr.toDate() : null);
        if (state.recurrenceEndByDate) {
            recurrenceOccurrenceAmountStr = recurrenceDates.length.toString(10);
        } else {
            recurrenceEndDateStr = Base.dateToDateStr(recurrenceDates[recurrenceDates.length - 1]);
        }
        this.setState({
            recurrenceIntervalDetail: value,
            recurrenceEndDateStr: recurrenceEndDateStr,
            recurrenceOccurrenceAmountStr: recurrenceOccurrenceAmountStr
        });
    };

    handleRecurrenceIntervalDetailWeekChange = (weekday: number, value: boolean) => {
        let recurrenceIntervalDetail = this.state.recurrenceIntervalDetail;
        const orgRecurrenceIntervalDetail = recurrenceIntervalDetail;
        const num = Math.pow(10, weekday);
        const isSet = Math.floor(recurrenceIntervalDetail / num) % 2 > 0.5;
        if (isSet && !value) {
            recurrenceIntervalDetail = recurrenceIntervalDetail - num;
        } else if (!isSet && value) {
            recurrenceIntervalDetail = recurrenceIntervalDetail + num;
        }
        if (recurrenceIntervalDetail === 0) {
            recurrenceIntervalDetail = orgRecurrenceIntervalDetail;
        }
        this.setState({ recurrenceIntervalDetail: recurrenceIntervalDetail });
        if (recurrenceIntervalDetail !== orgRecurrenceIntervalDetail) {
            this.updateRangeOfRecurrenceDelayed();
        }
    };

    changeDay = (value: string) => {
        const date = (value.toInteger(false, 1).toString(10) + "." + this.state.monthStr + "." + Base.getNowDate().getFullYear().toString(10)).toDate();
        this.setState({ dayStr: date.getDate().toString(10) });
    };

    handleDayChange = (event) => {
        const target = event.target;
        this.changeDay(target.value);
    };

    handleDayBlur = (event) => {
        const target = event.target;
        this.changeDay(target.value);
    };

    handleMonthChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const date = (this.state.dayStr + "." + value.toInteger(false, 1).toString(10) + "." + Base.getNowDate().getFullYear().toString(10)).toDate();
        this.setState({ monthStr: value, dayStr: date.getDate().toString(10) });
    };

    updateRangeOfRecurrenceDelayed = () => {
        const obj = this;
        const state = this.state;
        if (state.recurrenceEndByDate) {
            window.setTimeout(() => {
                obj.changeRecurrenceEndDate(state.recurrenceEndDateStr);
            }, 50);
        } else {
            window.setTimeout(() => {
                obj.changeRecurrenceOccurrenceAmount(state.recurrenceOccurrenceAmountStr);
            }, 50);
        }
    };

    changeRecurrenceInterval = (value: string) => {
        this.setState({ recurrenceIntervalStr: value });
        this.updateRangeOfRecurrenceDelayed();
    };

    handleRecurrenceIntervalChange = (event) => {
        const target = event.target;
        this.changeRecurrenceInterval(target.value);
    };

    handleRecurrenceIntervalBlur = (event) => {
        const target = event.target;
        this.changeRecurrenceInterval(target.value.toInteger(false, 1).toString(10));
    };

    handleRecurrenceEndByDateChange = (value: boolean) => {
        this.setState({ recurrenceEndByDate: value });
    };

    changeRecurrenceOccurrenceAmount = (value: string) => {
        const state = this.state;
        const recurrenceOccurrenceAmount = value.toInteger(false, 1);
        const startDate = state.startDateStr ? state.startDateStr.toDate() : null;
        const recurrenceInterval = state.recurrenceIntervalStr.toInteger(false, 1);
        const recurrenceDates = this.getRecurringWorkOrderStartDates(state.recurrenceType, recurrenceInterval, state.recurrenceIntervalDetail, recurrenceOccurrenceAmount, startDate, null);
        this.setState({
            recurrenceEndByDate: false,
            recurrenceOccurrenceAmountStr: recurrenceOccurrenceAmount.toString(10),
            recurrenceEndDateStr: recurrenceDates.length > 0 ? Base.dateToDateStr(recurrenceDates[recurrenceDates.length - 1]) : ""
        });
    };

    handleRecurrenceOccurrenceAmountChange = (event) => {
        const target = event.target;
        if (!target.value) {
            this.setState({
                recurrenceOccurrenceAmountStr: target.value
            });
        } else {
            this.changeRecurrenceOccurrenceAmount(target.value);
        }
    };

    handleRecurrenceOccurrenceAmountBlur = (event) => {
        const target = event.target;
        this.changeRecurrenceOccurrenceAmount(target.value);
    };

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <div className="recurrence">
                <PropertyDialog
                    classes="px800"
                    title={props.addMode ? Translations.AddRecurringTasks : Translations.Recurrence}
                    show={true}
                    body={<div>
                        <div className="workOrderRecurrenceContainer">
                            {props.addMode &&
                                <div className="addOptions">
                                    <div className="title subTitle">{props.addSourceIsTask ? Translations.ValuesCopiedFromWorkOrderTask : Translations.ValuesCopiedFromRecurringWork}</div>
                                    <div className="row pt-2">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="control-label smallFont">{Translations.WorkTitle}</label>
                                                <div className="readOnlyText">{props.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {!!props.description &&
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="control-label smallFont">{Translations.WorkDescription}</label>
                                                    <div className="textArea normal">{props.description}</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        {props.employeeIds.length > 0 &&
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label className="control-label smallFont">{props.employeeIds.length > 1 ? Translations.Employees : Translations.Employee}</label>
                                                    <div className="readOnlyText">{state.employeeNames}</div>
                                                </div>
                                            </div>
                                        }
                                        {props.vehicleIds.length > 0 &&
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label className="control-label smallFont">{props.vehicleIds.length > 1 ? Translations.Vehicles : Translations.Vehicle}</label>
                                                    <div className="readOnlyText">{state.vehicleNames}</div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            <div className="title subTitle">{Translations.WorkTime}</div>
                            <div className="row pt-2">
                                {!props.fullDay &&
                                    <div className="col-4">
                                        <div className="form-group">
                                            <label className="control-label smallFont">{Translations.StartTime}</label>
                                            <TimeSelector
                                                disabled={false}
                                                disabledValue={state.startTimeStr}
                                                date={state.startDateStr}
                                                value={state.startTimeStr}
                                                onChange={this.handleStartTimeChange}
                                            />
                                        </div>
                                    </div>
                                }
                                {!props.fullDay &&
                                    <div className="col-4">
                                        <div className="form-group">
                                            <label className="control-label smallFont">{Translations.EndDate}</label>
                                            <TimeSelector
                                                date={state.endDateStr}
                                                disabled={false}
                                                disabledValue={state.endTimeStr}
                                                startDate={state.startDateStr}
                                                startTime={state.startTimeStr}
                                                value={state.endTimeStr}
                                                onChange={this.handleEndTimeChange}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.RecurrenceDuration}</label>
                                        <select className="custom-select" name="duration" value={state.durationStr} onChange={this.handleDurationChange} disabled={false}>
                                            {state.durationItems.map((durationItem) =>
                                                <option key={durationItem.id} value={durationItem.id}>{durationItem.title}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="recurrencePatternContainer">
                            <div className="title subTitle">{Translations.RecurrencePattern}</div>
                            <div className="row pt-2">
                                <div className="form-group col-4">
                                    <Radio
                                        title={Translations.Daily}
                                        enabled={true}
                                        checked={EnumHelper.isEqual(state.recurrenceType, RecurrenceType.Daily)}
                                        onRadioClickBoolean={(value: boolean) => { if (value) { this.handleRecurrenceTypeChange(RecurrenceType.Daily); } } }
                                    />
                                    <Radio
                                        title={Translations.Weekly}
                                        enabled={true}
                                        checked={EnumHelper.isEqual(state.recurrenceType, RecurrenceType.Weekly)}
                                        onRadioClickBoolean={(value: boolean) => { if (value) { this.handleRecurrenceTypeChange(RecurrenceType.Weekly); } } }
                                    />
                                    <Radio
                                        title={Translations.Monthly}
                                        enabled={true}
                                        checked={EnumHelper.isEqual(state.recurrenceType, RecurrenceType.Monthly)}
                                        onRadioClickBoolean={(value: boolean) => { if (value) { this.handleRecurrenceTypeChange(RecurrenceType.Monthly); } } }
                                    />
                                    <Radio
                                        title={Translations.Yearly}
                                        enabled={true}
                                        checked={EnumHelper.isEqual(state.recurrenceType, RecurrenceType.Yearly)}
                                        onRadioClickBoolean={(value: boolean) => { if (value) { this.handleRecurrenceTypeChange(RecurrenceType.Yearly); } } }
                                    />
                                </div>
                                {EnumHelper.isEqual(state.recurrenceType, RecurrenceType.Daily) &&
                                    <div className="col-8 daily">
                                        <div className="row">
                                            <div className="col-2 pt-2 pr-0">
                                                <Radio
                                                    title={Translations.Every}
                                                    enabled={true}
                                                    checked={state.recurrenceIntervalDetail < 0.5}
                                                    onRadioClickBoolean={(value: boolean) => { if (value) { this.handleRecurrenceIntervalDetailChange(0); } } }
                                                />
                                            </div>
                                            <div className="col-2 pl-0 pr-0">
                                                <input type="number" className="form-control" name="recurrenceInterval" checked={false} onChange={this.handleRecurrenceIntervalChange} onBlur={this.handleRecurrenceIntervalBlur} value={state.recurrenceIntervalStr} />
                                            </div>
                                            <div className="col-8 pt-2">
                                                {Translations.DayInterval.toLocaleLowerCase()}
                                            </div>
                                        </div>
                                        <div className="pt-2">
                                            <Radio
                                                title={Translations.EveryWeekday}
                                                enabled={true}
                                                checked={state.recurrenceIntervalDetail >= 0.5}
                                                onRadioClickBoolean={(value: boolean) => { if (value) { this.handleRecurrenceIntervalDetailChange(1); } } }
                                            />
                                        </div>
                                    </div>
                                }
                                {EnumHelper.isEqual(state.recurrenceType, RecurrenceType.Weekly) &&
                                    <div className="col-8 weekly">
                                        <div className="row form-group">
                                            <div className="col-2 pt-2 pr-0">
                                                {Translations.Every}
                                            </div>
                                            <div className="col-2 pl-0 pr-0">
                                                <input type="number" className="form-control" name="recurrenceInterval" checked={false} onChange={this.handleRecurrenceIntervalChange} onBlur={this.handleRecurrenceIntervalBlur} value={state.recurrenceIntervalStr} />
                                            </div>
                                            <div className="col-8 pt-2">
                                                {Translations.WeekIntervalOn}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-auto">
                                                <CheckBox
                                                    title={Translations.Monday}
                                                    enabled={true}
                                                    checked={state.recurrenceIntervalDetail % 2 > 0.5}
                                                    onCheckboxClickBoolean={(value: boolean) => { this.handleRecurrenceIntervalDetailWeekChange(0, value); } }
                                                />
                                            </div>
                                            <div className="col-auto">
                                                <CheckBox
                                                    title={Translations.Tuesday}
                                                    enabled={true}
                                                    checked={Math.floor(state.recurrenceIntervalDetail / 10) % 2 > 0.5}
                                                    onCheckboxClickBoolean={(value: boolean) => { this.handleRecurrenceIntervalDetailWeekChange(1, value); } }
                                                />
                                            </div>
                                            <div className="col-auto">
                                                <CheckBox
                                                    title={Translations.Wednesday}
                                                    enabled={true}
                                                    checked={Math.floor(state.recurrenceIntervalDetail / 100) % 2 > 0.5}
                                                    onCheckboxClickBoolean={(value: boolean) => { this.handleRecurrenceIntervalDetailWeekChange(2, value); } }
                                                />
                                            </div>
                                            <div className="col-auto">
                                                <CheckBox
                                                    title={Translations.Thursday}
                                                    enabled={true}
                                                    checked={Math.floor(state.recurrenceIntervalDetail / 1000) % 2 > 0.5}
                                                    onCheckboxClickBoolean={(value: boolean) => { this.handleRecurrenceIntervalDetailWeekChange(3, value); } }
                                                />
                                            </div>
                                            <div className="col-auto">
                                                <CheckBox
                                                    title={Translations.Friday}
                                                    enabled={true}
                                                    checked={Math.floor(state.recurrenceIntervalDetail / 10000) % 2 > 0.5}
                                                    onCheckboxClickBoolean={(value: boolean) => { this.handleRecurrenceIntervalDetailWeekChange(4, value); } }
                                                />
                                            </div >
                                            <div className="col-auto">
                                                <CheckBox
                                                    title={Translations.Saturday}
                                                    enabled={true}
                                                    checked={Math.floor(state.recurrenceIntervalDetail / 100000) % 2 > 0.5}
                                                    onCheckboxClickBoolean={(value: boolean) => { this.handleRecurrenceIntervalDetailWeekChange(5, value); } }
                                                />
                                            </div>
                                            <div className="col-auto">
                                                <CheckBox
                                                    title={Translations.Sunday}
                                                    enabled={true}
                                                    checked={Math.floor(state.recurrenceIntervalDetail / 1000000) % 2 > 0.5}
                                                    onCheckboxClickBoolean={(value: boolean) => { this.handleRecurrenceIntervalDetailWeekChange(6, value); } }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {EnumHelper.isEqual(state.recurrenceType, RecurrenceType.Monthly) &&
                                    <div className="col-8 monthly">
                                        <div className="row">
                                            <div className="col-2 pt-2">
                                                {Translations.Always}
                                            </div>
                                            <div className="col-2 pr-0">
                                                <input type="number" className="form-control" name="recurrenceInterval" checked={false} onChange={this.handleDayChange} onBlur={this.handleDayBlur} value={state.dayStr} />
                                            </div>
                                            <div className="col-2 pt-2">
                                                {Translations.OnDay}
                                            </div>
                                            <div className="col-2 pr-0">
                                                <input type="number" className="form-control" name="recurrenceInterval" checked={false} onChange={this.handleRecurrenceIntervalChange} onBlur={this.handleRecurrenceIntervalBlur} value={state.recurrenceIntervalStr} />
                                            </div>
                                            <div className="col pt-2">
                                                {Translations.MonthIntervalOn}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {EnumHelper.isEqual(state.recurrenceType, RecurrenceType.Yearly) &&
                                    <div className="col-8 yerly">
                                        <div className="row form-group">
                                            <div className="col-2 pt-2">
                                                {Translations.Always}
                                            </div>
                                            <div className="col-2 pr-0">
                                                <input type="number" className="form-control" name="recurrenceInterval" checked={false} onChange={this.handleRecurrenceIntervalChange} onBlur={this.handleRecurrenceIntervalBlur} value={state.recurrenceIntervalStr} />
                                            </div>
                                            <div className="col pt-2">
                                                {Translations.YearIntervalOn}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-2 pr-0">
                                                <input type="number" className="form-control" name="recurrenceInterval" checked={false} onChange={this.handleDayChange} onBlur={this.handleDayBlur} value={state.dayStr} />
                                            </div>
                                            <div className="col-2 pt-2 pr-0">
                                                {Translations.OnDay}
                                            </div>
                                            <div className="col-5">
                                                <select className="custom-select" name="month" value={state.monthStr} onChange={this.handleMonthChange} disabled={false}>
                                                    <option key={1} value={"1"}>{Translations.January}</option>
                                                    <option key={2} value={"2"}>{Translations.February}</option>
                                                    <option key={3} value={"3"}>{Translations.March}</option>
                                                    <option key={4} value={"4"}>{Translations.April}</option>
                                                    <option key={5} value={"5"}>{Translations.May}</option>
                                                    <option key={6} value={"6"}>{Translations.June}</option>
                                                    <option key={7} value={"7"}>{Translations.July}</option>
                                                    <option key={8} value={"8"}>{Translations.August}</option>
                                                    <option key={9} value={"9"}>{Translations.September}</option>
                                                    <option key={10} value={"10"}>{Translations.October}</option>
                                                    <option key={11} value={"11"}>{Translations.November}</option>
                                                    <option key={12} value={"12"}>{Translations.December}</option>
                                                </select>
                                            </div>
                                            <div className="col-3" />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="rangeOfRecurrenceContainer">
                            <div className="title subTitle">
                                {Translations.RangeOfRecurrence}
                            </div>
                            <div className="row pt-2">
                                <div className="col-1 pt-2">
                                    {Translations.StartDate}
                                </div>
                                <div className="col-3">
                                    <div className="form-group">
                                        <DatePicker
                                            disabled={false}
                                            required={true}
                                            value={state.startDateStr}
                                            onChange={this.handleStartDateChange}
                                            onBlur={this.handleStartDateBlur}
                                        />
                                    </div>
                                </div>
                                <div className="col-8">
                                    <div className="row form-group">
                                        <div className="col-5 pt-2">
                                            <Radio
                                                title={Translations.RecurrenceEndBy}
                                                enabled={true}
                                                checked={state.recurrenceEndByDate}
                                                onRadioClickBoolean={this.handleRecurrenceEndByDateChange}
                                            />
                                        </div>
                                        <div className="col-4">
                                            <DatePicker
                                                disabled={false}
                                                required={true}
                                                value={state.recurrenceEndDateStr}
                                                onChange={this.handleRecurrenceEndDateChange}
                                                onBlur={this.handleRecurrenceEndDateBlur}
                                            />
                                        </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className="col-5 pt-2">
                                            <Radio
                                                title={Translations.RecurrenceEndAfter}
                                                enabled={true}
                                                checked={!state.recurrenceEndByDate}
                                                onRadioClickBoolean={(value: boolean) => this.handleRecurrenceEndByDateChange(!value)}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <input type="number" className="form-control" name="recurrenceOccurrenceAmount" checked={false} onChange={this.handleRecurrenceOccurrenceAmountChange} onBlur={this.handleRecurrenceOccurrenceAmountBlur} value={state.recurrenceOccurrenceAmountStr} />
                                        </div>
                                        <div className="col-4 pt-2">
                                            {Translations.RecurrenceAfterOccurrences}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={
                        [
                            { title: (props.addMode ? Translations.Add : Translations.Save), classes: "btn-primary", enabled: true, onClick: this.handleSaveAndCloseEdit },
                            { title: (Translations.RemoveRecurrence), classes: "btn-default" + (props.recurrenceType > 0.5 ? "" : " d-none"), enabled: props.recurrenceType > 0.5, onClick: !props.addMode ? this.handleRemoveRecurrence : null }
                        ]
                    }
                    onClose={this.handleCancelAndCloseDialog}
                />
            </div>
        );
    }
}
