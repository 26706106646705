// SettingsCompetencyList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as competencyService from "../../services/competencyService";
import { ToolButton } from "../framework/toolButton";
import { Base } from "../../framework/base";
import { ListSearchFilter } from "../framework/listSearchFilter";
import { ListHeaderColumn } from "../framework/listHeaderColumn";
import { ToolTitle } from "../framework/toolTitle";
import * as StoreActions from "../../models/store/storeActions";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { ICompetencyItem } from "../../models/competency/competencyItem";
import { ICompetencyEdit } from "../../models/competency/competencyEdit";
import { SettingsCompetencyDialog } from "./settingsCompetencyDialog";
import { infiniteListPageSize } from "../../models/common/consts";
import { handleApiError } from "../../models/store/storeEffects";
import { CheckBox } from "../framework/checkbox";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import { AppUtils } from "../../models/common/appUtils";

// SettingsCompetencyListLineHeader
export interface ISettingsCompetencyListLineHeaderProp {
    sortColumn: string;
    sortOrderIsAsc: boolean;
    onColumnClick: (column: string) => void;
}

export class SettingsCompetencyListLineHeader extends React.Component<ISettingsCompetencyListLineHeaderProp, {}> {
    handleColumnClick = (column: string) => {
        this.props.onColumnClick(column);
    };

    render() {
        return (
            <div className="row title">
                <ListHeaderColumn
                    title="#"
                    column="number"
                    classes="col-1"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.CompetencyType}
                    column="competencyType"
                    classes="col-2"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Name}
                    column="name"
                    classes="col-3"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Description}
                    column="description"
                    classes="col-5"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.InUse}
                    column="activeState"
                    classes="col-1"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
            </div>
        );
    }
}

// SettingsCompetencyListLine
export interface ISettingsCompetencyListLineProp {
    item: ICompetencyItem;
    selectedId: string;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

export class SettingsCompetencyListLine extends React.Component<ISettingsCompetencyListLineProp, {}> {
    handleNumberClick = (e) => {
        this.props.onDoubleClick(this.props.item.id);
    };

    render() {
        const item = this.props.item;
        return (
            <div className={"row line" + (item.id === this.props.selectedId ? " selected" : "") + (item.activeState > 0 ? "" : " disabled")} onClick={() => { this.props.onClick(item.id); }} onDoubleClick={() => { this.props.onDoubleClick(item.id); }} >
                <div className="col-1 number ca" onClick={this.handleNumberClick}>{item.number.toString(10)}</div>
                <div className="col-2">{item.competencyType}</div>
                <div className="col-3">{item.name}</div>
                <div className="col-5">{item.description}</div>
                <div className="col-1 inUse ca">{item.activeState > 0 ? <div className="icon check" /> : ""}</div>
            </div>
        );
    }
}

// SettingsCompetencyList
export interface ISettingsCompetencyListProp {
}

interface ISettingsCompetencyListState {
    // List
    pageSize: number;
    page: number;
    hasMore: boolean;
    filter: string;
    sortColumn: string;
    sortOrderIsAsc: boolean;
    showNotInUse: boolean;
    items: ICompetencyItem[];

    // Edit
    selectedId: string;
    editItem: ICompetencyEdit;
    showEditDialog: boolean;
}

export class SettingsCompetencyList extends React.Component<ISettingsCompetencyListProp, ISettingsCompetencyListState> {
    private containerDiv: HTMLDivElement;
    private listDiv: HTMLDivElement;
    private isLoading: boolean;

    constructor(props) {
        super(props);
        this.isLoading = false;
        this.state = {
            pageSize: infiniteListPageSize, page: 1, hasMore: false, filter: "", sortColumn: "number", sortOrderIsAsc: true, showNotInUse: false, items: [], selectedId: null, editItem: null, showEditDialog: false
        };
    }

    searchItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean, showNotInUse: boolean, resetItems: boolean, refreshList: boolean, successCallback: () => void = null) => {
        const obj = this;
        obj.isLoading = true;
        store.customStore.dispatch(StoreActions.fetchStart());
        competencyService.getCompetencyItems(!refreshList ? pageSize : (page * infiniteListPageSize), !refreshList ? page : 1, filter, sortColumn, sortOrderIsAsc, showNotInUse)
            .then(competencyItems => {
                let items: ICompetencyItem[];
                if (!resetItems && !refreshList) {
                    const oldIds = {};
                    for (let j = 0; j < obj.state.items.length; j++) {
                        oldIds[obj.state.items[j].id] = true;
                    }
                    const oldItems = obj.state.items.slice(0);
                    const newItems = competencyItems.items.filter(i => Object.prototype.isPrototypeOf.call(oldIds, i.id) ? false : (oldIds[i.id] = true));
                    items = [...oldItems, ...newItems];
                } else {
                    items = competencyItems.items;
                }
                obj.setState({
                    page: !refreshList ? competencyItems.page : page,
                    hasMore: competencyItems.hasMore,
                    filter: filter,
                    sortColumn: sortColumn,
                    sortOrderIsAsc: sortOrderIsAsc,
                    showNotInUse: showNotInUse,
                    items: items
                });
                if (!Base.isNullOrUndefined(successCallback)) {
                    successCallback();
                }
            },
            error => {
                handleApiError(error, store.customStore.dispatch);
            }).finally(() => {
                obj.isLoading = false;
                store.customStore.dispatch(StoreActions.fetchEnd());
            });
    };

    refreshList = () => {
        this.searchItems(this.state.pageSize, this.state.page, this.state.filter, this.state.sortColumn, this.state.sortOrderIsAsc, this.state.showNotInUse, false, true);
    };

    handleScrollSub = Base.debounce((obj: SettingsCompetencyList) => {
        if (obj.isLoading || !obj.state.hasMore) return;
        if (obj.listDiv.offsetHeight - (obj.containerDiv.clientHeight + obj.containerDiv.scrollTop) < 5) {
            obj.searchItems(obj.state.pageSize, obj.state.page + 1, obj.state.filter, obj.state.sortColumn, obj.state.sortOrderIsAsc, this.state.showNotInUse, false, false);
        }
    }, 100);

    handleScroll = (event) => {
        this.handleScrollSub(this);
    };

    componentDidMount(): void {
        this.containerDiv.addEventListener("scroll", this.handleScroll);
        this.searchItems(this.state.pageSize, this.state.page, this.state.filter, this.state.sortColumn, this.state.sortOrderIsAsc, this.state.showNotInUse, false, false);
    }

    componentWillUnmount(): void {
        this.containerDiv.removeEventListener("scroll", this.handleScroll);
    }

    changeFilter = (filter: string) => {
        this.searchItems(this.state.pageSize, 1, filter, this.state.sortColumn, this.state.sortOrderIsAsc, this.state.showNotInUse, true, false);
    };

    changeSortColumn = (sortColumn: string) => {
        const oldSortColumn = this.state.sortColumn;
        const sortOrderIsAsc = oldSortColumn === sortColumn ? !this.state.sortOrderIsAsc : true;
        this.searchItems(this.state.pageSize, 1, this.state.filter, sortColumn, sortOrderIsAsc, this.state.showNotInUse, true, false);
    };

    changeShowNotInUse = (showNotInUse: boolean) => {
        if (showNotInUse === this.state.showNotInUse) return;
        this.searchItems(this.state.pageSize, 1, this.state.filter, this.state.sortColumn, this.state.sortOrderIsAsc, showNotInUse, true, false);
    };

    getEditItem = (id: string) => {
        const obj = this;
        competencyService.getCompetencyEdit(id).then(editItem => {
            obj.setState({
                showEditDialog: true,
                editItem: editItem
            });
        }).catch(e => {
            if (e.status && e.message) {
                AppUtils.showErrorMessage(e.message);
            }
        });
    };

    handleAdd = () => {
        this.getEditItem(Base.emptyGuid);
    };

    handleEdit = () => {
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        this.getEditItem(selectedId);
    };

    handleEditDialogOk = (id: string, name: string, email: string) => {
        this.setState({
            showEditDialog: false
        });
        this.refreshList();
    };

    handleEditDialogCancel = () => {
        this.setState({
            showEditDialog: false
        });
    };

    handleRemove = () => {
        const obj = this;
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        const item = this.state.items.find(i => i.id === selectedId);
        if (Base.isNullOrUndefined(item)) return;
        store.customStore.dispatch(StoreActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, String.format(Translations.CompetencyRemoveConfirmation, item.number), () => {
            store.customStore.dispatch(StoreActions.clearConfirmation());
            // Call server
            store.customStore.dispatch(StoreActions.fetchStart());
            competencyService.removeCompetency(selectedId)
                .then(success => {
                    store.customStore.dispatch(StoreActions.showSuccessMessage(success.message));
                    obj.refreshList(); // TODO UPDATE ONLY CHANGED CUSTOMER DATA
                })
                .catch(error => {
                    store.customStore.dispatch(StoreActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                    return null;
                })
                .finally(() => store.customStore.dispatch(StoreActions.fetchEnd()));
        }, () => {
            store.customStore.dispatch(StoreActions.clearConfirmation());
        }));
    };

    handleClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleDoubleClick = (id: string) => {
        this.getEditItem(id);
    };

    render() {
        const items = this.state.items;
        return (
            <div className="backgroundContent">
                <div className="row commandRow main">
                    <ToolTitle
                        title={Translations.Competencies}
                    />
                    <div className="col">
                        <ToolButton
                            title={Translations.Add}
                            enabled={true}
                            classes={"round left add"}
                            onClick={this.handleAdd}
                        />
                        <ToolButton
                            title={Translations.Edit}
                            enabled={!!this.state.selectedId}
                            classes={"round left edit"}
                            onClick={this.handleEdit}
                        />
                        <ToolButton
                            title={Translations.Remove}
                            enabled={!!this.state.selectedId}
                            classes={"round left remove"}
                            onClick={this.handleRemove}
                        />
                    </div>
                    <div className="col-auto right">
                        <CheckBox
                            classes="tool"
                            title={Translations.ShowNotInUse}
                            enabled={true}
                            checked={this.state.showNotInUse}
                            onCheckboxClickBoolean={(checked: boolean) => { this.changeShowNotInUse(checked); }}
                        />
                    </div>
                    <div className="col-auto right">
                        <ListSearchFilter
                            searchFilter={this.state.filter}
                            onSearchClick={this.changeFilter}
                        />
                    </div>
                </div>
                <div>
                    <SettingsCompetencyListLineHeader
                        sortColumn={this.state.sortColumn}
                        sortOrderIsAsc={this.state.sortOrderIsAsc}
                        onColumnClick={this.changeSortColumn}
                    />
                </div>
                <div className="listContainer main competencysContainer" ref={(elem) => { this.containerDiv = elem; }}>
                    <div className="list competencys striped">
                        <div className="lineContainer" ref={(elem) => { this.listDiv = elem; }}>
                            {items.map((item) =>
                                <SettingsCompetencyListLine
                                    key={item.id}
                                    item={item}
                                    selectedId={this.state.selectedId}
                                    onClick={this.handleClick}
                                    onDoubleClick={this.handleDoubleClick}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {this.state.showEditDialog &&
                    <SettingsCompetencyDialog
                        editItem={this.state.editItem}
                        onOk={this.handleEditDialogOk}
                        onCancel={this.handleEditDialogCancel}
                    />
                }
            </div>
        );
    }
}