import { EmployeeListItemDto } from "../models/employee/employee";
import { ApiResponse, apiCall } from "./apiClient";

export const getEmployees = (salaryEmployeeGroupId?: string, signal?: AbortSignal): Promise<ApiResponse<EmployeeListItemDto[]>> => {
    return apiCall<EmployeeListItemDto[]>(
        `Employees${
            salaryEmployeeGroupId
                ? `?salaryEmployeeGroupId=${encodeURIComponent(
                      salaryEmployeeGroupId
                  )}`
                : ""
        }`,
        "GET",
        null,
        signal
    );
};

export const setEmployeeUiState = (key: string, value: string): Promise<ApiResponse<void>> => {
    return apiCall<void>("Employees/UiState", "POST", { key, value });
};

export const getEmployeeUiState = (key: string): Promise<ApiResponse<unknown>> => {
    return apiCall<unknown>("Employees/UiState", "GET", { key });
};
