import { IconButton, Typography, Chip, Box, Button, Tooltip } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TransportSideBarEntitiesContainer } from "./sidebar/transportSidebarentitiesContainer";
import { transportOrderPickupAddress, transportDateTimeRange, transportOrderDeliveryAddress, getCreatedDateTime, getEmployeeName } from "./transportUtils";
import EditIcon from "@mui/icons-material/Edit";
import { TransportOrderInvoicingState, TransportOrderListItemDto, TransportOrderState } from "../../../models/transport/transportOrder";
import { ParcelListItemDto, ParcelTypes } from "../../../models/transport/parcel";
import { showApiError, showApiSuccess } from "../../framework/formUtils";
import { RootState, useAppDispatch, useAppSelector } from "../../../framework/customStore";
import { customHistory } from "../../../framework/customHistory";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { createTransportOrder } from "../../../services/transportOrderService";
import { getParcelsForOrder, postParcel } from "../../../services/parcelService";
import { getCopyDataToTransportOrder } from "../transportTimeline/Utils";
import { TransportOrderInvoicingStateButton } from "../transportOrder/tranportOrderInvoicingStateButton";
import { TransportOrderAction } from "../transportTimeline/TransportOrderAction";
import { fetchEmployees } from "../../../store/employeeSlice";
import { setOrdersDataChanged } from "../../../store/transport/transportOrdersSlice";
import { setSideBarOrderDetails } from "../../../store/transport/transportVehiclesSlice";

interface ISidebarOrderDetailsProps {
    sidebarOrdersDataChanged?: boolean;
    setSidebarOrdersDataChanged?: React.Dispatch<React.SetStateAction<boolean>>;
    transportOrdersView?: boolean;
}

export const SidebarOrderDetails = ({ sidebarOrdersDataChanged, setSidebarOrdersDataChanged, transportOrdersView = false }: ISidebarOrderDetailsProps) => {
    const dispatch = useAppDispatch();
    const [parcels, setParcels] = useState<ParcelListItemDto[]>([]);
    const [loading, setLoading] = useState(false);
    const order = useAppSelector((state: RootState) => state.transportVehicles.sideBarOrderDetails as TransportOrderListItemDto);
    const ordersDataChanged = useAppSelector((state: RootState) => state.transportOrders.ordersDataChanged);
    const orderId = order?.id;
    const parcelTypeOptions = Object.keys(ParcelTypes).map((p, i) => { return { value: `${i}`, label: p }; });
    const employees = useAppSelector((state) => state.employees.employees);
    const employeeId = useAppSelector(state => state.user.employeeId);

    useEffect(() => {
        if (order) {
            setLoading(true);
            getParcelsForOrder(order.id)
                .then(res => {
                    setParcels(res.data);
                })
                .catch(showApiError)
                .finally(() => setLoading(false));
        }
    }, [orderId]);

    useEffect(() => {
        if(employees.length === 0) {
            dispatch(fetchEmployees() as any);
        }
    }, [employees]);

    const handleCreateCopy = () => {
        const copyData = getCopyDataToTransportOrder(order);
        createTransportOrder({ ...copyData, createdEmployeeId: employeeId })
            .then(res => {
                if (res) {
                    if (parcels) {
                        parcels.map(parcel => {
                            const { id, transportOrderId, ...rest } = parcel;
                            postParcel(res.data.id, rest)
                                .catch((err) => {
                                    showApiError(`Kopion luominen epäonnistui. Virhe: ${err.message as string}`);
                                });
                        });
                    }
                    if (transportOrdersView) {
                        dispatch(setOrdersDataChanged(!ordersDataChanged));
                    } else {
                        setSidebarOrdersDataChanged(!sidebarOrdersDataChanged);
                    };
                    dispatch(setSideBarOrderDetails(res.data));
                    showApiSuccess("Kopion luominen onnistui");
                }
            })
            .catch((err) => {
                showApiError(`Kopion luominen epäonnistui. Virhe: ${err.message as string}`);
            });
    };

    if (!order) return null;

    return (
        <Stack spacing={2} sx={{ width: "100%" }} fontSize="small">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h2">Kuljetustilauksen lisätiedot</Typography>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Tooltip title={"Kopioi uudeksi kuljetustilaukseksi"} >
                        <IconButton size="small" onClick={handleCreateCopy} key="btn-copy">
                            <ContentCopyOutlinedIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    {order.invoicingState !== TransportOrderInvoicingState.InvoicedInternally &&
                            order.invoicingState !== TransportOrderInvoicingState.InvoicedExternally &&
                            <Tooltip title="Muokkaa">
                                <Link to={{ pathname: `/transport/orders/${order.id}`, state: { fromPath: transportOrdersView ? "/transport/orders" : "/transport/vehicles" } }}>
                                    <IconButton color="primary">
                                        <EditIcon />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                    }
                </Box>
                {/* <Typography variant="h2">{order.name}</Typography> */}
                {/*TODO what to do with rahtikirja?*/}
                {/*<Button sx={{ marginLeft: "auto" }} variant="primary">Rahtikirja</Button>*/}
            </Stack>

            <div className="font-weight-bold">{order.name}</div>
            <TransportOrderAction order={order} transportOrdersView={transportOrdersView} />
            <div className="font-weight-bold">Tilausnumero:</div>
            #{order.orderNumber}

            {order.waybillNumber &&
                <>
                    <div className="font-weight-bold">Rahtikirjan numero:</div>
                    {order.waybillNumber}
                </>
            }

            {(order.invoicingState !== TransportOrderInvoicingState.NotInvoiceable && order.state === TransportOrderState.Delivered) &&
                <>
                    <div className="font-weight-bold">Laskutustiedot:</div>
                    <Box mt={"0 !important"} display="flex" alignItems="center" justifyContent="space-between">
                        {order.invoicingState === TransportOrderInvoicingState.Invoiceable
                            ? "Kuljetustilausta ei ole laskutettu."
                            : `Kuljetustilaus on ${order.invoicingState === TransportOrderInvoicingState.AddedToInvoice ? "laskulla" : "laskutettu"}`}
                        <Box display="flex" justifyContent="right">
                            <TransportOrderInvoicingStateButton order={order} transportOrdersView={transportOrdersView} />
                        </Box>
                        {(order.invoicingState === TransportOrderInvoicingState.AddedToInvoice ||
                        order.invoicingState === TransportOrderInvoicingState.InvoicedInternally) && (
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={() => customHistory.push(`/invoicingbeta/transportorderinvoice/${order.invoiceNewId}`, { from: transportOrdersView ? "/transport/orders" : "/transport/vehicles", state: transportOrdersView })}
                            >
                                Siirry laskulle
                            </Button>
                        )}
                    </Box>
                </>
            }

            {order.senderName &&
                <>
                    <div className="font-weight-bold">Lähettäjä</div>
                    {order.senderName}
                </>
            }

            {order.receiverName &&
                <>
                    <div className="font-weight-bold">Vastaanottaja</div>
                    {order.receiverName}
                </>
            }

            {!loading &&
                <div>
                    <div className="font-weight-bold">Kuljetusyksiköt:</div>
                    {parcels?.length === 0 && <div>Ei kuljetusyksiköitä</div>}
                    {parcels?.length > 0 && (
                        parcels.map(p => (
                            <Grid2 key={p.id} container direction="row" spacing={2}>
                                <Grid2 xs={6}>
                                    <TransportSideBarEntitiesContainer>
                                        {parcelTypeOptions.filter(t => t.value === `${p.parcelType}`)?.[0]?.label}
                                    </TransportSideBarEntitiesContainer>
                                </Grid2>
                                <Grid2 xs={3}>
                                    <TransportSideBarEntitiesContainer>
                                        {p.amount ?? 0} kpl
                                    </TransportSideBarEntitiesContainer>
                                </Grid2>
                                <Grid2 xs={3}>
                                    <TransportSideBarEntitiesContainer>
                                        {p.weight ?? 0} kg
                                    </TransportSideBarEntitiesContainer>
                                </Grid2>
                                {/*TODO height, width, length?*/}
                            </Grid2>
                        ))
                    )}
                </div>
            }

            <div>
                <div className="font-weight-bold mb-1 flex align-items-center">
                    <Chip size="small" color="primary" label="1" sx={{ mr: 1 }} />
                    <span>Noutopiste</span>
                </div>

                <TransportSideBarEntitiesContainer>
                    <Stack spacing={1}>
                        {(order.pickUpAddress || order.pickUpZipCode || order.pickUpCity) &&
                            <div className="font-weight-bold">{transportOrderPickupAddress(order)}</div>
                        }
                        <div>
                            <div>Noutoaika:</div>
                            <div className="font-weight-bold">{transportDateTimeRange(order.pickUpStartDateTime, order.pickUpEndDateTime)}</div>
                        </div>
                        {order.pickUpDetails &&
                            <div>
                                <div>Lisätiedot:</div>
                                <div className="font-weight-bold">{order.pickUpDetails}</div>
                            </div>
                        }
                    </Stack>
                </TransportSideBarEntitiesContainer>
            </div>

            <div>
                <div className="font-weight-bold mb-1 flex align-items-center">
                    <Chip size="small" color="primary" label="2" sx={{ mr: 1 }} />
                    <span>Toimituspiste</span>
                </div>
                <TransportSideBarEntitiesContainer>
                    <Stack spacing={1}>
                        {(order.deliveryAddress || order.deliveryZipCode || order.deliveryCity) &&
                            <div className="font-weight-bold">{transportOrderDeliveryAddress(order)}</div>
                        }
                        <div>
                            <div>Toimitusaika:</div>
                            <div className="font-weight-bold">{transportDateTimeRange(order.deliveryStartDateTime, order.deliveryEndDateTime)}</div>
                        </div>
                        {order.deliveryDetails &&
                            <div>
                                <div>Lisätiedot:</div>
                                <div className="font-weight-bold">{order.deliveryDetails}</div>
                            </div>
                        }
                    </Stack>
                </TransportSideBarEntitiesContainer>
            </div>

            <div>
                <div className="font-weight-bold">Kuljettajan kommentit</div>
                <TransportSideBarEntitiesContainer>
                    {order.driverComments ?? "-"}
                </TransportSideBarEntitiesContainer>
            </div>
            <div className="font-weight-bold">Luotu:</div>
            {getCreatedDateTime(order?.createdDateTime)}
            <div className="font-weight-bold">Luonut:</div>
            {getEmployeeName(order?.createdEmployeeId, employees)}
        </Stack>
    );
};
