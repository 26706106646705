import * as React from "react";
import * as store from "../../framework/customStore";
import * as baseService from "../../services/baseService";
import * as workOrderService from "../../services/workOrderService";
import * as StoreActions from "../../models/store/storeActions";
import { infiniteListPageSize } from "../../models/common/consts";
import { Base } from "../../framework/base";
import { handleApiError } from "../../models/store/storeEffects";
import { IIdTitle } from "../../models/common/idTitle";
import { IWorkOrderEdit } from "../../models/work/workOrderEdit";
import { Translations } from "../../models/translations";
import { WorkOrderDialogConnected } from "./workOrderDialog";
import { IWorkOrderInvoiceDataListItem } from "../../models/work/workOrderInvoiceDataListItem";
import { WorkInvoiceDataMainReadyWorkOrkdersList } from "./workInvoiceDataMainReadyWorkOrkdersList";
import { WorkOrderState, TimeFormat, EnumHelper } from "../../models/common/enums";
import { WorkOrderDetailsView, WorkOrderDetailsViewMode } from "./workOrderDetailsView";
import { WorkOrderOperations } from "../../models/work/workOrderOperations";
import { EmployeeParametersHelper, IEmployeeParameters } from "../../models/employee/employeeIParameters";
import { WorkInvoiceDataMainToBeInvoicedWorkOrkdersList } from "./workInvoiceDataMainToBeInvoicedWorkOrkdersList";
import { InvoiceCreateInvoicesDialog } from "../invoice/invoiceCreateInvoicesDialog";
import { WorkOrderCopyDialog } from "./workOrderCopyDialog";

export interface IWorkInvoiceDataMainProp {
    workTimeFormat: TimeFormat;
    employeeParameters: IEmployeeParameters;
    workOrderStates: IIdTitle[];
    setEmployeeParameters: (parameters: IEmployeeParameters, saveToDb: boolean) => void;
}

interface IWorkInvoiceDataMainState {
    pageSize: number;
    isLoading: boolean;
    detailsViewExpanded: boolean;

    // Ready WorkOrders List
    readyItems: IWorkOrderInvoiceDataListItem[];
    readyPage: number;
    readyHasMore: boolean;
    readyFilter: string;
    readySortColumn: string;
    readySortOrderIsAsc: boolean;
    readySelectedId: string;
    readyCheckedIds: string[];
    readySelectedState: number;

    // To Be Invoiced WorkOrders List
    checkedItems: IWorkOrderInvoiceDataListItem[];
    checkedPage: number;
    checkedHasMore: boolean;
    checkedFilter: string;
    checkedSortColumn: string;
    checkedSortOrderIsAsc: boolean;
    checkedSelectedId: string;
    checkedCheckedIds: string[];
    checkedSelectedState: number;

    //DetailsView
    detailsLoading: boolean;
    detailsItem: IWorkOrderEdit;

    // WorkOrders Edit
    editItem: IWorkOrderEdit;
    showEditDialog: boolean;
    showCopyWorkOrderDialog: boolean;

    // Invoice
    showWorkOrderInvoiceDialog: boolean;
    invoiceItems: IWorkOrderInvoiceDataListItem[];

    // Employees
    selectedEmployeeId: string;

    // Resources
    selectedResourceId: string;
}

export class WorkInvoiceDataMain extends React.Component<IWorkInvoiceDataMainProp, IWorkInvoiceDataMainState> {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: infiniteListPageSize,
            isLoading: false,
            detailsItem: null,
            detailsLoading: false,
            detailsViewExpanded: true,
            readyItems: [],
            readyPage: 1,
            readyHasMore: false,
            readyFilter: "",
            readySortColumn: "number",
            readySortOrderIsAsc: true,
            readySelectedId: null,
            readyCheckedIds: [],
            readySelectedState: -1,
            checkedItems: [],
            checkedPage: 1,
            checkedHasMore: false,
            checkedFilter: "",
            checkedSortColumn: "number",
            checkedSortOrderIsAsc: true,
            checkedSelectedId: null,
            checkedCheckedIds: [],
            checkedSelectedState: -1,
            editItem: null,
            showEditDialog: false,
            showWorkOrderInvoiceDialog: false,
            showCopyWorkOrderDialog: false,
            invoiceItems: [],
            selectedEmployeeId: "",
            selectedResourceId: ""
        };
    }

    componentDidMount(): void {
        const state = this.state;
        this.searchWorkOrderInvoiceData(state.pageSize, state.readyPage, state.readyFilter, WorkOrderState.Done, state.readySortColumn, state.readySortOrderIsAsc, false, false);
        this.searchWorkOrderInvoiceData(state.pageSize, state.readyPage, state.readyFilter, WorkOrderState.Checked, state.readySortColumn, state.readySortOrderIsAsc, false, false);
    }

    // #region Details && Edit Load
    refreshWorkOrderDetails = (id: string) => {
        const obj = this;
        if (!id) return;
        this.setState({
            detailsLoading: true
        });
        workOrderService.getWorkOrderEdit(id, null, null, null)
            .then(workOrderEdit => {
                obj.setState({
                    detailsItem: workOrderEdit,
                });
            })
            .catch(error => {
                store.customStore.dispatch(StoreActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            })
            .finally(() => {
                this.setState({
                    detailsLoading: false
                });
            });
    };

    getWorkOrderEditItem = (id: string) => {
        const obj = this;
        if (!id) return;
        WorkOrderOperations.getWorkOrderEdit(id, null, null, null)
            .then(editItem => {
                obj.setState({
                    showEditDialog: true,
                    editItem: editItem,
                });
            });
    };
    // #endregion Details && Edit Load

    // #region List
    searchWorkOrderInvoiceData = (pageSize: number, page: number, filter: string, workOrderState: WorkOrderState, sortColumn: string, sortOrderIsAsc: boolean, resetItems: boolean, refreshList: boolean, successCallback: () => void = null): Promise<void> => {
        const obj = this;
        obj.setState({
            isLoading: true
        });
        store.customStore.dispatch(StoreActions.fetchStart());
        return workOrderService.getWorkOrderInvoiceDataListItems(!refreshList ? pageSize : (page * infiniteListPageSize), !refreshList ? page : 1, filter, [workOrderState], sortColumn, sortOrderIsAsc, EnumHelper.isEqual(workOrderState, WorkOrderState.Checked) ? false : null)
            .then(workOrderInvoiceDataItems => {
                if (EnumHelper.isEqual(workOrderState, WorkOrderState.Done)) {
                    const listItems = Base.getListItems<IWorkOrderInvoiceDataListItem>(obj.state.readyItems, obj.state.readySelectedId, obj.state.readyCheckedIds, workOrderInvoiceDataItems.items, resetItems, refreshList);
                    obj.setState({
                        readyItems: listItems.items,
                        readyPage: !refreshList ? workOrderInvoiceDataItems.page : page,
                        readyHasMore: workOrderInvoiceDataItems.hasMore,
                        readyFilter: filter,
                        readySortColumn: sortColumn,
                        readySortOrderIsAsc: sortOrderIsAsc,
                        readySelectedId: listItems.selectedId,
                        readyCheckedIds: listItems.checkedIds,
                        readySelectedState: this.getSelectedState(listItems.items, listItems.selectedId, listItems.checkedIds)
                    });
                } else {
                    const listItems = Base.getListItems<IWorkOrderInvoiceDataListItem>(obj.state.checkedItems, obj.state.checkedSelectedId, obj.state.checkedCheckedIds, workOrderInvoiceDataItems.items, resetItems, refreshList);
                    obj.setState({
                        checkedItems: listItems.items,
                        checkedPage: !refreshList ? workOrderInvoiceDataItems.page : page,
                        checkedHasMore: workOrderInvoiceDataItems.hasMore,
                        checkedFilter: filter,
                        checkedSortColumn: sortColumn,
                        checkedSortOrderIsAsc: sortOrderIsAsc,
                        checkedSelectedId: listItems.selectedId,
                        checkedCheckedIds: listItems.checkedIds,
                        checkedSelectedState: this.getSelectedState(listItems.items, listItems.selectedId, listItems.checkedIds)
                    });
                }
                if (!Base.isNullOrUndefined(successCallback)) {
                    successCallback();
                }
            },
            error => {
                handleApiError(error, store.customStore.dispatch);
            })
            .finally(() => {
                obj.setState({
                    isLoading: false
                });
                store.customStore.dispatch(StoreActions.fetchEnd());
            });
    };

    refreshLists = (workOrderStates: WorkOrderState[]): Promise<void> => {
        const state = this.state;
        const promises: Promise<void>[] = [];
        for (const workOrderState of workOrderStates) {
            if (EnumHelper.isEqual(workOrderState, WorkOrderState.Done)) {
                promises.push(this.searchWorkOrderInvoiceData(state.pageSize, state.readyPage, state.readyFilter, workOrderState, state.readySortColumn, state.readySortOrderIsAsc, false, true));
            } else {
                promises.push(this.searchWorkOrderInvoiceData(state.pageSize, state.checkedPage, state.checkedFilter, workOrderState, state.checkedSortColumn, state.checkedSortOrderIsAsc, false, true));
            }
        }
        return Promise.all(promises).then();
    };

    handleChangeWorkOrdersFilter = (filter: string, workOrderState: WorkOrderState) => {
        const state = this.state;
        const sortColumn = EnumHelper.isEqual(workOrderState, WorkOrderState.Done) ? state.readySortColumn : state.checkedSortColumn;
        const sortOrderIsAsc = EnumHelper.isEqual(workOrderState, WorkOrderState.Done) ? state.readySortOrderIsAsc : state.checkedSortOrderIsAsc;
        this.searchWorkOrderInvoiceData(state.pageSize, 1, filter, workOrderState, sortColumn, sortOrderIsAsc, true, false);
    };

    handleChangeWorkOrdersSortColumn = (sortColumn: string, workOrderState: WorkOrderState) => {
        const state = this.state;
        const filter = EnumHelper.isEqual(workOrderState, WorkOrderState.Done) ? state.readyFilter : state.checkedFilter;
        const oldSortColumn = EnumHelper.isEqual(workOrderState, WorkOrderState.Done) ? state.readySortColumn : state.checkedSortColumn;
        const oldSortOrderIsAsc = EnumHelper.isEqual(workOrderState, WorkOrderState.Done) ? state.readySortOrderIsAsc : state.checkedSortOrderIsAsc;
        const sortOrderIsAsc = oldSortColumn === sortColumn ? !oldSortOrderIsAsc : true;
        this.searchWorkOrderInvoiceData(state.pageSize, 1, filter, workOrderState, sortColumn, sortOrderIsAsc, true, false);
    };

    getSelectedState = (items: IWorkOrderInvoiceDataListItem[], id: string, checkedIds: string[]): number => {
        const selectedIds = Base.getSelectedIds(id, checkedIds);
        const selectedStates = new Set(items.reduce((result: number[], i) => {
            if (selectedIds.indexOf(i.id) >= 0) {
                result.push(i.state);
            }
            return result;
        }, []));
        return selectedStates.size === 1 ? selectedStates.values().next().value : -1;
    };

    handleChangeWorkOrdersColumnCheckbox = (column: string, checked: boolean, workOrderState: WorkOrderState) => {
        const state = this.state;
        if (EnumHelper.isEqual(workOrderState, WorkOrderState.Done)) {
            const result = Base.getIdsOnColumnCheckboxChange(state.readyItems, checked);
            this.setState({
                readySelectedId: result.selectedId,
                readyCheckedIds: result.checkedIds,
                readySelectedState: this.getSelectedState(state.readyItems, result.selectedId, result.checkedIds)
            });
        } else {
            const result = Base.getIdsOnColumnCheckboxChange(state.checkedItems, checked);
            this.setState({
                checkedSelectedId: result.selectedId,
                checkedCheckedIds: result.checkedIds,
                checkedSelectedState: this.getSelectedState(state.checkedItems, result.selectedId, result.checkedIds)
            });
        }
    };

    handleChangeWorkOrdersLineCheckbox = (id: string, checked: boolean, workOrderState: WorkOrderState) => {
        const state = this.state;
        if (EnumHelper.isEqual(workOrderState, WorkOrderState.Done)) {
            const result = Base.getIdsOnLineCheckboxChange(state.readySelectedId, state.readyCheckedIds, id, checked);
            this.setState({
                readySelectedId: result.selectedId,
                readyCheckedIds: result.checkedIds,
                readySelectedState: this.getSelectedState(state.readyItems, result.selectedId, result.checkedIds)
            });
        } else {
            const result = Base.getIdsOnLineCheckboxChange(state.checkedSelectedId, state.checkedCheckedIds, id, checked);
            this.setState({
                checkedSelectedId: result.selectedId,
                checkedCheckedIds: result.checkedIds,
                checkedSelectedState: this.getSelectedState(state.checkedItems, result.selectedId, result.checkedIds)
            });
        }
    };

    handleWorkOrderLineClick = (id: string, workOrderState: WorkOrderState) => {
        const state = this.state;
        if (!id) return;
        if (EnumHelper.isEqual(workOrderState, WorkOrderState.Done)) {
            this.setState({
                readySelectedId: id,
                readySelectedState: this.getSelectedState(state.readyItems, id, state.readyCheckedIds)
            });
        } else {
            this.setState({
                checkedSelectedId: id,
                checkedSelectedState: this.getSelectedState(state.checkedItems, id, state.checkedCheckedIds)
            });
        }
        this.refreshWorkOrderDetails(id);
    };

    handleWorkOrderLineDoubleClick = (id: string) => {
        this.getWorkOrderEditItem(id);
    };
    // #endregion List

    // #region Details and Lists
    refreshByIds = (ids: string[], refreshDetails: boolean = true, refreshListWorkOrderStates: WorkOrderState[] = []): Promise<void> => {
        const state = this.state;
        if (ids.length < 1) return;
        //Details
        if (refreshDetails) {
            const detailsId = state.detailsItem && state.detailsItem.workOrder ? state.detailsItem.workOrder.id : "";
            if (detailsId && ids.indexOf(detailsId) > -1) {
                this.refreshWorkOrderDetails(detailsId);
            }
        }
        //Lists
        const workOrderStates: WorkOrderState[] = refreshListWorkOrderStates ? refreshListWorkOrderStates.slice(0) : [];
        if (workOrderStates.indexOf(WorkOrderState.Done) < 0 && state.readyItems.filter(i => ids.indexOf(i.id) > -1).length) {
            workOrderStates.push(WorkOrderState.Done);
        }
        if (workOrderStates.indexOf(WorkOrderState.Checked) < 0 && state.checkedItems.filter(i => ids.indexOf(i.id) > -1).length) {
            workOrderStates.push(WorkOrderState.Checked);
        }
        this.refreshLists(workOrderStates); // TODO UPDATE ONLY CHANGED CUSTOMER DATA
    };

    refreshViewFromDetails = () => {
        const state = this.state;
        if (!state.detailsItem || !state.detailsItem.workOrder) return;
        this.refreshByIds([state.detailsItem.workOrder.id]);
    };
    // #endregion Details and Lists

    // #region Converting to and from Project
    handleConvertToProjectFromDetails = () => {
        const obj = this;
        const state = this.state;
        const workOrder = state.detailsItem ? state.detailsItem.workOrder : null;
        if (!workOrder) return;
        WorkOrderOperations.convertToProject(workOrder.isWork(), workOrder.isNew(), workOrder.id, (newId: string) => {
            obj.handleEditWorkOrderDialogOk(newId, false);
        });
    };

    handleDetachFromProjectFromDetails = () => {
        const obj = this;
        const state = this.state;
        const workOrder = state.detailsItem ? state.detailsItem.workOrder : null;
        if (!workOrder) return;
        WorkOrderOperations.detachFromProject(workOrder.isTask(), workOrder.isNew(), workOrder.parentId, workOrder.id, workOrder.parentTaskCount, () => {
            obj.handleEditWorkOrderDialogCancel();
        });
    };
    // #endregion Converting to and from Project

    // #region Canceling
    handleCancelWorkOrderFromDetails = () => {
        const obj = this;
        const state = this.state;
        const workOrder = state.detailsItem ? state.detailsItem.workOrder : null;
        if (!workOrder) return;
        WorkOrderOperations.cancelWorkOrder(workOrder.canceled, workOrder.id, () => {
            obj.handleEditWorkOrderDialogCancel();
        });
    };

    handleRestoreWorkOrderFromDetails = () => {
        const obj = this;
        const state = this.state;
        const workOrder = state.detailsItem ? state.detailsItem.workOrder : null;
        if (!workOrder) return;
        WorkOrderOperations.restoreWorkOrder(workOrder.canceled, workOrder.id, () => {
            obj.handleEditWorkOrderDialogCancel();
        });
    };
    // #endregion Canceling

    // #region Operations
    handlePrintWorkOrder = (workOrderState: WorkOrderState) => {
        const state = this.state;
        const workOrderId = EnumHelper.isEqual(workOrderState, WorkOrderState.Done) ? state.readySelectedId : state.checkedSelectedId;
        if (!workOrderId) return;
        WorkOrderOperations.printWorkOrder(workOrderId);
    };

    handlePrintWorkOrderFromDetails = () => {
        const state = this.state;
        if (!state.detailsItem || !state.detailsItem.workOrder) return;
        WorkOrderOperations.printWorkOrder(state.detailsItem.workOrder.id);
    };

    handleCopyWorkOrderFromDetails = () => {
        const state = this.state;
        if (!state.detailsItem || !state.detailsItem.workOrder) return;
        this.setState({
            showCopyWorkOrderDialog: true,
        });
    };

    handleCopyWorkOrderDialogOk = (workOrderEdit: IWorkOrderEdit) => {
        this.setState({
            showCopyWorkOrderDialog: false,
            showEditDialog: true,
            editItem: workOrderEdit,
        });
    };

    handleCopyWorkOrderDialogCancel = () => {
        this.setState({
            showCopyWorkOrderDialog: false,
        });
    };

    handleEditWorkOrder = (workOrderState: WorkOrderState) => {
        const state = this.state;
        const workOrderId = EnumHelper.isEqual(workOrderState, WorkOrderState.Done) ? state.readySelectedId : state.checkedSelectedId;
        if (!workOrderId) return;
        this.getWorkOrderEditItem(workOrderId);
    };

    handleEditWorkOrderDialogOk = (openWorkOrderId: string, makeProductBookingsOnOpen: boolean) => {
        const obj = this;
        const state = this.state;
        this.setState({
            showEditDialog: false
        });
        const editId = state.editItem && state.editItem.workOrder ? state.editItem.workOrder.id : "";
        if (!editId) return;
        this.refreshByIds([editId]).then(() => {
            if (openWorkOrderId) {
                obj.getWorkOrderEditItem(openWorkOrderId);
            }
        });
    };

    handleEditWorkOrderDialogCopied = (workOrderEdit: IWorkOrderEdit) => {
        const obj = this;
        const state = this.state;
        this.setState({
            showEditDialog: false
        });
        const editId = state.editItem && state.editItem.workOrder ? state.editItem.workOrder.id : "";
        if (!editId) return;
        this.refreshByIds([editId]).then(() => {
            obj.setState({
                showEditDialog: true,
                editItem: workOrderEdit,
            });
        });
    };

    handleEditWorkOrderDialogCancel = () => {
        const state = this.state;
        this.setState({
            showEditDialog: false
        });
        const editId = state.editItem && state.editItem.workOrder ? state.editItem.workOrder.id : "";
        if (!editId) return;
        this.refreshByIds([editId]);
    };

    setWorkOrdersState = (ids: string[], newState: number, refreshListWorkOrderStates: WorkOrderState[] = []) => {
        const obj = this;
        if (ids.length < 1) return;
        WorkOrderOperations.setWorkOrderState(ids, newState, () => {
            obj.refreshByIds(ids, true, refreshListWorkOrderStates);
        });
    };

    handleSetWorkOrderStateFromDetails = (newState: number) => {
        const state = this.state;
        if (!state.detailsItem || !state.detailsItem.workOrder) return;
        const refreshListWorkOrderStates: WorkOrderState[] = [];
        if (EnumHelper.isEqual(state.detailsItem.workOrder.state, WorkOrderState.Done) || EnumHelper.isEqual(newState, WorkOrderState.Done)) {
            refreshListWorkOrderStates.push(WorkOrderState.Done);
        }
        if (EnumHelper.isEqual(state.detailsItem.workOrder.state, WorkOrderState.Checked) || EnumHelper.isEqual(newState, WorkOrderState.Checked)) {
            refreshListWorkOrderStates.push(WorkOrderState.Checked);
        }
        this.setWorkOrdersState([state.detailsItem.workOrder.id], newState, refreshListWorkOrderStates);
    };

    handleAddToFavorites = () => {
        const selectedId = this.state.readySelectedId;
        if (!selectedId) return;
        const props = this.props;
        props.setEmployeeParameters(EmployeeParametersHelper.addToFavoriteWorkOrders(props.employeeParameters, selectedId), true);
    };

    handleRemoveFromFavorites = () => {
        const selectedId = this.state.readySelectedId;
        if (!selectedId) return;
        const props = this.props;
        props.setEmployeeParameters(EmployeeParametersHelper.removeFromFavoriteWorkOrders(props.employeeParameters, selectedId), true);
    };

    handleApproveWorkOrdersToBeInvoiced = () => {
        const obj = this;
        const state = this.state;
        const selectedIds = Base.getSelectedIds(state.readySelectedId, state.readyCheckedIds);
        if (selectedIds.length < 1) return;
        obj.setWorkOrdersState(selectedIds, WorkOrderState.Checked, [WorkOrderState.Checked]);
    };

    handleInvoiceWorkOrder = () => {
        const state = this.state;
        const selectedIds = Base.getSelectedIds(state.checkedSelectedId, state.checkedCheckedIds);
        if (selectedIds.length < 1) return;
        const invoiceItems = state.checkedItems.filter(i => selectedIds.indexOf(i.id) > -1 && i.invoices.length < 1);
        if (invoiceItems.length < 1) return;
        this.setState({
            showWorkOrderInvoiceDialog: true,
            invoiceItems: invoiceItems
        });
    };

    handleWorkOrderInvoiceDialogCancel = () => {
        this.setState({
            showWorkOrderInvoiceDialog: false
        });
        this.refreshByIds(this.state.invoiceItems.map(i => i.id));
    };

    handleRemoveWorkOrderFromDetails = () => {
        const obj = this;
        const state = this.state;
        const detailsId = state.detailsItem && state.detailsItem.workOrder ? state.detailsItem.workOrder.id : "";
        if (!detailsId) return;
        const item = state.readyItems.find(i => i.id === detailsId) ?? state.checkedItems.find(i => i.id === detailsId);
        if (!item) return;
        const ids = [];
        let isProject = false;
        let hasChildren = false;
        let acknowledgementSent = false;
        let number = 0;
        ids.push(item.id);
        number = item.number;
        if (item.isProject()) {
            isProject = true;
            if (item.projectTasks.length > 0) {
                hasChildren = true;
            }
        }
        if (item.acknowledgementSent) {
            acknowledgementSent = true;
        }
        WorkOrderOperations.removeWorkOrder(isProject, ids, number, hasChildren, acknowledgementSent, () => {
            obj.refreshByIds(ids, false);
            obj.setState({
                detailsItem: null
            });
        });
    };

    handleSetDurationByEstimatedHoursFromDetails = () => {
        const obj = this;
        const state = this.state;
        const detailsId = state.detailsItem && state.detailsItem.workOrder ? state.detailsItem.workOrder.id : "";
        if (!detailsId) return;
        WorkOrderOperations.setWorkOrderDuration([detailsId], null, () => {
            obj.refreshByIds([detailsId], true);
        });
    };
    // #endregion Operations

    handleChangeExpansionMode = (expanded: boolean) => {
        this.setState({
            detailsViewExpanded: expanded
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <div>
                <div className="row workListMain">
                    <div className={state.detailsViewExpanded ? "col-9" : "col-12 withCollapsedDrawer"}>
                        <div className="backgroundBoard">
                            <div className="backgroundContent">
                                <WorkInvoiceDataMainReadyWorkOrkdersList
                                    workTimeFormat={props.workTimeFormat}
                                    pageSize={state.pageSize}
                                    page={state.checkedPage}
                                    hasMore={state.readyHasMore}
                                    filter={state.readyFilter}
                                    sortColumn={state.readySortColumn}
                                    sortOrderIsAsc={state.readySortOrderIsAsc}
                                    items={state.readyItems}
                                    workOrderStates={props.workOrderStates}
                                    state={WorkOrderState.Done}
                                    selectedId={state.readySelectedId}
                                    selectedState={state.readySelectedState}
                                    checkedIds={state.readyCheckedIds}
                                    isLoading={state.isLoading}
                                    searchItems={this.searchWorkOrderInvoiceData}
                                    onChangeFilter={this.handleChangeWorkOrdersFilter}
                                    onChangeSortColumn={this.handleChangeWorkOrdersSortColumn}
                                    onChangeColumnCheckbox={this.handleChangeWorkOrdersColumnCheckbox}
                                    onChangeLineCheckbox={this.handleChangeWorkOrdersLineCheckbox}
                                    onLineClick={this.handleWorkOrderLineClick}
                                    onLineDoubleClick={this.handleWorkOrderLineDoubleClick}
                                    onEdit={this.handleEditWorkOrder}
                                    onPrint={this.handlePrintWorkOrder}
                                    onApproveToBeInvoiced={this.handleApproveWorkOrdersToBeInvoiced}
                                />
                                <WorkInvoiceDataMainToBeInvoicedWorkOrkdersList
                                    workTimeFormat={props.workTimeFormat}
                                    pageSize={state.pageSize}
                                    page={state.checkedPage}
                                    hasMore={state.checkedHasMore}
                                    filter={state.checkedFilter}
                                    sortColumn={state.checkedSortColumn}
                                    sortOrderIsAsc={state.checkedSortOrderIsAsc}
                                    items={state.checkedItems}
                                    workOrderStates={props.workOrderStates}
                                    state={WorkOrderState.Checked}
                                    selectedId={state.checkedSelectedId}
                                    selectedState={state.checkedSelectedState}
                                    checkedIds={state.checkedCheckedIds}
                                    isLoading={state.isLoading}
                                    searchItems={this.searchWorkOrderInvoiceData}
                                    onChangeFilter={this.handleChangeWorkOrdersFilter}
                                    onChangeSortColumn={this.handleChangeWorkOrdersSortColumn}
                                    onChangeColumnCheckbox={this.handleChangeWorkOrdersColumnCheckbox}
                                    onChangeLineCheckbox={this.handleChangeWorkOrdersLineCheckbox}
                                    onLineClick={this.handleWorkOrderLineClick}
                                    onLineDoubleClick={this.handleWorkOrderLineDoubleClick}
                                    onEdit={this.handleEditWorkOrder}
                                    onPrint={this.handlePrintWorkOrder}
                                    onInvoice={this.handleInvoiceWorkOrder}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={state.detailsViewExpanded ? "col-3" : "collapsedDrawer"}>
                        <div className="backgroundBoard">
                            <WorkOrderDetailsView
                                workTimeFormat={props.workTimeFormat}
                                title={Translations.SelectedOrder}
                                employeeParameters={props.employeeParameters}
                                viewMode={WorkOrderDetailsViewMode.InvoiceData}
                                item={state.detailsItem}
                                expanded={state.detailsViewExpanded}
                                isLoading={state.detailsLoading}
                                onOpenWorkOrder={this.handleWorkOrderLineDoubleClick}
                                onPrint={this.handlePrintWorkOrderFromDetails}
                                onCopyWorkOrder={this.handleCopyWorkOrderFromDetails}
                                onRefresh={this.refreshViewFromDetails}
                                onRemove={this.handleRemoveWorkOrderFromDetails}
                                convertToProject={this.handleConvertToProjectFromDetails}
                                detachFromProject={this.handleDetachFromProjectFromDetails}
                                onCancelWorkOrder={this.handleCancelWorkOrderFromDetails}
                                onRestoreWorkOrder={this.handleRestoreWorkOrderFromDetails}
                                onChangeExpansionMode={this.handleChangeExpansionMode}
                                onStateChange={this.handleSetWorkOrderStateFromDetails}
                                onAddToFavorites={this.handleAddToFavorites}
                                onRemoveFromFavorites={this.handleRemoveFromFavorites}
                                onSetDurationByEstimatedHours={this.handleSetDurationByEstimatedHoursFromDetails}
                                onAddEmployeeToWorkOrder={null}
                                onAddVehicleToWorkOrder={null}
                                setEmployeeParameters={props.setEmployeeParameters}
                            />
                        </div>
                    </div>
                </div>
                {state.showEditDialog &&
                    <WorkOrderDialogConnected
                        workOrderEdit={state.editItem}
                        makeProductBookingsOnOpen={false}
                        onOk={this.handleEditWorkOrderDialogOk}
                        onCopied={this.handleEditWorkOrderDialogCopied}
                        onCancel={this.handleEditWorkOrderDialogCancel}
                    />
                }
                {state.showWorkOrderInvoiceDialog &&
                    <InvoiceCreateInvoicesDialog
                        items={state.invoiceItems}
                        sortColumn={state.checkedSortColumn}
                        sortOrderIsAsc={state.checkedSortOrderIsAsc}
                        onCancel={this.handleWorkOrderInvoiceDialogCancel}
                    />
                }
                {state.showCopyWorkOrderDialog &&
                    <WorkOrderCopyDialog
                        workOrderId={state.detailsItem.workOrder.id}
                        number={state.detailsItem.workOrder.number}
                        parentNumber={state.detailsItem.workOrder.parentNumber}
                        name={state.detailsItem.workOrder.name}
                        isProject={state.detailsItem.workOrder.isProject()}
                        isRecurring={state.detailsItem.workOrder.isRecurring()}
                        isWork={state.detailsItem.workOrder.isWork()}
                        hasEmployees={state.detailsItem.workOrder.employeeIds.length > 0}
                        hasVehicles={state.detailsItem.workOrder.vehicleIds.length > 0}
                        hasRoute={state.detailsItem.workOrder.routePoints.length > 0}
                        hasBookings={state.detailsItem.workOrder.hourBookings.length + state.detailsItem.workOrder.productBookings.length + state.detailsItem.workOrder.rideBookings.length > 0}
                        hasDocuments={state.detailsItem.workOrder.documents.length > 0}
                        hasTasks={state.detailsItem.workOrder.projectTasks.length > 0}
                        onOk={this.handleCopyWorkOrderDialogOk}
                        onCancel={this.handleCopyWorkOrderDialogCancel}
                    />
                }

            </div>
        );
    }
}
