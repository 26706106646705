/* global JSX */
// TimeSelector
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import { IIdTitle, IdTitle } from "../../models/common/idTitle";
import { Base } from "../../framework/base";
import { ToolButton } from "./toolButton";
import { AppUtils } from "../../models/common/appUtils";

export interface ITimeSelectorProp {
    autoFocus?: boolean;
    classes?: string;
    date?: string;
    disabled?: boolean;
    disabledValue?: string;
    interval?: number;
    placeholder?: string;
    required?: boolean;
    title?: string | JSX.Element;
    titleClasses?: string;
    startDate?: string;
    startTime?: string;
    value: string;
    onChange(value: string);
}

export interface ITimeSelectorState {
    value: string;
    values: IIdTitle[];
}

export class TimeSelector extends React.Component<ITimeSelectorProp, ITimeSelectorState> {
    private selectListDiv: HTMLDivElement;
    private selectListCloseTime: number;

    private addTimeToValues = (values: IIdTitle[], minutes: number, startTimeMinutes: number, time: Date, startTime: Date, date: Date, startDateTime: Date) => {
        const oneDayMinutes = 24 * 60;
        const value = time.getHours().toString(10) + "." + Base.leftPad(time.getMinutes().toString(10), 2);
        let suffix = "";
        if (startTime && date) {
            const duration = startDateTime
                ? Base.dateDiffInMinutes(startDateTime, date.addMinutes(minutes))
                : (minutes >= startTimeMinutes ? minutes : 24 * 60 + minutes) - startTimeMinutes;
            suffix = duration > 0 && duration < oneDayMinutes ? " (" + AppUtils.getDurationStrByDurationMin(duration) + ")" : "";
        }
        values.push(IdTitle.createIdTitle(value, value + suffix));
    };

    private getValues = (props: ITimeSelectorProp): IIdTitle[] => {
        const startDate = props.startDate ? props.startDate.toDate() : null;
        const startTime = props.startTime ? props.startTime.toTime() : null;
        const startTimeMinutes = startTime ? 60 * startTime.getHours() + startTime.getMinutes() : 0;
        const startDateTime = startDate && startTime ? startDate.addMinutes(startTimeMinutes) : null;
        const date = props.date ? props.date.toDate() : null;
        const timeValue = props.value ? props.value.toTime() : Base.getNowDate();
        const equalDates = startDate && date && startDate.getTime() === date.getTime();
        let time = startTime && equalDates ? startTime : "0.00".toTime();
        const interval = props.interval ?? 30;
        const values: IIdTitle[] = [];
        for (let i = 0; i < 24 * 60 / interval; i++) {
            const minutes = 60 * time.getHours() + time.getMinutes();
            //Add interval
            this.addTimeToValues(values, minutes, startTimeMinutes, time, startTime, date, startDateTime);
            //Add current value that does not fit in interval
            const valueMinutes = timeValue.getHours() * 60 + timeValue.getMinutes();
            if (valueMinutes > minutes && valueMinutes < minutes + interval) {
                this.addTimeToValues(values, valueMinutes, startTimeMinutes, time, startTime, date, startDateTime);
            }
            //Next time
            time = time.addMinutes(interval);
        }
        return values;
    };

    constructor(props: ITimeSelectorProp) {
        super(props);
        this.state = { value: props.value, values: this.getValues(props) };
    }

    componentDidUpdate(prevProps: ITimeSelectorProp, prevState: ITimeSelectorState): void {
        const props = this.props;
        if (prevProps.startDate === props.startDate && prevProps.startTime === props.startTime && prevProps.date === props.date && prevProps.value === props.value) return;
        const prevValue = prevProps.value;
        const value = props.value;
        const values = this.getValues(props);
        //const mayAutomaticallyChangeValue = prevProps.value === props.value;
        //if (mayAutomaticallyChangeValue) {
        //    const prevStartTime = prevProps.startTime ? prevProps.startTime.toTime() : null;
        //    const startTime = props.startTime ? props.startTime.toTime() : null;
        //    const prevTime = prevProps.value ? prevProps.value.toTime() : null;
        //    if (prevStartTime && prevTime && startTime) {
        //        const prevStartDate = prevProps.startDate ? prevProps.startDate.toDate() : null;
        //        const prevDate = prevProps.date ? prevProps.date.toDate() : null;
        //        const startDate = props.startDate ? props.startDate.toDate() : null;
        //        const date = props.date ? props.date.toDate() : null;
        //        const prevDuration = Base.dateDiffInMinutesFromParts(prevStartDate ? prevStartDate.getFullYear() : 2000, prevStartDate ? prevStartDate.getMonth() : 0, prevStartDate ? prevStartDate.getDate() : 1, prevStartTime.getHours(), prevStartTime.getMinutes(), 0,
        //            prevDate ? prevDate.getFullYear() : 2000, prevDate ? prevDate.getMonth() : 0, prevDate ? prevDate.getDate() : 1, prevTime.getHours(), prevTime.getMinutes(), 0);
        //        if (prevDuration < 24 * 60) {
        //            for (let i = 0; i < values.length; i++) {
        //                const valueTime = values[i].id.toTime();
        //                const valueDuration = Base.dateDiffInMinutesFromParts(startDate ? startDate.getFullYear() : 2000, startDate ? startDate.getMonth() : 0, startDate ? startDate.getDate() : 1, startTime.getHours(), startTime.getMinutes(), 0,
        //                    date ? date.getFullYear() : 2000, date ? date.getMonth() : 0, date ? date.getDate() : 1, valueTime.getHours(), valueTime.getMinutes(), 0);
        //                if (valueDuration < 0) continue;
        //                if (valueDuration <= prevDuration) {
        //                    value = values[i].id;
        //                } else {
        //                    break;
        //                }
        //            }
        //        }
        //    }
        //}
        this.setState({
            values: values,
            value: value
        });
        if (prevValue !== value) {
            props.onChange(value);
        }
    }

    openDropDown = (id: string) => {
        if (!this.selectListDiv) return;
        if (this.selectListDiv.style.display !== "block") {
            this.selectListDiv.style.display = "block";
            if (id) {
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ block: "center" });
                }
            }
        }
    };

    closeDropDown = () => {
        if (!this.selectListDiv) return;
        if (this.selectListDiv.style.display === "block") {
            this.selectListDiv.style.display = "none";
            this.selectListCloseTime = new Date().getTime();
        }
    };

    componentDidMount(): void {
        window.addEventListener("click", this.closeDropDown, true);
    }

    componentWillUnmount(): void {
        window.removeEventListener("click", this.closeDropDown);
    }

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        this.setState({
            value: value,
        });
    };

    handleBlur = (event) => {
        const target = event.target;
        const value: string = target.value;
        const timeStr = Base.dateToTimeStr(value.toTime());
        this.setState({
            value: timeStr,
        });
        this.props.onChange(timeStr);
    };

    handleSelectItem = (value: string) => {
        this.closeDropDown();
        this.props.onChange(value);
    };

    handleClearClick = () => {
        this.props.onChange("");
    };

    handleOpenClick = () => {
        const nowTime = new Date().getTime();
        if (nowTime - this.selectListCloseTime < 50) return;
        this.openDropDown(this.state.value);
    };

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <div className={"timeSelector" + (props.classes ? " " + props.classes : "") + (props.disabled ? " disabled" : "")}>
                {props.title &&
                    <label className={"control-label" + (props.titleClasses ? " " + props.titleClasses : "")}>{props.title}</label>
                }
                {!props.disabled &&
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder={props.placeholder} value={state.value} onChange={this.handleChange} onBlur={this.handleBlur} onClick={this.handleOpenClick}/>
                        <div className="selectList" ref={(div) => { this.selectListDiv = div; }}>
                            {state.values.map((value) =>
                                <div key={value.id} className={value.id === state.value ? "selected" : ""} id={value.id} title={value.title} onClick={ (e) => { this.handleSelectItem(value.id); }}>{value.title}</div>
                            )}
                        </div>
                        <div className="input-group-append">
                            {!props.disabled && !props.required &&
                                <ToolButton
                                    removeDefaultClasses={true}
                                    title={Translations.Clear}
                                    classes="btn withIcon xCross gray"
                                    enabled={true}
                                    onClick={this.handleClearClick}
                                />
                            }
                            {!props.disabled &&
                                <ToolButton
                                    removeDefaultClasses={true}
                                    title={Translations.Open}
                                    classes="btn withIcon doExpand"
                                    enabled={true}
                                    onClick={this.handleOpenClick}
                                />
                            }
                        </div>
                    </div>
                }
                {props.disabled &&
                    <div>{props.disabledValue}</div>
                }
            </div>
        );
    }
}
