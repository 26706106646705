import React, { memo, useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import Stack from "@mui/material/Stack";
import { useDispatch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../../framework/customStore";
import { TransportPlanState } from "../../../models/transport/transportPlan";
import { VehicleListItemDto } from "../../../models/transport/vehicle";
import {
    extendTimeline,
    fetchTransportPlans,
    fetchVehicles,
    moveTimelineLeft,
} from "../../../store/transport/transportVehiclesSlice";
import {
    TimelineGrid,
    TimelineGridRow,
} from "../../timelinePlanner/timelinePlannerGrid";

import { TransportTimelineCell } from "../transportTimeline/TransportTimelineCell";
import { TransportPlansWithoutVehicleTimelineRow } from "./TransportPlansWithoutVehicleTimelineRow";
import { DateActions } from "../transportTimeline/DateActions";
import { NewPlanDialog } from "../transportTimeline/NewPlanDialog";

export const TransportVehiclesTimelineGrid = () => {
    const timelineStart = useAppSelector(
        (state) => state.transportVehicles.timelineStart
    );
    const timelineEnd = useAppSelector(
        (state) => state.transportVehicles.timelineEnd
    );
    const vehicles = useAppSelector((state) => state.transportVehicles.vehices);
    const selectedVehicles = useAppSelector(
        (state) => state.transportVehicles.selectedVehicles
    );
    const selectedVehicleGroups = useAppSelector(
        (state) => state.transportVehicles.selectedVehicleGroups
    );
    const selectedPlanStates = useAppSelector(
        (state) => state.transportVehicles.selectedPlanStates
    );
    const plans = useAppSelector(
        (state) => state.transportVehicles.plans
    );
    const gridDataChanged = useAppSelector((state) => state.transportVehicles.gridDataChanged);
    const appDispatch = useAppDispatch();
    const dispatch = useDispatch();

    const handleLoadMore = useCallback(() => {
        appDispatch(extendTimeline());
    }, []);

    const handleMoveLeft = useCallback(() => {
        appDispatch(moveTimelineLeft());
    }, []);

    useEffect(() => {
        dispatch(fetchVehicles());
    }, []);

    useEffect(() => {
        let states = Object.entries(selectedPlanStates)
            .filter(([, val]) => val === true)
            .map(([state]) => state) as unknown as TransportPlanState[];

        if (states.length === 5) {
            states = [];
        }
        dispatch(
            fetchTransportPlans({
                timeFrameStartDate: timelineStart.toISOString(),
                timeFrameEndDate: timelineEnd.toISOString(),
                ...(selectedVehicles.length > 0 && {
                    vehicleIds: selectedVehicles,
                }),
                ...(states.length > 0 && { states }),
            })
        );
    }, [timelineStart, timelineEnd, selectedVehicles, selectedPlanStates, gridDataChanged]);

    const plansWithoutVehicleId = Object.values(plans).filter((item) => item.vehicle === null);

    return (
        <>
            <TimelineGrid
                startDate={timelineStart}
                endDate={timelineEnd}
                loadMore={handleLoadMore}
                moveLeft={handleMoveLeft}
            >
                {vehicles.map((vehicle) => {
                    if (
                        selectedVehicles.length > 0 &&
                        !selectedVehicles.includes(vehicle.id)
                    ) {
                        return null;
                    }
                    if (
                        selectedVehicleGroups.length > 0 &&
                        !selectedVehicleGroups.includes(vehicle.vehicleGroupId)
                    ) {
                        return null;
                    }
                    return (
                        <VehicleTimelineRow key={vehicle.id} item={vehicle} />
                    );
                })}
                {selectedVehicles.length === 0 && selectedVehicleGroups.length === 0 &&
                    <TransportPlansWithoutVehicleTimelineRow items={plansWithoutVehicleId} />
                }
            </TimelineGrid>
            <NewPlanDialog />
        </>
    );
};

interface VehicleTimelineRowProps {
    item: VehicleListItemDto;
}

export const VehicleTimelineRow = memo((props: VehicleTimelineRowProps) => {
    const vehiclePlans = useAppSelector(
        (state) => state.transportVehicles.vehiclePlans[props.item.id]
    );
    return (
        <>
            <TimelineGridRow
                renderColumnHeader={(date: Date) => (
                    <DateActions date={date} vehicleId={props.item.id} />
                )}
                infoColumn={
                    <Box sx={{ fontSize: "small", pt: 1, pb: 1 }}>
                        <Stack direction="row" justifyContent="space-between">
                            <div className="bold flex align-items-center overflow-break">
                                <LocalShippingOutlinedIcon
                                    sx={{ mr: 1, color: "primary.dark" }}
                                    fontSize="small"
                                />
                                <span>
                                    {props.item.brand}{" "}
                                    {props.item.registerNumber}
                                </span>
                            </div>
                        </Stack>
                    </Box>
                }
            >
                {vehiclePlans &&
                    vehiclePlans.map((planId) => (
                        <TransportTimelineCell
                            planId={planId}
                            key={planId}
                            editEmployeeEnabled
                            editVehicleEnabled
                        />
                    ))}
            </TimelineGridRow>
        </>
    );
});
