// TrackingEventLogList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as eventLogService from "../../services/eventLogService";
import { Base } from "../../framework/base";
import { ListSearchFilter } from "../framework/listSearchFilter";
import { ListHeaderColumn } from "../framework/listHeaderColumn";
import { ToolTitle } from "../framework/toolTitle";
import * as StoreActions from "../../models/store/storeActions";
import { infiniteListPageSize } from "../../models/common/consts";
import { handleApiError } from "../../models/store/storeEffects";
import { IEventLogItem } from "../../models/eventLog/eventLogItem";
import { ToolButton } from "../framework/toolButton";
import { TrackingEventLogDialog } from "./trackingEventLogDialog";

// TrackingEventLogListLineHeader
export interface ITrackingEventLogListLineHeaderProp {
    sortColumn: string;
    sortOrderIsAsc: boolean;
    onColumnClick: (column: string) => void;
}

export class TrackingEventLogListLineHeader extends React.Component<ITrackingEventLogListLineHeaderProp, {}> {
    handleColumnClick = (column: string) => {
        this.props.onColumnClick(column);
    };

    render() {
        return (
            <div className="row title">
                <ListHeaderColumn
                    title={Translations.Time}
                    column="time"
                    classes="col-1"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.RelatedObjectType}
                    column="objectType"
                    classes="col-1"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title="#"
                    column="number"
                    classes="col-1"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.EntryType}
                    column="entryType"
                    classes="col-1"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Message}
                    column="message"
                    classes="col-6"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Username}
                    column="user"
                    classes="col-2"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
            </div>
        );
    }
}

// TrackingEventLogListLine
export interface ITrackingEventLogListLineProp {
    item: IEventLogItem;
    selectedId: string;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

export class TrackingEventLogListLine extends React.Component<ITrackingEventLogListLineProp, {}> {
    render() {
        const item = this.props.item;
        return (
            <div className={"row line" + (item.id === this.props.selectedId ? " selected" : "")} onClick={() => { this.props.onClick(item.id); }} onDoubleClick={() => { this.props.onDoubleClick(item.id); }} >
                <div className="col-1">{Base.timeToDateTimeStr(item.time)}</div>
                <div className="col-1">{item.objectType}</div>
                <div className="col-1">{item.number.toString(10)}</div>
                <div className="col-1">{item.entryType}</div>
                <div className="col-6">{item.message}</div>
                <div className="col-2">{item.user}</div>
            </div>
        );
    }
}

// TrackingEventLogList
export interface ITrackingEventLogListProp {
}

interface ITrackingEventLogListState {
    // List
    pageSize: number;
    page: number;
    hasMore: boolean;
    filter: string;
    sortColumn: string;
    sortOrderIsAsc: boolean;
    items: IEventLogItem[];

    // Edit
    selectedId: string;
    editItem: IEventLogItem;
    showEditDialog: boolean;
}

export class TrackingEventLogList extends React.Component<ITrackingEventLogListProp, ITrackingEventLogListState> {
    private containerDiv: HTMLDivElement;
    private listDiv: HTMLDivElement;
    private isLoading: boolean;

    constructor(props) {
        super(props);
        this.isLoading = false;
        this.state = {
            pageSize: infiniteListPageSize, page: 1, hasMore: false, filter: "", sortColumn: "time", sortOrderIsAsc: false, items: [], selectedId: null, editItem: null, showEditDialog: false
        };
    }

    searchItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean, resetItems: boolean, refreshList: boolean, successCallback: () => void = null) => {
        const obj = this;
        obj.isLoading = true;
        store.customStore.dispatch(StoreActions.fetchStart());
        eventLogService.getEventLogs(!refreshList ? pageSize : (page * infiniteListPageSize), !refreshList ? page : 1, filter, sortColumn, sortOrderIsAsc)
            .then(contactItems => {
                let items: IEventLogItem[];
                if (!resetItems && !refreshList) {
                    const oldIds = {};
                    for (let j = 0; j < obj.state.items.length; j++) {
                        oldIds[obj.state.items[j].id] = true;
                    }
                    const oldItems = obj.state.items.slice(0);
                    const newItems = contactItems.items.filter(i => Object.prototype.isPrototypeOf.call(oldIds, i.id) ? false : (oldIds[i.id] = true));
                    items = [...oldItems, ...newItems];
                } else {
                    items = contactItems.items;
                }
                obj.setState({
                    page: !refreshList ? contactItems.page : page,
                    hasMore: contactItems.hasMore,
                    filter: filter,
                    sortColumn: sortColumn,
                    sortOrderIsAsc: sortOrderIsAsc,
                    items: items
                });
                if (!Base.isNullOrUndefined(successCallback)) {
                    successCallback();
                }
            },
            error => {
                handleApiError(error, store.customStore.dispatch);
            }).finally(() => {
                obj.isLoading = false;
                store.customStore.dispatch(StoreActions.fetchEnd());
            });
    };

    refreshList = () => {
        this.searchItems(this.state.pageSize, this.state.page, this.state.filter, this.state.sortColumn, this.state.sortOrderIsAsc, false, true);
    };

    handleScrollSub = Base.debounce((obj: TrackingEventLogList) => {
        if (obj.isLoading || !obj.state.hasMore) return;
        if (obj.listDiv.offsetHeight - (obj.containerDiv.clientHeight + obj.containerDiv.scrollTop) < 5) {
            obj.searchItems(obj.state.pageSize, obj.state.page + 1, obj.state.filter, obj.state.sortColumn, obj.state.sortOrderIsAsc, false, false);
        }
    }, 100);

    handleScroll = (event) => {
        this.handleScrollSub(this);
    };

    componentDidMount(): void {
        this.containerDiv.addEventListener("scroll", this.handleScroll);
        this.searchItems(this.state.pageSize, this.state.page, this.state.filter, this.state.sortColumn, this.state.sortOrderIsAsc, false, false);
    }

    componentWillUnmount(): void {
        this.containerDiv.removeEventListener("scroll", this.handleScroll);
    }

    changeFilter = (filter: string) => {
        this.searchItems(this.state.pageSize, 1, filter, this.state.sortColumn, this.state.sortOrderIsAsc, true, false);
    };

    changeSortColumn = (sortColumn: string) => {
        const oldSortColumn = this.state.sortColumn;
        const sortOrderIsAsc = oldSortColumn === sortColumn ? !this.state.sortOrderIsAsc : true;
        this.searchItems(this.state.pageSize, 1, this.state.filter, sortColumn, sortOrderIsAsc, true, false);
    };

    getEditItem = (id: string) => {
        const editItem = this.state.items.find(i => i.id === id);
        if (Base.isNullOrUndefined(editItem)) return;
        this.setState({
            showEditDialog: true,
            editItem: editItem
        });
    };

    handleEdit = () => {
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        this.getEditItem(selectedId);
    };

    handleEditDialogOk = () => {
        this.setState({
            showEditDialog: false
        });
        this.refreshList();
    };

    handleEditDialogCancel = () => {
        this.setState({
            showEditDialog: false
        });
    };

    handleClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleDoubleClick = (id: string) => {
        this.handleEdit();
    };

    render() {
        const items = this.state.items;
        return (
            <div className="backgroundContent">
                <div className="row commandRow main">
                    <ToolTitle
                        title={Translations.EventLog}
                    />
                    <div className="col">
                        <ToolButton
                            title={Translations.Open}
                            enabled={!!this.state.selectedId}
                            classes={"round left open"}
                            onClick={this.handleEdit}
                        />
                    </div>
                    <div className="col-auto right">
                        <ListSearchFilter
                            searchFilter={this.state.filter}
                            onSearchClick={this.changeFilter}
                        />
                    </div>
                </div>
                <div>
                    <TrackingEventLogListLineHeader
                        sortColumn={this.state.sortColumn}
                        sortOrderIsAsc={this.state.sortOrderIsAsc}
                        onColumnClick={this.changeSortColumn}
                    />
                </div>
                <div className="listContainer main eventLogsContainer">
                    <div className="list tools striped" ref={(elem) => { this.containerDiv = elem; }}>
                        <div className="lineContainer" ref={(elem) => { this.listDiv = elem; }}>
                            {items.map((item) =>
                                <TrackingEventLogListLine
                                    key={item.id}
                                    item={item}
                                    selectedId={this.state.selectedId}
                                    onClick={this.handleClick}
                                    onDoubleClick={this.handleDoubleClick}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {this.state.showEditDialog &&
                    <TrackingEventLogDialog
                        editItem={this.state.editItem}
                        onCancel={this.handleEditDialogCancel}
                    />
                }
            </div>
        );
    }
}