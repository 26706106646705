// SelectIdTitleDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "../../framework/base";
import { Translations } from "../../models/translations";
import { SelectDialog } from "./dialog";
import { IEndlessList } from "../../models/common/endlessList";
import { IdTitle, IIdTitle } from "../../models/common/idTitle";
import { ListSearchFilter } from "./listSearchFilter";
import { ListBox } from "./listbox";

// IdCodeListLine
export interface IIdTitleListLineProp {
    item: IIdTitle;
    selected: boolean;
    onClick: (id: string) => void;
}

export class IdTitleListLine extends React.Component<IIdTitleListLineProp, {}> {
    handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(this.props.item.id);
    };

    render() {
        const item = this.props.item;
        return (
            <div className={"line" + (this.props.selected ? " selected" : "")} onClick={this.handleClick}>
                <div className="row">
                    <div className={"col col-12"}>
                        <div className="singleLine">{item.title}</div>
                    </div>
                </div>
            </div>
        );
    }
}

// SelectIdTitleDialog
export interface ISelectIdTitleDialogProps {
    classes?: string;
    title: string;
    selectedId: string;
    listbox?: boolean;
    autoFilter?: boolean;
    filter?: string;
    loadIdCodes: (page: number, filter: string) => Promise<IEndlessList<IdTitle>>;
    onAdd?: (title: string) => void;
    onOk: (id: string, title: string) => void;
    onCancel: () => void;
}

export interface ISelectIdTitleDialogState {
    items: IIdTitle[];
    page: number;
    filter: string;
    hasMore: boolean;
    isLoading: boolean;
    selectedId: string;
    setSelectedId: boolean;
}

export class SelectIdTitleDialog extends React.Component<ISelectIdTitleDialogProps, ISelectIdTitleDialogState> {
    private containerDiv: HTMLDivElement;
    private listDiv: HTMLDivElement;

    constructor(props) {
        super(props);
        this.state = { items: [], selectedId: props.selectedId, page: 1, isLoading: false, hasMore: true, setSelectedId: !props.selectedId, filter: props.filter ?? "" };
    }

    loadIdCodes = (page: number, filter: string, clear: boolean) => {
        const obj = this;
        this.props.loadIdCodes(page, filter).then(list => {
            if (!list) return;
            let newItems: IdTitle[];
            if (clear) {
                newItems = list.items.slice(0);
            } else {
                const oldIds = {};
                for (let j = 0; j < obj.state.items.length; j++) {
                    oldIds[obj.state.items[j].id] = true;
                }
                newItems = list.items.filter(i => Object.prototype.isPrototypeOf.call(oldIds, i.id) ? false : (oldIds[i.id] = true));
            }
            const items = clear ? newItems : [...obj.state.items, ...newItems];
            let selectedId = obj.state.selectedId;
            if (obj.state.setSelectedId && items.length > 0 && !!items[0]) {
                selectedId = items[0].id;
            }
            obj.setState({ items: items, page: list.page, hasMore: list.hasMore, selectedId: selectedId, filter: filter });
        });
    };

    handleScrollSub = Base.debounce((obj: SelectIdTitleDialog) => {
        if (obj.state.isLoading || !obj.state.hasMore) return;
        if (obj.listDiv.offsetHeight - (obj.containerDiv.clientHeight + obj.containerDiv.scrollTop) < 5) {
            obj.loadIdCodes(obj.state.page + 1, obj.state.filter, false);
        }
    }, 100);

    handleScroll = (event) => {
        this.handleScrollSub(this);
    };

    changeFilter = (filter: string) => {
        this.loadIdCodes(this.state.page, filter, true);
    };

    handleLineClick = (id: string) => {
        if (!id) return;
        this.setState({
            selectedId: id
        });
    };

    componentDidMount(): void {
        if (this.containerDiv) {
            this.containerDiv.addEventListener("scroll", this.handleScroll);
        }
        this.loadIdCodes(1, this.state.filter, false);
    }

    componentWillUnmount(): void {
        if (!this.containerDiv) return;
        this.containerDiv.removeEventListener("scroll", this.handleScroll);
    }

    handleAddClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onAdd(this.state.filter);
    };

    handleOkClick = () => {
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        const idCode = this.state.items.find(i => i.id === selectedId);
        if (!idCode) return;
        this.props.onOk(idCode.id, idCode.title);
    };

    handleListBoxChange = (item: IIdTitle) => {
        const obj = this;
        obj.setState({
            selectedId: item ? item.id : ""
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "selectIdTitle px400" + (props.classes ? " " + props.classes : "");
        return (
            <SelectDialog
                classes={dialogClasses}
                title={this.props.title}
                show={true}
                body={
                    <div>
                        {props.listbox &&
                            <ListBox
                                autoFocus={true}
                                filter={state.filter}
                                items={state.items}
                                onChange={this.handleListBoxChange}
                            />
                        }
                        {!props.listbox &&
                            <ListSearchFilter
                                autoFilter={props.autoFilter}
                                searchFilter={state.filter}
                                onSearchClick={this.changeFilter}
                            />
                        }
                        {!props.listbox &&
                            <div ref={(elem) => { this.containerDiv = elem; }}>
                                <div ref={(elem) => { this.listDiv = elem; }}>
                                    <div className="listContainer" ref={(elem) => { this.containerDiv = elem; }}>
                                        <div className="list" ref={(elem) => { this.listDiv = elem; }}>
                                            {props.onAdd &&
                                                <div className="line link" onClick={this.handleAddClick} title={Translations.AddNew}>{Translations.AddNew}</div>
                                            }
                                            {state.items.map(item => (
                                                <IdTitleListLine
                                                    key={item.id}
                                                    item={item}
                                                    selected={state.selectedId === item.id}
                                                    onClick={this.handleLineClick}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                buttons={[
                    { title: Translations.Select, classes: "btn-primary", enabled: true, onClick: this.handleOkClick }]}
                onClose={props.onCancel}
            />
        );
    }
}