// SalaryEdit - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import * as salaryService from "../../services/salaryService";
import * as workTimeAccountService from "../../services/workTimeAccountService";
import { handleApiError } from "../../models/store/storeEffects";
import { ISalaryRowTypeItem } from "../../models/salary/salaryRowTypeItem";
import { Translations } from "../../models/translations";
import { Button } from "../framework/button";
import { ToolButton } from "../framework/toolButton";
import { Base } from "../../framework/base";
import { ISalaryEditItemSalaryRow, SalaryEditItemSalaryRow } from "../../models/salary/salaryEditItemSalaryRow";
import { ISalaryEditItem, SalaryEditItem, SalaryEditItemSave } from "../../models/salary/salaryEditItem";
import { EditableDecimalCell, EditableStringCell } from "../framework/editableCell";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { SaveData } from "../../framework/saveData";
import { DatePicker } from "../framework/datePicker";
import { ISalaryWorkTimePeriod } from "../../models/salary/salaryWorkTimePeriod";
import { IEmployeeItem } from "../../models/employee/employeeItem";
import { IEmployeeGroupItem } from "../../models/employeeGroup/employeeGroupItem";
import { WorkTimeAccountDialog } from "./workTimeDialogWorkTimeAccount";
import { IWorkTimeAccountTransactionSaveData, WorkTimeAccountTransactionSaveData } from "../../models/workTimeAccount/workTimeAccountTransactionSaveData";
import { OwnerParameters } from "../../models/owner/ownerParameters";
import { WorkTimeAccountTransactionType } from "../../models/common/enums";
import { ISalaryRowCalculated } from "../../models/salary/salaryRowCalculated";
import { SalaryTransferToSystemDialog } from "./salaryTransferToSystemDialog";
import { ISalaryListItem } from "../../models/salary/salaryListItem";

//SalaryEditTitleRow
class SalaryEditTitleRow extends React.Component<{}, {}> {
    render() {
        return (
            <div className="mainTitleRow">
                <div className="row">
                    <div className="col-4 cell"><span className="tile">{Translations.SalaryRowType}</span></div>
                    <div className="col-6 cell"><span className="tile">{Translations.Comment}</span></div>
                    <div className="col-1 cell"><span className="tile">{Translations.Amount}</span></div>
                    <div className="col-1 cell"><span className="tile">{Translations.MeasureUnit}</span></div>
                </div>
            </div>
        );
    }
}

//SalaryEditSalaryRow
interface ISalaryEditSalaryRowProps {
    row: ISalaryEditItemSalaryRow;
    rowIndex: number;
    onChange: (value: number) => void;
    onCommentChange: (value: string) => void;
}

interface ISalaryEditSalaryRowState {
    rowHover: boolean;
    rowFocus: boolean;
}

class SalaryEditSalaryRow extends React.Component<ISalaryEditSalaryRowProps, ISalaryEditSalaryRowState> {
    constructor(props) {
        super(props);
        this.state = {
            rowHover: false,
            rowFocus: false
        };
    }

    handleFocus = (focus: boolean) => {
        this.setState({ rowFocus: focus });
    };

    handleSetFocus = (id: string) => {
        if (!id) return;
        const element = document.getElementById(id);
        if (!element) return;
        setTimeout(function() {
            element.focus();
        }, 0);
    };

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <div className={"salaryRow" + (state.rowFocus ? " rowFocus" : "") + (props.row.rowType.readOnly ? " disabled" : "")}>
                <div className="row">
                    <div className="col-1 cell">
                        <div className="title">{props.row.rowType.code}</div>
                    </div>
                    <div className="col-3 cell">
                        <div className="title">{props.row.rowType.name}</div>
                    </div>
                    <div className="col-6 cell">
                        <EditableStringCell
                            id={"R" + props.rowIndex.toString() + "C1"}
                            disabled={true}
                            value={props.row.comment}
                            onValueChange={(value) => { props.onCommentChange(value); }}
                            onFocus={() => this.handleFocus(true)}
                            onBlur={() => this.handleFocus(false)}
                            onMoveUp={() => this.handleSetFocus("R" + (props.rowIndex - 1).toString() + "C1")}
                            onMoveDown={() => this.handleSetFocus("R" + (props.rowIndex + 1).toString() + "C1")}
                        />
                    </div>
                    <div className="col-1 cell">
                        <EditableDecimalCell
                            id={"R" + props.rowIndex.toString() + "C2"}
                            decimals={props.row.rowType.decimals}
                            disabled={true}
                            amount={props.row.amount}
                            onAmountChange={(value) => { props.onChange(value); }}
                            onFocus={() => this.handleFocus(true)}
                            onBlur={() => this.handleFocus(false)}
                            onMoveUp={() => this.handleSetFocus("R" + (props.rowIndex - 1).toString() + "C2")}
                            onMoveDown={() => this.handleSetFocus("R" + (props.rowIndex + 1).toString() + "C2")}
                        />
                    </div>
                    <div className="col-1 cell">{props.row.rowType.measureUnit}</div>
                </div>
            </div>
        );
    }
}

//SalaryEditEmployeeSalaryForm
interface ISalaryEditEmployeeSalaryFormProps {
    salary: ISalaryEditItem;
    workTimeAccountTransaction: IWorkTimeAccountTransactionSaveData;
}

interface ISalaryEditEmployeeSalaryFormState {
    dateStr: string;
    comment: string;
    transferDateStr: string;
    rows: ISalaryEditItemSalaryRow[];
}

class SalaryEditEmployeeSalaryForm extends React.Component<ISalaryEditEmployeeSalaryFormProps, ISalaryEditEmployeeSalaryFormState> {
    getStateFromProps = (props: ISalaryEditEmployeeSalaryFormProps): ISalaryEditEmployeeSalaryFormState => {
        return {
            dateStr: props.salary && props.salary.date ? Base.utcTimeToDateStr(props.salary.date) : "",
            comment: props.salary ? props.salary.comment : "",
            transferDateStr: props.salary && props.salary.transferDate ? Base.timeToDateStr(props.salary.transferDate) : "",
            rows: props.salary ? props.salary.rows.slice(0) : []
        };
    };

    constructor(props: ISalaryEditEmployeeSalaryFormProps) {
        super(props);
        this.state = this.getStateFromProps(props);
    }

    componentDidUpdate(prevProps: ISalaryEditEmployeeSalaryFormProps, prevState: ISalaryEditEmployeeSalaryFormState): void {
        const props = this.props;
        const prevSalaryHash = prevProps.salary ? prevProps.salary.getHash() : "";
        const salaryHash = props.salary ? props.salary.getHash() : "";
        if (prevSalaryHash === salaryHash) return;
        this.setState(this.getStateFromProps(props));
    }

    // #region General
    handleChange = (event) => {
        const obj = this;
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "comment") {
            obj.setState({ comment: value });
        }
    };

    handleDateBlur = (value: string) => {
        this.setState({ dateStr: value ? Base.dateToDateStr(value.toDate()) : "" });
    };

    handleDateChange = (value: string) => {
        this.setState({ dateStr: value });
    };
    // #endregion General

    // #region Cell edition
    handleRowValueChange = (rowIndex: number, value: number) => {
        const state = this.state;
        const rows = state.rows.slice(0);
        if (rowIndex < 0 || rowIndex > rows.length - 1) return;
        const row = rows[rowIndex];
        row.amount = value;
        this.setState({
            rows: rows,
        });
    };

    handleRowCommentChange = (rowIndex: number, value: string) => {
        const state = this.state;
        const rows = state.rows.slice(0);
        if (rowIndex < 0 || rowIndex > rows.length - 1) return;
        const row = rows[rowIndex];
        row.comment = value;
        this.setState({
            rows: rows,
        });
    };
    // #endregion Cell edition

    // #region Saving
    private static checkErrors = (props: ISalaryEditEmployeeSalaryFormProps, state: ISalaryEditEmployeeSalaryFormState): Promise<boolean> => {
        return new Promise<boolean>((resolve) => {
            if (!state.dateStr) {
                store.customStore.dispatch(storeActions.showErrorMessage(Translations.DateMustBeDefined));
                return resolve(false);
            }
            return resolve(true);
        });
    };

    private static getWarningMessage = (props: ISalaryEditEmployeeSalaryFormProps, state: ISalaryEditEmployeeSalaryFormState): Promise<string> => {
        return new Promise<string>((resolve) => {
            //if (check for warning) {
            //    warnings = Translations.WarningMessage + Base.lf;
            //}
            return resolve("");
        });
    };

    validate = (): Promise<boolean> => {
        return SalaryEditEmployeeSalaryForm.checkErrors(this.props, this.state).then(success => {
            if (success) {
                return SalaryEditEmployeeSalaryForm.getWarningMessage(this.props, this.state).then(warnings => {
                    if (!warnings) {
                        return new Promise<boolean>((resolve) => { resolve(true); });
                    }
                    return new Promise<boolean>((resolve) => {
                        store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, warnings + Base.lf + Translations.DoYouReallyWantToSaveSalary,
                            () => {
                                store.customStore.dispatch(storeActions.clearConfirmation());
                                resolve(true);
                            },
                            () => {
                                store.customStore.dispatch(storeActions.clearConfirmation());
                                resolve(false);
                            }, null));
                    });
                });
            } else {
                return new Promise<boolean>((resolve) => { resolve(false); });
            }
        });
    };

    getSaveSalary = (): Promise<ISalaryEditItem> => {
        const state = this.state;
        const salary = new SalaryEditItem(this.props.salary);
        salary.date = state.dateStr ? state.dateStr.toUtcDate().getTime() : 0;
        salary.comment = state.comment;
        salary.rows = state.rows;
        return new Promise<ISalaryEditItem>((resolve) => { resolve(salary); });
    };

    getValidatedSaveSalary = (): Promise<ISalaryEditItem> => {
        const obj = this;
        return this.validate()
            .then(success => {
                return success ? obj.getSaveSalary() : null;
            });
    };
    // #endregion Saving

    render() {
        const state = this.state;
        return (
            <div className="salaryForm">
                <div className="row">
                    <div className="col-2">
                        <div className="form-group">
                            <label className="control-label smallFont">{Translations.Date}</label>
                            <DatePicker
                                required={true}
                                disabled={true}
                                value={state.dateStr}
                                onChange={this.handleDateChange}
                                onBlur={this.handleDateBlur}
                            />
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="form-group">
                            <label className="control-label smallFont">{Translations.Comment}</label>
                            <input type="text" readOnly className="form-control" name="comment" title={Translations.Comment} value={state.comment} onChange={this.handleChange} maxLength={1000} />
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="form-group">
                            <label className="control-label smallFont">{Translations.Transferred}</label>
                            <div>{state.transferDateStr}</div>
                        </div>
                    </div>
                </div>
                <SalaryEditTitleRow />
                <div className="rowsContainer">
                    {state.rows.map((row, i) =>
                        <SalaryEditSalaryRow
                            key={row.salaryRowTypeId}
                            rowIndex={i}
                            row={row}
                            onChange={(value: number) => { this.handleRowValueChange(i, value); }}
                            onCommentChange={(value: string) => { this.handleRowCommentChange(i, value); }}
                        />
                    )}
                </div>
            </div>
        );
    }
}

//SalaryEditGroupSalaryRow
interface ISalaryEditGroupSalaryRowProps {
    salary: ISalaryEditItem;
    selectedId: string;
    onClick: (employeeId: string) => void;
    onGotoEmployee: (employeeId: string) => void;
}

class SalaryEditGroupSalaryRow extends React.Component<
    ISalaryEditGroupSalaryRowProps,
    {}
> {
    render() {
        const props = this.props;
        const salary = props.salary;
        return (
            <div
                className={
                    "row salaryGroupRow" +
                    (salary.employeeId === props.selectedId ? " selected" : "")
                }
                onClick={() => {
                    props.onClick(salary.employeeId);
                }}
            >
                <div className="col-2 employeeColumn">
                    <div
                        className="clickable withIcon"
                        onClick={() => props.onGotoEmployee(salary.employeeId)}
                    >
                        {salary.employeeName}
                    </div>
                    {!!salary.transferDate && (
                        <span
                            className="icon eoCheck"
                            title={
                                Translations.Transferred +
                                " " +
                                Base.timeToDateStr(props.salary.transferDate)
                            }
                        />
                    )}
                </div>
                <div className="col-10 pl-0">
                    <div className="row fullHeight">
                        {salary.rows.map((row, i) => (
                            <div
                                key={row.salaryRowTypeId}
                                className={
                                    "col-2 text-right textValue" +
                                    (i === 0
                                        ? " pl-1 pr-0 firstCol"
                                        : " pl-0 pr-0")
                                }
                                title={row.comment}
                            >
                                {row.amount
                                    ? row.amount.toLocaleFixed(
                                        row.rowType.decimals
                                    )
                                    : ""}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

//SalaryEditGroupSalaryRows
interface ISalaryEditGroupSalaryRowsProps {
    salaryRowTypes: ISalaryRowTypeItem[];
    salaries: ISalaryEditItem[];
    selectedId: string;
    onClick: (employeeId: string) => void;
    onGotoEmployee: (employeeId: string) => void;
}

class SalaryEditGroupSalaryRows extends React.Component<
    ISalaryEditGroupSalaryRowsProps,
    {}
> {
    render() {
        const props = this.props;
        return (
            <div className="groupRowsContainer">
                <div className="row titleRow">
                    <div className="col-2 employeeColumn">
                        {Translations.Employee}
                        {props.salaries.length > 0 &&
                            !props.salaries.find((i) => !i.transferDate) && (
                                <span
                                    className="icon eoCheck"
                                    title={Translations.Transferred}
                                />
                        )}
                    </div>
                    <div className="col-10 pl-0 fullWidth">
                        <div className="row fullHeight">
                            {props.salaryRowTypes.map((salaryRowType, i) => (
                                <div
                                    key={salaryRowType.id}
                                    className={
                                        "col-2 text-right textValue" +
                                        (i === 0
                                            ? " pl-1 pr-0 firstCol"
                                            : " pl-0 pr-0")
                                    }
                                    title={salaryRowType.name}
                                >
                                    {salaryRowType.name}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {props.salaries.map((salary) => (
                    <SalaryEditGroupSalaryRow
                        key={salary.employeeId}
                        salary={salary}
                        selectedId={props.selectedId}
                        onClick={props.onClick}
                        onGotoEmployee={props.onGotoEmployee}
                    />
                ))}
            </div>
        );
    }
}

//SalaryEdit
interface ISalaryEditProps {
    employeeGroup: IEmployeeGroupItem;
    employees: IEmployeeItem[];
    salaryPeriod: ISalaryWorkTimePeriod;
    salaryRowTypes: ISalaryRowTypeItem[];
    salaryTransferEnabled: boolean;
    onChangeEmployee: (employeeId: string) => void;
    onChangeSalaryPeriod: (salaryPeriodId: string) => void;
}

interface ISalaryEditState {
    salaryFileGenerationInUse: boolean;
    salaries: ISalaryEditItem[];
    selectedId: string;
    hasNewSalaries: boolean;
    isLoading: boolean;
    showWorkTimeAccoundDialog: boolean;
    workTimeAccountDialogInitializeFromDatabase: boolean;
    workTimeAccountIsInUse: boolean;
    workTimeAccountIsInUseForEmployee: boolean;
    workTimeAccountTransactionDepositSalaryRowTypeCode: string;
    workTimeAccountTransactionWithdrawSalaryRowTypeCode: string;
    workTimeAccountTransaction: IWorkTimeAccountTransactionSaveData;
    workTimeAccountBalance: number;
    showSalaryTransferToSystemDialog: boolean;
    salaryTransferToSystemDialogItems: ISalaryListItem[];
}

export class SalaryEdit extends React.Component<ISalaryEditProps, ISalaryEditState> {
    private salaryEditEmployeeSalaryFormRef = React.createRef<SalaryEditEmployeeSalaryForm>();
    private orgStateHash: string = "";

    constructor(props) {
        super(props);
        const ownerParameters = new OwnerParameters();
        this.state = {
            salaryFileGenerationInUse: false,
            salaries: [],
            selectedId: "",
            hasNewSalaries: false,
            isLoading: false,
            showWorkTimeAccoundDialog: false,
            workTimeAccountDialogInitializeFromDatabase: true,
            workTimeAccountIsInUse: ownerParameters.getWorkTimeAccountInUse(),
            workTimeAccountIsInUseForEmployee: false,
            workTimeAccountTransactionDepositSalaryRowTypeCode: ownerParameters.getWorkTimeAccountTransactionDepositSalaryRowTypeCode(),
            workTimeAccountTransactionWithdrawSalaryRowTypeCode: ownerParameters.getWorkTimeAccountTransactionWithdrawSalaryRowTypeCode(),
            workTimeAccountTransaction: new WorkTimeAccountTransactionSaveData(),
            workTimeAccountBalance: 0,
            showSalaryTransferToSystemDialog: false,
            salaryTransferToSystemDialogItems: []
        };
    }

    getSalaryEdit = (employeeGroupId: string, employeeIds: string[], salaryPeriodId: string): Promise<void> => {

        if (!salaryPeriodId) {
            return;
        }

        const obj = this;
        const props = this.props;
        obj.setState({
            isLoading: true
        });
        store.customStore.dispatch(storeActions.fetchStart());
        return salaryService.getSalaryEdit(employeeIds, salaryPeriodId)
            .then(
                salaryEdit => {
                    const salaries = SalaryEditItem.addMissingSalaries(props.salaryRowTypes, props.employees, salaryEdit.salaries);
                    //Combine duplicate salary rows
                    for (const salary of salaries) {
                        const salaryRows = salary.rows.map(i => new SalaryEditItemSalaryRow(i));
                        salary.removeDuplicateSalaryRowTypeRows();
                        const salaryRowTypeGroups = Base.groupArray<ISalaryEditItemSalaryRow>(salaryRows, "salaryRowTypeId");
                        Object.keys(salaryRowTypeGroups).forEach(salaryRowTypeId => {
                            const salaryRowTypeSalaryRows = salaryRowTypeGroups[salaryRowTypeId];
                            const row = salary.rows.find(i => i.salaryRowTypeId === salaryRowTypeId);
                            if (!row) return;
                            row.setByGroupedRows(salaryRowTypeSalaryRows);
                        });
                    }
                    //Check work time account usage
                    let workTimeAccountIsInUseForEmployee = false;
                    if (!props.employeeGroup && salaries.length > 0) {
                        const employee = props.employees.find(i => i.id === salaries[0].employeeId);
                        if (employee) {
                            workTimeAccountIsInUseForEmployee = employee.workTimeAccountInUse;
                        }
                    }
                    obj.setState({
                        salaryFileGenerationInUse: salaryEdit.salaryFileGenerationInUse,
                        salaries: salaries,
                        hasNewSalaries: salaries.filter(i => i.isNew()).length > 0,
                        workTimeAccountIsInUseForEmployee: workTimeAccountIsInUseForEmployee,
                        workTimeAccountTransaction: new WorkTimeAccountTransactionSaveData(),
                        workTimeAccountDialogInitializeFromDatabase: true,
                    });
                    const saveData = obj.createSaveData(salaryPeriodId, employeeIds, salaries, null);
                    obj.orgStateHash = saveData.hash;
                },
                error => {
                    handleApiError(error, store.customStore.dispatch);
                })
            .finally(() => {
                obj.setState({
                    isLoading: false
                });
                store.customStore.dispatch(storeActions.fetchEnd());
            });
    };

    getCalculatedSalary = (employeeGroupId: string, employeeIds: string[], startDate: number, endDate: number): Promise<void> => {
        const obj = this;
        const state = this.state;
        if (state.isLoading) return;
        obj.setState({
            isLoading: true
        });
        store.customStore.dispatch(storeActions.fetchStart());
        return salaryService.calculateSalary(employeeIds, startDate, endDate)
            .then(
                calculatedSalary => {
                    const salaries = state.salaries.map(i => new SalaryEditItem(i));
                    for (const salary of salaries) {
                        if (!salary.date) {
                            salary.date = Base.getNowUtcDate().getTime();
                        }
                        const employeeRows = calculatedSalary.rows.filter(i => i.employeeId === salary.employeeId);
                        //Set calculated values to salary rows and combine duplicate calculated rows
                        const salaryRowTypeGroups = Base.groupArray<ISalaryRowCalculated>(employeeRows, "salaryRowTypeId");
                        // clear salary rows before adding calculated rows
                        for (const row of salary.rows) {
                            row.reset();
                        }

                        Object.keys(salaryRowTypeGroups).forEach(salaryRowTypeId => {
                            const calculatedSalaryRows = salaryRowTypeGroups[salaryRowTypeId];
                            const row = salary.rows.find(i => i.salaryRowTypeId === salaryRowTypeId);
                            if (!row) return;
                            row.setByGroupedRows(calculatedSalaryRows.map(i => SalaryEditItemSalaryRow.createSalaryEditItemSalaryRow(salaryRowTypeId, i.amount, i.comment, i.startDate, i.endDate, i.dayHoursRatio)));
                        });
                    }
                    obj.setState({
                        salaries: salaries
                    });
                },
                error => {
                    handleApiError(error, store.customStore.dispatch);
                })
            .finally(() => {
                obj.setState({
                    isLoading: false
                });
                store.customStore.dispatch(storeActions.fetchEnd());
            });
    };

    refreshView = () => {
        const props = this.props;
        this.getSalaryEdit(props.employeeGroup ? props.employeeGroup.id : "", props.employees.map(i => i.id), props.salaryPeriod.id);
        this.getWorkTimeAccountBalance();
    };

    componentDidMount(): void {
        this.refreshView();
    }

    componentDidUpdate(prevProps: ISalaryEditProps, prevState: ISalaryEditState): void {
        const props = this.props;
        const state = this.state;
        // Salary parameters
        const prevSalaryPeriodId = prevProps.salaryPeriod ? prevProps.salaryPeriod.id : "";
        const salaryPeriodId = props.salaryPeriod ? props.salaryPeriod.id : "";
        const prevEmployeeRowIds = prevProps.employees.map(i => i.rowId);
        const employeeRowIds = props.employees.map(i => i.rowId);
        const prevEmployeeIds = prevProps.employees.map(i => i.id);
        const employeeIds = props.employees.map(i => i.id);
        const prevEmployeeGroupId = prevProps.employeeGroup ? prevProps.employeeGroup.id : "";
        const employeeGroupId = props.employeeGroup ? props.employeeGroup.id : "";
        const salaryParametersChanged = !(prevSalaryPeriodId === salaryPeriodId &&
            prevEmployeeGroupId === employeeGroupId &&
            prevEmployeeIds.join("#") === employeeIds.join("#") &&
            prevEmployeeRowIds.join("#") === employeeRowIds.join("#"));
        if (salaryParametersChanged) {
            this.getSalaryEdit(employeeGroupId, employeeIds, salaryPeriodId);
            this.getWorkTimeAccountBalance();
        };
        // Work time account changes
        const ownerParameters = new OwnerParameters();
        const newWorkTimeAccountIsInUse = ownerParameters.getWorkTimeAccountInUse();
        const newWorkTimeAccountTransactionDepositSalaryRowTypeCode = ownerParameters.getWorkTimeAccountTransactionDepositSalaryRowTypeCode();
        const newWworkTimeAccountTransactionWithdrawSalaryRowTypeCode = ownerParameters.getWorkTimeAccountTransactionWithdrawSalaryRowTypeCode();
        const ownerWorkTimeAccountSettingsChanged = !(state.workTimeAccountIsInUse === newWorkTimeAccountIsInUse &&
            state.workTimeAccountTransactionDepositSalaryRowTypeCode === newWorkTimeAccountTransactionDepositSalaryRowTypeCode &&
            state.workTimeAccountTransactionWithdrawSalaryRowTypeCode === newWworkTimeAccountTransactionWithdrawSalaryRowTypeCode);
        if (ownerWorkTimeAccountSettingsChanged) {
            this.setState({
                workTimeAccountIsInUse: newWorkTimeAccountIsInUse,
                workTimeAccountTransactionDepositSalaryRowTypeCode: newWorkTimeAccountTransactionDepositSalaryRowTypeCode,
                workTimeAccountTransactionWithdrawSalaryRowTypeCode: newWworkTimeAccountTransactionWithdrawSalaryRowTypeCode
            });
        }
    }
    // #region Saving

    createSaveData = (salaryPeriodId: string, employeeIds: string[], salaries: ISalaryEditItem[], workTimeAccountTransaction: IWorkTimeAccountTransactionSaveData): SaveData => {
        const data = new SaveData();
        data.append("salaryPeriodId", salaryPeriodId);
        data.append("employeeIds", JSON.stringify(employeeIds));
        data.append("salaries", JSON.stringify(salaries.map(i => new SalaryEditItemSave(i))));
        data.append("workTimeAccountTransaction", workTimeAccountTransaction ? JSON.stringify(workTimeAccountTransaction) : "");
        return data;
    };

    getSaveData = (validate: boolean): Promise<SaveData> => {
        const props = this.props;
        const state = this.state;
        if (props.employeeGroup) {
            return new Promise<SaveData>((resolve) => { resolve(this.createSaveData(props.salaryPeriod.id, props.employees.map(i => i.id), state.salaries, null)); });
        } else if (this.salaryEditEmployeeSalaryFormRef && this.salaryEditEmployeeSalaryFormRef.current) {
            return (validate ? this.salaryEditEmployeeSalaryFormRef.current.getValidatedSaveSalary() : this.salaryEditEmployeeSalaryFormRef.current.getSaveSalary())
                .then(salary => {
                    if (!salary) return null;
                    const saveWorkTimeAccountTransaction = state.workTimeAccountIsInUse && state.workTimeAccountIsInUseForEmployee && !Base.isEqualInteger(state.workTimeAccountTransaction.amount, 0);
                    return this.createSaveData(props.salaryPeriod.id, props.employees.map(i => i.id), [salary], saveWorkTimeAccountTransaction ? state.workTimeAccountTransaction : null);
                });
        }
    };

    saveSalary = (proceedCallback: () => void = null) => {
        const obj = this;
        this.getSaveData(true)
            .then(saveData => {
                if (!saveData) return;
                // Call server
                store.customStore.dispatch(storeActions.fetchStart());
                salaryService.saveSalaryEdit(saveData.formData)
                    .then(success => {
                        store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                        obj.orgStateHash = saveData.hash;
                        if (proceedCallback) {
                            proceedCallback();
                        }
                    })
                    .catch(error => {
                        store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                    })
                    .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
            });
    };

    cancelSalary = (proceedCallback: () => void) => {
        proceedCallback();

        // const obj = this;
        // this.getSaveData(false)
        //     .then(saveData => {
        //         if (!saveData) return;
        //         if (saveData && saveData.hash !== this.orgStateHash) {
        //             store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
        //                 () => {
        //                     store.customStore.dispatch(storeActions.clearConfirmation());
        //                     obj.saveSalary(proceedCallback);
        //                 },
        //                 () => {
        //                     store.customStore.dispatch(storeActions.clearConfirmation());
        //                     proceedCallback();
        //                 },
        //                 () => {
        //                     store.customStore.dispatch(storeActions.clearConfirmation());
        //                 }));
        //         } else {
        //             proceedCallback();
        //         }
        //     });
    };
    // #endregion Saving

    // #region General
    handlePrevious = () => {
        const props = this.props;
        this.cancelSalary(() => {
            props.onChangeSalaryPeriod(props.salaryPeriod.previousId);
        });
    };

    handleNext = () => {
        const props = this.props;
        this.cancelSalary(() => {
            props.onChangeSalaryPeriod(props.salaryPeriod.nextId);
        });
    };

    // handleSave = () => {
    //     const obj = this;
    //     this.saveSalary(() => {
    //         obj.refreshView();
    //     });
    // };

    handleCalculate = () => {
        const obj = this;
        const props = this.props;
        store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.DoYouReallyWantToCalculateSalaryAndDiscardAllCurrentData,
            () => {
                store.customStore.dispatch(storeActions.clearConfirmation());
                obj.getCalculatedSalary(props.employeeGroup ? props.employeeGroup.id : "", props.employees.map(i => i.id), props.salaryPeriod.startDate, props.salaryPeriod.endDate);
            },
            () => {
                store.customStore.dispatch(storeActions.clearConfirmation());
            },
            () => {
                store.customStore.dispatch(storeActions.clearConfirmation());
            }));
    };

    // #region alaryTransferToSystemDialog
    handleShowSalaryTransferToSystemDialog = () => {
        const props = this.props;
        const state = this.state;
        if (!state.salaryFileGenerationInUse && !props.salaryTransferEnabled && state.salaries.length < 1) return;
        this.cancelSalary(() => {
            this.setState({
                showSalaryTransferToSystemDialog: true,
                salaryTransferToSystemDialogItems: state.salaries.map(i => new SalaryEditItem(i))
            });
        });
    };

    handleSetTransferDataToSalaries = (salaryIds: string[]) => {
        if (salaryIds.length < 1) return;
        const state = this.state;
        const salaries = state.salaries.slice(0);
        const nowTime = new Date().getTime();
        for (let i = 0; i < salaries.length; i++) {
            if (salaryIds.indexOf(salaries[i].id) < 0) continue;
            const salary = new SalaryEditItem(salaries[i]);
            salary.transferDate = nowTime;
            salaries[i] = salary;
        }
        this.setState({
            salaries
        });
    };

    handleSalaryTransferToSystemDialogClose = () => {
        this.setState({
            showSalaryTransferToSystemDialog: false
        });
    };
    // #endregion alaryTransferToSystemDialog

    handleWorkTimeDialogClick = () => {
        this.setState({
            showWorkTimeAccoundDialog: true
        });
    };

    handleWorkTimeDialogClose = () => {
        this.setState({
            showWorkTimeAccoundDialog: false
        });
    };

    getWorkTimeAccountTransaction = async(amount: number, transactionType: WorkTimeAccountTransactionType, previousAmount: number) => {
        const props = this.props;
        const state = this.state;
        const depositSalaryRowType = props.salaryRowTypes.find(i => i.code === state.workTimeAccountTransactionDepositSalaryRowTypeCode);
        const withdrawSalaryRowType = props.salaryRowTypes.find(i => i.code === state.workTimeAccountTransactionWithdrawSalaryRowTypeCode);
        if (!state.workTimeAccountIsInUse || !state.workTimeAccountIsInUseForEmployee || !depositSalaryRowType || !withdrawSalaryRowType || state.salaries.length < 1) return;
        const salaries = state.salaries.slice(0);
        const salary = await this.salaryEditEmployeeSalaryFormRef.current.getSaveSalary();
        salaries[0] = salary;
        const depositSalaryRow = salary.rows.find(row => row.salaryRowTypeId === depositSalaryRowType.id);
        const withdrawSalaryRow = salary.rows.find(row => row.salaryRowTypeId === withdrawSalaryRowType.id);
        if (!depositSalaryRow || !withdrawSalaryRow) return;
        const oldTransactionAmount = Base.isEqualInteger(previousAmount, 0) ? state.workTimeAccountTransaction.amount : previousAmount;
        if (oldTransactionAmount > 0) {
            depositSalaryRow.amount = depositSalaryRow.amount + oldTransactionAmount;
        }
        if (oldTransactionAmount < 0) {
            withdrawSalaryRow.amount = withdrawSalaryRow.amount + oldTransactionAmount;
        }
        let transactionAmount = 0;
        switch (transactionType) {
            case WorkTimeAccountTransactionType.Deposit:
                depositSalaryRow.amount = depositSalaryRow.amount - amount;
                transactionAmount = amount;
                break;
            case WorkTimeAccountTransactionType.Withdraw:
                withdrawSalaryRow.amount = withdrawSalaryRow.amount + amount;
                transactionAmount = -1 * amount;
                break;
            default:
        }
        this.setState({
            salaries: salaries,
            workTimeAccountTransaction: { salaryId: salary.id, amount: transactionAmount, description: "" },
            workTimeAccountDialogInitializeFromDatabase: false
        });
    };

    getWorkTimeAccountBalance = () => {
        store.customStore.dispatch(storeActions.fetchStart());
        return workTimeAccountService.getWorkTimeAccountBalance(this.props.employees.length > 0 ? this.props.employees[0].id : "")
            .then(balance => {
                this.setState({ workTimeAccountBalance: balance.balance });
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    // #endregion General

    // #region List
    handleClick = (id: string) => {
        this.setState({
            selectedId: id,
        });
    };
    // #endregion List

    render() {
        const props = this.props;
        const state = this.state;
        const workHourBalanceEnabled = (!props.employeeGroup &&
            state.workTimeAccountIsInUse &&
            state.workTimeAccountIsInUseForEmployee);
        return (
            <div className="salaryEdit">
                <div className="row">
                    <div className="col-3">
                        <div className="form-group">
                            <label className="control-label smallFont">{Translations.SalaryPeriod}</label>
                            <div>
                                <div className="left timeText">{props.salaryPeriod.startDate > 0 ? Base.utcTimeToDateStr(props.salaryPeriod.startDate) + "-" + Base.utcTimeToDateStr(props.salaryPeriod.endDate - 1) : ""}</div>
                                <ToolButton
                                    title={Translations.Previous}
                                    enabled={props.salaryPeriod.previousId !== Base.emptyGuid}
                                    classes={"round left previous"}
                                    onClick={this.handlePrevious}
                                />
                                <ToolButton
                                    title={Translations.Next}
                                    enabled={props.salaryPeriod.nextId !== Base.emptyGuid}
                                    classes={"round left next"}
                                    onClick={this.handleNext}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="form-group">
                            <label className="control-label smallFont">{props.employeeGroup ? Translations.EmployeeGroup : Translations.Employee}</label>
                            <div>{props.employeeGroup ? props.employeeGroup.name : (props.employees.length > 0 ? props.employees[0].name : "")}</div>
                        </div>
                    </div>
                    {workHourBalanceEnabled &&
                        <div className="col-3">
                            <div className="form-group">
                                <div className="left">
                                    <label className="control-label smallFont">{Translations.WorkHourBalance}</label>
                                    <div>{state.workTimeAccountBalance}</div>
                                </div>
                                <Button
                                    classes={"right btn-default"}
                                    title={Translations.TransferWorkHours}
                                    enabled={true}
                                    onClick={this.handleWorkTimeDialogClick}
                                />
                            </div>
                        </div>
                    }
                    {/* <div className={workHourBalanceEnabled ? "col-4" : "col-7"}>
                        <div className="form-group">
                            <Button
                                classes={"right btn-primary"}
                                title={Translations.Save}
                                enabled={true}
                                onClick={this.handleSave}
                            />
                            <Button
                                classes={"right btn-default"}
                                title={Translations.Calculate}
                                enabled={true}
                                onClick={this.handleCalculate}
                            />
                            {(state.salaryFileGenerationInUse || props.salaryTransferEnabled) &&
                                <Button
                                    classes={"right btn-default"}
                                    title={Translations.TransferToBePaid}
                                    enabled={!state.hasNewSalaries}
                                    onClick={this.handleShowSalaryTransferToSystemDialog}
                                />
                            }
                        </div>
                    </div> */}
                </div>
                {(props.employeeGroup
                    ? <SalaryEditGroupSalaryRows
                            salaryRowTypes={props.salaryRowTypes}
                            salaries={state.salaries}
                            selectedId={state.selectedId}
                            onClick={this.handleClick}
                            onGotoEmployee={props.onChangeEmployee}
                      />
                    : <SalaryEditEmployeeSalaryForm
                            ref={this.salaryEditEmployeeSalaryFormRef}
                            salary={state.salaries.length > 0 ? state.salaries[0] : null}
                            workTimeAccountTransaction={state.workTimeAccountTransaction}
                      />
                )}
                {state.showWorkTimeAccoundDialog &&
                    <WorkTimeAccountDialog
                        initializeFromDatabase={state.workTimeAccountDialogInitializeFromDatabase}
                        onClose={this.handleWorkTimeDialogClose}
                        employeeId={props.employees.length > 0 ? props.employees[0].id : ""}
                        salaryId={state.salaries.length > 0 ? state.salaries[0].id : ""}
                        getWorkTimeAccountTransaction={this.getWorkTimeAccountTransaction}
                        workTimeAccountBalance={state.workTimeAccountBalance}
                        amount={state.workTimeAccountTransaction.amount}
                    />
                }
                {state.showSalaryTransferToSystemDialog &&
                    <SalaryTransferToSystemDialog
                        salaryTransferEnabled={props.salaryTransferEnabled}
                        salaryDownloadEnabled={state.salaryFileGenerationInUse}
                        showTransferred={!props.employeeGroup}
                        items={state.salaryTransferToSystemDialogItems}
                        onSetTransferDataToSalaries={this.handleSetTransferDataToSalaries}
                        onCancel={this.handleSalaryTransferToSystemDialogClose}
                    />
                }
            </div>
        );
    }
}
