import { TransportOrderListItemDto } from "../models/transport/transportOrder";
import { TransportPlanListItemDto, TransportPlanListQueryParameters } from "../models/transport/transportPlan";
import { apiCall } from "./apiClient";

export const getTransportPlans = async(params) => {
    const response = await apiCall<TransportPlanListItemDto[], TransportPlanListQueryParameters>("TransportPlans", "GET", params);
    return response.data;
};

export const getTransportPlan = async(transportPlanId) => {
    const response = await apiCall<TransportPlanListItemDto[], TransportPlanListQueryParameters>(`TransportPlans/${transportPlanId}`, "GET");
    return response.data;
};

export const updatePlanState = async(params) => {
    const response = await apiCall<TransportOrderListItemDto[]>(`TransportPlans/${params.planId}/State/${params.newState}`, "PUT");
    return response.data;
};
