import { IAcknowledgementEditItem, AcknowledgementEditItem } from "./acknowledgementEditItem";

export interface IAcknowledgementMain {
    ownerLogo: string;
    canSendMessage: boolean;
    acknowledgement: IAcknowledgementEditItem;
}

export class AcknowledgementMain implements IAcknowledgementMain {
    ownerLogo: string;
    canSendMessage: boolean;
    acknowledgement: AcknowledgementEditItem;

    constructor();
    constructor(obj: IAcknowledgementMain);
    constructor(obj?: any) {
        this.ownerLogo = obj && obj.ownerLogo || "";
        this.canSendMessage = obj && obj.canSendMessage || false;
        this.acknowledgement = null;
        if (obj && obj.acknowledgement) {
            this.acknowledgement = new AcknowledgementEditItem(obj.acknowledgement);
        }
    }
}