import { EnumHelper, WorkOrderCategory, WorkOrderState } from "../common/enums";
import { Base } from "../../framework/base";

/* eslint-disable no-unused-vars */
export const enum WorkOrderDragDataType {
    WorkOrder = "WorkOrder",
    Employee = "Employee",
    Vehicle = "Vehicle",
}
/* eslint-enable no-unused-vars */

export interface IWorkOrderDragData {
    dataId: string;
    type: WorkOrderDragDataType;
    id: string;
    startTime: number;
    employeeId: string;
    vehicleId: string;
    state: number;
    category: number;
    hasChildren: boolean;
    width: number;

    isWorkOrderType(): boolean;
    isEmployeeType(): boolean;
    isVehicleType(): boolean;
    isPreliminary(): boolean;
    isLessThanInProgress(): boolean;
    isProject(): boolean;
    isWork(): boolean;
    isTask(): boolean;
}

export class WorkOrderDragData implements IWorkOrderDragData {
    static dataKey = "dragdata#";
    static dataCache: WorkOrderDragData[] = [];

    dataId: string;
    type: WorkOrderDragDataType;
    id: string;
    startTime: number;
    employeeId: string;
    vehicleId: string;
    state: number;
    category: number;
    hasChildren: boolean;
    width: number;

    constructor();
    constructor(obj: IWorkOrderDragData);
    constructor(obj?: any) {
        this.dataId = obj && obj.dataId || "";
        this.type = obj && obj.type || "";
        this.id = obj && obj.id || "";
        this.startTime = obj && obj.startTime || null;
        this.employeeId = obj && obj.employeeId || "";
        this.vehicleId = obj && obj.vehicleId || "";
        this.state = obj && obj.state || 0;
        this.category = obj && obj.category || 0;
        this.hasChildren = obj && obj.hasChildren || false;
        this.width = obj && obj.width || 0;
    }

    static addToCache(data: WorkOrderDragData): string {
        data.dataId = Base.getGuid();
        WorkOrderDragData.dataCache = [data];
        return data.dataId;
    }

    static getFromCache(dataId: string): WorkOrderDragData {
        return WorkOrderDragData.dataCache.find(i => i.dataId === dataId);
    }

    static removeFromCache(dataId: string) {
        WorkOrderDragData.dataCache = WorkOrderDragData.dataCache.filter(i => i.dataId !== dataId);
    }

    static setWorkOrderDragData(ev: any, itemType: WorkOrderDragDataType, id: string, state: number, category: number, employeeId: string, vehicleId: string, startTime: number, hasChildren: boolean, width: number) {
        const data = new WorkOrderDragData();
        data.type = itemType;
        data.id = id;
        data.state = state;
        data.category = category;
        data.employeeId = employeeId;
        data.vehicleId = vehicleId;
        data.startTime = startTime;
        data.hasChildren = hasChildren;
        data.width = width;
        const dataId = WorkOrderDragData.addToCache(data);
        ev.dataTransfer.setData(WorkOrderDragData.dataKey + dataId, dataId);
    }

    static getWorkOrderDragData(ev: any): IWorkOrderDragData {
        const eventType = ev.dataTransfer.types.find(i => i && i.indexOf(WorkOrderDragData.dataKey) > -1);
        if (!eventType) return new WorkOrderDragData();
        const values = eventType.split("#");
        if (values.length < 2) return new WorkOrderDragData();
        return WorkOrderDragData.getFromCache(values[1]);
    }

    isWorkOrderType(): boolean {
        return this.type === WorkOrderDragDataType.WorkOrder;
    }

    isEmployeeType(): boolean {
        return this.type === WorkOrderDragDataType.Employee;
    }

    isVehicleType(): boolean {
        return this.type === WorkOrderDragDataType.Vehicle;
    }

    isPreliminary(): boolean {
        return EnumHelper.isEqual(this.state, WorkOrderState.Preliminary);
    }

    isLessThanInProgress(): boolean {
        return EnumHelper.isLessThan(this.state, WorkOrderState.InProgress);
    }

    isProject(): boolean {
        return EnumHelper.isEqual(this.category, WorkOrderCategory.Project);
    }

    isWork(): boolean {
        return EnumHelper.isEqual(this.category, WorkOrderCategory.Work);
    }

    isTask(): boolean {
        return EnumHelper.isEqual(this.category, WorkOrderCategory.Task);
    }
}