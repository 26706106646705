import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { TransportPlanState } from "../../models/transport/transportPlan";

const DEFAULT_TIMERANGE = 14;

interface TransportPlansState {
    timeRange: [Date, Date];
    selectedVehicles: string[];
    selectedDrivers: string[];
    selectedPlanStates: Record<TransportPlanState, boolean>;
}

const initialState: TransportPlansState = {
    timeRange: [
        moment().startOf("week").toDate(),
        moment().startOf("week").add(DEFAULT_TIMERANGE, "days").toDate(),
    ],
    selectedVehicles: [],
    selectedDrivers: [],
    selectedPlanStates: {
        [TransportPlanState.Todo]: true,
        [TransportPlanState.Planning]: true,
        [TransportPlanState.Planned]: true,
        [TransportPlanState.InTransport]: true,
        [TransportPlanState.Completed]: true,
    }
};

export const transportPlansSlice = createSlice({
    name: "transportPlans",
    initialState,
    reducers: {
        setTimeRange(state, action: PayloadAction<[Date, Date]>) {
            state.timeRange = action.payload;
        },
        setVehicles(state, action: PayloadAction<string[]>) {
            state.selectedVehicles = action.payload;
        },
        setDrivers(state, action: PayloadAction<string[]>) {
            state.selectedDrivers = action.payload;
        },
        setPlanStates(state, action: PayloadAction<Partial<TransportPlansState["selectedPlanStates"]>>) {
            state.selectedPlanStates = { ...state.selectedPlanStates, ...action.payload };
        },
        togglePlanStateSelected(state, action: PayloadAction<TransportPlanState>) {
            state.selectedPlanStates[action.payload] = !state.selectedPlanStates[action.payload];
        }
    },
});

export const {
    setTimeRange,
    setVehicles,
    setDrivers,
    togglePlanStateSelected,
} = transportPlansSlice.actions;

export const transportPlansReducer = transportPlansSlice.reducer;