import { useEffect, useRef } from "react";

interface Args {
    handleInView?: () => void;
    handleOutOfView?: () => void;
}

export function useInterceptionObserver(args: Args) {
    const observer = useRef<IntersectionObserver>(null);
    const argsRef = useRef<Args>();
    const elementRef = useRef<HTMLElement>();

    useEffect(() => {
        argsRef.current = args;
    }, [args]);

    function startObserve() {
        if (elementRef.current) {
            // Use setTimeout to allow any pending layout changes to happen
            // before observation begins, and to throttle observations a bit.
            setTimeout(() => {
                observer.current?.observe(elementRef.current);
            }, 2000);
        }
    }

    function setRootRef(node: HTMLElement) {
        observer.current?.disconnect();
        if (node) {
            observer.current = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        argsRef.current?.handleInView?.();
                    } else {
                        argsRef.current?.handleOutOfView?.();
                    }
                });
            }, { root: node });
            startObserve();
        }
    }

    function setElementRef(node: HTMLElement) {
        if (elementRef.current) {
            observer.current?.observe(elementRef.current);
        }
        if (node) {
            elementRef.current = node;
            startObserve();
        }
    }

    return { setElementRef, setRootRef };
}