export class ReportingQueryResponse {
    items: ReportingItems[];
    columnDefination: [];
}

export class ReportingItems {
    amount: number;
    category: number;
    categoryName: string;
    employeeGroupId: string;
    employeeId: string;
    id: number;
    name: string;
    ownerId: string;
    total: number;
}