import {  getApiCall } from "./tokenService";
import { WorkTimePageMain } from "../models/workTime/workTimePageMain";
import { WorkTimePageMainEmployeesAndGroups } from "../models/workTime/workTimePageMainEmployeesAndGroups";

export const getWorkTimePageMain = (): Promise<WorkTimePageMain> => {
    return getApiCall<WorkTimePageMain>("api/worktime/worktimepagemain", WorkTimePageMain);
};

export const getWorkTimePageMainEmployeesAndGroups = (): Promise<WorkTimePageMainEmployeesAndGroups> => {
    return getApiCall<WorkTimePageMainEmployeesAndGroups>("api/worktime/worktimepagemainemployeesandemployeegroups", WorkTimePageMainEmployeesAndGroups);
};
