import * as React from "react";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as integrationService from "../../services/integrationService";
import { handleApiError } from "../../models/store/storeEffects";
import { IOwnerState, IUserState } from "../../models/store/storeTypes";

export interface ITelematicsMainOwnProps {
}

export interface ITelematicsMainStateProps {
    owner: IOwnerState;
    user: IUserState;
}

type TelematicsMainProp = ITelematicsMainOwnProps & ITelematicsMainStateProps;

interface ITelematicsMainState {
    idpResponse: string;
    ssoUrl: string;
}

export class TelematicsMain extends React.Component<TelematicsMainProp, ITelematicsMainState> {
    private formElement: HTMLFormElement;

    constructor(props) {
        super(props);
        this.state = {
            idpResponse: "",
            ssoUrl: "",
        };
    }

    getUser = () =>{
        const appState = store.customStore.getState();
        return appState.user;
    };
    getIdpResponse = () => {
        const obj = this;
        const user = this.getUser();
        if (!user || !user.ssoIntegrations || user.ssoIntegrations.length < 1) return;
        const ssoIntegration = user.ssoIntegrations[0];

        store.customStore.dispatch(storeActions.fetchStart());
        integrationService.getIntegrationSsoIdpResponse(ssoIntegration.serviceName)
            .then(idpResponse => {
                obj.setState({
                    idpResponse: idpResponse,
                    ssoUrl: ssoIntegration.url
                });
                if (idpResponse && obj.formElement) {
                    window.setTimeout(() => {
                        obj.formElement.submit();
                    }, 100);
                }
            },
            error => {
                handleApiError(error, store.customStore.dispatch);
            })
            .finally(() => {
                store.customStore.dispatch(storeActions.fetchEnd());
            });
    };

    componentDidMount(): void {
        this.getIdpResponse();
    }

    render() {
        const state = this.state;
        return (
            <div>
                <div className="row mainContent telematicsMain">
                    <div className="col-12">
                        <div className="backgroundBoard">
                            <form action={state.ssoUrl} name="myForm" method="post" target="_iframe" ref={(elem) => { this.formElement = elem; }}>
                                <input name="RelayState" value={state.ssoUrl} />
                                <input name="SAMLResponse" value={state.idpResponse} />
                            </form>
                            <iframe name="_iframe" className="backgroundContent" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
