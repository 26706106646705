import * as CustomStore from "../../framework/customStore";
import * as StoreActions from "../store/storeActions";
import * as contactService from "../../services/contactService";
import * as BaseService from "../../services/baseService";
import { Base } from "../../framework/base";
import { IContactEdit } from "./contactEdit";

export class ContactOperations {
    // #region Edit
    static getContactEdit(id: string): Promise<IContactEdit> {
        CustomStore.customStore.dispatch(StoreActions.fetchStart());
        return contactService.getContactEdit(id)
            .then(editItem => {
                return editItem;
            })
            .catch(error => {
                CustomStore.customStore.dispatch(StoreActions.showErrorMessage(BaseService.getErrorMessageFromError(error)));
                return Base.getPromiseResult(null);
            })
            .finally(() => CustomStore.customStore.dispatch(StoreActions.fetchEnd()));
    }
    // #endregion Edit
}