import * as React from "react";
import { Translations } from "../../../models/translations";
import { EditDialog, EditDialogProps, ExposedFunctions } from "../../framework/editDialog";
import {
    defaultFormikConfig,
    formatString,
    formikFieldProps,
    showApiError,
    showConfirm
} from "../../framework/formUtils";
import { StorageEditItem } from "../../../models/storage/storage";
import { Button, TextField } from "@mui/material";
import { useRef } from "react";
import { createStorageSite, deleteStorage, updateStorageSite } from "../../../services/storageService";
import { SiteResponseMessage } from "../../../models/storage/siteResponseMessage";
import * as store from "../../../framework/customStore";
import * as StoreActions from "../../../models/store/storeActions";
import * as baseService from "../../../services/baseService";
import Grid2 from "@mui/material/Unstable_Grid2";
import { STYLE_CONSTANTS } from "../../../framework/theme";
import { useFormik } from "formik";
import * as yup from "yup";
import { IApiError } from "../../../services/baseService";

interface Props extends EditDialogProps<StorageEditItem> {
    hasStorageLocations: boolean;
}

export const StorageDialog = ({ item, hasStorageLocations, open, onSave, onClose, onRemove }: Props) => {
    const dialogRef = useRef<ExposedFunctions>();

    const handleSubmit = (formData: StorageEditItem) => {
        const onSubmitSuccess = (res: SiteResponseMessage) => {
            formik.setSubmitting(false);
            void formik.setFieldValue("id", res.storageId);
            void formik.setFieldValue("siteId", res.siteId);
            void formik.setFieldValue("siteRowId", res.rowId);
            dialogRef.current.setInitialFormDataFromForm();
            store.customStore.dispatch(StoreActions.showSuccessMessage(Translations.SaveSuccess));

            if (onSave) {
                onSave(dialogRef.current.getInitialFormData() as StorageEditItem);
            }
        };

        const onSubmitFailure = (error: IApiError) => {
            store.customStore.dispatch(StoreActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            formik.setSubmitting(false);
        };

        if (!formData.id) {
            createStorageSite(formData)
                .then(onSubmitSuccess)
                .catch(onSubmitFailure);
        } else {
            updateStorageSite(formData)
                .then(onSubmitSuccess)
                .catch(onSubmitFailure);
        }
    };

    const formik = useFormik<StorageEditItem>({
        ...defaultFormikConfig,
        initialValues: item ?? new StorageEditItem(),
        validationSchema: yup.object({
            name: yup.string()
                .required(formatString(Translations.FieldIsRequired, Translations.Name))
                .max(50, formatString(Translations.FieldMaxLengthError, Translations.Name.toLowerCase(), 50)),
            street: yup.string()
                .max(50, formatString(Translations.FieldMaxLengthError, Translations.StreetAddress.toLowerCase(), 50)),
            postalCode: yup.string()
                .max(10, formatString(Translations.FieldMaxLengthError, Translations.PostalCode.toLowerCase(), 10)),
            city: yup.string()
                .max(50, formatString(Translations.FieldMaxLengthError, Translations.CityAddress.toLowerCase(), 50)),
            countryCode: yup.string()
                .max(50, formatString(Translations.FieldMaxLengthError, Translations.CountryCode.toLowerCase(), 50)),
        }),
        onSubmit: handleSubmit,
    });

    const handleRemove = () => {
        if (hasStorageLocations) {
            store.customStore.dispatch(StoreActions.showErrorMessage(Translations.CanNotDeleteStorage));
            return;
        }

        const submitRemove = () => void deleteStorage(formik.values.siteId)
            .then(() => {
                store.customStore.dispatch(StoreActions.showSuccessMessage(Translations.DeleteWasSuccess));
                onRemove();
            })
            .catch(showApiError);

        showConfirm(Translations.AreYouSureWantDelete, submitRemove);
    };

    const primaryActions = [
        <Button variant="save" disabled={formik.isSubmitting} onClick={() => void formik.submitForm()} key="btn-save">
            {Translations.Save}
        </Button>,
    ];

    if (formik.values.id !== null) {
        primaryActions.push(
            <Button variant="outlined" disabled={formik.isSubmitting} color="error" onClick={handleRemove} key="btn-remove">
                {Translations.Remove}
            </Button>
        );
    }

    return (
        <EditDialog
            open={open}
            title={formik.values.id === null ? Translations.CreateNewStorage : Translations.EditStorage}
            formik={formik}
            onClose={onClose}
            onRemove={onClose}
            primaryActions={primaryActions}
            ref={dialogRef}
            body={
                <Grid2 container direction="column" spacing={STYLE_CONSTANTS.formSpacing} sx={{ width: { xs: "100%", md: "auto" } }}>
                    <Grid2>
                        <TextField
                            label={Translations.Name}
                            fullWidth
                            required
                            autoComplete="stringToDisableAutoComplete"
                            {...formikFieldProps(formik, "name")}
                        />
                    </Grid2>

                    <Grid2>
                        <TextField
                            label={Translations.StreetAddress}
                            fullWidth
                            autoComplete="stringToDisableAutoComplete"
                            {...formikFieldProps(formik, "street")}
                        />
                    </Grid2>

                    <Grid2 container direction="row">
                        <Grid2 xs={6}>
                            <TextField
                                label={Translations.PostalCode}
                                {...formikFieldProps(formik, "postalCode")}
                            />
                        </Grid2>

                        <Grid2 xs={6}>
                            <TextField
                                label={Translations.CityAddress}
                                autoComplete="stringToDisableAutoComplete"
                                {...formikFieldProps(formik, "city")}
                            />
                        </Grid2>
                    </Grid2>

                    <Grid2 xs={6}>
                        <TextField
                            label={Translations.CountryCode}
                            autoComplete="stringToDisableAutoComplete"
                            {...formikFieldProps(formik, "countryCode")}
                        />
                    </Grid2>
                </Grid2>
            }
        />
    );
};
