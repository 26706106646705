import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { Dialog, DialogContent, DialogTitle, IconButton, Paper, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../framework/customStore";
import { EventLogItem } from "../../models/eventLog/eventLogItem";
import * as salaryPeriodService from "../../services/salaryPeriodService";
import { TableDef, TableWrapper } from "../framework/tableWrapper";

const SalariesEvents = () => {
    const [events, setEvents] = useState<EventLogItem[]>([]);
    const selectedSalaryPeriod = useAppSelector(state => state.workTimeBeta.fetchFilters.selectedSalaryPeriod);

    useEffect(() => {
        if (!selectedSalaryPeriod) {
            return;
        }
        const fetchEvents = async() => {
            try {
                const response = await salaryPeriodService.getSalaryPeriodEvents(selectedSalaryPeriod.id);
                setEvents(response);
            } catch (error) {
                console.error("Error fetching events:", error);
            }
        };

        void fetchEvents();
    }, [selectedSalaryPeriod]);

    const [detailsDialog, setDetailsDialog] = useState<EventLogItem | null>(null);

    const tableDef: TableDef<EventLogItem> = useMemo(() => ({
        rowsPerPage: 10,
        sortBy: { id: "time", desc: true },
        columns: [
            {
                id: "time",
                label: "Aika",
                accessor: "time",
                renderCell(val: string) {
                    return new Date(val).toLocaleString();
                },
                sortType: "datetime",
            },
            {
                id: "user",
                label: "Käyttäjä",
                accessor: "user",
            },
            {
                id: "Tapahtuma",
                label: "Tapahtuma",
                accessor: "message",
            },
            {
                id: "textData",
                label: "",
                renderCell(val, row) {
                    if (row.textData) {
                        return (
                            <IconButton onClick={() => setDetailsDialog(row)}>
                                <InfoIcon />
                            </IconButton>
                        );
                    }
                }
            }
        ],
    }), []);

    if (!selectedSalaryPeriod || events.length === 0) {
        return null;
    }

    return (
        <div>
            <Typography variant="h3">Tapahtumat</Typography>
            <TableWrapper tableDef={tableDef} data={events} />

            {detailsDialog && (
                <Dialog open={detailsDialog !== null} fullWidth maxWidth="md">
                    <DialogTitle>
                        {detailsDialog.message} {new Date(detailsDialog.time).toLocaleString()}
                        <IconButton
                            onClick={() => setDetailsDialog(null)}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Paper variant="outlined" sx={{ p: 1, bgcolor: "lightGrayBlue.main" }}>
                            <pre>{detailsDialog.textData}</pre>
                        </Paper>
                    </DialogContent>
                </Dialog>
            )}

        </div>
    );
};

export default SalariesEvents;