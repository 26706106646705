// LoadingIndicator
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";

export interface ILoadingIndicatorProp {
    tooltip?: string;
    classes?: string;
}

export class LoadingIndicator extends React.Component<ILoadingIndicatorProp, {}> {
    render() {
        const props = this.props;
        return (
            <img className={"loadingIndicator" + (props.classes ? " " + props.classes : "")} alt={Translations.Loading} title={props.tooltip ? props.tooltip : Translations.Loading} src={appConfig.ownRoot + "spinner-loader.gif"} />
        );
    }
}