// SettingsProductGroupDialogProductList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { IProductItem } from "../../models/product/productItem";

// SettingsProductGroupDialogProductListLine
export interface ISettingsProductGroupDialogProductListLineProp {
    item: IProductItem;
}

export class SettingsProductGroupDialogProductListLine extends React.Component<ISettingsProductGroupDialogProductListLineProp, {}> {
    render() {
        const item = this.props.item;
        return (
            <div className={"row line"} title={item.name} >
                <div className="col-12">{item.name}</div>
            </div>
        );
    }
}
// SettingsProductGroupDialogProductList
export interface ISettingsProductGroupDialogProductListProp {
    title?: string;
    items: IProductItem[];
}

export class SettingsProductGroupDialogProductList extends React.Component<ISettingsProductGroupDialogProductListProp, {}> {
    render() {
        const props = this.props;
        const items = props.items;
        return (
            <div>
                <div className="commandRow">
                    {!!props.title &&
                        <label className="control-label listTitle">{props.title}</label>
                    }
                </div>
                <div className="listContainer productsContainer">
                    <div className="list products" >
                        {items.map((item) =>
                            <SettingsProductGroupDialogProductListLine
                                key={item.id}
                                item={item}
                            />
                        )}

                    </div>
                </div>
            </div>
        );
    }
}