import { GRID_TREE_DATA_GROUPING_FIELD, GridColumnVisibilityModel } from "@mui/x-data-grid-premium";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface workTimeDetailsState {
    workTimeDetailsListShownColumns: GridColumnVisibilityModel;
    hoveredId: string | null;
};

export const initialState: workTimeDetailsState = {
    workTimeDetailsListShownColumns: {
        [GRID_TREE_DATA_GROUPING_FIELD]: false,
        automatic: true,
        startLocationName: true,
        startTime: true,
        endLocationName: true,
        timeZoneDisplayName: false,
        endTime: true,
        workOrderName: true,
        customerName: true,
        workTimeTypeName: true,
        salaryRowTypeName: true,
        comment: true,
        vehicleRegisterNumber: true,
        id: true,
    },
    hoveredId: null
};

export const workTimeDetailsSlice = createSlice({
    name: "workTimeDetails",
    initialState,
    reducers: {
        setShownColumns: (state, action: PayloadAction<GridColumnVisibilityModel>) => {
            state.workTimeDetailsListShownColumns = action.payload;
        },
        setHoveredId(state, action: PayloadAction<string | null>) {
            if(action.payload !== null) {
                action.payload = action.payload.split(".")?.[0]; // save parent row id
            }
            state.hoveredId = action.payload;
        },
    },
});

export const { setShownColumns, setHoveredId } = workTimeDetailsSlice.actions;
export const workTimeDetailsReducer = workTimeDetailsSlice.reducer;
