import React from "react";
import AppBar, { AppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

interface SectionHeaderProps {
    children: JSX.Element | JSX.Element[];
    position?: AppBarProps["position"];
}

export const SectionHeader = ({
    children,
    position = "relative",
}: SectionHeaderProps) => {
    return (
        <AppBar position={position} sx={{ bgcolor: "primary.dark" }}>
            <Toolbar variant="dense">{children}</Toolbar>
        </AppBar>
    );
};
