import React from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { TransportPlanListItemDto, TransportPlanState } from "../../../models/transport/transportPlan";
import { TransportSideBarEntitiesContainer } from "../components/sidebar/transportSidebarentitiesContainer";
import { transportOrderPickupAddress, transportDateTimeRange, transportOrderDeliveryAddress } from "../components/transportUtils";

interface TransportPlansSidebarProps {
    row: TransportPlanListItemDto | null;
    onClose: () => void;
}

export const TransportPlansSidebar = (props: TransportPlansSidebarProps) => {
    const { row, onClose } = props;

    return (row &&
        <Box fontSize="small" sx={{ position: "relative", p: 1, backgroundColor: "lightBlue.main", width: "100%", height: "100%" }}>
            <Stack spacing={2}>
                <div style={{ position: "absolute", right: -5, top: -5 }}>
                    <IconButton onClick={onClose} size="small">
                        <CloseIcon/>
                    </IconButton>
                </div>

                <Stack direction="row" alignItems="center">
                    {row.state !== TransportPlanState.InTransport && row.state !== TransportPlanState.Completed &&
                        <Link to={{ pathname: `/transport/plans/plan/${row.id}`, state: { fromPath: "/transport/plans/list" } }}>
                            <IconButton color="primary">
                                <EditIcon/>
                            </IconButton>
                        </Link>
                    }
                    <Typography variant="h2">{row.name}</Typography>
                </Stack>

                <div>
                    <div className="font-weight-bold">Tilaukset</div>

                    {row.orders.length === 0 && "Ei tilauksia"}
                    {row.orders.length > 0 && (
                        <Stack spacing={2}>
                            {row.orders.map(order => (
                                <TransportSideBarEntitiesContainer key={order.id}>
                                    <Link to={{ pathname: `/transport/orders/${order.id}`, state: { fromPath: "/transport/plans/list" } }}>
                                        #{order.orderNumber} {order.name}
                                    </Link>
                                    <div>{transportOrderPickupAddress(order)}</div>
                                    <div>{transportDateTimeRange(order.pickUpStartDateTime, order.pickUpEndDateTime)}</div>
                                    <Divider />
                                    <div>{transportOrderDeliveryAddress(order)}</div>
                                    <div>{transportDateTimeRange(order.deliveryStartDateTime, order.deliveryEndDateTime)}</div>
                                </TransportSideBarEntitiesContainer>
                            ))}
                        </Stack>
                    )}

                </div>

            </Stack>
        </Box>
    );
};
