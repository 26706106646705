// SettingsCustomerPriceDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import { PropertyDialog } from "../framework/dialog";
import { ICustomerItem } from "../../models/customer/customerItem";
import { IProductItem } from "../../models/product/productItem";
import { CustomerPriceEditItem, ICustomerPriceEditItem } from "../../models/customerPrice/customerPriceEditItem";
import { Base } from "../../framework/base";
import { PricePerUnit } from "../framework/pricePerUnit";

// SettingsCustomerPriceDialog
// ***********************************************************************************************************************
export interface ISettingsCustomerPriceDialogProp {
    classes?: string;
    customers: ICustomerItem[];
    products: IProductItem[];
    forNew: boolean;
    customerReadOnly?: boolean;
    productReadOnly?: boolean;
    customerPrice: ICustomerPriceEditItem;
    onOk: (item: ICustomerPriceEditItem) => void;
    onCancel: () => void;
}

export interface ISettingsCustomerPriceDialogState {
    productId: string;
    customerId: string;
    unitPrice: number;
    unitPriceStr: string;
}

export class SettingsCustomerPriceDialog extends React.Component<ISettingsCustomerPriceDialogProp, ISettingsCustomerPriceDialogState> {
    constructor(props: ISettingsCustomerPriceDialogProp) {
        super(props);
        const customerPrice = props.customerPrice;
        const customerId = customerPrice.customerId !== Base.emptyGuid ? customerPrice.customerId : this.props.customers[0].id;
        const productId = customerPrice.productId !== Base.emptyGuid ? customerPrice.productId : this.props.products[0].id;
        this.state = {
            productId: productId,
            customerId: customerId,
            unitPrice: customerPrice.unitPrice,
            unitPriceStr: customerPrice.unitPrice.toLocaleFixed(Base.getDecimalAmountForPrice(customerPrice.unitPrice))
        };
    }

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "unitPrice") {
            this.setState({ unitPriceStr: value });
        } else if (name === "productId") {
            this.setState({ productId: value });
        } else if (name === "customerId") {
            this.setState({ customerId: value });
        }
    };

    handleBlur = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "unitPrice") {
            this.setState({ unitPriceStr: value.toDecimal().toLocaleFixed(Base.getDecimalAmountForPrice(value.toDecimal())) });
        }
    };

    handleOkClick = () => {
        const item = new CustomerPriceEditItem(this.props.customerPrice);
        item.productId = this.state.productId;
        item.customerId = this.state.customerId;
        item.unitPrice = this.state.unitPriceStr.toDecimal();
        this.props.onOk(item);
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const dialogClasses = "settings customerPrice px600" + (props.classes ? " " + props.classes : "");
        const product = props.products.find(p => p.id === this.state.productId);
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.CustomerPrice + (!props.customerPrice.isNew() ? "" : " - " + Translations.Add)}
                    show={true}
                    body={<div>
                        <div className="">
                            <div>
                                <div>
                                    {!props.customerReadOnly &&
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group required">
                                                    <label className="control-label smallFont">{Translations.Customer}</label>
                                                    <select className="custom-select" name="customerId" title={Translations.Customer} value={this.state.customerId} onChange={this.handleChange} disabled={!props.forNew}>
                                                        {props.customers.map((customer) =>
                                                            <option key={customer.id} value={customer.id}>{customer.name}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {!props.productReadOnly &&
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group required">
                                                    <label className="control-label smallFont">{Translations.Product}</label>
                                                    <select className="custom-select" name="productId" title={Translations.Product} value={this.state.productId} onChange={this.handleChange} disabled={!props.forNew}>
                                                        {props.products.map((product) =>
                                                            <option key={product.id} value={product.id}>{product.name}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group required">
                                                <label className="control-label smallFont">
                                                    {Translations.UnitPrice}&nbsp;
                                                    <PricePerUnit unit={product?.unit} wrapInParenthesis={true} />
                                                </label>
                                                <input type="text" className="form-control" name="unitPrice" title={Translations.UnitPrice} value={this.state.unitPriceStr} onChange={this.handleChange} onBlur={this.handleBlur} maxLength={6} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}
