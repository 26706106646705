import React, { useState } from "react";
import {
    Button,
    TextField,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { apiCall } from "../../../services/apiClient";
import { customHistory } from "../../../framework/customHistory";
import { Translations } from "../../../models/translations";
import { useAppDispatch } from "../../../framework/customStore";
import { fetchEnd, fetchStart, showErrorMessage, showSuccessMessage } from "../../../models/store/storeActions";
import { showApiError } from "../../framework/formUtils";
import { CustomerSelect } from "../components/customerSelect";
import { InvoiceCreateDto } from "../../../models/transportOrderInvoice/invoiceCreateDto";
import { InvoiceDetailsDto } from "../../../models/transportOrderInvoice/invoiceDetailsDto";
import { CustomerDetails } from "./customerDetails";
import { TransportOrderInvoiceDetails } from "./TransportOrderInvoiceDetails";

export const TransportOrderInvoiceNew = () => {
    const [reference, setReference] = useState("");
    const [message, setMessage] = useState("");
    const [invoiceCustomer, setInvoiceCustomer] = useState("");

    const dispatch = useAppDispatch();
    const newInvoiceData: InvoiceCreateDto = {
        reference: reference,
        customerId: invoiceCustomer,
        invoiceMessage: message,
    };
    const handleReferenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReference(event.target.value);
    };
    const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(event.target.value);
    };

    const handleCreateInvoice = () => {
        dispatch(fetchStart());
        apiCall<InvoiceDetailsDto>("Invoices", "POST", newInvoiceData)
            .then((response) => {
                if (response.data && response.data.id) {
                    dispatch(showSuccessMessage(Translations.InvoiceInformationSaveSuccess));
                    customHistory.push(
                        `/invoicingbeta/transportorderinvoice/${response.data.id}`,
                        { from: "/invoicingbeta/transportorderinvoice/new" }
                    );
                } else {
                    dispatch(showErrorMessage(Translations.InvoiceInformationSaveFailed));
                }
            })
            .catch((error) => {
                showApiError(error.message);
            })
            .finally(() => {
                dispatch(fetchEnd());
            });
    };
    return (
        <div style={{ marginLeft: 32, marginRight: 32 }}>
            <Grid2 container py={2} spacing={7}>
                <Grid2 container xs={5} minWidth={500}>
                    <Grid2 xs={12}>
                        <CustomerSelect
                            value={invoiceCustomer}
                            onChange={(val: string) => setInvoiceCustomer(val)}
                            required
                            fullWidth
                        />
                    </Grid2>
                    <Grid2 xs={12}>
                        <TextField value={reference} onChange={handleReferenceChange} label={Translations.ReferenceInformation} fullWidth />
                    </Grid2>
                </Grid2>

                <Grid2 container xs={5} minWidth={500}>
                    <Grid2 xs={12}>
                        <TextField value={message} onChange={handleMessageChange} label={Translations.Message} fullWidth multiline rows={6} />
                    </Grid2>
                </Grid2>

                <Grid2 container xs={2} >
                    <Grid2 xs={12} style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                        <Button
                            variant="contained"
                            onClick={() => void handleCreateInvoice()}
                            disabled={!invoiceCustomer}
                        >
                            {Translations.SaveInvoiceInformation}
                        </Button>
                    </Grid2>
                </Grid2>
            </Grid2>
            <Grid2 xs={12} pt={newInvoiceData?.customerId ? 0 : 2} mb={2}>
                <CustomerDetails customerId={newInvoiceData?.customerId} />
            </Grid2>
            <TransportOrderInvoiceDetails selectedCustomer={invoiceCustomer} />
        </div>
    );
};
