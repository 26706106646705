import { Base } from "../../framework/base";
import { IEmployeeEditItemSsoIntegrationAttribute, EmployeeEditItemSsoIntegrationAttribute, EmployeeEditItemSsoSaveIntegrationAttribute } from "./employeeEditItemSsoIntegrationAttribute";

export interface IEmployeeEditItemSsoIntegration {
    serviceName: string;
    attributes: IEmployeeEditItemSsoIntegrationAttribute[];
}

export class EmployeeEditItemSsoIntegration implements IEmployeeEditItemSsoIntegration {
    serviceName: string;
    attributes: EmployeeEditItemSsoIntegrationAttribute[];

    constructor();
    constructor(obj: IEmployeeEditItemSsoIntegration);
    constructor(obj?: any) {
        this.serviceName = obj && obj.serviceName || "";
        this.attributes = Base.getTypedArray<EmployeeEditItemSsoIntegrationAttribute>(obj ? obj.attributes : null, EmployeeEditItemSsoIntegrationAttribute);
    }
}

export class EmployeeEditItemSaveSsoIntegration {
    serviceName: string;
    attributes: EmployeeEditItemSsoSaveIntegrationAttribute[];

    constructor();
    constructor(obj: IEmployeeEditItemSsoIntegration);
    constructor(obj?: any) {
        this.serviceName = obj && obj.serviceName || "";
        this.attributes = Base.getTypedArray<EmployeeEditItemSsoSaveIntegrationAttribute>(obj ? obj.attributes : null, EmployeeEditItemSsoSaveIntegrationAttribute);
    }
}