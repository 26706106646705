import { IWorkOrderItem, WorkOrderItem } from "./workOrderItem";
import { ICalendarDayItem, CalendarDayItem } from "../calendarDay/calendarDayItem";
import { IDayBookingItem, DayBookingItem } from "../dayBooking/dayBookingItem";
import { IWorkOrderCalendarLineItem, WorkOrderCalendarLineItem } from "./workOrderCalendarLineItem";
import { Base } from "../../framework/base";
import { INumberTitle, NumberTitle } from "../common/numberTitle";
import { IVehicleItem, VehicleItem } from "../vehicle/vehicleItem";

export interface IWorkOrderItems {
    startDate: number;
    endDate: number;
    workOrders: IWorkOrderItem[];
    workOrderTemplates: IWorkOrderItem[];
    favoriteWorkOrders: IWorkOrderItem[];
    showOnMapVehicles: IVehicleItem[];
    calendarLines: IWorkOrderCalendarLineItem[];
    calendarDays: ICalendarDayItem[];
    dayBookings: IDayBookingItem[];
    dayBookingTypes: INumberTitle[];
}

export class WorkOrderItems implements IWorkOrderItems {
    startDate: number;
    endDate: number;
    workOrders: WorkOrderItem[];
    workOrderTemplates: WorkOrderItem[];
    favoriteWorkOrders: WorkOrderItem[];
    showOnMapVehicles: VehicleItem[];
    calendarLines: WorkOrderCalendarLineItem[];
    calendarDays: CalendarDayItem[];
    dayBookings: DayBookingItem[];
    dayBookingTypes: NumberTitle[];

    constructor();
    constructor(obj: IWorkOrderItems);
    constructor(obj?: any) {
        this.startDate = obj && obj.startDate || 0;
        this.endDate = obj && obj.endDate || 0;
        this.workOrders = Base.getTypedArray<WorkOrderItem>(obj ? obj.workOrders : null, WorkOrderItem);
        this.workOrderTemplates = Base.getTypedArray<WorkOrderItem>(obj ? obj.workOrderTemplates : null, WorkOrderItem);
        this.favoriteWorkOrders = Base.getTypedArray<WorkOrderItem>(obj ? obj.favoriteWorkOrders : null, WorkOrderItem);
        this.showOnMapVehicles = Base.getTypedArray<VehicleItem>(obj ? obj.showOnMapVehicles : null, VehicleItem);
        this.calendarLines = Base.getTypedArray<WorkOrderCalendarLineItem>(obj ? obj.calendarLines : null, WorkOrderCalendarLineItem);
        this.calendarDays = Base.getTypedArray<CalendarDayItem>(obj ? obj.calendarDays : null, CalendarDayItem);
        this.dayBookings = Base.getTypedArray<DayBookingItem>(obj ? obj.dayBookings : null, DayBookingItem);
        this.dayBookingTypes = Base.getTypedArray<NumberTitle>(obj ? obj.dayBookingTypes : null, NumberTitle);
    }
}
