import { Base } from "../../framework/base";

export interface ICodeTitleNode {
    code: string;
    title: string;
    children: ICodeTitleNode[];
}

export class CodeTitleNode implements ICodeTitleNode {
    code: string;
    title: string;
    children: CodeTitleNode[];

    constructor();
    constructor(obj: ICodeTitleNode);
    constructor(obj?: any) {
        this.code = obj && obj.code || "";
        this.title = obj && obj.title || "";
        this.children = Base.getTypedArray<CodeTitleNode>(obj ? obj.children : null, CodeTitleNode);
    }
}