import {
    hourBookingTypeCategoryNormal,
    hourBookingTypeCatehoryNormalSecondary,
    hourBookingTypeCategoryDailyBreak,
    hourBookingTypeCategoryOvertimeBase,
    hourBookingTypeCategoryPaidBreak,
    hourBookingTypeCategoryPaidWaiting
     } from "../common/consts";

export interface IHourBookingCategoryItem {
    id: string;
    name: string;
    category: number;
    scope: number;
    salaryRowTypeId: string;
    salaryRowTypeMeasureUnit: string;
    salaryRowTypeDecimals: number;

    hasNormalCategory: () => boolean;
    hasMealBreak: () => boolean;
    subractMealBreak: () => boolean;
    hasHourBookingTypeCategoryNormalSecondary: () => boolean;
}

export class HourBookingCategoryItem implements IHourBookingCategoryItem {
    id: string;
    name: string;
    category: number;
    scope: number;
    salaryRowTypeId: string;
    salaryRowTypeMeasureUnit: string;
    salaryRowTypeDecimals: number;
    salaryRowTypeName: string;

    constructor();
    constructor(obj: IHourBookingCategoryItem);
    constructor(obj?: any) {
        this.id = (obj && obj.id) || "";
        this.name = (obj && obj.name) || "";
        this.category = (obj && obj.category) || 0;
        this.scope = (obj && obj.scope) || 0;
        this.salaryRowTypeId = (obj && obj.salaryRowTypeId) || "";
        this.salaryRowTypeMeasureUnit =
            (obj && obj.salaryRowTypeMeasureUnit) || "";
        this.salaryRowTypeDecimals = (obj && obj.salaryRowTypeDecimals) || 0;
        this.salaryRowTypeName = (obj && obj.salaryRowTypeName) || "";
    }

    hasNormalCategory = (): boolean =>
        [
            hourBookingTypeCategoryNormal,
            hourBookingTypeCatehoryNormalSecondary,
            hourBookingTypeCategoryOvertimeBase,
            hourBookingTypeCategoryPaidBreak,
            hourBookingTypeCategoryPaidWaiting,
        ].includes(this.category);

    hasMealBreak = (): boolean =>
        [hourBookingTypeCategoryDailyBreak].includes(this.category);

    subractMealBreak = (): boolean =>
        [hourBookingTypeCategoryNormal].includes(this.category);

    hasHourBookingTypeCategoryNormalSecondary = (): boolean =>
        [hourBookingTypeCatehoryNormalSecondary].includes(this.category);
}
