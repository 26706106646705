import { useMemo } from "react";
import {
    makeSelectWorkShiftTimeSlotsByDateRange,
    makeSelectWorkShiftTimeSlotsByIds,
    workShiftTimeSlotSelector,
} from "../../store/workShiftTimeSlotSlice";
import { useAppSelector } from "../../framework/customStore";
import { useSelectWorkShiftsTimeSlotIdsByWorkShiftDate } from "./useSelectWorkShifts";

export const useSelectWorkShiftTimeSlotById = (id: string) => {
    const state = useAppSelector((state) => state.workShiftTimeSlot);
    return workShiftTimeSlotSelector.selectById(state, id);
};

export const useSelectWorkShiftTimeSlotByIds = (ids: string[]) => {
    const state = useAppSelector((state) => state.workShiftTimeSlot);
    const selectWorkShiftTimeSlotsByIds = useMemo(
        makeSelectWorkShiftTimeSlotsByIds,
        []
    );
    return selectWorkShiftTimeSlotsByIds(state, ids);
};

export const useSelectWorkShiftTimeSlotsByDateRange = (
    employeeId: string,
    rangeStart: string,
    rangeEnd: string
) => {
    const state = useAppSelector((state) => state.workShiftTimeSlot);
    const selectWorkShiftTimeSlotsByDateRange = useMemo(
        makeSelectWorkShiftTimeSlotsByDateRange,
        []
    );
    return selectWorkShiftTimeSlotsByDateRange(
        state,
        employeeId,
        rangeStart,
        rangeEnd
    );
};

export const useSelectWorkShiftTimeSlotsByWorkShiftDate = (
    employeeId: string,
    rangeStart: string,
    rangeEnd: string
) => {
    const ids = useSelectWorkShiftsTimeSlotIdsByWorkShiftDate(
        employeeId,
        rangeStart,
        rangeEnd
    );
    return useSelectWorkShiftTimeSlotByIds(ids);
};
