import { getApiCall } from "./tokenService";
import { OperationLogItems } from "../models/operationLog/operationLogItems";
import { OperationLogEdit } from "../models/operationLog/operationLogEdit";

export const getOperationLogs = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean): Promise<OperationLogItems> => {
    return getApiCall<OperationLogItems>("api/operationlog/?page=" + (page || "") + "&pageSize=" + (pageSize || "") +
        "&sortColumn=" + (sortColumn ? encodeURIComponent(sortColumn) : "") + "&sortOrder=" + (sortOrderIsAsc ? "asc" : "desc") +
        "&filter=" + (filter ? encodeURIComponent(filter) : ""), OperationLogItems);
};

export const getOperationLogEdit = (id: string): Promise<OperationLogEdit> => {
    return getApiCall<OperationLogEdit>("api/operationlog/edit/?id=" + (id || ""), OperationLogEdit);
};