import { ApiSuccess, SaveSuccess } from "./baseService";
import { getApiCall, postFormApiCall, postApiCall } from "./tokenService";
import { CompetencyItems } from "../models/competency/competencyItems";
import { CompetencyEdit } from "../models/competency/competencyEdit";

export const getCompetencyItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean, showNotInUse: boolean): Promise<CompetencyItems> => {
    return getApiCall<CompetencyItems>("api/competency/?page=" + (page || "") + "&pageSize=" + (pageSize || "") +
        "&sortColumn=" + (sortColumn ? encodeURIComponent(sortColumn) : "") + "&sortOrder=" + (sortOrderIsAsc ? "asc" : "desc") +
        "&filter=" + (filter ? encodeURIComponent(filter) : "") + "&showNotInUse=" + (showNotInUse ? "1" : "0"), CompetencyItems);
};

export const getCompetencyEdit = (id: string): Promise<CompetencyEdit> => {
    return getApiCall<CompetencyEdit>("api/competency/edit/?id=" + (id || ""), CompetencyEdit);
};

export const saveCompetencyEdit = (data: FormData): Promise<SaveSuccess> => {
    return postFormApiCall<SaveSuccess>("api/competency/save", data, SaveSuccess);
};

export const removeCompetency = (id: string): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/competency/remove/?id=" + (id || ""), ApiSuccess);
};