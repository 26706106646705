import * as React from "react";
import { Base } from "../../framework/base";

//EditableDecimalCell
//***********************************************************************************************************************
export interface IEditableDecimalCellProp {
    id?: string;
    amount: number;
    disabled?: boolean;
    decimals: number;
    allowNegative?: boolean;
    onAmountChange: (value: number) => void;
    onMoveUp?: () => void;
    onMoveDown?: () => void;
    onFocus: () => void;
    onBlur: () => void;
}

export interface IEditableDecimalCellState {
    orgAmount: number;
}

export class EditableDecimalCell extends React.Component<IEditableDecimalCellProp, IEditableDecimalCellState> {
    private plannedAmountDiv: HTMLDivElement;
    private html: string;

    getAmountStr(amount: number, decimals: number): string {
        return amount.toLocaleFixed(decimals);
    }

    constructor(props) {
        super(props);
        this.html = props.amount ? this.getAmountStr(props.amount, props.decimals) : "";
        this.state = { orgAmount: props.amount ?? 0 };
    }

    UNSAFE_componentWillReceiveProps(nextProps: IEditableDecimalCellProp, nextContext): void {
        this.html = nextProps.amount ? this.getAmountStr(nextProps.amount, nextProps.decimals) : "";
        this.setState({ orgAmount: nextProps.amount ?? 0 });
    }

    componentDidUpdate(prevProps: IEditableDecimalCellProp, prevState: IEditableDecimalCellState, prevContext): void {
        if (this.html !== this.plannedAmountDiv.innerHTML) {
            this.plannedAmountDiv.innerHTML = this.html;
        }
    }

    changeAmount = (amount: number, moveUp: boolean, moveDown: boolean) => {
        const props = this.props;
        const orgAmount = this.getAmountStr(this.state.orgAmount, props.decimals);
        const newAmount = this.getAmountStr(amount, props.decimals);
        if (orgAmount !== newAmount) {
            if (props.disabled) return;
            props.onAmountChange(amount);
        }
        if (moveUp && props.onMoveUp) {
            props.onMoveUp();
        } else if (moveDown && props.onMoveDown) {
            props.onMoveDown();
        }
    };

    handleInput = (e) => {
        if (this.props.disabled) return;
        this.html = this.plannedAmountDiv.innerHTML;
    };

    handleBlur = (e) => {
        const props = this.props;
        if (props.disabled) return;
        this.changeAmount(this.plannedAmountDiv.innerHTML !== "" ? this.plannedAmountDiv.innerHTML.toDecimal(props.allowNegative, true) : 0, false, false);
        props.onBlur();
    };

    handleOnFocus = (e) => {
        if (this.props.disabled) return;
        const obj = this;
        window.setTimeout(() => {
            Base.selectElementContents(obj.plannedAmountDiv);
        }, 1);
        this.props.onFocus();
    };

    handleMouseUp = (e) => {
        const obj = this;
        // only left mouse button
        if (e.button !== 0) return;
        window.setTimeout(() => {
            Base.selectElementContents(obj.plannedAmountDiv);
        }, 1);
    };

    handleKeyUp = (e) => {
        const props = this.props;
        if (e.keyCode === 13) {
            this.changeAmount(this.plannedAmountDiv.innerHTML !== "" ? this.plannedAmountDiv.innerHTML.toDecimal(props.allowNegative, true) : 0, false, false);
            window.getSelection().removeAllRanges();
            e.preventDefault();
        }
        if (e.keyCode === 38 && props.onMoveUp) {
            this.changeAmount(this.plannedAmountDiv.innerHTML !== "" ? this.plannedAmountDiv.innerHTML.toDecimal(props.allowNegative, true) : 0, true, false);
            window.getSelection().removeAllRanges();
            e.preventDefault();
        } else if (e.keyCode === 40 && props.onMoveDown) {
            this.changeAmount(this.plannedAmountDiv.innerHTML !== "" ? this.plannedAmountDiv.innerHTML.toDecimal(props.allowNegative, true) : 0, false, true);
            window.getSelection().removeAllRanges();
            e.preventDefault();
        }
    };

    handleKeyPress = (e) => {
        //Enter
        const chars = this.props.allowNegative
            ? ["-", "−", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ",", "."]
            : ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ",", "."];
        if (this.props.disabled || chars.indexOf(e.key) < 0) {
            e.preventDefault();
            return false;
        }
        return true;
    };

    render() {
        return (
            <div id={this.props.id} className="editableCell number" onInput={this.handleInput} onBlur={this.handleBlur} onMouseUp={this.handleMouseUp} onKeyPress={this.handleKeyPress} onKeyUp={this.handleKeyUp} onFocus={this.handleOnFocus} contentEditable dangerouslySetInnerHTML={ { __html: this.html } } ref={(elem) => { this.plannedAmountDiv = elem; }} />
        );
    }
}

//EditableTimeCell
//***********************************************************************************************************************
export interface IEditableTimeCellProp {
    id?: string;
    amount: number;
    disabled?: boolean;
    onAmountChange: (value: number) => void;
    onMoveUp?: () => void;
    onMoveDown?: () => void;
    onFocus: () => void;
    onBlur: () => void;
}

export interface IEditableTimeCellState {
    orgAmount: number;
}

export class EditableTimeCell extends React.Component<IEditableTimeCellProp, IEditableTimeCellState> {
    private plannedAmountDiv: HTMLDivElement;
    private html: string;

    constructor(props) {
        super(props);
        this.html = props.amount ? Base.hoursToTimeStr(props.amount) : "";
        this.state = { orgAmount: props.amount ?? 0 };
    }

    UNSAFE_componentWillReceiveProps(nextProps: IEditableTimeCellProp, nextContext): void {
        this.html = nextProps.amount ? Base.hoursToTimeStr(nextProps.amount) : "";
        this.setState({ orgAmount: nextProps.amount ?? 0 });
    }

    componentDidUpdate(prevProps: IEditableTimeCellProp, prevState: IEditableTimeCellState, prevContext): void {
        if (this.html !== this.plannedAmountDiv.innerHTML) {
            this.plannedAmountDiv.innerHTML = this.html;
        }
    }

    changeAmount = (amount: number, moveUp: boolean, moveDown: boolean) => {
        const props = this.props;
        const orgAmount = Base.hoursToTimeStr(this.state.orgAmount);
        const newAmount = Base.hoursToTimeStr(amount);
        if (orgAmount !== newAmount) {
            if (props.disabled) return;
            props.onAmountChange(amount);
        }
        if (moveUp && props.onMoveUp) {
            props.onMoveUp();
        } else if (moveDown && props.onMoveDown) {
            props.onMoveDown();
        }
    };

    handleInput = (e) => {
        if (this.props.disabled) return;
        this.html = this.plannedAmountDiv.innerHTML;
    };

    handleBlur = (e) => {
        const props = this.props;
        if (props.disabled) return;
        this.changeAmount(this.plannedAmountDiv.innerHTML !== "" ? this.plannedAmountDiv.innerHTML.toTimeHours() : 0, false, false);
        props.onBlur();
    };

    handleOnFocus = (e) => {
        if (this.props.disabled) return;
        const obj = this;
        window.setTimeout(() => {
            Base.selectElementContents(obj.plannedAmountDiv);
        }, 1);
        this.props.onFocus();
    };

    handleMouseUp = (e) => {
        const obj = this;
        // only left mouse button
        if (e.button !== 0) return;
        window.setTimeout(() => {
            Base.selectElementContents(obj.plannedAmountDiv);
        }, 1);
    };

    handleKeyUp = (e) => {
        const props = this.props;
        if (e.keyCode === 13) {
            this.changeAmount(this.plannedAmountDiv.innerHTML !== "" ? this.plannedAmountDiv.innerHTML.toTimeHours() : 0, false, false);
            window.getSelection().removeAllRanges();
            e.preventDefault();
        }
        if (e.keyCode === 38 && props.onMoveUp) {
            this.changeAmount(this.plannedAmountDiv.innerHTML !== "" ? this.plannedAmountDiv.innerHTML.toTimeHours() : 0, true, false);
            window.getSelection().removeAllRanges();
            e.preventDefault();
        } else if (e.keyCode === 40 && props.onMoveDown) {
            this.changeAmount(this.plannedAmountDiv.innerHTML !== "" ? this.plannedAmountDiv.innerHTML.toTimeHours() : 0, false, true);
            window.getSelection().removeAllRanges();
            e.preventDefault();
        }
    };

    handleKeyPress = (e) => {
        //Enter
        const chars = [":", ",", ".", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
        if (this.props.disabled || chars.indexOf(e.key) < 0) {
            e.preventDefault();
            return false;
        }
        return true;
    };

    render() {
        return (
            <div id={this.props.id} className="editableCell time" onInput={this.handleInput} onBlur={this.handleBlur} onMouseUp={this.handleMouseUp} onKeyPress={this.handleKeyPress} onKeyUp={this.handleKeyUp} onFocus={this.handleOnFocus} contentEditable dangerouslySetInnerHTML={ { __html: this.html } } ref={(elem) => { this.plannedAmountDiv = elem; }} />
        );
    }
}

//EditableStringCell
//***********************************************************************************************************************
export interface IEditableStringCellProp {
    id?: string;
    disabled?: boolean;
    value: string;
    onValueChange: (value: string) => void;
    onMoveUp?: () => void;
    onMoveDown?: () => void;
    onFocus: () => void;
    onBlur: () => void;
}

export interface IEditableStringCellState {
    orgValue: string;
}

export class EditableStringCell extends React.Component<IEditableStringCellProp, IEditableStringCellState> {
    private plannedAmountDiv: HTMLDivElement;
    private html: string;

    constructor(props) {
        super(props);
        this.state = { orgValue: this.props.value };
    }

    UNSAFE_componentWillReceiveProps(nextProps: IEditableStringCellProp, nextContext): void {
        this.html = Base.textToHtml(nextProps.value);
        this.setState({ orgValue: nextProps.value });
    }

    componentDidUpdate(prevProps: IEditableStringCellProp, prevState: IEditableStringCellState, prevContext): void {
        if (this.html !== this.plannedAmountDiv.innerHTML) {
            this.plannedAmountDiv.innerHTML = this.html;
        }
    }

    changeValue = (value: string, moveUp: boolean, moveDown: boolean) => {
        const props = this.props;
        const orgValue = this.state.orgValue;
        const newValue = Base.htmlToText(value);
        if (orgValue !== newValue) {
            if (props.disabled) return;
            props.onValueChange(newValue);
        }
        if (moveUp && props.onMoveUp) {
            props.onMoveUp();
        } else if (moveDown && props.onMoveDown) {
            props.onMoveDown();
        }
    };

    handleInput = (e) => {
        if (this.props.disabled) return;
        this.html = this.plannedAmountDiv.innerHTML;
    };

    handleBlur = (e) => {
        const props = this.props;
        if (props.disabled) return;
        this.changeValue(this.plannedAmountDiv.innerHTML, false, false);
        props.onBlur();
    };

    handleOnFocus = (e) => {
        if (this.props.disabled) return;
        const obj = this;
        window.setTimeout(() => {
            Base.selectElementContents(obj.plannedAmountDiv);
        }, 1);
        this.props.onFocus();
    };

    handleMouseUp = (e) => {
        const obj = this;
        // only left mouse button
        if (e.button !== 0) return;
        window.setTimeout(() => {
            Base.selectElementContents(obj.plannedAmountDiv);
        }, 1);
    };

    handleKeyDown = (e) => {
        if ((this.props.disabled && e.keyCode !== 9) || e.keyCode === 13) {
            e.preventDefault();
        }
    };

    handleKeyUp = (e) => {
        const props = this.props;
        if (e.keyCode === 13) {
            e.preventDefault();
            this.changeValue(this.plannedAmountDiv.innerHTML, false, false);
            window.getSelection().removeAllRanges();
        }
        if (e.keyCode === 38 && props.onMoveUp) {
            this.changeValue(this.plannedAmountDiv.innerHTML, true, false);
            window.getSelection().removeAllRanges();
            e.preventDefault();
        } else if (e.keyCode === 40 && props.onMoveDown) {
            this.changeValue(this.plannedAmountDiv.innerHTML, false, true);
            window.getSelection().removeAllRanges();
            e.preventDefault();
        }
    };

    render() {
        return (
            <div id={this.props.id} className="editableCell text" onInput={this.handleInput} onBlur={this.handleBlur} onMouseUp={this.handleMouseUp} onKeyDown={this.handleKeyDown} onKeyUp={this.handleKeyUp} onFocus={this.handleOnFocus} contentEditable dangerouslySetInnerHTML={{ __html: this.html }} ref={(elem) => { this.plannedAmountDiv = elem; }} />
        );
    }
}
