import * as React from "react";
import { Translations } from "../../models/translations";
import { IInvoiceEdit } from "../../models/invoice/invoiceEdit";
import { ButtonDropdown } from "../framework/dropdown";
import { RadioButton } from "../framework/radio";
import * as CustomStore from "../../framework/customStore";
import * as StoreActions from "../../models/store/storeActions";
import * as InvoiceService from "../../services/invoiceService";
import * as BaseService from "../../services/baseService";
import { DocumentPreview } from "../framework/documentPreview";
import { DrawerView } from "../framework/drawerView";
import { EnumHelper, InvoiceState } from "../../models/common/enums";
import { LoadingIndicator } from "../framework/loadingIndicator";
import { WorkListLineSimple } from "../work/workListLineSimple";
import { DocumentList } from "../document/documentList";
import { IDocument } from "../../models/document/document";
import downloadjs from "downloadjs";
import * as documentService from "../../services/documentService";

/* eslint-disable no-unused-vars */
export const enum InvoiceDetailsViewSubPage {
    Data = "1",
    Print = "2"
}
/* eslint-enable no-unused-vars */

interface IInvoiceDetailsContentProp {
    classes?: string;
    item: IInvoiceEdit;
    isLoading: boolean;
    titleSet?: boolean;
    onOpenWorkOrder: (id: string) => void;
    onPrintInvoices: (ids: string[]) => void;
    onEditInvoice: (id: string) => void;
    onRemoveInvoice: (id: string) => void;
    onTakeToInvoicing: (id: string) => void;
    onEditCustomer: (id: string) => void;
}

interface IInvoiceDetailsContentState {
    page: InvoiceDetailsViewSubPage;
    file: string;
    fileName: string;
    thumbnails: string[];
    thumbnailIndex: number;
    documents: IDocument[];
}

export class InvoiceDetailsContent extends React.Component<IInvoiceDetailsContentProp, IInvoiceDetailsContentState> {
    constructor(props) {
        super(props);
        this.state = {
            page: InvoiceDetailsViewSubPage.Data,
            file: "",
            fileName: "",
            thumbnails: [],
            thumbnailIndex: -1,
            documents: [],
        };
    }

    componentDidUpdate(prevProps: IInvoiceDetailsContentProp, prevState: IInvoiceDetailsContentState): void {
        const props = this.props;
        if (props.item?.invoice && (!prevProps.item || props.item.invoice.id !== prevProps.item.invoice.id)) {
            this.loadDocumentData();
        }
        if (prevState.page !== InvoiceDetailsViewSubPage.Print || !prevProps.item && !props.item || prevProps.item && props.item && prevProps.item.invoice.id === props.item.invoice.id) {
            return;
        }
        this.loadInvoiceReport();
    }

    loadInvoiceReport = () => {
        const props = this.props;
        if (!props.item || !props.item.invoice.id) return;
        CustomStore.customStore.dispatch(StoreActions.fetchStart());
        InvoiceService.getInvoicePrintWithThumbnail([props.item.invoice.id])
            .then(fileWithThumbnail => {
                this.setState({
                    file: fileWithThumbnail.file,
                    fileName: fileWithThumbnail.fileName,
                    thumbnails: fileWithThumbnail.thumbnails,
                    thumbnailIndex: fileWithThumbnail.thumbnails.length > 0 ? 0 : -1,
                    page: InvoiceDetailsViewSubPage.Print
                });
            })
            .catch(error => {
                CustomStore.customStore.dispatch(StoreActions.showErrorMessage(BaseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => CustomStore.customStore.dispatch(StoreActions.fetchEnd()));
    };

    loadDocumentData = () => {
        const documents = [];
        this.props.item.invoice.workOrders.forEach(wo => {
            wo.documents.forEach(doc => documents.push(doc));
        });
        this.setState({
            documents: documents
        });
    };

    handleChangePage = (page: InvoiceDetailsViewSubPage): void => {
        if (page === InvoiceDetailsViewSubPage.Data) {
            this.setState({ page: page });
        } else {
            this.loadInvoiceReport();
        }
    };

    handleDocumentDownload = (id: string) => {
        if (!id) return;
        const document = this.state.documents.find(i => i.id === id);
        if (!document) return;
        if (document.file) {
            downloadjs(document.file, document.reference);
        }
        documentService.getDocument(id).catch(error => {
            CustomStore.customStore.dispatch(StoreActions.showErrorMessage(BaseService.getErrorMessageFromError(error)));
        });
    };

    render() {
        const obj = this;
        const props = obj.props;
        const state = obj.state;
        const item = obj.props.item;
        if (!item) {
            return (
                <div className={"detailsContent" + (props.classes ? " " + props.classes : "")}>
                    {props.isLoading &&
                        <LoadingIndicator />
                    }
                    <div className="content">
                        <div className="item">
                            {!props.titleSet &&
                                <span className="text">{Translations.NoSelectedInvoice}</span>
                            }
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={"detailsContent" + (props.classes ? " " + props.classes : "")}>
                {props.isLoading &&
                    <LoadingIndicator />
                }
                <div className="content">
                    {state.page === InvoiceDetailsViewSubPage.Data &&
                        <div className="right dropdownContainer">
                            <ButtonDropdown
                                menuRight
                                selectedTitle={""}
                                classes={"property noTopMargin"}
                                actions={[
                                    { title: (Translations.Edit), onClick: () => props.onEditInvoice(item.invoice.id), disabled: !item },
                                    { title: (Translations.Remove), onClick: () => props.onRemoveInvoice(item.invoice.id), disabled: !item },
                                    { title: (Translations.Print), onClick: () => props.onPrintInvoices([item.invoice.id]), disabled: !item },
                                    { title: (Translations.TakeToInvoicing), onClick: () => props.onTakeToInvoicing(item.invoice.id), disabled: !EnumHelper.isEqual(item.invoice.state, InvoiceState.Preliminary) },
                                ]}
                            />
                        </div>
                    }
                    <div className="btn-group btn-group-toggle states">
                        <RadioButton
                            key={0}
                            classes="btn-secondary"
                            title={Translations.Data}
                            enabled={true}
                            checked={state.page === InvoiceDetailsViewSubPage.Data}
                            onRadioClick={() => this.handleChangePage(InvoiceDetailsViewSubPage.Data)}
                        />
                        <RadioButton
                            key={1}
                            classes="btn-secondary"
                            title={Translations.APrint}
                            enabled={true}
                            checked={state.page === InvoiceDetailsViewSubPage.Print}
                            onRadioClick={() => this.handleChangePage(InvoiceDetailsViewSubPage.Print)}
                        />
                    </div>
                    {state.page === InvoiceDetailsViewSubPage.Data &&
                        <div className="detailsList">
                            <div className="row detailsListLine">
                                <div className="col-6">
                                    <label className="control-label smallFont">{Translations.Number}</label>
                                    <div className="detailsListLine">{item.invoice.number.toString(10)}</div>
                                </div>
                                <div className="col-6 ra">
                                    <label className="control-label smallFont">{Translations.State}</label>
                                    <div className="detailsListLine">{item.invoice.stateStr}</div>
                                </div>
                            </div>
                            <div className="row detailsListLine">
                                <div className="col-6">
                                    <label className="control-label smallFont">{Translations.Customer}</label>
                                    <div className="detailsListLine">
                                        <a className="readOnlyLink" onClick={() => this.props.onEditCustomer(item.invoice.customerId)}>{item.invoice.customerName}</a>
                                    </div>
                                </div>
                                <div className="col-6 ra">
                                    <label className="control-label smallFont">{Translations.AType}</label>
                                    <div>{item.invoice.workOrderTypeName}</div>
                                </div>
                            </div>
                            <div>
                                <label className="control-label smallFont">  {Translations.WorkOrders}</label>
                                {item.invoice.workOrders.map((workOrder) =>
                                    <WorkListLineSimple
                                        key={workOrder.id}
                                        id={workOrder.id}
                                        number={workOrder.number}
                                        parentNumber={workOrder.parentNumber}
                                        name={workOrder.name}
                                        state={workOrder.state}
                                        tooltip={null}
                                        isSelected={false}
                                        onClick={null}
                                        onDoubleClick={props.onOpenWorkOrder}
                                    />
                                )}
                            </div>
                            <div>
                                <DocumentList
                                    relatedObjectId={""}
                                    title={Translations.Documents}
                                    titleClass={"control-label smallFont"}
                                    selectedId={""}
                                    documents={state.documents}
                                    isReadOnly={true}
                                    canShowCamera={false}
                                    smallImage={true}
                                    changeDocumentTypeEnabled={false}
                                    onDownloadDocument={this.handleDocumentDownload}
                                />
                            </div>
                        </div>
                    }
                    {state.page === InvoiceDetailsViewSubPage.Print &&
                        <div className="col-12 detailsList pt-2">
                            <DocumentPreview
                                file={state.file}
                                fileName={state.fileName}
                                fileMimeType={"application/pdf"}
                                thumbnails={state.thumbnails}
                                thumbnailIndex={state.thumbnailIndex}
                            />
                        </div>
                    }
                </div>
            </div>
        );
    }
}

interface InvoiceDetailsViewProp {
    classes: string;
    title: string;
    item: IInvoiceEdit;
    isLoading: boolean;
    expanded: boolean;
    onOpenWorkOrder: (id: string) => void;
    onPrintInvoices: (ids: string[]) => void;
    onEditInvoice: (id: string) => void;
    onRemoveInvoice: (id: string) => void;
    onTakeToInvoicing: (id: string) => void;
    onChangeExpansionMode: (expanded: boolean) => void;
    onEditCustomer: (id: string) => void;

}

export class InvoiceDetailsView extends React.Component<InvoiceDetailsViewProp, {}> {
    render() {
        const obj = this;
        const props = obj.props;
        const item = obj.props.item;
        return (
            <DrawerView
                classes={"backgroundContent detailsView" + (props.classes ? " " + props.classes : "")}
                title={item ? Translations.SelectedInvoice : Translations.NoSelectedInvoice}
                content={
                    <InvoiceDetailsContent
                        item={props.item}
                        isLoading={props.isLoading}
                        titleSet={true}
                        onOpenWorkOrder={props.onOpenWorkOrder}
                        onPrintInvoices={props.onPrintInvoices}
                        onEditInvoice={props.onEditInvoice}
                        onRemoveInvoice={props.onRemoveInvoice}
                        onTakeToInvoicing={props.onTakeToInvoicing}
                        onEditCustomer={props.onEditCustomer}

                    />
                }
                expanded={props.expanded}
                onChangeMode={props.onChangeExpansionMode}
            />
        );
    }
}
