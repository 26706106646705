export interface IWorkOrderTypeListItem {
    id: string;
    name: string;
    vatRate: number;
}

export class WorkOrderTypeListItem implements IWorkOrderTypeListItem {
    id: string;
    name: string;
    vatRate: number;

    constructor();
    constructor(obj: IWorkOrderTypeListItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
        this.vatRate = obj && obj.vatRate || 0;
    }
}
