// WorkOrderSetValuesDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import * as workOrderService from "../../services/workOrderService";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { Translations } from "../../models/translations";
import { Dialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { WorkOrderDialogState } from "./workOrderDialogState";
import { INumberTitle } from "../../models/common/numberTitle";
import { CheckBoxDropdown } from "../framework/dropdown";
import { SaveData } from "../../framework/saveData";
import { IVehicleItem } from "../../models/vehicle/vehicleItem";
import { IEmployeeItem } from "../../models/employee/employeeItem";
import { AppUtils } from "../../models/common/appUtils";

export interface IWorkOrderSetValuesData {
    workOrderIds: string[];
    setEmployees: boolean;
    setVehicles: boolean;
    setState: boolean;
    setDuration: boolean;
    employeeIds: string[];
    vehicleIds: string[];
    state: number;
    estimatedHours: number;
}

export interface IWorkOrderSetValuesDialogProp {
    classes?: string;
    workOrderStates: INumberTitle[];
    employees: IEmployeeItem[];
    vehicles: IVehicleItem[];
    workOrderIds: string[];
    employeeIds: string[];
    vehicleIds: string[];
    state: number;
    estimatedHours: number;
    setEmployees: boolean;
    setVehicles: boolean;
    setState: boolean;
    setDuration: boolean;
    onOk: () => void;
    onCancel: () => void;
}

export interface IWorkOrderSetValuesDialogState {
    employeeIds: string[];
    vehicleIds: string[];
    state: number;
    estimatedHoursStr: string;
}

export class WorkOrderSetValuesDialog extends React.Component<IWorkOrderSetValuesDialogProp, IWorkOrderSetValuesDialogState> {
    constructor(props: IWorkOrderSetValuesDialogProp) {
        super(props);
        this.state = {
            employeeIds: props.employeeIds.slice(0),
            vehicleIds: props.vehicleIds.slice(0),
            state: props.state,
            estimatedHoursStr: props.estimatedHours ? AppUtils.getDurationStrByDurationMinShort(60 * props.estimatedHours) : "",
        };
    }

    handleStateChange = (newState: number) => {
        this.setState({ state: newState });
    };

    handleEmployeesChange = (employeeIds: string[]) => {
        this.setState({ employeeIds: employeeIds });
    };

    handleVehiclesChange = (vehicleIds: string[]) => {
        this.setState({ vehicleIds: vehicleIds });
    };

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "estimatedHours") {
            this.setState({ estimatedHoursStr: value });
        }
    };

    handleEstimatedHoursBlur = (event) => {
        const target = event.target;
        const value: string = target.value;
        const estimatedHours = value ? AppUtils.strDurationToHours(value) : 0;
        this.setState({ estimatedHoursStr: estimatedHours ? AppUtils.getDurationStrByDurationMinShort(60 * estimatedHours) : "" });
    };

    static getWorkOrderSetValuesData = (workOrderIds: string[], setState: boolean, setEmployees: boolean, setVehicles: boolean, setDuration: boolean, state: number, employeeIds: string[], vehicleIds: string[], estimatedHours: number): IWorkOrderSetValuesData => {
        return {
            workOrderIds: workOrderIds.slice(0),
            setState: setState ?? false,
            setEmployees: setEmployees ?? false,
            setVehicles: setVehicles ?? false,
            setDuration: setDuration ?? false,
            state: state ?? 0,
            employeeIds: employeeIds ? employeeIds.slice(0) : [],
            vehicleIds: vehicleIds ? vehicleIds.slice(0) : [],
            estimatedHours: estimatedHours //NULL VALUE IS ALLOWED
        };
    };

    static getSaveData = (data: IWorkOrderSetValuesData): SaveData => {
        const result = new SaveData();
        // Common
        result.append("ids", JSON.stringify(data.workOrderIds));
        result.append("setState", data.setState ? "1" : "0");
        result.append("setEmployees", data.setEmployees ? "1" : "0");
        result.append("setVehicles", data.setVehicles ? "1" : "0");
        result.append("setDuration", data.setDuration ? "1" : "0");
        // General
        result.append("state", data.state.toString(10));
        result.append("employeeIds", JSON.stringify(data.employeeIds));
        result.append("vehicleIds", JSON.stringify(data.vehicleIds));
        result.append("estimatedHours", data.estimatedHours ? data.estimatedHours.toString(10) : "");
        return result;
    };

    static checkErrors = (data: IWorkOrderSetValuesData): Promise<boolean> => {
        return new Promise<boolean>((resolve) => {
            if (data.setDuration && !Base.isNullOrUndefined(data.estimatedHours) && data.estimatedHours <= 0.001) {
                store.customStore.dispatch(storeActions.showErrorMessage(Translations.EstimatedHoursMustBeDefined));
                return resolve(false);
            }
            return resolve(true);
        });
    };

    static getWarningMessage = (data: IWorkOrderSetValuesData): Promise<string> => {
        return new Promise<string>((resolve) => {
            return resolve("");
        });
    };

    static validate = (data: IWorkOrderSetValuesData): Promise<boolean> => {
        return WorkOrderSetValuesDialog.checkErrors(data)
            .then(success => {
                if (success) {
                    return WorkOrderSetValuesDialog.getWarningMessage(data).then(warnings => {
                        if (!warnings) {
                            return Base.getPromiseResult(true);
                        }
                        return new Promise<boolean>((resolve) => {
                            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, warnings + Base.lf + Translations.DoYouReallyWantToChangeWorkOrders,
                                () => {
                                    store.customStore.dispatch(storeActions.clearConfirmation());
                                    resolve(true);
                                },
                                () => {
                                    store.customStore.dispatch(storeActions.clearConfirmation());
                                    resolve(false);
                                }));
                        });
                    });
                } else {
                    return Base.getPromiseResult(false);
                }
            });
    };

    static setWorkOrderValues = (data: IWorkOrderSetValuesData): Promise<boolean> => {
        return WorkOrderSetValuesDialog.validate(data)
            .then(success => {
                if (!success) return Base.getPromiseResult(false);
                //Set SaveData
                const saveData = WorkOrderSetValuesDialog.getSaveData(data);
                if (!saveData) return Base.getPromiseResult(false);
                // Call server
                store.customStore.dispatch(storeActions.fetchStart());
                return workOrderService.setValues(saveData.formData)
                    .then(success => {
                        store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                        return Base.getPromiseResult(true);
                    })
                    .catch(error => {
                        store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                        return Base.getPromiseResult(false);
                    })
                    .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
            });
    };

    getWorkOrderSetData = (): IWorkOrderSetValuesData => {
        const props = this.props;
        const state = this.state;
        return WorkOrderSetValuesDialog.getWorkOrderSetValuesData(props.workOrderIds,
            props.setState,
            props.setEmployees,
            props.setVehicles,
            props.setDuration,
            state.state,
            state.employeeIds,
            state.vehicleIds,
            state.estimatedHoursStr ? AppUtils.strDurationToHours(state.estimatedHoursStr) : 0);
    };

    handleOkClick = () => {
        const obj = this;
        WorkOrderSetValuesDialog.setWorkOrderValues(this.getWorkOrderSetData())
            .then(success => {
                if (success) {
                    obj.props.onOk();
                }
            });
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "setWorkOrderValues px400" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <Dialog
                    classes={dialogClasses}
                    title={props.setState ? Translations.SetState : (props.setEmployees ? Translations.SetEmployees : (props.setVehicles ? Translations.SetVehicles : Translations.SetDurationByEstimatedHours))}
                    show={true}
                    body={<div>
                        {props.setState &&
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.State}</label>
                                        <WorkOrderDialogState
                                            isReadOnly={false}
                                            isProject={false}
                                            state={state.state}
                                            projectStates={[]}
                                            workOrderStates={props.workOrderStates}
                                            onStateChange={this.handleStateChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {props.setEmployees &&
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.Employee}</label>
                                        <CheckBoxDropdown
                                            selectedIds={state.employeeIds}
                                            values={props.employees}
                                            onChange={this.handleEmployeesChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {props.setVehicles &&
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.Vehicle}</label>
                                        <CheckBoxDropdown
                                            selectedIds={state.vehicleIds}
                                            values={props.vehicles}
                                            onChange={this.handleVehiclesChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {props.setDuration &&
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.EstimatedHours}</label>
                                        <input type="text" className="form-control" name="estimatedHours" title={Translations.EstimatedHours} value={state.estimatedHoursStr} onChange={this.handleChange} onBlur={this.handleEstimatedHoursBlur} maxLength={10}/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>}
                    buttons={[
                        { title: Translations.OK, classes: "btn-primary", enabled: true, onClick: this.handleOkClick }
                    ]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}