import { styled, TableRow } from "@mui/material";

export const StripedTableRowCollapsible = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    "&:nth-of-type(4n+3)": {
        backgroundColor: "var(--palette-lightGrayBlue-main)"
    },
}));
export const StripedTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    "&:nth-of-type(even)": {
        backgroundColor: "var(--palette-lightGrayBlue-main)"
    },
}));
