import { getApiCall, postApiCall } from "./tokenService";
import { ReportListItems } from "../models/report/reportListItems";
import { Base64FileWithThumbnail } from "../models/common/base64FileWithThumbnail";
import { ReportGenerate } from "../models/report/reportListItem";

export const getReportListItems = (type: string, filter: string): Promise<ReportListItems> => {
    return getApiCall<ReportListItems>("api/report/?type=" + (type || "") + "&filter=" + (filter ? encodeURIComponent(filter) : ""), ReportListItems);
};

export const generateReport = (data: ReportGenerate): Promise<Base64FileWithThumbnail> => {
    return postApiCall<Base64FileWithThumbnail>("api/report/generate", Base64FileWithThumbnail, JSON.stringify(data));
};
