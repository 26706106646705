import { Base } from "../../framework/base";
import { ISalaryRowTypeItem, SalaryRowTypeItem } from "./salaryRowTypeItem";

export interface ISalaryEditItemSalaryRow {
    id: string;
    salaryRowTypeId: string;
    amount: number;
    comment: string;
    startDate: number;
    endDate: number;
    dayHoursRatio: number;
    //UI
    rowType: ISalaryRowTypeItem;
    groupedRows: ISalaryEditItemSalaryRow[];
    setByGroupedRows(groupedRows: SalaryEditItemSalaryRow[]);
    reset();
    getHash(): string;
}

export class SalaryEditItemSalaryRow implements ISalaryEditItemSalaryRow {
    id: string;
    salaryRowTypeId: string;
    amount: number;
    comment: string;
    startDate: number;
    endDate: number;
    dayHoursRatio: number;
    //UI
    rowType: SalaryRowTypeItem;
    groupedRows: SalaryEditItemSalaryRow[];

    constructor();
    constructor(obj: ISalaryEditItemSalaryRow);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.salaryRowTypeId = obj && obj.salaryRowTypeId || "";
        this.amount = obj && obj.amount || 0;
        this.comment = obj && obj.comment || "";
        this.startDate = obj && obj.startDate || null;
        this.endDate = obj && obj.endDate || null;
        this.dayHoursRatio = obj && obj.dayHoursRatio || null;
        //UI
        this.rowType = new SalaryRowTypeItem();
        if (obj && obj.rowType) {
            this.rowType = new SalaryRowTypeItem(obj.rowType);
        }
        this.groupedRows = Base.getTypedArray<SalaryEditItemSalaryRow>(obj ? obj.groupedRows : null, SalaryEditItemSalaryRow);
    }

    reset() {
        this.amount = 0;
        this.comment = "";
        this.startDate = null;
        this.endDate = null;
        this.dayHoursRatio = null;
        this.groupedRows = [];
    }
    setByGroupedRows(groupedRows: SalaryEditItemSalaryRow[]) {
        this.amount = 0;
        this.comment = "";
        this.startDate = null;
        this.endDate = null;
        this.dayHoursRatio = null;
        this.groupedRows = groupedRows;
        const comments: string[] = [];
        for (const groupedRow of groupedRows) {
            this.amount = this.amount + groupedRow.amount;
            this.startDate = groupedRow.startDate;
            this.endDate = groupedRow.endDate;
            this.dayHoursRatio = groupedRow.dayHoursRatio;
            comments.push(groupedRow.comment);
        }
        this.comment = Base.getStringWithSeparators(comments, ", ");
    }

    getHash(): string {
        return Base.getStringWithSeparators([this.id, this.amount.toLocaleFixed(this.rowType.decimals), this.comment, (this.startDate ?? 0).toString(10), (this.endDate ?? 0).toString(10), (this.dayHoursRatio ?? 0).toString(10)], "#");
    }

    static createSalaryEditItemSalaryRow(salaryRowTypeId: string, amount: number, comment: string, startDate: number, endDate: number, dayHoursRatio: number): SalaryEditItemSalaryRow {
        const result = new SalaryEditItemSalaryRow();
        result.salaryRowTypeId = salaryRowTypeId;
        result.amount = amount;
        result.comment = comment;
        result.startDate = startDate;
        result.endDate = endDate;
        result.dayHoursRatio = dayHoursRatio;
        return result;
    }

    static sortSalaryEditItemSalaryRows(items: ISalaryEditItemSalaryRow[]) {
        if (!items || items.length < 2) return;
        items.sort((a: ISalaryEditItemSalaryRow, b: ISalaryEditItemSalaryRow) => {
            if (!a || !b) {
                return 0;
            }
            const result = Base.stringCompare(a.rowType.code, b.rowType.code);
            return result !== 0 ? result : Base.stringCompare(a.rowType.name, b.rowType.name);
        });
    }
}

//SalaryEditItemSalaryRowSave
export interface ISalaryEditItemSalaryRowSave {
    salaryRowTypeId: string;
    amount: number;
    comment: string;
    startDate: number;
    endDate: number;
    dayHoursRatio: number;
}

export class SalaryEditItemSalaryRowSave implements ISalaryEditItemSalaryRowSave {
    salaryRowTypeId: string;
    amount: number;
    comment: string;
    startDate: number;
    endDate: number;
    dayHoursRatio: number;

    constructor();
    constructor(obj: ISalaryEditItemSalaryRow);
    constructor(obj?: any) {
        this.salaryRowTypeId = obj && obj.salaryRowTypeId || "";
        this.amount = obj && obj.amount || 0;
        this.comment = obj && obj.comment || "";
        this.startDate = obj && obj.startDate || null;
        this.endDate = obj && obj.endDate || null;
        this.dayHoursRatio = obj && obj.dayHoursRatio || null;
    }
}
