export interface IEmployeeCompetencyItem {
    id: string;
    employeeName: string;
    competencyTypeName: string;
    competencyName: string;
    date: number;
    expireDate: number;
    comment: string;
}

export class EmployeeCompetencyItem implements IEmployeeCompetencyItem {
    id: string;
    employeeName: string;
    competencyTypeName: string;
    competencyName: string;
    date: number;
    expireDate: number;
    comment: string;

    constructor();
    constructor(obj: IEmployeeCompetencyItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.employeeName = obj && obj.employeeName || "";
        this.competencyTypeName = obj && obj.competencyTypeName || "";
        this.competencyName = obj && obj.competencyName || "";
        this.date = obj && obj.date || null;
        this.expireDate = obj && obj.expireDate || null;
        this.comment = obj && obj.comment || "";
    }
}