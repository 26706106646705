export interface IProductItem {
    id: string;
    number: number;
    name: string;
    description: string;
    erp: string;
    unitPrice: number;
    unit: string;
    activeState: number;
    productGroupCode: string;
    productGroupName: string;

    getTitle(): string;
}

export class ProductItem implements IProductItem {
    id: string;
    number: number;
    name: string;
    description: string;
    erp: string;
    unitPrice: number;
    unit: string;
    activeState: number;
    productGroupCode: string;
    productGroupName: string;

    constructor();
    constructor(obj: IProductItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.name = obj && obj.name || "";
        this.description = obj && obj.description || "";
        this.erp = obj && obj.erp || "";
        this.unitPrice = obj && obj.unitPrice || 0;
        this.unit = obj && obj.unit || "";
        this.activeState = obj && obj.activeState || 0;
        this.productGroupCode = obj && obj.productGroupCode || "";
        this.productGroupName = obj && obj.productGroupName || "";
    }

    getTitle(): string {
        return this.name;
    }
}
