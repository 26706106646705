// ListHeaderColumn
// ***********************************************************************************************************************
import * as React from "react";

export interface IUnsortableListHeaderColumnProp {
    title: string;
    classes: string;
    style?: any;
    icon?: string;
}

export interface IListHeaderColumnProp extends IUnsortableListHeaderColumnProp {
    column: string;
    sortColumn: string;
    sortOrderIsAsc: boolean;
    onClick: (column: string) => void;
}

export interface IListHeaderCheckBoxColumnProp extends IListHeaderColumnProp {
    onCheckboxChange: (column: string, checked: boolean) => void;
}

export interface IUnsortableListHeaderCheckBoxColumnProp extends IUnsortableListHeaderColumnProp {
    onCheckboxChange: (column: string, checked: boolean) => void;
}

interface IListHeaderBaseColumnProp extends IListHeaderCheckBoxColumnProp {
    hasCheckBox: boolean;
}

class ListHeaderBaseColumn extends React.Component<IListHeaderBaseColumnProp, {}> {
    handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.onClick == null) return;
        this.props.onClick(this.props.column);
    };

    handleCheckboxChange = (e) => {
        e.stopPropagation();
        if (this.props.onCheckboxChange == null) return;
        this.props.onCheckboxChange(this.props.column, e.target.checked);
    };

    render() {
        const props = this.props;
        const colClass = "vertSplitter" + (props.classes ? " " + props.classes : "");
        const spanClass = props.sortColumn ? ("ml-2 sort" + (props.column === props.sortColumn ? (props.sortOrderIsAsc ? "asc bi bi-arrow-down" : "desc bi bi-arrow-up") : "")) : "";
        const style = props.style;
        return (
            <div className={colClass} onClick={this.handleClick} style={style} title={props.title}>
                {props.hasCheckBox && <input type="checkbox" onClick={this.handleCheckboxChange} value="" />}
                <span>{props.title}{/*<i className="ml-2 bi bi-arrow-down-up"></i>*/}</span>
                <span className={spanClass} />
            </div>
        );
    }
}

export class ListHeaderColumn extends React.Component<IListHeaderColumnProp, {}> {
    render() {
        const props = this.props;
        return (
            <ListHeaderBaseColumn
                title={props.title}
                column={props.column}
                classes={props.classes}
                style={props.style}
                sortColumn={props.sortColumn}
                sortOrderIsAsc={props.sortOrderIsAsc}
                hasCheckBox={false}
                onClick={props.onClick}
                onCheckboxChange={null}
            />
        );
    }
}

export class ListHeaderCheckBoxColumn extends React.Component<IListHeaderCheckBoxColumnProp, {}> {
    render() {
        const props = this.props;
        return (
            <ListHeaderBaseColumn
                title={props.title}
                column={props.column}
                classes={props.classes}
                style={props.style}
                sortColumn={props.sortColumn}
                sortOrderIsAsc={props.sortOrderIsAsc}
                hasCheckBox={true}
                onClick={props.onClick}
                onCheckboxChange={props.onCheckboxChange}
            />
        );
    }
}

export class UnsortableListHeaderColumn extends React.Component<IUnsortableListHeaderColumnProp, {}> {
    render() {
        const props = this.props;
        return (
            <ListHeaderBaseColumn
                title={props.title}
                column=""
                classes={props.classes}
                style={props.style}
                sortColumn=""
                sortOrderIsAsc={false}
                hasCheckBox={false}
                onClick={null}
                onCheckboxChange={null}
            />
        );
    }
}

export class UnsortableListHeaderCheckBoxColumn extends React.Component<IUnsortableListHeaderCheckBoxColumnProp, {}> {
    render() {
        const props = this.props;
        return (
            <ListHeaderBaseColumn
                title={props.title}
                column=""
                classes={props.classes}
                style={props.style}
                sortColumn=""
                sortOrderIsAsc={false}
                hasCheckBox={true}
                onClick={null}
                onCheckboxChange={props.onCheckboxChange}
            />
        );
    }
}