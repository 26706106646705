import { Base } from "../../framework/base";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import { Translations } from "../translations";

export class IStorageProduct {
    id: string;
    productId: string;
    storageLocationId: string;
    storageName: string;
    storageLocationName: string;
    customerName: string;
    productGroupCode: string;
    productName: string;
    storageAmount: number;
    bookedAmount: number;
    unit: string;
    rowId: string;
}

export class ProductEditItem {
    productId: string = null;
    productGroupId: string = null;
    name = "";
    productNumber: number | string = "";
    description = "";
    erpReference = "";
    activeState = 1;
    comment = "";
    unit: number = null;
    unitPrice = 0;
    storageProducts: StorageProductEditItem[] = [];
    customerPrices: CustomerPriceEditItem[] = [];
    removedStorageProductIds: string[] = [];
    removedCustomerPriceIds: string[] = [];
    rowId: string = null;
}

export class StorageProductEditItem {
    id: string = null;
    storageId: string = null;
    storageLocationId: string = null;
    freeAmount = 0;
    bookedAmount = 0;
}

export class CustomerPriceEditItem {
    id: string = null;
    customerId: string = null;
    unitPrice = 0;
    rowId: string = null;
}

export class StorageProduct {
    id: string;
    productId: string;
    storageLocationId: string;
    storageName: string;
    storageLocationName: string;
    customerName: string;
    productGroupCode: string;
    productName: string;
    storageAmount: number;
    bookedAmount: number;
    unit: string;
    rowId: string;

    constructor();
    constructor(obj: IStorageProduct);
    constructor(obj?: any) {
        if (obj == null || !obj.id) {
            this.id = Base.getGuid();
            this.storageAmount = 0;
        } else {
            this.id = obj.id;
            this.productId = obj.productId;
            this.storageLocationId = obj.storageLocationId;
            this.storageName = obj.storageName;
            this.storageLocationName = obj.storageLocationName;
            this.customerName = obj.customerName;
            this.productGroupCode = obj.productGroupCode;
            this.productName = obj.productName;
            this.storageAmount = obj.storageAmount;
            this.bookedAmount = obj.bookedAmount;
            this.unit = obj.unit;
            this.rowId = obj.rowId;
        }
    }

    validate(): boolean {
        if (this.storageLocationId === Base.emptyGuid || this.storageLocationId === null) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.StorageLocationMustBeDefined));
            return false;
        } else if (!this.productId) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.ProductMustBeDefined));
            return false;
        }
        return true;
    }
}
