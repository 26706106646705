import { getApiCall } from "./tokenService";
import { CalendarDay } from "../models/calendarDay/calendarDay";

export const getCalendarDays = (
    startDate: string,
    endDate: string,
    signal?: AbortSignal
): Promise<CalendarDay[]> => {
    return getApiCall<CalendarDay[]>(
        `api/calendardays?startDate=${startDate}&endDate=${endDate}`,
        null,
        signal
    );
};
