import { EnumHelper, UserGroupPermission } from "../common/enums";

export interface IEmployeeItem {
    id: string;
    employeeGroupId: string;
    salaryEmployeeGroupId: string;
    employeeGroupName: string;
    salaryEmployeeGroupName: string;
    number: number;
    name: string;
    fullName: string;
    abbreviation: string;
    picture: string;
    username: string;
    userGroupName: string;
    phone: string;
    workTimeAccountInUse: boolean;
    vehicleRegisterNumber: string;
    activeState: number;
    webPermission: number;
    mobilePermission: number;
    rowId: string;

    getTitle(): string;
    getAvatarText(): string;
    getIsOwnerAdmin(): boolean;
}

export class EmployeeItem implements IEmployeeItem {
    id: string;
    employeeGroupId: string;
    salaryEmployeeGroupId: string;
    employeeGroupName: string;
    salaryEmployeeGroupName: string;
    number: number;
    name: string;
    fullName: string;
    abbreviation: string;
    picture: string;
    username: string;
    userGroupName: string;
    phone: string;
    workTimeAccountInUse: boolean;
    vehicleRegisterNumber: string;
    activeState: number;
    webPermission: number;
    mobilePermission: number;
    rowId: string;

    constructor();
    constructor(obj: IEmployeeItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.employeeGroupId = obj && obj.employeeGroupId || "";
        this.employeeGroupName = obj && obj.employeeGroupName || "";
        this.salaryEmployeeGroupId = obj && obj.salaryEmployeeGroupId || "";
        this.salaryEmployeeGroupName = obj && obj.salaryEmployeeGroupName || "";
        this.phone = obj && obj.phone || "";
        this.number = obj && obj.number || 0;
        this.name = obj && obj.name || "";
        this.fullName = obj && obj.fullName || "";
        this.abbreviation = obj && obj.abbreviation || "";
        this.picture = obj && obj.picture || "";
        this.username = obj && obj.username || "";
        this.userGroupName = obj && obj.userGroupName || "";
        this.phone = obj && obj.phone || "";
        this.workTimeAccountInUse = obj && obj.workTimeAccountInUse || false;
        this.vehicleRegisterNumber = obj && obj.vehicleRegisterNumber || "";
        this.activeState = obj && obj.activeState || 0;
        this.webPermission = obj && obj.webPermission || 0;
        this.mobilePermission = obj && obj.mobilePermission || 0;
        this.rowId = obj && obj.rowId || "";
    }

    getTitle(): string {
        return this.name;
    }

    getAvatarText(): string {
        if (this.abbreviation) {
            return this.abbreviation.slice(0, 3);
        }
        if (this.name) {
            return this.name;
        }
        return "";
    }

    getIsOwnerAdmin(): boolean {
        return EnumHelper.isGreaterOrEqual(this.webPermission, UserGroupPermission.Admin);
    }
}
