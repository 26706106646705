import React, { useEffect, useMemo, useState } from "react";
import { useMap } from "@vis.gl/react-google-maps";
import {
    Cluster,
    ClusterStats,
    MarkerClusterer as GoogleMarkerClusterer,
    Marker,
    Renderer,
    SuperClusterOptions,
} from "@googlemaps/markerclusterer";
import { ClusteredMarkerChip } from "./markerChip";
import { MapPointProps } from "./map";
import { Base } from "../../../framework/base";
import { donutChart } from "../../../charts/donutChart";

interface MarkerClustererProps {
    points: MapPointProps[];
    onPointSelect?: (id: string) => void;
    onMouseEnter?: (el: HTMLElement, ids: string[]) => void;
    onMouseLeave?: (el: HTMLElement, ids: string[]) => void;
}

const markerClass = "clustered-marker";

interface Group {
    color: string;
    name: string;
}

interface ColorByPoint {
    [key: string]: Group;
}

// https://github.com/mapbox/supercluster#options
const clusterRadius = 120; // Cluster radius, in pixels.

export const MarkerClusterer = ({
    points,
    onPointSelect,
    onMouseEnter,
    onMouseLeave,
}: MarkerClustererProps) => {
    const map = useMap();
    const [clusterer, setClusterer] = useState<GoogleMarkerClusterer>();

    const colorByPointId = useMemo(
        () =>
            !!points
                ? Object.fromEntries(
                      points.map((p) => [
                          p.id,
                          { color: p.groupColor, name: p.groupName },
                      ])
                  )
                : {},
        [points]
    );

    // Initialize MarkerClusterer, if the map has changed
    useEffect(() => {
        if (!map) return;

        setClusterer(
            new GoogleMarkerClusterer({
                map,
                algorithmOptions: {
                    radius: clusterRadius,
                } as SuperClusterOptions,
                renderer:
                    !!onMouseEnter || !!onMouseLeave
                        ? new MarkerClusterRenderer({
                              onMouseEnter,
                              onMouseLeave,
                              markerClass,
                              colorByPointId,
                          })
                        : undefined,
            })
        );

        return () => {
            clusterer?.setMap(null);
        };
    }, [map, colorByPointId]);

    return points?.map((r, i) => (
        <ClusteredMarkerChip
            key={`${i}-${r.coords.lat}-${r.coords.lng}`}
            clusterer={clusterer}
            point={r}
            onSelect={onPointSelect}
            className={markerClass}
        />
    ));
};

// Renders a donut chart of the clustered items.
// Displays menu of the items on hover.
class MarkerClusterRenderer implements Renderer {
    private _onMouseEnter: (marker: Node, ids: string[]) => void;
    private _onMouseLeave: (marker: Node, ids: string[]) => void;
    private _markerClass: string;
    private _colorByPointId: ColorByPoint;

    constructor({
        onMouseEnter,
        onMouseLeave,
        markerClass,
        colorByPointId,
    }: {
        onMouseEnter: (marker: Node, ids: string[]) => void;
        onMouseLeave: (marker: Node, ids: string[]) => void;
        markerClass: string;
        colorByPointId: ColorByPoint;
    }) {
        this._onMouseEnter = onMouseEnter;
        this._onMouseLeave = onMouseLeave;
        this._markerClass = markerClass;
        this._colorByPointId = colorByPointId;
    }

    private getMarkerIds(markers: Marker[]) {
        return markers
            .map(
                (m) =>
                    (
                        (
                            (m as google.maps.marker.AdvancedMarkerElement)
                                .content as Element
                        )?.querySelector(`.${this._markerClass}`) as HTMLElement
                    )?.dataset.id
            )
            .filter(Boolean);
    }

    private getClusterGroupData(markers: Marker[]) {
        const grouped = Base.groupArray(
            this.getMarkerIds(markers).map((id) => this._colorByPointId[id]),
            "name"
        );

        return Object.keys(grouped)
            .sort()
            .map((k) => ({
                name: k,
                value: grouped[k].length,
                color: grouped[k][0].color,
            }));
    }

    public render(
        { count, position, markers }: Cluster,
        _: ClusterStats,
        map: google.maps.Map
    ): Marker {
        // adjust zIndex to be above other markers
        const zIndex: number = Number(google.maps.Marker.MAX_ZINDEX) + count;

        const svgEl = donutChart({
            data: this.getClusterGroupData(markers),
            width: 50,
            totalCount: count,
            showTotalCount: true,
        });
        svgEl.setAttribute("transform", "translate(0 25)");

        const clusterOptions: google.maps.marker.AdvancedMarkerElementOptions =
            {
                map,
                position,
                zIndex,
                content: svgEl,
            };

        const marker = new google.maps.marker.AdvancedMarkerElement(
            clusterOptions
        );

        if (this._onMouseEnter) {
            marker.content?.getRootNode().addEventListener("mouseenter", () => {
                this._onMouseEnter(marker.content, this.getMarkerIds(markers));
            });
        }

        if (this._onMouseLeave) {
            marker.content
                ?.getRootNode()
                .addEventListener("mouseleave", () =>
                    this._onMouseLeave(
                        marker.content,
                        this.getMarkerIds(markers)
                    )
                );
        }

        return marker;
    }
}
