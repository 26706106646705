// WorkOrderDialogHourBooking - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import Datetime from "react-datetime";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import { PropertyDialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { IWorkOrderEditItemHourBooking } from "../../models/work/workOrderEditItemHourBooking";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { IdTitle } from "../../models/common/idTitle";
import { AppUtils } from "../../models/common/appUtils";
import { TimeFormat } from "../../models/common/enums";
import { IEmployeeItem } from "../../models/employee/employeeItem";

// WorkOrderDialogHourBookingDialog
// ***********************************************************************************************************************
export interface IWorkOrderDialogHourBookingDialogProp {
    classes?: string;
    workTimeFormat: TimeFormat;
    isReadOnly: boolean;
    hourBooking: IWorkOrderEditItemHourBooking;
    employees: IEmployeeItem[];
    onOk: (employeeId: string, date: number, amount: number, invoice: number, comment: string) => void;
    onCancel: () => void;
}

export interface IWorkOrderDialogHourBookingDialogState {
    dateStr: string;
    employeeId: string;
    amountOrgStr: string;
    amountStr: string;
    invoiceAmountStr: string;
    calculatedAmountStr: string;
    comment: string;
}

export class WorkOrderDialogHourBookingDialog extends React.Component<IWorkOrderDialogHourBookingDialogProp, IWorkOrderDialogHourBookingDialogState> {
    constructor(props: IWorkOrderDialogHourBookingDialogProp) {
        super(props);
        const amountStr = props.hourBooking.isNew() ? "" : AppUtils.getWorkTimeStr(props.workTimeFormat, props.hourBooking.amount);
        this.state = {
            dateStr: Base.utcTimeToDateStr(props.hourBooking.date),
            employeeId: props.hourBooking.employeeId,
            amountOrgStr: amountStr,
            amountStr: amountStr,
            invoiceAmountStr: props.hourBooking.isNew() || Base.isNullOrUndefined(props.hourBooking.invoice) ? "" : AppUtils.getWorkTimeStr(props.workTimeFormat, props.hourBooking.invoice),
            calculatedAmountStr: props.hourBooking.isNew() || Base.isNullOrUndefined(props.hourBooking.invoice) ? "" : AppUtils.getWorkTimeStr(props.workTimeFormat, props.hourBooking.calculated),
            comment: props.hourBooking.comment
        };
    }

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "amount") {
            this.setState({ amountStr: value });
        } else if (name === "invoice") {
            this.setState({ invoiceAmountStr: value });
        } else if (name === "employeeId") {
            this.setState({ employeeId: value });
        } else if (name === "comment") {
            this.setState({ comment: value });
        }
    };

    handleBlur = (event) => {
        const props = this.props;
        const state = this.state;
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "amount") {
            const amountStr = AppUtils.strToValidWorkTimeStr(props.workTimeFormat, value);
            let invoiceAmountStr = state.invoiceAmountStr;
            if (!invoiceAmountStr || invoiceAmountStr === state.amountOrgStr) {
                invoiceAmountStr = amountStr;
            }
            this.setState({ amountStr: amountStr, amountOrgStr: amountStr, invoiceAmountStr: invoiceAmountStr });
        } else if (name === "invoice") {
            this.setState({ invoiceAmountStr: AppUtils.strToValidWorkTimeStr(props.workTimeFormat, value) });
        }
    };

    handleDateChange = (value: moment.Moment | string) => {
        if (!(typeof value === "string")) {
            this.setState({ dateStr: Base.utcTimeToDateStr(value) });
        }
    };

    handleDateBlur = (value: moment.Moment | string) => {
        if (typeof value === "string") {
            this.setState({ dateStr: Base.utcTimeToDateStr(value.toUtcDate().getTime()) });
        } else {
            this.setState({ dateStr: Base.utcTimeToDateStr(value) });
        }
    };

    private static checkErrors = (props: IWorkOrderDialogHourBookingDialogProp, state: IWorkOrderDialogHourBookingDialogState): Promise<boolean> => {
        return new Promise<boolean>((resolve) => {
            if (!state.employeeId) {
                store.customStore.dispatch(storeActions.showErrorMessage(Translations.EmployeeMustBeDefined));
                return resolve(false);
            }
            const amount = AppUtils.strWorkTimeToHours(props.workTimeFormat, state.amountStr);
            if (!state.comment && amount < 0.01) {
                store.customStore.dispatch(storeActions.showErrorMessage(Translations.HourBookingCommentMustBeDefinedIfAmountIsZero));
                return resolve(false);
            }
            return resolve(true);
        });
    };

    private static getWarningMessage = (props: IWorkOrderDialogHourBookingDialogProp, state: IWorkOrderDialogHourBookingDialogState): Promise<string> => {
        return new Promise<string>((resolve) => {
            const amount = AppUtils.strWorkTimeToHours(props.workTimeFormat, state.amountStr);
            if (amount < 0.01) {
                return resolve(Translations.HourBookingAmountIsZero + Base.lf);
            }
            return resolve("");
        });
    };

    private static validate = (props: IWorkOrderDialogHourBookingDialogProp, state: IWorkOrderDialogHourBookingDialogState, saveCallback: () => void): Promise<void> => {
        return WorkOrderDialogHourBookingDialog.checkErrors(props, state).then(success => {
            if (success) {
                return WorkOrderDialogHourBookingDialog.getWarningMessage(props, state).then(warnings => {
                    if (!warnings) {
                        saveCallback();
                        return new Promise<void>((resolve) => { resolve(); });
                    }
                    return new Promise<void>((resolve) => {
                        store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, warnings + Base.lf + Translations.DoYouReallyWantToSaveData,
                            () => {
                                store.customStore.dispatch(storeActions.clearConfirmation());
                                saveCallback();
                                resolve();
                            },
                            () => {
                                store.customStore.dispatch(storeActions.clearConfirmation());
                                resolve();
                            }, null));
                    });
                });
            } else {
                return new Promise<void>((resolve) => { resolve(); });
            }
        });
    };

    handleOkClick = () => {
        const props = this.props;
        const state = this.state;
        WorkOrderDialogHourBookingDialog.validate(props, state, () => {
            props.onOk(state.employeeId, state.dateStr.toUtcDate().getTime(), AppUtils.strWorkTimeToHours(props.workTimeFormat, state.amountStr), AppUtils.strWorkTimeToHours(props.workTimeFormat, state.invoiceAmountStr), state.comment);
        });
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "hourBooking px600" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.HourBooking + " - " + (!props.hourBooking.isNew() ? Base.utcTimeToDateStr(props.hourBooking.date) : Translations.New)}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group required">
                                    <label className="control-label">{Translations.Date}</label>
                                    <Datetime
                                        locale={appConfig.culture}
                                        className={"roWhite"}
                                        value={state.dateStr}
                                        dateFormat={"D.M.YYYY"}
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        inputProps={{ maxLength: 10, readOnly: props.isReadOnly, disabled: props.isReadOnly }}
                                        onChange={this.handleDateChange}
                                        onClose={this.handleDateBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="form-group required">
                                    <label className="control-label">{Translations.Employee}</label>
                                    <select className="custom-select" name="employeeId" title={Translations.Employee} value={state.employeeId} disabled={props.isReadOnly} onChange={this.handleChange}>
                                        {props.employees.map((employee) =>
                                            <option key={employee.id} value={employee.id}>{employee.name}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group required">
                                    <label className="control-label">{Translations.AmountH}</label>
                                    <input type="text" className="form-control" name="amount" title={Translations.AmountH} value={state.amountStr} onChange={this.handleChange} onBlur={this.handleBlur} maxLength={5} readOnly={props.isReadOnly} />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group required">
                                    <label className="control-label">{Translations.InvoiceH}</label>
                                    <input type="text" className="form-control" name="invoice" title={Translations.InvoiceH} value={state.invoiceAmountStr} onChange={this.handleChange} onBlur={this.handleBlur} maxLength={5} readOnly={props.isReadOnly} />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group required">
                                    <label className="control-label">{Translations.CalculatedH}</label>
                                    <input type="text" className="form-control" name="calculated" title={Translations.CalculatedH} value={state.calculatedAmountStr} readOnly={true} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="control-label">{Translations.Comment}</label>
                                    <textarea className={"form-control" + (props.isReadOnly ? " disabled" : "")} name="comment" title={Translations.Comment} value={state.comment} onChange={this.handleChange} maxLength={1000} readOnly={props.isReadOnly} />
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[{ title: Translations.Save, classes: "btn-primary" + (props.isReadOnly ? " d-none" : ""), enabled: !props.isReadOnly, onClick: this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}

// WorkOrderDialogHourBookingListLine
export interface IWorkOrderDialogHourBookingListLineProp {
    showHourBookingInvoiceHours: boolean;
    workTimeFormat: TimeFormat;
    employees: IEmployeeItem[];
    hourBooking: IWorkOrderEditItemHourBooking;
    selectedId: string;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

export class WorkOrderDialogHourBookingListLine extends React.Component<IWorkOrderDialogHourBookingListLineProp, {}> {
    render() {
        const props = this.props;
        const hourBooking = props.hourBooking;
        const employeeName = IdTitle.getTitleById(props.employees, hourBooking.employeeId);
        const amount = AppUtils.getWorkTimeStr(props.workTimeFormat, props.showHourBookingInvoiceHours ? hourBooking.invoice : hourBooking.amount) + " " + Translations.MeasureUnitH;
        return (
            <div className={"row line" + (hourBooking.id === props.selectedId ? " selected" : "")} onClick={() => { props.onClick(hourBooking.id); }} onDoubleClick={() => { props.onDoubleClick(hourBooking.id); }}
                title={Base.getStringWithSeparators([Base.utcTimeToDateStr(hourBooking.date) + " " + employeeName, amount, hourBooking.comment], ", ")}
            >
                <div className="col-2">{Translations.HourBooking}</div>
                <div className="col-7">{Base.utcTimeToDateStr(hourBooking.date) + " " + employeeName}</div>
                <div className="col-3 text-right">{amount}</div>
            </div>
        );
    }
}
