export const Units = [
    "",
    "kpl",
    "kg",
    "m",
    "l",
    "h",
    "km",
    "pkt",
    "plo",
    "m2",
    "tn",
    "m3",
]
