import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useAppSelector, useAppDispatch } from "../../../framework/customStore";
import { MuiDatePicker, MuiDateRangePicker } from "../../framework/muiDatepicker";
import { Translations } from "../../../models/translations";
import {
    setTimelineStart,
    setTimeRange,
    setSelectedVehicleGroups,
    setSelectedVehicles,
    setSelectedEmployees,
    setSelectedEmployeeGroups
} from "../../../store/transport/transportVehiclesSlice";
import { VehicleGroupSelect } from "../components/vehicleGroupSelect";
import { VehicleSelect } from "../components/vehicleSelect";
import { DriverSelect } from "../components/driverSelect";
import { EmployeeGroupSelect } from "../components/employeeGroupSelect";

interface ITransportTimelineFilters {
    timeRangeEnabled?: boolean;
    vehicleGroupsEnabled?: boolean;
    vehiclesEnabled?: boolean;
    employeeGroupsEnabled?: boolean;
    employeesEnabled?: boolean;
}

export const TransportTimelineFilters = (
    {
        timeRangeEnabled,
        vehicleGroupsEnabled,
        vehiclesEnabled,
        employeeGroupsEnabled,
        employeesEnabled
    }: ITransportTimelineFilters) => {
    const {
        timelineStart,
        timeRange,
        selectedVehicleGroups,
        selectedVehicles,
        selectedEmployeeGroups,
        selectedEmployees
    } = useAppSelector((state) => state.transportVehicles);
    const dispatch = useAppDispatch();
    return (
        <div>
            <Grid2 container spacing={2}>
                <Grid2>
                    <MuiDatePicker
                        label={Translations.ShowCalendarFrom}
                        value={timelineStart}
                        onChange={(val) => dispatch(setTimelineStart(val))}
                    />
                </Grid2>
                { timeRangeEnabled &&
                    <Grid2>
                        <MuiDateRangePicker
                            labels={["Aikaväli alkaa", "Aikaväli loppuu"]}
                            value={timeRange}
                            onChange={(val) => dispatch(setTimeRange(val))}
                        />
                    </Grid2>
                }
                { vehicleGroupsEnabled &&
                    <Grid2>
                        <VehicleGroupSelect
                            multiple
                            value={selectedVehicleGroups}
                            onChange={(val) =>
                                dispatch(setSelectedVehicleGroups(val))
                            }
                        />
                    </Grid2>
                }
                { vehiclesEnabled &&
                    <Grid2>
                        <VehicleSelect
                            multiple
                            value={selectedVehicles}
                            onChange={(val) => dispatch(setSelectedVehicles(val))}
                            selectedVehicleGroupIds={selectedVehicleGroups}
                        />
                    </Grid2>
                }
                { employeeGroupsEnabled &&
                    <Grid2>
                        <EmployeeGroupSelect
                            multiple
                            value={selectedEmployeeGroups}
                            onChange={(val) =>
                                dispatch(setSelectedEmployeeGroups(val))
                            }
                        />
                    </Grid2>
                }
                { employeesEnabled &&
                    <Grid2>
                        <DriverSelect
                            multiple
                            value={selectedEmployees}
                            onChange={(val) => dispatch(setSelectedEmployees(val))}
                        />
                    </Grid2>
                }
            </Grid2>
        </div>
    );
};
