export interface IWorkShiftTimeSlotType {
    id: string;
    title: string;
    usageType: number;
    category: number;

    getTitle(): string;
    isCategory(category: number): boolean;
}

export class WorkShiftTimeSlotType implements IWorkShiftTimeSlotType {
    id: string;
    title: string;
    usageType: number;
    category: number;

    constructor();
    constructor(obj: IWorkShiftTimeSlotType);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.title = obj && obj.title || "";
        this.usageType = obj && obj.usageType || 0;
        this.category = obj && obj.category || 0;
    }

    getTitle(): string {
        return this.title;
    }
    
    isCategory(category: number): boolean {
        return this.category > category - 0.5 && this.category <= category + 0.5;
    }

    static getIdByCategory(items: IWorkShiftTimeSlotType[], category: number): string {
        if (!items || items.length < 1) return "";
        const item = items.find(i => i.isCategory(category));
        if (!item) return "";
        return item.id;
    }

    static getRoutePointTransferWorkShiftTimeSlotTypeIds = (items: IWorkShiftTimeSlotType[]): string[] => {
        if (items.length < 1) return [];
        return [items[0].id];
    }
}