import {
    FormControlLabel,
    FormControlLabelProps,
    Switch,
    SwitchProps,
} from "@mui/material";
import React from "react";

interface Props extends SwitchProps {
    label?: string;
    labelSlotProps?: FormControlLabelProps["slotProps"];
    labelSx?: FormControlLabelProps["sx"];
}

export const MuiSwitch = (props: Props) => {
    const { label, labelSlotProps, labelSx, ...rest } = props;

    return (
        <FormControlLabel
            label={label}
            slotProps={labelSlotProps}
            control={<Switch {...rest} />}
            sx={labelSx}
        />
    );
};
