import { ApiResponse, apiCall } from "./apiClient";
import { ApiSuccess } from "./baseService";
import { InvoiceCreateDto } from "../models/transportOrderInvoice/invoiceCreateDto";
import { InvoiceListItemDto, InvoiceListQueryParameters } from "../models/transportOrderInvoice/invoiceListItemDto";
import { InvoiceDetailsDto } from "../models/transportOrderInvoice/invoiceDetailsDto";

export const getInvoices = (qsp: InvoiceListQueryParameters): Promise<ApiResponse<InvoiceListItemDto[]>> => {
    return apiCall<InvoiceListItemDto[]>("invoices", "GET", qsp);
};

export const getInvoice = (id: string): Promise<ApiResponse<InvoiceDetailsDto>> => {
    return apiCall<InvoiceDetailsDto>(`invoices/${id}`, "GET");
};

export const createNewInvoice = (data: InvoiceCreateDto): Promise<ApiResponse<InvoiceCreateDto>> => {
    return apiCall<InvoiceCreateDto>("Invoices",
        "POST",
        data);
};

export const addTransportOrdersToInvoice = (invoiceId: string, data: string[]): Promise<ApiResponse<string[]>> => {
    return apiCall<string[]>(`invoices/${invoiceId}/transportorders`,
        "PUT",
        data);
};

export const approveInvoice = (invoiceId: string, invoiceState: number): Promise<ApiResponse<ApiSuccess>> => {
    return apiCall(`Invoices/${invoiceId}/state?state=${invoiceState}`, "PUT");
};

export const sendInvoiceToIntegration = (invoiceId: string): Promise<ApiResponse<ApiSuccess>> => {
    return apiCall<ApiSuccess>(`invoices/${invoiceId}/send`, "PUT");
};

export const removeInvoice = (invoiceId: string,): Promise<ApiResponse<void>> => {
    return apiCall<void>(`Invoices/${invoiceId}`, "DELETE");
};
