// TrackingEventLogDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import { Dialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { IEventLogItem } from "../../models/eventLog/eventLogItem";

// TrackingEventLogDialog
// ***********************************************************************************************************************
export interface ITrackingEventLogDialogProp {
    classes?: string;
    editItem: IEventLogItem;
    onCancel: () => void;
}

export interface ITrackingEventLogDialogState {
}

export class TrackingEventLogDialog extends React.Component<ITrackingEventLogDialogProp, ITrackingEventLogDialogState> {
    handleCancelClick = () => {
        const obj = this;
        obj.props.onCancel();
    };

    render() {
        const props = this.props;
        const editItem = props.editItem;
        const dialogClasses = "tracking eventLog px800 property" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <Dialog
                    classes={dialogClasses}
                    title={Translations.EventLog + " - " + Base.timeToDateTimeStr(editItem.time)}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-3">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Time}</label>
                                    <div className="roValue">{Base.timeToDateTimeStr(editItem.time)}</div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.RelatedObjectType}</label>
                                    <div className="roValue">{editItem.objectType}</div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Number}</label>
                                    <div className="roValue">{editItem.number}</div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.EntryType}</label>
                                    <div className="roValue">{editItem.entryType}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Username}</label>
                                    <div className="roValue">{editItem.user}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Message}</label>
                                    <div className="roValue longValue">{editItem.message}</div>
                                </div>
                            </div>
                        </div>
                        {!!editItem.textData &&
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.AdditionalInformation}</label>
                                        <div className="roValue extraLongValue">{editItem.textData}</div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>}
                    buttons={[]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}