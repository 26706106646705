import { IIdTitle, IdTitle } from "./idTitle";
import { Base } from "../../framework/base";

export interface IIdTitleItems {
    items: IIdTitle[];
}

export class IdTitleItems implements IIdTitleItems {
    items: IdTitle[];

    constructor();
    constructor(obj: IIdTitleItems);
    constructor(obj?: any) {
        this.items = Base.getTypedArray<IdTitle>(!Base.isNullOrUndefined(obj) ? obj.items : null, IdTitle);
    }
}