// WorkOrderSaveAsTemplateDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { SaveData } from "../../framework/saveData";
import { AppUtils } from "../../models/common/appUtils";
import * as workOrderService from "../../services/workOrderService";
import { Translations } from "../../models/translations";
import { CheckBox } from "../framework/checkbox";
import { Dialog } from "../framework/dialog";
import * as StoreEffects from "../../models/store/storeEffects";
import { connect } from "react-redux";

export interface IWorkOrderSaveAsTemplateDialogProp {
    classes?: string;
    workOrderId: string;
    number: number;
    parentNumber: number;
    name: string;
    description: string;
    isProject: boolean;
    isRecurring: boolean;
    isWork: boolean;
    hasEmployees: boolean;
    hasVehicles: boolean;
    hasRoute: boolean;
    hasDocuments: boolean;
    hasBookings: boolean;
    hasTasks: boolean;
    onOk: (workOrderId: string) => void;
    onCancel: () => void;
    loadWorkOrderTemplates: () => void;
}

export interface IWorkOrderSaveAsTemplateDialogState {
    name: string;
    description: string;
    employees: boolean;
    vehicles: boolean;
    route: boolean;
    documents: boolean;
    bookings: boolean;
    projectTasks: boolean;
}

export class WorkOrderSaveAsTemplateDialog extends React.Component<IWorkOrderSaveAsTemplateDialogProp, IWorkOrderSaveAsTemplateDialogState> {
    constructor(props: IWorkOrderSaveAsTemplateDialogProp) {
        super(props);
        this.state = {
            name: props.name,
            description: props.description,
            employees: false,
            vehicles: false,
            route: false,
            documents: false,
            bookings: false,
            projectTasks: false
        };
    }

    // #region General
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "Nm") {
            this.setState({ name: value });
        } else if (name === "description") {
            this.setState({ description: value });
        }
    };
    // #endregion General

    private checkErrors = (): Promise<boolean> => {
        const state = this.state;
        return new Promise<boolean>((resolve) => {
            //Add error checking in here
            if (!state.name) {
                AppUtils.showErrorMessage(Translations.WorkTitleMustBeDefined);
                return resolve(false);
            }
            return resolve(true);
        });
    };

    private getWarningMessage = (): Promise<string> => {
        return new Promise<string>((resolve) => {
            //let warnings = "";
            //Add warning checking in here
            //if (!state.someField) {
            //    warnings = warnings + Translations.SomeFieldIsNotDefined + Base.lf;
            //}
            //return resolve(warnings);
            return resolve("");
        });
    };

    private static getSaveDataFromState = (props: IWorkOrderSaveAsTemplateDialogProp, state: IWorkOrderSaveAsTemplateDialogState): SaveData => {
        const data = new SaveData();
        // Common - Data that does not change
        data.append("id", props.workOrderId);
        // General - Data that changes
        data.append("name", state.name);
        data.append("description", state.description);
        data.append("copyEmployees", state.employees.toString());
        data.append("copyVehicles", state.vehicles.toString());
        data.append("copyRoute", state.route.toString());
        data.append("copyDocuments", state.documents.toString());
        data.append("copyBookings", state.bookings.toString());
        data.append("copyProjectTasks", state.projectTasks.toString());
        return data;
    };

    saveWorkOrderAsTemplate = async() => {
        const props = this.props;
        const state = this.state;
        if (!await AppUtils.validate(this.checkErrors, this.getWarningMessage)) return;
        const saveData = WorkOrderSaveAsTemplateDialog.getSaveDataFromState(props, state);
        //Call server
        const result = await AppUtils.callService(() => workOrderService.saveWorkOrderAsTemplate(saveData.formData));
        if (!result) return;
        props.onOk(result.id);
        props.loadWorkOrderTemplates();
    };

    handleOkClick = () => {
        this.saveWorkOrderAsTemplate();
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "saveWorkOrderAsTemplate px400" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <Dialog
                    classes={dialogClasses}
                    title={Translations.SaveAsWorkOrderTemplate}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.WorkTitle}</label>
                                    <input type="text" className="form-control" name="Nm" title={Translations.WorkTitle} value={state.name} onChange={this.handleChange} maxLength={100} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.WorkDescription}</label>
                                    <textarea className="form-control" name="description" title={Translations.WorkDescription} value={state.description} onChange={this.handleChange} maxLength={1000} />
                                </div>
                            </div>
                            <div className="col-12">
                                <label className="control-label smallFont noMargin">{Translations.DataToBeSavedInWorkOrderTemplate}</label>
                            </div>
                            <div className="col-12">
                                <CheckBox
                                    title={Translations.Employees}
                                    enabled={props.hasEmployees || props.isProject}
                                    checked={state.employees}
                                    onCheckboxClickBoolean={(value: boolean) => { this.setState({ employees: value }); }}
                                />
                            </div>
                            <div className="col-12">
                                <CheckBox
                                    title={Translations.Vehicles}
                                    enabled={props.hasVehicles || props.isProject}
                                    checked={state.vehicles}
                                    onCheckboxClickBoolean={(value: boolean) => { this.setState({ vehicles: value }); }}
                                />
                            </div>
                            <div className="col-12">
                                <CheckBox
                                    title={Translations.Route}
                                    enabled={props.hasRoute}
                                    checked={state.route}
                                    onCheckboxClickBoolean={(value: boolean) => { this.setState({ route: value }); }}
                                />
                            </div>
                            <div className="col-12">
                                <CheckBox
                                    title={Translations.Attachments}
                                    enabled={props.hasDocuments || props.isProject}
                                    checked={state.documents}
                                    onCheckboxClickBoolean={(value: boolean) => { this.setState({ documents: value }); }}
                                />
                            </div>
                            <div className="col-12">
                                <CheckBox
                                    title={Translations.ToBeInvoicedRecords}
                                    enabled={props.hasBookings || props.isProject}
                                    checked={state.bookings}
                                    onCheckboxClickBoolean={(value: boolean) => { this.setState({ bookings: value }); }}
                                />
                            </div>
                            {(props.hasTasks && !props.isRecurring) &&
                                <div className="col-12">
                                    <CheckBox
                                        title={Translations.WorkOrderTasks}
                                        enabled={true}
                                        checked={state.projectTasks}
                                        onCheckboxClickBoolean={(value: boolean) => { this.setState({ projectTasks: value }); }}
                                    />
                                </div>
                            }
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: this.handleOkClick }
                    ]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadWorkOrderTemplates: () => dispatch(StoreEffects.getWorkOrderTemplates()),
    };
}

export const WorkOrderSaveAsTemplateDialogConnected = connect(null, mapDispatchToProps)(WorkOrderSaveAsTemplateDialog);
