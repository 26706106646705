import * as React from "react";

// component with salaryperiod select

import { Translations } from "../../models/translations";
import MuiSelect from "../framework/muiSelect";
import { Base } from "../../framework/base";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ISalaryPeriodItem } from "../../models/salaryPeriod/salaryPeriodItem";

interface SalaryPeriodSelectProps {
    salaryPeriods: ISalaryPeriodItem[];
    selectedSalaryPeriod: ISalaryPeriodItem;
    onChange: (salaryPeriod: ISalaryPeriodItem) => void;
}
// using muiselect
export const SalaryPeriodSelect = (props: SalaryPeriodSelectProps) => {
    const {
        salaryPeriods,
        selectedSalaryPeriod,
        onChange
    } = props;

    const salaryPeriodChanged = React.useCallback((salaryPeriodId: string) => {
        onChange(salaryPeriods.find(x => x.id === salaryPeriodId));
    }, [onChange, salaryPeriods]);

    const [prevDisabled, setPrevDisabled] = React.useState<boolean>(false);
    const [nextDisabled, setNextDisabled] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!salaryPeriods || salaryPeriods.length === 0 || !selectedSalaryPeriod) {
            return;
        }
        const index = salaryPeriods.findIndex(x => x.id === selectedSalaryPeriod.id);
        setPrevDisabled(index === 0);
        setNextDisabled(index === salaryPeriods.length - 1);
    }, [salaryPeriods, selectedSalaryPeriod]);



    if (!salaryPeriods || salaryPeriods.length === 0 || !selectedSalaryPeriod) {
        return null;
    }

    function handlePrev() {
        const index = salaryPeriods.findIndex(x => x.id === selectedSalaryPeriod.id);
        if (index > 0) {
            onChange(salaryPeriods[index - 1]);
        }
    }

    function handleNext() {
        const index = salaryPeriods.findIndex(x => x.id === selectedSalaryPeriod.id);
        if (index < salaryPeriods.length - 1) {
            onChange(salaryPeriods[index + 1]);
        }
    }

    return (
        <Grid2 container alignItems="center" minWidth={300}>
            <Grid2 width="auto">
                <MuiSelect
                    options={salaryPeriods.map((x) => ({
                        label:
                            Base.dayjsToDateStr(x.startDate) +
                            "-" +
                            Base.dayjsToDateStr(x.endDate),
                        value: x.id,
                    }))}
                    defaultValue={selectedSalaryPeriod.id}
                    label={Translations.SalaryPeriod}
                    onChange={salaryPeriodChanged}
                    maxHeight={300}
                />
            </Grid2>
            <Grid2>
                <IconButton
                    color="primary"
                    onClick={() => handlePrev()}
                    disabled={prevDisabled}
                >
                    <ArrowBackIcon />
                </IconButton>
                <IconButton
                    color="primary"
                    onClick={() => handleNext()}
                    disabled={nextDisabled}
                >
                    <ArrowForwardIcon />
                </IconButton>
            </Grid2>
        </Grid2>
    );

};
