import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICostCenterItem } from "../models/costCenter/costCenterItem";
import { employeeGroupListItemDto } from "../models/employeeGroup/employeeGroup";
import { ISalaryPeriodItem } from "../models/salaryPeriod/salaryPeriodItem";
import { WorkTimeListColumnGroup } from "../models/workTime/workTimeList";

export interface Filters {
    selectedEmployeeGroup: employeeGroupListItemDto;
    selectedEmployees: string[];
    selectedCostCenters: ICostCenterItem[];
}
export interface FetchFilters {
    initialized: boolean;
    selectedSalaryPeriod: ISalaryPeriodItem;
}
export interface WorkTimeBetaState {
    filters: Filters;
    fetchFilters: FetchFilters;
    showFilters: boolean;
    workTimeListShownColumns: string[] | null;
    workTimeListOpenColumnGroups: WorkTimeListColumnGroup[];
    openedEmployees: string[];
}

export interface ToggleAllEmployeesOpenedPayload {
    opened: boolean;
    employeeIds: string[];
}

export const initialState: WorkTimeBetaState = {
    filters: {
        selectedEmployeeGroup: null,
        selectedEmployees: [],
        selectedCostCenters: []
    },
    fetchFilters: {
        initialized: false,
        selectedSalaryPeriod: null,
    },
    showFilters: true,
    workTimeListShownColumns: null,
    workTimeListOpenColumnGroups: [],
    openedEmployees: [],
};

export const workTimeBetaSlice = createSlice({
    name: "workTimeBeta",
    initialState,
    reducers: {
        updateFilters: (state, action: PayloadAction<Partial<Filters>>) => {
            state.filters = { ...state.filters, ...action.payload };
        },
        updateFetchFilters: (state, action: PayloadAction<Partial<FetchFilters>>) => {
            state.fetchFilters = { ...state.fetchFilters, ...action.payload };
            state.fetchFilters.initialized = !!state.fetchFilters.selectedSalaryPeriod?.id;
        },
        clearFilters: (state) => {
            state.fetchFilters.initialized = false;
            state.fetchFilters = { ...initialState.fetchFilters };
            state.filters = { ...initialState.filters };
        },
        toggleFilters: (state) => {
            state.showFilters = !state.showFilters;
        },
        updateWorkTimeListShownColumns: (state, action: PayloadAction<string[]>) => {
            state.workTimeListShownColumns = action.payload;
        },
        toggleWorkTimeListColumn: (state, action: PayloadAction<WorkTimeListColumnGroup>) => {
            state.workTimeListOpenColumnGroups =
                state.workTimeListOpenColumnGroups.includes(action.payload)
                    ? state.workTimeListOpenColumnGroups.filter(
                        (g) => g !== action.payload
                    )
                    : [...state.workTimeListOpenColumnGroups, action.payload];
        },
        toggleEmployeeOpened: (state, action: PayloadAction<string>) => {
            if(state.openedEmployees.includes(action.payload)){
                state.openedEmployees = state.openedEmployees.filter(x => x !== action.payload);
            } else {
                state.openedEmployees.push(action.payload);
            }
        },
        setAllEmployeesOpenedState: (state, action: PayloadAction<ToggleAllEmployeesOpenedPayload>) => {
            if(action.payload.opened){
                state.openedEmployees = action.payload.employeeIds;
            } else {
                state.openedEmployees = [];
            }
        },
    },
});

export const {
    updateFilters,
    updateFetchFilters,
    clearFilters,
    toggleFilters,
    updateWorkTimeListShownColumns,
    toggleWorkTimeListColumn,
    toggleEmployeeOpened,
    setAllEmployeesOpenedState,
} = workTimeBetaSlice.actions;

export const workTimeBetaReducer = workTimeBetaSlice.reducer;
