import { Base } from "../../framework/base";
import { IProductItem, ProductItem } from "./productItem";

export interface IProductItems {
    items: IProductItem[];
    hasMore: boolean;
    page: number;
}

export class ProductItems implements IProductItems {
    items: ProductItem[];
    hasMore: boolean;
    page: number;

    constructor();
    constructor(obj: IProductItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = Base.getTypedArray<ProductItem>(obj ? obj.items : null, ProductItem);
    }
}