import { Base } from "../../framework/base";
import { WorkOrderCategory, EnumHelper, WorkOrderState, RecurrenceType, SiteType, WorkOrderClass, LocationPointType } from "../common/enums";
import { Translations } from "../translations";
import { AppUtils } from "../common/appUtils";
import { ILocationPoint, LocationPoint } from "../common/locationPoint";
import { IIdTitle, IdTitle } from "../common/idTitle";

export interface IWorkOrderItem extends ILocationPoint {
    parentId: string;
    number: number;
    parentNumber: number;
    parentRecurrenceType: number;
    startTime: number;
    endTime: number;
    fullDay: boolean;
    name: string;
    description: string;
    workOrderTypeName: string;
    workOrderTypeSaveLocationData: boolean;
    customerName: string;
    siteType: SiteType;
    siteName: string;
    siteNumber: string;
    siteAddress: string;
    employees: IIdTitle[];
    vehicles: IIdTitle[];
    acknowledgementSent: boolean;
    state: number;
    canceled: boolean;
    comment: string;
    category: WorkOrderCategory;
    recurrenceType: RecurrenceType;
    hasChildren: boolean;
    hasRoute: boolean;
    hasBookings: boolean;
    hasDocuments: boolean;
    hasWorkOrderLocation: boolean;
    locationName: string;
    locationEmployeeId: string;
    rowId: string;
    sortOrder: number;

    tooltip: string;
    workOrderClass: WorkOrderClass;

    isPreliminary(): boolean;
    isPlanned(): boolean;
    isInProgress(): boolean;
    isLessThanInProgress(): boolean;
    isDone(): boolean;
    isChecked(): boolean;
    hasBeenTransferred(): boolean;
    isProject(): boolean;
    isWork(): boolean;
    isTask(): boolean;
    isRecurring(): boolean;
    isRecurringTask(): boolean;
    isOverlapping(startTime: number, endTime: number): boolean;
    siteHasLocation(): boolean;
    getTitle(): string;
    setTooltip(getLineName: (lineId: string) => string);
    getEmployeeNames(): string;
}

export class WorkOrderItem extends LocationPoint implements IWorkOrderItem {
    parentId: string;
    number: number;
    parentNumber: number;
    parentRecurrenceType: number;
    startTime: number;
    endTime: number;
    fullDay: boolean;
    name: string;
    description: string;
    workOrderTypeName: string;
    workOrderTypeSaveLocationData: boolean;
    customerName: string;
    siteType: SiteType;
    siteName: string;
    siteNumber: string;
    siteAddress: string;
    employees: IdTitle[];
    vehicles: IdTitle[];
    acknowledgementSent: boolean;
    state: number;
    canceled: boolean;
    comment: string;
    category: WorkOrderCategory;
    recurrenceType: RecurrenceType;
    hasChildren: boolean;
    hasRoute: boolean;
    hasBookings: boolean;
    hasDocuments: boolean;
    hasWorkOrderLocation: boolean;
    locationName: string;
    locationEmployeeId: string;
    rowId: string;
    sortOrder: number;
        
    tooltip: string;
    workOrderClass: WorkOrderClass;

    constructor();
    constructor(obj: IWorkOrderItem);
    constructor(obj?: any) {
        super(obj);
        this.parentId = obj && obj.parentId || "";
        this.number = obj && obj.number || 0;
        this.parentNumber = obj && obj.parentNumber || 0;
        this.parentRecurrenceType = obj && obj.parentRecurrenceType || 0;
        this.startTime = obj && obj.startTime || null;
        this.endTime = obj && obj.endTime || null;
        this.fullDay = obj && obj.fullDay || false;
        this.name = obj && obj.name || "";
        this.description = obj && obj.description || "";
        this.workOrderTypeName = obj && obj.workOrderTypeName || "";
        this.workOrderTypeSaveLocationData = obj && obj.workOrderTypeSaveLocationData || false;
        this.customerName = obj && obj.customerName || "";
        this.siteType = obj && obj.siteType || 0;
        this.siteName = obj && obj.siteName || "";
        this.siteNumber = obj && obj.siteNumber || "";
        this.siteAddress = obj && obj.siteAddress || "";
        this.employees = Base.getTypedArray<IdTitle>(obj ? obj.employees : null, IdTitle);
        this.vehicles = Base.getTypedArray<IdTitle>(obj ? obj.vehicles : null, IdTitle);
        this.acknowledgementSent = obj && obj.acknowledgementSent || false;
        this.state = obj && obj.state || 0;
        this.canceled = obj && obj.canceled || false;
        this.comment = obj && obj.comment || "";
        this.category = obj && obj.category || WorkOrderCategory.Work;
        this.recurrenceType = obj && obj.recurrenceType || RecurrenceType.NoRecurrence;
        this.hasChildren = obj && obj.hasChildren || false;
        this.hasRoute = obj && obj.hasRoute || false;
        this.hasBookings = obj && obj.hasBookings || false;
        this.hasDocuments = obj && obj.hasDocuments || false;
        this.hasWorkOrderLocation = obj && obj.hasWorkOrderLocation || false;
        this.locationName = obj && obj.locationName || "";
        this.locationEmployeeId = obj && obj.locationEmployeeId || "";
        this.rowId = obj && obj.rowId || "";
        this.tooltip = obj && obj.tooltip || "";
        this.locationPointType = LocationPointType.WorkOrder;
        this.locationPointCategory = this.hasWorkOrderLocation ? 1 : 0;
        this.workOrderClass = this.isWork()
            ? WorkOrderClass.Work
            : (this.isProject()
                ? (this.isRecurring() ? WorkOrderClass.RecurringWork : WorkOrderClass.Project)
                : (this.isRecurringTask() ? WorkOrderClass.RecurringTask : WorkOrderClass.ProjectTask));
    }

    isPreliminary(): boolean {
        return EnumHelper.isEqual(this.state, WorkOrderState.Preliminary);
    }

    isPlanned(): boolean {
        return EnumHelper.isEqual(this.state, WorkOrderState.Planned);
    }

    isInProgress(): boolean {
        return EnumHelper.isEqual(this.state, WorkOrderState.InProgress);
    }

    isLessThanInProgress(): boolean {
        return EnumHelper.isLessThan(this.state, WorkOrderState.InProgress);
    }

    isDone(): boolean {
        return EnumHelper.isEqual(this.state, WorkOrderState.Done);
    }

    isChecked(): boolean {
        return EnumHelper.isEqual(this.state, WorkOrderState.Checked);
    }

    hasBeenTransferred(): boolean {
        return EnumHelper.isGreaterOrEqual(this.state, WorkOrderState.Transferred);
    }

    isProject(): boolean {
        return EnumHelper.isEqual(this.category, WorkOrderCategory.Project);
    }

    isWork(): boolean {
        return EnumHelper.isEqual(this.category, WorkOrderCategory.Work);
    }

    isTask(): boolean {
        return EnumHelper.isEqual(this.category, WorkOrderCategory.Task);
    }

    isRecurring(): boolean {
        return !EnumHelper.isEqual(this.recurrenceType, RecurrenceType.NoRecurrence);
    }

    isRecurringTask(): boolean {
        return this.isTask() && EnumHelper.isGreaterThan(this.parentRecurrenceType, RecurrenceType.NoRecurrence);
    }

    isOverlapping(startTime: number, endTime: number): boolean {
        return Base.isOverlapping(this.startTime, this.endTime, startTime, endTime);
    }

    siteHasLocation(): boolean {
        return !EnumHelper.isEqual(this.siteType, SiteType.NoLocation);
    }

    getTitle(): string {
        return AppUtils.getWorkOrderTitle(this);
    }

    getTooltip(getLineName: (lineId: string) => string): string {
        const title = this.isProject()
            ? (this.isRecurring() ? Translations.RecurringWork : Translations.WorkOrderProject)
            : (this.isTask() ? Translations.WorkOrderTask : Translations.WorkOrderWork);
        if (this.isProject()) {
            return Base.getStringWithSeparators([title + " " + this.number.toString(10), this.customerName, this.name, this.siteName, this.siteAddress], "\n");
        } else {
            return Base.getStringWithSeparators([title + " " + this.number.toString(10), this.customerName, this.name, this.siteName, this.siteAddress,
                this.employees.map(i => getLineName(i.id)).join(", "), this.vehicles.map(i => getLineName(i.id)).join(", "), Base.timeToDateStr(this.startTime)], "\n");
        }
    }

    getEmployeeNames(): string {
        return this.employees.map(i => i.title).join(", ");
    }

    setTooltip(getLineName: (lineId: string) => string) {
        this.tooltip = this.getTooltip(getLineName);
    }

    getState(): number {
        return this.state;
    }

    getMapTooltip(): string {
        return Base.getStringWithSeparators([this.vehicles.length > 0 ? this.vehicles[0].title : "", IdTitle.getTitleById(this.employees, this.locationEmployeeId), this.name], ", ");
    }

    getLocationCategory(): number {
        return this.hasWorkOrderLocation ? 2 : 1;
    }

    static getMaxOverlap(workOrders: IWorkOrderItem[]): number {
        if (Base.isNullOrUndefined(workOrders) || workOrders.length < 2) return 1;
        let maxOverlaps = 1;
        for (let i = 0; i < workOrders.length; i++) {
            const a = workOrders[i];
            let overlaps = 0;
            for (let j = 0; j < workOrders.length; j++) {
                if (i === j) continue;
                const b = workOrders[j];
                if (b.endTime > a.startTime && b.endTime < a.endTime || b.startTime <= a.startTime && b.endTime > a.endTime || b.startTime >= a.startTime && b.startTime < a.endTime) {
                    overlaps++;
                }
            }
            if (overlaps > maxOverlaps) {
                maxOverlaps = overlaps;
            }
        }
        return maxOverlaps;
    }

    static sortWorkOrderItems(items: IWorkOrderItem[], column: string, asc: boolean) {
        if (Base.isNullOrUndefined(items) || items.length < 2) return;
        let sortFunc: (a: IWorkOrderItem, b: IWorkOrderItem) => number;
        if (column === "startTime") sortFunc = (a: IWorkOrderItem, b: IWorkOrderItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.startTime, b.startTime)); };
        else if (column === "endTime") sortFunc = (a: IWorkOrderItem, b: IWorkOrderItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.endTime, b.endTime)); };
        else if (column === "name") sortFunc = (a: IWorkOrderItem, b: IWorkOrderItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.name, b.name)); };
        else if (column === "description") sortFunc = (a: IWorkOrderItem, b: IWorkOrderItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.description, b.description)); };
        else if (column === "customerName") sortFunc = (a: IWorkOrderItem, b: IWorkOrderItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.customerName, b.customerName)); };
        else if (column === "siteName") sortFunc = (a: IWorkOrderItem, b: IWorkOrderItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.siteName, b.siteName)); };
        else if (column === "siteAddress") sortFunc = (a: IWorkOrderItem, b: IWorkOrderItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.siteAddress, b.siteAddress)); };
        //else if (column === "employeeName") sortFunc = (a: IWorkOrderItem, b: IWorkOrderItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.employeeNames, b.employeeNames)); };
        else if (column === "state") sortFunc = (a: IWorkOrderItem, b: IWorkOrderItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.state, b.state)); };
        else if (column === "category") sortFunc = (a: IWorkOrderItem, b: IWorkOrderItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.category, b.category)); };
        else if (column === "workOrderTypeName") sortFunc = (a: IWorkOrderItem, b: IWorkOrderItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.workOrderTypeName, b.workOrderTypeName)); };
        else sortFunc = (a: IWorkOrderItem, b: IWorkOrderItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.number, b.number)); };
        items.sort(sortFunc);
    }
}
