import { Base } from "../../framework/base";

export interface IContactEditItemCustomerContactItem {
    id: string;
    customerId: string;
    title: string;
    isNew: boolean;
}

export class ContactEditItemCustomerContactItem implements IContactEditItemCustomerContactItem {
    id: string;
    customerId: string;
    title: string;
    isNew: boolean;

    constructor();
    constructor(obj: IContactEditItemCustomerContactItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.customerId = obj && obj.customerId || "";
        this.title = obj && obj.title || "";
        this.isNew = !this.id;
    }

    static sortContactEditItemCustomerContactItems(items: IContactEditItemCustomerContactItem[], column: string, asc: boolean) {
        if (Base.isNullOrUndefined(items) || items.length < 2) return;
        const sortFunc = (a: IContactEditItemCustomerContactItem, b: IContactEditItemCustomerContactItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.title, b.title)); };
        items.sort(sortFunc);
    }
}