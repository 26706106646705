import * as React from "react";
import { Base } from "../../framework/base";
import { Translations } from "../../models/translations";
import { ToolButton } from "./toolButton";
//import { ImageCropDialog } from "./imageCropDialog";

export interface IImageSelectorProp {
    classes?: string;
    fileInputName?: string;
    disabled?: boolean;
    image: string;
    aspectRatio?: number;
    label?: string;
    onChange: (file: File) => void;
    onClear?: () => void;
    onError: (message: string) => void;
}

export interface IImageSelectorState {
    file: File;
    showImageCropDialog: boolean;
}

export class ImageSelector extends React.Component<IImageSelectorProp, IImageSelectorState> {
    constructor(props) {
        super(props);
        this.state = { file: null, showImageCropDialog: false };
    }

    handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const props = this.props;
        if (props.disabled || Base.isNullOrUndefined(e.target.files) || e.target.files.length < 1) return;
        const file = e.target.files[0];
        if (Base.isImageFile(file)) {
            props.onChange(file);
        } else {
            props.onError(Translations.SelectImageFile);
        }
    //    if (Base.isImageFile(file)) {
    //        this.setState({ file: file, showImageCropDialog: true });
    //    } else {
    //        this.props.onChange(file);
    //    }
    };

    handleClear = () => {
        this.setState({ showImageCropDialog: false });
        this.props.onClear();
    };

    handleCropOk = (file: File) => {
        this.setState({ showImageCropDialog: false });
        this.props.onChange(file);
    };

    handleCropCancel = () => {
        this.setState({ showImageCropDialog: false });
        this.props.onChange(null);
    };

    render() {
        const props = this.props;
        const classes = "imageSelector" + (props.classes ? " " + props.classes : "") + (props.disabled ? " disabled" : "");
        const fileInputName = props.fileInputName ? props.fileInputName : "image";
        const hint = !props.disabled ? (props.image ? Translations.ClickToChangeImage : Translations.ClickToAddImage) : "";
        return (
            <div>
                {props.label &&
                    <label className="control-label smallFont">{props.label}</label>
                }
                <div>
                    <label className={classes} title={hint} style={{ backgroundImage: "url(" + props.image + ")" } }>
                        {!props.disabled && !props.image &&
                            <span>{hint}</span>
                        }
                        {(!props.disabled) &&
                            <input type="file" name={fileInputName} accept="image/*" className="file" style={{ display: "none" }} multiple={false} onChange={this.handleFileChange} />
                        }
                        {(!props.disabled && !!props.image && !!props.onClear) &&
                            <ToolButton
                                classes={"close smallButton"}
                                enabled={true}
                                stopPropagation={true}
                                title={Translations.Remove}
                                onClick={this.handleClear}
                            />
                        }
                    </label>
                </div>
            </div>
        );
    }
}
