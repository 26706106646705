import React from "react";
import { EmployeesVehicleGrid } from "./employeesVehicleGrid";
import {
    ReportData,
    ReportOptionsMapping,
} from "../../../hooks/reporting/useReportData";
import { Reporting } from "../reportingMain";

const dataInitialized = (data: ReportData) =>
    !!data.workShifts && !!data.vehicles;

export const EmployeesMain = () => {
    return (
        <Reporting
            selectedOptions={[
                ReportOptionsMapping.vehicles.attr,
                ReportOptionsMapping.employees.attr,
                ReportOptionsMapping.costCenters.attr,
                ReportOptionsMapping.workTimeTypes.attr,
            ]}
            dataInitialized={dataInitialized}
            component={EmployeesVehicleGrid}
        />
    );
};
