import { Base } from "../../framework/base";
import { IRoutePointCheckEditItem, RoutePointCheckEditItem } from "../routePointCheck/routePointCheckEditItem";
import { ILocationPoint, LocationPoint } from "../common/locationPoint";
import { IRoutePointWorkShiftTimeSlotItem, RoutePointWorkShiftTimeSlotItem } from "./routePointWorkShiftTimeSlotItem";
import { Translations } from "../translations";
import { AppUtils } from "../common/appUtils";
import { EditItem } from "../common/editItem";
import { RoutePointTypeEnum } from "../common/enums";

export interface IRoutePointItem extends ILocationPoint {
    contactId: string;
    routePointTypeId: string;
    routePointTypeCode: RoutePointTypeEnum;
    number: number;
    name: string;
    name2: string;
    street: string;
    postalCode: string;
    city: string;
    countryCode: string;
    mapLink: string;
    fromPreviousDistanceM: number;
    fromPreviousDurationS: number;
    rowId: string;
    checks: IRoutePointCheckEditItem[];
    workShiftTimeSlots: IRoutePointWorkShiftTimeSlotItem[];

    isNew():boolean;
    getActiveWorkShiftTimeSlot(): IRoutePointWorkShiftTimeSlotItem;
    getFromPreviousDistanceKmStr(): string;
    getFromPreviousDurationStr(): string;
    getName(): string;
    getAddress(): string;
    getHash(): string;
    getTitle(): string;
    getLabelTitle(): string;
}

export class RoutePointItem extends LocationPoint implements IRoutePointItem {
    contactId: string;
    routePointTypeId: string;
    routePointTypeCode: RoutePointTypeEnum;
    number: number;
    name: string;
    name2: string;
    street: string;
    postalCode: string;
    city: string;
    countryCode: string;
    mapLink: string;
    fromPreviousDistanceM: number;
    fromPreviousDurationS: number;
    rowId: string;
    checks: RoutePointCheckEditItem[];
    workShiftTimeSlots: RoutePointWorkShiftTimeSlotItem[];

    constructor();
    constructor(obj: IRoutePointItem);
    constructor(obj?: any) {
        super(obj);
        this.contactId = obj && obj.contactId || "";
        this.routePointTypeId = obj && obj.routePointTypeId || "";
        this.routePointTypeCode = obj && obj.routePointTypeCode || RoutePointTypeEnum.OtherType;
        this.number = obj && obj.number || 0;
        this.name = obj && obj.name || "";
        this.name2 = obj && obj.name2 || "";
        this.street = obj && obj.street || "";
        this.postalCode = obj && obj.postalCode || "";
        this.city = obj && obj.city || "";
        this.countryCode = obj && obj.countryCode || "";
        this.longitude = obj && obj.longitude || 0;
        this.latitude = obj && obj.latitude || 0;
        this.mapLink = obj && obj.mapLink || "";
        this.fromPreviousDistanceM = obj && obj.fromPreviousDistanceM || 0;
        this.fromPreviousDurationS = obj && obj.fromPreviousDurationS || 0;
        this.rowId = obj && obj.rowId || "";
        this.checks = Base.getTypedArray<RoutePointCheckEditItem>(obj ? obj.checks : null, RoutePointCheckEditItem);
        this.workShiftTimeSlots = Base.getTypedArray<RoutePointWorkShiftTimeSlotItem>(obj ? obj.workShiftTimeSlots : null, RoutePointWorkShiftTimeSlotItem);
    }

    isNew():boolean {
        return !this.rowId;
    }

    getActiveWorkShiftTimeSlot(): IRoutePointWorkShiftTimeSlotItem {
        return this.workShiftTimeSlots.find(i => !i.endTime);
    }

    getFromPreviousDistanceKmStr(): string {
        return this.fromPreviousDistanceM
            ? (this.fromPreviousDistanceM / 1000).toFixed(1) + " " + Translations.MeasureUnitKm
            : "";
    }

    getFromPreviousDurationStr(): string {
        return this.fromPreviousDurationS ? AppUtils.getDurationStrByDurationMinShort(Math.round(this.fromPreviousDurationS / 60)) : "";
    }

    getName(): string {
        return Base.getStringWithSeparators([this.name, this.name2], ", ");
    }

    getAddress(): string {
        return Base.getStringWithSeparators([this.street, Base.getStringWithSeparators([this.postalCode, this.city], " "), this.countryCode], ", ");
    }
        
    getHash(): string {
        return this.id + "#" + this.rowId + "#" + EditItem.getHash(this.workShiftTimeSlots);
    }
        
    getTitle(): string {
        let title = this.name;
        if (this.name2.length > 0) {
            title += " (" + this.name2 + ")";
        }
        if (this.street.length > 0) {
            title += " - " + this.street;
        }
        return title;
    }

    getLabelTitle(): string {
        return this.street ?? this.name;
    }

    static sortRoutePointItems(items: IRoutePointItem[]) {
        if (!items || items.length < 2) return;
        items.sort((a: IRoutePointItem, b: IRoutePointItem) => {
            return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b))
                ? 0
                : Base.numberCompare(a.number, b.number);
        });
    }

    static getHash(items: IRoutePointItem[]): string {
        if (!items || items.length < 1) return "";
        return items.map(i => i.getHash()).join(",");
    }
}

export class SaveRoutePointItem {
    siteId: string;
    workOrderId: string;
    contactId: string;
    routePointTypeId: string;
    id: string;
    number: number;
    name: string;
    name2: string;
    street: string;
    postalCode: string;
    city: string;
    countryCode: string;
    longitude: number;
    latitude: number;
    locationName: string;
    mapLink: string;
    fromPreviousDistanceM: number;
    fromPreviousDurationS: number;
    rowId: string;

    constructor();
    constructor(obj: IRoutePointItem);
    constructor(obj?: any) {
        this.siteId = obj && obj.siteId || "";
        this.workOrderId = obj && obj.workOrderId || "";
        this.contactId = obj && obj.contactId || "";
        this.routePointTypeId = obj && obj.routePointTypeId || "";
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.name = obj && obj.name || "";
        this.name2 = obj && obj.name2 || "";
        this.street = obj && obj.street || "";
        this.postalCode = obj && obj.postalCode || "";
        this.city = obj && obj.city || "";
        this.countryCode = obj && obj.countryCode || "";
        this.longitude = obj && obj.longitude || null;
        this.latitude = obj && obj.latitude || null;
        this.locationName = obj && obj.locationName || "";
        this.mapLink = obj && obj.mapLink || "";
        this.fromPreviousDistanceM = obj && obj.fromPreviousDistanceM || null;
        this.fromPreviousDurationS = obj && obj.fromPreviousDurationS || null;
        this.rowId = obj && obj.rowId || "";
    }

    static createSaveRoutePointItemForSite(siteId: string, item: IRoutePointItem): SaveRoutePointItem {
        const result = new SaveRoutePointItem(item);
        result.siteId = siteId;
        result.workOrderId = "";
        return result;
    }
}
