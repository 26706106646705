import React, { ReactNode, useEffect, useMemo, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Theme } from "@emotion/react";
import { SxProps } from "@mui/system";
import { Base } from "../../framework/base";
import { ListSubheader } from "@mui/material";

export interface IMuiSelectOption {
    label: string;
    value: string | number;
    group?: string;
    disabled?: boolean;
}

export interface IMuiSelectProps {
    label?: string;
    options: IMuiSelectOption[];
    value?: string | number;
    defaultValue?: string | number;
    maxHeight?: number;
    onChange?: (val: string) => void;
    size?: "small" | "medium";
    noSelectionLabel?: string;
    required?: boolean;
    error?: boolean;
    fullWidth?: boolean;
    className?: string;
    sx?: SxProps<Theme> | undefined;
}

const emptyValue = "_empty";

export default function MuiSelect({
    onChange,
    value,
    defaultValue,
    label,
    options,
    maxHeight,
    size,
    noSelectionLabel,
    required,
    error,
    fullWidth = true,
    className = "",
    sx
}: IMuiSelectProps) {
    const getValue = () => (value ?? defaultValue ?? (noSelectionLabel ? emptyValue : "")).toString();
    const [selected, setSelected] = useState<string>(getValue());

    useEffect(() => {
        const newValue = getValue();
        if (newValue !== selected) {
            setSelected(getValue());
        }
    }, [value, defaultValue]);

    const handleChange = (event: SelectChangeEvent) => {
        if (value === undefined) {
            // Uncontrolled input
            setSelected(event.target.value);
        } else if (onChange && event.target.value !== value) {
            // Controlled input
            onChange(
                event.target.value === emptyValue ? "" : event.target.value
            );
        }
    };

    useEffect(() => {
        if (onChange) {
            onChange(selected === emptyValue ? "" : selected);
        }
    }, [selected]);

    const grouped = useMemo(() => Base.groupArray(options, "group"), [options]);

    return (
        <FormControl fullWidth={fullWidth} className={className} error={error}>
            {label ? (
                <InputLabel id="mui-select-label">{label}</InputLabel>
            ) : null}
            <Select
                labelId="mui-select-label"
                id="mui-select"
                value={options?.length > 0 ? selected : ""}
                label={label}
                onChange={handleChange}
                MenuProps={{ PaperProps: { style: { maxHeight: maxHeight } } }}
                size={size ?? "medium"}
                required={required}
                sx={sx ? sx : null}
            >
                {noSelectionLabel ? (
                    <MenuItem value={emptyValue}>{noSelectionLabel}</MenuItem>
                ) : null}

                {Object.entries(grouped).map(([group, groupOptions]) => {
                    const items: ReactNode[] = [];
                    if (group !== "undefined") {
                        items.push(<ListSubheader>{group}</ListSubheader>);
                    }
                    items.push(
                        ...groupOptions.map((o) => (
                            <MenuItem
                                key={o.value}
                                value={o.value}
                                disabled={o.disabled}
                            >
                                {o.label}
                            </MenuItem>
                        ))
                    );

                    return items;
                })}
            </Select>
        </FormControl>
    );
}
