import { SaveSuccess } from "./baseService";
import { getApiCall, postFormApiCall } from "./tokenService";
import { OwnerEdit } from "../models/owner/ownerEdit";

export const getOwnerEdit = (): Promise<OwnerEdit> => {
    return getApiCall<OwnerEdit>("api/owner/edit", OwnerEdit);
};

export const saveOwner = (data: FormData): Promise<SaveSuccess> => {
    return postFormApiCall<SaveSuccess>("api/owner/save", data, SaveSuccess);
};
