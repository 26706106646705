import * as React from "react";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import { Translations } from "../../models/translations";
import { RadioButton } from "../framework/radio";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { INumberTitle } from "../../models/common/numberTitle";

//WorkOrderStateMultiSelect
export interface IWorkOrderStateMultiSelectProp {
    classes?: string;
    isReadOnly?: boolean;
    states: number[];
    workOrderStates: INumberTitle[];
    onToggleState: (state: number) => void;
}

interface IWorkOrderStateMultiSelectState {
    uniqueStates: number[];
    uniqueStatesHash: string;
}

export class WorkOrderStateMultiSelect extends React.Component<IWorkOrderStateMultiSelectProp, IWorkOrderStateMultiSelectState> {
    getUniqueStates = (props: IWorkOrderStateMultiSelectProp): number[] => {
        const result = [...Array.from(new Set(props.states))];
        result.sort();
        return result;
    };

    getUniqueStatesHash = (uniqueProjectStates: number[]): string => {
        return uniqueProjectStates.join(",");
    };

    constructor(props: IWorkOrderStateMultiSelectProp) {
        super(props);
        const uniqueProjectStates = this.getUniqueStates(props);
        this.state = {
            uniqueStates: uniqueProjectStates,
            uniqueStatesHash: this.getUniqueStatesHash(uniqueProjectStates)
        };
    }

    componentDidUpdate(prevProps: IWorkOrderStateMultiSelectProp, prevState: IWorkOrderStateMultiSelectState): void {
        const props = this.props;
        const uniqueStates = this.getUniqueStates(props);
        const uniqueStatesHash = this.getUniqueStatesHash(uniqueStates);
        if (prevState.uniqueStatesHash === uniqueStatesHash) return;
        this.setState({
            uniqueStates: uniqueStates,
            uniqueStatesHash: uniqueStatesHash
        });
    }

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <div className={"btn-group btn-group-toggle workOrderStateBar" + (props.classes ? " " + props.classes : "")}>
                {props.workOrderStates.map((workOrderState) =>
                    <RadioButton
                        key={workOrderState.number}
                        classes={"btn-secondary editable state" + workOrderState.number.toString(10)}
                        tooltip={workOrderState.title}
                        iconClasses={"state" + workOrderState.number.toString(10)}
                        enabled={!props.isReadOnly}
                        checked={state.uniqueStates.indexOf(workOrderState.number) > -1}
                        onRadioClickBoolean={(checked: boolean) => {
                            if (props.isReadOnly) {
                                return;
                            }
                            props.onToggleState(workOrderState.number);
                        }}
                    />)
                }
            </div>);
    }
}

//WorkOrderDialogState
export interface IWorkOrderDialogStateProp {
    classes?: string;
    isReadOnly: boolean;
    isProject: boolean;
    state: number;
    projectStates: number[];
    workOrderStates: INumberTitle[];
    enabledStates?: number[];
    onStateChange: (state: number) => void;
}

interface IWorkOrderDialogStateState {
    uniqueProjectStates: number[];
    uniqueProjectStatesHash: string;
}

export class WorkOrderDialogState extends React.Component<IWorkOrderDialogStateProp, IWorkOrderDialogStateState> {
    getUniqueProjectStates = (props: IWorkOrderDialogStateProp): number[] => {
        const result = props.isProject ? [...Array.from(new Set(props.projectStates))] : [];
        result.sort();
        return result;
    };

    getUniqueProjectStatesHash = (uniqueProjectStates: number[]): string => {
        return uniqueProjectStates.join(",");
    };

    constructor(props: IWorkOrderDialogStateProp) {
        super(props);
        const uniqueProjectStates = this.getUniqueProjectStates(props);
        this.state = {
            uniqueProjectStates: uniqueProjectStates,
            uniqueProjectStatesHash: this.getUniqueProjectStatesHash(uniqueProjectStates)
        };
    }

    componentDidUpdate(prevProps: IWorkOrderDialogStateProp, prevState: IWorkOrderDialogStateState): void {
        const props = this.props;
        const uniqueProjectStates = this.getUniqueProjectStates(props);
        const uniqueProjectStatesHash = this.getUniqueProjectStatesHash(uniqueProjectStates);
        if (prevState.uniqueProjectStatesHash === uniqueProjectStatesHash) return;
        this.setState({
            uniqueProjectStates: uniqueProjectStates,
            uniqueProjectStatesHash: uniqueProjectStatesHash
        });
    }

    handleStateChange = (state: number, stateName: string) => {
        const props = this.props;
        if (props.state === state) return;
        if (!props.isReadOnly) {
            props.onStateChange(state);
        } else {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, String.format(Translations.DoYouWantToMoveWorkOrderStateToParameterState, stateName),
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    props.onStateChange(state);
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <div className={"btn-group btn-group-toggle workOrderStateBar" + (props.classes ? " " + props.classes : "")}>
                {!props.isProject
                    ? (
                        props.workOrderStates.map((workOrderState) =>
                            <RadioButton
                                key={workOrderState.number}
                                classes={"btn-secondary editable state" + workOrderState.number.toString(10)}
                                tooltip={workOrderState.title}
                                iconClasses={"state" + workOrderState.number.toString(10) + ((!this.props.enabledStates || this.props.enabledStates.indexOf(workOrderState.number) === -1) ? " disabled" : "")}
                                enabled={!this.props.enabledStates || this.props.enabledStates.indexOf(workOrderState.number) > -1}
                                checked={props.state === workOrderState.number}
                                onRadioClickBoolean={(checked: boolean) => { if (checked) { this.handleStateChange(workOrderState.number, workOrderState.title); } }}
                            />
                        )
                    )
                    : (
                        props.workOrderStates.map((workOrderState) =>
                            <RadioButton
                                key={workOrderState.number}
                                classes={"btn-secondary state" + workOrderState.number.toString(10) + (!(props.isReadOnly && state.uniqueProjectStates.length === 1) ? " disabled " : " editable")}
                                tooltip={workOrderState.title}
                                iconClasses={"state" + workOrderState.number.toString(10)}
                                enabled={props.isReadOnly && state.uniqueProjectStates.length === 1}
                                checked={state.uniqueProjectStates.indexOf(workOrderState.number) > -1}
                                onRadioClickBoolean={(checked: boolean) => { if (checked) { this.handleStateChange(workOrderState.number, workOrderState.title); } }}
                            />
                        )
                    )
                }
            </div>);
    }
}
