//Scrollspy
//***********************************************************************************************************************
import * as React from "react";

const spyInterval = 100;

export interface IScrollSpyItem {
    id: string;
    title: string;
    classes: string;
}

export class ScrollSpyItem implements IScrollSpyItem {
    id: string;
    title: string;
    classes: string;

    constructor();
    constructor(obj: IScrollSpyItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.title = obj && obj.title || "";
        this.classes = obj && obj.classes || "";
    }

    static createScrollSpyItem(id: string, title: string, classes: string): ScrollSpyItem {
        const result = new ScrollSpyItem();
        result.id = id;
        result.title = title;
        result.classes = classes;
        return result;
    }
}

interface ISpyItem {
    inView: boolean;
    item: IScrollSpyItem;
    element: HTMLElement;
}

interface IScrollSpyMenuItemProps {
    item: ISpyItem;
    onClick: (element: HTMLElement) => void;
}

class ScrollSpyMenuItem extends React.Component<IScrollSpyMenuItemProps, {}> {
    handleClick = () => {
        this.props.onClick(this.props.item.element);
    };

    render() {
        const props = this.props;
        const classes = "item " + (props.item.item.classes ? " " + props.item.item.classes : "") + (props.item.inView ? " active" : "");
        return (
            <div className={classes} onClick={this.handleClick} title={props.item.item.title}>{props.item.item.title}</div>
        );
    }
}

export interface IScrollSpyProps {
    classes?: string;
    dataContainer?: HTMLDivElement;
    items: IScrollSpyItem[];
    offset: number;
}

interface IScrollSpyState {
    items: ISpyItem[];
}

export class ScrollSpy extends React.Component<IScrollSpyProps, IScrollSpyState> {
    private timer: number;

    constructor(props: any) {
        super(props);
        this.state = { items: [] };
    }

    private spy() {
        const props = this.props;
        const spyItems: ISpyItem[] = [];
        for (let i = 0; i < props.items.length; i++) {
            const element = document.getElementById(props.items[i].id);
            if (!element) continue;
            spyItems.push({ inView: this.isInView(element), item: props.items[i], element: element } as ISpyItem);
        }
        const firstInViewItem = spyItems.find(i => i.inView);
        if (!firstInViewItem) {
            return; // dont update state
        }
        this.setState({ items: spyItems });
        // Below: Show only first visible item
    //    const update = spyItems.map(item => {
    //        return { ...item, inView: item === firstInViewItem } as ISpyItem;
    //    });
    //    this.setState({ items: update });
    }

    componentDidMount() {
        this.timer = window.setInterval(() => this.spy(), spyInterval);
    }

    componentWillUnmount() {
        window.clearInterval(this.timer);
    }

    private isInView = (element: HTMLElement) => {
        if (!element) {
            return false;
        }
        const offset = this.props.offset;
        const rect = element.getBoundingClientRect();
        let innerHeight = window.innerHeight;
        if (this.props.dataContainer) {
            const rect2 = this.props.dataContainer.getBoundingClientRect();
            innerHeight = rect2.height;
        }

        return rect.top >= 0 - offset && rect.bottom <= innerHeight + offset;
    };

    private scrollTo(element: HTMLElement) {
        element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    }

    render() {
        const props = this.props;
        const state = this.state;
        const classes = "scrollSpy" + (props.classes ? " " + props.classes : "");
        return (
            <div className={classes}>
                {state.items.map((item) =>
                    <ScrollSpyMenuItem
                        item={item}
                        key={item.element.id}
                        onClick={this.scrollTo}
                    />
                )}
            </div>
        );
    }
}
