import * as React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { Session } from "../../models/session/session";

interface IPrivateRouteProps extends RouteProps {
    // tslint:disable-next-line:no-any
    component: any;
    componentProps?: any;
}

interface IPrivateRouteState {
    loaded: boolean;
    accessToken: string;
}

export class PrivateRoute extends React.Component<IPrivateRouteProps, IPrivateRouteState> {
    constructor(props: IPrivateRouteProps) {
        super(props);
        this.state = { loaded: false, accessToken: null };
    }

    componentDidMount() {
        const obj = this;
        Session.getAccessToken().then(i => {
            obj.setState({
                loaded: true,
                accessToken: i
            });
        });
    }

    render() {
        const { component: Component, componentProps, ...rest } = this.props;
        const state = this.state;
        if (!state.loaded) return null;
        return (
            <Route {...rest} render={props => {
                return state.accessToken
                    ? <Component {...props} {...componentProps} />
                    : <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
            }}
            />
        );
    }
}
