import { IWorkOrderItem, WorkOrderItem } from "./workOrderItem";
import { IWorkOrderCalendarLineItem, WorkOrderCalendarLineItem } from "./workOrderCalendarLineItem";
import { ICalendarDayItem, CalendarDayItem } from "../calendarDay/calendarDayItem";
import { DayBookingItem, IDayBookingItem } from "../dayBooking/dayBookingItem";
import { Base } from "../../framework/base";
import { INumberTitle, NumberTitle } from "../common/numberTitle";
import { IEmployeeParameters } from "../employee/employeeIParameters";
import { TimeFormat } from "../common/enums";
import { IVehicleItem, VehicleItem } from "../vehicle/vehicleItem";
import { IEmployeeGroupItem, EmployeeGroupItem } from "../employeeGroup/employeeGroupItem";
import { IVehicleGroupItem, VehicleGroupItem } from "../vehicleGroup/vehicleGroupItem";

export interface IWorkOrderMain {
    workTimeFormat: TimeFormat;
    startDate: number;
    endDate: number;
    employeeParameters: IEmployeeParameters;
    workOrders: IWorkOrderItem[];
    workOrderTemplates: IWorkOrderItem[];
    favoriteWorkOrders: IWorkOrderItem[];
    showOnMapVehicles: IVehicleItem[];
    calendarLines: IWorkOrderCalendarLineItem[];
    calendarDays: ICalendarDayItem[];
    dayBookings: IDayBookingItem[];
    dayBookingTypes: INumberTitle[];
    workOrderStates: INumberTitle[];
    workOrderClasses: INumberTitle[];
    employeeGroups: IEmployeeGroupItem[];
    vehicleGroups: IVehicleGroupItem[];
}

export class WorkOrderMain implements IWorkOrderMain {
    workTimeFormat: TimeFormat;
    startDate: number;
    endDate: number;
    employeeParameters: IEmployeeParameters;
    workOrders: WorkOrderItem[];
    workOrderTemplates: WorkOrderItem[];
    favoriteWorkOrders: WorkOrderItem[];
    showOnMapVehicles: VehicleItem[];
    calendarLines: WorkOrderCalendarLineItem[];
    calendarDays: ICalendarDayItem[];
    dayBookings: DayBookingItem[];
    dayBookingTypes: NumberTitle[];
    workOrderStates: NumberTitle[];
    workOrderClasses: NumberTitle[];
    employeeGroups: EmployeeGroupItem[];
    vehicleGroups: VehicleGroupItem[];

    constructor();
    constructor(obj: IWorkOrderMain);
    constructor(obj?: any) {
        this.workTimeFormat = obj && obj.workTimeFormat || TimeFormat.DecimalFormat;
        this.startDate = obj && obj.startDate || 0;
        this.endDate = obj && obj.endDate || 0;
        this.employeeParameters = obj && obj.employeeParameters || {};
        this.workOrders = Base.getTypedArray<WorkOrderItem>(obj ? obj.workOrders : null, WorkOrderItem);
        this.workOrderTemplates = Base.getTypedArray<WorkOrderItem>(obj ? obj.workOrderTemplates : null, WorkOrderItem);
        this.favoriteWorkOrders = Base.getTypedArray<WorkOrderItem>(obj ? obj.favoriteWorkOrders : null, WorkOrderItem);
        this.showOnMapVehicles = Base.getTypedArray<VehicleItem>(obj ? obj.showOnMapVehicles : null, VehicleItem);
        this.calendarLines = Base.getTypedArray<WorkOrderCalendarLineItem>(obj ? obj.calendarLines : null, WorkOrderCalendarLineItem);
        this.calendarDays = Base.getTypedArray<CalendarDayItem>(obj ? obj.calendarDays : null, CalendarDayItem);
        this.dayBookings = Base.getTypedArray<DayBookingItem>(obj ? obj.dayBookings : null, DayBookingItem);
        this.dayBookingTypes = Base.getTypedArray<NumberTitle>(obj ? obj.dayBookingTypes : null, NumberTitle);
        this.workOrderStates = Base.getTypedArray<NumberTitle>(obj ? obj.workOrderStates : null, NumberTitle);
        this.workOrderClasses = Base.getTypedArray<NumberTitle>(obj ? obj.workOrderClasses : null, NumberTitle);
        this.employeeGroups = Base.getTypedArray<EmployeeGroupItem>(obj ? obj.employeeGroups : null, EmployeeGroupItem);
        this.vehicleGroups = Base.getTypedArray<VehicleGroupItem>(obj ? obj.vehicleGroups : null, VehicleGroupItem);
    }
}
