import { getApiCall, postFormApiCall, postApiCall } from "./tokenService";
import { SalaryPeriodTypeItems } from "../models/salaryPeriodType/salaryPeriodTypeItems";
import { SalaryPeriodTypeEdit } from "../models/salaryPeriodType/salaryPeriodTypeEdit";
import { ApiSuccess } from "./baseService";

export const getSalaryPeriodTypeItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean): Promise<SalaryPeriodTypeItems> => {
    return getApiCall<SalaryPeriodTypeItems>("api/salaryperiodtype/?page=" + (page || "") + "&pageSize=" + (pageSize || "") +
        "&sortColumn=" + (sortColumn ? encodeURIComponent(sortColumn) : "") + "&sortOrder=" + (sortOrderIsAsc ? "asc" : "desc") +
        "&filter=" + (filter ? encodeURIComponent(filter) : ""), SalaryPeriodTypeItems);
};

export const getSalaryPeriodTypeEdit = (id: string): Promise<SalaryPeriodTypeEdit> => {
    return getApiCall<SalaryPeriodTypeEdit>("api/salaryperiodtype/edit/?id=" + (id || ""), SalaryPeriodTypeEdit);
};

export const saveSalaryPeriodTypeEdit = (data: FormData): Promise<ApiSuccess> => {
    return postFormApiCall<ApiSuccess>("api/salaryperiodtype/save", data, ApiSuccess);
};

export const removeSalaryPeriodType = (id: string): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/salaryperiodtype/remove/?id=" + (id || ""), ApiSuccess);
};
