import React from "react";
import { AutocompleteOption, AutocompleteWrapper } from "../../framework/muiAutocomplete";
import { IComponentFilterDefination } from "../../../models/reporting/getReportingResponse";
import { SxProps } from "@mui/material";

interface Props {
    type: string;
    value: string[];
    options: IComponentFilterDefination;
    onChange: (val: string[]) => void;
    label: string;
    multiple: boolean;
    sx?: SxProps;
}

export const AutoComplete = (props: Props) => {
    const { multiple, label, value, options, sx } = props;
    const onChange = (val: AutocompleteOption[]) => {
        if(Array.isArray(val)) {
            props.onChange(val.map(v => v.id));
        } else {
            if(val) {
                props.onChange([val["id"]]);
            } else {
                props.onChange([]);
            }
        }
    };
    return <AutocompleteWrapper
        label={label}
        value={multiple ? value : value.toString()}
        options={options.componentItems}
        onChange={onChange}
        multiple={multiple}
        sx={sx}
           />;
};