import { IInvoiceEditItem, InvoiceEditItem } from "./invoiceEditItem";
import { IWorkOrderEditWorkOrderTypeItem, WorkOrderEditWorkOrderTypeItem } from "../work/workOrderEditWorkOrderTypeItem";
import { Base } from "../../framework/base";

export interface IInvoiceEdit {
    invoice: IInvoiceEditItem;
    workOrderTypes: IWorkOrderEditWorkOrderTypeItem[];
}

export class InvoiceEdit implements IInvoiceEdit {
    invoice: InvoiceEditItem;
    workOrderTypes: WorkOrderEditWorkOrderTypeItem[];

    constructor();
    constructor(obj: IInvoiceEdit);
    constructor(obj?: any) {
        this.invoice = null;
        if (obj && obj.invoice) {
            this.invoice = new InvoiceEditItem(obj.invoice);
        }
        this.workOrderTypes = Base.getTypedArray<WorkOrderEditWorkOrderTypeItem>(obj ? obj.workOrderTypes : null, WorkOrderEditWorkOrderTypeItem);
    }
}