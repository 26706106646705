export interface ICompetencyItem {
    id: string;
    number: number;
    competencyType: string;
    name: string;
    description: string;
    activeState: number;
}

export class CompetencyItem implements ICompetencyItem {
    id: string;
    number: number;
    competencyType: string;
    name: string;
    description: string;
    activeState: number;

    constructor();
    constructor(obj: ICompetencyItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.competencyType = obj && obj.competencyType || "";
        this.name = obj && obj.name || "";
        this.description = obj && obj.description || "";
        this.activeState = obj && obj.activeState || 0;
    }
}