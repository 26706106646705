import React from "react";

const getRandomKey = () => Math.ceil(Math.random() * 1000000);

const useReload = (): [number, () => void] => {
    const [key, setKey] = React.useState<number>(getRandomKey());
    const reload = React.useCallback(() => setKey(getRandomKey()), []);

    return [key, reload];
};

export { useReload };
