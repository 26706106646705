import { Base } from "../../framework/base";

export interface ICustomerSiteItem {
    id: string;
    customerId: string;
    customerName: string;
    siteNumber: string;
    name: string;
    name2: string;
    street: string;
    postalCode: string;
    city: string;
    hasRoutePoints: boolean;

    address(): string;
}

export class CustomerSiteItem implements ICustomerSiteItem {
    id: string;
    customerId: string;
    customerName: string;
    siteNumber: string;
    name: string;
    name2: string;
    street: string;
    postalCode: string;
    city: string;
    hasRoutePoints: boolean;

    constructor();
    constructor(obj: ICustomerSiteItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.customerId = obj && obj.customerId || "";
        this.customerName = obj && obj.customerName || "";
        this.siteNumber = obj && obj.siteNumber || "";
        this.name = obj && obj.name || "";
        this.name2 = obj && obj.name2 || "";
        this.street = obj && obj.street || "";
        this.postalCode = obj && obj.postalCode || "";
        this.city = obj && obj.city || "";
        this.hasRoutePoints = obj && obj.hasRoutePoints || false;
    }

    address(): string {
        return Base.getStringWithSeparators([this.street, this.postalCode, this.city], ", ");
    }
}