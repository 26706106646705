import { Base } from "../../framework/base";
import { Translations } from "../translations";
import { EnumHelper, SiteType } from "../common/enums";

export interface IWorkOrderEditSite {
    id: string;
    siteType: number;
    name: string;
    name2: string;
    street: string;
    postalCode: string;
    city: string;
    distance: number;
    mapLink: string;
    longitude: number;
    latitude: number;
    rideBookingRequired: boolean;
    familiarizationType: number;

    siteHasLocation(): boolean;
    getTitle(): string;
}

export class WorkOrderEditSite implements IWorkOrderEditSite {
    id: string;
    siteType: number;
    name: string;
    name2: string;
    street: string;
    postalCode: string;
    city: string;
    distance: number;
    mapLink: string;
    longitude: number;
    latitude: number;
    rideBookingRequired: boolean;
    familiarizationType: number;

    constructor();
    constructor(obj: IWorkOrderEditSite);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.siteType = obj && obj.siteType || 0;
        this.name = obj && obj.name || "";
        this.name2 = obj && obj.name2 || "";
        this.street = obj && obj.street || "";
        this.postalCode = obj && obj.postalCode || "";
        this.city = obj && obj.city || "";
        this.distance = obj && obj.distance || 0;
        this.mapLink = obj && obj.mapLink || "";
        this.longitude = obj && obj.longitude || 0;
        this.latitude = obj && obj.latitude || 0;
        this.rideBookingRequired = obj && obj.rideBookingRequired || false;
        this.familiarizationType = obj && obj.familiarizationType || 0;
    }
    
    siteHasLocation(): boolean {
        return !EnumHelper.isEqual(this.siteType, SiteType.NoLocation);
    }

    getTitle(): string {
        return this.name + (this.name2 ? ", " + this.name2 : "") + (this.familiarizationType > 0 ? Translations.SiteSamiliarizationType1Suffix : "");
    }

    static createWorkOrderEditSite(id: string, name: string, name2: string, street: string, postalCode: string, city: string, distance: number, mapLink: string, longitude: number, latitude: number, rideBookingRequired: boolean, familiarizationType: number): WorkOrderEditSite {
        const result = new WorkOrderEditSite();
        result.id = id;
        result.name = name;
        result.name2 = name2;
        result.street = street;
        result.postalCode = postalCode;
        result.city = city;
        result.distance = distance;
        result.mapLink = mapLink;
        result.longitude = longitude;
        result.latitude = latitude;
        result.rideBookingRequired = rideBookingRequired;
        result.familiarizationType = familiarizationType;
        return result;
    }

    static getWorkOrderEditSiteBySiteId(items: IWorkOrderEditSite[], id: string): IWorkOrderEditSite {
        if (!items || !id || items.length < 1) return null;
        return items.find(i => i.id === id);
    }

    static sortWorkOrderEditSites(items: IWorkOrderEditSite[], column: string, asc: boolean) {
        if (Base.isNullOrUndefined(items) || items.length < 2) return;
        let sortFunc: (a: IWorkOrderEditSite, b: IWorkOrderEditSite) => number;
        if (column === "rideBookingRequired") sortFunc = (a: IWorkOrderEditSite, b: IWorkOrderEditSite) => { return (!a || !b) ? 0 : ((asc ? 1 : -1) * Base.booleanCompare(a.rideBookingRequired, b.rideBookingRequired)); };
        else if (column === "familiarizationType") sortFunc = (a: IWorkOrderEditSite, b: IWorkOrderEditSite) => { return (!a || !b) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.familiarizationType, b.familiarizationType)); };
        else if (column === "mapLink") sortFunc = (a: IWorkOrderEditSite, b: IWorkOrderEditSite) => { return (!a || !b) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.mapLink, b.mapLink)); };
        else if (column === "longitude") sortFunc = (a: IWorkOrderEditSite, b: IWorkOrderEditSite) => { return (!a || !b) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.longitude, b.longitude)); };
        else if (column === "latitude") sortFunc = (a: IWorkOrderEditSite, b: IWorkOrderEditSite) => { return (!a || !b) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.latitude, b.latitude)); };
        else if (column === "distance") sortFunc = (a: IWorkOrderEditSite, b: IWorkOrderEditSite) => { return (!a || !b) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.distance, b.distance)); };
        else if (column === "name2") sortFunc = (a: IWorkOrderEditSite, b: IWorkOrderEditSite) => { return (!a || !b) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.name2, b.name2)); };
        else if (column === "street") sortFunc = (a: IWorkOrderEditSite, b: IWorkOrderEditSite) => { return (!a || !b) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.street, b.street)); };
        else if (column === "postalCode") sortFunc = (a: IWorkOrderEditSite, b: IWorkOrderEditSite) => { return (!a || !b) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.postalCode, b.postalCode)); };
        else if (column === "city") sortFunc = (a: IWorkOrderEditSite, b: IWorkOrderEditSite) => { return (!a || !b) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.city, b.city)); };
        else sortFunc = (a: IWorkOrderEditSite, b: IWorkOrderEditSite) => { return (!a || !b) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.name, b.name)); };
        items.sort(sortFunc);
    }
}