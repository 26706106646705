import React from "react";
import { ToggleButton, useTheme } from "@mui/material";
import { Translations } from "../../../models/translations";
import { TransportOrderListItemDto } from "../../../models/transport/transportOrder";

export interface MultiSelectButtonProps {
    onClick: (row: TransportOrderListItemDto) => void;
    onRemove: (row: TransportOrderListItemDto) => void;
    disabled?: boolean;
    row?: TransportOrderListItemDto;
    selected?: boolean;
}

export const MultiSelectButton = ({ onClick, onRemove, disabled, row, selected }: MultiSelectButtonProps) => {
    const handleClick = () => { selected ? onRemove(row) : onClick(row); };
    const theme = useTheme();

    return (
        <ToggleButton
            fullWidth
            key={row.id}
            value="check"
            selected={selected}
            size="small"
            color={selected ? "success" : "primary"}
            onClick={() => handleClick()}
            disabled={disabled}
            sx={{
                // Define borders and shadows for each variant to maintain a consistent cell size.
                // This avoids flickering when buttons are clicked and their state changes.
                "&.Mui-selected": {
                    backgroundColor: "success.light",
                    color: "success.contrastText",
                    boxShadow: "inset 0px 2px 4px rgba(0,0,0,0.2)",
                    border: "none",
                    "&:hover": {
                        backgroundColor: "success.main",
                    },
                },
                "&:not(.Mui-selected)": {
                    backgroundColor: "success.dark",
                    color: "success.contrastText",
                    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
                    border: "none",
                    "&:hover": {
                        backgroundColor: "success.main",
                    },
                },
                "&.Mui-disabled": {
                    backgroundColor: theme.palette.grey[300],
                    color: theme.palette.grey[400],
                    boxShadow: "inset 0px 2px 4px rgba(0,0,0,0)",
                    border: "none",
                },
            }}
        >
            {selected ? Translations.Selected : Translations.Select}
        </ToggleButton>
    );
};
