export interface IIdTitleDescription {
    id: string;
    title: string;
    description: string;
}

export class IdTitleDescription implements IIdTitleDescription {
    id: string;
    title: string;
    description: string;

    constructor();
    constructor(obj: IIdTitleDescription);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.title = obj && obj.title || "";
        this.description = obj && obj.description || "";
    }

    static createIdTitleDescription(id: string, title: string, description: string): IdTitleDescription {
        const result = new IdTitleDescription();
        result.id = id;
        result.title = title;
        result.description = description;
        return result;
    }
}