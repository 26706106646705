// LocationMap
// ***********************************************************************************************************************
import * as React from "react";
import { LatLngExpression, LatLngBounds, latLngBounds, divIcon, Point, DivIcon, Map, LeafletMouseEventHandlerFn } from "leaflet";
import { MapContainer, Marker, TileLayer, LayersControl, Tooltip, useMapEvent } from "react-leaflet";
import { Translations } from "../../models/translations";
import { ILocationPoint, LocationPoint } from "../../models/common/locationPoint";
import { EnumHelper, LocationPointType, WorkOrderState } from "../../models/common/enums";
import { Base } from "../../framework/base";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { createRef } from "react";

export interface ILocationMapProp {
    classes?: string;
    style?: React.CSSProperties;
    title?: string;
    titleId?: string;
    titleClass?: string;
    refreshId?: number;
    autoFitToBoundsId?: number;
    points: ILocationPoint[];
    points2?: ILocationPoint[];
    selectedIds: string[];
    setLocationMode?: boolean;
    onSetClickedLocation?: (latitude: number, longitude: number) => void;
    onSetSelectedIds?: (ids: string[]) => void;
    onSetSelectedIdsWithAllParameters?: (ids: string[], layerPoint: Point, containerPoint: Point, originalEvent: MouseEvent) => void;
}

interface ILocationMapState {
    locationPoints: LocationPoint[];
    locationPoints2: LocationPoint[];
    mapBounds: LatLngBounds;
    locationHash: string;
    prevLocationHash: string;
}

export class LocationMap extends React.Component<ILocationMapProp, ILocationMapState> {
    defaultPosition1: LatLngExpression;
    defaultPosition2: LatLngExpression;
    mapRef = createRef<Map>();

    getMapBounds = (locationPoints: ILocationPoint[]): LatLngBounds => {
        let result: LatLngBounds;
        if (locationPoints.length > 1) {
            result = latLngBounds([
                { lat: locationPoints[0].latitude, lng: locationPoints[0].longitude },
                { lat: locationPoints[0].latitude, lng: locationPoints[0].longitude }
            ]);
            for (let i = 1; i < locationPoints.length; i++) {
                result.extend({ lat: locationPoints[i].latitude, lng: locationPoints[i].longitude });
            }
        } else if (locationPoints.length > 0) {
            result = latLngBounds([
                { lat: locationPoints[0].latitude - 0.02, lng: locationPoints[0].longitude - 0.02 },
                { lat: locationPoints[0].latitude + 0.02, lng: locationPoints[0].longitude + 0.02 }
            ]);
        } else {
            result = latLngBounds([this.defaultPosition1, this.defaultPosition2]);
        }
        return result;
    };

    getLocationHash = (locationPoints: ILocationPoint[], selectedIds: string[], refreshId: number, autoFitToBoundsId: number): string => {
        return locationPoints.map(i => i.getLocationHash()).join("#") + "_R_" + (refreshId ?? 0).toString(10) + "_A_" + (autoFitToBoundsId ?? 0).toString(10);
    };

    constructor(props: ILocationMapProp) {
        super(props);
        this.defaultPosition1 = { lat: 61.171976, lng: 21.755611 };
        this.defaultPosition2 = { lat: 69.113796, lng: 29.781170 };
        const locationPoints = props.points.filter(i => i.hasLocation());
        const locationPoints2 = props.points2 ? props.points2.filter(i => i.hasLocation()) : [];
        const locationHash = this.getLocationHash(locationPoints.concat(locationPoints2), props.selectedIds, props.refreshId, props.autoFitToBoundsId);
        this.state = {
            prevLocationHash: locationHash,
            locationHash: locationHash,
            locationPoints: locationPoints,
            locationPoints2: locationPoints2,
            mapBounds: this.getMapBounds(locationPoints.concat(locationPoints2)),
        };
    }

    componentDidUpdate(prevProps: ILocationMapProp, prevState: ILocationMapState): void {
        const props = this.props;
        const prevLocationHash = this.state.locationHash;
        const locationPoints = props.points.filter(i => i.hasLocation());
        const locationPoints2 = props.points2 ? props.points2.filter(i => i.hasLocation()) : [];
        const autoFitToBoundsId = props.autoFitToBoundsId ?? 0;
        const locationHash = this.getLocationHash(locationPoints.concat(locationPoints2), props.selectedIds, props.refreshId, autoFitToBoundsId);
        if (prevLocationHash === locationHash) return;
        const prevAutoFitToBoundsId = prevProps.autoFitToBoundsId ?? 0;

        const bounds = Base.isEqualInteger(autoFitToBoundsId, prevAutoFitToBoundsId) ? null : this.getMapBounds(locationPoints.concat(locationPoints2));
        this.setState({
            prevLocationHash: prevLocationHash,
            locationHash: locationHash,
            locationPoints: locationPoints,
            locationPoints2: locationPoints2,
            mapBounds: bounds
        });

        if (bounds) {
            this.mapRef.current?.fitBounds(bounds, { padding: [50, 50] });
        }

    }

    selectPointsByLocationHash = (hash: string, layerPoint: Point, containerPoint: Point, originalEvent: MouseEvent) => {
        const selectedIds: string[] = [];
        const props = this.props;
        const state = this.state;
        for (let i = 0; i < state.locationPoints.length; i++) {
            if (state.locationPoints[i].getLocationHash() !== hash) continue;
            selectedIds.push(state.locationPoints[i].id);
        }
        if (props.onSetSelectedIdsWithAllParameters) {
            props.onSetSelectedIdsWithAllParameters(selectedIds, layerPoint, containerPoint, originalEvent);
        } else if (props.onSetSelectedIds) {
            props.onSetSelectedIds(selectedIds);
        }
    };

    getMarkerIcon = (classes: string): DivIcon => {
        return divIcon({
            className: "mapMarker " + classes,
            iconSize: new Point(36, 36),
            iconAnchor: new Point(18, 36),
            html: "<div></div>"
        });
    };

    getSmallMarkerIcon = (classes: string): DivIcon => {
        return divIcon({
            className: "mapMarker " + classes,
            iconSize: new Point(20, 20),
            iconAnchor: new Point(10, 20),
            html: "<div></div>"
        });
    };

    getUnfocusedMarkerIcon = (classes: string): DivIcon => {
        return divIcon({
            className: "mapMarker " + classes,
            iconSize: new Point(26, 26),
            iconAnchor: new Point(13, 26),
            html: "<div></div>"
        });
    };

    onMapClick: LeafletMouseEventHandlerFn = (e) => {
        if (e && this.props.setLocationMode && this.props.onSetClickedLocation) {
            if (e.latlng) {
                this.props.onSetClickedLocation(e.latlng.lat, e.latlng.lng);
            }
        }
    };

    render() {
        const obj = this;
        const state = this.state;
        const props = this.props;
        const blueMarkerIcon = this.getUnfocusedMarkerIcon("blue");
        const preliminaryIcon = this.getUnfocusedMarkerIcon("preliminary");
        const plannedIcon = this.getUnfocusedMarkerIcon("planned");
        const inProgressIcon = this.getUnfocusedMarkerIcon("inProgress");
        const doneIcon = this.getUnfocusedMarkerIcon("done");
        const checkedIcon = this.getUnfocusedMarkerIcon("checked");
        const transferredIcon = this.getUnfocusedMarkerIcon("transferred");
        const workOrderLocationIcon = this.getUnfocusedMarkerIcon("workOrderLocation");
        const workOrderLocationFocusedIcon = this.getMarkerIcon("workOrderLocationFocused");
        const redMarkerIcon = this.getMarkerIcon("red");
        const vehicleMarkerIcon = this.getSmallMarkerIcon("vehicle");
        return (
            <div className={"locationMap" + (props.classes ? " " + props.classes : "") + (props.setLocationMode ? " setLocationMode" : "")} style={props.style}>
                {!!props.title &&
                    <div className="commandRow">
                        <label id={props.titleId} className={"control-label listTitle" + (props.titleClass ? " " + props.titleClass : "")}>{props.title}</label>
                    </div>
                }
                <MapContainer
                    ref={this.mapRef}
                    bounds={state.mapBounds}
                    boundsOptions={{ padding: [50, 50] }}
                    zoom={11}
                    scrollWheelZoom={true}
                >
                    <MapClickHandler onClick={this.onMapClick}/>
                    <LayersControl position="topright">
                        <LayersControl.BaseLayer checked name="OpenStreetMap">
                            <TileLayer
                                attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name={"Google Maps - " + Translations.Satellite}>
                            {/* @ts-expect-error: library types not correct */}
                            <ReactLeafletGoogleLayer apiKey={appConfig.googleApiKey} type="satellite"/>
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name={"Google Maps - " + Translations.Roadmap}>
                            {/* @ts-expect-error: library types not correct */}
                            <ReactLeafletGoogleLayer apiKey={appConfig.googleApiKey} type="roadmap"/>
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name={"Google Maps - " + Translations.Terrain}>
                            {/* @ts-expect-error: library types not correct */}
                            <ReactLeafletGoogleLayer apiKey={appConfig.googleApiKey} type="terrain"/>
                        </LayersControl.BaseLayer>
                    </LayersControl>
                    {state.locationPoints2.map((locationPoint2) =>
                        <Marker
                            key={locationPoint2.id}
                            position={{ lat: locationPoint2.latitude, lng: locationPoint2.longitude }}
                            icon={vehicleMarkerIcon}
                        >
                            <Tooltip>{locationPoint2.getMapTooltip()}</Tooltip>
                        </Marker>
                    )}
                    {state.locationPoints.map((locationPoint) => {
                        const selected = props.selectedIds.indexOf(locationPoint.id) > -1;
                        if (locationPoint.locationPointType === LocationPointType.WorkOrder) {
                            const workOrderLocation = locationPoint.locationPointCategory > 0.5;
                            return (
                                <Marker
                                    key={locationPoint.id}
                                    position={{ lat: locationPoint.latitude, lng: locationPoint.longitude }}
                                    zIndexOffset={selected ? 500 : 0}
                                    icon={selected
                                        ? (workOrderLocation ? workOrderLocationFocusedIcon : redMarkerIcon)
                                        : (EnumHelper.isEqual(locationPoint.state, WorkOrderState.Planned)
                                            ? plannedIcon
                                            : (EnumHelper.isEqual(locationPoint.state, WorkOrderState.InProgress)
                                                ? (workOrderLocation ? workOrderLocationIcon : inProgressIcon)
                                                : (EnumHelper.isEqual(locationPoint.state, WorkOrderState.Done)
                                                    ? doneIcon
                                                    : (EnumHelper.isEqual(locationPoint.state, WorkOrderState.Checked)
                                                        ? checkedIcon
                                                        : (EnumHelper.isEqual(locationPoint.state, WorkOrderState.Transferred)
                                                            ? transferredIcon
                                                            : preliminaryIcon))))) //Preliminary
                                    }
                                    eventHandlers={{
                                        click: (ev) => {
                                            obj.selectPointsByLocationHash(locationPoint.getLocationHash(), ev.layerPoint, ev.containerPoint, ev.originalEvent);
                                        },
                                    }}
                                >
                                    {workOrderLocation && selected &&
                                        <Tooltip permanent={true}>{locationPoint.getMapTooltip()}</Tooltip>
                                    }
                                    {workOrderLocation && !selected &&
                                        <Tooltip className="slim" permanent={true}>{locationPoint.getMapTooltip()}</Tooltip>
                                    }
                                </Marker>
                            );
                        } else {
                            return (
                                <Marker
                                    key={locationPoint.id}
                                    position={{ lat: locationPoint.latitude, lng: locationPoint.longitude }}
                                    zIndexOffset={selected ? 500 : 0}
                                    icon={selected ? redMarkerIcon : blueMarkerIcon}
                                    eventHandlers={{
                                        click: (ev) => {
                                            obj.selectPointsByLocationHash(locationPoint.getLocationHash(), ev.layerPoint, ev.containerPoint, ev.originalEvent);
                                        },
                                    }}
                                >
                                    {!!locationPoint.getMapTooltip() &&
                                        <Tooltip direction="bottom">{locationPoint.getMapTooltip()}</Tooltip>
                                    }
                                </Marker>
                            );
                        }
                    }
                    )}

                </MapContainer>
            </div>
        );
    }
}

const MapClickHandler = (props: {onClick: LeafletMouseEventHandlerFn}) => {
    useMapEvent("click", props.onClick);
    return null;
};
