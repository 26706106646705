import { OptionDataType } from "./enums";

export interface ITypedOption {
    code: string;
    dataType: OptionDataType;
    value: string;
}

export class TypedOption implements ITypedOption {
    code: string;
    dataType: OptionDataType;
    value: string;

    constructor();
    constructor(obj: ITypedOption);
    constructor(obj?: any) {
        this.code = obj && obj.code || "";
        this.dataType = obj && obj.dataType || OptionDataType.String;
        this.value = obj && obj.value || "";
    }

    static getHash(options: ITypedOption[]): string {
        if (!Array.isArray(options)) {
            console.error('Expected options to be an array, received:', options);
            return "";
        }
        if (!options || options.length < 1) return "";
        return options.map(i => i.code + "#" + i.value).join("$");
    }

    static getStrValue(options: ITypedOption[], code: string, defaultValue: string[] = []): string {
        if (!Array.isArray(options)) {
            console.error('Expected options to be an array, received:', options);
            return "";
        }
        if (!options || options.length < 1) return "";
        const option = options.find(i => i.code === code.toLowerCase());
        if (!option) return "";
        return option.value;
    }

    static getBooleanValue(options: ITypedOption[], code: string, defaultValue: boolean = false): boolean {
        if (!Array.isArray(options)) {
            console.error('Expected options to be an array, received:', options);
            return defaultValue;
        }
        if (!options || options.length < 1) return defaultValue;
        const option = options.find(i => i.code.toLowerCase() === code.toLowerCase());
        if (!option) return defaultValue;
        return option.value === "1";
    }

    static getIntValue(options: ITypedOption[], code: string, defaultValue: number = 0): number {
        if (!Array.isArray(options)) {
            console.error('Expected options to be an array, received:', options);
            return defaultValue;
        }
        if (!options || options.length < 1) return defaultValue;
        const option = options.find(i => i.code.toLowerCase() === code.toLowerCase());
        if (!option) return defaultValue;
        return parseInt(option.value);
    }
}

export class TypedOptionSave {
    code: string;
    value: string;

    constructor();
    constructor(obj: ITypedOption);
    constructor(obj?: any) {
        this.code = obj && obj.code || "";
        this.value = obj && obj.value || "";
    }
}
