// ObjectEventLogList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "../../framework/base";
import { IObjectEventLogItem } from "../../models/eventLog/objectEventLogItem";

// ObjectEventLogListLine
export interface IObjectEventLogListLineProp {
    item: IObjectEventLogItem;
    selectedId: string;
    onClick: (id: string) => void;
}

export class ObjectEventLogListLine extends React.Component<IObjectEventLogListLineProp, {}> {
    render() {
        const item = this.props.item;
        return (
            <div className={"row line" + (item.id === this.props.selectedId ? " selected" : "")} onClick={() => { this.props.onClick(item.id); }}>
                <div className="col-3 col-2_5">{Base.timeToDateTimeStr(item.time)}</div>
                <div className="col-6 col-6_5">{item.message}</div>
                <div className="col-3 ra">{item.user}</div>
            </div>
        );
    }
}

// ObjectEventLogListLineReadOnlyView
export interface IObjectEventLogListLineReadOnlyViewProp {
    item: IObjectEventLogItem;
    selectedId: string;
}

export class ObjectEventLogListLineReadOnlyView extends React.Component<IObjectEventLogListLineReadOnlyViewProp, {}> {
    render() {
        const item = this.props.item;
        return (
            <div className={"row line" + (item.id === this.props.selectedId ? " selected" : "")}>
                <div className="col-3 col-2_5">{Base.timeToDateTimeStr(item.time)}</div>
                <div className="col-6 col-6_5">{item.message}</div>
                <div className="col-3 ra">{item.user}</div>
            </div>
        );
    }
}

// ObjectEventLogList
export interface IObjectEventLogListProp {
    classes?: string;
    title?: string;
    titleId?: string;
    titleClass?: string;
    items: IObjectEventLogItem[];
}

interface IObjectEventLogListState {
    selectedId: string;
}

export class ObjectEventLogList extends React.Component<IObjectEventLogListProp, IObjectEventLogListState> {
    constructor(props) {
        super(props);
        this.state = { selectedId: null };
    }

    handleEventLogClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    render() {
        const props = this.props;
        const items = props.items;
        return (
            <div className={props.classes ? props.classes : ""}>
                <div className="commandRow">
                    {!!props.title &&
                        <label id={props.titleId} className={"control-label listTitle" + (props.titleClass ? " " + props.titleClass : "")}>{props.title}</label>
                    }
                </div>
                <div className="listContainer eventLogsContainer">
                    {items.length > 0 &&
                        <div className="list eventLogs">
                            {items.map((item) =>
                                <ObjectEventLogListLine
                                    key={item.id}
                                    item={item}
                                    selectedId={this.state.selectedId}
                                    onClick={this.handleEventLogClick}
                                />
                            )}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

// ObjectEventLogListReadOnlyView
export interface IObjectEventLogListReadOnlyViewProp {
    items: IObjectEventLogItem[];
}

interface IObjectEventLogListReadOnlyViewState {
    selectedId: string;
}

export class ObjectEventLogListReadOnlyView extends React.Component<IObjectEventLogListReadOnlyViewProp, IObjectEventLogListReadOnlyViewState> {
    constructor(props) {
        super(props);
        this.state = { selectedId: null };
    }

    handleEventLogClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    render() {
        const items = this.props.items;
        return (
            <div>
                <div className="listContainer eventLogsContainer">
                    {items.length > 0 &&
                        <div className="list eventLogs">
                            {items.map((item) =>
                                <ObjectEventLogListLineReadOnlyView
                                    key={item.id}
                                    item={item}
                                    selectedId={this.state.selectedId}
                                />
                            )}
                        </div>
                    }
                </div>
            </div>
        );
    }
}