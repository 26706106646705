import { Base } from "../../framework/base";
import { CustomerTypeItem, ICustomerTypeItem } from "./customerTypeItem";

export interface ICustomerTypeItems {
    items: ICustomerTypeItem[];
    hasMore: boolean;
    page: number;
}

export class CustomerTypeItems implements ICustomerTypeItems {
    items: CustomerTypeItem[];
    hasMore: boolean;
    page: number;

    constructor();
    constructor(obj: ICustomerTypeItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = Base.getTypedArray<CustomerTypeItem>(obj ? obj.items : null, CustomerTypeItem);
    }
}