import { Base } from "../../framework/base";
import { IToolItem, ToolItem } from "./toolItem";

export interface IToolItems {
    items: IToolItem[];
    hasMore: boolean;
    page: number;
}

export class ToolItems implements IToolItems {
    items: ToolItem[];
    hasMore: boolean;
    page: number;

    constructor();
    constructor(obj: IToolItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = Base.getTypedArray<ToolItem>(obj ? obj.items : null, ToolItem);
    }
}