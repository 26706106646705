// WorkMainWorkOrderGroup
// ***********************************************************************************************************************
import * as React from "react";
import { IWorkOrderItem } from "../../models/work/workOrderItem";
import { WorkListLineShort } from "./workListLineShort";

// WorkMainWorkOrderGroup
export interface IWorkMainWorkOrderGroupProp {
    top: number;
    left: number;
    items: IWorkOrderItem[];
    onItemClick: (id: string) => void;
    onEditWorkOrderItem: (id: string) => void;
    onCancel: () => void;
}

export interface IWorkMainWorkOrderGroupState {
    selectedId: string;
}

export class WorkMainWorkOrderGroup extends React.Component<IWorkMainWorkOrderGroupProp, IWorkMainWorkOrderGroupState> {
    constructor(props) {
        super(props);
        this.state = {
            selectedId: "",
        };
    }

    handleLineClick = (id: string) => {
        if (!id) return;
        this.setState({ selectedId: id });
        this.props.onItemClick(id);
    };

    handleLineDoubleClick = (id: string) => {
        if (!id) return;
        this.props.onEditWorkOrderItem(id);
        this.props.onCancel();
    };

    handleCancelClick = (e) => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const style = {
            top: props.top + "px",
            left: props.left + "px",
        };
        return (
            <div>
                <div className="workOrderGroupBackground" onClick={this.handleCancelClick} />
                <div className="workOrderGroupMenu" style={style}>
                    <div className="content">
                        {props.items.map((item) =>
                            // Correct! Key should be specified inside the array.
                            <WorkListLineShort
                                key={item.id}
                                item={item}
                                employeeId={item.employees.length > 0 ? item.employees[0].id : ""}
                                vehicleId={item.vehicles.length > 0 ? item.vehicles[0].id : ""}
                                isSelected={state.selectedId === item.id}
                                onClick={this.handleLineClick}
                                onDoubleClick={this.handleLineDoubleClick}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
