import * as React from "react";
import { Switch, RouteComponentProps } from "react-router-dom";
import { PrivateRoute } from "../app/privateRoute";
import { WorkTimeDetailsPageMain } from "./workTimeBetaDetailsPageMain";
import { WorkTimeMain } from "./workTimeMain";

export const WorkTimeBetaBase = (props: RouteComponentProps) => {
    return (
        <Switch>
            <PrivateRoute
                path={`${props.match.path}/details/:employeeId?/:date?`}
                component={WorkTimeDetailsPageMain}
                componentProps={{ backPath: props.match.path }}
            />
            <PrivateRoute
                component={WorkTimeMain}
                componentProps={{ path: props.match.path }}
            />
        </Switch>
    );
};
