import * as CustomStore from "../../framework/customStore";
import * as StoreActions from "../store/storeActions";
import * as BaseService from "../../services/baseService";
import * as ReportService from "../../services/reportService";
import { Base } from "../../framework/base";
import { IReportListItem } from "./reportListItem";

export interface IGetReportListItemsResult {
    reportItems: IReportListItem[];
    selectedReportId: string;
    filter: string;
}

export class ReportOperations {
    // #region List
    static getReportListItems = (reportItems: IReportListItem[], selectedReportId: string, reportType: string, filter: string, onBefore: () => void, onFinally: () => void): Promise<IGetReportListItemsResult> => {
        if (onBefore) {
            onBefore();
        }
        CustomStore.customStore.dispatch(StoreActions.fetchStart());
        return ReportService.getReportListItems(reportType, filter)
            .then(reportListItems => {
                const listItems = Base.getListItems<IReportListItem>(reportItems, selectedReportId, [], reportListItems.items, true, false);
                return {
                    filter: filter,
                    reportItems: listItems.items,
                    selectedReportId: listItems.selectedId
                };
            })
            .catch(error => {
                CustomStore.customStore.dispatch(StoreActions.showErrorMessage(BaseService.getErrorMessageFromError(error)));
                return Base.getPromiseResult<IGetReportListItemsResult>(null);
            })
            .finally(() => {
                if (onFinally) {
                    onFinally();
                }
                CustomStore.customStore.dispatch(StoreActions.fetchEnd());
            });
    };
    // #endregion Remove
}