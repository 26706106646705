// OwnerSettingsOwnerEdit - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as baseService from "../../services/baseService";
import * as storeActions from "../../models/store/storeActions";
import * as ownerService from "../../services/ownerService";
import { Base } from "../../framework/base";
import { SaveData } from "../../framework/saveData";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { ImageSelector } from "../framework/imageSelector";
import { IOwnerEdit } from "../../models/owner/ownerEdit";
import { Button } from "../framework/button";
import { refreshAuthentication } from "../../services/tokenService";

// OwnerSettingsOwnerEdit
// ***********************************************************************************************************************
export interface IOwnerSettingsOwnerEditProp {
    classes?: string;
    setNavigationCallback: (name: string, callback: () => Promise<boolean>) => void;
}

export interface IOwnerSettingsOwnerEditState {
    ownerEdit: IOwnerEdit;
    rowId: string;
    logo: string;
    logoFile: File;
    removeLogo: boolean;
    backgroundImage: string;
    backgroundImageFile: File;
    removeBackgroundImage: boolean;
    showDefaultLogo: boolean;
    name: string;
    name2: string;
    mapOnIdStr: string;
    apiKey: string;
}

export class OwnerSettingsOwnerEdit extends React.Component<IOwnerSettingsOwnerEditProp, IOwnerSettingsOwnerEditState> {
    private static orgStateHash: string = "";

    constructor(props: IOwnerSettingsOwnerEditProp) {
        super(props);
        this.state = {
            ownerEdit: null,
            rowId: "",
            logo: "",
            logoFile: null,
            removeLogo: false,
            backgroundImage: "",
            backgroundImageFile: null,
            removeBackgroundImage: false,
            showDefaultLogo: false,
            name: "",
            name2: "",
            mapOnIdStr: "",
            apiKey: ""
        };
    }

    setNavigationCallback = (callback: () => Promise<boolean>) => {
        this.props.setNavigationCallback("OwnerEdit", callback);
    };

    getOwnerEdit = () => {
        const obj = this;
        ownerService.getOwnerEdit().then(editItem => {
            const owner = editItem.owner;
            obj.setState({
                ownerEdit: editItem,
                rowId: owner.rowId,
                logo: owner.logo,
                logoFile: null,
                backgroundImage: owner.backgroundImage,
                backgroundImageFile: null,
                showDefaultLogo: owner.showDefaultLogo,
                name: owner.name,
                name2: owner.name2,
                mapOnIdStr: owner.mapOnId ? owner.mapOnId.toString(10) : "",
                apiKey: owner.apiKey
            }, () => {
                OwnerSettingsOwnerEdit.orgStateHash = OwnerSettingsOwnerEdit.getSaveDataFromState(obj.props, obj.state).hash;
                this.setNavigationCallback(this.cancelView);
            });

        });
    };

    componentDidMount(): void {
        this.getOwnerEdit();
    }

    componentWillUnmount(): void {
        this.setNavigationCallback(null);
    }

    // #region General
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "Nm") {
            this.setState({ name: value });
        } else if (name === "Nm2") {
            this.setState({ name2: value });
        } else if (name === "mapOnId") {
            this.setState({ mapOnIdStr: value });
        } else if (name === "apiKey") {
            this.setState({ apiKey: value });
        }
    };

    handleBlur = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "mapOnId") {
            this.setState({ mapOnIdStr: value.toInteger().toString(10) });
        }
    };

    handleSetLogo = (file: File) => {
        if (Base.isNullOrUndefined(file)) return;
        const obj = this;
        Base.blobToBase64(file).then(base64Image => {
            obj.setState({
                logoFile: file,
                logo: base64Image,
                removeLogo: false
            });
        });
    };

    handleRemoveLogo = () => {
        this.setState({
            logoFile: null,
            logo: "",
            removeLogo: true
        });
    };

    handleSetBackgroundImage = (file: File) => {
        if (Base.isNullOrUndefined(file)) return;
        const obj = this;
        Base.blobToBase64(file).then(base64Image => {
            obj.setState({
                backgroundImageFile: file,
                backgroundImage: base64Image,
                removeBackgroundImage: false
            });
        });
    };

    handleRemoveBackgroundImage = () => {
        this.setState({
            backgroundImageFile: null,
            backgroundImage: "",
            removeBackgroundImage: true
        });
    };
    // #endregion General

    private static validate = (state: IOwnerSettingsOwnerEditState): boolean => {
        if (!state.name) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.NameMustBeDefined));
            return false;
        }
        return true;
    };

    private static getSaveDataFromState = (props: IOwnerSettingsOwnerEditProp, state: IOwnerSettingsOwnerEditState): SaveData => {
        const data = new SaveData();
        const owner = state.ownerEdit.owner;
        // Common
        data.append("id", owner.id);
        data.append("rowId", state.rowId);
        // General
        if (!Base.isNullOrUndefined(state.logoFile)) {
            data.append("logo[]", state.logoFile, state.logoFile.name);
        }
        if (!Base.isNullOrUndefined(state.backgroundImageFile)) {
            data.append("backgroundImage[]", state.backgroundImageFile, state.backgroundImageFile.name);
        }
        data.append("removeLogo", state.removeLogo ? "1" : "0");
        data.append("removeBackgroundImage", state.removeBackgroundImage ? "1" : "0");
        data.append("showDefaultLogo", state.showDefaultLogo ? "1" : "0");
        data.append("name", state.name);
        data.append("name2", state.name2);
        data.append("mapOnId", state.mapOnIdStr);
        data.append("apiKey", state.apiKey);
        return data;
    };

    saveEditItem = () => {
        const obj = this;
        if (!OwnerSettingsOwnerEdit.validate(this.state)) return;
        const saveData = OwnerSettingsOwnerEdit.getSaveDataFromState(this.props, this.state);
        if (Base.isNullOrUndefined(saveData)) return;
        // Call server
        store.customStore.dispatch(storeActions.fetchStart());
        ownerService.saveOwner(saveData.formData)
            .then(success => {
                obj.setNavigationCallback(null);
                obj.setState({ rowId: success.rowId });
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                refreshAuthentication(true);
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleOkClick = () => {
        this.saveEditItem();
    };

    toggleShowDefaultLogo = () => {
        this.setState({ showDefaultLogo: !this.state.showDefaultLogo });
    };

    cancelView = (): Promise<boolean> => {
        const obj = this;
        const saveData = OwnerSettingsOwnerEdit.getSaveDataFromState(this.props, this.state);
        return new Promise<boolean>((resolve) => {
            if (!Base.isNullOrUndefined(saveData) && saveData.hash !== OwnerSettingsOwnerEdit.orgStateHash) {
                store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                    () => {
                        store.customStore.dispatch(storeActions.clearConfirmation());
                        obj.saveEditItem();
                        resolve(true);
                    },
                    () => {
                        store.customStore.dispatch(storeActions.clearConfirmation());
                        //obj.props.onCancel();
                        resolve(true);
                    },
                    () => {
                        store.customStore.dispatch(storeActions.clearConfirmation());
                        resolve(false);
                    }));
            } else {
                //obj.props.onCancel();
                resolve(true);
            }
        });
    };

    render() {
        const state = this.state;
        if (!state.ownerEdit) {
            return null;
        }
        return (
            <div className="ownerEdit">
                <div className="row">
                    <div className="col-12">
                        <Button
                            classes={"btn-primary right"}
                            title={Translations.Save}
                            enabled={true}
                            onClick={this.handleOkClick}
                        />
                    </div>
                </div>
                <div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group required">
                                <label className="control-label smallFont">{Translations.Name}</label>
                                <input type="text" className="form-control" name="Nm" title={Translations.Name} value={state.name} onChange={this.handleChange} maxLength={50} autoFocus={true} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.Specifier}</label>
                                <input type="text" className="form-control" name="Nm2" title={Translations.Specifier} value={state.name2} onChange={this.handleChange} maxLength={50} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.MapOnId}</label>
                                <input type="text" className="form-control" name="mapOnId" title={Translations.MapOnId} value={state.mapOnIdStr} onChange={this.handleChange} onBlur={this.handleBlur} maxLength={9} />
                            </div>
                        </div>
                    </div>
                    <div className ="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className="control-label smallFon">{Translations.ApiKeyLabel}</label>
                                <input type ="text" className="form-control" name="apiKey" title={Translations.ApiKeyLabel} value={state.apiKey} onChange={this.handleChange} onBlur={this.handleBlur} maxLength={40} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group" style={{ display: "flex" }}>
                                <input type="checkbox" name="showDefaultLogo" checked={state.showDefaultLogo} onClick={this.toggleShowDefaultLogo}/>
                                <label className="control-label smallFont" onClick={this.toggleShowDefaultLogo} style={{ marginLeft: "4px", marginTop: "2px" }}>{Translations.ShowDefaultLogo}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <ImageSelector
                            fileInputName="logo"
                            image={state.logo}
                            aspectRatio={null}
                            label={Translations.OwnerLogo}
                            onChange={this.handleSetLogo}
                            onClear={this.handleRemoveLogo}
                            onError={(message) => { store.customStore.dispatch(storeActions.showErrorMessage(message)); }}
                        />
                    </div>
                    <div className="col-4">
                        <ImageSelector
                            fileInputName="backgroundImage"
                            image={state.backgroundImage}
                            aspectRatio={null}
                            label={Translations.OwnerMobileBackgroundImage}
                            onChange={this.handleSetBackgroundImage}
                            onClear={this.handleRemoveBackgroundImage}
                            onError={(message) => { store.customStore.dispatch(storeActions.showErrorMessage(message)); }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
