import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { SxProps } from "@mui/system";

interface NumberInputProps {
    defaultValue?: string | number;
    value?: string | number;
    label?: string;
    id?: string;
    className?: string;
    onChange?: (val: number | string) => void;
    endAdornment?: string;
    allowEmpty?: boolean;
    allowNegative?: boolean;
    allowDecimals?: boolean;
    sx?: SxProps;
    disabled?: boolean;
}

export const NumberInput = ({
    defaultValue,
    value,
    label,
    id,
    className = "",
    onChange,
    endAdornment,
    allowEmpty = false,
    allowNegative = false,
    allowDecimals = false,
    sx,
    disabled = false,
}: NumberInputProps) => {
    const handleChange = (val: string | number) => {
        if (onChange) {
            // Allow empty string
            onChange(val ? +val : val);
        }
    };

    return (
        <TextField
            id={id}
            type="number"
            value={value}
            defaultValue={defaultValue}
            className={className}
            label={label}
            onChange={(event) => handleChange(event.target.value)}
            onBlur={(event) => {
                let value = event.target.value;

                if (!allowEmpty) {
                    // If empty, change to 0 on blur
                    handleChange(value || 0);
                }

                if (!allowNegative && !!value) {
                    value = Math.max(+value, 0).toString();
                }

                if (!allowDecimals && !!value) {
                    value = parseInt(value).toString();
                }

                handleChange(value);
            }}
            InputProps={
                endAdornment
                    ? {
                          endAdornment: (
                              <InputAdornment position="end">
                                  {endAdornment}
                              </InputAdornment>
                          ),
                      }
                    : null
            }
            sx={sx}
            disabled={disabled}
        />
    );
};
