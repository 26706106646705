// ProgressBar
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "../../framework/base";

/* eslint-disable no-unused-vars */
export const enum ProgressBarStyle {
    None = "",
    Success = " bg-success",
    Info = " bg-info",
    Warning = " bg-warning",
    Danger = " bg-danger"
}
/* eslint-enable no-unused-vars */

export interface IProgressBarProp {
    animated?: boolean;
    classes?: string;
    minValue?: number;
    maxValue?: number;
    striped?: boolean;
    style?: ProgressBarStyle;
    tooltip?: string;
    value: number;
}

export class ProgressBar extends React.Component<IProgressBarProp, {}> {
    render() {
        const props = this.props;
        const minValue = !Base.isNullOrUndefined(props.minValue) ? props.minValue : 0;
        const maxValue = !Base.isNullOrUndefined(props.maxValue) ? props.maxValue : 100;
        const value = Math.max(Math.min(!Base.isNullOrUndefined(props.value) ? props.value : 0, maxValue), minValue);
        const style = !Base.isNullOrUndefined(props.style) ? props.style : ProgressBarStyle.None;
        const range = maxValue - minValue;
        const percent = range > 0 ? value / range * 100 : 0;
        return (
            <div className={"progress" + (props.classes ? " " + props.classes : "")} title={props.tooltip}>
                <div className={"progress-bar" + style + (props.striped ? " progress-bar-striped" : "") + (props.animated ? " progress-bar-animated" : "")} style={{ width: percent.toString(10) + "%" }} role="progressbar" aria-valuenow={value} aria-valuemin={minValue} aria-valuemax={maxValue} />
            </div>
        );
    }
}