import { Base } from "../../framework/base";
import { CustomerPriceEditItem, ICustomerPriceEditItem } from "../customerPrice/customerPriceEditItem";

export interface IProductEditItem {
    id: string;
    productGroupId: string;
    number: number;
    name: string;
    unit: number;
    unitPrice: number;
    erp: string;
    description: string;
    comment: string;
    activeState: number;
    rowId: string;
    customerPrices: ICustomerPriceEditItem[];

    isNew():boolean;
}

export class ProductEditItem implements IProductEditItem {
    id: string;
    productGroupId: string;
    number: number;
    name: string;
    unit: number;
    unitPrice: number;
    erp: string;
    description: string;
    comment: string;
    activeState: number;
    rowId: string;
    customerPrices: CustomerPriceEditItem[];

    constructor();
    constructor(obj: IProductEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.productGroupId = obj && obj.productGroupId || "";
        this.number = obj && obj.number || 0;
        this.name = obj && obj.name || "";
        this.unit = obj && obj.unit || 0;
        this.unitPrice = obj && obj.unitPrice || 0;
        this.erp = obj && obj.erp || "";
        this.description = obj && obj.description || "";
        this.comment = obj && obj.comment || "";
        this.activeState = obj && obj.activeState || 0;
        this.rowId = obj && obj.rowId || "";
        this.customerPrices = Base.getTypedArray<CustomerPriceEditItem>(!Base.isNullOrUndefined(obj) ? obj.customerPrices : null, CustomerPriceEditItem);
    }

    isNew():boolean {
        return !this.rowId;
    }
}