import { EmployeeListItemDto } from "../employee/employee";
import { TransportPlanListItemDto } from "./transportPlan";
import { VehicleListItemDto } from "./vehicle";

export interface TransportPlanTemplateListItemDto {
    id: string;
    name: string;
    scheduledStartTime: string;
    duration: number;
    repeatStartDate: string;
    repeatEndDate: string;
    enabledDays: number;
}

export interface TransportPlanTemplateDetailsDto {
    id: string;
    name: string;
    scheduledStartTime: string;
    duration: number;
    repeatStartDate: string;
    repeatEndDate: string;
    enabledDays: number;
    vehicle: VehicleListItemDto;
    employee: EmployeeListItemDto;
    transportPlans: TransportPlanListItemDto[];
}

export interface TransportPlanTemplateUpdateDto {
    name: string;
    scheduledStartTime: string;
    duration: number;
    employeeId?: string;
    vehicleId?: string;
    repeatConfigStartDate: string;
    repeatConfigEndDate: string;
    enabledDays: number;
}

export interface TransportPlanTemplateFormData {
    name: string;
    scheduledStartTime: string;
    duration: number;
    durationDay?: number;
    durationHours?: number;
    durationMinutes?: number;
    employeeId?: string;
    vehicleId?: string;
    repeatStartDate: Date;
    repeatEndDate: Date;
    repeatEnabledDays: number;
}

export interface TransportPlanTemplateCreateDto {
    name: string;
    scheduledStartTime: string;
    duration: number;
    employeeId?: string;
    vehicleId?: string;
    repeatConfigStartDate: Date;
    repeatConfigEndDate: Date;
    repeatEnabledDays: number;
}

export enum RepeatEnabledDays
{
    None = 0,
    Monday1 = 1 << 0,
    Tuesday1 = 1 << 1,
    Wednesday1 = 1 << 2,
    Thursday1 = 1 << 3,
    Friday1 = 1 << 4,
    Saturday1 = 1 << 5,
    Sunday1 = 1 << 6,
    Monday2 = 1 << 7,
    Tuesday2 = 1 << 8,
    Wednesday2 = 1 << 9,
    Thursday2 = 1 << 10,
    Friday2 = 1 << 11,
    Saturday2 = 1 << 12,
    Sunday2 = 1 << 13
}
