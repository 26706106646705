import { Base } from "../../framework/base";
import { CompetencyTypeItem, ICompetencyTypeItem } from "./competencyTypeItem";

export interface ICompetencyTypeItems {
    items: ICompetencyTypeItem[];
    hasMore: boolean;
    page: number;
}

export class CompetencyTypeItems implements ICompetencyTypeItems {
    items: CompetencyTypeItem[];
    hasMore: boolean;
    page: number;

    constructor();
    constructor(obj: ICompetencyTypeItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = Base.getTypedArray<CompetencyTypeItem>(obj ? obj.items : null, CompetencyTypeItem);
    }
}