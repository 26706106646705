export interface IOperationLogEditItem {
    id: string;
    time: number;
    version: string;
    entryType: string;
    ip: string;
    operation: string;
    parameters: string;
    message: string;
    browser: string;
    user: string;
}

export class OperationLogEditItem implements IOperationLogEditItem {
    id: string;
    time: number;
    version: string;
    entryType: string;
    ip: string;
    operation: string;
    parameters: string;
    message: string;
    browser: string;
    user: string;

    constructor();
    constructor(obj: IOperationLogEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.time = obj && obj.time || 0;
        this.version = obj && obj.version || "";
        this.entryType = obj && obj.entryType || "";
        this.ip = obj && obj.ip || "";
        this.operation = obj && obj.operation || "";
        this.parameters = obj && obj.parameters || "";
        this.message = obj && obj.message || "";
        this.browser = obj && obj.browser || "";
        this.user = obj && obj.user || "";
    }
}