export interface ISessionStateSsoIntegration {
    serviceName: string;
    url: string;
}

export class SessionStateSsoIntegration implements ISessionStateSsoIntegration {
    serviceName: string;
    url: string;

    constructor();
    constructor(obj: ISessionStateSsoIntegration);
    constructor(obj?: any) {
        this.serviceName = obj && obj.serviceName || "";
        this.url = obj && obj.url || "";
    }
}