import { Box } from "@mui/material";
import React from "react";
import { Base } from "../../framework/base";
import { AppUtils } from "../../models/common/appUtils";
import { WorkShiftTimeSlotItem } from "../../models/workShitTimeSlot/workShiftTimeSlotItem";
import { IWorkTimeFormItem } from "./workTimeDetailsForm";

interface ICompactTimelineProps {
    overlappingSlots: WorkShiftTimeSlotItem[];
    newSlot: IWorkTimeFormItem;
    formatTime: (time: string) => string;
    calculateDuration: (startDate: any, endDate: any, short?: boolean) => string;
}

export const CompactTimeline = ({ overlappingSlots, newSlot, formatTime, calculateDuration }: ICompactTimelineProps) => {
    const convertToSimpleTimeSlotItem = (item: IWorkTimeFormItem): Partial<WorkShiftTimeSlotItem> => {
        const startDateWithTimeZone = item.startDate.includes("Z")
            ? item.startDate
            : item.startDate+Base.dateTzOffset(item.startDate, item.timeZoneName.value);
        const endDateWithTimeZone = item.endDate.includes("Z")
            ? item.endDate
            : item.endDate+Base.dateTzOffset(item.startDate, item.timeZoneName.value);
        return {
            id: "",
            startDate: startDateWithTimeZone,
            endDate: endDateWithTimeZone,
            timeZoneName: item.timeZoneName.value,
        };
    };

    const getHourLabels = (start: Date, end: Date): Date[] => {
        start.setMinutes(0, 0, 0);

        const labels: Date[] = [];
        const current = new Date(start);
        while (current < end) {
            labels.push(new Date(current));
            current.setHours(current.getHours() + 1);
        }
        return labels;
    };

    const formatDates = (slot: IWorkTimeFormItem) => {
        return `${formatTime(slot.startDate)} - ${formatTime(slot.endDate)} (UTC ${Base.dateTzOffset(slot.startDate, slot.timeZoneName.value)})`;
    };

    const newTimeSlot = convertToSimpleTimeSlotItem(newSlot);
    const allSlots = [...overlappingSlots, newTimeSlot];

    const minStartTime = Math.min(...allSlots.map(slot => new Date(slot.startDate).getTime()));
    const maxEndTime = Math.max(...allSlots.map(slot => new Date(slot.endDate).getTime()));

    const roundedMinStartTime = new Date(minStartTime);
    roundedMinStartTime.setMinutes(0, 0, 0);
    const hourLabels = getHourLabels(new Date(roundedMinStartTime), new Date(maxEndTime));

    const getSlotStyle = (item: Partial<WorkShiftTimeSlotItem>) => {
        const isNewItem = !item.id || item.id === "";
        return {
            position: "absolute" as const,
            left: `${calculatePosition(item.startDate)}%`,
            right: `${100 - calculatePosition(item.endDate)}%`,
            top: "10px",
            height: "30px",
            backgroundColor: isNewItem ? "transparent" : "#e3e3e3",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: isNewItem ? "14px" : "12px",
            borderRadius: "6px",
            border: isNewItem ? "2px dotted #1976d2" : "2px dotted lightgray",
            color: isNewItem ? "transparent" : "gray",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap" as const,
        };
    };

    const calculatePosition = (date) => {
        const time = new Date(date).getTime();
        const position = ((time - minStartTime) / (maxEndTime - minStartTime)) * 100;
        return position;
    };

    return (
        <Box paddingX={4} marginTop={4}>
            <div style={{ position: "relative", height: "50px", width: "100%" }}>
                {hourLabels.map((label, index) => (
                    <React.Fragment key={index}>
                        <div
                            style={{
                                position: "absolute",
                                left: `${calculatePosition(label)}%`,
                                top: "0",
                                bottom: "0",
                                borderLeft: "1px solid #e3e3e3"
                            }}
                        />
                        <div
                            style={{
                                position: "absolute",
                                left: `${calculatePosition(label)}%`,
                                top: "-12px",
                                transform: "translateX(-50%)",
                                fontSize: "12px",
                                color: "#000",
                            }}
                        >
                            {Base.dayjsToTimeStr(label)}
                        </div>
                    </React.Fragment>
                ))}
                {allSlots.map(slot => {
                    const isNewSlot = !slot.id || slot.id === "";
                    const slotLabel = isNewSlot ? (
                        <div style={{ textAlign: "center", paddingTop: "40px", fontSize: "12px", color: "#1976d2" }}>
                            {`${AppUtils.translate("workTime.newEntry")}: ${formatDates(newSlot)}`}
                        </div>
                    ) : null;

                    return (
                        <div key={slot.id || "newSlot"} style={{ position: "relative" }}>
                            <div style={getSlotStyle(slot)}>
                                <span>{calculateDuration(slot.startDate, slot.endDate, true)}</span>
                            </div>
                            {slotLabel}
                        </div>
                    );
                })}
            </div>
        </Box>
    );
};
