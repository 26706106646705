import React from "react";
import { WorkTimeEventLog } from "./workTimeEventLog";
interface IWorkTimeEventIndicatorProps {
    id: string;
    baseLabel: string;
    edited: boolean;
    orgStartTime: string;
    orgEndTime: string;
}

export const WorkTimeEventIndicator = ({
    id,
    baseLabel,
    edited,
    orgStartTime,
    orgEndTime,
}: IWorkTimeEventIndicatorProps) => {
    if (!edited || !orgEndTime) return <>{baseLabel}</>;

    return (
        <div className="cursor-pointer">
            {edited && (
                <WorkTimeEventLog
                    id={id}
                    originalStartTime={orgStartTime}
                    originalEndTime={orgEndTime}
                    baseLabel={baseLabel}
                />
            )}
        </div>
    );
};
