import React, { useEffect, useState } from "react";
import { Translations } from "../../../models/translations";
import { FormikFieldProps} from "../../framework/formUtils";
import { AutocompleteOption, AutocompleteWrapper } from "../../framework/muiAutocomplete";
import { useAppDispatch, useAppSelector } from "../../../framework/customStore";
import { fetchEmployees } from "../../../store/employeeSlice";

interface DriverSelectProps extends Partial<FormikFieldProps> {
    small?: boolean;
    required?: boolean;
    multiple?: boolean;
    open?: boolean;
    autoFocus?: boolean;
    disabled?: boolean;
    onChange?: (id: string | string[] | null, title?: string | null) => void;
}

export const DriverSelect = (props: DriverSelectProps) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const { value, small, onChange, onBlur, multiple, open, autoFocus, ...rest } = props;
    const employees = useAppSelector((state) => state.employees.employees);

    useEffect(() => {
        if(employees?.length === 0) {
            setLoading(true);
            dispatch(fetchEmployees() as any);
        } else {
            setLoading(false);
        }
    }, [employees]);

    const handleChange = (val: AutocompleteOption | AutocompleteOption[] | null) => {
        if (Array.isArray(val)) {
            onChange(val.map(v => v.id));
        } else {
            onChange(val?.id ?? null, val?.title);
        }
    };

    return (
        <AutocompleteWrapper
            label={Translations.Employee}
            value={value}
            size={small ? "small" : "medium"}
            options={employees && employees.map(e =>({ id: e.id, title: `${e.firstName} ${e.lastName}` })) }
            onChange={handleChange}
            onBlur={onBlur}
            loading={loading}
            required={props.required}
            multiple={multiple}
            open={open}
            autoFocus={autoFocus}
            textFieldProps={{ ...rest }}
            disabled={props.disabled}
        />
    );
};
