import { Action } from "redux";
import { IWorkOrderItem } from "../work/workOrderItem";
import { IWorkOrderCalendarLineItem } from "../work/workOrderCalendarLineItem";
import { ICalendarDayItem } from "../calendarDay/calendarDayItem";
import { IDayBookingItem } from "../dayBooking/dayBookingItem";
import { INumberTitle } from "../common/numberTitle";
import { IAcknowledgementEditItem } from "../acknowledgement/acknowledgementEditItem";
import { CalendarAccuracyType, TimeFormat } from "../common/enums";
import { IEmployeeParameters } from "../employee/employeeIParameters";
import { IVehicleItem } from "../vehicle/vehicleItem";
import { ISessionStateSsoIntegration } from "../session/sessionStateSsoIntegration";
import { ITypedOption } from "../common/typedOption";
import { IRoutePointAutocompleteItems, IRoutePointAutocompleteItem } from "../routePoint/routePointAutocompleteItems";
import { IEmployeeGroupItem } from "../employeeGroup/employeeGroupItem";
import { IVehicleGroupItem } from "../vehicleGroup/vehicleGroupItem";
import { StorageProductShoppingCartItem } from "../storage/storageProductShoppingCartItem";
import { GetReportResponse, IGetReportResponse } from "../reporting/getReportingResponse";
import React from "react";

// NOTE: Mostly legacy code. Don't add new types here unless necessary.

// #region ServiceWorker
export interface IBeforeInstallPromptEvent extends Event {
    // Returns an array of DOMString items containing the platforms on which the event was dispatched.
    // This is provided for user agents that want to present a choice of versions to the user such as,
    // for example, "web" or "play" which would allow the user to chose between a web version or
    // an Android version.
    readonly platforms: Array<string>;
    // Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
    readonly userChoice: Promise<{
        outcome: "accepted" | "dismissed",
        platform: string;
    }>;
    // Allows a developer to show the install prompt at a time of their own choosing.
    // This method returns a Promise.
    prompt(): Promise<void>;
}

export interface IInstallState {
    proposalEvent: IBeforeInstallPromptEvent;
}

// #endregion ServiceWorker

/* eslint-disable no-unused-vars */
export enum ConfirmationDialogType {
    Warning,
    Information
}
/* eslint-enable no-unused-vars */

export interface IConfirmationCallback {
    (): void;
}

export interface IAlertState {
    message: string;
    info: string;
    error: string;
}

export interface IConfirmationState {
    type: ConfirmationDialogType;
    title: string;
    text?: string;
    content?: React.ReactNode;
    show: boolean;
    yesTitle: string;
    yesClasses: string;
    noTitle: string;
    noClasses: string;
    onYes: IConfirmationCallback;
    onNo: IConfirmationCallback;
    onCancel: IConfirmationCallback;
}

export interface IUserState {
    authenticated: boolean;
    employeeId: string;
    ownerAdmin: boolean;
    parameters: IEmployeeParameters;
    ssoIntegrations: ISessionStateSsoIntegration[];
}

export interface IOwnerState {
    name: string;
    name2: string;
    style: string;
    parameters: ITypedOption[];
}

export interface IAcknowledgementMainState {
    ownerLogo: string;
    canSendMessage: boolean;
    isLoading: boolean;
    acknowledgement: IAcknowledgementEditItem;
}

export interface IWorkMainState {
    workTimeFormat: TimeFormat;
    dataId: number;
    startDate: number;
    endDate: number;
    calendarLines: IWorkOrderCalendarLineItem[];
    workOrders: IWorkOrderItem[];
    workOrderTemplates: IWorkOrderItem[];
    favoriteWorkOrders: IWorkOrderItem[];
    showOnMapVehicles: IVehicleItem[];
    calendarDays: ICalendarDayItem[];
    dayBookings: IDayBookingItem[];
    dayBookingTypes: INumberTitle[];
    workOrderStates: INumberTitle[];
    workOrderClasses: INumberTitle[];
    employeeGroups: IEmployeeGroupItem[];
    vehicleGroups: IVehicleGroupItem[];
}

export interface IShoppingCartState {
    items: StorageProductShoppingCartItem[];
    selectedOrderId: string | null;
}

export interface IReportResponseState {
    response: IGetReportResponse;
}

export interface IApplicationState {
    // Fetch
    fetchCount: number;
    // Install
    install: IInstallState;
    // Alert
    alert: IAlertState;
    // Confirmation
    confirmation: IConfirmationState;
    // Owner
    owner: IOwnerState;
    // User
    user: IUserState;
    // acknowledgement
    acknowledgementMain: IAcknowledgementMainState;
    // Work
    workMain: IWorkMainState;
    // Route point
    ownerRoutePoints: IRoutePointAutocompleteItems;
    // Shopping cart
    shoppingCart: IShoppingCartState;
}

// Action type constants

// Install
export const INSTALL_SET = "INSTALL_SET";
export const INSTALL_CLEAR = "INSTALL_CLEAR";

type T_INSTALL_SET = typeof INSTALL_SET;
type T_INSTALL_CLEAR = typeof INSTALL_CLEAR;

// Alert
export const ALERT_SUCCESS = "ALERT_SUCCESS";
export const ALERT_INFO = "ALERT_INFO";
export const ALERT_ERROR = "ALERT_ERROR";
export const ALERT_CLEAR = "ALERT_CLEAR";
export const ALERT_SUCCESS_CLEAR = "ALERT_SUCCESS_CLEAR";
export const ALERT_INFO_CLEAR = "ALERT_INFO_CLEAR";

type T_ALERT_SUCCESS = typeof ALERT_SUCCESS;
type T_ALERT_INFO = typeof ALERT_INFO;
type T_ALERT_ERROR = typeof ALERT_ERROR;
type T_ALERT_CLEAR = typeof ALERT_CLEAR;
type T_ALERT_SUCCESS_CLEAR = typeof ALERT_SUCCESS_CLEAR;
type T_ALERT_INFO_CLEAR = typeof ALERT_INFO_CLEAR;

// Confirmation Dialog
export const CONFIRMATION_SET = "CONFIRMATION_SET";
export const CONFIRMATION_CLEAR = "CONFIRMATION_CLEAR";

type T_CONFIRMATION_SET = typeof CONFIRMATION_SET;
type T_CONFIRMATION_CLEAR = typeof CONFIRMATION_CLEAR;

// Fetch
export const FETCH_START = "FETCH_START";
export const FETCH_END = "FETCH_END";

type T_FETCH_START = typeof FETCH_START;
type T_FETCH_END = typeof FETCH_END;

// Token
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

type T_LOGIN_SUCCESS = typeof LOGIN_SUCCESS;
type T_LOGIN_FAILURE = typeof LOGIN_FAILURE;
type T_LOGOUT_SUCCESS = typeof LOGOUT_SUCCESS;

// Owner
export const OWNER_INITIALIZE = "OWNER_INITIALIZE";
export const OWNER_SET_STATE = "OWNER_SET_STATE";

type T_OWNER_INITIALIZE = typeof OWNER_INITIALIZE;
type T_OWNER_SET_STATE = typeof OWNER_SET_STATE;

// User
export const USER_SET_PARAMETERS = "USER_SET_PARAMETERS";
export const USER_SET_STATE = "USER_SET_STATE";

type T_USER_SET_PARAMETERS = typeof USER_SET_PARAMETERS;
type T_USER_SET_STATE = typeof USER_SET_STATE;

// Acknowledgement
export const ACKNOWLEDGEMENT_INITIALIZE = "ACKNOWLEDGEMENT_INITIALIZE";
export const ACKNOWLEDGEMENT_LOAD_START = "ACKNOWLEDGEMENT_LOAD_START";
export const ACKNOWLEDGEMENT_MAIN_SUCCESS = "ACKNOWLEDGEMENT_MAIN_SUCCESS";
export const ACKNOWLEDGEMENT_MAIN_FAILURE = "ACKNOWLEDGEMENT_MAIN_FAILURE";
export const ACKNOWLEDGEMENT_APPROVAL_SUCCESS = "ACKNOWLEDGEMENT_APPROVAL_SUCCESS";

type T_ACKNOWLEDGEMENT_INITIALIZE = typeof ACKNOWLEDGEMENT_INITIALIZE;
type T_ACKNOWLEDGEMENT_LOAD_START = typeof ACKNOWLEDGEMENT_LOAD_START;
type T_ACKNOWLEDGEMENT_MAIN_SUCCESS = typeof ACKNOWLEDGEMENT_MAIN_SUCCESS;
type T_ACKNOWLEDGEMENT_MAIN_FAILURE = typeof ACKNOWLEDGEMENT_MAIN_FAILURE;
type T_ACKNOWLEDGEMENT_APPROVAL_SUCCESS = typeof ACKNOWLEDGEMENT_APPROVAL_SUCCESS;

// Work
export const WORK_MAIN_INITIALIZE = "WORK_MAIN_INITIALIZE";
export const WORK_MAIN_SUCCESS = "WORK_MAIN_SUCCESS";
export const WORK_MAIN_FAILURE = "WORK_MAIN_FAILURE";
export const WORK_MAIN_SET_CALENDAR_ACCURACY = "WORK_MAIN_SET_CALENDAR_ACCURACY";
export const WORK_ITEMS_SUCCESS = "WORK_ITEMS_SUCCESS";
export const WORK_ITEMS_FAILURE = "WORK_ITEMS_FAILURE";
export const WORK_TEMPLATES_SUCCESS = "WORK_TEMPLATES_SUCCESS";

type T_WORK_MAIN_INITIALIZE = typeof WORK_MAIN_INITIALIZE;
type T_WORK_MAIN_SUCCESS = typeof WORK_MAIN_SUCCESS;
type T_WORK_MAIN_FAILURE = typeof WORK_MAIN_FAILURE;
type T_WORK_MAIN_SET_CALENDAR_ACCURACY = typeof WORK_MAIN_SET_CALENDAR_ACCURACY;
type T_WORK_ITEMS_SUCCESS = typeof WORK_ITEMS_SUCCESS;
type T_WORK_ITEMS_FAILURE = typeof WORK_ITEMS_FAILURE;
type T_WORK_TEMPLATES_SUCCESS = typeof WORK_TEMPLATES_SUCCESS;

// Route points
export const OWNER_ROUTE_POINTS_INITIALIZE = "OWNER_ROUTE_POINTS_INITIALIZE";

export const GET_OWNER_ROUTE_POINTS = "GET_OWNER_ROUTE_POINTS";
export const GET_OWNER_ROUTE_POINTS_SUCCESS = "GET_OWNER_ROUTE_POINTS_SUCCESS";
export const GET_OWNER_ROUTE_POINTS_FAILURE = "GET_OWNER_ROUTE_POINTS_FAILURE";
export const OWNER_ROUTE_POINTS_ADD = "OWNER_ROUTE_POINTS_ADD";

type T_OWNER_ROUTE_POINTS_INITIALIZE = typeof OWNER_ROUTE_POINTS_INITIALIZE;
type T_GET_OWNER_ROUTE_POINTS_SUCCESS = typeof GET_OWNER_ROUTE_POINTS_SUCCESS;
type T_OWNER_ROUTE_POINTS_ADD = typeof OWNER_ROUTE_POINTS_ADD;

// Shopping cart
export const SHOPPING_CART_INITIALIZE = "SHOPPING_CART_INITIALIZE";
export const SHOPPING_CART_UPDATE = "SHOPPING_CART_UPDATE";
export const SHOPPING_CART_SELECT_ORDER = "SHOPPING_CART_SELECT_ORDER";
type T_SHOPPING_CART_INITIALIZE = typeof SHOPPING_CART_INITIALIZE;

// Settings
export const SETTINGS_MAIN_SUCCESS = "SETTINGS_MAIN_SUCCESS";
export const SETTINGS_MAIN_FAILURE = "SETTINGS_MAIN_FAILURE";

type T_SETTINGS_MAIN_SUCCESS = typeof SETTINGS_MAIN_SUCCESS;
type T_SETTINGS_MAIN_FAILURE = typeof SETTINGS_MAIN_FAILURE;

// Tracking
export const TRACKING_MAIN_SUCCESS = "TRACKING_MAIN_SUCCESS";
export const TRACKING_MAIN_FAILURE = "TRACKING_MAIN_FAILURE";

type T_TRACKING_MAIN_SUCCESS = typeof TRACKING_MAIN_SUCCESS;
type T_TRACKING_MAIN_FAILURE = typeof TRACKING_MAIN_FAILURE;

//Reporting
export const REPORTING_INITIALIZE = "REPORTING_INITIALIZE";
export const REPORTING_UPDATE = "REPORTING_UPDATE";

export const REPORTING_MAIN_SUCCESS = "REPORTING_MAIN_SUCCESS";
export const REPORTING_MAIN_FAILURE = "REPORTING_MAIN_FAILURE";

type T_REPORTING_MAIN_SUCCESS = typeof REPORTING_MAIN_SUCCESS;
type T_REPORTING_MAIN_FAILURE = typeof REPORTING_MAIN_FAILURE;

// Action types


// Install
export interface IInstallSet extends Action {
    type: T_INSTALL_SET;
    event: IBeforeInstallPromptEvent;
}

export interface IInstallClear extends Action {
    type: T_INSTALL_CLEAR;
}

export type InstallAction =
    | IInstallSet
    | IInstallClear;

// Update
export interface IUpdateSet extends Action {
    type: T_UPDATE_SET;
    onYes: IConfirmationCallback;
    onNo: IConfirmationCallback;
}

export interface IUpdateClear extends Action {
    type: T_UPDATE_CLEAR;
}

export type UpdateAction =
    | IUpdateSet
    | IUpdateClear;

// Alert
export interface IAlertSuccess extends Action {
    type: T_ALERT_SUCCESS;
    message: string;
}

export interface IAlertInfo extends Action {
    type: T_ALERT_INFO;
    message: string;
}

export interface IAlertError extends Action {
    type: T_ALERT_ERROR;
    message: string;
}

export interface IAlertClear extends Action {
    type: T_ALERT_CLEAR;
}

export interface IAlertSuccessClear extends Action {
    type: T_ALERT_SUCCESS_CLEAR;
}

export interface IAlertInfoClear extends Action {
    type: T_ALERT_INFO_CLEAR;
}

export type AlertAction =
    | IAlertSuccess
    | IAlertInfo
    | IAlertError
    | IAlertClear
    | IAlertSuccessClear
    | IAlertInfoClear;

// Confirmation
export interface IConfirmationSet extends Action {
    type: T_CONFIRMATION_SET;
    dialogType: ConfirmationDialogType;
    title: string;
    content?: React.ReactNode;
    text?: string;
    show: boolean;
    yesTitle?: string;
    yesClasses?: string;
    noTitle?: string;
    noClasses?: string;
    onYes: IConfirmationCallback;
    onNo?: IConfirmationCallback;
    onCancel?: IConfirmationCallback;
}

export interface IConfirmationClear extends Action {
    type: T_CONFIRMATION_CLEAR;
}

export type ConfirmationAction =
    | IConfirmationSet
    | IConfirmationClear;

// Fetch
export interface IFetchStart extends Action {
    type: T_FETCH_START;
}

export interface IFetchEnd extends Action {
    type: T_FETCH_END;
}

export type FetchAction =
    | IFetchStart
    | IFetchEnd;

// Owner
export interface IOwnerInitialize extends Action {
    type: T_OWNER_INITIALIZE;
}

export interface IOwnerSetState extends Action {
    type: T_OWNER_SET_STATE;
    name: string;
    name2: string;
    parameters: ITypedOption[];
}

export type OwnerAction =
    | IOwnerInitialize
    | IOwnerSetState;

// Login
export interface ILoginSuccess extends Action {
    type: T_LOGIN_SUCCESS;
}

export interface ILoginFailure extends Action {
    type: T_LOGIN_FAILURE;
}

export interface ILogoutSuccess extends Action {
    type: T_LOGOUT_SUCCESS;
}

export interface IUserSetParameters extends Action {
    type: T_USER_SET_PARAMETERS;
    parameters: IEmployeeParameters;
}

export interface IUserSetState extends Action {
    type: T_USER_SET_STATE;
    employeeId: string;
    ownerAdmin: boolean;
    ssoIntegrations: ISessionStateSsoIntegration[];
}

export type LoginAction =
    | ILoginSuccess
    | ILoginFailure
    | ILogoutSuccess
    | IUserSetParameters
    | IUserSetState;

// Acknowledgement
export interface IAcknowledgementInitialize extends Action {
    type: T_ACKNOWLEDGEMENT_INITIALIZE;
}

export interface IAcknowledgementLoadStart extends Action {
    type: T_ACKNOWLEDGEMENT_LOAD_START;
}

export interface IAcknowledgementMainSuccess extends Action {
    type: T_ACKNOWLEDGEMENT_MAIN_SUCCESS;
    ownerLogo: string;
    canSendMessage: boolean;
    acknowledgement: IAcknowledgementEditItem;
}

export interface IAcknowledgementMainFailure extends Action {
    type: T_ACKNOWLEDGEMENT_MAIN_FAILURE;
}

export interface IAcknowledgementApprovalSuccess extends Action {
    type: T_ACKNOWLEDGEMENT_APPROVAL_SUCCESS;
    approvalDate: number;
}

export type AcknowledgementAction =
    | IAcknowledgementInitialize
    | IAcknowledgementLoadStart
    | IAcknowledgementMainSuccess
    | IAcknowledgementMainFailure
    | IAcknowledgementApprovalSuccess;

// Work
export interface IWorkMainInitialize extends Action {
    type: T_WORK_MAIN_INITIALIZE;
}

export interface IWorkMainSuccess extends Action {
    type: T_WORK_MAIN_SUCCESS;
    workTimeFormat: TimeFormat;
    startDate: number;
    endDate: number;
    workOrders: IWorkOrderItem[];
    workOrderTemplates: IWorkOrderItem[];
    favoriteWorkOrders: IWorkOrderItem[];
    showOnMapVehicles: IVehicleItem[];
    calendarLines: IWorkOrderCalendarLineItem[];
    calendarDays: ICalendarDayItem[];
    dayBookings: IDayBookingItem[];
    dayBookingTypes: INumberTitle[];
    workOrderStates: INumberTitle[];
    workOrderClasses: INumberTitle[];
    employeeGroups: IEmployeeGroupItem[];
    vehicleGroups: IVehicleGroupItem[];
}

export interface IWorkMainFailure extends Action {
    type: T_WORK_MAIN_FAILURE;
}

export interface IWorkMainSetCalendarAccuracy extends Action {
    type: T_WORK_MAIN_SET_CALENDAR_ACCURACY;
    calendarAccuracy: CalendarAccuracyType;
}

export interface IWorkItemsSuccess extends Action {
    type: T_WORK_ITEMS_SUCCESS;
    startDate: number;
    endDate: number;
    workOrders: IWorkOrderItem[];
    workOrderTemplates: IWorkOrderItem[];
    favoriteWorkOrders: IWorkOrderItem[];
    showOnMapVehicles: IVehicleItem[];
    calendarLines: IWorkOrderCalendarLineItem[];
    calendarDays: ICalendarDayItem[];
    dayBookings: IDayBookingItem[];
    dayBookingTypes: INumberTitle[];
}

export interface IWorkItemsFailure extends Action {
    type: T_WORK_ITEMS_FAILURE;
}

export interface IWorkTemplatesSuccess extends Action {
    type: T_WORK_TEMPLATES_SUCCESS;
    workOrderTemplates: IWorkOrderItem[];
}

export type WorkAction =
    | IWorkMainInitialize
    | IWorkMainSuccess
    | IWorkMainFailure
    | IWorkMainSetCalendarAccuracy
    | IWorkItemsSuccess
    | IWorkItemsFailure
    | IWorkTemplatesSuccess;

// Route points
export interface IOwnerRoutePointsInitialize extends Action {
    type: T_OWNER_ROUTE_POINTS_INITIALIZE;
}

export interface IGetOwnerRoutePointsSuccess extends Action {
    type: T_GET_OWNER_ROUTE_POINTS_SUCCESS;
    ownerRoutePoints: IRoutePointAutocompleteItems;
}

export interface IOwnerRoutePointsAdd extends Action {
    type: T_OWNER_ROUTE_POINTS_ADD;
    routePoint: IRoutePointAutocompleteItem;
}

export type OwnerRoutePointsAction =
    | IOwnerRoutePointsInitialize
    | IGetOwnerRoutePointsSuccess
    | IOwnerRoutePointsAdd;

// Shopping cart
export interface IShoppingCartInitialize extends Action {
    type: T_SHOPPING_CART_INITIALIZE;
}

export interface IShoppingCartUpdate extends Action {
    items: StorageProductShoppingCartItem[];
}

export interface IShoppingCartSelectOrder extends Action {
    selectedOrderId: string;
}


export interface IGetReportingUpdate extends Action {
    response: IGetReportResponse;
}

export interface IReportingInitial extends Action {
}

export type IGetReportingAction =
    | IReportingInitial
    | IGetReportingUpdate

export type ShoppingCartAction =
    | IShoppingCartInitialize
    | IShoppingCartUpdate
    | IShoppingCartSelectOrder

// Settings
export interface ISettingsMainSuccess extends Action {
    type: T_SETTINGS_MAIN_SUCCESS;
}

export interface ISettingsMainFailure extends Action {
    type: T_SETTINGS_MAIN_FAILURE;
}

export type SettingsAction =
    | ISettingsMainSuccess
    | ISettingsMainFailure;

// Tracking
export interface ITrackingMainSuccess extends Action {
    type: T_TRACKING_MAIN_SUCCESS;
}

export interface IReportingMainSuccess extends Action {
    type: T_REPORTING_MAIN_SUCCESS;
}

export interface IReportingMainFailure extends Action {
    type: T_REPORTING_MAIN_FAILURE;
}

export interface ITrackingMainFailure extends Action {
    type: T_TRACKING_MAIN_FAILURE;
}

export type TrackingAction =
    | ITrackingMainSuccess
    | ITrackingMainFailure;

export type ReportingAction =
    | IReportingMainSuccess
    | IReportingMainFailure;

