import React, { useState } from "react";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { AutoComplete } from "./components/autoComplete";
import { StyledDatePicker as DatePicker } from "./components/styledDatePicker";
import { ComponentType, IComponentFilterDefination, ReportingComponentType } from "../../models/reporting/getReportingResponse";
import { AppDispatch, useAppDispatch, useAppSelector } from "../../framework/customStore";
import { setTimePeriod, updateSelectedFilters, setCheckBoxFilter } from "../../store/reportingSlice";
import fi from "date-fns/locale/fi";

type props = {
    filterList: IComponentFilterDefination[];
    requiredFilled: boolean;
    requiredFields: string[];
};

const createList = (filterList: IComponentFilterDefination[]) => {
    let counter = 0;
    let list: IComponentFilterDefination[] = [];
    const listOfFilterList: IComponentFilterDefination[][] = [];
    let prevGrid = -1;
    filterList.forEach((defination) => {
        if (prevGrid === -1) prevGrid = defination.grid;
        list.push(defination);
        counter++;
        if (prevGrid !== defination.grid) {
            list.pop();
            listOfFilterList.push(list);
            list = [];
            list.push(defination);
            if (counter > 3) counter = 0;
        } else if (counter > 3) {
            listOfFilterList.push(list);
            list = [];
            counter = 0;
        }
        prevGrid = defination.grid;
    });
    if (list.length > 0) listOfFilterList.push(list);
    return listOfFilterList;
};

export const ReportingFilters = (props: props) => {
    const listOfFilterList: IComponentFilterDefination[][] = createList(props.filterList);
    return (
        <Grid container spacing={2} alignItems="center">
            {listOfFilterList.map((item, index) =>
                <FilterLine
                    key={index}
                    requiredFilled={props.requiredFilled}
                    requiredFields={props.requiredFields}
                    filterList={item}
                />
            )}
        </Grid>
    );
};

export const FilterLine = (props: props) => {
    const state = useAppSelector(state => state.reporting);
    const dispatch : AppDispatch = useAppDispatch();
    const [dateRange, setDateRange] = useState([new Date(), null]);
    const [startDate, endDate] = dateRange;
    const [locale, setLocale] = useState(fi);
    const importLocaleFile = async() => {
        // This webpack option stops all of the date-fns files being imported and chunked.
        const localeToSet = await import(
          /* webpackMode: "lazy", webpackChunkName: "df-[index]", webpackExclude: /_lib/ */
          `date-fns/locale/${appConfig.culture}/index.js`
        );
        setLocale(localeToSet.default);
    };
    if (appConfig.culture !== locale.code){
        importLocaleFile();
    }
    const createComponent = (i: IComponentFilterDefination, index: number) => {
        const type = Object.keys(ReportingComponentType)[i.componentTypeName];
        let disabled = !props.requiredFilled;
        if(props.requiredFields.some(p => i.componentTypeName.toString() === p.toString())) disabled = false;
        switch (i.componentType) {
            case ComponentType.ComboBox:
                return (
                    <AutoComplete
                        type={type}
                        value={state.selectedFilters[type] || []}
                        onChange={(val) => dispatch(updateSelectedFilters({ type: type, items: val }))}
                        options={props.filterList[index]}
                        label={i.label}
                        multiple={false}
                        sx={{ minWidth: "200px" }}
                    />
                );
            case ComponentType.Text:
                return (<TextField disabled={disabled} label={i.label} onChange={(e) => console.log("todo")} />);
            case ComponentType.Period:
                return (
                    <DatePicker
                        disabled={disabled}
                        label={i.label}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(value) => {
                            setDateRange(value);
                            const isoDates = [
                                new Date(value[0]).toISOString(),
                                new Date(value[1]).toISOString()
                            ].toString().replace(",", ";");
                            dispatch(setTimePeriod(isoDates));
                        }}
                        locale={locale}
                    />
                );
            case ComponentType.MultiSelecBox:
                return (
                    <AutoComplete
                        type={type}
                        value={state.selectedFilters[type] || []}
                        onChange={(val) => dispatch(updateSelectedFilters({ type: type, items: val }))}
                        options={props.filterList[index]}
                        label={i.label}
                        multiple={true}
                    />
                );
            case ComponentType.CheckBox:
                return (
                    <>
                        <label>{i.label}</label>
                        <input
                            disabled={disabled}
                            style={{ marginLeft: "10px" }}
                            type="checkbox"
                            onChange={(e) => dispatch(setCheckBoxFilter({ type: type, value: e.target.checked ? 1 : 0 }))}
                        />
                    </>
                );
            case ComponentType.Empty:
    <div>&nbsp;</div>;
            default:
        }
    };
    return (
        <>
            { props.filterList.map((i: IComponentFilterDefination, index) => <Grid key={index}>{createComponent(i, index)}</Grid>) }
        </>);
};
