import { ApiSuccess, SaveSuccess, SaveSuccessItems } from "./baseService";
import { getApiCall, postFormApiCall, postApiCall, getFileApiCall } from "./tokenService";
import { EmployeeItems } from "../models/employee/employeeItems";
import { EmployeeEdit } from "../models/employee/employeeEdit";
import { EmployeePasswordChangeSuccess } from "../models/employee/employeePasswordChangeSuccess";
import { IdTitleItems } from "../models/common/idTitleItems";
import { EmployeeCompetencyItems } from "../models/employee/employeeCompetencyItems";
import { EmployeeCompetencyEdit } from "../models/employee/employeeCompetencyEdit";
import { IEmployeeParameters, EmployeeParameters } from "../models/employee/employeeIParameters";
import { DayBookingEdit } from "../models/dayBooking/dayBookingEdit";

export const getEmployeeItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean, showNotInUse: boolean): Promise<EmployeeItems> => {
    return getApiCall<EmployeeItems>("api/employee/?page=" + (page || "") + "&pageSize=" + (pageSize || "") +
        "&sortColumn=" + (sortColumn ? encodeURIComponent(sortColumn) : "") + "&sortOrder=" + (sortOrderIsAsc ? "asc" : "desc") +
        "&filter=" + (filter ? encodeURIComponent(filter) : "") + "&showNotInUse=" + (showNotInUse ? "1" : "0"), EmployeeItems);
};

export const getEmployeeEdit = (id: string): Promise<EmployeeEdit> => {
    return getApiCall<EmployeeEdit>("api/employee/edit/?id=" + (id || ""), EmployeeEdit);
};

export const saveEmployeeEdit = (data: FormData): Promise<SaveSuccess> => {
    return postFormApiCall<SaveSuccess>("api/employee/save", data, SaveSuccess);
};

export const changeEmployeePassword = (data: FormData): Promise<EmployeePasswordChangeSuccess> => {
    return postFormApiCall<EmployeePasswordChangeSuccess>("api/employee/changepassword", data, EmployeePasswordChangeSuccess);
};

export const changeEmployeeUsername = (data: FormData): Promise<EmployeePasswordChangeSuccess> => {
    return postFormApiCall<EmployeePasswordChangeSuccess>("api/employee/changeusername", data, EmployeePasswordChangeSuccess);
};

export const removeEmployee = (id: string): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/employee/remove/?id=" + (id || ""), ApiSuccess);
};

export const getEmployeeIdTitles = (ignoreIds: string[]): Promise<IdTitleItems> => {
    return postApiCall<IdTitleItems>("api/employee/idtitle", IdTitleItems, JSON.stringify(ignoreIds));
};

export const getEmployeesHaveTime = (ids: string[], startTime: number, endTime: number): Promise<boolean> => {
    return postApiCall<boolean>("api/employee/havetime", null, JSON.stringify({
        employeeIds: ids,
        startTime: startTime,
        endTime: endTime
    }));
};

export const geEmployeePrint = (id: string, startTime: number, endTime: number): Promise<string> => {
    return getFileApiCall("api/employee/print?id=" + (id || "") + "&startTime=" + startTime + "&endTime=" + endTime);
};

// employee day booking
export const getEmployeeDayBookingEdit = (id: string): Promise<DayBookingEdit> => {
    return getApiCall<DayBookingEdit>("api/employee/daybooking/?id=" + (id || ""), DayBookingEdit);
};

export const saveEmployeeDayBookings = (data: FormData): Promise<SaveSuccessItems> => {
    return postFormApiCall<SaveSuccessItems>("api/employee/savedaybookings", data, SaveSuccessItems);
};

export const removeEmployeeDayBookings = (data: FormData): Promise<ApiSuccess> => {
    return postFormApiCall<ApiSuccess>("api/employee/removedaybookings", data, ApiSuccess);
};

// employeecompetency
export const getEmployeeCompetencyItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean): Promise<EmployeeCompetencyItems> => {
    return getApiCall<EmployeeCompetencyItems>("api/employeecompetency/?page=" + (page || "") + "&pageSize=" + (pageSize || "") +
        "&sortColumn=" + (sortColumn ? encodeURIComponent(sortColumn) : "") + "&sortOrder=" + (sortOrderIsAsc ? "asc" : "desc") +
        "&filter=" + (filter ? encodeURIComponent(filter) : ""), EmployeeCompetencyItems);
};

export const getEmployeeCompetencyEdit = (id: string): Promise<EmployeeCompetencyEdit> => {
    return getApiCall<EmployeeCompetencyEdit>("api/employeecompetency/edit/?id=" + (id || ""), EmployeeCompetencyEdit);
};

export const removeEmployeeCompetency = (id: string): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/employeecompetency/remove/?id=" + (id || ""), ApiSuccess);
};

export const saveEmployeeCompetencyEdit = (data: FormData): Promise<ApiSuccess> => {
    return postFormApiCall<ApiSuccess>("api/employeecompetency/save", data, ApiSuccess);
};

export const getEmployeeParameters = (): Promise<IEmployeeParameters> => {
    return getApiCall<IEmployeeParameters>("api/employee/getemployeeparameters", EmployeeParameters);
};

export const setEmployeeParameters = (parameters: IEmployeeParameters): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/employee/setemployeeparameters", ApiSuccess, JSON.stringify(parameters));
};

// employee employee groups
export const saveEmployeeEmployeeGroups = (data: FormData): Promise<SaveSuccessItems> => {
    return postFormApiCall<SaveSuccessItems>("api/employee/addemployeegroups", data, SaveSuccessItems);
};

export const removeEmployeeEmployeeGroups = (data: FormData): Promise<ApiSuccess> => {
    return postFormApiCall<ApiSuccess>("api/employee/removeemployeegroups", data, ApiSuccess);
};

// ownsettings
export const getOwnSettings = (): Promise<EmployeeEdit> => {
    return getApiCall<EmployeeEdit>("api/ownsettings/edit", EmployeeEdit);
};

export const saveOwnSettings = (data: FormData): Promise<ApiSuccess> => {
    return postFormApiCall<ApiSuccess>("api/ownsettings/save", data, ApiSuccess);
};
