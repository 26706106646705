import { getApiCall, postApiCall, postFormApiCall } from "./tokenService";
import { SalaryRowTypeItems } from "../models/salary/salaryRowTypeItems";
import { SalaryRowTypeEdit } from "../models/salary/salaryRowTypeEdit";
import { ApiSuccess, SaveSuccess } from "./baseService";

export const getSalaryRowTypeItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean): Promise<SalaryRowTypeItems> => {
    return getApiCall<SalaryRowTypeItems>("api/salaryrowtype/?page=" + (page || "") + "&pageSize=" + (pageSize || "") +
        "&sortColumn=" + (sortColumn ? encodeURIComponent(sortColumn) : "") + "&sortOrder=" + (sortOrderIsAsc ? "asc" : "desc") +
        "&filter=" + (filter ? encodeURIComponent(filter) : ""), SalaryRowTypeItems);
};

export const getSalaryRowTypeEdit = (id: string): Promise<SalaryRowTypeEdit> => {
    return getApiCall<SalaryRowTypeEdit>("api/salaryrowtype/edit/?id=" + (id || ""), SalaryRowTypeEdit);
};

export const saveSalaryRowTypeEdit = (data: FormData): Promise<SaveSuccess> => {
    return postFormApiCall<SaveSuccess>("api/salaryrowtype/save", data, SaveSuccess);
};

export const removeSalaryRowTypes = (ids: string[]): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/salaryrowtype/remove", ApiSuccess, JSON.stringify({ ids: ids }));
};