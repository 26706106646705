import * as React from "react";

type props = { onClick: Function; onDoubleClick: Function; className?: string; children?: React.ReactNode };

// Allows for single and double click on the same element while triggering only one of them at once.
// Wraps children in a div element which can be given a class if necessary.
export class UseSingleAndDoubleClick extends React.Component<props> {
    timer = null;

    onClickHandler = event => {
        clearTimeout(this.timer);

        if (event.detail === 1) {
            this.timer = setTimeout(this.props.onClick, 400);
        } else if (event.detail === 2) {
            this.props.onDoubleClick();
        }
    };

    render() {
        return (
            <div onClick={this.onClickHandler} className={this.props.className}>
                {this.props.children}
            </div>
        );
    }
}
