import { Base } from "../../framework/base";
import { EnumHelper, WorkOrderCategory } from "../common/enums";
import { IWorkOrderListItemWorkOrderTask, WorkOrderListItemWorkOrderTask } from "./workOrderListItemWorkOrderTask";
import { IWorkOrderListItemEmployee, WorkOrderListItemEmployee } from "./workOrderListItemEmployee";
import { IWorkOrderListItemInvoice, WorkOrderListItemInvoice } from "./workOrderListItemInvoice";

export interface IWorkOrderInvoiceDataListItem {
    id: string;
    number: number;
    parentNumber: number;
    startTime: number;
    endTime: number;
    name: string;
    description: string;
    customerId: string;
    customerName: string;
    siteName: string;
    siteNumber: string;
    siteAddress: string;
    siteLongitude: number;
    siteLatitude: number;
    state: number;
    stateStr: string;
    category: number;
    acknowledgementSent: boolean;
    workOrderTypeName: string;
    fullDay: boolean;
    employees: IWorkOrderListItemEmployee[];
    invoices: IWorkOrderListItemInvoice[];
    projectTasks: IWorkOrderListItemWorkOrderTask[];

    //Calculated fields
    employeeNames: string;
    invoiceNumbers: string;

    isProject(): boolean;
    isWork(): boolean;
    isTask(): boolean;
    hasLocation(): boolean;
    getLocationHash(): string;
}

export class WorkOrderInvoiceDataListItem implements IWorkOrderInvoiceDataListItem {
    id: string;
    number: number;
    parentNumber: number;
    startTime: number;
    endTime: number;
    description: string;
    name: string;
    customerId: string;
    customerName: string;
    siteName: string;
    siteNumber: string;
    siteAddress: string;
    siteLongitude: number;
    siteLatitude: number;
    state: number;
    stateStr: string;
    category: number;
    acknowledgementSent: boolean;
    workOrderTypeName: string;
    fullDay: boolean;
    employees: WorkOrderListItemEmployee[];
    invoices: WorkOrderListItemInvoice[];
    projectTasks: WorkOrderListItemWorkOrderTask[];

    //Calculated fields
    employeeNames: string;
    invoiceNumbers: string;

    constructor();
    constructor(obj: IWorkOrderInvoiceDataListItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.parentNumber = obj && obj.parentNumber || 0;
        this.startTime = obj && obj.startTime || null;
        this.endTime = obj && obj.endTime || null;
        this.name = obj && obj.name || "";
        this.description = obj && obj.description || "";
        this.customerId = obj && obj.customerId || "";
        this.customerName = obj && obj.customerName || "";
        this.siteName = obj && obj.siteName || "";
        this.siteNumber = obj && obj.siteNumber || "";
        this.siteAddress = obj && obj.siteAddress || "";
        this.siteLongitude = obj && obj.siteLongitude || 0;
        this.siteLatitude = obj && obj.siteLatitude || 0;
        this.state = obj && obj.state || 0;
        this.stateStr = obj && obj.stateStr || "";
        this.category = obj && obj.category || 0;
        this.acknowledgementSent = obj && obj.acknowledgementSent || false;
        this.workOrderTypeName = obj && obj.workOrderTypeName || "";
        this.fullDay = obj && obj.fullDay || false;
        this.employees = Base.getTypedArray<WorkOrderListItemEmployee>(obj ? obj.employees : null, WorkOrderListItemEmployee);
        this.invoices = Base.getTypedArray<WorkOrderListItemInvoice>(obj ? obj.invoices : null, WorkOrderListItemInvoice);
        this.projectTasks = Base.getTypedArray<WorkOrderListItemWorkOrderTask>(obj ? obj.projectTasks : null, WorkOrderListItemWorkOrderTask);
        //Calculated fields
        this.employeeNames = Base.getStringWithSeparators(this.employees.map(i => i.name), ", ");
        this.invoiceNumbers = Base.getStringWithSeparators(this.invoices.map(i => i.number.toString(10)), ", ");
    }

    isProject(): boolean {
        return EnumHelper.isEqual(this.category, WorkOrderCategory.Project);
    }

    isWork(): boolean {
        return EnumHelper.isEqual(this.category, WorkOrderCategory.Work);
    }

    isTask(): boolean {
        return EnumHelper.isEqual(this.category, WorkOrderCategory.Task);
    }

    hasLocation(): boolean {
        return !!this.siteLatitude && !!this.siteLongitude;
    }
    
    getLocationHash(): string {
        return (this.siteLatitude ? this.siteLatitude.toFixed(6) : "") + "," + (this.siteLongitude ? this.siteLongitude.toFixed(6) : "");
    }
}