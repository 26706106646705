import { ApiResponse, apiCall } from "./apiClient";
import { TransportPlanTemplateListItemDto, TransportPlanTemplateFormData, TransportPlanTemplateDetailsDto, TransportPlanTemplateUpdateDto, TransportPlanTemplateCreateDto } from "../models/transport/transportPlanTemplate";
import { ApiSuccess } from "./baseService";

export const getTransportPlanTemplates = (): Promise<ApiResponse<TransportPlanTemplateListItemDto[]>> => {
    return apiCall<TransportPlanTemplateListItemDto[]>("TransportPlanTemplates", "GET");
};

export const createRecurringTransportPlan = (data: TransportPlanTemplateCreateDto): Promise<ApiResponse<TransportPlanTemplateFormData>> => {
    return apiCall<TransportPlanTemplateFormData>("TransportPlanTemplates", "POST", data);
};

export const updateTransportPlanTemplate = (data: TransportPlanTemplateUpdateDto, id: string): Promise<ApiResponse<ApiSuccess>> => {
    return apiCall(`TransportPlanTemplates/${id}`,
        "PUT",
        data);
};

export const getTransportPlanTemplate = (id: string): Promise<ApiResponse<TransportPlanTemplateDetailsDto>> => {
    return apiCall<TransportPlanTemplateDetailsDto>(`TransportPlanTemplates/${id}`, "GET");
};

export const removeTransportPlanTemplate = (id: string,): Promise<ApiResponse<void>> => {
    return apiCall<void>(`TransportPlanTemplates/${id}`, "DELETE");
};