import * as React from "react";
import { Translations } from "../../models/translations";
import { IWorkTimeAccountTransaction } from "../../models/workTimeAccount/workTimeAccountTransaction";
import { PropertyDialog } from "../framework/dialog";
import * as store from "../../framework/customStore";
import * as StoreActions from "../../models/store/storeActions";
import * as workTimeAccountService from "../../services/workTimeAccountService";
import { handleApiError } from "../../models/store/storeEffects";
import { SettingsEmployeeWorkHourAccountTransactions } from "../settings/settingsEmployeeWorkHourAccountTransactions";
import { RadioButton } from "../framework/radio";
import { WorkTimeAccountTransactionType } from "../../models/common/enums";

//WorkTimeAccountDialog
interface IWorkTimeDialogWorkTimeAccountProps {
    onClose: () => void;
    salaryId: string;
    employeeId: string;
    initializeFromDatabase: boolean;
    getWorkTimeAccountTransaction: (amount: number, transactionType: WorkTimeAccountTransactionType, previousAmount: number) => void;
    amount: number;
    workTimeAccountBalance: number;
}

interface IWorkTimeDialogWorkTimeAccountState {
    amount: string;
    previousAmount: number;
    items: IWorkTimeAccountTransaction[];
    transactionType: WorkTimeAccountTransactionType;
}

export class WorkTimeAccountDialog extends React.Component<IWorkTimeDialogWorkTimeAccountProps, IWorkTimeDialogWorkTimeAccountState> {
    constructor(props: IWorkTimeDialogWorkTimeAccountProps) {
        super(props);
        this.state = {
            amount: Math.abs(props.amount).toString(10),
            previousAmount: 0,
            items: [],
            transactionType: props.amount >= 0 ? WorkTimeAccountTransactionType.Deposit : WorkTimeAccountTransactionType.Withdraw
        };
    }

    getItems = () => {
        const obj = this;
        const props = this.props;
        const state = this.state;
        store.customStore.dispatch(StoreActions.fetchStart());
        workTimeAccountService.getWorkTimeAccountTransactions(props.employeeId)
            .then(workTimeAccountTransactions => {
                const items = workTimeAccountTransactions.items;
                let amount = state.amount;
                let previousAmount = 0;
                let transactionType = state.transactionType;
                if (props.initializeFromDatabase) {
                    const savedTransaction = items.find(i => i.salaryId === props.salaryId);
                    if (savedTransaction) {
                        previousAmount = savedTransaction.amount;
                        amount = Math.abs(previousAmount).toString(10);
                        transactionType = previousAmount >= 0
                            ? WorkTimeAccountTransactionType.Deposit
                            : WorkTimeAccountTransactionType.Withdraw;
                    }
                }
                obj.setState({
                    items: items,
                    amount: amount,
                    previousAmount: previousAmount,
                    transactionType: transactionType
                });
            },
            error => {
                handleApiError(error, store.customStore.dispatch);
            })
            .finally(() => {
                store.customStore.dispatch(StoreActions.fetchEnd());
            });
    };

    componentDidMount() {
        this.getItems();
    }

    transactionModes = [
        { title: Translations.AddWorkHoursToBank, transactionType: WorkTimeAccountTransactionType.Deposit },
        { title: Translations.TakeWorkHoursFromBank, transactionType: WorkTimeAccountTransactionType.Withdraw }
    ];

    handleOkClick = () => {
        const props = this.props;
        const state = this.state;
        props.getWorkTimeAccountTransaction(state.amount.toInteger(), state.transactionType, state.previousAmount);
        props.onClose();
    };

    handleCloseClick = () => {
        this.props.onClose();
    };

    handleChange = (event: any) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "amount") {
            this.setState({ amount: value });
        }
    };

    handleBlur = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "amount") {
            this.setState({ amount: value.toInteger().toString(10) });
        }
    };

    handleRadioClick = (value: WorkTimeAccountTransactionType) => {
        this.setState({
            transactionType: value
        });
    };

    render() {
        const dialogClasses = "settings px800";
        const props = this.props;
        const state = this.state;
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.TransferWorkHours}
                    show={true}
                    body={
                        <div>
                            <div className="row">
                                <div className="col-8">
                                    <div className="form-group">
                                        {Translations.WorkHourBalance}
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        {props.workTimeAccountBalance}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                    <div className="form-group">
                                        {this.transactionModes.map((mode) =>
                                            <div className="row" key={mode.transactionType}>
                                                <RadioButton
                                                    title={mode.title}
                                                    classes={""}
                                                    enabled={true}
                                                    checked={mode.transactionType === state.transactionType}
                                                    onRadioClick={() => this.handleRadioClick(mode.transactionType)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-4" style={{}}>
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.Hours}</label>
                                        <input type="number" className="form-control" name="amount" title={Translations.Hours} value={state.amount} onChange={this.handleChange} onBlur={this.handleBlur} min="0" />
                                    </div>
                                </div>
                            </div>
                            <SettingsEmployeeWorkHourAccountTransactions
                                title={Translations.WorkhourBank}
                                items={state.items}
                            />
                        </div>
                    }
                    buttons={[
                        { title: Translations.OK, classes: "btn-primary", enabled: true, onClick: this.handleOkClick }
                    ]}
                    onClose={this.handleCloseClick}
                />
            </div>
        );
    }
};