import React, { useEffect, useState } from "react";
import {
    DataGridPremium, fiFI,
    GridColDef,
    GridRowModel,
    GridRowSelectionModel,
    GridRowsMeta,
    GridToolbar,
    GridToolbarContainer,
    GridToolbarExport,
    useGridApiRef,
    useKeepGroupedColumnsHidden
} from "@mui/x-data-grid-premium";
//import ClickAwayListener from "@mui/base/ClickAwayListener";
import { darken, lighten, styled } from "@mui/material/styles";
import { ReportingItems } from "../../../models/reporting/ReportingQueryResponse";
import { Translations } from "../../../models/translations";
import { ClickAwayListener } from "@mui/material";

export interface Props {
    data: GridData;
}

type GridData = { columns: GridColDef[]; rows: ReportingItems[] }

const CustomToolbar =()=> {
    // return(<div></div>)
    return (
        <GridToolbarContainer sx={{
            backgroundColor: "primary.dark",
        }}
        >
            <GridToolbarExport />
        </GridToolbarContainer>
    );
};

const removeDuplicateColumns = (items : GridColDef[], key : string) => [...new Map(items.map(item => [item[key], item])).values()];
const mapRowsToColumns = (columns : GridColDef[], rows : ReportingItems[]) => {
    return removeDuplicateColumns([
        ...columns,
        ...rows.map(r => {
            return {
                field: `column${r.category}`,
                headerName: r.categoryName,
                width: r.categoryName.length > 5 ? 140 : 120,
                editable: false,
                groupable: false,
                type: "number"
            };
        }),
    ], "field");
};

const formAggregationModel = (columns : GridColDef[]) => {
    columns = columns.filter(c => c.field !== "name");
    return columns.reduce((model, column) => ({
        ...model,
        [column.field]: "sum"
    }), {});
};

const mapEmployeeRowValuesToColumns = (rows : ReportingItems[]) => {
    const employees = [...new Set(rows.map(r => r.employeeId))];
    const employeeRows = employees.map(e => rows.filter(r => r.employeeId === e));
    return employeeRows.map(r => {
        return r.reduce((previous, current) => {
            return {
                ...previous,
                [`column${current.category}`]: current.amount,
                "total": previous.total + current.amount
            };
        }, { ...{ ...r[0], total: 0 } });
    });
};

export const DataGridStyle = (props: Props) => {
    const { data } = props;
    const [gridData, setGridData] = useState<GridData>(data);
    const [selection, setSelection] = React.useState([]);
    const apiRef = useGridApiRef();
    const initialState = useKeepGroupedColumnsHidden({
        apiRef,
        initialState: {}
    });

    useEffect(() => {
        if(data?.columns.length > 0 && data?.rows.length > 0) {
            const { columns, rows } = data;
            const columnsModified = mapRowsToColumns(columns, rows);
            const rowsModified = mapEmployeeRowValuesToColumns(rows);
            const aggregationModel = formAggregationModel(columnsModified);
            apiRef.current.setAggregationModel(aggregationModel);
            setGridData({ columns: columnsModified, rows: rowsModified });
        } else {
            setGridData({ columns: [], rows: [] });
        }
    }, [data]);

    const defaultLocale = { toolbarColumns: "" };
    const fiLocale = { ...fiFI.components.MuiDataGrid.defaultProps.localeText, toolbarColumns: "" };
    return(
        <ClickAwayListener onClickAway={() => setSelection([])}>
            <StyledDataGrid
                {...gridData}
                apiRef={apiRef}
                localeText={appConfig.culture === "fi" ? fiLocale : defaultLocale}
                initialState={initialState}
                rowSelectionModel={selection}
                onRowSelectionModelChange={(selectionModel : GridRowSelectionModel) =>setSelection(selectionModel)}

                slots={{
                    toolbar: CustomToolbar
                }}
                getRowClassName={(params: GridRowModel) => params.indexRelativeToCurrentPage % 2 === 0
                    ? "reporting-even"
                    : "reporting-odd"
                }
            />
        </ClickAwayListener>
    );
};

const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({
    "& .MuiDataGrid-cell:focus": {
        outline: "none"
    },
    "& .reporting-even": {
        backgroundColor: getBackgroundColor(theme.palette.primary.main, theme.palette.mode),
        "&:hover": {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.success.light,
                theme.palette.mode,
            ),
        },
        "&.Mui-selected": {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode,
            ),
            "&:hover": {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.success.light,
                    theme.palette.mode,
                ),
            },
        },
    },
    "& .reporting-odd": {
        backgroundColor: getBackgroundColor(theme.palette.lightBlue.main, theme.palette.mode),
        "&:hover": {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.success.light,
                theme.palette.mode,
            ),
        },
        "&.Mui-selected": {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode,
            ),
            "&:hover": {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.success.light,
                    theme.palette.mode,
                ),
            },
        },
    }
}));

const getBackgroundColor = (color: string, mode: string) => mode === "dark" ? darken(color, 0.8) : lighten(color, 0.8);
const getHoverBackgroundColor = (color: string, mode: string) => mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);
const getSelectedBackgroundColor = (color: string, mode: string) => mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);
const getSelectedHoverBackgroundColor = (color: string, mode: string) => mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);
