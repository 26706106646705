// SettingsCustomerDialogContactList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as contactService from "../../services/contactService";
import { Translations } from "../../models/translations";
import { ToolButton } from "../framework/toolButton";
import { Base } from "../../framework/base";
import { ICustomerEditItemCustomerContactItem } from "../../models/customer/customerEditItemCustomerContactItem";
import { IWorkOrderEditContact } from "../../models/work/workOrderEditContact";
import { WorkOrderDialogSelectContactDialog } from "../work/workOrderDialogSelectContactDialog";
import { SettingsContactDialog } from "./settingsContactDialog";
import { IContactEdit } from "../../models/contact/contactEdit";
import { ContactOperations } from "../../models/contact/contactOperations";

// SettingsCustomerDialogContactListLine
export interface ISettingsCustomerDialogContactListLineProp {
    item: ICustomerEditItemCustomerContactItem;
    selectedId: string;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

export class SettingsCustomerDialogContactListLine extends React.Component<ISettingsCustomerDialogContactListLineProp, {}> {
    render() {
        const item = this.props.item;
        return (
            <div className={"row line" + (item.id === this.props.selectedId ? " selected" : "")} onClick={() => { this.props.onClick(item.id); }} onDoubleClick={() => { this.props.onDoubleClick(item.id); }} title={Base.getStringWithSeparators([item.name, item.phone, item.email, item.comment], "\n")} >
                <div className="col-12">{item.name + (item.phone && ", " + item.phone) + (item.email && ", " + item.email) + (item.comment && ", " + item.comment)}</div>
            </div>
        );
    }
}

// SettingsCustomerDialogContactList
export interface ISettingsCustomerDialogContactListProp {
    title?: string;
    readOnly: boolean;
    items: ICustomerEditItemCustomerContactItem[];
    onRemoveContact: (id: string) => void;
    onAddContact: (contactId: string, title: string, phone: string, email: string, comment: string) => void;
    onEditContact: (contactId: string, title: string, phone: string, email: string, comment: string) => void;
}

interface ISettingsCustomerDialogContactListState {
    selectedId: string;
    showSelectContactDialog: boolean;
    selectContactDialogItems: IWorkOrderEditContact[];
    showContactDialog: boolean;
    contactDialogItem: IContactEdit;
}

export class SettingsCustomerDialogContactList extends React.Component<ISettingsCustomerDialogContactListProp, ISettingsCustomerDialogContactListState> {
    constructor(props) {
        super(props);
        this.state = { selectedId: null, showSelectContactDialog: false, selectContactDialogItems: [], showContactDialog: false, contactDialogItem: null };
    }

    handleAdd = () => {
        if (this.props.readOnly) return;
        const obj = this;
        contactService.getWorkOrderEditContacts(obj.props.items.map(i => i.contactId)).then(workOrderEditContacts => {
            obj.setState({
                showSelectContactDialog: true,
                selectContactDialogItems: workOrderEditContacts.items
            });
        });
    };

    handleSelectContactDialogOk = (contactId: string, name: string, phone: string, email: string, comment: string) => {
        const obj = this;
        if (!contactId) return;
        this.props.onAddContact(contactId, name, phone, email, comment);
        obj.setState({
            showSelectContactDialog: false
        });
    };

    handleSelectContactDialogCancel = () => {
        this.setState({
            showSelectContactDialog: false
        });
    };

    editContact = (id: string) => {
        const obj = this;
        if (this.props.readOnly || !id) return;
        const item = this.props.items.find(i => i.id === id);
        if (Base.isNullOrUndefined(item)) return;
        ContactOperations.getContactEdit(item.contactId)
            .then(editItem => {
                obj.setState({
                    showContactDialog: true,
                    contactDialogItem: editItem
                });
            });
    };

    handleEdit = () => {
        this.editContact(this.state.selectedId);
    };

    handleContactDialogOk = (id: string, name: string, phone: string, email: string, comment: string) => {
        if (!id) return;
        this.props.onEditContact(id, name, phone, email, comment);
        this.setState({
            showContactDialog: false,
            contactDialogItem: null
        });
    };

    handleContactDialogCancel = () => {
        this.setState({
            showContactDialog: false,
            contactDialogItem: null
        });
    };

    handleRemove = () => {
        if (this.props.readOnly) return;
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        this.props.onRemoveContact(selectedId);
    };

    handleLineClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleLineDoubleClick = (id: string) => {
        this.editContact(id);
    };

    render() {
        const props = this.props;
        const state = this.state;
        const items = props.items;
        return (
            <div>
                <div className="commandRow">
                    {!!props.title &&
                        <label className="control-label listTitle">{props.title}</label>
                    }
                    <ToolButton
                        title={Translations.Remove}
                        enabled={!props.readOnly && !!state.selectedId}
                        classes={"round right remove"}
                        onClick={this.handleRemove}
                    />
                    <ToolButton
                        title={Translations.Edit}
                        enabled={!props.readOnly && !!state.selectedId}
                        classes={"round right edit"}
                        onClick={this.handleEdit}
                    />
                    <ToolButton
                        title={Translations.Add}
                        enabled={!props.readOnly}
                        classes={"round right add"}
                        onClick={this.handleAdd}
                    />
                </div>
                <div className="listContainer customerContactsContainer">
                    {items.length > 0 &&
                        <div className="list customerContacts">
                            {items.map((item) =>
                                <SettingsCustomerDialogContactListLine
                                    key={item.id}
                                    item={item}
                                    selectedId={state.selectedId}
                                    onClick={this.handleLineClick}
                                    onDoubleClick={this.handleLineDoubleClick}
                                />
                            )}
                        </div>
                    }
                </div>
                {state.showSelectContactDialog &&
                    <WorkOrderDialogSelectContactDialog
                        selectedId={null}
                        name={""}
                        items={state.selectContactDialogItems}
                        onOk={this.handleSelectContactDialogOk}
                        onCancel={this.handleSelectContactDialogCancel}
                    />
                }
                {state.showContactDialog && !Base.isNullOrUndefined(state.contactDialogItem) &&
                    <SettingsContactDialog
                        customersReadOnly={true}
                        editItem={state.contactDialogItem}
                        onOk={this.handleContactDialogOk}
                        onCancel={this.handleContactDialogCancel}
                    />
                }
            </div>
        );
    }
}