export interface IWorkTimeAccountBalance {
    balance: number;
}

export class WorkTimeAccountBalance implements IWorkTimeAccountBalance {
    balance: number;

    constructor();
    constructor(obj: IWorkTimeAccountBalance);
    constructor(obj?: any) {
        this.balance = obj && obj.balance || 0;
    }
}