import { ApiSuccess } from "./baseService";
import { getApiCall, postFormApiCall, postApiCall } from "./tokenService";
import { IdTitleItems } from "../models/common/idTitleItems";
import { ToolItems } from "../models/tool/toolItems";
import { ToolEdit } from "../models/tool/toolEdit";

export const getToolItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean, showNotInUse: boolean): Promise<ToolItems> => {
    return getApiCall<ToolItems>("api/tool/?page=" + (page || "") + "&pageSize=" + (pageSize || "") +
        "&sortColumn=" + (sortColumn ? encodeURIComponent(sortColumn) : "") + "&sortOrder=" + (sortOrderIsAsc ? "asc" : "desc") +
        "&filter=" + (filter ? encodeURIComponent(filter) : "") + "&showNotInUse=" + (showNotInUse ? "1" : "0"), ToolItems);
};

export const getToolEdit = (id: string): Promise<ToolEdit> => {
    return getApiCall<ToolEdit>("api/tool/edit/?id=" + (id || ""), ToolEdit);
};

export const saveToolEdit = (data: FormData): Promise<ApiSuccess> => {
    return postFormApiCall<ApiSuccess>("api/tool/save", data, ApiSuccess);
};

export const removeTool = (id: string): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/tool/remove/?id=" + (id || ""), ApiSuccess);
};

export const getToolIdTitles = (ignoreIds: string[]): Promise<IdTitleItems> => {
    return postApiCall<IdTitleItems>("api/tool/idtitle", IdTitleItems, JSON.stringify(ignoreIds));
};