import React, { useState } from "react";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Box, IconButton } from "@mui/material";
import {
    GridColDef,
    GridToolbarContainer,
    GridToolbarExport,
    useGridApiContext,
    useGridSelector,
    gridRowsLookupSelector,
    gridDetailPanelExpandedRowIdsSelector,
    gridDetailPanelExpandedRowsContentCacheSelector,
    GridRowId,
    GridGroupNode,
    GRID_ROOT_GROUP_ID,
} from "@mui/x-data-grid-premium";

import { MuiSwitch } from "../muiSwitch";
import { useTranslation } from "react-i18next";
import MuiMenu from "../muiMenu";
import SettingsIcon from "@mui/icons-material/Settings";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

interface CustomToolbarProps {
    hideEmptyColumns: boolean;
    setHideEmptyColumns: (val: boolean) => void;
    columns: GridColDef[];
    emptyColumns: string[];
    hiddenColumns: string[];
    setHiddenColumns: (cols: string[]) => void;
    enableColumnSelector: boolean;
}

export const CustomToolbar = ({
    hideEmptyColumns,
    setHideEmptyColumns,
    columns,
    emptyColumns,
    hiddenColumns,
    setHiddenColumns,
    enableColumnSelector,
}: CustomToolbarProps) => {
    const { t } = useTranslation();

    return (
        <GridToolbarContainer>
            <GridToolbarExport
                csvOptions={{
                    utf8WithBom: true,
                }}
            />
            <ExpandOrCollapseAllButton />
            <Box flexGrow={1} />
            <MuiSwitch
                checked={hideEmptyColumns}
                onChange={() => setHideEmptyColumns(!hideEmptyColumns)}
                label={t("workTime.hideEmptyColumns")}
                size="small"
                labelSlotProps={{ typography: { variant: "subtitle2" } }}
                labelSx={enableColumnSelector ? { mr: 0 } : undefined}
            />
            {enableColumnSelector ? (
                <MuiMenu
                    label={<SettingsIcon />}
                    size="small"
                    keepOpenAfterSelect
                    items={columns
                        .filter(
                            (c) =>
                                !hideEmptyColumns ||
                                !emptyColumns.includes(c.field)
                        )
                        .map((c) => ({
                            label: c.headerName,
                            icon: hiddenColumns.includes(c.field) ? (
                                <CheckBoxOutlineBlankIcon />
                            ) : (
                                <CheckBoxIcon color="primary" />
                            ),
                            onClick: () =>
                                setHiddenColumns(
                                    hiddenColumns.includes(c.field)
                                        ? hiddenColumns.filter(
                                              (hc) => hc !== c.field
                                          )
                                        : [...hiddenColumns, c.field]
                                ),
                        }))}
                />
            ) : null}
        </GridToolbarContainer>
    );
};

const ExpandOrCollapseAllButton = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const apiRef = useGridApiContext();
    const expandedRowIds = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowIdsSelector
    );
    const rowsWithDetailPanels = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector
    );
    const noDetailPanelsOpen = expandedRowIds.length === 0;
    const expandOrCollapseAll = () => {
        const dataRowIdToModelLookup = gridRowsLookupSelector(apiRef);
        const groups =
            apiRef.current.getRowNode<GridGroupNode>(
                GRID_ROOT_GROUP_ID
            )!.children;
        if (!isExpanded) {
            if (groups.length > 0) {
                for (const group of groups) {
                    apiRef.current.setRowChildrenExpansion(group, true);
                }
            }

            const allRowIdsWithDetailPanels: GridRowId[] = Object.keys(
                rowsWithDetailPanels
            ).map((key) =>
                apiRef.current.getRowId(dataRowIdToModelLookup[key])
            );

            apiRef.current.setExpandedDetailPanels(allRowIdsWithDetailPanels);
            setIsExpanded(true);
        } else {
            if (groups.length > 0) {
                for (const group of groups) {
                    apiRef.current.setRowChildrenExpansion(group, false);
                }
            }
            apiRef.current.setExpandedDetailPanels([]);
            setIsExpanded(false);
        }
    };
    const Icon = noDetailPanelsOpen ? UnfoldMoreIcon : UnfoldLessIcon;
    return (
        <IconButton
            size="small"
            tabIndex={-1}
            onClick={expandOrCollapseAll}
            aria-label={noDetailPanelsOpen ? "Expand All" : "Collapse All"}
        >
            <Icon fontSize="inherit" />
        </IconButton>
    );
};
