import { ApiSuccess, ISaveSuccess, SaveSuccess } from "./baseService";
import { getApiCall, postFormApiCall, postApiCall } from "./tokenService";
import { ProductItems } from "../models/product/productItems";
import { ProductEdit } from "../models/product/productEdit";
import { MainGroupType } from "../components/settings/ProductGroupCommon";

export const getProductItems = (
    pageSize: number,
    page: number,
    filter: string,
    sortColumn: string,
    sortOrderIsAsc: boolean,
    showNotInUse: boolean,
    productGroupCode: string = "",
    mainGroup: number = null
): Promise<ProductItems> => {
    return getApiCall<ProductItems>(
        "api/product/?page=" + (page || "") +
        "&pageSize=" + (pageSize || "") +
        "&sortColumn=" + (sortColumn ? encodeURIComponent(sortColumn) : "") +
        "&sortOrder=" + (sortOrderIsAsc ? "asc" : "desc") +
        "&filter=" + (filter ? encodeURIComponent(filter) : "") +
        "&showNotInUse=" + (showNotInUse ? "1" : "0") +
        "&productGroupCode=" + (productGroupCode ? encodeURIComponent(productGroupCode) : "",
        "&mainGroup=" + (mainGroup ? mainGroup: "")), ProductItems);
};

export const getProductEdit = (id: string, mainGroup?: MainGroupType): Promise<ProductEdit> => {
    return getApiCall<ProductEdit>(`api/product/edit/?id=${id}&mainGroup=${mainGroup ?? ""}`, ProductEdit);
};

export const saveProductEdit = (data: FormData): Promise<ISaveSuccess> => {
    return postFormApiCall<ISaveSuccess>("api/product/save", data, SaveSuccess);
};

export const removeProduct = (id: string): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/product/remove/?id=" + (id || ""), ApiSuccess);
};
