import { Base } from "../../framework/base";
import { IUserGroupItem, UserGroupItem } from "./userGroupItem";

export interface IUserGroupItems {
    items: IUserGroupItem[];
    hasMore: boolean;
    page: number;
}

export class UserGroupItems implements IUserGroupItems {
    items: UserGroupItem[];
    hasMore: boolean;
    page: number;

    constructor();
    constructor(obj: IUserGroupItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = Base.getTypedArray<UserGroupItem>(obj ? obj.items : null, UserGroupItem);
    }
}