import { Base } from "../../framework/base";
import { EnumHelper, WorkOrderCategory, SiteType, RecurrenceType } from "../common/enums";
import { IWorkOrderListItemWorkOrderTask, WorkOrderListItemWorkOrderTask } from "./workOrderListItemWorkOrderTask";
import { IWorkOrderListItemEmployee, WorkOrderListItemEmployee } from "./workOrderListItemEmployee";

export interface IWorkOrderListItem {
    id: string;
    number: number;
    parentNumber: number;
    startTime: number;
    endTime: number;
    name: string;
    description: string;
    customerId: string;
    customerName: string;
    siteType: SiteType;
    siteName: string;
    siteNumber: string;
    siteAddress: string;
    siteLongitude: number;
    siteLatitude: number;
    state: number;
    stateStr: string;
    category: WorkOrderCategory;
    recurrenceType: RecurrenceType;
    hasChildren: boolean;
    workOrderTypeId: string;
    workOrderTypeName: string;
    fullDay: boolean;
    canceled: boolean;
    comment: string;
    acknowledgementSent: boolean;
    employees: IWorkOrderListItemEmployee[];
    projectTasks: IWorkOrderListItemWorkOrderTask[];
    invoiceIds: string[];
    vehicleIds: string[];
    hasRoute: boolean;
    hasHourBookings: boolean;
    hasProductBookings: boolean;
    hasRideBookings: boolean;
    hasDocuments: boolean;
    sortOrder: number;

    //Calculated fields
    employeeNames: string;

    isProject(): boolean;
    isWork(): boolean;
    isTask(): boolean;
    isRecurring(): boolean;
    siteHasLocation(): boolean;
    hasLocation(): boolean;
    getLocationHash(): string;
    getEmployeeNames(): string;
}

export class WorkOrderListItem implements IWorkOrderListItem {
    id: string;
    number: number;
    parentNumber: number;
    startTime: number;
    endTime: number;
    description: string;
    name: string;
    customerId: string;
    customerName: string;
    siteType: SiteType;
    siteName: string;
    siteNumber: string;
    siteAddress: string;
    siteLongitude: number;
    siteLatitude: number;
    state: number;
    stateStr: string;
    category: WorkOrderCategory;
    recurrenceType: RecurrenceType;
    hasChildren: boolean;
    workOrderTypeId: string;
    workOrderTypeName: string;
    fullDay: boolean;
    canceled: boolean;
    comment: string;
    acknowledgementSent: boolean;
    employees: WorkOrderListItemEmployee[];
    projectTasks: WorkOrderListItemWorkOrderTask[];
    invoiceIds: string[];
    vehicleIds: string[];
    hasRoute: boolean;
    hasHourBookings: boolean;
    hasProductBookings: boolean;
    hasRideBookings: boolean;
    hasDocuments: boolean;
    sortOrder: number;

    //Calculated fields
    employeeNames: string;

    constructor();
    constructor(obj: IWorkOrderListItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.parentNumber = obj && obj.parentNumber || 0;
        this.startTime = obj && obj.startTime || null;
        this.endTime = obj && obj.endTime || null;
        this.name = obj && obj.name || "";
        this.description = obj && obj.description || "";
        this.customerId = obj && obj.customerId || "";
        this.customerName = obj && obj.customerName || "";
        this.siteType = obj && obj.siteType || 0;
        this.siteName = obj && obj.siteName || "";
        this.siteNumber = obj && obj.siteNumber || "";
        this.siteAddress = obj && obj.siteAddress || "";
        this.siteLongitude = obj && obj.siteLongitude || 0;
        this.siteLatitude = obj && obj.siteLatitude || 0;
        this.state = obj && obj.state || 0;
        this.stateStr = obj && obj.stateStr || "";
        this.category = obj && obj.category || WorkOrderCategory.Work;
        this.recurrenceType = obj && obj.recurrenceType || RecurrenceType.NoRecurrence;
        this.hasChildren = obj && obj.hasChildren || false;
        this.workOrderTypeId = obj && obj.workOrderTypeId || "";
        this.workOrderTypeName = obj && obj.workOrderTypeName || "";
        this.fullDay = obj && obj.fullDay || false;
        this.canceled = obj && obj.canceled || false;
        this.comment = obj && obj.comment || "";
        this.acknowledgementSent = obj && obj.acknowledgementSent || false;
        this.employees = Base.getTypedArray<WorkOrderListItemEmployee>(obj ? obj.employees : null, WorkOrderListItemEmployee);
        this.projectTasks = Base.getTypedArray<WorkOrderListItemWorkOrderTask>(obj ? obj.projectTasks : null, WorkOrderListItemWorkOrderTask);
        this.invoiceIds = obj && obj.invoiceIds || [];
        this.vehicleIds = obj && obj.vehicleIds || [];
        this.hasRoute = obj && obj.hasRoute || false;
        this.hasHourBookings = obj && obj.hasHourBookings || false;
        this.hasProductBookings = obj && obj.hasProductBookings || false;
        this.hasRideBookings = obj && obj.hasRideBookings || false;
        this.hasDocuments = obj && obj.hasDocuments || false;
        //Calculated fields
        this.employeeNames = this.employees.map(i => i.name).join(", ");
    }

    isProject(): boolean {
        return EnumHelper.isEqual(this.category, WorkOrderCategory.Project);
    }

    isWork(): boolean {
        return EnumHelper.isEqual(this.category, WorkOrderCategory.Work);
    }

    isTask(): boolean {
        return EnumHelper.isEqual(this.category, WorkOrderCategory.Task);
    }

    isRecurring(): boolean {
        return !EnumHelper.isEqual(this.recurrenceType, RecurrenceType.NoRecurrence);
    }

    siteHasLocation(): boolean {
        return !EnumHelper.isEqual(this.siteType, SiteType.NoLocation);
    }

    hasLocation(): boolean {
        return !!this.siteLatitude && !!this.siteLongitude;
    }

    getLocationHash(): string {
        return (this.siteLatitude ? this.siteLatitude.toFixed(6) : "") + "," + (this.siteLongitude ? this.siteLongitude.toFixed(6) : "");
    }

    getEmployeeNames(): string {
        return this.employees.map(i => i.name).join(", ");
    }

    static sortWorkOrderListItems(items: IWorkOrderListItem[], column: string, asc: boolean) {
        if (Base.isNullOrUndefined(items) || items.length < 2) return;
        let sortFunc: (a: IWorkOrderListItem, b: IWorkOrderListItem) => number;
        if (column === "startTime") sortFunc = (a: IWorkOrderListItem, b: IWorkOrderListItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.startTime, b.startTime)); };
        else if (column === "endTime") sortFunc = (a: IWorkOrderListItem, b: IWorkOrderListItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.endTime, b.endTime)); };
        else if (column === "name") sortFunc = (a: IWorkOrderListItem, b: IWorkOrderListItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.name, b.name)); };
        else if (column === "description") sortFunc = (a: IWorkOrderListItem, b: IWorkOrderListItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.description, b.description)); };
        else if (column === "customerName") sortFunc = (a: IWorkOrderListItem, b: IWorkOrderListItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.customerName, b.customerName)); };
        else if (column === "siteName") sortFunc = (a: IWorkOrderListItem, b: IWorkOrderListItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.siteName, b.siteName)); };
        else if (column === "siteAddress") sortFunc = (a: IWorkOrderListItem, b: IWorkOrderListItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.siteAddress, b.siteAddress)); };
        else if (column === "employeeName") sortFunc = (a: IWorkOrderListItem, b: IWorkOrderListItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.employeeNames, b.employeeNames)); };
        else if (column === "state") sortFunc = (a: IWorkOrderListItem, b: IWorkOrderListItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.state, b.state)); };
        else if (column === "category") sortFunc = (a: IWorkOrderListItem, b: IWorkOrderListItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.category, b.category)); };
        else if (column === "workOrderTypeName") sortFunc = (a: IWorkOrderListItem, b: IWorkOrderListItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.workOrderTypeName, b.workOrderTypeName)); };
        else sortFunc = (a: IWorkOrderListItem, b: IWorkOrderListItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.number, b.number)); };
        items.sort(sortFunc);
    }
}
