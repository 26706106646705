import { arc as d3_arc, pie as d3_pie, PieArcDatum } from "d3-shape";
import { create } from "d3-selection";

interface ChartData {
    color: string;
    name: string;
    value: number;
}

interface ChartOptions {
    data: ChartData[];
    width: number;
    showTotalCount?: boolean;
    totalCount?: number;
}

export function donutChart({
    data,
    width,
    totalCount,
    showTotalCount = false,
}: ChartOptions) {
    const height = Math.min(width, 500);
    const radius = Math.min(width, height) / 2;
    const innerRadius = radius * 0.67;
    const outerRadius = radius - 1;

    const arc = d3_arc<PieArcDatum<ChartData>>()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius);

    const pie = d3_pie<ChartData>()
        .padAngle(1 / radius)
        .sort(null)
        .value((d) => d.value);

    const svg = create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [-width / 2, -height / 2, width, height])
        .attr("style", "max-width: 100%; height: auto;");

    // Add a circle to fill the center of the donut
    svg.append("circle")
        .attr("cx", 0)
        .attr("cy", 0)
        .attr("r", outerRadius) // Matches the inner radius of the donut
        .attr("fill", "#fff");

    if (showTotalCount) {
        // Add the bolded number inside the donut
        svg.append("text")
            .attr("x", 0)
            .attr("y", 0)
            .attr("text-anchor", "middle") // Centers the text horizontally
            .attr("dominant-baseline", "middle") // Centers the text vertically
            .attr("font-size", radius * 0.6) // Adjust size based on radius
            .attr("font-weight", "bold")
            .attr("fill", "#000")
            .text(totalCount);
    }

    svg.append("g")
        .selectAll()
        .data(pie(data))
        .join("path")
        .attr("fill", (d) => d.data.color)
        .attr("d", arc);

    return svg.node();
}
