import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../framework/customStore";
import {
    clearConfirmation,
    setConfirmation,
} from "../models/store/storeActions";
import { ConfirmationDialogType } from "../models/store/storeTypes";
import { Translations } from "../models/translations";
import { UnregisterCallback } from "history";

enum Answer {
    Yes = "yes",
    No = "no",
    Cancel = "cancel",
}

// Block navigation and prompt user before leaving the page.
export function usePrompt(
    when: boolean,
    onYes: () => Promise<boolean>,
    dialogType = ConfirmationDialogType.Warning,
    title = Translations.Warning,
    text = Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges
) {
    const [showPrompt, setShowPrompt] = useState<boolean>(false);
    const [answer, setAnswer] = useState<Answer | null>();
    const history = useHistory();
    const dispatch = useAppDispatch();

    const pathname = useRef<string>();
    const unblockRef = useRef<UnregisterCallback>();

    const unregisterBlock = () => {
        unblockRef.current && unblockRef.current();
    };

    const goToPath = () => {
        unregisterBlock();
        history.push(pathname.current);
    };

    const handleOK = async () => {
        const success = await onYes();

        if (success) {
            goToPath();
        }
    };

    const handleNo = () => {
        goToPath();
    };

    const showDialog = () => {
        dispatch(
            setConfirmation(
                dialogType,
                title,
                text,
                () => {
                    setAnswer(Answer.Yes);
                },
                () => {
                    setAnswer(Answer.No);
                },
                () => {
                    setAnswer(Answer.Cancel);
                }
            )
        );
    };

    // Block react-router navigation
    useEffect(() => {
        if (when) {
            unblockRef.current = history.block((prompt) => {
                // Don't block when user has been logged out.
                if (prompt.pathname !== "/login") {
                    setShowPrompt(true);
                    pathname.current = prompt.pathname;
                    return false;
                }
            });
        } else {
            unregisterBlock();
        }

        return unregisterBlock;
    }, [history, when]);

    useEffect(() => {
        if (showPrompt) {
            showDialog();
        } else {
            dispatch(clearConfirmation());
        }
    }, [showPrompt]);

    useEffect(() => {
        if (!!answer) {
            if (answer === Answer.Yes) {
                handleOK();
            } else if (answer === Answer.No) {
                handleNo();
            }
            setShowPrompt(false);
            setAnswer(null);
        }
    }, [answer]);
}
