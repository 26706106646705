import { ICustomerItem, CustomerItem } from "./customerItem";
import { Base } from "../../framework/base";

export interface ICustomerItems {
    items: ICustomerItem[];
    hasMore: boolean;
    page: number;
}

export class CustomerItems implements ICustomerItems {
    items: ICustomerItem[];
    hasMore: boolean;
    page: number;

    constructor();
    constructor(obj: ICustomerItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = Base.getTypedArray<CustomerItem>(obj ? obj.items : null, CustomerItem);
    }
}