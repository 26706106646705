import { Box, Typography } from "@mui/material";
import React from "react";

interface ITransportOrderInvoiceSumRowProps {
    title: string;
    value: number;
}

export const TransportOrderInvoiceSumRow = ({ title, value }: ITransportOrderInvoiceSumRowProps) => {
    return (
        <Box
            style={{
                marginTop: "10px",
                backgroundColor: "#f0f0f0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px",
                borderRadius: "8px",
            }}
        >
            <Typography variant="h6" color="primary.dark">
                {title}
            </Typography>
            <Typography variant="h6" color="primary.dark">
                {value.toFixed(2)}
            </Typography>
        </Box>
    );
};
