// SettingsCompetencyDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as baseService from "../../services/baseService";
import * as storeActions from "../../models/store/storeActions";
import * as competencyService from "../../services/competencyService";
import { PropertyDialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { SaveData } from "../../framework/saveData";
import { CheckBox } from "../framework/checkbox";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { ICompetencyEdit } from "../../models/competency/competencyEdit";

// SettingsCompetencyDialog
// ***********************************************************************************************************************
export interface ISettingsCompetencyDialogProp {
    classes?: string;
    customersReadOnly?: boolean;
    editItem: ICompetencyEdit;
    onOk: (id: string, competencyTypeId: string, name: string, description: string) => void;
    onCancel: () => void;
}

export interface ISettingsCompetencyDialogState {
    competencyTypeId: string;
    name: string;
    description: string;
    activeState: number;
}

export class SettingsCompetencyDialog extends React.Component<ISettingsCompetencyDialogProp, ISettingsCompetencyDialogState> {
    private static orgStateHash: string = "";

    constructor(props) {
        super(props);
        const competency = props.editItem.competency;
        this.state = { competencyTypeId: competency.competencyTypeId, name: competency.name, description: competency.description, activeState: competency.activeState };
        const saveData = SettingsCompetencyDialog.getSaveDataFromState(props, this.state);
        SettingsCompetencyDialog.orgStateHash = saveData.hash;
    }

    // #region General
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "competencyTypeId") {
            this.setState({ competencyTypeId: value });
        } else if (name === "Nm") {
            this.setState({ name: value });
        } else if (name === "description") {
            this.setState({ description: value });
        }
    };

    handleActiveStateChange = (newActiveState: number) => {
        this.setState({ activeState: newActiveState });
    };
    // #endregion General

    private static validate = (state: ISettingsCompetencyDialogState): boolean => {
        if (!state.name) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.NameMustBeDefined));
            return false;
        }
        return true;
    };

    private static getSaveDataFromState = (props: ISettingsCompetencyDialogProp, state: ISettingsCompetencyDialogState): SaveData => {
        const data = new SaveData();
        const competency = props.editItem.competency;
        // Common
        data.append("id", competency.id);
        data.append("rowId", competency.rowId);
        // General
        data.append("competencyTypeId", state.competencyTypeId);
        data.append("name", state.name);
        data.append("description", state.description);
        data.append("activeState", state.activeState.toString(10));
        return data;
    };

    saveCompetency = () => {
        const obj = this;
        const props = this.props;
        const state = this.state;
        if (!SettingsCompetencyDialog.validate(state)) return;
        const saveData = SettingsCompetencyDialog.getSaveDataFromState(props, state);
        if (Base.isNullOrUndefined(saveData)) return;
        // Call server
        store.customStore.dispatch(storeActions.fetchStart());
        competencyService.saveCompetencyEdit(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                obj.props.onOk(props.editItem.competency.id, state.competencyTypeId, state.name, state.description);
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleOkClick = () => {
        this.saveCompetency();
    };

    handleCancelClick = () => {
        const obj = this;
        const saveData = SettingsCompetencyDialog.getSaveDataFromState(this.props, this.state);
        if (!Base.isNullOrUndefined(saveData) && saveData.hash !== SettingsCompetencyDialog.orgStateHash) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.saveCompetency();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.props.onCancel();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));
        } else {
            obj.props.onCancel();
        }
    };

    render() {
        const props = this.props;
        const editItem = props.editItem;
        const dialogClasses = "settings competency px800" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.Competency + " - " + (!editItem.competency.isNew() ? editItem.competency.number.toString(10) + " " + editItem.competency.name : Translations.New)}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-2">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.Number}</label>
                                    <input type="text" className="form-control" name="number" title={Translations.Number} value={editItem.competency.isNew() ? Translations.New : editItem.competency.number.toString(10)} readOnly={true} disabled={true} />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.CompetencyType}</label>
                                    <select className="form-control" name="competencyTypeId" title={Translations.CompetencyType} value={this.state.competencyTypeId} onChange={this.handleChange}>
                                        {props.editItem.competencyTypes.map((competencyType) =>
                                            <option key={competencyType.id} value={competencyType.id}>{competencyType.title}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="col-5">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.Name}</label>
                                    <input type="text" className="form-control" name="Nm" title={Translations.Name} value={this.state.name} onChange={this.handleChange} maxLength={50} />
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="form-group">
                                    <label className="control-label smallFont">&nbsp;</label>
                                    <div>
                                        <CheckBox
                                            title={Translations.InUse}
                                            enabled={true}
                                            checked={this.state.activeState > 0}
                                            onCheckboxClickBoolean={(checked: boolean) => { this.handleActiveStateChange(checked ? 1 : 0); }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Description}</label>
                                    <textarea className="form-control" name="description" title={Translations.Description} value={this.state.description} onChange={this.handleChange} maxLength={1000} />
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: this.handleOkClick },
                    ]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}