//SettingsProductGroupDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as baseService from "../../services/baseService";
import * as storeActions from "../../models/store/storeActions";
import { PropertyDialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { SaveData } from "../../framework/saveData";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { IProductGroupEdit } from "../../models/ProductGroup/ProductGroupEdit";
import * as productGroupService from "../../services/productGroupService";
import { ProductItem } from "../../models/product/productItem";
import { SettingsProductGroupDialogProductList } from "./settingsProductGroupDialogProductList";
import { Autocomplete, IAutocompleteItem } from "../framework/autocomplete";
import { MainGroups, ProductMainGroup } from "./ProductGroupCommon";

// settingsProductGroupDialog
// ***********************************************************************************************************************
export interface ISettingsProductGroupDialogProp {
    classes?: string;
    editItem: IProductGroupEdit;
    onOk: () => void;
    onCancel: () => void;
}

export interface ISettingsProductGroupDialogState {
    products: ProductItem[];
    productId: string;
    name: string;
    code: string;
    mainGroups: ProductMainGroup[];
    selectedMainGroup: ProductMainGroup;
}


export class SettingsProductGroupDialog extends React.Component<ISettingsProductGroupDialogProp, ISettingsProductGroupDialogState> {
    private static orgStateHash: string = "";

    constructor(props: ISettingsProductGroupDialogProp) {
        super(props);
        const productGroup = props.editItem.productGroup;
        const products = props.editItem.products;
        const mainGroups = [
            MainGroups.common,
            MainGroups.invoicing,
            MainGroups.storage,
            MainGroups.extranet
        ];
        const selected = mainGroups[mainGroups.findIndex(m => m.databaseId === productGroup.mainGroup)];
        this.state = {
            products: products,
            productId: productGroup.id,
            name: productGroup.name,
            code: productGroup.code,
            selectedMainGroup: selected,
            mainGroups: mainGroups,
        };
        const saveData = SettingsProductGroupDialog.getSaveDataFromState(props, this.state);
        SettingsProductGroupDialog.orgStateHash = saveData.hash;
    }

    // #region General
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "nm") {
            this.setState({ name: value });
        } else if (name === "code") {
            this.setState({ code: value });
        } else if (name === "productId") {
            this.setState({ productId: value });
        }
    };

    private handleAutocompleteChange(value: any): void {
        if (value && value.length > 0) {
            const index = this.state.mainGroups.findIndex(m => m.id === value[0]);
            this.setState({ selectedMainGroup: this.state.mainGroups[index] });
        } else {
            // trying fill combobox value again after user has push open button.
            // this seems not work - open button cleans selected item and want that
            // value fill again - which is not wanted feature. It looks line set value
            // needs user click - so probably autocomplete component bug.
            if (this.state.selectedMainGroup) {
                this.setState({ selectedMainGroup: this.state.selectedMainGroup });
            } else {
                this.setState({ selectedMainGroup: this.state.mainGroups[0] });
            }
        }
    }

    // #endregion General

    private static validate = (state: ISettingsProductGroupDialogState): boolean => {
        if (!state.name) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.NameMustBeDefined));
            return false;
        }
        return true;
    };

    private static getSaveDataFromState = (props: ISettingsProductGroupDialogProp, state: ISettingsProductGroupDialogState): SaveData => {
        const data = new SaveData();
        const ProductGroup = props.editItem.productGroup;
        // Common
        data.append("id", ProductGroup.id);
        data.append("rowId", ProductGroup.rowId);
        // General
        data.append("name", state.name);
        data.append("code", state.code);
        data.append("mainGroup", state.selectedMainGroup.databaseId.toString());
        return data;
    };

    saveEditItem = () => {
        const obj = this;
        if (!SettingsProductGroupDialog.validate(this.state)) return;
        const saveData = SettingsProductGroupDialog.getSaveDataFromState(this.props, this.state);
        if (!saveData) return;
        // Call server
        store.customStore.dispatch(storeActions.fetchStart());
        productGroupService.saveProductGroupEdit(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                obj.props.onOk();
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleOkClick = () => {
        this.saveEditItem();
    };

    handleCancelClick = () => {
        const obj = this;
        const saveData = SettingsProductGroupDialog.getSaveDataFromState(this.props, this.state);
        if (!Base.isNullOrUndefined(saveData) && saveData.hash !== SettingsProductGroupDialog.orgStateHash) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.saveEditItem();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.props.onCancel();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));
        } else {
            obj.props.onCancel();
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "settings ProductGroup px800" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.ProductGroup }
                    show={true}
                    body={<div>
                        <div className="">
                            <div>
                                <div>
                                    <div className="row">
                                        <div className="col-7">
                                            <div className="form-group required">
                                                <label className="control-label smallFont">{Translations.Code}</label>
                                                <input type="text" className="form-control" name="code" title={Translations.Code} value={state.code} onChange={this.handleChange} maxLength={50} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group required">
                                                <label className="control-label smallFont">{Translations.Name}</label>
                                                <input type="text" className="form-control" name="nm" title={Translations.Name} value={state.name} onChange={this.handleChange} maxLength={50} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group required">
                                                <label className="control-label smallFont">{"Periytyy pääryhmästä"}</label>
                                                <Autocomplete
                                                    items={state.mainGroups}
                                                    selectedItems={[state.selectedMainGroup]}
                                                    onChange={(value: any) => this.handleAutocompleteChange(value) }// contains database ids in this case
                                                    editDisabled={true}
                                                    doNotClear={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {state.products.length > 0 &&
                                        <SettingsProductGroupDialogProductList
                                            title={Translations.Products}
                                            items={state.products}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: this.handleOkClick },
                    ]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}
