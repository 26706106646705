import { IWorkOrderTypeEditItem, WorkOrderTypeEditItem } from "./workOrderTypeEditItem";
import { IWorkShiftTimeSlotType, WorkShiftTimeSlotType } from "../workShiftTimeSlotType/workShiftTimeSlotType";
import { Base } from "../../framework/base";
import { IProductItem, ProductItem } from "../product/productItem";

export interface IWorkOrderTypeEdit {
    workOrderType: IWorkOrderTypeEditItem;
    workShiftTimeSlotTypes: IWorkShiftTimeSlotType[];
    products: IProductItem[];
}

export class WorkOrderTypeEdit implements IWorkOrderTypeEdit {
    workOrderType: WorkOrderTypeEditItem;
    workShiftTimeSlotTypes: WorkShiftTimeSlotType[];
    products: ProductItem[];

    constructor();
    constructor(obj: IWorkOrderTypeEdit);
    constructor(obj?: any) {
        this.workOrderType = new WorkOrderTypeEditItem(obj ? obj.workOrderType : null);
        this.workShiftTimeSlotTypes = Base.getTypedArray<WorkShiftTimeSlotType>(obj ? obj.workShiftTimeSlotTypes : null, WorkShiftTimeSlotType);
        this.products = Base.getTypedArray<ProductItem>(!Base.isNullOrUndefined(obj) ? obj.products : null, ProductItem);
        this.workOrderType.setSortValues(this.workShiftTimeSlotTypes);
    }
}