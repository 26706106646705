import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as serviceWorkerRegistration from "../../serviceWorkerRegistration";
import {
    AlertType,
    AppUpdateState,
    addAlert,
    setAppUpdate,
} from "../../store/appSlice";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";

export const ServiceWorkerHandler = () => {
    const update = useAppSelector((state) => state.app.update);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const location = useLocation();

    useEffect(() => {
        if (update === AppUpdateState.Pending) {
            // Force reload on navigation
            window.location.reload();
        }
    }, [location.pathname]);

    useEffect(() => {
        if (update === AppUpdateState.Confirmed) {
            // Reload after confirm
            window.location.reload();
        }
    }, [update]);

    const onUpdate = () => {
        // Set to update to pending to trigger update on navigation.
        dispatch(setAppUpdate(AppUpdateState.Pending));

        // Display a notification or prompt the user to update the application
        dispatch(
            addAlert({
                message: t("common.updateAvailable"),
                duration: null,
                type: AlertType.AppUpdate,
                dialog: true,
            })
        );
    };

    useEffect(() => {
        serviceWorkerRegistration.register({ onUpdate });
    }, []);

    return null;
};
