export interface ICollectiveAgreementItem {
    id: string;
    name: string;
}

export class CollectiveAgreementItem implements ICollectiveAgreementItem {
    id: string;
    name: string;

    constructor();
    constructor(obj: ICollectiveAgreementItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
    }
}
