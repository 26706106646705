import React, { useMemo } from "react";
import { formatTime, formatWorkTime } from "../../salaries/salariesListGrid";
import { GridRow, WorkSlotItem } from "./employeesVehicleGrid";
import { Translations } from "../../../models/translations";
import { StrMeasureUnit } from "../../../models/common/enums";
import { useTranslation } from "react-i18next";
import {
    LinkToEmployeeWorkTimeDetails,
    ReportingDetailsTable,
} from "./reportingDetailsTable";

interface EmployeeDetailsRow {
    row: GridRow;
}

interface IWorkSlotItemUnique extends WorkSlotItem {
    rowId: number;
}

export const EmployeeDetailsTable = (props: EmployeeDetailsRow) => {
    const { t } = useTranslation();

    const { vehicles, workSlotItems, costCenters, workTimeTypes } = props.row;

    const workShifts = workSlotItems.map((wh, i) => {
        return { ...wh, rowId: i } as IWorkSlotItemUnique;
    });

    const columns = useMemo(
        () => [
            {
                header: t("vehicle.startTime"),
                renderCell: (row: IWorkSlotItemUnique) =>
                    LinkToEmployeeWorkTimeDetails(
                        row.employeeId,
                        row.dateStr,
                        formatTime(row.startTime, row.dateStr)
                    ),
            },
            {
                header: t("vehicle.endTime"),
                renderCell: (row: IWorkSlotItemUnique) =>
                    LinkToEmployeeWorkTimeDetails(
                        row.employeeId,
                        row.dateStr,
                        formatTime(row.endTime, row.dateStr)
                    ),
            },
            {
                header: t("vehicle.amount"),
                renderCell: (row: IWorkSlotItemUnique) =>
                    formatWorkTime(row?.amount, StrMeasureUnit.HourFi, 2),
            },
            {
                header: t("workTime.costCenter"),
                renderCell: (row: IWorkSlotItemUnique) =>
                    costCenters.find((c) => c?.id === row?.costCenterId)
                        ?.name ?? "-",
            },
            {
                header: t("workTime.workTimeType"),
                renderCell: (row: IWorkSlotItemUnique) =>
                    workTimeTypes.find(
                        (wt) => wt?.workTimeTypeId === row?.workTimeTypeId
                    )?.name ?? "-",
            },
            {
                header: Translations.Vehicle,
                renderCell: (row: IWorkSlotItemUnique) =>
                    vehicles?.find((wt) => wt?.id === row?.vehicleId)
                        ?.registerNumber ?? "-",
            },
            {
                header: t("vehicle.comment"),
                renderCell: (row: IWorkSlotItemUnique) => row.comment || "-",
            },
        ],
        [costCenters, workTimeTypes, vehicles]
    );

    return <ReportingDetailsTable columns={columns} rows={workShifts} />;
};
