import { IProductItem } from "../product/productItem";
import { Base } from "../../framework/base";
import { ICustomerItem } from "../customer/customerItem";

export interface ICustomerPriceEditItem {
    id: string;
    productId: string;
    customerId: string;
    unitPrice: number;
    rowId: string;

    sortValue: string;
    isNew(): boolean;
}

export class CustomerPriceEditItem implements ICustomerPriceEditItem {
    id: string;
    productId: string;
    customerId: string;
    unitPrice: number;
    rowId: string;
    sortValue: string;

    constructor();
    constructor(obj: ICustomerPriceEditItem);
    constructor(obj?: any) {
        this.id = obj?.id ?? "";
        this.productId = obj?.productId ?? "";
        this.customerId = obj?.customerId ?? "";
        this.unitPrice = obj?.unitPrice ?? 0;
        this.rowId = obj?.rowId ?? "";
        this.sortValue = obj?.sortValue ?? "";
    }

    isNew(): boolean {
        return !this.rowId;
    }

    static setSortValuesByProduct(items: ICustomerPriceEditItem[], products: IProductItem[]) {
        if (!items || items.length < 2 || !products || products.length < 1) return;
        const groups = Base.groupArray<ICustomerPriceEditItem>(items, "productId");
        Object.keys(groups).forEach(productId => {
            const customerPrices = groups[productId];
            const product = products.find(i => i.id === productId);
            if (!product) return;
            for (const customerPrice of customerPrices) {
                customerPrice.sortValue = product.name;
            }
        });
    }

    static setSortValuesByCustomer(items: ICustomerPriceEditItem[], customers: ICustomerItem[]) {
        if (!items || items.length < 2 || !customers || customers.length < 1) return;
        const groups = Base.groupArray<ICustomerPriceEditItem>(items, "customerId");
        Object.keys(groups).forEach(customerId => {
            const customerPrices = groups[customerId];
            const customer = customers.find(i => i.id === customerId);
            if (!customer) return;
            for (const customerPrice of customerPrices) {
                customerPrice.sortValue = customer.name;
            }
        });
    }

    static sortCustomerPriceEditItems(items: ICustomerPriceEditItem[], asc: boolean) {
        if (!items || items.length < 2) return;
        const sortFunc = (a: ICustomerPriceEditItem, b: ICustomerPriceEditItem) => { return (!a || !b) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.sortValue, b.sortValue)); };
        items.sort(sortFunc);
    }
}

export class SaveCustomerPriceEditItem {
    id: string;
    productId: string;
    customerId: string;
    unitPrice: number;
    rowId: string;

    constructor();
    constructor(obj: ICustomerPriceEditItem);
    constructor(obj?: any) {
        this.id = obj?.id ?? "";
        this.productId = obj?.productId ?? "";
        this.customerId = obj?.customerId ?? "";
        this.unitPrice = obj?.unitPrice ?? 0;
        this.rowId = obj?.rowId ?? "";
    }
}