// OwnerSettingsSalaryPeriodTypeDialogSalaryPeriodList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as salaryPeriodService from "../../services/salaryPeriodService";
import { Translations } from "../../models/translations";
import { PropertyDialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { SaveData } from "../../framework/saveData";
import { ToolButton } from "../framework/toolButton";
import { ISalaryWorkTimePeriodItem, SalaryWorkTimePeriodItem, SaveSalaryWorkTimePeriodItem } from "../../models/salaryPeriod/salaryWorkTimePeriodItem";
import { DatePicker } from "../framework/datePicker";
import { AppUtils } from "../../models/common/appUtils";
import { ConfirmationDialogResult } from "../../models/common/enums";
import { ButtonDropdown, IButtonDropdownAction } from "../framework/dropdown";
import moment from "moment";

// OwnerSettingsSalaryPeriodTypeDialogSalaryPeriodDialog
// ***********************************************************************************************************************
export interface IOwnerSettingsSalaryPeriodTypeDialogSalaryPeriodDialogProp {
    classes?: string;
    isReadOnly: boolean;
    salaryPeriod: ISalaryWorkTimePeriodItem;
    onOk: (startDate: string, endDate: string) => void;
    onCancel: () => void;
}

interface IOwnerSettingsSalaryPeriodTypeDialogSalaryPeriodDialogState {
    startDateStr: string;
    endDateStr: string;
}

export class OwnerSettingsSalaryPeriodTypeDialogSalaryPeriodDialog extends React.Component<IOwnerSettingsSalaryPeriodTypeDialogSalaryPeriodDialogProp, IOwnerSettingsSalaryPeriodTypeDialogSalaryPeriodDialogState> {
    private orgStateHash = "";

    constructor(props: IOwnerSettingsSalaryPeriodTypeDialogSalaryPeriodDialogProp) {
        super(props);
        const salaryPeriod = props.salaryPeriod;
        this.state = {
            startDateStr: salaryPeriod.startDate ? Base.utcTimeToDateStr(new Date(salaryPeriod.startDate).getTime()) : "",
            endDateStr: salaryPeriod.endDate ? Base.utcTimeToDateStr(new Date(salaryPeriod.endDate).addDays(-1).getTime()) : "",
        };
        const saveData = OwnerSettingsSalaryPeriodTypeDialogSalaryPeriodDialog.getSaveDataFromState(props, this.state);
        this.orgStateHash = saveData.hash;
    }

    handleStartDateChange = (value: string) => {
        this.setState({ startDateStr: value });
    };

    handleEndDateChange = (value: string) => {
        this.setState({ endDateStr: value });
    };

    private checkErrors = (): Promise<boolean> => {
        const state = this.state;
        return new Promise<boolean>((resolve) => {
            //Add error checking in here
            if (!state.startDateStr) {
                AppUtils.showErrorMessage(Translations.StartDateMustBeDefined);
                return resolve(false);
            }
            if (!state.endDateStr) {
                AppUtils.showErrorMessage(Translations.EndDateMustBeDefined);
                return resolve(false);
            }
            if (state.startDateStr.toUtcDate() > state.endDateStr.toUtcDate()) {
                AppUtils.showErrorMessage(Translations.InvalidEndDate);
                return resolve(false);
            }
            return resolve(true);
        });
    };

    private getWarningMessage = (): Promise<string> => {
        return new Promise<string>((resolve) => {
            //let warnings = "";
            //Add warning checking in here
            //if (!state.someField) {
            //    warnings = warnings + Translations.SomeFieldIsNotDefined + Base.lf;
            //}
            //return resolve(warnings);
            return resolve("");
        });
    };

    private static getSaveDataFromState = (props: IOwnerSettingsSalaryPeriodTypeDialogSalaryPeriodDialogProp, state: IOwnerSettingsSalaryPeriodTypeDialogSalaryPeriodDialogState): SaveData => {
        const data = new SaveData();
        const salaryPeriod = props.salaryPeriod;
        // Common - Data that does not change
        data.append("id", salaryPeriod.id);
        data.append("rowId", salaryPeriod.rowId);
        // General - Data that changes
        data.append("startDateStr", state.startDateStr);
        data.append("endDateStr", state.endDateStr);
        return data;
    };

    saveEditItem = async() => {
        const props = this.props;
        const state = this.state;
        if (!await AppUtils.validate(this.checkErrors, this.getWarningMessage)) return;
        props.onOk(moment(state.startDateStr, "D.M.YYYY").format("YYYY-MM-DD"), moment(state.endDateStr, "D.M.YYYY").add(1, "day").format("YYYY-MM-DD"));
    };

    handleOkClick = async() => {
        this.saveEditItem();
    };

    handleCancelClick = async() => {
        const props = this.props;
        const state = this.state;
        const cancelResult = await AppUtils.cancel(OwnerSettingsSalaryPeriodTypeDialogSalaryPeriodDialog.getSaveDataFromState(props, state).hash, this.orgStateHash);
        if (cancelResult === ConfirmationDialogResult.Cancel) return;
        if (cancelResult === ConfirmationDialogResult.Yes) {
            this.saveEditItem();
        } else {
            props.onCancel();
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        const salaryPeriod = props.salaryPeriod;
        const dialogClasses = "salaryPeriod px400" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.SalaryPeriod + " - " + (!salaryPeriod.isNew() ? (state.startDateStr && state.endDateStr ? state.startDateStr + "-" + state.endDateStr : "") : Translations.New)}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.StartTime}</label>
                                    <DatePicker
                                        required={true}
                                        disabled={props.isReadOnly}
                                        value={state.startDateStr}
                                        onChange={this.handleStartDateChange}
                                        onBlur={this.handleStartDateChange}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.EndDate}</label>
                                    <DatePicker
                                        required={true}
                                        disabled={props.isReadOnly}
                                        value={state.endDateStr}
                                        onChange={this.handleEndDateChange}
                                        onBlur={this.handleEndDateChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[{ title: Translations.Save, classes: "btn-primary", enabled: !props.isReadOnly, onClick: this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}

// OwnerSettingsSalaryPeriodTypeDialogSalaryPeriodSeriesDialog
// ***********************************************************************************************************************
/* eslint-disable no-unused-vars */
export const enum SalaryPeriodLength {
    Months1,
    Months2,
    Weeks1,
    Weeks2,
    Weeks3,
    Weeks4,
    Weeks5,
    Weeks6,
    Weeks7,
    Weeks8,
    Weeks9
}
/* eslint-enable no-unused-vars */

export interface IOwnerSettingsSalaryPeriodTypeDialogAddSalaryPeriodsDialogProp {
    classes?: string;
    onOk: (salaryPeriods: ISalaryWorkTimePeriodItem[]) => void;
    onCancel: () => void;
}

interface IOwnerSettingsSalaryPeriodTypeDialogAddSalaryPeriodsDialogState {
    startDateStr: string;
    endDateStr: string;
    periodLength: SalaryPeriodLength;
}

export class OwnerSettingsSalaryPeriodTypeDialogAddSalaryPeriodsDialog extends React.Component<IOwnerSettingsSalaryPeriodTypeDialogAddSalaryPeriodsDialogProp, IOwnerSettingsSalaryPeriodTypeDialogAddSalaryPeriodsDialogState> {
    constructor(props: IOwnerSettingsSalaryPeriodTypeDialogAddSalaryPeriodsDialogProp) {
        super(props);
        const startDate = Base.getFirstDayOfWeek(Base.getNowDate());
        this.state = {
            startDateStr: Base.timeToDateStr(startDate.getTime()),
            endDateStr: "",
            periodLength: SalaryPeriodLength.Months1
        };
    }

    handleStartDateChange = (value: string) => {
        this.setState({ startDateStr: value });
    };

    handleEndDateChange = (value: string) => {
        this.setState({ endDateStr: value });
    };

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "periodLength") {
            this.setState({ periodLength: value.toInteger() });
        }
    };

    private checkErrors = (): Promise<boolean> => {
        const state = this.state;
        return new Promise<boolean>((resolve) => {
            //Add error checking in here
            if (!state.startDateStr) {
                AppUtils.showErrorMessage(Translations.StartDateMustBeDefined);
                return resolve(false);
            }
            if (!state.endDateStr) {
                AppUtils.showErrorMessage(Translations.EndDateMustBeDefined);
                return resolve(false);
            }
            if (state.startDateStr.toUtcDate().addDays(7) >= state.endDateStr.toUtcDate()) {
                AppUtils.showErrorMessage(Translations.InvalidEndDate);
                return resolve(false);
            }
            return resolve(true);
        });
    };

    private getWarningMessage = (): Promise<string> => {
        return new Promise<string>((resolve) => {
            //let warnings = "";
            //Add warning checking in here
            //if (!state.someField) {
            //    warnings = warnings + Translations.SomeFieldIsNotDefined + Base.lf;
            //}
            //return resolve(warnings);
            return resolve("");
        });
    };

    handleOkClick = async() => {
        const props = this.props;
        const state = this.state;
        if (!await AppUtils.validate(this.checkErrors, this.getWarningMessage)) return;
        const salaryPeriods: ISalaryWorkTimePeriodItem[] = [];
        let periodStartDate = moment(state.startDateStr, "D.M.YYYY").toDate();
        const endDate = state.endDateStr.toUtcDate();
        while (periodStartDate < endDate) {
            const periodEndDate = state.periodLength >= SalaryPeriodLength.Weeks1 &&
                state.periodLength <= SalaryPeriodLength.Weeks9
                ? periodStartDate.addDays((state.periodLength - SalaryPeriodLength.Weeks1 + 1) * 7)
                : periodStartDate.addMonths(state.periodLength - SalaryPeriodLength.Months1 + 1);
            const salaryPeriod = new SalaryWorkTimePeriodItem();
            salaryPeriod.id = Base.getGuid();
            salaryPeriod.startDate = moment(periodStartDate).format("YYYY-MM-DD");
            salaryPeriod.endDate = moment(periodEndDate).format("YYYY-MM-DD");
            salaryPeriods.push(salaryPeriod);
            periodStartDate = periodEndDate;
        }
        props.onOk(salaryPeriods);
    };

    handleCancelClick = async() => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "salaryPeriodSeries px400" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.AddSalaryPeriods}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.StartTime}</label>
                                    <DatePicker
                                        required={true}
                                        value={state.startDateStr}
                                        onChange={this.handleStartDateChange}
                                        onBlur={this.handleStartDateChange}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.PeriodLength}</label>
                                    <select className="custom-select" name="periodLength" title={Translations.PeriodLength} value={state.periodLength.toString(10)} onChange={this.handleChange}>
                                        <option value={SalaryPeriodLength.Months1.toString(10)}>{"1 " + Translations.Month}</option>
                                        <option value={SalaryPeriodLength.Months2.toString(10)}>{String.format(Translations.ParameterMonths, 2)}</option>
                                        <option value={SalaryPeriodLength.Weeks1.toString(10)}>{"1 " + Translations.Week}</option>
                                        <option value={SalaryPeriodLength.Weeks2.toString(10)}>{String.format(Translations.ParameterWeeks, 2)}</option>
                                        <option value={SalaryPeriodLength.Weeks3.toString(10)}>{String.format(Translations.ParameterWeeks, 3)}</option>
                                        <option value={SalaryPeriodLength.Weeks4.toString(10)}>{String.format(Translations.ParameterWeeks, 4)}</option>
                                        <option value={SalaryPeriodLength.Weeks5.toString(10)}>{String.format(Translations.ParameterWeeks, 5)}</option>
                                        <option value={SalaryPeriodLength.Weeks6.toString(10)}>{String.format(Translations.ParameterWeeks, 6)}</option>
                                        <option value={SalaryPeriodLength.Weeks7.toString(10)}>{String.format(Translations.ParameterWeeks, 7)}</option>
                                        <option value={SalaryPeriodLength.Weeks8.toString(10)}>{String.format(Translations.ParameterWeeks, 8)}</option>
                                        <option value={SalaryPeriodLength.Weeks9.toString(10)}>{String.format(Translations.ParameterWeeks, 9)}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.EndDate}</label>
                                    <DatePicker
                                        required={true}
                                        value={state.endDateStr}
                                        onChange={this.handleEndDateChange}
                                        onBlur={this.handleEndDateChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[{ title: Translations.Add, classes: "btn-primary", enabled: true, onClick: this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}

// OwnerSettingsSalaryPeriodTypeDialogSalaryPeriodListLine
export interface IOwnerSettingsSalaryPeriodTypeDialogSalaryPeriodListLineProp {
    salaryPeriod: ISalaryWorkTimePeriodItem;
    selectedId: string;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

export class OwnerSettingsSalaryPeriodTypeDialogSalaryPeriodListLine extends React.Component<IOwnerSettingsSalaryPeriodTypeDialogSalaryPeriodListLineProp, {}> {
    render() {
        const props = this.props;
        const salaryPeriod = props.salaryPeriod;
        const localeDateStringOptions: Intl.DateTimeFormatOptions = { dateStyle: "short", timeZone: "Europe/Helsinki" };
        return (
            <div className={"row line" + (salaryPeriod.id === props.selectedId ? " selected" : "")} onClick={() => { props.onClick(salaryPeriod.id); }} onDoubleClick={() => { props.onDoubleClick(salaryPeriod.id); }} >
                <div className="col-6">{new Date(salaryPeriod.startDate).toLocaleDateString("fi", localeDateStringOptions)}</div>
                <div className="col-6">{new Date(salaryPeriod.endDate).addDays(-1).toLocaleDateString("fi", localeDateStringOptions)}</div>
            </div>
        );
    }
}

// OwnerSettingsSalaryPeriodTypeDialogSalaryPeriodList
export interface IOwnerSettingsSalaryPeriodTypeDialogSalaryPeriodListProp {
    titleId?: string;
    titleClass?: string;
    isReadOnly: boolean;
    salaryPeriodTypeId: string;
    salaryPeriods: ISalaryWorkTimePeriodItem[];
    onModified: (salaryPeriods: ISalaryWorkTimePeriodItem[]) => void;
    onRemoved: (id: string) => void;
}

interface IOwnerSettingsSalaryPeriodTypeDialogSalaryPeriodListState {
    selectedId: string;
    editDialogItem: ISalaryWorkTimePeriodItem;
    showEditDialog: boolean;
    showAddPeriodsDialog: boolean;
    addActions: IButtonDropdownAction[];
}

export class OwnerSettingsSalaryPeriodTypeDialogSalaryPeriodList extends React.Component<IOwnerSettingsSalaryPeriodTypeDialogSalaryPeriodListProp, IOwnerSettingsSalaryPeriodTypeDialogSalaryPeriodListState> {
    constructor(props: IOwnerSettingsSalaryPeriodTypeDialogSalaryPeriodListProp) {
        super(props);
        this.state = {
            selectedId: null,
            editDialogItem: null,
            showEditDialog: false,
            showAddPeriodsDialog: false,
            addActions: [
                { title: Translations.AddSalaryPeriod, onClick: this.handleAdd },
                { title: Translations.AddSalaryPeriods, onClick: this.handleAddMultiple }
            ]
        };
    }

    edit = (salaryPeriod: ISalaryWorkTimePeriodItem) => {
        if (!salaryPeriod) return;
        this.setState({
            editDialogItem: salaryPeriod,
            showEditDialog: true,
        });
    };

    handleAdd = () => {
        if (this.props.isReadOnly) return;
        const editDialogItem = new SalaryWorkTimePeriodItem();
        editDialogItem.id = Base.getGuid();
        editDialogItem.startDate = null;
        editDialogItem.endDate = null;
        this.edit(editDialogItem);
    };

    handleAddMultiple = () => {
        if (this.props.isReadOnly) return;
        this.setState({
            showAddPeriodsDialog: true
        });
    };

    private getSaveData = (salaryPeriods: ISalaryWorkTimePeriodItem[]): SaveData => {
        const saveData = new SaveData();
        saveData.append("id", this.props.salaryPeriodTypeId);
        saveData.append("salaryPeriods", JSON.stringify(salaryPeriods.reduce((result, i) => {
            result.push(new SaveSalaryWorkTimePeriodItem(i));
            return result;
        }, [])));
        return saveData;
    };

    save = async(salaryPeriod: ISalaryWorkTimePeriodItem) => {
        const result = await AppUtils.callService(() => salaryPeriodService.saveSalaryPeriods(this.getSaveData([salaryPeriod]).formData));
        if (!result) return;
        this.setState({
            selectedId: result.ids[0],
            showEditDialog: false
        });
        salaryPeriod.id = result.ids[0];
        salaryPeriod.rowId = result.rowIds[0];
        this.props.onModified([salaryPeriod]);
    };

    handleOwnerSettingsSalaryPeriodTypeDialogSalaryPeriodDialogOk = (startDate: string, endDate: string) => {
        if (this.props.isReadOnly) return;
        const editDialogItem = this.state.editDialogItem;
        if (!editDialogItem) return;
        editDialogItem.startDate = startDate;
        editDialogItem.endDate = endDate;
        this.save(editDialogItem);
    };

    handleOwnerSettingsSalaryPeriodTypeDialogSalaryPeriodDialogCancel = () => {
        this.setState({
            showEditDialog: false
        });
    };

    handleOwnerSettingsSalaryPeriodTypeDialogAddSalaryPeriodsDialogOk = async(salaryPeriods: ISalaryWorkTimePeriodItem[]) => {
        if (this.props.isReadOnly || salaryPeriods.length < 1) return;
        const result = await AppUtils.callService(() => salaryPeriodService.saveSalaryPeriods(this.getSaveData(salaryPeriods).formData));
        if (!result) return;
        for (let i = 0; i < salaryPeriods.length; i++) {
            salaryPeriods[i].id = result.ids[i];
            salaryPeriods[i].rowId = result.rowIds[i];
        }
        this.setState({
            selectedId: result.ids[0],
            showAddPeriodsDialog: false
        });
        this.props.onModified(salaryPeriods);
    };

    handleOwnerSettingsSalaryPeriodTypeDialogAddSalaryPeriodsDialogCancel = () => {
        this.setState({
            showAddPeriodsDialog: false
        });
    };

    remove = async(id: string) => {
        const props = this.props;
        //Create savedata
        const salaryPeriodIds = [id];
        const saveData = new SaveData();
        saveData.append("id", props.salaryPeriodTypeId);
        saveData.append("salaryPeriodIds", JSON.stringify(salaryPeriodIds));
        //Save to db
        const result = await AppUtils.callService(() => salaryPeriodService.removeSalaryPeriods(saveData.formData));
        if (!result) return;
        props.onRemoved(id);
    };

    handleEdit = () => {
        const props = this.props;
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        this.edit(props.salaryPeriods.find(i => i.id === selectedId));
    };

    handleRemove = () => {
        if (this.props.isReadOnly) return;
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        this.remove(selectedId);
    };

    handleLineClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleLineDoubleClick = (id: string) => {
        this.handleEdit();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const salaryPeriods = props.salaryPeriods;
        return (
            <div className="salaryPeriodTypeSubList">
                <div className="commandRow">
                    <label id={props.titleId} className={"control-label listTitle" + (props.titleClass ? " " + props.titleClass : "")}>{Translations.SalaryPeriods}</label>
                    {!props.isReadOnly &&
                        <ToolButton
                            title={Translations.Remove}
                            enabled={!!state.selectedId}
                            classes={"round right remove"}
                            onClick={this.handleRemove}
                        />
                    }
                    <ToolButton
                        title={Translations.Edit}
                        enabled={!!state.selectedId}
                        classes={"round right edit"}
                        onClick={this.handleEdit}
                    />
                    {!props.isReadOnly &&
                        <ButtonDropdown
                            classes={"right"}
                            buttonClasses={"btn btn-tool round add"}
                            buttonIconClasses={"add"}
                            menuRight={true}
                            removeDefaultButtonClasses={true}
                            tooltip={Translations.Add}
                            actions={state.addActions}
                        />
                    //    <ToolButton
                    //        title={Translations.Add}
                    //        enabled={true}
                    //        classes={"round right add"}
                    //        onClick={this.handleAdd}
                    //    />
                    }
                </div>
                <div className="listContainer salaryPeriodTypeSalaryPeriodsContainer">
                    {salaryPeriods.length > 0 &&
                        <div className="list salaryPeriods">
                            {salaryPeriods.map((salaryPeriod) =>
                                <OwnerSettingsSalaryPeriodTypeDialogSalaryPeriodListLine
                                    key={salaryPeriod.id}
                                    salaryPeriod={salaryPeriod}
                                    selectedId={state.selectedId}
                                    onClick={this.handleLineClick}
                                    onDoubleClick={this.handleLineDoubleClick}
                                />
                            )}
                        </div>
                    }
                </div>
                {state.showEditDialog &&
                    <OwnerSettingsSalaryPeriodTypeDialogSalaryPeriodDialog
                        isReadOnly={props.isReadOnly}
                        salaryPeriod={state.editDialogItem}
                        onOk={this.handleOwnerSettingsSalaryPeriodTypeDialogSalaryPeriodDialogOk}
                        onCancel={this.handleOwnerSettingsSalaryPeriodTypeDialogSalaryPeriodDialogCancel}
                    />
                }
                {state.showAddPeriodsDialog &&
                    <OwnerSettingsSalaryPeriodTypeDialogAddSalaryPeriodsDialog
                        onOk={this.handleOwnerSettingsSalaryPeriodTypeDialogAddSalaryPeriodsDialogOk}
                        onCancel={this.handleOwnerSettingsSalaryPeriodTypeDialogAddSalaryPeriodsDialogCancel}
                    />
                }
            </div>
        );
    }
}
