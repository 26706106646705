import { WorkTimeAccountBalance } from "../models/workTimeAccount/workTimeAccountBalance";
import { WorkTimeAccountTransactions } from "../models/workTimeAccount/workTimeAccountTransactions";
import { SaveSuccess } from "./baseService";
import { getApiCall, postFormApiCall } from "./tokenService";

export const getWorkTimeAccountBalance = (employeeId: string): Promise<WorkTimeAccountBalance> => {
    return getApiCall<WorkTimeAccountBalance>("api/worktimeaccount/balance/?employeeId=" + (employeeId || ""), WorkTimeAccountBalance);
};

export const getWorkTimeAccountTransactions = (employeeId: string): Promise<WorkTimeAccountTransactions> => {
    return getApiCall<WorkTimeAccountTransactions>("api/worktimeaccount/transactions/?employeeId=" + (employeeId || ""), WorkTimeAccountTransactions);
};

export const saveWorkTimeAccountTransaction = (data: FormData): Promise<SaveSuccess> => {
    return postFormApiCall<SaveSuccess>("api/worktimeaccount", data, SaveSuccess);
};