// WorkPageMain
import * as React from "react";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as salaryService from "../../services/salaryService";
import * as workTimeService from "../../services/workTimeService";
import { RouteComponentProps } from "react-router-dom";
import { handleApiError } from "../../models/store/storeEffects";
import { Translations } from "../../models/translations";
import { ICalendarDayItem } from "../../models/calendarDay/calendarDayItem";
import { IHourBookingCategoryItem } from "../../models/hourBookingType/hourBookingTypeItem";
import { ISalaryRowTypeItem } from "../../models/salary/salaryRowTypeItem";
import { SalaryMain } from "./salaryMain";
import { SalaryTransferMain } from "./salaryTransferMain";
import { ReportType, TimeFormat } from "../../models/common/enums";
import { IEmployeeParameters } from "../../models/employee/employeeIParameters";
import { ISalaryWorkTimePeriod, SalaryWorkTimePeriod } from "../../models/salary/salaryWorkTimePeriod";
import { Base } from "../../framework/base";
import { IEmployeeGroupItem } from "../../models/employeeGroup/employeeGroupItem";
import { IEmployeeItem } from "../../models/employee/employeeItem";
import { IOwnerState, IUserState } from "../../models/store/storeTypes";
import { IReportListItem, ReportListItem } from "../../models/report/reportListItem";
import { ReportOperations } from "../../models/report/reportOperations";
import { ReportView } from "../report/reportView";
import { IEmployeeEdit } from "../../models/employee/employeeEdit";
import { SettingsEmployeeDialog } from "../settings/settingsEmployeeDialog";
import { INumberTitle } from "../../models/common/numberTitle";
import { EmployeeOperations } from "../../models/employee/employeeOperations";
import { ErrorBoundary } from "../../framework/errorBoundary";

//WorkTimePageMainEmployeeGroups
interface IWorkTimePageMainEmployeeGroupsProps {
    employeeGroups: IEmployeeGroupItem[];
    selectedEmployeeGroup: IEmployeeGroupItem;
    selectedEmployeeGroupEmployees: IEmployeeItem[];
    selectedEmployee: IEmployeeItem;
    onChangeEmployee: (employeeId: string) => void;
    onChangeEmployeeGroup: (employeeGroupId: string) => void;
}

export class WorkTimePageMainEmployeeGroups extends React.Component<IWorkTimePageMainEmployeeGroupsProps, {}> {
    render() {
        const props = this.props;
        return (
            <div className="sub">
                {props.employeeGroups.map((employeeGroup) =>
                    <div key={employeeGroup.id}>
                        <div className={"menuItem" + (props.selectedEmployeeGroup && props.selectedEmployeeGroup.id === employeeGroup.id ? " active" : "")} onClick={() => props.onChangeEmployeeGroup(employeeGroup.id)}>{employeeGroup.name}</div>
                        {props.selectedEmployeeGroup && props.selectedEmployeeGroup.id === employeeGroup.id
                            //<div className="sub">
                            //    {props.selectedEmployeeGroupEmployees.map((employee) =>
                            //        <div key={employee.id} className={"menuItem" + (props.selectedEmployee && props.selectedEmployee.id === employee.id ? " active" : "")} onClick={() => props.onChangeEmployee(employee.id)}>
                            //            {employee.name}
                            //        </div>
                            //    )}
                            //</div>
                        }
                    </div>
                )}
            </div>
        );
    }
}

//WorkTimePageMain
/* eslint-disable no-unused-vars */
export const enum WorkTimePageMainSubPage {
    Salaries = "2",
    Transfer = "3",
    Reports = "4",
}
/* eslint-enable no-unused-vars */

export interface IWorkTimePageMainOwnProps {
}

export interface IWorkTimePageMainStateProps {
    owner: IOwnerState;
    user: IUserState;
}

export interface IWorkTimePageMainDispatchProps {
    setEmployeeParameters: (parameters: IEmployeeParameters, saveToDb: boolean) => void;
    onLogout: () => void;
}

export interface IWorkTimePageMainPathProps {
    page: WorkTimePageMainSubPage;
    state: string;
}

type WorkTimePageMainProp = IWorkTimePageMainOwnProps & IWorkTimePageMainStateProps & IWorkTimePageMainDispatchProps & RouteComponentProps<IWorkTimePageMainPathProps>;

interface IWorkTimePageMainState {
    page: WorkTimePageMainSubPage;
    workTimeFormat: TimeFormat;
    employeeGroups: IEmployeeGroupItem[];
    employees: IEmployeeItem[];
    calendarDays: ICalendarDayItem[];
    hourBookingTypes: IHourBookingCategoryItem[];
    dayBookingTypes: INumberTitle[];
    employee: IEmployeeItem;
    employeeGroup: IEmployeeGroupItem;
    employeeGroupEmployees: IEmployeeItem[];
    //Employee
    employeeEdit: IEmployeeEdit;
    showEmployeeDialog: boolean;
    //WorkHours
    salaryPeriod: ISalaryWorkTimePeriod;
    salaryPeriods: ISalaryWorkTimePeriod[];
    //Salary
    salaryRowTypes: ISalaryRowTypeItem[];
    salaryTransferEnabled: boolean;
    //Reports
    reportFilter: string;
    reportItems: IReportListItem[];
    selectedReportId: string;
    selectedReport: IReportListItem;
    isLoading: boolean;
}

export class WorkTimePageMain extends React.Component<WorkTimePageMainProp, IWorkTimePageMainState> {
    private salaryMainRef = React.createRef<SalaryMain>();

    getSalaryPeriodByEmployeeGroup = (salaryPeriods: ISalaryWorkTimePeriod[], employeeGroup: IEmployeeGroupItem): ISalaryWorkTimePeriod => {
        return employeeGroup
            ? salaryPeriods.find(i => i.periodTypeId === employeeGroup.salaryPeriodTypeId)
            : new SalaryWorkTimePeriod();
    };

    constructor(props: WorkTimePageMainProp) {
        super(props);
        this.state = {
            page: WorkTimePageMainSubPage.Salaries,
            employee: null,
            employeeGroup: null,
            employeeGroupEmployees: [],
            workTimeFormat: TimeFormat.DecimalFormat,
            employeeGroups: [],
            employees: [],
            calendarDays: [],
            hourBookingTypes: [],
            dayBookingTypes: [],
            employeeEdit: null,
            showEmployeeDialog: false,
            salaryPeriod: new SalaryWorkTimePeriod(),
            salaryPeriods: [],
            salaryRowTypes: [],
            salaryTransferEnabled: false,
            reportFilter: "",
            reportItems: [],
            selectedReportId: "",
            selectedReport: null,
            isLoading: false
        };
    }

    componentDidCatch(error, errorInfo) {
        console.log({ error, errorInfo });
    }

    componentDidMount(): void {
        this.getWorkPageMain();
        this.getReportListItems(this.state.reportFilter);
    }

    getWorkPageMain = (): Promise<void> => {
        const obj = this;
        obj.setState({
            isLoading: true
        });
        store.customStore.dispatch(storeActions.fetchStart());
        return workTimeService.getWorkTimePageMain()
            .then(
                workTimePageMain => {
                    const usedEmployeeGroupIds = workTimePageMain.employees.map(i => i.salaryEmployeeGroupId);
                    const usedEmployeeGroups = workTimePageMain.employeeGroups.filter(i => usedEmployeeGroupIds.indexOf(i.id) > -1);
                    const employeeGroup = usedEmployeeGroups.length > 0
                        ? usedEmployeeGroups[0]
                        : null;
                    const employeeGroupEmployees = employeeGroup ? workTimePageMain.employees.filter(i => i.salaryEmployeeGroupId === employeeGroup.id) : [];
                    obj.setState({
                        workTimeFormat: workTimePageMain.workTimeFormat,
                        salaryPeriod: obj.getSalaryPeriodByEmployeeGroup(workTimePageMain.salaryPeriods, employeeGroup),
                        salaryPeriods: workTimePageMain.salaryPeriods,
                        employeeGroups: usedEmployeeGroups,
                        employees: workTimePageMain.employees,
                        calendarDays: workTimePageMain.calendarDays,
                        hourBookingTypes: workTimePageMain.hourBookingTypes,
                        dayBookingTypes: workTimePageMain.dayBookingTypes,
                        salaryRowTypes: workTimePageMain.salaryRowTypes,
                        salaryTransferEnabled: workTimePageMain.salaryTransferEnabled,
                        employeeGroup: employeeGroup,
                        employeeGroupEmployees: employeeGroupEmployees,
                        employee: null
                    });
                },
                error => {
                    handleApiError(error, store.customStore.dispatch);
                })
            .finally(() => {
                obj.setState({
                    isLoading: false
                });
                store.customStore.dispatch(storeActions.fetchEnd());
            });
    };

    cancelView = (proceedCallback: () => void) => {
        if (this.salaryMainRef && this.salaryMainRef.current) {
            this.salaryMainRef.current.cancelSalary(proceedCallback);
        } else {
            proceedCallback();
        }
    };

    handleChangeEmployee = (employeeId: string) => {
        const obj = this;
        this.cancelView(() => {
            const employee = obj.state.employees.find(i => i.id === employeeId);
            obj.setState({ employee: employee });
        });
    };

    handleChangeEmployeeGroup = (employeeGroupId: string) => {
        const obj = this;
        this.cancelView(() => {
            const state = obj.state;
            const employeeGroup = state.employeeGroups.find(i => i.id === employeeGroupId);
            const employeeGroupEmployees = employeeGroup ? state.employees.filter(i => i.salaryEmployeeGroupId === employeeGroup.id) : [];
            obj.setState({
                employeeGroup: employeeGroup,
                employeeGroupEmployees: employeeGroupEmployees,
                employee: null,
                salaryPeriod: obj.getSalaryPeriodByEmployeeGroup(state.salaryPeriods, employeeGroup)
            });
        });
    };

    handleChangeSalaryPeriod = (salaryPeriodId: string) => {
        if (salaryPeriodId === Base.emptyGuid) return;
        store.customStore.dispatch(storeActions.fetchStart());
        return salaryService.getSalaryPeriod(salaryPeriodId)
            .then(
                salaryPeriod => {
                    const state = this.state;
                    const salaryPeriods = state.salaryPeriods.filter(i => i.id !== state.salaryPeriod.id);
                    salaryPeriods.push(salaryPeriod);
                    this.setState({
                        salaryPeriod: salaryPeriod,
                        salaryPeriods: salaryPeriods
                    });
                },
                error => {
                    handleApiError(error, store.customStore.dispatch);
                })
            .finally(() => {
                this.setState({
                    isLoading: false
                });
                store.customStore.dispatch(storeActions.fetchEnd());
            });
    };

    handleMenuItemClick = (page: WorkTimePageMainSubPage) => {
        const obj = this;
        this.cancelView(() => {
            obj.setState({ page: page });
        });
    };

    // #region Employee Edit
    refreshView = () => {
        if (this.salaryMainRef && this.salaryMainRef.current) {
            this.salaryMainRef.current.refreshView();
        }
    };

    resfreshEmployeesAndGroups = (): Promise<void> => {
        const obj = this;
        obj.setState({
            isLoading: true
        });
        let employee = this.state.employee;
        let employeeGroup = this.state.employeeGroup;
        store.customStore.dispatch(storeActions.fetchStart());
        return workTimeService.getWorkTimePageMainEmployeesAndGroups()
            .then(
                workTimePageMainEmployeesAndGroups => {
                    const usedEmployeeGroupIds = workTimePageMainEmployeesAndGroups.employees.map(i => i.salaryEmployeeGroupId);
                    const usedEmployeeGroups = workTimePageMainEmployeesAndGroups.employeeGroups.filter(i => usedEmployeeGroupIds.indexOf(i.id) > -1);
                    if (employee) {
                        employee = workTimePageMainEmployeesAndGroups.employees.find(i => i.id === employee.id);
                        employeeGroup = usedEmployeeGroups.find(i => i.id === employee.salaryEmployeeGroupId);
                    } else if (employeeGroup) {
                        employeeGroup = usedEmployeeGroups.find(i => i.id === employeeGroup.id);
                    }
                    if (!employee && !employeeGroup) {
                        employeeGroup = usedEmployeeGroups.length > 0
                            ? usedEmployeeGroups[0]
                            : null;
                    }
                    const employeeGroupEmployees = employeeGroup ? workTimePageMainEmployeesAndGroups.employees.filter(i => i.salaryEmployeeGroupId === employeeGroup.id) : [];
                    obj.setState({
                        employeeGroups: usedEmployeeGroups,
                        employees: workTimePageMainEmployeesAndGroups.employees,
                        employee: employee,
                        employeeGroup: employeeGroup,
                        employeeGroupEmployees: employeeGroupEmployees,
                        salaryPeriod: obj.getSalaryPeriodByEmployeeGroup(obj.state.salaryPeriods, employeeGroup)
                    });
                    window.setTimeout(() => {
                        obj.refreshView();
                    }, 50);
                },
                error => {
                    handleApiError(error, store.customStore.dispatch);
                })
            .finally(() => {
                obj.setState({
                    isLoading: false
                });
                store.customStore.dispatch(storeActions.fetchEnd());
            });
    };

    handleEditEmployee = (employeeId: string) => {
        const obj = this;
        this.cancelView(() => {
            EmployeeOperations.getEmployeeEdit(employeeId)
                .then(employeeEdit => {
                    obj.setState({
                        employeeEdit: employeeEdit,
                        showEmployeeDialog: true,
                    });
                });
        });
    };

    handleEmployeeDialogOk = () => {
        this.setState({
            showEmployeeDialog: false
        });
        this.resfreshEmployeesAndGroups();
    };

    handleEmployeeDialogCancel = (hasBeenChanged: boolean) => {
        this.setState({
            showEmployeeDialog: false
        });
        if (!hasBeenChanged) return;
        this.resfreshEmployeesAndGroups();
    };
    // #endregion Employee Edit

    // #region Report
    getReportListItems = (filter: string) => {
        const obj = this;
        const state = this.state;
        ReportOperations.getReportListItems(state.reportItems, state.selectedReportId, ReportType.WorkTime, filter, () => { obj.setState({ isLoading: true }); }, () => { obj.setState({ isLoading: false }); })
            .then(result => {
                if (!result) return;
                obj.setState({
                    reportFilter: result.filter,
                    reportItems: result.reportItems,
                    selectedReportId: result.selectedReportId
                });
            });
    };

    handleReportLineClick = (id: string) => {
        if (!id) return;
        const selected = this.state.reportItems.find(i => i.id === id);
        this.setState({
            selectedReportId: id,
            selectedReport: new ReportListItem(selected)
        });
    };

    handleChangeReportParameterValue = (name: string, value: string) => {
        const selected = this.state.selectedReport;
        if (!selected) return;
        const parameter = selected.parameters.find(i => i.name === name);
        if (!parameter) return;
        parameter.value = value;
        this.setState({
            selectedReport: new ReportListItem(selected),
        });
    };
    // #endregion Report

    render() {
        const props = this.props;
        const state = this.state;

        return (
            <div>
                <ErrorBoundary>
                    <div className="row mainContent">
                        <div className="col-2">
                            <div className="backgroundBoard fullHeight">
                                <div className="panel verticalMenu">
                                    <div className={"menuItem" + (state.page === WorkTimePageMainSubPage.Salaries ? " active" : "")} onClick={() => { this.handleMenuItemClick(WorkTimePageMainSubPage.Salaries); }}>{Translations.Salaries}</div>
                                    {state.page === WorkTimePageMainSubPage.Salaries &&
                                        <WorkTimePageMainEmployeeGroups
                                            employeeGroups={state.employeeGroups}
                                            selectedEmployeeGroup={state.employeeGroup}
                                            selectedEmployeeGroupEmployees={state.employeeGroupEmployees}
                                            selectedEmployee={state.employee}
                                            onChangeEmployee={this.handleChangeEmployee}
                                            onChangeEmployeeGroup={this.handleChangeEmployeeGroup}
                                        />
                                    }
                                    <div className={"menuItem" + (state.page === WorkTimePageMainSubPage.Transfer ? " active" : "")} onClick={() => { this.handleMenuItemClick(WorkTimePageMainSubPage.Transfer); }}>{Translations.SalaryDatas}</div>
                                    {state.page === WorkTimePageMainSubPage.Transfer &&
                                        <WorkTimePageMainEmployeeGroups
                                            employeeGroups={state.employeeGroups}
                                            selectedEmployeeGroup={state.employeeGroup}
                                            selectedEmployeeGroupEmployees={state.employeeGroupEmployees}
                                            selectedEmployee={state.employee}
                                            onChangeEmployee={this.handleChangeEmployee}
                                            onChangeEmployeeGroup={this.handleChangeEmployeeGroup}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-10 ">
                            <div className="panel">
                                {state.page === WorkTimePageMainSubPage.Salaries &&
                                    <SalaryMain
                                        ref={this.salaryMainRef}
                                        employeeGroup={state.employee ? null : state.employeeGroup}
                                        employees={state.employee ? [state.employee] : state.employeeGroupEmployees}
                                        salaryPeriod={state.salaryPeriod}
                                        salaryRowTypes={state.salaryRowTypes}
                                        salaryTransferEnabled={state.salaryTransferEnabled}
                                        onChangeEmployee={this.handleChangeEmployee}
                                        onChangeSalaryPeriod={this.handleChangeSalaryPeriod}
                                    />
                                }
                                {state.page === WorkTimePageMainSubPage.Transfer &&
                                    <SalaryTransferMain
                                        employeeGroup={state.employeeGroup}
                                        employees={state.employeeGroupEmployees}
                                        salaryPeriod={state.salaryPeriod}
                                        onChangeSalaryPeriod={this.handleChangeSalaryPeriod}
                                    />
                                }
                                {state.page === WorkTimePageMainSubPage.Reports &&
                                    <ReportView
                                        reportType={ReportType.WorkTime}
                                        selected={state.selectedReport}
                                        onChangeParameterValue={this.handleChangeReportParameterValue}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    {state.showEmployeeDialog &&
                        <SettingsEmployeeDialog
                            employeeEdit={state.employeeEdit}
                            onOk={this.handleEmployeeDialogOk}
                            onCancel={this.handleEmployeeDialogCancel}
                            setEmployeeParameters={props.setEmployeeParameters}
                        />
                    }
                </ErrorBoundary>
            </div>
        );
    }
}
