// OwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import * as workOrderTypeService from "../../services/workOrderTypeService";
import { Translations } from "../../models/translations";
import { PropertyDialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { IdTitle } from "../../models/common/idTitle";
import { IWorkOrderTypeEditItemWorkShiftTimeSlotType, WorkOrderTypeEditItemSaveWorkShiftTimeSlotType, WorkOrderTypeEditItemWorkShiftTimeSlotType } from "../../models/workOrderType/workOrderTypeEditItemWorkShiftTimeSlotType";
import { IWorkShiftTimeSlotType } from "../../models/workShiftTimeSlotType/workShiftTimeSlotType";
import { IProductItem } from "../../models/product/productItem";
import { SaveData } from "../../framework/saveData";
import { ToolButton } from "../framework/toolButton";

// OwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotDialog
// ***********************************************************************************************************************
export interface IOwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotDialogProp {
    classes?: string;
    isReadOnly: boolean;
    workOrderTypeWorkShiftTimeSlotType: IWorkOrderTypeEditItemWorkShiftTimeSlotType;
    workShiftTimeSlotTypes: IWorkShiftTimeSlotType[];
    products: IProductItem[];
    usedWorkShiftTimeSlotTypeIds: string[];
    onOk: (workShiftTimeSlotTypeId: string, productId: string) => void;
    onCancel: () => void;
}

interface IOwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotDialogState {
    workShiftTimeSlotTypes: IWorkShiftTimeSlotType[];
    workShiftTimeSlotTypeId: string;
    productId: string;
}

export class OwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotDialog extends React.Component<IOwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotDialogProp, IOwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotDialogState> {
    constructor(props: IOwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotDialogProp) {
        super(props);
        const workOrderTypeWorkShiftTimeSlotType = props.workOrderTypeWorkShiftTimeSlotType;
        this.state = {
            workShiftTimeSlotTypes: props.workShiftTimeSlotTypes.filter(i => props.usedWorkShiftTimeSlotTypeIds.indexOf(i.id) < 0 || i.id === workOrderTypeWorkShiftTimeSlotType.workShiftTimeSlotTypeId),
            workShiftTimeSlotTypeId: workOrderTypeWorkShiftTimeSlotType.workShiftTimeSlotTypeId,
            productId: workOrderTypeWorkShiftTimeSlotType.productId,
        };
    }

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "workShiftTimeSlotTypeId") {
            this.setState({ workShiftTimeSlotTypeId: value });
        } else if (name === "productId") {
            this.setState({ productId: value });
        }
    };

    handleOkClick = () => {
        const state = this.state;
        this.props.onOk(state.workShiftTimeSlotTypeId, state.productId);
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "workOrderTypeWorkShiftTimeSlotType px400" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.RoutePointWorkShiftTimeSlotType + " - " + (!props.workOrderTypeWorkShiftTimeSlotType.isNew() ? IdTitle.getTitleById(state.workShiftTimeSlotTypes, state.workShiftTimeSlotTypeId) : Translations.New)}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group required">
                                    <label className="control-label">{Translations.RoutePointWorkShiftTimeSlotType}</label>
                                    <select className="custom-select" name="workShiftTimeSlotTypeId" title={Translations.RoutePointWorkShiftTimeSlotType} value={state.workShiftTimeSlotTypeId} disabled={props.isReadOnly} onChange={this.handleChange} autoFocus={true}>
                                        {state.workShiftTimeSlotTypes.map((workShiftTimeSlotType) =>
                                            <option key={workShiftTimeSlotType.id} value={workShiftTimeSlotType.id}>{workShiftTimeSlotType.getTitle()}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="control-label">{Translations.Product}</label>
                                    <select className="custom-select" name="productId" title={Translations.Product} value={state.productId} disabled={props.isReadOnly} onChange={this.handleChange}>
                                        <option key="noinuse" value="">{"'" + Translations.NotSelected + "'"}</option>
                                        {props.products.map((product) =>
                                            <option key={product.id} value={product.id}>{product.name}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[{ title: Translations.Save, classes: "btn-primary", enabled: !props.isReadOnly, onClick: this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}

// OwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotListLine
export interface IOwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotListLineProp {
    workOrderTypeWorkShiftTimeSlotType: IWorkOrderTypeEditItemWorkShiftTimeSlotType;
    workShiftTimeSlotTypes: IWorkShiftTimeSlotType[];
    products: IProductItem[];
    selectedId: string;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

export class OwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotListLine extends React.Component<IOwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotListLineProp, {}> {
    render() {
        const props = this.props;
        const workOrderTypeWorkShiftTimeSlotType = props.workOrderTypeWorkShiftTimeSlotType;
        return (
            <div className={"row line" + (workOrderTypeWorkShiftTimeSlotType.id === props.selectedId ? " selected" : "")} onClick={() => { props.onClick(workOrderTypeWorkShiftTimeSlotType.id); }} onDoubleClick={() => { props.onDoubleClick(workOrderTypeWorkShiftTimeSlotType.id); }}>
                <div className="col-6">{IdTitle.getTitleById(props.workShiftTimeSlotTypes, workOrderTypeWorkShiftTimeSlotType.workShiftTimeSlotTypeId)}</div>
                <div className="col-6">{IdTitle.getTitleById(props.products, workOrderTypeWorkShiftTimeSlotType.productId)}</div>
            </div>
        );
    }
}

// OwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotList
export interface IOwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotListProp {
    titleId?: string;
    titleClass?: string;
    isReadOnly: boolean;
    workOrderTypeId: string;
    workOrderTypeWorkShiftTimeSlotTypes: IWorkOrderTypeEditItemWorkShiftTimeSlotType[];
    workShiftTimeSlotTypes: IWorkShiftTimeSlotType[];
    products: IProductItem[];
    onModified: (workOrderTypeWorkShiftTimeSlotType: IWorkOrderTypeEditItemWorkShiftTimeSlotType) => void;
    onRemoved: (id: string) => void;
}

interface IOwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotListState {
    selectedId: string;
    usedWorkShiftTimeSlotTypeIds: string[];
    workOrderTypeWorkShiftTimeSlotTypeDialogItem: IWorkOrderTypeEditItemWorkShiftTimeSlotType;
    showWorkOrderTypeWorkShiftTimeSlotTypeDialog: boolean;
}

export class OwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotList extends React.Component<IOwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotListProp, IOwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotListState> {
    constructor(props: IOwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotListProp) {
        super(props);
        this.state = {
            selectedId: null,
            usedWorkShiftTimeSlotTypeIds: props.workOrderTypeWorkShiftTimeSlotTypes.map(i => i.workShiftTimeSlotTypeId),
            workOrderTypeWorkShiftTimeSlotTypeDialogItem: null,
            showWorkOrderTypeWorkShiftTimeSlotTypeDialog: false
        };
    }

    edit = (workOrderTypeWorkShiftTimeSlotType: IWorkOrderTypeEditItemWorkShiftTimeSlotType) => {
        if (!workOrderTypeWorkShiftTimeSlotType) return;
        this.setState({
            workOrderTypeWorkShiftTimeSlotTypeDialogItem: workOrderTypeWorkShiftTimeSlotType,
            showWorkOrderTypeWorkShiftTimeSlotTypeDialog: true,
        });
    };

    getFirstFreeWorkShiftTimeSlotType = (): IWorkShiftTimeSlotType => {
        return this.props.workShiftTimeSlotTypes.find(i => this.state.usedWorkShiftTimeSlotTypeIds.indexOf(i.id) < 0);
    };

    handleAdd = () => {
        if (this.props.isReadOnly) return;
        const workShiftTimeSlotType = this.getFirstFreeWorkShiftTimeSlotType();
        if (!workShiftTimeSlotType) return;
        const workOrderTypeWorkShiftTimeSlotType = new WorkOrderTypeEditItemWorkShiftTimeSlotType();
        workOrderTypeWorkShiftTimeSlotType.id = Base.getGuid();
        workOrderTypeWorkShiftTimeSlotType.workShiftTimeSlotTypeId = workShiftTimeSlotType.id;
        workOrderTypeWorkShiftTimeSlotType.productId = "";
        this.edit(workOrderTypeWorkShiftTimeSlotType);
    };

    save = (workOrderTypeWorkShiftTimeSlotType: IWorkOrderTypeEditItemWorkShiftTimeSlotType) => {
        const obj = this;
        const props = this.props;
        const usedWorkShiftTimeSlotTypeIds = props.workOrderTypeWorkShiftTimeSlotTypes.filter(i => i.id !== workOrderTypeWorkShiftTimeSlotType.id).map(i => i.workShiftTimeSlotTypeId);
        //Create savedata
        const workOrderTypeWorkShiftTimeSlotTypes = [workOrderTypeWorkShiftTimeSlotType];
        const saveData = new SaveData();
        saveData.append("id", props.workOrderTypeId);
        saveData.append("workOrderTypeWorkShiftTimeSlotTypes", JSON.stringify(workOrderTypeWorkShiftTimeSlotTypes.reduce((result, i) => {
            result.push(new WorkOrderTypeEditItemSaveWorkShiftTimeSlotType(i));
            return result;
        }, [])));
        //Save to db
        store.customStore.dispatch(storeActions.fetchStart());
        workOrderTypeService.saveWorkOrderTypeWorkShiftTimeSlotTypes(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                usedWorkShiftTimeSlotTypeIds.push(workOrderTypeWorkShiftTimeSlotType.workShiftTimeSlotTypeId);
                obj.setState({
                    selectedId: success.ids[0],
                    showWorkOrderTypeWorkShiftTimeSlotTypeDialog: false,
                    usedWorkShiftTimeSlotTypeIds: usedWorkShiftTimeSlotTypeIds
                });
                workOrderTypeWorkShiftTimeSlotType.id = success.ids[0];
                workOrderTypeWorkShiftTimeSlotType.rowId = success.rowIds[0];
                props.onModified(workOrderTypeWorkShiftTimeSlotType);
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleWorkOrderTypeWorkShiftTimeSlotTypeDialogOk = (workShiftTimeSlotTypeId: string, productId: string) => {
        if (this.props.isReadOnly) return;
        const workOrderTypeWorkShiftTimeSlotType = this.state.workOrderTypeWorkShiftTimeSlotTypeDialogItem;
        if (!workOrderTypeWorkShiftTimeSlotType) return;
        workOrderTypeWorkShiftTimeSlotType.workShiftTimeSlotTypeId = workShiftTimeSlotTypeId;
        workOrderTypeWorkShiftTimeSlotType.productId = productId;
        this.save(workOrderTypeWorkShiftTimeSlotType);
    };

    handleWorkOrderTypeWorkShiftTimeSlotTypeDialogCancel = () => {
        this.setState({
            showWorkOrderTypeWorkShiftTimeSlotTypeDialog: false
        });
    };

    remove = (id: string) => {
        const props = this.props;
        //Create savedata
        const workOrderTypeWorkShiftTimeSlotTypeIds = [id];
        const saveData = new SaveData();
        saveData.append("id", props.workOrderTypeId);
        saveData.append("workOrderTypeWorkShiftTimeSlotTypeIds", JSON.stringify(workOrderTypeWorkShiftTimeSlotTypeIds));
        //Save to db
        store.customStore.dispatch(storeActions.fetchStart());
        workOrderTypeService.removeWorkOrderTypeWorkShiftTimeSlotTypes(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                props.onRemoved(id);
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleEdit = () => {
        const props = this.props;
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        this.edit(props.workOrderTypeWorkShiftTimeSlotTypes.find(i => i.id === selectedId));
    };

    handleRemove = () => {
        if (this.props.isReadOnly) return;
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        this.remove(selectedId);
    };

    handleLineClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleLineDoubleClick = (id: string) => {
        this.handleEdit();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const workOrderTypeWorkShiftTimeSlotTypes = props.workOrderTypeWorkShiftTimeSlotTypes;
        return (
            <div className="workOrderTypeSubList">
                <div className="commandRow">
                    <label id={props.titleId} className={"control-label listTitle" + (props.titleClass ? " " + props.titleClass : "")}>{Translations.RoutePointWorkShiftTimeSlotTypes}</label>
                    {!props.isReadOnly &&
                        <ToolButton
                            title={Translations.Remove}
                            enabled={!!state.selectedId}
                            classes={"round right remove"}
                            onClick={this.handleRemove}
                        />
                    }
                    <ToolButton
                        title={Translations.Edit}
                        enabled={!!state.selectedId}
                        classes={"round right edit"}
                        onClick={this.handleEdit}
                    />
                    {!props.isReadOnly &&
                        <ToolButton
                            title={Translations.Add}
                            enabled={true}
                            classes={"round right add"}
                            onClick={this.handleAdd}
                        />
                    }
                </div>
                <div className="listContainer workOrderTypeWorkShiftTimeSlotTypesContainer">
                    {workOrderTypeWorkShiftTimeSlotTypes.length > 0 &&
                        <div className="list workOrderTypeWorkShiftTimeSlotTypes">
                            {workOrderTypeWorkShiftTimeSlotTypes.map((workOrderTypeWorkShiftTimeSlotType) =>
                                <OwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotListLine
                                    key={workOrderTypeWorkShiftTimeSlotType.id}
                                    workOrderTypeWorkShiftTimeSlotType={workOrderTypeWorkShiftTimeSlotType}
                                    workShiftTimeSlotTypes={props.workShiftTimeSlotTypes}
                                    products={props.products}
                                    selectedId={state.selectedId}
                                    onClick={this.handleLineClick}
                                    onDoubleClick={this.handleLineDoubleClick}
                                />
                            )}
                        </div>
                    }
                </div>
                {state.showWorkOrderTypeWorkShiftTimeSlotTypeDialog &&
                    <OwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotDialog
                        isReadOnly={props.isReadOnly}
                        workOrderTypeWorkShiftTimeSlotType={state.workOrderTypeWorkShiftTimeSlotTypeDialogItem}
                        workShiftTimeSlotTypes={props.workShiftTimeSlotTypes}
                        products={props.products}
                        usedWorkShiftTimeSlotTypeIds={state.usedWorkShiftTimeSlotTypeIds}
                        onOk={this.handleWorkOrderTypeWorkShiftTimeSlotTypeDialogOk}
                        onCancel={this.handleWorkOrderTypeWorkShiftTimeSlotTypeDialogCancel}
                    />
                }
            </div>
        );
    }
}
