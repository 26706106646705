import { getApiCall, postFormApiCall, postApiCall } from "./tokenService";
import { CustomerTypeItems } from "../models/customerType/customerTypeItems";
import { CustomerTypeEdit } from "../models/customerType/customerTypeEdit";
import { ApiSuccess, SaveSuccess } from "./baseService";

export const getCustomerTypeItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean): Promise<CustomerTypeItems> => {
    return getApiCall<CustomerTypeItems>("api/customertype/?page=" + (page || "") + "&pageSize=" + (pageSize || "") +
        "&sortColumn=" + (sortColumn ? encodeURIComponent(sortColumn) : "") + "&sortOrder=" + (sortOrderIsAsc ? "asc" : "desc") +
        "&filter=" + (filter ? encodeURIComponent(filter) : ""), CustomerTypeItems);
};

export const getCustomerTypeEdit = (id: string): Promise<CustomerTypeEdit> => {
    return getApiCall<CustomerTypeEdit>("api/customertype/edit/?id=" + (id || ""), CustomerTypeEdit);
};

export const saveCustomerTypeEdit = (data: FormData): Promise<SaveSuccess> => {
    return postFormApiCall<SaveSuccess>("api/customertype/save", data, SaveSuccess);
};

export const removeCustomerType = (id: string): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/customertype/remove/?id=" + (id || ""), ApiSuccess);
};