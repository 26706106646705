import * as CustomStore from "../../framework/customStore";
import * as StoreActions from "../store/storeActions";
import * as vehicleService from "../../services/vehicleService";
import * as BaseService from "../../services/baseService";
import { Base } from "../../framework/base";
import { IVehicleEdit } from "./vehicleEdit";

export class VehicleOperations {
    // #region Edit
    static getVehicleEdit(id: string): Promise<IVehicleEdit> {
        CustomStore.customStore.dispatch(StoreActions.fetchStart());
        return vehicleService.getVehicleEdit(id)
            .then(editItem => {
                return editItem;
            })
            .catch(error => {
                CustomStore.customStore.dispatch(StoreActions.showErrorMessage(BaseService.getErrorMessageFromError(error)));
                return Base.getPromiseResult(null);
            })
            .finally(() => CustomStore.customStore.dispatch(StoreActions.fetchEnd()));
    }
    // #endregion Edit
}