import * as React from "react";
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    TextField,
    useTheme,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Translations } from "../../models/translations";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import {
    updateFilters,
    updateFetchFilters,
    clearFilters
} from "../../store/workTimeBetaSlice";
import { useEffect, useState } from "react";
import { SalaryPeriodSelect } from "./muiSalaryPeriodSelect";
import { employeeGroupListItemDto } from "../../models/employeeGroup/employeeGroup";
import { getEmployeeGroups } from "../../services/employeeGroupService";
import { getSalaryPeriodItems } from "../../services/salaryPeriodService";
import { ISalaryPeriodItem } from "../../models/salaryPeriod/salaryPeriodItem";
import { handleApiError } from "../../models/store/storeEffects";
import { Base } from "../../framework/base";
import { useFetch } from "../../hooks/useFetch";
import { CostCenterItems } from "../../models/costCenter/costCenterItems";
import { ICostCenterItem } from "../../models/costCenter/costCenterItem";
import * as costCenterService from "../../services/costCenterService";
import { showApiError } from "../framework/formUtils";
import { useTranslation } from "react-i18next";
import { showErrorMessage } from "../../models/store/storeActions";
import { EmployeeListItemDto } from "../../models/employee/employee";

export const WorkTimeFilters = (props: {
    showCostCenters?: boolean;
    employees: EmployeeListItemDto[];
}) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [employeeGroups, setEmployeeGroups] = useState<employeeGroupListItemDto[]>();
    const [salaryPeriods, setSalaryPeriods] = useState<ISalaryPeriodItem[]>();
    const [costCenters, setCostCenters] = useState<CostCenterItems>();
    const { filters, fetchFilters, showFilters } = useAppSelector(
        (state) => state.workTimeBeta
    );

    useFetch(
        (signal) => getEmployeeGroups(true, signal),
        {
            onSuccess: (res) =>
                setEmployeeGroups(
                    res.data.filter((d) => !!d.salaryPeriodTypeId)
                ),
        },
        []
    );

    const fetchSalaryPeriods = async() => {
        const salaryPeriods = await getSalaryPeriodItems(
            filters.selectedEmployeeGroup?.salaryPeriodTypeId
        ).catch((e) => handleApiError(e, dispatch));

        if (salaryPeriods) {
            setSalaryPeriods(salaryPeriods);
            dispatch(
                updateFetchFilters({
                    selectedSalaryPeriod: salaryPeriods.find(
                        (p) => p.id === fetchFilters.selectedSalaryPeriod?.id
                    ),
                })
            );
        }

        if (!salaryPeriods || salaryPeriods.length === 0) {
            dispatch(showErrorMessage(Translations.SalaryPeriodMissing));
            return;
        }
    };

    useEffect(() => {
        if (!props.showCostCenters) {
            return;
        }
        const fetchCostCenters = async() => {
            return await costCenterService.getCostCenterItems(100, 1, "", "name", true, false);
        };
        fetchCostCenters()
            .then((costCenters) => {
                setCostCenters(costCenters);
            })
            .catch(() => showApiError(t("workTime.costCenterFetchFailed")));
    }, [props.showCostCenters]);

    useEffect(() => {
        if (filters.selectedEmployeeGroup?.salaryPeriodTypeId) {
            fetchSalaryPeriods();
        }
    }, [filters.selectedEmployeeGroup?.salaryPeriodTypeId]);

    useEffect(() => {
        if (!!employeeGroups && !filters.selectedEmployeeGroup)
            dispatch(
                updateFilters({
                    selectedEmployeeGroup: employeeGroups[0],
                })
            );

    }, [employeeGroups, filters.selectedEmployeeGroup]);

    useEffect(() => {
        if (!!salaryPeriods && !fetchFilters.selectedSalaryPeriod) {
            dispatch(
                updateFetchFilters({
                    selectedSalaryPeriod:
                        salaryPeriods.find((p) =>
                            Base.isBetween(
                                new Date(),
                                p.startDate,
                                p.endDate,
                                "day",
                                "[)"
                            )
                        ) || salaryPeriods[0],
                })
            );
        }

    }, [salaryPeriods, fetchFilters.selectedSalaryPeriod]);

    const salaryPeriodChanged = React.useCallback(
        (salaryPeriod: ISalaryPeriodItem) => {
            if (!salaryPeriod) {
                return;
            }
            dispatch(
                updateFetchFilters({
                    selectedSalaryPeriod: salaryPeriod,
                })
            );
        },
        [dispatch]
    );

    return (
        <Box className="work-time-beta-top-bar">
            {showFilters && salaryPeriods && employeeGroups && filters.selectedEmployeeGroup && (
                <Grid2 container alignItems="center" rowSpacing={1}>
                    <Grid2 lg={3} md={12} sm={12} xs={12}>
                        <SalaryPeriodSelect
                            salaryPeriods={salaryPeriods}
                            selectedSalaryPeriod={fetchFilters.selectedSalaryPeriod}
                            onChange={salaryPeriodChanged}
                        />
                    </Grid2>
                    <Grid2 md={4} sm={12} xs={12} maxWidth={280} marginRight={3}>
                        <FormControl variant="outlined" fullWidth={true}>
                            <Autocomplete
                                isOptionEqualToValue={(a, b) => a.id === b.id}
                                value={filters.selectedEmployeeGroup}
                                multiple={false}
                                fullWidth={true}
                                disableClearable={true}
                                noOptionsText={Translations.NoOptions}
                                options={employeeGroups || []}
                                getOptionLabel={(option: employeeGroupListItemDto) => option.name}
                                onChange={(_, value: employeeGroupListItemDto) =>
                                    dispatch(
                                        updateFilters({
                                            selectedEmployeeGroup: value,
                                            selectedEmployees: []
                                        })
                                    )
                                }
                                renderInput={(params) => (
                                    <TextField {...params} label={Translations.EmployeeGroup} />
                                )}
                            />
                        </FormControl>
                    </Grid2>
                    <Grid2 md={4} sm={12} xs={12} maxWidth={280} marginRight={3}>
                        <FormControl variant="outlined" fullWidth={true}>
                            <Autocomplete
                                isOptionEqualToValue={(a, b) => a.id === b.id}
                                value={props.employees?.filter(e => filters.selectedEmployees.includes(e.id)) ?? []}
                                fullWidth={true}
                                multiple
                                limitTags={2}
                                noOptionsText={Translations.NoOptions}
                                options={props.employees ?? []}
                                getOptionLabel={(option: EmployeeListItemDto) => `${option.lastName} ${option.firstName}`}
                                onChange={(_, value: EmployeeListItemDto[]) =>
                                    dispatch(updateFilters({ selectedEmployees: value.map(v => v.id) }))
                                }
                                renderInput={(params) => (
                                    <TextField {...params} label={Translations.Employee} />
                                )}
                            />
                        </FormControl>
                    </Grid2>
                    {props.showCostCenters && (
                    <Grid2 md={4} sm={12} xs={12} maxWidth={280} marginRight={3}>
                        <FormControl variant="outlined" fullWidth={true}>
                            <Autocomplete
                                isOptionEqualToValue={(a, b) => a.id === b.id}
                                value={filters.selectedCostCenters}
                                fullWidth={true}
                                multiple
                                limitTags={2}
                                noOptionsText={Translations.NoOptions}
                                options={costCenters?.items || []}
                                getOptionLabel={(option: ICostCenterItem) => option.name}
                                onChange={(_, value: ICostCenterItem[]) => {
                                    dispatch(updateFilters({ selectedCostCenters: value }));
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label={Translations.CostCenter} />
                                )}
                            />
                        </FormControl>
                    </Grid2>
                    )}

                    <Grid2 xsOffset="auto" alignItems="self-end">
                        {showFilters && (
                            <Button
                                color="secondary"
                                sx={{ color: theme.palette.common.white }}
                                onClick={() => dispatch(clearFilters())}
                                variant={"contained"}
                            >
                                {Translations.Clear}
                            </Button>
                        )}
                    </Grid2>
                </Grid2>
            )}
        </Box>
    );
};
