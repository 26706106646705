// Autocomplete
// ***********************************************************************************************************************
import * as React from "react";
import { Menu, MenuItem, Typeahead, TypeaheadRef } from "react-bootstrap-typeahead";
import { Base } from "../../framework/base";
import { ToolButton } from "./toolButton";
import { Translations } from "../../models/translations";
import { Align } from "react-bootstrap-typeahead/types/types";

export interface IAutocompleteItem {
    id: string;
    getLabelTitle?: () => string;
    getTitle(): string;
}

export class AutocompleteItem implements IAutocompleteItem {
    id: string;
    title: string;

    getTitle = () => {
        return this.title;
    };

    constructor(obj: { id: string; title: string }) {
        this.id = obj.id;
        this.title = obj.title;
    }
}

export interface IAutocompleteProp {
    id?: string;
    align?: Align;
    autoFocus?: boolean;
    classes?: string;
    dropup?: boolean;
    disabled?: boolean;
    doNotClearOnOpen?: boolean;
    editDisabled?: boolean;
    maxResults?: number;
    minLength?: number;
    multiple?: boolean;
    placeholder?: string;
    required?: boolean;
    selectedItems: IAutocompleteItem[];
    items: IAutocompleteItem[];

    onChange: (ids: string[], isClearing?: boolean) => void;
    onAddClick?: (title: string) => void;
    onAttachClick?: (title: string) => void;
    onEditClick?: (id: string) => void;
    onBlur?: (value: string, selectedIds: string[]) => void;
    onEnterPress?: (value: string, selectedIds: string[]) => void;
    onSearch?: (value: string, selectedIds: string[]) => void;
    doNotClear?: boolean;
    doClean?: boolean;
    cleanReady?: (state) => void;
}

export interface IAutocompleteState {
    id: string;
    minLength: number;
    selectedItems: IAutocompleteItem[];
}

/**
 * @deprecated Use muiAutocomplete.tsx instead, at least for views using MUI components.
 * Visually, it is close enough that it can be used in legacy components too.
 */
export class Autocomplete extends React.Component<IAutocompleteProp, IAutocompleteState> {
    static defaultMinLength = 0;
    typeahead: TypeaheadRef;

    constructor(props: IAutocompleteProp) {
        super(props);
        this.state = {
            id: props.id ? props.id : Base.getGuid(),
            minLength: props.minLength ?? Autocomplete.defaultMinLength,
            selectedItems: props.selectedItems.slice(0)
        };
    }

    componentDidUpdate(prevProps: IAutocompleteProp, prevState: IAutocompleteState): void {

        if (prevProps.selectedItems.map(i => i.id + "," + i.getTitle()).join() === this.props.selectedItems.map(i => i.id + "," + i.getTitle()).join()) {
            if (this.props.doClean) {
                //because system does not clearing selected items automatic
                //after list is changes -> should clearing textfield and list
                //with prop.
                this.setState({
                    selectedItems: []
                });

                if (this.props.cleanReady) {
                    this.props.cleanReady(false);
                }
            }

            return;
        }

        this.setState({
            selectedItems: this.props.selectedItems.slice(0)
        });
    }

    handleChange = (items: IAutocompleteItem[]) => {
        const props = this.props;
        this.setState({
            selectedItems: items
        });
        if (items.length >= 1) {
            props.onChange(items.map(i => i.id));
        }
    };

    handleBlur = (e: any) => {
        const props = this.props;
        const required = props.required ?? false;
        if (this.state.selectedItems.length < 1) {
            if (required) {
                props.onChange(props.selectedItems.map(i => i.id));
            } else {
                props.onChange([]);
            }
        }
        if (props.onBlur) {
            props.onBlur(this.typeahead.getInput().value, this.state.selectedItems.map(i => i.id));
        }
    };

    handleOpenClick = () => {
        if (!this.props.doNotClearOnOpen) {
            this.setState({
                selectedItems: []
            });
        }
        window.setTimeout(() => {
            this.typeahead.getInput().focus();
        }, 100);
    };



    handleClearClick = () => {
        const props = this.props;
        this.setState({
            selectedItems: []
        });
        const required = props.required ?? false;
        if (required) {
            window.setTimeout(() => {
                this.typeahead.getInput().focus();
            }, 100);
        } else {
            props.onChange([], true);
        }
    };

    handleAddNew = (e: any) => {
        e.preventDefault();
        this.props.onAddClick(this.typeahead.getInput().value);
    };

    handleAttach = (e: any) => {
        e.preventDefault();
        this.props.onAttachClick(this.typeahead.getInput().value);
    };

    handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && this.props.onEnterPress) {
            // Only trigger callback when pressing enter without selecting an item from dropdown
            setTimeout(() => {
                if (this.state.selectedItems.length === 0) {
                    this.props.onEnterPress(this.typeahead.getInput().value, this.state.selectedItems.map(i => i.id));
                }
            });
        }
    };

    handleSearch = () => {
        this.props.onSearch(this.typeahead.getInput().value, this.state.selectedItems.map(i => i.id));
    };

    render() {
        const props = this.props;
        const state = this.state;
        const classes = (props.classes ? " " + props.classes : "") + (props.disabled ? " disabled" : "");
        return (
            <div className="input-group autocomplete">
                <Typeahead
                    id={state.id}
                    align={props.align}
                    allowNew={false}
                    autoFocus={props.autoFocus}
                    className={classes}
                    disabled={props.disabled}
                    emptyLabel={Translations.NoMatchesFound}
                    labelKey={(i: IAutocompleteItem) => (i.getLabelTitle ? i.getLabelTitle() : i.getTitle())}
                    filterBy={(option: IAutocompleteItem, filterProps) => {
                        return (option?.getTitle() ?? "").toLocaleLowerCase().indexOf(filterProps.text.toLowerCase()) > -1;
                    }}
                    maxResults={props.maxResults}
                    minLength={state.minLength}
                    multiple={props.multiple}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    options={props.items}
                    paginationText={Translations.ShowAdditionalResults}
                    placeholder={props.placeholder}
                    dropup={props.dropup}
                    ref={(t) => { this.typeahead = t; }}
                    renderMenu={(results, menuProps) => (
                        <Menu {...menuProps}>
                            {props.onAddClick &&
                                <Menu.Header><div onClick={this.handleAddNew}>{Translations.AddNew}</div></Menu.Header>
                            }
                            {props.onAttachClick &&
                                <Menu.Header><div onClick={this.handleAttach}>{Translations.AttachExisting}</div></Menu.Header>
                            }
                            {results.map((result: IAutocompleteItem, index) => (
                                <MenuItem key={result.id} option={result} position={index}>
                                    {result && result.getTitle ? result.getTitle() : ""}
                                </MenuItem>
                            ))}
                        </Menu>)
                    }
                    selected={state.selectedItems}
                />
                {(!props.disabled || props.onEditClick) &&
                    <span className="input-group-append">
                        {!props.disabled && state.selectedItems && state.selectedItems.length > 0 && !props.doNotClear &&
                        <ToolButton
                            removeDefaultClasses={true}
                            title={Translations.Clear}
                            classes="btn withIcon xCross gray"
                            enabled={true}
                            onClick={this.handleClearClick}
                        />
                        }
                        {!props.disabled && (!state.selectedItems || state.selectedItems.length < 1) && props.onSearch &&
                            <ToolButton
                                removeDefaultClasses={true}
                                title={Translations.Open}
                                classes="btn withIcon search"
                                enabled={true}
                                onClick={this.handleSearch}
                            />
                        }
                        {!props.disabled &&
                            <ToolButton
                                removeDefaultClasses={true}
                                title={Translations.Open}
                                classes="btn withIcon doExpand"
                                enabled={true}
                                onClick={this.handleOpenClick}
                            />
                        }
                        {props.onEditClick &&
                            <ToolButton
                                removeDefaultClasses={true}
                                title={Translations.Edit}
                                classes="btn withIcon edit"
                                enabled={!props.editDisabled && state.selectedItems && state.selectedItems.length > 0}
                                onClick={() => props.onEditClick(state.selectedItems[0].id)}
                            />
                        }
                    </span>
                }
            </div>
        );
    }
}
