import { Box } from "@mui/system";
import React from "react";
import { TransportSideBarOrders } from "./transportSidebarOrders";

export const TransportSidebarContainer = () => {
    return (
        <Box sx={{ backgroundColor: "lightBlue.main", width: "100%", height: "100%", overflow: "auto" }}>
            <TransportSideBarOrders/>
        </Box>
    );
};
