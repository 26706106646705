import { Base } from "../../framework/base";

export interface IWorkOrderEditItemRideBooking {
    id: string;
    employeeId: string;
    vehicleId: string;
    date: number;
    amount: number;
    comment: string;
    rowId: string;

    isNew():boolean;
}

export class WorkOrderEditItemRideBooking implements IWorkOrderEditItemRideBooking {
    id: string;
    employeeId: string;
    vehicleId: string;
    date: number;
    amount: number;
    comment: string;
    rowId: string;

    constructor();
    constructor(obj: IWorkOrderEditItemRideBooking);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.employeeId = obj && obj.employeeId || "";
        this.vehicleId = obj && obj.vehicleId || "";
        this.date = obj && obj.date || 0;
        this.amount = obj && obj.amount || 0;
        this.comment = obj && obj.comment || "";
        this.rowId = obj && obj.rowId || "";
    }

    isNew():boolean {
        return !this.rowId;
    }

    static sortWorkOrderEditItemRideBookings(items: WorkOrderEditItemRideBooking[], column: string, asc: boolean) {
        if (Base.isNullOrUndefined(items) || items.length < 2) return;
        let sortFunc: (a: WorkOrderEditItemRideBooking, b: WorkOrderEditItemRideBooking) => number;
        if (column === "amount") sortFunc = (a: WorkOrderEditItemRideBooking, b: WorkOrderEditItemRideBooking) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.amount, b.amount)); };
        else if (column === "comment") sortFunc = (a: WorkOrderEditItemRideBooking, b: WorkOrderEditItemRideBooking) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.comment, b.comment)); };
        else sortFunc = (a: WorkOrderEditItemRideBooking, b: WorkOrderEditItemRideBooking) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.date, b.date)); };
        items.sort(sortFunc);
    }
}

export class WorkOrderEditItemSaveRideBooking {
    id: string;
    employeeId: string;
    vehicleId: string;
    date: number;
    amount: number;
    comment: string;
    rowId: string;

    constructor();
    constructor(obj: IWorkOrderEditItemRideBooking);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.employeeId = obj && obj.employeeId || "";
        this.vehicleId = obj && obj.vehicleId || "";
        this.date = obj && obj.date || 0;
        this.amount = obj && obj.amount || 0;
        this.comment = obj && obj.comment || "";
        this.rowId = obj && obj.rowId || "";
    }
}