import { Base } from "../../framework/base";
import { ICustomerEditItemSiteItemEmployeeItem, CustomerEditItemSiteItemEmployeeItem } from "./customerEditItemSiteItemEmpoyeeItem";
import { IRoutePointItem, RoutePointItem } from "../routePoint/routePointItem";

export interface ICustomerEditItemSiteItem {
    id: string;
    siteType: number;
    siteNumber: string;
    name: string;
    name2: string;
    distance: number;
    rideBookingRequired: boolean;
    familiarizationType: number;
    rowId: string;
    employees: ICustomerEditItemSiteItemEmployeeItem[];
    routePoints: IRoutePointItem[];

    isNew():boolean;
}

export class CustomerEditItemSiteItem implements ICustomerEditItemSiteItem {
    id: string;
    siteType: number;
    siteNumber: string;
    name: string;
    name2: string;
    distance: number;
    rideBookingRequired: boolean;
    familiarizationType: number;
    rowId: string;
    employees: CustomerEditItemSiteItemEmployeeItem[];
    routePoints: RoutePointItem[];

    constructor();
    constructor(obj: ICustomerEditItemSiteItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.siteType = obj && obj.siteType || 0;
        this.siteNumber = obj && obj.siteNumber || "";
        this.name = obj && obj.name || "";
        this.name2 = obj && obj.name2 || "";
        this.distance = obj && obj.distance || 0;
        this.rideBookingRequired = obj && obj.rideBookingRequired || false;
        this.familiarizationType = obj && obj.familiarizationType || 0;
        this.rowId = obj && obj.rowId || "";
        this.employees = Base.getTypedArray<CustomerEditItemSiteItemEmployeeItem>(obj ? obj.employees : null, CustomerEditItemSiteItemEmployeeItem);
        this.routePoints = Base.getTypedArray<RoutePointItem>(obj ? obj.routePoints : null, RoutePointItem);
    }

    isNew():boolean {
        return !this.rowId;
    }

    static sortCustomerEditItemSiteItems(items: ICustomerEditItemSiteItem[], asc: boolean) {
        if (!items || items.length < 2) return;
        items.sort((a: ICustomerEditItemSiteItem, b: ICustomerEditItemSiteItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.name, b.name)); });
    }
}

export class CustomerEditItemSaveSiteItem {
    id: string;
    siteType: number;
    siteNumber: string;
    name: string;
    name2: string;
    distance: number;
    rideBookingRequired: boolean;
    familiarizationType: number;
    rowId: string;

    constructor();
    constructor(obj: ICustomerEditItemSiteItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.siteType = obj && obj.siteType || 0;
        this.siteNumber = obj && obj.siteNumber || "";
        this.name = obj && obj.name || "";
        this.name2 = obj && obj.name2 || "";
        this.distance = obj && obj.distance || 0;
        this.rideBookingRequired = obj && obj.rideBookingRequired || false;
        this.familiarizationType = obj && obj.familiarizationType || 0;
        this.rowId = obj && obj.rowId || "";
    }
}