// WorkOrderDialog - MODULEopenedFromProject
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "../../framework/base";
import * as store from "../../framework/customStore";
import * as contactService from "../../services/contactService";
import * as workOrderService from "../../services/workOrderService";
import * as storeActions from "../../models/store/storeActions";
import { PropertyDialog } from "../framework/dialog";
import { Translations } from "../../models/translations";
import { IWorkOrderEdit } from "../../models/work/workOrderEdit";
import { SettingsCustomerDialog } from "../settings/settingsCustomerDialog";
import { IIdTitle, IdTitle } from "../../models/common/idTitle";
import { ICustomerEdit } from "../../models/customer/customerEdit";
import { IWorkOrderEditSite, WorkOrderEditSite } from "../../models/work/workOrderEditSite";
import { WorkOrderEditContact, IWorkOrderEditContact } from "../../models/work/workOrderEditContact";
import { WorkOrderDialogSelectContactDialog } from "./workOrderDialogSelectContactDialog";
import { SettingsContactDialog } from "../settings/settingsContactDialog";
import { IContactEdit } from "../../models/contact/contactEdit";
import { ICustomerEditItemSiteItem } from "../../models/customer/customerEditItemSiteItem";
import { SettingsSiteDialog } from "../settings/settingsSiteDialog";
import { WorkOrderEditItem } from "../../models/work/workOrderEditItem";
import { WorkOrderDialogGeneral } from "./workOrderDialogGeneral";
import { EnumHelper, RecurrenceType, WorkOrderCategory, WorkOrderState } from "../../models/common/enums";
import { IWorkOrderEditItemWorkOrderTask, WorkOrderEditItemWorkOrderTask } from "../../models/work/workOrderEditItemWorkOrderTask";
import { IWorkOrderEditAcknowledgementType } from "../../models/work/workOrderEditAcknowledgementType";
import { INumberTitle } from "../../models/common/numberTitle";
import { IWorkOrderEditItemHourBooking, WorkOrderEditItemHourBooking } from "../../models/work/workOrderEditItemHourBooking";
import { IWorkOrderEditItemRideBooking, WorkOrderEditItemRideBooking } from "../../models/work/workOrderEditItemRideBooking";
import { WorkOrderDialogWorkerOrderTaskList } from "./workOrderDialogWorkerOrderTaskList";
import { IDocument } from "../../models/document/document";
import { WorkOrderDialogWorkTimeList } from "./workOrderDialogWorkTimeList";
import { IWorkTimeListItem } from "../../models/workTime/workTimeListItem";
import { WorkOrderDialogInvoicedRecordsList } from "./workOrderDialogInvoicedRecordsList";
import { ObjectEventLogList } from "../eventLog/objectEventLogList";
import { WorkOrderDialogDocumentList } from "./workOrderDialogDocumentList";
import { IWorkOrderEditItemProductBooking, WorkOrderEditItemProductBooking } from "../../models/work/workOrderEditItemProductBooking";
import { WorkOrderOperations } from "../../models/work/workOrderOperations";
import { IWorkOrderEditWorkOrderTypeItem, WorkOrderEditWorkOrderTypeItem } from "../../models/work/workOrderEditWorkOrderTypeItem";
import { WorkOrderDialogRoutePointListConnected } from "./workOrderDialogRoutePointList";
import { IRoutePointItem, RoutePointItem } from "../../models/routePoint/routePointItem";
import { IVehicleItem } from "../../models/vehicle/vehicleItem";
import { IWorkOrderEditItemRoutePointBooking } from "../../models/work/workOrderEditItemRoutePointBooking";
import { CustomerOperations } from "../../models/customer/customerOperations";
import { ContactOperations } from "../../models/contact/contactOperations";
import { IEmployeeItem } from "../../models/employee/employeeItem";
import { IWorkOrderEditProductItem } from "../../models/work/workOrderEditProductItem";
import { WorkOrderCopyDialog } from "./workOrderCopyDialog";
import { connect } from "react-redux";
import * as storeEffects from "../../models/store/storeEffects";
import { WorkOrderSaveAsTemplateDialogConnected } from "./workOrderSaveAsTemplateDialog";
import { Tab, Tabs, Box } from "@mui/material";
import { TabPanel } from "../framework/tabPanel";
import { OwnerParameters } from "../../models/owner/ownerParameters";
import { WorkOrderStorageProducts } from "./workOrderStorageProducts";
import { IStorageProductBooking } from "../../models/storage/storageProductBooking";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { WorkOrderDialogBookingsList } from "./workOrderDialogBookingsList";
import { AppUtils } from "../../models/common/appUtils";

// WorkOrderDialog
// ***********************************************************************************************************************
export type DSetContactSiteAndAcknowledgementType = (contactIds: string[], siteIds: string[], workOrderAcknowledgementTypeId: string) => void;

export interface IWorkOrderDialogProp {
    classes?: string;
    workOrderEdit: IWorkOrderEdit;
    makeProductBookingsOnOpen: boolean;
    openedFromProject?: boolean;
    openedFromTask?: boolean;
    storageEnabled?: boolean;
    enabledStates?: number[];
    onCreated?: (newWorkOrderId: string) => void;
    onOk: (openWorkOrderId: string, makeProductBookingsOnOpen: boolean) => void;
    onCopied: (workOrderEdit: IWorkOrderEdit) => void;
    onCancel: () => void;
    getOwnerRoutePoints?: () => void;

}

export interface IWorkOrderDialogState {
    //WorkOrder
    id: string;
    rowId: string;
    contactId: string;
    customerId: string;
    employeeIds: string[];
    vehicleIds: string[];
    siteId: string;
    workOrderAcknowledgementTypeId: string;
    workOrderTypeId: string;
    workOrderType: IWorkOrderEditWorkOrderTypeItem | null;
    category: number;
    description: string;
    endTime: number;
    fullDay: boolean;
    name: string;
    number: number;
    startTime: number;
    state: number;
    canceled: boolean;
    comment: string;
    estimatedHours: number;
    acknowledgementSent: boolean;
    template: boolean;
    showCollectionListInMobile: boolean;
    //Parent
    parentId: string;
    parentName: string;
    parentNumber: number;
    parentTaskCount: number;
    parentRecurrenceType: number;
    parentCustomerName: string;
    parentSite: IWorkOrderEditSite;
    parentContact: IWorkOrderEditContact;
    //Recurrence
    recurrenceType: number;
    recurrenceInterval: number;
    recurrenceIntervalDetail: number;
    recurrenceOccurrenceAmount: number;
    recurrenceEndDate: number;
    //Invoice
    invoiceId: string;
    invoiceNumber: number;
    //Lists
    contacts: IWorkOrderEditContact[];
    customers: IIdTitle[];
    products: IWorkOrderEditProductItem[];
    employees: IEmployeeItem[];
    projectTasks: IWorkOrderEditItemWorkOrderTask[];
    projectStates: number[];
    sites: IWorkOrderEditSite[];
    vehicles: IVehicleItem[];
    workOrderAcknowledgementTypes: IWorkOrderEditAcknowledgementType[];
    workOrderStates: INumberTitle[];
    workOrderTypes: IWorkOrderEditWorkOrderTypeItem[];
    //Logo
    customerLogo: string;
    //Documents
    documents: IDocument[];
    //RoutePoints
    routePoints: IRoutePointItem[];
    //Storage
    storageProductBookings: IStorageProductBooking[];
    //Bookings
    hourBookings: IWorkOrderEditItemHourBooking[];
    rideBookings: IWorkOrderEditItemRideBooking[];
    productBookings: IWorkOrderEditItemProductBooking[];
    routePointBookings: IWorkOrderEditItemRoutePointBooking[];
    workTimes: IWorkTimeListItem[];
    //Dialogs
    //Dialogs - Project Task
    showTaskWorkOrderDialog: boolean;
    taskWorkOrderDialogItem: IWorkOrderEdit;
    //Dialogs - Parent Project
    showParentWorkOrderDialog: boolean;
    parentWorkOrderDialogItem: IWorkOrderEdit;
    //Dialogs - Copy Work Order
    showCopyWorkOrderDialog: boolean;
    //Dialogs - Save Work Order as Template
    showSaveWorkOrderAsTemplateDialog: boolean;

    makeProductBookingsOnOpen: boolean;
    showCustomerDialog: boolean;
    customerDialogOkCallback: (customerId: string) => void;
    customerDialogItem: ICustomerEdit;
    showSiteDialog: boolean;
    siteDialogCustomerId: string;
    siteDialogSiteId: string;
    siteDialogSiteName: string;
    siteDialogOkCallback: (siteId: string) => void;
    showSelectContactDialog: boolean;
    selectContactDialogName: string;
    selectContactDialogItems: IWorkOrderEditContact[];
    selectContactDialogCustomerId: string;
    selectContactDialogOkCallback: (contactId: string) => void;
    showContactDialog: boolean;
    contactDialogItem: IContactEdit;
    contactDialogCustomerId: string;
    contactDialogOkCallback: (contactId: string) => void;

    sortOrder: number;
    hasSequentialTasks: boolean;
    isSaving: boolean;
    selectedTab: number;
    isSubmitting: boolean;
}

export class WorkOrderDialog extends React.Component<IWorkOrderDialogProp, IWorkOrderDialogState> {
    private workOrderDialogGeneralRef = React.createRef<WorkOrderDialogGeneral>();
    private hasSequentialTasksSwitchRef = React.createRef<HTMLInputElement>();

    private static getProjectStates(projectTasks: IWorkOrderEditItemWorkOrderTask[]): number[] {
        const result = projectTasks.map(i => i.state);
        if (result.length < 1) {
            result.push(WorkOrderState.Preliminary);
        }
        return result;
    }

    private static getStateFromWorkOrderEdit(workOrderEdit: IWorkOrderEdit, makeProductBookingsOnOpen: boolean, oldState?: IWorkOrderDialogState): IWorkOrderDialogState {
        const workOrder = workOrderEdit.workOrder;
        const readonly = EnumHelper.isEqual(workOrder.category !== WorkOrderCategory.Project ? workOrder.state : Math.max(...WorkOrderDialog.getProjectStates(workOrder.projectTasks)), WorkOrderState.Transferred);
        if (readonly) {
            workOrderEdit.initStateEmployeeIds = null;
            workOrderEdit.initStateStartTime = null;
            workOrderEdit.initStateState = null;
        }
        const startTime = !readonly && workOrderEdit.initStateStartTime ? workOrderEdit.initStateStartTime : workOrder.startTime;
        let endTime = !readonly && workOrderEdit.initStateStartTime ? workOrderEdit.initStateStartTime + (workOrder.endTime - workOrder.startTime) : workOrder.endTime;
        if (workOrder.fullDay) {
            endTime = new Date(endTime).addDays(-1).getTime();
        }
        const workOrderType = EnumHelper.isEqual(workOrder.category, WorkOrderCategory.Project) ? null : WorkOrderEditWorkOrderTypeItem.getWorkOrderEditWorkOrderTypeItemById(workOrderEdit.workOrderTypes, workOrder.workOrderTypeId);
        const projectTasks = workOrder.projectTasks.slice(0);
        WorkOrderEditItemWorkOrderTask.sortWorkOrderEditItemWorkOrderTasks(projectTasks);

        if (EnumHelper.isEqual(workOrder.category, WorkOrderCategory.Project) && workOrder.hasSequentialTasks) {
            projectTasks.sort((a, b) => a.sortOrder - b.sortOrder);
        } else {
            projectTasks.sort((a, b) => a.number - b.number);
        }

        return {
            //WorkOrder
            id: workOrder.id,
            rowId: workOrder.rowId,
            contactId: workOrder.contactId,
            customerId: workOrder.customerId,
            employeeIds: workOrderEdit.initStateEmployeeIds
                ? workOrderEdit.initStateEmployeeIds
                : workOrder.employeeIds,
            vehicleIds: workOrderEdit.initStateVehicleIds ? workOrderEdit.initStateVehicleIds : workOrder.vehicleIds,
            siteId: workOrder.siteId,
            workOrderAcknowledgementTypeId: workOrder.workOrderAcknowledgementTypeId,
            workOrderTypeId: workOrderType?.id,
            workOrderType: workOrderType,
            category: workOrder.category,
            description: workOrder.description,
            endTime: endTime,
            fullDay: workOrder.fullDay,
            name: workOrder.name,
            number: workOrder.number,
            startTime: startTime,
            state: workOrder.state,
            canceled: workOrder.canceled,
            comment: workOrder.comment,
            estimatedHours: workOrder.estimatedHours,
            acknowledgementSent: workOrder.acknowledgementSent,
            template: workOrder.template,
            showCollectionListInMobile: workOrder.showCollectionListInMobile,
            parentId: workOrder.parentId,
            parentName: workOrder.parentName,
            parentNumber: workOrder.parentNumber,
            parentTaskCount: workOrder.parentTaskCount,
            parentRecurrenceType: workOrder.parentRecurrenceType,
            parentCustomerName: IdTitle.getTitleById(workOrderEdit.customers, workOrder.customerId),
            parentSite: workOrderEdit.sites.find(i => i.id === workOrder.siteId),
            parentContact: workOrder.contactId ? workOrderEdit.contacts.find(i => i.id === workOrder.contactId) : null,
            sortOrder: workOrder.sortOrder,
            hasSequentialTasks: workOrder.hasSequentialTasks,
            //Recurrence
            recurrenceType: workOrderEdit.initStateStartTime ? RecurrenceType.NoRecurrence : workOrder.recurrenceType,
            recurrenceInterval: workOrderEdit.initStateStartTime ? 1 : workOrder.recurrenceInterval,
            recurrenceIntervalDetail: workOrderEdit.initStateStartTime ? 0 : workOrder.recurrenceIntervalDetail,
            recurrenceOccurrenceAmount: workOrderEdit.initStateStartTime ? 1 : workOrder.recurrenceOccurrenceAmount,
            recurrenceEndDate: workOrderEdit.initStateStartTime ? null : workOrder.recurrenceEndDate,
            //Invoice
            invoiceId: workOrder.invoiceId,
            invoiceNumber: workOrder.invoiceNumber,
            //Lists
            contacts: workOrderEdit.contacts.slice(0),
            customers: workOrderEdit.customers.slice(0),
            products: workOrderEdit.products.slice(0),
            employees: workOrderEdit.employees.slice(0),
            projectTasks: projectTasks,
            projectStates: WorkOrderDialog.getProjectStates(projectTasks),
            sites: workOrderEdit.sites.slice(0),
            vehicles: workOrderEdit.vehicles.slice(0),
            workOrderAcknowledgementTypes: workOrderEdit.workOrderAcknowledgementTypes.slice(0),
            workOrderStates: workOrderEdit.workOrderStates.slice(0),
            workOrderTypes: workOrderEdit.workOrderTypes.slice(0),
            //Logo
            customerLogo: workOrderEdit.customerLogo,
            //Documents
            documents: workOrder.documents.slice(0),
            //RoutePoints
            routePoints: workOrder.routePoints.slice(0),
            //Storage
            storageProductBookings: workOrder.storageProductBookings.slice(0),
            //Bookings
            hourBookings: workOrder.hourBookings.slice(0),
            rideBookings: workOrder.rideBookings.slice(0),
            productBookings: workOrder.productBookings.slice(0),
            routePointBookings: workOrder.routePointBookings.slice(0),
            workTimes: workOrder.workTimes.slice(0),
            //Dialogs
            showTaskWorkOrderDialog: false,
            taskWorkOrderDialogItem: null,
            showParentWorkOrderDialog: false,
            parentWorkOrderDialogItem: null,
            showCopyWorkOrderDialog: oldState?.showCopyWorkOrderDialog ?? false,
            showSaveWorkOrderAsTemplateDialog: oldState?.showSaveWorkOrderAsTemplateDialog ?? false,
            makeProductBookingsOnOpen: makeProductBookingsOnOpen,
            showCustomerDialog: false,
            customerDialogOkCallback: null,
            customerDialogItem: null,
            showSiteDialog: false,
            siteDialogSiteId: "",
            siteDialogSiteName: "",
            siteDialogCustomerId: "",
            siteDialogOkCallback: null,
            showSelectContactDialog: false,
            selectContactDialogName: "",
            selectContactDialogItems: [],
            selectContactDialogCustomerId: "",
            selectContactDialogOkCallback: null,
            showContactDialog: false,
            contactDialogItem: null,
            contactDialogCustomerId: "",
            contactDialogOkCallback: null,
            isSaving: false,
            selectedTab: oldState?.selectedTab ?? 0,
            isSubmitting: false
        };
    }

    constructor(props: IWorkOrderDialogProp) {
        super(props);
        this.state = WorkOrderDialog.getStateFromWorkOrderEdit(props.workOrderEdit, props.makeProductBookingsOnOpen);
    }

    componentDidMount() {
        if (this.props.getOwnerRoutePoints) {
            this.props.getOwnerRoutePoints();
        }
    }

    // #region Calculated properties
    isNew = (): boolean => {
        return !this.state.rowId;
    };

    isProject = (): boolean => {
        return EnumHelper.isEqual(this.state.category, WorkOrderCategory.Project);
    };

    isWork = (): boolean => {
        return EnumHelper.isEqual(this.state.category, WorkOrderCategory.Work);
    };

    isTask = (): boolean => {
        return EnumHelper.isEqual(this.state.category, WorkOrderCategory.Task);
    };

    isRecurringTask = (): boolean => {
        return this.isTask() && EnumHelper.isGreaterThan(this.state.parentRecurrenceType, RecurrenceType.NoRecurrence);
    };

    isPreliminary = (): boolean => {
        return EnumHelper.isEqual(this.state.state, WorkOrderState.Preliminary);
    };

    isLessThanPlanned = (): boolean => {
        const state = this.state;
        return EnumHelper.isLessThan(!this.isProject() ? state.state : Math.max(...state.projectStates), WorkOrderState.Planned);
    };

    isGreaterThanPlanned = (): boolean => {
        const state = this.state;
        return EnumHelper.isGreaterThan(!this.isProject() ? state.state : Math.max(...state.projectStates), WorkOrderState.Planned);
    };

    isReadOnly = (): boolean => {
        const isTransferred = EnumHelper.isEqual(!this.isProject() ? this.state.state : Math.max(...this.state.projectStates), WorkOrderState.Transferred);
        return isTransferred || this.state.canceled;
    };

    isGreaterThanDone = (): boolean => {
        const state = this.state;
        return EnumHelper.isGreaterThan(!this.isProject() ? state.state : Math.max(...state.projectStates), WorkOrderState.Done);
    };

    isRecurring = (): boolean => {
        return EnumHelper.isGreaterThan(this.state.recurrenceType, RecurrenceType.NoRecurrence);
    };

    isCanceled = (): boolean => {
        return !!this.state.canceled;
    };
    // #region Calculated properties

    // #region Customer
    handleUpdateCustomerDataByCustomerId = (customerId: string, callback: DSetContactSiteAndAcknowledgementType) => {
        const obj = this;
        if (!customerId) return;
        workOrderService.getCustomerData(customerId).then(customerData => {
            if (customerData && customerData.sites && customerData.contacts) {
                const contacts = customerData.contacts.slice(0);
                contacts.unshift(WorkOrderEditContact.createWorkOrderEditContact(Base.emptyGuid, "", "", "", ""));
                obj.setState({
                    sites: customerData.sites,
                    contacts: contacts,
                    products: customerData.products,
                    customerLogo: customerData.customerLogo
                });
                if (callback) {
                    callback(contacts.map(i => i.id), customerData.sites.map(i => i.id), customerData.workOrderAcknowledgementTypeId);
                }
            }
        });
    };

    getCustomerEditItem = (customerId: string, name: string, callback: (customerId: string) => void) => {
        const obj = this;
        if (!customerId) return;
        CustomerOperations.getCustomerEdit(customerId)
            .then(editItem => {
                if (name) {
                    editItem.customer.name = name;
                }
                obj.setState({
                    showCustomerDialog: true,
                    customerDialogItem: editItem,
                    customerDialogOkCallback: callback
                });
            });
    };

    handleAddCustomer = (name: string, callback: (customerId: string) => void) => {
        this.getCustomerEditItem(Base.emptyGuid, name, callback);
    };

    handleEditCustomer = (customerId: string, callback: (customerId: string) => void) => {
        this.getCustomerEditItem(customerId, null, callback);
    };

    handleCustomerDialogOk = (customerId: string, name: string) => {
        const state = this.state;
        const customerEdit = state.customerDialogItem;
        if (!customerEdit) return;
        const customers = state.customers.filter(i => i.id !== customerEdit.customer.id);
        customers.push(IdTitle.createIdTitle(customerId, name));
        IdTitle.sortIdTitles(customers, "title", true);
        this.setState({
            customers: customers,
            showCustomerDialog: false,
            customerDialogItem: null
        });
        if (this.state.customerDialogOkCallback) {
            this.state.customerDialogOkCallback(customerId);
        }
    };

    handleCustomerDialogCancel = () => {
        this.setState({
            showCustomerDialog: false,
            customerDialogItem: null
        });
    };
    // #endregion Customer

    // #region Site
    getSiteEdit = (customerId: string, siteId: string, name: string, callback: (siteId: string) => void) => {
        this.setState({
            showSiteDialog: true,
            siteDialogSiteId: siteId,
            siteDialogSiteName: name,
            siteDialogCustomerId: customerId,
            siteDialogOkCallback: callback
        });
    };

    handleAddSite = (customerId: string, name: string, callback: (siteId: string) => void) => {
        this.getSiteEdit(customerId, Base.emptyGuid, name, callback);
    };

    handleEditSite = (customerId: string, siteId: string) => {
        if (!siteId || siteId === Base.emptyGuid) return;
        this.getSiteEdit(customerId, siteId, null, null);
    };

    handleSiteDialogOk = (item: ICustomerEditItemSiteItem) => {
        const obj = this;
        const sites = obj.state.sites.filter(i => i.id !== item.id).slice(0);
        const routePoint = item.routePoints.length > 0 ? item.routePoints[0] : new RoutePointItem();
        sites.push(WorkOrderEditSite.createWorkOrderEditSite(item.id, item.name, item.name2, routePoint.street, routePoint.postalCode, routePoint.city, item.distance, routePoint.mapLink, routePoint.longitude, routePoint.latitude, item.rideBookingRequired, item.familiarizationType));
        WorkOrderEditSite.sortWorkOrderEditSites(sites, "name", true);
        obj.setState({
            sites: sites,
            showSiteDialog: false,
            siteDialogSiteId: null
        });
        if (obj.state.siteDialogOkCallback) {
            obj.state.siteDialogOkCallback(item.id);
        }
        if (this.props.getOwnerRoutePoints) {
            this.props.getOwnerRoutePoints();
        }
    };

    handleSiteDialogCancel = () => {
        this.setState({
            showSiteDialog: false,
            siteDialogSiteId: null
        });
    };
    // #endregion Site

    // #region Contact
    getContact = (customerId: string, contactId: string, name: string, callback: (contactId: string) => void) => {
        const obj = this;
        ContactOperations.getContactEdit(contactId)
            .then(editItem => {
                if (name) {
                    editItem.contact.name = name;
                }
                obj.setState({
                    showContactDialog: true,
                    contactDialogItem: editItem,
                    contactDialogCustomerId: customerId,
                    contactDialogOkCallback: callback
                });
            });
    };

    handleAddContact = (customerId: string, name: string, callback: (contactId: string) => void) => {
        this.getContact(customerId, Base.emptyGuid, name, (contactId: string) => {
            CustomerOperations.addCustomerContact(customerId, contactId)
                .then(success => {
                    store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                    callback(contactId);
                });
        });
    };

    /**
     * Contact is added to state if it hasn't been previously attached to another route point.
     */
    handleAddContactFromRoutePointCopy = (contactId: string): void => {
        ContactOperations.getContactEdit(contactId).then((contact) => {
            this.state.contacts.push(new WorkOrderEditContact(contact.contact));
        });
        CustomerOperations.addCustomerContact(this.state.customerId, contactId);
    };

    handleAttachContact = (customerId: string, name: string, callback: (contactId: string) => void) => {
        const obj = this;
        contactService.getWorkOrderEditContacts(obj.state.contacts.map(i => i.id)).then(workOrderEditContacts => {
            obj.setState({
                showSelectContactDialog: true,
                selectContactDialogName: name,
                selectContactDialogCustomerId: customerId,
                selectContactDialogOkCallback: callback,
                selectContactDialogItems: workOrderEditContacts.items
            });
        });
    };

    setNewContact = (contactId: string, name: string, phone: string, email: string, comment: string, callback: (contactId: string) => void) => {
        const contacts = this.state.contacts.filter(i => i.id !== contactId);
        contacts.push(WorkOrderEditContact.createWorkOrderEditContact(contactId, name, phone, email, comment));
        WorkOrderEditContact.sortWorkOrderEditContacts(contacts, "name", true);
        this.setState({
            contacts: contacts,
            showSelectContactDialog: false
        });
        if (callback) {
            callback(contactId);
        }
    };

    handleSelectContactDialogOk = (contactId: string, name: string, phone: string, email: string, comment: string) => {
        const obj = this;
        const state = this.state;
        if (!contactId) return;
        if (state.selectContactDialogCustomerId) {
            //Connect contact to customer
            CustomerOperations.addCustomerContact(state.selectContactDialogCustomerId, contactId)
                .then(success => {
                    store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                    obj.setNewContact(contactId, name, phone, email, comment, state.selectContactDialogOkCallback);
                });
        } else {
            //Add contact before customer is selected
            obj.setNewContact(contactId, name, phone, email, comment, state.selectContactDialogOkCallback);
        }
    };

    handleSelectContactDialogCancel = () => {
        this.setState({
            showSelectContactDialog: false
        });
    };

    handleEditContact = (customerId: string, contactId: string) => {
        if (!contactId || contactId === Base.emptyGuid) return;
        this.getContact(customerId, contactId, null, null);
    };

    handleContactDialogOk = (id: string, name: string, phone: string, email: string, comment: string) => {
        if (!id) return;
        const state = this.state;
        const contacts = state.contacts.slice(0).filter(i => i.id !== id);
        contacts.push(WorkOrderEditContact.createWorkOrderEditContact(id, name, phone, email, comment));
        WorkOrderEditContact.sortWorkOrderEditContacts(contacts, "name", true);
        this.setState({
            contacts: contacts,
            showContactDialog: false,
            contactDialogItem: null
        });
        if (state.contactDialogOkCallback) {
            state.contactDialogOkCallback(id);
        }
    };

    handleContactDialogCancel = () => {
        this.setState({
            showContactDialog: false,
            contactDialogItem: null
        });
    };
    // #endregion Contact

    // #region Project Task
    handleAddTask = () => {
        const obj = this;
        const state = this.state;
        workOrderService.getWorkOrderEdit(Base.emptyGuid, null, WorkOrderCategory.Task, state.id)
            .then(workOrderEdit => {
                obj.setState({
                    showTaskWorkOrderDialog: true,
                    taskWorkOrderDialogItem: workOrderEdit
                });
            });
    };

    handleEditTask = (id: string) => {
        const obj = this;
        workOrderService.getWorkOrderEdit(id, null, null, null)
            .then(workOrderEdit => {
                obj.setState({
                    showTaskWorkOrderDialog: true,
                    taskWorkOrderDialogItem: workOrderEdit
                });
            });
    };

    refreshProject = () => {
        const obj = this;
        workOrderService.getWorkOrderEdit(this.state.id, null, null, null).then(workOrderEdit => {
            const workOrder = workOrderEdit.workOrder;
            const projectTasks = workOrder.projectTasks.slice(0);
            WorkOrderEditItemWorkOrderTask.sortWorkOrderEditItemWorkOrderTasks(projectTasks);
            obj.setState({
                rowId: workOrder.rowId,
                documents: workOrder.documents.slice(0),
                hourBookings: workOrder.hourBookings.slice(0),
                rideBookings: workOrder.rideBookings.slice(0),
                productBookings: workOrder.productBookings.slice(0),
                projectTasks: projectTasks,
                projectStates: WorkOrderDialog.getProjectStates(projectTasks)
            });
        });
    };

    handleWorkOrderDialogOk = (openWorkOrderId: string, makeProductBookingsOnOpen: boolean) => {
        this.setState({
            showTaskWorkOrderDialog: false
        });
        this.refreshProject();
    };

    handleWorkOrderDialogCancel = () => {
        this.setState({
            showTaskWorkOrderDialog: false
        });
        this.refreshProject();
    };
    // #endregion Project Task

    // #region Documents
    handleDocumentsModified = (documents: IDocument[]) => {
        if (!documents) return;
        this.setState({ documents: documents });
    };

    handleDocumentRemoved = (documentId: string) => {
        if (!documentId) return;
        this.setState({ documents: this.state.documents.filter(i => i.id !== documentId) });
    };
    // #endregion Documents

    // #region RoutePoints
    handleRoutePointsModified= (modifiedRoutePoints: IRoutePointItem[], removedRoutePointIds: string[]) => {
        const props = this.props;
        if (modifiedRoutePoints.length < 1 && removedRoutePointIds.length < 1) return;
        const routePoints = this.state.routePoints.filter(i => removedRoutePointIds.indexOf(i.id) < 0).concat(modifiedRoutePoints);
        RoutePointItem.sortRoutePointItems(routePoints);
        this.setState({ routePoints: routePoints });
        if (props.getOwnerRoutePoints) {
            props.getOwnerRoutePoints();
        }
    };
    // #endregion RoutePoints

    // #region HourBookings
    handleHourBookingModified = (hourBooking: IWorkOrderEditItemHourBooking) => {
        if (!hourBooking) return;
        const hourBookings = this.state.hourBookings.filter(i => i.id !== hourBooking.id);
        hourBookings.push(hourBooking);
        WorkOrderEditItemHourBooking.sortWorkOrderEditItemHourBookings(hourBookings, "date", true);
        this.setState({ hourBookings: hourBookings });
    };

    handleHourBookingRemoved = (hourBookingId: string) => {
        if (!hourBookingId) return;
        this.setState({ hourBookings: this.state.hourBookings.filter(i => i.id !== hourBookingId) });
    };
    // #endregion HourBookings

    // #region ProductBookings
    handleProductBookingModified = (productBooking: IWorkOrderEditItemProductBooking) => {
        if (!productBooking) return;
        const productBookings = this.state.productBookings.filter(i => i.id !== productBooking.id);
        productBookings.push(productBooking);
        WorkOrderEditItemProductBooking.sortWorkOrderEditItemProductBookings(productBookings, this.state.products, "product", true);
        this.setState({ productBookings: productBookings });
    };

    handleProductBookingRemoved = (productBookingId: string) => {
        if (!productBookingId) return;
        this.setState({ productBookings: this.state.productBookings.filter(i => i.id !== productBookingId) });
    };
    // #endregion ProductBookings

    // #region RideBookings
    handleRideBookingModified = (rideBooking: IWorkOrderEditItemRideBooking) => {
        if (!rideBooking) return;
        const rideBookings = this.state.rideBookings.filter(i => i.id !== rideBooking.id);
        rideBookings.push(rideBooking);
        WorkOrderEditItemRideBooking.sortWorkOrderEditItemRideBookings(rideBookings, "date", true);
        this.setState({ rideBookings: rideBookings });
    };

    handleRideBookingRemoved = (rideBookingId: string) => {
        if (!rideBookingId) return;
        this.setState({ rideBookings: this.state.rideBookings.filter(i => i.id !== rideBookingId) });
    };
    // #endregion RideBookings

    // #region Converting to and from Project
    handleConvertToProjectClick = () => {
        const obj = this;
        const state = this.state;
        if (this.isReadOnly()) return;
        WorkOrderOperations.convertToProject(this.isWork(), this.isNew(), state.id, (newId: string) => {
            if (obj.props.onCreated) {
                obj.props.onCreated(newId);
            }
            obj.props.onOk(newId, false);
        });
    };

    handleDetachFromProjectClick = () => {
        const obj = this;
        const state = this.state;
        if (this.isReadOnly()) return;
        WorkOrderOperations.detachFromProject(this.isTask(), this.isNew(), state.parentId, state.id, state.parentTaskCount, () => {
            obj.props.onCancel();
        });
    };
    // #endregion Converting to and from Project

    // #region Canceling
    handleCancelWorkOrderClick = () => {
        const props = this.props;
        const state = this.state;
        WorkOrderOperations.cancelWorkOrder(state.canceled, state.id, () => {
            props.onCancel();
        });
    };

    handleRestoreWorkOrderClick = () => {
        const props = this.props;
        const state = this.state;
        WorkOrderOperations.restoreWorkOrder(state.canceled, state.id, () => {
            props.onCancel();
        });
    };
    // #endregion Canceling

    // #region Set Duration
    handleSetDurationByEstimatedHours = () => {
        const obj = this;
        const state = this.state;
        if (this.isReadOnly()) return;
        WorkOrderOperations.setWorkOrderDuration([state.id], state.estimatedHours, () => {
            obj.handleRefreshWorkOrder();
        });
    };
    // #endregion Set Duration

    // #region ParentProject
    handleEditParentProject = () => {
        const obj = this;
        workOrderService.getWorkOrderEdit(this.state.parentId, null, null, null).then(parentWorkOrderEdit => {
            obj.setState({
                showParentWorkOrderDialog: true,
                parentWorkOrderDialogItem: parentWorkOrderEdit
            });
        });
    };

    refreshParentProjectInfo = () => {
        const obj = this;
        workOrderService.getWorkOrderEdit(this.state.id, null, null, null).then(parentWorkOrderEdit => {
            const workOrder = parentWorkOrderEdit.workOrder;
            obj.setState({
                parentNumber: workOrder.parentNumber,
                parentName: workOrder.parentName,
                parentRecurrenceType: workOrder.parentRecurrenceType,
                parentCustomerName: IdTitle.getTitleById(parentWorkOrderEdit.customers, workOrder.customerId),
                parentSite: parentWorkOrderEdit.sites.find(i => i.id === workOrder.siteId),
                parentContact: workOrder.contactId ? parentWorkOrderEdit.contacts.find(i => i.id === workOrder.contactId) : null,
                showParentWorkOrderDialog: false,
                parentWorkOrderDialogItem: null
            });
        });
    };

    handleParentWorkOrderDialogOk = (openWorkOrderId: string, makeProductBookingsOnOpen: boolean) => {
        this.setState({
            showParentWorkOrderDialog: false
        });
        this.refreshParentProjectInfo();
    };

    handleParentWorkOrderDialogCancel = () => {
        this.setState({
            showParentWorkOrderDialog: false
        });
        this.refreshParentProjectInfo();
    };

    // Updates visible tabs and other work order type settings immediately when switching work order type
    handleSetWorkOrderType = (workOrderType: WorkOrderEditWorkOrderTypeItem) => {
        this.setState({
            workOrderType: workOrderType,
            workOrderTypeId: workOrderType.id,
        });
    };
    // #endregion ParentProject

    // #endregion General
    handleOpenMapLink = (mapLink: string) => {
        Base.openMapLinkInMaps(mapLink);
    };

    handleSaveGeneral = (callback?: () => void) => {
        const obj = this;
        if (!this.workOrderDialogGeneralRef || obj.state.isSubmitting) return;
        this.setState({
            isSubmitting: true,
        });
        this.workOrderDialogGeneralRef.current.saveWorkOrderAndCloseGeneral((saved: boolean, workOrderId: string, makeProductBookingsAfterSave: boolean) => {
            if (obj.isNew() && obj.props.onCreated) {
                obj.props.onCreated(workOrderId);
            }
            obj.refreshWorkOrderToState(workOrderId);
            if (callback) {
                callback();
            }
        });
    };

    handleCancelGeneral = (afterSaveCallback: (saved: boolean, workOrderId: string, makeProductBookingsAfterSave: boolean) => void = null) => {
        const obj = this;
        if (!this.workOrderDialogGeneralRef) return;
        this.workOrderDialogGeneralRef.current.cancelWorkOrderEdit((saved: boolean, workOrderId: string, makeProductBookingsAfterSave: boolean) => {
            if (saved && obj.isNew() && obj.props.onCreated) {
                obj.props.onCreated(workOrderId);
            }
            if (afterSaveCallback) {
                afterSaveCallback(saved, workOrderId, makeProductBookingsAfterSave);
            } else {
                if (saved) {
                    obj.refreshWorkOrderToState(workOrderId);
                }
            }
        });
    };

    // Generic prompt with callback. There are only "yes" and "cancel" options. Saving the work order resets state which closes dialogs etc.
    promptSaveUnsavedChanges = (callback: () => void) => {
        if (this.workOrderDialogGeneralRef.current?.hasChanges()) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    this.handleSaveGeneral(callback);
                },
                null,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }, null, null, null, "hidden"));
        } else {
            callback();
        }
    };

    handleSubmitEnd = () => {
        this.setState({
            isSubmitting: false,
        });
    };
    // #endregion General

    // #endregion Copy WorkOrder
    handleCopyWorkOrder = () => {
        if (this.isNew() || !this.props.onCopied) return;
        this.setState({
            showCopyWorkOrderDialog: true
        });
    };

    handleCopyWorkOrderDialogOk = (workOrderEdit: IWorkOrderEdit) => {
        this.setState({
            showCopyWorkOrderDialog: false,
        });
        this.props.onCopied(workOrderEdit);
    };

    handleCopyWorkOrderDialogCancel = () => {
        this.setState({
            showCopyWorkOrderDialog: false,
        });
    };
    // #endregion Copy WorkOrder

    // #endregion Save WorkOrder as Template
    handleSaveAsWorkOrderTemplate = () => {
        if (this.state.template || !this.props.onCopied) return;
        this.setState({
            showSaveWorkOrderAsTemplateDialog: true
        });
    };

    handleSaveWorkOrderAsTemplateDialogOk = (workOrderId: string) => {
        this.setState({
            showSaveWorkOrderAsTemplateDialog: false,
        });
    };

    handleSaveWorkOrderAsTemplateDialogCancel = () => {
        this.setState({
            showSaveWorkOrderAsTemplateDialog: false,
        });
    };
    // #endregion WorkOrder as Template

    removeWorkOrder = (id: string) => {
        const obj = this;
        WorkOrderOperations.removeWorkOrderSub([id])
            .then(success => {
                if (success) {
                    obj.props.onCancel();
                }
            });
    };

    handleRemoveWorkOrder = () => {
        const obj = this;
        const state = this.state;
        if (!state.id) return;
        WorkOrderOperations.removeWorkOrder(this.isProject(), [state.id], state.number, state.projectTasks.length > 0, state.acknowledgementSent, () => {
            obj.props.onCancel();
        });
    };

    handlePrintClick = () => {
        WorkOrderOperations.printWorkOrder(this.state.id);
    };

    editWorkOrderItem = (id: string, sourceId: string, category: number, parentId: string) => {
        const obj = this;
        if (!id) return;
        WorkOrderOperations.getWorkOrderEdit(id, sourceId, category, parentId)
            .then(workOrderEdit => {
                obj.setState({
                    showParentWorkOrderDialog: true,
                    makeProductBookingsOnOpen: true,
                    taskWorkOrderDialogItem: workOrderEdit,
                });
            });
    };

    handleCloseDialog = () => {
        const props = this.props;
        if (this.isReadOnly()) {
            props.onCancel();
            return;
        }
        if (!this.workOrderDialogGeneralRef?.current) return;
        this.handleCancelGeneral((saved: boolean, workOrderId: string, makeProductBookingsAfterSave: boolean) => {
            if (saved) {
                props.onOk(makeProductBookingsAfterSave ? workOrderId : null, makeProductBookingsAfterSave);
            } else {
                props.onCancel();
            }
        });
    };

    handleSetMakeProductBookingsOnOpenFalse = () => {
        this.setState({
            makeProductBookingsOnOpen: false

        });
    };

    refreshWorkOrderToState = (workOrderId: string) => {
        const obj = this;
        workOrderService.getWorkOrderEdit(workOrderId, null, null, null)
            .then(workOrderEdit => {
                obj.setState(WorkOrderDialog.getStateFromWorkOrderEdit(workOrderEdit, false, obj.state));
            });
    };

    handleRefreshWorkOrder = () => {
        this.refreshWorkOrderToState(this.state.id);
    };

    handleSetRecurrenceType = (recurrenceType: number) => {
        this.setState({ recurrenceType: recurrenceType });
    };

    handleReorderTasks = (tasks: IWorkOrderEditItemWorkOrderTask[]) => {
        const obj = this;
        const reserve = [...obj.state.projectTasks];
        let newTaskList = tasks;

        // Optimistic setState before actual save
        obj.setState({
            projectTasks: newTaskList,
            isSaving: true
        }, () => {
            workOrderService.setWorkOrderSortOrder(tasks).catch(e => {
                // Revert list back to original order on error
                newTaskList = reserve;
                store.customStore.dispatch(storeActions.showErrorMessage(Translations.WorkOrderSortOrderError));
            }).finally(() => {
                obj.setState({
                    projectTasks: newTaskList,
                    isSaving: false
                });
            });
        });
    };

    handleSwitchHasSequentialTasks = (sequentialTasksState: boolean) => {
        const obj = this;

        obj.setState({
            hasSequentialTasks: sequentialTasksState
        });
    };

    handleTabChange = (event: React.SyntheticEvent, selectedTab: number) => {
        if (this.isNew() || (this.state.selectedTab === 0 && this.workOrderDialogGeneralRef.current?.hasChanges())) {
            this.handleSaveGeneral(() => {
                this.setState({ selectedTab });
            });
        } else {
            this.setState({ selectedTab });
        }
    };

    updateStorageProductBookings = (storageProductBookings: IStorageProductBooking[]) => {
        this.setState({ storageProductBookings });
    };

    handleSetShowCollectionListInMobile = async(value: boolean) => {
        const state = this.state;
        const result = await AppUtils.callService(() => workOrderService.setShowCollectionListInMobile(state.id, value), false);
        if (!result) return;
        this.setState({
            showCollectionListInMobile: value,
            rowId: result.rowId
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        const site = WorkOrderEditSite.getWorkOrderEditSiteBySiteId(state.sites, state.siteId);
        const dialogClasses = "workOrder px1400" + (props.classes ? " " + props.classes : "");
        const workOrderType = EnumHelper.isEqual(state.category, WorkOrderCategory.Project) ? null : WorkOrderEditWorkOrderTypeItem.getWorkOrderEditWorkOrderTypeItemById(props.workOrderEdit.workOrderTypes, state.workOrderTypeId);
        const showEstimatedHours = workOrderType?.showEstimatedHours;
        return (

            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={WorkOrderEditItem.getUiTitleStatic(this.isNew(), this.isProject(), this.isRecurring(), this.isWork(), state.number, state.parentNumber, state.name, this.isCanceled(), true, state.template)}
                    show={true}
                    body={<div>
                        <div className="workOrder editView row">
                            <Box sx={{ ml: 3, pr: 8 }} className="col-12">
                                <Tabs
                                    variant={!this.isProject() ? "fullWidth" : "standard"}
                                    value={state.selectedTab}
                                    onChange={this.handleTabChange}
                                >
                                    <Tab label={Translations.General} value={0}/>
                                    {state.workOrderType?.routePointsUsed() &&
                                        <Tab label={Translations.Route} value={1}/>
                                    }
                                    {(props.storageEnabled && !this.isProject()) &&
                                        <Tab label={Translations.Storage} value={2}/>
                                    }
                                    {this.isProject() &&
                                        <Tab label={Translations.WorkOrderTasks} value={3}/>
                                    }
                                    {state.workOrderType?.showAttachments &&
                                        <Tab label={Translations.Attachments} value={4}/>
                                    }
                                    {(!this.isRecurring() && state.workOrderType?.showRoutePointBookings) &&
                                        <Tab label={Translations.ToBeInvoicedRecords} value={5}/>
                                    }
                                    {(!this.isRecurring() && state.workOrderType?.showRoutePointBookings) &&
                                        <Tab label={Translations.Bookings} value={6}/>
                                    }
                                    <Tab label={Translations.Events} value={7} disabled={props.workOrderEdit.eventLogs.length === 0}/>
                                </Tabs>
                            </Box>

                            <div className="workOrderContentArea col-12">
                                <TabPanel index={0} selectedTab={state.selectedTab}>
                                    <WorkOrderDialogGeneral
                                        ref={this.workOrderDialogGeneralRef}
                                        workTimeFormat={props.workOrderEdit.workTimeFormat}
                                        id={state.id}
                                        rowId={state.rowId}
                                        contactId={state.contactId}
                                        customerId={state.customerId}
                                        employeeIds={state.employeeIds}
                                        vehicleIds={state.vehicleIds}
                                        siteId={state.siteId}
                                        workOrderAcknowledgementTypeId={state.workOrderAcknowledgementTypeId}
                                        workOrderTypeId={state.workOrderTypeId}
                                        category={state.category}
                                        description={state.description}
                                        endTime={state.endTime}
                                        fullDay={state.fullDay}
                                        name={state.name}
                                        startTime={state.startTime}
                                        state={state.state}
                                        canceled={state.canceled}
                                        comment={state.comment}
                                        estimatedHours={state.estimatedHours}
                                        parentId={state.parentId}
                                        parentName={state.parentName}
                                        parentNumber={state.parentNumber}
                                        parentCustomerName={state.parentCustomerName}
                                        parentSite={state.parentSite}
                                        parentContact={state.parentContact}
                                        isProject={this.isProject()}
                                        isTask={this.isTask()}
                                        isWork={this.isWork()}
                                        isRecurringTask={this.isRecurringTask()}
                                        isGreaterThanPlanned={this.isGreaterThanPlanned()}
                                        isGreaterThanDone={this.isGreaterThanDone()}
                                        isReadOnly={this.isReadOnly()}
                                        isPreliminary={this.isPreliminary()}
                                        enabledStates={this.props.enabledStates}
                                        isRecurring={this.isRecurring()}
                                        recurrenceType={state.recurrenceType}
                                        recurrenceInterval={state.recurrenceInterval}
                                        recurrenceIntervalDetail={state.recurrenceIntervalDetail}
                                        recurrenceOccurrenceAmount={state.recurrenceOccurrenceAmount}
                                        recurrenceEndDate={state.recurrenceEndDate}
                                        invoiceId={state.invoiceId}
                                        invoiceNumber={state.invoiceNumber}
                                        contacts={state.contacts}
                                        customers={state.customers}
                                        employees={state.employees}
                                        vehicles={state.vehicles}
                                        projectTasks={state.projectTasks}
                                        projectStates={state.projectStates}
                                        sites={state.sites}
                                        workOrderAcknowledgementTypes={state.workOrderAcknowledgementTypes}
                                        workOrderStates={state.workOrderStates}
                                        workOrderTypes={state.workOrderTypes}
                                        customerLogo={state.customerLogo}
                                        openedFromProject={props.openedFromProject}
                                        hourBookings={state.hourBookings}
                                        rideBookings={state.rideBookings}
                                        routePointBookings={state.routePointBookings}
                                        onAddContact={this.handleAddContact}
                                        onAttachContact={this.handleAttachContact}
                                        onEditContact={this.handleEditContact}
                                        onAddCustomer={this.handleAddCustomer}
                                        onEditCustomer={this.handleEditCustomer}
                                        onEditParentProject={this.handleEditParentProject}
                                        onAddSite={this.handleAddSite}
                                        onEditSite={this.handleEditSite}
                                        openMapLink={this.handleOpenMapLink}
                                        onRefreshWorkOrder={this.handleRefreshWorkOrder}
                                        onSetRecurrenceType={this.handleSetRecurrenceType}
                                        onUpdateCustomerDataByCustomerId={this.handleUpdateCustomerDataByCustomerId}
                                        removeWorkOrder={this.removeWorkOrder}
                                        sortOrder={state.sortOrder}
                                        hasSequentialTasks={state.hasSequentialTasks}
                                        onSwitchHasSequentialTasks={this.handleSwitchHasSequentialTasks}
                                        onWorkOrderTypeChange={this.handleSetWorkOrderType}
                                        onSubmitEnd={this.handleSubmitEnd}
                                    />
                                </TabPanel>
                                {(state.workOrderType?.routePointsUsed() && state.selectedTab === 1) &&
                                    <TabPanel index={1} selectedTab={state.selectedTab}>
                                        <WorkOrderDialogRoutePointListConnected
                                            plannedRoute={!this.isGreaterThanPlanned()}
                                            title={Translations.Route}
                                            titleClass={"title"}
                                            isReadOnly={this.isNew() || this.isGreaterThanDone()}
                                            mapLinkTemplate={props.workOrderEdit.mapLinkTemplate}
                                            directionsMapLinkTemplate={props.workOrderEdit.directionsMapLinkTemplate}
                                            customerId={state.customerId}
                                            vehicles={state.vehicles.filter(i => state.vehicleIds.indexOf(i.id) > -1)}
                                            employees={state.employees.filter(i => state.employeeIds.indexOf(i.id) > -1)}
                                            workOrderId={state.id}
                                            workOrderIsProject={this.isProject()}
                                            routePointWorkShiftTimeSlotTypes={props.workOrderEdit.routePointWorkShiftTimeSlotTypes.filter(i => state.workOrderType?.workShiftTimeSlotTypeIds.indexOf(i.id) > -1)}
                                            routePointTypes={props.workOrderEdit.routePointTypes}
                                            contacts={state.contacts}
                                            routePoints={state.routePoints}
                                            productBookings={state.storageProductBookings}
                                            onAddContact={this.handleAddContact}
                                            onAddContactFromRoutePointCopy={this.handleAddContactFromRoutePointCopy}
                                            onAttachContact={this.handleAttachContact}
                                            onEditContact={this.handleEditContact}
                                            onRoutePointsModified={this.handleRoutePointsModified}
                                        />
                                    </TabPanel>
                                }
                                {(props.storageEnabled && !this.isProject()) &&
                                    <TabPanel index={2} selectedTab={state.selectedTab}>
                                        <WorkOrderStorageProducts
                                            routePoints={state.routePoints}
                                            storageProductBookings={state.storageProductBookings}
                                            isReadOnly={this.isGreaterThanDone() || state.canceled}
                                            isPreliminary={this.isPreliminary()}
                                            workOrderId={state.id}
                                            showCollectionListInMobile={state.showCollectionListInMobile}
                                            onChange={this.updateStorageProductBookings}
                                            onCloseDialog={() => this.handleCloseDialog()}
                                            onSetShowCollectionListInMobile={this.handleSetShowCollectionListInMobile}
                                        />
                                    </TabPanel>
                                }
                                {this.isProject() &&
                                    <TabPanel index={3} selectedTab={state.selectedTab}>
                                        <WorkOrderDialogWorkerOrderTaskList
                                            workOrderStates={state.workOrderStates}
                                            employees={state.employees}
                                            vehicles={state.vehicles}
                                            employeeIds={state.employeeIds}
                                            vehicleIds={state.vehicleIds}
                                            name={state.name}
                                            description={state.description}
                                            startTime={state.startTime}
                                            endTime={state.endTime}
                                            fullDay={state.fullDay}
                                            estimatedHours={state.estimatedHours}
                                            showEstimatedHours={showEstimatedHours}
                                            recurrenceType={state.recurrenceType}
                                            recurrenceInterval={state.recurrenceInterval}
                                            recurrenceIntervalDetail={state.recurrenceIntervalDetail}
                                            recurrenceOccurrenceAmount={state.recurrenceOccurrenceAmount}
                                            recurrenceEndDate={state.recurrenceEndDate}
                                            title={Translations.WorkOrderTasks}
                                            titleId={"tasks"}
                                            titleClass={"title"}
                                            tasks={state.projectTasks}
                                            customerName={IdTitle.getTitleById(state.customers, state.customerId)}
                                            workOrderId={state.id}
                                            workOrderIsRecurring={this.isRecurring()}
                                            isReadOnly={this.isNew() || props.openedFromTask || this.isReadOnly()}
                                            onAddTask={this.handleAddTask}
                                            onEditTask={this.handleEditTask}
                                            onTaskRemoved={this.refreshProject}
                                            onTasksConnected={this.refreshProject}
                                            onTasksAdded={this.refreshProject}
                                            onTasksModified={this.refreshProject}
                                            onReorderTasks={this.handleReorderTasks}
                                            hasSequentialTasks={state.hasSequentialTasks}
                                            isSaving={state.isSaving}
                                            hasSequentialTasksSwitchRef={this.hasSequentialTasksSwitchRef}
                                            onSwitchHasSequentialTasks={this.handleSwitchHasSequentialTasks}
                                            isProject={this.isProject()}
                                        />
                                    </TabPanel>
                                }
                                {state.workOrderType?.showAttachments &&
                                    <TabPanel index={4} selectedTab={state.selectedTab}>
                                        <WorkOrderDialogDocumentList
                                            title={Translations.Attachments}
                                            titleId={"attachments"}
                                            titleClass={"title"}
                                            documents={state.documents}
                                            isReadOnly={this.isNew() || this.isGreaterThanDone() || this.isReadOnly()}
                                            workOrderId={state.id}
                                            onDocumentsModified={this.handleDocumentsModified}
                                            onDocumentRemoved={this.handleDocumentRemoved}
                                        />
                                    </TabPanel>
                                }
                                {!this.isRecurring() && state.workOrderType?.showRoutePointBookings &&
                                    <TabPanel index={5} selectedTab={state.selectedTab}>
                                        <WorkOrderDialogInvoicedRecordsList
                                            isReadOnly={this.isNew() || this.isProject() || this.isGreaterThanDone() || this.isReadOnly()}
                                            title={Translations.ToBeInvoicedRecords}
                                            titleId={"toBeInvoicedRecords"}
                                            titleClass={"title"}
                                            employeeIds={state.employeeIds}
                                            workOrderId={state.id}
                                            productBookings={state.productBookings}
                                            products={state.products}
                                            measureUnits={props.workOrderEdit.measureUnits}
                                            employees={state.employees}
                                            onProductBookingModified={this.handleProductBookingModified}
                                            onProductBookingRemoved={this.handleProductBookingRemoved}
                                        />
                                    </TabPanel>
                                }
                                {!this.isRecurring() && state.workOrderType?.showRoutePointBookings &&
                                    <TabPanel index={6} selectedTab={state.selectedTab}>
                                        <WorkOrderDialogBookingsList
                                            isReadOnly={this.isNew() || this.isProject() || this.isGreaterThanDone() || this.isReadOnly()}
                                            workTimeFormat={props.workOrderEdit.workTimeFormat}
                                            title={Translations.Bookings}
                                            titleId="bookings"
                                            titleClass="title"
                                            employeeIds={state.employeeIds}
                                            workOrderId={state.id}
                                            workOrderStartTime={state.startTime}
                                            hourBookings={state.hourBookings}
                                            showHourBookingInvoiceHours={false}
                                            site={site}
                                            rideBookings={state.rideBookings}
                                            employees={state.employees}
                                            vehicles={state.vehicles}
                                            onHourBookingModified={this.handleHourBookingModified}
                                            onHourBookingRemoved={this.handleHourBookingRemoved}
                                            onRideBookingModified={this.handleRideBookingModified}
                                            onRideBookingRemoved={this.handleRideBookingRemoved}
                                        />
                                        <WorkOrderDialogWorkTimeList
                                            workTimeFormat={props.workOrderEdit.workTimeFormat}
                                            title={Translations.WorkingTimeRecords}
                                            titleId={"workTimes"}
                                            titleClass={"title"}
                                            workTimes={state.workTimes}
                                        />
                                    </TabPanel>
                                }
                                {props.workOrderEdit.eventLogs.length > 0 &&
                                    <TabPanel index={7} selectedTab={state.selectedTab}>
                                        <ObjectEventLogList
                                            classes={"workOrderSubList"}
                                            title={Translations.Events}
                                            titleId={"events"}
                                            titleClass={"title"}
                                            items={props.workOrderEdit.eventLogs}
                                        />
                                    </TabPanel>
                                }
                            </div>
                        </div>
                        {state.showCustomerDialog && !Base.isNullOrUndefined(state.customerDialogItem) &&
                            <SettingsCustomerDialog
                                sitesReadOnly={false}
                                contactsReadOnly={false}
                                customerEdit={state.customerDialogItem}
                                onOk={this.handleCustomerDialogOk}
                                onCancel={this.handleCustomerDialogCancel}
                            />
                        }
                        {state.showSiteDialog && state.siteDialogSiteId &&
                            <SettingsSiteDialog
                                readOnly={false}
                                customerId={state.siteDialogCustomerId}
                                siteId={state.siteDialogSiteId}
                                newSiteName={state.siteDialogSiteName}
                                onOk={this.handleSiteDialogOk}
                                onCancel={this.handleSiteDialogCancel}
                            />
                        }
                        {state.showSelectContactDialog &&
                            <WorkOrderDialogSelectContactDialog
                                name={state.selectContactDialogName}
                                selectedId={""}
                                items={state.selectContactDialogItems}
                                onOk={this.handleSelectContactDialogOk}
                                onCancel={this.handleSelectContactDialogCancel}
                            />
                        }
                        {state.showContactDialog && !Base.isNullOrUndefined(state.contactDialogItem) &&
                            <SettingsContactDialog
                                customersReadOnly={true}
                                editItem={state.contactDialogItem}
                                onOk={this.handleContactDialogOk}
                                onCancel={this.handleContactDialogCancel}
                            />
                        }
                        {state.showTaskWorkOrderDialog && !!state.taskWorkOrderDialogItem &&
                            <WorkOrderDialog
                                workOrderEdit={state.taskWorkOrderDialogItem}
                                makeProductBookingsOnOpen={false}
                                openedFromProject={true}
                                onOk={this.handleWorkOrderDialogOk}
                                onCopied={null}
                                onCancel={this.handleWorkOrderDialogCancel}
                            />
                        }
                        {state.showParentWorkOrderDialog && !!state.parentWorkOrderDialogItem &&
                            <WorkOrderDialogConnected
                                workOrderEdit={state.parentWorkOrderDialogItem}
                                makeProductBookingsOnOpen={false}
                                openedFromTask={true}
                                onOk={this.handleParentWorkOrderDialogOk}
                                onCopied={null}
                                onCancel={this.handleParentWorkOrderDialogCancel}
                            />
                        }
                        {state.showCopyWorkOrderDialog &&
                            <WorkOrderCopyDialog
                                workOrderId={state.id}
                                number={state.number}
                                parentNumber={state.parentNumber}
                                name={state.name}
                                isProject={this.isProject()}
                                isRecurring={this.isRecurring()}
                                isWork={this.isWork()}
                                hasEmployees={state.employeeIds.length > 0}
                                hasVehicles={state.vehicleIds.length > 0}
                                hasRoute={state.routePoints.length > 0}
                                hasBookings={state.hourBookings.length + state.productBookings.length + state.rideBookings.length > 0}
                                hasDocuments={state.documents.length > 0}
                                hasTasks={state.projectTasks.length > 0}
                                onOk={this.handleCopyWorkOrderDialogOk}
                                onCancel={this.handleCopyWorkOrderDialogCancel}
                            />
                        }
                        {state.showSaveWorkOrderAsTemplateDialog &&
                            <WorkOrderSaveAsTemplateDialogConnected
                                workOrderId={state.id}
                                number={state.number}
                                parentNumber={state.parentNumber}
                                name={state.name}
                                description={state.description}
                                isProject={this.isProject()}
                                isRecurring={this.isRecurring()}
                                isWork={this.isWork()}
                                hasEmployees={state.employeeIds.length > 0}
                                hasVehicles={state.vehicleIds.length > 0}
                                hasRoute={state.routePoints.length > 0}
                                hasBookings={state.hourBookings.length + state.productBookings.length + state.rideBookings.length > 0}
                                hasDocuments={state.documents.length > 0}
                                hasTasks={state.projectTasks.length > 0}
                                onOk={this.handleSaveWorkOrderAsTemplateDialogOk}
                                onCancel={this.handleSaveWorkOrderAsTemplateDialogCancel}
                            />
                        }
                    </div>}
                    buttons={
                        [
                            { title: (Translations.Save), classes: "btn-primary" + (state.selectedTab !== 0 ? " hidden" : ""), enabled: !state.isSubmitting && !this.isReadOnly(), onClick: () => this.handleSaveGeneral(), mainButton: true },
                            { title: (Translations.Print), classes: "btn-default", enabled: !this.isNew(), onClick: this.handlePrintClick },
                            { title: (Translations.Copy), classes: "btn-default", enabled: !this.isNew() && !!props.onCopied, onClick: () => this.promptSaveUnsavedChanges(this.handleCopyWorkOrder) },
                            { title: (Translations.SaveAsWorkOrderTemplate), classes: "btn-default", enabled: !state.template && !this.isRecurring() && !!props.onCopied, onClick: () => this.promptSaveUnsavedChanges(this.handleSaveAsWorkOrderTemplate) },
                            { title: (Translations.ConvertToProject), classes: "btn-default", enabled: !this.isReadOnly(), onClick: !this.isNew() && this.isWork() ? () => this.promptSaveUnsavedChanges(this.handleConvertToProjectClick) : null },
                            { title: (this.isRecurringTask() ? Translations.DetachFromRecurringWork : Translations.DetachFromProject), classes: "btn-default", enabled: !this.isReadOnly(), onClick: !this.isNew() && this.isTask() ? this.handleDetachFromProjectClick : null },
                            { title: (Translations.CancelWorkOrder), classes: "btn-default", enabled: !this.isNew() && !this.isCanceled() && !this.isProject() && !this.isReadOnly(), onClick: () => this.promptSaveUnsavedChanges(this.handleCancelWorkOrderClick) },
                            { title: (Translations.RestoreWorkOrder), classes: "btn-default", enabled: !this.isNew() && this.isCanceled() && !this.isProject() && !this.isReadOnly(), onClick: this.handleRestoreWorkOrderClick },
                            { title: (Translations.SetDurationByEstimatedHours), classes: "btn-default", enabled: !this.isNew() && !this.isReadOnly(), onClick: showEstimatedHours && (this.isWork() || this.isTask()) ? () => this.promptSaveUnsavedChanges(this.handleSetDurationByEstimatedHours) : null },
                            { title: (Translations.Remove), classes: "btn-default", enabled: !this.isNew(), onClick: this.handleRemoveWorkOrder }
                        ]
                    }
                    onClose={this.handleCloseDialog}
                />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getOwnerRoutePoints: () => dispatch(storeEffects.getOwnerRoutePoints()),
    };
}

function mapStateToProps() {
    return {
        storageEnabled: new OwnerParameters().getOwnerHasStorageEnabled(),
    };
}

export const WorkOrderDialogConnected = connect(mapStateToProps, mapDispatchToProps)(WorkOrderDialog);
