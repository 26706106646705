import { Base } from "../../framework/base";
import { IEditItem, EditItem } from "../common/editItem";
import { WorkOrderTypeSiteRoutePointUsageType, WorkOrderTypeEmployeeUsageType, WorkOrderTypeVehicleUsageType, WorkOrderTypeLocationUsageType, WorkOrderTypeHoursInvoicingType,
    WorkOrderTypeKmsInvoicingType
} from "../common/enums";
import { IWorkOrderTypeEditItemWorkShiftTimeSlotType, WorkOrderTypeEditItemWorkShiftTimeSlotType } from "./workOrderTypeEditItemWorkShiftTimeSlotType";
import { IWorkShiftTimeSlotType } from "../workShiftTimeSlotType/workShiftTimeSlotType";

export interface IWorkOrderTypeEditItem extends IEditItem {
    title: string;
    vatRate: number;
    siteRoutePointUsageType: WorkOrderTypeSiteRoutePointUsageType;
    employeeUsageType: WorkOrderTypeEmployeeUsageType;
    vehicleUsageType: WorkOrderTypeVehicleUsageType;
    locationUsageType: WorkOrderTypeLocationUsageType;
    hoursInvoicingType: WorkOrderTypeHoursInvoicingType;
    hoursInvoicingProductId: string;
    kmsInvoicingType: WorkOrderTypeKmsInvoicingType;
    kmsInvoicingProductId: string;
    showAttachments: boolean;
    showComment: boolean;
    showDescription: boolean;
    showMap: boolean;
    showRoutePointBookings: boolean;
    showWorkOrderAcknowledgementType: boolean;
    showEstimatedHours: boolean;
    workOrderTypeWorkShiftTimeSlotTypes: IWorkOrderTypeEditItemWorkShiftTimeSlotType[];
    showWorkOrderTypeInWorkQueue: boolean;
    locationDataUpdateIntervalS: number;
    canBeCreatedInMobile: boolean;

    getTitle(): string;
}

export class WorkOrderTypeEditItem extends EditItem implements IWorkOrderTypeEditItem {
    title: string;
    vatRate: number;
    siteRoutePointUsageType: WorkOrderTypeSiteRoutePointUsageType;
    employeeUsageType: WorkOrderTypeEmployeeUsageType;
    vehicleUsageType: WorkOrderTypeVehicleUsageType;
    locationUsageType: WorkOrderTypeLocationUsageType;
    hoursInvoicingType: WorkOrderTypeHoursInvoicingType;
    hoursInvoicingProductId: string;
    kmsInvoicingType: WorkOrderTypeKmsInvoicingType;
    kmsInvoicingProductId: string;
    showAttachments: boolean;
    showComment: boolean;
    showDescription: boolean;
    showMap: boolean;
    showRoutePointBookings: boolean;
    showWorkOrderAcknowledgementType: boolean;
    showEstimatedHours: boolean;
    workOrderTypeWorkShiftTimeSlotTypes: WorkOrderTypeEditItemWorkShiftTimeSlotType[];
    showWorkOrderTypeInWorkQueue: boolean;
    locationDataUpdateIntervalS: number;
    canBeCreatedInMobile: boolean;

    constructor();
    constructor(obj: IWorkOrderTypeEditItem);
    constructor(obj?: any) {
        super(obj);
        this.title = obj && obj.title || "";
        this.vatRate = obj && obj.vatRate || 0;
        this.siteRoutePointUsageType = obj && obj.siteRoutePointUsageType || WorkOrderTypeSiteRoutePointUsageType.Site;
        this.employeeUsageType = obj && obj.employeeUsageType || WorkOrderTypeEmployeeUsageType.NotInUse;
        this.vehicleUsageType = obj && obj.vehicleUsageType || WorkOrderTypeVehicleUsageType.NotInUse;
        this.locationUsageType = obj && obj.locationUsageType || WorkOrderTypeLocationUsageType.NotInUse;
        this.hoursInvoicingType = obj && obj.hoursInvoicingType || WorkOrderTypeHoursInvoicingType.NotInUse;
        this.hoursInvoicingProductId = obj && obj.hoursInvoicingProductId || "";
        this.kmsInvoicingType = obj && obj.kmsInvoicingType || WorkOrderTypeKmsInvoicingType.NotInUse;
        this.kmsInvoicingProductId = obj && obj.kmsInvoicingProductId || "";
        this.showAttachments = obj && !Base.isNullOrUndefined(obj.showAttachments) ? obj.showAttachments : true;
        this.showComment = obj && !Base.isNullOrUndefined(obj.showComment) ? obj.showComment : true;
        this.showDescription = obj && !Base.isNullOrUndefined(obj.showDescription) ? obj.showDescription : true;
        this.showMap = obj && !Base.isNullOrUndefined(obj.showMap) ? obj.showMap : true;
        this.showRoutePointBookings = obj && !Base.isNullOrUndefined(obj.showRoutePointBookings) ? obj.showRoutePointBookings : true;
        this.showWorkOrderAcknowledgementType = obj && !Base.isNullOrUndefined(obj.showWorkOrderAcknowledgementType) ? obj.showWorkOrderAcknowledgementType : true;
        this.showEstimatedHours = obj && !Base.isNullOrUndefined(obj.showEstimatedHours) ? obj.showEstimatedHours : true;
        this.workOrderTypeWorkShiftTimeSlotTypes = Base.getTypedArray<WorkOrderTypeEditItemWorkShiftTimeSlotType>(obj ? obj.workOrderTypeWorkShiftTimeSlotTypes : null, WorkOrderTypeEditItemWorkShiftTimeSlotType);
        this.showWorkOrderTypeInWorkQueue = obj && !Base.isNullOrUndefined(obj.showWorkOrderTypeInWorkQueue) ? obj.showWorkOrderTypeInWorkQueue : true;
        this.locationDataUpdateIntervalS = obj && obj.locationDataUpdateIntervalS || 0;
        this.canBeCreatedInMobile = obj && !Base.isNullOrUndefined(obj.canBeCreatedInMobile) ? obj.canBeCreatedInMobile : true;
    }
    
    getTitle(): string {
        return this.title;
    }
    
    setSortValues = (workShiftTimeSlotTypes: IWorkShiftTimeSlotType[]) => {
        for (const workOrderTypeWorkShiftTimeSlotType of this.workOrderTypeWorkShiftTimeSlotTypes) {
            workOrderTypeWorkShiftTimeSlotType.setSortValue(workShiftTimeSlotTypes);
        }
    }
}
