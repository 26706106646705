// WorkOrderEditRouteEditorWorkShiftTimeSlotList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as workOrderService from "../../services/workOrderService";
import { Base } from "../../framework/base";
import { Translations } from "../../models/translations";
import { IdTitle } from "../../models/common/idTitle";
import { IWorkShiftTimeSlotType } from "../../models/workShiftTimeSlotType/workShiftTimeSlotType";
import { IRoutePointWorkShiftTimeSlotItem, RoutePointSaveWorkShiftTimeSlotItem, RoutePointWorkShiftTimeSlotItem } from "../../models/routePoint/routePointWorkShiftTimeSlotItem";
import { ToolButton } from "../framework/toolButton";
import { AppUtils } from "../../models/common/appUtils";
import { ConfirmationDialogResult, WorkShiftTimeSlotState } from "../../models/common/enums";
import { IVehicleItem } from "../../models/vehicle/vehicleItem";
import { IEmployeeItem } from "../../models/employee/employeeItem";
import { WorkOrderEditRouteEditorWorkShiftTimeSlotDialog } from "./workOrderEditRouteEditorWorkShiftTimeSlotDialog";
import { EditItem } from "../../models/common/editItem";
import { SaveData } from "../../framework/saveData";

// WorkOrderEditRouteEditorWorkShiftTimeSlotListItem
interface IWorkOrderEditRouteEditorWorkShiftTimeSlotListItem {
    id: string;
    workShiftTimeSlotTypeId: string;
    employeeId: string;
    employeeName: string;
    routePointId: string;
    vehicleId: string;
    title: string;
    startTime: number;
    endTime: number;
    description: string;
    durationMin: number;
    rowId: string;
    category: number;
    hasOverlap: boolean;
}

class WorkOrderEditRouteEditorWorkShiftTimeSlotListItem implements IWorkOrderEditRouteEditorWorkShiftTimeSlotListItem {
    id: string;
    workShiftTimeSlotTypeId: string;
    employeeId: string;
    employeeName: string;
    routePointId: string;
    vehicleId: string;
    title: string;
    startTime: number;
    endTime: number;
    description: string;
    durationMin: number;
    rowId: string;
    sortValue: number;
    category: number;
    hasOverlap: boolean;

    constructor();
    constructor(obj: IRoutePointWorkShiftTimeSlotItem);
    constructor(obj: IWorkOrderEditRouteEditorWorkShiftTimeSlotListItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.workShiftTimeSlotTypeId = obj && obj.workShiftTimeSlotTypeId || "";
        this.employeeId = obj && obj.employeeId || "";
        this.employeeName = obj && obj.employeeName || "";
        this.routePointId = obj && obj.routePointId || "";
        this.vehicleId = obj && obj.vehicleId || "";
        this.title = obj && obj.title || "";
        this.startTime = obj && obj.startTime || 0;
        this.endTime = obj && obj.endTime || 0;
        this.vehicleId = obj && obj.vehicleId || "";
        this.description = obj && obj.description || 0;
        this.rowId = obj && obj.rowId || "";
        this.category = obj && obj.category || 0;
        this.hasOverlap = obj && obj.hasOverlap || false;
    }

    static sortWorkTimeListItems(items: IWorkOrderEditRouteEditorWorkShiftTimeSlotListItem[]) {
        if (!items || items.length < 2) return;
        items.sort((a: IWorkOrderEditRouteEditorWorkShiftTimeSlotListItem, b: IWorkOrderEditRouteEditorWorkShiftTimeSlotListItem) => {
            if (!a || !b) {
                return 0;
            }
            const result = Base.numberCompare(a.startTime, b.startTime);
            if (result !== 0) return result;
            return Base.numberCompare(a.category, b.category);
        });
    }
}
// WorkOrderEditRouteEditorWorkShiftTimeSlotListLine
export interface IWorkOrderEditRouteEditorWorkShiftTimeSlotListLineProp {
    workShiftTimeSlot: IWorkOrderEditRouteEditorWorkShiftTimeSlotListItem;
    employees: IEmployeeItem[];
    selected: boolean;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

interface IWorkOrderEditRouteEditorWorkShiftTimeSlotListLineState {
    employeeName: string;
}

export class WorkOrderEditRouteEditorWorkShiftTimeSlotListLine extends React.Component<IWorkOrderEditRouteEditorWorkShiftTimeSlotListLineProp, IWorkOrderEditRouteEditorWorkShiftTimeSlotListLineState> {
    constructor(props: IWorkOrderEditRouteEditorWorkShiftTimeSlotListLineProp) {
        super(props);
        this.state = {
            employeeName: IdTitle.getTitleById(props.employees, props.workShiftTimeSlot.employeeId),
        };
    }

    componentDidUpdate(prevProps: IWorkOrderEditRouteEditorWorkShiftTimeSlotListLineProp, prevState: IWorkOrderEditRouteEditorWorkShiftTimeSlotListLineState): void {
        const props = this.props;
        if (props.workShiftTimeSlot.employeeId === prevProps.workShiftTimeSlot.employeeId) return;
        this.setState({
            employeeName: IdTitle.getTitleById(props.employees, props.workShiftTimeSlot.employeeId),
        });
    }

    render() {
        const props = this.props;
        const state = this.state;
        const workShiftTimeSlot = props.workShiftTimeSlot;
        const isNotFinished = !workShiftTimeSlot.endTime;
        return (
            <div className={"row line" + (props.selected ? " selected" : "") + (workShiftTimeSlot.hasOverlap ? " hasOverlap" : "")} onClick={() => props.onClick(workShiftTimeSlot.id)} onDoubleClick={() => props.onDoubleClick(workShiftTimeSlot.id)}>
                <div className="col-md-4 col-6">
                    {isNotFinished &&
                        <svg className="clock"/>
                    }
                    {Base.timeToDateStr(workShiftTimeSlot.startTime) + " " + Base.timeToTimeStr(workShiftTimeSlot.startTime)}
                </div>
                <div className="col-md-3 col-6">{workShiftTimeSlot.title}</div>
                <div className="d-none d-sm-none d-md-block col-md-4">{state.employeeName}</div>
                <div className="d-none d-sm-none d-md-block col-md-1 text-right">{(isNotFinished ? "(" : "") + AppUtils.getDurationStrByDurationMinShort(workShiftTimeSlot.durationMin) + (isNotFinished ? ")" : "") }</div>
            </div>
        );
    }
}

// WorkOrderEditRouteEditorWorkShiftTimeSlotList
export interface IWorkOrderEditRouteEditorWorkShiftTimeSlotListProp {
    title?: string;
    titleId?: string;
    titleClass?: string;
    isReadOnly: boolean;
    workOrderId: string;
    routePointId: string;
    workShiftTimeSlotTypes: IWorkShiftTimeSlotType[];
    employeeId: string;
    employees: IEmployeeItem[];
    vehicles: IVehicleItem[];
    workShiftTimeSlots: IRoutePointWorkShiftTimeSlotItem[];
    onWorkShiftTimeSlotsModified: (workShiftTimeSlots: IRoutePointWorkShiftTimeSlotItem[], removedWorkShiftTimeSlotIds: string[]) => void;
}

interface IWorkOrderEditRouteEditorWorkShiftTimeSlotListState {
    workShiftTimeSlots: IWorkOrderEditRouteEditorWorkShiftTimeSlotListItem[];
    selectedId: string;
    showEditDialog: boolean;
    editDialogItem: IWorkOrderEditRouteEditorWorkShiftTimeSlotListItem;
}

export class WorkOrderEditRouteEditorWorkShiftTimeSlotList extends React.Component<IWorkOrderEditRouteEditorWorkShiftTimeSlotListProp, IWorkOrderEditRouteEditorWorkShiftTimeSlotListState> {
    setWorkShiftTimeSlotsOverlappings = (workShiftTimeSlotListItems: IWorkOrderEditRouteEditorWorkShiftTimeSlotListItem[]) => {
        const nowTime = new Date().getTime();
        let prevWorkShiftTimeSlotListItem: IWorkOrderEditRouteEditorWorkShiftTimeSlotListItem = null;
        for (const workShiftTimeSlotListItem of workShiftTimeSlotListItems) {
            if (prevWorkShiftTimeSlotListItem) {
                const isOverlapping = Base.isOverlapping(Base.getMinuteAccuracyTime(prevWorkShiftTimeSlotListItem.startTime), Base.getMinuteAccuracyTime(prevWorkShiftTimeSlotListItem.endTime ? prevWorkShiftTimeSlotListItem.endTime : nowTime),
                    Base.getMinuteAccuracyTime(workShiftTimeSlotListItem.startTime), Base.getMinuteAccuracyTime(workShiftTimeSlotListItem.endTime ? workShiftTimeSlotListItem.endTime : nowTime));
                if (isOverlapping) {
                    prevWorkShiftTimeSlotListItem.hasOverlap = true;
                }
            }
            prevWorkShiftTimeSlotListItem = workShiftTimeSlotListItem;
        }
    };

    getWorkShiftTimeSlots = (props: IWorkOrderEditRouteEditorWorkShiftTimeSlotListProp): IWorkOrderEditRouteEditorWorkShiftTimeSlotListItem[] => {
        const result: IWorkOrderEditRouteEditorWorkShiftTimeSlotListItem[] = [];
        for (const workShiftTimeSlot of props.workShiftTimeSlots) {
            const workShiftTimeSlotListItem = new WorkOrderEditRouteEditorWorkShiftTimeSlotListItem(workShiftTimeSlot);
            const workShiftTimeSlotType = props.workShiftTimeSlotTypes.find(i => i.id === workShiftTimeSlot.workShiftTimeSlotTypeId);
            workShiftTimeSlotListItem.title = workShiftTimeSlotType ? workShiftTimeSlotType.title : "";
            workShiftTimeSlotListItem.category = workShiftTimeSlotType ? workShiftTimeSlotType.category : 0;
            workShiftTimeSlotListItem.startTime = workShiftTimeSlot.startTime;
            workShiftTimeSlotListItem.endTime = workShiftTimeSlot.endTime;
            workShiftTimeSlotListItem.durationMin = Base.dateDiffInMinutes(new Date(workShiftTimeSlot.startTime), workShiftTimeSlot.endTime ? new Date(workShiftTimeSlot.endTime) : new Date());
            workShiftTimeSlotListItem.employeeName = IdTitle.getTitleById(props.employees, workShiftTimeSlot.employeeId);
            result.push(workShiftTimeSlotListItem);
        }
        this.setWorkShiftTimeSlotsOverlappings(result);
        return result;
    };

    constructor(props: IWorkOrderEditRouteEditorWorkShiftTimeSlotListProp) {
        super(props);
        this.state = {
            selectedId: "",
            workShiftTimeSlots: this.getWorkShiftTimeSlots(props),
            showEditDialog: false,
            editDialogItem: null
        };
    }

    componentDidUpdate(prevProps: IWorkOrderEditRouteEditorWorkShiftTimeSlotListProp, prevState: IWorkOrderEditRouteEditorWorkShiftTimeSlotListState): void {
        const props = this.props;
        const state = this.state;
        if (EditItem.getHash(prevProps.workShiftTimeSlots) === EditItem.getHash(props.workShiftTimeSlots)) return;
        this.setState({
            selectedId: props.workShiftTimeSlots.find(i => i.id === state.selectedId) ? state.selectedId : "",
            workShiftTimeSlots: this.getWorkShiftTimeSlots(props)
        });
    }

    handleLineClick = (id: string) => {
        if (!id) return;
        this.setState({
            selectedId: id
        });
    };

    handleLineDoubleClick = (id: string) => {
        this.handleEdit(id);
    };

    handleEdit = (id: string) => {
        const state = this.state;
        const workShiftTimeSlot = state.workShiftTimeSlots.find(i => i.id === id);
        if (!workShiftTimeSlot) return;
        this.setState({
            showEditDialog: true,
            editDialogItem: workShiftTimeSlot,
            selectedId: id,
        });
    };

    handleAdd = () => {
        const props = this.props;
        if (props.isReadOnly || props.employees.length < 1 || props.workShiftTimeSlotTypes.length < 1) return;
        const workShiftTimeSlot = new WorkOrderEditRouteEditorWorkShiftTimeSlotListItem();
        const employeeId = props.employeeId ? props.employeeId : props.employees[0].id;
        workShiftTimeSlot.id = Base.getGuid();
        workShiftTimeSlot.employeeId = employeeId;
        workShiftTimeSlot.employeeName = IdTitle.getTitleById(props.employees, employeeId);
        workShiftTimeSlot.routePointId = props.routePointId;
        workShiftTimeSlot.vehicleId = props.vehicles.length > 0 ? props.vehicles[0].id : "";
        const workShiftTimeSlotType = props.workShiftTimeSlotTypes[0];
        workShiftTimeSlot.workShiftTimeSlotTypeId = workShiftTimeSlotType.id;
        workShiftTimeSlot.title = workShiftTimeSlotType.getTitle();
        workShiftTimeSlot.category = workShiftTimeSlotType.category;
        workShiftTimeSlot.startTime = 0;
        workShiftTimeSlot.endTime = 0;
        workShiftTimeSlot.durationMin = 0;
        workShiftTimeSlot.description = "";
        this.setState({
            showEditDialog: true,
            editDialogItem: workShiftTimeSlot,
            selectedId: workShiftTimeSlot.id,
        });
    };

    getRoutePointWorkShiftTimeSlotItem = (workShiftTimeSlot: IWorkOrderEditRouteEditorWorkShiftTimeSlotListItem): RoutePointWorkShiftTimeSlotItem => {
        const result = new RoutePointWorkShiftTimeSlotItem(this.props.workShiftTimeSlots.find(i => i.id === workShiftTimeSlot.id));
        result.id = workShiftTimeSlot.id;
        result.workShiftTimeSlotTypeId = workShiftTimeSlot.workShiftTimeSlotTypeId;
        result.routePointId = workShiftTimeSlot.routePointId;
        result.employeeId = workShiftTimeSlot.employeeId;
        result.vehicleId = workShiftTimeSlot.vehicleId;
        result.state = result.endTime ? WorkShiftTimeSlotState.Done : WorkShiftTimeSlotState.InProgress;
        result.startTime = workShiftTimeSlot.startTime;
        result.endTime = workShiftTimeSlot.endTime ? workShiftTimeSlot.endTime : null;
        result.description = workShiftTimeSlot.description;
        return result;
    };

    saveWorkShiftTimeSlot = async(workShiftTimeSlotListItem: IWorkOrderEditRouteEditorWorkShiftTimeSlotListItem) => {
        const props = this.props;
        //Create savedata
        const workShiftTimeSlot = this.getRoutePointWorkShiftTimeSlotItem(workShiftTimeSlotListItem);
        const workShiftTimeSlots = [workShiftTimeSlot];
        const saveData = new SaveData();
        saveData.append("workOrderId", props.workOrderId);
        saveData.append("routePointId", props.routePointId);
        saveData.append("workShiftTimeSlots", JSON.stringify(workShiftTimeSlots.reduce((result, i) => {
            result.push(new RoutePointSaveWorkShiftTimeSlotItem(i));
            return result;
        }, [])));
        //Save to db
        const result = await AppUtils.callService(() => workOrderService.saveRoutePointWorkShiftTimeSlots(saveData.formData));
        if (!result) return false;
        //Return result
        const removedWorkShiftTimeSlotIds = workShiftTimeSlots.map(i => i.id);
        for (let i = 0; i < workShiftTimeSlots.length; i++) {
            const savedWorkShiftTimeSlot = workShiftTimeSlots[i];
            savedWorkShiftTimeSlot.id = result.ids[i];
            savedWorkShiftTimeSlot.rowId = result.rowIds[i];
        }
        props.onWorkShiftTimeSlotsModified(workShiftTimeSlots, removedWorkShiftTimeSlotIds);
        //Clse dialog
        this.setState({
            showEditDialog: false
        });
    };

    removeWorkShiftTimeSlot = async(id: string) => {
        const props = this.props;
        //Create savedata
        const workShiftTimeSlotIds = [id];
        const saveData = new SaveData();
        saveData.append("workOrderId", props.workOrderId);
        saveData.append("routePointId", props.routePointId);
        saveData.append("workShiftTimeSlotIds", JSON.stringify(workShiftTimeSlotIds));
        //Save to db
        const result = await AppUtils.callService(() => workOrderService.removeRoutePointWorkShiftTimeSlots(saveData.formData));
        if (!result) return;
        props.onWorkShiftTimeSlotsModified([], [id]);
    };

    handleRemove = async(id: string) => {
        if (!id) return;
        const confirmationResult = await AppUtils.showConfirmationDialog(Translations.WorkShiftTimeSlotRemoveConfirmation);
        if (confirmationResult !== ConfirmationDialogResult.Yes) return;
        this.removeWorkShiftTimeSlot(id);
    };

    handleEditDialogOk = (workShiftTimeSlot: IWorkOrderEditRouteEditorWorkShiftTimeSlotListItem) => {
        if (!workShiftTimeSlot) return;
        this.saveWorkShiftTimeSlot(workShiftTimeSlot);
    };

    handlEditDialogCancel = () => {
        this.setState({
            showEditDialog: false
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        const workShiftTimeSlots = state.workShiftTimeSlots;
        return (
            <div className="workShiftTimeSlots">
                <div className="commandRow">
                    {!!props.title &&
                        <label id={props.titleId} className={"control-label listTitle" + (props.titleClass ? " " + props.titleClass : "")}>{props.title}</label>
                    }
                    {!props.isReadOnly &&
                        <ToolButton
                            title={Translations.Add}
                            enabled={props.employees.length > 0}
                            classes={"round right add"}
                            onClick={this.handleAdd}
                        />
                    }
                    <ToolButton
                        title={Translations.Edit}
                        enabled={!!state.selectedId}
                        classes={"round right edit"}
                        onClick={() => this.handleEdit(state.selectedId)}
                    />
                    {!props.isReadOnly &&
                        <ToolButton
                            title={Translations.Remove}
                            enabled={!!state.selectedId}
                            classes={"round right remove"}
                            onClick={() => this.handleRemove(state.selectedId)}
                        />
                    }
                </div>
                <div className={"listContainer workShiftTimeSlotsContainer"}>
                    <div className="list workShiftTimeSlotsData">
                        {workShiftTimeSlots.map((workShiftTimeSlot) =>
                            <WorkOrderEditRouteEditorWorkShiftTimeSlotListLine
                                key={workShiftTimeSlot.id}
                                workShiftTimeSlot={workShiftTimeSlot}
                                employees={props.employees}
                                selected={state.selectedId === workShiftTimeSlot.id}
                                onClick={this.handleLineClick}
                                onDoubleClick={this.handleLineDoubleClick}
                            />
                        )}
                    </div>
                </div>
                {state.showEditDialog &&
                    <WorkOrderEditRouteEditorWorkShiftTimeSlotDialog
                        isReadOnly={false}
                        workShiftTimeSlotTypes={props.workShiftTimeSlotTypes}
                        employees={props.employees}
                        workShiftTimeSlot={state.editDialogItem}
                        onOk={this.handleEditDialogOk}
                        onCancel={this.handlEditDialogCancel}
                    />
                }
            </div>
        );
    }
}
