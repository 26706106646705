import { Base } from "../framework/base";
import { Session } from "../models/session/session";
import downloadjs from "downloadjs";

export interface IApiResponse {
    message: string;
}

export interface IApiError {
    status: number;
    message: string;
}

export class ApiError implements IApiError {
    status: number;
    message: string;

    constructor();
    constructor(obj: IApiError);
    constructor(obj?: any) {
        this.status = obj && obj.status || 200;
        this.message = obj && obj.message || "";
    }
}

export interface IApiSuccess {
    message: string;
    errorMessage: string;
}

export class ApiSuccess implements IApiSuccess {
    message: string;
    errorMessage: string;

    constructor();
    constructor(obj: IApiError);
    constructor(obj?: any) {
        this.message = obj && obj.message || "";
        this.errorMessage = obj && obj.errorMessage || "";
    }
}

export interface ISaveSuccess {
    message: string;
    errorMessage: string;
    id: string;
    rowId: string;
}

export class SaveSuccess implements ISaveSuccess {
    message: string;
    errorMessage: string;
    id: string;
    rowId: string;

    constructor();
    constructor(obj: ISaveSuccess);
    constructor(obj?: any) {
        this.message = obj && obj.message || "";
        this.errorMessage = obj && obj.errorMessage || "";
        this.id = obj && obj.id || "";
        this.rowId = obj && obj.rowId || "";
    }
}

export interface ISaveSuccessItems {
    message: string;
    ids: string[];
    rowIds: string[];
}

export class SaveSuccessItems implements ISaveSuccessItems {
    message: string;
    ids: string[];
    rowIds: string[];

    constructor();
    constructor(obj: ISaveSuccessItems);
    constructor(obj?: any) {
        this.message = obj && obj.message || "";
        this.ids = obj && obj.ids || [];
        this.rowIds = obj && obj.rowIds || [];
    }
}

export function isInstanceOfIApiError(object: any): object is IApiError {
    return object && !(typeof(object) === "string") && "message" in object && typeof(object.message) === "string";
}

export function getErrorMessageFromError(error: any): string {
    if (isInstanceOfIApiError(error)) {
        return error.message;
    } else if (typeof error === "string") {
        return error;
    }

    return JSON.stringify(error);
}

export function authorizationHeaderValue(): Promise<string> {
    return Session.getAccessToken().then(i => {
        if (i) {
            return "Bearer " + i;
        } else {
            return "";
        }
    });
}

export function authorizationHeaderRefreshTokenValue() {
    return Session.getRefreshToken().then(i => {
        if (i) {
            return "Bearer " + i;
        } else {
            return "";
        }
    });
}

function getErrorMessageFromBody(text: string): string {
    if (Base.isJsonString(text)) {
        const obj = JSON.parse(text);
        const message = obj.message ? obj.message : (obj.Message ? obj.Message : text);
        return message;
    } else {
        return text;
    }
}

export function getJsonHeaders(body: string) {
    return authorizationHeaderValue().then(i => {
        return {
            // "Cache-Control": "no-cache",
            // "Pragma": "no-cache",
            "Accept": "application/json",
            "Content-Type": "application/json;charset=utf-8",
            "Content-Length": (body ? body.length : 0).toString(10),
            "Authorization": i,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
        };
    });
}

export function getFormDataHeaders() {
    return authorizationHeaderValue().then(i => {
        return {
            "Accept": "application/json",
            "Authorization": i,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
        };
    });
}

export function fileResponseFileName(response: Response): string {
    let filename = response.headers.get("content-disposition");
    if (filename && filename.indexOf("attachment") !== -1) {
        const filenameRegex = /filename\*[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/ig;
        const matches = filenameRegex.exec(filename);
        if (matches != null && matches.length > 3 && matches[3]) {
            filename = matches[3].replace(/['"]/g, "");
            filename = decodeURIComponent(filename);
        }
    } else {
        filename = "";
    }
    return filename;
}

export function isFileResponse(response: Response): boolean {
    return !!fileResponseFileName(response);
}

export function handleResponse(response: Response): any {
    return new Promise<void>((resolve, reject) => {
        if (response.ok) {
            // return json if it was returned in the response
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                response.json().then(json => resolve(json));
            } else {
                resolve();
            }
        } else {
            // return error message
            const error = new ApiError();
            error.status = response.status;
            response.text().then(text => {
                error.message = getErrorMessageFromBody(text);
                reject(error);
            });
        }
    });
}

export function handleFileBlobResponse(response: Response): Promise<Blob> {
    console.log(response);
    return new Promise((resolve, reject) => {
        if (response.ok) {
            return resolve(response.blob());
        } else {
            // return error message
            const error = new ApiError();
            error.status = response.status;
            response.text().then(text => {
                error.message = getErrorMessageFromBody(text);
                reject(error);
            });
            return null;
        }
    });
}

export function handleFileResponse(response: Response): any {
    console.log(response);
    let filename: string;
    return new Promise((resolve, reject) => {
        if (response.ok) {
            filename = fileResponseFileName(response);
            return resolve(response.blob());
        } else {
            // return error message
            const error = new ApiError();
            error.status = response.status;
            response.text().then(text => {
                error.message = getErrorMessageFromBody(text);
                reject(error);
            });
            return null;
        }
    }).then(body => {
        downloadjs(body as Blob, filename, "application/octet-stream");
        return new Promise((resolve) => {
            return resolve(filename);
        });
    });
}
