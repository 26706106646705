// WorkOrderPlannedTimeEditor
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "../../framework/base";
import { AppUtils } from "../../models/common/appUtils";
import { ConfirmationDialogResult, EnumHelper, WorkOrderState } from "../../models/common/enums";
import { Translations } from "../../models/translations";
import { IWorkOrderEditItemHourBooking } from "../../models/work/workOrderEditItemHourBooking";
import * as employeeService from "../../services/employeeService";
import { Button } from "../framework/button";
import { DatePicker } from "../framework/datePicker";
import { TimeSelector } from "../framework/timeSelector";

export interface IWorkOrderPlannedTimeState {
    orgStartDateStr: string;
    orgStartTimeStr: string;
    startDateStr: string;
    startTimeStr: string;
    endDateStr: string;
    endTimeStr: string;
}

export interface IWorkOrderPlannedTimeEditorProp {
    workOrderState: number;
    employeeIds: string[];
    hourBookings: IWorkOrderEditItemHourBooking[];
    startTime: number;
    endTime: number;
    fullDay: boolean;
    disabled?: boolean;
    onSave: (startTime: number, endTime: number) => Promise<boolean>;
}

export interface IWorkOrderPlannedTimeEditorState {
    editMode: boolean;
    orgStartDateStr: string;
    orgStartTimeStr: string;
    startDateStr: string;
    startTimeStr: string;
    endDateStr: string;
    endTimeStr: string;
}

export class WorkOrderPlannedTimeEditor extends React.Component<IWorkOrderPlannedTimeEditorProp, IWorkOrderPlannedTimeEditorState> {
    private editorId: string;
    private orgStateHash: string = "";
    private isValidating = false;

    static getWorkOrderStartDateTimeStateChange = (orgStartDateStr: string, orgStartTimeStr: string, startDateStr: string, startTimeStr: string, endDateStr: string, endTimeStr: string, fullDay: boolean): IWorkOrderPlannedTimeState => {
        const orgStartDateTime = AppUtils.getDateTime(orgStartDateStr, orgStartTimeStr, fullDay);
        const startDateTime = AppUtils.getDateTime(startDateStr, startTimeStr, fullDay);
        let endDateTime = AppUtils.getDateTime(endDateStr, endTimeStr, fullDay);
        if (!endDateTime) {
            if (startDateTime) {
                endDateTime = startDateTime;
                if (!fullDay) {
                    endDateTime = endDateTime.addMinutes(120);
                }
                endDateStr = Base.dateToDateStr(endDateTime);
                endTimeStr = Base.dateToTimeStr(endDateTime);
            }
        } else {
            if (orgStartDateTime && startDateTime) {
                const differenceInMin = Base.dateDiffInMinutes(orgStartDateTime, startDateTime);
                endDateTime = endDateTime.addMinutes(differenceInMin);
                endDateStr = Base.dateToDateStr(endDateTime);
                endTimeStr = Base.dateToTimeStr(endDateTime);
            }
        }
        return {
            startDateStr: startDateStr,
            startTimeStr: startTimeStr,
            orgStartDateStr: startDateStr,
            orgStartTimeStr: startTimeStr,
            endDateStr: endDateStr,
            endTimeStr: endTimeStr
        };
    };

    static checkWorkOrderPlannedTimeErrors = (state: IWorkOrderPlannedTimeState, fullDay: boolean, workOrderState: number): Promise<boolean> => {
        return new Promise<boolean>((resolve) => {
            if (EnumHelper.isGreaterOrEqual(workOrderState, WorkOrderState.Planned)) {
                const startDateTime = AppUtils.getDateTime(state.startDateStr, state.startTimeStr, fullDay);
                let endDateTime = AppUtils.getDateTime(state.endDateStr, state.endTimeStr, fullDay);
                if (fullDay) {
                    endDateTime = endDateTime.addDays();
                }
                if (!startDateTime) {
                    AppUtils.showErrorMessage(Translations.StartDateMustBeDefined);
                    return resolve(false);
                }
                if (!endDateTime) {
                    AppUtils.showErrorMessage(Translations.EndDateMustBeDefined);
                    return resolve(false);
                }
                const duration = Math.max(0, Base.dateDiffInMinutes(startDateTime, endDateTime));
                if (duration < 1) {
                    AppUtils.showErrorMessage(Translations.WorkOrderDurationIsZero);
                    return resolve(false);
                }
            }
            return resolve(true);
        });
    };

    static getWorkOrderPlannedTimeWarningMessage = (state: IWorkOrderPlannedTimeState, workOrderState: number, employeeIds: string[], hourBookings: IWorkOrderEditItemHourBooking[]): Promise<string> => {
        return new Promise<string>((resolve) => {
            if (EnumHelper.isEqual(workOrderState, WorkOrderState.Planned)) {
                let warnings: string = "";
                // UMP
                // Contact
                //if (!isTask && (!state.contactId || state.contactId === Base.emptyGuid)) {
                //    warnings = Translations.ContactIsNotDefined + Base.lf;
                //}
                // StartTime
                const startTime = state.startTimeStr.toTime();
                const startDateTime = state.startDateStr.toDate().addHours(startTime.getHours()).addMinutes(startTime.getMinutes());
                const nowTime = Base.getNowDate();
                if (startDateTime < nowTime) {
                    warnings = warnings + Translations.StartDateIsInThePast + Base.lf;
                }
                // Free Time
                const endTime = state.endTimeStr.toTime();
                const endDateTime = state.endDateStr.toDate().addHours(endTime.getHours()).addMinutes(endTime.getMinutes());
                return employeeService.getEmployeesHaveTime(employeeIds, startDateTime.getTime(), endDateTime.getTime())
                    .then(success => {
                        return resolve(success ? warnings : (warnings + Translations.EmployeesHaveDayBookingsForWorkOrderTime + Base.lf));
                    })
                    .catch(error => {
                        console.log(error);
                        return resolve(warnings);
                    });
            } else if (EnumHelper.isEqual(workOrderState, WorkOrderState.Done)) {
                let warnings: string = "";
                // Hour booking for all work days
                const startDate = state.startDateStr.toDate();
                const endDate = state.endDateStr.toDate();
                /* eslint-disable no-unmodified-loop-condition */
                for (const d = startDate; d < endDate; d.setDate(d.getDate() + 1)) {
                    /* eslint-enable no-unmodified-loop-condition */
                    const date = d.getTime();
                    if (Base.isNullOrUndefined(hourBookings.find(i => i.date === date))) {
                        warnings = Translations.HourBookingsAreNotDefinedForAllWorkDays + Base.lf;
                    }
                }
                // UMP
                // Material bookings exist
                //if (state.productBookings.length < 1) {
                //    warnings = warnings + Translations.ProductBookingsAreNotDefined + Base.lf;
                //}
                return resolve(warnings);
            }
            return resolve("");
        });
    };

    getStateFromProps = (props: IWorkOrderPlannedTimeEditorProp, editMode: boolean): IWorkOrderPlannedTimeEditorState => {
        return {
            editMode: editMode,
            orgStartDateStr: Base.timeToDateStr(props.startTime),
            orgStartTimeStr: Base.timeToTimeStr(props.startTime),
            startDateStr: Base.timeToDateStr(props.startTime),
            startTimeStr: Base.timeToTimeStr(props.startTime),
            endDateStr: Base.timeToDateStr(props.endTime),
            endTimeStr: Base.timeToTimeStr(props.endTime)
        };
    };

    constructor(props: IWorkOrderPlannedTimeEditorProp) {
        super(props);
        this.editorId = Base.getGuid();
        this.state = this.getStateFromProps(props, false);
    }

    componentDidUpdate(prevProps: IWorkOrderPlannedTimeEditorProp, prevState: IWorkOrderPlannedTimeEditorState): void {
        const props = this.props;
        if ((prevProps.startTime ?? 0) === (props.startTime ?? 0) && (prevProps.endTime ?? 0) === (props.endTime ?? 0) && (prevProps.fullDay ?? false) === (props.fullDay ?? false)) return;
        this.setState(this.getStateFromProps(props, this.state.editMode));
    }

    private getHashFromState = (state: IWorkOrderPlannedTimeEditorState): string => {
        return JSON.stringify({
            startDateStr: state.startDateStr,
            startTimeStr: state.startTimeStr,
            endDateStr: state.endDateStr,
            endTimeStr: state.endTimeStr
        });
    };

    handleEdit = (ev: any) => {
        ev.stopPropagation();
        this.setState({
            editMode: true,
        });
        this.orgStateHash = this.getHashFromState(this.state);
    };

    handleClose = async() => {
        if (this.state.editMode) {
            const cancelResult = await AppUtils.cancel(this.getHashFromState(this.state), this.orgStateHash);
            if (cancelResult === ConfirmationDialogResult.Cancel) return;
            if (cancelResult === ConfirmationDialogResult.Yes) {
                this.handleSave();
                return;
            }
        }
        this.setState(this.getStateFromProps(this.props, false));
    };

    editorClickEvent = (ev: MouseEvent) => {
        if (this.isValidating) {
            return;
        }
        const element = ev.target as HTMLElement;
        const editor = element.closest("[data-id='" + this.editorId + "']");
        if (!editor) {
            this.handleClose();
        }
    };

    componentDidMount(): void {
        window.addEventListener("click", this.editorClickEvent, true);
    }

    componentWillUnmount(): void {
        window.removeEventListener("click", this.editorClickEvent);
    }

    handleStartDateChange = (value: string) => {
        this.setState({ startDateStr: value });
    };

    handleStartDateBlur = (value: string) => {
        const state = this.state;
        this.setState(WorkOrderPlannedTimeEditor.getWorkOrderStartDateTimeStateChange(state.orgStartDateStr, state.orgStartTimeStr, value, state.startTimeStr, state.endDateStr, state.endTimeStr, this.props.fullDay));
    };

    handleStartTimeChange = (value: string) => {
        const state = this.state;
        this.setState(WorkOrderPlannedTimeEditor.getWorkOrderStartDateTimeStateChange(state.orgStartDateStr, state.orgStartTimeStr, state.startDateStr, value, state.endDateStr, state.endTimeStr, this.props.fullDay));
    };

    handleEndDateChange = (value: string) => {
        this.setState({ endDateStr: value });
    };

    handleEndTimeChange = (value: string) => {
        this.setState({ endTimeStr: value });
    };

    private checkErrors = (): Promise<boolean> => {
        const props = this.props;
        return WorkOrderPlannedTimeEditor.checkWorkOrderPlannedTimeErrors(this.state, props.fullDay, props.workOrderState);
    };

    private getWarningMessage = (): Promise<string> => {
        const props = this.props;
        return WorkOrderPlannedTimeEditor.getWorkOrderPlannedTimeWarningMessage(this.state, props.workOrderState, props.employeeIds, props.hourBookings);
    };

    handleSave = async() => {
        const props = this.props;
        const state = this.state;
        this.isValidating = true;
        try {
            if (!await AppUtils.validate(this.checkErrors, this.getWarningMessage)) {
                return;
            };
        } finally {
            this.isValidating = false;
        }
        const startDateTime = AppUtils.getDateTime(state.startDateStr, state.startTimeStr, props.fullDay);
        let endDateTime = AppUtils.getDateTime(state.endDateStr, state.endTimeStr, props.fullDay);
        if (props.fullDay) {
            endDateTime = endDateTime.addDays();
        }
        const success = await this.props.onSave(startDateTime?.getTime(), endDateTime?.getTime());
        if (!success) {
            return;
        }
        this.setState({
            editMode: false
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <div data-id={this.editorId}>
                {!state.editMode &&
                    <div className="inputArea editable" onClick={this.handleEdit}>{AppUtils.getTimeDurationStr(props.startTime, props.endTime, props.fullDay)}</div>
                }
                {state.editMode &&
                    <div className="editableRegion">
                        <div className="grayRegion">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.StartTime}</label>
                                        <DatePicker
                                            classes="lOffsetM15"
                                            disabled={props.disabled}
                                            titleClasses={"smallFont"}
                                            value={state.startDateStr}
                                            onChange={this.handleStartDateChange}
                                            onBlur={this.handleStartDateBlur}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <TimeSelector
                                            disabled={props.disabled || props.fullDay}
                                            disabledValue={props.fullDay ? "0:00" : state.startTimeStr}
                                            date={state.startDateStr}
                                            value={state.startTimeStr}
                                            onChange={this.handleStartTimeChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.EndDate}</label>
                                        <DatePicker
                                            classes="lOffsetM15"
                                            disabled={props.disabled}
                                            titleClasses={"smallFont"}
                                            value={state.endDateStr}
                                            onChange={this.handleEndDateChange}
                                            onBlur={this.handleEndDateChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <TimeSelector
                                            date={state.endDateStr}
                                            disabled={props.disabled || props.fullDay}
                                            disabledValue={props.fullDay ? "0:00" : state.endTimeStr}
                                            startDate={state.startDateStr}
                                            startTime={state.startTimeStr}
                                            value={state.endTimeStr}
                                            onChange={this.handleEndTimeChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="editableRegionButtons text-right">
                            <Button
                                title={Translations.Cancel}
                                classes="btn-default rnd"
                                enabled={true}
                                onClick={this.handleClose}
                            />
                            <Button
                                title={Translations.Save}
                                classes="btn-primary rnd"
                                enabled={true}
                                onClick={this.handleSave}
                            />
                        </div>
                    </div>
                }
            </div>
        );
    }
}
