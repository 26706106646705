// SettingsToolDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as baseService from "../../services/baseService";
import * as storeActions from "../../models/store/storeActions";
import * as toolService from "../../services/toolService";
import { PropertyDialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { SaveData } from "../../framework/saveData";
import { CheckBox } from "../framework/checkbox";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { IToolEdit } from "../../models/tool/toolEdit";
import { IVehicleItem, VehicleItem } from "../../models/vehicle/vehicleItem";

// SettingsToolDialog
// ***********************************************************************************************************************
export interface ISettingsToolDialogProp {
    classes?: string;
    editItem: IToolEdit;
    onOk: () => void;
    onCancel: () => void;
}

export interface ISettingsToolDialogState {
    vehicles: IVehicleItem[];
    vehicleId: string;
    name: string;
    description: string;
    amountStr: string;
    comment: string;
    activeState: number;
}

export class SettingsToolDialog extends React.Component<ISettingsToolDialogProp, ISettingsToolDialogState> {
    private static orgStateHash: string = "";

    constructor(props) {
        super(props);
        const tool = props.editItem.tool;
        const vehicles = props.editItem.vehicles.slice(0);
        const unselectedVehicle = new VehicleItem();
        unselectedVehicle.id = Base.emptyGuid;
        vehicles.unshift(unselectedVehicle);
        this.state = {
            vehicles: vehicles, vehicleId: tool.vehicleId, name: tool.name, description: tool.description, amountStr: tool.amount.toString(10), comment: tool.comment, activeState: tool.activeState
        };
        const saveData = SettingsToolDialog.getSaveDataFromState(props, this.state);
        SettingsToolDialog.orgStateHash = saveData.hash;
    }

    // #region General
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "Nm") {
            this.setState({ name: value });
        } else if (name === "vehicleId") {
            this.setState({ vehicleId: value });
        } else if (name === "amount") {
            this.setState({ amountStr: value });
        } else if (name === "description") {
            this.setState({ description: value });
        } else if (name === "comment") {
            this.setState({ comment: value });
        }
    };

    handleBlur = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "amount") {
            this.setState({ amountStr: value.toInteger().toString(10) });
        }
    };

    handleActiveStateChange = (newActiveState: number) => {
        this.setState({ activeState: newActiveState });
    };
    // #endregion General

    private static validate = (state: ISettingsToolDialogState): boolean => {
        if (!state.name) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.NameMustBeDefined));
            return false;
        }
        return true;
    };

    private static getSaveDataFromState = (props: ISettingsToolDialogProp, state: ISettingsToolDialogState): SaveData => {
        const data = new SaveData();
        const tool = props.editItem.tool;
        // Common
        data.append("id", tool.id);
        data.append("rowId", tool.rowId);
        // General
        data.append("vehicleId", state.vehicleId !== Base.emptyGuid ? state.vehicleId : String(null));
        data.append("name", state.name);
        data.append("description", state.description);
        data.append("amount", state.amountStr.toInteger().toString(10));
        data.append("comment", state.comment);
        data.append("activeState", state.activeState.toString(10));
        return data;
    };

    saveEditItem = () => {
        const obj = this;
        if (!SettingsToolDialog.validate(this.state)) return;
        const saveData = SettingsToolDialog.getSaveDataFromState(this.props, this.state);
        if (Base.isNullOrUndefined(saveData)) return;
        // Call server
        store.customStore.dispatch(storeActions.fetchStart());
        toolService.saveToolEdit(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                obj.props.onOk();
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleOkClick = () => {
        this.saveEditItem();
    };

    handleCancelClick = () => {
        const obj = this;
        const saveData = SettingsToolDialog.getSaveDataFromState(this.props, this.state);
        if (!Base.isNullOrUndefined(saveData) && saveData.hash !== SettingsToolDialog.orgStateHash) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.saveEditItem();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.props.onCancel();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));
        } else {
            obj.props.onCancel();
        }
    };

    render() {
        const props = this.props;
        const editItem = props.editItem;
        const dialogClasses = "settings tool px800" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.Tool + " - " + (!editItem.tool.isNew() ? editItem.tool.number.toString(10) + " " + editItem.tool.name : Translations.New)}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-2">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.Number}</label>
                                    <input type="text" className="form-control" name="number" title={Translations.Number} value={editItem.tool.isNew() ? Translations.New : editItem.tool.number.toString(10)} readOnly={true} disabled={true}/>
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.Name}</label>
                                    <input type="text" className="form-control" name="Nm" title={Translations.Name} value={this.state.name} onChange={this.handleChange} maxLength={50}/>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="form-group">
                                    <label className="control-label smallFont">&nbsp;</label>
                                    <div>
                                        <CheckBox
                                            title={Translations.InUse}
                                            enabled={true}
                                            checked={this.state.activeState > 0}
                                            onCheckboxClickBoolean={(checked: boolean) => { this.handleActiveStateChange(checked ? 1 : 0); }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Description}</label>
                                    <textarea className="form-control" name="description" title={Translations.Description} value={this.state.description} onChange={this.handleChange} maxLength={1000} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Comment}</label>
                                    <textarea className="form-control" name="comment" title={Translations.Comment} value={this.state.comment} onChange={this.handleChange} maxLength={1000} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Amount}</label>
                                    <input type="text" className="form-control" name="amount" title={Translations.Amount} value={this.state.amountStr} onChange={this.handleChange} onBlur={this.handleBlur} maxLength={6}/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Vehicle}</label>
                                    <select className="form-control" name="vehicleId" title={Translations.Vehicle} value={this.state.vehicleId} onChange={this.handleChange}>
                                        {this.state.vehicles.map((vehicle) =>
                                            <option key={vehicle.id} value={vehicle.id}>{vehicle.getTitle()}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}