export interface IVehicleGroupItem {
    id: string;
    name: string;
    description: string;
}

export class VehicleGroupItem implements IVehicleGroupItem {
    id: string;
    name: string;
    description: string;

    constructor();
    constructor(obj: IVehicleGroupItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
        this.description = obj && obj.description || "";
    }
}