import { Base } from "../../framework/base";
import { CostCenterItem, ICostCenterItem } from "./costCenterItem";

export interface ICostCenterItems {
    items: ICostCenterItem[];
    hasMore: boolean;
    page: number;
}

export class CostCenterItems implements ICostCenterItems {
    items: CostCenterItem[];
    hasMore: boolean;
    page: number;

    constructor();
    constructor(obj: ICostCenterItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = Base.getTypedArray<CostCenterItem>(obj ? obj.items : null, CostCenterItem);
    }
}