import { TextField, InputAdornment, Box, Checkbox } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import React, { useEffect } from "react";
import { IBoundaries } from "../../models/calculation/TESCalculationParameters";

interface BoundaryProps {
    boundaries: IBoundaries[];
    isOn: boolean;
    translate: (str: string) => string;
    fillBoundaries: (boundaries: IBoundaries[]) => void;
    default50: number;
    default100: number;
    label50?: string;
    label100?: string;
    disabled?: boolean;
}

export const CalcBoundaries = ({
    boundaries,
    isOn,
    translate,
    fillBoundaries,
    default50,
    default100,
    label50,
    label100,
    disabled,
}: BoundaryProps) => {
    const is50InUse = !!boundaries.find((x) => x.boundary === 50);
    const is100InUse = !!boundaries.find((x) => x.boundary === 100);
    const fiftyValue =
        boundaries.find((x) => x.boundary === 50)?.boundaryValue || default50;
    const hundredValue =
        boundaries.find((x) => x.boundary === 100)?.boundaryValue || default100;

    useEffect(() => {
        if (isOn && boundaries.length === 0) {
            // Set default boundaries, if calculation is on but boundaries are missing.
            fillBoundaries([
                { boundary: 50, boundaryValue: default50 },
                { boundary: 100, boundaryValue: default100 },
            ]);
        }
    }, [isOn, boundaries]);

    const updateIsInUse = (inUse: boolean, boundary: number) => {
        let newBoundaries = [...boundaries];

        if (inUse) {
            newBoundaries.push({
                boundary,
                boundaryValue: boundary === 50 ? default50 : default100,
            });
        } else {
            newBoundaries = newBoundaries.filter(
                (b) => b.boundary !== boundary
            );
        }

        fillBoundaries(newBoundaries);
    };

    const updateValue = (value: number, boundary: number) => {
        const newBoundaries = [...boundaries];
        newBoundaries.find((x) => x.boundary === boundary).boundaryValue =
            value;
        fillBoundaries(newBoundaries);
    };

    return (
        <Grid2>
            <Box sx={{ width: 200 }} mt={1}>
                <Grid2
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    alignItems="center"
                >
                    <Grid2 xs={2}>
                        <Checkbox
                            id="FIFTYLIMIT"
                            checked={is50InUse}
                            onClick={() => updateIsInUse(!is50InUse, 50)}
                            disabled={disabled}
                        />
                    </Grid2>
                    <Grid2 xs={10}>
                        <TextField
                            type="number"
                            label={label50 ?? translate("FIFTYLIMIT")}
                            onChange={(event) =>
                                updateValue(+event.target.value, 50)
                            }
                            value={fiftyValue}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        h
                                    </InputAdornment>
                                ),
                            }}
                            disabled={!is50InUse}
                        />
                    </Grid2>
                    <Grid2 xs={2}>
                        <Checkbox
                            id="HUNDREDLIMIT"
                            checked={is100InUse}
                            onClick={() => updateIsInUse(!is100InUse, 100)}
                            disabled={disabled}
                        />
                    </Grid2>
                    <Grid2 xs={10}>
                        <TextField
                            type="number"
                            label={label100 ?? translate("HUNDREDLIMIT")}
                            onChange={(event) =>
                                updateValue(+event.target.value, 100)
                            }
                            value={hundredValue}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        h
                                    </InputAdornment>
                                ),
                            }}
                            disabled={!is100InUse}
                        />
                    </Grid2>
                </Grid2>
            </Box>
        </Grid2>
    );
};
