// SettingsVehicleDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import Datetime from "react-datetime";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as baseService from "../../services/baseService";
import * as storeActions from "../../models/store/storeActions";
import * as vehicleService from "../../services/vehicleService";
import { PropertyDialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { SaveData } from "../../framework/saveData";
import { CheckBox } from "../framework/checkbox";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { IVehicleEdit } from "../../models/vehicle/vehicleEdit";
import { IIdTitle, IdTitle } from "../../models/common/idTitle";
import { SettingsVehicleDialogEmployeeList } from "./settingsVehicleDialogEmployeeList";
import { SettingsVehicleDialogToolList } from "./settingsVehicleDialogToolList";
import { IVehicleEditItemIntegration, WorkOrderEditItemSaveIntegration } from "../../models/vehicle/vehicleEditItemIntegration";
import { ImageSelector } from "../framework/imageSelector";
import { IdTitleDescription, IIdTitleDescription } from "../../models/common/idTitleDescription";
import { t } from "i18next";

// SettingsVehicleDialog
// ***********************************************************************************************************************
export interface ISettingsVehicleDialogProp {
    classes?: string;
    editItem: IVehicleEdit;
    onOk: () => void;
    onCancel: () => void;
}

export interface ISettingsVehicleDialogState {
    costCenters: IIdTitleDescription[];
    vehicleGroupId: string;
    costCenterId: string;
    registerNumber: string;
    brand: string;
    picture: string;
    pictureFile: File;
    removePicture: boolean;
    abbreviation: string;
    comment: string;
    activeState: number;
    inspectionDateStr: string;
    code: string;
    employees: IIdTitle[];
    removedEmployeeIds: string[];
    savedEmployeeIds: string[];
    tools: IIdTitle[];
    removedToolIds: string[];
    savedToolIds: string[];
    selectedLocationIds: string[];
    integrations: IVehicleEditItemIntegration[];
}

export class SettingsVehicleDialog extends React.Component<ISettingsVehicleDialogProp, ISettingsVehicleDialogState> {
    private static orgStateHash: string = "";

    constructor(props) {
        super(props);
        const vehicle = props.editItem.vehicle;
        const costCenters = props.editItem.costCenters.slice(0);
        costCenters.unshift(IdTitleDescription.createIdTitleDescription(Base.emptyGuid, "", ""));
        this.state = {
            costCenters: costCenters,
            vehicleGroupId: vehicle.vehicleGroupId,
            costCenterId: vehicle.costCenterId,
            registerNumber: vehicle.registerNumber,
            brand: vehicle.brand,
            picture: vehicle.picture,
            pictureFile: null,
            removePicture: false,
            abbreviation: vehicle.abbreviation,
            comment: vehicle.comment,
            activeState: vehicle.activeState,
            inspectionDateStr: vehicle.inspectionDate ? Base.utcTimeToDateStr(vehicle.inspectionDate) : "",
            code: vehicle.code,
            employees: vehicle.employees.slice(0),
            removedEmployeeIds: [],
            savedEmployeeIds: [],
            tools: vehicle.tools.slice(0),
            removedToolIds: [],
            savedToolIds: [],
            selectedLocationIds: [],
            integrations: vehicle.integrations.slice(0)
        };
        const saveData = SettingsVehicleDialog.getSaveDataFromState(props, this.state);
        SettingsVehicleDialog.orgStateHash = saveData.hash;
    }

    // #region General
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "registerNumber") {
            this.setState({ registerNumber: value });
        } else if (name === "brand") {
            this.setState({ brand: value });
        } else if (name === "abbreviation") {
            this.setState({ abbreviation: value });
        } else if (name === "comment") {
            this.setState({ comment: value });
        } else if (name === "vehicleGroupId") {
            this.setState({ vehicleGroupId: value });
        } else if (name === "costCenterId") {
            this.setState({ costCenterId: value });
        } else if (name === "code") {
            this.setState({ code: value });
        }
    };

    handleActiveStateChange = (activeState: number) => {
        this.setState({ activeState: activeState });
    };

    handleInspectionDateChange = (value: moment.Moment | string) => {
        if (typeof value === "string") {
            if (!value) {
                this.setState({ inspectionDateStr: "" });
            }
        } else {
            this.setState({ inspectionDateStr: Base.utcTimeToDateStr(value) });
        }
    };

    handleInspectionDateBlur = (value: moment.Moment | string) => {
        let inspectionDateStr: string;
        if (typeof value === "string") {
            inspectionDateStr = Base.utcTimeToDateStr(value.toUtcDate().getTime());
        } else {
            inspectionDateStr = Base.utcTimeToDateStr(value);
        }
        this.setState({ inspectionDateStr: inspectionDateStr });
    };

    handleSetPicture = (file: File) => {
        if (Base.isNullOrUndefined(file)) return;
        const obj = this;
        Base.blobToBase64(file).then(base64Image => {
            obj.setState({
                pictureFile: file,
                picture: base64Image,
                removePicture: false
            });
        });
    };

    handleRemovePicture = () => {
        this.setState({
            pictureFile: null,
            picture: "",
            removePicture: true
        });
    };

    handleIntegrationActiveStateChange = (id: string, activeState: number) => {
        const integrations = this.state.integrations.slice(0);
        const integration = integrations.find(i => i.id === id);
        if (!integration) return;
        integration.activeState = activeState;
        this.setState({ integrations: integrations });
    };

    handleIntegrationIdChange = (id: string, event) => {
        const target = event.target;
        const value: string = target.value;
        const integrations = this.state.integrations.slice(0);
        const integration = integrations.find(i => i.id === id);
        if (!integration) return;
        integration.uniqueId = value;
        this.setState({ integrations: integrations });
    };
    // #endregion General

    // #region Employees
    handleEmployeeAdd = (id: string, title: string) => {
        const employees = this.state.employees.slice(0);
        const savedEmployeeIds = this.state.savedEmployeeIds.slice(0);
        const item = new IdTitle();
        item.id = id;
        item.title = title;
        item.isNew = true;
        employees.push(item);
        savedEmployeeIds.push(item.id);
        IdTitle.sortIdTitles(employees, "title", true);
        this.setState({ employees: employees, savedEmployeeIds: savedEmployeeIds });
    };

    handleEmployeeRemove = (id: string) => {
        if (!id) return;
        const item = this.state.employees.find(i => i.id === id);
        if (Base.isNullOrUndefined(item)) return;
        const removedEmployeeIds = this.state.removedEmployeeIds.filter(i => i !== id);
        if (!item.isNew) {
            removedEmployeeIds.push(id);
        }
        this.setState({ employees: this.state.employees.filter(i => i.id !== id), savedEmployeeIds: this.state.savedEmployeeIds.filter(i => i !== id), removedEmployeeIds: removedEmployeeIds });
    };
    // #endregion Employees

    // #region Tools
    handleToolAdd = (id: string, title: string) => {
        const tools = this.state.tools.slice(0);
        const savedToolIds = this.state.savedToolIds.slice(0);
        const item = new IdTitle();
        item.id = id;
        item.title = title;
        item.isNew = true;
        tools.push(item);
        savedToolIds.push(item.id);
        IdTitle.sortIdTitles(tools, "title", true);
        this.setState({ tools: tools, savedToolIds: savedToolIds });
    };

    handleToolRemove = (id: string) => {
        if (!id) return;
        const item = this.state.tools.find(i => i.id === id);
        if (Base.isNullOrUndefined(item)) return;
        const removedToolIds = this.state.removedToolIds.filter(i => i !== id);
        if (!item.isNew) {
            removedToolIds.push(id);
        }
        this.setState({ tools: this.state.tools.filter(i => i.id !== id), savedToolIds: this.state.savedToolIds.filter(i => i !== id), removedToolIds: removedToolIds });
    };
    // #endregion Tools

    private static validate = (state: ISettingsVehicleDialogState): boolean => {
        if (!state.registerNumber) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.RegisterNumberMustBeDefined));
            return false;
        }
        return true;
    };

    private static getSaveDataFromState = (props: ISettingsVehicleDialogProp, state: ISettingsVehicleDialogState): SaveData => {
        const data = new SaveData();
        const vehicle = props.editItem.vehicle;
        // Common
        data.append("id", vehicle.id);
        data.append("rowId", vehicle.rowId);
        // General
        data.append("vehicleGroupId", state.vehicleGroupId);
        data.append("costCenterId", state.costCenterId !== Base.emptyGuid ? state.costCenterId : String(null));
        data.append("registerNumber", state.registerNumber);
        data.append("brand", state.brand);
        data.append("abbreviation", state.abbreviation);
        data.append("comment", state.comment);
        data.append("activeState", state.activeState.toString(10));
        data.append("inspectionDate", state.inspectionDateStr);
        data.append("code", state.code);
        if (!Base.isNullOrUndefined(state.pictureFile)) {
            data.append("picture[]", state.pictureFile, state.pictureFile.name);
        }
        data.append("removePicture", state.removePicture ? "1" : "0");
        // Employees
        data.append("addedEmployeeIds", JSON.stringify(state.employees.reduce((result, i) => {
            if (state.savedEmployeeIds.indexOf(i.id) >= 0) {
                result.push(i.id);
            }
            return result;
        }, [])));
        data.append("removedEmployeeIds", JSON.stringify(state.removedEmployeeIds));
        // tools
        data.append("addedToolIds", JSON.stringify(state.tools.reduce((result, i) => {
            if (state.savedToolIds.indexOf(i.id) >= 0) {
                result.push(i.id);
            }
            return result;
        }, [])));
        data.append("removedToolIds", JSON.stringify(state.removedToolIds));
        // Integrations
        data.append("integrations", JSON.stringify(state.integrations.map(i => new WorkOrderEditItemSaveIntegration(i))));
        return data;
    };

    saveEditItem = () => {
        const obj = this;
        if (!SettingsVehicleDialog.validate(this.state)) return;
        const saveData = SettingsVehicleDialog.getSaveDataFromState(this.props, this.state);
        if (Base.isNullOrUndefined(saveData)) return;
        // Call server
        store.customStore.dispatch(storeActions.fetchStart());
        vehicleService.saveVehicleEdit(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                obj.props.onOk();
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleOkClick = () => {
        this.saveEditItem();
    };

    handleSetSelectedLocationIds = (ids: string[]) => {
        this.setState({ selectedLocationIds: ids });
    };

    handleCancelClick = () => {
        const obj = this;
        const saveData = SettingsVehicleDialog.getSaveDataFromState(this.props, this.state);
        if (!Base.isNullOrUndefined(saveData) && saveData.hash !== SettingsVehicleDialog.orgStateHash) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.saveEditItem();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.props.onCancel();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));
        } else {
            obj.props.onCancel();
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        const editItem = props.editItem;
        const vehicle = editItem.vehicle;
        const dialogClasses = "settings vehicle px800" + (props.classes ? " " + props.classes : "");
        const activeIntegrationsExist = state.integrations.filter(i => i.activeState > 0 && !!i.uniqueId).length > 0;
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    initialHeightOffset={activeIntegrationsExist ? 200 : 0}
                    title={Translations.Vehicle + " - " + (!vehicle.isNew() ? vehicle.number.toString(10) + " " + vehicle.registerNumber : Translations.New)}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-3">
                                <ImageSelector
                                    fileInputName="picture"
                                    image={state.picture}
                                    aspectRatio={null}
                                    onChange={this.handleSetPicture}
                                    onClear={this.handleRemovePicture}
                                    onError={(message) => { store.customStore.dispatch(storeActions.showErrorMessage(message)); }}
                                />
                            </div>
                            <div className="col-9">
                                <div className="row">
                                    <div className="col-2">
                                        <div className="form-group required">
                                            <label className="control-label smallFont">{Translations.Number}</label>
                                            <input type="text" className="form-control" name="number" title={Translations.Number} value={vehicle.isNew() ? Translations.New : vehicle.number.toString(10)} readOnly={true} disabled={true} />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-group required">
                                            <label className="control-label smallFont">{Translations.RegisterNumber}</label>
                                            <input type="text" className="form-control" name="registerNumber" title={Translations.RegisterNumber} value={state.registerNumber} onChange={this.handleChange} maxLength={50} />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-group required">
                                            <label className="control-label smallFont">{Translations.VehicleGroup}</label>
                                            <select className="form-control" name="vehicleGroupId" title={Translations.VehicleGroup} value={state.vehicleGroupId} onChange={this.handleChange}>
                                                {editItem.vehicleGroups.map((vehicleGroup) =>
                                                    <option key={vehicleGroup.id} value={vehicleGroup.id}>{vehicleGroup.title}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="form-group">
                                            <label className="control-label smallFont">{Translations.Abbreviation}</label>
                                            <input type="text" className="form-control" name="abbreviation" title={Translations.Abbreviation} value={state.abbreviation} onChange={this.handleChange} maxLength={3} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <div className="form-group">
                                            <label className="control-label smallFont">{Translations.NextVehicleInspection}</label>
                                            <Datetime
                                                locale={appConfig.culture}
                                                className={"roWhite"}
                                                value={state.inspectionDateStr}
                                                dateFormat={"D.M.YYYY"}
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                inputProps={{ maxLength: 10 }}
                                                onChange={this.handleInspectionDateChange}
                                                onClose={this.handleInspectionDateBlur}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="form-group">
                                            <label className="control-label smallFont">&nbsp;</label>
                                            <div>
                                                <CheckBox
                                                    title={Translations.InUse}
                                                    enabled={true}
                                                    checked={state.activeState > 0}
                                                    onCheckboxClickBoolean={(checked: boolean) => { this.handleActiveStateChange(checked ? 1 : 0); }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="control-label smallFont">{Translations.Brand}</label>
                                            <input type="text" className="form-control" name="brand" title={Translations.Brand} value={state.brand} onChange={this.handleChange} maxLength={50} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Comment}</label>
                                    <textarea className="form-control" name="comment" title={Translations.Comment} value={state.comment} onChange={this.handleChange} maxLength={1000} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.CostCenter}</label>
                                    <select className="form-control" name="costCenterId" title={Translations.CostCenter} value={state.costCenterId} onChange={this.handleChange}>
                                        {state.costCenters.map((costCenter) =>
                                            <option key={costCenter.id} value={costCenter.id}>{costCenter.title}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="control-label smallFont wrap">{t("costCenter.code")}</label>
                                    <input type="text" className="form-control" name="code" title={Translations.Code} value={state.code} onChange={this.handleChange}/>
                                </div>
                            </div>
                        </div>
                        {state.integrations.length > 0 &&
                            <div>
                                <label className="control-label listTitle">{Translations.Integrations}</label>
                                {state.integrations.map((integration) =>
                                    <div key={integration.id} className="row">
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="control-label smallFont">&nbsp;</label>
                                                <div>
                                                    <CheckBox
                                                        title={integration.serviceName}
                                                        enabled={true}
                                                        checked={integration.activeState > 0}
                                                        onCheckboxClickBoolean={(checked: boolean) => { this.handleIntegrationActiveStateChange(integration.id, checked ? 1 : 0); }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="control-label smallFont">{Translations.Code}</label>
                                                <input type="text" className="form-control" name="brand" title={integration.serviceName} value={integration.uniqueId} onChange={(e) => this.handleIntegrationIdChange(integration.id, e)} maxLength={250} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                        <div>
                            <div>
                                <SettingsVehicleDialogEmployeeList
                                    title={Translations.Employees}
                                    items={state.employees}
                                    onRemoveEmployee={this.handleEmployeeRemove}
                                    onAddEmployee={this.handleEmployeeAdd}
                                />
                            </div>
                        </div>
                        <div>
                            <div>
                                <SettingsVehicleDialogToolList
                                    title={Translations.Tools}
                                    items={state.tools}
                                    onRemoveTool={this.handleToolRemove}
                                    onAddTool={this.handleToolAdd}
                                />
                            </div>
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: this.handleOkClick },
                    ]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}
