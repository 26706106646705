import { Base } from "../../framework/base";
import { IdTitle, IIdTitle } from "../common/idTitle";

export interface IDayBookingEditItem {
    id: string;
    dayBookingTypeId: string;
    employeeId: string;
    date: number;
    duration: number;
    dayUsageType: number;
    comment: string;
    rowId: string;

    dayBookingTypeName: string;

    isNew():boolean;
}

export class DayBookingEditItem implements IDayBookingEditItem {
    id: string;
    dayBookingTypeId: string;
    employeeId: string;
    date: number;
    duration: number;
    dayUsageType: number;
    comment: string;
    rowId: string;

    dayBookingTypeName: string;

    constructor();
    constructor(obj: IDayBookingEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.dayBookingTypeId = obj && obj.dayBookingTypeId || "";
        this.employeeId = obj && obj.employeeId || "";
        this.date = obj && obj.date || 0;
        this.duration = obj && obj.duration || 0;
        this.dayUsageType = obj && obj.dayUsageType || 0;
        this.comment = obj && obj.comment || "";
        this.rowId = obj && obj.rowId || "";
    }

    isNew():boolean {
        return !this.rowId;
    }

    static setDayBookingTypeNames(items: IDayBookingEditItem[], dayBookingTypes: IIdTitle[]): IDayBookingEditItem[] {
        for (let i = 0; i < items.length; i++) {
            items[i].dayBookingTypeName = IdTitle.getTitleById(dayBookingTypes, items[i].dayBookingTypeId);
        }
        return items;
    }

    static sortDayBookingEditItems(items: IDayBookingEditItem[], column: string, asc: boolean) {
        if (Base.isNullOrUndefined(items) || items.length < 2) return;
        let sortFunc: (a: IDayBookingEditItem, b: IDayBookingEditItem) => number;
        if (column === "duration") sortFunc = (a: IDayBookingEditItem, b: IDayBookingEditItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.duration, b.duration)); };
        else if (column === "comment") sortFunc = (a: IDayBookingEditItem, b: IDayBookingEditItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.comment, b.comment)); };
        else if (column === "dayBookingType") sortFunc = (a: IDayBookingEditItem, b: IDayBookingEditItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.dayBookingTypeName, b.dayBookingTypeName)); };
        else if (column === "dayUsageType") sortFunc = (a: IDayBookingEditItem, b: IDayBookingEditItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.dayUsageType, b.dayUsageType)); };
        else sortFunc = (a: IDayBookingEditItem, b: IDayBookingEditItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.date, b.date)); };
        items.sort(sortFunc);
    }
}

export class DayBookingEditSaveItem {
    id: string;
    dayBookingTypeId: string;
    date: number;
    duration: number;
    dayUsageType: number;
    comment: string;
    rowId: string;

    constructor();
    constructor(obj: IDayBookingEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.dayBookingTypeId = obj && obj.dayBookingTypeId || "";
        this.date = obj && obj.date || 0;
        this.duration = obj && obj.duration || 0;
        this.dayUsageType = obj && obj.dayUsageType || 0;
        this.comment = obj && obj.comment || "";
        this.rowId = obj && obj.rowId || "";
    }
}