import { IOperationLogItem, OperationLogItem } from "./operationLogItem";

export interface IOperationLogItems {
    items: IOperationLogItem[];
    hasMore: boolean;
    page: number;
}

export class OperationLogItems implements IOperationLogItems {
    items: OperationLogItem[];
    hasMore: boolean;
    page: number;

    constructor();
    constructor(obj: IOperationLogItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = [];
        if (obj && obj.items) {
            for (let i = 0; i < obj.items.length; i++) {
                this.items.push(new OperationLogItem(obj.items[i]));
            }
        }
    }
}