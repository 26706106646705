import React, { useEffect, useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { TransportPlanTemplateFormData, TransportPlanTemplateUpdateDto } from "../../../models/transport/transportPlanTemplate";
import { useFormik } from "formik";
import {
    defaultFormikConfig,
    formikFieldProps,
    showApiError,
    showApiSuccess,
    showConfirm
} from "../../framework/formUtils";
import * as yup from "yup";
import { removeTransportPlanTemplate, updateTransportPlanTemplate } from "../../../services/transportPlanTemplateService";
import { Translations } from "../../../models/translations";
import { customHistory } from "../../../framework/customHistory";
import { useAppDispatch } from "../../../framework/customStore";
import { showErrorMessage } from "../../../models/store/storeActions";
import Grid2 from "@mui/material/Unstable_Grid2";
import { STYLE_CONSTANTS } from "../../../framework/theme";
import { TransportPlanRecurringView } from "./transportPlanRecurringView";
import moment from "moment";
import { useParams } from "react-router-dom";
import { VehicleSelect } from "../components/vehicleSelect";
import { DriverSelect } from "../components/driverSelect";
import { TransportPlanListItemDto, TransportPlanState } from "../../../models/transport/transportPlan";
import dayjs from "dayjs";
import { Base } from "../../../framework/base";

interface TransportPlanRecurringEditProps {
    item: TransportPlanTemplateFormData;
    plans?: TransportPlanListItemDto[];
    fromPath: string;
}

export const TransportPlanRecurringEdit = (props: TransportPlanRecurringEditProps) => {
    const DATE_FORMAT = "YYYY-MM-DD";
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();
    const fieldProps = (field: keyof TransportPlanTemplateUpdateDto) => formikFieldProps(formik, field);
    const plansInTransportOrCompleted = props.plans.filter(plan => plan.state === TransportPlanState.InTransport || plan.state === TransportPlanState.Completed);
    const [isStartDateChanged, setIsStartDateChanged] = useState(false);
    const existingStartDate = props.item.repeatStartDate;
    const formattedExistingStartDate = existingStartDate ? dayjs(existingStartDate).format(DATE_FORMAT) : null;

    const getUpdateData = (formData: TransportPlanTemplateFormData) => {
        if (formData) {
            return {
                name: formData.name,
                scheduledStartTime: formData.scheduledStartTime,
                duration: formData.duration,
                employeeId: formData.employeeId,
                vehicleId: formData.vehicleId,
                repeatConfigStartDate: Base.dayjsToJsonDate(formData.repeatStartDate),
                repeatConfigEndDate: Base.dayjsToJsonDate(formData.repeatEndDate),
                enabledDays: formData.repeatEnabledDays
            };
        }
    };

    const handleSubmit = (formData: TransportPlanTemplateFormData) => {
        formik.setSubmitting(true);
        updateTransportPlanTemplate(getUpdateData(formData), id)
            .then(res => {
                if (res) {
                    showApiSuccess(Translations.SaveSuccess);
                    customHistory.replace(props.fromPath);
                } else {
                    dispatch(showErrorMessage(Translations.SaveFailed));
                }
            }).catch(() => {
                showApiError(Translations.SaveFailed);
            });
        formik.setSubmitting(false);
    };

    const handleRemove = () => {
        const submitRemove = () => {
            removeTransportPlanTemplate(id)
                .then(() => {
                    formik.setSubmitting(false);
                    showApiSuccess(Translations.DeleteWasSuccess);
                    customHistory.replace(props.fromPath);
                })
                .catch(() => {
                    formik.setSubmitting(false);
                    showApiError("Poisto epäonnistui");
                });
        };

        const checkPlans = () => {
            if (props.plans.some(plan => plan.state === TransportPlanState.InTransport || plan.state === TransportPlanState.Completed)) {
                return "Toistuvuudella on ajossa tai valmis tilassa olevia kuljetussuunnitelmia. Haluatko poistaa kaikki ajamattomat kuljetussuunnitelmat tältä toistuvuudelta?";
            } else {
                return "Kaikki tämän toistuvuuden kuljetussuunnitelmat poistetaan. Oletko varma, että haluat poistaa toistuvuuden?";
            }
        };
        showConfirm(checkPlans(), submitRemove);
    };

    const minEndDate = plansInTransportOrCompleted?.reduce((latest, current) => {
        if (latest === null || current?.scheduledEndDateTime > latest?.scheduledEndDateTime) {
            return current;
        }
        return latest;
    }, null);

    const maxStartDate = plansInTransportOrCompleted?.reduce((earliest, current) => {
        if (earliest === null || current?.scheduledStartDateTime < earliest?.scheduledStartDateTime) {
            return current;
        }
        return earliest;
    }, null);

    const formik = useFormik<TransportPlanTemplateFormData>({
        ...defaultFormikConfig,
        enableReinitialize: true,
        initialValues: props.item,
        onSubmit: handleSubmit,
        validationSchema: yup.object({
            name: yup
                .string()
                .required("Nimi on pakollinen tieto")
                .min(3, "Nimen tulee olla vähintään 3 merkkiä pitkä")
                .max(50, "Nimen tulee olla korkeintaan 50 merkkiä pitkä"),
            repeatEnabledDays: yup
                .number()
                .test(
                    "check-repeatEnabledDays",
                    "",
                    function(value) {
                        return value > 0 ? true : false;
                    }),
            scheduledStartTime: yup
                .string()
                .required("Aloitusaika on pakollinen tieto"),
            repeatStartDate: isStartDateChanged
                ? yup
                    .string()
                    .when("maxStartDate", {
                        is: () => maxStartDate,
                        then: yup
                            .string()
                            .test(
                                "maxStartDateCheck",
                                `Aloitusaika on oltava viimeistään ${dayjs(maxStartDate?.scheduledStartDateTime).format("DD.MM.YYYY")}`,
                                function(value) {
                                    const dayStart = moment(maxStartDate?.scheduledStartDateTime).startOf("day");
                                    const inputValue = moment(value).startOf("day");
                                    return inputValue.isSameOrBefore(dayStart);
                                }
                            )
                    })
                    .nullable()
                    .required("Aloitusaika on pakollinen tieto")
                    .test(
                        "start-date-not-past",
                        "Aloitusaika ei voi olla menneisyydessä.",
                        function(value) {
                            const today = dayjs().startOf("day");
                            const startDate = dayjs(value);
                            return startDate.isSameOrAfter(today);
                        }
                    )
                    .test(
                        "start-date-range",
                        "Aloitusaika on oltava ennen päättymisaikaa.",
                        (value, context) =>
                            moment(value).isBefore(
                                context.resolve(yup.ref("repeatEndDate"))
                            )
                    )
                    .test(
                        "start-date-range-270-days",
                        "Aloitusajan ja päättymisajan väli voi olla enintään 270 päivää.",
                        function(value) {
                            const endDate = this.resolve(yup.ref("repeatEndDate"));
                            if (!value || !endDate) {
                                return true;
                            }
                            const startDate = moment(value);
                            const differenceInDays = moment(endDate).diff(startDate, "days");
                            return differenceInDays <= 270;
                        }
                    )
                : yup
                    .string(),
            repeatEndDate: yup
                .string()
                .when("minEndDate", {
                    is: () => minEndDate,
                    then: yup
                        .string()
                        .test(
                            "minEndDateCheck",
                            `Aikaisin päättymisaika on ${dayjs(minEndDate?.scheduledEndDateTime).format("DD.MM.YYYY")}`,
                            function(value) {
                                const dayEnd = moment(minEndDate?.scheduledEndDateTime).endOf("day");
                                const inputValue = moment(value).endOf("day");
                                return inputValue.isSameOrAfter(dayEnd);
                            }
                        )
                })
                .nullable()
                .required("Päättymisaika on pakollinen tieto")
                .test(
                    "end-date-not-past",
                    "Päättymisaika ei voi olla menneisyydessä.",
                    function(value) {
                        const today = dayjs().startOf("day");
                        const endDate = dayjs(value);
                        return endDate.isSameOrAfter(today);
                    }
                )
                .test(
                    "end-date-range",
                    "Päättymisaika on oltava aloitusajan jälkeen.",
                    (value, context) =>
                        moment(value).isAfter(
                            context.resolve(yup.ref("repeatStartDate"))
                        )
                )
                .test(
                    "end-date-range-270-days",
                    "Aloitusajan ja päättymisajan väli voi olla enintään 270 päivää.",
                    function(value) {
                        const startDate = this.resolve(yup.ref("repeatStartDate"));
                        if (!value || !startDate) {
                            return true;
                        }
                        const endDate = moment(value);
                        const differenceInDays = moment(endDate).diff(startDate, "days");
                        return differenceInDays <= 270;
                    }
                ),
            duration: yup
                .number()
                .nullable()
                .required()
                .test(
                    "check-duration",
                    "Kesto on pakollinen tieto",
                    function(value) {
                        return value > 0 ? true : false;
                    }),
            durationDay: yup
                .number(),
            durationHours: yup
                .number(),
            durationMinutes: yup
                .number(),
        })
    });

    useEffect(() => {
        const formattedRepeatStartDate = formik.values.repeatStartDate ? dayjs(formik.values.repeatStartDate).format(DATE_FORMAT) : null;
        if (formattedRepeatStartDate !== formattedExistingStartDate) {
            setIsStartDateChanged(true);
        } else {
            setIsStartDateChanged(false);
        }
    }, [formik.values.repeatStartDate, formattedExistingStartDate]);

    return (
        <form>
            <Typography variant="h3" mt={1}>Kuljetussuunnitelman perustiedot</Typography>
            <Grid2 container spacing={2} mb={3}>
                <Grid2>
                    <TextField
                        label="Kuljetussuunnitelman nimi"
                        required
                        {...fieldProps("name")}
                    />
                </Grid2>
            </Grid2>
            <TransportPlanRecurringView
                formik={formik}
            />
            <Typography variant="h3" mt={4}>Kalusto ja kuljettaja</Typography>
            <Grid2 container spacing={STYLE_CONSTANTS.formSpacing}>
                <Grid2>
                    <VehicleSelect {...fieldProps("vehicleId")} />
                </Grid2>
                <Grid2>
                    <DriverSelect {...fieldProps("employeeId")} />
                </Grid2>
            </Grid2>
            <Grid2 container spacing={STYLE_CONSTANTS.formSpacing}>
                <Grid2>
                    <Button
                        variant="contained"
                        color="success"
                        size="large"
                        disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
                        onClick={() => void formik.submitForm()}
                        sx={{ width: "150px" }}
                    >
                        {Translations.Save}
                    </Button>
                </Grid2>
                {id && (
                    <Grid2>
                        <Button
                            variant="contained"
                            disabled={
                                formik.isSubmitting
                            }
                            color="error"
                            size="large"
                            onClick={handleRemove} key="btn-remove"
                            sx={{ width: "150px", marginRight: "12px" }}
                        >
                            {Translations.Remove}
                        </Button>
                    </Grid2>
                )}
            </Grid2>
        </form>
    );
};
