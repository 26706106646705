export interface IEmployeePasswordChangeSuccess {
    message: string;
    rowId: string;
}

export class EmployeePasswordChangeSuccess implements IEmployeePasswordChangeSuccess {
    message: string;
    rowId: string;

    constructor();
    constructor(obj: IEmployeePasswordChangeSuccess);
    constructor(obj?: any) {
        this.message = obj && obj.message || "";
        this.rowId = obj && obj.rowId || "";
    }
}