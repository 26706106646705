// ToolTitle
// ***********************************************************************************************************************
import * as React from "react";

export interface IToolTitleProp {
    title: string;
    classes?: string;
    labelClasses?: string;
    onClick?: () => void;
}

export class ToolTitle extends React.Component<IToolTitleProp, {}> {
    handleTitleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick();
    };

    render() {
        const props = this.props;
        return (
            <div className={"tool-title" + (props.classes ? " " + props.classes : "") + (props.onClick ? " link" : "")} onClick={props.onClick ? this.handleTitleClick : null}>
                <label className={"control-label listTitle" + (props.labelClasses ? " " + props.labelClasses : "")}>{props.title}</label>
            </div>
        );
    }
}