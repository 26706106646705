export interface TransportSaveDto {
    transportPlanId: string;
    employeeId: string;
    vehicleId: string;
}

export const enum TransportState {
    Waiting,
    InTransport,
    Completed,
    Failed,
}

export interface TransportDetailsDto {
    id: string;
}

export interface TransportTaskDetailsDto {
    id: string;
    performedDateTime: string;
    taskType: TransportTaskType;
}

export const enum TransportTaskType {
    PickedUp,
    Delivered,
}
