/* eslint-disable  no-undef */
(function (window) {
    //Polyfill
    if (!Date.now) {
        Date.now = function () {
            return new Date().getTime();
        };
    }
    let date_type, date_prototype;
    date_type = Date;
    date_type.__typeName = "Number";
    date_type.__class = true;
    date_prototype = date_type.prototype;
    
    date_prototype.addYears = function (years) {
        if (years === null || years === undefined) {
            years = 1;
        }
        var result = new Date(this.valueOf());
        result.setFullYear(result.getFullYear() + years);
        return result;
    };
    
    date_prototype.addMonths = function (months) {
        if (months === null || months === undefined) {
            months = 1;
        }
        var result = new Date(this.valueOf());
        result.setMonth(result.getMonth() + months);
        return result;
    };
    
    date_prototype.addDays = function (days) {
        if (days === null || days === undefined) {
            days = 1;
        }
        var result = new Date(this.valueOf());
        result.setDate(result.getDate() + days);
        return result;
    };

    date_prototype.addHours = function (hours) {
        if (hours === null || hours === undefined) {
            hours = 1;
        }
        var result = new Date(this.valueOf());
        result.setHours(result.getHours() + hours);
        return result;
    };

    date_prototype.addMinutes = function (minutes) {
        if (minutes === null || minutes === undefined) {
            minutes = 1;
        }
        var result = new Date(this.valueOf());
        result.setMinutes(result.getMinutes() + minutes);
        return result;
    };

    date_prototype.firstDayOfWeek = function () {
        var result = new Date(this.valueOf());
        var dayOfWeek = this.getDay();
        result.setDate(result.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
        result.setHours(0, 0, 0, 0);
        return result;
    };

    //UTC
    date_prototype.addYearsUtc = function (years) {
        if (years === null || years === undefined) {
            years = 1;
        }
        var result = new Date(this.valueOf());
        result.setUTCFullYear(result.getUTCFullYear() + years);
        return result;
    };
    
    date_prototype.addMonthsUtc = function (months) {
        if (months === null || months === undefined) {
            months = 1;
        }
        var result = new Date(this.valueOf());
        result.setUTCMonth(result.getUTCMonth() + months);
        return result;
    };
    
    date_prototype.addDaysUtc = function (days) {
        if (days === null || days === undefined) {
            days = 1;
        }
        var result = new Date(this.valueOf());
        result.setUTCDate(result.getUTCDate() + days);
        return result;
    };

    date_prototype.addHoursUtc = function (hours) {
        if (hours === null || hours === undefined) {
            hours = 1;
        }
        var result = new Date(this.valueOf());
        result.setUTCHours(result.getUTCHours() + hours);
        return result;
    };

    date_prototype.addMinutesUtc = function (minutes) {
        if (minutes === null || minutes === undefined) {
            minutes = 1;
        }
        var result = new Date(this.valueOf());
        result.setUTCMinutes(result.getUTCMinutes() + minutes);
        return result;
    };

    date_prototype.firstDayOfWeekUtc = function () {
        var result = new Date(this.valueOf());
        var dayOfWeek = this.getDayUTC();
        result.setDateUTC(result.getDateUTC() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
        result.setHoursUTC(0, 0, 0, 0);
        return result;
    };

})(typeof window !== "undefined" ? window : this);
