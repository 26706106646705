export interface IRouteResult {
    distanceM: number;
    durationS: number;
}

export class RouteResult implements IRouteResult {
    distanceM: number;
    durationS: number;

    constructor();
    constructor(obj: IRouteResult);
    constructor(obj?: any) {
        this.distanceM = obj && obj.distanceM || 0;
        this.durationS = obj && obj.durationS || 0;
    }
}