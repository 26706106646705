// OwnerSettingsDayBookingTypeDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as dayBookingTypeService from "../../services/dayBookingTypeService";
import { PropertyDialog } from "../framework/dialog";
import { SaveData } from "../../framework/saveData";
import { IDayBookingTypeEdit } from "../../models/dayBookingType/dayBookingTypeEdit";
import { AppUtils } from "../../models/common/appUtils";
import { ConfirmationDialogResult } from "../../models/common/enums";
import MuiSelect from "../framework/muiSelect";
import { MuiSwitch } from "../framework/muiSwitch";
import { t } from "i18next";
import { MuiTimeField } from "../framework/muiTimeField";
import { Base } from "../../framework/base";

// OwnerSettingsDayBookingTypeDialog
// ***********************************************************************************************************************
export interface IOwnerSettingsDayBookingTypeDialogProp {
    classes?: string;
    edit: IDayBookingTypeEdit;
    onOk: () => void;
    onCancel: () => void;
}

export interface IOwnerSettingsDayBookingTypeDialogState {
    name: string;
    salaryRowTypeId: string;
    calculateNormal: boolean;
    calculateOvertime: boolean;
    dayHoursRatio: number;
}

export class OwnerSettingsDayBookingTypeDialog extends React.Component<IOwnerSettingsDayBookingTypeDialogProp, IOwnerSettingsDayBookingTypeDialogState> {
    private orgStateHash = "";

    constructor(props: IOwnerSettingsDayBookingTypeDialogProp) {
        super(props);
        const dayBookingType = props.edit.dayBookingType;
        this.state = {
            name: dayBookingType.name,
            salaryRowTypeId: dayBookingType.salaryRowTypeId,
            calculateNormal: dayBookingType.calculateNormal,
            calculateOvertime: dayBookingType.calculateOvertime,
            dayHoursRatio: dayBookingType.dayHoursRatio,
        };
        const saveData = OwnerSettingsDayBookingTypeDialog.getSaveDataFromState(props, this.state);
        this.orgStateHash = saveData.hash;
    }

    // #region General
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "Nm") {
            this.setState({ name: value });
        } 
    };
    // #endregion General

    isCalculateAllowed = (salaryRowTypeId: string) => {
        const measureUnit = this.props.edit.salaryRowTypes?.find(t => t.id === salaryRowTypeId)?.measureUnit;
        return AppUtils.isTimeUnit(measureUnit) || AppUtils.isDayUnit(measureUnit);
    }

    isDayHoursRatioAvailable = (salaryRowTypeId: string) => {
        const measureUnit = this.props.edit.salaryRowTypes?.find(t => t.id === salaryRowTypeId)?.measureUnit;
        return AppUtils.isDayUnit(measureUnit);
    }

    handleSalaryRowTypeChange = (salaryRowTypeId: string) => {
        this.setState(prevState => {
            // If salaryRowType is changed to type which doesn't allow calculateNormal,
            // set it to false
            const calculateAllowed = this.isCalculateAllowed(salaryRowTypeId);
            return { 
                salaryRowTypeId,
                calculateNormal: calculateAllowed && prevState.calculateNormal,
                calculateOvertime: calculateAllowed && prevState.calculateOvertime,
            };
        });
    };

    private checkErrors = (): Promise<boolean> => {
        const state = this.state;
        return new Promise<boolean>((resolve) => {
            //Add error checking in here
            if (!state.name) {
                AppUtils.showErrorMessage(Translations.NameMustBeDefined);
                return resolve(false);
            }
            return resolve(true);
        });
    };

    private getWarningMessage = (): Promise<string> => {
        return new Promise<string>((resolve) => {
            //let warnings = "";
            //Add warning checking in here
            //if (!state.someField) {
            //    warnings = warnings + Translations.SomeFieldIsNotDefined + Base.lf;
            //}
            //return resolve(warnings);
            return resolve("");
        });
    };

    private static getSaveDataFromState = (props: IOwnerSettingsDayBookingTypeDialogProp, state: IOwnerSettingsDayBookingTypeDialogState): SaveData => {
        const data = new SaveData();
        const dayBookingType = props.edit.dayBookingType;

        // Common - Data that does not change
        data.append("id", dayBookingType.id);
        data.append("rowId", dayBookingType.rowId);
        // General - Data that changes
        data.append("name", state.name);
        data.append("salaryRowTypeId", state.salaryRowTypeId);
        data.append("calculateNormal", state.calculateNormal.toString());
        data.append("calculateOvertime", state.calculateOvertime.toString());
        data.append("dayHoursRatio", state.dayHoursRatio?.toString() ?? "");
        return data;
    };

    saveEditItem = async() => {
        const props = this.props;
        const state = this.state;
        if (!await AppUtils.validate(this.checkErrors, this.getWarningMessage)) return;
        const saveData = OwnerSettingsDayBookingTypeDialog.getSaveDataFromState(props, state);
        //Call server
        const result = await AppUtils.callService(() => dayBookingTypeService.saveDayBookingTypeEdit(saveData.formData));
        if (!result) return;
        props.onOk();
    };

    handleOkClick = () => {
        this.saveEditItem();
    };

    handleCancelClick = async() => {
        const props = this.props;
        const state = this.state;
        const cancelResult = await AppUtils.cancel(OwnerSettingsDayBookingTypeDialog.getSaveDataFromState(props, state).hash, this.orgStateHash);
        if (cancelResult === ConfirmationDialogResult.Cancel) return;
        if (cancelResult === ConfirmationDialogResult.Yes) {
            this.saveEditItem();
        } else {
            props.onCancel();
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        const edit = props.edit;
        const dayBookingType = edit.dayBookingType;
        const dialogClasses = "settings dayBookingType px600" + (props.classes ? " " + props.classes : "");
        const calculateAllowed = this.isCalculateAllowed(state.salaryRowTypeId);
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.DayBookingType + " - " + (!dayBookingType.isNew() ? dayBookingType.number.toString(10) + " " + dayBookingType.name : Translations.New)}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-2">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.Number}</label>
                                    <input type="text" className="form-control" name="number" title={Translations.Number} value={dayBookingType.isNew() ? Translations.New : dayBookingType.number.toString(10)} readOnly={true} disabled={true}/>
                                </div>
                            </div>
                            <div className="col-10">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.Name}</label>
                                    <input type="text" className="form-control" name="Nm" title={Translations.Name} value={state.name} onChange={this.handleChange} maxLength={50}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group mt-2">
                                    <MuiSelect
                                        label={Translations.SalaryRowType}
                                        defaultValue={state.salaryRowTypeId || ""}
                                        options={edit.salaryRowTypes?.map((c) =>
                                            ({ label: c.name, value: c.id })
                                        )}
                                        size="small"
                                        noSelectionLabel={"'" + Translations.NotInUse + "'"}
                                        onChange={this.handleSalaryRowTypeChange}
                                    />
                                </div>
                                <div className="form-group mt-4">
                                    <MuiTimeField
                                        label={t("dayBookingType.dayHoursRatio")}
                                        value={Base.hoursToTimeStr(state.dayHoursRatio)}
                                        onBlur={(val) =>
                                            this.setState({ dayHoursRatio: Base.timeStrToHours(val) })
                                        }
                                        size="small"
                                        disabled={!this.isDayHoursRatioAvailable(state.salaryRowTypeId)}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group mt-2">
                                    <MuiSwitch
                                        disabled={!calculateAllowed}
                                        label={t("dayBookingType.calculateNormal")}
                                        checked={state.calculateNormal}
                                        onChange={(_, chekced) => this.setState({ calculateNormal: chekced })}
                                    />
                                </div>
                                <div className="form-group mt-4">
                                    <MuiSwitch
                                        disabled={!calculateAllowed}
                                        label={t("dayBookingType.calculateOvertime")}
                                        checked={state.calculateOvertime}
                                        onChange={(_, chekced) => this.setState({ calculateOvertime: chekced })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: this.handleOkClick }
                    ]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}
