
import { Translations_en_US } from "./translations.en-US";

// -----------------------------------------------------------------
// !!! THIS FILE IS AUTOMATICALLY GENERATED DO NOT EDIT MANUALLY !!!
// -----------------------------------------------------------------
export class Translations_fi {
    static ApplicationName = 'EasyOpp työnohjaus';
    static ArgumentParameterIsNULL = 'Parametrin \'{0}\' arvo on NULL!';
    static Cancel = 'Peruuta';
    static ClassParameterHasNotBeenInitialized = 'Luokkaa \'{0}\' ei ole alustettu!';
    static EmployeeParameterCodeIsAlreadyInUse = 'Työntekijän asetuksen tunnus on jo käytössä!';
    static EmployeeParameterParameterSavedSuccessfully = 'Työntekijän asetuksen \'{0}\' tallennus onnistui!';
    static EmployeeParameterParameterSaveFailedConcurrencyError = 'Työntekijän asetuksen \'{0}\' tallennus epäonnistui, koska työntekijän asetuksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static EmployeeParameterParameterSaveFailedErrorParameter = 'Työntekijän asetuksen \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
    static Maintenance = 'Ylläpito';
    static No = 'Ei';
    static Password = 'Salasana';
    static PermissionDeniedForOperation = 'Käyttäjällä ei ole oikeuksia kyseiseen toimintoon!';
    static PlannedWorks = 'Suunnitellut työt';
    static Planning = 'Suunnittelu';
    static Reporting = 'Raportointi';
    static SignIn = 'Kirjaudu';
    static SignOut = 'Kirjaudu ulos';
    static ToBeCheckedWorks = 'Tarkistettavat työt';
    static ToBeInvoicedWorks = 'Laskutettavat työt';
    static UserClaimParameterNotFound = 'Käyttäjän ominaisuutta \'{0}\' ei löytynyt!';
    static UserClaimsNotFound = 'Käyttäjän ominaisuuksia ei löytynyt!';
    static Username = 'Käyttäjätunnus';
    static WaitingWorks = 'Odottavat työt';
    static Yes = 'Kyllä';
    static Employee = 'Työntekijä';
    static InvalidUsernameOrPassword = 'Virheellinen käyttäjätunnus tai salasana!';
    static PasswordIsTooShort = 'Salasana on liian lyhyt!';
    static PasswordMustContainDigitOrSpecialCharacter = 'Salasanan täytyy sisältää numero tai erikoismerkki!';
    static PasswordMustContainLowercaseChar = 'Salasanan täytyy sisältää pienaakkonen!';
    static PasswordMustContainUppercaseChar = 'Salasanan täytyy sisältää suuraakkonen!';
    static TheSystemDoesNotSupportYourWebBrowserUpdateToLatestBrowserVersion = 'Järjestelmä ei tue selainversiotasi, päivitä selaimesi uusimpaan versioon.';
    static Week = 'Viikko';
    static WorkPlan = 'Työsuunnitelma';
    static Actions = 'Toiminnot';
    static Copy = 'Kopioi';
    static Edit = 'Muokkaa';
    static FindWeek = 'Etsi viikko';
    static New = 'Uusi';
    static NextWeek = 'Seuraava viikko';
    static PreviousWeek = 'Edellinen viikko';
    static Remove = 'Poista';
    static Clear = 'Tyhjennä';
    static Close = 'Sulje';
    static Contact = 'Yhteyshenkilö';
    static Duration = 'Kesto';
    static MeasureUnitKg = 'kg';
    static MeasureUnitL = 'l';
    static MeasureUnitM = 'm';
    static MeasureUnitPcs = 'kpl';
    static OK = 'OK';
    static Site = 'Kohde';
    static StartDate = 'Aloitus';
    static State = 'Tila';
    static WorkDescription = 'Työn kuvaus';
    static WorkOrder = 'Tilaus';
    static WorkOrderBookingsTab = 'Kirjaukset';
    static WorkOrderDocumentsTab = 'Työn kuvaus ja liitteet';
    static WorkOrderGeneralTab = 'Ohjeistus';
    static WorkOrderParameterNotFound = 'Tilausta \'{0}\' ei löytynyt!';
    static WorkOrderStateChecked = 'Laskutettava';
    static WorkOrderStateDone = 'Valmis';
    static WorkOrderStateInProgress = 'Työn alla';
    static WorkOrderStatePlanned = 'Suunniteltu';
    static WorkOrderStatePreliminary = 'Odottaa';
    static WorkOrderStateTransferred = 'Siirretty';
    static WorkOrderType = 'Tilaustyyppi';
    static Add = 'Lisää';
    static AddPhoto = 'Lisää valokuva';
    static Amount = 'Määrä';
    static AmountH = 'Määrä (h)';
    static AmountKm = 'Määrä (km)';
    static Attachments = 'Liitteet';
    static Change = 'Vaihda';
    static Comment = 'Kommentti';
    static Cost = 'Kustannus';
    static Customer = 'Asiakas';
    static Date = 'Päiväys';
    static DocumentIsEmpty = 'Dokumentti on tyhjä!';
    static DocumentParameterNotFound = 'Dokumenttia \'{0}\' ei löytynyt!';
    static Download = 'Lataa';
    static EndDate = 'Päättyy';
    static HourBooking = 'Tuntikirjaus';
    static HourBookings = 'Laskutettavat tunnit';
    static ProductBooking = 'Tuotekirjaus';
    static ProductBookings = 'Tuotteet';
    static Product = 'Tuote';
    static MeasureUnitH = 'h';
    static MeasureUnitKm = 'km';
    static RideBooking = 'Matka';
    static RideBookings = 'Matkat';
    static Save = 'Tallenna';
    static SelectCustomer = 'Valitse asiakas';
    static UnitPrice = 'Yksikköhinta';
    static Unnamed = 'Nimeämätön';
    static Vehicle = 'Kalusto';
    static DecreaseState = 'Laske tilaa';
    static EmployeesMustBeDefined = 'Työntekijät täytyy määritellä!';
    static HourBookingParameterNotFound = 'Tuntikirjausta \'{0}\' ei löytynyt!';
    static IncreaseState = 'Nosta tilaa';
    static ProductBookingParameterNotFound = 'Tuotekirjausta \'{0}\' ei löytynyt!';
    static NotImplemented = 'Ei toteutettu!';
    static RideBookingParameterNotFound = 'Matkaa \'{0}\' ei löytynyt!';
    static SelectDate = 'Valitse päiväys';
    static StartDateMustBeDefined = 'Aloitusaika täytyy määritellä!';
    static WorkOrderSaveFailedConcurrencyError = 'Tilauksen \'{0}\' tallennus epäonnistui, koska tilauksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrderSaveFailedErrorParameter = 'Tilauksen \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
    static WorkOrderSaveSuccess = 'Tilauksen \'{0}\' tallennus onnistui!';
    static WorkOrderCannotBeModifiedInThisState = 'Tilausta ei voi muikata tässä tilassa!';
    static FinishWork = 'Lopeta työ';
    static StartWork = 'Aloita mittaus';
    static WorkOrderCannotCrossWeekBoundary = 'Tilaus ei voi ylittää viikkorajaa!';
    static WorkOrders = 'Työtilaukset';
    static MeasureUnitD = 'pv';
    static StartDateAndDuration = 'Aloitus ja kesto';
    static Warning = 'Varoitus';
    static YouHaveNotSavedChangesDoYouWantToSaveChanges = 'Et ole tallentanut tekemiäsi muutoksia. Tallennetaanko muutokset?';
    static Loading = 'Ladataan...';
    static Photo = 'Valokuva';
    static AddToHomeScreen = 'Lisää  aloitusnäyttöön';
    static DoYouWantToAddThisApplicationToHomeScreen = 'Haluatko lisätä tämän sovelluksen aloitusnäyttöön?';
    static NewVersionAvailable = 'Uusi versio sovelluksesta saatavilla!';
    static Update = 'Päivitä';
    static Contacts = 'Yhteyshenkilöt';
    static CustomerParameterNotFound = 'Asiakasta \'{0}\' ei löytynyt!';
    static Customers = 'Asiakkaat';
    static CustomerSaveFailedConcurrencyError = 'Asiakkaan \'{0}\' tallennus epäonnistui, koska asiakkaan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static CustomerSaveFailedErrorParameter = 'Asiakkaan \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
    static CustomerSaveSuccess = 'Asiakkaan \'{0}\' tallennus onnistui!';
    static Employees = 'Työntekijät';
    static ErpReference = 'ERP-viitekoodi';
    static General = 'Perustiedot';
    static Products = 'Tuotteet';
    static Name = 'Nimi';
    static NameMustBeDefined = 'Nimi täytyy määritellä!';
    static Next = 'Seuraava';
    static Previous = 'Edellinen';
    static Receipts = 'Kuittaukset';
    static Search = 'Hae';
    static SearchCondition = 'Hakuehto';
    static Sites = 'Kohteet';
    static Tools = 'Työkalut';
    static Vehicles = 'Kalusto';
    static WorkOrderTypes = 'Tilaustyypit';
    static EventLogMessageWorkOrderCreated = 'Tilaus luotu';
    static EventLogMessageWorkOrderRemoved = 'Tilaus poistettu';
    static EventLogMessageWorkOrderStateChanged = 'Tilauksen tila muuttunut: \'{0}\' => \'{1}\'';
    static Events = 'Tapahtumat';
    static WorkOrderEventsTab = 'Tapahtumat';
    static WorkOrderRemoveConfirmation = 'Haluatko todella poistaa tilauksen \'{0}\'?';
    static WorkOrderRemoveFailedConcurrencyError = 'Tilauksen \'{0}\' poisto epäonnistui, koska tilauksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrderRemoveFailedErrorParameter = 'Tilauksen \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
    static WorkOrdersRemoveSuccess = 'Tilausten \'{0}\' poisto onnistui!';
    static CustomerParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders = 'Asiakasta \'{0}\' ei voi poistaa, koska asiakas on käytössä {1} tilauksessa!';
    static CustomerRemoveConfirmation = 'Haluatko todella poistaa asiakkaan \'{0}\'?';
    static CustomerRemoveFailedConcurrencyError = 'Asiakkaan \'{0}\' poisto epäonnistui, koska asiakkaan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static CustomerRemoveFailedErrorParameter = 'Asiakkaan \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
    static CustomerRemoveSuccess = 'Asiakkaan \'{0}\' poisto onnistui!';
    static EmployeeRemoveConfirmation = 'Haluatko todella poistaa työntekijän \'{0}\'?';
    static EventLogCustomerRemoved = 'Asiakas poistettu';
    static EventLogMessageCustomerCreated = 'Asiakas luotu';
    static InUse = 'Käytössä';
    static Number = 'Numero';
    static ChangePassword = 'Vaihda salasana';
    static DayBookings = 'Päiväkirjaukset';
    static Email = 'Sähköposti';
    static EmployeeParameterNotFound = 'Työntekijää \'{0}\' ei löytynyt!';
    static FirstName = 'Etunimi';
    static FirstNameMustBeDefined = 'Etunimi täytyy määritellä!';
    static LastName = 'Sukunimi';
    static LastNameMustBeDefined = 'Sukunimi täytyy määritellä!';
    static Phone = 'Puhelin';
    static UserGroup = 'Käyttäjäryhmä';
    static ChangePasswordFailedConcurrencyError = 'Työntekijän \'{0}\' salasanan vaihto epäonnistui, koska työntekijän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static ChangePasswordFailedErrorParameter = 'Työntekijän \'{0}\' salasanan vaihto epäonnistui! Virhe: \'{1}\'';
    static ChangePasswordSuccess = 'Työntekijän \'{0}\' salasanan vaihto onnistui!';
    static ChangeUsername = 'Vaihda käyttäjätunnus';
    static ChangeUsernameFailedConcurrencyError = 'Työntekijän \'{0}\' käyttäjätunnuksen vaihto epäonnistui, koska työntekijän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static ChangeUsernameFailedErrorParameter = 'Työntekijän \'{0}\' käyttäjätunnuksen vaihto epäonnistui! Virhe: \'{1}\'';
    static ChangeUsernameSuccess = 'Työntekijän \'{0}\' käyttäjätunnuksen vaihto onnistui!';
    static CreditCardNumber = 'Luottokortin numero';
    static CurrentPasswordIsIncorrect = 'Nykyinen salasana on väärä!';
    static DayBooking = 'Päiväkirjaus';
    static DayBookingParameterNotFound = 'Päiväkirjausta \'{0}\' ei löytynyt!';
    static DayBookingType = 'Kirjaustyyppi';
    static Documents = 'Dokumentit';
    static EmployeeGroup = 'Työntekijäryhmä';
    static EmployeeSaveFailedConcurrencyError = 'Työntekijän \'{0}\' tallennus epäonnistui, koska työntekijän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static EmployeeSaveFailedErrorParameter = 'Työntekijän \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
    static EmployeeSaveSuccess = 'Työntekijän \'{0}\' tallennus onnistui!';
    static EventLogMessageEmployeeCreated = 'Työntekijä luotu';
    static NewPassword = 'Uusi salasana';
    static NewPasswordConfirmation = 'Uuden salasanan vahvistus';
    static NewUsername = 'Uusi käyttäjätunnus';
    static PasswordConfirmation = 'Salasanan vahvistus';
    static PasswordConfirmationDoesNotMatchPassword = 'Salasanan vahvistus ei ole sama kuin salasana!';
    static PasswordMustBeDefined = 'Salasana täytyy määritellä!';
    static PasswordMustMeetComplexityRequirements = 'Salasanan täytyy olla vähintään 8 merkkiä pitkä ja pitää sisältää isoja ja pieniä kirjaimia sekä vähintään yksi erikoismerkki tai numero';
    static UsernameIsAlreadyInUse = 'Käyttäjätunnus on jo käytössä!';
    static UsernameMustBeDefined = 'Käyttäjätunnus täytyy määritellä!';
    static UsernameMustMeetComplexityRequirements = 'Käyttäjätunnuksen täytyy olla vähintään 8 merkkiä pitkä!';
    static CityAddress = 'Postitoimipaikka';
    static ContactParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders = 'Yhteyshenkilöä \'{0}\' ei voi poistaa, koska yhteyshenkilö on käytössä {1} tilauksessa!';
    static ContactParameterNotFound = 'Yhteyshenkilöä \'{0}\' ei löytynyt!';
    static ContactRemoveConfirmation = 'Haluatko todella poistaa yhteyshenkilön \'{0}\'?';
    static ContactRemoveFailedConcurrencyError = 'Yhteyshenkilön \'{0}\' poisto epäonnistui, koska yhteyshenkilön tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static ContactRemoveFailedErrorParameter = 'Yhteyshenkilön \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
    static ContactRemoveSuccess = 'Yhteyshenkilön \'{0}\' poisto onnistui!';
    static ContactSaveFailedConcurrencyError = 'Yhteyshenkilön \'{0}\' tallennus epäonnistui, koska yhteyshenkilön tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static ContactSaveFailedErrorParameter = 'Yhteyshenkilön \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
    static ContactSaveSuccess = 'Yhteyshenkilön \'{0}\' tallennus onnistui!';
    static CustomerSiteNumber = 'Asiakkaan kohdenumero';
    static Distance = 'Etäisyys (km)';
    static EmployeeParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders = 'Työntekijää \'{0}\' ei voi poistaa, koska työntekijä on käytössä {1} tilauksessa!';
    static EmployeeRemoveFailedConcurrencyError = 'Työntekijän \'{0}\' poisto epäonnistui, koska työntekijän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static EmployeeRemoveFailedErrorParameter = 'Työntekijän \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
    static EmployeeRemoveSuccess = 'Työntekijän \'{0}\' poisto onnistui!';
    static MapLink = 'Karttalinkki';
    static PostalCode = 'Postinumero';
    static SelectContact = 'Valitse yhteyshenkilö';
    static SiteParameterNotFound = 'Kohdetta \'{0}\' ei löytynyt!';
    static Specifier = 'Tarkenne';
    static StreetAddress = 'Katuosoite';
    static Brand = 'Merkki';
    static Description = 'Kuvaus';
    static RegisterNumber = 'Rekisterinumero';
    static RegisterNumberMustBeDefined = 'Rekisterinumero täytyy määritellä!';
    static RideBookingRequired = 'Matkakirjaus vaaditaan';
    static SelectEmployee = 'Valitse työntekijä';
    static SelectTool = 'Valitse työkalu';
    static Tool = 'Työkalu';
    static ToolParameterNotFound = 'Työkalua \'{0}\' ei löytynyt!';
    static ToolRemoveConfirmation = 'Haluatko todella poistaa työkalun \'{0}\'?';
    static ToolRemoveFailedConcurrencyError = 'Työkalun \'{0}\' poisto epäonnistui, koska työkalun tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static ToolRemoveFailedErrorParameter = 'Työkalun \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
    static ToolRemoveSuccess = 'Työkalun \'{0}\' poisto onnistui!';
    static ToolSaveFailedConcurrencyError = 'Työkalun \'{0}\' tallennus epäonnistui, koska työkalun tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static ToolSaveFailedErrorParameter = 'Työkalun \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
    static ToolSaveSuccess = 'Työkalun \'{0}\' tallennus onnistui!';
    static VehicleParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders = 'Kalustoa \'{0}\' ei voi poistaa, koska kalusto on käytössä {1} tilauksessa!';
    static VehicleParameterNotFound = 'Kalustoa \'{0}\' ei löytynyt!';
    static VehicleRemoveConfirmation = 'Haluatko todella poistaa kaluston \'{0}\'?';
    static VehicleRemoveFailedConcurrencyError = 'Kaluston \'{0}\' poisto epäonnistui, koska kaluston tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static VehicleRemoveFailedErrorParameter = 'Kaluston \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
    static VehicleRemoveSuccess = 'Kaluston \'{0}\' poisto onnistui!';
    static VehicleSaveFailedConcurrencyError = 'Kaluston \'{0}\' tallennus epäonnistui, koska kaluston tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static VehicleSaveFailedErrorParameter = 'Kaluston \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
    static VehicleSaveSuccess = 'Kaluston \'{0}\' tallennus onnistui!';
    static WorkDescriptionMustBeDefined = 'Työn kuvaus täytyy määritellä!';
    static ProductParameterNotFound = 'Tuotetta \'{0}\' ei löytynyt!';
    static ProductRemoveConfirmation = 'Haluatko todella poistaa tuotteen \'{0}\'?';
    static ProductRemoveFailedConcurrencyError = 'Tuotteen \'{0}\' poisto epäonnistui, koska tuotteen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static ProductRemoveFailedErrorParameter = 'Tuotteen \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
    static ProductRemoveSuccess = 'Tuotteen \'{0}\' poisto onnistui!';
    static ProductSaveFailedConcurrencyError = 'Tuotteen \'{0}\' tallennus epäonnistui, koska tuotteen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static ProductSaveFailedErrorParameter = 'Tuotteen \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
    static ProductSaveSuccess = 'Tuotteen \'{0}\' tallennus onnistui!';
    static MeasureUnit = 'Mittayksikkö';
    static PrivacyPolicy = 'Tietosuoja';
    static ContactIsNotDefined = 'Yhteyshenkilöä ei ole määritelty!';
    static DoYouReallyWantToSetWorkOrderDone = 'Haluatko todella asettaa tilauksen tehdyksi?';
    static DoYouReallyWantToSetWorkOrderPlanned = 'Haluatko todella asettaa tilauksen suunnitelluksi?';
    static EmployeesHaveDayBookingsForWorkOrderTime = 'Työntekijöillä on päiväkirjauksia tilauksen ajalle!';
    static HourBookingsMustBeDefined = 'Tuntikirjaukset täytyy määritellä!';
    static HourBookingsAreNotDefinedForAllWorkDays = 'Tuntikirjauksia ei ole syötetty kaikille työpäiville!';
    static ProductBookingsAreNotDefined = 'Tuotekirjauksia ei ole syötetty!';
    static RideBookingsMustBeDefined = 'Matkakirjaukset täytyy määritellä!';
    static StartDateIsInThePast = 'Aloitusaika on menneisyydessä!';
    static MeasureUnitBtl = 'plo';
    static MeasureUnitM2 = 'm2';
    static MeasureUnitM3 = 'm3';
    static MeasureUnitPkg = 'pkt';
    static SiteMustBeDefined = 'Kohde täytyy määritellä!';
    static SiteSaveFailedConcurrencyError = 'Kohteen \'{0}\' tallennus epäonnistui, koska kohteen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static SiteSaveFailedErrorParameter = 'Kohteen \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
    static SiteSaveSuccess = 'Kohteen \'{0}\' tallennus onnistui!';
    static SitesThatRelatedToWorkOrdersCannotBeRemoved = 'Kohteita, jotka liittyvät tilauksiin, ei voi poistaa!';
    static NoWorkOrdersForThisDay = 'Ei tilauksia';
    static AttachmentRemoveConfirmation = 'Haluatko poistaa liitteen?';
    static HourBookingRemoveConfirmation = 'Haluatko poistaa tuntikirjauksen?';
    static ProductBookingRemoveConfirmation = 'Haluatko poistaa tuotekirjauksen?';
    static RideBookingRemoveConfirmation = 'Haluatko poistaa matkakirjauksen?';
    static Code = 'Tunnus';
    static DateRangeEnd = 'Aikavälin loppu';
    static DateRangeStart = 'Aikavälin alku';
    static DocumentParameterGenerationFailedParameter = 'Dokumentin \'{0}\' generointi epäonnistui! Virhe: \'{1}\'';
    static EmployeeReportName = 'Työntekijän viikkoraportti';
    static Message = 'Viesti';
    static Print = 'Tulosta';
    static SelectDateRange = 'Valitse aikaväli';
    static WorkOrderAcknowledgementType = 'Tilauksen hyväksymispyyntö';
    static DoYouWantToSendWorkOrderAcknowledgementToContact = 'Haluatko lähettää tilauksen hyväksymispyynnön yhteyshenkilölle osoitteeseen \'{0}\'?';
    static Information = 'Huomio';
    static AcknowledgementCannotBeSendBecauseValidEmailIsNotDefinedForContact = 'Hyväksymispyyntöä ei voi lähettää, koska sähköpostiosoitetta ei ole määritelty yhteyshenkilölle!';
    static EmailAddressParameterIsInvalid = 'Sähköpostiosoite \'{0}\' on virheellinen!';
    static SendingEmailFailedErrorParameter = 'Sähköpostin lähetys epäonnistui! Virhe: \'{0}\'';
    static AcknowledgementParameterNotFound = 'Hyväksymispyyntöä \'{0}\' ei löytynyt, koska se on vanhentunut!';
    static ApplicationTempPathIsNotSpecified = 'Sovelluksen temp-hakemistoa ei ole määritelty!';
    static ApplicationTempPathParameterNotFound = 'Sovelluksen temp-hakemistoa \'{0}\' ei löydy!';
    static ApprovedToBeInvoiced = 'Hyväksytty laskutettavaksi';
    static ApproveToBeInvoiced = 'Hyväksy laskutettavaksi';
    static CustomerView = 'Asiakasnäkymä';
    static LoadingAcknowledgement = 'Odota hetki. Ladataan hyväksymispyyntöä...';
    static MessageContent = 'Viestin sisältö';
    static MessageContentMustBeDefined = 'Viestin sisältö täytyy määritellä!';
    static MessageSendFailedErrorParameter = 'Viesti lähetys epäonnistui! Virhe: \'{0}\'';
    static MessageSendSuccess = 'Viesti lähetetty onnistuneesti!';
    static MessageSubject = 'Viestin aihe';
    static MessageSubjectMustBeDefined = 'Viestin aihe täytyy määritellä!';
    static MessageToAcknowledgementRequest = 'Viesti hyväksymispyyntöön';
    static Send = 'Lähetä';
    static Sender = 'Lähettäjä';
    static SendMessage = 'Lähetä viesti';
    static ThisBrowserDoesNotSupportPdfsPleaseDownloadPdfToViewIt = 'Tämä selain ei tue PDF-tiedostojen näyttämistä. Lataa PDF-tiedosto käyttäen Lataa-painiketta.';
    static AcknowledgementParameterHasAlreadyBeenApproved = 'Hyväksymispyyntö \'{0}\' on jo hyväksytty!';
    static EventLogMessageWorkOrderApprovalRequest = 'Tilauksen hyväksymispyyntö lähetetty';
    static EventLogMessageWorkOrderApproved = 'Tilaus hyväksytty';
    static EventLogSaveFailedErrorParameter = 'Tilauksen tallennus lokiin epäonnistui! Virhe: \'{0}\'';
    static EventLogSaveSuccess = 'Tapahtuman tallennus lokiin onnistui!';
    static WorkOrderAcknowledgementApprovalFailedConcurrencyError = 'Hyväksymispyynnön \'{0}\' hyväksyminen epäonnistui, koska hyväksymispyynnön tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrderAcknowledgementApprovalFailedErrorParameter = 'Hyväksymispyynnön \'{0}\' hyväksyminen epäonnistui! Virhe: \'{1}\'';
    static WorkOrderAcknowledgementApprovalSuccess = 'Hyväksymispyynnön \'{0}\' hyväksyminen onnistui!';
    static AcknowledgementSentToAddress = 'Hyväksymispyyntö lähetetty osoitteeseen \'{0}\'.';
    static DoYouReallyWantToSaveData = 'Haluatko todella tallentaa tiedot?';
    static DoYouWantToMakeProductBookingsBeforehand = 'Haluatko tehdä tuotekirjauksia etukäteen?';
    static EmployeeWhenVehicleIsNotDefinedEmployeeCannotMakeRideBookings = 'Jos työntekijälle ei ole määritelty kalustoa, hän ei voi tehdä matkakirjauksia.';
    static HourBookingAmountIsZero = 'Tuntikirjauksen tuntimäärä on 0.';
    static HourBookingCommentMustBeDefinedIfAmountIsZero = 'Tuntikirjaukselle täytyy syöttää kommentti, jos tuntimäärä on 0!';
    static ProductBookingProductMustBeDefined = 'Tuotekirjaukselle täytyy määritellä tuote!';
    static RideBookingVehicleMustBeDefined = 'Matkakirjaukselle täytyy määritellä kalusto!';
    static Browser = 'Selain';
    static EntryType = 'Tapahtumatyyppi';
    static EventLog = 'Liiketoimintaloki';
    static IpAddress = 'IP-osoite';
    static LogEntryType = 'Tyyppi';
    static ModifyUserName = 'Muokkaaja';
    static Open = 'Avaa';
    static Operation = 'Toiminto';
    static OperationLog = 'Sovellusloki';
    static OperationLogParameterNotFound = 'Sovelluslokia \'{0}\' ei löytynyt!';
    static Parameters = 'Parametrit';
    static RelatedObjectType = 'Tietuetyyppi';
    static SoftwareVersion = 'Ohjelmistoversio';
    static Time = 'Aika';
    static Tracking = 'Seuranta';
    static ContactWithEmailMustBeDefinedWhenAcknowledementTypeIsNotDoNotSend = 'Yhteyshenkilö, jolle on määritelty sähköpostiosoite, täytyy määritellä tilaukselle, jos tilauksen hyväksymispyyntö ei ole \'Älä lähetä\'!';
    static EmployeesThatHaveWorkedInSite = 'Kohteessa työskennelleet työntekijät';
    static OpenMapLink = 'Avaa karttalinkki';
    static SiteFamiliarizationRequired = 'Etäperehdytys vaaditaan';
    static SiteSamiliarizationType1Suffix = ' (ep)';
    static WorkOrderRemoveConfirmationAcknowledgementSent = 'Tilaukselle on lähetetty hyväksymispyyntö. Haluatko todella poistaa tilauksen \'{0}\'?';
    static CreateMapLink = 'Luo karttalinkki';
    static ProductBookingAmountMustBeGreaterThanZero = 'Tuotekirjaukselle täytyy syöttää nollaa suurempi määrä!';
    static NextVehicleInspection = 'Seuraava kaluston tarkastus';
    static ShowNotInUse = 'Näytä käytöstä poistetut';
    static Competencies = 'Pätevyydet';
    static Competency = 'Pätevyys';
    static CompetencyParameterCannotBeRemovedBecauseItIsRelatedToParameterEmployees = 'Pätevyyttä \'{0}\' ei voi poistaa, koska pätevyys on käytössä {1} työntekijässä!';
    static CompetencyParameterNotFound = 'Pätevyyttä \'{0}\' ei löytynyt!';
    static CompetencyRemoveConfirmation = 'Haluatko todella poistaa pätevyyden \'{0}\'?';
    static CompetencyRemoveFailedConcurrencyError = 'Pätevyyden \'{0}\' poisto epäonnistui, koska pätevyyden tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static CompetencyRemoveFailedErrorParameter = 'Pätevyyden \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
    static CompetencyRemoveSuccess = 'Pätevyyden \'{0}\' poisto onnistui!';
    static CompetencySaveFailedConcurrencyError = 'Pätevyyden \'{0}\' tallennus epäonnistui, koska pätevyyden tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static CompetencySaveFailedErrorParameter = 'Pätevyyden \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
    static CompetencySaveSuccess = 'Pätevyyden \'{0}\' tallennus onnistui!';
    static CompetencyType = 'Pätevyystyyppi';
    static CompetencyTypesNotFound = 'Pätevyystyyppejä ei löytynyt!';
    static CompetenciesNotFound = 'Pätevyyksiä ei löytynyt!';
    static EmployeeCompetencies = 'Työntekijöiden pätevyydet';
    static EmployeeCompetencyParameterNotFound = 'Työntekijän pätevyyttä \'{0}\' ei löytynyt!';
    static EmployeeCompetencyRemoveConfirmation = 'Haluatko todella poistaa pätevyyden \'{1}\' työntekijältä \'{0}\'?';
    static EmployeeCompetencyRemoveFailedConcurrencyError = 'Pätevyyden \'{1}\' poisto työntekijältä \'{0}\' epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static EmployeeCompetencyRemoveFailedErrorParameter = 'Pätevyyden \'{1}\' poisto työntekijältä \'{0}\' epäonnistui! Virhe: \'{2}\'';
    static EmployeeCompetencyRemoveSuccess = 'Pätevyyden \'{1}\' poisto työntekijältä \'{0}\' onnistui!';
    static EmployeeCompetencySaveFailedConcurrencyError = 'Pätevyyden \'{1}\' tallennus työntekijälle \'{0}\' epäonnistui, koska tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static EmployeeCompetencySaveFailedErrorParameter = 'Pätevyyden \'{1}\' tallennus työntekijälle \'{0}\' epäonnistui! Virhe: \'{2}\'';
    static EmployeeCompetencySaveSuccess = 'Pätevyyden \'{1}\' tallennus työntekijälle \'{0}\' onnistui!';
    static EmployeesNotFound = 'Työntekijöitä \'{0}\' ei löytynyt!';
    static ExpirationDate = 'Vanhenemispäivä';
    static GrantDate = 'Myöntämispäivä';
    static ExpirationDateAbrv = 'Vanhenemispv';
    static GrantDateAbrv = 'Myöntämispv';
    static AllStates = 'Kaikki tilat';
    static CreateInvoiceXmlFailedErrorParameter = 'Laskutustiedoston luonti epäonnistui! Virhe: \'{0}\'';
    static CreateInvoiceXmlSuccess = 'Laskutustiedoston luonti onnistui!';
    static DownloadInvoiceFile = 'Lataa laskutustiedosto';
    static SetWorkOrdersAndInvoicesToTransferredState = 'Merkitse tilaukset ja laskut Siirretty-tilaan';
    static TakeToInvoicing = 'Siirrä laskutukseen';
    static ValueOfOptionParameterNotSpecified = 'Asetuksen \'{0}\' arvoa ei määritelty!';
    static AdditionalInformation = 'Lisätiedot';
    static NotFullDay = 'Ei kokopäiväinen';
    static PermissionDenied = 'Käyttäjällä ei ole tarvittavia oikeuksia!';
    static WorkOrderInvoiceFile = 'Tilausten laskutustiedosto';
    static WorkOrderInvoicePrint = 'Tilausten laskutustuloste';
    static FileParameterDownloaded = 'Tiedosto \'{0}\' ladattu!';
    static Camera = 'Kamera';
    static CombineCustomersWorkOrdersToSameInvoice = 'Yhdistä tilaukset asiakkaittain samalle laskulle';
    static ConvertToProject = 'Muuta projektiksi';
    static OnlyWorkOrderThatIsNotAttachedToProjectCanBeConvertedToProject = 'Vain työ, jota ei ole liitetty projektiin, voidaan muuttaa projektiksi!';
    static WorkOrderProject = 'Projekti';
    static WorkOrderTask = 'Tehtävä';
    static WorkOrderWork = 'Työ';
    static WorkTitle = 'Työn otsikko';
    static WorkTitleMustBeDefined = 'Työn otsikko täytyy määritellä!';
    static AbrDays = 'pv';
    static Attach = 'Liitä';
    static DoYouReallyWantToConvertWorkOrderWorkToWorkOrderProject = 'Haluatko todella muuttaa työn projektiksi?';
    static AttachWorkToProject = 'Liitä työ projektiin';
    static EventLogMessageWorkOrderAttachedToProjectParameter = 'Tilaus liitetty projektiin \'{0}\'';
    static EventLogMessageWorkOrderConvertedToProject = 'Tilaus muutettu projektiksi';
    static EventLogMessageWorkOrderDetachedFromProjectParameter = 'Tehtävä poistettu projektilta \'{0}\'';
    static WorkOrderDetachConfirmation = 'Haluatko todella poistaa tehtävän \'{0}\' projektilta?';
    static WorkOrderDetachFromProjectErrorParameter = 'Tehtävän poistaminen projektilta epäonnistui! Virhe: \'{0}\'';
    static WorkOrderDetachFromProjectSuccess = 'Tehtävän poistaminen projektilta onnistui!';
    static WorkOrderDetachConfirmationRecurring = 'Haluatko todella poistaa tehtävän \'{0}\' toistuvalta työltä?';
    static WorkOrderParameterIsNotAttachedToWorkOrderParameter = 'Tehtävää \'{0}\' ei ole ole liitetty projektiin \'{1}\'!';
    static WorkOrderParameterIsNotProject = 'Tilaus \'{0}\' ei ole projekti!';
    static WorkOrderParameterIsNotTask = 'Tilaus \'{0}\' ei ole tehtävä!';
    static WorkOrdersAttachToProjectErrorParameter = 'Töiden liittäminen projektiin epäonnistui! Virhe: \'{0}\'';
    static WorkOrdersAttachToProjectSuccess = 'Töiden liittäminen projektiin onnistui!';
    static WorkOrdersCanBeAttachedOnlyToProjects = 'Töitä voi liittää vain projekteihin!';
    static WorkOrdersNotFound = 'Tilauksia ei löytynyt!';
    static AbrNumber = 'Nro';
    static AttachedToProject = 'Liittyy tilaukseen';
    static DetachFromProject = 'Irrota projektista';
    static Invoice = 'Lasku';
    static ProjectDoesNotHaveAnyTasksDoYouWantToRemoveProject = 'Projektilla ei ole tehtäviä. Haluatko poistaa projektin?';
    static WorkOrderChildDetachConfirmation = 'Haluatko todella irrottaa tehtävän projektista?';
    static WorkOrderChildDetachRemoveConfirmation = 'Projektilla ei ole enää tehtäviä. Haluatko samalla poistaa projektin?';
    static WorkOrderProjectParameterCannotBeRemovedBecauseOtherWorkOrderTasksAreBelongingToIt = 'Projektia \'{0}\' ei voi poistaa, koska siihen liittyy tehtäviä!';
    static WorkOrderStateUndefined = '(useita tiloja)';
    static AllWorkOrderTasksMustBeInSameState = 'Kaikkien tehtävien täytyy olla samassa tilassa!';
    static RemoveWorkOrderProjectTasksBeforeRemovingTheProject = 'Poista projektilta tehtävät ennen projektin poistamista.';
    static WorkOrderProjectRemoveConfirmation = 'Haluatko todella poistaa projektin \'{0}\'?';
    static WorkOrderTaskParameterCannotCrossWeekBoundary = 'Tehtävä \'{0}\' ei voi ylittää viikkorajaa!';
    static CombineProjectWorkOrdersToSameInvoice = 'Yhdistä projektien tehtävät  samalle laskulle';
    static CreateInvoices = 'Luo laskut';
    static CreateInvoicesSuccess = 'Lasku luotiin onnistuneesti!';
    static CreateWorkOrderInvoicesFailedErrorParameter = 'Laskun luominen epäonnistui! Virhe: \'{0}\'';
    static CustomerParameterCannotBeRemovedBecauseItIsRelatedToParameterInvoices = 'Asiakasta \'{0}\' ei voi poistaa, koska asiakas on käytössä {1} laskussa!';
    static EventLogMessageInvoiceCreated = 'Lasku luotu';
    static EventLogMessageInvoiceRemoved = 'Lasku poistettu';
    static EventLogMessageWorkOrderAttachedToInvoiceParameter = 'Tilaus liitetty laskuun \'{0}\'';
    static EventLogMessageWorkOrderDetachedFromInvoiceParameter = 'Tilaus poistettu laskulta \'{0}\'';
    static InvoiceDataDate = 'Tiedosto ladattu';
    static InvoiceParameterNotFound = 'Laskua \'{0}\' ei löytynyt!';
    static InvoiceRemoveConfirmation = 'Haluatko todella poistaa laskun \'{0}\'?';
    static InvoiceRemoveFailedConcurrencyError = 'Laskun \'{0}\' poisto epäonnistui, koska laskun tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static InvoiceRemoveFailedErrorParameter = 'Laskun \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
    static InvoiceRemoveSuccess = 'Laskun \'{0}\' poisto onnistui!';
    static Invoices = 'Laskut';
    static InvoiceStatePreliminary = 'Alustava';
    static InvoiceStateTransferred = 'Siirretty';
    static SelectWorkOrders = 'Valitse tilaukset';
    static WorkOrderParameterCannotBeRemovedBecauseItIsRelatedToParameterInvoices = 'Tilausta \'{0}\' ei voi poistaa, koska asiakas on käytössä {1} laskussa!';
    static AddWorkOrdersToInvoiceFailedErrorParameter = 'Tilausten lisäys laskulle \'{0}\' epäonnistui! Virhe: \'{1}\'';
    static AddWorkOrdersToInvoiceSuccess = 'Tilausten lisäys laskulle \'{0}\' onnistui!';
    static CustomerSiteNumbers = 'Asiakkaan kohdenumerot';
    static EventLogMessageInvoiceStateChanged = 'Laskun tila muuttunut: \'{0}\' => \'{1}\'';
    static StateOfAllInvoicesMustBeParameter = 'Kaikkien laskujen tila täytyy olla \'{0}\'!';
    static WorkOrderRemoveFromInvoiceConfirmation = 'Haluatko todella poistaa tilauksen \'{0}\' laskulta?';
    static WorkOrderRemoveFromInvoiceFailedConcurrencyError = 'Tilauksen \'{0}\' poisto laskulta \'{1}\' epäonnistui, koska laskun tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrderRemoveFromInvoiceFailedErrorParameter = 'Tilauksen \'{0}\' poisto laskulta \'{1}\' epäonnistui! Virhe: \'{2}\'';
    static WorkOrderRemoveFromInvoiceSuccess = 'Tilauksen \'{0}\' poisto laskulta \'{1}\' onnistui!';
    static WorkOrdersToBeAddedMustHaveSameCustomerAsInvoice = 'Lisättävillä tilauksilla täytyy olla sama asiakas kuin laskulla!';
    static IsRelatedToInvoiceParameter = 'Liittyy laskuun {0}';
    static NoCompetenciesDefined = 'Pätevyyksiä ei määritelty';
    static AllWorkOrderTypes = 'Kaikki tyypit';
    static CustomerErpReference = 'Asiakkaan ERP-viitekoodi';
    static EventLogMessageInvoiceFileCreated = 'Laskutustiedosto luotu';
    static WorkOrdersToBeAddedMustHaveSameWorkOrderTypeAsInvoice = 'Lisättävillä tilauksilla täytyy olla sama tilaustyyppi kuin laskulla!';
    static Definitions = 'Selitteet';
    static NewWork = 'Uusi työ';
    static Show = 'Näytä';
    static Team = 'Tiimi';
    static NoMatchesFound = 'Tuloksia ei löytynyt';
    static ShowAdditionalResults = 'Näytä lisää tuloksia...';
    static Collapse = 'Supista';
    static Expand = 'Laajenna';
    static Calendar = 'Kalenteri';
    static InvoicingTitle = 'Laskutus';
    static MapTitle = 'Kartta';
    static WorkCalendarTitle = 'Suunnittelu';
    static WorkListTitle = 'Laskutus';
    static WorkTimeTitle = 'Työaika';
    static Billable = 'Laskutettava';
    static Changed = 'Muutettu';
    static Reported = 'Raportoitu';
    static WorkingTimeRegistrations = 'Työvuorot';
    static CalendarAccuracyDayTwoWeeks = '24h';
    static CalendarAccuracyQuarterHour = '15 min';
    static NextDay = 'Seuraava päivä';
    static PreviousDay = 'Edellinen päivä';
    static Weeks = 'Viikot';
    static CalendarAccuracyFourHours = '4 h';
    static AddNew = 'Lisää uusi';
    static OwnSettings = 'Omat asetukset';
    static SystemConfiguration = 'Järjestelmän konfigurointi';
    static Reports = 'Raportit';
    static Select = 'Valitse';
    static Projects = 'Projektit';
    static Resources = 'Resurssit';
    static StandByWorks = 'Odottavat työt';
    static Teams = 'Tiimit';
    static SelectedOrder = 'Valittu tilaus';
    static FinishedWorks = 'Valmiit työt';
    static TransferredWork = 'Siirretyt työt';
    static Recurrence = 'Toistuvuus';
    static Allday = 'Koko päivä';
    static EndTime = 'Loppuu';
    static RecurrenceDuration = 'Kesto';
    static StartTime = 'Alkaa';
    static Daily = 'Päivittäin';
    static Days = 'Päivä';
    static Every = 'Joka';
    static EveryWeekday = 'Joka arkipäivä';
    static Monthly = 'Kuukausittain';
    static Weekly = 'Viikottain';
    static Yearly = 'Vuosittain';
    static Today = 'Tänään';
    static NoSelectedWorkOrders = 'Ei tilauksia valittuna';
    static NewContactPerson = 'Uusi yhteyshenkilö';
    static NewCustomer = 'Uusi asiakas';
    static Subscriber = 'Tilaaja';
    static WorkOrderTasks = 'Tehtävät';
    static CustomerMustBeDefined = 'Asiakas täytyy määritellä!';
    static Hour = 'Tunti';
    static HourPluralDuration = 'Tuntia';
    static Minutes = 'minuuttia';
    static Always = 'Aina';
    static April = 'huhtikuu';
    static August = 'elokuu';
    static Day = 'Päivä';
    static DayInterval = 'Päivä';
    static DayPluralDuration = 'Päivää';
    static December = 'joulukuu';
    static EditRecurrence = 'Muokkaa toistuvuutta';
    static February = 'helmikuu';
    static Friday = 'perjantai';
    static January = 'tammikuu';
    static July = 'heinäkuu';
    static June = 'kesäkuu';
    static MakeRecurring = 'Tee toistuvaksi';
    static March = 'maaliskuu';
    static May = 'toukokuu';
    static Monday = 'maanantai';
    static MonthIntervalOn = 'kuukauden välein';
    static November = 'marraskuu';
    static October = 'lokakuu';
    static OnDay = 'päivänä';
    static RangeOfRecurrence = 'Toistumisaikaväli';
    static RecurrenceAfterOccurrences = 'kerran jälkeen';
    static RecurrenceEndAfter = 'Lopetetaan';
    static RecurrenceEndBy = 'Päättyminen';
    static RecurrencePattern = 'Toistumiskaava';
    static RemoveRecurrence = 'Poista toistuvuus';
    static Saturday = 'lauantai';
    static September = 'syyskuu';
    static StartDateAndEndDateMustBeSpecifiedBeforeRecurrenceCanBeDefined = 'Alkaa ja päättyy ajat täytyy määritellä ennen toistuvuuden määrittelyä!';
    static Sunday = 'sunnuntai';
    static Thursday = 'torstai';
    static Tuesday = 'tiistai';
    static Wednesday = 'keskiviikko';
    static WeekIntervalOn = 'viikko';
    static WeekPluralDuration = 'Viikkoa';
    static WorkTime = 'Työaika';
    static YearIntervalOn = 'vuoden välein';
    static EventLogMessageWorkOrderConvertedToRecurringProject = 'Tilaus muutettu toistuvaksi tilaukseksi';
    static OnlyWorkOrderThatIsNotAttachedToProjectCanBeConvertedToRecurringProject = 'Vain työ, jota ei ole liitetty projektiin, voidaan muuttaa toistuvaksi tilaukseksi!';
    static StartTimeAndEndTimeMustBeDefinedForRecurringWork = 'Toistuvan työn alkuaika ja loppuaika täytyy määritellä!';
    static EventLogMessageWorkOrderRecurrenceChanged = 'Toistuvan tilauksen toistuvuutta muutettu';
    static RecurringWorkMustHaveAtLeastOneOccurence = 'Toistuva työllä täytyy olla ainakin yksi instanssi!';
    static EndDateMustBeDefined = 'Päättymisaika täytyy määritellä!';
    static AbrHours = 'h';
    static AbrMins = 'min';
    static CostCenter = 'Kustannuspaikka';
    static NoWorkShiftsForThisDay = 'Ei työvuoroja';
    static Order = 'Tilaus';
    static Orders = 'Tilaukset';
    static Settings = 'Asetukset';
    static Break = 'Tauko';
    static BreakReason = 'Tauon syy';
    static CommentMustBeDefined = 'Kommentti täytyy määritellä!';
    static Continue = 'Jatka';
    static CurrentWorkShiftIsNotRelatedToWorkOrder = 'Käynnissä oleva työ ei liity tilaukseen!';
    static CurrentWorkShiftNotFound = 'Käynnissä olevaa työtä ei löytynyt!';
    static DoYouWantToSetCurrentWorkOrderDone = 'Haluatko merkitä nykyisen tilauksen valmiiksi?';
    static EndLocation = 'Päättymissijainti';
    static Finish = 'Lopeta';
    static NonOrder = 'Ei tilausta';
    static NotInUse = 'Ei käytössä';
    static Pause = 'Keskeytä';
    static PauseWork = 'Keskeytä mittaus';
    static Start = 'Aloita';
    static StartLocation = 'Aloitussijainti';
    static StartNew = 'Aloita uusi';
    static SuitableWorkOrdersOrCostCentersNotFound = 'Sopivia tilauksia tai kustannuspaikkoja ei löytynyt!';
    static WorkShiftTimeSlotTypeLunchBreak = 'Lounas';
    static WorkShiftInProgress = 'Työ käynnissä';
    static WorkShiftTimeSlotSaveFailedConcurrencyError = 'Työaikakirjauksen tallennus epäonnistui, koska työaikakirjauksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkShiftTimeSlotSaveFailedErrorParameter = 'Työaikakirjauksen tallennus epäonnistui! Virhe: \'{0}\'';
    static WorkShiftTimeSlotSaveSuccess = 'Työaikakirjauksen tallennus onnistui!';
    static WorkShiftTimeSlotStateInvoicing = 'Laskutuksessa';
    static WorkShiftTimeSlotStateDone = 'Raportoitu';
    static WorkShiftTimeSlotStateInProgress = 'Käynnissä';
    static PreviousWorkShiftNotFound = 'Edellistä työvuoroa ei löytynyt!';
    static WorkShiftTimeSlotTypeBreak = 'Tauko';
    static WorkShiftTimeSlotTypeWork = 'Työ';
    static AllTypes = 'Kaikki tyypit';
    static AType = 'Tyyppi';
    static Task = 'Tehtävä';
    static NoSelectedInvoice = 'Ei laskua valittuna';
    static SelectedInvoice = 'Valittu lasku';
    static ThisWeek = 'Tällä viikolla';
    static InvoicesTransfer = 'Siirto laskutukseen';
    static Salaries = 'Palkat';
    static WorkingTimeRecords = 'Työaikakirjaukset';
    static WorkOrdersChecking = 'Tilausten tarkistus';
    static RoutePoint = 'Reittipiste';
    static RoutePointBookingCheckParameterNotFound = 'Reittikirjauksen tarkistuskirjausta \'{0}\' ei löytynyt!';
    static RoutePointBookingParameterNotFound = 'Reittikirjausta \'{0}\' ei löytynyt!';
    static RoutePointBookingRemoveConfirmation = 'Haluatko poistaa reittikirjauksen?';
    static RoutePointBookingRemoveFailedConcurrencyError = 'Reittikirjauksen \'{0}\' poisto epäonnistui, koska reittikirjauksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static RoutePointBookingRemoveFailedErrorParameter = 'Reittikirjauksen \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
    static RoutePointBookingRemoveSuccess = 'Reittikirjauksen \'{0}\' poisto onnistui!';
    static RoutePointBookings = 'Reittikirjaukset';
    static RoutePointBookingSaveFailedConcurrencyError = 'Reittikirjauksen \'{0}\' tallennus epäonnistui, koska reittipistekirjauksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static RoutePointBookingSaveFailedErrorParameter = 'Reittikirjauksen \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
    static RoutePointBookingSaveSuccess = 'Reittikirjauksen \'{0}\' tallennus onnistui!';
    static RoutePointNotFound = 'Reittipistettä ei löytynyt!';
    static Total = 'Yhteensä';
    static AllRoutePointsHaveBeenAdded = 'Kaikki reittipisteet on lisätty!';
    static RecurringWorks = 'Toistuvat työt';
    static NoRoutePointChecksDefined = 'Reittipisteelle ei ole määritelty tarkistuksia ';
    static RecurringWork = 'Toistuva työ';
    static StartRouteBooking = 'Aloita reittikirjausten syöttö';
    static RoutePointChecks = 'Reittipisteen tarkistukset';
    static CountryCode = 'Maakoodi';
    static MoveDown = 'Siirrä alaspäin';
    static MoveUp = 'Siirrä ylöspäin';
    static RoutePointCheck = 'Reittipisteen tarkistus';
    static RoutePointCheckParameterNotFound = 'Reittipisteen tarkistusta \'{0}\' ei löytynyt!';
    static RoutePointParameterNotFound = 'Reittipisttä \'{0}\' ei löytynyt!';
    static RoutePoints = 'Reittipisteet';
    static WorkOrderRemoveSuccess = 'Tilauksen \'{0}\' poisto onnistui!';
    static WorkOrdersRemoveConfirmation = 'Haluatko todella poistaa valitut {0} tilausta?';
    static WorkOrdersRemoveFailedConcurrencyError = 'Tilausten \'{0}\' poisto epäonnistui, koska tilausten tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrdersRemoveFailedErrorParameter = 'Tilausten \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
    static EmptyResponse = 'Tyhjä vastaus';
    static GeocodeFailedFailedErrorParameter = 'Geokoodaus epäonnistui! Virhe: \'{0}\'';
    static NullValueResponse = 'Null-arvoinen vastaus';
    static ClickToChangeImage = 'Klikkaa vaihtaaksesi kuvaa';
    static InvoiceAddress = 'Laskutusosoite';
    static InvoicePeriod = 'Laskutusjakso';
    static SelectImageFile = 'Valitse kuvatiedosto!';
    static WorkOrderParameterEmployeesMustBeDefined = 'Työn \'{0}\' työntekijät täytyy määritellä!';
    static WorkOrderParameterEndDateMustBeDefined = 'Työn \'{0}\' päättymisaika täytyy määritellä!';
    static WorkOrderParameterHourBookingsMustBeDefined = 'Työn \'{0}\' tuntikirjaukset täytyy määritellä!';
    static WorkOrderParameterRideBookingsMustBeDefined = 'Työn \'{0}\' matkakirjaukset täytyy määritellä!';
    static WorkOrderParameterStartDateMustBeDefined = 'Työn \'{0}\' aloitusaika täytyy määritellä!';
    static WorkOrderParameterStateChangeFailedConcurrencyError = 'Tilauksen \'{0}\' tilan vaihto epäonnistui, koska tilauksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrderParameterStateChangeFailedErrorParameter = 'Tilauksen \'{0}\' tilan vaihto epäonnistui! Virhe: \'{1}\'';
    static WorkOrderParameterStateChangeSuccess = 'Tilauksen \'{0}\' tilan vaihto onnistui!';
    static WorkOrderProjectParameterStateCannotBeChangedBecauseTasksAreNotInSameState = 'Projektin \'{0}\' tilaa ei voi vaihtaa, koska kaikki sen tehtävät eivät ole samassa tilassa!';
    static WorkOrdersParameterStateChangeFailedConcurrencyError = 'Tilausten \'{0}\' tilan vaihto epäonnistui, koska tilausten tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrdersParameterStateChangeFailedErrorParameter = 'Tilausten \'{0}\' tilan vaihto epäonnistui! Virhe: \'{1}\'';
    static WorkOrdersParameterStateChangeSuccess = 'Tilausten \'{0}\' tilan vaihto onnistui!';
    static WorkOrderTaskParameterEmployeesMustBeDefined = 'Tehtävän \'{0}\' työntekijät täytyy määritellä!';
    static WorkOrderTaskParameterEndDateMustBeDefined = 'Tehtävän \'{0}\' päättymisaika täytyy määritellä!';
    static WorkOrderTaskParameterHourBookingsMustBeDefined = 'Tehtävän \'{0}\' tuntikirjaukset täytyy määritellä!';
    static WorkOrderTaskParameterRideBookingsMustBeDefined = 'Tehtävän \'{0}\' matkakirjaukset täytyy määritellä!';
    static WorkOrderTaskParameterStartDateMustBeDefined = 'Tehtävän \'{0}\' aloitusaika täytyy määritellä!';
    static Card = 'Kortti';
    static List = 'Lista';
    static Latitude = 'Leveyspiiri';
    static Longitude = 'Pituuspiiri';
    static Location = 'Sijainti';
    static WorkOrderSaveFailedRecurrenceChangeFailed = 'Tilauksen \'{0}\' tallennus epäonnistui! Toistuvien tehtävien luonti epäonnistui!\'';
    static APrint = 'Tuloste';
    static Data = 'Tiedot';
    static NoCheckedWorkOrdersFound = 'Laskutettava-tilassa olevia tilauksia ei löytynyt!';
    static Address = 'Osoite';
    static CustomerSites = 'Asiakkaiden kohteet ja reitit';
    static SiteParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders = 'Kohdetta \'{0}\' ei voi poistaa, koska kohde on käytössä {1} tilauksessa!';
    static SiteRemoveConfirmation = 'Haluatko todella poistaa kohteen \'{0}\'?';
    static SiteRemoveFailedConcurrencyError = 'Kohteen \'{0}\' poisto epäonnistui, koska kohteen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static SiteRemoveFailedErrorParameter = 'Kohteen \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
    static SiteRemoveSuccess = 'Kohteen \'{0}\' poisto onnistui!';
    static FieldParameterValueMustBeDefined = 'Kentän \'{0}\' arvo täytyy määritellä!';
    static AttachExisting = 'Liitä olemassa oleva';
    static ContactWithNameParameterAlreadyExists = 'Yhteyshenkilö \'{0}\' on jo olemassa!';
    static StartTimeMustBeDefined = 'Alkuaika täytyy määritellä!';
    static WorkingTimeRegistration = 'Työvuoro';
    static NotTransfered = 'Ei siirretty';
    static OwnerCannotBeChanged = 'Omistajaa ei voi vaihtaa!';
    static SelectCostCenter = 'Valitse kustannuspaikka';
    static SelectWorkOrder = 'Valitse tilaus';
    static TransferredToInvoicing = 'Siirretty laskutukseen';
    static TransferredToSalary = 'Siirretty palkanlaskentaan';
    static WorkShiftTimeSlotHasBeenTranferredToInvoicing = 'Työaikakirjaus on jo siirretty laskutukseen!';
    static WorkShiftTimeSlotHasBeenTranferredToSalaryPayment = 'Työaikakirjaus on jo siirretty palkanlaskentaan!';
    static WorkShiftTimeSlotParameterNotFound = 'Työaikakirjausta \'{0}\' ei löytynyt!';
    static WorkShiftTimeSlotStateComplete = 'Käsitelty';
    static WorkShiftTimeSlotStateSalary = 'Palkanlaskennassa';
    static DoYouReallyWantToSaveWorkShiftTimeSlot = 'Haluatko todella tallentaa työaikakirjauksen?';
    static SelectTaskType = 'Valitse tehtävän tyyppi';
    static Roadmap = 'Tiestö';
    static Satellite = 'Satelliitti';
    static Terrain = 'Maasto';
    static WorkOrdersApproveToBeInvoicedConfirmation = 'Haluatko todella hyväksy valitut {0} tilausta laskutettavaksi?';
    static CreateReport = 'Luo raportti';
    static InvalidRequestData = 'Virheelliset palvelun kutsuparametrit!';
    static Preview = 'Esikatselu';
    static Report = 'Raportti';
    static ReportParameterNotFound = 'Raporttia \'{0}\' ei löytynyt!';
    static AddCustomersBeforeAddingCustomerSites = 'Lisää asiakkaat ennen kohteiden lisäämistä!';
    static MeasuredTimeParameterWorkTimeParameter = 'Mitattu aika {0},  työaikaa {1}';
    static ContinueWork = 'Jatka mittausta';
    static WorkTimeMeasurement = 'Työajan mittaus';
    static Hide = 'Piilota';
    static ToBeInvoicedRecords = 'Laskutettavat rivit';
    static WorkOrderDocumentsSaveFailedErrorParameter = 'Tilauksen \'{0}\' dokumenttien tallennus epäonnistui! Virhe: \'{1}\'';
    static WorkOrderDocumentsSaveSuccess = 'Tilauksen \'{0}\' dokumenttien tallennus onnistui!';
    static WorkOrderRemoveDocumentsFailedErrorParameter = 'Tilauksen \'{0}\' dokumenttien poisto epäonnistui! Virhe: \'{1}\'';
    static WorkOrderRemoveDocumentsSuccess = 'Tilauksen \'{0}\' dokumenttien poisto onnistui!';
    static AddHourBooking = 'Lisää tuntikirjaus';
    static AddProductBooking = 'Lisää tuotekirjaus';
    static AddRideBooking = 'Lisää matkakirjaus';
    static DoYouWantToMoveWorkOrderStateToParameterState = 'Haluatko siirtää tilauksen tilaan \'{0}\'?';
    static WorkOrderParameterHourBookingSaveFailedConcurrencyError = 'Tilauksen \'{0}\' tuntikirjauksen tallennus epäonnistui, koska tuntikirjauksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrderParameterHourBookingsSaveFailedErrorParameter = 'Tilauksen \'{0}\' tuntikirjausten tallennus epäonnistui! Virhe: \'{1}\'';
    static WorkOrderParameterHourBookingsSaveSuccess = 'Tilauksen \'{0}\' tuntikirjausten tallennus onnistui!';
    static WorkOrderParameterProductBookingSaveFailedConcurrencyError = 'Tilauksen \'{0}\' tuotekirjauksen tallennus epäonnistui, koska tuotekirjauksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrderParameterProductBookingsSaveFailedErrorParameter = 'Tilauksen \'{0}\' tuotekirjausten tallennus epäonnistui! Virhe: \'{1}\'';
    static WorkOrderParameterProductBookingsSaveSuccess = 'Tilauksen \'{0}\' tuotekirjausten tallennus onnistui!';
    static WorkOrderParameterRideBookingSaveFailedConcurrencyError = 'Tilauksen \'{0}\' matkakirjauksen tallennus epäonnistui, koska matkakirjauksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrderParameterRideBookingsSaveFailedErrorParameter = 'Tilauksen \'{0}\' matkakirjausten tallennus epäonnistui! Virhe: \'{1}\'';
    static WorkOrderParameterRideBookingsSaveSuccess = 'Tilauksen \'{0}\' matkakirjausten tallennus onnistui!';
    static WorkOrderRemoveHourBookingsFailedErrorParameter = 'Tilauksen \'{0}\' tuntikirjausten poisto epäonnistui! Virhe: \'{1}\'';
    static WorkOrderRemoveHourBookingsSuccess = 'Tilauksen \'{0}\' tuntikirjausten poisto onnistui!';
    static WorkOrderRemoveProductBookingsFailedErrorParameter = 'Tilauksen \'{0}\' tuotekirjausten poisto epäonnistui! Virhe: \'{1}\'';
    static WorkOrderRemoveProductBookingsSuccess = 'Tilauksen \'{0}\' tuotekirjausten poisto onnistui!';
    static WorkOrderRemoveRideBookingsFailedErrorParameter = 'Tilauksen \'{0}\' matkakirjausten poisto epäonnistui! Virhe: \'{1}\'';
    static WorkOrderRemoveRideBookingsSuccess = 'Tilauksen \'{0}\' matkakirjausten poisto onnistui!';
    static ChangeState = 'Muuta tila';
    static RecurringWorkCannotHaveMoreThanParameterOccurences = 'Toistuva työllä voi olla korkeintaan {0} instanssia!';
    static Hours = 'Tunnit';
    static CalculateHours = 'Laske tunnit';
    static CostCenters = 'Kustannuspaikat';
    static DailyAdditionalHours = 'Päiväkohtaiset lisät';
    static EventsWorkTimeTotal = 'Tapahtumien työaika yhteensä';
    static DoYouReallyWantToCalculateHoursAndDiscardAllCurrentData = 'Haluatko todella laskea tunnit? Kaikki nykyiset tunnit korvataan lasketuilla tunneilla.';
    static DoYouReallyWantToSaveHours = 'Haluatko todella tallentaa tunnit?';
    static HoursSaveSaveFailedErrorParameter = 'Tuntien tallennus epäonnistui! Virhe: \'{0}\'';
    static HoursSaveSuccess = 'Tuntien tallennus onnistui!';
    static EmployeeParameterCannotBeRemovedBecauseItIsRelatedToParameterHourBookings = 'Työntekijää \'{0}\' ei voi poistaa, koska työntekijä on käytössä {1} tuntikirjauksessa!';
    static EmployeeParameterCannotBeRemovedBecauseItIsRelatedToParameterSalaries = 'Työntekijää \'{0}\' ei voi poistaa, koska työntekijä on käytössä {1} palkassa!';
    static SalaryPeriod = 'Palkkajakso';
    static SalaryType = 'Palkkatyyppi';
    static Calculate = 'Laske';
    static DoYouReallyWantToCalculateSalaryAndDiscardAllCurrentData = 'Haluatko todella laskea palkkalajit? Kaikki nykyiset arvot korvataan lasketuilla arvoilla.';
    static DoYouReallyWantToSaveSalary = 'Haluatko todella tallentaa palkan?';
    static SalaryParameterNotFound = 'Palkkaa \'{0}\' ei löytynyt!';
    static SalaryRowType = 'Palkkalaji';
    static SalarySaveFailedConcurrencyError = 'Palkan \'{0}\' tallennus epäonnistui, koska palkan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static SalarySaveSaveFailedErrorParameter = 'Palkan \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
    static SalarySaveSuccess = 'Palkan tallennus onnistui!';
    static DateMustBeDefined = 'Päiväys täytyy määritellä';
    static SalaryTransfer = 'Palkkojen siirto';
    static Transferred = 'Siirretty';
    static WorkShiftTimeSlotRemoveConfirmation = 'Haluatko todella poistaa työaikakirjauksen?';
    static WorkShiftTimeSlotRemoveFailedConcurrencyError = 'Työaikakirjauksen poisto epäonnistui, koska työaikakirjauksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkShiftTimeSlotRemoveFailedErrorParameter = 'Työaikakirjauksen poisto epäonnistui! Virhe: \'{0}\'';
    static WorkShiftTimeSlotRemoveSuccess = 'Työaikakirjauksen poisto onnistui!';
    static BusinessId = 'Y-tunnus';
    static CustomerType = 'Asiakastyyppi';
    static CustomerTypesMustBeDefinedBeforeAddingCustomers = 'Asiakastyypit täytyy määritellä ennen asiakkaiden lisäystä!';
    static ProductGroup = 'Tuoteryhmä';
    static ProductGroupsMustBeDefinedBeforeAddingProducts = 'Tuoteryhmät täytyy määritellä ennen tuotteiden lisäystä!';
    static Canceled = 'Peruttu';
    static CancelWorkOrder = 'Peru tilaus';
    static EventLogMessageWorkOrderCanceled = 'Tilaus peruttu';
    static EventLogMessageWorkOrderRestored = 'Tilaus palautettu';
    static ProjectCannotBeCanceled = 'Projektia ei voi perua!';
    static RestoreWorkOrder = 'Palauta tilaus';
    static WorkOrderCancelConfirmation = 'Haluatko todella perua tilauksen?';
    static WorkOrderCancelFailedConcurrencyError = 'Tilauksen \'{0}\' peruminen epäonnistui, koska tilauksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrderCancelFailedErrorParameter = 'Tilauksen \'{0}\' peruminen epäonnistui! Virhe: \'{1}\'';
    static WorkOrderCancelSuccess = 'Tilauksen \'{0}\' peruminen onnistui!';
    static WorkOrderRestoreConfirmation = 'Haluatko todella palauttaa tilauksen?';
    static WorkOrderRestoreFailedConcurrencyError = 'Tilauksen \'{0}\' palauttaminen epäonnistui, koska tilauksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrderRestoreFailedErrorParameter = 'Tilauksen \'{0}\' palauttaminen epäonnistui! Virhe: \'{1}\'';
    static WorkOrderRestoreSuccess = 'Tilauksen \'{0}\' palauttaminen onnistui!';
    static Attachment = 'Liite';
    static WorkOrderDocumentSaveFailedErrorParameter = 'Tilauksen \'{0}\' dokumentin tallennus epäonnistui! Virhe: \'{1}\'';
    static WorkOrderDocumentSaveSuccess = 'Tilauksen \'{0}\' dokumentin tallennus onnistui!';
    static MapOnId = 'Mapon Id';
    static VehicleLocationsSaveFailedErrorParameter = 'Kaluston \'{0}\' sijaintien tallennus epäonnistui! Virhe: \'{1}\'';
    static VehicleLocationsSaveSuccess = 'Kaluston \'{0}\' sijaintien tallennus onnistui!';
    static AddToFavories = 'Lisää suosikkeihin';
    static Favorites = 'Suosikit';
    static LookUp = 'Haku';
    static RemoveFromFavories = 'Poista suosikeista';
    static SiteMapLinkUpdateFailedConcurrencyError = 'Kohteen \'{0}\' karttalinkin päivitys epäonnistui, koska kohteen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static SiteMapLinkUpdateFailedErrorParameter = 'Kohteen \'{0}\' karttalinkin päivitys epäonnistui! Virhe: \'{1}\'';
    static SiteMapLinkUpdateSuccess = 'Kohteen \'{0}\' karttalinkin päivitys onnistui!';
    static SitesMapLinkUpdateFailedConcurrencyError = 'Karttalinkin päivitys epäonnistui {0} kohteelle, koska kohteiden tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static SitesMapLinkUpdateFailedErrorParameter = 'Karttalinkin päivitys epäonnistui {0} kohteelle! Virhe: \'{1}\'';
    static SitesMapLinkUpdateSuccess = 'Karttalinkin päivitys onnistui {0} kohteelle!';
    static SitesRemoveConfirmation = 'Haluatko todella poistaa valitut {0} kohdetta?';
    static SitesRemoveFailedConcurrencyError = '{0} kohteen poisto epäonnistui, koska kohteiden tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static SitesRemoveFailedErrorParameter = '{0} kohteen poisto epäonnistui! Virhe: \'{1}\'';
    static SitesRemoveSuccess = '{0} kohteen poisto onnistui!';
    static CalendarAccuracyDefault = 'Kalenterin oletustarkkuus';
    static CalendarOfficeTimeEnd = 'Kalenterin työaika loppuu';
    static CalendarOfficeTimeStart = 'Kalenterin työaika alkaa';
    static ClickToAddImage = 'Klikkaa lisätäksesi kuva';
    static DefaultCulture = 'Kieli';
    static LanguageEnglish = 'englanti';
    static LanguageFinnish = 'suomi';
    static Map = 'Kartta';
    static OnMap = 'Kartalla';
    static TimeTable = 'Aikataulu';
    static Company = 'Yritys';
    static CompanySettings = 'Yrityksen asetukset';
    static CompetencyTypeRemoveConfirmation = 'Haluatko todella poistaa kompetenssityypin \'{0}\'?';
    static CompetencyTypes = 'Kompetenssityypit';
    static CostCenterRemoveConfirmation = 'Haluatko todella poistaa kustannuspaikan \'{0}\'?';
    static CustomerTypeRemoveConfirmation = 'Haluatko todella poistaa asiakastyypin \'{0}\'?';
    static CustomerTypes = 'Asiakastyypit';
    static DayBookingTypeRemoveConfirmation = 'Haluatko todella poistaa päiväkirjaustyypin \'{0}\'?';
    static DayBookingTypes = 'Päiväkirjaustyypit';
    static HourBookingTypes = 'Tuntikirjaustyypit';
    static Mobile = 'Mobiili';
    static ProductGroups = 'Tuoteryhmät';
    static SalaryRowTypes = 'Palkkalajit';
    static SalaryTypes = 'Palkkatyypit';
    static SendingType = 'Lähetystyyppi';
    static UserGroupRemoveConfirmation = 'Haluatko todella poistaa käyttäjäryhmän \'{0}\'?';
    static UserGroups = 'Käyttäjäryhmät';
    static VatRate = 'ALV';
    static Web = 'Planner';
    static WorkOrderAcknowledgementTypeRemoveConfirmation = 'Haluatko todella poistaa hyväksymispyyntötyypin \'{0}\'?';
    static WorkOrderAcknowledgementTypes = 'Hyväksymispyyntötyypit';
    static WorkOrderAcknowledgementTypeSendingTypeDoNotSend = 'Älä lähetä';
    static WorkOrderAcknowledgementTypeSendingTypeSendAlways = 'Lähetä aina';
    static WorkOrderAcknowledgementTypeSendingTypeSendManually = 'Lähetä manuaalisesti';
    static WorkOrderTypeRemoveConfirmation = 'Haluatko todella poistaa tilaustyypin \'{0}\'?';
    static LocationError = 'Sijainti: Ei löytynyt';
    static LocationSuccess = 'Sijainti: Asetettu';
    static AddRoute = 'Lisää reitti';
    static AddRoutePoint = 'Lisää reittipiste';
    static EditRoute = 'Muokkaa reittiä';
    static HideAdditionalInformation = 'Piilota lisätiedot';
    static Route = 'Reitti';
    static ShowAdditionalInformation = 'Näytä lisätiedot';
    static RoutePointRemoveConfirmation = 'Haluatko todella poistaa reittipisteen \'{0}\'?';
    static Approve = 'Hyväksy';
    static UnresourcedStandByWorkOrders = 'Odottavat työt, joita ei ole vielä resursoitu';
    static UnscheduledStandByWorkOrders = 'Odottavat työt, joita ei ole vielä aikataulutettu';
    static OwnerParameterNotFound = 'Yritystä \'{0}\' ei löytynyt!';
    static OwnerSaveFailedConcurrencyError = 'Yrityksen tallennus epäonnistui, koska yrityksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static OwnerSaveFailedErrorParameter = 'Yrityksen tallennus epäonnistui! Virhe: \'{0}\'';
    static OwnerSaveSuccess = 'Yrityksen tallennus onnistui!';
    static Theme = 'Teema';
    static DoLockAll = 'Lukitse';
    static DoUnlockAll = 'Poista lukitus';
    static Locked = 'Lukittu';
    static WorkShiftTimeSlotsLockingFailedConcurrencyError = 'Työaikakirjausten lukitseminen epäonnistui, koska työaikakirjauksien tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkShiftTimeSlotsLockingFailedErrorParameter = 'Työaikakirjausten lukitseminen epäonnistui! Virhe: \'{0}\'';
    static WorkShiftTimeSlotsLockingSuccess = 'Työaikakirjausten lukitseminen onnistui!';
    static WorkShiftTimeSlotStateInProgressCannotBeLocked = 'Käynnissä olevaa työaikakirjausta ei voi lukita!';
    static WorkShiftTimeSlotStateLocked = 'Lukittu';
    static WorkShiftTimeSlotStateLockedCannotBeChanged = 'Lukittua työaikakirjausta ei voi muokata!';
    static WorkShiftTimeSlotStateLockedCannotBeRemoved = 'Lukittua työaikakirjausta ei voi poistaa!';
    static WorkShiftTimeSlotsUnlockingFailedConcurrencyError = 'Työaikakirjausten lukituksen poisto epäonnistui, koska työaikakirjauksien tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkShiftTimeSlotsUnlockingFailedErrorParameter = 'Työaikakirjausten lukituksen poisto epäonnistui! Virhe: \'{0}\'';
    static WorkShiftTimeSlotsUnlockingSuccess = 'Työaikakirjausten lukituksen poisto onnistui!';
    static CalculatedH = 'Mitattu (h)';
    static InvoiceH = 'Laskutus (h)';
    static Invoicing = 'Laskutus';
    static Measured = 'Mitattu';
    static FormContainsUnapprovedValues = 'Lomake sisältää arvoja, joita ei ole hyväksytty!';
    static Kind = 'Laji';
    static PeriodAdditionalHours = 'Jaksokohtaiset lisät';
    static WorkHours = 'Työtunnit';
    static SalaryPeriodParameterNotFound = 'Palkkajaksoa \'{0}\' ei löytynyt!';
    static WorkTimePeriodParameterNotFound = 'Työaikajaksoa \'{0}\' ei löytynyt!';
    static SalaryPeriodRemoveConfirmation = 'Haluatko todella poistaa palkkajakson \'{0}\'?';
    static SalaryPeriods = 'Palkkajaksot';
    static WorkTimePeriod = 'Työaikajakso';
    static WorkTimePeriods = 'Työaikajaksot';
    static CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterHourBookings = 'Kustannuspaikkaa \'{0}\' ei voi poistaa, koska kustannuspaikka on käytössä {1} tuntikirjauksessa!';
    static CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkShiftTimeSlots = 'Kustannuspaikkaa \'{0}\' ei voi poistaa, koska kustannuspaikka on käytössä {1} työaikakirjauksessa!';
    static CostCenterParameterNotFound = 'Kustannuspaikkaa \'{0}\' ei löytynyt!';
    static CostCenterRemoveFailedConcurrencyError = 'Kustannuspaikan poisto epäonnistui, koska kustannuspaikan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static CostCenterRemoveFailedErrorParameter = 'Kustannuspaikan poisto epäonnistui! Virhe: \'{0}\'';
    static CostCenterRemoveSuccess = 'Kustannuspaikan \'{0}\' poisto onnistui!';
    static CostCenterSaveFailedConcurrencyError = 'Kustannuspaikan \'{0}\' tallennus epäonnistui, koska kustannuspaikan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static CostCenterSaveFailedErrorParameter = 'Kustannuspaikan \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
    static CostCenterSaveSuccess = 'Kustannuspaikan \'{0}\' tallennus onnistui!';
    static EmployeeGroupRemoveConfirmation = 'Haluatko todella poistaa työntekijäryhmän \'{0}\'?';
    static EmployeeGroups = 'Työntekijäryhmät';
    static EmployeeGroupsHaveNotBeenSpecified = 'Työntekijäryhmiä ei ole määritelty!';
    static SalaryTypesHaveNotBeenSpecified = 'Palkkatyyppejä ei ole määritelty!';
    static UserGroupsHaveNotBeenSpecified = 'Käyttäjäryhmiä ei ole määritelty!';
    static VehicleGroup = 'Kalustoryhmä';
    static VehicleGroupRemoveConfirmation = 'Haluatko todella poistaa kalustoryhmän \'{0}\'?';
    static VehicleGroups = 'Kalustoryhmät';
    static VehicleGroupsHaveNotBeenSpecified = 'Kalustoryhmiä ei ole määritelty!';
    static TokenHasBeenExpired = 'Istunto on vanhentunut!';
    static WorkOrderDurationIsZero = 'Tilauksen kesto on 0 minuuttia!';
    static WorkOrderParameterDurationIsZero = 'Tilauksen \'{0}\' kesto on 0 minuuttia!';
    static WorkOrderTaskParameterDurationIsZero = 'Tehtävän \'{0}\' kesto on 0 minuuttia!';
    static InvalidEndTime = 'Virheellinen päättymisaika!';
    static RemoveTask = 'Poista tehtävä';
    static RemoveTasks = 'Poista tehtävät';
    static WorkOrderProjectRecurringParameterCannotBeRemovedBecauseOtherWorkOrderTasksAreBelongingToIt = 'Toistuvaa työtä \'{0}\' ei voi poistaa, koska siihen liittyy tehtäviä!';
    static WorkOrdersDetachConfirmation = 'Haluatko todella poistaa valitut tehtävät projektilta?';
    static WorkOrdersDetachConfirmationRecurring = 'Haluatko todella poistaa valitut tehtävät toistuvalta työltä?';
    static WorkShiftStartedParameter = 'Työvuoro aloitettu {0}';
    static AddRecurringTasks = 'Lisää toistuvia tehtäviä';
    static DoYouReallyWantToAddRecurringTasks = 'Haluatko todella lisätä toistuvia tehtäviä?';
    static AddExistingWorkOrderWork = 'Lisää olemassa oleva työ';
    static AddTask = 'Lisää tehtävä';
    static AttachWorkToRecurringWork = 'Liitä työ toistuvaan työhön';
    static DoYouReallyWantToChangeWorkOrders = 'Haluatko todella muuttaa tilauksia?';
    static DurationOfRecurringTaskCannotBeZero = 'Toistuvan tehtävän kesto ei voi olla 0 minuuttia!';
    static DurationOfRecurringWorkCannotBeZero = 'Toistuvan työn kesto ei voi olla 0 minuuttia!';
    static Set = 'Aseta';
    static SetEmployees = 'Aseta työntekijät';
    static SetState = 'Aseta tila';
    static SetVehicles = 'Aseta kalusto';
    static ValuesCopiedFromRecurringWork = 'Toistuvalta työltä kopioitavat tiedot';
    static ValuesCopiedFromWorkOrderTask = 'Tehtävältä kopioitavat arvot';
    static WorkOrderAddRecurringTasksFailedConcurrencyError = 'Toistuvien tehtävien lisäys epäonnistui, koska toistuvan työn tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrderAddRecurringTasksFailedErrorParameter = 'Toistuvien tehtävien lisäys epäonnistui! Virhe: \'{0}\'';
    static WorkOrderAddRecurringTasksSuccess = 'Toistuvien tehtävien lisäys onnistui!';
    static WorkOrderParameterEmployeesChangeFailedConcurrencyError = 'Tilauksen \'{0}\' työntekijöiden asetus epäonnistui, koska tilauksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrderParameterEmployeesChangeFailedErrorParameter = 'Tilauksen \'{0}\' työntekijöiden asetus epäonnistui! Virhe: \'{1}\'';
    static WorkOrderParameterEmployeesChangeSuccess = 'Tilauksen \'{0}\' työntekijöiden asetus onnistui!';
    static WorkOrderParameterVehiclesChangeFailedConcurrencyError = 'Tilauksen \'{0}\' kaluston asetus epäonnistui, koska tilauksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrderParameterVehiclesChangeFailedErrorParameter = 'Tilauksen \'{0}\' kaluston asetus epäonnistui! Virhe: \'{1}\'';
    static WorkOrderParameterVehiclesChangeSuccess = 'Tilauksen \'{0}\' kaluston asetus onnistui!';
    static WorkOrdersParameterEmployeesChangeFailedConcurrencyError = 'Tilausten \'{0}\' työntekijöiden asetus epäonnistui, koska tilausten tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrdersParameterEmployeesChangeFailedErrorParameter = 'Tilausten \'{0}\' työntekijöiden asetus epäonnistui! Virhe: \'{1}\'';
    static WorkOrdersParameterEmployeesChangeSuccess = 'Tilausten \'{0}\' työntekijöiden asetus onnistui!';
    static WorkOrdersParameterVehclesChangeFailedErrorParameter = 'Tilausten \'{0}\' kaluston asetus epäonnistui! Virhe: \'{1}\'';
    static WorkOrdersParameterVehiclesChangeFailedConcurrencyError = 'Tilausten \'{0}\' kaluston asetus epäonnistui, koska tilausten tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrdersParameterVehiclesChangeSuccess = 'Tilausten \'{0}\' kaluston asetus onnistui!';
    static IntegrationParameterSaveFailed = 'Integrointiparametrin tallennus epäonnistui';
    static IntegrationParameterSaveSuccess = 'Integrointiparametri tallennettu onnistuneesti';
    static WorkOrderParameterRoutePointSaveFailedConcurrencyError = 'Tilauksen \'{0}\' reittipisteen tallennus epäonnistui, koska reittipisteen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrderParameterRoutePointsSaveFailedErrorParameter = 'Tilauksen \'{0}\' reittipisteiden tallennus epäonnistui! Virhe: \'{1}\'';
    static WorkOrderParameterRoutePointsSaveSuccess = 'Tilauksen \'{0}\' reittipisteiden tallennus onnistui!';
    static WorkOrderRemoveRoutePointsFailedErrorParameter = 'Tilauksen \'{0}\' reittipisteiden poisto epäonnistui! Virhe: \'{1}\'';
    static WorkOrderRemoveRoutePointsSuccess = 'Tilauksen \'{0}\' reittipisteiden poisto onnistui!';
    static CreateSalaryFileDataFailedErrorParameter = 'Palkkatiedoston luonti epäonnistui! Virhe: \'{0}\'';
    static CreateSalaryFileDataSuccess = 'Palkkatiedoston luonti onnistui!';
    static EventLogMessageSalaryFileCreated = 'Palkkatiedosto luotu';
    static SalaryFile = 'Palkkatiedosto';
    static BusinessIdentityCode = 'Y-tunnus';
    static EInvoiceAddress = 'Verkkolaskuosoite';
    static EInvoiceOperator = 'Operaattori';
    static InvoiceEmail = 'Laskutussähköposti';
    static SiteTypePoint = 'Osoite';
    static SiteTypeRoute = 'Reitti';
    static VatCode = 'ALV-tunnus';
    static WeekHours = 'Viikkotunnit';
    static WorkTimeTotal = 'Työaika yhteensä';
    static WorkShiftTimeSlot = 'Työaikakirjaus';
    static WorkShiftTimeSlotTypeParameterNotFound = 'Työaikakirjaustyyppiä \'{0}\' ei löytynyt!';
    static WorkShiftTimeSlotTypesHaveNotBeenSpecified = 'Työaikakirjaustyyppejä ei ole määritelty!';
    static AllRoutePointsHaveBeenHandled = 'Kaikki reittipisteet on käsitelty!';
    static RoutePointWorkShiftTimeSlotEndTime = 'Lähtöaika';
    static RoutePointWorkShiftTimeSlotEndTimeSet = 'Aseta lähtöaika';
    static RoutePointWorkShiftTimeSlotStartTime = 'Tuloaika';
    static SavingIsNotFinished = 'Tallennus on vielä kesken!';
    static WorkOrderParameterRoutePointParameterHasBookingsAndCannotBeRemoved = 'Tilauksen \'{0}\' reittipisteellä \'{1}\' on kirjauksia, joten reittipistettä ei voi poistaa!';
    static ParameterTargets = '{0} kohdetta';
    static SiteTypeNoLocation = 'Toimipiste';
    static Integrations = 'Integroinnit';
    static VehiclesVisibleOnMap = 'Kartalla näkyvä kalusto';
    static Abbreviation = 'Lyhenne';
    static Telematics = 'Kalustonhallinta';
    static SiteCannotBeChangedWhenRoutePointBookingsAreDefined = 'Kohdetta ei voi muuttaa, jos kohteen reitille on määritelty reittikirjauksia!';
    static WorkShiftTimeSlotDurationCannotBeExtended = 'Työaikakirjauksen kestoa ei voi pidentää!';
    static CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterEmployees = 'Kustannuspaikkaa \'{0}\' ei voi poistaa, koska kustannuspaikka on käytössä {1} työntekijässä!';
    static CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterVehicles = 'Kustannuspaikkaa \'{0}\' ei voi poistaa, koska kustannuspaikka on käytössä {1} kalustossa!';
    static EmployeeGroupParameterCannotBeRemovedBecauseItIsRelatedToParameterEmployees = 'Työntekijäryhmää \'{0}\' ei voi poistaa, koska työntekijäryhmä on käytössä {1} työntekijässä!';
    static EmployeeGroupParameterNotFound = 'Työntekijäryhmää \'{0}\' ei löytynyt!';
    static EmployeeGroupRemoveFailedConcurrencyError = 'Työntekijäryhmän poisto epäonnistui, koska työntekijäryhmän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static EmployeeGroupRemoveFailedErrorParameter = 'Työntekijäryhmän poisto epäonnistui! Virhe: \'{0}\'';
    static EmployeeGroupRemoveSuccess = 'Työntekijäryhmän poisto onnistui!';
    static EmployeeGroupSaveFailedConcurrencyError = 'Työntekijäryhmän tallennus epäonnistui, koska työntekijäryhmän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static EmployeeGroupSaveFailedErrorParameter = 'Työntekijäryhmän tallennus epäonnistui! Virhe: \'{0}\'';
    static EmployeeGroupSaveSuccess = 'Työntekijäryhmän tallennus onnistui!';
    static DurationMustBeDefined = 'Kesto täytyy määritellä!';
    static Employment = 'Laskentamalli';
    static AddDayBooking = 'Lisää päiväkirjaus';
    static AddWorkShiftTimeSlot = 'Lisää työaikakirjaus';
    static CostCentersHaveNotBeenSpecified = 'Kustannuspaikkoja ei ole määritelty!';
    static DayBookingRemoveConfirmation = 'Haluatko todella poistaa päiväkirjauksen?';
    static DayBookingRemoveFailedErrorParameter = 'Päiväkirjauksen poisto epäonnistui! Virhe: \'{0}\'';
    static DayBookingRemoveSuccess = 'Päiväkirjauksen poisto onnistui!';
    static DayBookingSaveFailedConcurrencyError = 'Päiväkirjauksen \'{0}\' tallennus epäonnistui, koska päiväkirjauksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static DayBookingSaveSuccess = 'Päiväkirjauksen tallennus onnistui!';
    static DayBookingsRemoveFailedErrorParameter = 'Päiväkirjausten poisto epäonnistui! Virhe: \'{0}\'';
    static DayBookingsRemoveSuccess = 'Päiväkirjausten poisto onnistui!';
    static DayBookingsSaveFailedConcurrencyError = 'Päiväkirjausten tallennus epäonnistui, koska päiväkirjausten tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static DayBookingsSaveFailedErrorParameter = 'Päiväkirjausten tallennus epäonnistui! Virhe: \'{0}\'';
    static DayBookingsSaveSuccess = 'Päiväkirjausten tallennus onnistui!';
    static DetachTasks = 'Irrota tehtävät';
    static EmployeeIsNotInUse = 'Työntekijä ei ole käytössä!';
    static EventLogWorkShiftTimeSlotChanged = 'Työaikakirjausta muokattu';
    static InvalidPassword = 'Virheellinen salasana!';
    static RefreshTokenIsEmpty = 'Refresh token on tyhjä!';
    static RefreshTokenValidationFailed = 'Refresh token validointi epäonnistui!';
    static SigningIn = 'Kirjautuminen';
    static SigningOut = 'Uloskirjautuminen';
    static SignInRefresh = 'Kirjautumisen uusiminen';
    static OrginalTimeParameter = 'Alkuperäinen aika: {0}';
    static InvoiceHoursAreNotCloseToReportedHours = 'Laskutettavat tunnit eroavat huomattavasti raportoiduista tunneista!';
    static CodeMustBeDefined = 'Tunnus täytyy määritellä!';
    static CustomerTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterCustomers = 'Asiakastyyppiä \'{0}\' ei voi poistaa, koska asiakastyyppi on käytössä {1} asiakkaassa!';
    static CustomerTypeParameterNotFound = 'Asiakastyyppiä \'{0}\' ei löytynyt!';
    static CustomerTypeRemoveFailedConcurrencyError = 'Asiakastyypin poisto epäonnistui, koska asiakastyypin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static CustomerTypeRemoveFailedErrorParameter = 'Asiakastyypin poisto epäonnistui! Virhe: \'{0}\'';
    static CustomerTypeRemoveSuccess = 'Asiakastyypin poisto onnistui!';
    static CustomerTypeSaveFailedConcurrencyError = 'Asiakastyypin tallennus epäonnistui, koska asiakastyypin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static CustomerTypeSaveFailedErrorParameter = 'Asiakastyypin tallennus epäonnistui! Virhe: \'{0}\'';
    static CustomerTypeSaveSuccess = 'Asiakastyypin tallennus onnistui!';
    static ProductGroupParameterCannotBeRemovedBecauseItIsRelatedToParameterProducts = 'Tuoteryhmää \'{0}\' ei voi poistaa, koska tuoteryhmä on käytössä {1} tuotteessa!';
    static ProductGroupParameterNotFound = 'Tyoteryhmää \'{0}\' ei löytynyt!';
    static ProductGroupRemoveConfirmation = 'Haluatko todella poistaa tuoteryhmän \'{0}\'?';
    static ProductGroupRemoveFailedConcurrencyError = 'Tuoteryhmän poisto epäonnistui, koska tuoteryhmän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static ProductGroupRemoveFailedErrorParameter = 'Tuoteryhmän poisto epäonnistui! Virhe: \'{0}\'';
    static ProductGroupRemoveSuccess = 'Tuoteryhmän poisto onnistui!';
    static ProductGroupSaveFailedConcurrencyError = 'Tuoteryhmän tallennus epäonnistui, koska tuoteryhmän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static ProductGroupSaveFailedErrorParameter = 'Tuoteryhmän tallennus epäonnistui! Virhe: \'{0}\'';
    static ProductGroupSaveSuccess = 'Tuoteryhmän tallennus onnistui!';
    static VehicleGroupParameterCannotBeRemovedBecauseItIsRelatedToParameterVehicles = 'Kalustoryhmää \'{0}\' ei voi poistaa, koska kalustoryhmä on käytössä {1} kalustossa!';
    static VehicleGroupParameterNotFound = 'Kalustoryhmää \'{0}\' ei löytynyt!';
    static VehicleGroupRemoveFailedConcurrencyError = 'Kalustoryhmän poisto epäonnistui, koska kalustoryhmän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static VehicleGroupRemoveFailedErrorParameter = 'Kalustoryhmän poisto epäonnistui! Virhe: \'{0}\'';
    static VehicleGroupRemoveSuccess = 'Kalustoryhmän poisto onnistui!';
    static VehicleGroupSaveFailedConcurrencyError = 'Kalustoryhmän tallennus epäonnistui, koska kalustoryhmän tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static VehicleGroupSaveFailedErrorParameter = 'Kalustoryhmän tallennus epäonnistui! Virhe: \'{0}\'';
    static VehicleGroupSaveSuccess = 'Kalustoryhmän tallennus onnistui!';
    static FilterWorkOrders = 'Rajaa tilauksia';
    static ClearFilters = 'Tyhjennä rajaukset';
    static MyWorkList = 'Oma työlista';
    static SelectedDay = 'Valittu päivä';
    static SelectedPeriod = 'Valittu jakso';
    static TimeFilter = 'Aikarajaus';
    static IntegrationServiceCallFailedErrorParameter = 'Integraatiopalvelun kutsu epäonnistui! Virhe: \'{0}\'';
    static EmployeeParametersSavedSuccessfully = 'Työntekijän asetusten tallennus onnistui!';
    static EmployeeParametersSaveFailedConcurrencyError = 'Työntekijän asetusten epäonnistui, koska työntekijän asetuksia on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static EmployeeParameterSSaveFailedErrorParameter = 'Työntekijän asetusten tallennus epäonnistui! Virhe: \'{0}\'';
    static ReadyWorkOrders = 'Valmiit tilaukset';
    static ToBeInvoicedWorkOrders = 'Laskutettavat tilaukset';
    static PreliminaryInvoices = 'Alustavat laskut';
    static TransferredInvoices = 'Siirretyt laskut';
    static SsoIntegrations = 'SSO Integraatiot';
    static SsoParameters = 'SSO Parametrit';
    static EmployeeMustBeDefined = 'Työntekijä täytyy määritellä!';
    static MeasureUnitTn = 'tn';
    static ExportToInvoicingSystem = 'Vie laskutusjärjestelmään';
    static ExportToTransferFile = 'Vie siirtotiedostoon';
    static FileExportedToInvoicingSystem = 'Tiedosto \'{0}\' on nyt viety laskutusjärjestelmään';
    static GoToWorkOrderArchive = 'Siirry tilausarkistoon';
    static OverlappingWorkShiftTimeSlots = 'Päällekkäisiä työaikakirjauksia!';
    static ShowSelectedCostCenters = 'Näytä valitut kustannuspaikat';
    static ShowSelectedVehicles = 'Näytä valittu kalusto';
    static CustomerPriceNotFound = 'Asiakaskohtaista hintaa ei löytynyt!';
    static CustomerPriceRemoveFailedConcurrencyError = 'Asiakaskohtaisen hinnan poisto epäonnistui, koska asiakaskohtaisen hinnan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static CustomerPriceRemoveFailedError = 'Asiakaskohtaisen hinnan poisto epäonnistui! Virhe: {0}';
    static CustomerPriceRemoveSuccess = 'Asiakaskohtaisen hinnan poisto onnistui!';
    static CustomerPriceSaveFailedConcurrencyError = 'Asiakaskohtaisen hinnan tallennus epäonnistui, koska asiakaskohtaisen hinnan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static CustomerPriceSaveFailedError = 'Asiakaskohtaisen hinnan tallennus epäonnistui! Virhe: {0}';
    static CustomerPriceSaveSuccess = 'Asiakaskohtaisen hinnan tallennus onnistui!';
    static CustomerPriceRemoveConfirmation = 'Haluatko todella poistaa asiakaskohtaisen hinnan?';
    static CustomerPrices = 'Asiakaskohtaiset hinnat';
    static CustomerPrice = 'Asiakashinta';
    static APIInvoiceSuccessfullyCreated = 'Lasku {0} luotiin onnistuneesti';
    static InvoicesRemoveConfirmation = 'Haluatko todella poistaa valitut {0} laskua?';
    static InvoicesRemoveFailedConcurrencyError = 'Laskujen \'{0}\' poisto epäonnistui, koska laskujen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static InvoicesRemoveFailedErrorParameter = 'Laskujen \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
    static InvoicesRemoveSuccess = 'Laskujen \'{0}\' poisto onnistui!';
    static RelatedToInvoice = 'Liitetty laskuun';
    static AllWorkOrderClasses = 'Kaikki tilausluokat';
    static WorkOrderClass = 'Tilausluokka';
    static WorkOrderProjectTask = 'Projektin tehtävä';
    static WorkOrderRecurringTask = 'Toistuva tehtävä';
    static InvoiceReturnToPreliminaryConfirmation = 'Haluatko todella palauttaa laskun \'{0}\' alustava tilaan? Nykyinen lasku poistetaan ja laskun tilauksille luodaan uusi lasku.';
    static InvoicesReturnToPreliminaryConfirmation = 'Haluatko todella palauttaa valitut {0} laskua alustava tilaan? Nykyiset laskut poistetaan ja tilauksille luodaan uudet laskut.';
    static ReturnToPreliminaryState = 'Palauta alustavaksi';
    static WorkOrderDistanceInvoicingByActualDistance = 'Toteutuneet kilometrit';
    static WorkOrderDistanceInvoicingByPlannedDistance = 'Suunnitellut kilometrit';
    static WorkOrderDistanceInvoicingNone = 'Ei mitään';
    static UserGroupPermissionAdmin = 'Ylläpitäjä';
    static UserGroupPermissionEdit = 'Käyttäjä';
    static UserGroupPermissionNone = 'Ei oikeuksia';
    static EstimatedHours = 'Työmäärä tunteina';
    static DetachFromRecurringWork = 'Irrota toistuvasta työstä';
    static EstimatedHoursMustBeDefined = 'Työmäärä tunteina täytyy määritellä!';
    static SetDurationByEstimatedHours = 'Aseta kesto työmäärän perusteella';
    static WorkOrderParameterDurationChangeFailedConcurrencyError = 'Tilauksen \'{0}\' keston muutos epäonnistui, koska tilauksen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrderParameterDurationChangeFailedErrorParameter = 'Tilauksen \'{0}\' keston muutos epäonnistui! Virhe: \'{1}\'';
    static WorkOrderParameterDurationChangeSuccess = 'Tilauksen \'{0}\' keston muutos onnistui!';
    static WorkOrderParameterEstimatedHoursMustBeDefined = 'Tilauksen \'{0}\' työmäärä tunteina täytyy määritellä!';
    static WorkOrdersParameterDurationChangeFailedConcurrencyError = 'Tilausten \'{0}\' keston muutos epäonnistui, koska tilausten tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrdersParameterDurationChangeFailedErrorParameter = 'Tilausten \'{0}\' keston muutos epäonnistui! Virhe: \'{1}\'';
    static WorkOrdersParameterDurationChangeSuccess = 'Tilausten \'{0}\' keston muutos onnistui!';
    static WorkOrderTaskParameterEstimatedHoursMustBeDefined = 'Tehtävän \'{0}\' työmäärä tunteina täytyy määritellä!';
    static And = 'ja';
    static Month = 'Kuukausi';
    static Occurs = 'Toistuu';
    static Year = 'Vuosi';
    static NewProject = 'Uusi projekti';
    static DayBookingTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterDayBookings = 'Päiväkirjaustyyppiä \'{0}\' ei voi poistaa, koska päiväkirjaustyyppi on käytössä {1} päiväkirjauksessa!';
    static DayBookingTypeParameterNotFound = 'Päiväkirjaustyyppiä \'{0}\' ei löytynyt!';
    static DayBookingTypeParameterParameterSaveFailedErrorParameter = 'Päiväkirjaustyypin \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
    static DayBookingTypeParameterRemoveFailedConcurrencyError = 'Päiväkirjaustyypin \'{0}\' poisto epäonnistui, koska päiväkirjaustyypin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static DayBookingTypeParameterRemoveFailedErrorParameter = 'Päiväkirjaustyypin \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
    static DayBookingTypeParameterRemoveSuccess = 'Päiväkirjaustyypin \'{0}\' poisto onnistui!';
    static DayBookingTypeParameterSaveFailedConcurrencyError = 'Päiväkirjaustyypin \'{0}\' tallennus epäonnistui, koska päiväkirjaustyypin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static DayBookingTypeParameterSaveSuccess = 'Päiväkirjaustyypin \'{0}\' tallennus onnistui!';
    static DayBookingTypesParameterRemoveFailedConcurrencyError = 'Päiväkirjaustyyppien \'{0}\' poisto epäonnistui, koska päiväkirjaustyyppien tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static DayBookingTypesParameterRemoveFailedErrorParameter = 'Päiväkirjaustyyppien \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
    static DayBookingTypesParameterRemoveSuccess = 'Päiväkirjaustyyppien \'{0}\' poisto onnistui!';
    static RoutingFailedFailedErrorParameter = 'Reititys epäonnistui! Virhe: \'{0}\'';
    static GeneralSettings = 'Yleiset asetukset';
    static UserProfile = 'Käyttäjäprofiili';
    static SetLocationFromMap = 'Aseta sijainti kartalta';
    static CopyRecurringWork = 'Kopioi toistuva työ';
    static CopyWorkOrderProject = 'Kopioi projekti';
    static CopyWorkOrderTask = 'Kopioi tehtävä';
    static CopyWorkOrderWork = 'Kopioi työ';
    static DataToBeCopied = 'Kopioitavat tiedot';
    static WorkOrderCopyFailedErrorParameter = 'Tilauksen \'{0}\' kopiointi epäonnistui! Virhe: \'{1}\'';
    static WorkOrderCopySuccess = 'Tilauksen \'{0}\' kopiointi onnistui!';
    static EmployeeGroupsVisibleOnDesign = 'Suunnittelunäkymässä näkyvät työntekijäryhmät';
    static VehicleGroupsVisibleOnDesign = 'Suunnittelunäkymässä näkyvät kalustoryhmät';
    static MoveToState = 'Siirry tilaan';
    static CustomerPriceParameterNotFound = 'Asiakaskohtaista hintaa {\'0\'} ei löytynyt!';
    static WorkOrderAttachmentToInvoice = 'Laskun liitteeksi';
    static DayView = 'Päivänäkymä';
    static WeekView = 'Viikkonäkymä';
    static WeekViewNoaccordions = 'Viikkonäkymä ilman päiväerottelua';
    static ShowOrdersAs = 'Näytä tilaukset';
    static VatRateMustBeDefined = 'ALV täytyy määritellä!';
    static EmployeeUsageType = 'Työntekijöiden käyttö';
    static EmployeeUsageTypeMultiple = 'Useita työntekijöitä';
    static EmployeeUsageTypeOne = 'Yksi työntekijä';
    static HoursInvoicingType = 'Työtuntien laskutus';
    static HoursInvoicingTypePlanned = 'Arvioidun työmäärän mukaan';
    static HoursInvoicingTypeRealized = 'Toteutuneiden kirjausten mukaan';
    static LocationUsageType = 'Sijaintitiedon käyttö';
    static AttachmentsAreVisible = 'Liitteet näytetään';
    static CommentIsVisible = 'Kommentti näytetään';
    static DescriptionIsVisible = 'Kuvaus näytetään';
    static EstimatedHoursAreVisible = 'Työmäärä tunteina näytetään';
    static MapIsVisible = 'Kartta näytetään';
    static ToBeInvoicedRecordsAreVisible = 'Laskutettavat rivit näytetään';
    static SiteRoutePointUsageType = 'Reittipisteiden käyttö';
    static SiteRoutePointUsageTypeRoutePoint = 'Reittipisteitä voi lisätä tilaukselle';
    static SiteRoutePointUsageTypeSite = 'Reittipisteet näytetään kohteelta';
    static VehicleUsageType = 'Kaluston käyttö';
    static VehicleUsageTypeMultiple = 'Useita kulkuneuvoja';
    static VehicleUsageTypeOne = 'Yksi kulkuneuvo';
    static RoutePointTypes = 'Reittipistetyypit';
    static WorkOrderAcknowledgementTypeIsVisible = 'Tilauksen hyväksymispyyntö näytetään';
    static WorkOrderTypeParameterNotFound = 'Tilaustyyppiä \'{0}\' ei löytynyt!';
    static RoutePointWorkShiftTimeSlotType = 'Reittipisteen tila';
    static RoutePointWorkShiftTimeSlotTypes = 'Reittipisteiden tilat';
    static WorkOrderTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders = 'Tilaustyyppiä \'{0}\' ei voi poistaa, koska tilaustyyppi on käytössä {1} tilauksessa!';
    static WorkOrderTypeParameterParameterSaveFailedErrorParameter = 'Tilaustyypin \'{0}\' tallennus epäonnistui! Virhe: \'{0}\'';
    static WorkOrderTypeParameterRemoveFailedConcurrencyError = 'Tilaustyypin \'{0}\' poisto epäonnistui, koska tilaustyypin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrderTypeParameterRemoveFailedErrorParameter = 'Tilaustyypin \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
    static WorkOrderTypeParameterRemoveSuccess = 'Tilaustyypin \'{0}\' poisto onnistui!';
    static WorkOrderTypeParameterSaveFailedConcurrencyError = 'Tilaustyypin \'{0}\' tallennus epäonnistui, koska tilaustyypin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesRemoveFailedErrorParameter = 'Tilaustyypin \'{0}\' reittipisteiden tilojen poisto epäonnistui! Virhe: \'{1}\'';
    static WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesRemoveSuccess = 'Tilaustyypin \'{0}\' reittipisteiden tilojen poisto onnistui!';
    static WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesSaveFailedErrorParameter = 'Tilaustyypin \'{0}\' reittipisteiden tilojen tallennus epäonnistui! Virhe: \'{1}\'';
    static WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesSaveSuccess = 'Tilaustyypin \'{0}\' reittipisteiden tilojen tallennus onnistui!';
    static WorkOrderTypeParameterWorkShiftTimeSlotTypeSaveFailedConcurrencyError = 'Tilaustyypin \'{0}\' reittipisteen tilan tallennus epäonnistui, koska reittipisteen tilan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrderTypesParameterRemoveFailedConcurrencyError = 'Tilaustyyppien \'{0}\' poisto epäonnistui, koska tilaustyyppien tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static WorkOrderTypesParameterRemoveFailedErrorParameter = 'Tilaustyyppien \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
    static WorkOrderTypesParameterRemoveSuccess = 'Tilaustyyppien \'{0}\' poisto onnistui!';
    static WorkOrderTypeTypeParameterSaveSuccess = 'Tilaustyypin \'{0}\' tallennus onnistui!';
    static WorkOrderTypeWorkShiftTimeSlotTypeParameterNotFound = 'Reittipisteen tilaa \'{0}\' ei löytynyt!';
    static WorkOrderTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterInvoices = 'Tilaustyyppiä \'{0}\' ei voi poistaa, koska tilaustyyppi on käytössä {1} laskussa!';
    static ShowFilteredWorkOrders = 'Näytä suodatetut tilaukset aikataulussa';
    static NoWorkOrders = 'Ei tilauksia';
    static WorkTimeAccountTransactionSaveSuccess = 'Työaikapankin tapahtuman tallennus onnistui!';
    static WorkTimeAccountTransactionSaveFailed = 'Työaikapankin tapahtuman tallennus epäonnistui! Virhe: \'{0}\'';
    static AddWorkHoursToBank = 'Vie perustyöajan tunteja tuntipankkiin';
    static TakeWorkHoursFromBank = 'Tuo tunteja tuntipankista palkkalaskelmaan';
    static TransferWorkHours = 'Siirrä tunteja';
    static WorkHourBalance = 'Tuntipankin saldo';
    static WorkhourBank = 'Tuntipankin tapahtumat';
    static AddWorkOrdersToInvoice = 'Lisää tilauksia laskulle';
    static CombineWorkOrdersByWorkOrderTypesToSameInvoice = 'Yhdistä tilaukset tilaustyypeittäin samalle laskulle';
    static InvoiceAddWorkOrdersDifferentWorkOrderTypeConfirmation = 'Olet yhdistämässä eri tyyppisiä tilauksia samalle laskulle,  joten varmista, että laskun ALV ja muut tiedot soveltuvat kaikille valituille tilauksille. Haluatko lisätä valitut tilaukset laskulle?';
    static InvoiceDataFromWorkOrderType = 'Laskutustiedot tilaustyypiltä';
    static InvoiceParameterParameterSaveFailedErrorParameter = 'Laskun \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
    static InvoiceParameterSaveFailedConcurrencyError = 'Laskun \'{0}\' tallennus epäonnistui, koska laskun tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static InvoiceParameterSaveSuccess = 'Laskun \'{0}\' tallennus onnistui!';
    static MenuStorage = 'Varastonhallinta';
    static StorageLocations = 'Varastopaikat';
    static StorageReport = 'Raportti';
    static Storages = 'Varastot';
    static Storage = 'Varasto';
    static StorageLocation = 'Varastopaikka';
    static StorageLocationShort = 'Paikka';
    static StorageProduct = 'Varastotuote';
    static StorageProducts = 'Varastotuotteet';
    static NoSelectedItem = 'Ei valintaa';
    static SelectedProduct = 'Valittu tuote';
    static SelectedStorage = 'Valittu varasto';
    static SelectedStorageLocation = 'Valittu varastopaikka';
    static SelectedStorageProduct = 'Valittu varastotuote';
    static StorageProductRemoveConfirmation = 'Haluatko varmasti poistaa valitun varastotuotteen?';
    static StorageProductRemoveSuccess = 'Varastotuote poistettiin onnistuneesti';
    static StorageProductSaveSuccess = 'Varastotuote tallennettiin onnistuneesti';
    static RouteAndSchedule = 'Reitti ja aikataulu';
    static ShowAllCostCenters = 'Näytä kaikki kustannuspaikat';
    static ShowAllVehicles = 'Näytä koko kalusto';
    static Summary = 'Yhteenveto';
    static RouteLength = 'Reitin pituus';
    static RoutePhases = 'Reitin vaiheet';
    static TotalDuration = 'Kokonaisaika';
    static NoVehicle = 'Ei kalustoa';
    static Planned = 'Suunniteltu';
    static Realized = 'Toteutunut';
    static RoutePointBooking = 'Reittikirjaus';
    static WorkhourBankInUse = 'Tuntipankki käytössä';
    static CreateSalaryDataFailedErrorParameter = 'Palkkadatan luonti epäonnistui! Virhe: \'{0}\'';
    static CreateSalaryDataSuccess = 'Palkkadatan luonti onnistui!';
    static EventLogMessageSalaryDataCreated = 'Palkkadata luotu';
    static EventLogMessageSalaryTransferred = 'Palkka siirretty';
    static SalariesTransferredToSalarySystem = 'Palkat on nyt viety palkanmaksujärjestelmään';
    static ExportToSalarySystem = 'Vie palkanmaksujärjestelmään';
    static SetSalariesToTransferredState = 'Merkitse palkat Siirretty-tilaan';
    static TransferToBePaid = 'Siirrä maksettavaksi';
    static IncludeTransferredSalaries = 'Sisällytä jo siirretyt palkat';
    static DataToBeSavedInWorkOrderTemplate = 'Tilauspohjaan tallennettavat tiedot';
    static NewProjectBasedOnWorkOrderTemplate = 'Uusi projekti tilauspohjasta';
    static NewWorkBasedOnWorkOrderTemplate = 'Uusi työ tilauspohjasta';
    static NoWorkOrderTemplates = 'Ei tilauspohjia!';
    static SaveAsWorkOrderTemplate = 'Tallenna tilauspohjaksi';
    static SelectWorkOrderTemplate = 'Valitse tilauspohja';
    static WorkOrderSaveAsWorkOrderTemplateFailedErrorParameter = 'Tilauksen \'{0}\' tallennus tilauspohjaksi epäonnistui! Virhe: \'{1}\'';
    static WorkOrderSaveAsWorkOrderTemplateSuccess = 'Tilauksen \'{0}\' tallennus tilauspohjaksi onnistui!';
    static WorkOrderTemplate = 'Tilauspohja';
    static WorkOrderTemplateRemoveConfirmation = 'Haluatko todella poistaa tilauspohjan \'{0}\'?';
    static WorkOrderTemplateRemoveFailedErrorParameter = 'Tilauspohjan \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
    static WorkOrderTemplateRemoveSuccess = 'Tilauspohjan \'{0}\' poisto onnistui!';
    static WorkOrderTemplatesRemoveFailedErrorParameter = 'Tilauspohjien \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
    static WorkOrderTemplatesRemoveSuccess = 'Tilauspohjien \'{0}\' poisto onnistui!';
    static NoSalaryDataToTransfer = 'Ei siirrettävää palka-aineistoa!';
    static SalaryDatas = 'Palkka-aineistot';
    static WorkQueue = 'Työjono';
    static AssignToMe = 'Ota työn alle';
    static WorkOrderIsShownInWorkQueue = 'Tilaus näytetään avoimessa työjonossa';
    static DoYouWantToReturnWorkOrderToWorkQueue = 'Oletko varma, että haluat palauttaa tilauksen työjonoon?';
    static ReturnWorkOrderToWorkQueue = 'Palauta tilaus työjonoon';
    static UsesWorkQueue = 'Käyttää avointa työjonoa';
    static EmployeeGroupAddFailedErrorParameter = 'Työntekijäryhmän lisäys epäonnistui! Virhe: \'{0}\'';
    static EmployeeGroupAddSuccess = 'Työntekijäryhmän lisäys onnistui!';
    static EmployeeGroupsAddFailedErrorParameter = 'Työntekijäryhmien lisäys epäonnistui! Virhe: \'{0}\'';
    static EmployeeGroupsAddSuccess = 'Työntekijäryhmien lisäys onnistui!';
    static EmployeeGroupsRemoveFailedErrorParameter = 'Työntekijäryhmien poisto epäonnistui! Virhe: \'{0}\'';
    static EmployeeGroupsRemoveSuccess = 'Työntekijäryhmien poisto onnistui!';
    static SecondaryEmployeeGroups = 'Toissijaiset työntekijäryhmät';
    static WorkQueuePostalCodeRangeEnd = 'Avoimen työjonon postinumeroalueen loppu';
    static WorkQueuePostalCodeRangeStart = 'Avoimen työjonon postinumeroalueen alku';
    static AssignToMeDoStartWorkConfirmation = 'Haluatko aloittaa työajan mittauksen tilaukselle?';
    static AssignToMeWorkOrderConfirmation = 'Haluatko ottaa tilauksen työn alle?';
    static CompletionDate = 'Valmistumisaika';
    static CreationDate = 'Luontiaika';
    static WorkOrderAssignToMeFailedErrorParameter = 'Tilauksen ottaminen työn alle epäonnistui! Virhe: \'{0}\'';
    static WorkOrderAssignToMeSuccess = 'Tilauksen ottaminen työn alle onnistui!';
    static WorkOrderUnAssignFromEmployeesFailedErrorParameter = 'Tilauksen palauttaminen työjonoon epäonnistui! Virhe: \'{0}\'';
    static WorkOrderUnAssignFromEmployeesSuccess = 'Tilauksen palauttaminen työjonoon onnistui!';
    static WorkTimeMeasurementStarted = 'Työajan mittaus aloitettu!';
    static PrimaryEmployeeGroup = 'Ensisijainen työntekijäryhmä';
    static StorageLocationCode = 'Tunnus';
    static StorageLocationName = 'Nimi';
    static StorageLocationSaveSuccess = 'Varastopaikka tallennettu';
    static StorageLocationRemoveConfirmation = 'Haluatko varmasti poistaa valitun varastopaikan?';
    static StorageLocationRemoveSuccess = 'Varastopaikka poistettiin onnistuneesti';
    static CreateNewStorageLocation = 'Luo uusi varastopaikka';
    static RemoveSelected = 'Poista valitut';
    static ConcuranceError = 'Tallennus epäonnistui, koska kohteen tietoja on muokattu samaan aikaan toisen käyttäjän toimesta';
    static SaveFailed = 'Tallennus epäonnistui virheen takia, ota yhteyttä asiakaspalveluun sähköpostilla asiakaspalvelu@easyopp.fi tai puhelimitse +358 29 170 1788.';
    static SaveSuccess = 'Tallennus onnistui!';
    static CustomerSiteAddress = 'Työmaan kohde';
    static MultipleStorageLocationRemoveConfirmation = 'Haluatko varmasti poistaa valitut varastopaikat?';
    static MultipleStorageLocationsRemoveSuccess = 'Valittujen varastopaikkojen poistaminen onnistui';
    static NoCustomer = '(Ei asiakasta)';
    static AreYouSureWantDelete = 'Oletko varma, että haluat tehdä poiston?';
    static SaveAndClose = 'Tallenna ja sulje';
    static CreateStorageLocations = 'Luo varastopaikkoja';
    static YouCanAlsoCreateStorageLocations = 'Voit samalla kertaa luoda myös varastolle varastopaikkoja.';
    static CreateNewStorage = 'Luo uusi varasto';
    static CreateNewStorageText = 'Luo uusi varasto antamalla sille nimi ja osoite.';
    static AddProducts = 'Lisää tuotteita';
    static YouCanAlsoAddProducts = 'Voit myös lisätä varastopaikoille tuotteita.';
    static EditStorage = 'Muokkaa varastoa';
    static NameAndAddressMustBeDefined = 'Nimi ja osoite täytyy määritellä';
    static AddStorageProducts = 'Lisää varastotuotteita';
    static AddStorageProductsText = 'Lisää tai muokkaa varastotuotteita valitulla varastopaikalla.';
    static CreateProduct = 'Luo tuote';
    static ProductSearch = 'Tuotehaku';
    static BookedAmount = 'Varatut';
    static FreeAmount = 'Saldo';
    static Sum = 'Yhteensä';
    static ProductMustBeDefined = 'Tuote täytyy määritellä';
    static StorageLocationMustBeDefined = 'Varastopaikka täytyy määritellä';
    static Code1 = 'Koodi';
    static OwnerLogo = 'Yrityksen logo';
    static OwnerMobileBackgroundImage = 'Mobiilisovelluksen taustakuva';
    static ShowDefaultLogo = 'Näytä EasyOpp-logo yrityksen logon sijaan';
    static AvailableForBooking = 'Varattavissa';
    static ErrorAmountNotEnough = 'Tuotetta ei ole saatavilla tarpeeksi';
    static AddToOrder = 'Lisää tilaukselle';
    static CreateNewOrder = 'Luo uusi tilaus';
    static ShoppingCart = 'Tilauskori';
    static SelectExistingWorkOrder = 'Valitse olemassa oleva tilaus';
    static ShoppingCartProductListTitle = 'Tilaukselle liitettävät tuotteet';
    static CanNotDeleteStorage = 'Poistaminen ei ole mahdollista, koska varastolla on varastopaikka.';
    static DeleteWasSuccess = 'Poisto onnistui.';
    static LocationDataSavingInterval = 'Sijaintitiedon tallennusväli';
    static LocationDataSavingInUse = 'Sijaintitiedon tallennus käytössä';
    static LocationPermissionMustBeAllowedInTheDeviceForThisWebSiteParameterBeforeWorkOrderCanBeMovedToInProgressState = 'Sijaintitietojen käyttö täytyy sallia selaimen asetuksissa tälle web-osoitteelle (\'{0}\') ennen kuin tilaus voidaan siirtää \'Työn alla\'-tilaan! Poista myös käytöstä akun optimointi selainsovelluksille järjestelmän asetuksista, jotta sijaintitietojen tallentaminen toimii luotettavasti.';
    static WorkOrdersParameterLocationSaveFailedErrorParameter = 'Tilausten \'{0}\' sijainnin tallennus epäonnistui! Virhe: \'{1}\'';
    static WorkOrdersParameterLocationSaveSuccess = 'Tilausten \'{0}\' sijainnin tallennus onnistui!';
    static LocationPermissionMustBeAllowedInTheDeviceForThisWebSiteParameter = 'Sijaintitietojen käyttö täytyy sallia selaimen asetuksissa tälle web-osoitteelle (\'{0}\')! Poista myös käytöstä akun optimointi selainsovelluksille järjestelmän asetuksista, jotta sijaintitietojen tallentaminen toimii luotettavasti.';
    static DeliveryPoint = 'Toimituspiste';
    static ProductName = 'Tuotteen nimi';
    static NoRows = 'Ei rivejä';
    static BookingContainsNotValidLines = 'Tallennuksessa huomattiin että joku oli varannut määriä samaan aikaan, joten määrät eivät pidä paikkansa. Korjaa virheelliset rivit, jotka on merkitty virheellisiksi.';
    static SalaryPeriodTypeRemoveConfirmation = 'Haluatko todella poistaa palkkajaksotyypin \'{0}\'?';
    static SalaryPeriodTypes = 'Palkkajaksotyypit';
    static InvalidEndDate = 'Virheellinen päättymispäivä!';
    static SalaryPeriodType = 'Palkkajaksotyyppi';
    static SalaryPeriodTypeParameterNotFound = 'Palkkajaksotyyppiä \'{0}\' ei löytynyt!';
    static SalaryPeriodTypeSaveFailedConcurrencyError = 'Palkkajaksotyypin \'{0}\' tallennus epäonnistui, koska palkkajaksotyypin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static SalaryPeriodTypeSaveFailedErrorParameter = 'Palkkajaksotyypin \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
    static SalaryPeriodTypeSaveSuccess = 'Palkkajaksotyypin \'{0}\' tallennus onnistui!';
    static AddSalaryPeriod = 'Lisää palkkajakso';
    static AddSalaryPeriods = 'Lisää palkkajaksoja';
    static ParameterMonths = '{0} kuukautta';
    static ParameterWeeks = '{0} viikkoa';
    static PeriodLength = 'Jakson pituus';
    static SalaryPeriodParameterCannotBeEditedBecauseItIsUsedInParameterSalaries = 'Palkkajaksoa \'{0}\' ei voi muokata, koska palkkajakso on käytössä {1} palkassa!';
    static SalaryPeriodParameterCannotBeRemovedBecauseItIsUsedInParameterSalaries = 'Palkkajaksoa \'{0}\' ei voi poistaa, koska palkkajakso on käytössä {1} palkassa!';
    static SalaryPeriodTypeParameterCannotBeRemovedBecauseItIsUsedInParameterSalaries = 'Palkkajaksotyyppiä \'{0}\' ei voi poistaa, koska palkkajaksotyyppi on käytössä {1} palkassa!';
    static SalaryPeriodTypeParameterRemoveFailedErrorParameter = 'Palkkajaksotyypin \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
    static SalaryPeriodTypeParameterRemoveSuccess = 'Palkkajaksotyypin \'{0}\' poisto onnistui!';
    static SalaryPeriodTypeParameterSalaryPeriodsRemoveFailedErrorParameter = 'Palkkajaksotyypin \'{0}\' palkkajaksojen poisto epäonnistui! Virhe: \'{1}\'';
    static SalaryPeriodTypeParameterSalaryPeriodsRemoveSuccess = 'Palkkajaksotyypin \'{0}\' palkkajaksojen poisto onnistui!';
    static SalaryPeriodTypeParameterSalaryPeriodsSaveFailedConcurrencyError = 'Palkkajaksotyypin \'{0}\' palkkajakson tallennus epäonnistui, koska palkkajaksontilan tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static SalaryPeriodTypeParameterSalaryPeriodsSaveFailedErrorParameter = 'Palkkajaksotyypin \'{0}\' palkkajaksojen tallennus epäonnistui! Virhe: \'{1}\'';
    static SalaryPeriodTypeParameterSalaryPeriodsSaveSuccess = 'Palkkajaksotyypin \'{0}\' palkkajaksojen tallennus onnistui!';
    static EmployeeGroupsForSalaryCalculationHaveNotBeenSpecified = 'Palkanlaskennan työntekijäryhmiä ei ole määritelty!';
    static EmployeeGroupForDesign = 'Suunnittelun työntekijäryhmä';
    static EmployeeGroupForSalaryCalculation = 'Palkanlaskennan työntekijäryhmä';
    static EmployeeGroupSalaryPeriodTypeCannotBeRemovedError = 'Palkkajaksotyyppiä ei voi poistaa työntekijäryhmästä, koska työntekijäryhmä on määritelty palkanlaskennan työntekijäryhmäksi {0} työntekijälle!';
    static SalaryCalculation = 'Palkanlaskenta';
    static WorkOrderCanBeCreatedInMobileApp = 'Tilaus voidaan luoda mobiilisovelluksessa';
    static Extranet = 'Extranet';
    static CommonType = 'Yleinen';
    static StorageInvoice = 'Varaston laskutus';
    static ErrorAmountNotEnoughWithName = 'Tuotetta \'{0}\' ei ole saatavilla tarpeeksi! Saldo varastossa: {1}.';
    static StorageProductParameterNotFound = 'Varastotuotetta \'{0}\' ei löytynyt!';
    static ProductsAddedToWorkOrder = 'Tuotteet lisättiin tilaukselle.';
    static ProductsWillBeAddedAfterSave = 'Valitut tuotteet lisätään tilaukselle tallennuksen jälkeen.';
    static AddingProductsToWorkOrder = 'Tuotteita lisätään tilaukselle...';
    static CheckAmounts = 'Tarkista määrät.';
    static ErrorState = 'Virhe';
    static DoYouWantSaveChanges = 'Haluatko tallentaa muutokset?';
    static ToShoppingCart = 'Tuotekoriin';
    static RoutePointHasProduct = 'Reittipisteelle on lisätty tuotteita. Poista tuotteet ensin.  Nimi: {0}.';
    static WorkOrderSortOrderError = 'Projektin tehtävien järjestyksen päivittäminen epäonnistui';
    static WorkOrderSortOrderUpdated = 'Projektin tehtävien järjestys päivitetty';
    static WorkOrderCreationAllowedInMobileApp = 'Tilauksen luominen sallittu mobiilisovelluksessa';
    static WorkOrderSequentialTaskExecution = 'Määrätty suoritusjärjestys';
    static WorkOrderSequentialTaskExecutionNon = 'Joustava suoritusjärjestys';
    static DailyDeductionHours = 'Päiväkohtaiset vähennykset';
    static PeriodDeductionHours = 'Jaksokohtaiset vähennykset';
    static SuitableWorkOrderTypesForNewWorkOrderNotFound = 'Uudelle tilaukselle sopivia tilaustyyppejä ei löytynyt!';
    static ProductStorageLocationError = 'Tuote on liitetty varastopaikkoihin.';
    static ProductBookingError = 'Tuote on liitetty tilauksiin.';
    static ProductStorageError = 'Tuote on liitetty varastotuotteisiin.';
    static StorageProductRemoveWarning = 'Oletko varma että haluat poistaa tämän tuotteen? Tuotetta ei poisteta olemassa olevilta tilauksilta.';
    static EventLogMessageSiteCreated = 'Kohde luotu';
    static ERPCodeMissing = 'Lähetys epäonnistui. Työntekijältä puuttuu ERP-viitekoodi.';
    static PlannedRoute = 'Suuniteltu reitti';
    static CollectiveAgreement = 'Työehtosopimus';
    static ProductsToDeliver = 'Toimitettavat tuotteet';
    static ProductsToFetch = 'Noudettavat tuotteet';
    static CancelSetLocationFromMap = 'Peruuta sijainnin asettaminen';
    static SendGridAddressIsMissing = 'SendGridin lähetysosoite puuttuu';
    static SendGridApiKeyIsMissing = 'SendGridin API avain puuttuu';
    static SendGridSendingMailFailed = 'Sähköpostin lähetys epäonnistui';
    static HoursInvoicingProduct = 'Työtuntien laskutustuote';
    static HoursInvoicingTypeNoNotInUse = 'Ei laskutusta';
    static KmsInvoicingProduct = 'Kilometrien laskutustuote';
    static KmsInvoicingType = 'Kilometrien laskutus';
    static KmsInvoicingTypeNotInUse = 'Ei laskutusta';
    static KmsInvoicingTypePlanned = 'Suunnitellun reitin arvioidun matkan mukaan';
    static KmsInvoicingTypeRealized = 'Toteutuneen matkan mukaan';
    static NotSelected = 'Ei valittu';
    static RoutePointUsage = 'Reittipisteiden käyttö';
    static UserInterfaceSettings = 'Käyttöliittymäasetukset';
    static Bookings = 'Kirjaukset';
    static HideMap = 'Piilota kartta';
    static ShowMap = 'Näytä kartta';
    static UseCollectionListInMobile = 'Käytä keräilylistaa mobiilissa';
    static MarkProductAsCollectedByCheckingRow = 'Merkitse tuote kerätyksi rastittamalla rivi';
    static StorageProductBookingAmountChangeFailed = 'Tuotteen määrän muuttaminen epäonnistui! Avaa tilaus uudelleen ja kokeile muutosta uudelleen.';
    static StorageProductBookingRemoveConfirmation = 'Haluatko poistaa tuotteen \'{0}\'?';
    static StorageProductBookings = 'Tilaukseen kuuluvat tuotteet';
    static Interval = 'Aikaväli';
    static SelectSalaryType = 'Valitse palkkalajit';
    static ShowCostCenters = 'Näytä kustannuspaikat';
    static ShowCustomers = 'Näytä asiakkaat';
    static ShowOrders = 'Näytä tilaukset';
    static InvoiceReport = 'Laskutusraportti';
    static SalaryReport = 'Palkkaraportti';
    static WorkTimeReport = 'Työaikaraportti';
    static StartingTimeMustOccurBeforeEndingTime = 'Aloitusajan täytyy olla ennen lopetusaikaa!';
    static TaskTypeMustBeSelected = 'Tehtävätyyppi täytyy valita!';
    static SalaryPeriodMissing = 'Palkkajaksot puuttuvat! Ne pitää lisätä ensin.';
    static HourBookingTypesMissing = 'Tuntikirjaustyypit puuttuvat.';
    static InvalidTabIndex = 'Välilehden indeksi ei täsmää';
    static CloseWindow = 'sulje ikkuna';
    static ChooseCustomer = 'Valitse asiakas';
    static ChooseStorage = 'Valitse varasto';
    static SearchConditions = 'Hakuehdot';
    static NoOptions = 'Ei vaihtoehtoja';
    static FieldIsRequired = '\'{0}\' on pakollinen kenttä.';
    static FieldMaxLengthError = 'Kentän \'{0}\' maksimipituus on {1} merkkiä.';
    static FieldMinLengthError = 'Kentän \'{0}\' minimipituus on {1} merkkiä.';
    static StorageNotFound = 'Varastoa ei löytynyt.';
    static ShowOwnWorkOrders = 'Näytä omat tilaukset';
    static RemoveProductFromCart = 'Poista tuote korista';
    static CloseCart = 'Sulje tilauskori';
    static EditStorageLocation = 'Muokkaa varastopaikkaa';
    static SalaryRowTypeKind = 'Palkkalajin tyyppi';
    static SalaryRowTypeParameterNotFound = 'Palkkalajia \'{0}\' ei löytynyt!';
    static SalaryRowTypeParameterParameterSaveFailedErrorParameter = 'Palkkalajin \'{0}\' tallennus epäonnistui! Virhe: \'{1}\'';
    static SalaryRowTypeParameterSaveFailedConcurrencyError = 'Palkkalajin tallennus epäonnistui, koska palkkalajin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static SalaryRowTypeParameterSaveSuccess = 'Palkkalajin \'{0}\' tallennus onnistui!';
    static SalaryRowTypeRemoveConfirmation = 'Haluatko todella poistaa palkkarivityypin?';
    static SalaryRowTypeParameterCannotBeRemovedBecauseItIsUsedInParameterDayBookingType = 'Palkkalajia \'{0}\' ei voi poistaa, koska palkkalaji on käytössä \'{1}\' päiväkirjaustyypillä!';
    static SalaryRowTypeParameterRemoveFailedConcurrencyError = 'Palkkalajien \'{0}\' poisto epäonnistui, koska palkkalajin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static SalaryRowTypeParameterRemoveFailedErrorParameter = 'Palkkalajin \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
    static SalaryRowTypesParameterRemoveFailedConcurrencyError = 'Palkkalajien \'{0}\' poisto epäonnistui, koska palkkalajin tietoja on muokattu samaan aikaan toisen käyttäjän toimesta!';
    static SalaryRowTypesParameterRemoveFailedErrorParameter = 'Palkkalajien \'{0}\' poisto epäonnistui! Virhe: \'{1}\'';
    static SalaryRowTypesParameterRemoveSuccess = 'Palkkalajin \'{0}\' poisto onnistui!';
    static SalaryRowTypeParameterCannotBeRemovedBecauseItIsInUsed = 'Palkkalajia \'{0}\' ei voi poistaa, koska palkkalaji on käytössä!';
    static SalaryRowTypeParameterCannotBeRemovedBecauseItIsUsedInParameterHourBookingType = 'Palkkalajia \'{0}\' ei voi poistaa, koska palkkalaji on käytössä \'{1}\' tuntikirjaustyypillä!';
    static BillingProduct = 'Laskutustuote';
    static AddProduct = 'Lisää tuote';
    static InvalidAmountError = 'Määrän täytyy olla vähintään 0.';
    static InvalidValue = 'Arvo ei kelpaa.';
    static StorageLocationNotFound = 'Varastopaikkaa ei löytynyt.';
    static CannotDeleteStorageLocationWithProducts = 'Poistaminen ei ole mahdollista, koska varastopaikalla on tuotteita.';
    static ScrollLabelRowsPerPage = 'Rivejä per sivu';
    static ShowDetails = 'Näytä tiedot';
    static FetchFailed = 'Haku epäonnistui';
    static ProductNumber = 'Tuotenumero';
    static ProductDescription = 'Tuotekuvaus';
    static CreateNewProduct = 'Luo uusi tuote';
    static EditProduct = 'Muokkaa tuotetta';
    static CreateNewStorageProduct = 'Luo uusi tuote';
    static ProductAddedToCart = 'Tuote lisätty tilauskoriin';
    static ProductNotFound = 'Tuotetta ei löytynyt';
    static InvalidPriceError = 'Hinnan täytyy olla vähintään 0.';
    static NoCustomerPrices = 'Ei asiakaskohtaisia hintoja';
    static CustomerPriceAlreadyExists = 'Valitulle asiakkaalle on jo määritetty hinta.';
    static EnterPositiveNumber = 'Anna positiivinen luku.';
    static NewStorageLocationRow = 'Lisää varastoon';
    static AreYouSureWantDeleteStorageProduct = 'Haluatko poistaa tuotteen varastosta?';
    static OpenBetaVersion = 'Beta-versio';
    static ApproveAll = 'Hyväksy kaikki';
    static Approved = 'Hyväksytty';
    static ShowOnlyNotApproved = 'Näytä vain hyväksymättömät';
    static LockAll = 'Lukitse kaikki';
    static UnlockAll = 'Poista lukitus kaikista';
    static WorkTimeOriginIntegration = 'Työaika tullut piirturilta integraation kautta';
    static ApproveAndSave = 'Hyväksy ja tallenna';
    static WorkHoursApproveWarning = 'Haluatko varmasti hyväksyä ja tallentaa tunnit? Kaikki nykyiset tunnit korvataan lasketuilla tunneilla.';
    static WorkTimeDifferentFromOriginal = 'Työaika muuttunut alkuperäisestä';
    static WorkTimeApproval = 'Työtuntien hyväksyntä';
    static WorkTimeDetailsHeaderLabel = 'Työaikakirjaukset';
    static WorkTimeType = 'Työvaihe';
    static NoSalaryRowType = 'Ei palkkalajia';
    static AbrWeek = 'vk';
    static WorkHoursLockRowsWarning = 'Haluatko varmasti lukita kirjaukset?';
    static EmployeeNotFound = 'Työntekijää ei löytynyt!';
    static RoundAllWorkShiftStartAndEnds = 'Pyöristä työvuorot';
    static RoundUnlockedWorkShiftsApproveWarning = 'Lukitsemattomat työvuorot pyöristetään, haluatko jatkaa?';
    static PrintStarted = 'Tulosteen muodostus aloitettiin ja lataus käynnistyy pian';
    static WorkShiftTimeSlotStateInProgressCannotBeChanged = 'Käynnissä olevaa työaikakirjausta ei voi muokata!';
    static WorkShiftTimeSlotEndTimeBeforeStartTime = 'Lopetus ei voi olla ennen aloitusta!';
    static SalaryRowTypeKindIdMustBeDefined = 'Palkkalajin tyyppi täytyy määritellä';
    static MeasureUnitMustBeDefined = 'Mittayksikkö täytyy määritellä';
    static Vacations = 'Lomat';
    static Absences = 'Vähennykset';
    static AreYouSureYouWantToRemoveTransportPlan = 'Haluatko varmasti poistaa kuljetussuunnitelman?';
    static NewPlan = 'Uusi suunnitelma';
    static NewTransportPlan = 'Uusi kuljetussuunnitelma';
    static NoEmployee = 'Ei kuljettajaa';
    static NonOrders = 'Ei tilauksia';
    static RemovePlan = 'Poista suunnitelma';
    static ShowCalendarFrom = 'Näytä kalenteri alkaen';
    static TransportOrders = 'tilausta';
    static TimeZone = 'Aikavyöhyke';
    static WorkTimeRoundingInfoInProgressWorkShifts = 'Käynnissä olevia työvuoroja ei pyöristetty.';
    static TransportOrdersInvoices = 'Kuljetustilaukset';
    static SelectedOrders = 'Valitut tilaukset';
    static CreateNewInvoice = 'Luo uusi lasku';
    static SelectAllDelivered = 'Valitse kaikki toimitetut';
    static TransferToInvoice = 'Siirrä laskulle';
    static TransferToInvoicing = 'Siirrä laskutukseen';
    static Definition = 'Selite';
    static OrderNumber = 'Tilausnumero';
    static NotDelivered = 'Ei toimitettu';
    static ShowOnlyDelivered = 'Näytä vain toimitetut';
    static Default = 'Oletus';
    static WorkTimeTypes = 'Työvaiheet';
    static Selected = 'Valittu';
    static WorkTimeTypeParameterNotFound = 'Työvaihetta \'{0}\' ei löytynyt!';
    static WorkTimeTypeRemoveConfirmation = 'Haluatko todella poistaa työvaiheen \'{0}\'?';
    static WorkTimeTypeRemoveFailedErrorParameter = 'Työvaiheen \'{0}\' poistaminen epäonnistui! Virhe: \'{1}\'';
    static WorkTimeTypeRemoveSuccess = 'Työvaiheen \'{0}\' poistaminen onnistui!';
    static WorkTimeTypesAlreadyInitialized = 'Työvaiheet on jo alustettu';
    static InitializeWorkTimeTypes = 'Alusta työvaiheet';
    static InitializeWorkTimeTypesConfirmation = 'Vahvista työvaiheiden alustus';
    static InitializeWorkTimeTypesSuccess = 'Työvaiheet alustettu onnistuneesti';
    static InitializeWorkTimeTypesFailedErrorParameter = 'Työvaiheiden alustus epäonnistui, virhe: \'{0}\'';
    static ApiKeyLabel = 'Api key';
    static AddTransportToInvoice = 'Lisää uusi tilaus laskulle';
    static ApproveInvoice = 'Hyväksy lasku';
    static InvoiceInformation = 'Laskun perustiedot';
    static InvoiceInformationSaveFailed = 'Laskun perustietojen tallentaminen ei onnistunut.';
    static InvoiceInformationSaveSuccess = 'Laskun perustiedot tallennettu onnistuneesti.';
    static InvoiceNumber = 'Laskun numero';
    static NoInvoiceRows = 'Ei laskurivejä';
    static ReferenceInformation = 'Maksajan viitetieto';
    static RemoveOrder = 'Poista tilaus';
    static SaveInvoiceInformation = 'Tallenna perustiedot';
    static TaxFree = 'Veroton';
    static TaxInclusive = 'Verollinen';
    static TaxInclusiveTotalEUR = 'Verollinen yhteensä EUR';
    static Transports = 'Kuljetukset';
    static Unit = 'Yksikkö';
    static UnitPriceEUR = 'À-hinta EUR';
    static ShowOnlyInvoiceable = 'Näytä vain laskutettavissa olevat';
    static OnTheInvoice = 'Laskulla';
    static NoSelectedOrders = 'Ei valittuja tilauksia';
    static RemoveInvoice = 'Poista lasku';
    static FailedToCreateInvoice = 'Laskun luonti epäonnistui!';
    static DisplaySentInvoices = 'Näytä lähetetyt laskut';
    static InvoiceApproved = 'Lasku hyväksytty';
    static InvoiceNotApproved = 'Laskua ei hyväksytty';
    static InvoiceSent = 'Lasku lähetetty';
    static PayersName = 'Maksajan nimi';
    static SentDate = 'Lähetyspäivämäärä';
    static TaxExclusiveTotal = 'Veroton summa';
    static TotalInvoiceAmount = 'Laskun kokonaissumma';
    static TotalTax = 'Veron määrä';
    static TransportOrderInvoices = 'Kuljetustilausten laskut';
    static SearchByInvoiceNumber = 'Hae laskunumerolla';
    static SendInvoice = 'Lähetä lasku';
    static InvoiceDetails = 'Laskun tiedot';
    static CreateInvoiceSuccess = 'Lasku luotiin onnistuneesti!';
    static AddSelected = 'Lisää valitut';
    static CreateInvoicesFailed = 'Laskun luonti epäonnistui!';
    static AddTransportOrdersToInvoiceFailed = 'Tilausten lisäys laskulle epäonnistui!';
    static AddTransportOrdersToInvoiceSuccess = 'Tilausten lisäys laskulle onnistui!';
    static CategoryNormal = 'Normaali tuntityö';
    static CategoryPeriodOvertime50 = 'Jaksoylityö 50%';
    static CategoryPeriodOvertime100 = 'Jaksoylityö 100%';
    static CategoryWeekOvertime50 = 'Viikkoylityö 50%';
    static CategoryWeekOvertime100 = 'Viikkoylityö 100%';
    static CategoryEvening = 'Iltalisä';
    static CategoryNight = 'Yölisä';
    static CategorySaturday = 'Lauantailisä';
    static CategorySundayOrHoliday = 'Sunnuntailisä';
    static CategoryAlternativeSunday = 'Vaihtoehtoinen sunnuntailisä';
    static CategoryPaidBreak = 'Palkallinen tauko';
    static CategoryCustomNormalType = 'Valittava normaali tuntityö';
    static CategoryMidWeekHoliday = 'Arkipyhä';
    static CategoryMidWeekHoliday200 = 'Arkipyhä 200%';
    static CategorySalaryInSickTime = 'Sairausajan palkka';
    static CategoryWorkTimeCut = 'Työajan leikkaus';
    static CategoryHalfDailyAllowance = 'Osapäiväraha';
    static CategoryDailyAllowance = 'Päiväraha';
    static CategoryDailyOvertime50 = 'Vuorokausiylityö 50%';
    static CategoryDailyOvertime100 = 'Vuorokausiylityö 100%';
    static CategoryBreak = 'Palkaton tauko';
    static CategoryMealBreak = 'Ruokatauko';
    static InvoiceSendFailed = 'Laskun lähetys epäonnistui';
    static ShowAllTransportOrders = 'Näytä kaikki kuljetustilaukset';
    static EditInvoice = 'Muokkaa laskua';
    static CategorySecondaryEvening = 'Vaihtoehtoinen iltalisä';
    static CategorySecondaryNight = 'Vaihtoehtoinen yölisä';
    static RevokeApproval = 'Kumoa hyväksyntä';
    static Delivered = 'Toimitettu';
    static DeliveryDetails = 'Toimituspisteen lisätiedot';
    static PickupDetails = 'Noutopisteen lisätiedot';
    static WaybillNumber = 'Rahtikirjan numero';
    static TotalPrice = 'Kokonaishinta';
    static BillingCustomer = 'Laskutusasiakas';
    static AreYouSureWantRevokeApproval = 'Oletko varma, että haluat kumota hyväksynnän?';
    static InvoicingBetaTitle = 'Laskutus Beta';
    static WorkTimeTypeBreak = 'Tauko';
    static WorkTimeTypeDriving = 'Ajossa';
    static WorkTimeTypeLoading = 'Lastaus';
    static WorkTimeTypeOther = 'Muu työ';
    static WorkTimeTypeUnLoading = 'Purku';
    static WorkTimeTypeWaiting = 'Odotus';
    static DeliveryTime = 'Toimitusajankohta';
    static NotSent = 'Ei lähetetty';
    static Price = 'Hinta';
    static Sent = 'Lähetetty';
    static WaybillNo = 'Rahtikirjan nro';
    static TransportOrderInvoiceFetchFail = 'Laskun haku epäonnistui';
    static TransportOrderInvoicesFetchFail = 'Laskujen haku epäonnistui';
    static TransportOrdersFetchFail = 'Kuljetustilausten haku epäonnistui';
    static RemoveFromPlan = 'Poista suunnitelmalta';
    static Invoiced = 'Laskutettu';
    static CategoryPaidWaiting = 'Palkallinen odotus';
    static CategoryUnPaidWaiting = 'Palkaton odotus';
    static CategoryOvertimeBase = 'Ylityön perusosa';
    static CategoryMonthlyOvertime50 = 'Kuukausiylityö 50%';
    static CategoryGrandHoliday = 'Suurjuhlapyhä';
    static EditRecurringTransportPlan = 'Muokkaa kuljetussuunnitelman toistuvuutta';
    static CannotAddOrderToCompletedPlan = 'Kuljetustilausta ei voi lisätä valmiille kuljetussuunnitelmalle.';
    static ThreeMonthTransportPlanQuery = 'Haku palauttaa suunnitelmat valitusta alkuajasta kolme kuukautta eteenpäin.';
    static TransportPlansFetchFail = 'Kuljetussuunnitelmien haku epäonnistui.';
    static AddBreak = 'Lisää tauko';
    static breakRangeError = 'Tauko voi olla 1-60 min';
    static ChangeInInvoicingStateWasSuccessful = 'Laskutustilan muutos onnistui.';
    static Invoiceable = 'Laskutettavissa';
    static InvoicingStateChangeFailed = 'Laskutustilan muutos epäonnistui.';
    static MarkAsInvoiced = 'Merkitse laskutetuksi';
    static ReturnToInvoiceable = 'Palauta laskutettavaksi';
    static TransportOrderInvoicingStateConfirmation = 'Haluatko varmasti muuttaa kuljetustilauksen tilaksi: \'{0}\'?';
    static FixedPriceProductRemoveInformation = 'Kiinteähintaista tuotetta \'{0}\' ei voi poistaa';
    static OverlappingWorkTimeEntries = 'Työaikakirjauksen tallennus ei onnistunut. Päällekkäiset työaikakirjaukset eivät ole sallittuja.';
    static WorkShiftInProgressCannotBeSaved = 'Työaikakirjausta ei voi tallentaa käynnissä olevan kirjauksen päälle, jos se päättyy tulevaisuuteen';
    static DateNotInSalaryPeriod = 'Päivämäärä ei voi olla palkkajakson ulkopuolella.';
    static OverwritingWorkTimeRangeError = 'Työaikakirjauksen sallittu kesto on yhdestä minuutista 24 tuntiin. Yli 24 tunnin merkintöjä ei voi tallentaa ylikirjoittamalla.';
    static CategoryCustomAnyType = 'Valittava muu kirjaus';
    static CategoryOverTimeCalculation = 'Ylityötä kerryttävä työ';
    static CategoryPaidMealBreak = 'Palkallinen ruokatauko';
    static CategoryCustomAddition = 'Valittava lisä';
    static DayBookingLockSuccess = 'Päiväkirjauksien lukitus onnistui!';
    static DayBookingUnlockSuccess = 'Päiväkirjauksien lukituksen poisto onnistui!';
    static DayBookingLockFail = 'Päiväkirjauksien lukitus epäonnistui! Virhe: \'{0}\'';
    static DayBookingUnlockFail = 'Päiväkirjauksien lukituksen poisto epäonnistui! Virhe: \'{0}\'';
    static DayBookingLocked = 'Päiväkirjaus on lukittu';
}
export class Translations {
	static ApplicationName: string = Translations_fi.ApplicationName;
	static ArgumentParameterIsNULL: string = Translations_fi.ArgumentParameterIsNULL;
	static Cancel: string = Translations_fi.Cancel;
	static ClassParameterHasNotBeenInitialized: string = Translations_fi.ClassParameterHasNotBeenInitialized;
	static EmployeeParameterCodeIsAlreadyInUse: string = Translations_fi.EmployeeParameterCodeIsAlreadyInUse;
	static EmployeeParameterParameterSavedSuccessfully: string = Translations_fi.EmployeeParameterParameterSavedSuccessfully;
	static EmployeeParameterParameterSaveFailedConcurrencyError: string = Translations_fi.EmployeeParameterParameterSaveFailedConcurrencyError;
	static EmployeeParameterParameterSaveFailedErrorParameter: string = Translations_fi.EmployeeParameterParameterSaveFailedErrorParameter;
	static Maintenance: string = Translations_fi.Maintenance;
	static No: string = Translations_fi.No;
	static Password: string = Translations_fi.Password;
	static PermissionDeniedForOperation: string = Translations_fi.PermissionDeniedForOperation;
	static PlannedWorks: string = Translations_fi.PlannedWorks;
	static Planning: string = Translations_fi.Planning;
	static Reporting: string = Translations_fi.Reporting;
	static SignIn: string = Translations_fi.SignIn;
	static SignOut: string = Translations_fi.SignOut;
	static ToBeCheckedWorks: string = Translations_fi.ToBeCheckedWorks;
	static ToBeInvoicedWorks: string = Translations_fi.ToBeInvoicedWorks;
	static UserClaimParameterNotFound: string = Translations_fi.UserClaimParameterNotFound;
	static UserClaimsNotFound: string = Translations_fi.UserClaimsNotFound;
	static Username: string = Translations_fi.Username;
	static WaitingWorks: string = Translations_fi.WaitingWorks;
	static Yes: string = Translations_fi.Yes;
	static Employee: string = Translations_fi.Employee;
	static InvalidUsernameOrPassword: string = Translations_fi.InvalidUsernameOrPassword;
	static PasswordIsTooShort: string = Translations_fi.PasswordIsTooShort;
	static PasswordMustContainDigitOrSpecialCharacter: string = Translations_fi.PasswordMustContainDigitOrSpecialCharacter;
	static PasswordMustContainLowercaseChar: string = Translations_fi.PasswordMustContainLowercaseChar;
	static PasswordMustContainUppercaseChar: string = Translations_fi.PasswordMustContainUppercaseChar;
	static TheSystemDoesNotSupportYourWebBrowserUpdateToLatestBrowserVersion: string = Translations_fi.TheSystemDoesNotSupportYourWebBrowserUpdateToLatestBrowserVersion;
	static Week: string = Translations_fi.Week;
	static WorkPlan: string = Translations_fi.WorkPlan;
	static Actions: string = Translations_fi.Actions;
	static Copy: string = Translations_fi.Copy;
	static Edit: string = Translations_fi.Edit;
	static FindWeek: string = Translations_fi.FindWeek;
	static New: string = Translations_fi.New;
	static NextWeek: string = Translations_fi.NextWeek;
	static PreviousWeek: string = Translations_fi.PreviousWeek;
	static Remove: string = Translations_fi.Remove;
	static Clear: string = Translations_fi.Clear;
	static Close: string = Translations_fi.Close;
	static Contact: string = Translations_fi.Contact;
	static Duration: string = Translations_fi.Duration;
	static MeasureUnitKg: string = Translations_fi.MeasureUnitKg;
	static MeasureUnitL: string = Translations_fi.MeasureUnitL;
	static MeasureUnitM: string = Translations_fi.MeasureUnitM;
	static MeasureUnitPcs: string = Translations_fi.MeasureUnitPcs;
	static OK: string = Translations_fi.OK;
	static Site: string = Translations_fi.Site;
	static StartDate: string = Translations_fi.StartDate;
	static State: string = Translations_fi.State;
	static WorkDescription: string = Translations_fi.WorkDescription;
	static WorkOrder: string = Translations_fi.WorkOrder;
	static WorkOrderBookingsTab: string = Translations_fi.WorkOrderBookingsTab;
	static WorkOrderDocumentsTab: string = Translations_fi.WorkOrderDocumentsTab;
	static WorkOrderGeneralTab: string = Translations_fi.WorkOrderGeneralTab;
	static WorkOrderParameterNotFound: string = Translations_fi.WorkOrderParameterNotFound;
	static WorkOrderStateChecked: string = Translations_fi.WorkOrderStateChecked;
	static WorkOrderStateDone: string = Translations_fi.WorkOrderStateDone;
	static WorkOrderStateInProgress: string = Translations_fi.WorkOrderStateInProgress;
	static WorkOrderStatePlanned: string = Translations_fi.WorkOrderStatePlanned;
	static WorkOrderStatePreliminary: string = Translations_fi.WorkOrderStatePreliminary;
	static WorkOrderStateTransferred: string = Translations_fi.WorkOrderStateTransferred;
	static WorkOrderType: string = Translations_fi.WorkOrderType;
	static Add: string = Translations_fi.Add;
	static AddPhoto: string = Translations_fi.AddPhoto;
	static Amount: string = Translations_fi.Amount;
	static AmountH: string = Translations_fi.AmountH;
	static AmountKm: string = Translations_fi.AmountKm;
	static Attachments: string = Translations_fi.Attachments;
	static Change: string = Translations_fi.Change;
	static Comment: string = Translations_fi.Comment;
	static Cost: string = Translations_fi.Cost;
	static Customer: string = Translations_fi.Customer;
	static Date: string = Translations_fi.Date;
	static DocumentIsEmpty: string = Translations_fi.DocumentIsEmpty;
	static DocumentParameterNotFound: string = Translations_fi.DocumentParameterNotFound;
	static Download: string = Translations_fi.Download;
	static EndDate: string = Translations_fi.EndDate;
	static HourBooking: string = Translations_fi.HourBooking;
	static HourBookings: string = Translations_fi.HourBookings;
	static ProductBooking: string = Translations_fi.ProductBooking;
	static ProductBookings: string = Translations_fi.ProductBookings;
	static Product: string = Translations_fi.Product;
	static MeasureUnitH: string = Translations_fi.MeasureUnitH;
	static MeasureUnitKm: string = Translations_fi.MeasureUnitKm;
	static RideBooking: string = Translations_fi.RideBooking;
	static RideBookings: string = Translations_fi.RideBookings;
	static Save: string = Translations_fi.Save;
	static SelectCustomer: string = Translations_fi.SelectCustomer;
	static UnitPrice: string = Translations_fi.UnitPrice;
	static Unnamed: string = Translations_fi.Unnamed;
	static Vehicle: string = Translations_fi.Vehicle;
	static DecreaseState: string = Translations_fi.DecreaseState;
	static EmployeesMustBeDefined: string = Translations_fi.EmployeesMustBeDefined;
	static HourBookingParameterNotFound: string = Translations_fi.HourBookingParameterNotFound;
	static IncreaseState: string = Translations_fi.IncreaseState;
	static ProductBookingParameterNotFound: string = Translations_fi.ProductBookingParameterNotFound;
	static NotImplemented: string = Translations_fi.NotImplemented;
	static RideBookingParameterNotFound: string = Translations_fi.RideBookingParameterNotFound;
	static SelectDate: string = Translations_fi.SelectDate;
	static StartDateMustBeDefined: string = Translations_fi.StartDateMustBeDefined;
	static WorkOrderSaveFailedConcurrencyError: string = Translations_fi.WorkOrderSaveFailedConcurrencyError;
	static WorkOrderSaveFailedErrorParameter: string = Translations_fi.WorkOrderSaveFailedErrorParameter;
	static WorkOrderSaveSuccess: string = Translations_fi.WorkOrderSaveSuccess;
	static WorkOrderCannotBeModifiedInThisState: string = Translations_fi.WorkOrderCannotBeModifiedInThisState;
	static FinishWork: string = Translations_fi.FinishWork;
	static StartWork: string = Translations_fi.StartWork;
	static WorkOrderCannotCrossWeekBoundary: string = Translations_fi.WorkOrderCannotCrossWeekBoundary;
	static WorkOrders: string = Translations_fi.WorkOrders;
	static MeasureUnitD: string = Translations_fi.MeasureUnitD;
	static StartDateAndDuration: string = Translations_fi.StartDateAndDuration;
	static Warning: string = Translations_fi.Warning;
	static YouHaveNotSavedChangesDoYouWantToSaveChanges: string = Translations_fi.YouHaveNotSavedChangesDoYouWantToSaveChanges;
	static Loading: string = Translations_fi.Loading;
	static Photo: string = Translations_fi.Photo;
	static AddToHomeScreen: string = Translations_fi.AddToHomeScreen;
	static DoYouWantToAddThisApplicationToHomeScreen: string = Translations_fi.DoYouWantToAddThisApplicationToHomeScreen;
	static NewVersionAvailable: string = Translations_fi.NewVersionAvailable;
	static Update: string = Translations_fi.Update;
	static Contacts: string = Translations_fi.Contacts;
	static CustomerParameterNotFound: string = Translations_fi.CustomerParameterNotFound;
	static Customers: string = Translations_fi.Customers;
	static CustomerSaveFailedConcurrencyError: string = Translations_fi.CustomerSaveFailedConcurrencyError;
	static CustomerSaveFailedErrorParameter: string = Translations_fi.CustomerSaveFailedErrorParameter;
	static CustomerSaveSuccess: string = Translations_fi.CustomerSaveSuccess;
	static Employees: string = Translations_fi.Employees;
	static ErpReference: string = Translations_fi.ErpReference;
	static General: string = Translations_fi.General;
	static Products: string = Translations_fi.Products;
	static Name: string = Translations_fi.Name;
	static NameMustBeDefined: string = Translations_fi.NameMustBeDefined;
	static Next: string = Translations_fi.Next;
	static Previous: string = Translations_fi.Previous;
	static Receipts: string = Translations_fi.Receipts;
	static Search: string = Translations_fi.Search;
	static SearchCondition: string = Translations_fi.SearchCondition;
	static Sites: string = Translations_fi.Sites;
	static Tools: string = Translations_fi.Tools;
	static Vehicles: string = Translations_fi.Vehicles;
	static WorkOrderTypes: string = Translations_fi.WorkOrderTypes;
	static EventLogMessageWorkOrderCreated: string = Translations_fi.EventLogMessageWorkOrderCreated;
	static EventLogMessageWorkOrderRemoved: string = Translations_fi.EventLogMessageWorkOrderRemoved;
	static EventLogMessageWorkOrderStateChanged: string = Translations_fi.EventLogMessageWorkOrderStateChanged;
	static Events: string = Translations_fi.Events;
	static WorkOrderEventsTab: string = Translations_fi.WorkOrderEventsTab;
	static WorkOrderRemoveConfirmation: string = Translations_fi.WorkOrderRemoveConfirmation;
	static WorkOrderRemoveFailedConcurrencyError: string = Translations_fi.WorkOrderRemoveFailedConcurrencyError;
	static WorkOrderRemoveFailedErrorParameter: string = Translations_fi.WorkOrderRemoveFailedErrorParameter;
	static WorkOrdersRemoveSuccess: string = Translations_fi.WorkOrdersRemoveSuccess;
	static CustomerParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders: string = Translations_fi.CustomerParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders;
	static CustomerRemoveConfirmation: string = Translations_fi.CustomerRemoveConfirmation;
	static CustomerRemoveFailedConcurrencyError: string = Translations_fi.CustomerRemoveFailedConcurrencyError;
	static CustomerRemoveFailedErrorParameter: string = Translations_fi.CustomerRemoveFailedErrorParameter;
	static CustomerRemoveSuccess: string = Translations_fi.CustomerRemoveSuccess;
	static EmployeeRemoveConfirmation: string = Translations_fi.EmployeeRemoveConfirmation;
	static EventLogCustomerRemoved: string = Translations_fi.EventLogCustomerRemoved;
	static EventLogMessageCustomerCreated: string = Translations_fi.EventLogMessageCustomerCreated;
	static InUse: string = Translations_fi.InUse;
	static Number: string = Translations_fi.Number;
	static ChangePassword: string = Translations_fi.ChangePassword;
	static DayBookings: string = Translations_fi.DayBookings;
	static Email: string = Translations_fi.Email;
	static EmployeeParameterNotFound: string = Translations_fi.EmployeeParameterNotFound;
	static FirstName: string = Translations_fi.FirstName;
	static FirstNameMustBeDefined: string = Translations_fi.FirstNameMustBeDefined;
	static LastName: string = Translations_fi.LastName;
	static LastNameMustBeDefined: string = Translations_fi.LastNameMustBeDefined;
	static Phone: string = Translations_fi.Phone;
	static UserGroup: string = Translations_fi.UserGroup;
	static ChangePasswordFailedConcurrencyError: string = Translations_fi.ChangePasswordFailedConcurrencyError;
	static ChangePasswordFailedErrorParameter: string = Translations_fi.ChangePasswordFailedErrorParameter;
	static ChangePasswordSuccess: string = Translations_fi.ChangePasswordSuccess;
	static ChangeUsername: string = Translations_fi.ChangeUsername;
	static ChangeUsernameFailedConcurrencyError: string = Translations_fi.ChangeUsernameFailedConcurrencyError;
	static ChangeUsernameFailedErrorParameter: string = Translations_fi.ChangeUsernameFailedErrorParameter;
	static ChangeUsernameSuccess: string = Translations_fi.ChangeUsernameSuccess;
	static CreditCardNumber: string = Translations_fi.CreditCardNumber;
	static CurrentPasswordIsIncorrect: string = Translations_fi.CurrentPasswordIsIncorrect;
	static DayBooking: string = Translations_fi.DayBooking;
	static DayBookingParameterNotFound: string = Translations_fi.DayBookingParameterNotFound;
	static DayBookingType: string = Translations_fi.DayBookingType;
	static Documents: string = Translations_fi.Documents;
	static EmployeeGroup: string = Translations_fi.EmployeeGroup;
	static EmployeeSaveFailedConcurrencyError: string = Translations_fi.EmployeeSaveFailedConcurrencyError;
	static EmployeeSaveFailedErrorParameter: string = Translations_fi.EmployeeSaveFailedErrorParameter;
	static EmployeeSaveSuccess: string = Translations_fi.EmployeeSaveSuccess;
	static EventLogMessageEmployeeCreated: string = Translations_fi.EventLogMessageEmployeeCreated;
	static NewPassword: string = Translations_fi.NewPassword;
	static NewPasswordConfirmation: string = Translations_fi.NewPasswordConfirmation;
	static NewUsername: string = Translations_fi.NewUsername;
	static PasswordConfirmation: string = Translations_fi.PasswordConfirmation;
	static PasswordConfirmationDoesNotMatchPassword: string = Translations_fi.PasswordConfirmationDoesNotMatchPassword;
	static PasswordMustBeDefined: string = Translations_fi.PasswordMustBeDefined;
	static PasswordMustMeetComplexityRequirements: string = Translations_fi.PasswordMustMeetComplexityRequirements;
	static UsernameIsAlreadyInUse: string = Translations_fi.UsernameIsAlreadyInUse;
	static UsernameMustBeDefined: string = Translations_fi.UsernameMustBeDefined;
	static UsernameMustMeetComplexityRequirements: string = Translations_fi.UsernameMustMeetComplexityRequirements;
	static CityAddress: string = Translations_fi.CityAddress;
	static ContactParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders: string = Translations_fi.ContactParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders;
	static ContactParameterNotFound: string = Translations_fi.ContactParameterNotFound;
	static ContactRemoveConfirmation: string = Translations_fi.ContactRemoveConfirmation;
	static ContactRemoveFailedConcurrencyError: string = Translations_fi.ContactRemoveFailedConcurrencyError;
	static ContactRemoveFailedErrorParameter: string = Translations_fi.ContactRemoveFailedErrorParameter;
	static ContactRemoveSuccess: string = Translations_fi.ContactRemoveSuccess;
	static ContactSaveFailedConcurrencyError: string = Translations_fi.ContactSaveFailedConcurrencyError;
	static ContactSaveFailedErrorParameter: string = Translations_fi.ContactSaveFailedErrorParameter;
	static ContactSaveSuccess: string = Translations_fi.ContactSaveSuccess;
	static CustomerSiteNumber: string = Translations_fi.CustomerSiteNumber;
	static Distance: string = Translations_fi.Distance;
	static EmployeeParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders: string = Translations_fi.EmployeeParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders;
	static EmployeeRemoveFailedConcurrencyError: string = Translations_fi.EmployeeRemoveFailedConcurrencyError;
	static EmployeeRemoveFailedErrorParameter: string = Translations_fi.EmployeeRemoveFailedErrorParameter;
	static EmployeeRemoveSuccess: string = Translations_fi.EmployeeRemoveSuccess;
	static MapLink: string = Translations_fi.MapLink;
	static PostalCode: string = Translations_fi.PostalCode;
	static SelectContact: string = Translations_fi.SelectContact;
	static SiteParameterNotFound: string = Translations_fi.SiteParameterNotFound;
	static Specifier: string = Translations_fi.Specifier;
	static StreetAddress: string = Translations_fi.StreetAddress;
	static Brand: string = Translations_fi.Brand;
	static Description: string = Translations_fi.Description;
	static RegisterNumber: string = Translations_fi.RegisterNumber;
	static RegisterNumberMustBeDefined: string = Translations_fi.RegisterNumberMustBeDefined;
	static RideBookingRequired: string = Translations_fi.RideBookingRequired;
	static SelectEmployee: string = Translations_fi.SelectEmployee;
	static SelectTool: string = Translations_fi.SelectTool;
	static Tool: string = Translations_fi.Tool;
	static ToolParameterNotFound: string = Translations_fi.ToolParameterNotFound;
	static ToolRemoveConfirmation: string = Translations_fi.ToolRemoveConfirmation;
	static ToolRemoveFailedConcurrencyError: string = Translations_fi.ToolRemoveFailedConcurrencyError;
	static ToolRemoveFailedErrorParameter: string = Translations_fi.ToolRemoveFailedErrorParameter;
	static ToolRemoveSuccess: string = Translations_fi.ToolRemoveSuccess;
	static ToolSaveFailedConcurrencyError: string = Translations_fi.ToolSaveFailedConcurrencyError;
	static ToolSaveFailedErrorParameter: string = Translations_fi.ToolSaveFailedErrorParameter;
	static ToolSaveSuccess: string = Translations_fi.ToolSaveSuccess;
	static VehicleParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders: string = Translations_fi.VehicleParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders;
	static VehicleParameterNotFound: string = Translations_fi.VehicleParameterNotFound;
	static VehicleRemoveConfirmation: string = Translations_fi.VehicleRemoveConfirmation;
	static VehicleRemoveFailedConcurrencyError: string = Translations_fi.VehicleRemoveFailedConcurrencyError;
	static VehicleRemoveFailedErrorParameter: string = Translations_fi.VehicleRemoveFailedErrorParameter;
	static VehicleRemoveSuccess: string = Translations_fi.VehicleRemoveSuccess;
	static VehicleSaveFailedConcurrencyError: string = Translations_fi.VehicleSaveFailedConcurrencyError;
	static VehicleSaveFailedErrorParameter: string = Translations_fi.VehicleSaveFailedErrorParameter;
	static VehicleSaveSuccess: string = Translations_fi.VehicleSaveSuccess;
	static WorkDescriptionMustBeDefined: string = Translations_fi.WorkDescriptionMustBeDefined;
	static ProductParameterNotFound: string = Translations_fi.ProductParameterNotFound;
	static ProductRemoveConfirmation: string = Translations_fi.ProductRemoveConfirmation;
	static ProductRemoveFailedConcurrencyError: string = Translations_fi.ProductRemoveFailedConcurrencyError;
	static ProductRemoveFailedErrorParameter: string = Translations_fi.ProductRemoveFailedErrorParameter;
	static ProductRemoveSuccess: string = Translations_fi.ProductRemoveSuccess;
	static ProductSaveFailedConcurrencyError: string = Translations_fi.ProductSaveFailedConcurrencyError;
	static ProductSaveFailedErrorParameter: string = Translations_fi.ProductSaveFailedErrorParameter;
	static ProductSaveSuccess: string = Translations_fi.ProductSaveSuccess;
	static MeasureUnit: string = Translations_fi.MeasureUnit;
	static PrivacyPolicy: string = Translations_fi.PrivacyPolicy;
	static ContactIsNotDefined: string = Translations_fi.ContactIsNotDefined;
	static DoYouReallyWantToSetWorkOrderDone: string = Translations_fi.DoYouReallyWantToSetWorkOrderDone;
	static DoYouReallyWantToSetWorkOrderPlanned: string = Translations_fi.DoYouReallyWantToSetWorkOrderPlanned;
	static EmployeesHaveDayBookingsForWorkOrderTime: string = Translations_fi.EmployeesHaveDayBookingsForWorkOrderTime;
	static HourBookingsMustBeDefined: string = Translations_fi.HourBookingsMustBeDefined;
	static HourBookingsAreNotDefinedForAllWorkDays: string = Translations_fi.HourBookingsAreNotDefinedForAllWorkDays;
	static ProductBookingsAreNotDefined: string = Translations_fi.ProductBookingsAreNotDefined;
	static RideBookingsMustBeDefined: string = Translations_fi.RideBookingsMustBeDefined;
	static StartDateIsInThePast: string = Translations_fi.StartDateIsInThePast;
	static MeasureUnitBtl: string = Translations_fi.MeasureUnitBtl;
	static MeasureUnitM2: string = Translations_fi.MeasureUnitM2;
	static MeasureUnitM3: string = Translations_fi.MeasureUnitM3;
	static MeasureUnitPkg: string = Translations_fi.MeasureUnitPkg;
	static SiteMustBeDefined: string = Translations_fi.SiteMustBeDefined;
	static SiteSaveFailedConcurrencyError: string = Translations_fi.SiteSaveFailedConcurrencyError;
	static SiteSaveFailedErrorParameter: string = Translations_fi.SiteSaveFailedErrorParameter;
	static SiteSaveSuccess: string = Translations_fi.SiteSaveSuccess;
	static SitesThatRelatedToWorkOrdersCannotBeRemoved: string = Translations_fi.SitesThatRelatedToWorkOrdersCannotBeRemoved;
	static NoWorkOrdersForThisDay: string = Translations_fi.NoWorkOrdersForThisDay;
	static AttachmentRemoveConfirmation: string = Translations_fi.AttachmentRemoveConfirmation;
	static HourBookingRemoveConfirmation: string = Translations_fi.HourBookingRemoveConfirmation;
	static ProductBookingRemoveConfirmation: string = Translations_fi.ProductBookingRemoveConfirmation;
	static RideBookingRemoveConfirmation: string = Translations_fi.RideBookingRemoveConfirmation;
	static Code: string = Translations_fi.Code;
	static DateRangeEnd: string = Translations_fi.DateRangeEnd;
	static DateRangeStart: string = Translations_fi.DateRangeStart;
	static DocumentParameterGenerationFailedParameter: string = Translations_fi.DocumentParameterGenerationFailedParameter;
	static EmployeeReportName: string = Translations_fi.EmployeeReportName;
	static Message: string = Translations_fi.Message;
	static Print: string = Translations_fi.Print;
	static SelectDateRange: string = Translations_fi.SelectDateRange;
	static WorkOrderAcknowledgementType: string = Translations_fi.WorkOrderAcknowledgementType;
	static DoYouWantToSendWorkOrderAcknowledgementToContact: string = Translations_fi.DoYouWantToSendWorkOrderAcknowledgementToContact;
	static Information: string = Translations_fi.Information;
	static AcknowledgementCannotBeSendBecauseValidEmailIsNotDefinedForContact: string = Translations_fi.AcknowledgementCannotBeSendBecauseValidEmailIsNotDefinedForContact;
	static EmailAddressParameterIsInvalid: string = Translations_fi.EmailAddressParameterIsInvalid;
	static SendingEmailFailedErrorParameter: string = Translations_fi.SendingEmailFailedErrorParameter;
	static AcknowledgementParameterNotFound: string = Translations_fi.AcknowledgementParameterNotFound;
	static ApplicationTempPathIsNotSpecified: string = Translations_fi.ApplicationTempPathIsNotSpecified;
	static ApplicationTempPathParameterNotFound: string = Translations_fi.ApplicationTempPathParameterNotFound;
	static ApprovedToBeInvoiced: string = Translations_fi.ApprovedToBeInvoiced;
	static ApproveToBeInvoiced: string = Translations_fi.ApproveToBeInvoiced;
	static CustomerView: string = Translations_fi.CustomerView;
	static LoadingAcknowledgement: string = Translations_fi.LoadingAcknowledgement;
	static MessageContent: string = Translations_fi.MessageContent;
	static MessageContentMustBeDefined: string = Translations_fi.MessageContentMustBeDefined;
	static MessageSendFailedErrorParameter: string = Translations_fi.MessageSendFailedErrorParameter;
	static MessageSendSuccess: string = Translations_fi.MessageSendSuccess;
	static MessageSubject: string = Translations_fi.MessageSubject;
	static MessageSubjectMustBeDefined: string = Translations_fi.MessageSubjectMustBeDefined;
	static MessageToAcknowledgementRequest: string = Translations_fi.MessageToAcknowledgementRequest;
	static Send: string = Translations_fi.Send;
	static Sender: string = Translations_fi.Sender;
	static SendMessage: string = Translations_fi.SendMessage;
	static ThisBrowserDoesNotSupportPdfsPleaseDownloadPdfToViewIt: string = Translations_fi.ThisBrowserDoesNotSupportPdfsPleaseDownloadPdfToViewIt;
	static AcknowledgementParameterHasAlreadyBeenApproved: string = Translations_fi.AcknowledgementParameterHasAlreadyBeenApproved;
	static EventLogMessageWorkOrderApprovalRequest: string = Translations_fi.EventLogMessageWorkOrderApprovalRequest;
	static EventLogMessageWorkOrderApproved: string = Translations_fi.EventLogMessageWorkOrderApproved;
	static EventLogSaveFailedErrorParameter: string = Translations_fi.EventLogSaveFailedErrorParameter;
	static EventLogSaveSuccess: string = Translations_fi.EventLogSaveSuccess;
	static WorkOrderAcknowledgementApprovalFailedConcurrencyError: string = Translations_fi.WorkOrderAcknowledgementApprovalFailedConcurrencyError;
	static WorkOrderAcknowledgementApprovalFailedErrorParameter: string = Translations_fi.WorkOrderAcknowledgementApprovalFailedErrorParameter;
	static WorkOrderAcknowledgementApprovalSuccess: string = Translations_fi.WorkOrderAcknowledgementApprovalSuccess;
	static AcknowledgementSentToAddress: string = Translations_fi.AcknowledgementSentToAddress;
	static DoYouReallyWantToSaveData: string = Translations_fi.DoYouReallyWantToSaveData;
	static DoYouWantToMakeProductBookingsBeforehand: string = Translations_fi.DoYouWantToMakeProductBookingsBeforehand;
	static EmployeeWhenVehicleIsNotDefinedEmployeeCannotMakeRideBookings: string = Translations_fi.EmployeeWhenVehicleIsNotDefinedEmployeeCannotMakeRideBookings;
	static HourBookingAmountIsZero: string = Translations_fi.HourBookingAmountIsZero;
	static HourBookingCommentMustBeDefinedIfAmountIsZero: string = Translations_fi.HourBookingCommentMustBeDefinedIfAmountIsZero;
	static ProductBookingProductMustBeDefined: string = Translations_fi.ProductBookingProductMustBeDefined;
	static RideBookingVehicleMustBeDefined: string = Translations_fi.RideBookingVehicleMustBeDefined;
	static Browser: string = Translations_fi.Browser;
	static EntryType: string = Translations_fi.EntryType;
	static EventLog: string = Translations_fi.EventLog;
	static IpAddress: string = Translations_fi.IpAddress;
	static LogEntryType: string = Translations_fi.LogEntryType;
	static ModifyUserName: string = Translations_fi.ModifyUserName;
	static Open: string = Translations_fi.Open;
	static Operation: string = Translations_fi.Operation;
	static OperationLog: string = Translations_fi.OperationLog;
	static OperationLogParameterNotFound: string = Translations_fi.OperationLogParameterNotFound;
	static Parameters: string = Translations_fi.Parameters;
	static RelatedObjectType: string = Translations_fi.RelatedObjectType;
	static SoftwareVersion: string = Translations_fi.SoftwareVersion;
	static Time: string = Translations_fi.Time;
	static Tracking: string = Translations_fi.Tracking;
	static ContactWithEmailMustBeDefinedWhenAcknowledementTypeIsNotDoNotSend: string = Translations_fi.ContactWithEmailMustBeDefinedWhenAcknowledementTypeIsNotDoNotSend;
	static EmployeesThatHaveWorkedInSite: string = Translations_fi.EmployeesThatHaveWorkedInSite;
	static OpenMapLink: string = Translations_fi.OpenMapLink;
	static SiteFamiliarizationRequired: string = Translations_fi.SiteFamiliarizationRequired;
	static SiteSamiliarizationType1Suffix: string = Translations_fi.SiteSamiliarizationType1Suffix;
	static WorkOrderRemoveConfirmationAcknowledgementSent: string = Translations_fi.WorkOrderRemoveConfirmationAcknowledgementSent;
	static CreateMapLink: string = Translations_fi.CreateMapLink;
	static ProductBookingAmountMustBeGreaterThanZero: string = Translations_fi.ProductBookingAmountMustBeGreaterThanZero;
	static NextVehicleInspection: string = Translations_fi.NextVehicleInspection;
	static ShowNotInUse: string = Translations_fi.ShowNotInUse;
	static Competencies: string = Translations_fi.Competencies;
	static Competency: string = Translations_fi.Competency;
	static CompetencyParameterCannotBeRemovedBecauseItIsRelatedToParameterEmployees: string = Translations_fi.CompetencyParameterCannotBeRemovedBecauseItIsRelatedToParameterEmployees;
	static CompetencyParameterNotFound: string = Translations_fi.CompetencyParameterNotFound;
	static CompetencyRemoveConfirmation: string = Translations_fi.CompetencyRemoveConfirmation;
	static CompetencyRemoveFailedConcurrencyError: string = Translations_fi.CompetencyRemoveFailedConcurrencyError;
	static CompetencyRemoveFailedErrorParameter: string = Translations_fi.CompetencyRemoveFailedErrorParameter;
	static CompetencyRemoveSuccess: string = Translations_fi.CompetencyRemoveSuccess;
	static CompetencySaveFailedConcurrencyError: string = Translations_fi.CompetencySaveFailedConcurrencyError;
	static CompetencySaveFailedErrorParameter: string = Translations_fi.CompetencySaveFailedErrorParameter;
	static CompetencySaveSuccess: string = Translations_fi.CompetencySaveSuccess;
	static CompetencyType: string = Translations_fi.CompetencyType;
	static CompetencyTypesNotFound: string = Translations_fi.CompetencyTypesNotFound;
	static CompetenciesNotFound: string = Translations_fi.CompetenciesNotFound;
	static EmployeeCompetencies: string = Translations_fi.EmployeeCompetencies;
	static EmployeeCompetencyParameterNotFound: string = Translations_fi.EmployeeCompetencyParameterNotFound;
	static EmployeeCompetencyRemoveConfirmation: string = Translations_fi.EmployeeCompetencyRemoveConfirmation;
	static EmployeeCompetencyRemoveFailedConcurrencyError: string = Translations_fi.EmployeeCompetencyRemoveFailedConcurrencyError;
	static EmployeeCompetencyRemoveFailedErrorParameter: string = Translations_fi.EmployeeCompetencyRemoveFailedErrorParameter;
	static EmployeeCompetencyRemoveSuccess: string = Translations_fi.EmployeeCompetencyRemoveSuccess;
	static EmployeeCompetencySaveFailedConcurrencyError: string = Translations_fi.EmployeeCompetencySaveFailedConcurrencyError;
	static EmployeeCompetencySaveFailedErrorParameter: string = Translations_fi.EmployeeCompetencySaveFailedErrorParameter;
	static EmployeeCompetencySaveSuccess: string = Translations_fi.EmployeeCompetencySaveSuccess;
	static EmployeesNotFound: string = Translations_fi.EmployeesNotFound;
	static ExpirationDate: string = Translations_fi.ExpirationDate;
	static GrantDate: string = Translations_fi.GrantDate;
	static ExpirationDateAbrv: string = Translations_fi.ExpirationDateAbrv;
	static GrantDateAbrv: string = Translations_fi.GrantDateAbrv;
	static AllStates: string = Translations_fi.AllStates;
	static CreateInvoiceXmlFailedErrorParameter: string = Translations_fi.CreateInvoiceXmlFailedErrorParameter;
	static CreateInvoiceXmlSuccess: string = Translations_fi.CreateInvoiceXmlSuccess;
	static DownloadInvoiceFile: string = Translations_fi.DownloadInvoiceFile;
	static SetWorkOrdersAndInvoicesToTransferredState: string = Translations_fi.SetWorkOrdersAndInvoicesToTransferredState;
	static TakeToInvoicing: string = Translations_fi.TakeToInvoicing;
	static ValueOfOptionParameterNotSpecified: string = Translations_fi.ValueOfOptionParameterNotSpecified;
	static AdditionalInformation: string = Translations_fi.AdditionalInformation;
	static NotFullDay: string = Translations_fi.NotFullDay;
	static PermissionDenied: string = Translations_fi.PermissionDenied;
	static WorkOrderInvoiceFile: string = Translations_fi.WorkOrderInvoiceFile;
	static WorkOrderInvoicePrint: string = Translations_fi.WorkOrderInvoicePrint;
	static FileParameterDownloaded: string = Translations_fi.FileParameterDownloaded;
	static Camera: string = Translations_fi.Camera;
	static CombineCustomersWorkOrdersToSameInvoice: string = Translations_fi.CombineCustomersWorkOrdersToSameInvoice;
	static ConvertToProject: string = Translations_fi.ConvertToProject;
	static OnlyWorkOrderThatIsNotAttachedToProjectCanBeConvertedToProject: string = Translations_fi.OnlyWorkOrderThatIsNotAttachedToProjectCanBeConvertedToProject;
	static WorkOrderProject: string = Translations_fi.WorkOrderProject;
	static WorkOrderTask: string = Translations_fi.WorkOrderTask;
	static WorkOrderWork: string = Translations_fi.WorkOrderWork;
	static WorkTitle: string = Translations_fi.WorkTitle;
	static WorkTitleMustBeDefined: string = Translations_fi.WorkTitleMustBeDefined;
	static AbrDays: string = Translations_fi.AbrDays;
	static Attach: string = Translations_fi.Attach;
	static DoYouReallyWantToConvertWorkOrderWorkToWorkOrderProject: string = Translations_fi.DoYouReallyWantToConvertWorkOrderWorkToWorkOrderProject;
	static AttachWorkToProject: string = Translations_fi.AttachWorkToProject;
	static EventLogMessageWorkOrderAttachedToProjectParameter: string = Translations_fi.EventLogMessageWorkOrderAttachedToProjectParameter;
	static EventLogMessageWorkOrderConvertedToProject: string = Translations_fi.EventLogMessageWorkOrderConvertedToProject;
	static EventLogMessageWorkOrderDetachedFromProjectParameter: string = Translations_fi.EventLogMessageWorkOrderDetachedFromProjectParameter;
	static WorkOrderDetachConfirmation: string = Translations_fi.WorkOrderDetachConfirmation;
	static WorkOrderDetachFromProjectErrorParameter: string = Translations_fi.WorkOrderDetachFromProjectErrorParameter;
	static WorkOrderDetachFromProjectSuccess: string = Translations_fi.WorkOrderDetachFromProjectSuccess;
	static WorkOrderDetachConfirmationRecurring: string = Translations_fi.WorkOrderDetachConfirmationRecurring;
	static WorkOrderParameterIsNotAttachedToWorkOrderParameter: string = Translations_fi.WorkOrderParameterIsNotAttachedToWorkOrderParameter;
	static WorkOrderParameterIsNotProject: string = Translations_fi.WorkOrderParameterIsNotProject;
	static WorkOrderParameterIsNotTask: string = Translations_fi.WorkOrderParameterIsNotTask;
	static WorkOrdersAttachToProjectErrorParameter: string = Translations_fi.WorkOrdersAttachToProjectErrorParameter;
	static WorkOrdersAttachToProjectSuccess: string = Translations_fi.WorkOrdersAttachToProjectSuccess;
	static WorkOrdersCanBeAttachedOnlyToProjects: string = Translations_fi.WorkOrdersCanBeAttachedOnlyToProjects;
	static WorkOrdersNotFound: string = Translations_fi.WorkOrdersNotFound;
	static AbrNumber: string = Translations_fi.AbrNumber;
	static AttachedToProject: string = Translations_fi.AttachedToProject;
	static DetachFromProject: string = Translations_fi.DetachFromProject;
	static Invoice: string = Translations_fi.Invoice;
	static ProjectDoesNotHaveAnyTasksDoYouWantToRemoveProject: string = Translations_fi.ProjectDoesNotHaveAnyTasksDoYouWantToRemoveProject;
	static WorkOrderChildDetachConfirmation: string = Translations_fi.WorkOrderChildDetachConfirmation;
	static WorkOrderChildDetachRemoveConfirmation: string = Translations_fi.WorkOrderChildDetachRemoveConfirmation;
	static WorkOrderProjectParameterCannotBeRemovedBecauseOtherWorkOrderTasksAreBelongingToIt: string = Translations_fi.WorkOrderProjectParameterCannotBeRemovedBecauseOtherWorkOrderTasksAreBelongingToIt;
	static WorkOrderStateUndefined: string = Translations_fi.WorkOrderStateUndefined;
	static AllWorkOrderTasksMustBeInSameState: string = Translations_fi.AllWorkOrderTasksMustBeInSameState;
	static RemoveWorkOrderProjectTasksBeforeRemovingTheProject: string = Translations_fi.RemoveWorkOrderProjectTasksBeforeRemovingTheProject;
	static WorkOrderProjectRemoveConfirmation: string = Translations_fi.WorkOrderProjectRemoveConfirmation;
	static WorkOrderTaskParameterCannotCrossWeekBoundary: string = Translations_fi.WorkOrderTaskParameterCannotCrossWeekBoundary;
	static CombineProjectWorkOrdersToSameInvoice: string = Translations_fi.CombineProjectWorkOrdersToSameInvoice;
	static CreateInvoices: string = Translations_fi.CreateInvoices;
	static CreateInvoicesSuccess: string = Translations_fi.CreateInvoicesSuccess;
	static CreateWorkOrderInvoicesFailedErrorParameter: string = Translations_fi.CreateWorkOrderInvoicesFailedErrorParameter;
	static CustomerParameterCannotBeRemovedBecauseItIsRelatedToParameterInvoices: string = Translations_fi.CustomerParameterCannotBeRemovedBecauseItIsRelatedToParameterInvoices;
	static EventLogMessageInvoiceCreated: string = Translations_fi.EventLogMessageInvoiceCreated;
	static EventLogMessageInvoiceRemoved: string = Translations_fi.EventLogMessageInvoiceRemoved;
	static EventLogMessageWorkOrderAttachedToInvoiceParameter: string = Translations_fi.EventLogMessageWorkOrderAttachedToInvoiceParameter;
	static EventLogMessageWorkOrderDetachedFromInvoiceParameter: string = Translations_fi.EventLogMessageWorkOrderDetachedFromInvoiceParameter;
	static InvoiceDataDate: string = Translations_fi.InvoiceDataDate;
	static InvoiceParameterNotFound: string = Translations_fi.InvoiceParameterNotFound;
	static InvoiceRemoveConfirmation: string = Translations_fi.InvoiceRemoveConfirmation;
	static InvoiceRemoveFailedConcurrencyError: string = Translations_fi.InvoiceRemoveFailedConcurrencyError;
	static InvoiceRemoveFailedErrorParameter: string = Translations_fi.InvoiceRemoveFailedErrorParameter;
	static InvoiceRemoveSuccess: string = Translations_fi.InvoiceRemoveSuccess;
	static Invoices: string = Translations_fi.Invoices;
	static InvoiceStatePreliminary: string = Translations_fi.InvoiceStatePreliminary;
	static InvoiceStateTransferred: string = Translations_fi.InvoiceStateTransferred;
	static SelectWorkOrders: string = Translations_fi.SelectWorkOrders;
	static WorkOrderParameterCannotBeRemovedBecauseItIsRelatedToParameterInvoices: string = Translations_fi.WorkOrderParameterCannotBeRemovedBecauseItIsRelatedToParameterInvoices;
	static AddWorkOrdersToInvoiceFailedErrorParameter: string = Translations_fi.AddWorkOrdersToInvoiceFailedErrorParameter;
	static AddWorkOrdersToInvoiceSuccess: string = Translations_fi.AddWorkOrdersToInvoiceSuccess;
	static CustomerSiteNumbers: string = Translations_fi.CustomerSiteNumbers;
	static EventLogMessageInvoiceStateChanged: string = Translations_fi.EventLogMessageInvoiceStateChanged;
	static StateOfAllInvoicesMustBeParameter: string = Translations_fi.StateOfAllInvoicesMustBeParameter;
	static WorkOrderRemoveFromInvoiceConfirmation: string = Translations_fi.WorkOrderRemoveFromInvoiceConfirmation;
	static WorkOrderRemoveFromInvoiceFailedConcurrencyError: string = Translations_fi.WorkOrderRemoveFromInvoiceFailedConcurrencyError;
	static WorkOrderRemoveFromInvoiceFailedErrorParameter: string = Translations_fi.WorkOrderRemoveFromInvoiceFailedErrorParameter;
	static WorkOrderRemoveFromInvoiceSuccess: string = Translations_fi.WorkOrderRemoveFromInvoiceSuccess;
	static WorkOrdersToBeAddedMustHaveSameCustomerAsInvoice: string = Translations_fi.WorkOrdersToBeAddedMustHaveSameCustomerAsInvoice;
	static IsRelatedToInvoiceParameter: string = Translations_fi.IsRelatedToInvoiceParameter;
	static NoCompetenciesDefined: string = Translations_fi.NoCompetenciesDefined;
	static AllWorkOrderTypes: string = Translations_fi.AllWorkOrderTypes;
	static CustomerErpReference: string = Translations_fi.CustomerErpReference;
	static EventLogMessageInvoiceFileCreated: string = Translations_fi.EventLogMessageInvoiceFileCreated;
	static WorkOrdersToBeAddedMustHaveSameWorkOrderTypeAsInvoice: string = Translations_fi.WorkOrdersToBeAddedMustHaveSameWorkOrderTypeAsInvoice;
	static Definitions: string = Translations_fi.Definitions;
	static NewWork: string = Translations_fi.NewWork;
	static Show: string = Translations_fi.Show;
	static Team: string = Translations_fi.Team;
	static NoMatchesFound: string = Translations_fi.NoMatchesFound;
	static ShowAdditionalResults: string = Translations_fi.ShowAdditionalResults;
	static Collapse: string = Translations_fi.Collapse;
	static Expand: string = Translations_fi.Expand;
	static Calendar: string = Translations_fi.Calendar;
	static InvoicingTitle: string = Translations_fi.InvoicingTitle;
	static MapTitle: string = Translations_fi.MapTitle;
	static WorkCalendarTitle: string = Translations_fi.WorkCalendarTitle;
	static WorkListTitle: string = Translations_fi.WorkListTitle;
	static WorkTimeTitle: string = Translations_fi.WorkTimeTitle;
	static Billable: string = Translations_fi.Billable;
	static Changed: string = Translations_fi.Changed;
	static Reported: string = Translations_fi.Reported;
	static WorkingTimeRegistrations: string = Translations_fi.WorkingTimeRegistrations;
	static CalendarAccuracyDayTwoWeeks: string = Translations_fi.CalendarAccuracyDayTwoWeeks;
	static CalendarAccuracyQuarterHour: string = Translations_fi.CalendarAccuracyQuarterHour;
	static NextDay: string = Translations_fi.NextDay;
	static PreviousDay: string = Translations_fi.PreviousDay;
	static Weeks: string = Translations_fi.Weeks;
	static CalendarAccuracyFourHours: string = Translations_fi.CalendarAccuracyFourHours;
	static AddNew: string = Translations_fi.AddNew;
	static OwnSettings: string = Translations_fi.OwnSettings;
	static SystemConfiguration: string = Translations_fi.SystemConfiguration;
	static Reports: string = Translations_fi.Reports;
	static Select: string = Translations_fi.Select;
	static Projects: string = Translations_fi.Projects;
	static Resources: string = Translations_fi.Resources;
	static StandByWorks: string = Translations_fi.StandByWorks;
	static Teams: string = Translations_fi.Teams;
	static SelectedOrder: string = Translations_fi.SelectedOrder;
	static FinishedWorks: string = Translations_fi.FinishedWorks;
	static TransferredWork: string = Translations_fi.TransferredWork;
	static Recurrence: string = Translations_fi.Recurrence;
	static Allday: string = Translations_fi.Allday;
	static EndTime: string = Translations_fi.EndTime;
	static RecurrenceDuration: string = Translations_fi.RecurrenceDuration;
	static StartTime: string = Translations_fi.StartTime;
	static Daily: string = Translations_fi.Daily;
	static Days: string = Translations_fi.Days;
	static Every: string = Translations_fi.Every;
	static EveryWeekday: string = Translations_fi.EveryWeekday;
	static Monthly: string = Translations_fi.Monthly;
	static Weekly: string = Translations_fi.Weekly;
	static Yearly: string = Translations_fi.Yearly;
	static Today: string = Translations_fi.Today;
	static NoSelectedWorkOrders: string = Translations_fi.NoSelectedWorkOrders;
	static NewContactPerson: string = Translations_fi.NewContactPerson;
	static NewCustomer: string = Translations_fi.NewCustomer;
	static Subscriber: string = Translations_fi.Subscriber;
	static WorkOrderTasks: string = Translations_fi.WorkOrderTasks;
	static CustomerMustBeDefined: string = Translations_fi.CustomerMustBeDefined;
	static Hour: string = Translations_fi.Hour;
	static HourPluralDuration: string = Translations_fi.HourPluralDuration;
	static Minutes: string = Translations_fi.Minutes;
	static Always: string = Translations_fi.Always;
	static April: string = Translations_fi.April;
	static August: string = Translations_fi.August;
	static Day: string = Translations_fi.Day;
	static DayInterval: string = Translations_fi.DayInterval;
	static DayPluralDuration: string = Translations_fi.DayPluralDuration;
	static December: string = Translations_fi.December;
	static EditRecurrence: string = Translations_fi.EditRecurrence;
	static February: string = Translations_fi.February;
	static Friday: string = Translations_fi.Friday;
	static January: string = Translations_fi.January;
	static July: string = Translations_fi.July;
	static June: string = Translations_fi.June;
	static MakeRecurring: string = Translations_fi.MakeRecurring;
	static March: string = Translations_fi.March;
	static May: string = Translations_fi.May;
	static Monday: string = Translations_fi.Monday;
	static MonthIntervalOn: string = Translations_fi.MonthIntervalOn;
	static November: string = Translations_fi.November;
	static October: string = Translations_fi.October;
	static OnDay: string = Translations_fi.OnDay;
	static RangeOfRecurrence: string = Translations_fi.RangeOfRecurrence;
	static RecurrenceAfterOccurrences: string = Translations_fi.RecurrenceAfterOccurrences;
	static RecurrenceEndAfter: string = Translations_fi.RecurrenceEndAfter;
	static RecurrenceEndBy: string = Translations_fi.RecurrenceEndBy;
	static RecurrencePattern: string = Translations_fi.RecurrencePattern;
	static RemoveRecurrence: string = Translations_fi.RemoveRecurrence;
	static Saturday: string = Translations_fi.Saturday;
	static September: string = Translations_fi.September;
	static StartDateAndEndDateMustBeSpecifiedBeforeRecurrenceCanBeDefined: string = Translations_fi.StartDateAndEndDateMustBeSpecifiedBeforeRecurrenceCanBeDefined;
	static Sunday: string = Translations_fi.Sunday;
	static Thursday: string = Translations_fi.Thursday;
	static Tuesday: string = Translations_fi.Tuesday;
	static Wednesday: string = Translations_fi.Wednesday;
	static WeekIntervalOn: string = Translations_fi.WeekIntervalOn;
	static WeekPluralDuration: string = Translations_fi.WeekPluralDuration;
	static WorkTime: string = Translations_fi.WorkTime;
	static YearIntervalOn: string = Translations_fi.YearIntervalOn;
	static EventLogMessageWorkOrderConvertedToRecurringProject: string = Translations_fi.EventLogMessageWorkOrderConvertedToRecurringProject;
	static OnlyWorkOrderThatIsNotAttachedToProjectCanBeConvertedToRecurringProject: string = Translations_fi.OnlyWorkOrderThatIsNotAttachedToProjectCanBeConvertedToRecurringProject;
	static StartTimeAndEndTimeMustBeDefinedForRecurringWork: string = Translations_fi.StartTimeAndEndTimeMustBeDefinedForRecurringWork;
	static EventLogMessageWorkOrderRecurrenceChanged: string = Translations_fi.EventLogMessageWorkOrderRecurrenceChanged;
	static RecurringWorkMustHaveAtLeastOneOccurence: string = Translations_fi.RecurringWorkMustHaveAtLeastOneOccurence;
	static EndDateMustBeDefined: string = Translations_fi.EndDateMustBeDefined;
	static AbrHours: string = Translations_fi.AbrHours;
	static AbrMins: string = Translations_fi.AbrMins;
	static CostCenter: string = Translations_fi.CostCenter;
	static NoWorkShiftsForThisDay: string = Translations_fi.NoWorkShiftsForThisDay;
	static Order: string = Translations_fi.Order;
	static Orders: string = Translations_fi.Orders;
	static Settings: string = Translations_fi.Settings;
	static Break: string = Translations_fi.Break;
	static BreakReason: string = Translations_fi.BreakReason;
	static CommentMustBeDefined: string = Translations_fi.CommentMustBeDefined;
	static Continue: string = Translations_fi.Continue;
	static CurrentWorkShiftIsNotRelatedToWorkOrder: string = Translations_fi.CurrentWorkShiftIsNotRelatedToWorkOrder;
	static CurrentWorkShiftNotFound: string = Translations_fi.CurrentWorkShiftNotFound;
	static DoYouWantToSetCurrentWorkOrderDone: string = Translations_fi.DoYouWantToSetCurrentWorkOrderDone;
	static EndLocation: string = Translations_fi.EndLocation;
	static Finish: string = Translations_fi.Finish;
	static NonOrder: string = Translations_fi.NonOrder;
	static NotInUse: string = Translations_fi.NotInUse;
	static Pause: string = Translations_fi.Pause;
	static PauseWork: string = Translations_fi.PauseWork;
	static Start: string = Translations_fi.Start;
	static StartLocation: string = Translations_fi.StartLocation;
	static StartNew: string = Translations_fi.StartNew;
	static SuitableWorkOrdersOrCostCentersNotFound: string = Translations_fi.SuitableWorkOrdersOrCostCentersNotFound;
	static WorkShiftTimeSlotTypeLunchBreak: string = Translations_fi.WorkShiftTimeSlotTypeLunchBreak;
	static WorkShiftInProgress: string = Translations_fi.WorkShiftInProgress;
	static WorkShiftTimeSlotSaveFailedConcurrencyError: string = Translations_fi.WorkShiftTimeSlotSaveFailedConcurrencyError;
	static WorkShiftTimeSlotSaveFailedErrorParameter: string = Translations_fi.WorkShiftTimeSlotSaveFailedErrorParameter;
	static WorkShiftTimeSlotSaveSuccess: string = Translations_fi.WorkShiftTimeSlotSaveSuccess;
	static WorkShiftTimeSlotStateInvoicing: string = Translations_fi.WorkShiftTimeSlotStateInvoicing;
	static WorkShiftTimeSlotStateDone: string = Translations_fi.WorkShiftTimeSlotStateDone;
	static WorkShiftTimeSlotStateInProgress: string = Translations_fi.WorkShiftTimeSlotStateInProgress;
	static PreviousWorkShiftNotFound: string = Translations_fi.PreviousWorkShiftNotFound;
	static WorkShiftTimeSlotTypeBreak: string = Translations_fi.WorkShiftTimeSlotTypeBreak;
	static WorkShiftTimeSlotTypeWork: string = Translations_fi.WorkShiftTimeSlotTypeWork;
	static AllTypes: string = Translations_fi.AllTypes;
	static AType: string = Translations_fi.AType;
	static Task: string = Translations_fi.Task;
	static NoSelectedInvoice: string = Translations_fi.NoSelectedInvoice;
	static SelectedInvoice: string = Translations_fi.SelectedInvoice;
	static ThisWeek: string = Translations_fi.ThisWeek;
	static InvoicesTransfer: string = Translations_fi.InvoicesTransfer;
	static Salaries: string = Translations_fi.Salaries;
	static WorkingTimeRecords: string = Translations_fi.WorkingTimeRecords;
	static WorkOrdersChecking: string = Translations_fi.WorkOrdersChecking;
	static RoutePoint: string = Translations_fi.RoutePoint;
	static RoutePointBookingCheckParameterNotFound: string = Translations_fi.RoutePointBookingCheckParameterNotFound;
	static RoutePointBookingParameterNotFound: string = Translations_fi.RoutePointBookingParameterNotFound;
	static RoutePointBookingRemoveConfirmation: string = Translations_fi.RoutePointBookingRemoveConfirmation;
	static RoutePointBookingRemoveFailedConcurrencyError: string = Translations_fi.RoutePointBookingRemoveFailedConcurrencyError;
	static RoutePointBookingRemoveFailedErrorParameter: string = Translations_fi.RoutePointBookingRemoveFailedErrorParameter;
	static RoutePointBookingRemoveSuccess: string = Translations_fi.RoutePointBookingRemoveSuccess;
	static RoutePointBookings: string = Translations_fi.RoutePointBookings;
	static RoutePointBookingSaveFailedConcurrencyError: string = Translations_fi.RoutePointBookingSaveFailedConcurrencyError;
	static RoutePointBookingSaveFailedErrorParameter: string = Translations_fi.RoutePointBookingSaveFailedErrorParameter;
	static RoutePointBookingSaveSuccess: string = Translations_fi.RoutePointBookingSaveSuccess;
	static RoutePointNotFound: string = Translations_fi.RoutePointNotFound;
	static Total: string = Translations_fi.Total;
	static AllRoutePointsHaveBeenAdded: string = Translations_fi.AllRoutePointsHaveBeenAdded;
	static RecurringWorks: string = Translations_fi.RecurringWorks;
	static NoRoutePointChecksDefined: string = Translations_fi.NoRoutePointChecksDefined;
	static RecurringWork: string = Translations_fi.RecurringWork;
	static StartRouteBooking: string = Translations_fi.StartRouteBooking;
	static RoutePointChecks: string = Translations_fi.RoutePointChecks;
	static CountryCode: string = Translations_fi.CountryCode;
	static MoveDown: string = Translations_fi.MoveDown;
	static MoveUp: string = Translations_fi.MoveUp;
	static RoutePointCheck: string = Translations_fi.RoutePointCheck;
	static RoutePointCheckParameterNotFound: string = Translations_fi.RoutePointCheckParameterNotFound;
	static RoutePointParameterNotFound: string = Translations_fi.RoutePointParameterNotFound;
	static RoutePoints: string = Translations_fi.RoutePoints;
	static WorkOrderRemoveSuccess: string = Translations_fi.WorkOrderRemoveSuccess;
	static WorkOrdersRemoveConfirmation: string = Translations_fi.WorkOrdersRemoveConfirmation;
	static WorkOrdersRemoveFailedConcurrencyError: string = Translations_fi.WorkOrdersRemoveFailedConcurrencyError;
	static WorkOrdersRemoveFailedErrorParameter: string = Translations_fi.WorkOrdersRemoveFailedErrorParameter;
	static EmptyResponse: string = Translations_fi.EmptyResponse;
	static GeocodeFailedFailedErrorParameter: string = Translations_fi.GeocodeFailedFailedErrorParameter;
	static NullValueResponse: string = Translations_fi.NullValueResponse;
	static ClickToChangeImage: string = Translations_fi.ClickToChangeImage;
	static InvoiceAddress: string = Translations_fi.InvoiceAddress;
	static InvoicePeriod: string = Translations_fi.InvoicePeriod;
	static SelectImageFile: string = Translations_fi.SelectImageFile;
	static WorkOrderParameterEmployeesMustBeDefined: string = Translations_fi.WorkOrderParameterEmployeesMustBeDefined;
	static WorkOrderParameterEndDateMustBeDefined: string = Translations_fi.WorkOrderParameterEndDateMustBeDefined;
	static WorkOrderParameterHourBookingsMustBeDefined: string = Translations_fi.WorkOrderParameterHourBookingsMustBeDefined;
	static WorkOrderParameterRideBookingsMustBeDefined: string = Translations_fi.WorkOrderParameterRideBookingsMustBeDefined;
	static WorkOrderParameterStartDateMustBeDefined: string = Translations_fi.WorkOrderParameterStartDateMustBeDefined;
	static WorkOrderParameterStateChangeFailedConcurrencyError: string = Translations_fi.WorkOrderParameterStateChangeFailedConcurrencyError;
	static WorkOrderParameterStateChangeFailedErrorParameter: string = Translations_fi.WorkOrderParameterStateChangeFailedErrorParameter;
	static WorkOrderParameterStateChangeSuccess: string = Translations_fi.WorkOrderParameterStateChangeSuccess;
	static WorkOrderProjectParameterStateCannotBeChangedBecauseTasksAreNotInSameState: string = Translations_fi.WorkOrderProjectParameterStateCannotBeChangedBecauseTasksAreNotInSameState;
	static WorkOrdersParameterStateChangeFailedConcurrencyError: string = Translations_fi.WorkOrdersParameterStateChangeFailedConcurrencyError;
	static WorkOrdersParameterStateChangeFailedErrorParameter: string = Translations_fi.WorkOrdersParameterStateChangeFailedErrorParameter;
	static WorkOrdersParameterStateChangeSuccess: string = Translations_fi.WorkOrdersParameterStateChangeSuccess;
	static WorkOrderTaskParameterEmployeesMustBeDefined: string = Translations_fi.WorkOrderTaskParameterEmployeesMustBeDefined;
	static WorkOrderTaskParameterEndDateMustBeDefined: string = Translations_fi.WorkOrderTaskParameterEndDateMustBeDefined;
	static WorkOrderTaskParameterHourBookingsMustBeDefined: string = Translations_fi.WorkOrderTaskParameterHourBookingsMustBeDefined;
	static WorkOrderTaskParameterRideBookingsMustBeDefined: string = Translations_fi.WorkOrderTaskParameterRideBookingsMustBeDefined;
	static WorkOrderTaskParameterStartDateMustBeDefined: string = Translations_fi.WorkOrderTaskParameterStartDateMustBeDefined;
	static Card: string = Translations_fi.Card;
	static List: string = Translations_fi.List;
	static Latitude: string = Translations_fi.Latitude;
	static Longitude: string = Translations_fi.Longitude;
	static Location: string = Translations_fi.Location;
	static WorkOrderSaveFailedRecurrenceChangeFailed: string = Translations_fi.WorkOrderSaveFailedRecurrenceChangeFailed;
	static APrint: string = Translations_fi.APrint;
	static Data: string = Translations_fi.Data;
	static NoCheckedWorkOrdersFound: string = Translations_fi.NoCheckedWorkOrdersFound;
	static Address: string = Translations_fi.Address;
	static CustomerSites: string = Translations_fi.CustomerSites;
	static SiteParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders: string = Translations_fi.SiteParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders;
	static SiteRemoveConfirmation: string = Translations_fi.SiteRemoveConfirmation;
	static SiteRemoveFailedConcurrencyError: string = Translations_fi.SiteRemoveFailedConcurrencyError;
	static SiteRemoveFailedErrorParameter: string = Translations_fi.SiteRemoveFailedErrorParameter;
	static SiteRemoveSuccess: string = Translations_fi.SiteRemoveSuccess;
	static FieldParameterValueMustBeDefined: string = Translations_fi.FieldParameterValueMustBeDefined;
	static AttachExisting: string = Translations_fi.AttachExisting;
	static ContactWithNameParameterAlreadyExists: string = Translations_fi.ContactWithNameParameterAlreadyExists;
	static StartTimeMustBeDefined: string = Translations_fi.StartTimeMustBeDefined;
	static WorkingTimeRegistration: string = Translations_fi.WorkingTimeRegistration;
	static NotTransfered: string = Translations_fi.NotTransfered;
	static OwnerCannotBeChanged: string = Translations_fi.OwnerCannotBeChanged;
	static SelectCostCenter: string = Translations_fi.SelectCostCenter;
	static SelectWorkOrder: string = Translations_fi.SelectWorkOrder;
	static TransferredToInvoicing: string = Translations_fi.TransferredToInvoicing;
	static TransferredToSalary: string = Translations_fi.TransferredToSalary;
	static WorkShiftTimeSlotHasBeenTranferredToInvoicing: string = Translations_fi.WorkShiftTimeSlotHasBeenTranferredToInvoicing;
	static WorkShiftTimeSlotHasBeenTranferredToSalaryPayment: string = Translations_fi.WorkShiftTimeSlotHasBeenTranferredToSalaryPayment;
	static WorkShiftTimeSlotParameterNotFound: string = Translations_fi.WorkShiftTimeSlotParameterNotFound;
	static WorkShiftTimeSlotStateComplete: string = Translations_fi.WorkShiftTimeSlotStateComplete;
	static WorkShiftTimeSlotStateSalary: string = Translations_fi.WorkShiftTimeSlotStateSalary;
	static DoYouReallyWantToSaveWorkShiftTimeSlot: string = Translations_fi.DoYouReallyWantToSaveWorkShiftTimeSlot;
	static SelectTaskType: string = Translations_fi.SelectTaskType;
	static Roadmap: string = Translations_fi.Roadmap;
	static Satellite: string = Translations_fi.Satellite;
	static Terrain: string = Translations_fi.Terrain;
	static WorkOrdersApproveToBeInvoicedConfirmation: string = Translations_fi.WorkOrdersApproveToBeInvoicedConfirmation;
	static CreateReport: string = Translations_fi.CreateReport;
	static InvalidRequestData: string = Translations_fi.InvalidRequestData;
	static Preview: string = Translations_fi.Preview;
	static Report: string = Translations_fi.Report;
	static ReportParameterNotFound: string = Translations_fi.ReportParameterNotFound;
	static AddCustomersBeforeAddingCustomerSites: string = Translations_fi.AddCustomersBeforeAddingCustomerSites;
	static MeasuredTimeParameterWorkTimeParameter: string = Translations_fi.MeasuredTimeParameterWorkTimeParameter;
	static ContinueWork: string = Translations_fi.ContinueWork;
	static WorkTimeMeasurement: string = Translations_fi.WorkTimeMeasurement;
	static Hide: string = Translations_fi.Hide;
	static ToBeInvoicedRecords: string = Translations_fi.ToBeInvoicedRecords;
	static WorkOrderDocumentsSaveFailedErrorParameter: string = Translations_fi.WorkOrderDocumentsSaveFailedErrorParameter;
	static WorkOrderDocumentsSaveSuccess: string = Translations_fi.WorkOrderDocumentsSaveSuccess;
	static WorkOrderRemoveDocumentsFailedErrorParameter: string = Translations_fi.WorkOrderRemoveDocumentsFailedErrorParameter;
	static WorkOrderRemoveDocumentsSuccess: string = Translations_fi.WorkOrderRemoveDocumentsSuccess;
	static AddHourBooking: string = Translations_fi.AddHourBooking;
	static AddProductBooking: string = Translations_fi.AddProductBooking;
	static AddRideBooking: string = Translations_fi.AddRideBooking;
	static DoYouWantToMoveWorkOrderStateToParameterState: string = Translations_fi.DoYouWantToMoveWorkOrderStateToParameterState;
	static WorkOrderParameterHourBookingSaveFailedConcurrencyError: string = Translations_fi.WorkOrderParameterHourBookingSaveFailedConcurrencyError;
	static WorkOrderParameterHourBookingsSaveFailedErrorParameter: string = Translations_fi.WorkOrderParameterHourBookingsSaveFailedErrorParameter;
	static WorkOrderParameterHourBookingsSaveSuccess: string = Translations_fi.WorkOrderParameterHourBookingsSaveSuccess;
	static WorkOrderParameterProductBookingSaveFailedConcurrencyError: string = Translations_fi.WorkOrderParameterProductBookingSaveFailedConcurrencyError;
	static WorkOrderParameterProductBookingsSaveFailedErrorParameter: string = Translations_fi.WorkOrderParameterProductBookingsSaveFailedErrorParameter;
	static WorkOrderParameterProductBookingsSaveSuccess: string = Translations_fi.WorkOrderParameterProductBookingsSaveSuccess;
	static WorkOrderParameterRideBookingSaveFailedConcurrencyError: string = Translations_fi.WorkOrderParameterRideBookingSaveFailedConcurrencyError;
	static WorkOrderParameterRideBookingsSaveFailedErrorParameter: string = Translations_fi.WorkOrderParameterRideBookingsSaveFailedErrorParameter;
	static WorkOrderParameterRideBookingsSaveSuccess: string = Translations_fi.WorkOrderParameterRideBookingsSaveSuccess;
	static WorkOrderRemoveHourBookingsFailedErrorParameter: string = Translations_fi.WorkOrderRemoveHourBookingsFailedErrorParameter;
	static WorkOrderRemoveHourBookingsSuccess: string = Translations_fi.WorkOrderRemoveHourBookingsSuccess;
	static WorkOrderRemoveProductBookingsFailedErrorParameter: string = Translations_fi.WorkOrderRemoveProductBookingsFailedErrorParameter;
	static WorkOrderRemoveProductBookingsSuccess: string = Translations_fi.WorkOrderRemoveProductBookingsSuccess;
	static WorkOrderRemoveRideBookingsFailedErrorParameter: string = Translations_fi.WorkOrderRemoveRideBookingsFailedErrorParameter;
	static WorkOrderRemoveRideBookingsSuccess: string = Translations_fi.WorkOrderRemoveRideBookingsSuccess;
	static ChangeState: string = Translations_fi.ChangeState;
	static RecurringWorkCannotHaveMoreThanParameterOccurences: string = Translations_fi.RecurringWorkCannotHaveMoreThanParameterOccurences;
	static Hours: string = Translations_fi.Hours;
	static CalculateHours: string = Translations_fi.CalculateHours;
	static CostCenters: string = Translations_fi.CostCenters;
	static DailyAdditionalHours: string = Translations_fi.DailyAdditionalHours;
	static EventsWorkTimeTotal: string = Translations_fi.EventsWorkTimeTotal;
	static DoYouReallyWantToCalculateHoursAndDiscardAllCurrentData: string = Translations_fi.DoYouReallyWantToCalculateHoursAndDiscardAllCurrentData;
	static DoYouReallyWantToSaveHours: string = Translations_fi.DoYouReallyWantToSaveHours;
	static HoursSaveSaveFailedErrorParameter: string = Translations_fi.HoursSaveSaveFailedErrorParameter;
	static HoursSaveSuccess: string = Translations_fi.HoursSaveSuccess;
	static EmployeeParameterCannotBeRemovedBecauseItIsRelatedToParameterHourBookings: string = Translations_fi.EmployeeParameterCannotBeRemovedBecauseItIsRelatedToParameterHourBookings;
	static EmployeeParameterCannotBeRemovedBecauseItIsRelatedToParameterSalaries: string = Translations_fi.EmployeeParameterCannotBeRemovedBecauseItIsRelatedToParameterSalaries;
	static SalaryPeriod: string = Translations_fi.SalaryPeriod;
	static SalaryType: string = Translations_fi.SalaryType;
	static Calculate: string = Translations_fi.Calculate;
	static DoYouReallyWantToCalculateSalaryAndDiscardAllCurrentData: string = Translations_fi.DoYouReallyWantToCalculateSalaryAndDiscardAllCurrentData;
	static DoYouReallyWantToSaveSalary: string = Translations_fi.DoYouReallyWantToSaveSalary;
	static SalaryParameterNotFound: string = Translations_fi.SalaryParameterNotFound;
	static SalaryRowType: string = Translations_fi.SalaryRowType;
	static SalarySaveFailedConcurrencyError: string = Translations_fi.SalarySaveFailedConcurrencyError;
	static SalarySaveSaveFailedErrorParameter: string = Translations_fi.SalarySaveSaveFailedErrorParameter;
	static SalarySaveSuccess: string = Translations_fi.SalarySaveSuccess;
	static DateMustBeDefined: string = Translations_fi.DateMustBeDefined;
	static SalaryTransfer: string = Translations_fi.SalaryTransfer;
	static Transferred: string = Translations_fi.Transferred;
	static WorkShiftTimeSlotRemoveConfirmation: string = Translations_fi.WorkShiftTimeSlotRemoveConfirmation;
	static WorkShiftTimeSlotRemoveFailedConcurrencyError: string = Translations_fi.WorkShiftTimeSlotRemoveFailedConcurrencyError;
	static WorkShiftTimeSlotRemoveFailedErrorParameter: string = Translations_fi.WorkShiftTimeSlotRemoveFailedErrorParameter;
	static WorkShiftTimeSlotRemoveSuccess: string = Translations_fi.WorkShiftTimeSlotRemoveSuccess;
	static BusinessId: string = Translations_fi.BusinessId;
	static CustomerType: string = Translations_fi.CustomerType;
	static CustomerTypesMustBeDefinedBeforeAddingCustomers: string = Translations_fi.CustomerTypesMustBeDefinedBeforeAddingCustomers;
	static ProductGroup: string = Translations_fi.ProductGroup;
	static ProductGroupsMustBeDefinedBeforeAddingProducts: string = Translations_fi.ProductGroupsMustBeDefinedBeforeAddingProducts;
	static Canceled: string = Translations_fi.Canceled;
	static CancelWorkOrder: string = Translations_fi.CancelWorkOrder;
	static EventLogMessageWorkOrderCanceled: string = Translations_fi.EventLogMessageWorkOrderCanceled;
	static EventLogMessageWorkOrderRestored: string = Translations_fi.EventLogMessageWorkOrderRestored;
	static ProjectCannotBeCanceled: string = Translations_fi.ProjectCannotBeCanceled;
	static RestoreWorkOrder: string = Translations_fi.RestoreWorkOrder;
	static WorkOrderCancelConfirmation: string = Translations_fi.WorkOrderCancelConfirmation;
	static WorkOrderCancelFailedConcurrencyError: string = Translations_fi.WorkOrderCancelFailedConcurrencyError;
	static WorkOrderCancelFailedErrorParameter: string = Translations_fi.WorkOrderCancelFailedErrorParameter;
	static WorkOrderCancelSuccess: string = Translations_fi.WorkOrderCancelSuccess;
	static WorkOrderRestoreConfirmation: string = Translations_fi.WorkOrderRestoreConfirmation;
	static WorkOrderRestoreFailedConcurrencyError: string = Translations_fi.WorkOrderRestoreFailedConcurrencyError;
	static WorkOrderRestoreFailedErrorParameter: string = Translations_fi.WorkOrderRestoreFailedErrorParameter;
	static WorkOrderRestoreSuccess: string = Translations_fi.WorkOrderRestoreSuccess;
	static Attachment: string = Translations_fi.Attachment;
	static WorkOrderDocumentSaveFailedErrorParameter: string = Translations_fi.WorkOrderDocumentSaveFailedErrorParameter;
	static WorkOrderDocumentSaveSuccess: string = Translations_fi.WorkOrderDocumentSaveSuccess;
	static MapOnId: string = Translations_fi.MapOnId;
	static VehicleLocationsSaveFailedErrorParameter: string = Translations_fi.VehicleLocationsSaveFailedErrorParameter;
	static VehicleLocationsSaveSuccess: string = Translations_fi.VehicleLocationsSaveSuccess;
	static AddToFavories: string = Translations_fi.AddToFavories;
	static Favorites: string = Translations_fi.Favorites;
	static LookUp: string = Translations_fi.LookUp;
	static RemoveFromFavories: string = Translations_fi.RemoveFromFavories;
	static SiteMapLinkUpdateFailedConcurrencyError: string = Translations_fi.SiteMapLinkUpdateFailedConcurrencyError;
	static SiteMapLinkUpdateFailedErrorParameter: string = Translations_fi.SiteMapLinkUpdateFailedErrorParameter;
	static SiteMapLinkUpdateSuccess: string = Translations_fi.SiteMapLinkUpdateSuccess;
	static SitesMapLinkUpdateFailedConcurrencyError: string = Translations_fi.SitesMapLinkUpdateFailedConcurrencyError;
	static SitesMapLinkUpdateFailedErrorParameter: string = Translations_fi.SitesMapLinkUpdateFailedErrorParameter;
	static SitesMapLinkUpdateSuccess: string = Translations_fi.SitesMapLinkUpdateSuccess;
	static SitesRemoveConfirmation: string = Translations_fi.SitesRemoveConfirmation;
	static SitesRemoveFailedConcurrencyError: string = Translations_fi.SitesRemoveFailedConcurrencyError;
	static SitesRemoveFailedErrorParameter: string = Translations_fi.SitesRemoveFailedErrorParameter;
	static SitesRemoveSuccess: string = Translations_fi.SitesRemoveSuccess;
	static CalendarAccuracyDefault: string = Translations_fi.CalendarAccuracyDefault;
	static CalendarOfficeTimeEnd: string = Translations_fi.CalendarOfficeTimeEnd;
	static CalendarOfficeTimeStart: string = Translations_fi.CalendarOfficeTimeStart;
	static ClickToAddImage: string = Translations_fi.ClickToAddImage;
	static DefaultCulture: string = Translations_fi.DefaultCulture;
	static LanguageEnglish: string = Translations_fi.LanguageEnglish;
	static LanguageFinnish: string = Translations_fi.LanguageFinnish;
	static Map: string = Translations_fi.Map;
	static OnMap: string = Translations_fi.OnMap;
	static TimeTable: string = Translations_fi.TimeTable;
	static Company: string = Translations_fi.Company;
	static CompanySettings: string = Translations_fi.CompanySettings;
	static CompetencyTypeRemoveConfirmation: string = Translations_fi.CompetencyTypeRemoveConfirmation;
	static CompetencyTypes: string = Translations_fi.CompetencyTypes;
	static CostCenterRemoveConfirmation: string = Translations_fi.CostCenterRemoveConfirmation;
	static CustomerTypeRemoveConfirmation: string = Translations_fi.CustomerTypeRemoveConfirmation;
	static CustomerTypes: string = Translations_fi.CustomerTypes;
	static DayBookingTypeRemoveConfirmation: string = Translations_fi.DayBookingTypeRemoveConfirmation;
	static DayBookingTypes: string = Translations_fi.DayBookingTypes;
	static HourBookingTypes: string = Translations_fi.HourBookingTypes;
	static Mobile: string = Translations_fi.Mobile;
	static ProductGroups: string = Translations_fi.ProductGroups;
	static SalaryRowTypes: string = Translations_fi.SalaryRowTypes;
	static SalaryTypes: string = Translations_fi.SalaryTypes;
	static SendingType: string = Translations_fi.SendingType;
	static UserGroupRemoveConfirmation: string = Translations_fi.UserGroupRemoveConfirmation;
	static UserGroups: string = Translations_fi.UserGroups;
	static VatRate: string = Translations_fi.VatRate;
	static Web: string = Translations_fi.Web;
	static WorkOrderAcknowledgementTypeRemoveConfirmation: string = Translations_fi.WorkOrderAcknowledgementTypeRemoveConfirmation;
	static WorkOrderAcknowledgementTypes: string = Translations_fi.WorkOrderAcknowledgementTypes;
	static WorkOrderAcknowledgementTypeSendingTypeDoNotSend: string = Translations_fi.WorkOrderAcknowledgementTypeSendingTypeDoNotSend;
	static WorkOrderAcknowledgementTypeSendingTypeSendAlways: string = Translations_fi.WorkOrderAcknowledgementTypeSendingTypeSendAlways;
	static WorkOrderAcknowledgementTypeSendingTypeSendManually: string = Translations_fi.WorkOrderAcknowledgementTypeSendingTypeSendManually;
	static WorkOrderTypeRemoveConfirmation: string = Translations_fi.WorkOrderTypeRemoveConfirmation;
	static LocationError: string = Translations_fi.LocationError;
	static LocationSuccess: string = Translations_fi.LocationSuccess;
	static AddRoute: string = Translations_fi.AddRoute;
	static AddRoutePoint: string = Translations_fi.AddRoutePoint;
	static EditRoute: string = Translations_fi.EditRoute;
	static HideAdditionalInformation: string = Translations_fi.HideAdditionalInformation;
	static Route: string = Translations_fi.Route;
	static ShowAdditionalInformation: string = Translations_fi.ShowAdditionalInformation;
	static RoutePointRemoveConfirmation: string = Translations_fi.RoutePointRemoveConfirmation;
	static Approve: string = Translations_fi.Approve;
	static UnresourcedStandByWorkOrders: string = Translations_fi.UnresourcedStandByWorkOrders;
	static UnscheduledStandByWorkOrders: string = Translations_fi.UnscheduledStandByWorkOrders;
	static OwnerParameterNotFound: string = Translations_fi.OwnerParameterNotFound;
	static OwnerSaveFailedConcurrencyError: string = Translations_fi.OwnerSaveFailedConcurrencyError;
	static OwnerSaveFailedErrorParameter: string = Translations_fi.OwnerSaveFailedErrorParameter;
	static OwnerSaveSuccess: string = Translations_fi.OwnerSaveSuccess;
	static Theme: string = Translations_fi.Theme;
	static DoLockAll: string = Translations_fi.DoLockAll;
	static DoUnlockAll: string = Translations_fi.DoUnlockAll;
	static Locked: string = Translations_fi.Locked;
	static WorkShiftTimeSlotsLockingFailedConcurrencyError: string = Translations_fi.WorkShiftTimeSlotsLockingFailedConcurrencyError;
	static WorkShiftTimeSlotsLockingFailedErrorParameter: string = Translations_fi.WorkShiftTimeSlotsLockingFailedErrorParameter;
	static WorkShiftTimeSlotsLockingSuccess: string = Translations_fi.WorkShiftTimeSlotsLockingSuccess;
	static WorkShiftTimeSlotStateInProgressCannotBeLocked: string = Translations_fi.WorkShiftTimeSlotStateInProgressCannotBeLocked;
	static WorkShiftTimeSlotStateLocked: string = Translations_fi.WorkShiftTimeSlotStateLocked;
	static WorkShiftTimeSlotStateLockedCannotBeChanged: string = Translations_fi.WorkShiftTimeSlotStateLockedCannotBeChanged;
	static WorkShiftTimeSlotStateLockedCannotBeRemoved: string = Translations_fi.WorkShiftTimeSlotStateLockedCannotBeRemoved;
	static WorkShiftTimeSlotsUnlockingFailedConcurrencyError: string = Translations_fi.WorkShiftTimeSlotsUnlockingFailedConcurrencyError;
	static WorkShiftTimeSlotsUnlockingFailedErrorParameter: string = Translations_fi.WorkShiftTimeSlotsUnlockingFailedErrorParameter;
	static WorkShiftTimeSlotsUnlockingSuccess: string = Translations_fi.WorkShiftTimeSlotsUnlockingSuccess;
	static CalculatedH: string = Translations_fi.CalculatedH;
	static InvoiceH: string = Translations_fi.InvoiceH;
	static Invoicing: string = Translations_fi.Invoicing;
	static Measured: string = Translations_fi.Measured;
	static FormContainsUnapprovedValues: string = Translations_fi.FormContainsUnapprovedValues;
	static Kind: string = Translations_fi.Kind;
	static PeriodAdditionalHours: string = Translations_fi.PeriodAdditionalHours;
	static WorkHours: string = Translations_fi.WorkHours;
	static SalaryPeriodParameterNotFound: string = Translations_fi.SalaryPeriodParameterNotFound;
	static WorkTimePeriodParameterNotFound: string = Translations_fi.WorkTimePeriodParameterNotFound;
	static SalaryPeriodRemoveConfirmation: string = Translations_fi.SalaryPeriodRemoveConfirmation;
	static SalaryPeriods: string = Translations_fi.SalaryPeriods;
	static WorkTimePeriod: string = Translations_fi.WorkTimePeriod;
	static WorkTimePeriods: string = Translations_fi.WorkTimePeriods;
	static CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterHourBookings: string = Translations_fi.CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterHourBookings;
	static CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkShiftTimeSlots: string = Translations_fi.CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkShiftTimeSlots;
	static CostCenterParameterNotFound: string = Translations_fi.CostCenterParameterNotFound;
	static CostCenterRemoveFailedConcurrencyError: string = Translations_fi.CostCenterRemoveFailedConcurrencyError;
	static CostCenterRemoveFailedErrorParameter: string = Translations_fi.CostCenterRemoveFailedErrorParameter;
	static CostCenterRemoveSuccess: string = Translations_fi.CostCenterRemoveSuccess;
	static CostCenterSaveFailedConcurrencyError: string = Translations_fi.CostCenterSaveFailedConcurrencyError;
	static CostCenterSaveFailedErrorParameter: string = Translations_fi.CostCenterSaveFailedErrorParameter;
	static CostCenterSaveSuccess: string = Translations_fi.CostCenterSaveSuccess;
	static EmployeeGroupRemoveConfirmation: string = Translations_fi.EmployeeGroupRemoveConfirmation;
	static EmployeeGroups: string = Translations_fi.EmployeeGroups;
	static EmployeeGroupsHaveNotBeenSpecified: string = Translations_fi.EmployeeGroupsHaveNotBeenSpecified;
	static SalaryTypesHaveNotBeenSpecified: string = Translations_fi.SalaryTypesHaveNotBeenSpecified;
	static UserGroupsHaveNotBeenSpecified: string = Translations_fi.UserGroupsHaveNotBeenSpecified;
	static VehicleGroup: string = Translations_fi.VehicleGroup;
	static VehicleGroupRemoveConfirmation: string = Translations_fi.VehicleGroupRemoveConfirmation;
	static VehicleGroups: string = Translations_fi.VehicleGroups;
	static VehicleGroupsHaveNotBeenSpecified: string = Translations_fi.VehicleGroupsHaveNotBeenSpecified;
	static TokenHasBeenExpired: string = Translations_fi.TokenHasBeenExpired;
	static WorkOrderDurationIsZero: string = Translations_fi.WorkOrderDurationIsZero;
	static WorkOrderParameterDurationIsZero: string = Translations_fi.WorkOrderParameterDurationIsZero;
	static WorkOrderTaskParameterDurationIsZero: string = Translations_fi.WorkOrderTaskParameterDurationIsZero;
	static InvalidEndTime: string = Translations_fi.InvalidEndTime;
	static RemoveTask: string = Translations_fi.RemoveTask;
	static RemoveTasks: string = Translations_fi.RemoveTasks;
	static WorkOrderProjectRecurringParameterCannotBeRemovedBecauseOtherWorkOrderTasksAreBelongingToIt: string = Translations_fi.WorkOrderProjectRecurringParameterCannotBeRemovedBecauseOtherWorkOrderTasksAreBelongingToIt;
	static WorkOrdersDetachConfirmation: string = Translations_fi.WorkOrdersDetachConfirmation;
	static WorkOrdersDetachConfirmationRecurring: string = Translations_fi.WorkOrdersDetachConfirmationRecurring;
	static WorkShiftStartedParameter: string = Translations_fi.WorkShiftStartedParameter;
	static AddRecurringTasks: string = Translations_fi.AddRecurringTasks;
	static DoYouReallyWantToAddRecurringTasks: string = Translations_fi.DoYouReallyWantToAddRecurringTasks;
	static AddExistingWorkOrderWork: string = Translations_fi.AddExistingWorkOrderWork;
	static AddTask: string = Translations_fi.AddTask;
	static AttachWorkToRecurringWork: string = Translations_fi.AttachWorkToRecurringWork;
	static DoYouReallyWantToChangeWorkOrders: string = Translations_fi.DoYouReallyWantToChangeWorkOrders;
	static DurationOfRecurringTaskCannotBeZero: string = Translations_fi.DurationOfRecurringTaskCannotBeZero;
	static DurationOfRecurringWorkCannotBeZero: string = Translations_fi.DurationOfRecurringWorkCannotBeZero;
	static Set: string = Translations_fi.Set;
	static SetEmployees: string = Translations_fi.SetEmployees;
	static SetState: string = Translations_fi.SetState;
	static SetVehicles: string = Translations_fi.SetVehicles;
	static ValuesCopiedFromRecurringWork: string = Translations_fi.ValuesCopiedFromRecurringWork;
	static ValuesCopiedFromWorkOrderTask: string = Translations_fi.ValuesCopiedFromWorkOrderTask;
	static WorkOrderAddRecurringTasksFailedConcurrencyError: string = Translations_fi.WorkOrderAddRecurringTasksFailedConcurrencyError;
	static WorkOrderAddRecurringTasksFailedErrorParameter: string = Translations_fi.WorkOrderAddRecurringTasksFailedErrorParameter;
	static WorkOrderAddRecurringTasksSuccess: string = Translations_fi.WorkOrderAddRecurringTasksSuccess;
	static WorkOrderParameterEmployeesChangeFailedConcurrencyError: string = Translations_fi.WorkOrderParameterEmployeesChangeFailedConcurrencyError;
	static WorkOrderParameterEmployeesChangeFailedErrorParameter: string = Translations_fi.WorkOrderParameterEmployeesChangeFailedErrorParameter;
	static WorkOrderParameterEmployeesChangeSuccess: string = Translations_fi.WorkOrderParameterEmployeesChangeSuccess;
	static WorkOrderParameterVehiclesChangeFailedConcurrencyError: string = Translations_fi.WorkOrderParameterVehiclesChangeFailedConcurrencyError;
	static WorkOrderParameterVehiclesChangeFailedErrorParameter: string = Translations_fi.WorkOrderParameterVehiclesChangeFailedErrorParameter;
	static WorkOrderParameterVehiclesChangeSuccess: string = Translations_fi.WorkOrderParameterVehiclesChangeSuccess;
	static WorkOrdersParameterEmployeesChangeFailedConcurrencyError: string = Translations_fi.WorkOrdersParameterEmployeesChangeFailedConcurrencyError;
	static WorkOrdersParameterEmployeesChangeFailedErrorParameter: string = Translations_fi.WorkOrdersParameterEmployeesChangeFailedErrorParameter;
	static WorkOrdersParameterEmployeesChangeSuccess: string = Translations_fi.WorkOrdersParameterEmployeesChangeSuccess;
	static WorkOrdersParameterVehclesChangeFailedErrorParameter: string = Translations_fi.WorkOrdersParameterVehclesChangeFailedErrorParameter;
	static WorkOrdersParameterVehiclesChangeFailedConcurrencyError: string = Translations_fi.WorkOrdersParameterVehiclesChangeFailedConcurrencyError;
	static WorkOrdersParameterVehiclesChangeSuccess: string = Translations_fi.WorkOrdersParameterVehiclesChangeSuccess;
	static IntegrationParameterSaveFailed: string = Translations_fi.IntegrationParameterSaveFailed;
	static IntegrationParameterSaveSuccess: string = Translations_fi.IntegrationParameterSaveSuccess;
	static WorkOrderParameterRoutePointSaveFailedConcurrencyError: string = Translations_fi.WorkOrderParameterRoutePointSaveFailedConcurrencyError;
	static WorkOrderParameterRoutePointsSaveFailedErrorParameter: string = Translations_fi.WorkOrderParameterRoutePointsSaveFailedErrorParameter;
	static WorkOrderParameterRoutePointsSaveSuccess: string = Translations_fi.WorkOrderParameterRoutePointsSaveSuccess;
	static WorkOrderRemoveRoutePointsFailedErrorParameter: string = Translations_fi.WorkOrderRemoveRoutePointsFailedErrorParameter;
	static WorkOrderRemoveRoutePointsSuccess: string = Translations_fi.WorkOrderRemoveRoutePointsSuccess;
	static CreateSalaryFileDataFailedErrorParameter: string = Translations_fi.CreateSalaryFileDataFailedErrorParameter;
	static CreateSalaryFileDataSuccess: string = Translations_fi.CreateSalaryFileDataSuccess;
	static EventLogMessageSalaryFileCreated: string = Translations_fi.EventLogMessageSalaryFileCreated;
	static SalaryFile: string = Translations_fi.SalaryFile;
	static BusinessIdentityCode: string = Translations_fi.BusinessIdentityCode;
	static EInvoiceAddress: string = Translations_fi.EInvoiceAddress;
	static EInvoiceOperator: string = Translations_fi.EInvoiceOperator;
	static InvoiceEmail: string = Translations_fi.InvoiceEmail;
	static SiteTypePoint: string = Translations_fi.SiteTypePoint;
	static SiteTypeRoute: string = Translations_fi.SiteTypeRoute;
	static VatCode: string = Translations_fi.VatCode;
	static WeekHours: string = Translations_fi.WeekHours;
	static WorkTimeTotal: string = Translations_fi.WorkTimeTotal;
	static WorkShiftTimeSlot: string = Translations_fi.WorkShiftTimeSlot;
	static WorkShiftTimeSlotTypeParameterNotFound: string = Translations_fi.WorkShiftTimeSlotTypeParameterNotFound;
	static WorkShiftTimeSlotTypesHaveNotBeenSpecified: string = Translations_fi.WorkShiftTimeSlotTypesHaveNotBeenSpecified;
	static AllRoutePointsHaveBeenHandled: string = Translations_fi.AllRoutePointsHaveBeenHandled;
	static RoutePointWorkShiftTimeSlotEndTime: string = Translations_fi.RoutePointWorkShiftTimeSlotEndTime;
	static RoutePointWorkShiftTimeSlotEndTimeSet: string = Translations_fi.RoutePointWorkShiftTimeSlotEndTimeSet;
	static RoutePointWorkShiftTimeSlotStartTime: string = Translations_fi.RoutePointWorkShiftTimeSlotStartTime;
	static SavingIsNotFinished: string = Translations_fi.SavingIsNotFinished;
	static WorkOrderParameterRoutePointParameterHasBookingsAndCannotBeRemoved: string = Translations_fi.WorkOrderParameterRoutePointParameterHasBookingsAndCannotBeRemoved;
	static ParameterTargets: string = Translations_fi.ParameterTargets;
	static SiteTypeNoLocation: string = Translations_fi.SiteTypeNoLocation;
	static Integrations: string = Translations_fi.Integrations;
	static VehiclesVisibleOnMap: string = Translations_fi.VehiclesVisibleOnMap;
	static Abbreviation: string = Translations_fi.Abbreviation;
	static Telematics: string = Translations_fi.Telematics;
	static SiteCannotBeChangedWhenRoutePointBookingsAreDefined: string = Translations_fi.SiteCannotBeChangedWhenRoutePointBookingsAreDefined;
	static WorkShiftTimeSlotDurationCannotBeExtended: string = Translations_fi.WorkShiftTimeSlotDurationCannotBeExtended;
	static CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterEmployees: string = Translations_fi.CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterEmployees;
	static CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterVehicles: string = Translations_fi.CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterVehicles;
	static EmployeeGroupParameterCannotBeRemovedBecauseItIsRelatedToParameterEmployees: string = Translations_fi.EmployeeGroupParameterCannotBeRemovedBecauseItIsRelatedToParameterEmployees;
	static EmployeeGroupParameterNotFound: string = Translations_fi.EmployeeGroupParameterNotFound;
	static EmployeeGroupRemoveFailedConcurrencyError: string = Translations_fi.EmployeeGroupRemoveFailedConcurrencyError;
	static EmployeeGroupRemoveFailedErrorParameter: string = Translations_fi.EmployeeGroupRemoveFailedErrorParameter;
	static EmployeeGroupRemoveSuccess: string = Translations_fi.EmployeeGroupRemoveSuccess;
	static EmployeeGroupSaveFailedConcurrencyError: string = Translations_fi.EmployeeGroupSaveFailedConcurrencyError;
	static EmployeeGroupSaveFailedErrorParameter: string = Translations_fi.EmployeeGroupSaveFailedErrorParameter;
	static EmployeeGroupSaveSuccess: string = Translations_fi.EmployeeGroupSaveSuccess;
	static DurationMustBeDefined: string = Translations_fi.DurationMustBeDefined;
	static Employment: string = Translations_fi.Employment;
	static AddDayBooking: string = Translations_fi.AddDayBooking;
	static AddWorkShiftTimeSlot: string = Translations_fi.AddWorkShiftTimeSlot;
	static CostCentersHaveNotBeenSpecified: string = Translations_fi.CostCentersHaveNotBeenSpecified;
	static DayBookingRemoveConfirmation: string = Translations_fi.DayBookingRemoveConfirmation;
	static DayBookingRemoveFailedErrorParameter: string = Translations_fi.DayBookingRemoveFailedErrorParameter;
	static DayBookingRemoveSuccess: string = Translations_fi.DayBookingRemoveSuccess;
	static DayBookingSaveFailedConcurrencyError: string = Translations_fi.DayBookingSaveFailedConcurrencyError;
	static DayBookingSaveSuccess: string = Translations_fi.DayBookingSaveSuccess;
	static DayBookingsRemoveFailedErrorParameter: string = Translations_fi.DayBookingsRemoveFailedErrorParameter;
	static DayBookingsRemoveSuccess: string = Translations_fi.DayBookingsRemoveSuccess;
	static DayBookingsSaveFailedConcurrencyError: string = Translations_fi.DayBookingsSaveFailedConcurrencyError;
	static DayBookingsSaveFailedErrorParameter: string = Translations_fi.DayBookingsSaveFailedErrorParameter;
	static DayBookingsSaveSuccess: string = Translations_fi.DayBookingsSaveSuccess;
	static DetachTasks: string = Translations_fi.DetachTasks;
	static EmployeeIsNotInUse: string = Translations_fi.EmployeeIsNotInUse;
	static EventLogWorkShiftTimeSlotChanged: string = Translations_fi.EventLogWorkShiftTimeSlotChanged;
	static InvalidPassword: string = Translations_fi.InvalidPassword;
	static RefreshTokenIsEmpty: string = Translations_fi.RefreshTokenIsEmpty;
	static RefreshTokenValidationFailed: string = Translations_fi.RefreshTokenValidationFailed;
	static SigningIn: string = Translations_fi.SigningIn;
	static SigningOut: string = Translations_fi.SigningOut;
	static SignInRefresh: string = Translations_fi.SignInRefresh;
	static OrginalTimeParameter: string = Translations_fi.OrginalTimeParameter;
	static InvoiceHoursAreNotCloseToReportedHours: string = Translations_fi.InvoiceHoursAreNotCloseToReportedHours;
	static CodeMustBeDefined: string = Translations_fi.CodeMustBeDefined;
	static CustomerTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterCustomers: string = Translations_fi.CustomerTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterCustomers;
	static CustomerTypeParameterNotFound: string = Translations_fi.CustomerTypeParameterNotFound;
	static CustomerTypeRemoveFailedConcurrencyError: string = Translations_fi.CustomerTypeRemoveFailedConcurrencyError;
	static CustomerTypeRemoveFailedErrorParameter: string = Translations_fi.CustomerTypeRemoveFailedErrorParameter;
	static CustomerTypeRemoveSuccess: string = Translations_fi.CustomerTypeRemoveSuccess;
	static CustomerTypeSaveFailedConcurrencyError: string = Translations_fi.CustomerTypeSaveFailedConcurrencyError;
	static CustomerTypeSaveFailedErrorParameter: string = Translations_fi.CustomerTypeSaveFailedErrorParameter;
	static CustomerTypeSaveSuccess: string = Translations_fi.CustomerTypeSaveSuccess;
	static ProductGroupParameterCannotBeRemovedBecauseItIsRelatedToParameterProducts: string = Translations_fi.ProductGroupParameterCannotBeRemovedBecauseItIsRelatedToParameterProducts;
	static ProductGroupParameterNotFound: string = Translations_fi.ProductGroupParameterNotFound;
	static ProductGroupRemoveConfirmation: string = Translations_fi.ProductGroupRemoveConfirmation;
	static ProductGroupRemoveFailedConcurrencyError: string = Translations_fi.ProductGroupRemoveFailedConcurrencyError;
	static ProductGroupRemoveFailedErrorParameter: string = Translations_fi.ProductGroupRemoveFailedErrorParameter;
	static ProductGroupRemoveSuccess: string = Translations_fi.ProductGroupRemoveSuccess;
	static ProductGroupSaveFailedConcurrencyError: string = Translations_fi.ProductGroupSaveFailedConcurrencyError;
	static ProductGroupSaveFailedErrorParameter: string = Translations_fi.ProductGroupSaveFailedErrorParameter;
	static ProductGroupSaveSuccess: string = Translations_fi.ProductGroupSaveSuccess;
	static VehicleGroupParameterCannotBeRemovedBecauseItIsRelatedToParameterVehicles: string = Translations_fi.VehicleGroupParameterCannotBeRemovedBecauseItIsRelatedToParameterVehicles;
	static VehicleGroupParameterNotFound: string = Translations_fi.VehicleGroupParameterNotFound;
	static VehicleGroupRemoveFailedConcurrencyError: string = Translations_fi.VehicleGroupRemoveFailedConcurrencyError;
	static VehicleGroupRemoveFailedErrorParameter: string = Translations_fi.VehicleGroupRemoveFailedErrorParameter;
	static VehicleGroupRemoveSuccess: string = Translations_fi.VehicleGroupRemoveSuccess;
	static VehicleGroupSaveFailedConcurrencyError: string = Translations_fi.VehicleGroupSaveFailedConcurrencyError;
	static VehicleGroupSaveFailedErrorParameter: string = Translations_fi.VehicleGroupSaveFailedErrorParameter;
	static VehicleGroupSaveSuccess: string = Translations_fi.VehicleGroupSaveSuccess;
	static FilterWorkOrders: string = Translations_fi.FilterWorkOrders;
	static ClearFilters: string = Translations_fi.ClearFilters;
	static MyWorkList: string = Translations_fi.MyWorkList;
	static SelectedDay: string = Translations_fi.SelectedDay;
	static SelectedPeriod: string = Translations_fi.SelectedPeriod;
	static TimeFilter: string = Translations_fi.TimeFilter;
	static IntegrationServiceCallFailedErrorParameter: string = Translations_fi.IntegrationServiceCallFailedErrorParameter;
	static EmployeeParametersSavedSuccessfully: string = Translations_fi.EmployeeParametersSavedSuccessfully;
	static EmployeeParametersSaveFailedConcurrencyError: string = Translations_fi.EmployeeParametersSaveFailedConcurrencyError;
	static EmployeeParameterSSaveFailedErrorParameter: string = Translations_fi.EmployeeParameterSSaveFailedErrorParameter;
	static ReadyWorkOrders: string = Translations_fi.ReadyWorkOrders;
	static ToBeInvoicedWorkOrders: string = Translations_fi.ToBeInvoicedWorkOrders;
	static PreliminaryInvoices: string = Translations_fi.PreliminaryInvoices;
	static TransferredInvoices: string = Translations_fi.TransferredInvoices;
	static SsoIntegrations: string = Translations_fi.SsoIntegrations;
	static SsoParameters: string = Translations_fi.SsoParameters;
	static EmployeeMustBeDefined: string = Translations_fi.EmployeeMustBeDefined;
	static MeasureUnitTn: string = Translations_fi.MeasureUnitTn;
	static ExportToInvoicingSystem: string = Translations_fi.ExportToInvoicingSystem;
	static ExportToTransferFile: string = Translations_fi.ExportToTransferFile;
	static FileExportedToInvoicingSystem: string = Translations_fi.FileExportedToInvoicingSystem;
	static GoToWorkOrderArchive: string = Translations_fi.GoToWorkOrderArchive;
	static OverlappingWorkShiftTimeSlots: string = Translations_fi.OverlappingWorkShiftTimeSlots;
	static ShowSelectedCostCenters: string = Translations_fi.ShowSelectedCostCenters;
	static ShowSelectedVehicles: string = Translations_fi.ShowSelectedVehicles;
	static CustomerPriceNotFound: string = Translations_fi.CustomerPriceNotFound;
	static CustomerPriceRemoveFailedConcurrencyError: string = Translations_fi.CustomerPriceRemoveFailedConcurrencyError;
	static CustomerPriceRemoveFailedError: string = Translations_fi.CustomerPriceRemoveFailedError;
	static CustomerPriceRemoveSuccess: string = Translations_fi.CustomerPriceRemoveSuccess;
	static CustomerPriceSaveFailedConcurrencyError: string = Translations_fi.CustomerPriceSaveFailedConcurrencyError;
	static CustomerPriceSaveFailedError: string = Translations_fi.CustomerPriceSaveFailedError;
	static CustomerPriceSaveSuccess: string = Translations_fi.CustomerPriceSaveSuccess;
	static CustomerPriceRemoveConfirmation: string = Translations_fi.CustomerPriceRemoveConfirmation;
	static CustomerPrices: string = Translations_fi.CustomerPrices;
	static CustomerPrice: string = Translations_fi.CustomerPrice;
	static APIInvoiceSuccessfullyCreated: string = Translations_fi.APIInvoiceSuccessfullyCreated;
	static InvoicesRemoveConfirmation: string = Translations_fi.InvoicesRemoveConfirmation;
	static InvoicesRemoveFailedConcurrencyError: string = Translations_fi.InvoicesRemoveFailedConcurrencyError;
	static InvoicesRemoveFailedErrorParameter: string = Translations_fi.InvoicesRemoveFailedErrorParameter;
	static InvoicesRemoveSuccess: string = Translations_fi.InvoicesRemoveSuccess;
	static RelatedToInvoice: string = Translations_fi.RelatedToInvoice;
	static AllWorkOrderClasses: string = Translations_fi.AllWorkOrderClasses;
	static WorkOrderClass: string = Translations_fi.WorkOrderClass;
	static WorkOrderProjectTask: string = Translations_fi.WorkOrderProjectTask;
	static WorkOrderRecurringTask: string = Translations_fi.WorkOrderRecurringTask;
	static InvoiceReturnToPreliminaryConfirmation: string = Translations_fi.InvoiceReturnToPreliminaryConfirmation;
	static InvoicesReturnToPreliminaryConfirmation: string = Translations_fi.InvoicesReturnToPreliminaryConfirmation;
	static ReturnToPreliminaryState: string = Translations_fi.ReturnToPreliminaryState;
	static WorkOrderDistanceInvoicingByActualDistance: string = Translations_fi.WorkOrderDistanceInvoicingByActualDistance;
	static WorkOrderDistanceInvoicingByPlannedDistance: string = Translations_fi.WorkOrderDistanceInvoicingByPlannedDistance;
	static WorkOrderDistanceInvoicingNone: string = Translations_fi.WorkOrderDistanceInvoicingNone;
	static UserGroupPermissionAdmin: string = Translations_fi.UserGroupPermissionAdmin;
	static UserGroupPermissionEdit: string = Translations_fi.UserGroupPermissionEdit;
	static UserGroupPermissionNone: string = Translations_fi.UserGroupPermissionNone;
	static EstimatedHours: string = Translations_fi.EstimatedHours;
	static DetachFromRecurringWork: string = Translations_fi.DetachFromRecurringWork;
	static EstimatedHoursMustBeDefined: string = Translations_fi.EstimatedHoursMustBeDefined;
	static SetDurationByEstimatedHours: string = Translations_fi.SetDurationByEstimatedHours;
	static WorkOrderParameterDurationChangeFailedConcurrencyError: string = Translations_fi.WorkOrderParameterDurationChangeFailedConcurrencyError;
	static WorkOrderParameterDurationChangeFailedErrorParameter: string = Translations_fi.WorkOrderParameterDurationChangeFailedErrorParameter;
	static WorkOrderParameterDurationChangeSuccess: string = Translations_fi.WorkOrderParameterDurationChangeSuccess;
	static WorkOrderParameterEstimatedHoursMustBeDefined: string = Translations_fi.WorkOrderParameterEstimatedHoursMustBeDefined;
	static WorkOrdersParameterDurationChangeFailedConcurrencyError: string = Translations_fi.WorkOrdersParameterDurationChangeFailedConcurrencyError;
	static WorkOrdersParameterDurationChangeFailedErrorParameter: string = Translations_fi.WorkOrdersParameterDurationChangeFailedErrorParameter;
	static WorkOrdersParameterDurationChangeSuccess: string = Translations_fi.WorkOrdersParameterDurationChangeSuccess;
	static WorkOrderTaskParameterEstimatedHoursMustBeDefined: string = Translations_fi.WorkOrderTaskParameterEstimatedHoursMustBeDefined;
	static And: string = Translations_fi.And;
	static Month: string = Translations_fi.Month;
	static Occurs: string = Translations_fi.Occurs;
	static Year: string = Translations_fi.Year;
	static NewProject: string = Translations_fi.NewProject;
	static DayBookingTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterDayBookings: string = Translations_fi.DayBookingTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterDayBookings;
	static DayBookingTypeParameterNotFound: string = Translations_fi.DayBookingTypeParameterNotFound;
	static DayBookingTypeParameterParameterSaveFailedErrorParameter: string = Translations_fi.DayBookingTypeParameterParameterSaveFailedErrorParameter;
	static DayBookingTypeParameterRemoveFailedConcurrencyError: string = Translations_fi.DayBookingTypeParameterRemoveFailedConcurrencyError;
	static DayBookingTypeParameterRemoveFailedErrorParameter: string = Translations_fi.DayBookingTypeParameterRemoveFailedErrorParameter;
	static DayBookingTypeParameterRemoveSuccess: string = Translations_fi.DayBookingTypeParameterRemoveSuccess;
	static DayBookingTypeParameterSaveFailedConcurrencyError: string = Translations_fi.DayBookingTypeParameterSaveFailedConcurrencyError;
	static DayBookingTypeParameterSaveSuccess: string = Translations_fi.DayBookingTypeParameterSaveSuccess;
	static DayBookingTypesParameterRemoveFailedConcurrencyError: string = Translations_fi.DayBookingTypesParameterRemoveFailedConcurrencyError;
	static DayBookingTypesParameterRemoveFailedErrorParameter: string = Translations_fi.DayBookingTypesParameterRemoveFailedErrorParameter;
	static DayBookingTypesParameterRemoveSuccess: string = Translations_fi.DayBookingTypesParameterRemoveSuccess;
	static RoutingFailedFailedErrorParameter: string = Translations_fi.RoutingFailedFailedErrorParameter;
	static GeneralSettings: string = Translations_fi.GeneralSettings;
	static UserProfile: string = Translations_fi.UserProfile;
	static SetLocationFromMap: string = Translations_fi.SetLocationFromMap;
	static CopyRecurringWork: string = Translations_fi.CopyRecurringWork;
	static CopyWorkOrderProject: string = Translations_fi.CopyWorkOrderProject;
	static CopyWorkOrderTask: string = Translations_fi.CopyWorkOrderTask;
	static CopyWorkOrderWork: string = Translations_fi.CopyWorkOrderWork;
	static DataToBeCopied: string = Translations_fi.DataToBeCopied;
	static WorkOrderCopyFailedErrorParameter: string = Translations_fi.WorkOrderCopyFailedErrorParameter;
	static WorkOrderCopySuccess: string = Translations_fi.WorkOrderCopySuccess;
	static EmployeeGroupsVisibleOnDesign: string = Translations_fi.EmployeeGroupsVisibleOnDesign;
	static VehicleGroupsVisibleOnDesign: string = Translations_fi.VehicleGroupsVisibleOnDesign;
	static MoveToState: string = Translations_fi.MoveToState;
	static CustomerPriceParameterNotFound: string = Translations_fi.CustomerPriceParameterNotFound;
	static WorkOrderAttachmentToInvoice: string = Translations_fi.WorkOrderAttachmentToInvoice;
	static DayView: string = Translations_fi.DayView;
	static WeekView: string = Translations_fi.WeekView;
	static WeekViewNoaccordions: string = Translations_fi.WeekViewNoaccordions;
	static ShowOrdersAs: string = Translations_fi.ShowOrdersAs;
	static VatRateMustBeDefined: string = Translations_fi.VatRateMustBeDefined;
	static EmployeeUsageType: string = Translations_fi.EmployeeUsageType;
	static EmployeeUsageTypeMultiple: string = Translations_fi.EmployeeUsageTypeMultiple;
	static EmployeeUsageTypeOne: string = Translations_fi.EmployeeUsageTypeOne;
	static HoursInvoicingType: string = Translations_fi.HoursInvoicingType;
	static HoursInvoicingTypePlanned: string = Translations_fi.HoursInvoicingTypePlanned;
	static HoursInvoicingTypeRealized: string = Translations_fi.HoursInvoicingTypeRealized;
	static LocationUsageType: string = Translations_fi.LocationUsageType;
	static AttachmentsAreVisible: string = Translations_fi.AttachmentsAreVisible;
	static CommentIsVisible: string = Translations_fi.CommentIsVisible;
	static DescriptionIsVisible: string = Translations_fi.DescriptionIsVisible;
	static EstimatedHoursAreVisible: string = Translations_fi.EstimatedHoursAreVisible;
	static MapIsVisible: string = Translations_fi.MapIsVisible;
	static ToBeInvoicedRecordsAreVisible: string = Translations_fi.ToBeInvoicedRecordsAreVisible;
	static SiteRoutePointUsageType: string = Translations_fi.SiteRoutePointUsageType;
	static SiteRoutePointUsageTypeRoutePoint: string = Translations_fi.SiteRoutePointUsageTypeRoutePoint;
	static SiteRoutePointUsageTypeSite: string = Translations_fi.SiteRoutePointUsageTypeSite;
	static VehicleUsageType: string = Translations_fi.VehicleUsageType;
	static VehicleUsageTypeMultiple: string = Translations_fi.VehicleUsageTypeMultiple;
	static VehicleUsageTypeOne: string = Translations_fi.VehicleUsageTypeOne;
	static RoutePointTypes: string = Translations_fi.RoutePointTypes;
	static WorkOrderAcknowledgementTypeIsVisible: string = Translations_fi.WorkOrderAcknowledgementTypeIsVisible;
	static WorkOrderTypeParameterNotFound: string = Translations_fi.WorkOrderTypeParameterNotFound;
	static RoutePointWorkShiftTimeSlotType: string = Translations_fi.RoutePointWorkShiftTimeSlotType;
	static RoutePointWorkShiftTimeSlotTypes: string = Translations_fi.RoutePointWorkShiftTimeSlotTypes;
	static WorkOrderTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders: string = Translations_fi.WorkOrderTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders;
	static WorkOrderTypeParameterParameterSaveFailedErrorParameter: string = Translations_fi.WorkOrderTypeParameterParameterSaveFailedErrorParameter;
	static WorkOrderTypeParameterRemoveFailedConcurrencyError: string = Translations_fi.WorkOrderTypeParameterRemoveFailedConcurrencyError;
	static WorkOrderTypeParameterRemoveFailedErrorParameter: string = Translations_fi.WorkOrderTypeParameterRemoveFailedErrorParameter;
	static WorkOrderTypeParameterRemoveSuccess: string = Translations_fi.WorkOrderTypeParameterRemoveSuccess;
	static WorkOrderTypeParameterSaveFailedConcurrencyError: string = Translations_fi.WorkOrderTypeParameterSaveFailedConcurrencyError;
	static WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesRemoveFailedErrorParameter: string = Translations_fi.WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesRemoveFailedErrorParameter;
	static WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesRemoveSuccess: string = Translations_fi.WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesRemoveSuccess;
	static WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesSaveFailedErrorParameter: string = Translations_fi.WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesSaveFailedErrorParameter;
	static WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesSaveSuccess: string = Translations_fi.WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesSaveSuccess;
	static WorkOrderTypeParameterWorkShiftTimeSlotTypeSaveFailedConcurrencyError: string = Translations_fi.WorkOrderTypeParameterWorkShiftTimeSlotTypeSaveFailedConcurrencyError;
	static WorkOrderTypesParameterRemoveFailedConcurrencyError: string = Translations_fi.WorkOrderTypesParameterRemoveFailedConcurrencyError;
	static WorkOrderTypesParameterRemoveFailedErrorParameter: string = Translations_fi.WorkOrderTypesParameterRemoveFailedErrorParameter;
	static WorkOrderTypesParameterRemoveSuccess: string = Translations_fi.WorkOrderTypesParameterRemoveSuccess;
	static WorkOrderTypeTypeParameterSaveSuccess: string = Translations_fi.WorkOrderTypeTypeParameterSaveSuccess;
	static WorkOrderTypeWorkShiftTimeSlotTypeParameterNotFound: string = Translations_fi.WorkOrderTypeWorkShiftTimeSlotTypeParameterNotFound;
	static WorkOrderTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterInvoices: string = Translations_fi.WorkOrderTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterInvoices;
	static ShowFilteredWorkOrders: string = Translations_fi.ShowFilteredWorkOrders;
	static NoWorkOrders: string = Translations_fi.NoWorkOrders;
	static WorkTimeAccountTransactionSaveSuccess: string = Translations_fi.WorkTimeAccountTransactionSaveSuccess;
	static WorkTimeAccountTransactionSaveFailed: string = Translations_fi.WorkTimeAccountTransactionSaveFailed;
	static AddWorkHoursToBank: string = Translations_fi.AddWorkHoursToBank;
	static TakeWorkHoursFromBank: string = Translations_fi.TakeWorkHoursFromBank;
	static TransferWorkHours: string = Translations_fi.TransferWorkHours;
	static WorkHourBalance: string = Translations_fi.WorkHourBalance;
	static WorkhourBank: string = Translations_fi.WorkhourBank;
	static AddWorkOrdersToInvoice: string = Translations_fi.AddWorkOrdersToInvoice;
	static CombineWorkOrdersByWorkOrderTypesToSameInvoice: string = Translations_fi.CombineWorkOrdersByWorkOrderTypesToSameInvoice;
	static InvoiceAddWorkOrdersDifferentWorkOrderTypeConfirmation: string = Translations_fi.InvoiceAddWorkOrdersDifferentWorkOrderTypeConfirmation;
	static InvoiceDataFromWorkOrderType: string = Translations_fi.InvoiceDataFromWorkOrderType;
	static InvoiceParameterParameterSaveFailedErrorParameter: string = Translations_fi.InvoiceParameterParameterSaveFailedErrorParameter;
	static InvoiceParameterSaveFailedConcurrencyError: string = Translations_fi.InvoiceParameterSaveFailedConcurrencyError;
	static InvoiceParameterSaveSuccess: string = Translations_fi.InvoiceParameterSaveSuccess;
	static MenuStorage: string = Translations_fi.MenuStorage;
	static StorageLocations: string = Translations_fi.StorageLocations;
	static StorageReport: string = Translations_fi.StorageReport;
	static Storages: string = Translations_fi.Storages;
	static Storage: string = Translations_fi.Storage;
	static StorageLocation: string = Translations_fi.StorageLocation;
	static StorageLocationShort: string = Translations_fi.StorageLocationShort;
	static StorageProduct: string = Translations_fi.StorageProduct;
	static StorageProducts: string = Translations_fi.StorageProducts;
	static NoSelectedItem: string = Translations_fi.NoSelectedItem;
	static SelectedProduct: string = Translations_fi.SelectedProduct;
	static SelectedStorage: string = Translations_fi.SelectedStorage;
	static SelectedStorageLocation: string = Translations_fi.SelectedStorageLocation;
	static SelectedStorageProduct: string = Translations_fi.SelectedStorageProduct;
	static StorageProductRemoveConfirmation: string = Translations_fi.StorageProductRemoveConfirmation;
	static StorageProductRemoveSuccess: string = Translations_fi.StorageProductRemoveSuccess;
	static StorageProductSaveSuccess: string = Translations_fi.StorageProductSaveSuccess;
	static RouteAndSchedule: string = Translations_fi.RouteAndSchedule;
	static ShowAllCostCenters: string = Translations_fi.ShowAllCostCenters;
	static ShowAllVehicles: string = Translations_fi.ShowAllVehicles;
	static Summary: string = Translations_fi.Summary;
	static RouteLength: string = Translations_fi.RouteLength;
	static RoutePhases: string = Translations_fi.RoutePhases;
	static TotalDuration: string = Translations_fi.TotalDuration;
	static NoVehicle: string = Translations_fi.NoVehicle;
	static Planned: string = Translations_fi.Planned;
	static Realized: string = Translations_fi.Realized;
	static RoutePointBooking: string = Translations_fi.RoutePointBooking;
	static WorkhourBankInUse: string = Translations_fi.WorkhourBankInUse;
	static CreateSalaryDataFailedErrorParameter: string = Translations_fi.CreateSalaryDataFailedErrorParameter;
	static CreateSalaryDataSuccess: string = Translations_fi.CreateSalaryDataSuccess;
	static EventLogMessageSalaryDataCreated: string = Translations_fi.EventLogMessageSalaryDataCreated;
	static EventLogMessageSalaryTransferred: string = Translations_fi.EventLogMessageSalaryTransferred;
	static SalariesTransferredToSalarySystem: string = Translations_fi.SalariesTransferredToSalarySystem;
	static ExportToSalarySystem: string = Translations_fi.ExportToSalarySystem;
	static SetSalariesToTransferredState: string = Translations_fi.SetSalariesToTransferredState;
	static TransferToBePaid: string = Translations_fi.TransferToBePaid;
	static IncludeTransferredSalaries: string = Translations_fi.IncludeTransferredSalaries;
	static DataToBeSavedInWorkOrderTemplate: string = Translations_fi.DataToBeSavedInWorkOrderTemplate;
	static NewProjectBasedOnWorkOrderTemplate: string = Translations_fi.NewProjectBasedOnWorkOrderTemplate;
	static NewWorkBasedOnWorkOrderTemplate: string = Translations_fi.NewWorkBasedOnWorkOrderTemplate;
	static NoWorkOrderTemplates: string = Translations_fi.NoWorkOrderTemplates;
	static SaveAsWorkOrderTemplate: string = Translations_fi.SaveAsWorkOrderTemplate;
	static SelectWorkOrderTemplate: string = Translations_fi.SelectWorkOrderTemplate;
	static WorkOrderSaveAsWorkOrderTemplateFailedErrorParameter: string = Translations_fi.WorkOrderSaveAsWorkOrderTemplateFailedErrorParameter;
	static WorkOrderSaveAsWorkOrderTemplateSuccess: string = Translations_fi.WorkOrderSaveAsWorkOrderTemplateSuccess;
	static WorkOrderTemplate: string = Translations_fi.WorkOrderTemplate;
	static WorkOrderTemplateRemoveConfirmation: string = Translations_fi.WorkOrderTemplateRemoveConfirmation;
	static WorkOrderTemplateRemoveFailedErrorParameter: string = Translations_fi.WorkOrderTemplateRemoveFailedErrorParameter;
	static WorkOrderTemplateRemoveSuccess: string = Translations_fi.WorkOrderTemplateRemoveSuccess;
	static WorkOrderTemplatesRemoveFailedErrorParameter: string = Translations_fi.WorkOrderTemplatesRemoveFailedErrorParameter;
	static WorkOrderTemplatesRemoveSuccess: string = Translations_fi.WorkOrderTemplatesRemoveSuccess;
	static NoSalaryDataToTransfer: string = Translations_fi.NoSalaryDataToTransfer;
	static SalaryDatas: string = Translations_fi.SalaryDatas;
	static WorkQueue: string = Translations_fi.WorkQueue;
	static AssignToMe: string = Translations_fi.AssignToMe;
	static WorkOrderIsShownInWorkQueue: string = Translations_fi.WorkOrderIsShownInWorkQueue;
	static DoYouWantToReturnWorkOrderToWorkQueue: string = Translations_fi.DoYouWantToReturnWorkOrderToWorkQueue;
	static ReturnWorkOrderToWorkQueue: string = Translations_fi.ReturnWorkOrderToWorkQueue;
	static UsesWorkQueue: string = Translations_fi.UsesWorkQueue;
	static EmployeeGroupAddFailedErrorParameter: string = Translations_fi.EmployeeGroupAddFailedErrorParameter;
	static EmployeeGroupAddSuccess: string = Translations_fi.EmployeeGroupAddSuccess;
	static EmployeeGroupsAddFailedErrorParameter: string = Translations_fi.EmployeeGroupsAddFailedErrorParameter;
	static EmployeeGroupsAddSuccess: string = Translations_fi.EmployeeGroupsAddSuccess;
	static EmployeeGroupsRemoveFailedErrorParameter: string = Translations_fi.EmployeeGroupsRemoveFailedErrorParameter;
	static EmployeeGroupsRemoveSuccess: string = Translations_fi.EmployeeGroupsRemoveSuccess;
	static SecondaryEmployeeGroups: string = Translations_fi.SecondaryEmployeeGroups;
	static WorkQueuePostalCodeRangeEnd: string = Translations_fi.WorkQueuePostalCodeRangeEnd;
	static WorkQueuePostalCodeRangeStart: string = Translations_fi.WorkQueuePostalCodeRangeStart;
	static AssignToMeDoStartWorkConfirmation: string = Translations_fi.AssignToMeDoStartWorkConfirmation;
	static AssignToMeWorkOrderConfirmation: string = Translations_fi.AssignToMeWorkOrderConfirmation;
	static CompletionDate: string = Translations_fi.CompletionDate;
	static CreationDate: string = Translations_fi.CreationDate;
	static WorkOrderAssignToMeFailedErrorParameter: string = Translations_fi.WorkOrderAssignToMeFailedErrorParameter;
	static WorkOrderAssignToMeSuccess: string = Translations_fi.WorkOrderAssignToMeSuccess;
	static WorkOrderUnAssignFromEmployeesFailedErrorParameter: string = Translations_fi.WorkOrderUnAssignFromEmployeesFailedErrorParameter;
	static WorkOrderUnAssignFromEmployeesSuccess: string = Translations_fi.WorkOrderUnAssignFromEmployeesSuccess;
	static WorkTimeMeasurementStarted: string = Translations_fi.WorkTimeMeasurementStarted;
	static PrimaryEmployeeGroup: string = Translations_fi.PrimaryEmployeeGroup;
	static StorageLocationCode: string = Translations_fi.StorageLocationCode;
	static StorageLocationName: string = Translations_fi.StorageLocationName;
	static StorageLocationSaveSuccess: string = Translations_fi.StorageLocationSaveSuccess;
	static StorageLocationRemoveConfirmation: string = Translations_fi.StorageLocationRemoveConfirmation;
	static StorageLocationRemoveSuccess: string = Translations_fi.StorageLocationRemoveSuccess;
	static CreateNewStorageLocation: string = Translations_fi.CreateNewStorageLocation;
	static RemoveSelected: string = Translations_fi.RemoveSelected;
	static ConcuranceError: string = Translations_fi.ConcuranceError;
	static SaveFailed: string = Translations_fi.SaveFailed;
	static SaveSuccess: string = Translations_fi.SaveSuccess;
	static CustomerSiteAddress: string = Translations_fi.CustomerSiteAddress;
	static MultipleStorageLocationRemoveConfirmation: string = Translations_fi.MultipleStorageLocationRemoveConfirmation;
	static MultipleStorageLocationsRemoveSuccess: string = Translations_fi.MultipleStorageLocationsRemoveSuccess;
	static NoCustomer: string = Translations_fi.NoCustomer;
	static AreYouSureWantDelete: string = Translations_fi.AreYouSureWantDelete;
	static SaveAndClose: string = Translations_fi.SaveAndClose;
	static CreateStorageLocations: string = Translations_fi.CreateStorageLocations;
	static YouCanAlsoCreateStorageLocations: string = Translations_fi.YouCanAlsoCreateStorageLocations;
	static CreateNewStorage: string = Translations_fi.CreateNewStorage;
	static CreateNewStorageText: string = Translations_fi.CreateNewStorageText;
	static AddProducts: string = Translations_fi.AddProducts;
	static YouCanAlsoAddProducts: string = Translations_fi.YouCanAlsoAddProducts;
	static EditStorage: string = Translations_fi.EditStorage;
	static NameAndAddressMustBeDefined: string = Translations_fi.NameAndAddressMustBeDefined;
	static AddStorageProducts: string = Translations_fi.AddStorageProducts;
	static AddStorageProductsText: string = Translations_fi.AddStorageProductsText;
	static CreateProduct: string = Translations_fi.CreateProduct;
	static ProductSearch: string = Translations_fi.ProductSearch;
	static BookedAmount: string = Translations_fi.BookedAmount;
	static FreeAmount: string = Translations_fi.FreeAmount;
	static Sum: string = Translations_fi.Sum;
	static ProductMustBeDefined: string = Translations_fi.ProductMustBeDefined;
	static StorageLocationMustBeDefined: string = Translations_fi.StorageLocationMustBeDefined;
	static Code1: string = Translations_fi.Code1;
	static OwnerLogo: string = Translations_fi.OwnerLogo;
	static OwnerMobileBackgroundImage: string = Translations_fi.OwnerMobileBackgroundImage;
	static ShowDefaultLogo: string = Translations_fi.ShowDefaultLogo;
	static AvailableForBooking: string = Translations_fi.AvailableForBooking;
	static ErrorAmountNotEnough: string = Translations_fi.ErrorAmountNotEnough;
	static AddToOrder: string = Translations_fi.AddToOrder;
	static CreateNewOrder: string = Translations_fi.CreateNewOrder;
	static ShoppingCart: string = Translations_fi.ShoppingCart;
	static SelectExistingWorkOrder: string = Translations_fi.SelectExistingWorkOrder;
	static ShoppingCartProductListTitle: string = Translations_fi.ShoppingCartProductListTitle;
	static CanNotDeleteStorage: string = Translations_fi.CanNotDeleteStorage;
	static DeleteWasSuccess: string = Translations_fi.DeleteWasSuccess;
	static LocationDataSavingInterval: string = Translations_fi.LocationDataSavingInterval;
	static LocationDataSavingInUse: string = Translations_fi.LocationDataSavingInUse;
	static LocationPermissionMustBeAllowedInTheDeviceForThisWebSiteParameterBeforeWorkOrderCanBeMovedToInProgressState: string = Translations_fi.LocationPermissionMustBeAllowedInTheDeviceForThisWebSiteParameterBeforeWorkOrderCanBeMovedToInProgressState;
	static WorkOrdersParameterLocationSaveFailedErrorParameter: string = Translations_fi.WorkOrdersParameterLocationSaveFailedErrorParameter;
	static WorkOrdersParameterLocationSaveSuccess: string = Translations_fi.WorkOrdersParameterLocationSaveSuccess;
	static LocationPermissionMustBeAllowedInTheDeviceForThisWebSiteParameter: string = Translations_fi.LocationPermissionMustBeAllowedInTheDeviceForThisWebSiteParameter;
	static DeliveryPoint: string = Translations_fi.DeliveryPoint;
	static ProductName: string = Translations_fi.ProductName;
	static NoRows: string = Translations_fi.NoRows;
	static BookingContainsNotValidLines: string = Translations_fi.BookingContainsNotValidLines;
	static SalaryPeriodTypeRemoveConfirmation: string = Translations_fi.SalaryPeriodTypeRemoveConfirmation;
	static SalaryPeriodTypes: string = Translations_fi.SalaryPeriodTypes;
	static InvalidEndDate: string = Translations_fi.InvalidEndDate;
	static SalaryPeriodType: string = Translations_fi.SalaryPeriodType;
	static SalaryPeriodTypeParameterNotFound: string = Translations_fi.SalaryPeriodTypeParameterNotFound;
	static SalaryPeriodTypeSaveFailedConcurrencyError: string = Translations_fi.SalaryPeriodTypeSaveFailedConcurrencyError;
	static SalaryPeriodTypeSaveFailedErrorParameter: string = Translations_fi.SalaryPeriodTypeSaveFailedErrorParameter;
	static SalaryPeriodTypeSaveSuccess: string = Translations_fi.SalaryPeriodTypeSaveSuccess;
	static AddSalaryPeriod: string = Translations_fi.AddSalaryPeriod;
	static AddSalaryPeriods: string = Translations_fi.AddSalaryPeriods;
	static ParameterMonths: string = Translations_fi.ParameterMonths;
	static ParameterWeeks: string = Translations_fi.ParameterWeeks;
	static PeriodLength: string = Translations_fi.PeriodLength;
	static SalaryPeriodParameterCannotBeEditedBecauseItIsUsedInParameterSalaries: string = Translations_fi.SalaryPeriodParameterCannotBeEditedBecauseItIsUsedInParameterSalaries;
	static SalaryPeriodParameterCannotBeRemovedBecauseItIsUsedInParameterSalaries: string = Translations_fi.SalaryPeriodParameterCannotBeRemovedBecauseItIsUsedInParameterSalaries;
	static SalaryPeriodTypeParameterCannotBeRemovedBecauseItIsUsedInParameterSalaries: string = Translations_fi.SalaryPeriodTypeParameterCannotBeRemovedBecauseItIsUsedInParameterSalaries;
	static SalaryPeriodTypeParameterRemoveFailedErrorParameter: string = Translations_fi.SalaryPeriodTypeParameterRemoveFailedErrorParameter;
	static SalaryPeriodTypeParameterRemoveSuccess: string = Translations_fi.SalaryPeriodTypeParameterRemoveSuccess;
	static SalaryPeriodTypeParameterSalaryPeriodsRemoveFailedErrorParameter: string = Translations_fi.SalaryPeriodTypeParameterSalaryPeriodsRemoveFailedErrorParameter;
	static SalaryPeriodTypeParameterSalaryPeriodsRemoveSuccess: string = Translations_fi.SalaryPeriodTypeParameterSalaryPeriodsRemoveSuccess;
	static SalaryPeriodTypeParameterSalaryPeriodsSaveFailedConcurrencyError: string = Translations_fi.SalaryPeriodTypeParameterSalaryPeriodsSaveFailedConcurrencyError;
	static SalaryPeriodTypeParameterSalaryPeriodsSaveFailedErrorParameter: string = Translations_fi.SalaryPeriodTypeParameterSalaryPeriodsSaveFailedErrorParameter;
	static SalaryPeriodTypeParameterSalaryPeriodsSaveSuccess: string = Translations_fi.SalaryPeriodTypeParameterSalaryPeriodsSaveSuccess;
	static EmployeeGroupsForSalaryCalculationHaveNotBeenSpecified: string = Translations_fi.EmployeeGroupsForSalaryCalculationHaveNotBeenSpecified;
	static EmployeeGroupForDesign: string = Translations_fi.EmployeeGroupForDesign;
	static EmployeeGroupForSalaryCalculation: string = Translations_fi.EmployeeGroupForSalaryCalculation;
	static EmployeeGroupSalaryPeriodTypeCannotBeRemovedError: string = Translations_fi.EmployeeGroupSalaryPeriodTypeCannotBeRemovedError;
	static SalaryCalculation: string = Translations_fi.SalaryCalculation;
	static WorkOrderCanBeCreatedInMobileApp: string = Translations_fi.WorkOrderCanBeCreatedInMobileApp;
	static Extranet: string = Translations_fi.Extranet;
	static CommonType: string = Translations_fi.CommonType;
	static StorageInvoice: string = Translations_fi.StorageInvoice;
	static ErrorAmountNotEnoughWithName: string = Translations_fi.ErrorAmountNotEnoughWithName;
	static StorageProductParameterNotFound: string = Translations_fi.StorageProductParameterNotFound;
	static ProductsAddedToWorkOrder: string = Translations_fi.ProductsAddedToWorkOrder;
	static ProductsWillBeAddedAfterSave: string = Translations_fi.ProductsWillBeAddedAfterSave;
	static AddingProductsToWorkOrder: string = Translations_fi.AddingProductsToWorkOrder;
	static CheckAmounts: string = Translations_fi.CheckAmounts;
	static ErrorState: string = Translations_fi.ErrorState;
	static DoYouWantSaveChanges: string = Translations_fi.DoYouWantSaveChanges;
	static ToShoppingCart: string = Translations_fi.ToShoppingCart;
	static RoutePointHasProduct: string = Translations_fi.RoutePointHasProduct;
	static WorkOrderSortOrderError: string = Translations_fi.WorkOrderSortOrderError;
	static WorkOrderSortOrderUpdated: string = Translations_fi.WorkOrderSortOrderUpdated;
	static WorkOrderCreationAllowedInMobileApp: string = Translations_fi.WorkOrderCreationAllowedInMobileApp;
	static WorkOrderSequentialTaskExecution: string = Translations_fi.WorkOrderSequentialTaskExecution;
	static WorkOrderSequentialTaskExecutionNon: string = Translations_fi.WorkOrderSequentialTaskExecutionNon;
	static DailyDeductionHours: string = Translations_fi.DailyDeductionHours;
	static PeriodDeductionHours: string = Translations_fi.PeriodDeductionHours;
	static SuitableWorkOrderTypesForNewWorkOrderNotFound: string = Translations_fi.SuitableWorkOrderTypesForNewWorkOrderNotFound;
	static ProductStorageLocationError: string = Translations_fi.ProductStorageLocationError;
	static ProductBookingError: string = Translations_fi.ProductBookingError;
	static ProductStorageError: string = Translations_fi.ProductStorageError;
	static StorageProductRemoveWarning: string = Translations_fi.StorageProductRemoveWarning;
	static EventLogMessageSiteCreated: string = Translations_fi.EventLogMessageSiteCreated;
	static ERPCodeMissing: string = Translations_fi.ERPCodeMissing;
	static PlannedRoute: string = Translations_fi.PlannedRoute;
	static CollectiveAgreement: string = Translations_fi.CollectiveAgreement;
	static ProductsToDeliver: string = Translations_fi.ProductsToDeliver;
	static ProductsToFetch: string = Translations_fi.ProductsToFetch;
	static CancelSetLocationFromMap: string = Translations_fi.CancelSetLocationFromMap;
	static SendGridAddressIsMissing: string = Translations_fi.SendGridAddressIsMissing;
	static SendGridApiKeyIsMissing: string = Translations_fi.SendGridApiKeyIsMissing;
	static SendGridSendingMailFailed: string = Translations_fi.SendGridSendingMailFailed;
	static HoursInvoicingProduct: string = Translations_fi.HoursInvoicingProduct;
	static HoursInvoicingTypeNoNotInUse: string = Translations_fi.HoursInvoicingTypeNoNotInUse;
	static KmsInvoicingProduct: string = Translations_fi.KmsInvoicingProduct;
	static KmsInvoicingType: string = Translations_fi.KmsInvoicingType;
	static KmsInvoicingTypeNotInUse: string = Translations_fi.KmsInvoicingTypeNotInUse;
	static KmsInvoicingTypePlanned: string = Translations_fi.KmsInvoicingTypePlanned;
	static KmsInvoicingTypeRealized: string = Translations_fi.KmsInvoicingTypeRealized;
	static NotSelected: string = Translations_fi.NotSelected;
	static RoutePointUsage: string = Translations_fi.RoutePointUsage;
	static UserInterfaceSettings: string = Translations_fi.UserInterfaceSettings;
	static Bookings: string = Translations_fi.Bookings;
	static HideMap: string = Translations_fi.HideMap;
	static ShowMap: string = Translations_fi.ShowMap;
	static UseCollectionListInMobile: string = Translations_fi.UseCollectionListInMobile;
	static MarkProductAsCollectedByCheckingRow: string = Translations_fi.MarkProductAsCollectedByCheckingRow;
	static StorageProductBookingAmountChangeFailed: string = Translations_fi.StorageProductBookingAmountChangeFailed;
	static StorageProductBookingRemoveConfirmation: string = Translations_fi.StorageProductBookingRemoveConfirmation;
	static StorageProductBookings: string = Translations_fi.StorageProductBookings;
	static Interval: string = Translations_fi.Interval;
	static SelectSalaryType: string = Translations_fi.SelectSalaryType;
	static ShowCostCenters: string = Translations_fi.ShowCostCenters;
	static ShowCustomers: string = Translations_fi.ShowCustomers;
	static ShowOrders: string = Translations_fi.ShowOrders;
	static InvoiceReport: string = Translations_fi.InvoiceReport;
	static SalaryReport: string = Translations_fi.SalaryReport;
	static WorkTimeReport: string = Translations_fi.WorkTimeReport;
	static StartingTimeMustOccurBeforeEndingTime: string = Translations_fi.StartingTimeMustOccurBeforeEndingTime;
	static TaskTypeMustBeSelected: string = Translations_fi.TaskTypeMustBeSelected;
	static SalaryPeriodMissing: string = Translations_fi.SalaryPeriodMissing;
	static HourBookingTypesMissing: string = Translations_fi.HourBookingTypesMissing;
	static InvalidTabIndex: string = Translations_fi.InvalidTabIndex;
	static CloseWindow: string = Translations_fi.CloseWindow;
	static ChooseCustomer: string = Translations_fi.ChooseCustomer;
	static ChooseStorage: string = Translations_fi.ChooseStorage;
	static SearchConditions: string = Translations_fi.SearchConditions;
	static NoOptions: string = Translations_fi.NoOptions;
	static FieldIsRequired: string = Translations_fi.FieldIsRequired;
	static FieldMaxLengthError: string = Translations_fi.FieldMaxLengthError;
	static FieldMinLengthError: string = Translations_fi.FieldMinLengthError;
	static StorageNotFound: string = Translations_fi.StorageNotFound;
	static ShowOwnWorkOrders: string = Translations_fi.ShowOwnWorkOrders;
	static RemoveProductFromCart: string = Translations_fi.RemoveProductFromCart;
	static CloseCart: string = Translations_fi.CloseCart;
	static EditStorageLocation: string = Translations_fi.EditStorageLocation;
	static SalaryRowTypeKind: string = Translations_fi.SalaryRowTypeKind;
	static SalaryRowTypeParameterNotFound: string = Translations_fi.SalaryRowTypeParameterNotFound;
	static SalaryRowTypeParameterParameterSaveFailedErrorParameter: string = Translations_fi.SalaryRowTypeParameterParameterSaveFailedErrorParameter;
	static SalaryRowTypeParameterSaveFailedConcurrencyError: string = Translations_fi.SalaryRowTypeParameterSaveFailedConcurrencyError;
	static SalaryRowTypeParameterSaveSuccess: string = Translations_fi.SalaryRowTypeParameterSaveSuccess;
	static SalaryRowTypeRemoveConfirmation: string = Translations_fi.SalaryRowTypeRemoveConfirmation;
	static SalaryRowTypeParameterCannotBeRemovedBecauseItIsUsedInParameterDayBookingType: string = Translations_fi.SalaryRowTypeParameterCannotBeRemovedBecauseItIsUsedInParameterDayBookingType;
	static SalaryRowTypeParameterRemoveFailedConcurrencyError: string = Translations_fi.SalaryRowTypeParameterRemoveFailedConcurrencyError;
	static SalaryRowTypeParameterRemoveFailedErrorParameter: string = Translations_fi.SalaryRowTypeParameterRemoveFailedErrorParameter;
	static SalaryRowTypesParameterRemoveFailedConcurrencyError: string = Translations_fi.SalaryRowTypesParameterRemoveFailedConcurrencyError;
	static SalaryRowTypesParameterRemoveFailedErrorParameter: string = Translations_fi.SalaryRowTypesParameterRemoveFailedErrorParameter;
	static SalaryRowTypesParameterRemoveSuccess: string = Translations_fi.SalaryRowTypesParameterRemoveSuccess;
	static SalaryRowTypeParameterCannotBeRemovedBecauseItIsInUsed: string = Translations_fi.SalaryRowTypeParameterCannotBeRemovedBecauseItIsInUsed;
	static SalaryRowTypeParameterCannotBeRemovedBecauseItIsUsedInParameterHourBookingType: string = Translations_fi.SalaryRowTypeParameterCannotBeRemovedBecauseItIsUsedInParameterHourBookingType;
	static BillingProduct: string = Translations_fi.BillingProduct;
	static AddProduct: string = Translations_fi.AddProduct;
	static InvalidAmountError: string = Translations_fi.InvalidAmountError;
	static InvalidValue: string = Translations_fi.InvalidValue;
	static StorageLocationNotFound: string = Translations_fi.StorageLocationNotFound;
	static CannotDeleteStorageLocationWithProducts: string = Translations_fi.CannotDeleteStorageLocationWithProducts;
	static ScrollLabelRowsPerPage: string = Translations_fi.ScrollLabelRowsPerPage;
	static ShowDetails: string = Translations_fi.ShowDetails;
	static FetchFailed: string = Translations_fi.FetchFailed;
	static ProductNumber: string = Translations_fi.ProductNumber;
	static ProductDescription: string = Translations_fi.ProductDescription;
	static CreateNewProduct: string = Translations_fi.CreateNewProduct;
	static EditProduct: string = Translations_fi.EditProduct;
	static CreateNewStorageProduct: string = Translations_fi.CreateNewStorageProduct;
	static ProductAddedToCart: string = Translations_fi.ProductAddedToCart;
	static ProductNotFound: string = Translations_fi.ProductNotFound;
	static InvalidPriceError: string = Translations_fi.InvalidPriceError;
	static NoCustomerPrices: string = Translations_fi.NoCustomerPrices;
	static CustomerPriceAlreadyExists: string = Translations_fi.CustomerPriceAlreadyExists;
	static EnterPositiveNumber: string = Translations_fi.EnterPositiveNumber;
	static NewStorageLocationRow: string = Translations_fi.NewStorageLocationRow;
	static AreYouSureWantDeleteStorageProduct: string = Translations_fi.AreYouSureWantDeleteStorageProduct;
	static OpenBetaVersion: string = Translations_fi.OpenBetaVersion;
	static ApproveAll: string = Translations_fi.ApproveAll;
	static Approved: string = Translations_fi.Approved;
	static ShowOnlyNotApproved: string = Translations_fi.ShowOnlyNotApproved;
	static LockAll: string = Translations_fi.LockAll;
	static UnlockAll: string = Translations_fi.UnlockAll;
	static WorkTimeOriginIntegration: string = Translations_fi.WorkTimeOriginIntegration;
	static ApproveAndSave: string = Translations_fi.ApproveAndSave;
	static WorkHoursApproveWarning: string = Translations_fi.WorkHoursApproveWarning;
	static WorkTimeDifferentFromOriginal: string = Translations_fi.WorkTimeDifferentFromOriginal;
	static WorkTimeApproval: string = Translations_fi.WorkTimeApproval;
	static WorkTimeDetailsHeaderLabel: string = Translations_fi.WorkTimeDetailsHeaderLabel;
	static WorkTimeType: string = Translations_fi.WorkTimeType;
	static NoSalaryRowType: string = Translations_fi.NoSalaryRowType;
	static AbrWeek: string = Translations_fi.AbrWeek;
	static WorkHoursLockRowsWarning: string = Translations_fi.WorkHoursLockRowsWarning;
	static EmployeeNotFound: string = Translations_fi.EmployeeNotFound;
	static RoundAllWorkShiftStartAndEnds: string = Translations_fi.RoundAllWorkShiftStartAndEnds;
	static RoundUnlockedWorkShiftsApproveWarning: string = Translations_fi.RoundUnlockedWorkShiftsApproveWarning;
	static PrintStarted: string = Translations_fi.PrintStarted;
	static WorkShiftTimeSlotStateInProgressCannotBeChanged: string = Translations_fi.WorkShiftTimeSlotStateInProgressCannotBeChanged;
	static WorkShiftTimeSlotEndTimeBeforeStartTime: string = Translations_fi.WorkShiftTimeSlotEndTimeBeforeStartTime;
	static SalaryRowTypeKindIdMustBeDefined: string = Translations_fi.SalaryRowTypeKindIdMustBeDefined;
	static MeasureUnitMustBeDefined: string = Translations_fi.MeasureUnitMustBeDefined;
	static Vacations: string = Translations_fi.Vacations;
	static Absences: string = Translations_fi.Absences;
	static AreYouSureYouWantToRemoveTransportPlan: string = Translations_fi.AreYouSureYouWantToRemoveTransportPlan;
	static NewPlan: string = Translations_fi.NewPlan;
	static NewTransportPlan: string = Translations_fi.NewTransportPlan;
	static NoEmployee: string = Translations_fi.NoEmployee;
	static NonOrders: string = Translations_fi.NonOrders;
	static RemovePlan: string = Translations_fi.RemovePlan;
	static ShowCalendarFrom: string = Translations_fi.ShowCalendarFrom;
	static TransportOrders: string = Translations_fi.TransportOrders;
	static TimeZone: string = Translations_fi.TimeZone;
	static WorkTimeRoundingInfoInProgressWorkShifts: string = Translations_fi.WorkTimeRoundingInfoInProgressWorkShifts;
	static TransportOrdersInvoices: string = Translations_fi.TransportOrdersInvoices;
	static SelectedOrders: string = Translations_fi.SelectedOrders;
	static CreateNewInvoice: string = Translations_fi.CreateNewInvoice;
	static SelectAllDelivered: string = Translations_fi.SelectAllDelivered;
	static TransferToInvoice: string = Translations_fi.TransferToInvoice;
	static TransferToInvoicing: string = Translations_fi.TransferToInvoicing;
	static Definition: string = Translations_fi.Definition;
	static OrderNumber: string = Translations_fi.OrderNumber;
	static NotDelivered: string = Translations_fi.NotDelivered;
	static ShowOnlyDelivered: string = Translations_fi.ShowOnlyDelivered;
	static Default: string = Translations_fi.Default;
	static WorkTimeTypes: string = Translations_fi.WorkTimeTypes;
	static Selected: string = Translations_fi.Selected;
	static WorkTimeTypeParameterNotFound: string = Translations_fi.WorkTimeTypeParameterNotFound;
	static WorkTimeTypeRemoveConfirmation: string = Translations_fi.WorkTimeTypeRemoveConfirmation;
	static WorkTimeTypeRemoveFailedErrorParameter: string = Translations_fi.WorkTimeTypeRemoveFailedErrorParameter;
	static WorkTimeTypeRemoveSuccess: string = Translations_fi.WorkTimeTypeRemoveSuccess;
	static WorkTimeTypesAlreadyInitialized: string = Translations_fi.WorkTimeTypesAlreadyInitialized;
	static InitializeWorkTimeTypes: string = Translations_fi.InitializeWorkTimeTypes;
	static InitializeWorkTimeTypesConfirmation: string = Translations_fi.InitializeWorkTimeTypesConfirmation;
	static InitializeWorkTimeTypesSuccess: string = Translations_fi.InitializeWorkTimeTypesSuccess;
	static InitializeWorkTimeTypesFailedErrorParameter: string = Translations_fi.InitializeWorkTimeTypesFailedErrorParameter;
	static ApiKeyLabel: string = Translations_fi.ApiKeyLabel;
	static AddTransportToInvoice: string = Translations_fi.AddTransportToInvoice;
	static ApproveInvoice: string = Translations_fi.ApproveInvoice;
	static InvoiceInformation: string = Translations_fi.InvoiceInformation;
	static InvoiceInformationSaveFailed: string = Translations_fi.InvoiceInformationSaveFailed;
	static InvoiceInformationSaveSuccess: string = Translations_fi.InvoiceInformationSaveSuccess;
	static InvoiceNumber: string = Translations_fi.InvoiceNumber;
	static NoInvoiceRows: string = Translations_fi.NoInvoiceRows;
	static ReferenceInformation: string = Translations_fi.ReferenceInformation;
	static RemoveOrder: string = Translations_fi.RemoveOrder;
	static SaveInvoiceInformation: string = Translations_fi.SaveInvoiceInformation;
	static TaxFree: string = Translations_fi.TaxFree;
	static TaxInclusive: string = Translations_fi.TaxInclusive;
	static TaxInclusiveTotalEUR: string = Translations_fi.TaxInclusiveTotalEUR;
	static Transports: string = Translations_fi.Transports;
	static Unit: string = Translations_fi.Unit;
	static UnitPriceEUR: string = Translations_fi.UnitPriceEUR;
	static ShowOnlyInvoiceable: string = Translations_fi.ShowOnlyInvoiceable;
	static OnTheInvoice: string = Translations_fi.OnTheInvoice;
	static NoSelectedOrders: string = Translations_fi.NoSelectedOrders;
	static RemoveInvoice: string = Translations_fi.RemoveInvoice;
	static FailedToCreateInvoice: string = Translations_fi.FailedToCreateInvoice;
	static DisplaySentInvoices: string = Translations_fi.DisplaySentInvoices;
	static InvoiceApproved: string = Translations_fi.InvoiceApproved;
	static InvoiceNotApproved: string = Translations_fi.InvoiceNotApproved;
	static InvoiceSent: string = Translations_fi.InvoiceSent;
	static PayersName: string = Translations_fi.PayersName;
	static SentDate: string = Translations_fi.SentDate;
	static TaxExclusiveTotal: string = Translations_fi.TaxExclusiveTotal;
	static TotalInvoiceAmount: string = Translations_fi.TotalInvoiceAmount;
	static TotalTax: string = Translations_fi.TotalTax;
	static TransportOrderInvoices: string = Translations_fi.TransportOrderInvoices;
	static SearchByInvoiceNumber: string = Translations_fi.SearchByInvoiceNumber;
	static SendInvoice: string = Translations_fi.SendInvoice;
	static InvoiceDetails: string = Translations_fi.InvoiceDetails;
	static CreateInvoiceSuccess: string = Translations_fi.CreateInvoiceSuccess;
	static AddSelected: string = Translations_fi.AddSelected;
	static CreateInvoicesFailed: string = Translations_fi.CreateInvoicesFailed;
	static AddTransportOrdersToInvoiceFailed: string = Translations_fi.AddTransportOrdersToInvoiceFailed;
	static AddTransportOrdersToInvoiceSuccess: string = Translations_fi.AddTransportOrdersToInvoiceSuccess;
	static CategoryNormal: string = Translations_fi.CategoryNormal;
	static CategoryPeriodOvertime50: string = Translations_fi.CategoryPeriodOvertime50;
	static CategoryPeriodOvertime100: string = Translations_fi.CategoryPeriodOvertime100;
	static CategoryWeekOvertime50: string = Translations_fi.CategoryWeekOvertime50;
	static CategoryWeekOvertime100: string = Translations_fi.CategoryWeekOvertime100;
	static CategoryEvening: string = Translations_fi.CategoryEvening;
	static CategoryNight: string = Translations_fi.CategoryNight;
	static CategorySaturday: string = Translations_fi.CategorySaturday;
	static CategorySundayOrHoliday: string = Translations_fi.CategorySundayOrHoliday;
	static CategoryAlternativeSunday: string = Translations_fi.CategoryAlternativeSunday;
	static CategoryPaidBreak: string = Translations_fi.CategoryPaidBreak;
	static CategoryCustomNormalType: string = Translations_fi.CategoryCustomNormalType;
	static CategoryMidWeekHoliday: string = Translations_fi.CategoryMidWeekHoliday;
	static CategoryMidWeekHoliday200: string = Translations_fi.CategoryMidWeekHoliday200;
	static CategorySalaryInSickTime: string = Translations_fi.CategorySalaryInSickTime;
	static CategoryWorkTimeCut: string = Translations_fi.CategoryWorkTimeCut;
	static CategoryHalfDailyAllowance: string = Translations_fi.CategoryHalfDailyAllowance;
	static CategoryDailyAllowance: string = Translations_fi.CategoryDailyAllowance;
	static CategoryDailyOvertime50: string = Translations_fi.CategoryDailyOvertime50;
	static CategoryDailyOvertime100: string = Translations_fi.CategoryDailyOvertime100;
	static CategoryBreak: string = Translations_fi.CategoryBreak;
	static CategoryMealBreak: string = Translations_fi.CategoryMealBreak;
	static InvoiceSendFailed: string = Translations_fi.InvoiceSendFailed;
	static ShowAllTransportOrders: string = Translations_fi.ShowAllTransportOrders;
	static EditInvoice: string = Translations_fi.EditInvoice;
	static CategorySecondaryEvening: string = Translations_fi.CategorySecondaryEvening;
	static CategorySecondaryNight: string = Translations_fi.CategorySecondaryNight;
	static RevokeApproval: string = Translations_fi.RevokeApproval;
	static Delivered: string = Translations_fi.Delivered;
	static DeliveryDetails: string = Translations_fi.DeliveryDetails;
	static PickupDetails: string = Translations_fi.PickupDetails;
	static WaybillNumber: string = Translations_fi.WaybillNumber;
	static TotalPrice: string = Translations_fi.TotalPrice;
	static BillingCustomer: string = Translations_fi.BillingCustomer;
	static AreYouSureWantRevokeApproval: string = Translations_fi.AreYouSureWantRevokeApproval;
	static InvoicingBetaTitle: string = Translations_fi.InvoicingBetaTitle;
	static WorkTimeTypeBreak: string = Translations_fi.WorkTimeTypeBreak;
	static WorkTimeTypeDriving: string = Translations_fi.WorkTimeTypeDriving;
	static WorkTimeTypeLoading: string = Translations_fi.WorkTimeTypeLoading;
	static WorkTimeTypeOther: string = Translations_fi.WorkTimeTypeOther;
	static WorkTimeTypeUnLoading: string = Translations_fi.WorkTimeTypeUnLoading;
	static WorkTimeTypeWaiting: string = Translations_fi.WorkTimeTypeWaiting;
	static DeliveryTime: string = Translations_fi.DeliveryTime;
	static NotSent: string = Translations_fi.NotSent;
	static Price: string = Translations_fi.Price;
	static Sent: string = Translations_fi.Sent;
	static WaybillNo: string = Translations_fi.WaybillNo;
	static TransportOrderInvoiceFetchFail: string = Translations_fi.TransportOrderInvoiceFetchFail;
	static TransportOrderInvoicesFetchFail: string = Translations_fi.TransportOrderInvoicesFetchFail;
	static TransportOrdersFetchFail: string = Translations_fi.TransportOrdersFetchFail;
	static RemoveFromPlan: string = Translations_fi.RemoveFromPlan;
	static Invoiced: string = Translations_fi.Invoiced;
	static CategoryPaidWaiting: string = Translations_fi.CategoryPaidWaiting;
	static CategoryUnPaidWaiting: string = Translations_fi.CategoryUnPaidWaiting;
	static CategoryOvertimeBase: string = Translations_fi.CategoryOvertimeBase;
	static CategoryMonthlyOvertime50: string = Translations_fi.CategoryMonthlyOvertime50;
	static CategoryGrandHoliday: string = Translations_fi.CategoryGrandHoliday;
	static EditRecurringTransportPlan: string = Translations_fi.EditRecurringTransportPlan;
	static CannotAddOrderToCompletedPlan: string = Translations_fi.CannotAddOrderToCompletedPlan;
	static ThreeMonthTransportPlanQuery: string = Translations_fi.ThreeMonthTransportPlanQuery;
	static TransportPlansFetchFail: string = Translations_fi.TransportPlansFetchFail;
	static AddBreak: string = Translations_fi.AddBreak;
	static breakRangeError: string = Translations_fi.breakRangeError;
	static ChangeInInvoicingStateWasSuccessful: string = Translations_fi.ChangeInInvoicingStateWasSuccessful;
	static Invoiceable: string = Translations_fi.Invoiceable;
	static InvoicingStateChangeFailed: string = Translations_fi.InvoicingStateChangeFailed;
	static MarkAsInvoiced: string = Translations_fi.MarkAsInvoiced;
	static ReturnToInvoiceable: string = Translations_fi.ReturnToInvoiceable;
	static TransportOrderInvoicingStateConfirmation: string = Translations_fi.TransportOrderInvoicingStateConfirmation;
	static FixedPriceProductRemoveInformation: string = Translations_fi.FixedPriceProductRemoveInformation;
	static OverlappingWorkTimeEntries: string = Translations_fi.OverlappingWorkTimeEntries;
	static WorkShiftInProgressCannotBeSaved: string = Translations_fi.WorkShiftInProgressCannotBeSaved;
	static DateNotInSalaryPeriod: string = Translations_fi.DateNotInSalaryPeriod;
	static OverwritingWorkTimeRangeError: string = Translations_fi.OverwritingWorkTimeRangeError;
	static CategoryCustomAnyType: string = Translations_fi.CategoryCustomAnyType;
	static CategoryOverTimeCalculation: string = Translations_fi.CategoryOverTimeCalculation;
	static CategoryPaidMealBreak: string = Translations_fi.CategoryPaidMealBreak;
	static CategoryCustomAddition: string = Translations_fi.CategoryCustomAddition;
	static DayBookingLockSuccess: string = Translations_fi.DayBookingLockSuccess;
	static DayBookingUnlockSuccess: string = Translations_fi.DayBookingUnlockSuccess;
	static DayBookingLockFail: string = Translations_fi.DayBookingLockFail;
	static DayBookingUnlockFail: string = Translations_fi.DayBookingUnlockFail;
	static DayBookingLocked: string = Translations_fi.DayBookingLocked;


static setCulture1(culture: string) {
		Translations.ApplicationName = culture === "fi" ? Translations_fi.ApplicationName : Translations_en_US.ApplicationName;
		Translations.ArgumentParameterIsNULL = culture === "fi" ? Translations_fi.ArgumentParameterIsNULL : Translations_en_US.ArgumentParameterIsNULL;
		Translations.Cancel = culture === "fi" ? Translations_fi.Cancel : Translations_en_US.Cancel;
		Translations.ClassParameterHasNotBeenInitialized = culture === "fi" ? Translations_fi.ClassParameterHasNotBeenInitialized : Translations_en_US.ClassParameterHasNotBeenInitialized;
		Translations.EmployeeParameterCodeIsAlreadyInUse = culture === "fi" ? Translations_fi.EmployeeParameterCodeIsAlreadyInUse : Translations_en_US.EmployeeParameterCodeIsAlreadyInUse;
		Translations.EmployeeParameterParameterSavedSuccessfully = culture === "fi" ? Translations_fi.EmployeeParameterParameterSavedSuccessfully : Translations_en_US.EmployeeParameterParameterSavedSuccessfully;
		Translations.EmployeeParameterParameterSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.EmployeeParameterParameterSaveFailedConcurrencyError : Translations_en_US.EmployeeParameterParameterSaveFailedConcurrencyError;
		Translations.EmployeeParameterParameterSaveFailedErrorParameter = culture === "fi" ? Translations_fi.EmployeeParameterParameterSaveFailedErrorParameter : Translations_en_US.EmployeeParameterParameterSaveFailedErrorParameter;
		Translations.Maintenance = culture === "fi" ? Translations_fi.Maintenance : Translations_en_US.Maintenance;
		Translations.No = culture === "fi" ? Translations_fi.No : Translations_en_US.No;
		Translations.Password = culture === "fi" ? Translations_fi.Password : Translations_en_US.Password;
		Translations.PermissionDeniedForOperation = culture === "fi" ? Translations_fi.PermissionDeniedForOperation : Translations_en_US.PermissionDeniedForOperation;
		Translations.PlannedWorks = culture === "fi" ? Translations_fi.PlannedWorks : Translations_en_US.PlannedWorks;
		Translations.Planning = culture === "fi" ? Translations_fi.Planning : Translations_en_US.Planning;
		Translations.Reporting = culture === "fi" ? Translations_fi.Reporting : Translations_en_US.Reporting;
		Translations.SignIn = culture === "fi" ? Translations_fi.SignIn : Translations_en_US.SignIn;
		Translations.SignOut = culture === "fi" ? Translations_fi.SignOut : Translations_en_US.SignOut;
		Translations.ToBeCheckedWorks = culture === "fi" ? Translations_fi.ToBeCheckedWorks : Translations_en_US.ToBeCheckedWorks;
		Translations.ToBeInvoicedWorks = culture === "fi" ? Translations_fi.ToBeInvoicedWorks : Translations_en_US.ToBeInvoicedWorks;
		Translations.UserClaimParameterNotFound = culture === "fi" ? Translations_fi.UserClaimParameterNotFound : Translations_en_US.UserClaimParameterNotFound;
		Translations.UserClaimsNotFound = culture === "fi" ? Translations_fi.UserClaimsNotFound : Translations_en_US.UserClaimsNotFound;
		Translations.Username = culture === "fi" ? Translations_fi.Username : Translations_en_US.Username;
		Translations.WaitingWorks = culture === "fi" ? Translations_fi.WaitingWorks : Translations_en_US.WaitingWorks;
		Translations.Yes = culture === "fi" ? Translations_fi.Yes : Translations_en_US.Yes;
		Translations.Employee = culture === "fi" ? Translations_fi.Employee : Translations_en_US.Employee;
		Translations.InvalidUsernameOrPassword = culture === "fi" ? Translations_fi.InvalidUsernameOrPassword : Translations_en_US.InvalidUsernameOrPassword;
		Translations.PasswordIsTooShort = culture === "fi" ? Translations_fi.PasswordIsTooShort : Translations_en_US.PasswordIsTooShort;
		Translations.PasswordMustContainDigitOrSpecialCharacter = culture === "fi" ? Translations_fi.PasswordMustContainDigitOrSpecialCharacter : Translations_en_US.PasswordMustContainDigitOrSpecialCharacter;
		Translations.PasswordMustContainLowercaseChar = culture === "fi" ? Translations_fi.PasswordMustContainLowercaseChar : Translations_en_US.PasswordMustContainLowercaseChar;
		Translations.PasswordMustContainUppercaseChar = culture === "fi" ? Translations_fi.PasswordMustContainUppercaseChar : Translations_en_US.PasswordMustContainUppercaseChar;
		Translations.TheSystemDoesNotSupportYourWebBrowserUpdateToLatestBrowserVersion = culture === "fi" ? Translations_fi.TheSystemDoesNotSupportYourWebBrowserUpdateToLatestBrowserVersion : Translations_en_US.TheSystemDoesNotSupportYourWebBrowserUpdateToLatestBrowserVersion;
		Translations.Week = culture === "fi" ? Translations_fi.Week : Translations_en_US.Week;
		Translations.WorkPlan = culture === "fi" ? Translations_fi.WorkPlan : Translations_en_US.WorkPlan;
		Translations.Actions = culture === "fi" ? Translations_fi.Actions : Translations_en_US.Actions;
		Translations.Copy = culture === "fi" ? Translations_fi.Copy : Translations_en_US.Copy;
		Translations.Edit = culture === "fi" ? Translations_fi.Edit : Translations_en_US.Edit;
		Translations.FindWeek = culture === "fi" ? Translations_fi.FindWeek : Translations_en_US.FindWeek;
		Translations.New = culture === "fi" ? Translations_fi.New : Translations_en_US.New;
		Translations.NextWeek = culture === "fi" ? Translations_fi.NextWeek : Translations_en_US.NextWeek;
		Translations.PreviousWeek = culture === "fi" ? Translations_fi.PreviousWeek : Translations_en_US.PreviousWeek;
		Translations.Remove = culture === "fi" ? Translations_fi.Remove : Translations_en_US.Remove;
		Translations.Clear = culture === "fi" ? Translations_fi.Clear : Translations_en_US.Clear;
		Translations.Close = culture === "fi" ? Translations_fi.Close : Translations_en_US.Close;
		Translations.Contact = culture === "fi" ? Translations_fi.Contact : Translations_en_US.Contact;
		Translations.Duration = culture === "fi" ? Translations_fi.Duration : Translations_en_US.Duration;
		Translations.MeasureUnitKg = culture === "fi" ? Translations_fi.MeasureUnitKg : Translations_en_US.MeasureUnitKg;
		Translations.MeasureUnitL = culture === "fi" ? Translations_fi.MeasureUnitL : Translations_en_US.MeasureUnitL;
		Translations.MeasureUnitM = culture === "fi" ? Translations_fi.MeasureUnitM : Translations_en_US.MeasureUnitM;
		Translations.MeasureUnitPcs = culture === "fi" ? Translations_fi.MeasureUnitPcs : Translations_en_US.MeasureUnitPcs;
		Translations.OK = culture === "fi" ? Translations_fi.OK : Translations_en_US.OK;
		Translations.Site = culture === "fi" ? Translations_fi.Site : Translations_en_US.Site;
		Translations.StartDate = culture === "fi" ? Translations_fi.StartDate : Translations_en_US.StartDate;
		Translations.State = culture === "fi" ? Translations_fi.State : Translations_en_US.State;
		Translations.WorkDescription = culture === "fi" ? Translations_fi.WorkDescription : Translations_en_US.WorkDescription;
		Translations.WorkOrder = culture === "fi" ? Translations_fi.WorkOrder : Translations_en_US.WorkOrder;
		Translations.WorkOrderBookingsTab = culture === "fi" ? Translations_fi.WorkOrderBookingsTab : Translations_en_US.WorkOrderBookingsTab;
		Translations.WorkOrderDocumentsTab = culture === "fi" ? Translations_fi.WorkOrderDocumentsTab : Translations_en_US.WorkOrderDocumentsTab;
		Translations.WorkOrderGeneralTab = culture === "fi" ? Translations_fi.WorkOrderGeneralTab : Translations_en_US.WorkOrderGeneralTab;
		Translations.WorkOrderParameterNotFound = culture === "fi" ? Translations_fi.WorkOrderParameterNotFound : Translations_en_US.WorkOrderParameterNotFound;
		Translations.WorkOrderStateChecked = culture === "fi" ? Translations_fi.WorkOrderStateChecked : Translations_en_US.WorkOrderStateChecked;
		Translations.WorkOrderStateDone = culture === "fi" ? Translations_fi.WorkOrderStateDone : Translations_en_US.WorkOrderStateDone;
		Translations.WorkOrderStateInProgress = culture === "fi" ? Translations_fi.WorkOrderStateInProgress : Translations_en_US.WorkOrderStateInProgress;
		Translations.WorkOrderStatePlanned = culture === "fi" ? Translations_fi.WorkOrderStatePlanned : Translations_en_US.WorkOrderStatePlanned;
		Translations.WorkOrderStatePreliminary = culture === "fi" ? Translations_fi.WorkOrderStatePreliminary : Translations_en_US.WorkOrderStatePreliminary;
		Translations.WorkOrderStateTransferred = culture === "fi" ? Translations_fi.WorkOrderStateTransferred : Translations_en_US.WorkOrderStateTransferred;
		Translations.WorkOrderType = culture === "fi" ? Translations_fi.WorkOrderType : Translations_en_US.WorkOrderType;
		Translations.Add = culture === "fi" ? Translations_fi.Add : Translations_en_US.Add;
		Translations.AddPhoto = culture === "fi" ? Translations_fi.AddPhoto : Translations_en_US.AddPhoto;
		Translations.Amount = culture === "fi" ? Translations_fi.Amount : Translations_en_US.Amount;
		Translations.AmountH = culture === "fi" ? Translations_fi.AmountH : Translations_en_US.AmountH;
		Translations.AmountKm = culture === "fi" ? Translations_fi.AmountKm : Translations_en_US.AmountKm;
		Translations.Attachments = culture === "fi" ? Translations_fi.Attachments : Translations_en_US.Attachments;
		Translations.Change = culture === "fi" ? Translations_fi.Change : Translations_en_US.Change;
		Translations.Comment = culture === "fi" ? Translations_fi.Comment : Translations_en_US.Comment;
		Translations.Cost = culture === "fi" ? Translations_fi.Cost : Translations_en_US.Cost;
		Translations.Customer = culture === "fi" ? Translations_fi.Customer : Translations_en_US.Customer;
		Translations.Date = culture === "fi" ? Translations_fi.Date : Translations_en_US.Date;
		Translations.DocumentIsEmpty = culture === "fi" ? Translations_fi.DocumentIsEmpty : Translations_en_US.DocumentIsEmpty;
		Translations.DocumentParameterNotFound = culture === "fi" ? Translations_fi.DocumentParameterNotFound : Translations_en_US.DocumentParameterNotFound;
		Translations.Download = culture === "fi" ? Translations_fi.Download : Translations_en_US.Download;
		Translations.EndDate = culture === "fi" ? Translations_fi.EndDate : Translations_en_US.EndDate;
		Translations.HourBooking = culture === "fi" ? Translations_fi.HourBooking : Translations_en_US.HourBooking;
		Translations.HourBookings = culture === "fi" ? Translations_fi.HourBookings : Translations_en_US.HourBookings;
		Translations.ProductBooking = culture === "fi" ? Translations_fi.ProductBooking : Translations_en_US.ProductBooking;
		Translations.ProductBookings = culture === "fi" ? Translations_fi.ProductBookings : Translations_en_US.ProductBookings;
		Translations.Product = culture === "fi" ? Translations_fi.Product : Translations_en_US.Product;
		Translations.MeasureUnitH = culture === "fi" ? Translations_fi.MeasureUnitH : Translations_en_US.MeasureUnitH;
		Translations.MeasureUnitKm = culture === "fi" ? Translations_fi.MeasureUnitKm : Translations_en_US.MeasureUnitKm;
		Translations.RideBooking = culture === "fi" ? Translations_fi.RideBooking : Translations_en_US.RideBooking;
		Translations.RideBookings = culture === "fi" ? Translations_fi.RideBookings : Translations_en_US.RideBookings;
		Translations.Save = culture === "fi" ? Translations_fi.Save : Translations_en_US.Save;
		Translations.SelectCustomer = culture === "fi" ? Translations_fi.SelectCustomer : Translations_en_US.SelectCustomer;
		Translations.UnitPrice = culture === "fi" ? Translations_fi.UnitPrice : Translations_en_US.UnitPrice;
		Translations.Unnamed = culture === "fi" ? Translations_fi.Unnamed : Translations_en_US.Unnamed;
		Translations.Vehicle = culture === "fi" ? Translations_fi.Vehicle : Translations_en_US.Vehicle;
		Translations.DecreaseState = culture === "fi" ? Translations_fi.DecreaseState : Translations_en_US.DecreaseState;
		Translations.EmployeesMustBeDefined = culture === "fi" ? Translations_fi.EmployeesMustBeDefined : Translations_en_US.EmployeesMustBeDefined;
		Translations.HourBookingParameterNotFound = culture === "fi" ? Translations_fi.HourBookingParameterNotFound : Translations_en_US.HourBookingParameterNotFound;
		Translations.IncreaseState = culture === "fi" ? Translations_fi.IncreaseState : Translations_en_US.IncreaseState;
		Translations.ProductBookingParameterNotFound = culture === "fi" ? Translations_fi.ProductBookingParameterNotFound : Translations_en_US.ProductBookingParameterNotFound;
		Translations.NotImplemented = culture === "fi" ? Translations_fi.NotImplemented : Translations_en_US.NotImplemented;
		Translations.RideBookingParameterNotFound = culture === "fi" ? Translations_fi.RideBookingParameterNotFound : Translations_en_US.RideBookingParameterNotFound;
		Translations.SelectDate = culture === "fi" ? Translations_fi.SelectDate : Translations_en_US.SelectDate;
		Translations.StartDateMustBeDefined = culture === "fi" ? Translations_fi.StartDateMustBeDefined : Translations_en_US.StartDateMustBeDefined;
		Translations.WorkOrderSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrderSaveFailedConcurrencyError : Translations_en_US.WorkOrderSaveFailedConcurrencyError;
		Translations.WorkOrderSaveFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderSaveFailedErrorParameter : Translations_en_US.WorkOrderSaveFailedErrorParameter;
		Translations.WorkOrderSaveSuccess = culture === "fi" ? Translations_fi.WorkOrderSaveSuccess : Translations_en_US.WorkOrderSaveSuccess;
		Translations.WorkOrderCannotBeModifiedInThisState = culture === "fi" ? Translations_fi.WorkOrderCannotBeModifiedInThisState : Translations_en_US.WorkOrderCannotBeModifiedInThisState;
		Translations.FinishWork = culture === "fi" ? Translations_fi.FinishWork : Translations_en_US.FinishWork;
		Translations.StartWork = culture === "fi" ? Translations_fi.StartWork : Translations_en_US.StartWork;
		Translations.WorkOrderCannotCrossWeekBoundary = culture === "fi" ? Translations_fi.WorkOrderCannotCrossWeekBoundary : Translations_en_US.WorkOrderCannotCrossWeekBoundary;
		Translations.WorkOrders = culture === "fi" ? Translations_fi.WorkOrders : Translations_en_US.WorkOrders;
		Translations.MeasureUnitD = culture === "fi" ? Translations_fi.MeasureUnitD : Translations_en_US.MeasureUnitD;
		Translations.StartDateAndDuration = culture === "fi" ? Translations_fi.StartDateAndDuration : Translations_en_US.StartDateAndDuration;
		Translations.Warning = culture === "fi" ? Translations_fi.Warning : Translations_en_US.Warning;
		Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges = culture === "fi" ? Translations_fi.YouHaveNotSavedChangesDoYouWantToSaveChanges : Translations_en_US.YouHaveNotSavedChangesDoYouWantToSaveChanges;
		Translations.Loading = culture === "fi" ? Translations_fi.Loading : Translations_en_US.Loading;
		Translations.Photo = culture === "fi" ? Translations_fi.Photo : Translations_en_US.Photo;
		Translations.AddToHomeScreen = culture === "fi" ? Translations_fi.AddToHomeScreen : Translations_en_US.AddToHomeScreen;
		Translations.DoYouWantToAddThisApplicationToHomeScreen = culture === "fi" ? Translations_fi.DoYouWantToAddThisApplicationToHomeScreen : Translations_en_US.DoYouWantToAddThisApplicationToHomeScreen;
		Translations.NewVersionAvailable = culture === "fi" ? Translations_fi.NewVersionAvailable : Translations_en_US.NewVersionAvailable;
		Translations.Update = culture === "fi" ? Translations_fi.Update : Translations_en_US.Update;
		Translations.Contacts = culture === "fi" ? Translations_fi.Contacts : Translations_en_US.Contacts;
		Translations.CustomerParameterNotFound = culture === "fi" ? Translations_fi.CustomerParameterNotFound : Translations_en_US.CustomerParameterNotFound;
		Translations.Customers = culture === "fi" ? Translations_fi.Customers : Translations_en_US.Customers;
		Translations.CustomerSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.CustomerSaveFailedConcurrencyError : Translations_en_US.CustomerSaveFailedConcurrencyError;
		Translations.CustomerSaveFailedErrorParameter = culture === "fi" ? Translations_fi.CustomerSaveFailedErrorParameter : Translations_en_US.CustomerSaveFailedErrorParameter;
		Translations.CustomerSaveSuccess = culture === "fi" ? Translations_fi.CustomerSaveSuccess : Translations_en_US.CustomerSaveSuccess;
		Translations.Employees = culture === "fi" ? Translations_fi.Employees : Translations_en_US.Employees;
		Translations.ErpReference = culture === "fi" ? Translations_fi.ErpReference : Translations_en_US.ErpReference;
		Translations.General = culture === "fi" ? Translations_fi.General : Translations_en_US.General;
		Translations.Products = culture === "fi" ? Translations_fi.Products : Translations_en_US.Products;
		Translations.Name = culture === "fi" ? Translations_fi.Name : Translations_en_US.Name;
		Translations.NameMustBeDefined = culture === "fi" ? Translations_fi.NameMustBeDefined : Translations_en_US.NameMustBeDefined;
		Translations.Next = culture === "fi" ? Translations_fi.Next : Translations_en_US.Next;
		Translations.Previous = culture === "fi" ? Translations_fi.Previous : Translations_en_US.Previous;
		Translations.Receipts = culture === "fi" ? Translations_fi.Receipts : Translations_en_US.Receipts;
		Translations.Search = culture === "fi" ? Translations_fi.Search : Translations_en_US.Search;
		Translations.SearchCondition = culture === "fi" ? Translations_fi.SearchCondition : Translations_en_US.SearchCondition;
		Translations.Sites = culture === "fi" ? Translations_fi.Sites : Translations_en_US.Sites;
		Translations.Tools = culture === "fi" ? Translations_fi.Tools : Translations_en_US.Tools;
		Translations.Vehicles = culture === "fi" ? Translations_fi.Vehicles : Translations_en_US.Vehicles;
		Translations.WorkOrderTypes = culture === "fi" ? Translations_fi.WorkOrderTypes : Translations_en_US.WorkOrderTypes;
		Translations.EventLogMessageWorkOrderCreated = culture === "fi" ? Translations_fi.EventLogMessageWorkOrderCreated : Translations_en_US.EventLogMessageWorkOrderCreated;
		Translations.EventLogMessageWorkOrderRemoved = culture === "fi" ? Translations_fi.EventLogMessageWorkOrderRemoved : Translations_en_US.EventLogMessageWorkOrderRemoved;
		Translations.EventLogMessageWorkOrderStateChanged = culture === "fi" ? Translations_fi.EventLogMessageWorkOrderStateChanged : Translations_en_US.EventLogMessageWorkOrderStateChanged;
		Translations.Events = culture === "fi" ? Translations_fi.Events : Translations_en_US.Events;
		Translations.WorkOrderEventsTab = culture === "fi" ? Translations_fi.WorkOrderEventsTab : Translations_en_US.WorkOrderEventsTab;
		Translations.WorkOrderRemoveConfirmation = culture === "fi" ? Translations_fi.WorkOrderRemoveConfirmation : Translations_en_US.WorkOrderRemoveConfirmation;
		Translations.WorkOrderRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrderRemoveFailedConcurrencyError : Translations_en_US.WorkOrderRemoveFailedConcurrencyError;
		Translations.WorkOrderRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderRemoveFailedErrorParameter : Translations_en_US.WorkOrderRemoveFailedErrorParameter;
		Translations.WorkOrdersRemoveSuccess = culture === "fi" ? Translations_fi.WorkOrdersRemoveSuccess : Translations_en_US.WorkOrdersRemoveSuccess;
		Translations.CustomerParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders = culture === "fi" ? Translations_fi.CustomerParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders : Translations_en_US.CustomerParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders;
		Translations.CustomerRemoveConfirmation = culture === "fi" ? Translations_fi.CustomerRemoveConfirmation : Translations_en_US.CustomerRemoveConfirmation;
		Translations.CustomerRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.CustomerRemoveFailedConcurrencyError : Translations_en_US.CustomerRemoveFailedConcurrencyError;
		Translations.CustomerRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.CustomerRemoveFailedErrorParameter : Translations_en_US.CustomerRemoveFailedErrorParameter;
		Translations.CustomerRemoveSuccess = culture === "fi" ? Translations_fi.CustomerRemoveSuccess : Translations_en_US.CustomerRemoveSuccess;
		Translations.EmployeeRemoveConfirmation = culture === "fi" ? Translations_fi.EmployeeRemoveConfirmation : Translations_en_US.EmployeeRemoveConfirmation;
		Translations.EventLogCustomerRemoved = culture === "fi" ? Translations_fi.EventLogCustomerRemoved : Translations_en_US.EventLogCustomerRemoved;
		Translations.EventLogMessageCustomerCreated = culture === "fi" ? Translations_fi.EventLogMessageCustomerCreated : Translations_en_US.EventLogMessageCustomerCreated;
		Translations.InUse = culture === "fi" ? Translations_fi.InUse : Translations_en_US.InUse;
		Translations.Number = culture === "fi" ? Translations_fi.Number : Translations_en_US.Number;
		Translations.ChangePassword = culture === "fi" ? Translations_fi.ChangePassword : Translations_en_US.ChangePassword;
		Translations.DayBookings = culture === "fi" ? Translations_fi.DayBookings : Translations_en_US.DayBookings;
		Translations.Email = culture === "fi" ? Translations_fi.Email : Translations_en_US.Email;
		Translations.EmployeeParameterNotFound = culture === "fi" ? Translations_fi.EmployeeParameterNotFound : Translations_en_US.EmployeeParameterNotFound;
		Translations.FirstName = culture === "fi" ? Translations_fi.FirstName : Translations_en_US.FirstName;
		Translations.FirstNameMustBeDefined = culture === "fi" ? Translations_fi.FirstNameMustBeDefined : Translations_en_US.FirstNameMustBeDefined;
		Translations.LastName = culture === "fi" ? Translations_fi.LastName : Translations_en_US.LastName;
		Translations.LastNameMustBeDefined = culture === "fi" ? Translations_fi.LastNameMustBeDefined : Translations_en_US.LastNameMustBeDefined;
		Translations.Phone = culture === "fi" ? Translations_fi.Phone : Translations_en_US.Phone;
		Translations.UserGroup = culture === "fi" ? Translations_fi.UserGroup : Translations_en_US.UserGroup;
		Translations.ChangePasswordFailedConcurrencyError = culture === "fi" ? Translations_fi.ChangePasswordFailedConcurrencyError : Translations_en_US.ChangePasswordFailedConcurrencyError;
		Translations.ChangePasswordFailedErrorParameter = culture === "fi" ? Translations_fi.ChangePasswordFailedErrorParameter : Translations_en_US.ChangePasswordFailedErrorParameter;
		Translations.ChangePasswordSuccess = culture === "fi" ? Translations_fi.ChangePasswordSuccess : Translations_en_US.ChangePasswordSuccess;
		Translations.ChangeUsername = culture === "fi" ? Translations_fi.ChangeUsername : Translations_en_US.ChangeUsername;
		Translations.ChangeUsernameFailedConcurrencyError = culture === "fi" ? Translations_fi.ChangeUsernameFailedConcurrencyError : Translations_en_US.ChangeUsernameFailedConcurrencyError;
		Translations.ChangeUsernameFailedErrorParameter = culture === "fi" ? Translations_fi.ChangeUsernameFailedErrorParameter : Translations_en_US.ChangeUsernameFailedErrorParameter;
		Translations.ChangeUsernameSuccess = culture === "fi" ? Translations_fi.ChangeUsernameSuccess : Translations_en_US.ChangeUsernameSuccess;
		Translations.CreditCardNumber = culture === "fi" ? Translations_fi.CreditCardNumber : Translations_en_US.CreditCardNumber;
		Translations.CurrentPasswordIsIncorrect = culture === "fi" ? Translations_fi.CurrentPasswordIsIncorrect : Translations_en_US.CurrentPasswordIsIncorrect;
		Translations.DayBooking = culture === "fi" ? Translations_fi.DayBooking : Translations_en_US.DayBooking;
		Translations.DayBookingParameterNotFound = culture === "fi" ? Translations_fi.DayBookingParameterNotFound : Translations_en_US.DayBookingParameterNotFound;
		Translations.DayBookingType = culture === "fi" ? Translations_fi.DayBookingType : Translations_en_US.DayBookingType;
		Translations.Documents = culture === "fi" ? Translations_fi.Documents : Translations_en_US.Documents;
		Translations.EmployeeGroup = culture === "fi" ? Translations_fi.EmployeeGroup : Translations_en_US.EmployeeGroup;
		Translations.EmployeeSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.EmployeeSaveFailedConcurrencyError : Translations_en_US.EmployeeSaveFailedConcurrencyError;
		Translations.EmployeeSaveFailedErrorParameter = culture === "fi" ? Translations_fi.EmployeeSaveFailedErrorParameter : Translations_en_US.EmployeeSaveFailedErrorParameter;
		Translations.EmployeeSaveSuccess = culture === "fi" ? Translations_fi.EmployeeSaveSuccess : Translations_en_US.EmployeeSaveSuccess;
		Translations.EventLogMessageEmployeeCreated = culture === "fi" ? Translations_fi.EventLogMessageEmployeeCreated : Translations_en_US.EventLogMessageEmployeeCreated;
		Translations.NewPassword = culture === "fi" ? Translations_fi.NewPassword : Translations_en_US.NewPassword;
		Translations.NewPasswordConfirmation = culture === "fi" ? Translations_fi.NewPasswordConfirmation : Translations_en_US.NewPasswordConfirmation;
		Translations.NewUsername = culture === "fi" ? Translations_fi.NewUsername : Translations_en_US.NewUsername;
		Translations.PasswordConfirmation = culture === "fi" ? Translations_fi.PasswordConfirmation : Translations_en_US.PasswordConfirmation;
		Translations.PasswordConfirmationDoesNotMatchPassword = culture === "fi" ? Translations_fi.PasswordConfirmationDoesNotMatchPassword : Translations_en_US.PasswordConfirmationDoesNotMatchPassword;
		Translations.PasswordMustBeDefined = culture === "fi" ? Translations_fi.PasswordMustBeDefined : Translations_en_US.PasswordMustBeDefined;
		Translations.PasswordMustMeetComplexityRequirements = culture === "fi" ? Translations_fi.PasswordMustMeetComplexityRequirements : Translations_en_US.PasswordMustMeetComplexityRequirements;
		Translations.UsernameIsAlreadyInUse = culture === "fi" ? Translations_fi.UsernameIsAlreadyInUse : Translations_en_US.UsernameIsAlreadyInUse;
		Translations.UsernameMustBeDefined = culture === "fi" ? Translations_fi.UsernameMustBeDefined : Translations_en_US.UsernameMustBeDefined;
		Translations.UsernameMustMeetComplexityRequirements = culture === "fi" ? Translations_fi.UsernameMustMeetComplexityRequirements : Translations_en_US.UsernameMustMeetComplexityRequirements;
		Translations.CityAddress = culture === "fi" ? Translations_fi.CityAddress : Translations_en_US.CityAddress;
		Translations.ContactParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders = culture === "fi" ? Translations_fi.ContactParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders : Translations_en_US.ContactParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders;
		Translations.ContactParameterNotFound = culture === "fi" ? Translations_fi.ContactParameterNotFound : Translations_en_US.ContactParameterNotFound;
		Translations.ContactRemoveConfirmation = culture === "fi" ? Translations_fi.ContactRemoveConfirmation : Translations_en_US.ContactRemoveConfirmation;
		Translations.ContactRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.ContactRemoveFailedConcurrencyError : Translations_en_US.ContactRemoveFailedConcurrencyError;
		Translations.ContactRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.ContactRemoveFailedErrorParameter : Translations_en_US.ContactRemoveFailedErrorParameter;
		Translations.ContactRemoveSuccess = culture === "fi" ? Translations_fi.ContactRemoveSuccess : Translations_en_US.ContactRemoveSuccess;
		Translations.ContactSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.ContactSaveFailedConcurrencyError : Translations_en_US.ContactSaveFailedConcurrencyError;
		Translations.ContactSaveFailedErrorParameter = culture === "fi" ? Translations_fi.ContactSaveFailedErrorParameter : Translations_en_US.ContactSaveFailedErrorParameter;
		Translations.ContactSaveSuccess = culture === "fi" ? Translations_fi.ContactSaveSuccess : Translations_en_US.ContactSaveSuccess;
		Translations.CustomerSiteNumber = culture === "fi" ? Translations_fi.CustomerSiteNumber : Translations_en_US.CustomerSiteNumber;
		Translations.Distance = culture === "fi" ? Translations_fi.Distance : Translations_en_US.Distance;
		Translations.EmployeeParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders = culture === "fi" ? Translations_fi.EmployeeParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders : Translations_en_US.EmployeeParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders;
		Translations.EmployeeRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.EmployeeRemoveFailedConcurrencyError : Translations_en_US.EmployeeRemoveFailedConcurrencyError;
		Translations.EmployeeRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.EmployeeRemoveFailedErrorParameter : Translations_en_US.EmployeeRemoveFailedErrorParameter;
		Translations.EmployeeRemoveSuccess = culture === "fi" ? Translations_fi.EmployeeRemoveSuccess : Translations_en_US.EmployeeRemoveSuccess;
		Translations.MapLink = culture === "fi" ? Translations_fi.MapLink : Translations_en_US.MapLink;
		Translations.PostalCode = culture === "fi" ? Translations_fi.PostalCode : Translations_en_US.PostalCode;
		Translations.SelectContact = culture === "fi" ? Translations_fi.SelectContact : Translations_en_US.SelectContact;
		Translations.SiteParameterNotFound = culture === "fi" ? Translations_fi.SiteParameterNotFound : Translations_en_US.SiteParameterNotFound;
		Translations.Specifier = culture === "fi" ? Translations_fi.Specifier : Translations_en_US.Specifier;
		Translations.StreetAddress = culture === "fi" ? Translations_fi.StreetAddress : Translations_en_US.StreetAddress;
		Translations.Brand = culture === "fi" ? Translations_fi.Brand : Translations_en_US.Brand;
		Translations.Description = culture === "fi" ? Translations_fi.Description : Translations_en_US.Description;
		Translations.RegisterNumber = culture === "fi" ? Translations_fi.RegisterNumber : Translations_en_US.RegisterNumber;
		Translations.RegisterNumberMustBeDefined = culture === "fi" ? Translations_fi.RegisterNumberMustBeDefined : Translations_en_US.RegisterNumberMustBeDefined;
		Translations.RideBookingRequired = culture === "fi" ? Translations_fi.RideBookingRequired : Translations_en_US.RideBookingRequired;
		Translations.SelectEmployee = culture === "fi" ? Translations_fi.SelectEmployee : Translations_en_US.SelectEmployee;
		Translations.SelectTool = culture === "fi" ? Translations_fi.SelectTool : Translations_en_US.SelectTool;
		Translations.Tool = culture === "fi" ? Translations_fi.Tool : Translations_en_US.Tool;
		Translations.ToolParameterNotFound = culture === "fi" ? Translations_fi.ToolParameterNotFound : Translations_en_US.ToolParameterNotFound;
		Translations.ToolRemoveConfirmation = culture === "fi" ? Translations_fi.ToolRemoveConfirmation : Translations_en_US.ToolRemoveConfirmation;
		Translations.ToolRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.ToolRemoveFailedConcurrencyError : Translations_en_US.ToolRemoveFailedConcurrencyError;
		Translations.ToolRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.ToolRemoveFailedErrorParameter : Translations_en_US.ToolRemoveFailedErrorParameter;
		Translations.ToolRemoveSuccess = culture === "fi" ? Translations_fi.ToolRemoveSuccess : Translations_en_US.ToolRemoveSuccess;
		Translations.ToolSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.ToolSaveFailedConcurrencyError : Translations_en_US.ToolSaveFailedConcurrencyError;
		Translations.ToolSaveFailedErrorParameter = culture === "fi" ? Translations_fi.ToolSaveFailedErrorParameter : Translations_en_US.ToolSaveFailedErrorParameter;
		Translations.ToolSaveSuccess = culture === "fi" ? Translations_fi.ToolSaveSuccess : Translations_en_US.ToolSaveSuccess;
		Translations.VehicleParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders = culture === "fi" ? Translations_fi.VehicleParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders : Translations_en_US.VehicleParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders;
		Translations.VehicleParameterNotFound = culture === "fi" ? Translations_fi.VehicleParameterNotFound : Translations_en_US.VehicleParameterNotFound;
		Translations.VehicleRemoveConfirmation = culture === "fi" ? Translations_fi.VehicleRemoveConfirmation : Translations_en_US.VehicleRemoveConfirmation;
		Translations.VehicleRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.VehicleRemoveFailedConcurrencyError : Translations_en_US.VehicleRemoveFailedConcurrencyError;
		Translations.VehicleRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.VehicleRemoveFailedErrorParameter : Translations_en_US.VehicleRemoveFailedErrorParameter;
		Translations.VehicleRemoveSuccess = culture === "fi" ? Translations_fi.VehicleRemoveSuccess : Translations_en_US.VehicleRemoveSuccess;
		Translations.VehicleSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.VehicleSaveFailedConcurrencyError : Translations_en_US.VehicleSaveFailedConcurrencyError;
		Translations.VehicleSaveFailedErrorParameter = culture === "fi" ? Translations_fi.VehicleSaveFailedErrorParameter : Translations_en_US.VehicleSaveFailedErrorParameter;
		Translations.VehicleSaveSuccess = culture === "fi" ? Translations_fi.VehicleSaveSuccess : Translations_en_US.VehicleSaveSuccess;
		Translations.WorkDescriptionMustBeDefined = culture === "fi" ? Translations_fi.WorkDescriptionMustBeDefined : Translations_en_US.WorkDescriptionMustBeDefined;
		Translations.ProductParameterNotFound = culture === "fi" ? Translations_fi.ProductParameterNotFound : Translations_en_US.ProductParameterNotFound;
		Translations.ProductRemoveConfirmation = culture === "fi" ? Translations_fi.ProductRemoveConfirmation : Translations_en_US.ProductRemoveConfirmation;
		Translations.ProductRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.ProductRemoveFailedConcurrencyError : Translations_en_US.ProductRemoveFailedConcurrencyError;
		Translations.ProductRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.ProductRemoveFailedErrorParameter : Translations_en_US.ProductRemoveFailedErrorParameter;
		Translations.ProductRemoveSuccess = culture === "fi" ? Translations_fi.ProductRemoveSuccess : Translations_en_US.ProductRemoveSuccess;
		Translations.ProductSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.ProductSaveFailedConcurrencyError : Translations_en_US.ProductSaveFailedConcurrencyError;
		Translations.ProductSaveFailedErrorParameter = culture === "fi" ? Translations_fi.ProductSaveFailedErrorParameter : Translations_en_US.ProductSaveFailedErrorParameter;
		Translations.ProductSaveSuccess = culture === "fi" ? Translations_fi.ProductSaveSuccess : Translations_en_US.ProductSaveSuccess;
		Translations.MeasureUnit = culture === "fi" ? Translations_fi.MeasureUnit : Translations_en_US.MeasureUnit;
		Translations.PrivacyPolicy = culture === "fi" ? Translations_fi.PrivacyPolicy : Translations_en_US.PrivacyPolicy;
		Translations.ContactIsNotDefined = culture === "fi" ? Translations_fi.ContactIsNotDefined : Translations_en_US.ContactIsNotDefined;
		Translations.DoYouReallyWantToSetWorkOrderDone = culture === "fi" ? Translations_fi.DoYouReallyWantToSetWorkOrderDone : Translations_en_US.DoYouReallyWantToSetWorkOrderDone;
		Translations.DoYouReallyWantToSetWorkOrderPlanned = culture === "fi" ? Translations_fi.DoYouReallyWantToSetWorkOrderPlanned : Translations_en_US.DoYouReallyWantToSetWorkOrderPlanned;
		Translations.EmployeesHaveDayBookingsForWorkOrderTime = culture === "fi" ? Translations_fi.EmployeesHaveDayBookingsForWorkOrderTime : Translations_en_US.EmployeesHaveDayBookingsForWorkOrderTime;
		Translations.HourBookingsMustBeDefined = culture === "fi" ? Translations_fi.HourBookingsMustBeDefined : Translations_en_US.HourBookingsMustBeDefined;
		Translations.HourBookingsAreNotDefinedForAllWorkDays = culture === "fi" ? Translations_fi.HourBookingsAreNotDefinedForAllWorkDays : Translations_en_US.HourBookingsAreNotDefinedForAllWorkDays;
		Translations.ProductBookingsAreNotDefined = culture === "fi" ? Translations_fi.ProductBookingsAreNotDefined : Translations_en_US.ProductBookingsAreNotDefined;
		Translations.RideBookingsMustBeDefined = culture === "fi" ? Translations_fi.RideBookingsMustBeDefined : Translations_en_US.RideBookingsMustBeDefined;
		Translations.StartDateIsInThePast = culture === "fi" ? Translations_fi.StartDateIsInThePast : Translations_en_US.StartDateIsInThePast;
		Translations.MeasureUnitBtl = culture === "fi" ? Translations_fi.MeasureUnitBtl : Translations_en_US.MeasureUnitBtl;
		Translations.MeasureUnitM2 = culture === "fi" ? Translations_fi.MeasureUnitM2 : Translations_en_US.MeasureUnitM2;
		Translations.MeasureUnitM3 = culture === "fi" ? Translations_fi.MeasureUnitM3 : Translations_en_US.MeasureUnitM3;
		Translations.MeasureUnitPkg = culture === "fi" ? Translations_fi.MeasureUnitPkg : Translations_en_US.MeasureUnitPkg;
		Translations.SiteMustBeDefined = culture === "fi" ? Translations_fi.SiteMustBeDefined : Translations_en_US.SiteMustBeDefined;
		Translations.SiteSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.SiteSaveFailedConcurrencyError : Translations_en_US.SiteSaveFailedConcurrencyError;
		Translations.SiteSaveFailedErrorParameter = culture === "fi" ? Translations_fi.SiteSaveFailedErrorParameter : Translations_en_US.SiteSaveFailedErrorParameter;
		Translations.SiteSaveSuccess = culture === "fi" ? Translations_fi.SiteSaveSuccess : Translations_en_US.SiteSaveSuccess;
		Translations.SitesThatRelatedToWorkOrdersCannotBeRemoved = culture === "fi" ? Translations_fi.SitesThatRelatedToWorkOrdersCannotBeRemoved : Translations_en_US.SitesThatRelatedToWorkOrdersCannotBeRemoved;
		Translations.NoWorkOrdersForThisDay = culture === "fi" ? Translations_fi.NoWorkOrdersForThisDay : Translations_en_US.NoWorkOrdersForThisDay;
		Translations.AttachmentRemoveConfirmation = culture === "fi" ? Translations_fi.AttachmentRemoveConfirmation : Translations_en_US.AttachmentRemoveConfirmation;
		Translations.HourBookingRemoveConfirmation = culture === "fi" ? Translations_fi.HourBookingRemoveConfirmation : Translations_en_US.HourBookingRemoveConfirmation;
		Translations.ProductBookingRemoveConfirmation = culture === "fi" ? Translations_fi.ProductBookingRemoveConfirmation : Translations_en_US.ProductBookingRemoveConfirmation;
		Translations.RideBookingRemoveConfirmation = culture === "fi" ? Translations_fi.RideBookingRemoveConfirmation : Translations_en_US.RideBookingRemoveConfirmation;
		Translations.Code = culture === "fi" ? Translations_fi.Code : Translations_en_US.Code;
		Translations.DateRangeEnd = culture === "fi" ? Translations_fi.DateRangeEnd : Translations_en_US.DateRangeEnd;
		Translations.DateRangeStart = culture === "fi" ? Translations_fi.DateRangeStart : Translations_en_US.DateRangeStart;
		Translations.DocumentParameterGenerationFailedParameter = culture === "fi" ? Translations_fi.DocumentParameterGenerationFailedParameter : Translations_en_US.DocumentParameterGenerationFailedParameter;
		Translations.EmployeeReportName = culture === "fi" ? Translations_fi.EmployeeReportName : Translations_en_US.EmployeeReportName;
		Translations.Message = culture === "fi" ? Translations_fi.Message : Translations_en_US.Message;
		Translations.Print = culture === "fi" ? Translations_fi.Print : Translations_en_US.Print;
		Translations.SelectDateRange = culture === "fi" ? Translations_fi.SelectDateRange : Translations_en_US.SelectDateRange;
		Translations.WorkOrderAcknowledgementType = culture === "fi" ? Translations_fi.WorkOrderAcknowledgementType : Translations_en_US.WorkOrderAcknowledgementType;
		Translations.DoYouWantToSendWorkOrderAcknowledgementToContact = culture === "fi" ? Translations_fi.DoYouWantToSendWorkOrderAcknowledgementToContact : Translations_en_US.DoYouWantToSendWorkOrderAcknowledgementToContact;
		Translations.Information = culture === "fi" ? Translations_fi.Information : Translations_en_US.Information;
		Translations.AcknowledgementCannotBeSendBecauseValidEmailIsNotDefinedForContact = culture === "fi" ? Translations_fi.AcknowledgementCannotBeSendBecauseValidEmailIsNotDefinedForContact : Translations_en_US.AcknowledgementCannotBeSendBecauseValidEmailIsNotDefinedForContact;
		Translations.EmailAddressParameterIsInvalid = culture === "fi" ? Translations_fi.EmailAddressParameterIsInvalid : Translations_en_US.EmailAddressParameterIsInvalid;
		Translations.SendingEmailFailedErrorParameter = culture === "fi" ? Translations_fi.SendingEmailFailedErrorParameter : Translations_en_US.SendingEmailFailedErrorParameter;
		Translations.AcknowledgementParameterNotFound = culture === "fi" ? Translations_fi.AcknowledgementParameterNotFound : Translations_en_US.AcknowledgementParameterNotFound;
		Translations.ApplicationTempPathIsNotSpecified = culture === "fi" ? Translations_fi.ApplicationTempPathIsNotSpecified : Translations_en_US.ApplicationTempPathIsNotSpecified;
		Translations.ApplicationTempPathParameterNotFound = culture === "fi" ? Translations_fi.ApplicationTempPathParameterNotFound : Translations_en_US.ApplicationTempPathParameterNotFound;
		Translations.ApprovedToBeInvoiced = culture === "fi" ? Translations_fi.ApprovedToBeInvoiced : Translations_en_US.ApprovedToBeInvoiced;
		Translations.ApproveToBeInvoiced = culture === "fi" ? Translations_fi.ApproveToBeInvoiced : Translations_en_US.ApproveToBeInvoiced;
		Translations.CustomerView = culture === "fi" ? Translations_fi.CustomerView : Translations_en_US.CustomerView;
		Translations.LoadingAcknowledgement = culture === "fi" ? Translations_fi.LoadingAcknowledgement : Translations_en_US.LoadingAcknowledgement;
		Translations.MessageContent = culture === "fi" ? Translations_fi.MessageContent : Translations_en_US.MessageContent;
		Translations.MessageContentMustBeDefined = culture === "fi" ? Translations_fi.MessageContentMustBeDefined : Translations_en_US.MessageContentMustBeDefined;
		Translations.MessageSendFailedErrorParameter = culture === "fi" ? Translations_fi.MessageSendFailedErrorParameter : Translations_en_US.MessageSendFailedErrorParameter;
		Translations.MessageSendSuccess = culture === "fi" ? Translations_fi.MessageSendSuccess : Translations_en_US.MessageSendSuccess;
		Translations.MessageSubject = culture === "fi" ? Translations_fi.MessageSubject : Translations_en_US.MessageSubject;
		Translations.MessageSubjectMustBeDefined = culture === "fi" ? Translations_fi.MessageSubjectMustBeDefined : Translations_en_US.MessageSubjectMustBeDefined;
		Translations.MessageToAcknowledgementRequest = culture === "fi" ? Translations_fi.MessageToAcknowledgementRequest : Translations_en_US.MessageToAcknowledgementRequest;
		Translations.Send = culture === "fi" ? Translations_fi.Send : Translations_en_US.Send;
		Translations.Sender = culture === "fi" ? Translations_fi.Sender : Translations_en_US.Sender;
		Translations.SendMessage = culture === "fi" ? Translations_fi.SendMessage : Translations_en_US.SendMessage;
		Translations.ThisBrowserDoesNotSupportPdfsPleaseDownloadPdfToViewIt = culture === "fi" ? Translations_fi.ThisBrowserDoesNotSupportPdfsPleaseDownloadPdfToViewIt : Translations_en_US.ThisBrowserDoesNotSupportPdfsPleaseDownloadPdfToViewIt;
		Translations.AcknowledgementParameterHasAlreadyBeenApproved = culture === "fi" ? Translations_fi.AcknowledgementParameterHasAlreadyBeenApproved : Translations_en_US.AcknowledgementParameterHasAlreadyBeenApproved;
		Translations.EventLogMessageWorkOrderApprovalRequest = culture === "fi" ? Translations_fi.EventLogMessageWorkOrderApprovalRequest : Translations_en_US.EventLogMessageWorkOrderApprovalRequest;
		Translations.EventLogMessageWorkOrderApproved = culture === "fi" ? Translations_fi.EventLogMessageWorkOrderApproved : Translations_en_US.EventLogMessageWorkOrderApproved;
		Translations.EventLogSaveFailedErrorParameter = culture === "fi" ? Translations_fi.EventLogSaveFailedErrorParameter : Translations_en_US.EventLogSaveFailedErrorParameter;
		Translations.EventLogSaveSuccess = culture === "fi" ? Translations_fi.EventLogSaveSuccess : Translations_en_US.EventLogSaveSuccess;
		Translations.WorkOrderAcknowledgementApprovalFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrderAcknowledgementApprovalFailedConcurrencyError : Translations_en_US.WorkOrderAcknowledgementApprovalFailedConcurrencyError;
		Translations.WorkOrderAcknowledgementApprovalFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderAcknowledgementApprovalFailedErrorParameter : Translations_en_US.WorkOrderAcknowledgementApprovalFailedErrorParameter;
		Translations.WorkOrderAcknowledgementApprovalSuccess = culture === "fi" ? Translations_fi.WorkOrderAcknowledgementApprovalSuccess : Translations_en_US.WorkOrderAcknowledgementApprovalSuccess;
		Translations.AcknowledgementSentToAddress = culture === "fi" ? Translations_fi.AcknowledgementSentToAddress : Translations_en_US.AcknowledgementSentToAddress;
		Translations.DoYouReallyWantToSaveData = culture === "fi" ? Translations_fi.DoYouReallyWantToSaveData : Translations_en_US.DoYouReallyWantToSaveData;
		Translations.DoYouWantToMakeProductBookingsBeforehand = culture === "fi" ? Translations_fi.DoYouWantToMakeProductBookingsBeforehand : Translations_en_US.DoYouWantToMakeProductBookingsBeforehand;
		Translations.EmployeeWhenVehicleIsNotDefinedEmployeeCannotMakeRideBookings = culture === "fi" ? Translations_fi.EmployeeWhenVehicleIsNotDefinedEmployeeCannotMakeRideBookings : Translations_en_US.EmployeeWhenVehicleIsNotDefinedEmployeeCannotMakeRideBookings;
		Translations.HourBookingAmountIsZero = culture === "fi" ? Translations_fi.HourBookingAmountIsZero : Translations_en_US.HourBookingAmountIsZero;
		Translations.HourBookingCommentMustBeDefinedIfAmountIsZero = culture === "fi" ? Translations_fi.HourBookingCommentMustBeDefinedIfAmountIsZero : Translations_en_US.HourBookingCommentMustBeDefinedIfAmountIsZero;
		Translations.ProductBookingProductMustBeDefined = culture === "fi" ? Translations_fi.ProductBookingProductMustBeDefined : Translations_en_US.ProductBookingProductMustBeDefined;
		Translations.RideBookingVehicleMustBeDefined = culture === "fi" ? Translations_fi.RideBookingVehicleMustBeDefined : Translations_en_US.RideBookingVehicleMustBeDefined;
		Translations.Browser = culture === "fi" ? Translations_fi.Browser : Translations_en_US.Browser;
		Translations.EntryType = culture === "fi" ? Translations_fi.EntryType : Translations_en_US.EntryType;
		Translations.EventLog = culture === "fi" ? Translations_fi.EventLog : Translations_en_US.EventLog;
		Translations.IpAddress = culture === "fi" ? Translations_fi.IpAddress : Translations_en_US.IpAddress;
		Translations.LogEntryType = culture === "fi" ? Translations_fi.LogEntryType : Translations_en_US.LogEntryType;
		Translations.ModifyUserName = culture === "fi" ? Translations_fi.ModifyUserName : Translations_en_US.ModifyUserName;
		Translations.Open = culture === "fi" ? Translations_fi.Open : Translations_en_US.Open;
		Translations.Operation = culture === "fi" ? Translations_fi.Operation : Translations_en_US.Operation;
		Translations.OperationLog = culture === "fi" ? Translations_fi.OperationLog : Translations_en_US.OperationLog;
		Translations.OperationLogParameterNotFound = culture === "fi" ? Translations_fi.OperationLogParameterNotFound : Translations_en_US.OperationLogParameterNotFound;
		Translations.Parameters = culture === "fi" ? Translations_fi.Parameters : Translations_en_US.Parameters;
		Translations.RelatedObjectType = culture === "fi" ? Translations_fi.RelatedObjectType : Translations_en_US.RelatedObjectType;
		Translations.SoftwareVersion = culture === "fi" ? Translations_fi.SoftwareVersion : Translations_en_US.SoftwareVersion;
		Translations.Time = culture === "fi" ? Translations_fi.Time : Translations_en_US.Time;
		Translations.Tracking = culture === "fi" ? Translations_fi.Tracking : Translations_en_US.Tracking;
		Translations.ContactWithEmailMustBeDefinedWhenAcknowledementTypeIsNotDoNotSend = culture === "fi" ? Translations_fi.ContactWithEmailMustBeDefinedWhenAcknowledementTypeIsNotDoNotSend : Translations_en_US.ContactWithEmailMustBeDefinedWhenAcknowledementTypeIsNotDoNotSend;
		Translations.EmployeesThatHaveWorkedInSite = culture === "fi" ? Translations_fi.EmployeesThatHaveWorkedInSite : Translations_en_US.EmployeesThatHaveWorkedInSite;
		Translations.OpenMapLink = culture === "fi" ? Translations_fi.OpenMapLink : Translations_en_US.OpenMapLink;
		Translations.SiteFamiliarizationRequired = culture === "fi" ? Translations_fi.SiteFamiliarizationRequired : Translations_en_US.SiteFamiliarizationRequired;
		Translations.SiteSamiliarizationType1Suffix = culture === "fi" ? Translations_fi.SiteSamiliarizationType1Suffix : Translations_en_US.SiteSamiliarizationType1Suffix;
		Translations.WorkOrderRemoveConfirmationAcknowledgementSent = culture === "fi" ? Translations_fi.WorkOrderRemoveConfirmationAcknowledgementSent : Translations_en_US.WorkOrderRemoveConfirmationAcknowledgementSent;
		Translations.CreateMapLink = culture === "fi" ? Translations_fi.CreateMapLink : Translations_en_US.CreateMapLink;
		Translations.ProductBookingAmountMustBeGreaterThanZero = culture === "fi" ? Translations_fi.ProductBookingAmountMustBeGreaterThanZero : Translations_en_US.ProductBookingAmountMustBeGreaterThanZero;
		Translations.NextVehicleInspection = culture === "fi" ? Translations_fi.NextVehicleInspection : Translations_en_US.NextVehicleInspection;
		Translations.ShowNotInUse = culture === "fi" ? Translations_fi.ShowNotInUse : Translations_en_US.ShowNotInUse;
		Translations.Competencies = culture === "fi" ? Translations_fi.Competencies : Translations_en_US.Competencies;
		Translations.Competency = culture === "fi" ? Translations_fi.Competency : Translations_en_US.Competency;
		Translations.CompetencyParameterCannotBeRemovedBecauseItIsRelatedToParameterEmployees = culture === "fi" ? Translations_fi.CompetencyParameterCannotBeRemovedBecauseItIsRelatedToParameterEmployees : Translations_en_US.CompetencyParameterCannotBeRemovedBecauseItIsRelatedToParameterEmployees;
		Translations.CompetencyParameterNotFound = culture === "fi" ? Translations_fi.CompetencyParameterNotFound : Translations_en_US.CompetencyParameterNotFound;
		Translations.CompetencyRemoveConfirmation = culture === "fi" ? Translations_fi.CompetencyRemoveConfirmation : Translations_en_US.CompetencyRemoveConfirmation;
		Translations.CompetencyRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.CompetencyRemoveFailedConcurrencyError : Translations_en_US.CompetencyRemoveFailedConcurrencyError;
		Translations.CompetencyRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.CompetencyRemoveFailedErrorParameter : Translations_en_US.CompetencyRemoveFailedErrorParameter;
		Translations.CompetencyRemoveSuccess = culture === "fi" ? Translations_fi.CompetencyRemoveSuccess : Translations_en_US.CompetencyRemoveSuccess;
		Translations.CompetencySaveFailedConcurrencyError = culture === "fi" ? Translations_fi.CompetencySaveFailedConcurrencyError : Translations_en_US.CompetencySaveFailedConcurrencyError;
		Translations.CompetencySaveFailedErrorParameter = culture === "fi" ? Translations_fi.CompetencySaveFailedErrorParameter : Translations_en_US.CompetencySaveFailedErrorParameter;
		Translations.CompetencySaveSuccess = culture === "fi" ? Translations_fi.CompetencySaveSuccess : Translations_en_US.CompetencySaveSuccess;
		Translations.CompetencyType = culture === "fi" ? Translations_fi.CompetencyType : Translations_en_US.CompetencyType;
		Translations.CompetencyTypesNotFound = culture === "fi" ? Translations_fi.CompetencyTypesNotFound : Translations_en_US.CompetencyTypesNotFound;
		Translations.CompetenciesNotFound = culture === "fi" ? Translations_fi.CompetenciesNotFound : Translations_en_US.CompetenciesNotFound;
		Translations.EmployeeCompetencies = culture === "fi" ? Translations_fi.EmployeeCompetencies : Translations_en_US.EmployeeCompetencies;
		Translations.EmployeeCompetencyParameterNotFound = culture === "fi" ? Translations_fi.EmployeeCompetencyParameterNotFound : Translations_en_US.EmployeeCompetencyParameterNotFound;
		Translations.EmployeeCompetencyRemoveConfirmation = culture === "fi" ? Translations_fi.EmployeeCompetencyRemoveConfirmation : Translations_en_US.EmployeeCompetencyRemoveConfirmation;
		Translations.EmployeeCompetencyRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.EmployeeCompetencyRemoveFailedConcurrencyError : Translations_en_US.EmployeeCompetencyRemoveFailedConcurrencyError;
		Translations.EmployeeCompetencyRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.EmployeeCompetencyRemoveFailedErrorParameter : Translations_en_US.EmployeeCompetencyRemoveFailedErrorParameter;
		Translations.EmployeeCompetencyRemoveSuccess = culture === "fi" ? Translations_fi.EmployeeCompetencyRemoveSuccess : Translations_en_US.EmployeeCompetencyRemoveSuccess;
		Translations.EmployeeCompetencySaveFailedConcurrencyError = culture === "fi" ? Translations_fi.EmployeeCompetencySaveFailedConcurrencyError : Translations_en_US.EmployeeCompetencySaveFailedConcurrencyError;
		Translations.EmployeeCompetencySaveFailedErrorParameter = culture === "fi" ? Translations_fi.EmployeeCompetencySaveFailedErrorParameter : Translations_en_US.EmployeeCompetencySaveFailedErrorParameter;
		Translations.EmployeeCompetencySaveSuccess = culture === "fi" ? Translations_fi.EmployeeCompetencySaveSuccess : Translations_en_US.EmployeeCompetencySaveSuccess;
		Translations.EmployeesNotFound = culture === "fi" ? Translations_fi.EmployeesNotFound : Translations_en_US.EmployeesNotFound;
		Translations.ExpirationDate = culture === "fi" ? Translations_fi.ExpirationDate : Translations_en_US.ExpirationDate;
		Translations.GrantDate = culture === "fi" ? Translations_fi.GrantDate : Translations_en_US.GrantDate;
		Translations.ExpirationDateAbrv = culture === "fi" ? Translations_fi.ExpirationDateAbrv : Translations_en_US.ExpirationDateAbrv;
		Translations.GrantDateAbrv = culture === "fi" ? Translations_fi.GrantDateAbrv : Translations_en_US.GrantDateAbrv;
		Translations.AllStates = culture === "fi" ? Translations_fi.AllStates : Translations_en_US.AllStates;
		Translations.CreateInvoiceXmlFailedErrorParameter = culture === "fi" ? Translations_fi.CreateInvoiceXmlFailedErrorParameter : Translations_en_US.CreateInvoiceXmlFailedErrorParameter;
		Translations.CreateInvoiceXmlSuccess = culture === "fi" ? Translations_fi.CreateInvoiceXmlSuccess : Translations_en_US.CreateInvoiceXmlSuccess;
		Translations.DownloadInvoiceFile = culture === "fi" ? Translations_fi.DownloadInvoiceFile : Translations_en_US.DownloadInvoiceFile;
		Translations.SetWorkOrdersAndInvoicesToTransferredState = culture === "fi" ? Translations_fi.SetWorkOrdersAndInvoicesToTransferredState : Translations_en_US.SetWorkOrdersAndInvoicesToTransferredState;
		Translations.TakeToInvoicing = culture === "fi" ? Translations_fi.TakeToInvoicing : Translations_en_US.TakeToInvoicing;
		Translations.ValueOfOptionParameterNotSpecified = culture === "fi" ? Translations_fi.ValueOfOptionParameterNotSpecified : Translations_en_US.ValueOfOptionParameterNotSpecified;
		Translations.AdditionalInformation = culture === "fi" ? Translations_fi.AdditionalInformation : Translations_en_US.AdditionalInformation;
		Translations.NotFullDay = culture === "fi" ? Translations_fi.NotFullDay : Translations_en_US.NotFullDay;
		Translations.PermissionDenied = culture === "fi" ? Translations_fi.PermissionDenied : Translations_en_US.PermissionDenied;
		Translations.WorkOrderInvoiceFile = culture === "fi" ? Translations_fi.WorkOrderInvoiceFile : Translations_en_US.WorkOrderInvoiceFile;
		Translations.WorkOrderInvoicePrint = culture === "fi" ? Translations_fi.WorkOrderInvoicePrint : Translations_en_US.WorkOrderInvoicePrint;
		Translations.FileParameterDownloaded = culture === "fi" ? Translations_fi.FileParameterDownloaded : Translations_en_US.FileParameterDownloaded;
		Translations.Camera = culture === "fi" ? Translations_fi.Camera : Translations_en_US.Camera;
		Translations.CombineCustomersWorkOrdersToSameInvoice = culture === "fi" ? Translations_fi.CombineCustomersWorkOrdersToSameInvoice : Translations_en_US.CombineCustomersWorkOrdersToSameInvoice;
		Translations.ConvertToProject = culture === "fi" ? Translations_fi.ConvertToProject : Translations_en_US.ConvertToProject;
		Translations.OnlyWorkOrderThatIsNotAttachedToProjectCanBeConvertedToProject = culture === "fi" ? Translations_fi.OnlyWorkOrderThatIsNotAttachedToProjectCanBeConvertedToProject : Translations_en_US.OnlyWorkOrderThatIsNotAttachedToProjectCanBeConvertedToProject;
		Translations.WorkOrderProject = culture === "fi" ? Translations_fi.WorkOrderProject : Translations_en_US.WorkOrderProject;
		Translations.WorkOrderTask = culture === "fi" ? Translations_fi.WorkOrderTask : Translations_en_US.WorkOrderTask;
		Translations.WorkOrderWork = culture === "fi" ? Translations_fi.WorkOrderWork : Translations_en_US.WorkOrderWork;
		Translations.WorkTitle = culture === "fi" ? Translations_fi.WorkTitle : Translations_en_US.WorkTitle;
		Translations.WorkTitleMustBeDefined = culture === "fi" ? Translations_fi.WorkTitleMustBeDefined : Translations_en_US.WorkTitleMustBeDefined;
		Translations.AbrDays = culture === "fi" ? Translations_fi.AbrDays : Translations_en_US.AbrDays;
		Translations.Attach = culture === "fi" ? Translations_fi.Attach : Translations_en_US.Attach;
		Translations.DoYouReallyWantToConvertWorkOrderWorkToWorkOrderProject = culture === "fi" ? Translations_fi.DoYouReallyWantToConvertWorkOrderWorkToWorkOrderProject : Translations_en_US.DoYouReallyWantToConvertWorkOrderWorkToWorkOrderProject;
		Translations.AttachWorkToProject = culture === "fi" ? Translations_fi.AttachWorkToProject : Translations_en_US.AttachWorkToProject;
		Translations.EventLogMessageWorkOrderAttachedToProjectParameter = culture === "fi" ? Translations_fi.EventLogMessageWorkOrderAttachedToProjectParameter : Translations_en_US.EventLogMessageWorkOrderAttachedToProjectParameter;
		Translations.EventLogMessageWorkOrderConvertedToProject = culture === "fi" ? Translations_fi.EventLogMessageWorkOrderConvertedToProject : Translations_en_US.EventLogMessageWorkOrderConvertedToProject;
		Translations.EventLogMessageWorkOrderDetachedFromProjectParameter = culture === "fi" ? Translations_fi.EventLogMessageWorkOrderDetachedFromProjectParameter : Translations_en_US.EventLogMessageWorkOrderDetachedFromProjectParameter;
		Translations.WorkOrderDetachConfirmation = culture === "fi" ? Translations_fi.WorkOrderDetachConfirmation : Translations_en_US.WorkOrderDetachConfirmation;
		Translations.WorkOrderDetachFromProjectErrorParameter = culture === "fi" ? Translations_fi.WorkOrderDetachFromProjectErrorParameter : Translations_en_US.WorkOrderDetachFromProjectErrorParameter;
		Translations.WorkOrderDetachFromProjectSuccess = culture === "fi" ? Translations_fi.WorkOrderDetachFromProjectSuccess : Translations_en_US.WorkOrderDetachFromProjectSuccess;
		Translations.WorkOrderDetachConfirmationRecurring = culture === "fi" ? Translations_fi.WorkOrderDetachConfirmationRecurring : Translations_en_US.WorkOrderDetachConfirmationRecurring;
		Translations.WorkOrderParameterIsNotAttachedToWorkOrderParameter = culture === "fi" ? Translations_fi.WorkOrderParameterIsNotAttachedToWorkOrderParameter : Translations_en_US.WorkOrderParameterIsNotAttachedToWorkOrderParameter;
		Translations.WorkOrderParameterIsNotProject = culture === "fi" ? Translations_fi.WorkOrderParameterIsNotProject : Translations_en_US.WorkOrderParameterIsNotProject;
		Translations.WorkOrderParameterIsNotTask = culture === "fi" ? Translations_fi.WorkOrderParameterIsNotTask : Translations_en_US.WorkOrderParameterIsNotTask;
		Translations.WorkOrdersAttachToProjectErrorParameter = culture === "fi" ? Translations_fi.WorkOrdersAttachToProjectErrorParameter : Translations_en_US.WorkOrdersAttachToProjectErrorParameter;
		Translations.WorkOrdersAttachToProjectSuccess = culture === "fi" ? Translations_fi.WorkOrdersAttachToProjectSuccess : Translations_en_US.WorkOrdersAttachToProjectSuccess;
		Translations.WorkOrdersCanBeAttachedOnlyToProjects = culture === "fi" ? Translations_fi.WorkOrdersCanBeAttachedOnlyToProjects : Translations_en_US.WorkOrdersCanBeAttachedOnlyToProjects;
		Translations.WorkOrdersNotFound = culture === "fi" ? Translations_fi.WorkOrdersNotFound : Translations_en_US.WorkOrdersNotFound;
		Translations.AbrNumber = culture === "fi" ? Translations_fi.AbrNumber : Translations_en_US.AbrNumber;
		Translations.AttachedToProject = culture === "fi" ? Translations_fi.AttachedToProject : Translations_en_US.AttachedToProject;
		Translations.DetachFromProject = culture === "fi" ? Translations_fi.DetachFromProject : Translations_en_US.DetachFromProject;
		Translations.Invoice = culture === "fi" ? Translations_fi.Invoice : Translations_en_US.Invoice;
		Translations.ProjectDoesNotHaveAnyTasksDoYouWantToRemoveProject = culture === "fi" ? Translations_fi.ProjectDoesNotHaveAnyTasksDoYouWantToRemoveProject : Translations_en_US.ProjectDoesNotHaveAnyTasksDoYouWantToRemoveProject;
		Translations.WorkOrderChildDetachConfirmation = culture === "fi" ? Translations_fi.WorkOrderChildDetachConfirmation : Translations_en_US.WorkOrderChildDetachConfirmation;
		Translations.WorkOrderChildDetachRemoveConfirmation = culture === "fi" ? Translations_fi.WorkOrderChildDetachRemoveConfirmation : Translations_en_US.WorkOrderChildDetachRemoveConfirmation;
		Translations.WorkOrderProjectParameterCannotBeRemovedBecauseOtherWorkOrderTasksAreBelongingToIt = culture === "fi" ? Translations_fi.WorkOrderProjectParameterCannotBeRemovedBecauseOtherWorkOrderTasksAreBelongingToIt : Translations_en_US.WorkOrderProjectParameterCannotBeRemovedBecauseOtherWorkOrderTasksAreBelongingToIt;
		Translations.WorkOrderStateUndefined = culture === "fi" ? Translations_fi.WorkOrderStateUndefined : Translations_en_US.WorkOrderStateUndefined;
		Translations.AllWorkOrderTasksMustBeInSameState = culture === "fi" ? Translations_fi.AllWorkOrderTasksMustBeInSameState : Translations_en_US.AllWorkOrderTasksMustBeInSameState;
		Translations.RemoveWorkOrderProjectTasksBeforeRemovingTheProject = culture === "fi" ? Translations_fi.RemoveWorkOrderProjectTasksBeforeRemovingTheProject : Translations_en_US.RemoveWorkOrderProjectTasksBeforeRemovingTheProject;
		Translations.WorkOrderProjectRemoveConfirmation = culture === "fi" ? Translations_fi.WorkOrderProjectRemoveConfirmation : Translations_en_US.WorkOrderProjectRemoveConfirmation;
		Translations.WorkOrderTaskParameterCannotCrossWeekBoundary = culture === "fi" ? Translations_fi.WorkOrderTaskParameterCannotCrossWeekBoundary : Translations_en_US.WorkOrderTaskParameterCannotCrossWeekBoundary;
		Translations.CombineProjectWorkOrdersToSameInvoice = culture === "fi" ? Translations_fi.CombineProjectWorkOrdersToSameInvoice : Translations_en_US.CombineProjectWorkOrdersToSameInvoice;
		Translations.CreateInvoices = culture === "fi" ? Translations_fi.CreateInvoices : Translations_en_US.CreateInvoices;
		Translations.CreateInvoicesSuccess = culture === "fi" ? Translations_fi.CreateInvoicesSuccess : Translations_en_US.CreateInvoicesSuccess;
		Translations.CreateWorkOrderInvoicesFailedErrorParameter = culture === "fi" ? Translations_fi.CreateWorkOrderInvoicesFailedErrorParameter : Translations_en_US.CreateWorkOrderInvoicesFailedErrorParameter;
		Translations.CustomerParameterCannotBeRemovedBecauseItIsRelatedToParameterInvoices = culture === "fi" ? Translations_fi.CustomerParameterCannotBeRemovedBecauseItIsRelatedToParameterInvoices : Translations_en_US.CustomerParameterCannotBeRemovedBecauseItIsRelatedToParameterInvoices;
		Translations.EventLogMessageInvoiceCreated = culture === "fi" ? Translations_fi.EventLogMessageInvoiceCreated : Translations_en_US.EventLogMessageInvoiceCreated;
		Translations.EventLogMessageInvoiceRemoved = culture === "fi" ? Translations_fi.EventLogMessageInvoiceRemoved : Translations_en_US.EventLogMessageInvoiceRemoved;
		Translations.EventLogMessageWorkOrderAttachedToInvoiceParameter = culture === "fi" ? Translations_fi.EventLogMessageWorkOrderAttachedToInvoiceParameter : Translations_en_US.EventLogMessageWorkOrderAttachedToInvoiceParameter;
		Translations.EventLogMessageWorkOrderDetachedFromInvoiceParameter = culture === "fi" ? Translations_fi.EventLogMessageWorkOrderDetachedFromInvoiceParameter : Translations_en_US.EventLogMessageWorkOrderDetachedFromInvoiceParameter;
		Translations.InvoiceDataDate = culture === "fi" ? Translations_fi.InvoiceDataDate : Translations_en_US.InvoiceDataDate;
		Translations.InvoiceParameterNotFound = culture === "fi" ? Translations_fi.InvoiceParameterNotFound : Translations_en_US.InvoiceParameterNotFound;
		Translations.InvoiceRemoveConfirmation = culture === "fi" ? Translations_fi.InvoiceRemoveConfirmation : Translations_en_US.InvoiceRemoveConfirmation;
		Translations.InvoiceRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.InvoiceRemoveFailedConcurrencyError : Translations_en_US.InvoiceRemoveFailedConcurrencyError;
		Translations.InvoiceRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.InvoiceRemoveFailedErrorParameter : Translations_en_US.InvoiceRemoveFailedErrorParameter;
		Translations.InvoiceRemoveSuccess = culture === "fi" ? Translations_fi.InvoiceRemoveSuccess : Translations_en_US.InvoiceRemoveSuccess;
		Translations.Invoices = culture === "fi" ? Translations_fi.Invoices : Translations_en_US.Invoices;
		Translations.InvoiceStatePreliminary = culture === "fi" ? Translations_fi.InvoiceStatePreliminary : Translations_en_US.InvoiceStatePreliminary;
		Translations.InvoiceStateTransferred = culture === "fi" ? Translations_fi.InvoiceStateTransferred : Translations_en_US.InvoiceStateTransferred;
		Translations.SelectWorkOrders = culture === "fi" ? Translations_fi.SelectWorkOrders : Translations_en_US.SelectWorkOrders;
		Translations.WorkOrderParameterCannotBeRemovedBecauseItIsRelatedToParameterInvoices = culture === "fi" ? Translations_fi.WorkOrderParameterCannotBeRemovedBecauseItIsRelatedToParameterInvoices : Translations_en_US.WorkOrderParameterCannotBeRemovedBecauseItIsRelatedToParameterInvoices;
		Translations.AddWorkOrdersToInvoiceFailedErrorParameter = culture === "fi" ? Translations_fi.AddWorkOrdersToInvoiceFailedErrorParameter : Translations_en_US.AddWorkOrdersToInvoiceFailedErrorParameter;
		Translations.AddWorkOrdersToInvoiceSuccess = culture === "fi" ? Translations_fi.AddWorkOrdersToInvoiceSuccess : Translations_en_US.AddWorkOrdersToInvoiceSuccess;
		Translations.CustomerSiteNumbers = culture === "fi" ? Translations_fi.CustomerSiteNumbers : Translations_en_US.CustomerSiteNumbers;
		Translations.EventLogMessageInvoiceStateChanged = culture === "fi" ? Translations_fi.EventLogMessageInvoiceStateChanged : Translations_en_US.EventLogMessageInvoiceStateChanged;
		Translations.StateOfAllInvoicesMustBeParameter = culture === "fi" ? Translations_fi.StateOfAllInvoicesMustBeParameter : Translations_en_US.StateOfAllInvoicesMustBeParameter;
		Translations.WorkOrderRemoveFromInvoiceConfirmation = culture === "fi" ? Translations_fi.WorkOrderRemoveFromInvoiceConfirmation : Translations_en_US.WorkOrderRemoveFromInvoiceConfirmation;
		Translations.WorkOrderRemoveFromInvoiceFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrderRemoveFromInvoiceFailedConcurrencyError : Translations_en_US.WorkOrderRemoveFromInvoiceFailedConcurrencyError;
		Translations.WorkOrderRemoveFromInvoiceFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderRemoveFromInvoiceFailedErrorParameter : Translations_en_US.WorkOrderRemoveFromInvoiceFailedErrorParameter;
		Translations.WorkOrderRemoveFromInvoiceSuccess = culture === "fi" ? Translations_fi.WorkOrderRemoveFromInvoiceSuccess : Translations_en_US.WorkOrderRemoveFromInvoiceSuccess;
		Translations.WorkOrdersToBeAddedMustHaveSameCustomerAsInvoice = culture === "fi" ? Translations_fi.WorkOrdersToBeAddedMustHaveSameCustomerAsInvoice : Translations_en_US.WorkOrdersToBeAddedMustHaveSameCustomerAsInvoice;
		Translations.IsRelatedToInvoiceParameter = culture === "fi" ? Translations_fi.IsRelatedToInvoiceParameter : Translations_en_US.IsRelatedToInvoiceParameter;
		Translations.NoCompetenciesDefined = culture === "fi" ? Translations_fi.NoCompetenciesDefined : Translations_en_US.NoCompetenciesDefined;
		Translations.AllWorkOrderTypes = culture === "fi" ? Translations_fi.AllWorkOrderTypes : Translations_en_US.AllWorkOrderTypes;
		Translations.CustomerErpReference = culture === "fi" ? Translations_fi.CustomerErpReference : Translations_en_US.CustomerErpReference;
		Translations.EventLogMessageInvoiceFileCreated = culture === "fi" ? Translations_fi.EventLogMessageInvoiceFileCreated : Translations_en_US.EventLogMessageInvoiceFileCreated;
		Translations.WorkOrdersToBeAddedMustHaveSameWorkOrderTypeAsInvoice = culture === "fi" ? Translations_fi.WorkOrdersToBeAddedMustHaveSameWorkOrderTypeAsInvoice : Translations_en_US.WorkOrdersToBeAddedMustHaveSameWorkOrderTypeAsInvoice;
		Translations.Definitions = culture === "fi" ? Translations_fi.Definitions : Translations_en_US.Definitions;
		Translations.NewWork = culture === "fi" ? Translations_fi.NewWork : Translations_en_US.NewWork;
		Translations.Show = culture === "fi" ? Translations_fi.Show : Translations_en_US.Show;
		Translations.Team = culture === "fi" ? Translations_fi.Team : Translations_en_US.Team;
		Translations.NoMatchesFound = culture === "fi" ? Translations_fi.NoMatchesFound : Translations_en_US.NoMatchesFound;
		Translations.ShowAdditionalResults = culture === "fi" ? Translations_fi.ShowAdditionalResults : Translations_en_US.ShowAdditionalResults;
		Translations.Collapse = culture === "fi" ? Translations_fi.Collapse : Translations_en_US.Collapse;
		Translations.Expand = culture === "fi" ? Translations_fi.Expand : Translations_en_US.Expand;
		Translations.Calendar = culture === "fi" ? Translations_fi.Calendar : Translations_en_US.Calendar;
		Translations.InvoicingTitle = culture === "fi" ? Translations_fi.InvoicingTitle : Translations_en_US.InvoicingTitle;
		Translations.MapTitle = culture === "fi" ? Translations_fi.MapTitle : Translations_en_US.MapTitle;
		Translations.WorkCalendarTitle = culture === "fi" ? Translations_fi.WorkCalendarTitle : Translations_en_US.WorkCalendarTitle;
		Translations.WorkListTitle = culture === "fi" ? Translations_fi.WorkListTitle : Translations_en_US.WorkListTitle;
		Translations.WorkTimeTitle = culture === "fi" ? Translations_fi.WorkTimeTitle : Translations_en_US.WorkTimeTitle;
		Translations.Billable = culture === "fi" ? Translations_fi.Billable : Translations_en_US.Billable;
		Translations.Changed = culture === "fi" ? Translations_fi.Changed : Translations_en_US.Changed;
		Translations.Reported = culture === "fi" ? Translations_fi.Reported : Translations_en_US.Reported;
		Translations.WorkingTimeRegistrations = culture === "fi" ? Translations_fi.WorkingTimeRegistrations : Translations_en_US.WorkingTimeRegistrations;
		Translations.CalendarAccuracyDayTwoWeeks = culture === "fi" ? Translations_fi.CalendarAccuracyDayTwoWeeks : Translations_en_US.CalendarAccuracyDayTwoWeeks;
		Translations.CalendarAccuracyQuarterHour = culture === "fi" ? Translations_fi.CalendarAccuracyQuarterHour : Translations_en_US.CalendarAccuracyQuarterHour;
		Translations.NextDay = culture === "fi" ? Translations_fi.NextDay : Translations_en_US.NextDay;
		Translations.PreviousDay = culture === "fi" ? Translations_fi.PreviousDay : Translations_en_US.PreviousDay;
		Translations.Weeks = culture === "fi" ? Translations_fi.Weeks : Translations_en_US.Weeks;
		Translations.CalendarAccuracyFourHours = culture === "fi" ? Translations_fi.CalendarAccuracyFourHours : Translations_en_US.CalendarAccuracyFourHours;
		Translations.AddNew = culture === "fi" ? Translations_fi.AddNew : Translations_en_US.AddNew;
		Translations.OwnSettings = culture === "fi" ? Translations_fi.OwnSettings : Translations_en_US.OwnSettings;
		Translations.SystemConfiguration = culture === "fi" ? Translations_fi.SystemConfiguration : Translations_en_US.SystemConfiguration;
		Translations.Reports = culture === "fi" ? Translations_fi.Reports : Translations_en_US.Reports;
		Translations.Select = culture === "fi" ? Translations_fi.Select : Translations_en_US.Select;
		Translations.Projects = culture === "fi" ? Translations_fi.Projects : Translations_en_US.Projects;
		Translations.Resources = culture === "fi" ? Translations_fi.Resources : Translations_en_US.Resources;
		Translations.StandByWorks = culture === "fi" ? Translations_fi.StandByWorks : Translations_en_US.StandByWorks;
		Translations.Teams = culture === "fi" ? Translations_fi.Teams : Translations_en_US.Teams;
		Translations.SelectedOrder = culture === "fi" ? Translations_fi.SelectedOrder : Translations_en_US.SelectedOrder;
		Translations.FinishedWorks = culture === "fi" ? Translations_fi.FinishedWorks : Translations_en_US.FinishedWorks;
		Translations.TransferredWork = culture === "fi" ? Translations_fi.TransferredWork : Translations_en_US.TransferredWork;
		Translations.Recurrence = culture === "fi" ? Translations_fi.Recurrence : Translations_en_US.Recurrence;
		Translations.Allday = culture === "fi" ? Translations_fi.Allday : Translations_en_US.Allday;
		Translations.EndTime = culture === "fi" ? Translations_fi.EndTime : Translations_en_US.EndTime;
		Translations.RecurrenceDuration = culture === "fi" ? Translations_fi.RecurrenceDuration : Translations_en_US.RecurrenceDuration;
		Translations.StartTime = culture === "fi" ? Translations_fi.StartTime : Translations_en_US.StartTime;
		Translations.Daily = culture === "fi" ? Translations_fi.Daily : Translations_en_US.Daily;
		Translations.Days = culture === "fi" ? Translations_fi.Days : Translations_en_US.Days;
		Translations.Every = culture === "fi" ? Translations_fi.Every : Translations_en_US.Every;
		Translations.EveryWeekday = culture === "fi" ? Translations_fi.EveryWeekday : Translations_en_US.EveryWeekday;
		Translations.Monthly = culture === "fi" ? Translations_fi.Monthly : Translations_en_US.Monthly;
		Translations.Weekly = culture === "fi" ? Translations_fi.Weekly : Translations_en_US.Weekly;
		Translations.Yearly = culture === "fi" ? Translations_fi.Yearly : Translations_en_US.Yearly;
		Translations.Today = culture === "fi" ? Translations_fi.Today : Translations_en_US.Today;
		Translations.NoSelectedWorkOrders = culture === "fi" ? Translations_fi.NoSelectedWorkOrders : Translations_en_US.NoSelectedWorkOrders;
		Translations.NewContactPerson = culture === "fi" ? Translations_fi.NewContactPerson : Translations_en_US.NewContactPerson;
		Translations.NewCustomer = culture === "fi" ? Translations_fi.NewCustomer : Translations_en_US.NewCustomer;
		Translations.Subscriber = culture === "fi" ? Translations_fi.Subscriber : Translations_en_US.Subscriber;
		Translations.WorkOrderTasks = culture === "fi" ? Translations_fi.WorkOrderTasks : Translations_en_US.WorkOrderTasks;
		Translations.CustomerMustBeDefined = culture === "fi" ? Translations_fi.CustomerMustBeDefined : Translations_en_US.CustomerMustBeDefined;
		Translations.Hour = culture === "fi" ? Translations_fi.Hour : Translations_en_US.Hour;
		Translations.HourPluralDuration = culture === "fi" ? Translations_fi.HourPluralDuration : Translations_en_US.HourPluralDuration;
		Translations.Minutes = culture === "fi" ? Translations_fi.Minutes : Translations_en_US.Minutes;
		Translations.Always = culture === "fi" ? Translations_fi.Always : Translations_en_US.Always;
		Translations.April = culture === "fi" ? Translations_fi.April : Translations_en_US.April;
		Translations.August = culture === "fi" ? Translations_fi.August : Translations_en_US.August;
		Translations.Day = culture === "fi" ? Translations_fi.Day : Translations_en_US.Day;
		Translations.DayInterval = culture === "fi" ? Translations_fi.DayInterval : Translations_en_US.DayInterval;
		Translations.DayPluralDuration = culture === "fi" ? Translations_fi.DayPluralDuration : Translations_en_US.DayPluralDuration;
		Translations.December = culture === "fi" ? Translations_fi.December : Translations_en_US.December;
		Translations.EditRecurrence = culture === "fi" ? Translations_fi.EditRecurrence : Translations_en_US.EditRecurrence;
		Translations.February = culture === "fi" ? Translations_fi.February : Translations_en_US.February;
		Translations.Friday = culture === "fi" ? Translations_fi.Friday : Translations_en_US.Friday;
		Translations.January = culture === "fi" ? Translations_fi.January : Translations_en_US.January;
		Translations.July = culture === "fi" ? Translations_fi.July : Translations_en_US.July;
		Translations.June = culture === "fi" ? Translations_fi.June : Translations_en_US.June;
		Translations.MakeRecurring = culture === "fi" ? Translations_fi.MakeRecurring : Translations_en_US.MakeRecurring;
		Translations.March = culture === "fi" ? Translations_fi.March : Translations_en_US.March;
		Translations.May = culture === "fi" ? Translations_fi.May : Translations_en_US.May;
		Translations.Monday = culture === "fi" ? Translations_fi.Monday : Translations_en_US.Monday;
		Translations.MonthIntervalOn = culture === "fi" ? Translations_fi.MonthIntervalOn : Translations_en_US.MonthIntervalOn;
		Translations.November = culture === "fi" ? Translations_fi.November : Translations_en_US.November;
		Translations.October = culture === "fi" ? Translations_fi.October : Translations_en_US.October;
		Translations.OnDay = culture === "fi" ? Translations_fi.OnDay : Translations_en_US.OnDay;
		Translations.RangeOfRecurrence = culture === "fi" ? Translations_fi.RangeOfRecurrence : Translations_en_US.RangeOfRecurrence;
		Translations.RecurrenceAfterOccurrences = culture === "fi" ? Translations_fi.RecurrenceAfterOccurrences : Translations_en_US.RecurrenceAfterOccurrences;
		Translations.RecurrenceEndAfter = culture === "fi" ? Translations_fi.RecurrenceEndAfter : Translations_en_US.RecurrenceEndAfter;
		Translations.RecurrenceEndBy = culture === "fi" ? Translations_fi.RecurrenceEndBy : Translations_en_US.RecurrenceEndBy;
		Translations.RecurrencePattern = culture === "fi" ? Translations_fi.RecurrencePattern : Translations_en_US.RecurrencePattern;
		Translations.RemoveRecurrence = culture === "fi" ? Translations_fi.RemoveRecurrence : Translations_en_US.RemoveRecurrence;
		Translations.Saturday = culture === "fi" ? Translations_fi.Saturday : Translations_en_US.Saturday;
		Translations.September = culture === "fi" ? Translations_fi.September : Translations_en_US.September;
		Translations.StartDateAndEndDateMustBeSpecifiedBeforeRecurrenceCanBeDefined = culture === "fi" ? Translations_fi.StartDateAndEndDateMustBeSpecifiedBeforeRecurrenceCanBeDefined : Translations_en_US.StartDateAndEndDateMustBeSpecifiedBeforeRecurrenceCanBeDefined;
		Translations.Sunday = culture === "fi" ? Translations_fi.Sunday : Translations_en_US.Sunday;
		Translations.Thursday = culture === "fi" ? Translations_fi.Thursday : Translations_en_US.Thursday;
		Translations.Tuesday = culture === "fi" ? Translations_fi.Tuesday : Translations_en_US.Tuesday;
		Translations.Wednesday = culture === "fi" ? Translations_fi.Wednesday : Translations_en_US.Wednesday;
		Translations.WeekIntervalOn = culture === "fi" ? Translations_fi.WeekIntervalOn : Translations_en_US.WeekIntervalOn;
		Translations.WeekPluralDuration = culture === "fi" ? Translations_fi.WeekPluralDuration : Translations_en_US.WeekPluralDuration;
		Translations.WorkTime = culture === "fi" ? Translations_fi.WorkTime : Translations_en_US.WorkTime;
		Translations.YearIntervalOn = culture === "fi" ? Translations_fi.YearIntervalOn : Translations_en_US.YearIntervalOn;
		Translations.EventLogMessageWorkOrderConvertedToRecurringProject = culture === "fi" ? Translations_fi.EventLogMessageWorkOrderConvertedToRecurringProject : Translations_en_US.EventLogMessageWorkOrderConvertedToRecurringProject;
		Translations.OnlyWorkOrderThatIsNotAttachedToProjectCanBeConvertedToRecurringProject = culture === "fi" ? Translations_fi.OnlyWorkOrderThatIsNotAttachedToProjectCanBeConvertedToRecurringProject : Translations_en_US.OnlyWorkOrderThatIsNotAttachedToProjectCanBeConvertedToRecurringProject;
		Translations.StartTimeAndEndTimeMustBeDefinedForRecurringWork = culture === "fi" ? Translations_fi.StartTimeAndEndTimeMustBeDefinedForRecurringWork : Translations_en_US.StartTimeAndEndTimeMustBeDefinedForRecurringWork;
		Translations.EventLogMessageWorkOrderRecurrenceChanged = culture === "fi" ? Translations_fi.EventLogMessageWorkOrderRecurrenceChanged : Translations_en_US.EventLogMessageWorkOrderRecurrenceChanged;
		Translations.RecurringWorkMustHaveAtLeastOneOccurence = culture === "fi" ? Translations_fi.RecurringWorkMustHaveAtLeastOneOccurence : Translations_en_US.RecurringWorkMustHaveAtLeastOneOccurence;
		Translations.EndDateMustBeDefined = culture === "fi" ? Translations_fi.EndDateMustBeDefined : Translations_en_US.EndDateMustBeDefined;
		Translations.AbrHours = culture === "fi" ? Translations_fi.AbrHours : Translations_en_US.AbrHours;
		Translations.AbrMins = culture === "fi" ? Translations_fi.AbrMins : Translations_en_US.AbrMins;
		Translations.CostCenter = culture === "fi" ? Translations_fi.CostCenter : Translations_en_US.CostCenter;
		Translations.NoWorkShiftsForThisDay = culture === "fi" ? Translations_fi.NoWorkShiftsForThisDay : Translations_en_US.NoWorkShiftsForThisDay;
		Translations.Order = culture === "fi" ? Translations_fi.Order : Translations_en_US.Order;
		Translations.Orders = culture === "fi" ? Translations_fi.Orders : Translations_en_US.Orders;
		Translations.Settings = culture === "fi" ? Translations_fi.Settings : Translations_en_US.Settings;
		Translations.Break = culture === "fi" ? Translations_fi.Break : Translations_en_US.Break;
		Translations.BreakReason = culture === "fi" ? Translations_fi.BreakReason : Translations_en_US.BreakReason;
		Translations.CommentMustBeDefined = culture === "fi" ? Translations_fi.CommentMustBeDefined : Translations_en_US.CommentMustBeDefined;
		Translations.Continue = culture === "fi" ? Translations_fi.Continue : Translations_en_US.Continue;
		Translations.CurrentWorkShiftIsNotRelatedToWorkOrder = culture === "fi" ? Translations_fi.CurrentWorkShiftIsNotRelatedToWorkOrder : Translations_en_US.CurrentWorkShiftIsNotRelatedToWorkOrder;
		Translations.CurrentWorkShiftNotFound = culture === "fi" ? Translations_fi.CurrentWorkShiftNotFound : Translations_en_US.CurrentWorkShiftNotFound;
		Translations.DoYouWantToSetCurrentWorkOrderDone = culture === "fi" ? Translations_fi.DoYouWantToSetCurrentWorkOrderDone : Translations_en_US.DoYouWantToSetCurrentWorkOrderDone;
		Translations.EndLocation = culture === "fi" ? Translations_fi.EndLocation : Translations_en_US.EndLocation;
		Translations.Finish = culture === "fi" ? Translations_fi.Finish : Translations_en_US.Finish;
		Translations.NonOrder = culture === "fi" ? Translations_fi.NonOrder : Translations_en_US.NonOrder;
		Translations.NotInUse = culture === "fi" ? Translations_fi.NotInUse : Translations_en_US.NotInUse;
		Translations.Pause = culture === "fi" ? Translations_fi.Pause : Translations_en_US.Pause;
		Translations.PauseWork = culture === "fi" ? Translations_fi.PauseWork : Translations_en_US.PauseWork;
		Translations.Start = culture === "fi" ? Translations_fi.Start : Translations_en_US.Start;
		Translations.StartLocation = culture === "fi" ? Translations_fi.StartLocation : Translations_en_US.StartLocation;
		Translations.StartNew = culture === "fi" ? Translations_fi.StartNew : Translations_en_US.StartNew;
		Translations.SuitableWorkOrdersOrCostCentersNotFound = culture === "fi" ? Translations_fi.SuitableWorkOrdersOrCostCentersNotFound : Translations_en_US.SuitableWorkOrdersOrCostCentersNotFound;
		Translations.WorkShiftTimeSlotTypeLunchBreak = culture === "fi" ? Translations_fi.WorkShiftTimeSlotTypeLunchBreak : Translations_en_US.WorkShiftTimeSlotTypeLunchBreak;
		Translations.WorkShiftInProgress = culture === "fi" ? Translations_fi.WorkShiftInProgress : Translations_en_US.WorkShiftInProgress;
		Translations.WorkShiftTimeSlotSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkShiftTimeSlotSaveFailedConcurrencyError : Translations_en_US.WorkShiftTimeSlotSaveFailedConcurrencyError;
		Translations.WorkShiftTimeSlotSaveFailedErrorParameter = culture === "fi" ? Translations_fi.WorkShiftTimeSlotSaveFailedErrorParameter : Translations_en_US.WorkShiftTimeSlotSaveFailedErrorParameter;
		Translations.WorkShiftTimeSlotSaveSuccess = culture === "fi" ? Translations_fi.WorkShiftTimeSlotSaveSuccess : Translations_en_US.WorkShiftTimeSlotSaveSuccess;
		Translations.WorkShiftTimeSlotStateInvoicing = culture === "fi" ? Translations_fi.WorkShiftTimeSlotStateInvoicing : Translations_en_US.WorkShiftTimeSlotStateInvoicing;
		Translations.WorkShiftTimeSlotStateDone = culture === "fi" ? Translations_fi.WorkShiftTimeSlotStateDone : Translations_en_US.WorkShiftTimeSlotStateDone;
		Translations.WorkShiftTimeSlotStateInProgress = culture === "fi" ? Translations_fi.WorkShiftTimeSlotStateInProgress : Translations_en_US.WorkShiftTimeSlotStateInProgress;
		Translations.PreviousWorkShiftNotFound = culture === "fi" ? Translations_fi.PreviousWorkShiftNotFound : Translations_en_US.PreviousWorkShiftNotFound;
		Translations.WorkShiftTimeSlotTypeBreak = culture === "fi" ? Translations_fi.WorkShiftTimeSlotTypeBreak : Translations_en_US.WorkShiftTimeSlotTypeBreak;
		Translations.WorkShiftTimeSlotTypeWork = culture === "fi" ? Translations_fi.WorkShiftTimeSlotTypeWork : Translations_en_US.WorkShiftTimeSlotTypeWork;
		Translations.AllTypes = culture === "fi" ? Translations_fi.AllTypes : Translations_en_US.AllTypes;
		Translations.AType = culture === "fi" ? Translations_fi.AType : Translations_en_US.AType;
		Translations.Task = culture === "fi" ? Translations_fi.Task : Translations_en_US.Task;
		Translations.NoSelectedInvoice = culture === "fi" ? Translations_fi.NoSelectedInvoice : Translations_en_US.NoSelectedInvoice;
		Translations.SelectedInvoice = culture === "fi" ? Translations_fi.SelectedInvoice : Translations_en_US.SelectedInvoice;
		Translations.ThisWeek = culture === "fi" ? Translations_fi.ThisWeek : Translations_en_US.ThisWeek;
		Translations.InvoicesTransfer = culture === "fi" ? Translations_fi.InvoicesTransfer : Translations_en_US.InvoicesTransfer;
		Translations.Salaries = culture === "fi" ? Translations_fi.Salaries : Translations_en_US.Salaries;
		Translations.WorkingTimeRecords = culture === "fi" ? Translations_fi.WorkingTimeRecords : Translations_en_US.WorkingTimeRecords;
		Translations.WorkOrdersChecking = culture === "fi" ? Translations_fi.WorkOrdersChecking : Translations_en_US.WorkOrdersChecking;
		Translations.RoutePoint = culture === "fi" ? Translations_fi.RoutePoint : Translations_en_US.RoutePoint;
		Translations.RoutePointBookingCheckParameterNotFound = culture === "fi" ? Translations_fi.RoutePointBookingCheckParameterNotFound : Translations_en_US.RoutePointBookingCheckParameterNotFound;
		Translations.RoutePointBookingParameterNotFound = culture === "fi" ? Translations_fi.RoutePointBookingParameterNotFound : Translations_en_US.RoutePointBookingParameterNotFound;
		Translations.RoutePointBookingRemoveConfirmation = culture === "fi" ? Translations_fi.RoutePointBookingRemoveConfirmation : Translations_en_US.RoutePointBookingRemoveConfirmation;
		Translations.RoutePointBookingRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.RoutePointBookingRemoveFailedConcurrencyError : Translations_en_US.RoutePointBookingRemoveFailedConcurrencyError;
		Translations.RoutePointBookingRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.RoutePointBookingRemoveFailedErrorParameter : Translations_en_US.RoutePointBookingRemoveFailedErrorParameter;
		Translations.RoutePointBookingRemoveSuccess = culture === "fi" ? Translations_fi.RoutePointBookingRemoveSuccess : Translations_en_US.RoutePointBookingRemoveSuccess;
		Translations.RoutePointBookings = culture === "fi" ? Translations_fi.RoutePointBookings : Translations_en_US.RoutePointBookings;
		Translations.RoutePointBookingSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.RoutePointBookingSaveFailedConcurrencyError : Translations_en_US.RoutePointBookingSaveFailedConcurrencyError;
		Translations.RoutePointBookingSaveFailedErrorParameter = culture === "fi" ? Translations_fi.RoutePointBookingSaveFailedErrorParameter : Translations_en_US.RoutePointBookingSaveFailedErrorParameter;
		Translations.RoutePointBookingSaveSuccess = culture === "fi" ? Translations_fi.RoutePointBookingSaveSuccess : Translations_en_US.RoutePointBookingSaveSuccess;
		Translations.RoutePointNotFound = culture === "fi" ? Translations_fi.RoutePointNotFound : Translations_en_US.RoutePointNotFound;
		Translations.Total = culture === "fi" ? Translations_fi.Total : Translations_en_US.Total;
		Translations.AllRoutePointsHaveBeenAdded = culture === "fi" ? Translations_fi.AllRoutePointsHaveBeenAdded : Translations_en_US.AllRoutePointsHaveBeenAdded;
		Translations.RecurringWorks = culture === "fi" ? Translations_fi.RecurringWorks : Translations_en_US.RecurringWorks;
		Translations.NoRoutePointChecksDefined = culture === "fi" ? Translations_fi.NoRoutePointChecksDefined : Translations_en_US.NoRoutePointChecksDefined;
		Translations.RecurringWork = culture === "fi" ? Translations_fi.RecurringWork : Translations_en_US.RecurringWork;
		Translations.StartRouteBooking = culture === "fi" ? Translations_fi.StartRouteBooking : Translations_en_US.StartRouteBooking;
		Translations.RoutePointChecks = culture === "fi" ? Translations_fi.RoutePointChecks : Translations_en_US.RoutePointChecks;
		Translations.CountryCode = culture === "fi" ? Translations_fi.CountryCode : Translations_en_US.CountryCode;
		Translations.MoveDown = culture === "fi" ? Translations_fi.MoveDown : Translations_en_US.MoveDown;
		Translations.MoveUp = culture === "fi" ? Translations_fi.MoveUp : Translations_en_US.MoveUp;
		Translations.RoutePointCheck = culture === "fi" ? Translations_fi.RoutePointCheck : Translations_en_US.RoutePointCheck;
		Translations.RoutePointCheckParameterNotFound = culture === "fi" ? Translations_fi.RoutePointCheckParameterNotFound : Translations_en_US.RoutePointCheckParameterNotFound;
		Translations.RoutePointParameterNotFound = culture === "fi" ? Translations_fi.RoutePointParameterNotFound : Translations_en_US.RoutePointParameterNotFound;
		Translations.RoutePoints = culture === "fi" ? Translations_fi.RoutePoints : Translations_en_US.RoutePoints;
		Translations.WorkOrderRemoveSuccess = culture === "fi" ? Translations_fi.WorkOrderRemoveSuccess : Translations_en_US.WorkOrderRemoveSuccess;
		Translations.WorkOrdersRemoveConfirmation = culture === "fi" ? Translations_fi.WorkOrdersRemoveConfirmation : Translations_en_US.WorkOrdersRemoveConfirmation;
		Translations.WorkOrdersRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrdersRemoveFailedConcurrencyError : Translations_en_US.WorkOrdersRemoveFailedConcurrencyError;
		Translations.WorkOrdersRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrdersRemoveFailedErrorParameter : Translations_en_US.WorkOrdersRemoveFailedErrorParameter;
		Translations.EmptyResponse = culture === "fi" ? Translations_fi.EmptyResponse : Translations_en_US.EmptyResponse;
		Translations.GeocodeFailedFailedErrorParameter = culture === "fi" ? Translations_fi.GeocodeFailedFailedErrorParameter : Translations_en_US.GeocodeFailedFailedErrorParameter;
		Translations.NullValueResponse = culture === "fi" ? Translations_fi.NullValueResponse : Translations_en_US.NullValueResponse;
		Translations.ClickToChangeImage = culture === "fi" ? Translations_fi.ClickToChangeImage : Translations_en_US.ClickToChangeImage;
		Translations.InvoiceAddress = culture === "fi" ? Translations_fi.InvoiceAddress : Translations_en_US.InvoiceAddress;
		Translations.InvoicePeriod = culture === "fi" ? Translations_fi.InvoicePeriod : Translations_en_US.InvoicePeriod;
		Translations.SelectImageFile = culture === "fi" ? Translations_fi.SelectImageFile : Translations_en_US.SelectImageFile;
		Translations.WorkOrderParameterEmployeesMustBeDefined = culture === "fi" ? Translations_fi.WorkOrderParameterEmployeesMustBeDefined : Translations_en_US.WorkOrderParameterEmployeesMustBeDefined;
		Translations.WorkOrderParameterEndDateMustBeDefined = culture === "fi" ? Translations_fi.WorkOrderParameterEndDateMustBeDefined : Translations_en_US.WorkOrderParameterEndDateMustBeDefined;
		Translations.WorkOrderParameterHourBookingsMustBeDefined = culture === "fi" ? Translations_fi.WorkOrderParameterHourBookingsMustBeDefined : Translations_en_US.WorkOrderParameterHourBookingsMustBeDefined;
		Translations.WorkOrderParameterRideBookingsMustBeDefined = culture === "fi" ? Translations_fi.WorkOrderParameterRideBookingsMustBeDefined : Translations_en_US.WorkOrderParameterRideBookingsMustBeDefined;
		Translations.WorkOrderParameterStartDateMustBeDefined = culture === "fi" ? Translations_fi.WorkOrderParameterStartDateMustBeDefined : Translations_en_US.WorkOrderParameterStartDateMustBeDefined;
		Translations.WorkOrderParameterStateChangeFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrderParameterStateChangeFailedConcurrencyError : Translations_en_US.WorkOrderParameterStateChangeFailedConcurrencyError;
		Translations.WorkOrderParameterStateChangeFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderParameterStateChangeFailedErrorParameter : Translations_en_US.WorkOrderParameterStateChangeFailedErrorParameter;
		Translations.WorkOrderParameterStateChangeSuccess = culture === "fi" ? Translations_fi.WorkOrderParameterStateChangeSuccess : Translations_en_US.WorkOrderParameterStateChangeSuccess;
		Translations.WorkOrderProjectParameterStateCannotBeChangedBecauseTasksAreNotInSameState = culture === "fi" ? Translations_fi.WorkOrderProjectParameterStateCannotBeChangedBecauseTasksAreNotInSameState : Translations_en_US.WorkOrderProjectParameterStateCannotBeChangedBecauseTasksAreNotInSameState;
		Translations.WorkOrdersParameterStateChangeFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrdersParameterStateChangeFailedConcurrencyError : Translations_en_US.WorkOrdersParameterStateChangeFailedConcurrencyError;
		Translations.WorkOrdersParameterStateChangeFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrdersParameterStateChangeFailedErrorParameter : Translations_en_US.WorkOrdersParameterStateChangeFailedErrorParameter;
		Translations.WorkOrdersParameterStateChangeSuccess = culture === "fi" ? Translations_fi.WorkOrdersParameterStateChangeSuccess : Translations_en_US.WorkOrdersParameterStateChangeSuccess;
		Translations.WorkOrderTaskParameterEmployeesMustBeDefined = culture === "fi" ? Translations_fi.WorkOrderTaskParameterEmployeesMustBeDefined : Translations_en_US.WorkOrderTaskParameterEmployeesMustBeDefined;
		Translations.WorkOrderTaskParameterEndDateMustBeDefined = culture === "fi" ? Translations_fi.WorkOrderTaskParameterEndDateMustBeDefined : Translations_en_US.WorkOrderTaskParameterEndDateMustBeDefined;
		Translations.WorkOrderTaskParameterHourBookingsMustBeDefined = culture === "fi" ? Translations_fi.WorkOrderTaskParameterHourBookingsMustBeDefined : Translations_en_US.WorkOrderTaskParameterHourBookingsMustBeDefined;
		Translations.WorkOrderTaskParameterRideBookingsMustBeDefined = culture === "fi" ? Translations_fi.WorkOrderTaskParameterRideBookingsMustBeDefined : Translations_en_US.WorkOrderTaskParameterRideBookingsMustBeDefined;
		Translations.WorkOrderTaskParameterStartDateMustBeDefined = culture === "fi" ? Translations_fi.WorkOrderTaskParameterStartDateMustBeDefined : Translations_en_US.WorkOrderTaskParameterStartDateMustBeDefined;
		Translations.Card = culture === "fi" ? Translations_fi.Card : Translations_en_US.Card;
		Translations.List = culture === "fi" ? Translations_fi.List : Translations_en_US.List;
		Translations.Latitude = culture === "fi" ? Translations_fi.Latitude : Translations_en_US.Latitude;
		Translations.Longitude = culture === "fi" ? Translations_fi.Longitude : Translations_en_US.Longitude;
		Translations.Location = culture === "fi" ? Translations_fi.Location : Translations_en_US.Location;
		Translations.WorkOrderSaveFailedRecurrenceChangeFailed = culture === "fi" ? Translations_fi.WorkOrderSaveFailedRecurrenceChangeFailed : Translations_en_US.WorkOrderSaveFailedRecurrenceChangeFailed;
		Translations.APrint = culture === "fi" ? Translations_fi.APrint : Translations_en_US.APrint;
		Translations.Data = culture === "fi" ? Translations_fi.Data : Translations_en_US.Data;
		Translations.NoCheckedWorkOrdersFound = culture === "fi" ? Translations_fi.NoCheckedWorkOrdersFound : Translations_en_US.NoCheckedWorkOrdersFound;
		Translations.Address = culture === "fi" ? Translations_fi.Address : Translations_en_US.Address;
		Translations.CustomerSites = culture === "fi" ? Translations_fi.CustomerSites : Translations_en_US.CustomerSites;
		Translations.SiteParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders = culture === "fi" ? Translations_fi.SiteParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders : Translations_en_US.SiteParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders;
		Translations.SiteRemoveConfirmation = culture === "fi" ? Translations_fi.SiteRemoveConfirmation : Translations_en_US.SiteRemoveConfirmation;
		Translations.SiteRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.SiteRemoveFailedConcurrencyError : Translations_en_US.SiteRemoveFailedConcurrencyError;
		Translations.SiteRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.SiteRemoveFailedErrorParameter : Translations_en_US.SiteRemoveFailedErrorParameter;
		Translations.SiteRemoveSuccess = culture === "fi" ? Translations_fi.SiteRemoveSuccess : Translations_en_US.SiteRemoveSuccess;
		Translations.FieldParameterValueMustBeDefined = culture === "fi" ? Translations_fi.FieldParameterValueMustBeDefined : Translations_en_US.FieldParameterValueMustBeDefined;
		Translations.AttachExisting = culture === "fi" ? Translations_fi.AttachExisting : Translations_en_US.AttachExisting;
		Translations.ContactWithNameParameterAlreadyExists = culture === "fi" ? Translations_fi.ContactWithNameParameterAlreadyExists : Translations_en_US.ContactWithNameParameterAlreadyExists;
		Translations.StartTimeMustBeDefined = culture === "fi" ? Translations_fi.StartTimeMustBeDefined : Translations_en_US.StartTimeMustBeDefined;
		Translations.WorkingTimeRegistration = culture === "fi" ? Translations_fi.WorkingTimeRegistration : Translations_en_US.WorkingTimeRegistration;
		Translations.NotTransfered = culture === "fi" ? Translations_fi.NotTransfered : Translations_en_US.NotTransfered;
		Translations.OwnerCannotBeChanged = culture === "fi" ? Translations_fi.OwnerCannotBeChanged : Translations_en_US.OwnerCannotBeChanged;
		Translations.SelectCostCenter = culture === "fi" ? Translations_fi.SelectCostCenter : Translations_en_US.SelectCostCenter;
		Translations.SelectWorkOrder = culture === "fi" ? Translations_fi.SelectWorkOrder : Translations_en_US.SelectWorkOrder;
		Translations.TransferredToInvoicing = culture === "fi" ? Translations_fi.TransferredToInvoicing : Translations_en_US.TransferredToInvoicing;
		Translations.TransferredToSalary = culture === "fi" ? Translations_fi.TransferredToSalary : Translations_en_US.TransferredToSalary;
		Translations.WorkShiftTimeSlotHasBeenTranferredToInvoicing = culture === "fi" ? Translations_fi.WorkShiftTimeSlotHasBeenTranferredToInvoicing : Translations_en_US.WorkShiftTimeSlotHasBeenTranferredToInvoicing;
		Translations.WorkShiftTimeSlotHasBeenTranferredToSalaryPayment = culture === "fi" ? Translations_fi.WorkShiftTimeSlotHasBeenTranferredToSalaryPayment : Translations_en_US.WorkShiftTimeSlotHasBeenTranferredToSalaryPayment;
		Translations.WorkShiftTimeSlotParameterNotFound = culture === "fi" ? Translations_fi.WorkShiftTimeSlotParameterNotFound : Translations_en_US.WorkShiftTimeSlotParameterNotFound;
		Translations.WorkShiftTimeSlotStateComplete = culture === "fi" ? Translations_fi.WorkShiftTimeSlotStateComplete : Translations_en_US.WorkShiftTimeSlotStateComplete;
		Translations.WorkShiftTimeSlotStateSalary = culture === "fi" ? Translations_fi.WorkShiftTimeSlotStateSalary : Translations_en_US.WorkShiftTimeSlotStateSalary;
		Translations.DoYouReallyWantToSaveWorkShiftTimeSlot = culture === "fi" ? Translations_fi.DoYouReallyWantToSaveWorkShiftTimeSlot : Translations_en_US.DoYouReallyWantToSaveWorkShiftTimeSlot;
		Translations.SelectTaskType = culture === "fi" ? Translations_fi.SelectTaskType : Translations_en_US.SelectTaskType;
		Translations.Roadmap = culture === "fi" ? Translations_fi.Roadmap : Translations_en_US.Roadmap;
		Translations.Satellite = culture === "fi" ? Translations_fi.Satellite : Translations_en_US.Satellite;
		Translations.Terrain = culture === "fi" ? Translations_fi.Terrain : Translations_en_US.Terrain;
		Translations.WorkOrdersApproveToBeInvoicedConfirmation = culture === "fi" ? Translations_fi.WorkOrdersApproveToBeInvoicedConfirmation : Translations_en_US.WorkOrdersApproveToBeInvoicedConfirmation;
		Translations.CreateReport = culture === "fi" ? Translations_fi.CreateReport : Translations_en_US.CreateReport;
		Translations.InvalidRequestData = culture === "fi" ? Translations_fi.InvalidRequestData : Translations_en_US.InvalidRequestData;
		Translations.Preview = culture === "fi" ? Translations_fi.Preview : Translations_en_US.Preview;
		Translations.Report = culture === "fi" ? Translations_fi.Report : Translations_en_US.Report;
		Translations.ReportParameterNotFound = culture === "fi" ? Translations_fi.ReportParameterNotFound : Translations_en_US.ReportParameterNotFound;
		Translations.AddCustomersBeforeAddingCustomerSites = culture === "fi" ? Translations_fi.AddCustomersBeforeAddingCustomerSites : Translations_en_US.AddCustomersBeforeAddingCustomerSites;
		Translations.MeasuredTimeParameterWorkTimeParameter = culture === "fi" ? Translations_fi.MeasuredTimeParameterWorkTimeParameter : Translations_en_US.MeasuredTimeParameterWorkTimeParameter;
		Translations.ContinueWork = culture === "fi" ? Translations_fi.ContinueWork : Translations_en_US.ContinueWork;
		Translations.WorkTimeMeasurement = culture === "fi" ? Translations_fi.WorkTimeMeasurement : Translations_en_US.WorkTimeMeasurement;
		Translations.Hide = culture === "fi" ? Translations_fi.Hide : Translations_en_US.Hide;
		Translations.ToBeInvoicedRecords = culture === "fi" ? Translations_fi.ToBeInvoicedRecords : Translations_en_US.ToBeInvoicedRecords;
		Translations.WorkOrderDocumentsSaveFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderDocumentsSaveFailedErrorParameter : Translations_en_US.WorkOrderDocumentsSaveFailedErrorParameter;
		Translations.WorkOrderDocumentsSaveSuccess = culture === "fi" ? Translations_fi.WorkOrderDocumentsSaveSuccess : Translations_en_US.WorkOrderDocumentsSaveSuccess;
		Translations.WorkOrderRemoveDocumentsFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderRemoveDocumentsFailedErrorParameter : Translations_en_US.WorkOrderRemoveDocumentsFailedErrorParameter;
		Translations.WorkOrderRemoveDocumentsSuccess = culture === "fi" ? Translations_fi.WorkOrderRemoveDocumentsSuccess : Translations_en_US.WorkOrderRemoveDocumentsSuccess;
		Translations.AddHourBooking = culture === "fi" ? Translations_fi.AddHourBooking : Translations_en_US.AddHourBooking;
		Translations.AddProductBooking = culture === "fi" ? Translations_fi.AddProductBooking : Translations_en_US.AddProductBooking;
		Translations.AddRideBooking = culture === "fi" ? Translations_fi.AddRideBooking : Translations_en_US.AddRideBooking;
		Translations.DoYouWantToMoveWorkOrderStateToParameterState = culture === "fi" ? Translations_fi.DoYouWantToMoveWorkOrderStateToParameterState : Translations_en_US.DoYouWantToMoveWorkOrderStateToParameterState;
		Translations.WorkOrderParameterHourBookingSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrderParameterHourBookingSaveFailedConcurrencyError : Translations_en_US.WorkOrderParameterHourBookingSaveFailedConcurrencyError;
		Translations.WorkOrderParameterHourBookingsSaveFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderParameterHourBookingsSaveFailedErrorParameter : Translations_en_US.WorkOrderParameterHourBookingsSaveFailedErrorParameter;
		Translations.WorkOrderParameterHourBookingsSaveSuccess = culture === "fi" ? Translations_fi.WorkOrderParameterHourBookingsSaveSuccess : Translations_en_US.WorkOrderParameterHourBookingsSaveSuccess;
		Translations.WorkOrderParameterProductBookingSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrderParameterProductBookingSaveFailedConcurrencyError : Translations_en_US.WorkOrderParameterProductBookingSaveFailedConcurrencyError;
		Translations.WorkOrderParameterProductBookingsSaveFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderParameterProductBookingsSaveFailedErrorParameter : Translations_en_US.WorkOrderParameterProductBookingsSaveFailedErrorParameter;
		Translations.WorkOrderParameterProductBookingsSaveSuccess = culture === "fi" ? Translations_fi.WorkOrderParameterProductBookingsSaveSuccess : Translations_en_US.WorkOrderParameterProductBookingsSaveSuccess;
		Translations.WorkOrderParameterRideBookingSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrderParameterRideBookingSaveFailedConcurrencyError : Translations_en_US.WorkOrderParameterRideBookingSaveFailedConcurrencyError;
		Translations.WorkOrderParameterRideBookingsSaveFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderParameterRideBookingsSaveFailedErrorParameter : Translations_en_US.WorkOrderParameterRideBookingsSaveFailedErrorParameter;
		Translations.WorkOrderParameterRideBookingsSaveSuccess = culture === "fi" ? Translations_fi.WorkOrderParameterRideBookingsSaveSuccess : Translations_en_US.WorkOrderParameterRideBookingsSaveSuccess;
		Translations.WorkOrderRemoveHourBookingsFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderRemoveHourBookingsFailedErrorParameter : Translations_en_US.WorkOrderRemoveHourBookingsFailedErrorParameter;
		Translations.WorkOrderRemoveHourBookingsSuccess = culture === "fi" ? Translations_fi.WorkOrderRemoveHourBookingsSuccess : Translations_en_US.WorkOrderRemoveHourBookingsSuccess;
		Translations.WorkOrderRemoveProductBookingsFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderRemoveProductBookingsFailedErrorParameter : Translations_en_US.WorkOrderRemoveProductBookingsFailedErrorParameter;
		Translations.WorkOrderRemoveProductBookingsSuccess = culture === "fi" ? Translations_fi.WorkOrderRemoveProductBookingsSuccess : Translations_en_US.WorkOrderRemoveProductBookingsSuccess;
		Translations.WorkOrderRemoveRideBookingsFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderRemoveRideBookingsFailedErrorParameter : Translations_en_US.WorkOrderRemoveRideBookingsFailedErrorParameter;
		Translations.WorkOrderRemoveRideBookingsSuccess = culture === "fi" ? Translations_fi.WorkOrderRemoveRideBookingsSuccess : Translations_en_US.WorkOrderRemoveRideBookingsSuccess;
		Translations.ChangeState = culture === "fi" ? Translations_fi.ChangeState : Translations_en_US.ChangeState;
		Translations.RecurringWorkCannotHaveMoreThanParameterOccurences = culture === "fi" ? Translations_fi.RecurringWorkCannotHaveMoreThanParameterOccurences : Translations_en_US.RecurringWorkCannotHaveMoreThanParameterOccurences;
		Translations.Hours = culture === "fi" ? Translations_fi.Hours : Translations_en_US.Hours;
		Translations.CalculateHours = culture === "fi" ? Translations_fi.CalculateHours : Translations_en_US.CalculateHours;
		Translations.CostCenters = culture === "fi" ? Translations_fi.CostCenters : Translations_en_US.CostCenters;
		Translations.DailyAdditionalHours = culture === "fi" ? Translations_fi.DailyAdditionalHours : Translations_en_US.DailyAdditionalHours;
		Translations.EventsWorkTimeTotal = culture === "fi" ? Translations_fi.EventsWorkTimeTotal : Translations_en_US.EventsWorkTimeTotal;
		Translations.DoYouReallyWantToCalculateHoursAndDiscardAllCurrentData = culture === "fi" ? Translations_fi.DoYouReallyWantToCalculateHoursAndDiscardAllCurrentData : Translations_en_US.DoYouReallyWantToCalculateHoursAndDiscardAllCurrentData;
		Translations.DoYouReallyWantToSaveHours = culture === "fi" ? Translations_fi.DoYouReallyWantToSaveHours : Translations_en_US.DoYouReallyWantToSaveHours;
		Translations.HoursSaveSaveFailedErrorParameter = culture === "fi" ? Translations_fi.HoursSaveSaveFailedErrorParameter : Translations_en_US.HoursSaveSaveFailedErrorParameter;
		Translations.HoursSaveSuccess = culture === "fi" ? Translations_fi.HoursSaveSuccess : Translations_en_US.HoursSaveSuccess;
		Translations.EmployeeParameterCannotBeRemovedBecauseItIsRelatedToParameterHourBookings = culture === "fi" ? Translations_fi.EmployeeParameterCannotBeRemovedBecauseItIsRelatedToParameterHourBookings : Translations_en_US.EmployeeParameterCannotBeRemovedBecauseItIsRelatedToParameterHourBookings;
		Translations.EmployeeParameterCannotBeRemovedBecauseItIsRelatedToParameterSalaries = culture === "fi" ? Translations_fi.EmployeeParameterCannotBeRemovedBecauseItIsRelatedToParameterSalaries : Translations_en_US.EmployeeParameterCannotBeRemovedBecauseItIsRelatedToParameterSalaries;
		Translations.SalaryPeriod = culture === "fi" ? Translations_fi.SalaryPeriod : Translations_en_US.SalaryPeriod;
		Translations.SalaryType = culture === "fi" ? Translations_fi.SalaryType : Translations_en_US.SalaryType;
		Translations.Calculate = culture === "fi" ? Translations_fi.Calculate : Translations_en_US.Calculate;
		Translations.DoYouReallyWantToCalculateSalaryAndDiscardAllCurrentData = culture === "fi" ? Translations_fi.DoYouReallyWantToCalculateSalaryAndDiscardAllCurrentData : Translations_en_US.DoYouReallyWantToCalculateSalaryAndDiscardAllCurrentData;
		Translations.DoYouReallyWantToSaveSalary = culture === "fi" ? Translations_fi.DoYouReallyWantToSaveSalary : Translations_en_US.DoYouReallyWantToSaveSalary;
		Translations.SalaryParameterNotFound = culture === "fi" ? Translations_fi.SalaryParameterNotFound : Translations_en_US.SalaryParameterNotFound;
		Translations.SalaryRowType = culture === "fi" ? Translations_fi.SalaryRowType : Translations_en_US.SalaryRowType;
		Translations.SalarySaveFailedConcurrencyError = culture === "fi" ? Translations_fi.SalarySaveFailedConcurrencyError : Translations_en_US.SalarySaveFailedConcurrencyError;
		Translations.SalarySaveSaveFailedErrorParameter = culture === "fi" ? Translations_fi.SalarySaveSaveFailedErrorParameter : Translations_en_US.SalarySaveSaveFailedErrorParameter;
		Translations.SalarySaveSuccess = culture === "fi" ? Translations_fi.SalarySaveSuccess : Translations_en_US.SalarySaveSuccess;
		Translations.DateMustBeDefined = culture === "fi" ? Translations_fi.DateMustBeDefined : Translations_en_US.DateMustBeDefined;
		Translations.SalaryTransfer = culture === "fi" ? Translations_fi.SalaryTransfer : Translations_en_US.SalaryTransfer;
		Translations.Transferred = culture === "fi" ? Translations_fi.Transferred : Translations_en_US.Transferred;
		Translations.WorkShiftTimeSlotRemoveConfirmation = culture === "fi" ? Translations_fi.WorkShiftTimeSlotRemoveConfirmation : Translations_en_US.WorkShiftTimeSlotRemoveConfirmation;
		Translations.WorkShiftTimeSlotRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkShiftTimeSlotRemoveFailedConcurrencyError : Translations_en_US.WorkShiftTimeSlotRemoveFailedConcurrencyError;
		Translations.WorkShiftTimeSlotRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.WorkShiftTimeSlotRemoveFailedErrorParameter : Translations_en_US.WorkShiftTimeSlotRemoveFailedErrorParameter;
		Translations.WorkShiftTimeSlotRemoveSuccess = culture === "fi" ? Translations_fi.WorkShiftTimeSlotRemoveSuccess : Translations_en_US.WorkShiftTimeSlotRemoveSuccess;
		Translations.BusinessId = culture === "fi" ? Translations_fi.BusinessId : Translations_en_US.BusinessId;
		Translations.CustomerType = culture === "fi" ? Translations_fi.CustomerType : Translations_en_US.CustomerType;
		Translations.CustomerTypesMustBeDefinedBeforeAddingCustomers = culture === "fi" ? Translations_fi.CustomerTypesMustBeDefinedBeforeAddingCustomers : Translations_en_US.CustomerTypesMustBeDefinedBeforeAddingCustomers;
		Translations.ProductGroup = culture === "fi" ? Translations_fi.ProductGroup : Translations_en_US.ProductGroup;
		Translations.ProductGroupsMustBeDefinedBeforeAddingProducts = culture === "fi" ? Translations_fi.ProductGroupsMustBeDefinedBeforeAddingProducts : Translations_en_US.ProductGroupsMustBeDefinedBeforeAddingProducts;
		Translations.Canceled = culture === "fi" ? Translations_fi.Canceled : Translations_en_US.Canceled;
		Translations.CancelWorkOrder = culture === "fi" ? Translations_fi.CancelWorkOrder : Translations_en_US.CancelWorkOrder;
		Translations.EventLogMessageWorkOrderCanceled = culture === "fi" ? Translations_fi.EventLogMessageWorkOrderCanceled : Translations_en_US.EventLogMessageWorkOrderCanceled;
		Translations.EventLogMessageWorkOrderRestored = culture === "fi" ? Translations_fi.EventLogMessageWorkOrderRestored : Translations_en_US.EventLogMessageWorkOrderRestored;
		Translations.ProjectCannotBeCanceled = culture === "fi" ? Translations_fi.ProjectCannotBeCanceled : Translations_en_US.ProjectCannotBeCanceled;
		Translations.RestoreWorkOrder = culture === "fi" ? Translations_fi.RestoreWorkOrder : Translations_en_US.RestoreWorkOrder;
		Translations.WorkOrderCancelConfirmation = culture === "fi" ? Translations_fi.WorkOrderCancelConfirmation : Translations_en_US.WorkOrderCancelConfirmation;
		Translations.WorkOrderCancelFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrderCancelFailedConcurrencyError : Translations_en_US.WorkOrderCancelFailedConcurrencyError;
		Translations.WorkOrderCancelFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderCancelFailedErrorParameter : Translations_en_US.WorkOrderCancelFailedErrorParameter;
		Translations.WorkOrderCancelSuccess = culture === "fi" ? Translations_fi.WorkOrderCancelSuccess : Translations_en_US.WorkOrderCancelSuccess;
		Translations.WorkOrderRestoreConfirmation = culture === "fi" ? Translations_fi.WorkOrderRestoreConfirmation : Translations_en_US.WorkOrderRestoreConfirmation;
		Translations.WorkOrderRestoreFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrderRestoreFailedConcurrencyError : Translations_en_US.WorkOrderRestoreFailedConcurrencyError;
		Translations.WorkOrderRestoreFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderRestoreFailedErrorParameter : Translations_en_US.WorkOrderRestoreFailedErrorParameter;
		Translations.WorkOrderRestoreSuccess = culture === "fi" ? Translations_fi.WorkOrderRestoreSuccess : Translations_en_US.WorkOrderRestoreSuccess;
		Translations.Attachment = culture === "fi" ? Translations_fi.Attachment : Translations_en_US.Attachment;
		Translations.WorkOrderDocumentSaveFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderDocumentSaveFailedErrorParameter : Translations_en_US.WorkOrderDocumentSaveFailedErrorParameter;
		Translations.WorkOrderDocumentSaveSuccess = culture === "fi" ? Translations_fi.WorkOrderDocumentSaveSuccess : Translations_en_US.WorkOrderDocumentSaveSuccess;
		Translations.MapOnId = culture === "fi" ? Translations_fi.MapOnId : Translations_en_US.MapOnId;
		Translations.VehicleLocationsSaveFailedErrorParameter = culture === "fi" ? Translations_fi.VehicleLocationsSaveFailedErrorParameter : Translations_en_US.VehicleLocationsSaveFailedErrorParameter;
		Translations.VehicleLocationsSaveSuccess = culture === "fi" ? Translations_fi.VehicleLocationsSaveSuccess : Translations_en_US.VehicleLocationsSaveSuccess;
		Translations.AddToFavories = culture === "fi" ? Translations_fi.AddToFavories : Translations_en_US.AddToFavories;
		Translations.Favorites = culture === "fi" ? Translations_fi.Favorites : Translations_en_US.Favorites;
		Translations.LookUp = culture === "fi" ? Translations_fi.LookUp : Translations_en_US.LookUp;
		Translations.RemoveFromFavories = culture === "fi" ? Translations_fi.RemoveFromFavories : Translations_en_US.RemoveFromFavories;
		Translations.SiteMapLinkUpdateFailedConcurrencyError = culture === "fi" ? Translations_fi.SiteMapLinkUpdateFailedConcurrencyError : Translations_en_US.SiteMapLinkUpdateFailedConcurrencyError;
		Translations.SiteMapLinkUpdateFailedErrorParameter = culture === "fi" ? Translations_fi.SiteMapLinkUpdateFailedErrorParameter : Translations_en_US.SiteMapLinkUpdateFailedErrorParameter;
		Translations.SiteMapLinkUpdateSuccess = culture === "fi" ? Translations_fi.SiteMapLinkUpdateSuccess : Translations_en_US.SiteMapLinkUpdateSuccess;
		Translations.SitesMapLinkUpdateFailedConcurrencyError = culture === "fi" ? Translations_fi.SitesMapLinkUpdateFailedConcurrencyError : Translations_en_US.SitesMapLinkUpdateFailedConcurrencyError;
		Translations.SitesMapLinkUpdateFailedErrorParameter = culture === "fi" ? Translations_fi.SitesMapLinkUpdateFailedErrorParameter : Translations_en_US.SitesMapLinkUpdateFailedErrorParameter;
		Translations.SitesMapLinkUpdateSuccess = culture === "fi" ? Translations_fi.SitesMapLinkUpdateSuccess : Translations_en_US.SitesMapLinkUpdateSuccess;
		Translations.SitesRemoveConfirmation = culture === "fi" ? Translations_fi.SitesRemoveConfirmation : Translations_en_US.SitesRemoveConfirmation;
		Translations.SitesRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.SitesRemoveFailedConcurrencyError : Translations_en_US.SitesRemoveFailedConcurrencyError;
		Translations.SitesRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.SitesRemoveFailedErrorParameter : Translations_en_US.SitesRemoveFailedErrorParameter;
		Translations.SitesRemoveSuccess = culture === "fi" ? Translations_fi.SitesRemoveSuccess : Translations_en_US.SitesRemoveSuccess;
		Translations.CalendarAccuracyDefault = culture === "fi" ? Translations_fi.CalendarAccuracyDefault : Translations_en_US.CalendarAccuracyDefault;
		Translations.CalendarOfficeTimeEnd = culture === "fi" ? Translations_fi.CalendarOfficeTimeEnd : Translations_en_US.CalendarOfficeTimeEnd;
		Translations.CalendarOfficeTimeStart = culture === "fi" ? Translations_fi.CalendarOfficeTimeStart : Translations_en_US.CalendarOfficeTimeStart;
		Translations.ClickToAddImage = culture === "fi" ? Translations_fi.ClickToAddImage : Translations_en_US.ClickToAddImage;
		Translations.DefaultCulture = culture === "fi" ? Translations_fi.DefaultCulture : Translations_en_US.DefaultCulture;
		Translations.LanguageEnglish = culture === "fi" ? Translations_fi.LanguageEnglish : Translations_en_US.LanguageEnglish;
		Translations.LanguageFinnish = culture === "fi" ? Translations_fi.LanguageFinnish : Translations_en_US.LanguageFinnish;
		Translations.Map = culture === "fi" ? Translations_fi.Map : Translations_en_US.Map;
		Translations.OnMap = culture === "fi" ? Translations_fi.OnMap : Translations_en_US.OnMap;
		Translations.TimeTable = culture === "fi" ? Translations_fi.TimeTable : Translations_en_US.TimeTable;
		Translations.Company = culture === "fi" ? Translations_fi.Company : Translations_en_US.Company;
		Translations.CompanySettings = culture === "fi" ? Translations_fi.CompanySettings : Translations_en_US.CompanySettings;
		Translations.CompetencyTypeRemoveConfirmation = culture === "fi" ? Translations_fi.CompetencyTypeRemoveConfirmation : Translations_en_US.CompetencyTypeRemoveConfirmation;
		Translations.CompetencyTypes = culture === "fi" ? Translations_fi.CompetencyTypes : Translations_en_US.CompetencyTypes;
		Translations.CostCenterRemoveConfirmation = culture === "fi" ? Translations_fi.CostCenterRemoveConfirmation : Translations_en_US.CostCenterRemoveConfirmation;
		Translations.CustomerTypeRemoveConfirmation = culture === "fi" ? Translations_fi.CustomerTypeRemoveConfirmation : Translations_en_US.CustomerTypeRemoveConfirmation;
		Translations.CustomerTypes = culture === "fi" ? Translations_fi.CustomerTypes : Translations_en_US.CustomerTypes;
		Translations.DayBookingTypeRemoveConfirmation = culture === "fi" ? Translations_fi.DayBookingTypeRemoveConfirmation : Translations_en_US.DayBookingTypeRemoveConfirmation;
		Translations.DayBookingTypes = culture === "fi" ? Translations_fi.DayBookingTypes : Translations_en_US.DayBookingTypes;
		Translations.HourBookingTypes = culture === "fi" ? Translations_fi.HourBookingTypes : Translations_en_US.HourBookingTypes;
		Translations.Mobile = culture === "fi" ? Translations_fi.Mobile : Translations_en_US.Mobile;
		Translations.ProductGroups = culture === "fi" ? Translations_fi.ProductGroups : Translations_en_US.ProductGroups;
	}

	static setCulture2(culture: string) {
		Translations.SalaryRowTypes = culture === "fi" ? Translations_fi.SalaryRowTypes : Translations_en_US.SalaryRowTypes;
		Translations.SalaryTypes = culture === "fi" ? Translations_fi.SalaryTypes : Translations_en_US.SalaryTypes;
		Translations.SendingType = culture === "fi" ? Translations_fi.SendingType : Translations_en_US.SendingType;
		Translations.UserGroupRemoveConfirmation = culture === "fi" ? Translations_fi.UserGroupRemoveConfirmation : Translations_en_US.UserGroupRemoveConfirmation;
		Translations.UserGroups = culture === "fi" ? Translations_fi.UserGroups : Translations_en_US.UserGroups;
		Translations.VatRate = culture === "fi" ? Translations_fi.VatRate : Translations_en_US.VatRate;
		Translations.Web = culture === "fi" ? Translations_fi.Web : Translations_en_US.Web;
		Translations.WorkOrderAcknowledgementTypeRemoveConfirmation = culture === "fi" ? Translations_fi.WorkOrderAcknowledgementTypeRemoveConfirmation : Translations_en_US.WorkOrderAcknowledgementTypeRemoveConfirmation;
		Translations.WorkOrderAcknowledgementTypes = culture === "fi" ? Translations_fi.WorkOrderAcknowledgementTypes : Translations_en_US.WorkOrderAcknowledgementTypes;
		Translations.WorkOrderAcknowledgementTypeSendingTypeDoNotSend = culture === "fi" ? Translations_fi.WorkOrderAcknowledgementTypeSendingTypeDoNotSend : Translations_en_US.WorkOrderAcknowledgementTypeSendingTypeDoNotSend;
		Translations.WorkOrderAcknowledgementTypeSendingTypeSendAlways = culture === "fi" ? Translations_fi.WorkOrderAcknowledgementTypeSendingTypeSendAlways : Translations_en_US.WorkOrderAcknowledgementTypeSendingTypeSendAlways;
		Translations.WorkOrderAcknowledgementTypeSendingTypeSendManually = culture === "fi" ? Translations_fi.WorkOrderAcknowledgementTypeSendingTypeSendManually : Translations_en_US.WorkOrderAcknowledgementTypeSendingTypeSendManually;
		Translations.WorkOrderTypeRemoveConfirmation = culture === "fi" ? Translations_fi.WorkOrderTypeRemoveConfirmation : Translations_en_US.WorkOrderTypeRemoveConfirmation;
		Translations.LocationError = culture === "fi" ? Translations_fi.LocationError : Translations_en_US.LocationError;
		Translations.LocationSuccess = culture === "fi" ? Translations_fi.LocationSuccess : Translations_en_US.LocationSuccess;
		Translations.AddRoute = culture === "fi" ? Translations_fi.AddRoute : Translations_en_US.AddRoute;
		Translations.AddRoutePoint = culture === "fi" ? Translations_fi.AddRoutePoint : Translations_en_US.AddRoutePoint;
		Translations.EditRoute = culture === "fi" ? Translations_fi.EditRoute : Translations_en_US.EditRoute;
		Translations.HideAdditionalInformation = culture === "fi" ? Translations_fi.HideAdditionalInformation : Translations_en_US.HideAdditionalInformation;
		Translations.Route = culture === "fi" ? Translations_fi.Route : Translations_en_US.Route;
		Translations.ShowAdditionalInformation = culture === "fi" ? Translations_fi.ShowAdditionalInformation : Translations_en_US.ShowAdditionalInformation;
		Translations.RoutePointRemoveConfirmation = culture === "fi" ? Translations_fi.RoutePointRemoveConfirmation : Translations_en_US.RoutePointRemoveConfirmation;
		Translations.Approve = culture === "fi" ? Translations_fi.Approve : Translations_en_US.Approve;
		Translations.UnresourcedStandByWorkOrders = culture === "fi" ? Translations_fi.UnresourcedStandByWorkOrders : Translations_en_US.UnresourcedStandByWorkOrders;
		Translations.UnscheduledStandByWorkOrders = culture === "fi" ? Translations_fi.UnscheduledStandByWorkOrders : Translations_en_US.UnscheduledStandByWorkOrders;
		Translations.OwnerParameterNotFound = culture === "fi" ? Translations_fi.OwnerParameterNotFound : Translations_en_US.OwnerParameterNotFound;
		Translations.OwnerSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.OwnerSaveFailedConcurrencyError : Translations_en_US.OwnerSaveFailedConcurrencyError;
		Translations.OwnerSaveFailedErrorParameter = culture === "fi" ? Translations_fi.OwnerSaveFailedErrorParameter : Translations_en_US.OwnerSaveFailedErrorParameter;
		Translations.OwnerSaveSuccess = culture === "fi" ? Translations_fi.OwnerSaveSuccess : Translations_en_US.OwnerSaveSuccess;
		Translations.Theme = culture === "fi" ? Translations_fi.Theme : Translations_en_US.Theme;
		Translations.DoLockAll = culture === "fi" ? Translations_fi.DoLockAll : Translations_en_US.DoLockAll;
		Translations.DoUnlockAll = culture === "fi" ? Translations_fi.DoUnlockAll : Translations_en_US.DoUnlockAll;
		Translations.Locked = culture === "fi" ? Translations_fi.Locked : Translations_en_US.Locked;
		Translations.WorkShiftTimeSlotsLockingFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkShiftTimeSlotsLockingFailedConcurrencyError : Translations_en_US.WorkShiftTimeSlotsLockingFailedConcurrencyError;
		Translations.WorkShiftTimeSlotsLockingFailedErrorParameter = culture === "fi" ? Translations_fi.WorkShiftTimeSlotsLockingFailedErrorParameter : Translations_en_US.WorkShiftTimeSlotsLockingFailedErrorParameter;
		Translations.WorkShiftTimeSlotsLockingSuccess = culture === "fi" ? Translations_fi.WorkShiftTimeSlotsLockingSuccess : Translations_en_US.WorkShiftTimeSlotsLockingSuccess;
		Translations.WorkShiftTimeSlotStateInProgressCannotBeLocked = culture === "fi" ? Translations_fi.WorkShiftTimeSlotStateInProgressCannotBeLocked : Translations_en_US.WorkShiftTimeSlotStateInProgressCannotBeLocked;
		Translations.WorkShiftTimeSlotStateLocked = culture === "fi" ? Translations_fi.WorkShiftTimeSlotStateLocked : Translations_en_US.WorkShiftTimeSlotStateLocked;
		Translations.WorkShiftTimeSlotStateLockedCannotBeChanged = culture === "fi" ? Translations_fi.WorkShiftTimeSlotStateLockedCannotBeChanged : Translations_en_US.WorkShiftTimeSlotStateLockedCannotBeChanged;
		Translations.WorkShiftTimeSlotStateLockedCannotBeRemoved = culture === "fi" ? Translations_fi.WorkShiftTimeSlotStateLockedCannotBeRemoved : Translations_en_US.WorkShiftTimeSlotStateLockedCannotBeRemoved;
		Translations.WorkShiftTimeSlotsUnlockingFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkShiftTimeSlotsUnlockingFailedConcurrencyError : Translations_en_US.WorkShiftTimeSlotsUnlockingFailedConcurrencyError;
		Translations.WorkShiftTimeSlotsUnlockingFailedErrorParameter = culture === "fi" ? Translations_fi.WorkShiftTimeSlotsUnlockingFailedErrorParameter : Translations_en_US.WorkShiftTimeSlotsUnlockingFailedErrorParameter;
		Translations.WorkShiftTimeSlotsUnlockingSuccess = culture === "fi" ? Translations_fi.WorkShiftTimeSlotsUnlockingSuccess : Translations_en_US.WorkShiftTimeSlotsUnlockingSuccess;
		Translations.CalculatedH = culture === "fi" ? Translations_fi.CalculatedH : Translations_en_US.CalculatedH;
		Translations.InvoiceH = culture === "fi" ? Translations_fi.InvoiceH : Translations_en_US.InvoiceH;
		Translations.Invoicing = culture === "fi" ? Translations_fi.Invoicing : Translations_en_US.Invoicing;
		Translations.Measured = culture === "fi" ? Translations_fi.Measured : Translations_en_US.Measured;
		Translations.FormContainsUnapprovedValues = culture === "fi" ? Translations_fi.FormContainsUnapprovedValues : Translations_en_US.FormContainsUnapprovedValues;
		Translations.Kind = culture === "fi" ? Translations_fi.Kind : Translations_en_US.Kind;
		Translations.PeriodAdditionalHours = culture === "fi" ? Translations_fi.PeriodAdditionalHours : Translations_en_US.PeriodAdditionalHours;
		Translations.WorkHours = culture === "fi" ? Translations_fi.WorkHours : Translations_en_US.WorkHours;
		Translations.SalaryPeriodParameterNotFound = culture === "fi" ? Translations_fi.SalaryPeriodParameterNotFound : Translations_en_US.SalaryPeriodParameterNotFound;
		Translations.WorkTimePeriodParameterNotFound = culture === "fi" ? Translations_fi.WorkTimePeriodParameterNotFound : Translations_en_US.WorkTimePeriodParameterNotFound;
		Translations.SalaryPeriodRemoveConfirmation = culture === "fi" ? Translations_fi.SalaryPeriodRemoveConfirmation : Translations_en_US.SalaryPeriodRemoveConfirmation;
		Translations.SalaryPeriods = culture === "fi" ? Translations_fi.SalaryPeriods : Translations_en_US.SalaryPeriods;
		Translations.WorkTimePeriod = culture === "fi" ? Translations_fi.WorkTimePeriod : Translations_en_US.WorkTimePeriod;
		Translations.WorkTimePeriods = culture === "fi" ? Translations_fi.WorkTimePeriods : Translations_en_US.WorkTimePeriods;
		Translations.CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterHourBookings = culture === "fi" ? Translations_fi.CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterHourBookings : Translations_en_US.CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterHourBookings;
		Translations.CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkShiftTimeSlots = culture === "fi" ? Translations_fi.CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkShiftTimeSlots : Translations_en_US.CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkShiftTimeSlots;
		Translations.CostCenterParameterNotFound = culture === "fi" ? Translations_fi.CostCenterParameterNotFound : Translations_en_US.CostCenterParameterNotFound;
		Translations.CostCenterRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.CostCenterRemoveFailedConcurrencyError : Translations_en_US.CostCenterRemoveFailedConcurrencyError;
		Translations.CostCenterRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.CostCenterRemoveFailedErrorParameter : Translations_en_US.CostCenterRemoveFailedErrorParameter;
		Translations.CostCenterRemoveSuccess = culture === "fi" ? Translations_fi.CostCenterRemoveSuccess : Translations_en_US.CostCenterRemoveSuccess;
		Translations.CostCenterSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.CostCenterSaveFailedConcurrencyError : Translations_en_US.CostCenterSaveFailedConcurrencyError;
		Translations.CostCenterSaveFailedErrorParameter = culture === "fi" ? Translations_fi.CostCenterSaveFailedErrorParameter : Translations_en_US.CostCenterSaveFailedErrorParameter;
		Translations.CostCenterSaveSuccess = culture === "fi" ? Translations_fi.CostCenterSaveSuccess : Translations_en_US.CostCenterSaveSuccess;
		Translations.EmployeeGroupRemoveConfirmation = culture === "fi" ? Translations_fi.EmployeeGroupRemoveConfirmation : Translations_en_US.EmployeeGroupRemoveConfirmation;
		Translations.EmployeeGroups = culture === "fi" ? Translations_fi.EmployeeGroups : Translations_en_US.EmployeeGroups;
		Translations.EmployeeGroupsHaveNotBeenSpecified = culture === "fi" ? Translations_fi.EmployeeGroupsHaveNotBeenSpecified : Translations_en_US.EmployeeGroupsHaveNotBeenSpecified;
		Translations.SalaryTypesHaveNotBeenSpecified = culture === "fi" ? Translations_fi.SalaryTypesHaveNotBeenSpecified : Translations_en_US.SalaryTypesHaveNotBeenSpecified;
		Translations.UserGroupsHaveNotBeenSpecified = culture === "fi" ? Translations_fi.UserGroupsHaveNotBeenSpecified : Translations_en_US.UserGroupsHaveNotBeenSpecified;
		Translations.VehicleGroup = culture === "fi" ? Translations_fi.VehicleGroup : Translations_en_US.VehicleGroup;
		Translations.VehicleGroupRemoveConfirmation = culture === "fi" ? Translations_fi.VehicleGroupRemoveConfirmation : Translations_en_US.VehicleGroupRemoveConfirmation;
		Translations.VehicleGroups = culture === "fi" ? Translations_fi.VehicleGroups : Translations_en_US.VehicleGroups;
		Translations.VehicleGroupsHaveNotBeenSpecified = culture === "fi" ? Translations_fi.VehicleGroupsHaveNotBeenSpecified : Translations_en_US.VehicleGroupsHaveNotBeenSpecified;
		Translations.TokenHasBeenExpired = culture === "fi" ? Translations_fi.TokenHasBeenExpired : Translations_en_US.TokenHasBeenExpired;
		Translations.WorkOrderDurationIsZero = culture === "fi" ? Translations_fi.WorkOrderDurationIsZero : Translations_en_US.WorkOrderDurationIsZero;
		Translations.WorkOrderParameterDurationIsZero = culture === "fi" ? Translations_fi.WorkOrderParameterDurationIsZero : Translations_en_US.WorkOrderParameterDurationIsZero;
		Translations.WorkOrderTaskParameterDurationIsZero = culture === "fi" ? Translations_fi.WorkOrderTaskParameterDurationIsZero : Translations_en_US.WorkOrderTaskParameterDurationIsZero;
		Translations.InvalidEndTime = culture === "fi" ? Translations_fi.InvalidEndTime : Translations_en_US.InvalidEndTime;
		Translations.RemoveTask = culture === "fi" ? Translations_fi.RemoveTask : Translations_en_US.RemoveTask;
		Translations.RemoveTasks = culture === "fi" ? Translations_fi.RemoveTasks : Translations_en_US.RemoveTasks;
		Translations.WorkOrderProjectRecurringParameterCannotBeRemovedBecauseOtherWorkOrderTasksAreBelongingToIt = culture === "fi" ? Translations_fi.WorkOrderProjectRecurringParameterCannotBeRemovedBecauseOtherWorkOrderTasksAreBelongingToIt : Translations_en_US.WorkOrderProjectRecurringParameterCannotBeRemovedBecauseOtherWorkOrderTasksAreBelongingToIt;
		Translations.WorkOrdersDetachConfirmation = culture === "fi" ? Translations_fi.WorkOrdersDetachConfirmation : Translations_en_US.WorkOrdersDetachConfirmation;
		Translations.WorkOrdersDetachConfirmationRecurring = culture === "fi" ? Translations_fi.WorkOrdersDetachConfirmationRecurring : Translations_en_US.WorkOrdersDetachConfirmationRecurring;
		Translations.WorkShiftStartedParameter = culture === "fi" ? Translations_fi.WorkShiftStartedParameter : Translations_en_US.WorkShiftStartedParameter;
		Translations.AddRecurringTasks = culture === "fi" ? Translations_fi.AddRecurringTasks : Translations_en_US.AddRecurringTasks;
		Translations.DoYouReallyWantToAddRecurringTasks = culture === "fi" ? Translations_fi.DoYouReallyWantToAddRecurringTasks : Translations_en_US.DoYouReallyWantToAddRecurringTasks;
		Translations.AddExistingWorkOrderWork = culture === "fi" ? Translations_fi.AddExistingWorkOrderWork : Translations_en_US.AddExistingWorkOrderWork;
		Translations.AddTask = culture === "fi" ? Translations_fi.AddTask : Translations_en_US.AddTask;
		Translations.AttachWorkToRecurringWork = culture === "fi" ? Translations_fi.AttachWorkToRecurringWork : Translations_en_US.AttachWorkToRecurringWork;
		Translations.DoYouReallyWantToChangeWorkOrders = culture === "fi" ? Translations_fi.DoYouReallyWantToChangeWorkOrders : Translations_en_US.DoYouReallyWantToChangeWorkOrders;
		Translations.DurationOfRecurringTaskCannotBeZero = culture === "fi" ? Translations_fi.DurationOfRecurringTaskCannotBeZero : Translations_en_US.DurationOfRecurringTaskCannotBeZero;
		Translations.DurationOfRecurringWorkCannotBeZero = culture === "fi" ? Translations_fi.DurationOfRecurringWorkCannotBeZero : Translations_en_US.DurationOfRecurringWorkCannotBeZero;
		Translations.Set = culture === "fi" ? Translations_fi.Set : Translations_en_US.Set;
		Translations.SetEmployees = culture === "fi" ? Translations_fi.SetEmployees : Translations_en_US.SetEmployees;
		Translations.SetState = culture === "fi" ? Translations_fi.SetState : Translations_en_US.SetState;
		Translations.SetVehicles = culture === "fi" ? Translations_fi.SetVehicles : Translations_en_US.SetVehicles;
		Translations.ValuesCopiedFromRecurringWork = culture === "fi" ? Translations_fi.ValuesCopiedFromRecurringWork : Translations_en_US.ValuesCopiedFromRecurringWork;
		Translations.ValuesCopiedFromWorkOrderTask = culture === "fi" ? Translations_fi.ValuesCopiedFromWorkOrderTask : Translations_en_US.ValuesCopiedFromWorkOrderTask;
		Translations.WorkOrderAddRecurringTasksFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrderAddRecurringTasksFailedConcurrencyError : Translations_en_US.WorkOrderAddRecurringTasksFailedConcurrencyError;
		Translations.WorkOrderAddRecurringTasksFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderAddRecurringTasksFailedErrorParameter : Translations_en_US.WorkOrderAddRecurringTasksFailedErrorParameter;
		Translations.WorkOrderAddRecurringTasksSuccess = culture === "fi" ? Translations_fi.WorkOrderAddRecurringTasksSuccess : Translations_en_US.WorkOrderAddRecurringTasksSuccess;
		Translations.WorkOrderParameterEmployeesChangeFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrderParameterEmployeesChangeFailedConcurrencyError : Translations_en_US.WorkOrderParameterEmployeesChangeFailedConcurrencyError;
		Translations.WorkOrderParameterEmployeesChangeFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderParameterEmployeesChangeFailedErrorParameter : Translations_en_US.WorkOrderParameterEmployeesChangeFailedErrorParameter;
		Translations.WorkOrderParameterEmployeesChangeSuccess = culture === "fi" ? Translations_fi.WorkOrderParameterEmployeesChangeSuccess : Translations_en_US.WorkOrderParameterEmployeesChangeSuccess;
		Translations.WorkOrderParameterVehiclesChangeFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrderParameterVehiclesChangeFailedConcurrencyError : Translations_en_US.WorkOrderParameterVehiclesChangeFailedConcurrencyError;
		Translations.WorkOrderParameterVehiclesChangeFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderParameterVehiclesChangeFailedErrorParameter : Translations_en_US.WorkOrderParameterVehiclesChangeFailedErrorParameter;
		Translations.WorkOrderParameterVehiclesChangeSuccess = culture === "fi" ? Translations_fi.WorkOrderParameterVehiclesChangeSuccess : Translations_en_US.WorkOrderParameterVehiclesChangeSuccess;
		Translations.WorkOrdersParameterEmployeesChangeFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrdersParameterEmployeesChangeFailedConcurrencyError : Translations_en_US.WorkOrdersParameterEmployeesChangeFailedConcurrencyError;
		Translations.WorkOrdersParameterEmployeesChangeFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrdersParameterEmployeesChangeFailedErrorParameter : Translations_en_US.WorkOrdersParameterEmployeesChangeFailedErrorParameter;
		Translations.WorkOrdersParameterEmployeesChangeSuccess = culture === "fi" ? Translations_fi.WorkOrdersParameterEmployeesChangeSuccess : Translations_en_US.WorkOrdersParameterEmployeesChangeSuccess;
		Translations.WorkOrdersParameterVehclesChangeFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrdersParameterVehclesChangeFailedErrorParameter : Translations_en_US.WorkOrdersParameterVehclesChangeFailedErrorParameter;
		Translations.WorkOrdersParameterVehiclesChangeFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrdersParameterVehiclesChangeFailedConcurrencyError : Translations_en_US.WorkOrdersParameterVehiclesChangeFailedConcurrencyError;
		Translations.WorkOrdersParameterVehiclesChangeSuccess = culture === "fi" ? Translations_fi.WorkOrdersParameterVehiclesChangeSuccess : Translations_en_US.WorkOrdersParameterVehiclesChangeSuccess;
		Translations.IntegrationParameterSaveFailed = culture === "fi" ? Translations_fi.IntegrationParameterSaveFailed : Translations_en_US.IntegrationParameterSaveFailed;
		Translations.IntegrationParameterSaveSuccess = culture === "fi" ? Translations_fi.IntegrationParameterSaveSuccess : Translations_en_US.IntegrationParameterSaveSuccess;
		Translations.WorkOrderParameterRoutePointSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrderParameterRoutePointSaveFailedConcurrencyError : Translations_en_US.WorkOrderParameterRoutePointSaveFailedConcurrencyError;
		Translations.WorkOrderParameterRoutePointsSaveFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderParameterRoutePointsSaveFailedErrorParameter : Translations_en_US.WorkOrderParameterRoutePointsSaveFailedErrorParameter;
		Translations.WorkOrderParameterRoutePointsSaveSuccess = culture === "fi" ? Translations_fi.WorkOrderParameterRoutePointsSaveSuccess : Translations_en_US.WorkOrderParameterRoutePointsSaveSuccess;
		Translations.WorkOrderRemoveRoutePointsFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderRemoveRoutePointsFailedErrorParameter : Translations_en_US.WorkOrderRemoveRoutePointsFailedErrorParameter;
		Translations.WorkOrderRemoveRoutePointsSuccess = culture === "fi" ? Translations_fi.WorkOrderRemoveRoutePointsSuccess : Translations_en_US.WorkOrderRemoveRoutePointsSuccess;
		Translations.CreateSalaryFileDataFailedErrorParameter = culture === "fi" ? Translations_fi.CreateSalaryFileDataFailedErrorParameter : Translations_en_US.CreateSalaryFileDataFailedErrorParameter;
		Translations.CreateSalaryFileDataSuccess = culture === "fi" ? Translations_fi.CreateSalaryFileDataSuccess : Translations_en_US.CreateSalaryFileDataSuccess;
		Translations.EventLogMessageSalaryFileCreated = culture === "fi" ? Translations_fi.EventLogMessageSalaryFileCreated : Translations_en_US.EventLogMessageSalaryFileCreated;
		Translations.SalaryFile = culture === "fi" ? Translations_fi.SalaryFile : Translations_en_US.SalaryFile;
		Translations.BusinessIdentityCode = culture === "fi" ? Translations_fi.BusinessIdentityCode : Translations_en_US.BusinessIdentityCode;
		Translations.EInvoiceAddress = culture === "fi" ? Translations_fi.EInvoiceAddress : Translations_en_US.EInvoiceAddress;
		Translations.EInvoiceOperator = culture === "fi" ? Translations_fi.EInvoiceOperator : Translations_en_US.EInvoiceOperator;
		Translations.InvoiceEmail = culture === "fi" ? Translations_fi.InvoiceEmail : Translations_en_US.InvoiceEmail;
		Translations.SiteTypePoint = culture === "fi" ? Translations_fi.SiteTypePoint : Translations_en_US.SiteTypePoint;
		Translations.SiteTypeRoute = culture === "fi" ? Translations_fi.SiteTypeRoute : Translations_en_US.SiteTypeRoute;
		Translations.VatCode = culture === "fi" ? Translations_fi.VatCode : Translations_en_US.VatCode;
		Translations.WeekHours = culture === "fi" ? Translations_fi.WeekHours : Translations_en_US.WeekHours;
		Translations.WorkTimeTotal = culture === "fi" ? Translations_fi.WorkTimeTotal : Translations_en_US.WorkTimeTotal;
		Translations.WorkShiftTimeSlot = culture === "fi" ? Translations_fi.WorkShiftTimeSlot : Translations_en_US.WorkShiftTimeSlot;
		Translations.WorkShiftTimeSlotTypeParameterNotFound = culture === "fi" ? Translations_fi.WorkShiftTimeSlotTypeParameterNotFound : Translations_en_US.WorkShiftTimeSlotTypeParameterNotFound;
		Translations.WorkShiftTimeSlotTypesHaveNotBeenSpecified = culture === "fi" ? Translations_fi.WorkShiftTimeSlotTypesHaveNotBeenSpecified : Translations_en_US.WorkShiftTimeSlotTypesHaveNotBeenSpecified;
		Translations.AllRoutePointsHaveBeenHandled = culture === "fi" ? Translations_fi.AllRoutePointsHaveBeenHandled : Translations_en_US.AllRoutePointsHaveBeenHandled;
		Translations.RoutePointWorkShiftTimeSlotEndTime = culture === "fi" ? Translations_fi.RoutePointWorkShiftTimeSlotEndTime : Translations_en_US.RoutePointWorkShiftTimeSlotEndTime;
		Translations.RoutePointWorkShiftTimeSlotEndTimeSet = culture === "fi" ? Translations_fi.RoutePointWorkShiftTimeSlotEndTimeSet : Translations_en_US.RoutePointWorkShiftTimeSlotEndTimeSet;
		Translations.RoutePointWorkShiftTimeSlotStartTime = culture === "fi" ? Translations_fi.RoutePointWorkShiftTimeSlotStartTime : Translations_en_US.RoutePointWorkShiftTimeSlotStartTime;
		Translations.SavingIsNotFinished = culture === "fi" ? Translations_fi.SavingIsNotFinished : Translations_en_US.SavingIsNotFinished;
		Translations.WorkOrderParameterRoutePointParameterHasBookingsAndCannotBeRemoved = culture === "fi" ? Translations_fi.WorkOrderParameterRoutePointParameterHasBookingsAndCannotBeRemoved : Translations_en_US.WorkOrderParameterRoutePointParameterHasBookingsAndCannotBeRemoved;
		Translations.ParameterTargets = culture === "fi" ? Translations_fi.ParameterTargets : Translations_en_US.ParameterTargets;
		Translations.SiteTypeNoLocation = culture === "fi" ? Translations_fi.SiteTypeNoLocation : Translations_en_US.SiteTypeNoLocation;
		Translations.Integrations = culture === "fi" ? Translations_fi.Integrations : Translations_en_US.Integrations;
		Translations.VehiclesVisibleOnMap = culture === "fi" ? Translations_fi.VehiclesVisibleOnMap : Translations_en_US.VehiclesVisibleOnMap;
		Translations.Abbreviation = culture === "fi" ? Translations_fi.Abbreviation : Translations_en_US.Abbreviation;
		Translations.Telematics = culture === "fi" ? Translations_fi.Telematics : Translations_en_US.Telematics;
		Translations.SiteCannotBeChangedWhenRoutePointBookingsAreDefined = culture === "fi" ? Translations_fi.SiteCannotBeChangedWhenRoutePointBookingsAreDefined : Translations_en_US.SiteCannotBeChangedWhenRoutePointBookingsAreDefined;
		Translations.WorkShiftTimeSlotDurationCannotBeExtended = culture === "fi" ? Translations_fi.WorkShiftTimeSlotDurationCannotBeExtended : Translations_en_US.WorkShiftTimeSlotDurationCannotBeExtended;
		Translations.CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterEmployees = culture === "fi" ? Translations_fi.CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterEmployees : Translations_en_US.CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterEmployees;
		Translations.CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterVehicles = culture === "fi" ? Translations_fi.CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterVehicles : Translations_en_US.CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterVehicles;
		Translations.EmployeeGroupParameterCannotBeRemovedBecauseItIsRelatedToParameterEmployees = culture === "fi" ? Translations_fi.EmployeeGroupParameterCannotBeRemovedBecauseItIsRelatedToParameterEmployees : Translations_en_US.EmployeeGroupParameterCannotBeRemovedBecauseItIsRelatedToParameterEmployees;
		Translations.EmployeeGroupParameterNotFound = culture === "fi" ? Translations_fi.EmployeeGroupParameterNotFound : Translations_en_US.EmployeeGroupParameterNotFound;
		Translations.EmployeeGroupRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.EmployeeGroupRemoveFailedConcurrencyError : Translations_en_US.EmployeeGroupRemoveFailedConcurrencyError;
		Translations.EmployeeGroupRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.EmployeeGroupRemoveFailedErrorParameter : Translations_en_US.EmployeeGroupRemoveFailedErrorParameter;
		Translations.EmployeeGroupRemoveSuccess = culture === "fi" ? Translations_fi.EmployeeGroupRemoveSuccess : Translations_en_US.EmployeeGroupRemoveSuccess;
		Translations.EmployeeGroupSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.EmployeeGroupSaveFailedConcurrencyError : Translations_en_US.EmployeeGroupSaveFailedConcurrencyError;
		Translations.EmployeeGroupSaveFailedErrorParameter = culture === "fi" ? Translations_fi.EmployeeGroupSaveFailedErrorParameter : Translations_en_US.EmployeeGroupSaveFailedErrorParameter;
		Translations.EmployeeGroupSaveSuccess = culture === "fi" ? Translations_fi.EmployeeGroupSaveSuccess : Translations_en_US.EmployeeGroupSaveSuccess;
		Translations.DurationMustBeDefined = culture === "fi" ? Translations_fi.DurationMustBeDefined : Translations_en_US.DurationMustBeDefined;
		Translations.Employment = culture === "fi" ? Translations_fi.Employment : Translations_en_US.Employment;
		Translations.AddDayBooking = culture === "fi" ? Translations_fi.AddDayBooking : Translations_en_US.AddDayBooking;
		Translations.AddWorkShiftTimeSlot = culture === "fi" ? Translations_fi.AddWorkShiftTimeSlot : Translations_en_US.AddWorkShiftTimeSlot;
		Translations.CostCentersHaveNotBeenSpecified = culture === "fi" ? Translations_fi.CostCentersHaveNotBeenSpecified : Translations_en_US.CostCentersHaveNotBeenSpecified;
		Translations.DayBookingRemoveConfirmation = culture === "fi" ? Translations_fi.DayBookingRemoveConfirmation : Translations_en_US.DayBookingRemoveConfirmation;
		Translations.DayBookingRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.DayBookingRemoveFailedErrorParameter : Translations_en_US.DayBookingRemoveFailedErrorParameter;
		Translations.DayBookingRemoveSuccess = culture === "fi" ? Translations_fi.DayBookingRemoveSuccess : Translations_en_US.DayBookingRemoveSuccess;
		Translations.DayBookingSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.DayBookingSaveFailedConcurrencyError : Translations_en_US.DayBookingSaveFailedConcurrencyError;
		Translations.DayBookingSaveSuccess = culture === "fi" ? Translations_fi.DayBookingSaveSuccess : Translations_en_US.DayBookingSaveSuccess;
		Translations.DayBookingsRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.DayBookingsRemoveFailedErrorParameter : Translations_en_US.DayBookingsRemoveFailedErrorParameter;
		Translations.DayBookingsRemoveSuccess = culture === "fi" ? Translations_fi.DayBookingsRemoveSuccess : Translations_en_US.DayBookingsRemoveSuccess;
		Translations.DayBookingsSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.DayBookingsSaveFailedConcurrencyError : Translations_en_US.DayBookingsSaveFailedConcurrencyError;
		Translations.DayBookingsSaveFailedErrorParameter = culture === "fi" ? Translations_fi.DayBookingsSaveFailedErrorParameter : Translations_en_US.DayBookingsSaveFailedErrorParameter;
		Translations.DayBookingsSaveSuccess = culture === "fi" ? Translations_fi.DayBookingsSaveSuccess : Translations_en_US.DayBookingsSaveSuccess;
		Translations.DetachTasks = culture === "fi" ? Translations_fi.DetachTasks : Translations_en_US.DetachTasks;
		Translations.EmployeeIsNotInUse = culture === "fi" ? Translations_fi.EmployeeIsNotInUse : Translations_en_US.EmployeeIsNotInUse;
		Translations.EventLogWorkShiftTimeSlotChanged = culture === "fi" ? Translations_fi.EventLogWorkShiftTimeSlotChanged : Translations_en_US.EventLogWorkShiftTimeSlotChanged;
		Translations.InvalidPassword = culture === "fi" ? Translations_fi.InvalidPassword : Translations_en_US.InvalidPassword;
		Translations.RefreshTokenIsEmpty = culture === "fi" ? Translations_fi.RefreshTokenIsEmpty : Translations_en_US.RefreshTokenIsEmpty;
		Translations.RefreshTokenValidationFailed = culture === "fi" ? Translations_fi.RefreshTokenValidationFailed : Translations_en_US.RefreshTokenValidationFailed;
		Translations.SigningIn = culture === "fi" ? Translations_fi.SigningIn : Translations_en_US.SigningIn;
		Translations.SigningOut = culture === "fi" ? Translations_fi.SigningOut : Translations_en_US.SigningOut;
		Translations.SignInRefresh = culture === "fi" ? Translations_fi.SignInRefresh : Translations_en_US.SignInRefresh;
		Translations.OrginalTimeParameter = culture === "fi" ? Translations_fi.OrginalTimeParameter : Translations_en_US.OrginalTimeParameter;
		Translations.InvoiceHoursAreNotCloseToReportedHours = culture === "fi" ? Translations_fi.InvoiceHoursAreNotCloseToReportedHours : Translations_en_US.InvoiceHoursAreNotCloseToReportedHours;
		Translations.CodeMustBeDefined = culture === "fi" ? Translations_fi.CodeMustBeDefined : Translations_en_US.CodeMustBeDefined;
		Translations.CustomerTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterCustomers = culture === "fi" ? Translations_fi.CustomerTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterCustomers : Translations_en_US.CustomerTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterCustomers;
		Translations.CustomerTypeParameterNotFound = culture === "fi" ? Translations_fi.CustomerTypeParameterNotFound : Translations_en_US.CustomerTypeParameterNotFound;
		Translations.CustomerTypeRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.CustomerTypeRemoveFailedConcurrencyError : Translations_en_US.CustomerTypeRemoveFailedConcurrencyError;
		Translations.CustomerTypeRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.CustomerTypeRemoveFailedErrorParameter : Translations_en_US.CustomerTypeRemoveFailedErrorParameter;
		Translations.CustomerTypeRemoveSuccess = culture === "fi" ? Translations_fi.CustomerTypeRemoveSuccess : Translations_en_US.CustomerTypeRemoveSuccess;
		Translations.CustomerTypeSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.CustomerTypeSaveFailedConcurrencyError : Translations_en_US.CustomerTypeSaveFailedConcurrencyError;
		Translations.CustomerTypeSaveFailedErrorParameter = culture === "fi" ? Translations_fi.CustomerTypeSaveFailedErrorParameter : Translations_en_US.CustomerTypeSaveFailedErrorParameter;
		Translations.CustomerTypeSaveSuccess = culture === "fi" ? Translations_fi.CustomerTypeSaveSuccess : Translations_en_US.CustomerTypeSaveSuccess;
		Translations.ProductGroupParameterCannotBeRemovedBecauseItIsRelatedToParameterProducts = culture === "fi" ? Translations_fi.ProductGroupParameterCannotBeRemovedBecauseItIsRelatedToParameterProducts : Translations_en_US.ProductGroupParameterCannotBeRemovedBecauseItIsRelatedToParameterProducts;
		Translations.ProductGroupParameterNotFound = culture === "fi" ? Translations_fi.ProductGroupParameterNotFound : Translations_en_US.ProductGroupParameterNotFound;
		Translations.ProductGroupRemoveConfirmation = culture === "fi" ? Translations_fi.ProductGroupRemoveConfirmation : Translations_en_US.ProductGroupRemoveConfirmation;
		Translations.ProductGroupRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.ProductGroupRemoveFailedConcurrencyError : Translations_en_US.ProductGroupRemoveFailedConcurrencyError;
		Translations.ProductGroupRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.ProductGroupRemoveFailedErrorParameter : Translations_en_US.ProductGroupRemoveFailedErrorParameter;
		Translations.ProductGroupRemoveSuccess = culture === "fi" ? Translations_fi.ProductGroupRemoveSuccess : Translations_en_US.ProductGroupRemoveSuccess;
		Translations.ProductGroupSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.ProductGroupSaveFailedConcurrencyError : Translations_en_US.ProductGroupSaveFailedConcurrencyError;
		Translations.ProductGroupSaveFailedErrorParameter = culture === "fi" ? Translations_fi.ProductGroupSaveFailedErrorParameter : Translations_en_US.ProductGroupSaveFailedErrorParameter;
		Translations.ProductGroupSaveSuccess = culture === "fi" ? Translations_fi.ProductGroupSaveSuccess : Translations_en_US.ProductGroupSaveSuccess;
		Translations.VehicleGroupParameterCannotBeRemovedBecauseItIsRelatedToParameterVehicles = culture === "fi" ? Translations_fi.VehicleGroupParameterCannotBeRemovedBecauseItIsRelatedToParameterVehicles : Translations_en_US.VehicleGroupParameterCannotBeRemovedBecauseItIsRelatedToParameterVehicles;
		Translations.VehicleGroupParameterNotFound = culture === "fi" ? Translations_fi.VehicleGroupParameterNotFound : Translations_en_US.VehicleGroupParameterNotFound;
		Translations.VehicleGroupRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.VehicleGroupRemoveFailedConcurrencyError : Translations_en_US.VehicleGroupRemoveFailedConcurrencyError;
		Translations.VehicleGroupRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.VehicleGroupRemoveFailedErrorParameter : Translations_en_US.VehicleGroupRemoveFailedErrorParameter;
		Translations.VehicleGroupRemoveSuccess = culture === "fi" ? Translations_fi.VehicleGroupRemoveSuccess : Translations_en_US.VehicleGroupRemoveSuccess;
		Translations.VehicleGroupSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.VehicleGroupSaveFailedConcurrencyError : Translations_en_US.VehicleGroupSaveFailedConcurrencyError;
		Translations.VehicleGroupSaveFailedErrorParameter = culture === "fi" ? Translations_fi.VehicleGroupSaveFailedErrorParameter : Translations_en_US.VehicleGroupSaveFailedErrorParameter;
		Translations.VehicleGroupSaveSuccess = culture === "fi" ? Translations_fi.VehicleGroupSaveSuccess : Translations_en_US.VehicleGroupSaveSuccess;
		Translations.FilterWorkOrders = culture === "fi" ? Translations_fi.FilterWorkOrders : Translations_en_US.FilterWorkOrders;
		Translations.ClearFilters = culture === "fi" ? Translations_fi.ClearFilters : Translations_en_US.ClearFilters;
		Translations.MyWorkList = culture === "fi" ? Translations_fi.MyWorkList : Translations_en_US.MyWorkList;
		Translations.SelectedDay = culture === "fi" ? Translations_fi.SelectedDay : Translations_en_US.SelectedDay;
		Translations.SelectedPeriod = culture === "fi" ? Translations_fi.SelectedPeriod : Translations_en_US.SelectedPeriod;
		Translations.TimeFilter = culture === "fi" ? Translations_fi.TimeFilter : Translations_en_US.TimeFilter;
		Translations.IntegrationServiceCallFailedErrorParameter = culture === "fi" ? Translations_fi.IntegrationServiceCallFailedErrorParameter : Translations_en_US.IntegrationServiceCallFailedErrorParameter;
		Translations.EmployeeParametersSavedSuccessfully = culture === "fi" ? Translations_fi.EmployeeParametersSavedSuccessfully : Translations_en_US.EmployeeParametersSavedSuccessfully;
		Translations.EmployeeParametersSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.EmployeeParametersSaveFailedConcurrencyError : Translations_en_US.EmployeeParametersSaveFailedConcurrencyError;
		Translations.EmployeeParameterSSaveFailedErrorParameter = culture === "fi" ? Translations_fi.EmployeeParameterSSaveFailedErrorParameter : Translations_en_US.EmployeeParameterSSaveFailedErrorParameter;
		Translations.ReadyWorkOrders = culture === "fi" ? Translations_fi.ReadyWorkOrders : Translations_en_US.ReadyWorkOrders;
		Translations.ToBeInvoicedWorkOrders = culture === "fi" ? Translations_fi.ToBeInvoicedWorkOrders : Translations_en_US.ToBeInvoicedWorkOrders;
		Translations.PreliminaryInvoices = culture === "fi" ? Translations_fi.PreliminaryInvoices : Translations_en_US.PreliminaryInvoices;
		Translations.TransferredInvoices = culture === "fi" ? Translations_fi.TransferredInvoices : Translations_en_US.TransferredInvoices;
		Translations.SsoIntegrations = culture === "fi" ? Translations_fi.SsoIntegrations : Translations_en_US.SsoIntegrations;
		Translations.SsoParameters = culture === "fi" ? Translations_fi.SsoParameters : Translations_en_US.SsoParameters;
		Translations.EmployeeMustBeDefined = culture === "fi" ? Translations_fi.EmployeeMustBeDefined : Translations_en_US.EmployeeMustBeDefined;
		Translations.MeasureUnitTn = culture === "fi" ? Translations_fi.MeasureUnitTn : Translations_en_US.MeasureUnitTn;
		Translations.ExportToInvoicingSystem = culture === "fi" ? Translations_fi.ExportToInvoicingSystem : Translations_en_US.ExportToInvoicingSystem;
		Translations.ExportToTransferFile = culture === "fi" ? Translations_fi.ExportToTransferFile : Translations_en_US.ExportToTransferFile;
		Translations.FileExportedToInvoicingSystem = culture === "fi" ? Translations_fi.FileExportedToInvoicingSystem : Translations_en_US.FileExportedToInvoicingSystem;
		Translations.GoToWorkOrderArchive = culture === "fi" ? Translations_fi.GoToWorkOrderArchive : Translations_en_US.GoToWorkOrderArchive;
		Translations.OverlappingWorkShiftTimeSlots = culture === "fi" ? Translations_fi.OverlappingWorkShiftTimeSlots : Translations_en_US.OverlappingWorkShiftTimeSlots;
		Translations.ShowSelectedCostCenters = culture === "fi" ? Translations_fi.ShowSelectedCostCenters : Translations_en_US.ShowSelectedCostCenters;
		Translations.ShowSelectedVehicles = culture === "fi" ? Translations_fi.ShowSelectedVehicles : Translations_en_US.ShowSelectedVehicles;
		Translations.CustomerPriceNotFound = culture === "fi" ? Translations_fi.CustomerPriceNotFound : Translations_en_US.CustomerPriceNotFound;
		Translations.CustomerPriceRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.CustomerPriceRemoveFailedConcurrencyError : Translations_en_US.CustomerPriceRemoveFailedConcurrencyError;
		Translations.CustomerPriceRemoveFailedError = culture === "fi" ? Translations_fi.CustomerPriceRemoveFailedError : Translations_en_US.CustomerPriceRemoveFailedError;
		Translations.CustomerPriceRemoveSuccess = culture === "fi" ? Translations_fi.CustomerPriceRemoveSuccess : Translations_en_US.CustomerPriceRemoveSuccess;
		Translations.CustomerPriceSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.CustomerPriceSaveFailedConcurrencyError : Translations_en_US.CustomerPriceSaveFailedConcurrencyError;
		Translations.CustomerPriceSaveFailedError = culture === "fi" ? Translations_fi.CustomerPriceSaveFailedError : Translations_en_US.CustomerPriceSaveFailedError;
		Translations.CustomerPriceSaveSuccess = culture === "fi" ? Translations_fi.CustomerPriceSaveSuccess : Translations_en_US.CustomerPriceSaveSuccess;
		Translations.CustomerPriceRemoveConfirmation = culture === "fi" ? Translations_fi.CustomerPriceRemoveConfirmation : Translations_en_US.CustomerPriceRemoveConfirmation;
		Translations.CustomerPrices = culture === "fi" ? Translations_fi.CustomerPrices : Translations_en_US.CustomerPrices;
		Translations.CustomerPrice = culture === "fi" ? Translations_fi.CustomerPrice : Translations_en_US.CustomerPrice;
		Translations.APIInvoiceSuccessfullyCreated = culture === "fi" ? Translations_fi.APIInvoiceSuccessfullyCreated : Translations_en_US.APIInvoiceSuccessfullyCreated;
		Translations.InvoicesRemoveConfirmation = culture === "fi" ? Translations_fi.InvoicesRemoveConfirmation : Translations_en_US.InvoicesRemoveConfirmation;
		Translations.InvoicesRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.InvoicesRemoveFailedConcurrencyError : Translations_en_US.InvoicesRemoveFailedConcurrencyError;
		Translations.InvoicesRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.InvoicesRemoveFailedErrorParameter : Translations_en_US.InvoicesRemoveFailedErrorParameter;
		Translations.InvoicesRemoveSuccess = culture === "fi" ? Translations_fi.InvoicesRemoveSuccess : Translations_en_US.InvoicesRemoveSuccess;
		Translations.RelatedToInvoice = culture === "fi" ? Translations_fi.RelatedToInvoice : Translations_en_US.RelatedToInvoice;
		Translations.AllWorkOrderClasses = culture === "fi" ? Translations_fi.AllWorkOrderClasses : Translations_en_US.AllWorkOrderClasses;
		Translations.WorkOrderClass = culture === "fi" ? Translations_fi.WorkOrderClass : Translations_en_US.WorkOrderClass;
		Translations.WorkOrderProjectTask = culture === "fi" ? Translations_fi.WorkOrderProjectTask : Translations_en_US.WorkOrderProjectTask;
		Translations.WorkOrderRecurringTask = culture === "fi" ? Translations_fi.WorkOrderRecurringTask : Translations_en_US.WorkOrderRecurringTask;
		Translations.InvoiceReturnToPreliminaryConfirmation = culture === "fi" ? Translations_fi.InvoiceReturnToPreliminaryConfirmation : Translations_en_US.InvoiceReturnToPreliminaryConfirmation;
		Translations.InvoicesReturnToPreliminaryConfirmation = culture === "fi" ? Translations_fi.InvoicesReturnToPreliminaryConfirmation : Translations_en_US.InvoicesReturnToPreliminaryConfirmation;
		Translations.ReturnToPreliminaryState = culture === "fi" ? Translations_fi.ReturnToPreliminaryState : Translations_en_US.ReturnToPreliminaryState;
		Translations.WorkOrderDistanceInvoicingByActualDistance = culture === "fi" ? Translations_fi.WorkOrderDistanceInvoicingByActualDistance : Translations_en_US.WorkOrderDistanceInvoicingByActualDistance;
		Translations.WorkOrderDistanceInvoicingByPlannedDistance = culture === "fi" ? Translations_fi.WorkOrderDistanceInvoicingByPlannedDistance : Translations_en_US.WorkOrderDistanceInvoicingByPlannedDistance;
		Translations.WorkOrderDistanceInvoicingNone = culture === "fi" ? Translations_fi.WorkOrderDistanceInvoicingNone : Translations_en_US.WorkOrderDistanceInvoicingNone;
		Translations.UserGroupPermissionAdmin = culture === "fi" ? Translations_fi.UserGroupPermissionAdmin : Translations_en_US.UserGroupPermissionAdmin;
		Translations.UserGroupPermissionEdit = culture === "fi" ? Translations_fi.UserGroupPermissionEdit : Translations_en_US.UserGroupPermissionEdit;
		Translations.UserGroupPermissionNone = culture === "fi" ? Translations_fi.UserGroupPermissionNone : Translations_en_US.UserGroupPermissionNone;
		Translations.EstimatedHours = culture === "fi" ? Translations_fi.EstimatedHours : Translations_en_US.EstimatedHours;
		Translations.DetachFromRecurringWork = culture === "fi" ? Translations_fi.DetachFromRecurringWork : Translations_en_US.DetachFromRecurringWork;
		Translations.EstimatedHoursMustBeDefined = culture === "fi" ? Translations_fi.EstimatedHoursMustBeDefined : Translations_en_US.EstimatedHoursMustBeDefined;
		Translations.SetDurationByEstimatedHours = culture === "fi" ? Translations_fi.SetDurationByEstimatedHours : Translations_en_US.SetDurationByEstimatedHours;
		Translations.WorkOrderParameterDurationChangeFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrderParameterDurationChangeFailedConcurrencyError : Translations_en_US.WorkOrderParameterDurationChangeFailedConcurrencyError;
		Translations.WorkOrderParameterDurationChangeFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderParameterDurationChangeFailedErrorParameter : Translations_en_US.WorkOrderParameterDurationChangeFailedErrorParameter;
		Translations.WorkOrderParameterDurationChangeSuccess = culture === "fi" ? Translations_fi.WorkOrderParameterDurationChangeSuccess : Translations_en_US.WorkOrderParameterDurationChangeSuccess;
		Translations.WorkOrderParameterEstimatedHoursMustBeDefined = culture === "fi" ? Translations_fi.WorkOrderParameterEstimatedHoursMustBeDefined : Translations_en_US.WorkOrderParameterEstimatedHoursMustBeDefined;
		Translations.WorkOrdersParameterDurationChangeFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrdersParameterDurationChangeFailedConcurrencyError : Translations_en_US.WorkOrdersParameterDurationChangeFailedConcurrencyError;
		Translations.WorkOrdersParameterDurationChangeFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrdersParameterDurationChangeFailedErrorParameter : Translations_en_US.WorkOrdersParameterDurationChangeFailedErrorParameter;
		Translations.WorkOrdersParameterDurationChangeSuccess = culture === "fi" ? Translations_fi.WorkOrdersParameterDurationChangeSuccess : Translations_en_US.WorkOrdersParameterDurationChangeSuccess;
		Translations.WorkOrderTaskParameterEstimatedHoursMustBeDefined = culture === "fi" ? Translations_fi.WorkOrderTaskParameterEstimatedHoursMustBeDefined : Translations_en_US.WorkOrderTaskParameterEstimatedHoursMustBeDefined;
		Translations.And = culture === "fi" ? Translations_fi.And : Translations_en_US.And;
		Translations.Month = culture === "fi" ? Translations_fi.Month : Translations_en_US.Month;
		Translations.Occurs = culture === "fi" ? Translations_fi.Occurs : Translations_en_US.Occurs;
		Translations.Year = culture === "fi" ? Translations_fi.Year : Translations_en_US.Year;
		Translations.NewProject = culture === "fi" ? Translations_fi.NewProject : Translations_en_US.NewProject;
		Translations.DayBookingTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterDayBookings = culture === "fi" ? Translations_fi.DayBookingTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterDayBookings : Translations_en_US.DayBookingTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterDayBookings;
		Translations.DayBookingTypeParameterNotFound = culture === "fi" ? Translations_fi.DayBookingTypeParameterNotFound : Translations_en_US.DayBookingTypeParameterNotFound;
		Translations.DayBookingTypeParameterParameterSaveFailedErrorParameter = culture === "fi" ? Translations_fi.DayBookingTypeParameterParameterSaveFailedErrorParameter : Translations_en_US.DayBookingTypeParameterParameterSaveFailedErrorParameter;
		Translations.DayBookingTypeParameterRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.DayBookingTypeParameterRemoveFailedConcurrencyError : Translations_en_US.DayBookingTypeParameterRemoveFailedConcurrencyError;
		Translations.DayBookingTypeParameterRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.DayBookingTypeParameterRemoveFailedErrorParameter : Translations_en_US.DayBookingTypeParameterRemoveFailedErrorParameter;
		Translations.DayBookingTypeParameterRemoveSuccess = culture === "fi" ? Translations_fi.DayBookingTypeParameterRemoveSuccess : Translations_en_US.DayBookingTypeParameterRemoveSuccess;
		Translations.DayBookingTypeParameterSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.DayBookingTypeParameterSaveFailedConcurrencyError : Translations_en_US.DayBookingTypeParameterSaveFailedConcurrencyError;
		Translations.DayBookingTypeParameterSaveSuccess = culture === "fi" ? Translations_fi.DayBookingTypeParameterSaveSuccess : Translations_en_US.DayBookingTypeParameterSaveSuccess;
		Translations.DayBookingTypesParameterRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.DayBookingTypesParameterRemoveFailedConcurrencyError : Translations_en_US.DayBookingTypesParameterRemoveFailedConcurrencyError;
		Translations.DayBookingTypesParameterRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.DayBookingTypesParameterRemoveFailedErrorParameter : Translations_en_US.DayBookingTypesParameterRemoveFailedErrorParameter;
		Translations.DayBookingTypesParameterRemoveSuccess = culture === "fi" ? Translations_fi.DayBookingTypesParameterRemoveSuccess : Translations_en_US.DayBookingTypesParameterRemoveSuccess;
		Translations.RoutingFailedFailedErrorParameter = culture === "fi" ? Translations_fi.RoutingFailedFailedErrorParameter : Translations_en_US.RoutingFailedFailedErrorParameter;
		Translations.GeneralSettings = culture === "fi" ? Translations_fi.GeneralSettings : Translations_en_US.GeneralSettings;
		Translations.UserProfile = culture === "fi" ? Translations_fi.UserProfile : Translations_en_US.UserProfile;
		Translations.SetLocationFromMap = culture === "fi" ? Translations_fi.SetLocationFromMap : Translations_en_US.SetLocationFromMap;
		Translations.CopyRecurringWork = culture === "fi" ? Translations_fi.CopyRecurringWork : Translations_en_US.CopyRecurringWork;
		Translations.CopyWorkOrderProject = culture === "fi" ? Translations_fi.CopyWorkOrderProject : Translations_en_US.CopyWorkOrderProject;
		Translations.CopyWorkOrderTask = culture === "fi" ? Translations_fi.CopyWorkOrderTask : Translations_en_US.CopyWorkOrderTask;
		Translations.CopyWorkOrderWork = culture === "fi" ? Translations_fi.CopyWorkOrderWork : Translations_en_US.CopyWorkOrderWork;
		Translations.DataToBeCopied = culture === "fi" ? Translations_fi.DataToBeCopied : Translations_en_US.DataToBeCopied;
		Translations.WorkOrderCopyFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderCopyFailedErrorParameter : Translations_en_US.WorkOrderCopyFailedErrorParameter;
		Translations.WorkOrderCopySuccess = culture === "fi" ? Translations_fi.WorkOrderCopySuccess : Translations_en_US.WorkOrderCopySuccess;
		Translations.EmployeeGroupsVisibleOnDesign = culture === "fi" ? Translations_fi.EmployeeGroupsVisibleOnDesign : Translations_en_US.EmployeeGroupsVisibleOnDesign;
		Translations.VehicleGroupsVisibleOnDesign = culture === "fi" ? Translations_fi.VehicleGroupsVisibleOnDesign : Translations_en_US.VehicleGroupsVisibleOnDesign;
		Translations.MoveToState = culture === "fi" ? Translations_fi.MoveToState : Translations_en_US.MoveToState;
		Translations.CustomerPriceParameterNotFound = culture === "fi" ? Translations_fi.CustomerPriceParameterNotFound : Translations_en_US.CustomerPriceParameterNotFound;
		Translations.WorkOrderAttachmentToInvoice = culture === "fi" ? Translations_fi.WorkOrderAttachmentToInvoice : Translations_en_US.WorkOrderAttachmentToInvoice;
		Translations.DayView = culture === "fi" ? Translations_fi.DayView : Translations_en_US.DayView;
		Translations.WeekView = culture === "fi" ? Translations_fi.WeekView : Translations_en_US.WeekView;
		Translations.WeekViewNoaccordions = culture === "fi" ? Translations_fi.WeekViewNoaccordions : Translations_en_US.WeekViewNoaccordions;
		Translations.ShowOrdersAs = culture === "fi" ? Translations_fi.ShowOrdersAs : Translations_en_US.ShowOrdersAs;
		Translations.VatRateMustBeDefined = culture === "fi" ? Translations_fi.VatRateMustBeDefined : Translations_en_US.VatRateMustBeDefined;
		Translations.EmployeeUsageType = culture === "fi" ? Translations_fi.EmployeeUsageType : Translations_en_US.EmployeeUsageType;
		Translations.EmployeeUsageTypeMultiple = culture === "fi" ? Translations_fi.EmployeeUsageTypeMultiple : Translations_en_US.EmployeeUsageTypeMultiple;
		Translations.EmployeeUsageTypeOne = culture === "fi" ? Translations_fi.EmployeeUsageTypeOne : Translations_en_US.EmployeeUsageTypeOne;
		Translations.HoursInvoicingType = culture === "fi" ? Translations_fi.HoursInvoicingType : Translations_en_US.HoursInvoicingType;
		Translations.HoursInvoicingTypePlanned = culture === "fi" ? Translations_fi.HoursInvoicingTypePlanned : Translations_en_US.HoursInvoicingTypePlanned;
		Translations.HoursInvoicingTypeRealized = culture === "fi" ? Translations_fi.HoursInvoicingTypeRealized : Translations_en_US.HoursInvoicingTypeRealized;
		Translations.LocationUsageType = culture === "fi" ? Translations_fi.LocationUsageType : Translations_en_US.LocationUsageType;
		Translations.AttachmentsAreVisible = culture === "fi" ? Translations_fi.AttachmentsAreVisible : Translations_en_US.AttachmentsAreVisible;
		Translations.CommentIsVisible = culture === "fi" ? Translations_fi.CommentIsVisible : Translations_en_US.CommentIsVisible;
		Translations.DescriptionIsVisible = culture === "fi" ? Translations_fi.DescriptionIsVisible : Translations_en_US.DescriptionIsVisible;
		Translations.EstimatedHoursAreVisible = culture === "fi" ? Translations_fi.EstimatedHoursAreVisible : Translations_en_US.EstimatedHoursAreVisible;
		Translations.MapIsVisible = culture === "fi" ? Translations_fi.MapIsVisible : Translations_en_US.MapIsVisible;
		Translations.ToBeInvoicedRecordsAreVisible = culture === "fi" ? Translations_fi.ToBeInvoicedRecordsAreVisible : Translations_en_US.ToBeInvoicedRecordsAreVisible;
		Translations.SiteRoutePointUsageType = culture === "fi" ? Translations_fi.SiteRoutePointUsageType : Translations_en_US.SiteRoutePointUsageType;
		Translations.SiteRoutePointUsageTypeRoutePoint = culture === "fi" ? Translations_fi.SiteRoutePointUsageTypeRoutePoint : Translations_en_US.SiteRoutePointUsageTypeRoutePoint;
		Translations.SiteRoutePointUsageTypeSite = culture === "fi" ? Translations_fi.SiteRoutePointUsageTypeSite : Translations_en_US.SiteRoutePointUsageTypeSite;
		Translations.VehicleUsageType = culture === "fi" ? Translations_fi.VehicleUsageType : Translations_en_US.VehicleUsageType;
		Translations.VehicleUsageTypeMultiple = culture === "fi" ? Translations_fi.VehicleUsageTypeMultiple : Translations_en_US.VehicleUsageTypeMultiple;
		Translations.VehicleUsageTypeOne = culture === "fi" ? Translations_fi.VehicleUsageTypeOne : Translations_en_US.VehicleUsageTypeOne;
		Translations.RoutePointTypes = culture === "fi" ? Translations_fi.RoutePointTypes : Translations_en_US.RoutePointTypes;
		Translations.WorkOrderAcknowledgementTypeIsVisible = culture === "fi" ? Translations_fi.WorkOrderAcknowledgementTypeIsVisible : Translations_en_US.WorkOrderAcknowledgementTypeIsVisible;
		Translations.WorkOrderTypeParameterNotFound = culture === "fi" ? Translations_fi.WorkOrderTypeParameterNotFound : Translations_en_US.WorkOrderTypeParameterNotFound;
		Translations.RoutePointWorkShiftTimeSlotType = culture === "fi" ? Translations_fi.RoutePointWorkShiftTimeSlotType : Translations_en_US.RoutePointWorkShiftTimeSlotType;
		Translations.RoutePointWorkShiftTimeSlotTypes = culture === "fi" ? Translations_fi.RoutePointWorkShiftTimeSlotTypes : Translations_en_US.RoutePointWorkShiftTimeSlotTypes;
		Translations.WorkOrderTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders = culture === "fi" ? Translations_fi.WorkOrderTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders : Translations_en_US.WorkOrderTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders;
		Translations.WorkOrderTypeParameterParameterSaveFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderTypeParameterParameterSaveFailedErrorParameter : Translations_en_US.WorkOrderTypeParameterParameterSaveFailedErrorParameter;
		Translations.WorkOrderTypeParameterRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrderTypeParameterRemoveFailedConcurrencyError : Translations_en_US.WorkOrderTypeParameterRemoveFailedConcurrencyError;
		Translations.WorkOrderTypeParameterRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderTypeParameterRemoveFailedErrorParameter : Translations_en_US.WorkOrderTypeParameterRemoveFailedErrorParameter;
		Translations.WorkOrderTypeParameterRemoveSuccess = culture === "fi" ? Translations_fi.WorkOrderTypeParameterRemoveSuccess : Translations_en_US.WorkOrderTypeParameterRemoveSuccess;
		Translations.WorkOrderTypeParameterSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrderTypeParameterSaveFailedConcurrencyError : Translations_en_US.WorkOrderTypeParameterSaveFailedConcurrencyError;
		Translations.WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesRemoveFailedErrorParameter : Translations_en_US.WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesRemoveFailedErrorParameter;
		Translations.WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesRemoveSuccess = culture === "fi" ? Translations_fi.WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesRemoveSuccess : Translations_en_US.WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesRemoveSuccess;
		Translations.WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesSaveFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesSaveFailedErrorParameter : Translations_en_US.WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesSaveFailedErrorParameter;
		Translations.WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesSaveSuccess = culture === "fi" ? Translations_fi.WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesSaveSuccess : Translations_en_US.WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesSaveSuccess;
		Translations.WorkOrderTypeParameterWorkShiftTimeSlotTypeSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrderTypeParameterWorkShiftTimeSlotTypeSaveFailedConcurrencyError : Translations_en_US.WorkOrderTypeParameterWorkShiftTimeSlotTypeSaveFailedConcurrencyError;
		Translations.WorkOrderTypesParameterRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.WorkOrderTypesParameterRemoveFailedConcurrencyError : Translations_en_US.WorkOrderTypesParameterRemoveFailedConcurrencyError;
		Translations.WorkOrderTypesParameterRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderTypesParameterRemoveFailedErrorParameter : Translations_en_US.WorkOrderTypesParameterRemoveFailedErrorParameter;
		Translations.WorkOrderTypesParameterRemoveSuccess = culture === "fi" ? Translations_fi.WorkOrderTypesParameterRemoveSuccess : Translations_en_US.WorkOrderTypesParameterRemoveSuccess;
		Translations.WorkOrderTypeTypeParameterSaveSuccess = culture === "fi" ? Translations_fi.WorkOrderTypeTypeParameterSaveSuccess : Translations_en_US.WorkOrderTypeTypeParameterSaveSuccess;
		Translations.WorkOrderTypeWorkShiftTimeSlotTypeParameterNotFound = culture === "fi" ? Translations_fi.WorkOrderTypeWorkShiftTimeSlotTypeParameterNotFound : Translations_en_US.WorkOrderTypeWorkShiftTimeSlotTypeParameterNotFound;
		Translations.WorkOrderTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterInvoices = culture === "fi" ? Translations_fi.WorkOrderTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterInvoices : Translations_en_US.WorkOrderTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterInvoices;
		Translations.ShowFilteredWorkOrders = culture === "fi" ? Translations_fi.ShowFilteredWorkOrders : Translations_en_US.ShowFilteredWorkOrders;
		Translations.NoWorkOrders = culture === "fi" ? Translations_fi.NoWorkOrders : Translations_en_US.NoWorkOrders;
		Translations.WorkTimeAccountTransactionSaveSuccess = culture === "fi" ? Translations_fi.WorkTimeAccountTransactionSaveSuccess : Translations_en_US.WorkTimeAccountTransactionSaveSuccess;
		Translations.WorkTimeAccountTransactionSaveFailed = culture === "fi" ? Translations_fi.WorkTimeAccountTransactionSaveFailed : Translations_en_US.WorkTimeAccountTransactionSaveFailed;
		Translations.AddWorkHoursToBank = culture === "fi" ? Translations_fi.AddWorkHoursToBank : Translations_en_US.AddWorkHoursToBank;
		Translations.TakeWorkHoursFromBank = culture === "fi" ? Translations_fi.TakeWorkHoursFromBank : Translations_en_US.TakeWorkHoursFromBank;
		Translations.TransferWorkHours = culture === "fi" ? Translations_fi.TransferWorkHours : Translations_en_US.TransferWorkHours;
		Translations.WorkHourBalance = culture === "fi" ? Translations_fi.WorkHourBalance : Translations_en_US.WorkHourBalance;
		Translations.WorkhourBank = culture === "fi" ? Translations_fi.WorkhourBank : Translations_en_US.WorkhourBank;
		Translations.AddWorkOrdersToInvoice = culture === "fi" ? Translations_fi.AddWorkOrdersToInvoice : Translations_en_US.AddWorkOrdersToInvoice;
		Translations.CombineWorkOrdersByWorkOrderTypesToSameInvoice = culture === "fi" ? Translations_fi.CombineWorkOrdersByWorkOrderTypesToSameInvoice : Translations_en_US.CombineWorkOrdersByWorkOrderTypesToSameInvoice;
		Translations.InvoiceAddWorkOrdersDifferentWorkOrderTypeConfirmation = culture === "fi" ? Translations_fi.InvoiceAddWorkOrdersDifferentWorkOrderTypeConfirmation : Translations_en_US.InvoiceAddWorkOrdersDifferentWorkOrderTypeConfirmation;
		Translations.InvoiceDataFromWorkOrderType = culture === "fi" ? Translations_fi.InvoiceDataFromWorkOrderType : Translations_en_US.InvoiceDataFromWorkOrderType;
		Translations.InvoiceParameterParameterSaveFailedErrorParameter = culture === "fi" ? Translations_fi.InvoiceParameterParameterSaveFailedErrorParameter : Translations_en_US.InvoiceParameterParameterSaveFailedErrorParameter;
		Translations.InvoiceParameterSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.InvoiceParameterSaveFailedConcurrencyError : Translations_en_US.InvoiceParameterSaveFailedConcurrencyError;
		Translations.InvoiceParameterSaveSuccess = culture === "fi" ? Translations_fi.InvoiceParameterSaveSuccess : Translations_en_US.InvoiceParameterSaveSuccess;
		Translations.MenuStorage = culture === "fi" ? Translations_fi.MenuStorage : Translations_en_US.MenuStorage;
		Translations.StorageLocations = culture === "fi" ? Translations_fi.StorageLocations : Translations_en_US.StorageLocations;
		Translations.StorageReport = culture === "fi" ? Translations_fi.StorageReport : Translations_en_US.StorageReport;
		Translations.Storages = culture === "fi" ? Translations_fi.Storages : Translations_en_US.Storages;
		Translations.Storage = culture === "fi" ? Translations_fi.Storage : Translations_en_US.Storage;
		Translations.StorageLocation = culture === "fi" ? Translations_fi.StorageLocation : Translations_en_US.StorageLocation;
		Translations.StorageLocationShort = culture === "fi" ? Translations_fi.StorageLocationShort : Translations_en_US.StorageLocationShort;
		Translations.StorageProduct = culture === "fi" ? Translations_fi.StorageProduct : Translations_en_US.StorageProduct;
		Translations.StorageProducts = culture === "fi" ? Translations_fi.StorageProducts : Translations_en_US.StorageProducts;
		Translations.NoSelectedItem = culture === "fi" ? Translations_fi.NoSelectedItem : Translations_en_US.NoSelectedItem;
		Translations.SelectedProduct = culture === "fi" ? Translations_fi.SelectedProduct : Translations_en_US.SelectedProduct;
		Translations.SelectedStorage = culture === "fi" ? Translations_fi.SelectedStorage : Translations_en_US.SelectedStorage;
		Translations.SelectedStorageLocation = culture === "fi" ? Translations_fi.SelectedStorageLocation : Translations_en_US.SelectedStorageLocation;
		Translations.SelectedStorageProduct = culture === "fi" ? Translations_fi.SelectedStorageProduct : Translations_en_US.SelectedStorageProduct;
		Translations.StorageProductRemoveConfirmation = culture === "fi" ? Translations_fi.StorageProductRemoveConfirmation : Translations_en_US.StorageProductRemoveConfirmation;
		Translations.StorageProductRemoveSuccess = culture === "fi" ? Translations_fi.StorageProductRemoveSuccess : Translations_en_US.StorageProductRemoveSuccess;
		Translations.StorageProductSaveSuccess = culture === "fi" ? Translations_fi.StorageProductSaveSuccess : Translations_en_US.StorageProductSaveSuccess;
		Translations.RouteAndSchedule = culture === "fi" ? Translations_fi.RouteAndSchedule : Translations_en_US.RouteAndSchedule;
		Translations.ShowAllCostCenters = culture === "fi" ? Translations_fi.ShowAllCostCenters : Translations_en_US.ShowAllCostCenters;
		Translations.ShowAllVehicles = culture === "fi" ? Translations_fi.ShowAllVehicles : Translations_en_US.ShowAllVehicles;
		Translations.Summary = culture === "fi" ? Translations_fi.Summary : Translations_en_US.Summary;
		Translations.RouteLength = culture === "fi" ? Translations_fi.RouteLength : Translations_en_US.RouteLength;
		Translations.RoutePhases = culture === "fi" ? Translations_fi.RoutePhases : Translations_en_US.RoutePhases;
		Translations.TotalDuration = culture === "fi" ? Translations_fi.TotalDuration : Translations_en_US.TotalDuration;
		Translations.NoVehicle = culture === "fi" ? Translations_fi.NoVehicle : Translations_en_US.NoVehicle;
		Translations.Planned = culture === "fi" ? Translations_fi.Planned : Translations_en_US.Planned;
		Translations.Realized = culture === "fi" ? Translations_fi.Realized : Translations_en_US.Realized;
		Translations.RoutePointBooking = culture === "fi" ? Translations_fi.RoutePointBooking : Translations_en_US.RoutePointBooking;
		Translations.WorkhourBankInUse = culture === "fi" ? Translations_fi.WorkhourBankInUse : Translations_en_US.WorkhourBankInUse;
		Translations.CreateSalaryDataFailedErrorParameter = culture === "fi" ? Translations_fi.CreateSalaryDataFailedErrorParameter : Translations_en_US.CreateSalaryDataFailedErrorParameter;
		Translations.CreateSalaryDataSuccess = culture === "fi" ? Translations_fi.CreateSalaryDataSuccess : Translations_en_US.CreateSalaryDataSuccess;
		Translations.EventLogMessageSalaryDataCreated = culture === "fi" ? Translations_fi.EventLogMessageSalaryDataCreated : Translations_en_US.EventLogMessageSalaryDataCreated;
		Translations.EventLogMessageSalaryTransferred = culture === "fi" ? Translations_fi.EventLogMessageSalaryTransferred : Translations_en_US.EventLogMessageSalaryTransferred;
		Translations.SalariesTransferredToSalarySystem = culture === "fi" ? Translations_fi.SalariesTransferredToSalarySystem : Translations_en_US.SalariesTransferredToSalarySystem;
		Translations.ExportToSalarySystem = culture === "fi" ? Translations_fi.ExportToSalarySystem : Translations_en_US.ExportToSalarySystem;
		Translations.SetSalariesToTransferredState = culture === "fi" ? Translations_fi.SetSalariesToTransferredState : Translations_en_US.SetSalariesToTransferredState;
		Translations.TransferToBePaid = culture === "fi" ? Translations_fi.TransferToBePaid : Translations_en_US.TransferToBePaid;
		Translations.IncludeTransferredSalaries = culture === "fi" ? Translations_fi.IncludeTransferredSalaries : Translations_en_US.IncludeTransferredSalaries;
		Translations.DataToBeSavedInWorkOrderTemplate = culture === "fi" ? Translations_fi.DataToBeSavedInWorkOrderTemplate : Translations_en_US.DataToBeSavedInWorkOrderTemplate;
		Translations.NewProjectBasedOnWorkOrderTemplate = culture === "fi" ? Translations_fi.NewProjectBasedOnWorkOrderTemplate : Translations_en_US.NewProjectBasedOnWorkOrderTemplate;
		Translations.NewWorkBasedOnWorkOrderTemplate = culture === "fi" ? Translations_fi.NewWorkBasedOnWorkOrderTemplate : Translations_en_US.NewWorkBasedOnWorkOrderTemplate;
		Translations.NoWorkOrderTemplates = culture === "fi" ? Translations_fi.NoWorkOrderTemplates : Translations_en_US.NoWorkOrderTemplates;
		Translations.SaveAsWorkOrderTemplate = culture === "fi" ? Translations_fi.SaveAsWorkOrderTemplate : Translations_en_US.SaveAsWorkOrderTemplate;
		Translations.SelectWorkOrderTemplate = culture === "fi" ? Translations_fi.SelectWorkOrderTemplate : Translations_en_US.SelectWorkOrderTemplate;
		Translations.WorkOrderSaveAsWorkOrderTemplateFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderSaveAsWorkOrderTemplateFailedErrorParameter : Translations_en_US.WorkOrderSaveAsWorkOrderTemplateFailedErrorParameter;
		Translations.WorkOrderSaveAsWorkOrderTemplateSuccess = culture === "fi" ? Translations_fi.WorkOrderSaveAsWorkOrderTemplateSuccess : Translations_en_US.WorkOrderSaveAsWorkOrderTemplateSuccess;
		Translations.WorkOrderTemplate = culture === "fi" ? Translations_fi.WorkOrderTemplate : Translations_en_US.WorkOrderTemplate;
		Translations.WorkOrderTemplateRemoveConfirmation = culture === "fi" ? Translations_fi.WorkOrderTemplateRemoveConfirmation : Translations_en_US.WorkOrderTemplateRemoveConfirmation;
		Translations.WorkOrderTemplateRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderTemplateRemoveFailedErrorParameter : Translations_en_US.WorkOrderTemplateRemoveFailedErrorParameter;
		Translations.WorkOrderTemplateRemoveSuccess = culture === "fi" ? Translations_fi.WorkOrderTemplateRemoveSuccess : Translations_en_US.WorkOrderTemplateRemoveSuccess;
		Translations.WorkOrderTemplatesRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderTemplatesRemoveFailedErrorParameter : Translations_en_US.WorkOrderTemplatesRemoveFailedErrorParameter;
		Translations.WorkOrderTemplatesRemoveSuccess = culture === "fi" ? Translations_fi.WorkOrderTemplatesRemoveSuccess : Translations_en_US.WorkOrderTemplatesRemoveSuccess;
		Translations.NoSalaryDataToTransfer = culture === "fi" ? Translations_fi.NoSalaryDataToTransfer : Translations_en_US.NoSalaryDataToTransfer;
		Translations.SalaryDatas = culture === "fi" ? Translations_fi.SalaryDatas : Translations_en_US.SalaryDatas;
		Translations.WorkQueue = culture === "fi" ? Translations_fi.WorkQueue : Translations_en_US.WorkQueue;
		Translations.AssignToMe = culture === "fi" ? Translations_fi.AssignToMe : Translations_en_US.AssignToMe;
		Translations.WorkOrderIsShownInWorkQueue = culture === "fi" ? Translations_fi.WorkOrderIsShownInWorkQueue : Translations_en_US.WorkOrderIsShownInWorkQueue;
		Translations.DoYouWantToReturnWorkOrderToWorkQueue = culture === "fi" ? Translations_fi.DoYouWantToReturnWorkOrderToWorkQueue : Translations_en_US.DoYouWantToReturnWorkOrderToWorkQueue;
		Translations.ReturnWorkOrderToWorkQueue = culture === "fi" ? Translations_fi.ReturnWorkOrderToWorkQueue : Translations_en_US.ReturnWorkOrderToWorkQueue;
		Translations.UsesWorkQueue = culture === "fi" ? Translations_fi.UsesWorkQueue : Translations_en_US.UsesWorkQueue;
		Translations.EmployeeGroupAddFailedErrorParameter = culture === "fi" ? Translations_fi.EmployeeGroupAddFailedErrorParameter : Translations_en_US.EmployeeGroupAddFailedErrorParameter;
		Translations.EmployeeGroupAddSuccess = culture === "fi" ? Translations_fi.EmployeeGroupAddSuccess : Translations_en_US.EmployeeGroupAddSuccess;
		Translations.EmployeeGroupsAddFailedErrorParameter = culture === "fi" ? Translations_fi.EmployeeGroupsAddFailedErrorParameter : Translations_en_US.EmployeeGroupsAddFailedErrorParameter;
		Translations.EmployeeGroupsAddSuccess = culture === "fi" ? Translations_fi.EmployeeGroupsAddSuccess : Translations_en_US.EmployeeGroupsAddSuccess;
		Translations.EmployeeGroupsRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.EmployeeGroupsRemoveFailedErrorParameter : Translations_en_US.EmployeeGroupsRemoveFailedErrorParameter;
		Translations.EmployeeGroupsRemoveSuccess = culture === "fi" ? Translations_fi.EmployeeGroupsRemoveSuccess : Translations_en_US.EmployeeGroupsRemoveSuccess;
		Translations.SecondaryEmployeeGroups = culture === "fi" ? Translations_fi.SecondaryEmployeeGroups : Translations_en_US.SecondaryEmployeeGroups;
		Translations.WorkQueuePostalCodeRangeEnd = culture === "fi" ? Translations_fi.WorkQueuePostalCodeRangeEnd : Translations_en_US.WorkQueuePostalCodeRangeEnd;
		Translations.WorkQueuePostalCodeRangeStart = culture === "fi" ? Translations_fi.WorkQueuePostalCodeRangeStart : Translations_en_US.WorkQueuePostalCodeRangeStart;
		Translations.AssignToMeDoStartWorkConfirmation = culture === "fi" ? Translations_fi.AssignToMeDoStartWorkConfirmation : Translations_en_US.AssignToMeDoStartWorkConfirmation;
		Translations.AssignToMeWorkOrderConfirmation = culture === "fi" ? Translations_fi.AssignToMeWorkOrderConfirmation : Translations_en_US.AssignToMeWorkOrderConfirmation;
		Translations.CompletionDate = culture === "fi" ? Translations_fi.CompletionDate : Translations_en_US.CompletionDate;
		Translations.CreationDate = culture === "fi" ? Translations_fi.CreationDate : Translations_en_US.CreationDate;
		Translations.WorkOrderAssignToMeFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderAssignToMeFailedErrorParameter : Translations_en_US.WorkOrderAssignToMeFailedErrorParameter;
		Translations.WorkOrderAssignToMeSuccess = culture === "fi" ? Translations_fi.WorkOrderAssignToMeSuccess : Translations_en_US.WorkOrderAssignToMeSuccess;
		Translations.WorkOrderUnAssignFromEmployeesFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrderUnAssignFromEmployeesFailedErrorParameter : Translations_en_US.WorkOrderUnAssignFromEmployeesFailedErrorParameter;
		Translations.WorkOrderUnAssignFromEmployeesSuccess = culture === "fi" ? Translations_fi.WorkOrderUnAssignFromEmployeesSuccess : Translations_en_US.WorkOrderUnAssignFromEmployeesSuccess;
		Translations.WorkTimeMeasurementStarted = culture === "fi" ? Translations_fi.WorkTimeMeasurementStarted : Translations_en_US.WorkTimeMeasurementStarted;
		Translations.PrimaryEmployeeGroup = culture === "fi" ? Translations_fi.PrimaryEmployeeGroup : Translations_en_US.PrimaryEmployeeGroup;
		Translations.StorageLocationCode = culture === "fi" ? Translations_fi.StorageLocationCode : Translations_en_US.StorageLocationCode;
		Translations.StorageLocationName = culture === "fi" ? Translations_fi.StorageLocationName : Translations_en_US.StorageLocationName;
		Translations.StorageLocationSaveSuccess = culture === "fi" ? Translations_fi.StorageLocationSaveSuccess : Translations_en_US.StorageLocationSaveSuccess;
		Translations.StorageLocationRemoveConfirmation = culture === "fi" ? Translations_fi.StorageLocationRemoveConfirmation : Translations_en_US.StorageLocationRemoveConfirmation;
		Translations.StorageLocationRemoveSuccess = culture === "fi" ? Translations_fi.StorageLocationRemoveSuccess : Translations_en_US.StorageLocationRemoveSuccess;
		Translations.CreateNewStorageLocation = culture === "fi" ? Translations_fi.CreateNewStorageLocation : Translations_en_US.CreateNewStorageLocation;
		Translations.RemoveSelected = culture === "fi" ? Translations_fi.RemoveSelected : Translations_en_US.RemoveSelected;
		Translations.ConcuranceError = culture === "fi" ? Translations_fi.ConcuranceError : Translations_en_US.ConcuranceError;
		Translations.SaveFailed = culture === "fi" ? Translations_fi.SaveFailed : Translations_en_US.SaveFailed;
		Translations.SaveSuccess = culture === "fi" ? Translations_fi.SaveSuccess : Translations_en_US.SaveSuccess;
		Translations.CustomerSiteAddress = culture === "fi" ? Translations_fi.CustomerSiteAddress : Translations_en_US.CustomerSiteAddress;
		Translations.MultipleStorageLocationRemoveConfirmation = culture === "fi" ? Translations_fi.MultipleStorageLocationRemoveConfirmation : Translations_en_US.MultipleStorageLocationRemoveConfirmation;
		Translations.MultipleStorageLocationsRemoveSuccess = culture === "fi" ? Translations_fi.MultipleStorageLocationsRemoveSuccess : Translations_en_US.MultipleStorageLocationsRemoveSuccess;
		Translations.NoCustomer = culture === "fi" ? Translations_fi.NoCustomer : Translations_en_US.NoCustomer;
		Translations.AreYouSureWantDelete = culture === "fi" ? Translations_fi.AreYouSureWantDelete : Translations_en_US.AreYouSureWantDelete;
		Translations.SaveAndClose = culture === "fi" ? Translations_fi.SaveAndClose : Translations_en_US.SaveAndClose;
		Translations.CreateStorageLocations = culture === "fi" ? Translations_fi.CreateStorageLocations : Translations_en_US.CreateStorageLocations;
		Translations.YouCanAlsoCreateStorageLocations = culture === "fi" ? Translations_fi.YouCanAlsoCreateStorageLocations : Translations_en_US.YouCanAlsoCreateStorageLocations;
		Translations.CreateNewStorage = culture === "fi" ? Translations_fi.CreateNewStorage : Translations_en_US.CreateNewStorage;
		Translations.CreateNewStorageText = culture === "fi" ? Translations_fi.CreateNewStorageText : Translations_en_US.CreateNewStorageText;
		Translations.AddProducts = culture === "fi" ? Translations_fi.AddProducts : Translations_en_US.AddProducts;
		Translations.YouCanAlsoAddProducts = culture === "fi" ? Translations_fi.YouCanAlsoAddProducts : Translations_en_US.YouCanAlsoAddProducts;
		Translations.EditStorage = culture === "fi" ? Translations_fi.EditStorage : Translations_en_US.EditStorage;
		Translations.NameAndAddressMustBeDefined = culture === "fi" ? Translations_fi.NameAndAddressMustBeDefined : Translations_en_US.NameAndAddressMustBeDefined;
		Translations.AddStorageProducts = culture === "fi" ? Translations_fi.AddStorageProducts : Translations_en_US.AddStorageProducts;
		Translations.AddStorageProductsText = culture === "fi" ? Translations_fi.AddStorageProductsText : Translations_en_US.AddStorageProductsText;
		Translations.CreateProduct = culture === "fi" ? Translations_fi.CreateProduct : Translations_en_US.CreateProduct;
		Translations.ProductSearch = culture === "fi" ? Translations_fi.ProductSearch : Translations_en_US.ProductSearch;
		Translations.BookedAmount = culture === "fi" ? Translations_fi.BookedAmount : Translations_en_US.BookedAmount;
		Translations.FreeAmount = culture === "fi" ? Translations_fi.FreeAmount : Translations_en_US.FreeAmount;
		Translations.Sum = culture === "fi" ? Translations_fi.Sum : Translations_en_US.Sum;
		Translations.ProductMustBeDefined = culture === "fi" ? Translations_fi.ProductMustBeDefined : Translations_en_US.ProductMustBeDefined;
		Translations.StorageLocationMustBeDefined = culture === "fi" ? Translations_fi.StorageLocationMustBeDefined : Translations_en_US.StorageLocationMustBeDefined;
		Translations.Code1 = culture === "fi" ? Translations_fi.Code1 : Translations_en_US.Code1;
		Translations.OwnerLogo = culture === "fi" ? Translations_fi.OwnerLogo : Translations_en_US.OwnerLogo;
		Translations.OwnerMobileBackgroundImage = culture === "fi" ? Translations_fi.OwnerMobileBackgroundImage : Translations_en_US.OwnerMobileBackgroundImage;
		Translations.ShowDefaultLogo = culture === "fi" ? Translations_fi.ShowDefaultLogo : Translations_en_US.ShowDefaultLogo;
		Translations.AvailableForBooking = culture === "fi" ? Translations_fi.AvailableForBooking : Translations_en_US.AvailableForBooking;
		Translations.ErrorAmountNotEnough = culture === "fi" ? Translations_fi.ErrorAmountNotEnough : Translations_en_US.ErrorAmountNotEnough;
		Translations.AddToOrder = culture === "fi" ? Translations_fi.AddToOrder : Translations_en_US.AddToOrder;
		Translations.CreateNewOrder = culture === "fi" ? Translations_fi.CreateNewOrder : Translations_en_US.CreateNewOrder;
		Translations.ShoppingCart = culture === "fi" ? Translations_fi.ShoppingCart : Translations_en_US.ShoppingCart;
		Translations.SelectExistingWorkOrder = culture === "fi" ? Translations_fi.SelectExistingWorkOrder : Translations_en_US.SelectExistingWorkOrder;
		Translations.ShoppingCartProductListTitle = culture === "fi" ? Translations_fi.ShoppingCartProductListTitle : Translations_en_US.ShoppingCartProductListTitle;
		Translations.CanNotDeleteStorage = culture === "fi" ? Translations_fi.CanNotDeleteStorage : Translations_en_US.CanNotDeleteStorage;
		Translations.DeleteWasSuccess = culture === "fi" ? Translations_fi.DeleteWasSuccess : Translations_en_US.DeleteWasSuccess;
		Translations.LocationDataSavingInterval = culture === "fi" ? Translations_fi.LocationDataSavingInterval : Translations_en_US.LocationDataSavingInterval;
		Translations.LocationDataSavingInUse = culture === "fi" ? Translations_fi.LocationDataSavingInUse : Translations_en_US.LocationDataSavingInUse;
		Translations.LocationPermissionMustBeAllowedInTheDeviceForThisWebSiteParameterBeforeWorkOrderCanBeMovedToInProgressState = culture === "fi" ? Translations_fi.LocationPermissionMustBeAllowedInTheDeviceForThisWebSiteParameterBeforeWorkOrderCanBeMovedToInProgressState : Translations_en_US.LocationPermissionMustBeAllowedInTheDeviceForThisWebSiteParameterBeforeWorkOrderCanBeMovedToInProgressState;
		Translations.WorkOrdersParameterLocationSaveFailedErrorParameter = culture === "fi" ? Translations_fi.WorkOrdersParameterLocationSaveFailedErrorParameter : Translations_en_US.WorkOrdersParameterLocationSaveFailedErrorParameter;
		Translations.WorkOrdersParameterLocationSaveSuccess = culture === "fi" ? Translations_fi.WorkOrdersParameterLocationSaveSuccess : Translations_en_US.WorkOrdersParameterLocationSaveSuccess;
		Translations.LocationPermissionMustBeAllowedInTheDeviceForThisWebSiteParameter = culture === "fi" ? Translations_fi.LocationPermissionMustBeAllowedInTheDeviceForThisWebSiteParameter : Translations_en_US.LocationPermissionMustBeAllowedInTheDeviceForThisWebSiteParameter;
		Translations.DeliveryPoint = culture === "fi" ? Translations_fi.DeliveryPoint : Translations_en_US.DeliveryPoint;
		Translations.ProductName = culture === "fi" ? Translations_fi.ProductName : Translations_en_US.ProductName;
		Translations.NoRows = culture === "fi" ? Translations_fi.NoRows : Translations_en_US.NoRows;
		Translations.BookingContainsNotValidLines = culture === "fi" ? Translations_fi.BookingContainsNotValidLines : Translations_en_US.BookingContainsNotValidLines;
		Translations.SalaryPeriodTypeRemoveConfirmation = culture === "fi" ? Translations_fi.SalaryPeriodTypeRemoveConfirmation : Translations_en_US.SalaryPeriodTypeRemoveConfirmation;
		Translations.SalaryPeriodTypes = culture === "fi" ? Translations_fi.SalaryPeriodTypes : Translations_en_US.SalaryPeriodTypes;
		Translations.InvalidEndDate = culture === "fi" ? Translations_fi.InvalidEndDate : Translations_en_US.InvalidEndDate;
		Translations.SalaryPeriodType = culture === "fi" ? Translations_fi.SalaryPeriodType : Translations_en_US.SalaryPeriodType;
		Translations.SalaryPeriodTypeParameterNotFound = culture === "fi" ? Translations_fi.SalaryPeriodTypeParameterNotFound : Translations_en_US.SalaryPeriodTypeParameterNotFound;
		Translations.SalaryPeriodTypeSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.SalaryPeriodTypeSaveFailedConcurrencyError : Translations_en_US.SalaryPeriodTypeSaveFailedConcurrencyError;
		Translations.SalaryPeriodTypeSaveFailedErrorParameter = culture === "fi" ? Translations_fi.SalaryPeriodTypeSaveFailedErrorParameter : Translations_en_US.SalaryPeriodTypeSaveFailedErrorParameter;
		Translations.SalaryPeriodTypeSaveSuccess = culture === "fi" ? Translations_fi.SalaryPeriodTypeSaveSuccess : Translations_en_US.SalaryPeriodTypeSaveSuccess;
		Translations.AddSalaryPeriod = culture === "fi" ? Translations_fi.AddSalaryPeriod : Translations_en_US.AddSalaryPeriod;
		Translations.AddSalaryPeriods = culture === "fi" ? Translations_fi.AddSalaryPeriods : Translations_en_US.AddSalaryPeriods;
		Translations.ParameterMonths = culture === "fi" ? Translations_fi.ParameterMonths : Translations_en_US.ParameterMonths;
		Translations.ParameterWeeks = culture === "fi" ? Translations_fi.ParameterWeeks : Translations_en_US.ParameterWeeks;
		Translations.PeriodLength = culture === "fi" ? Translations_fi.PeriodLength : Translations_en_US.PeriodLength;
		Translations.SalaryPeriodParameterCannotBeEditedBecauseItIsUsedInParameterSalaries = culture === "fi" ? Translations_fi.SalaryPeriodParameterCannotBeEditedBecauseItIsUsedInParameterSalaries : Translations_en_US.SalaryPeriodParameterCannotBeEditedBecauseItIsUsedInParameterSalaries;
		Translations.SalaryPeriodParameterCannotBeRemovedBecauseItIsUsedInParameterSalaries = culture === "fi" ? Translations_fi.SalaryPeriodParameterCannotBeRemovedBecauseItIsUsedInParameterSalaries : Translations_en_US.SalaryPeriodParameterCannotBeRemovedBecauseItIsUsedInParameterSalaries;
		Translations.SalaryPeriodTypeParameterCannotBeRemovedBecauseItIsUsedInParameterSalaries = culture === "fi" ? Translations_fi.SalaryPeriodTypeParameterCannotBeRemovedBecauseItIsUsedInParameterSalaries : Translations_en_US.SalaryPeriodTypeParameterCannotBeRemovedBecauseItIsUsedInParameterSalaries;
		Translations.SalaryPeriodTypeParameterRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.SalaryPeriodTypeParameterRemoveFailedErrorParameter : Translations_en_US.SalaryPeriodTypeParameterRemoveFailedErrorParameter;
		Translations.SalaryPeriodTypeParameterRemoveSuccess = culture === "fi" ? Translations_fi.SalaryPeriodTypeParameterRemoveSuccess : Translations_en_US.SalaryPeriodTypeParameterRemoveSuccess;
		Translations.SalaryPeriodTypeParameterSalaryPeriodsRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.SalaryPeriodTypeParameterSalaryPeriodsRemoveFailedErrorParameter : Translations_en_US.SalaryPeriodTypeParameterSalaryPeriodsRemoveFailedErrorParameter;
		Translations.SalaryPeriodTypeParameterSalaryPeriodsRemoveSuccess = culture === "fi" ? Translations_fi.SalaryPeriodTypeParameterSalaryPeriodsRemoveSuccess : Translations_en_US.SalaryPeriodTypeParameterSalaryPeriodsRemoveSuccess;
		Translations.SalaryPeriodTypeParameterSalaryPeriodsSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.SalaryPeriodTypeParameterSalaryPeriodsSaveFailedConcurrencyError : Translations_en_US.SalaryPeriodTypeParameterSalaryPeriodsSaveFailedConcurrencyError;
		Translations.SalaryPeriodTypeParameterSalaryPeriodsSaveFailedErrorParameter = culture === "fi" ? Translations_fi.SalaryPeriodTypeParameterSalaryPeriodsSaveFailedErrorParameter : Translations_en_US.SalaryPeriodTypeParameterSalaryPeriodsSaveFailedErrorParameter;
		Translations.SalaryPeriodTypeParameterSalaryPeriodsSaveSuccess = culture === "fi" ? Translations_fi.SalaryPeriodTypeParameterSalaryPeriodsSaveSuccess : Translations_en_US.SalaryPeriodTypeParameterSalaryPeriodsSaveSuccess;
		Translations.EmployeeGroupsForSalaryCalculationHaveNotBeenSpecified = culture === "fi" ? Translations_fi.EmployeeGroupsForSalaryCalculationHaveNotBeenSpecified : Translations_en_US.EmployeeGroupsForSalaryCalculationHaveNotBeenSpecified;
		Translations.EmployeeGroupForDesign = culture === "fi" ? Translations_fi.EmployeeGroupForDesign : Translations_en_US.EmployeeGroupForDesign;
		Translations.EmployeeGroupForSalaryCalculation = culture === "fi" ? Translations_fi.EmployeeGroupForSalaryCalculation : Translations_en_US.EmployeeGroupForSalaryCalculation;
		Translations.EmployeeGroupSalaryPeriodTypeCannotBeRemovedError = culture === "fi" ? Translations_fi.EmployeeGroupSalaryPeriodTypeCannotBeRemovedError : Translations_en_US.EmployeeGroupSalaryPeriodTypeCannotBeRemovedError;
		Translations.SalaryCalculation = culture === "fi" ? Translations_fi.SalaryCalculation : Translations_en_US.SalaryCalculation;
		Translations.WorkOrderCanBeCreatedInMobileApp = culture === "fi" ? Translations_fi.WorkOrderCanBeCreatedInMobileApp : Translations_en_US.WorkOrderCanBeCreatedInMobileApp;
		Translations.Extranet = culture === "fi" ? Translations_fi.Extranet : Translations_en_US.Extranet;
		Translations.CommonType = culture === "fi" ? Translations_fi.CommonType : Translations_en_US.CommonType;
		Translations.StorageInvoice = culture === "fi" ? Translations_fi.StorageInvoice : Translations_en_US.StorageInvoice;
		Translations.ErrorAmountNotEnoughWithName = culture === "fi" ? Translations_fi.ErrorAmountNotEnoughWithName : Translations_en_US.ErrorAmountNotEnoughWithName;
		Translations.StorageProductParameterNotFound = culture === "fi" ? Translations_fi.StorageProductParameterNotFound : Translations_en_US.StorageProductParameterNotFound;
		Translations.ProductsAddedToWorkOrder = culture === "fi" ? Translations_fi.ProductsAddedToWorkOrder : Translations_en_US.ProductsAddedToWorkOrder;
		Translations.ProductsWillBeAddedAfterSave = culture === "fi" ? Translations_fi.ProductsWillBeAddedAfterSave : Translations_en_US.ProductsWillBeAddedAfterSave;
		Translations.AddingProductsToWorkOrder = culture === "fi" ? Translations_fi.AddingProductsToWorkOrder : Translations_en_US.AddingProductsToWorkOrder;
		Translations.CheckAmounts = culture === "fi" ? Translations_fi.CheckAmounts : Translations_en_US.CheckAmounts;
		Translations.ErrorState = culture === "fi" ? Translations_fi.ErrorState : Translations_en_US.ErrorState;
		Translations.DoYouWantSaveChanges = culture === "fi" ? Translations_fi.DoYouWantSaveChanges : Translations_en_US.DoYouWantSaveChanges;
		Translations.ToShoppingCart = culture === "fi" ? Translations_fi.ToShoppingCart : Translations_en_US.ToShoppingCart;
		Translations.RoutePointHasProduct = culture === "fi" ? Translations_fi.RoutePointHasProduct : Translations_en_US.RoutePointHasProduct;
		Translations.WorkOrderSortOrderError = culture === "fi" ? Translations_fi.WorkOrderSortOrderError : Translations_en_US.WorkOrderSortOrderError;
		Translations.WorkOrderSortOrderUpdated = culture === "fi" ? Translations_fi.WorkOrderSortOrderUpdated : Translations_en_US.WorkOrderSortOrderUpdated;
		Translations.WorkOrderCreationAllowedInMobileApp = culture === "fi" ? Translations_fi.WorkOrderCreationAllowedInMobileApp : Translations_en_US.WorkOrderCreationAllowedInMobileApp;
		Translations.WorkOrderSequentialTaskExecution = culture === "fi" ? Translations_fi.WorkOrderSequentialTaskExecution : Translations_en_US.WorkOrderSequentialTaskExecution;
		Translations.WorkOrderSequentialTaskExecutionNon = culture === "fi" ? Translations_fi.WorkOrderSequentialTaskExecutionNon : Translations_en_US.WorkOrderSequentialTaskExecutionNon;
		Translations.DailyDeductionHours = culture === "fi" ? Translations_fi.DailyDeductionHours : Translations_en_US.DailyDeductionHours;
		Translations.PeriodDeductionHours = culture === "fi" ? Translations_fi.PeriodDeductionHours : Translations_en_US.PeriodDeductionHours;
		Translations.SuitableWorkOrderTypesForNewWorkOrderNotFound = culture === "fi" ? Translations_fi.SuitableWorkOrderTypesForNewWorkOrderNotFound : Translations_en_US.SuitableWorkOrderTypesForNewWorkOrderNotFound;
		Translations.ProductStorageLocationError = culture === "fi" ? Translations_fi.ProductStorageLocationError : Translations_en_US.ProductStorageLocationError;
		Translations.ProductBookingError = culture === "fi" ? Translations_fi.ProductBookingError : Translations_en_US.ProductBookingError;
		Translations.ProductStorageError = culture === "fi" ? Translations_fi.ProductStorageError : Translations_en_US.ProductStorageError;
		Translations.StorageProductRemoveWarning = culture === "fi" ? Translations_fi.StorageProductRemoveWarning : Translations_en_US.StorageProductRemoveWarning;
		Translations.EventLogMessageSiteCreated = culture === "fi" ? Translations_fi.EventLogMessageSiteCreated : Translations_en_US.EventLogMessageSiteCreated;
		Translations.ERPCodeMissing = culture === "fi" ? Translations_fi.ERPCodeMissing : Translations_en_US.ERPCodeMissing;
		Translations.PlannedRoute = culture === "fi" ? Translations_fi.PlannedRoute : Translations_en_US.PlannedRoute;
		Translations.CollectiveAgreement = culture === "fi" ? Translations_fi.CollectiveAgreement : Translations_en_US.CollectiveAgreement;
		Translations.ProductsToDeliver = culture === "fi" ? Translations_fi.ProductsToDeliver : Translations_en_US.ProductsToDeliver;
		Translations.ProductsToFetch = culture === "fi" ? Translations_fi.ProductsToFetch : Translations_en_US.ProductsToFetch;
		Translations.CancelSetLocationFromMap = culture === "fi" ? Translations_fi.CancelSetLocationFromMap : Translations_en_US.CancelSetLocationFromMap;
		Translations.SendGridAddressIsMissing = culture === "fi" ? Translations_fi.SendGridAddressIsMissing : Translations_en_US.SendGridAddressIsMissing;
		Translations.SendGridApiKeyIsMissing = culture === "fi" ? Translations_fi.SendGridApiKeyIsMissing : Translations_en_US.SendGridApiKeyIsMissing;
		Translations.SendGridSendingMailFailed = culture === "fi" ? Translations_fi.SendGridSendingMailFailed : Translations_en_US.SendGridSendingMailFailed;
		Translations.HoursInvoicingProduct = culture === "fi" ? Translations_fi.HoursInvoicingProduct : Translations_en_US.HoursInvoicingProduct;
		Translations.HoursInvoicingTypeNoNotInUse = culture === "fi" ? Translations_fi.HoursInvoicingTypeNoNotInUse : Translations_en_US.HoursInvoicingTypeNoNotInUse;
		Translations.KmsInvoicingProduct = culture === "fi" ? Translations_fi.KmsInvoicingProduct : Translations_en_US.KmsInvoicingProduct;
		Translations.KmsInvoicingType = culture === "fi" ? Translations_fi.KmsInvoicingType : Translations_en_US.KmsInvoicingType;
		Translations.KmsInvoicingTypeNotInUse = culture === "fi" ? Translations_fi.KmsInvoicingTypeNotInUse : Translations_en_US.KmsInvoicingTypeNotInUse;
		Translations.KmsInvoicingTypePlanned = culture === "fi" ? Translations_fi.KmsInvoicingTypePlanned : Translations_en_US.KmsInvoicingTypePlanned;
		Translations.KmsInvoicingTypeRealized = culture === "fi" ? Translations_fi.KmsInvoicingTypeRealized : Translations_en_US.KmsInvoicingTypeRealized;
		Translations.NotSelected = culture === "fi" ? Translations_fi.NotSelected : Translations_en_US.NotSelected;
		Translations.RoutePointUsage = culture === "fi" ? Translations_fi.RoutePointUsage : Translations_en_US.RoutePointUsage;
		Translations.UserInterfaceSettings = culture === "fi" ? Translations_fi.UserInterfaceSettings : Translations_en_US.UserInterfaceSettings;
		Translations.Bookings = culture === "fi" ? Translations_fi.Bookings : Translations_en_US.Bookings;
		Translations.HideMap = culture === "fi" ? Translations_fi.HideMap : Translations_en_US.HideMap;
		Translations.ShowMap = culture === "fi" ? Translations_fi.ShowMap : Translations_en_US.ShowMap;
		Translations.UseCollectionListInMobile = culture === "fi" ? Translations_fi.UseCollectionListInMobile : Translations_en_US.UseCollectionListInMobile;
		Translations.MarkProductAsCollectedByCheckingRow = culture === "fi" ? Translations_fi.MarkProductAsCollectedByCheckingRow : Translations_en_US.MarkProductAsCollectedByCheckingRow;
		Translations.StorageProductBookingAmountChangeFailed = culture === "fi" ? Translations_fi.StorageProductBookingAmountChangeFailed : Translations_en_US.StorageProductBookingAmountChangeFailed;
		Translations.StorageProductBookingRemoveConfirmation = culture === "fi" ? Translations_fi.StorageProductBookingRemoveConfirmation : Translations_en_US.StorageProductBookingRemoveConfirmation;
		Translations.StorageProductBookings = culture === "fi" ? Translations_fi.StorageProductBookings : Translations_en_US.StorageProductBookings;
		Translations.Interval = culture === "fi" ? Translations_fi.Interval : Translations_en_US.Interval;
		Translations.SelectSalaryType = culture === "fi" ? Translations_fi.SelectSalaryType : Translations_en_US.SelectSalaryType;
		Translations.ShowCostCenters = culture === "fi" ? Translations_fi.ShowCostCenters : Translations_en_US.ShowCostCenters;
		Translations.ShowCustomers = culture === "fi" ? Translations_fi.ShowCustomers : Translations_en_US.ShowCustomers;
		Translations.ShowOrders = culture === "fi" ? Translations_fi.ShowOrders : Translations_en_US.ShowOrders;
		Translations.InvoiceReport = culture === "fi" ? Translations_fi.InvoiceReport : Translations_en_US.InvoiceReport;
		Translations.SalaryReport = culture === "fi" ? Translations_fi.SalaryReport : Translations_en_US.SalaryReport;
		Translations.WorkTimeReport = culture === "fi" ? Translations_fi.WorkTimeReport : Translations_en_US.WorkTimeReport;
		Translations.StartingTimeMustOccurBeforeEndingTime = culture === "fi" ? Translations_fi.StartingTimeMustOccurBeforeEndingTime : Translations_en_US.StartingTimeMustOccurBeforeEndingTime;
		Translations.TaskTypeMustBeSelected = culture === "fi" ? Translations_fi.TaskTypeMustBeSelected : Translations_en_US.TaskTypeMustBeSelected;
		Translations.SalaryPeriodMissing = culture === "fi" ? Translations_fi.SalaryPeriodMissing : Translations_en_US.SalaryPeriodMissing;
		Translations.HourBookingTypesMissing = culture === "fi" ? Translations_fi.HourBookingTypesMissing : Translations_en_US.HourBookingTypesMissing;
		Translations.InvalidTabIndex = culture === "fi" ? Translations_fi.InvalidTabIndex : Translations_en_US.InvalidTabIndex;
		Translations.CloseWindow = culture === "fi" ? Translations_fi.CloseWindow : Translations_en_US.CloseWindow;
		Translations.ChooseCustomer = culture === "fi" ? Translations_fi.ChooseCustomer : Translations_en_US.ChooseCustomer;
		Translations.ChooseStorage = culture === "fi" ? Translations_fi.ChooseStorage : Translations_en_US.ChooseStorage;
		Translations.SearchConditions = culture === "fi" ? Translations_fi.SearchConditions : Translations_en_US.SearchConditions;
		Translations.NoOptions = culture === "fi" ? Translations_fi.NoOptions : Translations_en_US.NoOptions;
		Translations.FieldIsRequired = culture === "fi" ? Translations_fi.FieldIsRequired : Translations_en_US.FieldIsRequired;
		Translations.FieldMaxLengthError = culture === "fi" ? Translations_fi.FieldMaxLengthError : Translations_en_US.FieldMaxLengthError;
		Translations.FieldMinLengthError = culture === "fi" ? Translations_fi.FieldMinLengthError : Translations_en_US.FieldMinLengthError;
		Translations.StorageNotFound = culture === "fi" ? Translations_fi.StorageNotFound : Translations_en_US.StorageNotFound;
		Translations.ShowOwnWorkOrders = culture === "fi" ? Translations_fi.ShowOwnWorkOrders : Translations_en_US.ShowOwnWorkOrders;
		Translations.RemoveProductFromCart = culture === "fi" ? Translations_fi.RemoveProductFromCart : Translations_en_US.RemoveProductFromCart;
		Translations.CloseCart = culture === "fi" ? Translations_fi.CloseCart : Translations_en_US.CloseCart;
		Translations.EditStorageLocation = culture === "fi" ? Translations_fi.EditStorageLocation : Translations_en_US.EditStorageLocation;
		Translations.SalaryRowTypeKind = culture === "fi" ? Translations_fi.SalaryRowTypeKind : Translations_en_US.SalaryRowTypeKind;
		Translations.SalaryRowTypeParameterNotFound = culture === "fi" ? Translations_fi.SalaryRowTypeParameterNotFound : Translations_en_US.SalaryRowTypeParameterNotFound;
		Translations.SalaryRowTypeParameterParameterSaveFailedErrorParameter = culture === "fi" ? Translations_fi.SalaryRowTypeParameterParameterSaveFailedErrorParameter : Translations_en_US.SalaryRowTypeParameterParameterSaveFailedErrorParameter;
		Translations.SalaryRowTypeParameterSaveFailedConcurrencyError = culture === "fi" ? Translations_fi.SalaryRowTypeParameterSaveFailedConcurrencyError : Translations_en_US.SalaryRowTypeParameterSaveFailedConcurrencyError;
		Translations.SalaryRowTypeParameterSaveSuccess = culture === "fi" ? Translations_fi.SalaryRowTypeParameterSaveSuccess : Translations_en_US.SalaryRowTypeParameterSaveSuccess;
		Translations.SalaryRowTypeRemoveConfirmation = culture === "fi" ? Translations_fi.SalaryRowTypeRemoveConfirmation : Translations_en_US.SalaryRowTypeRemoveConfirmation;
		Translations.SalaryRowTypeParameterCannotBeRemovedBecauseItIsUsedInParameterDayBookingType = culture === "fi" ? Translations_fi.SalaryRowTypeParameterCannotBeRemovedBecauseItIsUsedInParameterDayBookingType : Translations_en_US.SalaryRowTypeParameterCannotBeRemovedBecauseItIsUsedInParameterDayBookingType;
		Translations.SalaryRowTypeParameterRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.SalaryRowTypeParameterRemoveFailedConcurrencyError : Translations_en_US.SalaryRowTypeParameterRemoveFailedConcurrencyError;
		Translations.SalaryRowTypeParameterRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.SalaryRowTypeParameterRemoveFailedErrorParameter : Translations_en_US.SalaryRowTypeParameterRemoveFailedErrorParameter;
		Translations.SalaryRowTypesParameterRemoveFailedConcurrencyError = culture === "fi" ? Translations_fi.SalaryRowTypesParameterRemoveFailedConcurrencyError : Translations_en_US.SalaryRowTypesParameterRemoveFailedConcurrencyError;
		Translations.SalaryRowTypesParameterRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.SalaryRowTypesParameterRemoveFailedErrorParameter : Translations_en_US.SalaryRowTypesParameterRemoveFailedErrorParameter;
		Translations.SalaryRowTypesParameterRemoveSuccess = culture === "fi" ? Translations_fi.SalaryRowTypesParameterRemoveSuccess : Translations_en_US.SalaryRowTypesParameterRemoveSuccess;
		Translations.SalaryRowTypeParameterCannotBeRemovedBecauseItIsInUsed = culture === "fi" ? Translations_fi.SalaryRowTypeParameterCannotBeRemovedBecauseItIsInUsed : Translations_en_US.SalaryRowTypeParameterCannotBeRemovedBecauseItIsInUsed;
		Translations.SalaryRowTypeParameterCannotBeRemovedBecauseItIsUsedInParameterHourBookingType = culture === "fi" ? Translations_fi.SalaryRowTypeParameterCannotBeRemovedBecauseItIsUsedInParameterHourBookingType : Translations_en_US.SalaryRowTypeParameterCannotBeRemovedBecauseItIsUsedInParameterHourBookingType;
		Translations.BillingProduct = culture === "fi" ? Translations_fi.BillingProduct : Translations_en_US.BillingProduct;
		Translations.AddProduct = culture === "fi" ? Translations_fi.AddProduct : Translations_en_US.AddProduct;
		Translations.InvalidAmountError = culture === "fi" ? Translations_fi.InvalidAmountError : Translations_en_US.InvalidAmountError;
		Translations.InvalidValue = culture === "fi" ? Translations_fi.InvalidValue : Translations_en_US.InvalidValue;
		Translations.StorageLocationNotFound = culture === "fi" ? Translations_fi.StorageLocationNotFound : Translations_en_US.StorageLocationNotFound;
		Translations.CannotDeleteStorageLocationWithProducts = culture === "fi" ? Translations_fi.CannotDeleteStorageLocationWithProducts : Translations_en_US.CannotDeleteStorageLocationWithProducts;
		Translations.ScrollLabelRowsPerPage = culture === "fi" ? Translations_fi.ScrollLabelRowsPerPage : Translations_en_US.ScrollLabelRowsPerPage;
		Translations.ShowDetails = culture === "fi" ? Translations_fi.ShowDetails : Translations_en_US.ShowDetails;
		Translations.FetchFailed = culture === "fi" ? Translations_fi.FetchFailed : Translations_en_US.FetchFailed;
		Translations.ProductNumber = culture === "fi" ? Translations_fi.ProductNumber : Translations_en_US.ProductNumber;
		Translations.ProductDescription = culture === "fi" ? Translations_fi.ProductDescription : Translations_en_US.ProductDescription;
		Translations.CreateNewProduct = culture === "fi" ? Translations_fi.CreateNewProduct : Translations_en_US.CreateNewProduct;
		Translations.EditProduct = culture === "fi" ? Translations_fi.EditProduct : Translations_en_US.EditProduct;
		Translations.CreateNewStorageProduct = culture === "fi" ? Translations_fi.CreateNewStorageProduct : Translations_en_US.CreateNewStorageProduct;
		Translations.ProductAddedToCart = culture === "fi" ? Translations_fi.ProductAddedToCart : Translations_en_US.ProductAddedToCart;
		Translations.ProductNotFound = culture === "fi" ? Translations_fi.ProductNotFound : Translations_en_US.ProductNotFound;
		Translations.InvalidPriceError = culture === "fi" ? Translations_fi.InvalidPriceError : Translations_en_US.InvalidPriceError;
		Translations.NoCustomerPrices = culture === "fi" ? Translations_fi.NoCustomerPrices : Translations_en_US.NoCustomerPrices;
		Translations.CustomerPriceAlreadyExists = culture === "fi" ? Translations_fi.CustomerPriceAlreadyExists : Translations_en_US.CustomerPriceAlreadyExists;
		Translations.EnterPositiveNumber = culture === "fi" ? Translations_fi.EnterPositiveNumber : Translations_en_US.EnterPositiveNumber;
		Translations.NewStorageLocationRow = culture === "fi" ? Translations_fi.NewStorageLocationRow : Translations_en_US.NewStorageLocationRow;
		Translations.AreYouSureWantDeleteStorageProduct = culture === "fi" ? Translations_fi.AreYouSureWantDeleteStorageProduct : Translations_en_US.AreYouSureWantDeleteStorageProduct;
		Translations.OpenBetaVersion = culture === "fi" ? Translations_fi.OpenBetaVersion : Translations_en_US.OpenBetaVersion;
		Translations.ApproveAll = culture === "fi" ? Translations_fi.ApproveAll : Translations_en_US.ApproveAll;
		Translations.Approved = culture === "fi" ? Translations_fi.Approved : Translations_en_US.Approved;
		Translations.ShowOnlyNotApproved = culture === "fi" ? Translations_fi.ShowOnlyNotApproved : Translations_en_US.ShowOnlyNotApproved;
		Translations.LockAll = culture === "fi" ? Translations_fi.LockAll : Translations_en_US.LockAll;
		Translations.UnlockAll = culture === "fi" ? Translations_fi.UnlockAll : Translations_en_US.UnlockAll;
		Translations.WorkTimeOriginIntegration = culture === "fi" ? Translations_fi.WorkTimeOriginIntegration : Translations_en_US.WorkTimeOriginIntegration;
		Translations.ApproveAndSave = culture === "fi" ? Translations_fi.ApproveAndSave : Translations_en_US.ApproveAndSave;
		Translations.WorkHoursApproveWarning = culture === "fi" ? Translations_fi.WorkHoursApproveWarning : Translations_en_US.WorkHoursApproveWarning;
		Translations.WorkTimeDifferentFromOriginal = culture === "fi" ? Translations_fi.WorkTimeDifferentFromOriginal : Translations_en_US.WorkTimeDifferentFromOriginal;
		Translations.WorkTimeApproval = culture === "fi" ? Translations_fi.WorkTimeApproval : Translations_en_US.WorkTimeApproval;
		Translations.WorkTimeDetailsHeaderLabel = culture === "fi" ? Translations_fi.WorkTimeDetailsHeaderLabel : Translations_en_US.WorkTimeDetailsHeaderLabel;
		Translations.WorkTimeType = culture === "fi" ? Translations_fi.WorkTimeType : Translations_en_US.WorkTimeType;
		Translations.NoSalaryRowType = culture === "fi" ? Translations_fi.NoSalaryRowType : Translations_en_US.NoSalaryRowType;
		Translations.AbrWeek = culture === "fi" ? Translations_fi.AbrWeek : Translations_en_US.AbrWeek;
		Translations.WorkHoursLockRowsWarning = culture === "fi" ? Translations_fi.WorkHoursLockRowsWarning : Translations_en_US.WorkHoursLockRowsWarning;
		Translations.EmployeeNotFound = culture === "fi" ? Translations_fi.EmployeeNotFound : Translations_en_US.EmployeeNotFound;
		Translations.RoundAllWorkShiftStartAndEnds = culture === "fi" ? Translations_fi.RoundAllWorkShiftStartAndEnds : Translations_en_US.RoundAllWorkShiftStartAndEnds;
		Translations.RoundUnlockedWorkShiftsApproveWarning = culture === "fi" ? Translations_fi.RoundUnlockedWorkShiftsApproveWarning : Translations_en_US.RoundUnlockedWorkShiftsApproveWarning;
		Translations.PrintStarted = culture === "fi" ? Translations_fi.PrintStarted : Translations_en_US.PrintStarted;
		Translations.WorkShiftTimeSlotStateInProgressCannotBeChanged = culture === "fi" ? Translations_fi.WorkShiftTimeSlotStateInProgressCannotBeChanged : Translations_en_US.WorkShiftTimeSlotStateInProgressCannotBeChanged;
		Translations.WorkShiftTimeSlotEndTimeBeforeStartTime = culture === "fi" ? Translations_fi.WorkShiftTimeSlotEndTimeBeforeStartTime : Translations_en_US.WorkShiftTimeSlotEndTimeBeforeStartTime;
		Translations.SalaryRowTypeKindIdMustBeDefined = culture === "fi" ? Translations_fi.SalaryRowTypeKindIdMustBeDefined : Translations_en_US.SalaryRowTypeKindIdMustBeDefined;
		Translations.MeasureUnitMustBeDefined = culture === "fi" ? Translations_fi.MeasureUnitMustBeDefined : Translations_en_US.MeasureUnitMustBeDefined;
		Translations.Vacations = culture === "fi" ? Translations_fi.Vacations : Translations_en_US.Vacations;
		Translations.Absences = culture === "fi" ? Translations_fi.Absences : Translations_en_US.Absences;
		Translations.AreYouSureYouWantToRemoveTransportPlan = culture === "fi" ? Translations_fi.AreYouSureYouWantToRemoveTransportPlan : Translations_en_US.AreYouSureYouWantToRemoveTransportPlan;
		Translations.NewPlan = culture === "fi" ? Translations_fi.NewPlan : Translations_en_US.NewPlan;
		Translations.NewTransportPlan = culture === "fi" ? Translations_fi.NewTransportPlan : Translations_en_US.NewTransportPlan;
		Translations.NoEmployee = culture === "fi" ? Translations_fi.NoEmployee : Translations_en_US.NoEmployee;
		Translations.NonOrders = culture === "fi" ? Translations_fi.NonOrders : Translations_en_US.NonOrders;
		Translations.RemovePlan = culture === "fi" ? Translations_fi.RemovePlan : Translations_en_US.RemovePlan;
		Translations.ShowCalendarFrom = culture === "fi" ? Translations_fi.ShowCalendarFrom : Translations_en_US.ShowCalendarFrom;
		Translations.TransportOrders = culture === "fi" ? Translations_fi.TransportOrders : Translations_en_US.TransportOrders;
		Translations.TimeZone = culture === "fi" ? Translations_fi.TimeZone : Translations_en_US.TimeZone;
		Translations.WorkTimeRoundingInfoInProgressWorkShifts = culture === "fi" ? Translations_fi.WorkTimeRoundingInfoInProgressWorkShifts : Translations_en_US.WorkTimeRoundingInfoInProgressWorkShifts;
		Translations.TransportOrdersInvoices = culture === "fi" ? Translations_fi.TransportOrdersInvoices : Translations_en_US.TransportOrdersInvoices;
		Translations.SelectedOrders = culture === "fi" ? Translations_fi.SelectedOrders : Translations_en_US.SelectedOrders;
		Translations.CreateNewInvoice = culture === "fi" ? Translations_fi.CreateNewInvoice : Translations_en_US.CreateNewInvoice;
		Translations.SelectAllDelivered = culture === "fi" ? Translations_fi.SelectAllDelivered : Translations_en_US.SelectAllDelivered;
		Translations.TransferToInvoice = culture === "fi" ? Translations_fi.TransferToInvoice : Translations_en_US.TransferToInvoice;
		Translations.TransferToInvoicing = culture === "fi" ? Translations_fi.TransferToInvoicing : Translations_en_US.TransferToInvoicing;
		Translations.Definition = culture === "fi" ? Translations_fi.Definition : Translations_en_US.Definition;
		Translations.OrderNumber = culture === "fi" ? Translations_fi.OrderNumber : Translations_en_US.OrderNumber;
		Translations.NotDelivered = culture === "fi" ? Translations_fi.NotDelivered : Translations_en_US.NotDelivered;
		Translations.ShowOnlyDelivered = culture === "fi" ? Translations_fi.ShowOnlyDelivered : Translations_en_US.ShowOnlyDelivered;
		Translations.Default = culture === "fi" ? Translations_fi.Default : Translations_en_US.Default;
		Translations.WorkTimeTypes = culture === "fi" ? Translations_fi.WorkTimeTypes : Translations_en_US.WorkTimeTypes;
		Translations.Selected = culture === "fi" ? Translations_fi.Selected : Translations_en_US.Selected;
		Translations.WorkTimeTypeParameterNotFound = culture === "fi" ? Translations_fi.WorkTimeTypeParameterNotFound : Translations_en_US.WorkTimeTypeParameterNotFound;
		Translations.WorkTimeTypeRemoveConfirmation = culture === "fi" ? Translations_fi.WorkTimeTypeRemoveConfirmation : Translations_en_US.WorkTimeTypeRemoveConfirmation;
		Translations.WorkTimeTypeRemoveFailedErrorParameter = culture === "fi" ? Translations_fi.WorkTimeTypeRemoveFailedErrorParameter : Translations_en_US.WorkTimeTypeRemoveFailedErrorParameter;
		Translations.WorkTimeTypeRemoveSuccess = culture === "fi" ? Translations_fi.WorkTimeTypeRemoveSuccess : Translations_en_US.WorkTimeTypeRemoveSuccess;
		Translations.WorkTimeTypesAlreadyInitialized = culture === "fi" ? Translations_fi.WorkTimeTypesAlreadyInitialized : Translations_en_US.WorkTimeTypesAlreadyInitialized;
		Translations.InitializeWorkTimeTypes = culture === "fi" ? Translations_fi.InitializeWorkTimeTypes : Translations_en_US.InitializeWorkTimeTypes;
		Translations.InitializeWorkTimeTypesConfirmation = culture === "fi" ? Translations_fi.InitializeWorkTimeTypesConfirmation : Translations_en_US.InitializeWorkTimeTypesConfirmation;
		Translations.InitializeWorkTimeTypesSuccess = culture === "fi" ? Translations_fi.InitializeWorkTimeTypesSuccess : Translations_en_US.InitializeWorkTimeTypesSuccess;
		Translations.InitializeWorkTimeTypesFailedErrorParameter = culture === "fi" ? Translations_fi.InitializeWorkTimeTypesFailedErrorParameter : Translations_en_US.InitializeWorkTimeTypesFailedErrorParameter;
		Translations.ApiKeyLabel = culture === "fi" ? Translations_fi.ApiKeyLabel : Translations_en_US.ApiKeyLabel;
		Translations.AddTransportToInvoice = culture === "fi" ? Translations_fi.AddTransportToInvoice : Translations_en_US.AddTransportToInvoice;
		Translations.ApproveInvoice = culture === "fi" ? Translations_fi.ApproveInvoice : Translations_en_US.ApproveInvoice;
		Translations.InvoiceInformation = culture === "fi" ? Translations_fi.InvoiceInformation : Translations_en_US.InvoiceInformation;
		Translations.InvoiceInformationSaveFailed = culture === "fi" ? Translations_fi.InvoiceInformationSaveFailed : Translations_en_US.InvoiceInformationSaveFailed;
		Translations.InvoiceInformationSaveSuccess = culture === "fi" ? Translations_fi.InvoiceInformationSaveSuccess : Translations_en_US.InvoiceInformationSaveSuccess;
		Translations.InvoiceNumber = culture === "fi" ? Translations_fi.InvoiceNumber : Translations_en_US.InvoiceNumber;
		Translations.NoInvoiceRows = culture === "fi" ? Translations_fi.NoInvoiceRows : Translations_en_US.NoInvoiceRows;
		Translations.ReferenceInformation = culture === "fi" ? Translations_fi.ReferenceInformation : Translations_en_US.ReferenceInformation;
		Translations.RemoveOrder = culture === "fi" ? Translations_fi.RemoveOrder : Translations_en_US.RemoveOrder;
		Translations.SaveInvoiceInformation = culture === "fi" ? Translations_fi.SaveInvoiceInformation : Translations_en_US.SaveInvoiceInformation;
		Translations.TaxFree = culture === "fi" ? Translations_fi.TaxFree : Translations_en_US.TaxFree;
		Translations.TaxInclusive = culture === "fi" ? Translations_fi.TaxInclusive : Translations_en_US.TaxInclusive;
		Translations.TaxInclusiveTotalEUR = culture === "fi" ? Translations_fi.TaxInclusiveTotalEUR : Translations_en_US.TaxInclusiveTotalEUR;
		Translations.Transports = culture === "fi" ? Translations_fi.Transports : Translations_en_US.Transports;
		Translations.Unit = culture === "fi" ? Translations_fi.Unit : Translations_en_US.Unit;
		Translations.UnitPriceEUR = culture === "fi" ? Translations_fi.UnitPriceEUR : Translations_en_US.UnitPriceEUR;
		Translations.ShowOnlyInvoiceable = culture === "fi" ? Translations_fi.ShowOnlyInvoiceable : Translations_en_US.ShowOnlyInvoiceable;
		Translations.OnTheInvoice = culture === "fi" ? Translations_fi.OnTheInvoice : Translations_en_US.OnTheInvoice;
		Translations.NoSelectedOrders = culture === "fi" ? Translations_fi.NoSelectedOrders : Translations_en_US.NoSelectedOrders;
		Translations.RemoveInvoice = culture === "fi" ? Translations_fi.RemoveInvoice : Translations_en_US.RemoveInvoice;
		Translations.FailedToCreateInvoice = culture === "fi" ? Translations_fi.FailedToCreateInvoice : Translations_en_US.FailedToCreateInvoice;
		Translations.DisplaySentInvoices = culture === "fi" ? Translations_fi.DisplaySentInvoices : Translations_en_US.DisplaySentInvoices;
		Translations.InvoiceApproved = culture === "fi" ? Translations_fi.InvoiceApproved : Translations_en_US.InvoiceApproved;
		Translations.InvoiceNotApproved = culture === "fi" ? Translations_fi.InvoiceNotApproved : Translations_en_US.InvoiceNotApproved;
		Translations.InvoiceSent = culture === "fi" ? Translations_fi.InvoiceSent : Translations_en_US.InvoiceSent;
		Translations.PayersName = culture === "fi" ? Translations_fi.PayersName : Translations_en_US.PayersName;
		Translations.SentDate = culture === "fi" ? Translations_fi.SentDate : Translations_en_US.SentDate;
		Translations.TaxExclusiveTotal = culture === "fi" ? Translations_fi.TaxExclusiveTotal : Translations_en_US.TaxExclusiveTotal;
		Translations.TotalInvoiceAmount = culture === "fi" ? Translations_fi.TotalInvoiceAmount : Translations_en_US.TotalInvoiceAmount;
		Translations.TotalTax = culture === "fi" ? Translations_fi.TotalTax : Translations_en_US.TotalTax;
		Translations.TransportOrderInvoices = culture === "fi" ? Translations_fi.TransportOrderInvoices : Translations_en_US.TransportOrderInvoices;
		Translations.SearchByInvoiceNumber = culture === "fi" ? Translations_fi.SearchByInvoiceNumber : Translations_en_US.SearchByInvoiceNumber;
		Translations.SendInvoice = culture === "fi" ? Translations_fi.SendInvoice : Translations_en_US.SendInvoice;
		Translations.InvoiceDetails = culture === "fi" ? Translations_fi.InvoiceDetails : Translations_en_US.InvoiceDetails;
		Translations.CreateInvoiceSuccess = culture === "fi" ? Translations_fi.CreateInvoiceSuccess : Translations_en_US.CreateInvoiceSuccess;
		Translations.AddSelected = culture === "fi" ? Translations_fi.AddSelected : Translations_en_US.AddSelected;
		Translations.CreateInvoicesFailed = culture === "fi" ? Translations_fi.CreateInvoicesFailed : Translations_en_US.CreateInvoicesFailed;
		Translations.AddTransportOrdersToInvoiceFailed = culture === "fi" ? Translations_fi.AddTransportOrdersToInvoiceFailed : Translations_en_US.AddTransportOrdersToInvoiceFailed;
		Translations.AddTransportOrdersToInvoiceSuccess = culture === "fi" ? Translations_fi.AddTransportOrdersToInvoiceSuccess : Translations_en_US.AddTransportOrdersToInvoiceSuccess;
		Translations.CategoryNormal = culture === "fi" ? Translations_fi.CategoryNormal : Translations_en_US.CategoryNormal;
		Translations.CategoryPeriodOvertime50 = culture === "fi" ? Translations_fi.CategoryPeriodOvertime50 : Translations_en_US.CategoryPeriodOvertime50;
		Translations.CategoryPeriodOvertime100 = culture === "fi" ? Translations_fi.CategoryPeriodOvertime100 : Translations_en_US.CategoryPeriodOvertime100;
		Translations.CategoryWeekOvertime50 = culture === "fi" ? Translations_fi.CategoryWeekOvertime50 : Translations_en_US.CategoryWeekOvertime50;
		Translations.CategoryWeekOvertime100 = culture === "fi" ? Translations_fi.CategoryWeekOvertime100 : Translations_en_US.CategoryWeekOvertime100;
		Translations.CategoryEvening = culture === "fi" ? Translations_fi.CategoryEvening : Translations_en_US.CategoryEvening;
		Translations.CategoryNight = culture === "fi" ? Translations_fi.CategoryNight : Translations_en_US.CategoryNight;
		Translations.CategorySaturday = culture === "fi" ? Translations_fi.CategorySaturday : Translations_en_US.CategorySaturday;
		Translations.CategorySundayOrHoliday = culture === "fi" ? Translations_fi.CategorySundayOrHoliday : Translations_en_US.CategorySundayOrHoliday;
		Translations.CategoryAlternativeSunday = culture === "fi" ? Translations_fi.CategoryAlternativeSunday : Translations_en_US.CategoryAlternativeSunday;
		Translations.CategoryPaidBreak = culture === "fi" ? Translations_fi.CategoryPaidBreak : Translations_en_US.CategoryPaidBreak;
		Translations.CategoryCustomNormalType = culture === "fi" ? Translations_fi.CategoryCustomNormalType : Translations_en_US.CategoryCustomNormalType;
		Translations.CategoryMidWeekHoliday = culture === "fi" ? Translations_fi.CategoryMidWeekHoliday : Translations_en_US.CategoryMidWeekHoliday;
		Translations.CategoryMidWeekHoliday200 = culture === "fi" ? Translations_fi.CategoryMidWeekHoliday200 : Translations_en_US.CategoryMidWeekHoliday200;
		Translations.CategorySalaryInSickTime = culture === "fi" ? Translations_fi.CategorySalaryInSickTime : Translations_en_US.CategorySalaryInSickTime;
		Translations.CategoryWorkTimeCut = culture === "fi" ? Translations_fi.CategoryWorkTimeCut : Translations_en_US.CategoryWorkTimeCut;
		Translations.CategoryHalfDailyAllowance = culture === "fi" ? Translations_fi.CategoryHalfDailyAllowance : Translations_en_US.CategoryHalfDailyAllowance;
		Translations.CategoryDailyAllowance = culture === "fi" ? Translations_fi.CategoryDailyAllowance : Translations_en_US.CategoryDailyAllowance;
		Translations.CategoryDailyOvertime50 = culture === "fi" ? Translations_fi.CategoryDailyOvertime50 : Translations_en_US.CategoryDailyOvertime50;
		Translations.CategoryDailyOvertime100 = culture === "fi" ? Translations_fi.CategoryDailyOvertime100 : Translations_en_US.CategoryDailyOvertime100;
		Translations.CategoryBreak = culture === "fi" ? Translations_fi.CategoryBreak : Translations_en_US.CategoryBreak;
		Translations.CategoryMealBreak = culture === "fi" ? Translations_fi.CategoryMealBreak : Translations_en_US.CategoryMealBreak;
		Translations.InvoiceSendFailed = culture === "fi" ? Translations_fi.InvoiceSendFailed : Translations_en_US.InvoiceSendFailed;
		Translations.ShowAllTransportOrders = culture === "fi" ? Translations_fi.ShowAllTransportOrders : Translations_en_US.ShowAllTransportOrders;
		Translations.EditInvoice = culture === "fi" ? Translations_fi.EditInvoice : Translations_en_US.EditInvoice;
		Translations.CategorySecondaryEvening = culture === "fi" ? Translations_fi.CategorySecondaryEvening : Translations_en_US.CategorySecondaryEvening;
		Translations.CategorySecondaryNight = culture === "fi" ? Translations_fi.CategorySecondaryNight : Translations_en_US.CategorySecondaryNight;
		Translations.RevokeApproval = culture === "fi" ? Translations_fi.RevokeApproval : Translations_en_US.RevokeApproval;
		Translations.Delivered = culture === "fi" ? Translations_fi.Delivered : Translations_en_US.Delivered;
		Translations.DeliveryDetails = culture === "fi" ? Translations_fi.DeliveryDetails : Translations_en_US.DeliveryDetails;
		Translations.PickupDetails = culture === "fi" ? Translations_fi.PickupDetails : Translations_en_US.PickupDetails;
		Translations.WaybillNumber = culture === "fi" ? Translations_fi.WaybillNumber : Translations_en_US.WaybillNumber;
		Translations.TotalPrice = culture === "fi" ? Translations_fi.TotalPrice : Translations_en_US.TotalPrice;
		Translations.BillingCustomer = culture === "fi" ? Translations_fi.BillingCustomer : Translations_en_US.BillingCustomer;
		Translations.AreYouSureWantRevokeApproval = culture === "fi" ? Translations_fi.AreYouSureWantRevokeApproval : Translations_en_US.AreYouSureWantRevokeApproval;
		Translations.InvoicingBetaTitle = culture === "fi" ? Translations_fi.InvoicingBetaTitle : Translations_en_US.InvoicingBetaTitle;
		Translations.WorkTimeTypeBreak = culture === "fi" ? Translations_fi.WorkTimeTypeBreak : Translations_en_US.WorkTimeTypeBreak;
		Translations.WorkTimeTypeDriving = culture === "fi" ? Translations_fi.WorkTimeTypeDriving : Translations_en_US.WorkTimeTypeDriving;
		Translations.WorkTimeTypeLoading = culture === "fi" ? Translations_fi.WorkTimeTypeLoading : Translations_en_US.WorkTimeTypeLoading;
		Translations.WorkTimeTypeOther = culture === "fi" ? Translations_fi.WorkTimeTypeOther : Translations_en_US.WorkTimeTypeOther;
		Translations.WorkTimeTypeUnLoading = culture === "fi" ? Translations_fi.WorkTimeTypeUnLoading : Translations_en_US.WorkTimeTypeUnLoading;
		Translations.WorkTimeTypeWaiting = culture === "fi" ? Translations_fi.WorkTimeTypeWaiting : Translations_en_US.WorkTimeTypeWaiting;
		Translations.DeliveryTime = culture === "fi" ? Translations_fi.DeliveryTime : Translations_en_US.DeliveryTime;
		Translations.NotSent = culture === "fi" ? Translations_fi.NotSent : Translations_en_US.NotSent;
		Translations.Price = culture === "fi" ? Translations_fi.Price : Translations_en_US.Price;
		Translations.Sent = culture === "fi" ? Translations_fi.Sent : Translations_en_US.Sent;
		Translations.WaybillNo = culture === "fi" ? Translations_fi.WaybillNo : Translations_en_US.WaybillNo;
		Translations.TransportOrderInvoiceFetchFail = culture === "fi" ? Translations_fi.TransportOrderInvoiceFetchFail : Translations_en_US.TransportOrderInvoiceFetchFail;
		Translations.TransportOrderInvoicesFetchFail = culture === "fi" ? Translations_fi.TransportOrderInvoicesFetchFail : Translations_en_US.TransportOrderInvoicesFetchFail;
		Translations.TransportOrdersFetchFail = culture === "fi" ? Translations_fi.TransportOrdersFetchFail : Translations_en_US.TransportOrdersFetchFail;
		Translations.RemoveFromPlan = culture === "fi" ? Translations_fi.RemoveFromPlan : Translations_en_US.RemoveFromPlan;
		Translations.Invoiced = culture === "fi" ? Translations_fi.Invoiced : Translations_en_US.Invoiced;
		Translations.CategoryPaidWaiting = culture === "fi" ? Translations_fi.CategoryPaidWaiting : Translations_en_US.CategoryPaidWaiting;
		Translations.CategoryUnPaidWaiting = culture === "fi" ? Translations_fi.CategoryUnPaidWaiting : Translations_en_US.CategoryUnPaidWaiting;
		Translations.CategoryOvertimeBase = culture === "fi" ? Translations_fi.CategoryOvertimeBase : Translations_en_US.CategoryOvertimeBase;
		Translations.CategoryMonthlyOvertime50 = culture === "fi" ? Translations_fi.CategoryMonthlyOvertime50 : Translations_en_US.CategoryMonthlyOvertime50;
		Translations.CategoryGrandHoliday = culture === "fi" ? Translations_fi.CategoryGrandHoliday : Translations_en_US.CategoryGrandHoliday;
		Translations.EditRecurringTransportPlan = culture === "fi" ? Translations_fi.EditRecurringTransportPlan : Translations_en_US.EditRecurringTransportPlan;
		Translations.CannotAddOrderToCompletedPlan = culture === "fi" ? Translations_fi.CannotAddOrderToCompletedPlan : Translations_en_US.CannotAddOrderToCompletedPlan;
		Translations.ThreeMonthTransportPlanQuery = culture === "fi" ? Translations_fi.ThreeMonthTransportPlanQuery : Translations_en_US.ThreeMonthTransportPlanQuery;
		Translations.TransportPlansFetchFail = culture === "fi" ? Translations_fi.TransportPlansFetchFail : Translations_en_US.TransportPlansFetchFail;
		Translations.AddBreak = culture === "fi" ? Translations_fi.AddBreak : Translations_en_US.AddBreak;
		Translations.breakRangeError = culture === "fi" ? Translations_fi.breakRangeError : Translations_en_US.breakRangeError;
		Translations.ChangeInInvoicingStateWasSuccessful = culture === "fi" ? Translations_fi.ChangeInInvoicingStateWasSuccessful : Translations_en_US.ChangeInInvoicingStateWasSuccessful;
		Translations.Invoiceable = culture === "fi" ? Translations_fi.Invoiceable : Translations_en_US.Invoiceable;
		Translations.InvoicingStateChangeFailed = culture === "fi" ? Translations_fi.InvoicingStateChangeFailed : Translations_en_US.InvoicingStateChangeFailed;
		Translations.MarkAsInvoiced = culture === "fi" ? Translations_fi.MarkAsInvoiced : Translations_en_US.MarkAsInvoiced;
		Translations.ReturnToInvoiceable = culture === "fi" ? Translations_fi.ReturnToInvoiceable : Translations_en_US.ReturnToInvoiceable;
		Translations.TransportOrderInvoicingStateConfirmation = culture === "fi" ? Translations_fi.TransportOrderInvoicingStateConfirmation : Translations_en_US.TransportOrderInvoicingStateConfirmation;
		Translations.FixedPriceProductRemoveInformation = culture === "fi" ? Translations_fi.FixedPriceProductRemoveInformation : Translations_en_US.FixedPriceProductRemoveInformation;
		Translations.OverlappingWorkTimeEntries = culture === "fi" ? Translations_fi.OverlappingWorkTimeEntries : Translations_en_US.OverlappingWorkTimeEntries;
		Translations.WorkShiftInProgressCannotBeSaved = culture === "fi" ? Translations_fi.WorkShiftInProgressCannotBeSaved : Translations_en_US.WorkShiftInProgressCannotBeSaved;
		Translations.DateNotInSalaryPeriod = culture === "fi" ? Translations_fi.DateNotInSalaryPeriod : Translations_en_US.DateNotInSalaryPeriod;
		Translations.OverwritingWorkTimeRangeError = culture === "fi" ? Translations_fi.OverwritingWorkTimeRangeError : Translations_en_US.OverwritingWorkTimeRangeError;
		Translations.CategoryCustomAnyType = culture === "fi" ? Translations_fi.CategoryCustomAnyType : Translations_en_US.CategoryCustomAnyType;
		Translations.CategoryOverTimeCalculation = culture === "fi" ? Translations_fi.CategoryOverTimeCalculation : Translations_en_US.CategoryOverTimeCalculation;
		Translations.CategoryPaidMealBreak = culture === "fi" ? Translations_fi.CategoryPaidMealBreak : Translations_en_US.CategoryPaidMealBreak;
		Translations.CategoryCustomAddition = culture === "fi" ? Translations_fi.CategoryCustomAddition : Translations_en_US.CategoryCustomAddition;
		Translations.DayBookingLockSuccess = culture === "fi" ? Translations_fi.DayBookingLockSuccess : Translations_en_US.DayBookingLockSuccess;
		Translations.DayBookingUnlockSuccess = culture === "fi" ? Translations_fi.DayBookingUnlockSuccess : Translations_en_US.DayBookingUnlockSuccess;
		Translations.DayBookingLockFail = culture === "fi" ? Translations_fi.DayBookingLockFail : Translations_en_US.DayBookingLockFail;
		Translations.DayBookingUnlockFail = culture === "fi" ? Translations_fi.DayBookingUnlockFail : Translations_en_US.DayBookingUnlockFail;
		Translations.DayBookingLocked = culture === "fi" ? Translations_fi.DayBookingLocked : Translations_en_US.DayBookingLocked;
	}

	//Divided to multiple functions because of error - TS2563 The containing function or module body is too large for control flow analysis.
	static setCulture(culture: string) {
	  this.setCulture1(culture);
	  this.setCulture2(culture);
	}
}
// -----------------------------------------------------------------
// !!! THIS FILE IS AUTOMATICALLY GENERATED DO NOT EDIT MANUALLY !!!
// -----------------------------------------------------------------