import React, { useEffect, useState } from "react";
import { MainLayout } from "../../layout/mainLayout";
import { IconButton, Stack, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useLocation, useParams } from "react-router-dom";
import { LoadingIndicator } from "../../framework/loadingIndicator";
import { TransportPlanTemplateFormData, TransportPlanTemplateDetailsDto } from "../../../models/transport/transportPlanTemplate";
import { TransportPlanListItemDto } from "../../../models/transport/transportPlan";
import {
    showApiError,
} from "../../framework/formUtils";
import { getTransportPlanTemplate } from "../../../services/transportPlanTemplateService";
import { Translations } from "../../../models/translations";
import Grid2 from "@mui/material/Unstable_Grid2";
import { STYLE_CONSTANTS } from "../../../framework/theme";
import { TransportPlanRecurringEdit } from "./transportPlanRecurringEdit";
import { convertDuration } from "../transportTimeline/Utils";

export const TransportPlanRecurringEditMain = () => {
    const location = useLocation<{ fromPath: string }>();
    const { id } = useParams<{ id: string }>();
    const fromPath = location.state?.fromPath ?? "/transport/plans";
    const [item, setItem] = useState<TransportPlanTemplateFormData>();
    const [loading, setLoading] = useState(false);
    const [plans, setPlans] = useState<TransportPlanListItemDto[]>([]);

    const getFormModel = (details?: TransportPlanTemplateDetailsDto) => {
        if (details) {
            return {
                name: details?.name,
                scheduledStartTime: details?.scheduledStartTime,
                duration: details?.duration,
                durationDay: convertDuration(details?.duration).days,
                durationHours: convertDuration(details?.duration).hours,
                durationMinutes: convertDuration(details?.duration).minutes,
                employeeId: details?.employee?.id,
                vehicleId: details?.vehicle?.id,
                repeatStartDate: new Date(details?.repeatStartDate),
                repeatEndDate: new Date(details?.repeatEndDate),
                repeatEnabledDays: details?.enabledDays || null,
            };
        } else return;
    };

    useEffect(() => {
        setLoading(true);
        getTransportPlanTemplate(id)
            .then((res) => {
                setItem(getFormModel(res.data));
                setPlans(res.data.transportPlans);
            }
            ).catch(() => {
                showApiError(Translations.FetchFailed);
            }).finally(() => setLoading(false));
    }, [id]);
    return (
        <MainLayout>
            <>
                <IconButton
                    component={Link}
                    to={fromPath}
                    color="primary"
                >
                    <ArrowBackIcon />
                </IconButton>
                <Stack m={4} spacing={2}>
                    <Typography variant="h2">
                        {Translations.EditRecurringTransportPlan}
                    </Typography>
                </Stack>
                <Grid2 m={4} spacing={STYLE_CONSTANTS.formSpacing}>
                    {loading && <LoadingIndicator />}
                    {!loading && item &&
                        <TransportPlanRecurringEdit
                            item={item}
                            plans={plans}
                            fromPath={fromPath}
                        />
                    }
                </Grid2>
            </>
        </MainLayout>
    );
};
