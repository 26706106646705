import React from "react";
import { Link } from "react-router-dom";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useSelectCalendarDay } from "../../../hooks/useCalendar";

export const LinkToEmployeeWorkTimeDetails = (
    employeeId: string,
    dateStr: string,
    content: string
) => {
    const calendarDay = useSelectCalendarDay(dateStr);
    return (
        <Box>
            <Link
                title={calendarDay?.isHoliday ? calendarDay?.description : ""}
                onClick={(e) => e.stopPropagation()}
                style={{ color: calendarDay?.isHoliday ? "red" : "inherit" }}
                target="_blank"
                to={`/worktimepage/details/${employeeId}/${dateStr}`}
            >
                {content}
            </Link>
        </Box>
    );
};

interface Row {
    rowId: string | number;
}

interface DetailsColumn {
    header: string;
    renderCell: (row) => JSX.Element | string;
    colSpan?: number;
}

interface ReportingDetailsTableProps<T> {
    columns: DetailsColumn[];
    rows: T[];
}

export const ReportingDetailsTable = <T extends Row>({
    columns,
    rows,
}: ReportingDetailsTableProps<T>) => {
    return (
        <Box sx={{ p: 3, width: "fit-content", position: "sticky", left: 0 }}>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {columns.map((c) => (
                                <TableCell key={c.header} colSpan={c.colSpan}>
                                    {c.header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.map((r) => (
                            <TableRow key={r.rowId}>
                                {columns.map((c) => (
                                    <TableCell
                                        key={c.header}
                                        colSpan={c.colSpan}
                                    >
                                        {c.renderCell(r)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};
