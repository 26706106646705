import { connect } from "react-redux";
import { IApplicationState } from "../../models/store/storeTypes";
import * as storeEffects from "../../models/store/storeEffects";
import { Base } from "../../framework/base";
import { IWorkPageMainStateProps, IWorkPageMainDispatchProps, IWorkPageMainOwnProps, WorkPageMain } from "../../components/work/workPageMain";
import { IEmployeeParameters } from "../../models/employee/employeeIParameters";

export function mapStateToProps(applicationState: IApplicationState): IWorkPageMainStateProps {
    if (Base.isNullOrUndefined(applicationState) || Base.isNullOrUndefined(applicationState.workMain)) {
        return {
            owner: null,
            user: null
        };
    }
    return {
        owner: applicationState.owner,
        user: applicationState.user
    };
}

export function mapDispatchToProps(dispatch: storeEffects.SettingsThunkDispatch): IWorkPageMainDispatchProps {
    return {
        setEmployeeParameters: (parameters: IEmployeeParameters, saveToDb: boolean) => dispatch(storeEffects.setEmployeeParameters(parameters, saveToDb)),
        onLogout: () => dispatch(storeEffects.logout())
    };
}

export default connect<IWorkPageMainStateProps, IWorkPageMainDispatchProps, IWorkPageMainOwnProps>(mapStateToProps, mapDispatchToProps)(WorkPageMain);
