// SettingsRoutePointDialogRoutePointCheckList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import { Translations } from "../../models/translations";
import { PropertyDialog } from "../framework/dialog";
import { ToolButton } from "../framework/toolButton";
import { Base } from "../../framework/base";
import { IRoutePointCheckEditItem, RoutePointCheckEditItem } from "../../models/routePointCheck/routePointCheckEditItem";
import { SaveData } from "../../framework/saveData";
import { ConfirmationDialogType } from "../../models/store/storeTypes";

// SettingsRoutePointDialogRoutePointCheckDialog
// ***********************************************************************************************************************
export interface ISettingsRoutePointDialogRoutePointCheckDialogProp {
    classes?: string;
    item: IRoutePointCheckEditItem;
    isNew: boolean;
    onOk: (item: IRoutePointCheckEditItem) => void;
    onCancel: () => void;
}

export interface ISettingsRoutePointDialogRoutePointCheckDialogState {
    name: string;
    description: string;
}

export class SettingsRoutePointDialogRoutePointCheckDialog extends React.Component<ISettingsRoutePointDialogRoutePointCheckDialogProp, ISettingsRoutePointDialogRoutePointCheckDialogState> {
    private static orgStateHash: string = "";

    private static getHashDataFromState = (state: ISettingsRoutePointDialogRoutePointCheckDialogState): string => {
        const data = new SaveData();
        data.append("name", state.name);
        data.append("description", state.description);
        return data.hash;
    };

    constructor(props) {
        super(props);
        const item = props.item;
        this.state = {
            name: item.name,
            description: item.description
        };
        SettingsRoutePointDialogRoutePointCheckDialog.orgStateHash = SettingsRoutePointDialogRoutePointCheckDialog.getHashDataFromState(this.state);
    }

    // #region General
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "Nm") {
            this.setState({ name: value });
        } else if (name === "description") {
            this.setState({ description: value });
        }
    };
    // #endregion General

    private static validate = (state: ISettingsRoutePointDialogRoutePointCheckDialogState): boolean => {
        if (!state.name) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.NameMustBeDefined));
            return false;
        }
        return true;
    };

    handleOkClick = () => {
        const state = this.state;
        if (!SettingsRoutePointDialogRoutePointCheckDialog.validate(state)) return;
        const item = new RoutePointCheckEditItem(this.props.item);
        item.name = state.name;
        item.description = state.description;
        this.props.onOk(item);
    };

    handleCancelClick = () => {
        const obj = this;
        const hashData = SettingsRoutePointDialogRoutePointCheckDialog.getHashDataFromState(this.state);
        if (hashData !== SettingsRoutePointDialogRoutePointCheckDialog.orgStateHash) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.handleOkClick();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.props.onCancel();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));
        } else {
            obj.props.onCancel();
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "siteRoutePoint px600" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.RoutePointCheck + " - " + (!props.isNew ? props.item.name : Translations.New)}
                    show={true}
                    body={<div>
                        <div className="">
                            <div>
                                <div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group required">
                                                <label className="control-label smallFont">{Translations.Name}</label>
                                                <input type="text" className="form-control" name="Nm" title={Translations.Name} value={state.name} onChange={this.handleChange} maxLength={50} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="control-label smallFont">{Translations.Description}</label>
                                                <textarea className="form-control" name="description" title={Translations.WorkDescription} value={state.description} onChange={this.handleChange} maxLength={1000} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: this.handleOkClick },
                    ]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}

// SettingsRoutePointDialogRoutePointCheckListLine
export interface ISettingsRoutePointDialogRoutePointCheckListLineProp {
    item: IRoutePointCheckEditItem;
    selectedId: string;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

export class SettingsRoutePointDialogRoutePointCheckListLine extends React.Component<ISettingsRoutePointDialogRoutePointCheckListLineProp, {}> {
    render() {
        const item = this.props.item;
        return (
            <div className={"row line" + (item.id === this.props.selectedId ? " selected" : "")} onClick={() => { this.props.onClick(item.id); }} onDoubleClick={() => { this.props.onDoubleClick(item.id); }}
                title={item.name + ", " + (item.description ? item.description : "")}
            >
                <div className="col-6">{item.name}</div>
                <div className="col-6">{item.description}</div>
            </div>
        );
    }
}

// SettingsRoutePointDialogRoutePointCheckList
export interface ISettingsRoutePointDialogRoutePointCheckListProp {
    title?: string;
    titleStyles?: string;
    readOnly: boolean;
    items: IRoutePointCheckEditItem[];
    hideButtons?: boolean;
    onRemoveRoutePointCheck: (id: string) => void;
    onAddRoutePointCheck: (item: IRoutePointCheckEditItem) => void;
    onEditRoutePointCheck: (item: IRoutePointCheckEditItem) => void;
    onMoveRoutePointCheck: (id: string, step: number) => void;
}

interface ISettingsRoutePointDialogRoutePointCheckListState {
    selectedId: string;
    selectedItem: IRoutePointCheckEditItem;
    showItemDialog: boolean;
    showItemDialogForNew: boolean;
}

export class SettingsRoutePointDialogRoutePointCheckList extends React.Component<ISettingsRoutePointDialogRoutePointCheckListProp, ISettingsRoutePointDialogRoutePointCheckListState> {
    constructor(props) {
        super(props);
        this.state = { selectedId: null, selectedItem: null, showItemDialog: false, showItemDialogForNew: false };
    }

    handleAdd = () => {
        const props = this.props;
        if (props.readOnly) return;
        const selectedItem = new RoutePointCheckEditItem();
        selectedItem.id = Base.getGuid();
        selectedItem.number = props.items.length > 0 ? props.items.reduce((a, b) => { return Math.max(a, b.number); }, 0) + 1 : 1;
        this.setState({
            selectedItem: selectedItem,
            showItemDialog: true,
            showItemDialogForNew: true
        });
    };

    handleEdit = () => {
        const props = this.props;
        if (props.readOnly) return;
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        const selectedItem = props.items.find(i => i.id === selectedId);
        if (!selectedItem) return;
        this.setState({
            selectedItem: selectedItem,
            showItemDialog: true,
            showItemDialogForNew: false
        });
    };

    handleRoutePointDialogOk = (item: IRoutePointCheckEditItem) => {
        const props = this.props;
        const selectedItem = this.state.selectedItem;
        const showItemDialogForNew = this.state.showItemDialogForNew;
        this.setState({
            selectedId: selectedItem.id,
            showItemDialog: false,
            showItemDialogForNew: false
        });
        if (showItemDialogForNew) {
            props.onAddRoutePointCheck(item);
        } else {
            props.onEditRoutePointCheck(item);
        }
    };

    handleRoutePointDialogCancel = () => {
        this.setState({
            showItemDialog: false
        });
    };

    handleRemove = () => {
        const props = this.props;
        if (props.readOnly) return;
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        props.onRemoveRoutePointCheck(selectedId);
    };

    handleMoveUp = () => {
        const props = this.props;
        if (props.readOnly) return;
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        props.onMoveRoutePointCheck(selectedId, -1);
    };

    handleMoveDown = () => {
        const props = this.props;
        if (props.readOnly) return;
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        props.onMoveRoutePointCheck(selectedId, 1);
    };

    handleLineClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleLineDoubleClick = (id: string) => {
        this.handleEdit();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const items = props.items;
        return (
            <div>
                <div className="commandRow">
                    {props.title &&
                        <label className={"control-label listTitle" + (props.titleStyles ? " " + props.titleStyles : "")}>{props.title}</label>
                    }
                    {!props.hideButtons &&
                        <ToolButton
                            title={Translations.Remove}
                            enabled={!props.readOnly && !!state.selectedId}
                            classes={"round right remove"}
                            onClick={this.handleRemove}
                        />
                    }
                    {!props.hideButtons &&
                        <ToolButton
                            title={Translations.Edit}
                            enabled={!props.readOnly && !!state.selectedId}
                            classes={"round right edit"}
                            onClick={this.handleEdit}
                        />
                    }
                    {!props.hideButtons &&
                        <ToolButton
                            title={Translations.Add}
                            enabled={!props.readOnly}
                            classes={"round right add"}
                            onClick={this.handleAdd}
                        />
                    }
                    {!props.hideButtons &&
                        <ToolButton
                            title={Translations.MoveUp}
                            enabled={!props.readOnly && !!state.selectedId}
                            classes={"round right up"}
                            onClick={this.handleMoveUp}
                        />
                    }
                    {!props.hideButtons &&
                        <ToolButton
                            title={Translations.MoveDown}
                            enabled={!props.readOnly && !!state.selectedId}
                            classes={"round right down"}
                            onClick={this.handleMoveDown}
                        />
                    }
                </div>
                <div className="listContainer routePointChecksContainer">
                    {items.length > 0 &&
                        <div className="list routePointChecks">
                            {items.map((item) =>
                                <SettingsRoutePointDialogRoutePointCheckListLine
                                    key={item.id}
                                    item={item}
                                    selectedId={state.selectedId}
                                    onClick={this.handleLineClick}
                                    onDoubleClick={this.handleLineDoubleClick}
                                />
                            )}
                        </div>
                    }
                </div>
                {state.showItemDialog && state.selectedItem &&
                    <SettingsRoutePointDialogRoutePointCheckDialog
                        item={state.selectedItem}
                        isNew={state.showItemDialogForNew}
                        onOk={this.handleRoutePointDialogOk}
                        onCancel={this.handleRoutePointDialogCancel}
                    />
                }
            </div>
        );
    }
}