// WorkOrderEditRouteEditor - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as locationService from "../../services/locationService";
import * as workOrderService from "../../services/workOrderService";
import { Base } from "../../framework/base";
import { IIdTitle } from "../../models/common/idTitle";
import { IRoutePointItem, RoutePointItem, SaveRoutePointItem, } from "../../models/routePoint/routePointItem";
import { Button } from "../framework/button";
import { IWorkOrderEditContact } from "../../models/work/workOrderEditContact";
import { Translations } from "../../models/translations";
import { IRoutePointAutocompleteItem } from "../../models/routePoint/routePointAutocompleteItems";
import { SaveData } from "../../framework/saveData";
import { AppUtils } from "../../models/common/appUtils";
import { ConfirmationDialogResult, RoutePointTypeEnum } from "../../models/common/enums";
import { BeforeCapture, DragDropContext, DragStart, DragUpdate, Droppable, DropResult, ResponderProvided, Draggable } from "react-beautiful-dnd";
import { IStorageProductBooking } from "../../models/storage/storageProductBooking";
import { WorkShiftTimeSlotType, IWorkShiftTimeSlotType } from "../../models/workShiftTimeSlotType/workShiftTimeSlotType";
import { IRoutePointWorkShiftTimeSlotItem, RoutePointWorkShiftTimeSlotItem } from "../../models/routePoint/routePointWorkShiftTimeSlotItem";
import { IRoutePointRoutingData, IRoutePointRoutingResultData, WorkOrderEditRouteEditorRoutePointEdit } from "./workOrderEditRouteEditorRoutePointEdit";
import { RadioButton } from "../framework/radio";
import { IVehicleItem } from "../../models/vehicle/vehicleItem";
import { IEmployeeItem } from "../../models/employee/employeeItem";
import { IRouteTimeline, IRouteTimelineRoutePoint, RouteTimeline, RouteTimelineRoutePoint, RouteTimelineWorkShiftTimeSlot, WorkOrderEditRouteEditorRoutePointTimeLine } from "./workOrderEditRouteEditorRoutePointTimeLine";
import { WorkOrderEditRouteEditorPlannedRoutePhases, WorkOrderEditRouteEditorRoutePhases } from "./workOrderEditRouteEditorRoutePhases";

// WorkOrderEditRouteEditor
export interface IWorkOrderEditRouteEditorProp {
    classes?: string;
    isReadOnly?: boolean;
    plannedRoute?: boolean;
    detailsView?: boolean;
    workOrderIsProject: boolean;
    customerId: string;
    mapLinkTemplate: string;
    directionsMapLinkTemplate: string;
    workOrderId: string;
    routePointTypes: IIdTitle[];
    employees: IEmployeeItem[];
    vehicles: IVehicleItem[];
    contacts: IWorkOrderEditContact[];
    routePoints: IRoutePointItem[];
    productBookings: IStorageProductBooking[];
    routePointWorkShiftTimeSlotTypes: IWorkShiftTimeSlotType[];
    ownerRoutePoints: IRoutePointAutocompleteItem[];
    onSaveWorkOrder?: () => Promise<string>;
    onSetSelectedRoutePoint: (id: string) => void;
    onOwnerRoutePointsAdd: (routePoint: IRoutePointAutocompleteItem) => void;
    onRoutePointsModified: (routePoints: IRoutePointItem[], removedRoutePointIds: string[]) => void;
    onAddContact: (customerId: string, name: string, callback: (contactId: string) => void) => void;
    onAttachContact: (customerId: string, name: string, callback: (contactId: string) => void) => void;
    onAddContactFromRoutePointCopy: (contactId: string) => void;
    onEditContact: (customerId: string, siteId: string) => void;
    onChangeRunningWorkShiftTimeSlot?: (routePointId: string, startWorkShiftTimeSlotTypeId: string) => void;
}

export interface IWorkOrderEditRouteEditorState {
    plannedRoute: boolean;
    routeTimeline: IRouteTimeline;
    routePoints: IRoutePointItem[];
    mainDivWidth: number;
    selectedRoutePointId: string;
    editTimelineRoutePoint: IRouteTimelineRoutePoint;
    editTimelineRoutePointIndex: number;
    //DragAndDrop
    draggingIsStarting: boolean;
    draggingRoutePoint: boolean;
    draggedRoutePointDestinationInvalid: boolean;
    droppingIsDisabled: boolean;
}

export class WorkOrderEditRouteEditor extends React.Component<IWorkOrderEditRouteEditorProp, IWorkOrderEditRouteEditorState> {
    private mainDiv: HTMLDivElement;
    private dragContainerHeight = 0;

    getPlannedRouteTimeline = (routePoints: IRoutePointItem[]): IRouteTimeline => {
        const props = this.props;
        const transferWorkShiftTimeSlotTypeIds = WorkShiftTimeSlotType.getRoutePointTransferWorkShiftTimeSlotTypeIds(props.routePointWorkShiftTimeSlotTypes);
        //Planned route
        const nowTime = new Date().getTime();
        let firstPlannedRoutePoint = true;
        const timeLineRoutePoints: IRouteTimelineRoutePoint[] = [];
        for (const routePoint of routePoints) {
            const timeLineRoutePoint = RouteTimelineRoutePoint.createRouteTimelineRoutePoint(routePoint, props.routePointTypes, props.contacts);
            timeLineRoutePoint.id = routePoint.id;
            timeLineRoutePoint.planned = true;
            const activeWorkShiftTimeSlot = timeLineRoutePoint.routePoint.getActiveWorkShiftTimeSlot();
            timeLineRoutePoint.visited = timeLineRoutePoint.routePoint.workShiftTimeSlots.length > 0 && !activeWorkShiftTimeSlot;
            timeLineRoutePoint.active = timeLineRoutePoint.routePoint.workShiftTimeSlots.length > 0 && !!activeWorkShiftTimeSlot;
            timeLineRoutePoint.productsToFetch = props.productBookings.filter((productBooking) =>
                productBooking.fetchRoutePointId === routePoint.id);
            timeLineRoutePoint.productsToDeliver = props.productBookings.filter((productBooking) =>
                productBooking.deliveryRoutePointId === routePoint.id);
            //Add WorkShiftTimeSlots so planned route can show active and visited route points
            const workShiftTimeSlots = timeLineRoutePoint.routePoint.workShiftTimeSlots.slice(0);
            RoutePointWorkShiftTimeSlotItem.sortRoutePointWorkShiftTimeSlotItems(workShiftTimeSlots);
            for (const workShiftTimeSlot of workShiftTimeSlots) {
                const workShiftTimeSlotType = props.routePointWorkShiftTimeSlotTypes.find(i => i.id === workShiftTimeSlot.workShiftTimeSlotTypeId);
                const timelineWorkShiftTimeSlot = new RouteTimelineWorkShiftTimeSlot();
                timelineWorkShiftTimeSlot.workShiftTimeSlot = workShiftTimeSlot;
                timelineWorkShiftTimeSlot.workShiftTimeSlotTypeName = workShiftTimeSlotType ? workShiftTimeSlotType.getTitle() : "";
                const endTime = workShiftTimeSlot.endTime ? workShiftTimeSlot.endTime : nowTime;
                timelineWorkShiftTimeSlot.durationMin = Math.max(0, Base.dateDiffInMinutes(workShiftTimeSlot.startTime, endTime));
                timeLineRoutePoint.timelineWorkShiftTimeSlots.push(timelineWorkShiftTimeSlot);
                if (!workShiftTimeSlot.endTime && !activeWorkShiftTimeSlot) {
                    timeLineRoutePoint.active = true;
                    timelineWorkShiftTimeSlot.active = true;
                }
            }
            timeLineRoutePoints.push(timeLineRoutePoint);
            if (firstPlannedRoutePoint) {
                timeLineRoutePoint.clearPlannedData();
            }
            firstPlannedRoutePoint = false;
        }
        //Return result
        const result = new RouteTimeline();
        result.transferWorkShiftTimeSlotTypeIds = transferWorkShiftTimeSlotTypeIds;
        result.timelineRoutePoints = timeLineRoutePoints;
        return result;
    };

    getRealizedRouteTimeline = (routePoints: IRoutePointItem[]): IRouteTimeline => {
        const props = this.props;
        let activeWorkShiftTimeSlot: IRoutePointWorkShiftTimeSlotItem = null;
        let workShiftTimeSlots: IRoutePointWorkShiftTimeSlotItem[] = [];
        const nowTime = new Date().getTime();
        const transferWorkShiftTimeSlotTypeIds = WorkShiftTimeSlotType.getRoutePointTransferWorkShiftTimeSlotTypeIds(props.routePointWorkShiftTimeSlotTypes);
        //Planned route
        const plannedRoutePoints: IRouteTimelineRoutePoint[] = [];
        for (const routePoint of routePoints) {
            const timeLineRoutePoint = RouteTimelineRoutePoint.createRouteTimelineRoutePoint(routePoint, props.routePointTypes, props.contacts);
            timeLineRoutePoint.id = routePoint.id;
            timeLineRoutePoint.planned = true;
            timeLineRoutePoint.productsToFetch = props.productBookings.filter((productBooking) =>
                productBooking.fetchRoutePointId === timeLineRoutePoint.routePoint.id);
            timeLineRoutePoint.productsToDeliver = props.productBookings.filter((productBooking) =>
                productBooking.deliveryRoutePointId === timeLineRoutePoint.routePoint.id);
            plannedRoutePoints.push(timeLineRoutePoint);
            workShiftTimeSlots = workShiftTimeSlots.concat(timeLineRoutePoint.routePoint.workShiftTimeSlots);
        }
        //Realized route
        const realizedRoutePoints: IRouteTimelineRoutePoint[] = [];
        RoutePointWorkShiftTimeSlotItem.sortRoutePointWorkShiftTimeSlotItems(workShiftTimeSlots);
        const usedPlannedRoutePointIds: string[] = [];
        let previousTimeLineRoutePoint: IRouteTimelineRoutePoint = null;
        let previousWorkShiftTimeSlot: IRoutePointWorkShiftTimeSlotItem = null;
        for (const workShiftTimeSlot of workShiftTimeSlots) {
            let timeLineRoutePoint: IRouteTimelineRoutePoint;
            if (previousWorkShiftTimeSlot && previousWorkShiftTimeSlot.routePointId === workShiftTimeSlot.routePointId && transferWorkShiftTimeSlotTypeIds.indexOf(workShiftTimeSlot.workShiftTimeSlotTypeId) < 0) {
                timeLineRoutePoint = previousTimeLineRoutePoint;
            } else {
                const routePointIndex = routePoints.findIndex(i => i.id === workShiftTimeSlot.routePointId);
                if (routePointIndex < 0) continue;
                const routePoint = routePoints[routePointIndex];
                usedPlannedRoutePointIds.push(routePoint.id);
                timeLineRoutePoint = RouteTimelineRoutePoint.createRouteTimelineRoutePoint(routePoint, props.routePointTypes, props.contacts);
                timeLineRoutePoint.id = workShiftTimeSlot.id;
                timeLineRoutePoint.productsToFetch = props.productBookings.filter((productBooking) =>
                    productBooking.fetchRoutePointId === routePoint.id);
                timeLineRoutePoint.productsToDeliver = props.productBookings.filter((productBooking) =>
                    productBooking.deliveryRoutePointId === routePoint.id);
                realizedRoutePoints.push(timeLineRoutePoint);
            }
            const workShiftTimeSlotType = props.routePointWorkShiftTimeSlotTypes.find(i => i.id === workShiftTimeSlot.workShiftTimeSlotTypeId);
            const timelineWorkShiftTimeSlot = new RouteTimelineWorkShiftTimeSlot();
            timelineWorkShiftTimeSlot.workShiftTimeSlot = workShiftTimeSlot;
            timelineWorkShiftTimeSlot.workShiftTimeSlotTypeName = workShiftTimeSlotType ? workShiftTimeSlotType.getTitle() : "";
            const endTime = workShiftTimeSlot.endTime ? workShiftTimeSlot.endTime : nowTime;
            timelineWorkShiftTimeSlot.durationMin = Math.max(0, Base.dateDiffInMinutes(workShiftTimeSlot.startTime, endTime));
            timeLineRoutePoint.timelineWorkShiftTimeSlots.push(timelineWorkShiftTimeSlot);
            if (!workShiftTimeSlot.endTime && !activeWorkShiftTimeSlot) {
                timeLineRoutePoint.active = true;
                timelineWorkShiftTimeSlot.active = true;
                activeWorkShiftTimeSlot = workShiftTimeSlot;
            }
            previousTimeLineRoutePoint = timeLineRoutePoint;
            previousWorkShiftTimeSlot = workShiftTimeSlot;
        }
        //Unused planned route points
        let unusedPlannedRoutePoints = plannedRoutePoints.filter(i => usedPlannedRoutePointIds.indexOf(i.routePoint.id) < 0);
        const unusedBeginRoutePoints: IRouteTimelineRoutePoint[] = []; //Route points fixed to be in start of realized route
        for (const unusedPlannedRoutePoint of unusedPlannedRoutePoints) {
            if (unusedPlannedRoutePoint.routePoint.routePointTypeCode === RoutePointTypeEnum.Begin) {
                unusedPlannedRoutePoint.begin = true;
                unusedBeginRoutePoints.push(unusedPlannedRoutePoint);
            }
        }
        const unusedBeginRoutePointIds = unusedBeginRoutePoints.map(i => i.routePoint.id);
        unusedPlannedRoutePoints = unusedPlannedRoutePoints.filter(i => unusedBeginRoutePointIds.indexOf(i.routePoint.id) < 0);
        //Clear planned data if mutual order of consecutive route points is different from planned order
        const timelineRoutePoints = unusedBeginRoutePoints.concat(realizedRoutePoints).concat(unusedPlannedRoutePoints);
        if (timelineRoutePoints.length > 0) {
            timelineRoutePoints[0].clearPlannedData();
            let prevIndex = routePoints.findIndex(i => i.id === timelineRoutePoints[0].routePoint.id);
            for (let i = 1; i < timelineRoutePoints.length; i++) {
                const thisIndex = routePoints.findIndex(j => j.id === timelineRoutePoints[i].routePoint.id);
                if (prevIndex !== thisIndex - 1) {
                    timelineRoutePoints[i].clearPlannedData();
                }
                prevIndex = thisIndex;
            }
        }
        //Return result
        const result = new RouteTimeline();
        result.transferWorkShiftTimeSlotTypeIds = transferWorkShiftTimeSlotTypeIds;
        result.timelineRoutePoints = timelineRoutePoints;
        result.activeWorkShiftTimeSlotTypeId = activeWorkShiftTimeSlot ? activeWorkShiftTimeSlot.workShiftTimeSlotTypeId : "";
        result.activeWorkShiftTimeSlot = activeWorkShiftTimeSlot;
        return result;
    };

    getRouteTimeline = (routePoints: IRoutePointItem[], plannedRoute: boolean): IRouteTimeline => {
        return plannedRoute
            ? this.getPlannedRouteTimeline(routePoints)
            : this.getRealizedRouteTimeline(routePoints);
    };

    constructor(props: IWorkOrderEditRouteEditorProp) {
        super(props);
        const plannedRoute = props.plannedRoute ?? true;
        this.state = {
            plannedRoute,
            routePoints: props.routePoints.slice(0),
            routeTimeline: this.getRouteTimeline(props.routePoints, plannedRoute),
            selectedRoutePointId: "",
            editTimelineRoutePoint: null,
            editTimelineRoutePointIndex: -1,
            mainDivWidth: 0,
            draggingIsStarting: false,
            draggingRoutePoint: false,
            draggedRoutePointDestinationInvalid: false,
            droppingIsDisabled: false,
        };
    }

    updateMainDivWidth = () => {
        if (!this.mainDiv) return;
        const state = this.state;
        const mainDivRect = this.mainDiv.getBoundingClientRect();
        const mainDivWidth = mainDivRect.width;
        if (state.mainDivWidth !== mainDivWidth) {
            this.setState({ mainDivWidth: mainDivWidth });
        }
    };

    componentDidMount(): void {
        this.updateMainDivWidth();
        window.addEventListener("resize", this.updateMainDivWidth);
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.updateMainDivWidth);
    }

    componentDidUpdate(prevProps: IWorkOrderEditRouteEditorProp, prevState: IWorkOrderEditRouteEditorState): void {
        const props = this.props;
        const state = this.state;
        if (RoutePointItem.getHash(prevProps.routePoints) === RoutePointItem.getHash(props.routePoints) &&
            JSON.stringify(prevProps.productBookings) === JSON.stringify(props.productBookings)) return; //If IStorageProductBooking would have rowId, saved changes could be detected by EditItem.getHash
        const routeTimeline = this.getRouteTimeline(props.routePoints, state.plannedRoute);
        this.setState({
            routePoints: props.routePoints.slice(0),
            routeTimeline,
            editTimelineRoutePoint: state.editTimelineRoutePointIndex >= 0 && state.editTimelineRoutePointIndex < routeTimeline.timelineRoutePoints.length
                ? routeTimeline.timelineRoutePoints[state.editTimelineRoutePointIndex]
                : null
        });
    }

    handleSetRouteTypePlanned = (plannedRoute: boolean) => {
        this.setState({
            plannedRoute,
            routeTimeline: this.getRouteTimeline(this.props.routePoints, plannedRoute)
        });
    };

    // #region Location and routing
    routeFromPreviousPoint = async(data: IRoutePointRoutingData): Promise<IRoutePointRoutingResultData> => {
        if (!data ||
            !data.previousRoutePoint ||
            !data.routePoint ||
            !data.previousRoutePoint.latitude ||
            !data.previousRoutePoint.longitude ||
            !data.routePoint.latitude ||
            !data.routePoint.longitude) {
            return null;
        }
        const routeResult = await locationService.route(data.previousRoutePoint.latitude,
            data.previousRoutePoint.longitude,
            data.routePoint.latitude,
            data.routePoint.longitude);
        if (!routeResult) return null;
        return {
            requestData: data,
            resultData: routeResult
        };
    };
    // #endregion Location and routing

    handleSetSelectedRoutePoint = (id: string) => {
        this.setState({ selectedRoutePointId: id });
        if (this.props.onSetSelectedRoutePoint) {
            this.props.onSetSelectedRoutePoint(id);
        }
    };

    handleAdd = () => {
        const props = this.props;
        const state = this.state;
        const routePoint = new RoutePointItem();
        routePoint.id = Base.getGuid();
        routePoint.number = state.routePoints.length > 0 ? state.routePoints.reduce((a, b) => { return Math.max(a, b.number); }, 0) + 1 : 1;
        routePoint.routePointTypeId = props.routePointTypes[Math.min(props.routePointTypes.length - 1, Math.max(0, state.routePoints.length))].id;
        this.setState({
            editTimelineRoutePointIndex: state.routePoints.length
        });
        props.onRoutePointsModified([routePoint], []);
        this.handleSetSelectedRoutePoint(routePoint.id);
    };

    saveRoutePointsToDb = async(routePoints: IRoutePointItem[]): Promise<boolean> => {
        const props = this.props;
        //Save work order if required
        let workOrderId = props.workOrderId;
        if (props.onSaveWorkOrder) {
            workOrderId = await props.onSaveWorkOrder();
            if (!workOrderId) {
                return false;
            }
        }
        //Create savedata
        const saveData = new SaveData();
        saveData.append("id", workOrderId);
        saveData.append("routePoints", JSON.stringify(routePoints.reduce((result, i) => {
            result.push(new SaveRoutePointItem(i));
            return result;
        }, [])));
        saveData.append("workShiftTimeSlotRoutePointIds", "");
        saveData.append("workShiftTimeSlots", "");
        //Save to db
        const result = await AppUtils.callService(() => workOrderService.saveWorkOrderRoutePoints(saveData.formData));
        if (!result) return false;
        //Return result
        const newRoutePoints: IRoutePointItem[] = [];
        const removedRoutePointIds = routePoints.map(i => i.id);
        for (let i = 0; i < routePoints.length; i++) {
            const newRoutePoint = new RoutePointItem(routePoints[i]);
            newRoutePoint.id = result.ids[i];
            newRoutePoint.rowId = result.rowIds[i];
            newRoutePoints.push(newRoutePoint);
        }
        props.onRoutePointsModified(newRoutePoints, removedRoutePointIds);
        return true;
    };

    routeAndSaveRoutePointsToDb = (routingData: IRoutePointRoutingData[]): Promise<boolean> => {
        const routePoints = routingData.map(i => i.routePoint).filter((v, i, a) => a.indexOf(v) === i);
        if (routePoints.length < 1) return Base.getPromiseResult(true);
        return routingData.filter(i => i.previousRoutePoint).reduce((promise, data) => promise
            .then((success) => {
                if (success) {
                    success.requestData.routePoint.fromPreviousDistanceM = success.resultData.distanceM;
                    success.requestData.routePoint.fromPreviousDurationS = success.resultData.durationS;
                }
                return this.routeFromPreviousPoint(data);
            }), Base.getPromiseResult<IRoutePointRoutingResultData>(null))
            .then((success: IRoutePointRoutingResultData) => {
                if (success) {
                    success.requestData.routePoint.fromPreviousDistanceM = success.resultData.distanceM;
                    success.requestData.routePoint.fromPreviousDurationS = success.resultData.durationS;
                }
                return this.saveRoutePointsToDb(routePoints);
            });
    };

    handleSaveRoutePoint = async(routePoint: IRoutePointItem): Promise<boolean> => {
        const state = this.state;
        const index = state.routePoints.findIndex(i => i.id === routePoint.id);
        if (index < 0) return;
        const previousRoutePoint = index > 0 ? state.routePoints[index - 1] : null;
        const nextRoutePoint = index < state.routePoints.length - 1 ? state.routePoints[index + 1] : null;
        const routingData: IRoutePointRoutingData[] = [];
        routingData.push({ previousRoutePoint: previousRoutePoint, routePoint: routePoint });
        if (nextRoutePoint) {
            routingData.push({ previousRoutePoint: routePoint, routePoint: nextRoutePoint });
        }
        return await this.routeAndSaveRoutePointsToDb(routingData);
    };

    removeRoutePointFromState = (id: string) => {
        this.setState({
            editTimelineRoutePoint: null,
            editTimelineRoutePointIndex: -1
        });
        this.handleSetSelectedRoutePoint("");
        this.props.onRoutePointsModified([], [id]);
    };

    removeRoutePointFromDb = async(id: string): Promise<boolean> => {
        const props = this.props;
        //Create savedata
        const routePointIds = [id];
        const saveData = new SaveData();
        saveData.append("id", props.workOrderId);
        saveData.append("routePointIds", JSON.stringify(routePointIds));
        //Save to db
        const result = await AppUtils.callService(() => workOrderService.removeWorkOrderRoutePoints(saveData.formData));
        if (!result) return;
        this.removeRoutePointFromState(id);
        return !!result;
    };

    handleRemoveRoutePoint = async(id: string) => {
        const state = this.state;
        if (!id) return;
        const index = state.routePoints.findIndex(i => i.id === id);
        if (index < 0) return;
        const routePoint = state.routePoints[index];
        if (!routePoint) return;
        if (!routePoint.isNew()) {
            const confirmationResult = await AppUtils.showConfirmationDialog(String.format(Translations.RoutePointRemoveConfirmation, routePoint.name));
            if (confirmationResult !== ConfirmationDialogResult.Yes) return;
        }
        //Remove only from state
        if (routePoint.isNew()) {
            this.removeRoutePointFromState(id);
            return;
        }
        //Remove from db
        const prevRoutePoint = index > 0 ? new RoutePointItem(state.routePoints[index - 1]) : null;
        const nextRoutePoint = index < state.routePoints.length - 1 ? new RoutePointItem(state.routePoints[index + 1]) : null;
        const removeResult = await this.removeRoutePointFromDb(id);
        if (!removeResult) return;
        this.handleSetSelectedRoutePoint("");
        //Update next route point distance and duration
        if (nextRoutePoint) {
            nextRoutePoint.fromPreviousDistanceM = null;
            nextRoutePoint.fromPreviousDurationS = null;
            if (prevRoutePoint) {
                const routeResult = await this.routeFromPreviousPoint({ previousRoutePoint: prevRoutePoint, routePoint: nextRoutePoint });
                nextRoutePoint.fromPreviousDistanceM = routeResult.resultData.distanceM;
                nextRoutePoint.fromPreviousDurationS = routeResult.resultData.durationS;
            }
            this.saveRoutePointsToDb([nextRoutePoint]);
        }
    };

    moveRoutePoints = async(sourceIndex: number, destinationIndex: number) => {
        const state = this.state;
        const orgRoutePoints = state.routePoints.slice(0);
        if (Base.isNullOrUndefined(sourceIndex) || Base.isNullOrUndefined(sourceIndex) || sourceIndex < 0 || destinationIndex < 0 ||
            sourceIndex > orgRoutePoints.length - 1 || destinationIndex > orgRoutePoints.length - 1 || sourceIndex === destinationIndex) return;
        const orgRoutePointRelations = orgRoutePoints.map((i, index) => (index > 0 ? orgRoutePoints[index - 1].id : "") + "#" + i.id);
        const orgRoutePointNumbers = orgRoutePoints.map((i) => i.number + "#" + i.id);
        //RoutePoints
        const routePoints = Base.moveArrayItem(orgRoutePoints, sourceIndex, destinationIndex).map(i => new RoutePointItem(i));
        for (let i = 0; i < routePoints.length; i++) {
            routePoints[i].number = i + 1;
        }
        //Update state so drag result remains in ui
        this.setState({
            routePoints: routePoints,
            routeTimeline: this.getRouteTimeline(routePoints, state.plannedRoute)
        });
        //Set routing data
        const routingData: IRoutePointRoutingData[] = [];
        const newRoutePointRelations = routePoints.map((i, index) => (index > 0 ? routePoints[index - 1].id : "") + "#" + i.id);
        const newRoutePointNumbers = routePoints.map((i) => i.number + "#" + i.id);
        for (let i = 0; i < newRoutePointRelations.length; i++) {
            const routePoint = routePoints[i];
            const relationChanged = orgRoutePointRelations.indexOf(newRoutePointRelations[i]) < 0;
            const numberChanged = orgRoutePointNumbers.indexOf(newRoutePointNumbers[i]) < 0;
            if (!relationChanged && !numberChanged) continue;
            if (i === 0) {
                routePoint.fromPreviousDurationS = null;
                routePoint.fromPreviousDistanceM = null;
            }
            routingData.push({ previousRoutePoint: i > 0 && relationChanged ? routePoints[i - 1] : null, routePoint: routePoint });
        }
        //Recalculate distances to next and save all route points
        const saveResult = await this.routeAndSaveRoutePointsToDb(routingData);
        if (saveResult) return;
        //Reverse state to original is save failed
        this.setState({
            routePoints: orgRoutePoints,
            routeTimeline: this.getRouteTimeline(orgRoutePoints, state.plannedRoute)
        });
    };

    // #region WorkShiftTimeSlots
    handleWorkShiftTimeSlotsModified = (modifiedWorkShiftTimeSlots: IRoutePointWorkShiftTimeSlotItem[],
        removedWorkShiftTimeSlotIds: string[]) => {
        const props = this.props;
        if (modifiedWorkShiftTimeSlots.length < 1 && removedWorkShiftTimeSlotIds.length < 1) return;
        const modifiedRoutePointIds = modifiedWorkShiftTimeSlots.map(i => i.routePointId);
        const modifiedRoutePoints: IRoutePointItem[] = [];
        for (const routePoint of this.state.routePoints) {
            if (!routePoint.workShiftTimeSlots.find(i => removedWorkShiftTimeSlotIds.indexOf(i.id) > -1) &&
                modifiedRoutePointIds.indexOf(routePoint.id) < 0) {
                continue;
            }
            const modifiedRoutePoint = new RoutePointItem(routePoint);
            modifiedRoutePoint.workShiftTimeSlots = routePoint.workShiftTimeSlots.filter(i => removedWorkShiftTimeSlotIds.indexOf(i.id) < 0)
                .concat(modifiedWorkShiftTimeSlots.filter(i => i.routePointId === routePoint.id));
            RoutePointWorkShiftTimeSlotItem.sortRoutePointWorkShiftTimeSlotItems(modifiedRoutePoint.workShiftTimeSlots);
            modifiedRoutePoints.push(modifiedRoutePoint);
        }
        props.onRoutePointsModified(modifiedRoutePoints, modifiedRoutePoints.map(i => i.id));
    };
    // #endregion WorkShiftTimeSlots

    // #region DragAndDrop
    handlRoutePointBeforeCapture = (before: BeforeCapture) => {
        if (this.mainDiv) {
            const mainDivRect = this.mainDiv.getBoundingClientRect();
            this.dragContainerHeight = mainDivRect.height;
        }
        this.setState({
            draggingIsStarting: true
        });
    };

    handlRoutePointDragStart = (initial: DragStart, provided: ResponderProvided) => {
        this.setState({
            draggingRoutePoint: true,
            draggedRoutePointDestinationInvalid: false,
            droppingIsDisabled: this.props.isReadOnly
        });
    };

    handleRoutePointDragUpdate = (initial: DragUpdate, provided: ResponderProvided) => {
        const state = this.state;
        if (!initial.destination) return;
        const dragDestinationIndex = initial.destination.index;
        const lastReadOnlyIndex = state.routePoints.map(i => i.workShiftTimeSlots.length > 0).lastIndexOf(true);
        const oldDroppingIsDisabled = state.droppingIsDisabled;
        const droppingIsDisabled = this.props.isReadOnly || dragDestinationIndex <= lastReadOnlyIndex;
        if (oldDroppingIsDisabled !== droppingIsDisabled) {
            this.setState({
                droppingIsDisabled: droppingIsDisabled
            });
        }
        const oldDraggedRoutePointDestinationInvalid = state.draggedRoutePointDestinationInvalid;
        const draggedRoutePointDestinationInvalid = state.draggingRoutePoint && !initial.destination;
        if (oldDraggedRoutePointDestinationInvalid === draggedRoutePointDestinationInvalid) return;
        this.setState({
            draggedRoutePointDestinationInvalid: draggedRoutePointDestinationInvalid
        });
        Base.setNotAllowedCursor(draggedRoutePointDestinationInvalid);
    };

    handleRoutePointDragEnd = (result: DropResult, provided: ResponderProvided) => {
        this.setState({
            draggingRoutePoint: false,
            draggingIsStarting: false
        });
        Base.setNotAllowedCursor(false);
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        this.moveRoutePoints(result.source.index, result.destination.index);
    };

    getStyle = (isDragging: boolean, draggableStyle: any) => {
        return {
            // some basic styles to make the items look a bit nicer
            userSelect: "none",
            // styles we need to apply on draggables
            ...draggableStyle
        };
    };
    // #endregion DragAndDrop

    // #region Editing
    handleOpenEditor = (timelineRoutePoint: IRouteTimelineRoutePoint) => {
        const editTimelineRoutePointIndex = this.state.routeTimeline.timelineRoutePoints.findIndex(i => i.id === timelineRoutePoint.id);
        if (editTimelineRoutePointIndex < 0) return;
        this.setState({
            editTimelineRoutePoint: timelineRoutePoint,
            editTimelineRoutePointIndex
        });
    };

    handleCloseEditor = () => {
        this.setState({
            editTimelineRoutePoint: null,
            editTimelineRoutePointIndex: -1
        });
    };
    // #endregion Editing

    render() {
        const props = this.props;
        const state = this.state;
        const timelineRoutePoints = state.routeTimeline.timelineRoutePoints;
        const editPrefixRoutePoints = state.editTimelineRoutePoint
            ? timelineRoutePoints.slice(0, state.editTimelineRoutePointIndex)
            : timelineRoutePoints;
        const editSuffixRoutePoints = state.editTimelineRoutePoint && state.editTimelineRoutePointIndex < timelineRoutePoints.length - 1
            ? timelineRoutePoints.slice(state.editTimelineRoutePointIndex + 1)
            : [];
        return (
            <div className={"workOrderRouteEditor" + (props.classes ? " " + props.classes : "")}>
                {props.routePointWorkShiftTimeSlotTypes.length > 0 &&
                    <div className="workOrder routePointListModeContainer">
                        <div className="btn-group btn-group-toggle states">
                            <RadioButton
                                classes={"btn-secondary"}
                                title={Translations.Planned}
                                enabled={true}
                                checked={state.plannedRoute}
                                onRadioClick={() => this.handleSetRouteTypePlanned(true)}
                            />
                            <RadioButton
                                classes={"btn-secondary"}
                                title={Translations.Realized}
                                enabled={true}
                                checked={!state.plannedRoute}
                                onRadioClick={() => this.handleSetRouteTypePlanned(false)}
                            />
                        </div>
                    </div>
                }
                {!props.isReadOnly && state.plannedRoute && !props.detailsView &&
                    <div className="commandRow">
                        <Button
                            icon="add blue"
                            title={Translations.RoutePoint}
                            classes="rnd btn-default"
                            enabled={!props.isReadOnly}
                            onClick={this.handleAdd}
                        />
                    </div>
                }
                <div className="workOrder workOrderRouteEditorRoutePointContainer workOrderRouteTimelineContainer" ref={(div) => { this.mainDiv = div; }}
                    style={state.draggingIsStarting && this.dragContainerHeight ? { height: this.dragContainerHeight.toString(10) + "px" } : null}
                >
                    <DragDropContext onBeforeCapture={this.handlRoutePointBeforeCapture} onDragStart={this.handlRoutePointDragStart} onDragEnd={this.handleRoutePointDragEnd} onDragUpdate={this.handleRoutePointDragUpdate}>
                        <Droppable droppableId="plannedRoutePoints" type="plannedRoutePoint" isDropDisabled={state.droppingIsDisabled}>
                            {(provided, snapshot) => (
                                <div className={"routePoints" + (snapshot.isDraggingOver ? " isDraggingOver" : "")} {...provided.droppableProps} ref={provided.innerRef}>
                                    {editPrefixRoutePoints.map((timelineRoutePoint, index) =>
                                        <Draggable key={timelineRoutePoint.id} draggableId={timelineRoutePoint.id} index={index}
                                            isDragDisabled={props.isReadOnly || timelineRoutePoint.routePoint.workShiftTimeSlots.length > 0 || !!state.editTimelineRoutePoint || !state.plannedRoute}
                                        >
                                            {(provided, snapshot) => (
                                                <div className={(props.isReadOnly ? " disabled" : "") + (snapshot.isDragging ? (" dragged" + (snapshot.draggingOver !== "plannedRoutePoints" ? " invalid" : "")) : "")}
                                                    onClick={() => this.handleSetSelectedRoutePoint(timelineRoutePoint.routePoint.id)}
                                                    ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={this.getStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                >
                                                    <WorkOrderEditRouteEditorRoutePointTimeLine
                                                        routePointTransferWorkShiftTimeSlotTypeIds={state.routeTimeline.transferWorkShiftTimeSlotTypeIds}
                                                        selectedRoutePointId={state.selectedRoutePointId}
                                                        timelineRoutePoint={timelineRoutePoint}
                                                        hasPreviousRoutePoint={index > 0}
                                                        hasNextRoutePoint={index < timelineRoutePoints.length - 1}
                                                        plannedRoute={state.plannedRoute}
                                                        onEditRoutePoint={() => this.handleOpenEditor(timelineRoutePoint)}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    )}
                                    {state.editTimelineRoutePoint &&
                                        <WorkOrderEditRouteEditorRoutePointEdit
                                            workOrderId={props.workOrderId}
                                            customerId={props.customerId}
                                            containerWidth={state.mainDivWidth}
                                            isReadOnly={props.isReadOnly}
                                            workOrderIsProject={props.workOrderIsProject}
                                            plannedRoute={state.plannedRoute}
                                            timelineRoutePoint={state.editTimelineRoutePoint}
                                            transferWorkShiftTimeSlotTypeIds={state.routeTimeline.transferWorkShiftTimeSlotTypeIds}
                                            ownerRoutePoints={props.ownerRoutePoints}
                                            mapLinkTemplate={props.mapLinkTemplate}
                                            routePointTypes={props.routePointTypes}
                                            contacts={props.contacts}
                                            workShiftTimeSlotTypes={props.routePointWorkShiftTimeSlotTypes}
                                            employees={props.employees}
                                            vehicles={props.vehicles}
                                            hasPreviousRoutePoint={state.editTimelineRoutePointIndex > 0}
                                            hasNextRoutePoint={state.editTimelineRoutePointIndex < timelineRoutePoints.length - 1}
                                            onOwnerRoutePointsAdd={props.onOwnerRoutePointsAdd}
                                            onRemoveRoutePoint={this.handleRemoveRoutePoint}
                                            onSaveRoutePoint={this.handleSaveRoutePoint}
                                            onWorkShiftTimeSlotsModified={this.handleWorkShiftTimeSlotsModified}
                                            onAddContact={props.onAddContact}
                                            onAttachContact={props.onAttachContact}
                                            onAddContactFromRoutePointCopy={props.onAddContactFromRoutePointCopy}
                                            onEditContact={props.onEditContact}
                                            onClose={this.handleCloseEditor}
                                        />
                                    }
                                    {editSuffixRoutePoints.map((timelineRoutePoint, index) =>
                                        <WorkOrderEditRouteEditorRoutePointTimeLine
                                            key={timelineRoutePoint.id}
                                            routePointTransferWorkShiftTimeSlotTypeIds={state.routeTimeline.transferWorkShiftTimeSlotTypeIds}
                                            selectedRoutePointId={state.selectedRoutePointId}
                                            timelineRoutePoint={timelineRoutePoint}
                                            hasPreviousRoutePoint={editPrefixRoutePoints.length + 1 + index > 0}
                                            hasNextRoutePoint={editPrefixRoutePoints.length + 1 + index < timelineRoutePoints.length - 1}
                                            plannedRoute={state.plannedRoute}
                                            onEditRoutePoint={() => this.handleOpenEditor(timelineRoutePoint)}
                                        />
                                    )}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
                {state.routePoints.length > 1 && state.plannedRoute &&
                    <WorkOrderEditRouteEditorPlannedRoutePhases
                        mapLinkTemplate={props.mapLinkTemplate}
                        directionsMapLinkTemplate={props.directionsMapLinkTemplate}
                        routePoints={state.routePoints}
                    />
                }
                {state.routePoints.length > 1 && !state.plannedRoute &&
                    <WorkOrderEditRouteEditorRoutePhases
                        routePoints={state.routePoints}
                        routePointWorkShiftTimeSlotTypes={props.routePointWorkShiftTimeSlotTypes}
                    />
                }
            </div>
        );
    }
}
