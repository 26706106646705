import React, { useMemo } from "react";
import { WorkShiftDto } from "../../models/workShift/workShift";
import { formatTime } from "../workTimeBeta/workTimeDetailsList";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { getWorkTimeTypeColorClass } from "../../models/workShitTimeSlot/workTimeType";
import { Base } from "../../framework/base";
import { WorkShiftTimeSlotItem } from "../../models/workShitTimeSlot/workShiftTimeSlotItem";
import { MonitorViewSidebar } from "./monitorViewSidebar";
import { useTranslation } from "react-i18next";

interface MonitorViewWorkShiftSidebarProps {
    workShift: WorkShiftDto;
    onClose: () => void;
    selectedWorkShiftTimeSlotId: string;
    setSelectedWorkShiftTimeSlotId: (id: string) => void;
    setHoveredWorkShiftTimeSlotId: (id: string) => void;
}

export const MonitorViewWorkShiftSidebar = ({
    workShift,
    onClose,
    selectedWorkShiftTimeSlotId,
    setSelectedWorkShiftTimeSlotId,
    setHoveredWorkShiftTimeSlotId,
}: MonitorViewWorkShiftSidebarProps) => {
    const { workShiftTimeSlots, effectiveDate } = workShift;

    const headerLabel = useMemo(
        () =>
            `${workShiftTimeSlots[0]?.employeeName} ${Base.dayjsToDateStr(
                effectiveDate
            )}`,
        [workShiftTimeSlots, effectiveDate]
    );

    return (
        <MonitorViewSidebar
            header={headerLabel}
            buttons={[{ key: "close", icon: <CloseIcon />, onClick: onClose }]}
        >
            <List dense disablePadding>
                {workShiftTimeSlots
                    ?.sort((a, b) =>
                        Base.dayjsCompare(a.startDate, b.startDate)
                    )
                    .map((s) => (
                        <WorkTimeListItem
                            key={s.id}
                            slot={s}
                            isSelected={s.id === selectedWorkShiftTimeSlotId}
                            onSelect={setSelectedWorkShiftTimeSlotId}
                            onHover={setHoveredWorkShiftTimeSlotId}
                        />
                    ))}
            </List>
        </MonitorViewSidebar>
    );
};

interface WorkTimeListItemProps {
    slot: WorkShiftTimeSlotItem;
    isSelected: boolean;
    onSelect: (id: string) => void;
    onHover: (id: string) => void;
}

const WorkTimeListItem = ({
    slot,
    isSelected,
    onSelect,
    onHover,
}: WorkTimeListItemProps) => {
    const { id, startDate, endDate, workTimeTypeName, workTimeTypeType } = slot;
    const { t } = useTranslation();

    const timeLabel = useMemo(
        () =>
            `${formatTime(startDate, Base.dayjsToJsonDate())} - ${
                endDate ? formatTime(endDate, Base.dayjsToJsonDate()) : ""
            }`,
        [startDate, endDate]
    );

    return (
        <ListItem
            disablePadding
            secondaryAction={new WorkShiftTimeSlotItem(slot).getDurationStr()}
        >
            <ListItemButton
                selected={isSelected}
                onClick={() => onSelect(id)}
                onMouseEnter={() => onHover(id)}
                onMouseLeave={() => onHover(null)}
            >
                <ListItemIcon>
                    <CircleIcon
                        className={`text-${getWorkTimeTypeColorClass(
                            workTimeTypeType
                        )}`}
                    />
                </ListItemIcon>
                <ListItemText
                    primary={
                        workTimeTypeName ?? t("workTimeType.noWorkTimeType")
                    }
                    secondary={timeLabel}
                    sx={{ mr: 2 }}
                ></ListItemText>
            </ListItemButton>
        </ListItem>
    );
};
