import { Base } from "../../framework/base";
import { IWorkOrderInvoiceDataListItem, WorkOrderInvoiceDataListItem } from "./workOrderInvoiceDataListItem";

export interface IWorkOrderInvoiceDataListItems {
    items: IWorkOrderInvoiceDataListItem[];
    hasMore: boolean;
    page: number;
}

export class WorkOrderInvoiceDataListItems implements IWorkOrderInvoiceDataListItems {
    items: WorkOrderInvoiceDataListItem[];
    hasMore: boolean;
    page: number;

    constructor();
    constructor(obj: IWorkOrderInvoiceDataListItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = Base.getTypedArray<WorkOrderInvoiceDataListItem>(!Base.isNullOrUndefined(obj) ? obj.items : null, WorkOrderInvoiceDataListItem);
    }
}