import React, { memo } from "react";
import moment from "moment";
import { useAppDispatch } from "../../../framework/customStore";
import { Translations } from "../../../models/translations";
import { setNewPlan } from "../../../store/transport/transportVehiclesSlice";

interface DateActionsProps {
    date: Date;
    vehicleId?: string;
    employeeId?: string;
}

export const DateActions = memo((props: DateActionsProps) => {
    const dispatch = useAppDispatch();

    const handleAddPlanForDate = () => {
        const start = moment(props.date).set({ hours: 8, minutes: 0 });
        const end = moment(props.date).set({ hours: 16, minutes: 0 });
        dispatch(
            setNewPlan({
                id: null,
                name: "",
                vehicleId: props.vehicleId || null,
                employeeId: props.employeeId || null,
                scheduledStartDateTime: start.toISOString(),
                scheduledEndDateTime: end.toISOString(),
            })
        );
    };

    // Not using material UI components or icons due to performance
    return (
        <div
            onClick={handleAddPlanForDate}
            className="cursor-pointer"
            title={Translations.NewPlan}
        >
            +
        </div>
    );
});