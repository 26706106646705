import { Base } from "../../framework/base";

export interface IRoutePointCheckEditItem {
    id: string;
    number: number;
    name: string;
    description: string;
    rowId: string;

    isNew():boolean;
}

export class RoutePointCheckEditItem implements IRoutePointCheckEditItem {
    id: string;
    number: number;
    name: string;
    description: string;
    rowId: string;

    constructor();
    constructor(obj: IRoutePointCheckEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.name = obj && obj.name || "";
        this.description = obj && obj.description || "";
        this.rowId = obj && obj.rowId || "";
    }

    isNew():boolean {
        return !this.rowId;
    }

    static sortRoutePointCheckEditItems(items: IRoutePointCheckEditItem[], column: string, asc: boolean) {
        if (!items || items.length < 2) return;
        items.sort((a: IRoutePointCheckEditItem, b: IRoutePointCheckEditItem) => { return (!a || !b) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.number, b.number)); });
    }
}

export class SaveRoutePointCheckEditItem {
    routePointId: string;
    id: string;
    number: number;
    name: string;
    description: string;
    rowId: string;

    constructor();
    constructor(obj: IRoutePointCheckEditItem);
    constructor(obj?: any) {
        this.routePointId = obj && obj.routePointId || "";
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.name = obj && obj.name || "";
        this.description = obj && obj.description || "";
        this.rowId = obj && obj.rowId || "";
    }

    static createSaveRoutePointCheckEditItem(routePointId: string, item: IRoutePointCheckEditItem): SaveRoutePointCheckEditItem {
        const result = new SaveRoutePointCheckEditItem(item);
        result.routePointId = routePointId;
        return result;
    }
}