import * as React from "react";
import Paper from "@mui/material/Paper";
import Grid2 from "@mui/material/Unstable_Grid2";

export interface Props {
    className?: string;
    topComponent?: JSX.Element;
    formComponent?: JSX.Element;
    calendarComponent?: JSX.Element;
    mapComponent?: JSX.Element;
    bottomComponent?: JSX.Element;
    radioButtonComponent?: JSX.Element;
    timelineComponent?: JSX.Element;
    mainComponent?: JSX.Element;
    maximized?: JSX.Element;
}

// Modified version of MainLayout to allow more customizability for work time details page
export const WorkTimeDetailsLayout = ({
    className,
    topComponent,
    formComponent,
    calendarComponent,
    mapComponent,
    bottomComponent,
    radioButtonComponent,
    timelineComponent,
    mainComponent,
    maximized,
}: Props) => {
    return (
        <section id="work-time-details-layout" className={className}>
            {maximized ? (
                <Grid2 container className="layout-grid-container">
                    <Grid2 xs={12}>
                        <Paper className="section-container p-0">
                            {maximized}
                        </Paper>
                    </Grid2>
                </Grid2>
            ) : (
                <Grid2
                    container
                    spacing={{ xs: 3, lg: 1 }}
                    className="layout-grid-container"
                >
                    <Grid2
                        spacing={2}
                        container
                        xs={12}
                        lg={true}
                        className="bg-white"
                        direction={{
                            xs: "row",
                            lg: "column",
                        }}
                    >
                        {topComponent && (
                            <Grid2 xs={12}>
                                <Paper className="section-container">
                                    {topComponent}
                                </Paper>
                            </Grid2>
                        )}
                        {formComponent && (
                            <Grid2 xs={12}>{formComponent}</Grid2>
                        )}
                        {timelineComponent && (
                            <Grid2 xs={12}>
                                <Paper className="section-container">
                                    {timelineComponent}
                                </Paper>
                            </Grid2>
                        )}
                        {radioButtonComponent && (
                            <Grid2 xs={12}>
                                {radioButtonComponent}
                            </Grid2>
                        )}
                        {mainComponent && (
                            <Grid2 xs={12}>
                                <Paper className="section-container p-0">
                                    {mainComponent}
                                </Paper>
                            </Grid2>
                        )}
                        {bottomComponent && (
                            <Grid2 xs={12}>
                                <Paper className="section-container p-0">
                                    {bottomComponent}
                                </Paper>
                            </Grid2>
                        )}
                    </Grid2>
                    <Grid2
                        xs={12}
                        lg="auto"
                        lgOffset="auto"
                        direction={{
                            xs: "column-reverse",
                            md: "row-reverse",
                            lg: "column",
                        }}
                        spacing={2}
                        container
                        className="bg-white"
                    >
                        <Grid2 xs="auto" xsOffset="auto">
                            <Paper>{calendarComponent}</Paper>
                        </Grid2>
                        {mapComponent && (
                            <Grid2 flexGrow={1} xs={12} md={true}>
                                <Paper className="section-container p-0 overflow-hidden">
                                    {mapComponent}
                                </Paper>
                            </Grid2>
                        )}
                    </Grid2>
                </Grid2>
            )}
        </section>
    );
};
