import * as React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Breakpoint,
    Button,
    Fade,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tab, Tabs,
    Typography,
    useMediaQuery
} from "@mui/material";
import { theme } from "../../framework/theme";
import { forwardRef, ReactNode, useEffect, useImperativeHandle, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Translations } from "../../models/translations";
import Grid2 from "@mui/material/Unstable_Grid2";
import { areObjectsEqual } from "../../framework/base";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { FormikProps } from "formik";
import * as yup from "yup";
import { TabPanel } from "./tabPanel";

// Set props within the container component where the wrapper is, e.g., StorageList
export interface EditDialogProps<T> {
    open: boolean;
    item?: T;
    tabs?: string[];
    onSave?: (item?: T) => void;
    onClose?: (item?: T) => void;
    onRemove?: () => void;
}

type TabContents = ReactNode[];

// Set props within the wrapper component, e.g., StorageDialog
export interface EditDialogWrapperProps extends EditDialogProps<unknown> {
    title: string;
    body: ReactNode | TabContents;
    primaryActions: ReactNode[];
    formik?: FormikProps<unknown>;
    maxWidth?: false | Breakpoint;
    validationSchema?: yup.AnyObjectSchema;
    secondaryActions?: {
        text: string;
        onClick: () => void;
        icon?: ReactNode;
    }[];
}

export interface ExposedFunctions {
    getInitialFormData: () => any;
    hasChanges: () => boolean;
    setInitialFormDataFromForm: () => void;
}

export const EditDialog = forwardRef((props: EditDialogWrapperProps, ref) => {
    const {
        open,
        title,
        body,
        primaryActions,
        tabs,
        formik,
        secondaryActions,
        onClose,
        maxWidth = "xl",
    } = props;
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
    const [initialFormData, setInitialFormData] = useState<any>();
    const [selectedTab, setSelectedTab] = useState<number>(0);

    // Expose functions to parent to avoid callback hell
    useImperativeHandle(ref, (): ExposedFunctions => ({
        getInitialFormData: () => initialFormData,
        hasChanges,
        setInitialFormDataFromForm,
    }));

    useEffect(() => {
        if (open) {
            setInitialFormDataFromForm();
        } else {
            // Reset on dialog close so it works when opening it again with new data
            formik?.resetForm();
        }
    }, [open]);

    const setInitialFormDataFromForm = () => {
        setInitialFormData(formik?.values);
    };

    const handleClose = () => {
        if (!formik) {
            onClose();
            return;
        }

        if (hasChanges()) {
            store.customStore.dispatch(storeActions.setConfirmation(
                ConfirmationDialogType.Warning,
                Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                null,
                () => formik.handleSubmit(),
                () => onClose(initialFormData),
                () => null,
            ));
        } else {
            onClose(initialFormData);
        }
    };

    const hasChanges = () => {
        return !areObjectsEqual(initialFormData, formik?.values);
    };

    const content = tabs && Array.isArray(body)
        ? body.map((tabContent, i) => (
            <TabPanel index={i} selectedTab={selectedTab} key={i} noPadding>
                {tabContent}
            </TabPanel>
        ))
        : body;


    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            maxWidth={maxWidth}
            onClose={handleClose}
            TransitionComponent={Fade}
        >
            <DialogTitle>
                <Grid2 container justifyContent="space-between" alignItems="center">
                    <Typography variant="h2">{title}</Typography>

                    {tabs &&
                        <Tabs
                            variant="standard"
                            value={selectedTab}
                            onChange={(e, value) => setSelectedTab(value)}
                            className="mx-4"
                        >
                            {tabs.map((tab, i) => (
                                <Tab label={tab} value={i} key={i}/>
                            ))}
                        </Tabs>
                    }

                    <Button color="primary" endIcon={<CloseIcon/>} onClick={handleClose} />
                </Grid2>
            </DialogTitle>
            <DialogContent dividers>
                {formik
                    ? (
                        <form onSubmit={formik.handleSubmit}>
                            {content}
                        </form>
                    )
                    : content
                }
            </DialogContent>
            <DialogActions className="mx-3 my-2" sx={{ flexDirection: "row-reverse" }}>
                {primaryActions.map((action, key) =>
                    <span key={key} className="mr-2">
                        {action}
                    </span>
                )}

                {secondaryActions &&
                    <>
                        <IconButton onClick={(e) => !menuAnchorEl ? setMenuAnchorEl(e.currentTarget) : setMenuAnchorEl(null)}>
                            <MoreVertIcon/>
                        </IconButton>

                        <Menu
                            anchorEl={menuAnchorEl}
                            open={Boolean(menuAnchorEl)}
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            transformOrigin={{ vertical: "bottom", horizontal: "center" }}
                            onClose={() => setMenuAnchorEl(null)}
                        >
                            {secondaryActions.map((action, key) =>
                                <MenuItem key={key} onClick={action.onClick}>
                                    {action.icon &&
                                        <ListItemIcon>{action.icon}</ListItemIcon>
                                    }
                                    <ListItemText>{action.text}</ListItemText>
                                </MenuItem>
                            )}
                        </Menu>
                    </>
                }

                <div className="flex-grow" />
            </DialogActions>
        </Dialog>
    );
});
