// SettingsSiteDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import * as siteService from "../../services/siteService";
import { ICustomerEditItemSiteItem } from "../../models/customer/customerEditItemSiteItem";
import { SettingsCustomerDialogSiteDialog } from "./settingsCustomerDialogSiteList";
import { ISiteEdit } from "../../models/customer/siteEdit";
import { SaveData } from "../../framework/saveData";
import { SaveRoutePointCheckEditItem } from "../../models/routePointCheck/routePointCheckEditItem";
import { SaveRoutePointItem } from "../../models/routePoint/routePointItem";

// SettingsSiteDialog
export interface ISettingsSiteDialogProp {
    readOnly: boolean;
    customerId: string;
    siteId: string;
    newSiteName: string;
    onOk: (item: ICustomerEditItemSiteItem) => void;
    onCancel: () => void;
}

interface ISettingsSiteDialogState {
    siteEdit: ISiteEdit;
}

export class SettingsSiteDialog extends React.Component<ISettingsSiteDialogProp, ISettingsSiteDialogState> {
    constructor(props) {
        super(props);
        this.state = { siteEdit: null };
    }

    getSiteEdit = () => {
        const obj = this;
        const props = this.props;
        store.customStore.dispatch(storeActions.fetchStart());
        siteService.getSiteEdit(props.siteId)
            .then(siteEdit => {
                if (props.newSiteName) {
                    siteEdit.site.name = props.newSiteName;
                }
                obj.setState({
                    siteEdit: siteEdit
                });
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    componentDidMount(): void {
        this.getSiteEdit();
    }

    private getSiteSaveData = (item: ICustomerEditItemSiteItem, removedRoutePointIds: string[], savedRoutePointIds: string[], removedRoutePointCheckIds: string[], savedRoutePointCheckIds: Map<string, string[]>): SaveData => {
        const props = this.props;
        const state = this.state;
        const data = new SaveData();
        // Common
        data.append("customerId", props.customerId);
        data.append("id", props.siteId);
        data.append("rowId", state.siteEdit.site.rowId);
        // General
        data.append("siteType", item.siteType.toString(10));
        data.append("siteNumber", item.siteNumber);
        data.append("name", item.name);
        data.append("name2", item.name2);
        data.append("distance", item.distance.toString(10));
        data.append("rideBookingRequired", item.rideBookingRequired ? "true" : "false");
        data.append("familiarizationType", item.familiarizationType.toString(10));
        // RoutePoints and RoutePointChecks
        const routePoints: SaveRoutePointItem[] = [];
        const routePointChecks: SaveRoutePointCheckEditItem[] = [];
        const site = item;
        for (let j = 0; j < site.routePoints.length; j++) {
            const siteRoutePoint = site.routePoints[j];
            if (savedRoutePointIds.indexOf(siteRoutePoint.id) >= 0) {
                routePoints.push(SaveRoutePointItem.createSaveRoutePointItemForSite(site.id, siteRoutePoint));
            }
            const routePointSavedRoutePointCheckIds = savedRoutePointCheckIds.get(siteRoutePoint.id) ?? [];
            for (let k = 0; k < siteRoutePoint.checks.length; k++) {
                const siteRoutePointCheck = siteRoutePoint.checks[k];
                if (routePointSavedRoutePointCheckIds.indexOf(siteRoutePointCheck.id) >= 0) {
                    routePointChecks.push(SaveRoutePointCheckEditItem.createSaveRoutePointCheckEditItem(siteRoutePoint.id, siteRoutePointCheck));
                }
            }
        }
        data.append("routePoints", JSON.stringify(routePoints));
        data.append("routePointChecks", JSON.stringify(routePointChecks));
        data.append("removedRoutePointIds", JSON.stringify(removedRoutePointIds));
        data.append("removedRoutePointCheckIds", JSON.stringify(removedRoutePointCheckIds));
        return data;
    };

    handleSiteDialogOk = (item: ICustomerEditItemSiteItem, removedRoutePointIds: string[], savedRoutePointIds: string[], removedRoutePointCheckIds: string[], savedRoutePointCheckIds: Map<string, string[]>) => {
        const saveData = this.getSiteSaveData(item, removedRoutePointIds, savedRoutePointIds, removedRoutePointCheckIds, savedRoutePointCheckIds);
        // Call server
        store.customStore.dispatch(storeActions.fetchStart());
        siteService.saveSiteEdit(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                item.id = success.id;
                this.props.onOk(item);
                this.setState({
                    siteEdit: null
                });
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleSiteDialogCancel = () => {
        this.props.onCancel();
        this.setState({
            siteEdit: null
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        if (!state.siteEdit) return null;
        const site = state.siteEdit.site;
        return (
            <SettingsCustomerDialogSiteDialog
                readOnly={props.readOnly}
                item={site}
                removedRoutePointIds={[]}
                savedRoutePointIds={[]}
                removedRoutePointCheckIds={[]}
                savedRoutePointCheckIds={new Map<string, string[]>()}
                mapLinkTemplate={state.siteEdit.mapLinkTemplate}
                isNew={site.isNew()}
                onOk={this.handleSiteDialogOk}
                onCancel={this.handleSiteDialogCancel}
            />
        );
    }
}
