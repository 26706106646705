// WorkPageMain
import * as React from "react";
import * as store from "../../framework/customStore";
import * as workOrderService from "../../services/workOrderService";
import * as StoreActions from "../../models/store/storeActions";
import { RouteComponentProps } from "react-router-dom";
import { handleApiError } from "../../models/store/storeEffects";
import { IIdTitle } from "../../models/common/idTitle";
import { Translations } from "../../models/translations";
import { IVehicleItem } from "../../models/vehicle/vehicleItem";
import { IEmployeeItem } from "../../models/employee/employeeItem";
import { InvoiceMain } from "../invoice/invoiceMain";
import { WorkInvoiceDataMain } from "./workInvoiceDataMain";
import { ReportType, TimeFormat } from "../../models/common/enums";
import { IReportListItem, ReportListItem } from "../../models/report/reportListItem";
import { IEmployeeParameters } from "../../models/employee/employeeIParameters";
import { IOwnerState, IUserState } from "../../models/store/storeTypes";
import { ReportSubMenuList } from "../report/reportSubMenuList";
import { ReportOperations } from "../../models/report/reportOperations";
import { ReportView } from "../report/reportView";
/*import { IWorkOrderItem, WorkOrderItem } from "./workOrderItem";*/

/* eslint-disable no-unused-vars */
export const enum WorkPageMainSubPage {
    OrdersChecking = "1",
    Invoices = "2",
    Reports = "3",
}
/* eslint-enable no-unused-vars */

export interface IWorkPageMainOwnProps {
}

export interface IWorkPageMainStateProps {
    owner: IOwnerState;
    user: IUserState;
}

export interface IWorkPageMainDispatchProps {
    setEmployeeParameters: (parameters: IEmployeeParameters, saveToDb: boolean) => void;
    onLogout: () => void;
}

export interface IWorkPageMainPathProps {
    page: WorkPageMainSubPage;
    state: string;
}

type WorkPageMainProp = IWorkPageMainOwnProps & IWorkPageMainStateProps & IWorkPageMainDispatchProps & RouteComponentProps<IWorkPageMainPathProps>;

interface IWorkPageMainState {
    workTimeFormat: TimeFormat;
    page: WorkPageMainSubPage;
    workOrderStates: IIdTitle[];
    workOrderTypes: IIdTitle[];
    employees: IEmployeeItem[];
    resources: IVehicleItem[];

    invoiceStates: IIdTitle[];
    //Reports
    reportFilter: string;
    reportItems: IReportListItem[];
    selectedReportId: string;
    selectedReport: IReportListItem;

    isLoading: boolean;
}

export class WorkPageMain extends React.Component<WorkPageMainProp, IWorkPageMainState> {
    constructor(props) {
        super(props);
        const pageNum = props.match.params.page ? props.match.params.page.toInteger(true, -1) : -1;
        const page = pageNum > -1 ? pageNum.toString(10) : WorkPageMainSubPage.OrdersChecking;
        this.state = {
            workTimeFormat: TimeFormat.DecimalFormat,
            page: page,
            workOrderStates: [],
            workOrderTypes: [],
            employees: [],
            resources: [],
            invoiceStates: [],
            reportFilter: "",
            reportItems: [],
            selectedReportId: "",
            selectedReport: null,
            isLoading: false
        };
    }

    componentDidMount(): void {
        this.getWorkPageMain();
        this.getReportListItems(this.state.reportFilter);
    }

    getWorkPageMain = (): Promise<void> => {
        const obj = this;
        obj.setState({
            isLoading: true
        });
        store.customStore.dispatch(StoreActions.fetchStart());
        return workOrderService.getWorkOrderPageMain()
            .then(
                workOrderPageMain => {
                    obj.setState({
                        workTimeFormat: workOrderPageMain.workTimeFormat,
                        workOrderStates: workOrderPageMain.workOrderStates,
                        workOrderTypes: workOrderPageMain.workOrderTypes,
                        employees: workOrderPageMain.employees,
                        resources: workOrderPageMain.resources,
                        invoiceStates: workOrderPageMain.invoiceStates,
                    });
                },
                error => {
                    handleApiError(error, store.customStore.dispatch);
                })
            .finally(() => {
                obj.setState({
                    isLoading: false
                });
                store.customStore.dispatch(StoreActions.fetchEnd());
            });
    };

    handleMenuItemClick = (page: WorkPageMainSubPage) => {
        this.setState({ page: page });
    };

    // #region Report
    getReportListItems = (filter: string) => {
        const obj = this;
        const state = this.state;
        ReportOperations.getReportListItems(state.reportItems, state.selectedReportId, ReportType.WorkOrder, filter, () => { obj.setState({ isLoading: true }); }, () => { obj.setState({ isLoading: false }); })
            .then(result => {
                if (!result) return;
                obj.setState({
                    reportFilter: result.filter,
                    reportItems: result.reportItems,
                    selectedReportId: result.selectedReportId
                });
            });
    };

    handleReportLineClick = (id: string) => {
        if (!id) return;
        const selected = this.state.reportItems.find(i => i.id === id);
        this.setState({
            selectedReportId: id,
            selectedReport: new ReportListItem(selected)
        });
    };

    handleChangeReportParameterValue = (name: string, value: string) => {
        const selected = this.state.selectedReport;
        if (!selected) return;
        const parameter = selected.parameters.find(i => i.name === name);
        if (!parameter) return;
        parameter.value = value;
        this.setState({
            selectedReport: new ReportListItem(selected),
        });
    };
    // #endregion Report

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <div>
                <div className="row mainContent ordersMain">
                    <div className="col-2">
                        <div className="backgroundBoard fullHeight">
                            <div className="panel verticalMenu">
                                <div className={"menuItem" + (state.page === WorkPageMainSubPage.OrdersChecking ? " active" : "")} onClick={() => { this.handleMenuItemClick(WorkPageMainSubPage.OrdersChecking); }}>{Translations.WorkOrdersChecking}</div>
                                <div className={"menuItem" + (state.page === WorkPageMainSubPage.Invoices ? " active" : "")} onClick={() => { this.handleMenuItemClick(WorkPageMainSubPage.Invoices); }}>{Translations.Invoices}</div>
                                <div className={"menuItem" + (state.page === WorkPageMainSubPage.Reports ? " active" : "")} onClick={() => { this.handleMenuItemClick(WorkPageMainSubPage.Reports); }}>{Translations.Reports}</div>
                                {state.page === WorkPageMainSubPage.Reports &&
                                    <ReportSubMenuList
                                        items={state.reportItems}
                                        selectedId={state.selectedReportId}
                                        onLineClick={this.handleReportLineClick}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-10">
                        {state.page === WorkPageMainSubPage.OrdersChecking &&
                            <WorkInvoiceDataMain
                                workTimeFormat={state.workTimeFormat}
                                employeeParameters={props.user.parameters}
                                workOrderStates={state.workOrderStates}
                                setEmployeeParameters={props.setEmployeeParameters}
                            />
                        }
                        {state.page === WorkPageMainSubPage.Invoices &&
                            <InvoiceMain />
                        }
                        {state.page === WorkPageMainSubPage.Reports &&
                            <ReportView
                                reportType={ReportType.WorkOrder}
                                selected={state.selectedReport}
                                onChangeParameterValue={this.handleChangeReportParameterValue}
                            />
                        }
                    </div>
                </div>
            </div>
        );
    }
}
