import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import { CSSProperties, ReactNode, createContext, memo, useContext, useMemo } from "react";
import { useInterceptionObserver } from "../hooks/useIntersectionObserver";
import { getDatesForRange } from "./timelineUtils";
import { Base } from "../../framework/base";
import { useAsyncThunkAction } from "../../hooks/useAsyncThunkAction";
import { fetchCalendarDays } from "../../store/calendarSlice";
import { useAppSelector } from "../../framework/customStore";

/*

Renders a grid where columns are generated for each date within a given range.
Cells are automatically placed within the grid based on the column prop given.

Usage:

<TimelineGrid
    startDate={startDate}
    endDate={endDate}
>
    <TimelineGridRow infoCell={<div>info box</div>}>

        <TimelineGridCell column={date1}>
            Cell for date1
        </TimelineGridCell>

        <TimelineGridCell column={date2}>
            Cell for date2
        </TimelineGridCell>

    </TimelineGridRow>
</TimelineGrid>

*/

const DATE_COLUMN_WIDTH = 180;
const DATE_COLUMN_WIDTH_PX = `${DATE_COLUMN_WIDTH}px`;

interface DateItem {
    date: Date;
    dateString: string;
    localizedDateString: string;
}

interface TimelineGridContext {
    columnCount: number;
    getGridDates: () => Date[];
    getColumnForDate: (date: Date) => number;
}

const TimelineGridContext = createContext<TimelineGridContext | null>(null);

interface TimelineGridProps {
    startDate: Date;
    endDate: Date;
    loadMore?: () => void;
    moveLeft?: () => void;
    // TODO can we enforce only children of type TimelinePlannerRow
    children?: ReactNode;
}

/**
 * This component should be used within a wrapper component (e.g., VehicleTimelinePlannerList).
 */
export const TimelineGrid = memo((props: TimelineGridProps) => {

    const dateRange = useMemo(() => getDateItemsForRange(props.startDate, props.endDate), [props.startDate, props.endDate]);

    useAsyncThunkAction(
        () =>
            fetchCalendarDays({
                startDate: props.startDate.toISOString(),
                endDate: props.endDate.toISOString()
            }),
        { onError: () => null },
        [props.startDate, props.endDate]
    );

    // Use intersection observer to know when end is reached.
    // TODO might be simpler to just use scroll offset info for this.
    const {
        setElementRef: setEndElementRef,
        setRootRef: setEndRootRef,
    } = useInterceptionObserver({
        handleInView: () => {
            props.loadMore();
        }
    });

    const todayDate = dateString(new Date());

    const context: TimelineGridContext = {
        columnCount: dateRange.length,
        getColumnForDate: (d) => {
            // TODO memoize
            const date = d.getDate();
            const month = d.getMonth();
            const year = d.getFullYear();

            const dateIndex = dateRange.findIndex(dr =>
                dr.date.getDate() === date &&
                dr.date.getMonth() === month &&
                dr.date.getFullYear() === year
            );

            // + 1 to account for the info cell
            return dateIndex + 1;
        },
        getGridDates: () => dateRange.map(dr => dr.date)
    };

    const calendarDays = useAppSelector(state => state.calendar.entities);

    return (
        <TimelineGridContext.Provider value={context}>
            <div className="timeline-grid-container" ref={setEndRootRef} style={{ height: "100%" }}>
                <div className="timeline-grid-row timeline-grid-dates" style={getRowGridStyle(context.columnCount)}>
                    {dateRange.map((d, i) => {
                        const isToday = d.dateString === todayDate;
                        const isHoliday = calendarDays[d.dateString]?.isHoliday ?? false;
                        return (
                            <div
                                style={{ position: "relative" }}
                                className={`timeline-grid-date timeline-grid-cell bold ${isToday ? "timeline-grid-today" : ""} ${isHoliday ? "timeline-grid-holiday" : ""}`}
                                key={d.dateString}
                            >
                                {i === 0 && props.moveLeft && (
                                    <Tooltip title="Näytä edellinen päivä" sx={{ position: "absolute", left: 0, top: -1 }}>
                                        <IconButton size="small" onClick={props.moveLeft}>
                                            <ArrowBackOutlinedIcon fontSize="small"/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {d.localizedDateString}
                            </div>
                        );
                    })}
                    <div className="timeline-grid-cell" ref={setEndElementRef}/>
                </div>
                <div>
                    {props.children}
                </div>
            </div>
        </TimelineGridContext.Provider>
    );
});

interface TimelineGridRowProps {
    infoColumn: ReactNode;
    renderColumnHeader?: (date: Date) => ReactNode;
    children: ReactNode;
}

export const TimelineGridRow = (props: TimelineGridRowProps) => {
    const context = useContext(TimelineGridContext);

    return (
        <div className="timeline-grid-row-container">
            <div className="timeline-grid-info-container timeline-grid-sticky-column">
                {props.infoColumn}
            </div>
            <div className="timeline-grid-row-cells-container">
                {props.renderColumnHeader && (
                <div>
                    {context.getGridDates().map((date, i) => (
                        <div
                            style={{ width: DATE_COLUMN_WIDTH_PX }}
                            key={i}
                            className="timeline-grid-header timeline-grid-cell"
                        >
                            {props.renderColumnHeader(date)}
                        </div>
                    ))}
                </div>
                )}

                <div
                    className="timeline-grid-row"
                    style={getRowGridStyle(context.columnCount)}
                >
                    {props.children}
                </div>
            </div>

        </div>
    );
};

interface TimelineGridCellProps {
    startColumn: Date;
    endColumn: Date;
    children: ReactNode;
}

export const TimelineGridCell = (props: TimelineGridCellProps) => {
    const context = useContext(TimelineGridContext);
    const gridColumnStart = context.getColumnForDate(props.startColumn);
    let gridColumnEnd = gridColumnStart;
    if (props.endColumn) {
        gridColumnEnd = context.getColumnForDate(props.endColumn);
    } else {
        // This should not happen, but some data might not have this.
        console.warn("TimelineGridCell endColumn prop missing");
    }

    // Column < 1 means cell didn't fit into current date range, so dont render it.
    if (gridColumnEnd < 1) return null;
    return (
        <div style={{ gridColumn: `${gridColumnStart}/${gridColumnEnd+1}` }} className="timeline-grid-cell">
            {props.children}
        </div>

    );
};

const getShortTime = () => new Intl.DateTimeFormat(appConfig.culture ?? "fi", {
    weekday: "short",
    day: "numeric",
    month: "numeric"
});

const padDatePart = (part: number) => `${part}`.padStart(2, "0");

function dateString(date: Date): string {
    return `${date.getFullYear()}-${padDatePart(date.getMonth()+1)}-${padDatePart(date.getDate())}`;
}

function getDateItem(date: Date): DateItem {
    return {
        date: date,
        dateString: dateString(date),
        localizedDateString: Base.capitalizeFirstChar(getShortTime().format(date)),
    };
}

function getDateItemsForRange(start: Date, end: Date): DateItem[] {
    return getDatesForRange(start, end).map(getDateItem);
}

function getRowGridStyle(columnCount: number): CSSProperties {
    return {
        gridTemplateColumns: `repeat(${columnCount}, ${DATE_COLUMN_WIDTH_PX})`,
    };
}
