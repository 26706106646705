import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { TransportOrderListItemDto, TransportOrderListQueryParameters, TransportOrderState, TransportOrderInvoicingState } from "../../models/transport/transportOrder";
import { TransportPlanDetailsDto, TransportPlanListItemDto, TransportPlanListQueryParameters, TransportPlanState, TransportPlanUpdateDto } from "../../models/transport/transportPlan";
import { VehicleListItemDto } from "../../models/transport/vehicle";
import { apiCall } from "../../services/apiClient";
import { VehicleGroupListItemDto } from "../../models/transport/vehicleGroup";
import { UniqueIdentifier } from "@dnd-kit/core";
import { getTransportPlans } from "../../services/transportPlanService";

const TIMELINE_DEFAULT_LENGTH = 90;

interface TransportVehiclesState {
    selectedVehicleGroups: string[];
    selectedVehicles: string[];
    selectedEmployees: string[];
    selectedEmployeeGroups: string[];
    timelineStart: Date;
    timelineEnd: Date;
    timeRange: [Date, Date];
    selectedPlanStates: Record<TransportPlanState, boolean>;
    vehicleGroups: VehicleGroupListItemDto[];
    vehices: VehicleListItemDto[];
    plans: Record<string, TransportPlanListItemDto>;
    vehiclePlans: Record<string, string[]>;
    newPlan: TransportPlanUpdateDto | null;
    sidebarOrders: TransportOrderListItemDto[];
    sideBarOrderDetails: TransportOrderListItemDto | null;
    activeDraggableId: UniqueIdentifier;
    gridDataChanged: boolean;
}

const initialState: TransportVehiclesState = {
    selectedVehicleGroups: [],
    selectedVehicles: [],
    selectedEmployees: [],
    selectedEmployeeGroups: [],
    timelineStart: moment().startOf("week").toDate(),
    timelineEnd: moment().startOf("week").add(TIMELINE_DEFAULT_LENGTH, "days").toDate(),
    timeRange: [
        moment().startOf("week").toDate(),
        moment().startOf("week").add(TIMELINE_DEFAULT_LENGTH, "days").toDate(),
    ],
    selectedPlanStates: {
        [TransportPlanState.Todo]: true,
        [TransportPlanState.Planning]: true,
        [TransportPlanState.Planned]: true,
        [TransportPlanState.InTransport]: true,
        [TransportPlanState.Completed]: true,
    },
    vehicleGroups: [],
    vehices: [],
    plans: {},
    vehiclePlans: {},
    newPlan: null,
    sidebarOrders: [],
    sideBarOrderDetails: null,
    activeDraggableId: "",
    gridDataChanged: false,
};

export const fetchVehicleGroups = createAsyncThunk(
    "VehicleGroup",
    async() => {
        const res = await apiCall<VehicleGroupListItemDto[]>("VehicleGroups", "GET");
        return res.data;
    }
);

export const fetchVehicles = createAsyncThunk<VehicleListItemDto[]>(
    "Vehicles",
    async() => {
        const res = await apiCall<VehicleListItemDto[]>("Vehicles", "GET");
        return res.data;
    }
);

export const fetchTransportPlans = createAsyncThunk(
    "TransportPlans",
    async(args: TransportPlanListQueryParameters) => {
        const data = await getTransportPlans(args);
        return data;
    }
);

export const addPlanToOrder = createAsyncThunk(
    "TransportPlansAddOrder",
    async(args: {planId: string; order: TransportOrderListItemDto}) => {
        const res = await apiCall<TransportPlanDetailsDto>(`TransportPlans/${args.planId}/TransportOrders/${args.order.id}`, "PUT");
        return res.data;
    }
);

export const removeTransportPlan = createAsyncThunk(
    "TransportPlanRemove",
    async(plan: TransportPlanListItemDto) => {
        const res = await apiCall(`TransportPlans/${plan.id}`, "DELETE");
        return res.data;
    }
);

export const removeOrderFromPlan = createAsyncThunk(
    "TransportOrderRemoveFromPlan",
    async(args: {planId: string; order: TransportOrderListItemDto}) => {
        const res = await apiCall(`TransportPlans/${args.planId}/TransportOrders/${args.order.id}`, "DELETE");
        return res.data;
    }
);

export const deleteTransportOrder = createAsyncThunk(
    "transportOrders/delete",
    async(orderId: string, { rejectWithValue }) => {
        try {
            await apiCall(`TransportOrders/${orderId}`, "DELETE");
            return orderId;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const setEmployeeToPlan = createAsyncThunk(
    "TransportPlansSetEmployee",
    async(args: {planId: string; order: TransportOrderListItemDto}) => {
        const res = await apiCall<TransportPlanDetailsDto>(`TransportPlans/${args.planId}/TransportOrders/${args.order.id}`, "PUT");
        return res.data;
    }
);

export const fetchSidebarOrders = createAsyncThunk(
    "TransportOrders",
    async(args: TransportOrderListQueryParameters) => {
        const response = await apiCall<TransportOrderListItemDto[], TransportOrderListQueryParameters>("TransportOrders", "GET", {
            ...args,
            noTransportPlan: true,
        });
        return response.data;
    }
);

export const updatePlanState = createAsyncThunk(
    "UpdateTransportPlanState",
    async(args: {planId: string; originalState: TransportPlanState; newState: TransportPlanState}) => {
        const response = await apiCall<TransportOrderListItemDto[]>(`TransportPlans/${args.planId}/State/${args.newState}`, "PUT");
        return response.data;
    }
);

export const TransportVehiclesSlice = createSlice({
    name: "transportVehicles",
    initialState,
    reducers: {
        setTimelineStart(state, action: PayloadAction<Date>) {
            const startTime = action.payload || initialState.timelineStart;
            state.timelineStart = startTime;
            state.timelineEnd = moment(startTime).add(TIMELINE_DEFAULT_LENGTH, "days").toDate();
        },
        setTimeRange(state, action: PayloadAction<[Date, Date]>) {
            state.timeRange = action.payload;
        },
        extendTimeline(state) {
            state.timelineEnd = moment(state.timelineEnd).add(TIMELINE_DEFAULT_LENGTH, "days").toDate();
        },
        moveTimelineLeft(state) {
            state.timelineStart = moment(state.timelineStart).subtract(1, "days").toDate();
            state.timelineEnd = moment(state.timelineStart).add(TIMELINE_DEFAULT_LENGTH, "days").toDate();
        },
        setSelectedVehicleGroups(state, action: PayloadAction<string[]>){
            state.selectedVehicleGroups = action.payload;
        },
        setSelectedVehicles(state, action: PayloadAction<string[]>) {
            state.selectedVehicles = action.payload;
        },
        setSelectedEmployees(state, action: PayloadAction<string[]>) {
            state.selectedEmployees = action.payload;
        },
        setSelectedEmployeeGroups(state, action: PayloadAction<string[]>){
            state.selectedEmployeeGroups = action.payload;
        },
        setPlanStates(state, action: PayloadAction<Partial<TransportVehiclesState["selectedPlanStates"]>>) {
            state.selectedPlanStates = { ...state.selectedPlanStates, ...action.payload };
        },
        togglePlanStateSelected(state, action: PayloadAction<TransportPlanState>) {
            state.selectedPlanStates[action.payload] = !state.selectedPlanStates[action.payload];
        },
        setNewPlan(state, action: PayloadAction<TransportPlanUpdateDto | null>) {
            state.newPlan = action.payload;
        },
        addPlanToGrid(state, action: PayloadAction<TransportPlanDetailsDto>) {
            const plan = action.payload;
            if (plan.vehicle?.id) {
                state.plans[plan.id] = plan;
                state.vehiclePlans[plan.vehicle.id] ??= [];
                state.vehiclePlans[plan.vehicle.id].push(plan.id);
            }
        },
        setSideBarOrderDetails(state, action: PayloadAction<TransportOrderListItemDto | null>) {
            state.sideBarOrderDetails = action.payload;
        },
        setSideBarOrderInvoicingState(state, action: PayloadAction<TransportOrderInvoicingState>) {
            state.sideBarOrderDetails.invoicingState = action.payload;
        },
        setActiveDraggableId(state, action: PayloadAction<UniqueIdentifier>) {
            state.activeDraggableId = action.payload;
        },
        setGridDataChanged(state, action: PayloadAction<boolean>) {
            state.gridDataChanged = action.payload;
        },
        updateSidebarOrderState(state, action: PayloadAction<TransportOrderState>) {
            if (state.sideBarOrderDetails) {
                const newOrderState = action.payload;
                const oldOrderState = state.sideBarOrderDetails.state;
                if (newOrderState === TransportOrderState.Delivered) {
                    state.sideBarOrderDetails.invoicingState = TransportOrderInvoicingState.Invoiceable;
                } else if (oldOrderState === TransportOrderState.Delivered) {
                    state.sideBarOrderDetails.invoicingState = TransportOrderInvoicingState.NotInvoiceable;
                }
                state.sideBarOrderDetails.state = newOrderState;
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchVehicles.fulfilled, (state, action) => {
            state.vehices = action.payload;
        });

        builder.addCase(fetchTransportPlans.fulfilled, (state, action) => {
            const vehiclePlans: Record<string, string[]> = {};
            const plans = {};
            for (const plan of action.payload) {
                if (plan.vehicle?.id) {
                    vehiclePlans[plan.vehicle.id] ??= [];
                    vehiclePlans[plan.vehicle.id].push(plan.id);
                }
                plans[plan.id] = plan;
            }
            state.plans = plans;
            state.vehiclePlans = vehiclePlans;
        });

        builder.addCase(addPlanToOrder.pending, (state, action) => {
            const args = action.meta.arg;
            const planState = state.plans[args.planId].state;
            let newOrderState;
            if (planState === TransportPlanState.Todo || planState === TransportPlanState.Planning) {
                newOrderState = TransportOrderState.OrderCreated;
            } else {
                newOrderState = TransportOrderState.Planned;
            }
            const updatedOrder = { ...args.order, state: newOrderState, transportPlanId: args.planId };
            state.plans[args.planId].orders.push(updatedOrder);
            state.sidebarOrders.splice(state.sidebarOrders.findIndex(o => o.id === args.order.id), 1);
            if (state.sideBarOrderDetails && state.sideBarOrderDetails.id === args.order.id) {
                state.sideBarOrderDetails = { ...state.sideBarOrderDetails, state: newOrderState };
            }
        });

        builder.addCase(addPlanToOrder.rejected, (state, action) => {
            const orders = state.plans[action.meta.arg.planId].orders;
            orders.splice(orders.findIndex(o => o.id === action.meta.arg.order.id), 1);
            state.sidebarOrders.push(action.meta.arg.order);
        });

        builder.addCase(removeTransportPlan.fulfilled, (state, action) => {
            const planId = action.meta.arg.id;
            const vehiclePlans = state.vehiclePlans[action.meta.arg.vehicle?.id];
            if (vehiclePlans) {
                vehiclePlans.splice(vehiclePlans.findIndex(vp => vp === planId), 1);
            }
            delete state.plans[planId];
        });

        builder.addCase(removeOrderFromPlan.pending, (state, action) => {
            const args = action.meta.arg;
            const plan = state.plans[args.planId];
            plan.orders.splice(plan.orders.findIndex(o => o.id === args.order.id), 1);
            // TODO order might not match current search params in the sidebar
            // refetch should be triggered instead somehow

            // TODO orders should be sorted according to some logic
            const updatedOrder = { ...args.order, state: TransportOrderState.OrderCreated, transportPlanId: null };
            state.sidebarOrders.push(updatedOrder);
            if (state.sideBarOrderDetails && state.sideBarOrderDetails.id === args.order.id) {
                state.sideBarOrderDetails = { ...state.sideBarOrderDetails, state: TransportOrderState.OrderCreated };
            }
        });

        builder.addCase(removeOrderFromPlan.rejected, (state, action) => {
            const args = action.meta.arg;
            const plan = state.plans[args.planId];
            plan.orders.push(args.order);
            state.sidebarOrders.splice(state.sidebarOrders.findIndex(o => o.id === args.order.id), 1);
        });

        builder.addCase(deleteTransportOrder.fulfilled, (state, action) => {
            const orderId = action.payload;
            const orderIndex = state.sidebarOrders.findIndex(order => order.id === orderId);
            if (orderIndex !== -1) {
                state.sidebarOrders.splice(orderIndex, 1);
            }
        });

        builder.addCase(fetchSidebarOrders.fulfilled, (state, action) => {
            state.sidebarOrders = action.payload;
        });

        builder.addCase(updatePlanState.pending, (state, action) => {
            state.plans[action.meta.arg.planId].state = action.meta.arg.newState;
        });

        builder.addCase(updatePlanState.rejected, (state, action) => {
            state.plans[action.meta.arg.planId].state = action.meta.arg.originalState;
        });
    }
});

export const {
    setTimelineStart,
    setTimeRange,
    extendTimeline,
    moveTimelineLeft,
    setSelectedVehicleGroups,
    setSelectedVehicles,
    setSelectedEmployeeGroups,
    setSelectedEmployees,
    togglePlanStateSelected,
    setNewPlan,
    addPlanToGrid,
    setSideBarOrderInvoicingState,
    setSideBarOrderDetails,
    setActiveDraggableId,
    setGridDataChanged,
    updateSidebarOrderState,
} = TransportVehiclesSlice.actions;

export const transportVehiclesReducer = TransportVehiclesSlice.reducer;
