import { getApiCall, makeApiCallWithAny, postApiCall, postFormApiCall } from "./tokenService";
import { EmployeeGroupItems } from "../models/employeeGroup/employeeGroupItems";
import { ApiSuccess, SaveSuccess } from "./baseService";
import { EmployeeGroupEdit } from "../models/employeeGroup/employeeGroupEdit";
import { IdTitleItems } from "../models/common/idTitleItems";
import { TESCalculationParameters } from "../models/calculation/TESCalculationParameters";
import { apiCall } from "./apiClient";
import { employeeGroupListItemDto } from "../models/employeeGroup/employeeGroup";

export const getEmployeeGroupItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean): Promise<EmployeeGroupItems> => {
    return getApiCall<EmployeeGroupItems>("api/employeegroup/?page="
      + (page.toString() || "")
      + "&pageSize="
      + (pageSize.toString() || "")
      + "&sortColumn="
      + (sortColumn ? encodeURIComponent(sortColumn) : "")
      + "&sortOrder="
      + (sortOrderIsAsc ? "asc" : "desc")
      + "&filter="
      + (filter ? encodeURIComponent(filter) : ""), EmployeeGroupItems);
};

export const getEmployeeGroupEdit = (id: string): Promise<EmployeeGroupEdit> => {
    return getApiCall<EmployeeGroupEdit>("api/employeegroup/edit/?id=" + (id || ""), EmployeeGroupEdit);
};

export const saveEmployeeGroupEdit = (data: FormData): Promise<SaveSuccess> => {
    return postFormApiCall<SaveSuccess>("api/employeegroup/save", data, SaveSuccess);
};

export const removeEmployeeGroup = (id: string): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/employeegroup/remove/?id=" + (id || ""), ApiSuccess);
};

export const getEmployeeGroupIdTitles = (ignoreIds: string[]): Promise<IdTitleItems> => {
    return postApiCall<IdTitleItems>("api/employeegroup/idtitle", IdTitleItems, JSON.stringify(ignoreIds));
};

export const getFunctionSettings = (id: string):Promise<TESCalculationParameters> => {
    return getApiCall<TESCalculationParameters>("api/tessettings/calculationparameters?employeeGroupId="+id, TESCalculationParameters);
};

export const saveFunctionSettings = (employeeGroupId: string,obj: TESCalculationParameters) => {
    return makeApiCallWithAny<string>("api/tessettings/calculationparameters/save?employeeGroupId="+ employeeGroupId,"post", obj,null);
}

export const getEmployeeGroups = (onlyWithActiveSalaryEmployees = false, signal?: AbortSignal) => {
    return apiCall<employeeGroupListItemDto[]>(
        `employeeGroups?onlyWithActiveSalaryEmployees=${encodeURIComponent(
            onlyWithActiveSalaryEmployees
        )}`,
        "GET",
        signal
    );
};