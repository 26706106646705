export interface IProductGroup {
    id: string;
    code: string;
    name: string;
    mainGroup: number;
}

export class ProductGroup implements IProductGroup {
    id: string;
    code: string;
    name: string;
    mainGroup: number;

    constructor();
    constructor(obj: IProductGroup);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.code = obj && obj.code || "";
        this.name = obj && obj.name || "";
        this.mainGroup = obj && obj.mainGroup || ""
    }
}
