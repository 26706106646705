import { IWorkTimeAccountTransaction, WorkTimeAccountTransaction } from "./workTimeAccountTransaction";
import { Base } from "../../framework/base";

export interface IWorkTimeAccountTransactions {
    items: IWorkTimeAccountTransaction[];
}

export class WorkTimeAccountTransactions implements IWorkTimeAccountTransactions {
    items: WorkTimeAccountTransaction[];

    constructor();
    constructor(obj: IWorkTimeAccountTransactions);
    constructor(obj?: any) {
        this.items = Base.getTypedArray<WorkTimeAccountTransaction>(obj, WorkTimeAccountTransaction);
    }
}