import { IWorkOrderEditSite, WorkOrderEditSite } from "./workOrderEditSite";
import { WorkOrderEditContact, IWorkOrderEditContact } from "./workOrderEditContact";
import { Base } from "../../framework/base";
import { WorkOrderEditProductItem, IWorkOrderEditProductItem } from "./workOrderEditProductItem";

export interface IWorkOrderEditCustomerData {
    customerLogo: string;
    workOrderAcknowledgementTypeId: string;
    sites: IWorkOrderEditSite[];
    contacts: IWorkOrderEditContact[];
    products: IWorkOrderEditProductItem[];
}

export class WorkOrderEditCustomerData implements IWorkOrderEditCustomerData {
    customerLogo: string;
    workOrderAcknowledgementTypeId: string;
    sites: WorkOrderEditSite[];
    contacts: WorkOrderEditContact[];
    products: WorkOrderEditProductItem[];

    constructor();
    constructor(obj: IWorkOrderEditCustomerData);
    constructor(obj?: any) {
        this.customerLogo = obj && obj.customerLogo || "";
        this.workOrderAcknowledgementTypeId = obj && obj.workOrderAcknowledgementTypeId || "";
        this.contacts = Base.getTypedArray<WorkOrderEditContact>(obj ? obj.contacts : null, WorkOrderEditContact);
        this.sites = Base.getTypedArray<WorkOrderEditSite>(obj ? obj.sites : null, WorkOrderEditSite);
        this.products = Base.getTypedArray<WorkOrderEditProductItem>(obj ? obj.products : null, WorkOrderEditProductItem);
    }
}