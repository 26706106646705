import { ThemeColor, colors } from "../../framework/theme";
import { Translations } from "../translations";

export enum TransportOrderInvoiceState {
    NotApproved,
    Approved,
    SentToIntegration
}

export interface TransportOrderInvoiceQueryParameters {
    state: TransportOrderInvoiceState;
    states?: TransportOrderInvoiceState[];
    customerId: string;
    createdDateTime: string;
    selectedStates?: TransportOrderInvoiceState[];
}

interface transportOrderInvoiceStateInfo {
    value: TransportOrderInvoiceState;
    name: string;
    color: string;
    colorName: ThemeColor;
}

export const transportOrderInvoiceStates: readonly transportOrderInvoiceStateInfo[] = Object.freeze([
    { value: TransportOrderInvoiceState.NotApproved, name: Translations.InvoiceNotApproved, color: colors.yellow.main, colorName: "yellow" },
    { value: TransportOrderInvoiceState.Approved, name: Translations.InvoiceApproved, color: colors.green.main, colorName: "green" },
    { value: TransportOrderInvoiceState.SentToIntegration, name: Translations.InvoiceSent, color: colors.blue.main, colorName: "blue" },
]);
