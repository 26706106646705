import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getWorkOrderMain } from "../services/workOrderService";

interface Filters {
    test: string | null,
}

export interface TimelinePlannerState {
    selectedTab: number;
    filters: Filters;
}

const initialState: TimelinePlannerState = {
    selectedTab: 0,
    filters: {
        test: null,
    },
};

export const timelinePlannerSlice = createSlice({
    name: "timelinePlanner",
    initialState,
    reducers: {
        setSelectedTab: (state, action: PayloadAction<number>) => {
            state.selectedTab = action.payload;
        },
        updateFilters: (state, action: PayloadAction<Partial<Filters>>) => { // This is an example of what you could do
            state.filters = {...state.filters, ...action.payload};
        },
    },
});

export const { setSelectedTab, updateFilters } = timelinePlannerSlice.actions;

// Examples of async actions. Use when calling an API and saving result to state.
// Contents are just for testing
export const testAsync = () => (dispatch) => {
    getWorkOrderMain().then(() => {
        dispatch(setSelectedTab(2));
    });
};
export const testAsyncThunk = createAsyncThunk("test", (_, thunkApi) => {
    getWorkOrderMain().then(() => {
        thunkApi.dispatch(setSelectedTab(2));
    });
});

export const timelinePlannerReducer = timelinePlannerSlice.reducer;


// TODO Remove comments when the real slice is implemented

// How to enable: (this one is already enabled)
// Add reducer and type to store, see: customStore.ts.

// Usage within function components:
// - Use type wrappers to avoid boilerplate types:
// import { useAppDispatch, useAppSelector } from "../../framework/customStore";
// - Select the values that you need. First one is usually recommended:
// const { selectedTab, filters } = useAppSelector(state => state.timelinePlanner);
// OR
// const selectedTab = useAppSelector(state => state.timelinePlanner.selectedTab);

// For complicated selects it is possible to do this:
// export const filterTest = (state) => state.filters.value.test; (within this file)
// and within component:
// const selectedTab = useAppSelector(filterTest);

// Usage within class components:
// - Use mapStateToProps as before:
// const mapStateToProps = (state) => ({
//   count: state.timelinePlanner.selectedTab.value
// });
// - OR use a HOC https://reactjs.org/docs/higher-order-components.html

// Dispatching actions:
// const dispatch = useAppDispatch();
// ...
// dispatch(setSelectedTab(1));
