// OwnerSettingsProductGroupList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as baseService from "../../services/baseService";
import * as productGroupService from "../../services/productGroupService";
import { ToolButton } from "../framework/toolButton";
import { Base } from "../../framework/base";
import { ListSearchFilter } from "../framework/listSearchFilter";
import { ListHeaderColumn } from "../framework/listHeaderColumn";
import { ToolTitle } from "../framework/toolTitle";
import * as StoreActions from "../../models/store/storeActions";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { infiniteListPageSize } from "../../models/common/consts";
import { handleApiError } from "../../models/store/storeEffects";
import { IProductGroupItem } from "../../models/productGroup/ProductGroupItem";
import { IProductGroupEdit } from "../../models/productGroup/productGroupEdit";
import { SettingsProductGroupDialog } from "./settingsProductGroupDialog";

// SettingsProductGroupListLineHeader
export interface ISettingsProductGroupListLineHeaderProp {
    sortColumn: string;
    sortOrderIsAsc: boolean;
    onColumnClick: (column: string) => void;
}

export class SettingsProductGroupListLineHeader extends React.Component<ISettingsProductGroupListLineHeaderProp, {}> {
    render() {
        const props = this.props;
        return (
            <div className="row title">
                <ListHeaderColumn
                    title={Translations.Code}
                    column="code"
                    classes="col-4"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={props.onColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Name}
                    column="name"
                    classes="col-8"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={props.onColumnClick}
                />
            </div>
        );
    }
}

// SettingsProductGroupListLine
export interface ISettingsProductGroupListLineProp {
    item: IProductGroupItem;
    selectedId: string;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

export class SettingsProductGroupListLine extends React.Component<ISettingsProductGroupListLineProp, {}> {
    handleOpenClick = (e) => {
        this.props.onDoubleClick(this.props.item.id);
    };

    render() {
        const props = this.props;
        const item = props.item;
        return (
            <div className={"row line" + (item.id === props.selectedId ? " selected" : "")} onClick={() => { props.onClick(item.id); }} onDoubleClick={() => { props.onDoubleClick(item.id); }} >
                <div className="col-4 openOnClick" onClick={this.handleOpenClick}>{item.code}</div>
                <div className="col-8">{item.name}</div>
            </div>
        );
    }
}

// SettingsProductGroupList
export interface ISettingsProductGroupListProp {
}

interface ISettingsProductGroupListState {
    isLoading: boolean;

    // List
    pageSize: number;
    page: number;
    hasMore: boolean;
    filter: string;
    sortColumn: string;
    sortOrderIsAsc: boolean;
    items: IProductGroupItem[];
    selectedId: string;
    checkedIds: string[];

    // Edit
    editItem: IProductGroupEdit;
    showEditDialog: boolean;
}

export class SettingsProductGroupList extends React.Component<ISettingsProductGroupListProp, ISettingsProductGroupListState> {
    private containerDiv: HTMLDivElement;
    private listDiv: HTMLDivElement;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false, pageSize: infiniteListPageSize, page: 1, hasMore: false, filter: "", sortColumn: "name", sortOrderIsAsc: true, items: [], selectedId: null, checkedIds: [], showEditDialog: false, editItem: null,
        };
    }

    searchItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean, resetItems: boolean, refreshList: boolean, successCallback: () => void = null) => {
        const obj = this;
        const state = this.state;
        this.setState({
            isLoading: true
        });
        store.customStore.dispatch(StoreActions.fetchStart());
        productGroupService.getProductGroupItems(!refreshList ? pageSize : (page * infiniteListPageSize), !refreshList ? page : 1, filter, sortColumn, sortOrderIsAsc)
            .then(dbItems => {
                const listItems = Base.getListItems<IProductGroupItem>(state.items, state.selectedId, state.checkedIds, dbItems.items, resetItems, refreshList);
                obj.setState({
                    page: !refreshList ? dbItems.page : page,
                    hasMore: dbItems.hasMore,
                    filter: filter,
                    sortColumn: sortColumn,
                    sortOrderIsAsc: sortOrderIsAsc,
                    items: listItems.items,
                    selectedId: listItems.selectedId,
                    checkedIds: listItems.checkedIds,
                });
                if (!Base.isNullOrUndefined(successCallback)) {
                    successCallback();
                }
            },
            error => {
                handleApiError(error, store.customStore.dispatch);
            }).finally(() => {
                obj.setState({
                    isLoading: false
                });
                store.customStore.dispatch(StoreActions.fetchEnd());
            });
    };

    refreshList = () => {
        this.searchItems(this.state.pageSize, this.state.page, this.state.filter, this.state.sortColumn, this.state.sortOrderIsAsc, false, true);
    };

    handleScrollSub = Base.debounce((obj: SettingsProductGroupList) => {
        if (obj.state.isLoading || !obj.state.hasMore) return;
        if (obj.listDiv.offsetHeight - (obj.containerDiv.clientHeight + obj.containerDiv.scrollTop) < 5) {
            obj.searchItems(obj.state.pageSize, obj.state.page + 1, obj.state.filter, obj.state.sortColumn, obj.state.sortOrderIsAsc, false, false);
        }
    }, 100);

    handleScroll = (event) => {
        this.handleScrollSub(this);
    };

    componentDidMount(): void {
        const state = this.state;
        this.containerDiv.addEventListener("scroll", this.handleScroll);
        this.searchItems(state.pageSize, state.page, state.filter, state.sortColumn, state.sortOrderIsAsc, false, false);
    }

    componentWillUnmount(): void {
        this.containerDiv.removeEventListener("scroll", this.handleScroll);
    }

    changeFilter = (filter: string) => {
        const state = this.state;
        this.searchItems(state.pageSize, 1, filter, state.sortColumn, state.sortOrderIsAsc, true, false);
    };

    changeSortColumn = (sortColumn: string) => {
        const state = this.state;
        const oldSortColumn = state.sortColumn;
        const sortOrderIsAsc = oldSortColumn === sortColumn ? !state.sortOrderIsAsc : true;
        this.searchItems(state.pageSize, 1, state.filter, sortColumn, sortOrderIsAsc, true, false);
    };

    getEditItem = (id: string) => {
        const obj = this;
        productGroupService.getProductGroupEdit(id).then(editItem => {
            obj.setState({
                showEditDialog: true,
                editItem: editItem
            });
        });
    };

    handleAdd = () => {
        this.getEditItem(Base.emptyGuid);
    };

    handleEdit = () => {
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        this.getEditItem(selectedId);
    };

    handleEditDialogOk = () => {
        this.setState({
            showEditDialog: false
        });
        this.refreshList();
    };

    handleEditDialogCancel = () => {
        this.setState({
            showEditDialog: false
        });
    };

    handleRemove = () => {
        const obj = this;
        const state = this.state;
        const selectedId = state.selectedId;
        if (!selectedId) return;
        const item = state.items.find(i => i.id === selectedId);
        if (Base.isNullOrUndefined(item)) return;
        store.customStore.dispatch(StoreActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, String.format(Translations.ProductGroupRemoveConfirmation, item.name), () => {
            store.customStore.dispatch(StoreActions.clearConfirmation());
            // Call server
            store.customStore.dispatch(StoreActions.fetchStart());
            productGroupService.removeProductGroup(selectedId)
                .then(success => {
                    store.customStore.dispatch(StoreActions.showSuccessMessage(success.message));
                    obj.refreshList(); // TODO UPDATE ONLY CHANGED CUSTOMER DATA
                })
                .catch(error => {
                    store.customStore.dispatch(StoreActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                    return null;
                })
                .finally(() => store.customStore.dispatch(StoreActions.fetchEnd()));
        }, () => {
            store.customStore.dispatch(StoreActions.clearConfirmation());
        }));
    };

    handleClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleDoubleClick = (id: string) => {
        this.getEditItem(id);
    };

    render() {
        const state = this.state;
        const items = state.items;
        return (
            <div>
                <div className="row commandRow main">
                    <ToolTitle
                        title={Translations.ProductGroups}
                    />
                    <div className="col">
                        <ToolButton
                            title={Translations.Add}
                            enabled={true}
                            classes={"round left add"}
                            onClick={this.handleAdd}
                        />
                        <ToolButton
                            title={Translations.Edit}
                            enabled={!!state.selectedId}
                            classes={"round left edit"}
                            onClick={this.handleEdit}
                        />
                        <ToolButton
                            title={Translations.Remove}
                            enabled={!!state.selectedId}
                            classes={"round left remove"}
                            onClick={this.handleRemove}
                        />
                    </div>
                    <div className="col-auto right">
                        <ListSearchFilter
                            searchFilter={this.state.filter}
                            onSearchClick={this.changeFilter}
                        />
                    </div>
                </div>
                <div>
                    <SettingsProductGroupListLineHeader
                        sortColumn={state.sortColumn}
                        sortOrderIsAsc={state.sortOrderIsAsc}
                        onColumnClick={this.changeSortColumn}
                    />
                </div>
                <div className="listContainer productGroupsContainer">
                    <div className="list striped" ref={(elem) => { this.containerDiv = elem; }}>
                        <div className="lineContainer" ref={(elem) => { this.listDiv = elem; }}>
                            {items.map((item) =>
                                <SettingsProductGroupListLine
                                    key={item.id}
                                    item={item}
                                    selectedId={state.selectedId}
                                    onClick={this.handleClick}
                                    onDoubleClick={this.handleDoubleClick}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {state.showEditDialog &&
                    <SettingsProductGroupDialog
                        editItem={state.editItem}
                        onOk={this.handleEditDialogOk}
                        onCancel={this.handleEditDialogCancel}
                    />
                }
            </div>
        );
    }
}
