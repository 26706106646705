import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";
import { useAppDispatch, useAppSelector } from "../../../framework/customStore";
import { Translations } from "../../../models/translations";
import { apiCall } from "../../../services/apiClient";
import { showApiError } from "../../framework/formUtils";
import { DriverSelect } from "../components/driverSelect";
import { TransportPlanState } from "../../../models/transport/transportPlan";
import { setGridDataChanged } from "../../../store/transport/transportVehiclesSlice";

interface EmployeeInlineEditProps {
    planId: string;
}

export const EmployeeInlineEdit = (props: EmployeeInlineEditProps) => {
    const plan = useAppSelector(
        (state) => state.transportVehicles.plans[props.planId]
    );
    const gridDataChanged = useAppSelector((state) => state.transportVehicles.gridDataChanged);
    const dispatch = useAppDispatch();

    const pendingTransport = plan.state !== TransportPlanState.InTransport && plan.state !== TransportPlanState.Completed;

    const [editing, setEditing] = useState<boolean>(false);
    const [employee, setEmployee] = useState<{
        id: string;
        name: string;
    } | null>(
        plan.employee && {
            id: plan.employee.id,
            name: `${plan.employee.firstName} ${plan.employee.lastName}`,
        }
    );

    useEffect(() => {
        if (plan.employee) {
            setEmployee({ id: plan.employee.id, name: `${plan.employee.firstName} ${plan.employee.lastName}` });
        } else setEmployee(null);
    }, [plan]);

    const handleChange = async(id: string, name: string) => {
        const prevEmployee = employee;

        if (!id) {
            await apiCall<void>(
                `TransportPlans/${plan.id}/Employees/Remove`,
                "PUT"
            ).catch((e) => {
                showApiError(e);
                setEmployee(prevEmployee);
            });
            setEmployee(null);
            setEditing(false);
            dispatch(setGridDataChanged(!gridDataChanged));
        } else {
            await apiCall<void>(
                `TransportPlans/${plan.id}/Employees/${id}`,
                "PUT"
            ).catch((e) => {
                showApiError(e);
                setEmployee(prevEmployee);
            });
            setEmployee({ id, name });
            setEditing(false);
            dispatch(setGridDataChanged(!gridDataChanged));
        }
    };

    if (editing) {
        return (
            <Box mt={1}>
                <DriverSelect
                    onChange={handleChange}
                    onBlur={() => setEditing(false)}
                    value={employee?.id}
                    autoFocus
                    open
                    small
                />
            </Box>
        );
    }

    return (
        <Typography
            className={pendingTransport ? "hover-effect" : ""}
            fontSize="small"
            fontWeight="600"
            onClick={(e) => {
                if (!pendingTransport) return;
                e.stopPropagation();
                setEditing(true);
            }}
        >
            {employee ? (
                <>
                    <PersonIcon
                        fontSize="small"
                        className="text-blue-dark mr-1"
                    />
                    {employee.name}
                </>
            ) : (
                <span className="text-red">{Translations.NoEmployee}</span>
            )}
        </Typography>
    );
};
