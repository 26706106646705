import { ISalaryPeriodTypeEditItem, SalaryPeriodTypeEditItem } from "./salaryPeriodTypeEditItem";

export interface ISalaryPeriodTypeEdit {
    salaryPeriodType: ISalaryPeriodTypeEditItem;
}

export class SalaryPeriodTypeEdit implements ISalaryPeriodTypeEdit {
    salaryPeriodType: SalaryPeriodTypeEditItem;

    constructor();
    constructor(obj: ISalaryPeriodTypeEdit);
    constructor(obj?: any) {
        this.salaryPeriodType = new SalaryPeriodTypeEditItem(obj ? obj.salaryPeriodType : null);
    }
}
