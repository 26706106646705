import { IIdTitle, IdTitle } from "../common/idTitle";
import { Base } from "../../framework/base";
import { TimeFormat } from "../common/enums";
import { INumberTitle, NumberTitle } from "../common/numberTitle";
import { IEmployeeParameters } from "../employee/employeeIParameters";

export interface ISettingsMain {
    employeeParameters: IEmployeeParameters;
    workTimeFormat: TimeFormat;
    workOrderStates: INumberTitle[];
    workOrderClasses: INumberTitle[];
    workOrderTypes: IIdTitle[];
}

export class SettingsMain implements ISettingsMain {
    employeeParameters: IEmployeeParameters;
    workTimeFormat: TimeFormat;
    workOrderStates: NumberTitle[];
    workOrderClasses: INumberTitle[];
    workOrderTypes: IdTitle[];

    constructor();
    constructor(obj: ISettingsMain);
    constructor(obj?: any) {
        this.employeeParameters = obj && obj.employeeParameters || {};
        this.workTimeFormat = obj && obj.workTimeFormat || TimeFormat.DecimalFormat;
        this.workOrderStates = Base.getTypedArray<NumberTitle>(!Base.isNullOrUndefined(obj) ? obj.workOrderStates : null, NumberTitle);
        this.workOrderClasses = Base.getTypedArray<NumberTitle>(!Base.isNullOrUndefined(obj) ? obj.workOrderClasses : null, NumberTitle);
        this.workOrderTypes = Base.getTypedArray<IdTitle>(!Base.isNullOrUndefined(obj) ? obj.workOrderTypes : null, IdTitle);
    }
}