import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Button, debounce, IconButton, TextField } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import { Translations } from "../../models/translations";
import {
    TransportOrderInvoicingState,
    TransportOrderListItemDto,
    TransportOrderListQueryParameters,
    TransportOrderState, transportOrderStates
} from "../../models/transport/transportOrder";
import { apiCall } from "../../services/apiClient";
import {
    setKeyword,
    setReceiverCustomer,
    setSenderCustomer,
    setTimeRange,
    toggleOrderStateSelected
} from "../../store/transport/transportOrdersSlice";
import { MuiDateRangePicker } from "../framework/muiDatepicker";
import { MuiSwitch } from "../framework/muiSwitch";
import { TableDef, TableWrapper } from "../framework/tableWrapper";
import { MainLayout } from "../layout/mainLayout";
import { showError } from "../storage/storageUtils";
import { CustomerSelect } from "./components/customerSelect";
import { getCreatedDateTime, getEmployeeName, transportDateTimeRange, TransportOrderStateIndicator } from "./components/transportUtils";
import { fetchEmployees } from "../../store/employeeSlice";
import { SidebarOrderDetails } from "./components/sidebarOrderDetails";
import { setSideBarOrderDetails } from "../../store/transport/transportVehiclesSlice";

export const TransportOrdersMain = () => {
    const [orderItems, setOrderItems] = useState<TransportOrderListItemDto[]>([]);
    const employees = useAppSelector((state) => state.employees.employees);

    const state = useAppSelector(state => state.transportOrders);
    const dispatch = useAppDispatch();
    const ordersDataChanged = useAppSelector((state) => state.transportOrders.ordersDataChanged);
    const sideBarOrderDetails = useAppSelector((state) => state.transportVehicles.sideBarOrderDetails);

    const fetchOrders = () => {

        const selectedOrderStates = transportOrderStates
            .map(tos => tos.value)
            .filter((tos => state.selectedOrderStates[tos]));

        void apiCall<TransportOrderListItemDto[], TransportOrderListQueryParameters>("TransportOrders", "GET", {
            ...(state.timeRange[0]) && { timeFrameStartDate: state.timeRange[0].toISOString() },
            ...(state.timeRange[1]) && { timeFrameEndDate: state.timeRange[1].toISOString() },
            ...(state.senderCustomer) && { senderCustomerId: state.senderCustomer },
            ...(state.receiverCustomer) && { receiverCustomerId: state.receiverCustomer },
            ...(state.keyword) && { keyword: state.keyword },
            ...(selectedOrderStates.length < transportOrderStates.length && { selectedOrderStates }),
        }).then((res) => {
            setOrderItems(res.data);
        }).catch(showError);
    };

    useEffect(fetchOrders, [state.timeRange, state.senderCustomer, state.receiverCustomer, state.selectedOrderStates, state.keyword, ordersDataChanged]);

    useEffect(() => {
        if (employees.length === 0) {
            dispatch(fetchEmployees() as any);
        }
    }, [employees]);

    function toggleState(toggledState: TransportOrderState) {
        dispatch(toggleOrderStateSelected(toggledState));
    }

    const debouncedSetKeyword = debounce((value: string) => {
        dispatch(setKeyword(value));
    }, 1000);

    return (
        <MainLayout
            topComponent={(
                <div>
                    <Grid2 container spacing={2}>
                        <Grid2>
                            <MuiDateRangePicker
                                labels={["Aikaväli alkaa", "Aikaväli loppuu"]}
                                value={state.timeRange}
                                onChange={(val) => dispatch(setTimeRange(val))}
                            />
                        </Grid2>
                        <Grid2>
                            <CustomerSelect
                                label="Lähettäjä"
                                value={state.senderCustomer}
                                onChange={(val: string) => dispatch(setSenderCustomer(val))}
                            />
                        </Grid2>
                        <Grid2>
                            <CustomerSelect
                                label="Vastaanottaja"
                                value={state.receiverCustomer}
                                onChange={(val: string) => dispatch(setReceiverCustomer(val))}
                            />
                        </Grid2>
                        <Grid2>
                            <TextField
                                label="Hae"
                                onChange={(e) => debouncedSetKeyword(e.currentTarget.value)}
                            />
                        </Grid2>
                    </Grid2>
                </div>
            )}
        >
            <>
                <Grid2 container spacing={2}>
                    <Grid2 xs>
                        <Stack direction="row" spacing={2} mb={3}>
                            <Grid2 container flexGrow={1}>
                                {transportOrderStates.map(tos => (
                                    <MuiSwitch
                                        key={tos.value}
                                        checked={state.selectedOrderStates[tos.value]}
                                        onChange={() => toggleState(tos.value)}
                                        color={tos.colorName}
                                        label={tos.name}
                                    />
                                ))}
                            </Grid2>
                            <Grid2>
                                <Button variant="save" component={Link} startIcon={<AddIcon />} to={{ pathname: "/transport/orders/new", state: { fromPath: "/transport/orders" } }}>Uusi kuljetustilaus</Button>
                            </Grid2>
                        </Stack>
                        <div style={{ minHeight: "600px" }}>
                            <OrdersTable orders={orderItems} onRowClick={(row) => dispatch(setSideBarOrderDetails(row))} />
                        </div>
                    </Grid2>
                    {sideBarOrderDetails && (
                        <Grid2 xs={3}>
                            <Box fontSize="small" sx={{ p: 2, backgroundColor: "lightBlue.main", width: "100%", height: "100%" }}>
                                <SidebarOrderDetails transportOrdersView />
                            </Box>
                        </Grid2>
                    )}

                </Grid2>
            </>
        </MainLayout>
    );
};

interface OrdersTableProps {
    orders: TransportOrderListItemDto[];
    onRowClick?: (row) => void;
    disablePagination?: boolean;
}

export const OrdersTable = ({ orders, onRowClick, disablePagination }: OrdersTableProps) => {
    const employees = useAppSelector((state) => state.employees.employees);

    const table = useMemo<TableDef<TransportOrderListItemDto>>(() => ({
        columns: [
            {
                label: "Tila",
                accessor: "state",
                renderCell: (val: TransportOrderState) => {
                    return (
                        <TransportOrderStateIndicator state={val} />
                    );
                }
            },
            {
                label: "Tilausnumero",
                accessor: "orderNumber",
            },
            {
                label: Translations.Name,
                accessor: "name",
            },
            {
                label: "Noutopaikka",
                accessor: "pickUpCity",
            },
            {
                label: "Noutoväli",
                accessor: "pickUpStartDateTime",
                renderCell: (_, row) => transportDateTimeRange(row.pickUpStartDateTime, row.pickUpEndDateTime),
            },
            {
                label: "Toimituspaikka",
                accessor: "deliveryCity",
            },
            {
                label: "Toimitusväli",
                accessor: "deliveryStartDateTime",
                renderCell: (_, row) => transportDateTimeRange(row.deliveryStartDateTime, row.deliveryEndDateTime),
            },
            {
                label: "Lähettäjä",
                accessor: "senderName",
            },
            {
                label: "Vastaanottaja",
                accessor: "receiverName",
            },
            {
                label: "Luotu",
                accessor: "createdDateTime",
                renderCell: (_, row) => getCreatedDateTime(row?.createdDateTime)
            },
            {
                label: "Luonut",
                accessor: "createdEmployeeId",
                renderCell: (_, row) => getEmployeeName(row?.createdEmployeeId, employees)
            },
            {
                label: "Muokkaa",
                renderCell: (_, row) => (
                    (row.invoicingState !== TransportOrderInvoicingState.InvoicedInternally &&
                     row.invoicingState !== TransportOrderInvoicingState.InvoicedExternally) ? (
                         <Link to={{ pathname: `/transport/orders/${row.id}`, state: { fromPath: "/transport/orders" } }}>
                             <IconButton size="small" color="primary">
                                 <EditIcon fontSize="small" />
                             </IconButton>
                         </Link>
                        ) : null
                ),
            },
        ],
        onRowClick,
    }), [employees]);

    return (
        <TableWrapper tableDef={table} data={orders} disablePagination={disablePagination} />
    );
};
