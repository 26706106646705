import React, { useEffect, useState } from "react";
import { Translations } from "../../../models/translations";
import { getShoppingCartWorkOrders } from "../../../services/workOrderService";
import { AutocompleteOption, AutocompleteWrapper } from "../../framework/muiAutocomplete";

interface Props {
    value: string | null;
    onChange: (val: string | null) => void;
}

export const CartWorkOrderSelect = (props: Props) => {
    const [workOrders, setWorkWorkOrders] = useState<AutocompleteOption[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getShoppingCartWorkOrders()
            .then(results => {
                setWorkWorkOrders(results);
            })
            .finally(() => setLoading(false));
    }, []);

    const onChange = (val: AutocompleteOption) => {
        props.onChange(val?.id ?? null);
    };

    return (
        <AutocompleteWrapper
            label={Translations.Order}
            value={props.value}
            options={workOrders}
            onChange={onChange}
            loading={loading}
            size="small"
        />
    );
};
