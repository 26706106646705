import { Base } from "../../framework/base";
import { IEmployeeEditItem, EmployeeEditItem } from "./employeeEditItem";
import { IIdTitle, IdTitle } from "../common/idTitle";
import { IEmployeeEditCompetencyItem, EmployeeEditCompetencyItem } from "./employeeEditCompetencyItem";
import { IEmployeeParameters } from "./employeeIParameters";
import { IIdTitleDescription, IdTitleDescription } from "../common/idTitleDescription";
import { IVehicleItem, VehicleItem } from "../vehicle/vehicleItem";
import { IUserGroupItem, UserGroupItem } from "../userGroup/userGroupItem";
import { IEmployeeGroupItem, EmployeeGroupItem } from "../employeeGroup/employeeGroupItem";

export interface IEmployeeEdit {
    editorIsEmployee: boolean;
    competencyWarningLimitInDays: number;
    employeeParameters: IEmployeeParameters;
    employee: IEmployeeEditItem;
    vehicles: IVehicleItem[];
    costCenters: IIdTitleDescription[];
    userGroups: IUserGroupItem[];
    salaryTypes: IIdTitle[];
    dayBookingTypes: IIdTitle[];
    employeeGroups: IEmployeeGroupItem[];
    vehicleGroups: IIdTitle[];
    competencies: IEmployeeEditCompetencyItem[];
}

export class EmployeeEdit implements IEmployeeEdit {
    editorIsEmployee: boolean;
    competencyWarningLimitInDays: number;
    employeeParameters: IEmployeeParameters;
    employee: EmployeeEditItem;
    vehicles: VehicleItem[];
    costCenters: IdTitleDescription[];
    userGroups: UserGroupItem[];
    salaryTypes: IdTitle[];
    dayBookingTypes: IdTitle[];
    employeeGroups: EmployeeGroupItem[];
    vehicleGroups: IdTitle[];
    competencies: EmployeeEditCompetencyItem[];

    constructor();
    constructor(obj: IEmployeeEdit);
    constructor(obj?: any) {
        this.editorIsEmployee = obj && obj.editorIsEmployee || false;
        this.competencyWarningLimitInDays = obj && obj.competencyWarningLimitInDays || 30;
        this.employeeParameters = obj && obj.employeeParameters || {};
        this.employee = null;
        if (obj && obj.employee) {
            this.employee = new EmployeeEditItem(obj.employee);
        }
        this.vehicles = Base.getTypedArray<VehicleItem>(obj ? obj.vehicles : null, VehicleItem);
        this.costCenters = Base.getTypedArray<IdTitleDescription>(obj ? obj.costCenters : null, IdTitleDescription);
        this.userGroups = Base.getTypedArray<UserGroupItem>(obj ? obj.userGroups : null, UserGroupItem);
        this.salaryTypes = Base.getTypedArray<IdTitle>(obj ? obj.salaryTypes : null, IdTitle);
        this.dayBookingTypes = Base.getTypedArray<IdTitle>(obj ? obj.dayBookingTypes : null, IdTitle);
        this.employeeGroups = Base.getTypedArray<EmployeeGroupItem>(obj ? obj.employeeGroups : null, EmployeeGroupItem);
        this.vehicleGroups = Base.getTypedArray<IdTitle>(obj ? obj.vehicleGroups : null, IdTitle);
        this.competencies = Base.getTypedArray<EmployeeEditCompetencyItem>(obj ? obj.competencies : null, EmployeeEditCompetencyItem);
    }
}
