import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { Button, Collapse, Divider, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../framework/customStore";
import { Translations } from "../../../../models/translations";
import { fetchSidebarOrders } from "../../../../store/transport/transportVehiclesSlice";
import { MuiDateTimeRangePicker, timeRangePickerValue } from "../../../framework/muiDatepicker";
import { useDebounce } from "../../../hooks/useDebounce";
import { CustomerSelect } from "../customerSelect";
import { TransportOrderDraggable } from "./transportSideBarDraggables";
import { TransportSideBarEntitiesContainer } from "./transportSidebarentitiesContainer";
import { TransportOrderListQueryParameters } from "../../../../models/transport/transportOrder";
import { SidebarOrderDetails } from "../sidebarOrderDetails";

export interface OrdersFilters {
    range: timeRangePickerValue;
    address: string;
    zipCode: string;
    city: string;
}

export const TransportSideBarOrders = () => {
    const [filtersExpanded, setFiltersExpanded] = useState(false);

    const [queryParams, setQueryParams] = useState<TransportOrderListQueryParameters>({});
    const [sidebarOrdersDataChanged, setSidebarOrdersDataChanged] = useState<boolean>(false);

    const setParam = (val: Partial<TransportOrderListQueryParameters>) => {
        setQueryParams({ ...queryParams, ...val });
    };

    return (
        <Stack p={1} spacing={1}>
            <Typography variant="h3" alignSelf="center" m={1}>Tilaukset</Typography>
            <Divider />
            <Stack p={1} spacing={2}>
                <Grid2 container spacing={1}>
                    <Grid2>
                        <CustomerSelect small label="Lähettäjä" value={queryParams.senderCustomerId ?? null} onChange={(val: string) => setParam({ senderCustomerId: val })} />
                    </Grid2>
                    <Grid2>
                        <CustomerSelect small label="Vastaanottaja" value={queryParams.receiverCustomerId ?? null} onChange={(val: string) => setParam({ receiverCustomerId: val })} />
                    </Grid2>
                </Grid2>

                <Collapse in={filtersExpanded}>
                    <Stack spacing={1} paddingX={1}>
                        <Typography variant="h4" pt={1}>Nouto</Typography>

                        <MuiDateTimeRangePicker
                            labels={["Aikaikkuna alku", "Aikaikkuna loppu"]}
                            small
                            value={[
                                queryParams.pickupTimeFrameStart ? new Date(queryParams.pickupTimeFrameStart) : null,
                                queryParams.pickupTimeFrameEnd ? new Date(queryParams.pickupTimeFrameEnd) : null
                            ]}
                            onChange={(range) => {
                                setParam({
                                    pickupTimeFrameStart: range[0]?.toISOString() ?? null,
                                    pickupTimeFrameEnd: range[1]?.toISOString() ?? null
                                });
                            }}
                        />
                        <TextField size="small" label="Postinumero" value={queryParams.pickupZipCode ?? ""} onChange={(e) => setParam({ pickupZipCode: e.target.value })} />
                        <TextField size="small" label="Paikkakunta" value={queryParams.pickupCity ?? ""} onChange={(e) => setParam({ pickupCity: e.target.value })}/>

                        <Typography variant="h4" pt={1}>Toimitus</Typography>

                        <MuiDateTimeRangePicker
                            labels={["Aikaikkuna alku", "Aikaikkuna loppu"]}
                            small
                            value={[
                                queryParams.deliveryTimeFrameStart ? new Date(queryParams.deliveryTimeFrameStart) : null,
                                queryParams.deliveryTimeFrameEnd ? new Date(queryParams.deliveryTimeFrameEnd) : null
                            ]}
                            onChange={(range) => {
                                setParam({
                                    deliveryTimeFrameStart: range[0]?.toISOString() ?? null,
                                    deliveryTimeFrameEnd: range[1]?.toISOString() ?? null
                                });
                            }}
                        />
                        <TextField size="small" label="Postinumero" value={queryParams.deliveryZipCode ?? ""} onChange={(e) => setParam({ deliveryZipCode: e.target.value })} />
                        <TextField size="small" label="Paikkakunta" value={queryParams.deliveryCity ?? ""} onChange={(e) => setParam({ deliveryCity: e.target.value })}/>
                    </Stack>
                </Collapse>
            </Stack>

            <Box alignSelf="end" paddingX={2}>
                <Button
                    onClick={() => setFiltersExpanded(!filtersExpanded)}
                    sx={{ fontSize: "12px", textTransform: "none" }}
                    color="dark"
                    endIcon={<ExpandCircleDownIcon color="primary" style={{ rotate: filtersExpanded ? "180deg": "0deg" }}/>}
                >
                    {filtersExpanded ? Translations.Hide : Translations.Show} lisähakukentät
                </Button>
            </Box>

            <Box paddingX={2}>
                <TransportSideBarEntitiesContainer>
                    <Stack spacing={3} sx={{ maxHeight: "500px", overflowY: "scroll" }}>
                        <OrderDraggables
                            queryParams={queryParams}
                            sidebarOrdersDataChanged={sidebarOrdersDataChanged}
                        />
                    </Stack>
                </TransportSideBarEntitiesContainer>
            </Box>

            <Box p={2}>
                <SidebarOrderDetails
                    sidebarOrdersDataChanged={sidebarOrdersDataChanged}
                    setSidebarOrdersDataChanged={setSidebarOrdersDataChanged}
                />
            </Box>
        </Stack>
    );
};

const OrderDraggables = (props: {
    queryParams: TransportOrderListQueryParameters;
    sidebarOrdersDataChanged?: boolean;
}) => {
    const orders = useAppSelector(state => state.transportVehicles.sidebarOrders);

    const dispatch = useDispatch();

    const getOrders = useDebounce((params: TransportOrderListQueryParameters) => {
        dispatch(fetchSidebarOrders(params));
    }, 1000);

    useEffect(() => {
        getOrders(props.queryParams);
    }, [props.queryParams, props.sidebarOrdersDataChanged]);

    return (
        <Grid2 container spacing={1}>
            {orders.length === 0 && <div>Ei tilauksia</div>}
            {orders.map(order => (
                <Grid2 key={order.id} >
                    <TransportOrderDraggable key={order.id} order={order} />
                </Grid2>
            ))}
        </Grid2>
    );
};
