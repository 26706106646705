/* global JSX */
// Radio
// ***********************************************************************************************************************
import * as React from "react";

export interface IRadioProp {
    title: string;
    tooltip?: string;
    classes?: string;
    name?: string;
    inline?: boolean;
    enabled: boolean;
    checked: boolean;
    onRadioClick?: (e: any) => void;
    onRadioClickBoolean?: (checked: boolean) => void;
}

export class Radio extends React.Component<IRadioProp, {}> {
    handleCheckBoxClick = (e) => {
        const props = this.props;
        e.stopPropagation();
        if (!props.enabled || !props.onRadioClickBoolean) return;
        props.onRadioClickBoolean(e.target.checked);
    };

    render() {
        const props = this.props;
        const checkboxClasses = "form-check" + (props.inline ? " form-check-inline" : "") + (props.classes ? " " + props.classes : "") + (!props.enabled ? " disabled" : "");
        const clickHandler = props.onRadioClick ? props.onRadioClick : this.handleCheckBoxClick;
        return (
            <div className={checkboxClasses} title={props.tooltip ? props.tooltip : props.title}>
                <label>
                    <input type="radio" name={props.name} className="form-check-input" checked={props.checked} onClick={clickHandler} value="" /> {this.props.title}
                </label>
            </div>
        );
    }
}

export interface IRadioButtonProp {
    title?: string | JSX.Element;
    tooltip?: string;
    classes?: string;
    iconClasses?: string;
    name?: string;
    enabled: boolean;
    checked: boolean;
    onRadioClick?: (e: any) => void;
    onRadioClickBoolean?: (checked: boolean) => void;
}

// RadioButton
export class RadioButton extends React.Component<IRadioButtonProp, {}> {
    handleCheckBoxClick = (e) => {
        const props = this.props;
        e.stopPropagation();
        if (!props.enabled || !props.onRadioClickBoolean) return;
        this.props.onRadioClickBoolean(e.target.checked);
    };

    render() {
        const props = this.props;
        const clickHandler = props.onRadioClick ? props.onRadioClick : this.handleCheckBoxClick;
        const tooltip = props.tooltip ? props.tooltip : (typeof props.title === "string" ? props.title : "");
        return (
            <label className={"btn" + (props.classes ? " " + props.classes : "") + (props.checked ? " active" : "")} title={tooltip}>
                <input type="radio" name={props.name} checked={this.props.checked} onClick={clickHandler} value="" /> {props.title ? props.title : <span className={"icon" + (props.iconClasses ? " " + props.iconClasses : "")} aria-hidden="true" />}
            </label>
        );
    }
}