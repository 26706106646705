export enum OvertimePeriodOption {
    None = "none",
    Period = "period",
    Month = "month",
    Week = "week",
}

export enum OvertimeDailyOption {
    Daily = "daily",
    Saturday = "saturday",
}

export enum LunchBreakOption {
    None = "none",
    Deduction = "deduction",
    Paid = "paid",
}

export enum ShiftEffectiveDateLogic {
    StartDate = 0, // Default
    MostHours = 1,
}

export interface IBoundaries {
    boundary: number;
    boundaryValue: number;
}

export class TESCalculationParameters {
    isPaidLunchBreakOn = false;
    lunchBreakOption = LunchBreakOption.Deduction;
    dailyBreakDuration = 0;
    stdShiftDuration = 0;
    dayWorkStartTime = "06:00";
    eveningWorkStartTime = "18:00";
    nightWorkStartTime = "22:00";
    paidBreak = 0;
    paidWaiting = 0;
    isOvertimeCalculationOn = false;
    isEveningCalculationOn = false;
    isNightCalculationOn = false;
    isSundayCalculationOn = false;
    isSaturdayCalculationOn = false;
    isGrandHolidayDayCalculationOn = false;
    overtimePeriodBoundaries: IBoundaries[] = [];
    isMonthlyOvertimeCalculationOn = false;
    isWeeklyOvertimeCalculationOn = false;
    overtimeWeekBoundaries: IBoundaries[] = [];
    overtimePeriodOption = OvertimePeriodOption.None;
    isDailyOvertimeCalculationOn = false;
    isSaturdayOvertimeCalculationOn = false;
    overtimeDailyBoundaries: IBoundaries[] = [];
    overtimeSaturdayBoundaries: IBoundaries[] = [];
    midweekHolidayOvertimeLimitDeduction = 0;
    grandHolidayEveStartTime = "14:00";
    resultRoundingMinutes = 0;
    isResultRoundingMinutesOn = false;
    shiftRoundingMinutes = 0;
    isShiftRoundingMinutesOn = false;
    shiftEffectiveDateLogic = ShiftEffectiveDateLogic.StartDate;
    dailyAllowanceBoundaries: IBoundaries[] = [];

    constructor();
    constructor(obj: TESCalculationParameters);
    constructor(obj?: any) {
        if (obj) {
            this.lunchBreakOption =
                obj.dailyBreakDuration === 0
                    ? LunchBreakOption.None
                    : obj.isPaidLunchBreakOn
                    ? LunchBreakOption.Paid
                    : LunchBreakOption.Deduction;
            this.isPaidLunchBreakOn = obj.isPaidLunchBreakOn;
            this.dailyBreakDuration = obj.dailyBreakDuration;
            this.stdShiftDuration = obj.stdShiftDuration;
            this.dayWorkStartTime = obj.dayWorkStartTime;
            this.eveningWorkStartTime = obj.eveningWorkStartTime;
            this.nightWorkStartTime = obj.nightWorkStartTime;
            this.paidBreak = obj.paidBreak;
            this.paidWaiting = obj.paidWaiting;
            this.isSundayCalculationOn = obj.isSundayCalculationOn;
            this.isSaturdayCalculationOn = obj.isSaturdayCalculationOn;
            this.isGrandHolidayDayCalculationOn =
                obj.isGrandHolidayDayCalculationOn ||
                obj.isGrandHolidayPeriodCalculationOn;
            this.isEveningCalculationOn = obj.isEveningCalculationOn;
            this.isNightCalculationOn = obj.isNightCalculationOn;
            this.isOvertimeCalculationOn = obj.isOvertimeCalculationOn;
            this.overtimePeriodBoundaries = obj.overtimePeriodBoundaries
                ? obj.overtimePeriodBoundaries
                : [];
            this.isMonthlyOvertimeCalculationOn =
                obj.isMonthlyOvertimeCalculationOn;
            this.isWeeklyOvertimeCalculationOn =
                obj.isWeeklyOvertimeCalculationOn;
            this.overtimeWeekBoundaries = obj.overtimeWeekBoundaries
                ? obj.overtimeWeekBoundaries
                : [];
            this.overtimePeriodOption = obj.isMonthlyOvertimeCalculationOn
                ? OvertimePeriodOption.Month
                : this.isWeeklyOvertimeCalculationOn
                ? OvertimePeriodOption.Week
                : this.isOvertimeCalculationOn
                ? OvertimePeriodOption.Period
                : OvertimePeriodOption.None;
            this.isDailyOvertimeCalculationOn =
                obj.isDailyOvertimeCalculationOn;
            this.isSaturdayOvertimeCalculationOn =
                obj.isSaturdayOvertimeCalculationOn;
            this.overtimeDailyBoundaries = obj.overtimeDailyBoundaries
                ? obj.overtimeDailyBoundaries
                : [];
            this.overtimeSaturdayBoundaries = obj.overtimeSaturdayBoundaries
                ? obj.overtimeSaturdayBoundaries
                : [];
            this.midweekHolidayOvertimeLimitDeduction =
                obj.midweekHolidayOvertimeLimitDeduction ?? 0;
            this.grandHolidayEveStartTime = obj.grandHolidayEveStartTime;
            this.resultRoundingMinutes = obj.resultRoundingMinutes || 30;
            this.isResultRoundingMinutesOn = obj.isResultRoundingMinutesOn;
            this.shiftRoundingMinutes = obj.shiftRoundingMinutes || 30;
            this.isShiftRoundingMinutesOn = obj.isShiftRoundingMinutesOn;
            this.shiftEffectiveDateLogic =
                obj.shiftEffectiveDateLogic ||
                ShiftEffectiveDateLogic.StartDate;
            this.dailyAllowanceBoundaries = obj.dailyAllowanceBoundaries || [];
        }
    }
}
