import { IEditItem, EditItem } from "../common/editItem";
import { Base } from "../../framework/base";

export interface ISalaryWorkTimePeriodItem extends IEditItem {
    startDate: string;
    endDate: string;
}

export class SalaryWorkTimePeriodItem extends EditItem implements ISalaryWorkTimePeriodItem {
    startDate: string;
    endDate: string;

    constructor();
    constructor(obj: ISalaryWorkTimePeriodItem);
    constructor(obj?: any) {
        super(obj);
        this.startDate = obj && obj.startDate || "";
        this.endDate = obj && obj.endDate || "";
    }

    static sortSalaryWorkTimePeriodItems(items: ISalaryWorkTimePeriodItem[]) {
        if (!items || items.length < 2) return;
        items.sort((a: ISalaryWorkTimePeriodItem, b: ISalaryWorkTimePeriodItem) => {
            if (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) return 0;
            const result = Base.stringCompare(a.startDate, b.startDate);
            return result !== 0 ? result : Base.stringCompare(a.endDate, b.endDate);
        });
    }
}

export class SaveSalaryWorkTimePeriodItem extends EditItem {
    startDate: string;
    endDate: string;

    constructor();
    constructor(obj: ISalaryWorkTimePeriodItem);
    constructor(obj?: any) {
        super(obj);
        this.startDate = obj && obj.startDate || "";
        this.endDate = obj && obj.endDate || "";
    }
}
