import { Base } from "../../framework/base";
import { IDocument, Document } from "../document/document";
import { EnumHelper, WorkOrderCategory } from "../common/enums";

export interface IInvoiceEditItemWorkOrder {
    id: string;
    number: number;
    parentNumber: number;
    customerName: string;
    state: number;
    stateStr: string;
    name: string;
    category: number;
    siteName: string;
    siteNumber: string;
    employeeName: string;
    startTime: number;
    endTime: number;
    fullDay: boolean;
    documents: IDocument[];

    isProject(): boolean;
    isWork(): boolean;
    isTask(): boolean;
}

export class InvoiceEditItemWorkOrder implements IInvoiceEditItemWorkOrder {
    id: string;
    number: number;
    parentNumber: number;
    customerName: string;
    state: number;
    stateStr: string;
    name: string;
    category: number;
    siteName: string;
    siteNumber: string;
    employeeName: string;
    startTime: number;
    endTime: number;
    fullDay: boolean;
    documents: IDocument[];

    constructor();
    constructor(obj: IInvoiceEditItemWorkOrder);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.parentNumber = obj && obj.parentNumber || 0;
        this.customerName = obj && obj.customerName || "";
        this.state = obj && obj.state || 0;
        this.stateStr = obj && obj.stateStr || "";
        this.name = obj && obj.name || "";
        this.category = obj && obj.category || 0;
        this.siteName = obj && obj.siteName || "";
        this.siteNumber = obj && obj.siteNumber || "";
        this.employeeName = obj && obj.employeeName || "";
        this.startTime = obj && obj.startTime || 0;
        this.endTime = obj && obj.endTime || 0;
        this.fullDay = obj && obj.fullDay || false;
        this.documents = obj && obj.documents.map(d => new Document(d)) || [];
    }

    isProject(): boolean {
        return EnumHelper.isEqual(this.category, WorkOrderCategory.Project);
    }

    isWork(): boolean {
        return EnumHelper.isEqual(this.category, WorkOrderCategory.Work);
    }

    isTask(): boolean {
        return EnumHelper.isEqual(this.category, WorkOrderCategory.Task);
    }

    static sortInvoiceEditItemWorkOrders(items: IInvoiceEditItemWorkOrder[], column: string, asc: boolean) {
        if (Base.isNullOrUndefined(items) || items.length < 2) return;
        let sortFunc: (a: IInvoiceEditItemWorkOrder, b: IInvoiceEditItemWorkOrder) => number;
        if (column === "name") sortFunc = (a: IInvoiceEditItemWorkOrder, b: IInvoiceEditItemWorkOrder) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.name, b.name)); };
        else if (column === "customerName") sortFunc = (a: IInvoiceEditItemWorkOrder, b: IInvoiceEditItemWorkOrder) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.customerName, b.customerName)); };
        else if (column === "siteName") sortFunc = (a: IInvoiceEditItemWorkOrder, b: IInvoiceEditItemWorkOrder) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.siteName, b.siteName)); };
        else if (column === "siteNumber") sortFunc = (a: IInvoiceEditItemWorkOrder, b: IInvoiceEditItemWorkOrder) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.siteNumber, b.siteNumber)); };
        else if (column === "employeeName") sortFunc = (a: IInvoiceEditItemWorkOrder, b: IInvoiceEditItemWorkOrder) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.employeeName, b.employeeName)); };
        else if (column === "state") sortFunc = (a: IInvoiceEditItemWorkOrder, b: IInvoiceEditItemWorkOrder) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.state, b.state)); };
        else if (column === "startTime") sortFunc = (a: IInvoiceEditItemWorkOrder, b: IInvoiceEditItemWorkOrder) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.startTime, b.startTime)); };
        else sortFunc = (a: IInvoiceEditItemWorkOrder, b: IInvoiceEditItemWorkOrder) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.number, b.number)); };
        const mainSortFunc = (a: IInvoiceEditItemWorkOrder, b: IInvoiceEditItemWorkOrder) => {
            const result = sortFunc(a, b);
            return !(result > -0.001 && result < 0.001) ? result : ((Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.number, b.number)));
        };
        items.sort(mainSortFunc);
    }
}
