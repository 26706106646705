import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { TransportOrderStateIndicator } from "../components/transportUtils";
import { TransportOrderListItemDto, TransportOrderState, transportOrderStates } from "../../../models/transport/transportOrder";

interface IOrderStateEditMenuProps {
    order: TransportOrderListItemDto;
    handleOrderDelivery: () => void;
    handleOrderPickUp: () => void;
    handleSetOrderAsPlanned: () => void;
};

type HandlerFunction = () => void;

export const OrderStateEditMenu = ({ order, handleOrderDelivery, handleOrderPickUp, handleSetOrderAsPlanned }: IOrderStateEditMenuProps) => {
    const stateHandlers: Partial<Record<TransportOrderState, HandlerFunction>> = {
        [TransportOrderState.Planned]: handleSetOrderAsPlanned,
        [TransportOrderState.PickedUp]: handleOrderPickUp,
        [TransportOrderState.Delivered]: handleOrderDelivery
    };

    const handleClick = (newState: TransportOrderState) => {
        if (order.state === newState) return;
        const handler = stateHandlers[newState];
        if (handler) {
            handler();
        }
    };

    return (
        <>
            {transportOrderStates.filter(i => (
                i.value !== TransportOrderState.OrderReceived && i.value !== TransportOrderState.OrderCreated
            )).map((os) => (
                <MenuItem
                    onClick={() => handleClick(os.value)}
                    dense
                    key={os.value}
                    selected={order.state === os.value}
                >
                    <TransportOrderStateIndicator state={os.value} />
                    {os.name}
                </MenuItem>
            ))}
        </>
    );
};
