import { WorkOrderAcknowledgementTypeSendingType } from "../common/enums";

export interface IWorkOrderEditAcknowledgementType {
    id: string;
    name: string;
    sendingType: number;
}

export class WorkOrderEditAcknowledgementType implements IWorkOrderEditAcknowledgementType {
    id: string;
    name: string;
    sendingType: number;

    constructor();
    constructor(obj: IWorkOrderEditAcknowledgementType);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
        this.sendingType = obj && obj.sendingType || WorkOrderAcknowledgementTypeSendingType.DoNotSend;
    }

    static getSendingTypeById(items: IWorkOrderEditAcknowledgementType[], id: string): number {
        const item = items.find(i => i.id === id);
        return item ? item.sendingType : WorkOrderAcknowledgementTypeSendingType.DoNotSend;
    }

    static getNameById(items: IWorkOrderEditAcknowledgementType[], id: string): string {
        const item = items.find(i => i.id === id);
        return item ? item.name : "";
    }
}