import { Base } from "../../framework/base";
import { WorkShiftTimeSlotItem } from "./workShiftTimeSlotItem";

export interface IWorkShiftTimeSlotItems {
    items: WorkShiftTimeSlotItem[];
    hasMore: boolean;
    page: number;
}

export class WorkShiftTimeSlotItems implements IWorkShiftTimeSlotItems {
    items: WorkShiftTimeSlotItem[];
    hasMore: boolean;
    page: number;

    constructor();
    constructor(obj: IWorkShiftTimeSlotItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = Base.getTypedArray<WorkShiftTimeSlotItem>(obj ? obj.items : null, WorkShiftTimeSlotItem);
    }
}
