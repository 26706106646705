import { Base } from "../../framework/base";
import { IToolEditItem, ToolEditItem } from "./toolEditItem";
import { IVehicleItem, VehicleItem } from "../vehicle/vehicleItem";

export interface IToolEdit {
    tool: IToolEditItem;
    vehicles: IVehicleItem[];
}

export class ToolEdit implements IToolEdit {
    tool: ToolEditItem;
    vehicles: VehicleItem[];

    constructor();
    constructor(obj: IToolEdit);
    constructor(obj?: any) {
        this.tool = null;
        if (obj && obj.tool) {
            this.tool = new ToolEditItem(obj.tool);
        }
        this.vehicles = Base.getTypedArray<VehicleItem>(!Base.isNullOrUndefined(obj) ? obj.vehicles : null, VehicleItem);
    }
}