import { Base } from "../../framework/base";
import { WorkOrderTypeSiteRoutePointUsageType, WorkOrderTypeEmployeeUsageType, WorkOrderTypeVehicleUsageType, EnumHelper } from "../common/enums";

export interface IWorkOrderEditWorkOrderTypeItem {
    id: string;
    title: string;
    siteRoutePointUsageType: WorkOrderTypeSiteRoutePointUsageType;
    employeeUsageType: WorkOrderTypeEmployeeUsageType;
    vehicleUsageType: WorkOrderTypeVehicleUsageType;
    showAttachments: boolean;
    showComment: boolean;
    showDescription: boolean;
    showMap: boolean;
    showRoutePointBookings: boolean;
    showWorkOrderAcknowledgementType: boolean;
    showEstimatedHours: boolean;
    workShiftTimeSlotTypeIds: string[];

    getTitle(): string;
    routePointsUsed(): boolean;
}

export class WorkOrderEditWorkOrderTypeItem implements IWorkOrderEditWorkOrderTypeItem {
    id: string;
    title: string;
    siteRoutePointUsageType: WorkOrderTypeSiteRoutePointUsageType;
    employeeUsageType: WorkOrderTypeEmployeeUsageType;
    vehicleUsageType: WorkOrderTypeVehicleUsageType;
    showAttachments: boolean;
    showComment: boolean;
    showDescription: boolean;
    showMap: boolean;
    showRoutePointBookings: boolean;
    showWorkOrderAcknowledgementType: boolean;
    showEstimatedHours: boolean;
    workShiftTimeSlotTypeIds: string[];

    constructor();
    constructor(obj: IWorkOrderEditWorkOrderTypeItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.title = obj && obj.title || "";
        this.siteRoutePointUsageType = obj && obj.siteRoutePointUsageType || WorkOrderTypeSiteRoutePointUsageType.Site;
        this.employeeUsageType = obj && obj.employeeUsageType || WorkOrderTypeEmployeeUsageType.Multiple;
        this.vehicleUsageType = obj && obj.vehicleUsageType || WorkOrderTypeVehicleUsageType.Multiple;
        this.showAttachments = obj && !Base.isNullOrUndefined(obj.showAttachments) ? obj.showAttachments : true;
        this.showComment = obj && !Base.isNullOrUndefined(obj.showComment) ? obj.showComment : true;
        this.showDescription = obj && !Base.isNullOrUndefined(obj.showDescription) ? obj.showDescription : true;
        this.showMap = obj && !Base.isNullOrUndefined(obj.showMap) ? obj.showMap : true;
        this.showRoutePointBookings = obj && !Base.isNullOrUndefined(obj.showRoutePointBookings) ? obj.showRoutePointBookings : true;
        this.showWorkOrderAcknowledgementType = obj && !Base.isNullOrUndefined(obj.showWorkOrderAcknowledgementType) ? obj.showWorkOrderAcknowledgementType : true;
        this.showEstimatedHours = obj && !Base.isNullOrUndefined(obj.showEstimatedHours) ? obj.showEstimatedHours : true;
        this.workShiftTimeSlotTypeIds = obj && obj.workShiftTimeSlotTypeIds || [];
    }

    getTitle(): string {
        return this.title;
    }

    routePointsUsed(): boolean {
        return EnumHelper.isEqual(this.siteRoutePointUsageType, WorkOrderTypeSiteRoutePointUsageType.RoutePoint);
    }

    static getWorkOrderEditWorkOrderTypeItemById(items: IWorkOrderEditWorkOrderTypeItem[], id: string): IWorkOrderEditWorkOrderTypeItem {
        if (!items || items.length < 1) return null;
        if (!id || id === "") return items[0];
        return items.find(i => i.id === id) ?? items[0];
    }
}
