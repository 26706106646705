// WorkOrderDialogProductBooking - MODULE
// ***********************************************************************************************************************
import downloadjs from "downloadjs";
import * as React from "react";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import * as documentService from "../../services/documentService";
import { Translations } from "../../models/translations";
import { PropertyDialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { IWorkOrderEditItemProductBooking } from "../../models/work/workOrderEditItemProductBooking";
import { IIdTitle, IdTitle } from "../../models/common/idTitle";
import { IWorkOrderEditProductItem, WorkOrderEditProductItem } from "../../models/work/workOrderEditProductItem";
import { IDocument, Document } from "../../models/document/document";
import { DocumentList } from "../document/documentList";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { IEmployeeItem } from "../../models/employee/employeeItem";
import { PricePerUnit } from "../framework/pricePerUnit";
import { UserParameters } from "../../models/employee/userParameters";
import { DocumentType } from "../../models/common/enums";

// WorkOrderDialogProductBookingDialog
// ***********************************************************************************************************************
export interface IWorkOrderDialogProductBookingDialogProp {
    classes?: string;
    isReadOnly: boolean;
    productBooking: IWorkOrderEditItemProductBooking;
    employees: IEmployeeItem[];
    products: IWorkOrderEditProductItem[];
    measureUnits: IIdTitle[];
    onOk: (employeeId: string, productId: string, amount: number, unitPrice: number, unit: number, cost: number, comment: string, documents: IDocument[], removedDocumentIds: string[]) => void;
    onCancel: () => void;
}

interface IWorkOrderDialogProductBookingDialogState {
    productId: string;
    employeeId: string;
    amountStr: string;
    unitPriceStr: string;
    costStr: string;
    comment: string;
    unit: number;
    documents: IDocument[];
    removedDocumentIds: string[];
    selecteDocumentId: string;
}

export class WorkOrderDialogProductBookingDialog extends React.Component<IWorkOrderDialogProductBookingDialogProp, IWorkOrderDialogProductBookingDialogState> {
    constructor(props: IWorkOrderDialogProductBookingDialogProp) {
        super(props);
        const productBooking = props.productBooking;
        this.state = {
            productId: productBooking.productId,
            employeeId: productBooking.employeeId,
            amountStr: props.productBooking.isNew() ? "" : productBooking.amount.toLocaleFixed(1),
            costStr: productBooking.cost.toLocaleFixed(2),
            unitPriceStr: productBooking.unitPrice.toLocaleFixed(Base.getDecimalAmountForPrice(productBooking.unitPrice)),
            unit: productBooking.unit,
            comment: productBooking.comment,
            documents: productBooking.documents.slice(0),
            removedDocumentIds: [],
            selecteDocumentId: null
        };
    }

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "amount") {
            this.setState({ amountStr: value });
        } else if (name === "unitPrice") {
            this.setState({ unitPriceStr: value });
        } else if (name === "cost") {
            this.setState({ costStr: value });
        } else if (name === "employeeId") {
            this.setState({ employeeId: value });
        } else if (name === "product") {
            const amount = this.state.amountStr.toDecimal();
            let unit = this.state.unit;
            let cost = this.state.costStr.toDecimal();
            let unitPrice = this.state.unitPriceStr.toDecimal();
            const product = this.props.products.find(i => i.id === value);
            if (!Base.isNullOrUndefined(product)) {
                unitPrice = product.unitPrice;
                unit = product.unit;
                cost = (amount * unitPrice).roundToDecimals(2);
            }
            this.setState({ productId: value, unit: unit, costStr: cost.toLocaleFixed(2), unitPriceStr: unitPrice.toLocaleFixed(Base.getDecimalAmountForPrice(unitPrice)) });
        } else if (name === "comment") {
            this.setState({ comment: value });
        }
    };

    handleBlur = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "amount") {
            if (value) {
                const amount = value.toDecimal();
                const unitPrice = this.state.unitPriceStr.toDecimal();
                this.setState({ amountStr: amount.toLocaleFixed(2), costStr: (amount * unitPrice).roundToDecimals(2).toLocaleFixed(2) });
            } else {
                this.setState({ amountStr: "", costStr: (0).roundToDecimals(2).toLocaleFixed(2) });
            }
        } else if (name === "unitPrice") {
            const amount = this.state.amountStr.toDecimal();
            const unitPrice = value.toDecimal();
            this.setState({ unitPriceStr: unitPrice.toLocaleFixed(Base.getDecimalAmountForPrice(unitPrice)), costStr: (amount * unitPrice).roundToDecimals(2).toLocaleFixed(2) });
        } else if (name === "cost") {
            this.setState({ costStr: value.toDecimal().toLocaleFixed(2) });
        }
    };

    handleDocumentsAdd = (fileList: FileList) => {
        const obj = this;
        const productBooking = this.props.productBooking;
        let selecteDocumentId = obj.state.selecteDocumentId;
        Document.addFileListToDocuments(productBooking.id, this.state.documents, fileList, (document: Document, index: number) => {
            selecteDocumentId = document.id;
        }).then(documents => {
            this.setState({
                documents: documents,
                selecteDocumentId: selecteDocumentId
            });
        });
    };

    handleDocumentEdit = (id: string, comment: string, documentType: DocumentType) => {
        if (!id) return;
        const documents = this.state.documents.slice(0);
        const document = documents.find(i => i.id === id);
        if (!document) return;
        document.comment = comment;
        document.documentType = documentType;
        this.setState({ documents: documents });
    };

    handleDocumentRemove = (id: string) => {
        if (!id) return;
        const documents = this.state.documents.filter(i => i.id !== id);
        const removedDocumentIds = this.state.removedDocumentIds.slice(0);
        removedDocumentIds.push(id);
        this.setState({ documents: documents, removedDocumentIds: removedDocumentIds });
    };

    handleDocumentDownload = (id: string) => {
        if (!id) return;
        const document = this.state.documents.find(i => i.id === id);
        if (!document) return;
        if (document.file) {
            downloadjs(document.file, document.reference); //, "image/jpeg"
            return;
        }
        if (document.isNew()) return;
        documentService.getDocument(id).catch(error => {
            store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
        });
    };

    private static checkErrors = (state: IWorkOrderDialogProductBookingDialogState): Promise<boolean> => {
        return new Promise<boolean>((resolve) => {
            if (!state.employeeId) {
                store.customStore.dispatch(storeActions.showErrorMessage(Translations.EmployeeMustBeDefined));
                return resolve(false);
            }
            if (!state.productId) {
                store.customStore.dispatch(storeActions.showErrorMessage(Translations.ProductBookingProductMustBeDefined));
                return resolve(false);
            }
            //if (state.amountStr.toDecimal() < 0.01) {
            //    store.customStore.dispatch(storeActions.showErrorMessage(Translations.ProductBookingAmountMustBeGreaterThanZero));
            //    return resolve(false);
            //}
            return resolve(true);
        });
    };

    private static getWarningMessage = (state: IWorkOrderDialogProductBookingDialogState): Promise<string> => {
        return new Promise<string>((resolve) => {
            return resolve("");
        });
    };

    private static validate = (state: IWorkOrderDialogProductBookingDialogState, saveCallback: () => void): Promise<void> => {
        return WorkOrderDialogProductBookingDialog.checkErrors(state).then(success => {
            if (success) {
                return WorkOrderDialogProductBookingDialog.getWarningMessage(state).then(warnings => {
                    if (!warnings) {
                        saveCallback();
                        return new Promise<void>((resolve) => { resolve(); });
                    }
                    return new Promise<void>((resolve) => {
                        store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, warnings + Base.lf + Translations.DoYouReallyWantToSaveData,
                            () => {
                                store.customStore.dispatch(storeActions.clearConfirmation());
                                saveCallback();
                                resolve();
                            },
                            () => {
                                store.customStore.dispatch(storeActions.clearConfirmation());
                                resolve();
                            }, null));
                    });
                });
            } else {
                return new Promise<void>((resolve) => { resolve(); });
            }
        });
    };

    handleOkClick = () => {
        const obj = this;
        const state = this.state;
        WorkOrderDialogProductBookingDialog.validate(state, () => {
            obj.props.onOk(state.employeeId, state.productId, state.amountStr.toDecimal(), state.unitPriceStr.toDecimal(), state.unit, state.costStr.toDecimal(), state.comment, state.documents, state.removedDocumentIds);
        });
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "productBooking px600" + (props.classes ? " " + props.classes : "");
        const amountTitle = Translations.Amount + " (" + IdTitle.getTitleById(props.measureUnits, state.unit.toString(10)) + ")";
        const unitStr = props.measureUnits.find(m => m.id === this.state.unit.toString())?.title;
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.ProductBooking + " - " + (!props.productBooking.isNew() ? WorkOrderEditProductItem.getTitleById(props.products, state.productId) : Translations.New)}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-8">
                                <div className="form-group required">
                                    <label className="control-label">{Translations.Product}</label>
                                    <select className={"custom-select" + (props.isReadOnly ? " disabled" : "")} name="product" title={Translations.Product} value={this.state.productId} onChange={this.handleChange} autoFocus={true} disabled={props.isReadOnly}>
                                        {this.props.products.map((product) =>
                                            <option key={product.id} value={product.id}>{product.getOptionTitle()}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group required">
                                    <label className="control-label">{Translations.Employee}</label>
                                    <select className="custom-select" name="employeeId" title={Translations.Employee} value={state.employeeId} disabled={props.isReadOnly} onChange={this.handleChange}>
                                        {props.employees.map((employee) =>
                                            <option key={employee.id} value={employee.id}>{employee.name}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className={UserParameters.isOwnerAdmin() ? "col-4" : "col-12"}>
                                <div className="form-group required">
                                    <label className="control-label">{amountTitle}</label>
                                    <input type="text" className="form-control" name="amount" title={amountTitle} value={this.state.amountStr} readOnly={props.isReadOnly} disabled={props.isReadOnly} onChange={this.handleChange} onBlur={this.handleBlur} maxLength={5} />
                                </div>
                            </div>
                            {UserParameters.isOwnerAdmin() &&
                                <>
                                    <div className="col-4">
                                        <div className="form-group required">
                                            <label className="control-label">
                                                {Translations.UnitPrice}&nbsp;
                                                <PricePerUnit unit={unitStr} wrapInParenthesis={true} />
                                            </label>
                                            <input type="text" className="form-control" name="unitPrice" title={Translations.UnitPrice} value={this.state.unitPriceStr} readOnly={props.isReadOnly} disabled={props.isReadOnly} onChange={this.handleChange} onBlur={this.handleBlur} />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-group required">
                                            <label className="control-label">{Translations.Cost}</label>
                                            <input type="text" className="form-control" name="cost" title={Translations.Cost} value={this.state.costStr} readOnly={true} disabled={true} maxLength={8} />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="control-label">{Translations.Comment}</label>
                                    <textarea className="form-control" name="comment" title={Translations.Comment} value={this.state.comment} readOnly={props.isReadOnly} disabled={props.isReadOnly} onChange={this.handleChange} maxLength={1000} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <DocumentList
                                classes="col-12"
                                relatedObjectId={props.productBooking.id}
                                selectedId={state.selecteDocumentId}
                                title={Translations.Attachments}
                                documents={state.documents}
                                isReadOnly={props.isReadOnly}
                                changeDocumentTypeEnabled={true}
                                canShowCamera={false}
                                onEditDocument={this.handleDocumentEdit}
                                onRemoveDocument={this.handleDocumentRemove}
                                onDownloadDocument={this.handleDocumentDownload}
                                onAddDocuments={this.handleDocumentsAdd}
                                onAddPhoto={null}
                            />
                        </div>
                    </div>}
                    buttons={[{ title: Translations.Save, classes: "btn-primary" + (props.isReadOnly ? " d-none" : ""), enabled: !props.isReadOnly, onClick: this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}

// WorkOrderDialogProductBookingListLine
export interface IWorkOrderDialogProductBookingListLineProp {
    productBooking: IWorkOrderEditItemProductBooking;
    employees: IEmployeeItem[];
    products: IWorkOrderEditProductItem[];
    measureUnits: IIdTitle[];
    selectedId: string;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

export class WorkOrderDialogProductBookingListLine extends React.Component<IWorkOrderDialogProductBookingListLineProp, {}> {
    render() {
        const props = this.props;
        const productBooking = props.productBooking;
        const product = props.products.find(i => i.id === productBooking.productId);
        const measureUnit = IdTitle.getTitleById(this.props.measureUnits, productBooking.unit.toString(10));
        const amount = productBooking.amount.toLocaleFixed(2) + " " + measureUnit;
        const cost = UserParameters.isOwnerAdmin() ? productBooking.cost.toLocaleFixed(2) + " €" : "";

        if (!productBooking.isStorageBooking) {
            return (
                <div className={"row line" + (productBooking.id === props.selectedId ? " selected" : "")} onClick={() => { props.onClick(productBooking.id); }} onDoubleClick={() => { props.onDoubleClick(productBooking.id); }}
                    title={Base.getStringWithSeparators([product.title, amount, UserParameters.isOwnerAdmin() ? cost : "", productBooking.comment], ", ")}
                >
                    <div className="col-6">{product.title}</div>
                    <div className="col-3 text-right">{amount}</div>
                    <div className="col-3 text-right">{cost}</div>
                </div>
            );
        } else {
            return (
                <div className={"row line"}
                    title={Base.getStringWithSeparators([product.title, amount, UserParameters.isOwnerAdmin() ? cost : "", productBooking.comment], ", ")}
                >
                    <div className="col-6">{product.title}</div>
                    <div className="col-3 text-right">{amount}</div>
                    <div className="col-3 text-right">{cost}</div>
                </div>
            );
        }
    }
}
