// ReportSubMenuList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { IReportListItem } from "../../models/report/reportListItem";

export interface IReportSubMenuListProp {
    items: IReportListItem[];
    selectedId: string;
    onLineClick: (id: string) => void;
}

export class ReportSubMenuList extends React.Component<IReportSubMenuListProp, {}> {
    render() {
        const props = this.props;
        const items = props.items;
        return (
            <div className="sub">
                {items.map((item) =>
                    <div key={item.id} className={"menuItem" + (props.selectedId === item.id ? " active" : "")} onClick={() => { props.onLineClick(item.id); }}>
                        {item.name}
                    </div>
                )}
            </div>
        );
    }
}