// WorkInvoiceDataMainReadyWorkOrkdersList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import { Base } from "../../framework/base";
import { ListHeaderColumn, ListHeaderCheckBoxColumn } from "../framework/listHeaderColumn";
import { ToolTitle } from "../framework/toolTitle";
import { IIdTitle } from "../../models/common/idTitle";
import { ButtonDropdown } from "../framework/dropdown";
import { IWorkOrderInvoiceDataListItem } from "../../models/work/workOrderInvoiceDataListItem";
import { WorkNoBox } from "./workNoBox";
import { ListMode, ListModeSelector } from "../framework/listModeSelector";
import { AppUtils } from "../../models/common/appUtils";
import { Button } from "../framework/button";
import { TimeFormat } from "../../models/common/enums";

// WorkInvoiceDataMainReadyWorkOrkdersListLineHeader
export interface IWorkInvoiceDataMainReadyWorkOrkdersListLineHeaderProp {
    sortColumn: string;
    sortOrderIsAsc: boolean;
    onColumnClick: (column: string) => void;
    onColumnCheckboxChange: (column: string, checked: boolean) => void;
}

export class WorkInvoiceDataMainReadyWorkOrkdersListLineHeader extends React.Component<IWorkInvoiceDataMainReadyWorkOrkdersListLineHeaderProp, {}> {
    handleColumnClick = (column: string) => {
        this.props.onColumnClick(column);
    };

    handleColumnCheckboxChange = (column: string, checked: boolean) => {
        this.props.onColumnCheckboxChange(column, checked);
    };

    render() {
        const props = this.props;
        return (
            <div className="row title">
                <ListHeaderCheckBoxColumn
                    title=""
                    column="state"
                    classes="col-1 withStateEnd"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                    onCheckboxChange={this.handleColumnCheckboxChange}
                />
                <ListHeaderColumn
                    title={Translations.Number}
                    column="number"
                    classes="col-1"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Customer}
                    column="customer"
                    classes="col-2"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Site}
                    column="siteName"
                    classes="col-2"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.WorkTitle}
                    column="name"
                    classes="col-3"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Time}
                    column="startTime"
                    classes="col-1"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.RelatedToInvoice}
                    column="invoiceNumber"
                    classes="col-2"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
            </div>
        );
    }
}

// WorkInvoiceDataMainReadyWorkOrkdersListLine
export interface IWorkInvoiceDataMainReadyWorkOrkdersListLineProp {
    workTimeFormat: TimeFormat;
    item: IWorkOrderInvoiceDataListItem;
    selectedId: string;
    checked: boolean;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
    onCheckboxChange: (id: string, checked: boolean) => void;
}

export class WorkInvoiceDataMainReadyWorkOrkdersListLine extends React.Component<IWorkInvoiceDataMainReadyWorkOrkdersListLineProp, {}> {
    handleCheckBoxClick = (e) => {
        e.stopPropagation();
        this.props.onCheckboxChange(this.props.item.id, !this.props.checked);
    };

    render() {
        const props = this.props;
        const item = props.item;
        return (
            <div className={"row line state" + item.state.toString(10) + (item.id === props.selectedId ? " selected" : "")} onClick={() => { props.onClick(item.id); }} onDoubleClick={() => { props.onDoubleClick(item.id); }} >
                <div className="col-1 workOrderStateEndContainer">
                    <div className={"stateEnd active state" + item.state.toString(10)}>
                        <span className={"icon state" + item.state.toString(10)} aria-hidden="true" />
                    </div>
                    <input type="checkbox" checked={props.checked} onClick={this.handleCheckBoxClick} value="" />
                </div>
                <div className="col-1 number" onClick={() => props.onDoubleClick(item.id)}>{item.number.toString(10) + (item.parentNumber ? " (" + item.parentNumber.toString(10) + ")" : "")}</div>
                <div className="col-2">{item.customerName}</div>
                <div className="col-2">{item.siteName + (item.siteNumber ? " (" + item.siteNumber + ")" : "")}</div>
                <div className="col-3">{item.name}</div>
                <div className="col-1">{Base.timeToDateStr(item.startTime)}</div>
                <div className="col-2 ra">{item.invoiceNumbers}</div>
            </div>
        );
    }
}

// WorkInvoiceDataMainReadyWorkOrkdersListCard
export class WorkInvoiceDataMainReadyWorkOrkdersListCard extends React.Component<IWorkInvoiceDataMainReadyWorkOrkdersListLineProp, {}> {
    render() {
        const props = this.props;
        const item = props.item;
        return (
            <div className={"cardContent state" + item.state.toString(10) + (item.id === props.selectedId ? " selected" : "")} onClick={() => { props.onClick(item.id); }} onDoubleClick={() => { props.onDoubleClick(item.id); }}>
                <div className="item">
                    <WorkNoBox
                        id={item.id}
                        state={item.state}
                        number={item.number}
                        parentNumber={item.parentNumber}
                        onClick={props.onDoubleClick}
                    />
                    <div className="cardTitle">{item.customerName}</div>
                </div>
                <div className="cardLineContainer">
                    <div className="cardLine">{Base.getStringWithSeparators([item.siteName, item.siteAddress], ", ")}</div>
                    {item.employeeNames &&
                        <div className="cardLine">{item.employeeNames}</div>
                    }
                    <div className="cardLine">{AppUtils.getTimeDurationStr(item.startTime, item.endTime, true)}</div>
                    <div className="cardLine">{item.name}</div>
                    {item.invoiceNumbers &&
                        <div className="cardLine">{Translations.RelatedToInvoice + ": " + item.invoiceNumbers}</div>
                    }
                    {item.isProject() &&
                        <div>
                            <div className="row taskComtainer">
                                {item.projectTasks.map((i) =>
                                    <WorkNoBox
                                        key={i.id}
                                        classes="left mb-1"
                                        id={i.id}
                                        state={i.state}
                                        number={i.number}
                                        parentNumber={0}
                                        onClick={props.onDoubleClick}
                                    />
                                )}
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

// WorkInvoiceDataMainReadyWorkOrkdersList
export interface IWorkInvoiceDataMainReadyWorkOrkdersListProp {
    workTimeFormat: TimeFormat;
    pageSize: number;
    page: number;
    hasMore: boolean;
    filter: string;
    sortColumn: string;
    sortOrderIsAsc: boolean;
    items: IWorkOrderInvoiceDataListItem[];
    workOrderStates: IIdTitle[];
    state: number;
    selectedId: string;
    checkedIds: string[];
    selectedState: number;
    isLoading: boolean;
    searchItems: (pageSize: number, page: number, filter: string, state: number, sortColumn: string, sortOrderIsAsc: boolean, resetItems: boolean, refreshList: boolean, successCallback: () => void) => Promise<void>;
    onChangeFilter: (filter: string, state: number) => void;
    onChangeSortColumn: (sortColumn: string, state: number) => void;
    onChangeColumnCheckbox: (column: string, checked: boolean, state: number) => void;
    onChangeLineCheckbox: (id: string, checked: boolean, state: number) => void;
    onLineClick: (id: string, state: number) => void;
    onLineDoubleClick: (id: string) => void;
    onEdit: (state: number) => void;
    onPrint: (state: number) => void;
    onApproveToBeInvoiced: () => void;
}

export interface IWorkInvoiceDataMainReadyWorkOrkdersListState {
    listMode: ListMode;
    showButtons: boolean;
}

export class WorkInvoiceDataMainReadyWorkOrkdersList extends React.Component<IWorkInvoiceDataMainReadyWorkOrkdersListProp, IWorkInvoiceDataMainReadyWorkOrkdersListState> {
    private commandRowDivWidthThreshold = 930;
    private commandRowDiv: HTMLDivElement;
    private containerDiv: HTMLDivElement;
    private listDiv: HTMLDivElement;

    constructor(props) {
        super(props);
        this.state = {
            listMode: ListMode.List,
            showButtons: true
        };
    }

    handleScrollSub = Base.debounce((obj: WorkInvoiceDataMainReadyWorkOrkdersList) => {
        const props = obj.props;
        if (props.isLoading || !props.hasMore) return;
        if (obj.listDiv.offsetHeight - (obj.containerDiv.clientHeight + obj.containerDiv.scrollTop) < 5) {
            props.searchItems(props.pageSize, props.page + 1, props.filter, props.state, props.sortColumn, props.sortOrderIsAsc, false, false, null);
        }
    }, 100);

    handleScroll = (event) => {
        this.handleScrollSub(this);
    };

    handleWindowsResize = () => {
        if (!this.commandRowDiv) return;
        const showButtons = this.commandRowDiv.clientWidth > this.commandRowDivWidthThreshold;
        if (showButtons === this.state.showButtons) return;
        this.setState({
            showButtons: showButtons
        });
    };

    componentDidMount(): void {
        this.containerDiv.addEventListener("scroll", this.handleScroll);
        window.addEventListener("resize", this.handleWindowsResize);
        this.handleWindowsResize();
    }

    componentWillUnmount(): void {
        this.containerDiv.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("resize", this.handleWindowsResize);
    }

    handleChangeListMode = (listMode: ListMode) => {
        this.setState({
            listMode: listMode
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        const items = props.items;
        return (
            <div className="row">
                <div className="col-12">
                    <div className="row commandRow main" ref={(elem) => { this.commandRowDiv = elem; }}>
                        <ToolTitle
                            classes="smaller"
                            title={Translations.ReadyWorkOrders}
                        />
                        {/*{state.showButtons*/}
                        {/*    ? <div className="col">*/}
                        {/*        <Button*/}
                        {/*            classes="blue upper left"*/}
                        {/*            title={Translations.Approve}*/}
                        {/*            tooltip={Translations.ApproveToBeInvoiced}*/}
                        {/*            enabled={!!props.selectedId || props.checkedIds.length > 0}*/}
                        {/*            onClick={props.onApproveToBeInvoiced}*/}
                        {/*        />*/}
                        {/*        <ToolButton*/}
                        {/*            title={Translations.Edit}*/}
                        {/*            enabled={!!props.selectedId}*/}
                        {/*            classes={"round left edit"}*/}
                        {/*            onClick={props.onEdit}*/}
                        {/*        />*/}
                        {/*        <ToolButton*/}
                        {/*            title={Translations.Print}*/}
                        {/*            enabled={!!props.selectedId || props.checkedIds.length > 0}*/}
                        {/*            classes={"round left print"}*/}
                        {/*            onClick={props.onPrint}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*    : <div className="col">*/}
                        {/*        <Button*/}
                        {/*            classes="blue upper left"*/}
                        {/*            title={Translations.Approve}*/}
                        {/*            tooltip={Translations.ApproveToBeInvoiced}*/}
                        {/*            enabled={!!props.selectedId || props.checkedIds.length > 0}*/}
                        {/*            onClick={props.onApproveToBeInvoiced}*/}
                        {/*        />*/}
                        {/*        <ButtonDropdown*/}
                        {/*            menuRight*/}
                        {/*            classes={"dropdown-tool"}*/}
                        {/*            disabled={!props.selectedId && props.checkedIds.length < 1}*/}
                        {/*            actions={[*/}
                        {/*                { title: (Translations.Edit), onClick: props.onEdit, disabled: !props.selectedId },*/}
                        {/*                { title: (Translations.Print), onClick: props.onPrint, disabled: !props.selectedId && props.checkedIds.length < 1 },*/}
                        {/*            ]}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*}*/}
                        <div className="col">
                            <ListModeSelector
                                listMode={state.listMode}
                                onChangeListMode={this.handleChangeListMode}
                            />
                        </div>
                        <div className="col-auto right">
                            <Button
                                classes={"right btn-primary"}
                                title={Translations.Approve}
                                tooltip={Translations.ApproveToBeInvoiced}
                                enabled={!!props.selectedId || props.checkedIds.length > 0}
                                onClick={props.onApproveToBeInvoiced}
                            />
                            <ButtonDropdown
                                menuRight
                                classes={"right dropdown-tool"}
                                disabled={!props.selectedId && props.checkedIds.length < 1}
                                actions={[
                                    { title: (Translations.Edit), onClick: () => props.onEdit(props.state), disabled: !props.selectedId },
                                    { title: (Translations.Print), onClick: () => props.onPrint(props.state), disabled: !props.selectedId && props.checkedIds.length < 1 },
                                ]}
                            />
                        </div>
                        {/*    <div className="col-auto right">*/}
                        {/*        <ListSearchFilter*/}
                        {/*            searchFilter={props.filter}*/}
                        {/*            onSearchClick={props.onChangeFilter} />*/}
                        {/*    </div>*/}
                    </div>
                    {state.listMode === ListMode.List &&
                        <div>
                            <WorkInvoiceDataMainReadyWorkOrkdersListLineHeader
                                sortColumn={props.sortColumn}
                                sortOrderIsAsc={props.sortOrderIsAsc}
                                onColumnClick={(sortColumn: string) => props.onChangeSortColumn(sortColumn, props.state)}
                                onColumnCheckboxChange={(column: string, checked: boolean) => props.onChangeColumnCheckbox(column, checked, props.state)}
                            />
                        </div>
                    }
                    <div className={"listContainer main workOrdersContainer halfHeight" + (state.listMode === ListMode.Card ? " card" : "")} ref={(elem) => { this.containerDiv = elem; }}>
                        <div className="list workOrders striped">
                            {state.listMode === ListMode.List
                                ? <div className="lineContainer" ref={(elem) => { this.listDiv = elem; }}>
                                    {items.map((item) =>
                                        <WorkInvoiceDataMainReadyWorkOrkdersListLine
                                            key={item.id}
                                            workTimeFormat={props.workTimeFormat}
                                            item={item}
                                            selectedId={props.selectedId}
                                            checked={props.checkedIds.indexOf(item.id) > -1}
                                            onClick={(id: string) => props.onLineClick(id, props.state)}
                                            onDoubleClick={props.onLineDoubleClick}
                                            onCheckboxChange={(id: string, checked: boolean) => props.onChangeLineCheckbox(id, checked, props.state)}
                                        />
                                    )}
                                </div>
                                : <div className="cardContainer" ref={(elem) => { this.listDiv = elem; }}>
                                    <div className="row">
                                        {items.map((item) =>
                                            <div className="col-4 card" key={item.id}>
                                                <WorkInvoiceDataMainReadyWorkOrkdersListCard
                                                    key={item.id}
                                                    workTimeFormat={props.workTimeFormat}
                                                    item={item}
                                                    selectedId={props.selectedId}
                                                    checked={props.checkedIds.indexOf(item.id) > -1}
                                                    onClick={(id: string) => props.onLineClick(id, props.state)}
                                                    onDoubleClick={props.onLineDoubleClick}
                                                    onCheckboxChange={(id: string, checked: boolean) => props.onChangeLineCheckbox(id, checked, props.state)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}