import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import {
    IBoundaries,
    TESCalculationParameters,
} from "../../models/calculation/TESCalculationParameters";
import { CalcBoundaries } from "./CalcBoundaries";

interface IRoundingProps {
    translate: (str: string) => string;
    settings: TESCalculationParameters;
    setSettings: React.Dispatch<React.SetStateAction<TESCalculationParameters>>;
}

export const CalcDailyAllowance = ({
    translate,
    settings,
    setSettings,
}: IRoundingProps) => {
    const { dailyAllowanceBoundaries } = settings;

    const handleChange = (boundaries: IBoundaries[]) => {
        const newSettings = { ...settings };
        newSettings.dailyAllowanceBoundaries = boundaries;
        setSettings(newSettings);
    };

    return (
        <Grid2 container direction="column" p={3}>
            <Grid2>
                <Typography variant="h3">
                    {translate("dailyAllowances")}
                </Typography>
            </Grid2>

            <Grid2>
                <CalcBoundaries
                    isOn={dailyAllowanceBoundaries.length > 0}
                    boundaries={dailyAllowanceBoundaries}
                    translate={translate}
                    fillBoundaries={handleChange}
                    default50={6}
                    default100={10}
                    label50={translate("partDailyAllowance")}
                    label100={translate("dailyAllowance")}
                />
            </Grid2>
        </Grid2>
    );
};
