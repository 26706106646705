import React, { memo, useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import LoopIcon from "@mui/icons-material/Loop";
import { useAppDispatch, useAppSelector } from "../../../framework/customStore";
import { extendTimeline, fetchVehicles, moveTimelineLeft } from "../../../store/transport/transportVehiclesSlice";
import { RepeatingAndNonRepeatingPlans, RepeatingTransportPlan } from "../../../store/transport/transportPlanTemplatesSlice";
import { TimelineGrid, TimelineGridRow } from "../../timelinePlanner/timelinePlannerGrid";
import { TransportTimelineCell } from "../transportTimeline/TransportTimelineCell";
import { DateActions } from "../transportTimeline/DateActions";
import { NewPlanDialog } from "../transportTimeline/NewPlanDialog";
import { convertDuration, getEnabledDays } from "../transportTimeline/Utils";
import { TransportPlanListItemDto } from "../../../models/transport/transportPlan";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";

interface TransportPlansTimelineGridProps {
    plans: RepeatingAndNonRepeatingPlans;
}

export const TransportPlansTimelineGrid = ({ plans }: TransportPlansTimelineGridProps) => {
    const dispatch = useAppDispatch();
    const timelineStart = useAppSelector(state => state.transportVehicles.timelineStart);
    const timelineEnd = useAppSelector(state => state.transportVehicles.timelineEnd);

    const handleLoadMore = useCallback(() => {
        dispatch(extendTimeline());
    }, []);

    const handleMoveLeft = useCallback(() => {
        dispatch(moveTimelineLeft());
    }, []);

    useEffect(() => {
        dispatch(fetchVehicles() as any);
    }, []);

    const listRepeating = () => {
        const repeatingPlans = plans?.repeatingPlans || [];
        return repeatingPlans.map((repeatingPlan, i) => repeatingPlan?.plans?.length > 0
            ? <RepeatingPlanTimelineRow key={`repeating-${i}`} plan={repeatingPlan} />
            : <div key={`repeating-${i}`} />
        );
    };
    const listNonRepeating = () => {
        const singlePlans = plans?.nonRepeatingPlans || [];
        return singlePlans.map((plan) => <PlanTimelineRow key={plan?.id} plan={plan} />);
    };
    return (
        <>
            <TimelineGrid
                startDate={timelineStart}
                endDate={timelineEnd}
                loadMore={handleLoadMore}
                moveLeft={handleMoveLeft}
            >
                { listRepeating() }
                { listNonRepeating() }
            </TimelineGrid>
            <NewPlanDialog />
        </>
    );
};

interface RepeatingPlanTimelineRowProps {
    plan: RepeatingTransportPlan;
}

export const RepeatingPlanTimelineRow = memo(({ plan }: RepeatingPlanTimelineRowProps) => {
    const repeatingPlans = plan?.plans;
    const { week1, week2 } = getEnabledDays(plan?.enabledDays);
    const duration = convertDuration(plan?.duration);
    return (
        <>
            <TimelineGridRow
                infoColumn={
                    <Box sx={{ fontSize: "small", pt: 1, pb: 1 }}>
                        <Stack justifyContent="space-between">
                            <div style={{ justifyContent: "space-between" }} className="bold flex align-items-center overflow-break">
                                <div className="left">
                                    <LoopIcon
                                        sx={{ mr: 1, color: "primary.dark" }}
                                        fontSize="small"
                                    />
                                    <span>
                                        {plan.name}{" "}
                                    </span>
                                </div>
                                <div className="right">
                                    <Link to={{ pathname: `/transport/plans/template/${plan.id}`, state: { fromPath: "/transport/plans/grid" } }}>
                                        <IconButton color="primary">
                                            <EditIcon />
                                        </IconButton>
                                    </Link>
                                </div>
                            </div>
                            <div className="bold align-items-center overflow-break" style={{ marginTop: "5px" }}>
                                <span>
                                    Toistuu: { dayjs(plan?.repeatStartDate).format("DD.MM") } - { dayjs(plan?.repeatEndDate).format("DD.MM") } <br />
                                    Viikko 1: { week1.map(d => d?.slice(0, 2)).join(", ") } <br />
                                    Viikko 2: { week2.map(d => d?.slice(0, 2)).join(", ") } <br />
                                    Kesto: {duration.days}d {duration.hours}h {duration.minutes}min <br />
                                </span>
                            </div>
                        </Stack>
                    </Box>
                }
            >
                { repeatingPlans && repeatingPlans.map((repeatingPlan) => (
                    <TransportTimelineCell
                        planId={repeatingPlan.id}
                        key={repeatingPlan.id}
                        editReturnPath={"/transport/plans/grid"}
                        editVehicleEnabled
                        editEmployeeEnabled
                    />
                ))}
            </TimelineGridRow>
        </>
    );
});

interface PlanTimelineRowProps {
    plan: TransportPlanListItemDto;
}

export const PlanTimelineRow = memo(({ plan }: PlanTimelineRowProps) => {
    return (
        <>
            <TimelineGridRow
                renderColumnHeader={(date: Date) => (
                    <DateActions date={date} vehicleId={plan.id} />
                )}
                infoColumn={
                    <Box sx={{ fontSize: "small", pt: 1, pb: 1 }}>
                        <Stack direction="row" justifyContent="space-between">
                            <div className="bold flex align-items-center overflow-break">
                                <span>
                                    {plan.name}{" "}
                                </span>
                            </div>
                        </Stack>
                    </Box>
                }
            >
                { plan &&
                    <TransportTimelineCell
                        planId={plan.id}
                        key={plan.id}
                        editReturnPath={"/transport/plans/grid"}
                        editVehicleEnabled
                        editEmployeeEnabled
                    />
                }
            </TimelineGridRow>
        </>
    );
});

