import { IIdTitle, IdTitle } from "../common/idTitle";
import { Base } from "../../framework/base";
import { IInvoiceListItem, InvoiceListItem } from "./invoiceListItem";

export interface IInvoiceListItems {
    items: IInvoiceListItem[];
    hasMore: boolean;
    page: number;
    invoiceStates: IIdTitle[];
}

export class InvoiceListItems implements IInvoiceListItems {
    items: InvoiceListItem[];
    hasMore: boolean;
    page: number;
    invoiceStates: IdTitle[];

    constructor();
    constructor(obj: IInvoiceListItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = Base.getTypedArray<InvoiceListItem>(!Base.isNullOrUndefined(obj) ? obj.items : null, InvoiceListItem);
        this.invoiceStates = Base.getTypedArray<IdTitle>(!Base.isNullOrUndefined(obj) ? obj.invoiceStates : null, IdTitle);
    }
}