import { Base } from "../../framework/base";
import { IWorkOrderEditItemRoutePointCheckBooking, WorkOrderEditItemRoutePointCheckBooking } from "./workOrderEditItemRoutePointCheckBooking";

export interface IWorkOrderEditItemRoutePointBooking {
    id: string;
    employeeId: string;
    vehicleId: string;
    number: number;
    pointName: string;
    pointRef: string;
    comment: string;
    bookingTime: number;
    rowId: string;
    checks: IWorkOrderEditItemRoutePointCheckBooking[];

    isNew(): boolean;
}

export class WorkOrderEditItemRoutePointBooking implements IWorkOrderEditItemRoutePointBooking {
    id: string;
    employeeId: string;
    vehicleId: string;
    number: number;
    pointName: string;
    pointRef: string;
    comment: string;
    bookingTime: number;
    rowId: string;
    checks: WorkOrderEditItemRoutePointCheckBooking[];

    constructor();
    constructor(obj: IWorkOrderEditItemRoutePointBooking);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.employeeId = obj && obj.employeeId || "";
        this.vehicleId = obj && obj.vehicleId || "";
        this.number = obj && obj.number || 0;
        this.pointName = obj && obj.pointName || "";
        this.pointRef = obj && obj.pointRef || "";
        this.comment = obj && obj.comment || "";
        this.bookingTime = obj && obj.bookingTime || 0;
        this.rowId = obj && obj.rowId || "";
        this.checks = Base.getTypedArray<WorkOrderEditItemRoutePointCheckBooking>(!Base.isNullOrUndefined(obj) ? obj.checks : null, WorkOrderEditItemRoutePointCheckBooking);
    }

    isNew():boolean {
        return !this.rowId;
    }

    static sortWorkOrderEditItemRoutePointBookings(items: WorkOrderEditItemRoutePointBooking[], column: string, asc: boolean) {
        if (Base.isNullOrUndefined(items) || items.length < 2) return;
        items.sort((a: WorkOrderEditItemRoutePointBooking, b: WorkOrderEditItemRoutePointBooking) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.number, b.number)); });
    }
}

export class WorkOrderEditItemSaveRoutePointBooking {
    id: string;
    employeeId: string;
    vehicleId: string;
    number: number;
    pointName: string;
    pointRef: string;
    comment: string;
    bookingTime: number;
    rowId: string;

    constructor();
    constructor(obj: IWorkOrderEditItemRoutePointBooking);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.employeeId = obj && obj.employeeId || "";
        this.vehicleId = obj && obj.vehicleId || null;
        this.number = obj && obj.number || 0;
        this.pointName = obj && obj.pointName || "";
        this.pointRef = obj && obj.pointRef || "";
        this.comment = obj && obj.comment || "";
        this.bookingTime = obj && obj.bookingTime || 0;
        this.rowId = obj && obj.rowId || "";
    }
}