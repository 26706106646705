import * as React from "react";
import { Base } from "../../framework/base";
import { IWorkTimeAccountTransaction } from "../../models/workTimeAccount/workTimeAccountTransaction";

interface ISettingsEmployeeWorkHourAccountTransactionsProps {
    title: string;
    items: IWorkTimeAccountTransaction[];
}

export class SettingsEmployeeWorkHourAccountTransactions extends React.Component<ISettingsEmployeeWorkHourAccountTransactionsProps, {}> {
    render() {
        const props = this.props;
        return (
            <div>
                <div className="commandRow">
                    <label className="control-label listTitle">{props.title}</label>
                </div>
                <div className="listContainer workHourTransactionsContainer">
                    {props.items.length > 0 &&
                        <div className="list workHourTransactions">
                            {props.items.map((item) =>
                                <div className="row line" key={item.id}>
                                    <div className="col-2">{Base.timeToDateStr(item.date)}</div>
                                    <div className="col-2">{item.amount.toString(10)}</div>
                                    <div className="col-3">{item.modifyUserName}</div>
                                    <div className="col-5">{item.description}</div>
                                </div>
                            )}
                        </div>
                    }
                </div>
            </div>
        );
    }
}