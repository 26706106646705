/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-misused-promises */
import React, { useState, useEffect } from "react";
import { cancelTransport, createTransport, createTransportTask, updateTransportState } from "../../../services/transportService";
import { getLatestTask, removeLatestTaskByType } from "../../../services/transportOrderService";
import { getTransportPlan, updatePlanState } from "../../../services/transportPlanService";
import { TransportPlanState } from "../../../models/transport/transportPlan";
import { TransportState, TransportTaskType } from "../../../models/transport/transport";
import { TransportOrderInvoicingState, TransportOrderListItemDto, TransportOrderState, transportOrderStates } from "../../../models/transport/transportOrder";
import { Box, Button, Menu, Tooltip, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../framework/customStore";
import { showApiError, showApiSuccess, showConfirm } from "../../framework/formUtils";
import { setGridDataChanged, updateSidebarOrderState } from "../../../store/transport/transportVehiclesSlice";
import { setOrdersDataChanged } from "../../../store/transport/transportOrdersSlice";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import { OrderStateEditMenu } from "./OrderStateEditMenu";
import { TransportOrderStateIndicator } from "../components/transportUtils";

interface ITransportOrderActionProps {
    order: TransportOrderListItemDto;
    displayAsMenuItem?: boolean;
    transportOrdersView?: boolean;
    onClose?: () => void;
}

export const TransportOrderAction = ({ order, displayAsMenuItem = false, transportOrdersView = false, onClose }: ITransportOrderActionProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const planFromState = useAppSelector(
        (state) => state.transportVehicles.plans[order.transportPlanId]
    );
    const [fetchedPlan, setFetchedPlan] = useState(null);
    const plan = planFromState ?? fetchedPlan;
    const ordersDataChanged = useAppSelector((state) => state.transportOrders.ordersDataChanged as boolean);
    const gridDataChanged = useAppSelector((state) => state.transportVehicles.gridDataChanged as boolean);
    const transportPlanId = order.transportPlanId || null;
    const employeeId = plan?.employee?.id;
    const invalidPlanState = plan?.state === TransportPlanState.Planning || plan?.state === TransportPlanState.Todo;

    const getOrderStateName = (orderState) => {
        const stateInfo = transportOrderStates.find(state => state.value === orderState);
        return stateInfo ? stateInfo.name : "Tuntematon tila";
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const fetchPlan = async() => {
            if (!planFromState && order.transportPlanId) {
                try {
                    const planData = await getTransportPlan(order.transportPlanId);
                    setFetchedPlan(planData);
                } catch (error) {
                    showApiError("Kuljetussuunnitelman haku epäonnistui.");
                }
            }
        };
        void fetchPlan();
    }, [planFromState, order.transportPlanId]);

    const shouldHideAction =
        order.invoicingState === TransportOrderInvoicingState.AddedToInvoice ||
        order.invoicingState === TransportOrderInvoicingState.InvoicedInternally ||
        order.invoicingState === TransportOrderInvoicingState.InvoicedExternally ||
        plan?.state === TransportPlanState.Completed;

    const shouldDisableAction =
        !transportPlanId ||
        !employeeId ||
        invalidPlanState;

    const tooltipTitle =
        (!transportPlanId && t("transport.orderAction.planRequired")) ||
        (!employeeId && t("transport.orderAction.employeeRequired")) ||
        (invalidPlanState && t("transport.orderAction.invalidState")) ||
        t("transport.orderAction.invalidAction");

    const vehicleId = plan?.vehicle?.id || null;
    const transportOrderId = order.id;
    let transportId = plan?.transportId;

    const setTransportAndPlanInTransport = async() => {
        // Check if Transport Plan State is not already InTransport before updating state to InTransport
        if (plan.state !== TransportPlanState.InTransport) {
            const params = {
                planId: transportPlanId,
                newState: TransportPlanState.InTransport
            };
            await updatePlanState(params);
            await updateTransportState(transportId, TransportState.InTransport);
        }
    };

    const createNewTransport = async() => {
        // Only create a new transport when order.transportId and plan.transportId are both empty
        if (!order.transportId && !plan.transportId) {
            const newTransport = await createTransport({ transportPlanId, employeeId, vehicleId });
            transportId = newTransport.id;
        }
    };

    const updateStateAfterChanges = (newState: TransportOrderState) => {
        if (transportOrdersView) {
            dispatch(setOrdersDataChanged(!ordersDataChanged));
        } else {
            dispatch(setGridDataChanged(!gridDataChanged));
        }
        dispatch(updateSidebarOrderState(newState));
        if (displayAsMenuItem) onClose();
    };

    const handleOrderDelivery = () => {
        const setOrderDelivered = async() => {
            try {
                await createNewTransport();
                await createTransportTask(transportId, transportOrderId, TransportTaskType.Delivered);
                await setTransportAndPlanInTransport();

                updateStateAfterChanges(TransportOrderState.Delivered);

                showApiSuccess(t("transport.orderAction.deliverySuccess"));

            } catch (error) {
                showApiError(t("transport.orderAction.deliveryFail"));
            }
        };
        showConfirm(
            t("transport.orderAction.deliveryConfirmation"),
            setOrderDelivered
        );
    };

    const handleOrderPickUp = () => {
        const setOrderPickedUp = async() => {
            try {
                await createNewTransport();

                // Add the picked up task if order state is not picked up or delivered
                if (order.state !== TransportOrderState.PickedUp && order.state !== TransportOrderState.Delivered)
                {
                    await createTransportTask(transportId, transportOrderId, TransportTaskType.PickedUp);
                } else if (order.state === TransportOrderState.Delivered) {
                    await removeLatestTaskByType(transportOrderId, TransportTaskType.Delivered);
                    const latestTaskType = await getLatestTask(transportOrderId);
                    if (!latestTaskType) {
                        await createTransportTask(transportId, transportOrderId, TransportTaskType.PickedUp);
                    }
                }

                await setTransportAndPlanInTransport();

                updateStateAfterChanges(TransportOrderState.PickedUp);

                showApiSuccess(t("transport.orderAction.pickUpSuccess"));

            } catch (error) {
                showApiError(t("transport.orderAction.pickUpFail"));
            }
        };

        showConfirm(
            t("transport.orderAction.pickUpConfirmation"),
            setOrderPickedUp
        );
    };

    const handleSetPlanned = () => {
        const setOrderAsPlanned = async() => {
            try {
                await removeLatestTaskByType(transportOrderId, TransportTaskType.Delivered);
                await removeLatestTaskByType(transportOrderId, TransportTaskType.PickedUp);

                // Check if other orders on the transport are delivered or picked up before removing transport
                const noOrdersInTransit = plan.orders.every(order => {
                    if (order.id === transportOrderId) {
                        return true;
                    }
                    // Check if the order's state is neither Delivered nor InTransport
                    return order.state !== TransportOrderState.Delivered &&
                           order.state !== TransportOrderState.PickedUp;
                });

                if (noOrdersInTransit) {
                    await cancelTransport(transportId);
                }

                updateStateAfterChanges(TransportOrderState.Planned);

                showApiSuccess(t("transport.orderAction.setAsPlannedSuccess"));

            } catch (error) {
                showApiError(t("transport.orderAction.setAsPlannedFail"));
            }
        };

        showConfirm(
            t("transport.orderAction.setAsPlannedConfirmation"),
            setOrderAsPlanned
        );
    };

    if (displayAsMenuItem && (shouldDisableAction || shouldHideAction)) return null;

    if (displayAsMenuItem) {
        return (
            <>
                <Divider />
                <OrderStateEditMenu
                    order={order}
                    handleOrderDelivery={handleOrderDelivery}
                    handleOrderPickUp={handleOrderPickUp}
                    handleSetOrderAsPlanned={handleSetPlanned}
                />
            </>
        );
    }

    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" mr={2}>
            <Box display="flex" alignItems="center">
                <TransportOrderStateIndicator state={order.state} />
                <Typography variant="subtitle2" ml={1}>
                    {getOrderStateName(order.state)}
                </Typography>
            </Box>
            {!shouldHideAction && (
                shouldDisableAction ? (
                    <Tooltip title={tooltipTitle}>
                        <Box>
                            <Button onClick={handleMenuOpen} variant="outlined" size="small" disabled>
                                {t("transport.orderAction.changeState")}
                            </Button>
                        </Box>
                    </Tooltip>
                ) : (
                    <Button onClick={handleMenuOpen} variant="outlined" size="small">
                        {t("transport.orderAction.changeState")}
                    </Button>
                )
            )}
            <Menu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                <OrderStateEditMenu
                    order={order}
                    handleOrderDelivery={handleOrderDelivery}
                    handleOrderPickUp={handleOrderPickUp}
                    handleSetOrderAsPlanned={handleSetPlanned}
                />
            </Menu>
        </Box>
    );
};
