import React, { memo, useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import PersonIcon from "@mui/icons-material/Person";
import { useAppDispatch, useAppSelector } from "../../../framework/customStore";
import { TransportPlanListItemDto, TransportPlanState } from "../../../models/transport/transportPlan";
import {
    extendTimeline,
    fetchTransportPlans,
    moveTimelineLeft,
} from "../../../store/transport/transportVehiclesSlice";
import {
    TimelineGrid,
    TimelineGridRow,
} from "../../timelinePlanner/timelinePlannerGrid";
import { TransportTimelineCell } from "../transportTimeline/TransportTimelineCell";
import { DateActions } from "../transportTimeline/DateActions";
import { NewPlanDialog } from "../transportTimeline/NewPlanDialog";
import { fetchEmployees } from "../../../store/employeeSlice";
import { EmployeeListItemDto } from "../../../models/employee/employee";
import { TransportPlansWithoutEmployeeRow } from "./TransportPlansWithoutEmployeeRow";

export const TransportEmployeesTimelineGrid = () => {
    const dispatch = useAppDispatch();
    const gridDataChanged = useAppSelector((state) => state.transportVehicles.gridDataChanged);

    const timelineStart = useAppSelector((state) => state.transportVehicles.timelineStart);
    const timelineEnd = useAppSelector((state) => state.transportVehicles.timelineEnd);
    const selectedPlanStates = useAppSelector((state) => state.transportVehicles.selectedPlanStates);

    const employees = useAppSelector((state) => state.employees.employees);
    const selectedEmployees = useAppSelector((state) => state.transportVehicles.selectedEmployees);
    const selectedEmployeeGroups = useAppSelector((state) => state.transportVehicles.selectedEmployeeGroups);

    const plans = useAppSelector((state) => state.transportVehicles.plans);
    const plansWithEmployee = Object.values(plans).filter(plan => plan?.employee?.id);
    const plansWithoutEmployee = Object.values(plans).filter((item) => item.employee === null);

    const handleLoadMore = useCallback(() => {
        dispatch(extendTimeline());
    }, []);

    const handleMoveLeft = useCallback(() => {
        dispatch(moveTimelineLeft());
    }, []);

    useEffect(() => {
        if(employees.length === 0) {
            dispatch(fetchEmployees() as any);
        }
    }, [employees]);

    useEffect(() => {
        let states = Object.entries(selectedPlanStates)
            .filter(([, val]) => val === true)
            .map(([state]) => state) as unknown as TransportPlanState[];

        if (states.length === 5) {
            states = [];
        }
        dispatch(
            fetchTransportPlans({
                timeFrameStartDate: timelineStart.toISOString(),
                timeFrameEndDate: timelineEnd.toISOString(),
                ...(selectedEmployees.length > 0 && {
                    employeeIds: selectedEmployees,
                }),
                ...(states.length > 0 && { states }),
            }) as any
        );
    }, [timelineStart, timelineEnd, selectedEmployees, selectedPlanStates, gridDataChanged]);

    return (
        <>
            <TimelineGrid
                startDate={timelineStart}
                endDate={timelineEnd}
                loadMore={handleLoadMore}
                moveLeft={handleMoveLeft}
            >
                {employees.length > 0 && employees.map((employee) => {
                    if (
                        selectedEmployees.length > 0 &&
                        !selectedEmployees.includes(employee?.id)
                    ) {
                        return null;
                    }
                    if (
                        selectedEmployeeGroups.length > 0 &&
                        !selectedEmployeeGroups.includes(employee?.groupId)
                    ) {
                        return null;
                    }
                    return (
                        <EmployeeTimelineRow
                            key={employee.id}
                            employee={employee}
                            plans={plansWithEmployee}
                        />
                    );
                })}
                {plansWithoutEmployee.length > 0 &&
                    <TransportPlansWithoutEmployeeRow
                        plans={plansWithoutEmployee}
                    />
                }
            </TimelineGrid>
            <NewPlanDialog />
        </>
    );
};

interface EmployeeTimelineRowProps {
    employee: EmployeeListItemDto;
    plans: TransportPlanListItemDto[];
}

export const EmployeeTimelineRow = memo(({ plans, employee }: EmployeeTimelineRowProps) => {
    const employeesPlans = plans.filter(plan => plan?.employee?.id === employee?.id);
    return (
        <>
            <TimelineGridRow
                renderColumnHeader={(date: Date) => (
                    <DateActions date={date} employeeId={employee?.id} />
                )}
                infoColumn={
                    <Box sx={{ fontSize: "small", pt: 1, pb: 1 }}>
                        <Stack direction="row" justifyContent="space-between">
                            <div className="bold flex align-items-center overflow-break">
                                <PersonIcon
                                    sx={{ mr: 1, color: "primary.dark" }}
                                    fontSize="small"
                                />
                                <span>
                                    {employee?.firstName}{" "}
                                    {employee?.lastName}
                                </span>
                            </div>
                        </Stack>
                    </Box>
                }
            >
                {employeesPlans &&
                    employeesPlans.map(({ id }) => (
                        <TransportTimelineCell
                            planId={id}
                            key={id}
                            editReturnPath="/transport/employees"
                            editVehicleEnabled
                            editEmployeeEnabled
                        />
                    ))}
            </TimelineGridRow>
        </>
    );
});
