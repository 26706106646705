import * as React from "react";
import { RadioButton } from "../framework/radio";
import { INumberTitle } from "../../models/common/numberTitle";

//WorkOrderClassMultiSelect
export interface IWorkOrderClassMultiSelectProp {
    classes?: string;
    isReadOnly?: boolean;
    selectedWorkOrderClasses: number[];
    workOrderClasses: INumberTitle[];
    onToggleClass: (workOrderClass: number) => void;
}

interface IWorkOrderClassMultiSelectState {
    uniqueClasses: number[];
    uniqueClassesHash: string;
}

export class WorkOrderClassMultiSelect extends React.Component<IWorkOrderClassMultiSelectProp, IWorkOrderClassMultiSelectState> {
    getUniqueClasses = (props: IWorkOrderClassMultiSelectProp): number[] => {
        const result = [...Array.from(new Set(props.selectedWorkOrderClasses))];
        result.sort();
        return result;
    };

    getUniqueClassesHash = (uniqueProjectStates: number[]): string => {
        return uniqueProjectStates.join(",");
    };

    constructor(props: IWorkOrderClassMultiSelectProp) {
        super(props);
        const uniqueClasses = this.getUniqueClasses(props);
        this.state = {
            uniqueClasses: uniqueClasses,
            uniqueClassesHash: this.getUniqueClassesHash(uniqueClasses)
        };
    }

    componentDidUpdate(prevProps: IWorkOrderClassMultiSelectProp, prevState: IWorkOrderClassMultiSelectState): void {
        const props = this.props;
        const uniqueClasses = this.getUniqueClasses(props);
        const uniqueClassesHash = this.getUniqueClassesHash(uniqueClasses);
        if (prevState.uniqueClassesHash === uniqueClassesHash) return;
        this.setState({
            uniqueClasses: uniqueClasses,
            uniqueClassesHash: uniqueClassesHash
        });
    }

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <div className={"btn-group btn-group-toggle workOrderClassBar" + (props.classes ? " " + props.classes : "")}>
                {props.workOrderClasses.map((workOrderClass) =>
                    <RadioButton
                        key={workOrderClass.number}
                        classes={"btn-secondary editable class" + workOrderClass.number.toString(10)}
                        tooltip={workOrderClass.title}
                        iconClasses={"class" + workOrderClass.number.toString(10)}
                        enabled={!props.isReadOnly}
                        checked={state.uniqueClasses.indexOf(workOrderClass.number) > -1}
                        onRadioClickBoolean={(checked: boolean) => {
                            if (props.isReadOnly) {
                                return;
                            }
                            props.onToggleClass(workOrderClass.number);
                        }}
                    />)
                }
            </div>);
    }
}