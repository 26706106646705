import { IContactEditItemCustomerContactItem, ContactEditItemCustomerContactItem } from "./contactEditItemCustomerContactItem";

export interface IContactEditItem {
    id: string;
    number: number;
    name: string;
    phone: string;
    email: string;
    comment: string;
    activeState: number;
    rowId: string;
    customerContacts: IContactEditItemCustomerContactItem[];

    isNew():boolean;
}

export class ContactEditItem implements IContactEditItem {
    id: string;
    number: number;
    name: string;
    phone: string;
    email: string;
    comment: string;
    activeState: number;
    rowId: string;
    customerContacts: ContactEditItemCustomerContactItem[];

    constructor();
    constructor(obj: IContactEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.name = obj && obj.name || "";
        this.phone = obj && obj.phone || "";
        this.email = obj && obj.email || "";
        this.comment = obj && obj.comment || "";
        this.activeState = obj && obj.activeState || 0;
        this.rowId = obj && obj.rowId || "";
        this.customerContacts = [];
        let i: number;
        if (obj && obj.customerContacts) {
            for (i = 0; i < obj.customerContacts.length; i++) {
                this.customerContacts.push(new ContactEditItemCustomerContactItem(obj.customerContacts[i]));
            }
        }
    }

    isNew():boolean {
        return !this.rowId;
    }
}