export interface IOwnerEditItem {
    id: string;
    code: string;
    name: string;
    name2: string;
    logo: string;
    backgroundImage: string;
    mapOnId: number;
    apiKey: string;
    showDefaultLogo: boolean;
    rowId: string;

    isNew():boolean;
}

export class OwnerEditItem implements IOwnerEditItem {
    id: string;
    code: string;
    name: string;
    name2: string;
    logo: string;
    backgroundImage: string;
    mapOnId: number;
    apiKey: string;
    showDefaultLogo: boolean;
    rowId: string;

    constructor();
    constructor(obj: IOwnerEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.code = obj && obj.code || "";
        this.name = obj && obj.name || "";
        this.name2 = obj && obj.name2 || "";
        this.logo = obj && obj.logo || "";
        this.backgroundImage = obj && obj.backgroundImage || "";
        this.mapOnId = obj && obj.mapOnId || 0;
        this.apiKey = obj && obj.apiKey || "";
        this.showDefaultLogo = obj && obj.showDefaultLogo || false;
        this.rowId = obj && obj.rowId || "";
    }

    isNew():boolean {
        return !this.rowId;
    }
}
