import { Base } from "../../framework/base";
import { CustomerSiteItem, ICustomerSiteItem } from "./customerSiteItem";

export interface ICustomerSiteItems {
    items: ICustomerSiteItem[];
    hasMore: boolean;
    page: number;
}

export class CustomerSiteItems implements ICustomerSiteItems {
    items: CustomerSiteItem[];
    hasMore: boolean;
    page: number;

    constructor();
    constructor(obj: ICustomerSiteItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = Base.getTypedArray<CustomerSiteItem>(obj ? obj.items : null, CustomerSiteItem);
    }
}