import { getApiCall } from "./tokenService";
import { UserGroupItems } from "../models/userGroup/userGroupItems";

export const getUserGroupItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean): Promise<UserGroupItems> => {
    return getApiCall<UserGroupItems>("api/usergroup/?page=" + (page || "") + "&pageSize=" + (pageSize || "") +
        "&sortColumn=" + (sortColumn ? encodeURIComponent(sortColumn) : "") + "&sortOrder=" + (sortOrderIsAsc ? "asc" : "desc") +
        "&filter=" + (filter ? encodeURIComponent(filter) : ""), UserGroupItems);
};

//export const getToolEdit = (id: string): Promise<ToolEdit> => {
//    return getApiCall<ToolEdit>("api/usergroup/edit/?id=" + (id || ""), ToolEdit);
//};

//export const saveToolEdit = (data: FormData): Promise<ApiSuccess> => {
//    return postFormApiCall<ApiSuccess>("api/usergroup/save", data, ApiSuccess);
//};

//export const removeTool = (id: string): Promise<ApiSuccess> => {
//    return postApiCall<ApiSuccess>("api/usergroup/remove/?id=" + (id || ""), ApiSuccess);
//};