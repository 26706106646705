// InvoiceMainInvoiceList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import { Base } from "../../framework/base";
import { ListHeaderColumn, ListHeaderCheckBoxColumn } from "../framework/listHeaderColumn";
import { ToolTitle } from "../framework/toolTitle";
import { IInvoiceListItem } from "../../models/invoice/invoiceListItem";
import { Button } from "../framework/button";
import { WorkNoBox } from "../work/workNoBox";
import { ButtonDropdown } from "../framework/dropdown";
import { InvoiceState } from "../../models/common/enums";
import { ListMode, ListModeSelector } from "../framework/listModeSelector";

// InvoiceMainPreliminaryInvoiceListLineHeader
export interface IInvoiceMainPreliminaryInvoiceListLineHeaderProp {
    sortColumn: string;
    sortOrderIsAsc: boolean;
    onColumnClick: (column: string) => void;
    onColumnCheckboxChange: (column: string, checked: boolean) => void;
}

export class InvoiceMainPreliminaryInvoiceListLineHeader extends React.Component<IInvoiceMainPreliminaryInvoiceListLineHeaderProp, {}> {
    handleColumnClick = (column: string) => {
        this.props.onColumnClick(column);
    };

    handleColumnCheckboxChange = (column: string, checked: boolean) => {
        this.props.onColumnCheckboxChange(column, checked);
    };

    render() {
        return (
            <div className="row title">
                <ListHeaderCheckBoxColumn
                    key="number"
                    title="#"
                    column="number"
                    classes="col-2"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                    onCheckboxChange={this.handleColumnCheckboxChange}
                />
                <ListHeaderColumn
                    title={Translations.Customer}
                    column="customer"
                    classes="col-3"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.WorkOrders}
                    column="workOrders"
                    classes="col-3"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.CustomerSiteNumbers}
                    column="siteNumbers"
                    classes="col-3"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
            </div>
        );
    }
}

// InvoiceMainPreliminaryInvoiceListLine
export interface IInvoiceMainPreliminaryInvoiceListLineProp {
    item: IInvoiceListItem;
    selectedId: string;
    checked: boolean;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
    onCheckboxChange: (id: string, checked: boolean) => void;
}

export class InvoiceMainPreliminaryInvoiceListLine extends React.Component<IInvoiceMainPreliminaryInvoiceListLineProp, {}> {
    handleNumberClick = (e) => {
        this.props.onDoubleClick(this.props.item.id);
    };

    handleCheckBoxClick = (e) => {
        e.stopPropagation();
        this.props.onCheckboxChange(this.props.item.id, !this.props.checked);
    };

    render() {
        const item = this.props.item;
        return (
            <div className={"row line" + (" invoiceState" + item.state) + (item.id === this.props.selectedId ? " selected" : "")} onClick={() => { this.props.onClick(item.id); }} onDoubleClick={() => { this.props.onDoubleClick(item.id); }} >
                <div className="col-2 number" onClick={this.handleNumberClick}><input type="checkbox" checked={this.props.checked} onClick={this.handleCheckBoxClick} value="" />{item.number.toString(10)}</div>
                <div className="col-3">{item.customerName}</div>
                <div className="col-3">{Base.getStringWithSeparatorsInt(item.workOrderNumbers, ", ")}</div>
                <div className="col-3">{Base.getStringWithSeparators(item.siteNumbers, ", ")}</div>
            </div>
        );
    }
}

// InvoiceMainPreliminaryInvoiceListCard
export interface IInvoiceMainPreliminaryInvoiceListCardProp {
    item: IInvoiceListItem;
    selectedId: string;
    checked: boolean;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
    onCheckboxChange: (id: string, checked: boolean) => void;
    onOpenWorkOrder: (id: string) => void;
}

export class InvoiceMainPreliminaryInvoiceListCard extends React.Component<IInvoiceMainPreliminaryInvoiceListCardProp, {}> {
    handleCheckBoxClick = (e) => {
        e.stopPropagation();
        this.props.onCheckboxChange(this.props.item.id, !this.props.checked);
    };

    render() {
        const props = this.props;
        const item = props.item;
        return (
            <div className={"cardContent" + (item.id === props.selectedId ? " selected" : "")} onClick={() => { props.onClick(item.id); }} onDoubleClick={() => { props.onDoubleClick(item.id); }} >
                <div>
                    <div className="cardLine" onClick={() => { props.onDoubleClick(item.id); }}>{item.number.toString(10) + " " + item.stateStr}</div>
                    <div className="cardLineContainer">
                        <div className="cardTitle">{item.customerName}</div>
                        <div className="cardLine">{item.workOrderTypeName}</div>
                        <div className="row taskComtainer">
                            {item.workOrders.map((i) =>
                                <WorkNoBox
                                    key={i.id}
                                    classes="left mb-1"
                                    id={i.id}
                                    state={i.state}
                                    number={i.number}
                                    parentNumber={0}
                                    onClick={props.onOpenWorkOrder}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// InvoiceMainPreliminaryInvoiceList
export interface IInvoiceMainPreliminaryInvoiceListProp {
    // List
    pageSize: number;
    page: number;
    hasMore: boolean;
    filter: string;
    sortColumn: string;
    sortOrderIsAsc: boolean;
    items: IInvoiceListItem[];
    state: InvoiceState;
    selectedId: string;
    checkedIds: string[];
    isLoading: boolean;
    searchItems: (pageSize: number, page: number, filter: string, invoiceState: InvoiceState, sortColumn: string, sortOrderIsAsc: boolean, resetItems: boolean, refreshList: boolean, successCallback: () => void) => Promise<void>;
    onChangeFilter: (filter: string, invoiceState: InvoiceState) => void;
    onChangeSortColumn: (sortColumn: string, invoiceState: InvoiceState) => void;
    onChangeColumnCheckbox: (column: string, checked: boolean, invoiceState: InvoiceState) => void;
    onChangeLineCheckbox: (id: string, checked: boolean, invoiceState: InvoiceState) => void;
    onLineClick: (id: string, invoiceState: InvoiceState) => void;
    onLineDoubleClick: (id: string) => void;
    onPrintInvoices: (invoiceState: InvoiceState) => void;
    onTakeToInvoicing: () => void;
    onEditInvoice: (invoiceState: InvoiceState) => void;
    onRemoveInvoice: (invoiceState: InvoiceState) => void;
    onOpenWorkOrder: (id: string) => void;
}

interface IInvoiceMainPreliminaryInvoiceListState {
    listMode: ListMode;
    showButtons: boolean;
}

export class InvoiceMainPreliminaryInvoiceList extends React.Component<IInvoiceMainPreliminaryInvoiceListProp, IInvoiceMainPreliminaryInvoiceListState> {
    private commandRowDivWidthThreshold = 930;
    private commandRowDiv: HTMLDivElement;
    private containerDiv: HTMLDivElement;
    private listDiv: HTMLDivElement;

    constructor(props) {
        super(props);
        this.state = {
            listMode: ListMode.List,
            showButtons: true
        };
    }

    handleScrollSub = Base.debounce((obj: InvoiceMainPreliminaryInvoiceList) => {
        const props = obj.props;
        if (props.isLoading || !props.hasMore) return;
        if (obj.listDiv.offsetHeight - (obj.containerDiv.clientHeight + obj.containerDiv.scrollTop) < 5) {
            props.searchItems(props.pageSize, props.page + 1, props.filter, props.state, props.sortColumn, props.sortOrderIsAsc, false, false, null);
        }
    }, 100);

    handleScroll = (event) => {
        this.handleScrollSub(this);
    };

    handleWindowsResize = () => {
        if (!this.commandRowDiv) return;
        const showButtons = this.commandRowDiv.clientWidth > this.commandRowDivWidthThreshold;
        if (showButtons === this.state.showButtons) return;
        this.setState({
            showButtons: showButtons
        });
    };

    componentDidMount(): void {
        this.containerDiv.addEventListener("scroll", this.handleScroll);
        window.addEventListener("resize", this.handleWindowsResize);
        this.handleWindowsResize();
    }

    componentWillUnmount(): void {
        this.containerDiv.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("resize", this.handleWindowsResize);
    }

    handleChangeListMode = (listMode: ListMode) => {
        this.setState({
            listMode: listMode
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        const items = props.items;
        return (
            <div>
                <div className="row commandRow main" id="commandRow" ref={(elem) => { this.commandRowDiv = elem; }}>
                    <ToolTitle
                        classes="smaller"
                        title={Translations.PreliminaryInvoices}
                    />
                    {/*{state.showButtons*/}
                    {/*    ? <div className="col">*/}
                    {/*        <ToolButton*/}
                    {/*            title={Translations.Edit}*/}
                    {/*            enabled={!!props.selectedId}*/}
                    {/*            classes={"round left edit"}*/}
                    {/*            onClick={props.onEditInvoice}*/}
                    {/*        />*/}
                    {/*        <ToolButton*/}
                    {/*            title={Translations.Remove}*/}
                    {/*            enabled={!!props.selectedId}*/}
                    {/*            classes={"round left remove"}*/}
                    {/*            onClick={props.onRemoveInvoice}*/}
                    {/*        />*/}
                    {/*        <ToolButton*/}
                    {/*            title={Translations.Print}*/}
                    {/*            enabled={!!props.selectedId || props.checkedIds.length > 0}*/}
                    {/*            classes={"round left print"}*/}
                    {/*            onClick={this.handlePrint}*/}
                    {/*        />*/}
                    {/*        <ToolButton*/}
                    {/*            title={Translations.TakeToInvoicing}*/}
                    {/*            enabled={!!props.selectedId || props.checkedIds.length > 0}*/}
                    {/*            classes={"round left invoice"}*/}
                    {/*            onClick={props.onTakeToInvoicing}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*    : <div className="col">*/}
                    {/*        <ButtonDropdown*/}
                    {/*            menuRight*/}
                    {/*            selectedTitle={""}*/}
                    {/*            classes={"dropdown-tool"}*/}
                    {/*            disabled={!props.selectedId && props.checkedIds.length < 1}*/}
                    {/*            actions={[*/}
                    {/*                { title: (Translations.Edit), onClick: () => { props.onEditInvoice(); }, disabled: false },*/}
                    {/*                { title: (Translations.Remove), onClick: () => { props.onRemoveInvoice(); }, disabled: false },*/}
                    {/*                { title: (Translations.Print), onClick: () => { this.handlePrint(); }, disabled: false },*/}
                    {/*                { title: (Translations.TakeToInvoicing), onClick: () => { props.onTakeToInvoicing(); }, disabled: false },*/}
                    {/*            ]}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*}*/}
                    <div className="col">
                        <ListModeSelector
                            listMode={state.listMode}
                            onChangeListMode={this.handleChangeListMode}
                        />
                    </div>
                    <div className="col-auto right">
                        <Button
                            classes={"right btn-primary"}
                            title={Translations.TakeToInvoicing}
                            tooltip={Translations.TakeToInvoicing}
                            enabled={!!props.selectedId || props.checkedIds.length > 0}
                            onClick={props.onTakeToInvoicing}
                        />
                        <ButtonDropdown
                            menuRight
                            selectedTitle={""}
                            classes={"dropdown-tool"}
                            disabled={!props.selectedId && props.checkedIds.length < 1}
                            actions={[
                                { title: (Translations.Edit), onClick: () => { props.onEditInvoice(props.state); }, disabled: false },
                                { title: (Translations.Remove), onClick: () => { props.onRemoveInvoice(props.state); }, disabled: false },
                                { title: (Translations.Print), onClick: () => { props.onPrintInvoices(props.state); }, disabled: false },
                                { title: (Translations.TakeToInvoicing), onClick: () => { props.onTakeToInvoicing(); }, disabled: false },
                            ]}
                        />
                    </div>
                    {/*    <div className="col-auto right">*/}
                    {/*        <ListSearchFilter*/}
                    {/*            searchFilter={props.filter}*/}
                    {/*            onSearchClick={props.onChangeFilter}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                </div>
                {state.listMode === ListMode.List &&
                    <div>
                        <InvoiceMainPreliminaryInvoiceListLineHeader
                            sortColumn={props.sortColumn}
                            sortOrderIsAsc={props.sortOrderIsAsc}
                            onColumnClick={(column: string) => props.onChangeSortColumn(column, props.state)}
                            onColumnCheckboxChange={(column: string, checked: boolean) => props.onChangeColumnCheckbox(column, checked, props.state)}
                        />
                    </div>
                }
                <div className={"listContainer main invoiceContainer halfHeight" + (state.listMode === ListMode.Card ? " card" : "")} ref={(elem) => { this.containerDiv = elem; }}>
                    <div className="list invoice striped">
                        {state.listMode === ListMode.List
                            ? <div className="lineContainer" ref={(elem) => { this.listDiv = elem; }}>
                                {items.map((item) =>
                                    <InvoiceMainPreliminaryInvoiceListLine
                                        key={item.id}
                                        item={item}
                                        selectedId={props.selectedId}
                                        checked={props.checkedIds.indexOf(item.id) > -1}
                                        onClick={(id: string) => props.onLineClick(id, props.state)}
                                        onDoubleClick={props.onLineDoubleClick}
                                        onCheckboxChange={(id: string, checked: boolean) => props.onChangeLineCheckbox(id, checked, props.state)}
                                    />
                                )}
                            </div>
                            : <div className="cardContainer" ref={(elem) => { this.listDiv = elem; }}>
                                <div className="row">
                                    {items.map((item) =>
                                        <div className="col-lg-6 col-xl-4 card" key={item.id}>
                                            <InvoiceMainPreliminaryInvoiceListCard
                                                key={item.id}
                                                item={item}
                                                selectedId={props.selectedId}
                                                checked={props.checkedIds.indexOf(item.id) > -1}
                                                onClick={(id: string) => props.onLineClick(id, props.state)}
                                                onDoubleClick={props.onLineDoubleClick}
                                                onCheckboxChange={(id: string, checked: boolean) => props.onChangeLineCheckbox(id, checked, props.state)}
                                                onOpenWorkOrder={props.onOpenWorkOrder}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        );
    }
}