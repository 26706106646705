import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TransportOrderListItemDto } from "../../../models/transport/transportOrder";
import { Translations } from "../../../models/translations";
import { Base } from "../../../framework/base";

interface SelectedOrderCardRowProps {
    title: string;
    text: string;
}

interface TransportOrderSelectedOrderCardProps {
    item: TransportOrderListItemDto;
}

export const TransportOrderSelectedOrderCard = ({ item }: TransportOrderSelectedOrderCardProps) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleAccordionChange = () => {
        setExpanded(!expanded);
    };

    const SelectedOrderCardRow = ({ title, text }: SelectedOrderCardRowProps) => {
        return (
            <Grid2 container justifyContent="space-between" mb={1}>
                <Typography variant="h4" color="primary.dark" mb={1}> {title}: </Typography>
                <Typography variant="h4" color="secondary">{text}</Typography>
            </Grid2>
        );
    };

    return (
        <Grid2 mb={2}>
            <Accordion
                onChange={handleAccordionChange}
                expanded={expanded}
                sx={{ width: "100%" }}
            >
                <AccordionSummary
                    expandIcon={<KeyboardArrowDownIcon id="workTime-form-header" />}
                >
                    <Grid2 spacing={2}>
                        <Typography variant="h3" mb={1}>{item.name}</Typography>
                        <Grid2 container>
                            <Typography variant="h4" color="primary.dark" mb={1}> {Translations.OrderNumber}:</Typography>
                            <Typography ml={2} variant="h4" color="secondary">{item.orderNumber}</Typography>
                        </Grid2>
                    </Grid2>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid2>
                        <SelectedOrderCardRow title={Translations.WaybillNumber} text={item.waybillNumber ? item.waybillNumber : " -"} />
                        <SelectedOrderCardRow title={`${Translations.TotalPrice } (EUR)`} text={item.fixedPrice.toFixed(2)} />
                        <SelectedOrderCardRow title={Translations.Delivered} text={Base.dayjsDateToDateTimeStr(item.actualDeliveryDateTime)} />
                    </Grid2>
                </AccordionDetails>
            </Accordion>
        </Grid2>
    );
};
