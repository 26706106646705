import { TFunction } from "i18next";
import { FormikProps, FormikErrors, getIn } from "formik";
import { FormikFieldProps, showApiError } from "../../../framework/formUtils";

export interface IFormikFieldArrayProps {
    rows: any[];
}

export const formikFieldArrayProps = (formik: FormikProps<IFormikFieldArrayProps>, field: string, i: number, t: TFunction): FormikFieldProps => ({
    name: `rows.${i}.${field}`,
    value: getIn(formik?.values?.rows[i], field),
    onChange: formik.handleChange,
    onBlur: () => {
        if(formik?.isValid && formik?.touched) {
            void formik?.submitForm().catch(() => showApiError(t("transport.parcel.type")));
        }
    },
    onFocus: (e) => e?.target.select(),
    error: error(formik.errors, field, i)
});

const isEmpty = (obj : FormikErrors<IFormikFieldArrayProps>) => Object.keys(obj).length === 0;
const error = (o : FormikErrors<IFormikFieldArrayProps>, k : string, i : number) =>
    !isEmpty(o) && o?.rows?.length > 0 ? typeof o.rows[i]?.[k] === "string" : false;
