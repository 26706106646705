import { IEmployeeCompetencyItem, EmployeeCompetencyItem } from "./employeeCompetencyItem";

export interface IEmployeeCompetencyItems {
    items: IEmployeeCompetencyItem[];
    hasMore: boolean;
    page: number;
    competencyWarningLimitInDays: number;
}

export class EmployeeCompetencyItems implements IEmployeeCompetencyItems {
    items: IEmployeeCompetencyItem[];
    hasMore: boolean;
    page: number;
    competencyWarningLimitInDays: number;

    constructor();
    constructor(obj: IEmployeeCompetencyItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.competencyWarningLimitInDays = obj && obj.competencyWarningLimitInDays || 30;
        this.items = [];
        if (obj && obj.items) {
            for (let i = 0; i < obj.items.length; i++) {
                this.items.push(new EmployeeCompetencyItem(obj.items[i]));
            }
        }
    }
}