export interface IWorkTimeAccountTransactionSaveData {
    salaryId: string;
    amount: number;
    description: string;
}

export class WorkTimeAccountTransactionSaveData implements IWorkTimeAccountTransactionSaveData {
    salaryId: string;
    amount: number;
    description: string;

    constructor();
    constructor(obj: IWorkTimeAccountTransactionSaveData);
    constructor(obj?: any) {
        this.salaryId = obj && obj.salaryId || "";
        this.amount = obj && obj.amount || 0;
        this.description = obj && obj.description || "";
    }
}