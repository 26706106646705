import { Base } from "../../framework/base";
import { DayBookingTypeItem, IDayBookingTypeItem } from "./dayBookingTypeItem";

export interface IDayBookingTypeItems {
    items: IDayBookingTypeItem[];
    hasMore: boolean;
    page: number;
}

export class DayBookingTypeItems implements IDayBookingTypeItems {
    items: DayBookingTypeItem[];
    hasMore: boolean;
    page: number;

    constructor();
    constructor(obj: IDayBookingTypeItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = Base.getTypedArray<DayBookingTypeItem>(obj ? obj.items : null, DayBookingTypeItem);
    }
}