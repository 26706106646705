import { connect } from "react-redux";
import { IApplicationState } from "../../models/store/storeTypes";
import * as storeEffects from "../../models/store/storeEffects";
import { LoginPage } from "../../components/login/loginPage";

/* eslint-disable no-empty-pattern */
export function mapStateToProps({}: IApplicationState) {
    return {};
}
/* eslint-enable no-empty-pattern */

export function mapDispatchToProps(dispatch: storeEffects.LogonThunkDispatch) {
    return {
        onLogin: (username: string, password: string) => {
            dispatch(storeEffects.login(username, password));
        },
        onLogout: () => {
            dispatch(storeEffects.logout());
        }
    };
}

export const LoginPageConnected = connect(mapStateToProps, mapDispatchToProps)(LoginPage);