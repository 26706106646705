import React from "react";
import { createRoot } from "react-dom/client";

import "bootstrap/dist/css/bootstrap-grid.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datetime/css/react-datetime.css";
import "react-bootstrap-typeahead/css/Typeahead.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "leaflet/dist/leaflet.css";
import "../cssSource/less/main.less";
import "react-datepicker/dist/react-datepicker.css";
import "./i18n/config";

import "./date.js";
import "./string.js";
import "./array.js";
import "./number.js";
import "./components/app/app";

import { ConnectedApp } from "./components/app/app";

const root = createRoot(document.getElementById("appContent"));
root.render(
    <React.StrictMode>
        <ConnectedApp />
    </React.StrictMode>
);
