export interface IEmployeeEditItemSsoIntegrationAttribute {
    name: string;
    value: string;
    required: boolean;
}

export class EmployeeEditItemSsoIntegrationAttribute implements IEmployeeEditItemSsoIntegrationAttribute {
    name: string;
    value: string;
    required: boolean;

    constructor();
    constructor(obj: IEmployeeEditItemSsoIntegrationAttribute);
    constructor(obj?: any) {
        this.name = obj && obj.name || "";
        this.value = obj && obj.value || "";
        this.required = obj && obj.required || false;
    }
}

export class EmployeeEditItemSsoSaveIntegrationAttribute {
    name: string;
    value: string;

    constructor();
    constructor(obj: IEmployeeEditItemSsoIntegrationAttribute);
    constructor(obj?: any) {
        this.name = obj && obj.name || "";
        this.value = obj && obj.value || "";
    }
}