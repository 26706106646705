import { getApiCall } from "./tokenService";
import { CodeTitle } from "../models/common/codeTitle";
import { IHourBookingCategoryItem } from "../models/hourBookingType/hourBookingTypeItem";

export const getHourBookingCategories = (
    signal?: AbortSignal
): Promise<CodeTitle[]> => {
    return getApiCall<CodeTitle[]>("api/hourbooking/categories", null, signal);
};

export const getHourBookingTypes = (
    signal?: AbortSignal
): Promise<IHourBookingCategoryItem[]> => {
    return getApiCall<IHourBookingCategoryItem[]>(
        "api/hourbooking/types",
        null,
        signal
    );
};
