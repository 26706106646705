export interface IBase64FileWithThumbnail {
    file: string;
    fileName: string;
    thumbnails: string[];
}

export class Base64FileWithThumbnail implements IBase64FileWithThumbnail {
    file: string;
    fileName: string;
    thumbnails: string[];

    constructor();
    constructor(obj: IBase64FileWithThumbnail);
    constructor(obj?: any) {
        this.file = obj && obj.file || "";
        this.fileName = obj && obj.fileName || "";
        this.thumbnails = obj && obj.thumbnails || [];
    }
}