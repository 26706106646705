import { Base } from "../../framework/base";

export interface ICustomerEditItemSiteItemEmployeeItem {
    id: string;
    name: string;
    lastWorkDate: number;
}

export class CustomerEditItemSiteItemEmployeeItem implements ICustomerEditItemSiteItemEmployeeItem {
    id: string;
    name: string;
    lastWorkDate: number;

    constructor();
    constructor(obj: ICustomerEditItemSiteItemEmployeeItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
        this.lastWorkDate = obj && obj.lastWorkDate || "";
    }

    static sortCustomerEditItemSiteItems(items: ICustomerEditItemSiteItemEmployeeItem[], column: string, asc: boolean) {
        if (Base.isNullOrUndefined(items) || items.length < 2) return;
        let sortFunc: (a: ICustomerEditItemSiteItemEmployeeItem, b: ICustomerEditItemSiteItemEmployeeItem) => number;
        if (column === "lastWorkDate") sortFunc = (a: ICustomerEditItemSiteItemEmployeeItem, b: ICustomerEditItemSiteItemEmployeeItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.lastWorkDate, b.lastWorkDate)); };
        else sortFunc = (a: ICustomerEditItemSiteItemEmployeeItem, b: ICustomerEditItemSiteItemEmployeeItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.name, b.name)); };
        items.sort(sortFunc);
    }
}