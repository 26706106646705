import React, { useState, useEffect } from "react";
import { LocalizationProvider, TimeField } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

interface TimeFieldProps {
    value: string;
    label?: string;
    onBlur?: (e: string) => void;
    error?: boolean;
    helperText?: string;
    required?: boolean;
    size?: "small" | "medium";
    disabled?: boolean;
}

const parseTime = (date: string) => dayjs(date, "HH:mm");

export const MuiTimeField = ({
    value,
    onBlur,
    error,
    helperText,
    required,
    label,
    size,
    disabled,
}: TimeFieldProps) => {
    const [selected, setSelected] = useState<Dayjs>(parseTime(value));

    useEffect(() => {
        if (value) {
            setSelected(parseTime(value));
        }
    }, [value]);

    const handleChange = (val: Dayjs) => {
        setSelected(val);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fi">
            <TimeField
                fullWidth
                label={label}
                value={selected}
                ampm={false}
                size={size}
                disabled={disabled}
                onChange={handleChange}
                onBlur={(e) => onBlur(e.target.value)}
                slotProps={{
                    textField: {
                        helperText,
                        error: !!error,
                        required,
                    },
                }}
            />
        </LocalizationProvider>
    );
};
