/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import LoopIcon from "@mui/icons-material/Loop";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { RootState, useAppSelector } from "../../../framework/customStore";
import { Translations } from "../../../models/translations";
import { TransportOrderInvoicingState, TransportOrderListItemDto, TransportOrderState, transportOrderStates } from "../../../models/transport/transportOrder";
import { TransportPlanListItemDto, TransportPlanUpdateDto, TransportPlanDetailsDto, TransportPlanState } from "../../../models/transport/transportPlan";
import { apiCall } from "../../../services/apiClient";
import { addPlanToOrder, removeTransportPlan, removeOrderFromPlan, setSideBarOrderDetails, fetchSidebarOrders, setGridDataChanged } from "../../../store/transport/transportVehiclesSlice";
import { Droppable, dndDropHandler } from "../../framework/drag-n-drop";
import { showConfirm, showApiError, showApiSuccess } from "../../framework/formUtils";
import { TimelineGridCell } from "../../timelinePlanner/timelinePlannerGrid";
import { Pill } from "../../timelinePlanner/timelinePlannerItem";
import { TransportDraggable } from "../components/sidebar/transportSideBarDraggables";
import { transportDateTimeRange, TransportPlanStateIndicator, TransportOrderStateIndicator } from "../components/transportUtils";
import { EmployeeInlineEdit } from "./EmployeeInlineEdit.";
import { VehicleInlineEdit } from "./VehicleInlineEdit";
import { PlanStateEditMenu } from "./PlanStateEditMenu";
import { showErrorMessage } from "../../../models/store/storeActions";
import { createTransportOrder } from "../../../services/transportOrderService";
import { ParcelListItemDto } from "../../../models/transport/parcel";
import { getParcelsForOrder, postParcel } from "../../../services/parcelService";
import { getCopyDataToTransportOrder } from "./Utils";
import { TransportOrderAction } from "./TransportOrderAction";

interface TransportTimelineCellProps {
    planId: string;
    editReturnPath?: string;
    editEmployeeEnabled?: boolean;
    editVehicleEnabled?: boolean;
}

const MAX_VISIBLE_ORDERS = 8 as const;

export const TransportTimelineCell = (props: TransportTimelineCellProps) => {
    const plan = useAppSelector(
        (state) => state.transportVehicles.plans[props.planId]
    );
    const gridDataChanged = useAppSelector((state) => state.transportVehicles.gridDataChanged);
    const isRepeating = !!plan?.transportPlanTemplateId;
    const menuAnchor = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const startDate = new Date(plan.scheduledStartDateTime);
    const endDate = new Date(plan.scheduledEndDateTime);

    const sidebarOrder = useAppSelector((state: RootState) => state.transportVehicles.sideBarOrderDetails);
    const displayedOrderId = sidebarOrder?.id;
    const [parcels, setParcels] = useState<ParcelListItemDto[]>([]);
    const inTransportOrCompleted = plan.state === TransportPlanState.InTransport || plan.state === TransportPlanState.Completed ? true : false;
    const isPickedUpOrDelivered = sidebarOrder?.state === TransportOrderState.PickedUp || sidebarOrder?.state === TransportOrderState.Delivered ? true : false;

    const employeeId = useAppSelector(state => state.user.employeeId);

    const handleDrop: dndDropHandler<
        TransportPlanListItemDto,
        TransportDraggable
    > = ({ draggable }) => {
        if (draggable.data.type === "transportOrder") {
            if (plan.state === TransportPlanState.Completed) {
                dispatch(showErrorMessage(Translations.CannotAddOrderToCompletedPlan));
            }
            dispatch(
                addPlanToOrder({ planId: plan.id, order: draggable.data.item })
            )
                .then(() => {
                    dispatch(setGridDataChanged(!gridDataChanged));
                });
        }
    };

    useEffect(() => {
        if (sidebarOrder) {
            const fetchParcels = async() => await getParcelsForOrder(sidebarOrder?.id);
            if (!parcels) {
                fetchParcels()
                    .then(res => {
                        setParcels(res.data);
                    })
                    .catch(() => showApiError("Kuljetusyksiköiden hakeminen epäonnistui"));
            }
        }
    }, [sidebarOrder]);

    const handleRemove = () => {
        const fetchOrders = plan.orders?.length > 0;

        const submitRemove = () => {
            dispatch(removeTransportPlan(plan)).then(() => {
                if (fetchOrders) {
                    dispatch(fetchSidebarOrders({}));
                }
            }).catch(() => {
                showApiError("Kuljetussuunnitelman poistaminen ei onnistunut");
            });
        };

        showConfirm(
            Translations.AreYouSureYouWantToRemoveTransportPlan,
            submitRemove
        );
    };

    const handleRemoveOrderFromPlan = (order: TransportOrderListItemDto) => {
        setSelectedOrder(null);
        if (order.state !== TransportOrderState.Delivered) {
            dispatch(removeOrderFromPlan({ planId: plan.id, order }));
        }
    };

    const handleCopyPlan = (originalPlan: TransportPlanListItemDto) => {
        const copyData: TransportPlanUpdateDto = {
            name: originalPlan.name,
            employeeId: originalPlan.employee?.id,
            vehicleId: originalPlan.vehicle?.id,
            scheduledStartDateTime: originalPlan.scheduledStartDateTime,
            scheduledEndDateTime: originalPlan.scheduledEndDateTime
        };

        apiCall<TransportPlanDetailsDto, TransportPlanUpdateDto>("TransportPlans", "POST", copyData)
            .then(() => {
                dispatch(setGridDataChanged(!gridDataChanged));
            })
            .catch(err => {
                showApiError(`Kopion luominen epäonnistui. Virhe: ${err.message as string}`);
            });
    };

    const handleCopyOrder = () => {
        const copyData = getCopyDataToTransportOrder(sidebarOrder);
        createTransportOrder({ ...copyData, createdEmployeeId: employeeId })
            .then(res => {
                if (res) {
                    if (parcels) {
                        parcels.map(parcel => {
                            const { id, transportOrderId, ...rest } = parcel;
                            postParcel(res.data.id, rest)
                                .catch((err) => {
                                    showApiError(`Kopion luominen epäonnistui. Virhe: ${err.message as string}`);
                                });
                        });
                    }
                    setSelectedOrder(null);
                    dispatch(setSideBarOrderDetails(res.data));
                    dispatch(fetchSidebarOrders({}));
                    showApiSuccess("Kopion luominen onnistui");
                }
            })
            .catch((err) => {
                showApiError(`Kopion luominen epäonnistui. Virhe: ${err.message as string}`);
            });
    };

    const handleCloseMenu = () => {
        setSelectedOrder(null);
    };

    const [selectedOrder, setSelectedOrder] = useState<{
        element: HTMLDivElement;
        order: TransportOrderListItemDto;
    }>(null);

    return (
        <TimelineGridCell startColumn={startDate} endColumn={endDate}>
            <Droppable<any, TransportDraggable>
                id={plan.id}
                onDrop={handleDrop}
                shouldHighlight={plan.state !== TransportPlanState.Completed}
            >
                <Box
                    p={1}
                    sx={{
                        border: "1px solid #dadada",
                        height: "100%",
                        backgroundColor: "light.main",
                        "&:hover": { backgroundColor: "lightBlue.main" },
                    }}
                    className="cursor-pointer transition-bg"
                    role="button"
                    ref={menuAnchor}
                    onClick={() => setMenuOpen(!menuOpen)}
                >
                    <Stack spacing={0.5}>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            className="text-blue-dark"
                        >
                            <Typography fontSize="small">
                                {transportDateTimeRange(startDate, endDate)}
                            </Typography>
                        </Stack>
                        <Typography fontSize="small" fontWeight="600">
                            {isRepeating &&
                                <LoopIcon
                                    sx={{ color: "primary.dark", ml: -1 }}
                                    fontSize="small"
                                />
                            }
                            <TransportPlanStateIndicator state={plan.state} />
                            {plan.name}
                        </Typography>
                        {props?.editEmployeeEnabled && <EmployeeInlineEdit planId={props.planId} />}
                        {props?.editVehicleEnabled && <VehicleInlineEdit planId={props.planId} />}
                    </Stack>
                    <Stack spacing={1} mt="4px">
                        {plan.orders.length === 0 && (
                            <Typography fontSize="small">
                                {Translations.NonOrder}
                            </Typography>
                        )}

                        {plan.orders
                            .slice(0, MAX_VISIBLE_ORDERS)
                            ?.map((order) => (
                                <div
                                    key={order.id}
                                    className="cursor-pointer"
                                    role="button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch(setSideBarOrderDetails(order));
                                        setSelectedOrder({
                                            element: e.currentTarget,
                                            order,
                                        });
                                    }}
                                >
                                    <Pill
                                        color={
                                            order.id === displayedOrderId ? transportOrderStates[order.state].colorDark : transportOrderStates[order.state].color
                                        }
                                        content={
                                            <Typography
                                                sx={{ color: "#fff", pl: 1 }}
                                                fontSize="small"
                                            >
                                                {order.name}
                                            </Typography>
                                        }
                                    />
                                </div>
                            ))}

                        {plan.orders.length > MAX_VISIBLE_ORDERS && (
                            <Typography
                                fontSize="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    history.push(
                                        `/transport/plans/plan/${plan.id}`,
                                        { initialSelectedTab: 1 }
                                    );
                                }}
                            >
                                +{plan.orders.length - MAX_VISIBLE_ORDERS}{" "}
                                {plan.orders.length - MAX_VISIBLE_ORDERS === 1
                                    ? Translations.Order
                                    : Translations.TransportOrders}
                            </Typography>
                        )}
                    </Stack>
                </Box>
            </Droppable>

            <Menu
                anchorEl={menuAnchor.current}
                open={menuOpen}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                onClose={() => setMenuOpen(false)}
                onClick={() => setMenuOpen(false)}
            >
                <Stack m={1}
                    sx={{ minWidth: 160 }}
                >
                    <div className="font-weight-bold">
                        <TransportPlanStateIndicator state={plan.state} />{" "}
                        {plan.name}
                    </div>
                </Stack>

                <Divider sx={{ marginBottom: 1 }} />
                {!inTransportOrCompleted &&
                    <MenuItem>
                        <Link
                            to={{
                                pathname: `/transport/plans/plan/${plan.id}`,
                                state: { fromPath: props?.editReturnPath || "/transport/vehicles" },
                            }}
                        >
                            <Typography variant="subtitle2" color="primary">
                                {Translations.Edit}
                            </Typography>
                        </Link>
                    </MenuItem>
                }
                <MenuItem
                    onClick={() =>
                        handleCopyPlan(plan)
                    }
                >
                    <Typography variant="subtitle2" color="primary">
                        {Translations.Copy}
                    </Typography>
                </MenuItem>
                {!inTransportOrCompleted &&
                <MenuItem onClick={handleRemove}>
                    <Typography variant="subtitle2" color="primary">
                        {Translations.Remove}
                    </Typography>
                </MenuItem>}
                <PlanStateEditMenu planId={props.planId} />
            </Menu>

            {selectedOrder && (
                <Menu
                    anchorEl={selectedOrder.element}
                    open={true}
                    onClose={() => setSelectedOrder(null)}
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                >
                    <Stack m={1}>
                        <div className="font-weight-bold">
                            <TransportOrderStateIndicator
                                state={selectedOrder.order.state}
                            />{" "}
                            #{selectedOrder.order.orderNumber} -{" "}
                            {selectedOrder.order.name}
                        </div>
                    </Stack>
                    <Divider />
                    {selectedOrder.order.invoicingState !== TransportOrderInvoicingState.InvoicedInternally &&
                        selectedOrder.order.invoicingState !== TransportOrderInvoicingState.InvoicedExternally &&
                        <MenuItem>
                            <Link
                                to={{
                                    pathname: `/transport/orders/${selectedOrder.order.id}`,
                                    state: { fromPath: props?.editReturnPath || "/transport/vehicles" },
                                }}
                            >
                                <Typography variant="subtitle2" color="primary" mt={1}>
                                    {Translations.Edit}
                                </Typography>
                            </Link>
                        </MenuItem>}
                    <MenuItem
                        onClick={() =>
                            handleCopyOrder()
                        }
                    >
                        <Typography variant="subtitle2" color="primary">
                            {Translations.Copy}
                        </Typography>
                    </MenuItem>
                    {selectedOrder.order.state !== TransportOrderState.Delivered && !isPickedUpOrDelivered &&
                        <MenuItem
                            onClick={() =>
                                handleRemoveOrderFromPlan(selectedOrder.order)
                            }
                        >
                            <Typography variant="subtitle2" color="primary">
                                {Translations.RemoveFromPlan}
                            </Typography>
                        </MenuItem>}
                    <TransportOrderAction order={selectedOrder.order} displayAsMenuItem onClose={handleCloseMenu} />
                </Menu>
            )}
        </TimelineGridCell>
    );
};
