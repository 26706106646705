import React, { useState } from "react";
import { Translations } from "../../../models/translations";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { TransportOrderListItemDto } from "../../../models/transport/transportOrder";
import { useParams } from "react-router-dom";
import { TransportOrderFilters } from "./TransportOrderFilters";
import { InvoicingTransportOrderList } from "./InvoicingTransportOrderList";
import { showErrorMessage, showSuccessMessage } from "../../../models/store/storeActions";
import { InvoiceCreateDto } from "../../../models/transportOrderInvoice/invoiceCreateDto";
import { useAppDispatch } from "../../../framework/customStore";
import { customHistory } from "../../../framework/customHistory";
import { showApiError } from "../../framework/formUtils";
import { addTransportOrdersToInvoice, createNewInvoice } from "../../../services/newInvoiceService";
import { fetchTransportOrderInvoice } from "../../../store/transportOrderInvoiceSlice";

interface TransportOrderInvoiceRowDialogProps {
    open: boolean;
    title: string;
    onClose: () => void;
    selectedInvoiceCustomerId?: string;
}

export const TransportOrderInvoiceRowDialog = ({ open, title, onClose, selectedInvoiceCustomerId }: TransportOrderInvoiceRowDialogProps) => {
    const dispatch = useAppDispatch();
    const [orderItems, setOrderItems] = useState<TransportOrderListItemDto[]>([]);
    const [selectedOrders, setSelectedOrders] = useState<TransportOrderListItemDto[]>([]);
    const { id } = useParams<{ id: string }>();
    const newInvoiceData: InvoiceCreateDto = {
        reference: "",
        customerId: selectedOrders[0]?.billingCustomerId,
        invoiceMessage: "",
    };

    const handleAddSelected = () => {
        if (id === "new") {
            createNewInvoice(newInvoiceData)
                .then(res => {
                    if (res) {
                        handleAddTransportOrdersToInvoice(res.data.id);
                    } else {
                        dispatch(showErrorMessage(Translations.FailedToCreateInvoice));
                    }
                }).catch(showApiError);
        } else {
            handleAddTransportOrdersToInvoice(id);
        }
    };

    const handleAddTransportOrdersToInvoice = (id: string) => {
        const transportOrders = selectedOrders.map(i => i.id);
        addTransportOrdersToInvoice(id, transportOrders)
            .then(response => {
                if (response) {
                    dispatch(showSuccessMessage(Translations.AddTransportOrdersToInvoiceSuccess));
                    customHistory.push(
                        `/invoicingbeta/transportorderinvoice/${id}`,
                        { from: "/invoicingbeta/transportorderinvoice/new" }
                    );
                    dispatch(fetchTransportOrderInvoice(id) as any);
                    onClose();
                } else {
                    dispatch(showErrorMessage(Translations.AddTransportOrdersToInvoiceFailed));
                }
            }).catch(() => {
                dispatch(
                    showErrorMessage(Translations.AddTransportOrdersToInvoiceFailed)
                );
            });
    };

    return (
        <Dialog
            open={open}
            title={title}
            onClose={onClose}
            fullWidth
            maxWidth={"xl"}
        >
            <DialogTitle>
                <Grid2 container justifyContent="space-between" alignItems="center">
                    <Typography variant="h2">{title}</Typography>
                    <Button color="primary" endIcon={<CloseIcon />} onClick={onClose} />
                </Grid2>
            </DialogTitle>
            <DialogContent dividers sx={{ minHeight: "350px" }}>
                <TransportOrderFilters
                    setOrderItems={setOrderItems}
                    showOnlyInvoiceable={true}
                    disabledCustomer={id !== "new"}
                    selectedInvoiceCustomerId={selectedInvoiceCustomerId}
                />
                <InvoicingTransportOrderList
                    orderItems={orderItems}
                    selectedOrders={selectedOrders}
                    setSelectedOrders={setSelectedOrders}
                />
            </DialogContent>
            <DialogActions className="mx-3 my-2">
                <Button
                    color="success"
                    variant={"contained"}
                    disabled={selectedOrders.length <= 0}
                    onClick={handleAddSelected}
                >
                    {Translations.AddSelected}
                </Button>
            </DialogActions>
        </Dialog>

    );
};