// ReportViewParameters - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import { ToolTitle } from "../framework/toolTitle";
import { IReportListItemParameter } from "../../models/report/reportListItemParameter";
import { ReportParameterType } from "../../models/common/enums";
import Reactdatetime from "react-datetime";
import { Base } from "../../framework/base";
import { Button } from "../framework/button";
import { IReportListItem } from "../../models/report/reportListItem";
import { CheckBoxDropdown, ICheckBoxDropdownItem } from "../framework/dropdown";

//ReportViewParametersParameterLine
interface IReportViewParametersParameterLineProp {
    item: IReportListItemParameter;
    lookupParameterState: ILookupParameterState;
    onChange: (value: string) => void;
    onLookupChangeGroup: (value: string) => void;
    onLookupChangeValue: (value: string) => void;
}

interface IReportViewParametersParameterLineState {
    values: string[];
    hierarchialItems: ICheckBoxDropdownItem[];
}

class ReportViewParametersParameterLine extends React.Component<IReportViewParametersParameterLineProp, IReportViewParametersParameterLineState> {
    getHierarchialValues = (): string[] => {
        const props = this.props;
        const result: string[] = [];
        if (props.item.type !== ReportParameterType.HierarchialValueList) return result;
        return props.item.value ? JSON.parse(props.item.value) : [];
    };

    getHierarchialItems = (): ICheckBoxDropdownItem[] => {
        const props = this.props;
        const result: ICheckBoxDropdownItem[] = [];
        if (props.item.type !== ReportParameterType.HierarchialValueList) return result;
        for (const groupItem of props.item.hierarchialValueList) {
            result.push({
                id: groupItem.code,
                classes: "group",
                getTitle: () => { return groupItem.title; },
            });
            for (const chilItem of groupItem.children) {
                result.push({
                    id: chilItem.code,
                    classes: "child",
                    getTitle: () => { return chilItem.title; }
                });
            }
        }
        return result;
    };

    constructor(props: IReportViewParametersParameterLineProp) {
        super(props);
        this.state = {
            values: this.getHierarchialValues(),
            hierarchialItems: this.getHierarchialItems()
        };
    }

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        this.props.onChange(value);
    };

    handleChangeHierarchial = (id: string, checked: boolean) => {
        if (!id) {
            this.props.onChange(JSON.stringify([]));
            this.setState({
                values: []
            });
            return;
        }
        const state = this.state;
        let values = state.values.filter(i => i !== id);
        const group = this.props.item.hierarchialValueList.find(i => i.code === id);
        if (group) {
            const childCodes = group.children.map(i => i.code);
            if (checked) {
                values = values.concat(childCodes);
            } else {
                values = values.filter(i => childCodes.indexOf(i) < 0);
            }
        }
        if (checked) {
            values.push(id);
        }
        this.props.onChange(JSON.stringify(values));
        this.setState({
            values: values
        });
    };

    handleChangeLookUpGroup = (event) => {
        const target = event.target;
        const value: string = target.value;
        this.props.onLookupChangeGroup(value);
    };

    handleChangeLookUpValue = (event) => {
        const target = event.target;
        const value: string = target.value;
        this.props.onLookupChangeValue(value);
    };

    handleDateChange = (value: moment.Moment | string) => {
        this.props.onChange(typeof value === "string"
            ? (value ?? "")
            : Base.timeToDateStr(value));
    };

    handleDateBlur = (value: moment.Moment | string) => {
        this.props.onChange(Base.timeToDateStr(typeof value === "string"
            ? value.toDate().getTime()
            : value));
    };

    render() {
        const props = this.props;
        const state = this.state;
        const item = props.item;
        const selectedLookupGroupCode = props.lookupParameterState
            ? props.lookupParameterState.selectedParentCode
            : "";
        const selectedLookupGroupState = props.lookupParameterState
            ? props.lookupParameterState.values.find(i => i.parentCode === selectedLookupGroupCode)
            : "";
        const selectedLookupGroup = selectedLookupGroupCode
            ? item.hierarchialValueList.find(i => i.code === selectedLookupGroupCode)
            : null;
        const selectedLookupChildCode = selectedLookupGroupState
            ? selectedLookupGroupState.childCode
            : "";
        return (
            <div className="row">
                {item.type !== ReportParameterType.LookupValueList &&
                    <div className="col-12">
                        <div className={"form-group" + (item.required ? " required" : "")}>
                            <label className="control-label smallFont">{item.title}</label>
                            {item.type === ReportParameterType.Date &&
                                <Reactdatetime
                                    locale={appConfig.culture}
                                    className={"roWhite"}
                                    value={item.value}
                                    dateFormat={"D.M.YYYY"}
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    inputProps={{ maxLength: 10 }}
                                    onChange={this.handleDateChange}
                                    onClose={this.handleDateBlur}
                                />
                            }
                            {item.type === ReportParameterType.String &&
                                <input type="text" className="form-control" title={item.title} value={item.value} onChange={this.handleChange} />
                            }
                            {item.type === ReportParameterType.ValueList &&
                                <select className="custom-select" title={item.title} value={item.value} onChange={this.handleChange}>
                                    {!item.required &&
                                        <option key={1} value={""} />
                                    }
                                    {item.valueList.map((valueItem) =>
                                        <option key={valueItem.code} value={valueItem.code}>{valueItem.title}</option>
                                    )}
                                </select>
                            }
                            {item.type === ReportParameterType.HierarchialValueList &&
                                <CheckBoxDropdown
                                    selectedIds={state.values}
                                    values={state.hierarchialItems}
                                    onChangeSingle={this.handleChangeHierarchial}
                                />
                            }
                        </div>
                    </div>
                }
                {item.type === ReportParameterType.LookupValueList && (item.hierarchialValueList.length > 1 || !item.required) &&
                    <div className="col-12">
                        <div className={"form-group" + (item.required ? " required" : "")}>
                            <label className="control-label smallFont">{item.parentTitle}</label>
                            <select className="custom-select" title={item.title} value={selectedLookupGroupCode} onChange={this.handleChangeLookUpGroup}>
                                {!item.required &&
                                    <option key={1} value={""} />
                                }
                                {item.hierarchialValueList.map((groupItem) =>
                                    <option key={groupItem.code} value={groupItem.code}>{groupItem.title}</option>
                                )}
                            </select>
                        </div>
                    </div>
                }
                {item.type === ReportParameterType.LookupValueList &&
                    <div className="col-12">
                        <div className={"form-group" + (item.required ? " required" : "")}>
                            <label className="control-label smallFont">{item.title}</label>
                            {selectedLookupGroup &&
                                <select className="custom-select" title={item.title} value={selectedLookupChildCode} onChange={this.handleChangeLookUpValue}>
                                    {!item.required &&
                                        <option key={1} value={""} />
                                    }
                                    {selectedLookupGroup.children.map((childItem) =>
                                        <option key={childItem.code} value={childItem.code}>{childItem.title}</option>
                                    )}
                                </select>
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }
}

// ReportViewParameters
interface ILookupState {
    parentCode: string;
    childCode: string;
}

interface ILookupParameterState {
    name: string;
    selectedParentCode: string;
    values: ILookupState[];
}

export interface IReportViewParametersProp {
    item: IReportListItem;
    onChangeValue: (name: string, value: string) => void;
    onCreateReport: () => void;
}

export interface IReportViewParametersState {
    lookupParameterStates: ILookupParameterState[];
}

export class ReportViewParameters extends React.Component<IReportViewParametersProp, IReportViewParametersState> {
    getLookupParameterStates = (props: IReportViewParametersProp): ILookupParameterState[] => {
        return props.item && props.item.parameters
            ? props.item.parameters.filter(i => i.type === ReportParameterType.LookupValueList).map(i => {
                const values = i.value ? JSON.parse(i.value) : [];
                return {
                    name: i.name,
                    selectedParentCode: i.hierarchialValueList.length > 0
                        ? i.hierarchialValueList[0].code
                        : "",
                    values: i.hierarchialValueList.map(j => {
                        return {
                            parentCode: j.code,
                            childCode: j.children.find(k => values.indexOf(k.code) > -1)?.code ?? ""
                        };
                    })
                };
            })
            : [];
    };

    setInitialLookupValues = (lookupParameterStates: ILookupParameterState[]) => {
        for (const lookupParameterState of lookupParameterStates) {
            if (lookupParameterState.values.length < 1) {
                continue;
            }
            this.props.onChangeValue(lookupParameterState.name, lookupParameterState.values[0].childCode);
        }
    };

    constructor(props: IReportViewParametersProp) {
        super(props);
        this.state = {
            lookupParameterStates: this.getLookupParameterStates(props)
        };
        this.setInitialLookupValues(this.state.lookupParameterStates);
    }

    componentDidUpdate(prevProps: IReportViewParametersProp, prevState: IReportViewParametersState): void {
        const props = this.props;
        const prevItemName = prevProps.item ? prevProps.item.name : "";
        const itemName = props.item ? props.item.name : "";
        if (prevItemName === itemName) return;
        const lookupParameterStates = this.getLookupParameterStates(props);
        this.setState({
            lookupParameterStates: lookupParameterStates
        });
        this.setInitialLookupValues(lookupParameterStates);
    }

    handleChangeLookUpGroup = (name: string, code: string) => {
        const props = this.props;
        const state = this.state;
        const lookupParameterState = state.lookupParameterStates.find(i => i.name === name);
        if (!lookupParameterState) {
            return;
        }
        const lookupState = lookupParameterState.values.find(i => i.parentCode === code);
        if (!lookupState) {
            return;
        }
        const lookupParameterStates = state.lookupParameterStates.filter(i => i.name !== name);
        lookupParameterStates.push({
            name: name,
            selectedParentCode: code,
            values: lookupParameterState.values.slice(0)
        });
        this.setState({
            lookupParameterStates: lookupParameterStates
        });
        props.onChangeValue(name, lookupState.childCode);
    };

    handleChangeLookUpValue = (name: string, value: string) => {
        const props = this.props;
        const state = this.state;
        const lookupParameterState = state.lookupParameterStates.find(i => i.name === name);
        if (!lookupParameterState) {
            return;
        }
        const lookupState = lookupParameterState.values.find(i => i.parentCode === lookupParameterState.selectedParentCode);
        if (!lookupState) {
            return;
        }
        const lookupParameterStates = state.lookupParameterStates.filter(i => i.name !== name);
        const lookupStates = lookupParameterState.values.filter(i => i.parentCode !== lookupParameterState.selectedParentCode);
        lookupStates.push({
            parentCode: lookupParameterState.selectedParentCode,
            childCode: value
        });
        lookupParameterStates.push({
            name: name,
            selectedParentCode: lookupParameterState.selectedParentCode,
            values: lookupStates
        });
        this.setState({
            lookupParameterStates: lookupParameterStates
        });
        props.onChangeValue(name, value);
    };

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <div className="backgroundContent row">
                <div className="col-12">
                    <div className="row commandRow">
                        <ToolTitle
                            title={Translations.Report}
                        />
                    </div>
                    {props.item &&
                        <div className="form-group">
                            <label className="control-label smallFont">{Translations.Name}</label>
                            <div className="reportTitle">{props.item.name}</div>
                        </div>
                    }
                    {props.item &&
                        <div className="reportParametersContainer">
                            {props.item.parameters.map((parameter) =>
                                <ReportViewParametersParameterLine
                                    key={parameter.name}
                                    item={parameter}
                                    lookupParameterState={state.lookupParameterStates.find(i => i.name === parameter.name)}
                                    onChange={(value: string) => props.onChangeValue(parameter.name, value)}
                                    onLookupChangeGroup={(value: string) => this.handleChangeLookUpGroup(parameter.name, value)}
                                    onLookupChangeValue={(value: string) => this.handleChangeLookUpValue(parameter.name, value)}
                                />
                            )}
                        </div>
                    }
                    {props.item &&
                        <div className="reportParametersButtons">
                            <Button
                                classes={"btn-default blue"}
                                title={Translations.CreateReport}
                                enabled={true}
                                onClick={props.onCreateReport}
                            />
                        </div>
                    }
                </div>
            </div>
        );
    }
}
