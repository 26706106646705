// SettingsChangePasswordDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import * as employeeService from "../../services/employeeService";
import { Translations } from "../../models/translations";
import { Dialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { SaveData } from "../../framework/saveData";

// SettingsChangePasswordDialog
// ***********************************************************************************************************************
export interface ISettingsChangePasswordDialogProp {
    classes?: string;
    employeeId: string;
    employeeRowId: string;
    adminMode: boolean;
    onOk: (rowId: string) => void;
    onCancel: () => void;
}

export interface ISettingsChangePasswordDialogState {
    oldPassword: string;
    password: string;
    passwordConfirmation: string;
}

export class SettingsChangePasswordDialog extends React.Component<ISettingsChangePasswordDialogProp, ISettingsChangePasswordDialogState> {
    constructor(props) {
        super(props);
        this.state = {
            oldPassword: "", password: "", passwordConfirmation: ""
        };
    }

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "oldPassword") {
            this.setState({ oldPassword: value });
        } else if (name === "password") {
            this.setState({ password: value });
        } else if (name === "passwordConfirmation") {
            this.setState({ passwordConfirmation: value });
        }
    };

    private static validate = (state: ISettingsChangePasswordDialogState): boolean => {
        if (!state.password) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.PasswordMustBeDefined));
            return false;
        }
        if (!Base.isValidPassword(state.password)) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.PasswordMustMeetComplexityRequirements));
            return false;
        }
        if (state.password !== state.passwordConfirmation) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.PasswordConfirmationDoesNotMatchPassword));
            return false;
        }
        return true;
    };

    private static getSaveDataFromState = (props: ISettingsChangePasswordDialogProp, state: ISettingsChangePasswordDialogState): SaveData => {
        const data = new SaveData();
        data.append("id", props.employeeId);
        data.append("rowId", props.employeeRowId);
        if (!props.adminMode) {
            data.append("oldPassword", state.oldPassword);
        }
        data.append("password", state.password);
        return data;
    };

    handleOkClick = () => {
        const obj = this;
        if (!SettingsChangePasswordDialog.validate(this.state)) return;
        const saveData = SettingsChangePasswordDialog.getSaveDataFromState(this.props, this.state);
        if (Base.isNullOrUndefined(saveData)) return;
        // Call server
        store.customStore.dispatch(storeActions.fetchStart());
        employeeService.changeEmployeePassword(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                obj.props.onOk(success.rowId);
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const dialogClasses = "changePassword px300" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <Dialog
                    classes={dialogClasses}
                    title={Translations.ChangePassword}
                    show={true}
                    body={<div>
                        {!props.adminMode &&
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group required">
                                        <label className="control-label smallFont">{Translations.Password}</label>
                                        <input type="text" className="form-control passwordFont" name="oldPassword" title={Translations.Password} value={this.state.oldPassword} onChange={this.handleChange} maxLength={50}/>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.NewPassword}</label>
                                    <input type="text" className="form-control passwordFont" name="password" title={Translations.NewPassword} value={this.state.password} onChange={this.handleChange} maxLength={50}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.NewPasswordConfirmation}</label>
                                    <input type="text" className={"form-control passwordFont" + (this.state.password !== this.state.passwordConfirmation ? " error" : "") } name="passwordConfirmation" title={Translations.NewPasswordConfirmation} value={this.state.passwordConfirmation} onChange={this.handleChange} maxLength={50}/>
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.OK, classes: "btn-primary", enabled: true, onClick: this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}