import { IdTitle } from "../models/common/idTitle";
import { GetReportResponse, IGetReportResponse } from "../models/reporting/getReportingResponse";
import { ReportingQueryResponse } from "../models/reporting/ReportingQueryResponse";
import { makeApiCallWithAny } from "./tokenService";

export const getReportingItems = (): Promise<IdTitle[]> => {
    return makeApiCallWithAny<IdTitle[]>("api/reporting/list", "GET", undefined, Array<IdTitle>);
};

export const getReportParameter = (reportId: string): Promise<IGetReportResponse> => {
    return makeApiCallWithAny<IGetReportResponse>("api/reporting/parameters?reportId=" + reportId, "GET", undefined, GetReportResponse);
};

export const getReportQuery = (object): Promise<ReportingQueryResponse> => {
    return makeApiCallWithAny<ReportingQueryResponse>("api/reporting?filters=" + JSON.stringify(object), "GET", undefined, ReportingQueryResponse);
};
