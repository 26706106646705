import { Base } from "../../framework/base";
import { IReportListItemParameter, ReportListItemParameter, ReportParameterGenerate } from "./reportListItemParameter";

export interface IReportListItem {
    id: string;
    name: string;
    parameters: IReportListItemParameter[];
}

export class ReportListItem implements IReportListItem {
    id: string;
    name: string;
    parameters: ReportListItemParameter[];

    constructor();
    constructor(obj: IReportListItem);
    constructor(obj?: any) {
        this.id = obj && obj.name || "";
        this.name = obj && obj.name || "";
        this.parameters = Base.getTypedArray<ReportListItemParameter>(obj ? obj.parameters : null, ReportListItemParameter);
    }
}

export class ReportGenerate {
    type: string;
    name: string;
    parameters: ReportParameterGenerate[];

    constructor();
    constructor(obj: IReportListItem);
    constructor(obj?: any) {
        this.type = obj && obj.type || "";
        this.name = obj && obj.name || "";
        this.parameters = Base.getTypedArray<ReportParameterGenerate>(obj ? obj.parameters : null, ReportParameterGenerate);
    }
}