export interface ISalaryPeriodTypeItem {
    id: string;
    name: string;
    activeState: number;
}

export class SalaryPeriodTypeItem implements ISalaryPeriodTypeItem {
    id: string;
    name: string;
    activeState: number;

    constructor();
    constructor(obj: ISalaryPeriodTypeItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
        this.activeState = obj && obj.activeState || 0;
    }
}
