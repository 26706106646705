export interface IGeocodeResult {
    latitude: number;
    longitude: number;
    street: string;
    postalCode: string;
    city: string;
    countryCode: string;
}

export class GeocodeResult implements IGeocodeResult {
    latitude: number;
    longitude: number;
    street: string;
    postalCode: string;
    city: string;
    countryCode: string;

    constructor();
    constructor(obj: IGeocodeResult);
    constructor(obj?: any) {
        this.longitude = obj && obj.longitude || 0;
        this.latitude = obj && obj.latitude || 0;
        this.street = obj && obj.street || "";
        this.postalCode = obj && obj.postalCode || "";
        this.city = obj && obj.city || "";
        this.countryCode = obj && obj.countryCode || "";
    }
}