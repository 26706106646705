import * as React from "react";
import Tabs, { TabsProps } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Redirect } from "react-router-dom";
import { useRouteMatch } from "../../hooks/useRouteMatch";
import { customHistory } from "../../framework/customHistory";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
}

interface ILinkTab {
    value: string;
    label: string;
    matchPaths?: string[];
    to: string;
    children?: React.ReactNode;
}

interface MuiTabsProps {
    tabs: ILinkTab[];
    tabsOnly?: boolean;
    panelsOnly?: boolean;
    handleTabsChange?: (event: React.SyntheticEvent) => void;
    TabsProps?: TabsProps;
}

function TabPanel(props: TabPanelProps) {
    const { children, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {children}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export const LinkTabs = ({
    tabs,
    tabsOnly,
    panelsOnly,
    handleTabsChange,
    TabsProps,
}: MuiTabsProps) => {
    const routeMatch = useRouteMatch(tabs.flatMap((t) => t.matchPaths ?? t.to));
    const currentPath = routeMatch?.path;
    const currentIndex = tabs.findIndex((t) =>
        (t.matchPaths ?? [t.to]).some((p) => p === currentPath)
    );
    const currentTab = tabs[currentIndex];

    const handleClick = (path: string) => {
        if (path !== currentTab?.to) {
            customHistory.push(path);
        }
    }; 

    if (!currentTab) {
        return <Redirect to={tabs[0].to} push={false} />;
    }

    return (
        <>
            {!panelsOnly ? (
                <Tabs
                    {...TabsProps}
                    value={currentTab.value}
                    onChange={handleTabsChange}
                >
                    {tabs.map((t, i) => (
                        <Tab
                            key={t.value}
                            label={t.label}
                            value={t.value}
                            {...a11yProps(i)}
                            onClick={() => handleClick(t.to)}
                        />
                    ))}
                </Tabs>
            ) : null}
            {!tabsOnly ? (
                <TabPanel index={currentIndex}>{currentTab.children}</TabPanel>
            ) : null}
        </>
    );
};
