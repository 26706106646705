import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

interface SelectableListItem {
    id: string;
    icon?: JSX.Element;
    text?: string | JSX.Element | JSX.Element[];
    secondaryAction?: string | JSX.Element | JSX.Element[];
    primaryText?: string;
    secondaryText?: string;
    onSelect: (id: string) => void;
    onHover?: (id: string) => void;
}

interface MuiSelectableListProps {
    items: SelectableListItem[];
    selected: string;
}

export const MuiSelectableList = ({
    items,
    selected,
}: MuiSelectableListProps) => {
    return (
        <List dense disablePadding>
            {items?.map(
                ({
                    id,
                    icon,
                    primaryText,
                    secondaryText,
                    text,
                    secondaryAction,
                    onSelect,
                    onHover,
                }) => (
                    <ListItem key={id} disablePadding>
                        <ListItemButton
                            selected={selected === id}
                            onClick={() => onSelect(id)}
                            onMouseEnter={
                                !!onHover ? () => onHover(id) : undefined
                            }
                            onMouseLeave={
                                !!onHover ? () => onHover(null) : undefined
                            }
                        >
                            {!!icon ? (
                                <ListItemIcon>{icon}</ListItemIcon>
                            ) : null}
                            <ListItemText
                                primary={primaryText}
                                secondary={secondaryText}
                                sx={!!secondaryAction ? { mr: 2 } : undefined}
                            >
                                {text}
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                )
            )}
        </List>
    );
};
