export interface IContactItem {
    id: string;
    number: number;
    name: string;
    phone: string;
    email: string;
    comment: string;
    activeState: number;
}

export class ContactItem implements IContactItem {
    id: string;
    number: number;
    name: string;
    phone: string;
    email: string;
    comment: string;
    activeState: number;

    constructor();
    constructor(obj: IContactItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.name = obj && obj.name || "";
        this.phone = obj && obj.phone || "";
        this.email = obj && obj.email || "";
        this.comment = obj && obj.comment || "";
        this.activeState = obj && obj.activeState || 0;
    }
}