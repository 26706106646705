/* global JSX */
// WorkMainPortlet
// ***********************************************************************************************************************
import * as React from "react";
import { customHistory } from "../../framework/customHistory";
import { ToolButton } from "../framework/toolButton";
import { Translations } from "../../models/translations";
import { Base } from "../../framework/base";

// WorkMainPortlet
// ***********************************************************************************************************************
export interface IWorkMainPortletProp {
    classes?: string;
    expanded?: boolean;
    title: string | JSX.Element;
    titleLink?: string;
    titleClasses?: string;
    content: string | JSX.Element;
    replaceContent?: boolean;
    onTitleClick?: () => void;
    onDragOver?: (e) => void;
    onDrop?: (e) => void;
    onDragLeave?: (e) => void;
    onChangeExpanded?: (expanded: boolean) => void;
}

interface IWorkMainPortletState {
    expanded: boolean;
}

export class WorkMainPortlet extends React.Component<IWorkMainPortletProp, IWorkMainPortletState> {
    constructor(props) {
        super(props);
        this.state = { expanded: props.expanded === null || props.expanded === undefined ? true : props.expanded };
    }

    componentDidUpdate(prevProps: IWorkMainPortletProp, prevState: IWorkMainPortletState): void {
        if (Base.isNullOrUndefined(prevProps.expanded) && !Base.isNullOrUndefined(this.props.expanded)) {
            this.setState({
                expanded: this.props.expanded
            });
        }
    }

    handleTitleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const props = this.props;
        if (props.onTitleClick) {
            props.onTitleClick();
        } else {
            customHistory.push(this.props.titleLink);
        }
    };

    handleChangeExpanded = (expanded: boolean) => {
        const props = this.props;
        this.setState({ expanded: expanded });
        if (!props.onChangeExpanded) return;
        props.onChangeExpanded(expanded);
    };

    render() {
        const props = this.props;
        const state = this.state;
        const classes = "portlet" + (props.classes ? " " + props.classes : "") + (state.expanded ? "" : " pCollapsed");
        return (
            <div className={classes} onDragOver={props.onDragOver} onDrop={props.onDrop} onDragLeave={props.onDragLeave}>
                <div>
                    <div className={"pTitle" + (props.titleClasses ? " " + props.titleClasses : "") + (props.titleLink || props.onTitleClick ? " link" : "")}>
                        {typeof props.title === "string" &&
                            <span className="text" onClick={props.titleLink || props.onTitleClick ? this.handleTitleClick : null}>{props.title}</span>
                        }
                        {typeof props.title === "string"
                            ? (null)
                            : (props.title)
                        }
                        <ToolButton
                            title={state.expanded ? Translations.Collapse : Translations.Expand}
                            enabled={true}
                            classes={"portBtn round " + (state.expanded ? "doCollapse" : "doExpand")}
                            onClick={() => { this.handleChangeExpanded(!state.expanded); }}
                        />
                    </div>
                    {props.replaceContent && state.expanded &&
                        props.content
                    }
                    {(!props.replaceContent || !state.expanded) &&
                        <div className="pContent">
                            {state.expanded &&
                                props.content
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }
}