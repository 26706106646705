import React, { SyntheticEvent } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datePicker.css";


interface Props {
    disabled: boolean;
    onChange: (date: [Date, Date], event: SyntheticEvent<any, Event>) => void;
    label: string;
    startDate: Date;
    endDate: Date;
    locale: Locale;
}

export const StyledDatePicker = ({ disabled, label, startDate, endDate, onChange, locale } : Props) => {

    registerLocale(appConfig.culture, locale);
    return (
        <DatePicker
            disabled={disabled}
            selectsRange={true}
            dateFormat={"dd.MM.yyyy"}
            placeholderText={label}
            startDate={startDate}
            endDate={endDate}
            isClearable={true}
            onChange={onChange}
            wrapperClassName="reportingDatePicker"
        />
    );
};


