import React, { useEffect, useRef, useState } from "react";
import { Translations } from "../../models/translations";
import { EditDialog, ExposedFunctions } from "../framework/editDialog";
import {
    defaultFormikConfig,
    formatString,
    formikFieldProps,
} from "../framework/formUtils";
import { Button, TextField } from "@mui/material";
import * as store from "../../framework/customStore";
import * as StoreActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import Grid2 from "@mui/material/Unstable_Grid2";
import { STYLE_CONSTANTS } from "../../framework/theme";
import { useFormik } from "formik";
import * as yup from "yup";
import { IApiError } from "../../services/baseService";
import { getWorkTimeTypeTypes, saveWorkTimeType } from "../../services/workTimeTypeService";
import { WorkTimeTypeEditItem } from "../../models/workTimeType/workTimeTypeEditItem";
import MuiSelect, { IMuiSelectOption } from "../framework/muiSelect";
import { handleApiError } from "../../models/store/storeEffects";
import { LoadingIndicator } from "../framework/loadingIndicator";
import { useTranslation } from "react-i18next";
import { MuiSwitch } from "../framework/muiSwitch";

interface SettingsWorkTimeTypeDialogProps {
    item: WorkTimeTypeEditItem;
    open: boolean;
    onClose: () => void;
}
export const SettingsWorkTimeTypeDialog = ({ item, open, onClose }: SettingsWorkTimeTypeDialogProps) => {
    const dialogRef = useRef<ExposedFunctions>();
    const [typeOptions, setTypeOptions] = useState<IMuiSelectOption[]>();
    const dispatch = store.useAppDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        getWorkTimeTypeTypes()
            .then((types) => {
                setTypeOptions(
                    types.map((t) => ({ value: t.code, label: t.title }))
                );
            })
            .catch((e) => handleApiError(e, dispatch));
    }, []);

    const handleSubmit = (formData : WorkTimeTypeEditItem) => {
        const onSubmitSuccess = () => {
            formik.setSubmitting(false);
            dialogRef.current.setInitialFormDataFromForm();
            store.customStore.dispatch(StoreActions.showSuccessMessage(Translations.SaveSuccess));
            onClose();
        };

        const onSubmitFailure = (error: IApiError) => {
            store.customStore.dispatch(StoreActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            formik.setSubmitting(false);
        };

        saveWorkTimeType(formData)
            .then(onSubmitSuccess)
            .catch(onSubmitFailure);
    };

    const formik = useFormik<WorkTimeTypeEditItem>({
        ...defaultFormikConfig,
        initialValues: item,
        validationSchema: yup.object({
            name: yup.string()
                .required(formatString(Translations.FieldIsRequired, Translations.Name))
                .max(50, formatString(Translations.FieldMaxLengthError, Translations.Name.toLowerCase(), 50)),
        }),
        onSubmit: handleSubmit,
    });

    const primaryActions = [
        <Button variant="save" disabled={formik.isSubmitting} onClick={() => void formik.submitForm()} key="btn-save">
            {Translations.Save}
        </Button>,
    ];

    return (
        <EditDialog
            open={open}
            title={
                formik.values.workTimeTypeId === null
                    ? Translations.AddNew
                    : Translations.Edit
            }
            formik={formik}
            onClose={onClose}
            primaryActions={primaryActions}
            ref={dialogRef}
            body={
                !typeOptions ? (
                    <LoadingIndicator />
                ) : (
                    <Grid2
                        container
                        direction="column"
                        spacing={STYLE_CONSTANTS.formSpacing}
                        sx={{ width: { xs: "100%", md: "auto" } }}
                    >
                        <Grid2 xs={12}>
                            <TextField
                                label={Translations.Name}
                                fullWidth
                                required
                                autoComplete="stringToDisableAutoComplete"
                                {...formikFieldProps(formik, "name")}
                            />
                        </Grid2>
                        <Grid2 xs={12}>
                            <MuiSelect
                                label={t("workTimeType.type")}
                                options={typeOptions}
                                required
                                value={formik.values.type}
                                onChange={(val: string) =>
                                    void formik.setFieldValue(
                                        "type",
                                        val.toInteger()
                                    )
                                }
                            />
                        </Grid2>
                        <Grid2 xs={12}>
                            <MuiSwitch
                                label={t("workTimeType.calculateOvertime")}
                                checked={formik.values.calculateOvertime}
                                onChange={(e) =>
                                    void formik.setFieldValue(
                                        "calculateOvertime",
                                        e.target.checked
                                    )
                                }
                            />
                        </Grid2>
                    </Grid2>
                )
            }
        />
    );
};
