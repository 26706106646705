import Grid2 from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import {
    FormikForm,
    formikValidationProps,
} from "../../framework/formUtils";
import { MuiDatePicker } from "../../framework/muiDatepicker";
import TextField from "@mui/material/TextField";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import { RepeatEnabledDays, TransportPlanTemplateFormData } from "../../../models/transport/transportPlanTemplate";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { Base } from "../../../framework/base";
import { getEnabledDays } from "../transportTimeline/Utils";
import { useTheme } from "@mui/material";

interface IDurationProps {
    days?: string;
    hours?: string;
    minutes?: string;
}

const DATE_FORMAT = "YYYY-MM-DD";
const isValidDate = (val: string) => val !== undefined && val !== null && dayjs(val, DATE_FORMAT).isValid();

export const TransportPlanRecurringView = ({ formik }: { formik: FormikForm<TransportPlanTemplateFormData> }) => {
    const [selectedDays, setSelectedDays] = useState({});
    const [scheduledStartTime, setScheduledStartTime] = useState<Dayjs>(null);
    const theme = useTheme();
    const { repeatStartDate, repeatEndDate } = formik?.values;

    const repeatStartDateValue = repeatStartDate && isValidDate(dayjs(repeatStartDate).format(DATE_FORMAT)) ? dayjs(repeatStartDate).toDate() : null;
    const repeatEndDateValue = repeatEndDate && isValidDate(dayjs(repeatEndDate).format(DATE_FORMAT)) ? dayjs(repeatEndDate).toDate() : null;

    const calculateValues = ({ days, hours, minutes }: IDurationProps) => {
        const { durationDay, durationHours, durationMinutes } = formik.values;
        const daysInt = parseInt(days || durationDay) || 0;
        const hoursInt = parseInt(hours || durationHours) || 0;
        const minutesInt = parseInt(minutes || durationMinutes) || 0;
        const total = daysInt * 24 * 60 + hoursInt * 60 + minutesInt;
        return {
            duration: total,
        };
    };

    const handleDayChange = (days: string) => {
        const { duration } = calculateValues({ days });
        void formik.setValues({
            ...formik.values,
            ["durationDay"]: days,
            ["duration"]: duration,
        });
    };

    const handleHoursChange = (hours: string) => {
        const { duration } = calculateValues({ hours });
        void formik.setValues({
            ...formik.values,
            ["durationHours"]: hours,
            ["duration"]: duration,
        });
    };

    const handleMinutesChange = (minutes: string) => {
        const { duration } = calculateValues({ minutes });
        void formik.setValues({
            ...formik.values,
            ["durationMinutes"]: minutes,
            ["duration"]: duration,
        });
    };

    const handleDaySelect = (week, days) => {
        setSelectedDays((prevSelectedDays) => ({
            ...prevSelectedDays,
            [week]: days,
        }));
    };

    const handleScheduledStartTime = (val: Dayjs) => {
        setScheduledStartTime(val);
        const formattedTime = val?.format("HH:mm:ss.SSS");
        void formik.setFieldValue("scheduledStartTime", formattedTime ?? "");
    };

    useEffect(() => {
        const enabledDays = getEnabledDays(formik.values.repeatEnabledDays, true);
        setSelectedDays(enabledDays);
        handleScheduledStartTime(dayjs(formik.values.scheduledStartTime, "HH:mm:ss"));
    }, []);

    useEffect(() => {
        const week1: [] = selectedDays[1] || [];
        const week2: [] = selectedDays[2] || [];

        void formik.setFieldValue(
            "repeatEnabledDays",
            week1.concat(week2).reduce((acc: number, currentValue: number) => acc + currentValue, 0) ?? null
        );
    }, [selectedDays]);

    const buttonStyles = {
        "&.Mui-selected": {
            width: "50px",
            margin: "0px 6px 0px 0px !important",
            borderRadius: "5px !important",
            backgroundColor: "primary.main",
            color: "primary.contrastText",
            boxShadow: "inset 0px 2px 4px rgba(0,0,0,0.2)",
            border: "none",
            "&:hover": {
                backgroundColor: "primary.light",
            },
            "&.Mui-disabled": {
                backgroundColor: theme.palette.grey[300],
                color: theme.palette.grey[400],
                boxShadow: "inset 0px 2px 4px rgba(0,0,0,0)",
                border: "none",
            },
        },
        "&:not(.Mui-selected)": {
            width: "50px",
            margin: "0px 6px 0px 0px !important",
            borderRadius: "5px !important",
            color: "primary.light",
            boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
            borderColor: "primary.light",
            border: "1px solid !important",
            "&.Mui-disabled": {
                backgroundColor: theme.palette.white,
                color: theme.palette.grey[400],
                boxShadow: "inset 0px 2px 4px rgba(0,0,0,0)",
                border: "none",
            },
        },

    };
    return (
        <Grid2>
            <Grid2 spacing={3}>
                <Typography>Valitse päivät joina kuljetussuunnitelma ajetaan. *</Typography>
                <Grid2 container spacing={3} mt={2}>
                    <Grid2>
                        <Typography sx={{ color: "primary.dark", fontWeight: "bold" }} variant="subtitle1">Viikko 1</Typography>
                        <ToggleButtonGroup
                            value={selectedDays[1]}
                            onChange={(e, newDays) => handleDaySelect(1, newDays)}
                            aria-label="Select weekdays for week 1"
                        >
                            <ToggleButton value={RepeatEnabledDays.Monday1} sx={buttonStyles}>Ma</ToggleButton>
                            <ToggleButton value={RepeatEnabledDays.Tuesday1} sx={buttonStyles}>Ti</ToggleButton>
                            <ToggleButton value={RepeatEnabledDays.Wednesday1} sx={buttonStyles}>Ke</ToggleButton>
                            <ToggleButton value={RepeatEnabledDays.Thursday1} sx={buttonStyles}>To</ToggleButton>
                            <ToggleButton value={RepeatEnabledDays.Friday1} sx={buttonStyles}>Pe</ToggleButton>
                            <ToggleButton value={RepeatEnabledDays.Saturday1} sx={buttonStyles}>La</ToggleButton>
                            <ToggleButton value={RepeatEnabledDays.Sunday1} sx={buttonStyles}>Su</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid2>
                    <Grid2>
                        <Typography sx={{ color: "primary.dark", fontWeight: "bold" }} variant="subtitle1">Viikko 2</Typography>
                        <ToggleButtonGroup
                            color="primary"
                            value={selectedDays[2]}
                            onChange={(e, newDays) => handleDaySelect(2, newDays)}
                            aria-label="Select weekdays for week 2"
                        >
                            <ToggleButton value={RepeatEnabledDays.Monday2} sx={buttonStyles}>Ma</ToggleButton>
                            <ToggleButton value={RepeatEnabledDays.Tuesday2} sx={buttonStyles}>Ti</ToggleButton>
                            <ToggleButton value={RepeatEnabledDays.Wednesday2} sx={buttonStyles}>Ke</ToggleButton>
                            <ToggleButton value={RepeatEnabledDays.Thursday2} sx={buttonStyles}>To</ToggleButton>
                            <ToggleButton value={RepeatEnabledDays.Friday2} sx={buttonStyles}>Pe</ToggleButton>
                            <ToggleButton value={RepeatEnabledDays.Saturday2} sx={buttonStyles}>La</ToggleButton>
                            <ToggleButton value={RepeatEnabledDays.Sunday2} sx={buttonStyles}>Su</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid2>
                </Grid2>
            </Grid2>

            <Grid2 container spacing={3}>
                <Grid2>
                    <Typography variant="h3" mt={4}>Aloitusaika</Typography>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="fi"
                    >
                        <DesktopTimePicker
                            label="Aloitusaika"
                            value={scheduledStartTime}
                            onChange={(val) => {
                                handleScheduledStartTime(val);
                            }}
                            slotProps={{
                                textField: {
                                    required: true,
                                    ...formikValidationProps(formik, "scheduledStartTime"),
                                },
                            }}
                        />
                    </LocalizationProvider>
                </Grid2>
                <Grid2>
                    <Typography variant="h3" mt={4}>Kesto *</Typography>
                    <Grid2 container spacing={1} >
                        <Grid2>
                            <TextField
                                sx={{ maxWidth: "300" }}
                                type="number"
                                label="pv"
                                value={formik.values.durationDay > 0 ? formik.values.durationDay : null}
                                onChange={(e) => handleDayChange(e.target.value)}
                                inputProps={{
                                    min: 0,
                                    max: 100,
                                }}
                                onBlur={() => {
                                    void formik.setFieldTouched("durationDay", true, true);
                                    void formik.validateField("duration");
                                }}
                            />
                        </Grid2>
                        <Grid2>
                            <TextField
                                sx={{ maxWidth: "300" }}
                                type="number"
                                label="h"
                                value={formik.values.durationHours > 0 ? formik.values.durationHours : null}
                                onChange={(e) => handleHoursChange(e.target.value)}
                                inputProps={{
                                    min: 0,
                                    max: 23,
                                }}
                                onBlur={() => {
                                    void formik.setFieldTouched("durationHours", true, true);
                                    void formik.validateField("duration");
                                }}
                            />
                        </Grid2>
                        <Grid2>
                            <TextField
                                sx={{ maxWidth: "300" }}
                                type="number"
                                label="min"
                                value={formik.values.durationMinutes > 0 ? formik.values.durationMinutes : null}
                                onChange={(e) => handleMinutesChange(e.target.value)}
                                inputProps={{
                                    min: 0,
                                    max: 59,
                                    step: 5
                                }}
                                onBlur={() => {
                                    void formik.setFieldTouched("durationMinutes", true, true);
                                    void formik.validateField("duration");
                                }}
                            />
                        </Grid2>
                    </Grid2>
                    {formik.errors.duration && (formik.touched.durationDay || formik.touched.durationHours || formik.touched.durationMinutes) && (
                        <Typography color={"error"} fontSize={12} ml={2}>{formik.errors.duration as any}</Typography>
                    )}
                </Grid2>
            </Grid2>
            <Grid2 container spacing={3}>
                <Grid2>
                    <Typography variant="h3" mt={4}>Toistuvuuden voimassaoloaika</Typography>
                    <Grid2 container spacing={3}>
                        <Grid2>
                            <MuiDatePicker
                                name="repeatStartDate"
                                value={repeatStartDateValue}
                                onChange={(val) => {
                                    void formik.setFieldValue("repeatStartDate", Base.dayjsToJsonDate(val) ?? null);
                                }}
                                minDate={new Date()}
                                label="Aloitusaika"
                                extraProps={{
                                    textFieldProps: {
                                        required: true,
                                        ...formikValidationProps(formik, "repeatStartDate"),
                                    }
                                }}
                            />
                        </Grid2>
                        <Grid2>
                            <MuiDatePicker
                                name="repeatEndDate"
                                value={repeatEndDateValue}
                                onChange={(val) => {
                                    void formik.setFieldValue("repeatEndDate", Base.dayjsToJsonDate(val) ?? null);
                                }}
                                minDate={new Date()}
                                label="Päättymisaika"
                                extraProps={{
                                    textFieldProps: {
                                        required: true,
                                        ...formikValidationProps(formik, "repeatEndDate"),
                                    }
                                }}
                            />
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Grid2>
        </Grid2 >
    );
};
