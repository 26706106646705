export interface IReverseGeocodeResult {
    formattedAddress: string;
    street: string;
    postalCode: string;
    city: string;
    countryCode: string;
}

export class ReverseGeocodeResult implements IReverseGeocodeResult {
    formattedAddress: string;
    street: string;
    postalCode: string;
    city: string;
    countryCode: string;

    constructor();
    constructor(obj: IReverseGeocodeResult);
    constructor(obj?: any) {
        this.formattedAddress = obj && obj.formattedAddress || "";
        this.street = obj && obj.street || "";
        this.postalCode = obj && obj.postalCode || "";
        this.city = obj && obj.city || "";
        this.countryCode = obj && obj.countryCode || "";
    }
}