export interface IOperationLogItem {
    id: string;
    time: number;
    entryType: string;
    ip: string;
    operation: string;
    message: string;
    user: string;
}

export class OperationLogItem implements IOperationLogItem {
    id: string;
    time: number;
    entryType: string;
    ip: string;
    operation: string;
    message: string;
    user: string;

    constructor();
    constructor(obj: IOperationLogItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.time = obj && obj.time || 0;
        this.entryType = obj && obj.entryType || "";
        this.ip = obj && obj.ip || "";
        this.operation = obj && obj.operation || "";
        this.message = obj && obj.message || "";
        this.user = obj && obj.user || "";
    }
}