export interface IVehicleEditItemIntegration {
    id: string;
    serviceName: string;
    uniqueId: string;
    activeState: number;
}

export class VehicleEditItemIntegration implements IVehicleEditItemIntegration {
    id: string;
    serviceName: string;
    uniqueId: string;
    activeState: number;

    constructor();
    constructor(obj: IVehicleEditItemIntegration);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.serviceName = obj && obj.serviceName || "";
        this.uniqueId = obj && obj.uniqueId || "";
        this.activeState = obj && obj.activeState || 0;
    }
}

export class WorkOrderEditItemSaveIntegration {
    id: string;
    uniqueId: string;
    activeState: number;

    constructor();
    constructor(obj: IVehicleEditItemIntegration);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.uniqueId = obj && obj.uniqueId || "";
        this.activeState = obj && obj.activeState || 0;
    }
}