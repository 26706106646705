// InvoiceCreateInvoicesDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as baseService from "../../services/baseService";
import * as storeActions from "../../models/store/storeActions";
import * as workOrderService from "../../services/workOrderService";
import { Dialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { SaveData } from "../../framework/saveData";
import { CheckBox } from "../framework/checkbox";
import { InvoiceDialogWorkerOrderListHeader, InvoiceDialogWorkerOrderListLine } from "./invoiceDialogWorkerOrderList";

// InvoiceCreateInvoicesDialogListHeader
export interface IInvoiceCreateInvoicesDialogListHeaderProp {
    sortColumn: string;
    sortOrderIsAsc: boolean;
    onColumnClick: (column: string) => void;
}

export interface IInvoiceCreateInvoicesDialogItem {
    id: string;
    number: number;
    parentNumber: number;
    name: string;
    startTime: number;
    endTime: number;
    category: number;
    state: number;
    stateStr: string;
    customerId: string;
    customerName: string;
    siteName: string;
    siteNumber: string;

    isTask(): boolean;
    isProject(): boolean;
}

// InvoiceCreateInvoicesDialog
// ***********************************************************************************************************************
export interface IInvoiceCreateInvoicesDialogProp {
    classes?: string;
    items: IInvoiceCreateInvoicesDialogItem[];
    sortColumn: string;
    sortOrderIsAsc: boolean;
    onCancel: () => void;
}

export interface IInvoiceCreateInvoicesDialogState {
    items: IInvoiceCreateInvoicesDialogItem[];
    hasProjects: boolean;
    sortColumn: string;
    sortOrderIsAsc: boolean;
    combineProjectWorkOrders: boolean;
    combineCustomerWorkOrders: boolean;
    combineWorkOrderTypeWorkOrders: boolean;
}

export class InvoiceCreateInvoicesDialog extends React.Component<IInvoiceCreateInvoicesDialogProp, IInvoiceCreateInvoicesDialogState> {
    constructor(props: IInvoiceCreateInvoicesDialogProp) {
        super(props);
        const hasProjects = props.items.filter(i => i.isProject()).length > 0;
        const items = props.items.slice(0);
        const sortColumn = props.sortColumn ?? "number";
        const sortOrderIsAsc = props.sortOrderIsAsc ?? true;
        this.sortInvoiceCreateInvoicesDialogItems(items, sortColumn, sortOrderIsAsc);
        this.state = {
            items: items,
            hasProjects: hasProjects,
            sortColumn: sortColumn,
            sortOrderIsAsc: sortOrderIsAsc,
            combineProjectWorkOrders: hasProjects,
            combineCustomerWorkOrders: false,
            combineWorkOrderTypeWorkOrders: false
        };
    }

    sortInvoiceCreateInvoicesDialogItems = (items: IInvoiceCreateInvoicesDialogItem[], column: string, asc: boolean) => {
        if (Base.isNullOrUndefined(items) || items.length < 2) return;
        let sortFunc: (a: IInvoiceCreateInvoicesDialogItem, b: IInvoiceCreateInvoicesDialogItem) => number;
        if (column === "startTime") sortFunc = (a: IInvoiceCreateInvoicesDialogItem, b: IInvoiceCreateInvoicesDialogItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.startTime, b.startTime)); };
        else if (column === "endTime") sortFunc = (a: IInvoiceCreateInvoicesDialogItem, b: IInvoiceCreateInvoicesDialogItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.endTime, b.endTime)); };
        else if (column === "name") sortFunc = (a: IInvoiceCreateInvoicesDialogItem, b: IInvoiceCreateInvoicesDialogItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.name, b.name)); };
        else if (column === "customerName") sortFunc = (a: IInvoiceCreateInvoicesDialogItem, b: IInvoiceCreateInvoicesDialogItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.customerName, b.customerName)); };
        else if (column === "siteName") sortFunc = (a: IInvoiceCreateInvoicesDialogItem, b: IInvoiceCreateInvoicesDialogItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.siteName, b.siteName)); };
        else if (column === "state") sortFunc = (a: IInvoiceCreateInvoicesDialogItem, b: IInvoiceCreateInvoicesDialogItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.state, b.state)); };
        else if (column === "category") sortFunc = (a: IInvoiceCreateInvoicesDialogItem, b: IInvoiceCreateInvoicesDialogItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.category, b.category)); };
        else sortFunc = (a: IInvoiceCreateInvoicesDialogItem, b: IInvoiceCreateInvoicesDialogItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.number, b.number)); };
        items.sort(sortFunc);
    };

    changeSortColumn = (sortColumn: string) => {
        const oldSortColumn = this.state.sortColumn;
        const sortOrderIsAsc = oldSortColumn === sortColumn ? !this.state.sortOrderIsAsc : true;
        const items = this.state.items.slice(0);
        this.sortInvoiceCreateInvoicesDialogItems(items, sortColumn, sortOrderIsAsc);
        this.setState({ items: items, sortColumn: sortColumn, sortOrderIsAsc: sortOrderIsAsc });
    };

    handleCombineProjectWorkOrdersChange = (value: boolean) => {
        this.setState({ combineProjectWorkOrders: value });
    };

    handleCombineCustomerWorkOrdersChange = (value: boolean) => {
        this.setState({ combineCustomerWorkOrders: value });
    };

    handleCombineWorkOrderTypeWorkOrdersChange = (value: boolean) => {
        this.setState({ combineWorkOrderTypeWorkOrders: value });
    };

    private static validate = (state: IInvoiceCreateInvoicesDialogState): boolean => {
        // if (!state.registerNumber) {
        //     store.customStore.dispatch(storeActions.showErrorMessage(Translations.RegisterNumberMustBeDefined));
        //     return false;
        // }
        return true;
    };

    private static getSaveDataFromState = (props: IInvoiceCreateInvoicesDialogProp, state: IInvoiceCreateInvoicesDialogState): SaveData => {
        const data = new SaveData();
        data.append("combineProjectWorkOrders", state.combineProjectWorkOrders.toString());
        data.append("combineCustomerWorkOrders", state.combineCustomerWorkOrders.toString());
        data.append("combineWorkOrderTypeWorkOrders", ((state.combineProjectWorkOrders || state.combineCustomerWorkOrders) ? state.combineWorkOrderTypeWorkOrders : true).toString());
        data.append("workOrderIds", JSON.stringify(props.items.map(i => i.id)));
        return data;
    };

    handleCreateInvoicesClick = () => {
        const obj = this;
        const state = this.state;
        if (!InvoiceCreateInvoicesDialog.validate(state)) return;
        const saveData = InvoiceCreateInvoicesDialog.getSaveDataFromState(this.props, state);
        if (Base.isNullOrUndefined(saveData)) return;
        // Call server
        store.customStore.dispatch(storeActions.fetchStart());
        workOrderService.createInvoices(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                obj.props.onCancel();
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const items = state.items;
        const dialogClasses = "settings workOrderCreateInvoices px1000" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <Dialog
                    classes={dialogClasses}
                    title={Translations.CreateInvoices}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-12">
                                <div>
                                    <InvoiceDialogWorkerOrderListHeader
                                        sortColumn={state.sortColumn}
                                        sortOrderIsAsc={state.sortOrderIsAsc}
                                        onColumnClick={this.changeSortColumn}
                                    />
                                </div>
                                <div className="listContainer noTopMargin">
                                    <div className="list striped">
                                        <div className="lineContainer">
                                            {items.map((item) =>
                                                <InvoiceDialogWorkerOrderListLine
                                                    key={item.id}
                                                    item={item}
                                                    selectedId={""}
                                                    onClick={null}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {state.hasProjects &&
                            <div className="row">
                                <div className="col-12">
                                    <CheckBox
                                        title={Translations.CombineProjectWorkOrdersToSameInvoice}
                                        enabled={true}
                                        checked={state.combineProjectWorkOrders}
                                        onCheckboxClickBoolean={this.handleCombineProjectWorkOrdersChange}
                                    />
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-12">
                                <CheckBox
                                    title={Translations.CombineCustomersWorkOrdersToSameInvoice}
                                    enabled={true}
                                    checked={state.combineCustomerWorkOrders}
                                    onCheckboxClickBoolean={this.handleCombineCustomerWorkOrdersChange}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <CheckBox
                                    title={Translations.CombineWorkOrdersByWorkOrderTypesToSameInvoice}
                                    enabled={state.combineProjectWorkOrders || state.combineCustomerWorkOrders}
                                    checked={state.combineWorkOrderTypeWorkOrders}
                                    onCheckboxClickBoolean={this.handleCombineWorkOrderTypeWorkOrdersChange}
                                />
                            </div>
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.CreateInvoices, classes: "btn-primary", enabled: true, onClick: this.handleCreateInvoicesClick }
                    ]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}