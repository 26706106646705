import { Base } from "../../framework/base";
import { VehicleGroupItem, IVehicleGroupItem } from "./vehicleGroupItem";

export interface IVehicleGroupItems {
    items: IVehicleGroupItem[];
    hasMore: boolean;
    page: number;
}

export class VehicleGroupItems implements IVehicleGroupItems {
    items: VehicleGroupItem[];
    hasMore: boolean;
    page: number;

    constructor();
    constructor(obj: IVehicleGroupItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = Base.getTypedArray<VehicleGroupItem>(obj ? obj.items : null, VehicleGroupItem);
    }
}