import { IWorkOrderEditItem, WorkOrderEditItem } from "./workOrderEditItem";
import { IIdTitle, IdTitle } from "../common/idTitle";
import { Base } from "../../framework/base";
import { IObjectEventLogItem, ObjectEventLogItem } from "../eventLog/objectEventLogItem";
import { IWorkOrderEditSite, WorkOrderEditSite } from "./workOrderEditSite";
import { IWorkOrderEditAcknowledgementType, WorkOrderEditAcknowledgementType } from "./workOrderEditAcknowledgementType";
import { IWorkOrderEditContact, WorkOrderEditContact } from "./workOrderEditContact";
import { INumberTitle, NumberTitle } from "../common/numberTitle";
import { IWorkOrderEditProductItem, WorkOrderEditProductItem } from "./workOrderEditProductItem";
import { TimeFormat } from "../common/enums";
import { IWorkOrderEditWorkOrderTypeItem, WorkOrderEditWorkOrderTypeItem } from "./workOrderEditWorkOrderTypeItem";
import { IWorkShiftTimeSlotType, WorkShiftTimeSlotType } from "../workShiftTimeSlotType/workShiftTimeSlotType";
import { IVehicleItem, VehicleItem } from "../vehicle/vehicleItem";
import { IEmployeeItem, EmployeeItem } from "../employee/employeeItem";

export interface IWorkOrderEdit {
    workTimeFormat: TimeFormat;
    mapLinkTemplate: string;
    directionsMapLinkTemplate: string;
    workOrder: IWorkOrderEditItem;
    customers: IIdTitle[];
    customerLogo: string;
    workOrderStates: INumberTitle[];
    measureUnits: IIdTitle[];
    routePointWorkShiftTimeSlotTypes: IWorkShiftTimeSlotType[];
    routePointTypes: IIdTitle[];
    workOrderTypes: IWorkOrderEditWorkOrderTypeItem[];
    workOrderAcknowledgementTypes: IWorkOrderEditAcknowledgementType[];
    sites: IWorkOrderEditSite[];
    contacts: IWorkOrderEditContact[];
    employees: IEmployeeItem[];
    vehicles: IVehicleItem[];
    products: IWorkOrderEditProductItem[];
    eventLogs: IObjectEventLogItem[];

    initStateState: number;
    initStateEmployeeIds: string[];
    initStateVehicleIds: string[];
    initStateStartTime: number;
}

export class WorkOrderEdit implements IWorkOrderEdit {
    workTimeFormat: TimeFormat;
    mapLinkTemplate: string;
    directionsMapLinkTemplate: string;
    workOrder: WorkOrderEditItem;
    customers: IdTitle[];
    customerLogo: string;
    workOrderStates: NumberTitle[];
    measureUnits: IdTitle[];
    routePointWorkShiftTimeSlotTypes: WorkShiftTimeSlotType[];
    routePointTypes: IdTitle[];
    workOrderTypes: WorkOrderEditWorkOrderTypeItem[];
    workOrderAcknowledgementTypes: WorkOrderEditAcknowledgementType[];
    sites: WorkOrderEditSite[];
    contacts: WorkOrderEditContact[];
    employees: EmployeeItem[];
    vehicles: VehicleItem[];
    products: WorkOrderEditProductItem[];
    eventLogs: ObjectEventLogItem[];

    initStateState: number;
    initStateEmployeeIds: string[];
    initStateVehicleIds: string[];
    initStateStartTime: number;

    constructor();
    constructor(obj: IWorkOrderEdit);
    constructor(obj?: any) {
        this.initStateState = null;
        this.initStateEmployeeIds = null;
        this.initStateVehicleIds = null;
        this.initStateStartTime = null;
        this.workTimeFormat = obj && obj.workTimeFormat || TimeFormat.DecimalFormat;
        this.mapLinkTemplate = obj && obj.mapLinkTemplate || "";
        this.directionsMapLinkTemplate = obj && obj.directionsMapLinkTemplate || "";
        this.workOrder = null;
        if (obj && obj.workOrder) {
            this.workOrder = new WorkOrderEditItem(obj.workOrder);
        }
        this.customers = Base.getTypedArray<IdTitle>(obj ? obj.customers : null, IdTitle);
        this.customerLogo = obj && obj.customerLogo || "";
        this.workOrderStates = Base.getTypedArray<NumberTitle>(obj ? obj.workOrderStates : null, NumberTitle);
        this.measureUnits = Base.getTypedArray<IdTitle>(obj ? obj.measureUnits : null, IdTitle);
        this.routePointWorkShiftTimeSlotTypes = Base.getTypedArray<WorkShiftTimeSlotType>(obj ? obj.routePointWorkShiftTimeSlotTypes : null, WorkShiftTimeSlotType);
        this.routePointTypes = Base.getTypedArray<IdTitle>(obj ? obj.routePointTypes : null, IdTitle);
        this.workOrderTypes = Base.getTypedArray<WorkOrderEditWorkOrderTypeItem>(obj ? obj.workOrderTypes : null, WorkOrderEditWorkOrderTypeItem);
        this.employees = Base.getTypedArray<EmployeeItem>(obj ? obj.employees : null, EmployeeItem);
        this.contacts = Base.getTypedArray<WorkOrderEditContact>(obj ? obj.contacts : null, WorkOrderEditContact);
        this.workOrderAcknowledgementTypes = Base.getTypedArray<WorkOrderEditAcknowledgementType>(obj ? obj.workOrderAcknowledgementTypes : null, WorkOrderEditAcknowledgementType);
        this.sites = Base.getTypedArray<WorkOrderEditSite>(obj ? obj.sites : null, WorkOrderEditSite);
        this.vehicles = Base.getTypedArray<VehicleItem>(obj ? obj.vehicles : null, VehicleItem);
        this.products = Base.getTypedArray<WorkOrderEditProductItem>(obj ? obj.products : null, WorkOrderEditProductItem);
        this.eventLogs = Base.getTypedArray<ObjectEventLogItem>(obj ? obj.eventLogs : null, ObjectEventLogItem);
    }
}