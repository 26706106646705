import { ICompetencyItem, CompetencyItem } from "./competencyItem";

export interface ICompetencyItems {
    items: ICompetencyItem[];
    hasMore: boolean;
    page: number;
}

export class CompetencyItems implements ICompetencyItems {
    items: CompetencyItem[];
    hasMore: boolean;
    page: number;

    constructor();
    constructor(obj: ICompetencyItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = [];
        if (obj && obj.items) {
            for (let i = 0; i < obj.items.length; i++) {
                this.items.push(new CompetencyItem(obj.items[i]));
            }
        }
    }
}