import { Base } from "../../framework/base";
import { IContactEditItem } from "../contact/contactEditItem";

export interface IWorkOrderEditContact {
    id: string;
    name: string;
    email: string;
    phone: string;
    comment: string;

    getTitle(): string;
}

export class WorkOrderEditContact implements IWorkOrderEditContact {
    id: string;
    name: string;
    email: string;
    phone: string;
    comment: string;

    constructor();
    constructor(obj: IWorkOrderEditContact);
    constructor(obj: IContactEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
        this.email = obj && obj.email || "";
        this.phone = obj && obj.phone || "";
        this.comment = obj && obj.comment || "";
    }

    getTitle(): string {
        return this.name;
    }

    static createWorkOrderEditContact(id: string, name: string, phone: string, email: string, comment: string): WorkOrderEditContact {
        const result = new WorkOrderEditContact();
        result.id = id;
        result.name = name;
        result.email = email;
        result.phone = phone;
        result.comment = comment;
        return result;
    }

    static sortWorkOrderEditContacts(items: IWorkOrderEditContact[], column: string, asc: boolean) {
        if (!items || items.length < 2) return;
        let sortFunc: (a: IWorkOrderEditContact, b: IWorkOrderEditContact) => number;
        if (column === "email") sortFunc = (a: IWorkOrderEditContact, b: IWorkOrderEditContact) => { return (!a || !b) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.email, b.email)); };
        else if (column === "phone") sortFunc = (a: IWorkOrderEditContact, b: IWorkOrderEditContact) => { return (!a || !b) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.phone, b.phone)); };
        else if (column === "comment") sortFunc = (a: IWorkOrderEditContact, b: IWorkOrderEditContact) => { return (!a || !b) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.comment, b.comment)); };
        else sortFunc = (a: IWorkOrderEditContact, b: IWorkOrderEditContact) => { return (!a || !b) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.name, b.name)); };
        items.sort(sortFunc);
    }

    static getEmailById(items: IWorkOrderEditContact[], id: string): string {
        const item = items.find(i => i.id === id);
        return item ? item.email : "";
    }
}
