import { Box, Button, FormControlLabel, IconButton, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Stack } from "@mui/system";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Link, RouteComponentProps, useLocation } from "react-router-dom";
import { customHistory } from "../../../framework/customHistory";
import { STYLE_CONSTANTS } from "../../../framework/theme";
import { Translations } from "../../../models/translations";
import { TransportOrderListItemDto, } from "../../../models/transport/transportOrder";
import { TransportPlanDetailsDto, TransportPlanState, TransportPlanUpdateDto } from "../../../models/transport/transportPlan";
import { apiCall } from "../../../services/apiClient";
import {
    FormikForm,
    GridBreak,
    defaultFormikConfig,
    formikFieldProps,
    formikValidationProps,
    showApiError,
    showApiSuccess,
    showConfirm
} from "../../framework/formUtils";
import { LoadingIndicator } from "../../framework/loadingIndicator";
import { MuiDatePicker } from "../../framework/muiDatepicker";
import { TabPanel } from "../../framework/tabPanel";
import { MainLayout } from "../../layout/mainLayout";
import { OrdersTable } from "../TransportOrdersMain";
import { DriverSelect } from "../components/driverSelect";
import { VehicleSelect } from "../components/vehicleSelect";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { MuiSwitch } from "../../framework/muiSwitch";
import { TransportPlanRecurringView } from "./transportPlanRecurringView";
import { TransportPlanTemplateFormData } from "../../../models/transport/transportPlanTemplate";
import { createRecurringTransportPlan } from "../../../services/transportPlanTemplateService";
import { useAppDispatch } from "../../../framework/customStore";
import { showErrorMessage } from "../../../models/store/storeActions";
import { transportPlanValidationSchema } from "../../../framework/schemas/transportPlanValidationSchema";
import { useParams } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

function getFormModel(details: TransportPlanDetailsDto): TransportPlanUpdateDto {
    return {
        id: details.id,
        name: details.name,
        employeeId: details.employee?.id,
        vehicleId: details.vehicle?.id,
        scheduledStartDateTime: details.scheduledStartDateTime,
        scheduledEndDateTime: details.scheduledEndDateTime
    };
}

export const TransportPlanMain = (props: RouteComponentProps<{ id: string }>) => {
    const location = useLocation<{ fromPath: string }>();

    const id = props.match.params.id === "new" ? null : props.match.params.id;

    const fromPath = location.state?.fromPath ?? "/transport/plans/grid";

    const [item, setItem] = useState<TransportPlanDetailsDto | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (id === null) {
            setItem({
                id: null,
                name: "",
                state: TransportPlanState.Todo,
                orders: [],
                employee: null,
                vehicle: null,
                scheduledStartDateTime: null,
                scheduledEndDateTime: null,
            });
        } else {
            setLoading(true);
            apiCall<TransportPlanDetailsDto>(`TransportPlans/${id}`, "GET")
                .then(res => {
                    setItem(res.data);
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => setLoading(false));
        }

    }, [id]);

    const handleSaved = (data: TransportPlanDetailsDto) => {
        customHistory.replace(`/transport/plans/plan/${data.id}`);
    };

    const handleRemoved = () => {
        customHistory.replace(fromPath);
    };

    return (
        <MainLayout>
            <>
                <IconButton
                    component={Link}
                    to={fromPath}
                    color="primary"
                >
                    <ArrowBackIcon />
                </IconButton>

                <Stack m={4} spacing={2}>
                    <Typography variant="h2">
                        {id === null ? "Luo uusi kuljetussuunnitelma" : "Muokkaa kuljetussuunnitelmaa"}
                    </Typography>
                    {item?.transportPlanTemplateId &&
                        <Typography>
                            <ErrorOutlineIcon sx={{ color: "primary.light" }} />
                            {" Olet muokkaamassa toistuvan kuljetussuunnitelman yksittäistä kuljetussuunnitelmaa"}
                        </Typography>
                    }
                </Stack>
                <>
                    {loading && <LoadingIndicator />}
                    {item &&
                        <TransportPlanForm
                            item={item}
                            orders={item.orders}
                            onSaved={handleSaved}
                            onRemoved={handleRemoved}
                        />}
                </>
            </>
        </MainLayout>
    );
};

interface TransportPlanFormProps {
    item: TransportPlanDetailsDto | TransportPlanUpdateDto;
    onlyBasics?: boolean;
    orders?: TransportOrderListItemDto[];
    autoFocus?: boolean;
    onSaved: (data: TransportPlanDetailsDto) => void;
    onSavedRecurring?: () => void;
    onRemoved: (id: string) => void;
}

export const TransportPlanForm = (props: TransportPlanFormProps) => {
    const [id, setId] = useState<string | null>(props.item.id);
    const location = useLocation<{ initialSelectedTab: number; fromPath: string }>();
    const [selectedTab, setSelectedTab] = useState<number>(location.state?.initialSelectedTab ?? 0);
    const [isRecurring, setIsRecurring] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const validationSchema = transportPlanValidationSchema(isRecurring);
    const item = "employeeId" in props.item ? props.item : getFormModel(props.item as TransportPlanDetailsDto);
    const fromPath = location.state?.fromPath;
    const inTransportOrCompleted = "state" in props.item && (props.item.state === TransportPlanState.Completed || props.item.state === TransportPlanState.InTransport) ? true : false;
    useEffect(() => {
        setId(item.id);
    }, [item]);

    const handleSubmit = (formData: TransportPlanUpdateDto | TransportPlanTemplateFormData) => {
        if (!isRecurring) {
            formik.setSubmitting(true);
            if (!id) {
                apiCall<TransportPlanDetailsDto, TransportPlanUpdateDto>("TransportPlans", "POST", formData)
                    .then(res => {
                        setId(res.data.id);
                        void formik.setValues(getFormModel(res.data));
                        props.onSaved(res.data);
                        showApiSuccess(Translations.SaveSuccess);
                        customHistory.replace(fromPath);
                    })
                    .catch(err => {
                        console.log("err", err);
                        showApiError(Translations.SaveFailed);
                    });
            } else {
                apiCall<TransportPlanDetailsDto, TransportPlanUpdateDto>(`TransportPlans/${id}`, "PUT", formData)
                    .then((res) => {
                        props.onSaved(res.data);
                        showApiSuccess(Translations.SaveSuccess);
                        customHistory.replace(fromPath);
                    })
                    .catch(err => {
                        console.log("err", err);
                        showApiError(Translations.SaveFailed);
                    });
            }
            formik.setSubmitting(false);
        }
        if (isRecurring) {
            formik.setSubmitting(true);
            if (!id) {
                createRecurringTransportPlan(getCreateModel(formData as TransportPlanTemplateFormData))
                    .then(res => {
                        if (res) {
                            if (props.onSavedRecurring) {
                                props.onSavedRecurring();
                            }
                            showApiSuccess(Translations.SaveSuccess);
                            customHistory.replace(fromPath);
                        } else {
                            dispatch(showErrorMessage(Translations.SaveFailed));
                        }
                    }).catch(showApiError);
            }
            formik.setSubmitting(false);
        }
    };

    const getCreateModel = (formData?: TransportPlanTemplateFormData) => {
        if (formData) {
            return {
                name: formData?.name,
                scheduledStartTime: formData?.scheduledStartTime,
                duration: formData?.duration,
                employeeId: formData?.employeeId,
                vehicleId: formData?.vehicleId,
                repeatConfigStartDate: formData?.repeatStartDate,
                repeatConfigEndDate: formData?.repeatEndDate,
                repeatEnabledDays: formData?.repeatEnabledDays,
            };
        } else return;
    };

    const handleRemove = () => {
        const submitRemove = () => {
            apiCall(`TransportPlans/${id}`, "DELETE")
                .then(() => {
                    formik.setSubmitting(false);
                    showApiSuccess(Translations.DeleteWasSuccess);
                    props.onRemoved(id);
                })
                .catch(() => {
                    formik.setSubmitting(false);
                    showApiError("Poisto epäonnistui");
                });
        };

        showConfirm(Translations.AreYouSureWantDelete, submitRemove);
    };

    const handleCreateCopy = () => {
        formik.setSubmitting(true);

        const copyData: TransportPlanUpdateDto = { ...formik.values };
        delete copyData.id;

        apiCall<TransportPlanDetailsDto, TransportPlanUpdateDto>("TransportPlans", "POST", copyData)
            .then(res => {
                setId(res.data.id);
                void formik.setValues(getFormModel(res.data));
                props.onSaved(res.data);
                showApiSuccess("Kopion luominen onnistui");
            })
            .catch(err => {
                showApiError(`Kopion luominen epäonnistui. Virhe: ${err.message as string}`);
            })
            .finally(() => {
                formik.setSubmitting(false);
            });
    };

    const formik = useFormik<TransportPlanUpdateDto | TransportPlanTemplateFormData>({
        ...defaultFormikConfig,
        enableReinitialize: true,
        initialValues: item,
        onSubmit: handleSubmit,
        validationSchema: validationSchema,
    });

    const ActionButtons = (
        <Grid2 container spacing={2}>
            <Grid2>
                <Button
                    variant="contained"
                    color="success"
                    size="large"
                    disabled={!formik.dirty || !formik.isValid || formik.isSubmitting || inTransportOrCompleted}
                    onClick={() => void formik.submitForm()}
                    sx={{ width: "150px" }}
                >
                    {Translations.Save}
                </Button>
            </Grid2>

            {id && (
                <Grid2>
                    <Button
                        variant="contained"
                        disabled={formik.isSubmitting || inTransportOrCompleted}
                        color="error"
                        size="large"
                        onClick={handleRemove} key="btn-remove"
                        sx={{ width: "150px", marginRight: "12px" }}
                    >
                        {Translations.Remove}
                    </Button>
                    <Tooltip title={"Kopioi uudeksi kuljetussuunnitelmaksi"} >
                        <IconButton disabled={formik.isSubmitting} onClick={handleCreateCopy} key="btn-copy">
                            <ContentCopyOutlinedIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                </Grid2>

            )}
        </Grid2>
    );

    if (props.onlyBasics) {
        return (
            <form>
                <TabPanel index={0} selectedTab={selectedTab}>
                    <BasicsTab
                        formik={formik}
                        autoFocus={props.autoFocus}
                        isRecurring={isRecurring}
                        setIsRecurring={setIsRecurring}
                    />
                </TabPanel>
                <Grid2 ml={3} mt={1}>
                    {ActionButtons}
                </Grid2>
            </form>
        );
    } else {
        return (
            <form>
                <Box ml={3}>
                    <Tabs
                        variant="standard"
                        value={selectedTab}
                        onChange={(_, value: number) => setSelectedTab(value)}
                    >
                        <Tab label="Perustiedot" value={0} />
                        <Tab
                            label={`${Translations.Orders} (${props.orders?.length ?? 0})`}
                            value={1}
                            disabled={props.orders?.length === 0}
                        />
                    </Tabs>
                </Box>

                <TabPanel index={0} selectedTab={selectedTab}>
                    <BasicsTab
                        formik={formik}
                        autoFocus={props.autoFocus}
                        isRecurring={isRecurring}
                        setIsRecurring={setIsRecurring}
                        inTransportOrCompleted={inTransportOrCompleted}
                    />
                </TabPanel>

                {props.orders &&
                    <TabPanel index={1} selectedTab={selectedTab}>
                        <OrdersTab orders={props.orders} />
                    </TabPanel>
                }

                {selectedTab !== 1 &&
                    <Grid2 ml={3} mt={1}>
                        {ActionButtons}
                    </Grid2>
                }
            </form>
        );
    }
};

export const BasicsTab = ({ formik, autoFocus, isRecurring, setIsRecurring, inTransportOrCompleted }: { formik: FormikForm; autoFocus: boolean; isRecurring: boolean; setIsRecurring: React.Dispatch<React.SetStateAction<boolean>>; inTransportOrCompleted?: boolean }) => {
    const fieldProps = (field: keyof TransportPlanUpdateDto) => formikFieldProps(formik, field);
    const { id } = useParams<{ id: string }>();
    const toggleRecurring = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsRecurring(event.target.checked);
    };

    const nameInput = useRef<HTMLInputElement>();

    useEffect(() => {
        if (autoFocus) {
            nameInput?.current?.focus();
        }
    }, []);

    return (
        <>
            <Typography variant="h3" mt={1}>Kuljetussuunnitelman perustiedot</Typography>

            <Grid2 container spacing={STYLE_CONSTANTS.formSpacing}>
                <Grid2>
                    <TextField
                        inputRef={nameInput}
                        label="Kuljetussuunnitelman nimi"
                        required
                        disabled={inTransportOrCompleted}
                        {...fieldProps("name")}
                    />
                </Grid2>
                <GridBreak />
            </Grid2>
            {(id === "new" || !id) &&
                <Grid2 container alignItems={"center"} xs={12}>
                    <Typography variant="h3" mt={4}>Toistuvuus</Typography>
                    <FormControlLabel
                        control={
                            <MuiSwitch
                                sx={{ ml: 4 }}
                                color={"success"}
                                checked={isRecurring}
                                onChange={toggleRecurring}
                            />}
                        label="Tee kuljetussuunnitelmasta toistuva"
                    />
                </Grid2>
            }
            {isRecurring &&
                <TransportPlanRecurringView
                    formik={formik}
                />
            }
            {!isRecurring &&
                <>
                    <Typography variant="h3" mt={4}>Aikataulu</Typography>

                    <Grid2 container spacing={STYLE_CONSTANTS.formSpacing}>
                        <Grid2>
                            <MuiDatePicker
                                value={formik.values.scheduledStartDateTime ? new Date(formik.values.scheduledStartDateTime) : null}
                                onChange={(val) => {
                                    void formik.setFieldValue("scheduledStartDateTime", val?.toISOString() ?? null);
                                }}
                                onBlur={() => {
                                    void formik.setFieldTouched("scheduledStartDateTime", true, true);
                                    void formik.validateField("scheduledStartDateTime");
                                }}
                                showTimeSelect
                                label="Suunniteltu alkamisaika"
                                disabled={inTransportOrCompleted}
                                extraProps={{
                                    textFieldProps: {
                                        required: true,
                                        ...formikValidationProps(formik, "scheduledStartDateTime"),
                                    }
                                }}
                            />
                        </Grid2>
                        <Grid2>
                            <MuiDatePicker
                                value={formik.values.scheduledEndDateTime ? new Date(formik.values.scheduledEndDateTime) : null}
                                onChange={(val) => {
                                    void formik.setFieldValue("scheduledEndDateTime", val?.toISOString() ?? null);
                                }}
                                onBlur={() => {
                                    void formik.setFieldTouched("scheduledEndDateTime", true, true);
                                    void formik.validateField("scheduledEndDateTime");
                                }}
                                showTimeSelect
                                label="Suunniteltu päättymisaika"
                                disabled={inTransportOrCompleted}
                                extraProps={{
                                    textFieldProps: {
                                        required: true,
                                        ...formikValidationProps(formik, "scheduledEndDateTime"),
                                    }
                                }}
                            />
                        </Grid2>
                    </Grid2>
                </>
            }
            <Typography variant="h3" mt={4}>Kalusto ja kuljettaja</Typography>

            <Grid2 container spacing={STYLE_CONSTANTS.formSpacing}>
                {/* TODO */}
                {/* <Grid2 xs={12}>
                            <FormControlLabel control={<Switch />} label="TODO: Näytä vain suunnitteluna aikana vapaana olevat kalusta ja kuljettajat" />
                        </Grid2> */}
                <Grid2>
                    <VehicleSelect {...fieldProps("vehicleId")}
                        disabled={inTransportOrCompleted}
                    />
                </Grid2>
                <Grid2>
                    <DriverSelect {...fieldProps("employeeId")}
                        disabled={inTransportOrCompleted}
                    />
                </Grid2>
                <GridBreak />
                {/* TODO */}
                {/* <Grid2>
                            <TextField
                                className="field-width-big"
                                label="Kuljetussuunnitelman lisätiedot"
                                multiline
                                minRows={4}
                                maxRows={20}
                            />
                        </Grid2> */}
            </Grid2>
        </>
    );
};

export const OrdersTab = ({ orders }: { orders: TransportOrderListItemDto[] }) => {
    return (
        <>
            <Typography variant="h3" mt={1}>Kuljetustilaukset</Typography>

            <Grid2 container spacing={STYLE_CONSTANTS.formSpacing}>
                <OrdersTable orders={orders} disablePagination />
            </Grid2>
        </>
    );
};
