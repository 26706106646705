import * as CustomStore from "../../framework/customStore";
import * as StoreActions from "../store/storeActions";
import * as employeeService from "../../services/employeeService";
import * as BaseService from "../../services/baseService";
import { Base } from "../../framework/base";
import { IEmployeeEdit } from "./employeeEdit";

export class EmployeeOperations {
    // #region Edit
    static getEmployeeEdit(id: string): Promise<IEmployeeEdit> {
        CustomStore.customStore.dispatch(StoreActions.fetchStart());
        return employeeService.getEmployeeEdit(id)
            .then(editItem => {
                return editItem;
            })
            .catch(error => {
                CustomStore.customStore.dispatch(StoreActions.showErrorMessage(BaseService.getErrorMessageFromError(error)));
                return Base.getPromiseResult(null);
            })
            .finally(() => CustomStore.customStore.dispatch(StoreActions.fetchEnd()));
    }
    // #endregion Edit
}