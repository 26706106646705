import { Base } from "../../framework/base";
import { ILocationPoint, LocationPoint } from "../common/locationPoint";
import { IWorkOrderEditItemRideBooking } from "../work/workOrderEditItemRideBooking";

export interface IVehicleItem extends ILocationPoint {
    number: number;
    registerNumber: string;
    abbreviation: string;
    inspectionDate: number;
    vehicleGroupId: string;
    vehicleGroup: string;
    brand: string;
    comment: string;
    picture: string;
    locationTime: number;
    activeState: number;
    integrationEnabled: boolean;
    employeeIds: string[];

    getTitle(): string;
    getAvatarText(): string;
}

export class VehicleItem extends LocationPoint implements IVehicleItem {
    number: number;
    registerNumber: string;
    abbreviation: string;
    inspectionDate: number;
    vehicleGroupId: string;
    vehicleGroup: string;
    brand: string;
    comment: string;
    picture: string;
    locationTime: number;
    activeState: number;
    integrationEnabled: boolean;
    employeeIds: string[];

    constructor();
    constructor(obj: IVehicleItem);
    constructor(obj?: any) {
        super(obj);
        this.number = obj && obj.number || 0;
        this.registerNumber = obj && obj.registerNumber || "";
        this.abbreviation = obj && obj.abbreviation || "";
        this.inspectionDate = obj && obj.inspectionDate || 0;
        this.vehicleGroupId = obj && obj.vehicleGroupId || "";
        this.vehicleGroup = obj && obj.vehicleGroup || "";
        this.brand = obj && obj.brand || "";
        this.comment = obj && obj.comment || "";
        this.picture = obj && obj.picture || "";
        this.locationTime = obj && obj.locationTime || 0;
        this.activeState = obj && obj.activeState || 0;
        this.integrationEnabled = obj && obj.integrationEnabled || false;
        this.employeeIds = obj && obj.employeeIds || [];
    }

    getTitle(): string {
        return Base.getStringWithSeparators([this.registerNumber ?? "", this.brand ?? ""], " ");
    }

    getAvatarText(): string {
        if (this.abbreviation) {
            return this.abbreviation.slice(0, 3);
        }
        if (this.registerNumber) {
            return this.registerNumber.slice(0, 3);
        }
        return "";
    }

    getMapTooltip(): string {
        return this.getTitle();
    }

    static getRegisterNumberById(items: IVehicleItem[], id: string): string {
        if (!items || !id || items.length < 1) return "";
        const item = items.find(i => i.id === id);
        if (!item) return "";
        return item.registerNumber;
    }

    static getVehicleItemsByRideBookingsAndEmployeeId(rideBookings: IWorkOrderEditItemRideBooking[], items: IVehicleItem[], employeeId: string): IVehicleItem[] {
        if (!rideBookings || !items || !employeeId) return [];
        return items.filter(i => i.employeeIds.indexOf(employeeId) > -1 || rideBookings.findIndex(j => j.vehicleId === i.id) > -1);
    }

    static getFirstVehicleIdByEmployeeId(items: IVehicleItem[], employeeId: string): string {
        if (!items || !employeeId) return "";
        const item = items.find(i => i.employeeIds.indexOf(employeeId) > -1);
        if (!item) return "";
        return item.id;
    }
}