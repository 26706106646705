import { Base } from "../../framework/base";

export interface IEmployeeEditEmployeeGroupItem {
    id: string;
    employeeGroupId: string;
    groupName: string;
}

export class EmployeeEditEmployeeGroupItem implements IEmployeeEditEmployeeGroupItem {
    id: string;
    employeeGroupId: string;
    groupName: string;

    constructor();
    constructor(obj: IEmployeeEditEmployeeGroupItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.employeeGroupId = obj && obj.employeeGroupId || "";
        this.groupName = obj && obj.groupName || "";
    }

    static sortIdTitles(items: IEmployeeEditEmployeeGroupItem[], column: string, asc: boolean) {
        if (Base.isNullOrUndefined(items) || items.length < 2) return;
        const sortFunc = (a: IEmployeeEditEmployeeGroupItem, b: IEmployeeEditEmployeeGroupItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.groupName, b.groupName)); };
        items.sort(sortFunc);
    }
}

export class EmployeeEditEmployeeGroupSaveItem {
    id: string;
    employeeGroupId: string;

    constructor();
    constructor(obj: IEmployeeEditEmployeeGroupItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.employeeGroupId = obj && obj.employeeGroupId || "";
    }
}
