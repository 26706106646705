import { getApiCall, makeApiCallWithAny, ApiCall, makeFormApiCall } from "./tokenService";
import { StorageProductListItem } from "../models/storage/storageProductListItem";
import { SaveSuccess } from "./baseService";
import { SiteResponseMessage } from "../models/storage/siteResponseMessage";
import { IJsonCustomerEditSiteItemCustomerId } from "../models/storage/jsonCustomerEditSiteItemCustomerId";
import { StorageLocationEditItem } from "../models/storage/storageLocation";
import { IdTitle } from "../models/common/idTitle";
import {  objectToUrlParams } from "./Helpers/serviceHelper";
import { StorageEditItem } from "../models/storage/storage";
import { ProductEditItem } from "../models/storage/storageProduct";
import { StorageTableData } from "../components/storage/storages/storageLocationList";
import { AutocompleteOption } from "../components/framework/muiAutocomplete";

interface StorageParameterObject {
    filter?: string;
    extraFilterObject?: Record<string, any>;
}

export const getStorageProductList = (params: StorageParameterObject): Promise<StorageProductListItem[]> => {
    return getApiCall<StorageProductListItem[]>("api/storage/products" + objectToUrlParams(params, true), null);
};

export const getSingleProduct = (productId: string): Promise<ProductEditItem> => {
    return getApiCall<ProductEditItem>(`api/storageproduct/${productId}`, null);
};

export const getStorageProduct = (id: string): Promise<StorageProductListItem> => {
    return getApiCall<StorageProductListItem>(`api/storage/product?id=${id}`, StorageProductListItem);
};

export const getStorage = (storageId: string): Promise<StorageEditItem> => {
    return getApiCall<StorageEditItem>(`api/storage/${storageId}`, null);
};

export const getStorageIdTitles = (): Promise<AutocompleteOption[]> => {
    return getApiCall<AutocompleteOption[]>("api/storage/idtitle", null);
};

export const removeStorageProduct = (id: string): Promise<SaveSuccess> => {
    return makeFormApiCall<SaveSuccess>(`api/storage/product/remove/${id}`, "DELETE", null, SaveSuccess);
};

//Storage location requests
export const getStorageLocationList = (params: any): Promise<StorageTableData[]> => {
    return getApiCall<StorageTableData[]>("api/storage/storagelocations" + objectToUrlParams(params, true), null);
};

export const getStorageLocationOptions = (): Promise<AutocompleteOption[]> => {
    return getApiCall<AutocompleteOption[]>("api/storage/storagelocation/options", null);
};

export const getStorageLocationCustomers = (): Promise<IdTitle[]> => {
    return getApiCall<IdTitle[]>("api/storage/storagelocations/customers", null);
};

export const getStorageLocation = (id: string): Promise<StorageLocationEditItem> => {
    return getApiCall<StorageLocationEditItem>(`api/storage/location/${id}`, null);
};

export const saveStorageLocation = (data: StorageLocationEditItem): Promise<StorageLocationEditItem> => {
    return makeApiCallWithAny<StorageLocationEditItem>("api/storage/location/save", "POST", data, StorageLocationEditItem);
};

export const removeStorageLocation = (id: string): Promise<SaveSuccess> => {
    return makeApiCallWithAny<SaveSuccess>(`api/storage/location/remove?id=${id}`,"DELETE", null, SaveSuccess);
};

export const createStorageSite = (data: StorageEditItem): Promise<SiteResponseMessage> => {
    return makeApiCallWithAny<SiteResponseMessage>("api/site/storage/create", "POST", data, SiteResponseMessage);
};

export const updateStorageSite = (data: StorageEditItem): Promise<SiteResponseMessage> => {
    return makeApiCallWithAny<SiteResponseMessage>("api/site/storage/update", "PUT", data, SiteResponseMessage);
};

export const getStorages = (params): Promise<IJsonCustomerEditSiteItemCustomerId[]> => {
    return getApiCall<IJsonCustomerEditSiteItemCustomerId[]>("api/storage/all" + objectToUrlParams(params, true), null);
};

export const deleteStorage = (id: string): Promise<boolean> => {
    return ApiCall<boolean>(`api/storage/remove?id=${id}`, "DELETE", null);
};

export const saveStorageProduct = (data: ProductEditItem): Promise<ProductEditItem> => {
    return makeApiCallWithAny<ProductEditItem>("api/storage/product/save", "POST", data, null);
};
