// DocumentList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import { Translations } from "../../models/translations";
import { ToolButton, ToolAddFileButton } from "../framework/toolButton";
import { Base, FileType } from "../../framework/base";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { IDocument } from "../../models/document/document";
import { PropertyDialog } from "../framework/dialog";
import { CheckBox } from "../framework/checkbox";
import { DocumentType } from "../../models/common/enums";

// DocumentDialog
// ***********************************************************************************************************************
export interface IDocumentDialogProp {
    classes?: string;
    isReadOnly: boolean;
    document: IDocument;
    onDownloadDocument: (id: string) => void;
    onOk: (comment: string, documentType: DocumentType, documentId?: string) => void;
    onCancel: () => void;
}

interface IDocumentDialogState {
    isImage: boolean;
    comment: string;
    attachmentToInvoice: boolean;
}

export class DocumentDialog extends React.Component<IDocumentDialogProp, IDocumentDialogState> {
    constructor(props: IDocumentDialogProp) {
        super(props);
        this.state = {
            isImage: props.document.fileType === FileType.Image && props.document.size > 0.001,
            comment: props.document.comment,
            attachmentToInvoice: props.document.documentType === DocumentType.AttachmentToInvoice,
        };
    }

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "comment") {
            this.setState({ comment: value });
        }
    };

    handleCheckBoxClick = () => {
        this.setState(prevState => ({
            attachmentToInvoice: !prevState.attachmentToInvoice
        }));
    };

    private static checkErrors = (state: IDocumentDialogState): Promise<boolean> => {
        return new Promise<boolean>((resolve) => {
            return resolve(true);
        });
    };

    private static getWarningMessage = (state: IDocumentDialogState): Promise<string> => {
        return new Promise<string>((resolve) => {
            return resolve("");
        });
    };

    private static validate = (state: IDocumentDialogState, saveCallback: () => void): Promise<void> => {
        return DocumentDialog.checkErrors(state).then(success => {
            if (success) {
                return DocumentDialog.getWarningMessage(state).then(warnings => {
                    if (!warnings) {
                        saveCallback();
                        return new Promise<void>((resolve) => { resolve(); });
                    }
                    return new Promise<void>((resolve) => {
                        store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, warnings + Base.lf + Translations.DoYouReallyWantToSaveData,
                            () => {
                                store.customStore.dispatch(storeActions.clearConfirmation());
                                saveCallback();
                                resolve();
                            },
                            () => {
                                store.customStore.dispatch(storeActions.clearConfirmation());
                                resolve();
                            }, null));
                    });
                });
            } else {
                return new Promise<void>((resolve) => { resolve(); });
            }
        });
    };

    handleOkClick = () => {
        const obj = this;
        DocumentDialog.validate(this.state, () => {
            obj.props.onOk(obj.state.comment, obj.state.attachmentToInvoice ? 1 : 0);
        });
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const document = props.document;
        const dialogClasses = "document px600" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.Attachment + " - " + document.title}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    {state.isImage &&
                                        <div>
                                            <div className="documentImages">
                                                <div className="documentImage" onClick={() => { props.onDownloadDocument(document.id); }}>
                                                    <img src={document.thumbnail} />
                                                </div>
                                            </div>
                                            <div className="name">{document.reference + " (" + Base.intToFileSizeStr(document.size, false) + ", " + Base.timeToDateStr(document.modifyDate) + ")"}</div>
                                        </div>
                                    }
                                    {!state.isImage &&
                                        <div className="documents">
                                            <div className="document" onClick={() => { props.onDownloadDocument(document.id); }}>
                                                <span className={"icon file file_type_" + document.fileType} />
                                                <span className="name">{document.reference + " (" + Base.intToFileSizeStr(document.size, false) + ", " + Base.timeToDateStr(document.modifyDate) + ")"}</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="control-label">{Translations.Comment}</label>
                                    <textarea className="form-control" name="comment" title={Translations.Comment} value={state.comment} readOnly={props.isReadOnly} disabled={props.isReadOnly} onChange={this.handleChange} maxLength={1000} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 form-group">
                                <CheckBox
                                    classes={"checkboxAttachmentToInvoice noMargin"}
                                    title={Translations.WorkOrderAttachmentToInvoice}
                                    enabled={!props.isReadOnly}
                                    checked={state.attachmentToInvoice}
                                    onCheckboxClick={!props.isReadOnly && this.handleCheckBoxClick}
                                />
                            </div>
                        </div>
                    </div>}
                    buttons={[{ title: Translations.Save, classes: "btn-primary" + (props.isReadOnly ? " d-none" : ""), enabled: !props.isReadOnly, onClick: this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}

// DocumentListImage
export interface IDocumentListImageProp {
    document: IDocument;
    selectedId: string;
    smallImage?: boolean;
    enabled: boolean;
    changeDocumentTypeEnabled: boolean;
    onClick: (id: string, isDoubleClick: boolean) => void;
    onToggleAttachmentToInvoice: (comment: string, documentType: DocumentType, documentId?: string) => void;
}

export interface IDocumentListImageState {

}

export class DocumentListImage extends React.Component<IDocumentListImageProp, IDocumentListImageState> {
    toggleAttachmentToInvoice = () => {
        if (!this.props.enabled) return;
        const document = this.props.document;
        this.props.onToggleAttachmentToInvoice(document.comment, document.documentType === 1 ? 0 : 1, document.id);
    };

    render() {
        const props = this.props;
        const document = props.document;
        return (
            <>
                <div className={"documentImageContainer" + (props.smallImage ? " smallImage" : "") + (document.id === props.selectedId ? " selected" : "")}>
                    <div className={"documentImage" + (props.smallImage ? " smallImage" : "")} onClick={(e) => { props.onClick(document.id, e.detail === 2); }} title={document.reference + " (" + Base.intToFileSizeStr(document.size, false) + ", " + Base.timeToDateStr(document.modifyDate) + ")"} >
                        <img src={document.thumbnail} />
                    </div>
                    {!!document.comment &&
                        <div className="comment" title={document.comment}>{document.comment}</div>
                    }
                    {props.changeDocumentTypeEnabled &&
                        <CheckBox
                            classes={"checkboxAttachmentToInvoice"}
                            title={Translations.WorkOrderAttachmentToInvoice}
                            enabled={this.props.enabled}
                            checked={document.documentType === DocumentType.AttachmentToInvoice}
                            onCheckboxClick={this.toggleAttachmentToInvoice}
                        />
                    }
                </div>
            </>
        );
    }
}

// DocumentListLine
export interface IDocumentListLineProp {
    document: IDocument;
    selectedId: string;
    enabled?: boolean;
    changeDocumentTypeEnabled: boolean;
    onClick: (id: string, isDoubleClick: boolean) => void;
    onDownload: (id: string) => void;
    onToggleAttachmentToInvoice: (comment: string, documentType: DocumentType, documentId?: string) => void;
}

export interface IDocumentListLineState {

}

export class DocumentListLine extends React.Component<IDocumentListLineProp, IDocumentListLineState> {
    toggleAttachmentToInvoice = () => {
        if (!this.props.enabled) return;
        const document = this.props.document;
        this.props.onToggleAttachmentToInvoice(document.comment, document.documentType === 1 ? 0 : 1, document.id);
    };

    render() {
        const props = this.props;
        const document = props.document;
        return (
            <div
                className={"line document" + (document.id === props.selectedId ? " selected" : "")}
                onClick={(e) => props.onClick(document.id, e.detail === 2)}
                title={document.reference + " (" + Base.intToFileSizeStr(document.size, false) + ", " + Base.timeToDateStr(document.modifyDate) + ")"}
            >
                <span className={"icon file file_type_" + document.fileType} title={Translations.Download} />
                <span className="name">{document.reference + " (" + Base.intToFileSizeStr(document.size, false) + ", " + Base.timeToDateStr(document.modifyDate) + ")"}</span>
                {!!document.comment &&
                    <div className="comment" title={document.comment}>{document.comment}</div>
                }
                {props.changeDocumentTypeEnabled &&
                    <CheckBox
                        classes={"checkboxAttachmentToInvoice"}
                        title={Translations.WorkOrderAttachmentToInvoice}
                        enabled={this.props.enabled}
                        checked={this.props.document.documentType === DocumentType.AttachmentToInvoice}
                        onCheckboxClick={this.toggleAttachmentToInvoice}
                    />
                }
            </div>
        );
    }
}

// DocumentList
export interface IDocumentListProp {
    title?: string;
    titleId?: string;
    titleClass?: string;
    classes?: string;
    selectedId?: string;
    relatedObjectId: string;
    documents: IDocument[];
    isReadOnly: boolean;
    canShowCamera: boolean;
    changeDocumentTypeEnabled: boolean;
    smallImage?: boolean;
    onEditDocument?: (id: string, comment: string, documentType: DocumentType) => void;
    onRemoveDocument?: (id: string) => void;
    onDownloadDocument?: (id: string) => void;
    onAddDocuments?: (fileList: FileList) => void;
    onAddPhoto?: () => void;
}

interface IDocumentListState {
    selectedId: string;
    selectedDocument: IDocument;
    showDocumentDialog: boolean;
}

export class DocumentList extends React.Component<IDocumentListProp, IDocumentListState> {
    constructor(props) {
        super(props);
        this.state = { selectedId: props.selectedId, selectedDocument: null, showDocumentDialog: false };
    }

    componentDidUpdate(prevProps: IDocumentListProp, prevState: IDocumentListState): void {
        const props = this.props;
        if (prevProps.selectedId === props.selectedId || !props.selectedId) return;
        this.setState({
            selectedId: props.selectedId
        });
    }

    handleAdd = (fileList: FileList) => {
        if (this.props.isReadOnly) return;
        this.props.onAddDocuments(fileList);
    };

    handleAddPhoto = () => {
        if (this.props.isReadOnly) return;
        this.props.onAddPhoto();
    };

    handleEdit = () => {
        const selectedDocument = this.state.selectedDocument;
        if (!selectedDocument) return;
        this.setState({
            showDocumentDialog: true
        });
    };

    handleDocumentSave = (comment: string, documentType: DocumentType, documentId?: string) => {
        const selectedId = documentId ?? this.state.selectedId;
        if (!selectedId) return;
        this.props.onEditDocument(selectedId, comment, documentType);
        this.setState({
            showDocumentDialog: false,
        });
    };

    handleDocumentDialogCancel = () => {
        this.setState({
            showDocumentDialog: false,
        });
    };

    handleDownload = () => {
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        this.props.onDownloadDocument(selectedId);
    };

    handleRemove = () => {
        if (this.props.isReadOnly) return;
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        this.props.onRemoveDocument(selectedId);
    };

    handleDocumentClick = (id: string, isDoubleClick: boolean) => {
        const document = this.props.documents.find(i => i.id === id);
        if (!document) return;
        if (isDoubleClick) {
            this.handleDocumentDownload(id);
        }
        this.setState({
            selectedId: id,
            selectedDocument: document
        });
    };

    handleDocumentDownload = (id: string) => {
        if (!id) return;
        this.props.onDownloadDocument(id);
    };

    render() {
        const props = this.props;
        const state = this.state;
        const documents = this.props.documents;
        const imageDocuments = documents.filter(i => i.fileType === FileType.Image && i.size > 0.001);
        const fileDocuments = documents.filter(i => i.fileType !== FileType.Image || i.size <= 0.001);
        return (
            <div className={props.classes ? props.classes : ""}>
                <div className="commandRow">
                    {!!props.title &&
                        <label id={props.titleId} className={"control-label listTitle" + (props.titleClass ? " " + props.titleClass : "")}>{props.title}</label>
                    }
                    {!props.isReadOnly &&
                        <ToolButton
                            title={Translations.Remove}
                            enabled={!!state.selectedId && state.selectedDocument && state.selectedDocument.relatedObjectId === props.relatedObjectId}
                            classes={"round right remove"}
                            onClick={this.handleRemove}
                        />
                    }
                    <ToolButton
                        title={Translations.Download}
                        enabled={!!state.selectedId}
                        classes={"round right download"}
                        onClick={this.handleDownload}
                    />
                    {!props.isReadOnly &&
                        <ToolButton
                            title={Translations.Edit}
                            enabled={!!state.selectedId}
                            classes={"round right edit"}
                            onClick={this.handleEdit}
                        />
                    }
                    {!props.isReadOnly && props.canShowCamera &&
                        <ToolButton
                            title={Translations.AddPhoto}
                            enabled={true}
                            classes={"round right camera"}
                            onClick={this.handleAddPhoto}
                        />
                    }
                    {!props.isReadOnly &&
                        <ToolAddFileButton
                            title={Translations.Add}
                            fileInputName="attachments"
                            enabled={true}
                            classes={"round right add"}
                            onChange={this.handleAdd}
                        />
                    }
                </div>
                <div className="listContainer documentsContainer">
                    {imageDocuments.length > 0 &&
                        <div className="list documentImages">
                            {imageDocuments.map((document) =>
                                <DocumentListImage
                                    key={document.id}
                                    document={document}
                                    selectedId={state.selectedId}
                                    smallImage={props.smallImage}
                                    enabled={!props.isReadOnly}
                                    changeDocumentTypeEnabled={props.changeDocumentTypeEnabled}
                                    onClick={this.handleDocumentClick}
                                    onToggleAttachmentToInvoice={this.handleDocumentSave}
                                />
                            )}
                        </div>
                    }
                    {fileDocuments.length > 0 &&
                        <div className="list documents">
                            {fileDocuments.map((document) =>
                                <DocumentListLine
                                    key={document.id}
                                    document={document}
                                    selectedId={state.selectedId}
                                    enabled={!props.isReadOnly}
                                    changeDocumentTypeEnabled={props.changeDocumentTypeEnabled}
                                    onClick={this.handleDocumentClick}
                                    onDownload={this.handleDocumentDownload}
                                    onToggleAttachmentToInvoice={this.handleDocumentSave}
                                />
                            )}
                        </div>
                    }
                </div>
                {state.showDocumentDialog &&
                    <DocumentDialog
                        isReadOnly={props.isReadOnly || state.selectedDocument.relatedObjectId !== props.relatedObjectId}
                        document={state.selectedDocument}
                        onDownloadDocument={props.onDownloadDocument}
                        onOk={this.handleDocumentSave}
                        onCancel={this.handleDocumentDialogCancel}
                    />
                }
            </div>
        );
    }
}
