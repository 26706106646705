import * as React from "react";
import { useMemo } from "react";
import { Translations } from "../../../models/translations";
import { StorageListItem } from "../../../models/storage/storageListiItem";
import { Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { TableDef, TableWrapper } from "../../framework/tableWrapper";

interface StorageTableData {
    id: string;
    name: string;
    street: string;
    postalCode: string;
    city: string;
    countryCode: string;
}

interface Props {
    onShowDetails: (id: string) => void;
    storages: StorageListItem[];
}

export const StorageList = ({ onShowDetails, storages }: Props) => {
    const tableDef: TableDef<StorageTableData> = useMemo(() => ({
        columns: [
            {
                label: Translations.Name,
                accessor: "name",
            },
            {
                label: Translations.Address,
                accessor: "street",
            },
            {
                label: Translations.PostalCode,
                accessor: "postalCode",
            },
            {
                label: Translations.Location,
                accessor: "city",
            },
            {
                label: Translations.CountryCode,
                accessor: "countryCode",
            },
            {
                accessor: "id",
                renderCell: (id: string) => (
                    <>
                        <Button
                            variant="primary" className="rounded" startIcon={<VisibilityIcon/>}
                            onClick={() => onShowDetails(id)}
                        >
                            {Translations.ShowDetails}
                        </Button>
                    </>
                ),
            },
        ],
    }), []);



    return (
        <>
            {storages.length > 0 &&
                <TableWrapper tableDef = {tableDef} data = {storages} />
            }
        </>
    );
};

