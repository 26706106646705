export interface IWorkTimeAccountTransaction {
    id: string;
    salaryId: string;
    date: number;
    amount: number;
    description: string;
    modifyDate: number;
    modifyUserName: string;
}

export class WorkTimeAccountTransaction implements IWorkTimeAccountTransaction {
    id: string;
    salaryId: string;
    date: number;
    amount: number;
    description: string;
    modifyDate: number;
    modifyUserName: string;

    constructor();
    constructor(obj: IWorkTimeAccountTransaction);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.salaryId = obj && obj.salaryId || "";
        this.date = obj && obj.date || 0;
        this.amount = obj && obj.amount || 0;
        this.description = obj && obj.description || "";
        this.modifyDate = obj && obj.modifyDate || 0;
        this.modifyUserName = obj && obj.modifyUserName || "";
    }
}