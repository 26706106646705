// OwnerSettingsSalaryPeriodTypeDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as salaryPeriodTypeService from "../../services/salaryPeriodTypeService";
import { PropertyDialog } from "../framework/dialog";
import { SaveData } from "../../framework/saveData";
import { ConfirmationDialogResult } from "../../models/common/enums";
import { ISalaryPeriodTypeEdit } from "../../models/salaryPeriodType/salaryPeriodTypeEdit";
import { AppUtils } from "../../models/common/appUtils";
import { OwnerSettingsSalaryPeriodTypeDialogSalaryPeriodList } from "./ownerSettingsSalaryPeriodTypeDialogSalaryPeriodList";
import { ISalaryWorkTimePeriodItem, SalaryWorkTimePeriodItem } from "../../models/salaryPeriod/salaryWorkTimePeriodItem";
import { CheckBox } from "../framework/checkbox";

// OwnerSettingsSalaryPeriodTypeDialog
// ***********************************************************************************************************************
export interface IOwnerSettingsSalaryPeriodTypeDialogProp {
    classes?: string;
    edit: ISalaryPeriodTypeEdit;
    onOk: () => void;
    onCancel: () => void;
}

export interface IOwnerSettingsSalaryPeriodTypeDialogState {
    name: string;
    activeState: number;
    salaryPeriods: ISalaryWorkTimePeriodItem[];
}

export class OwnerSettingsSalaryPeriodTypeDialog extends React.Component<IOwnerSettingsSalaryPeriodTypeDialogProp, IOwnerSettingsSalaryPeriodTypeDialogState> {
    private orgStateHash: string = "";

    constructor(props: IOwnerSettingsSalaryPeriodTypeDialogProp) {
        super(props);
        const salaryPeriodType = props.edit.salaryPeriodType;
        this.state = {
            name: salaryPeriodType.name,
            activeState: salaryPeriodType.activeState,
            salaryPeriods: salaryPeriodType.salaryPeriods.slice(0)
        };
        const saveData = OwnerSettingsSalaryPeriodTypeDialog.getSaveDataFromState(props, this.state);
        this.orgStateHash = saveData.hash;
    }

    // #region General
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "name") {
            this.setState({ name: value });
        }
    };

    handleActiveStateChange = (newActiveState: number) => {
        this.setState({ activeState: newActiveState });
    };
    // #endregion General

    private checkErrors = (): Promise<boolean> => {
        const state = this.state;
        return new Promise<boolean>((resolve) => {
            //Add error checking in here
            if (!state.name) {
                AppUtils.showErrorMessage(Translations.NameMustBeDefined);
                return resolve(false);
            }
            return resolve(true);
        });
    };

    private getWarningMessage = (): Promise<string> => {
        return new Promise<string>((resolve) => {
            //let warnings = "";
            //Add warning checking in here
            //if (!state.someField) {
            //    warnings = warnings + Translations.SomeFieldIsNotDefined + Base.lf;
            //}
            //return resolve(warnings);
            return resolve("");
        });
    };

    private static getSaveDataFromState = (props: IOwnerSettingsSalaryPeriodTypeDialogProp, state: IOwnerSettingsSalaryPeriodTypeDialogState): SaveData => {
        const data = new SaveData();
        const salaryPeriodType = props.edit.salaryPeriodType;
        // Common - Data that does not change
        data.append("id", salaryPeriodType.id);
        data.append("rowId", salaryPeriodType.rowId);
        // General - Data that changes
        data.append("name", state.name);
        data.append("activeState", state.activeState.toString(10));
        return data;
    };

    // #region SalaryPeriods
    handleSalaryPeriodModified = (modifiedSalaryPeriods: ISalaryWorkTimePeriodItem[]) => {
        if (!modifiedSalaryPeriods || modifiedSalaryPeriods.length < 1) return;
        const modifiedSalaryPeriodIds = modifiedSalaryPeriods.map(i => i.id);
        const salaryPeriods = this.state.salaryPeriods.filter(i => modifiedSalaryPeriodIds.indexOf(i.id) < 0).concat(modifiedSalaryPeriods);
        SalaryWorkTimePeriodItem.sortSalaryWorkTimePeriodItems(salaryPeriods);
        this.setState({ salaryPeriods: salaryPeriods });
    };

    handleSalaryPeriodRemoved = (salaryPeriodId: string) => {
        if (!salaryPeriodId) return;
        this.setState({ salaryPeriods: this.state.salaryPeriods.filter(i => i.id !== salaryPeriodId) });
    };
    // #endregion SalaryPeriods

    saveEditItem = async() => {
        const props = this.props;
        const state = this.state;
        if (!await AppUtils.validate(this.checkErrors, this.getWarningMessage)) return;
        const saveData = OwnerSettingsSalaryPeriodTypeDialog.getSaveDataFromState(props, state);
        //Call server
        const result = await AppUtils.callService(() => salaryPeriodTypeService.saveSalaryPeriodTypeEdit(saveData.formData));
        if (!result) return;
        props.onOk();
    };

    handleOkClick = () => {
        this.saveEditItem();
    };

    handleCancelClick = async() => {
        const props = this.props;
        const state = this.state;
        const cancelResult = await AppUtils.cancel(OwnerSettingsSalaryPeriodTypeDialog.getSaveDataFromState(props, state).hash, this.orgStateHash);
        if (cancelResult === ConfirmationDialogResult.Cancel) return;
        if (cancelResult === ConfirmationDialogResult.Yes) {
            this.saveEditItem();
        } else {
            props.onCancel();
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        const edit = props.edit;
        const salaryPeriodType = edit.salaryPeriodType;
        const dialogClasses = "settings salaryPeriodType px800" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.SalaryPeriodType + " - " + (!salaryPeriodType.isNew() ? salaryPeriodType.name : Translations.New)}
                    show={true}
                    body={
                        <div className="salaryPeriodTypeTypeContainer">
                            <div className="row">
                                <div className="col-9">
                                    <div className="form-group required">
                                        <label className="control-label smallFont">{Translations.Name}</label>
                                        <input type="text" className="form-control" name="name" title={Translations.Name} value={state.name} onChange={this.handleChange} maxLength={50}/>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="form-group">
                                        <label className="control-label smallFont">&nbsp;</label>
                                        <div>
                                            <CheckBox
                                                title={Translations.InUse}
                                                enabled={true}
                                                checked={state.activeState > 0}
                                                onCheckboxClickBoolean={(checked: boolean) => { this.handleActiveStateChange(checked ? 1 : 0); }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <OwnerSettingsSalaryPeriodTypeDialogSalaryPeriodList
                                isReadOnly={salaryPeriodType.isNew()}
                                salaryPeriodTypeId={salaryPeriodType.id}
                                salaryPeriods={state.salaryPeriods}
                                onModified={this.handleSalaryPeriodModified}
                                onRemoved={this.handleSalaryPeriodRemoved}
                            />
                        </div>
                    }
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: this.handleOkClick }
                    ]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}
