import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { IApplicationState } from "../../models/store/storeTypes";
import * as storeEffects from "../../models/store/storeEffects";
import { Base } from "../../framework/base";
import { IAcknowledgementMainOwnProps, IAcknowledgementMainStateProps, IAcknowledgementMainDispatchProps, AcknowledgementMain } from "../../components/acknowledgement/acknowledgementMain";

export function mapStateToProps(applicationState: IApplicationState): IAcknowledgementMainStateProps {
    if (Base.isNullOrUndefined(applicationState) || Base.isNullOrUndefined(applicationState.acknowledgementMain) || Base.isNullOrUndefined(applicationState.acknowledgementMain.acknowledgement)) {
        return {
            ownerLogo: "",
            canSendMessage: false,
            isLoading: false,
            acknowledgement: null
        };
    }
    const acknowledgementMain = applicationState.acknowledgementMain;
    return {
        ownerLogo: acknowledgementMain.ownerLogo,
        canSendMessage: acknowledgementMain.canSendMessage,
        isLoading: acknowledgementMain.isLoading,
        acknowledgement: acknowledgementMain.acknowledgement
    };
}

export function mapDispatchToProps(dispatch: storeEffects.AcknowledgementThunkDispatch): IAcknowledgementMainDispatchProps {
    return {
        loadAcknowledgement: (id: string) => dispatch(storeEffects.getAcknowledgementMain(id))
    };
}

export default withRouter(connect<IAcknowledgementMainStateProps, IAcknowledgementMainDispatchProps, IAcknowledgementMainOwnProps>(mapStateToProps, mapDispatchToProps)(AcknowledgementMain));
