// SettingsEmployeeDialogDayBookingList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import Datetime from "react-datetime";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import * as employeeService from "../../services/employeeService";
import { Translations } from "../../models/translations";
import { PropertyDialog } from "../framework/dialog";
import { ToolButton } from "../framework/toolButton";
import { Base } from "../../framework/base";
import { IIdTitle } from "../../models/common/idTitle";
import { CheckBox } from "../framework/checkbox";
import { DayBookingUsageType, EnumHelper } from "../../models/common/enums";
import { SaveData } from "../../framework/saveData";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { DayBookingEditItem, DayBookingEditSaveItem, IDayBookingEditItem } from "../../models/dayBooking/dayBookingEditItem";
import { DayBookingEdit, IDayBookingEdit } from "../../models/dayBooking/dayBookingEdit";

// SettingsEmployeeDialogDayBookingDialog
// ***********************************************************************************************************************
export interface ISettingsEmployeeDialogDayBookingDialogProp {
    classes?: string;
    isReadOnly: boolean;
    dayBookingEdit: IDayBookingEdit;
    onOk: (id: string, dayBookingTypeId: string, date: number, duration: number, dayUsageType: number, comment: string, rowId: string) => void;
    onCancel: () => void;
}

export interface ISettingsEmployeeDialogDayBookingDialogState {
    dayBookingTypeId: string;
    dateStr: string;
    durationStr: string;
    dayUsageType: number;
    comment: string;
}

export class SettingsEmployeeDialogDayBookingDialog extends React.Component<ISettingsEmployeeDialogDayBookingDialogProp, ISettingsEmployeeDialogDayBookingDialogState> {
    private static orgStateHash: string = "";

    constructor(props: ISettingsEmployeeDialogDayBookingDialogProp) {
        super(props);
        const dayBooking = props.dayBookingEdit.dayBooking;
        this.state = {
            dayBookingTypeId: dayBooking.dayBookingTypeId,
            dateStr: Base.utcTimeToDateStr(dayBooking.date),
            durationStr: dayBooking.duration.toString(10),
            dayUsageType: dayBooking.dayUsageType,
            comment: dayBooking.comment
        };
        const saveData = SettingsEmployeeDialogDayBookingDialog.getSaveDataFromState(props, this.state);
        SettingsEmployeeDialogDayBookingDialog.orgStateHash = saveData.hash;
    }

    // #region General
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "dayBookingTypeId") {
            this.setState({ dayBookingTypeId: value });
        } else if (name === "duration") {
            this.setState({ durationStr: value });
        } else if (name === "comment") {
            this.setState({ comment: value });
        }
    };

    handleBlur = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "duration") {
            let duration = value.toInteger();
            if (EnumHelper.isEqual(this.state.dayUsageType, DayBookingUsageType.NotFullDay)) {
                duration = Math.min(duration, 23);
            }
            this.setState({ durationStr: duration.toString(10) });
        }
    };

    handleDayUsageTypeChange = (newDayUsageType: number) => {
        this.setState({ dayUsageType: newDayUsageType });
    };

    handleDateChange = (value: moment.Moment | string) => {
        if (!(typeof value === "string")) {
            this.setState({ dateStr: Base.utcTimeToDateStr(value) });
        }
    };

    handleDateBlur = (value: moment.Moment | string) => {
        if (typeof value === "string") {
            this.setState({ dateStr: Base.utcTimeToDateStr(value.toUtcDate().getTime()) });
        } else {
            this.setState({ dateStr: Base.utcTimeToDateStr(value) });
        }
    };

    private static checkErrors = (props: ISettingsEmployeeDialogDayBookingDialogProp, state: ISettingsEmployeeDialogDayBookingDialogState): Promise<boolean> => {
        return new Promise<boolean>((resolve) => {
            if (!state.dateStr) {
                store.customStore.dispatch(storeActions.showErrorMessage(Translations.DateMustBeDefined));
                return resolve(false);
            }
            const duration = state.durationStr.toInteger();
            if (!duration) {
                store.customStore.dispatch(storeActions.showErrorMessage(Translations.DurationMustBeDefined));
                return resolve(false);
            }
            return resolve(true);
        });
    };

    private static getWarningMessage = (props: ISettingsEmployeeDialogDayBookingDialogProp, state: ISettingsEmployeeDialogDayBookingDialogState): Promise<string> => {
        return new Promise<string>((resolve) => {
            return resolve("");
        });
    };

    private static validate = (props: ISettingsEmployeeDialogDayBookingDialogProp, state: ISettingsEmployeeDialogDayBookingDialogState, saveCallback: () => void): Promise<void> => {
        return SettingsEmployeeDialogDayBookingDialog.checkErrors(props, state).then(success => {
            if (success) {
                return SettingsEmployeeDialogDayBookingDialog.getWarningMessage(props, state).then(warnings => {
                    if (!warnings) {
                        saveCallback();
                        return new Promise<void>((resolve) => { resolve(); });
                    }
                    return new Promise<void>((resolve) => {
                        store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, warnings + Base.lf + Translations.DoYouReallyWantToSaveData,
                            () => {
                                store.customStore.dispatch(storeActions.clearConfirmation());
                                saveCallback();
                                resolve();
                            },
                            () => {
                                store.customStore.dispatch(storeActions.clearConfirmation());
                                resolve();
                            }, null));
                    });
                });
            } else {
                return new Promise<void>((resolve) => { resolve(); });
            }
        });
    };

    private static getSaveDataFromState = (props: ISettingsEmployeeDialogDayBookingDialogProp, state: ISettingsEmployeeDialogDayBookingDialogState): SaveData => {
        const data = new SaveData();
        const dayBooking = props.dayBookingEdit.dayBooking;
        // Common
        data.append("employeeId", dayBooking.employeeId);
        const dayBookingEditSaveItem = new DayBookingEditSaveItem();
        dayBookingEditSaveItem.id = dayBooking.id;
        dayBookingEditSaveItem.rowId = dayBooking.rowId;
        dayBookingEditSaveItem.dayBookingTypeId = state.dayBookingTypeId;
        dayBookingEditSaveItem.date = state.dateStr.toUtcDate().getTime();
        dayBookingEditSaveItem.dayUsageType = state.dayUsageType;
        dayBookingEditSaveItem.duration = state.durationStr.toInteger();
        dayBookingEditSaveItem.comment = state.comment;
        data.append("dayBookings", JSON.stringify([dayBookingEditSaveItem]));
        return data;
    };

    saveEditItem = () => {
        const obj = this;
        const state = this.state;
        SettingsEmployeeDialogDayBookingDialog.validate(this.props, this.state, () => {
            const saveData = SettingsEmployeeDialogDayBookingDialog.getSaveDataFromState(this.props, this.state);
            if (Base.isNullOrUndefined(saveData)) return;
            // Call server
            store.customStore.dispatch(storeActions.fetchStart());
            employeeService.saveEmployeeDayBookings(saveData.formData)
                .then(success => {
                    store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                    obj.props.onOk(success.ids[0], state.dayBookingTypeId, state.dateStr.toUtcDate().getTime(), state.durationStr.toDecimal(), state.dayUsageType, state.comment, success.rowIds[0]);
                })
                .catch(error => {
                    store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                    return null;
                })
                .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
        });
    };

    handleOkClick = () => {
        this.saveEditItem();
    };

    handleCancelClick = () => {
        const obj = this;
        const saveData = SettingsEmployeeDialogDayBookingDialog.getSaveDataFromState(this.props, this.state);
        if (!Base.isNullOrUndefined(saveData) && saveData.hash !== SettingsEmployeeDialogDayBookingDialog.orgStateHash) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.saveEditItem();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.props.onCancel();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));
        } else {
            obj.props.onCancel();
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "employeeDayBooking px600" + (props.classes ? " " + props.classes : "");
        const dayBookingTypeName = props.dayBookingEdit.dayBookingTypes.find(i => i.id === props.dayBookingEdit.dayBooking.dayBookingTypeId).title;
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.DayBooking + " - " + (!props.dayBookingEdit.dayBooking.isNew() ? (Base.utcTimeToDateStr(props.dayBookingEdit.dayBooking.date) + ", " + dayBookingTypeName) : Translations.New)}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-3">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.Date}</label>
                                    <Datetime
                                        locale={appConfig.culture}
                                        className={"roWhite"}
                                        value={state.dateStr}
                                        dateFormat={"D.M.YYYY"}
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        inputProps={{ maxLength: 10, readOnly: props.isReadOnly, disabled: props.isReadOnly }}
                                        onChange={this.handleDateChange}
                                        onClose={this.handleDateBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.DayBookingType}</label>
                                    <select className="form-control" name="dayBookingTypeId" title={Translations.DayBookingType} value={state.dayBookingTypeId} onChange={this.handleChange} disabled={props.isReadOnly}>
                                        {props.dayBookingEdit.dayBookingTypes.map((dayBookingType) =>
                                            <option key={dayBookingType.id} value={dayBookingType.id}>{dayBookingType.title}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.Duration + " (" + (EnumHelper.isEqual(state.dayUsageType, DayBookingUsageType.FullDay) ? Translations.MeasureUnitD : Translations.MeasureUnitH) + ")"}</label>
                                    <input type="text" className="form-control" name="duration" title={Translations.Duration} value={state.durationStr} onChange={this.handleChange} onBlur={this.handleBlur} maxLength={3} readOnly={props.isReadOnly} disabled={props.isReadOnly} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <CheckBox
                                        title={Translations.NotFullDay}
                                        enabled={!props.isReadOnly}
                                        checked={state.dayUsageType > 0}
                                        onCheckboxClickBoolean={(checked: boolean) => { this.handleDayUsageTypeChange(checked ? 1 : 0); }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.Comment}</label>
                                    <textarea className="form-control" name="comment" title={Translations.Comment} value={state.comment} onChange={this.handleChange} maxLength={1000} readOnly={props.isReadOnly} disabled={props.isReadOnly} />
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: !props.isReadOnly ? this.handleOkClick : null }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}

// SettingsEmployeeDialogDayBookingListLine
export interface ISettingsEmployeeDialogDayBookingListLineProp {
    item: IDayBookingEditItem;
    selectedId: string;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

export class SettingsEmployeeDialogDayBookingListLine extends React.Component<ISettingsEmployeeDialogDayBookingListLineProp, {}> {
    render() {
        const item = this.props.item;
        return (
            <div className={"row line" + (item.id === this.props.selectedId ? " selected" : "")} onClick={() => { this.props.onClick(item.id); }} onDoubleClick={() => { this.props.onDoubleClick(item.id); }}
                title={Base.utcTimeToDateStr(item.date) + ", " + item.dayBookingTypeName + ", " + item.duration.toString(10) + " " + Translations.MeasureUnitD + ", " + item.comment}
            >
                <div className="col-2">{Base.utcTimeToDateStr(item.date)}</div>
                <div className="col-3">{item.dayBookingTypeName}</div>
                <div className="col-2 ra">{item.duration.toString(10) + " " + (EnumHelper.isEqual(item.dayUsageType, DayBookingUsageType.FullDay) ? Translations.MeasureUnitD : Translations.MeasureUnitH)}</div>
                <div className="col-5">{item.comment}</div>
            </div>
        );
    }
}

// SettingsEmployeeDialogDayBookingList
export interface ISettingsEmployeeDialogDayBookingListProp {
    title?: string;
    isReadOnly: boolean;
    employeeId: string;
    dayBookingTypes: IIdTitle[];
    items: IDayBookingEditItem[];
    onDayBookingRemoved: (id: string) => void;
    onAddDayBooking: (id: string, dayBookingTypeId: string, date: number, duration: number, dayUsageType: number, comment: string, rowId: string) => void;
    onEditDayBooking: (id: string, dayBookingTypeId: string, date: number, duration: number, dayUsageType: number, comment: string, rowId: string) => void;
}

interface ISettingsEmployeeDialogDayBookingListState {
    selectedId: string;
    dayBookingEdit: IDayBookingEdit;
    showDayBookingDialog: boolean;
    showDayBookingDialogForNew: boolean;
}

export class SettingsEmployeeDialogDayBookingList extends React.Component<ISettingsEmployeeDialogDayBookingListProp, ISettingsEmployeeDialogDayBookingListState> {
    constructor(props) {
        super(props);
        this.state = {
            selectedId: null,
            dayBookingEdit: null,
            showDayBookingDialog: false,
            showDayBookingDialogForNew: false
        };
    }

    handleAdd = () => {
        const props = this.props;
        const dayBookingEdit = new DayBookingEdit();
        dayBookingEdit.dayBooking = new DayBookingEditItem();
        dayBookingEdit.dayBooking.id = Base.emptyGuid;
        dayBookingEdit.dayBooking.employeeId = props.employeeId;
        dayBookingEdit.dayBooking.dayBookingTypeId = props.dayBookingTypes[0].id;
        dayBookingEdit.dayBooking.date = Base.getNowUtcDate().getTime();
        dayBookingEdit.dayBooking.duration = 1;
        dayBookingEdit.dayBookingTypes = props.dayBookingTypes;
        this.setState({
            dayBookingEdit: dayBookingEdit,
            showDayBookingDialog: true,
            showDayBookingDialogForNew: true
        });
    };

    handleEdit = () => {
        const props = this.props;
        const selectedId = this.state.selectedId;
        if (!selectedId || !props.onEditDayBooking) return;
        const selectedDayBooking = props.items.find(i => i.id === selectedId);
        if (!selectedDayBooking) return;
        const dayBookingEdit = new DayBookingEdit();
        dayBookingEdit.dayBooking = selectedDayBooking;
        dayBookingEdit.dayBookingTypes = props.dayBookingTypes;
        this.setState({
            dayBookingEdit: dayBookingEdit,
            showDayBookingDialog: true,
            showDayBookingDialogForNew: false
        });
    };

    handleBookingDialogOk = (id: string, dayBookingTypeId: string, date: number, duration: number, dayUsageType: number, comment: string, rowId: string) => {
        const showDayBookingDialogForNew = this.state.showDayBookingDialogForNew;
        this.setState({
            selectedId: id,
            showDayBookingDialog: false,
            showDayBookingDialogForNew: false
        });
        if (showDayBookingDialogForNew) {
            this.props.onAddDayBooking(id, dayBookingTypeId, date, duration, dayUsageType, comment, rowId);
        } else {
            this.props.onEditDayBooking(id, dayBookingTypeId, date, duration, dayUsageType, comment, rowId);
        }
    };

    handleBookingDialogCancel = () => {
        this.setState({
            showDayBookingDialog: false
        });
    };

    static removeDayBooking = (employeeId: string, id: string): Promise<boolean> => {
        if (!employeeId || !id) return Base.getPromiseResult(false);
        //Create savedata
        const dayBookingIds = [id];
        const saveData = new SaveData();
        saveData.append("employeeId", employeeId);
        saveData.append("dayBookingIds", JSON.stringify(dayBookingIds));
        //Save to db
        store.customStore.dispatch(storeActions.fetchStart());
        return employeeService.removeEmployeeDayBookings(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                return true;
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return false;
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleRemove = () => {
        const props = this.props;
        const selectedId = this.state.selectedId;
        SettingsEmployeeDialogDayBookingList.removeDayBooking(props.employeeId, selectedId)
            .then(success => {
                if (success) {
                    props.onDayBookingRemoved(selectedId);
                }
            });
    };

    handleLineClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleLineDoubleClick = (id: string) => {
        this.handleEdit();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const items = props.items;
        return (
            <div>
                <div className="commandRow">
                    {!!props.title &&
                        <label className="control-label listTitle">{props.title}</label>
                    }
                    {!!props.onDayBookingRemoved &&
                        <ToolButton
                            title={Translations.Remove}
                            enabled={!props.isReadOnly && !!state.selectedId}
                            classes={"round right remove"}
                            onClick={this.handleRemove}
                        />
                    }
                    {!!props.onEditDayBooking &&
                        <ToolButton
                            title={Translations.Edit}
                            enabled={!props.isReadOnly && !!state.selectedId}
                            classes={"round right edit"}
                            onClick={this.handleEdit}
                        />
                    }
                    {!!props.onAddDayBooking &&
                        <ToolButton
                            title={Translations.Add}
                            enabled={!props.isReadOnly}
                            classes={"round right add"}
                            onClick={this.handleAdd}
                        />
                    }
                </div>
                <div className="listContainer dayBookingsContainer">
                    {items.length > 0 &&
                        <div className="list dayBookings">
                            {items.map((item) =>
                                <SettingsEmployeeDialogDayBookingListLine
                                    key={item.id}
                                    item={item}
                                    selectedId={this.state.selectedId}
                                    onClick={this.handleLineClick}
                                    onDoubleClick={this.handleLineDoubleClick}
                                />
                            )}
                        </div>
                    }
                </div>
                {state.showDayBookingDialog &&
                    <SettingsEmployeeDialogDayBookingDialog
                        isReadOnly={props.isReadOnly}
                        dayBookingEdit={state.dayBookingEdit}
                        onOk={this.handleBookingDialogOk}
                        onCancel={this.handleBookingDialogCancel}
                    />
                }
            </div>
        );
    }
}
