import { Base } from "../../framework/base";
import { IWorkOrderAcknowledgementTypeItem, WorkOrderAcknowledgementTypeItem } from "./workOrderAcknowledgementTypeItem";

export interface IWorkOrderAcknowledgementTypeItems {
    items: IWorkOrderAcknowledgementTypeItem[];
    hasMore: boolean;
    page: number;
}

export class WorkOrderAcknowledgementTypeItems implements IWorkOrderAcknowledgementTypeItems {
    items: WorkOrderAcknowledgementTypeItem[];
    hasMore: boolean;
    page: number;

    constructor();
    constructor(obj: IWorkOrderAcknowledgementTypeItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = Base.getTypedArray<WorkOrderAcknowledgementTypeItem>(obj ? obj.items : null, WorkOrderAcknowledgementTypeItem);
    }
}