// SalaryTransferMain - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { ISalaryWorkTimePeriod } from "../../models/salary/salaryWorkTimePeriod";
import { SalaryTransferList } from "./salaryTransferList";
import { IEmployeeGroupItem } from "../../models/employeeGroup/employeeGroupItem";
import { IEmployeeItem } from "../../models/employee/employeeItem";
import * as storeActions from "../../models/store/storeActions";
import * as store from "../../framework/customStore";
import { Translations } from "../../models/translations";

interface ISalaryTransferMainProps {
    employeeGroup: IEmployeeGroupItem;
    employees: IEmployeeItem[];
    salaryPeriod: ISalaryWorkTimePeriod;
    onChangeSalaryPeriod: (salaryPeriodId: string) => void;
}

export class SalaryTransferMain extends React.Component<ISalaryTransferMainProps, {}> {
    componentDidUpdate() {
        if (!this.props.salaryPeriod) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.SalaryPeriodMissing));
        }
    }

    render() {
        const props = this.props;

        if (!props.salaryPeriod) {
            return;
        }
        return (
            <div>
                <div className="row salaryTransferMain">
                    <div className="col-12">
                        <div className="backgroundBoard">
                            <div className="backgroundContent">
                                <SalaryTransferList
                                    employeeGroup={props.employeeGroup}
                                    employees={props.employees}
                                    salaryPeriod={props.salaryPeriod}
                                    onChangeSalaryPeriod={props.onChangeSalaryPeriod}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
