// OwnerSettingsWorkOrderAcknowledgementTypeList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as workOrderAcknowledgementTypeService from "../../services/workOrderAcknowledgementTypeService";
import { ToolButton } from "../framework/toolButton";
import { Base } from "../../framework/base";
import { ListSearchFilter } from "../framework/listSearchFilter";
import { ListHeaderColumn } from "../framework/listHeaderColumn";
import { ToolTitle } from "../framework/toolTitle";
import * as StoreActions from "../../models/store/storeActions";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { infiniteListPageSize } from "../../models/common/consts";
import { handleApiError } from "../../models/store/storeEffects";
import { WorkOrderAcknowledgementTypeItem, IWorkOrderAcknowledgementTypeItem } from "../../models/workOrderAcknowledgementType/workOrderAcknowledgementTypeItem";

// OwnerSettingsWorkOrderAcknowledgementTypeListLineHeader
export interface IOwnerSettingsWorkOrderAcknowledgementTypeListLineHeaderProp {
    sortColumn: string;
    sortOrderIsAsc: boolean;
    onColumnClick: (column: string) => void;
}

export class OwnerSettingsWorkOrderAcknowledgementTypeListLineHeader extends React.Component<IOwnerSettingsWorkOrderAcknowledgementTypeListLineHeaderProp, {}> {
    render() {
        const props = this.props;
        return (
            <div className="row title">
                <ListHeaderColumn
                    title="#"
                    column="number"
                    classes="col-1"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={props.onColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Name}
                    column="name"
                    classes="col-7"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={props.onColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.SendingType}
                    column="sendingType"
                    classes="col-4"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={props.onColumnClick}
                />
            </div>
        );
    }
}

// OwnerSettingsWorkOrderAcknowledgementTypeListLine
export interface IOwnerSettingsWorkOrderAcknowledgementTypeListLineProp {
    item: IWorkOrderAcknowledgementTypeItem;
    selectedId: string;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

export class OwnerSettingsWorkOrderAcknowledgementTypeListLine extends React.Component<IOwnerSettingsWorkOrderAcknowledgementTypeListLineProp, {}> {
    handleOpenClick = (e) => {
        this.props.onDoubleClick(this.props.item.id);
    };

    render() {
        const props = this.props;
        const item = props.item;
        return (
            <div className={"row line" + (item.id === props.selectedId ? " selected" : "")} onClick={() => { props.onClick(item.id); }} onDoubleClick={() => { props.onDoubleClick(item.id); }} >
                <div className="col-1 ca openOnClick" onClick={this.handleOpenClick}>{item.number.toString(10)}</div>
                <div className="col-7">{item.name}</div>
                <div className="col-4">{item.sendingTypeStr}</div>
            </div>
        );
    }
}

// OwnerSettingsWorkOrderAcknowledgementTypeList
export interface IOwnerSettingsWorkOrderAcknowledgementTypeListProp {
}

interface IOwnerSettingsWorkOrderAcknowledgementTypeListState {
    isLoading: boolean;

    // List
    pageSize: number;
    page: number;
    hasMore: boolean;
    filter: string;
    sortColumn: string;
    sortOrderIsAsc: boolean;
    items: IWorkOrderAcknowledgementTypeItem[];
    selectedId: string;
    checkedIds: string[];

    // Edit
    //editItem: IToolEdit;
    showEditDialog: boolean;
}

export class OwnerSettingsWorkOrderAcknowledgementTypeList extends React.Component<IOwnerSettingsWorkOrderAcknowledgementTypeListProp, IOwnerSettingsWorkOrderAcknowledgementTypeListState> {
    private containerDiv: HTMLDivElement;
    private listDiv: HTMLDivElement;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false, pageSize: infiniteListPageSize, page: 1, hasMore: false, filter: "", sortColumn: "number", sortOrderIsAsc: true, items: [], selectedId: null, checkedIds: [], showEditDialog: false //editItem: null,
        };
    }

    searchItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean, resetItems: boolean, refreshList: boolean, successCallback: () => void = null) => {
        const obj = this;
        const state = this.state;
        this.setState({
            isLoading: true
        });
        store.customStore.dispatch(StoreActions.fetchStart());
        workOrderAcknowledgementTypeService.getWorkOrderAcknowledgementTypeItems(!refreshList ? pageSize : (page * infiniteListPageSize), !refreshList ? page : 1, filter, sortColumn, sortOrderIsAsc).then(dbItems => {
            const listItems = Base.getListItems<WorkOrderAcknowledgementTypeItem>(state.items, state.selectedId, state.checkedIds, dbItems.items, resetItems, refreshList);
            obj.setState({
                page: !refreshList ? dbItems.page : page,
                hasMore: dbItems.hasMore,
                filter: filter,
                sortColumn: sortColumn,
                sortOrderIsAsc: sortOrderIsAsc,
                items: listItems.items,
                selectedId: listItems.selectedId,
                checkedIds: listItems.checkedIds,
            });
            if (!Base.isNullOrUndefined(successCallback)) {
                successCallback();
            }
        },
        error => {
            handleApiError(error, store.customStore.dispatch);
        }).finally(() => {
            obj.setState({
                isLoading: false
            });
            store.customStore.dispatch(StoreActions.fetchEnd());
        });
    };

    refreshList = () => {
        this.searchItems(this.state.pageSize, this.state.page, this.state.filter, this.state.sortColumn, this.state.sortOrderIsAsc, false, true);
    };

    handleScrollSub = Base.debounce((obj: OwnerSettingsWorkOrderAcknowledgementTypeList) => {
        if (obj.state.isLoading || !obj.state.hasMore) return;
        if (obj.listDiv.offsetHeight - (obj.containerDiv.clientHeight + obj.containerDiv.scrollTop) < 5) {
            obj.searchItems(obj.state.pageSize, obj.state.page + 1, obj.state.filter, obj.state.sortColumn, obj.state.sortOrderIsAsc, false, false);
        }
    }, 100);

    handleScroll = (event) => {
        this.handleScrollSub(this);
    };

    componentDidMount(): void {
        const state = this.state;
        this.containerDiv.addEventListener("scroll", this.handleScroll);
        this.searchItems(state.pageSize, state.page, state.filter, state.sortColumn, state.sortOrderIsAsc, false, false);
    }

    componentWillUnmount(): void {
        this.containerDiv.removeEventListener("scroll", this.handleScroll);
    }

    changeFilter = (filter: string) => {
        const state = this.state;
        this.searchItems(state.pageSize, 1, filter, state.sortColumn, state.sortOrderIsAsc, true, false);
    };

    changeSortColumn = (sortColumn: string) => {
        const state = this.state;
        const oldSortColumn = state.sortColumn;
        const sortOrderIsAsc = oldSortColumn === sortColumn ? !state.sortOrderIsAsc : true;
        this.searchItems(state.pageSize, 1, state.filter, sortColumn, sortOrderIsAsc, true, false);
    };

    getEditItem = (id: string) => {
        //    const obj = this;
        //    toolService.getToolEdit(id).then(editItem => {
        //        obj.setState({
        //            showEditDialog: true,
        //            editItem: editItem
        //        });
        //    });
    };

    handleAdd = () => {
        this.getEditItem(Base.emptyGuid);
    };

    handleEdit = () => {
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        this.getEditItem(selectedId);
    };

    handleEditDialogOk = () => {
        this.setState({
            showEditDialog: false
        });
        this.refreshList();
    };

    handleEditDialogCancel = () => {
        this.setState({
            showEditDialog: false
        });
    };

    handleRemove = () => {
        //  const obj = this;
        const state = this.state;
        const selectedId = state.selectedId;
        if (!selectedId) return;
        const item = state.items.find(i => i.id === selectedId);
        if (Base.isNullOrUndefined(item)) return;
        store.customStore.dispatch(StoreActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, String.format(Translations.WorkOrderAcknowledgementTypeRemoveConfirmation, item.name), () => {
            store.customStore.dispatch(StoreActions.clearConfirmation());
            // Call server
            store.customStore.dispatch(StoreActions.fetchStart());
        //    toolService.removeTool(selectedId)
        //        .then(success => {
        //            store.customStore.dispatch(StoreActions.showSuccessMessage(success.message));
        //            obj.refreshList(); // TODO UPDATE ONLY CHANGED CUSTOMER DATA
        //        })
        //        .catch(error => {
        //            store.customStore.dispatch(StoreActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
        //            return null;
        //        })
        //        .finally(() => store.customStore.dispatch(StoreActions.fetchEnd()));
        }, () => {
            store.customStore.dispatch(StoreActions.clearConfirmation());
        }));
    };

    handleClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleDoubleClick = (id: string) => {
        this.getEditItem(id);
    };

    render() {
        const state = this.state;
        const items = state.items;
        return (
            <div>
                <div className="row commandRow main">
                    <ToolTitle
                        title={Translations.WorkOrderAcknowledgementTypes}
                    />
                    <div className="col">
                        <ToolButton
                            title={Translations.Add}
                            enabled={true}
                            classes={"round left add"}
                            onClick={this.handleAdd}
                        />
                        <ToolButton
                            title={Translations.Edit}
                            enabled={!!state.selectedId}
                            classes={"round left edit"}
                            onClick={this.handleEdit}
                        />
                        <ToolButton
                            title={Translations.Remove}
                            enabled={!!state.selectedId}
                            classes={"round left remove"}
                            onClick={this.handleRemove}
                        />
                    </div>
                    <div className="col-auto right">
                        <ListSearchFilter
                            searchFilter={this.state.filter}
                            onSearchClick={this.changeFilter}
                        />
                    </div>
                </div>
                <div>
                    <OwnerSettingsWorkOrderAcknowledgementTypeListLineHeader
                        sortColumn={state.sortColumn}
                        sortOrderIsAsc={state.sortOrderIsAsc}
                        onColumnClick={this.changeSortColumn}
                    />
                </div>
                <div className="listContainer workOrderAcknowledgementTypesContainer">
                    <div className="list striped" ref={(elem) => { this.containerDiv = elem; }}>
                        <div className="lineContainer" ref={(elem) => { this.listDiv = elem; }}>
                            {items.map((item) =>
                                <OwnerSettingsWorkOrderAcknowledgementTypeListLine
                                    key={item.id}
                                    item={item}
                                    selectedId={state.selectedId}
                                    onClick={this.handleClick}
                                    onDoubleClick={this.handleDoubleClick}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {/*    {state.showEditDialog &&*/}
                {/*        <SettingsToolDialog*/}
                {/*            editItem={state.editItem}*/}
                {/*            onOk={this.handleEditDialogOk}*/}
                {/*            onCancel={this.handleEditDialogCancel}*/}
                {/*        />*/}
                {/*    }*/}
            </div>
        );
    }
}