import React, { useState } from "react";
import { WorkTimeListBase } from "./workTimeListBase";
import { MainLayout } from "../layout/mainLayout";
import { WorkTimeFilters } from "./workTimeFilters";
import { useFetch } from "../../hooks/useFetch";
import { EmployeeListItemDto } from "../../models/employee/employee";
import { getEmployees } from "../../services/employeeServiceNew";
import { useAppSelector } from "../../framework/customStore";

interface IWorkTimeMainProps {
    path: string;
}

export const WorkTimeMain = (props: IWorkTimeMainProps) => {
    const [employees, setEmployees] = useState<EmployeeListItemDto[]>();

    const selectedEmployeeGroupId = useAppSelector(
        (state) => state.workTimeBeta.filters.selectedEmployeeGroup?.id
    );

    useFetch(
        (signal) => {
            if (selectedEmployeeGroupId) {
                return getEmployees(selectedEmployeeGroupId, signal);
            }
        },
        {
            onSuccess: (res) => setEmployees(res.data),
        },
        [selectedEmployeeGroupId]
    );

    return (
        <MainLayout noContainer topComponent={<WorkTimeFilters employees={employees} showCostCenters/>}>
            <WorkTimeListBase {...props} employees={employees} />
        </MainLayout>
    );
};
