// ToolButton
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "../../framework/base";

export interface IToolButtonProp {
    id?: string;
    removeDefaultClasses?: boolean;
    stopPropagation?: boolean;
    text?: string;
    classes: string;
    title: string;
    enabled: boolean;
    onClick: () => void;
}

export class ToolButton extends React.Component<IToolButtonProp, {}> {
    handleClick = (e) => {
        const props = this.props;
        if (!props.enabled || !this.props.onClick) return;
        if (this.props.stopPropagation) {
            e.stopPropagation();
        }
        const activeElement: any = document.activeElement;
        if (activeElement && activeElement.blur) {
            activeElement.blur();
            window.setTimeout(() => {
                props.onClick();
            }, 50);
        } else {
            props.onClick();
        }
    };

    render() {
        const props = this.props;
        const hasText = !!props.text;
        const buttonClasses = (props.removeDefaultClasses ? "" : "btn btn-tool") + (props.classes ? " " + props.classes : "") + (!props.enabled ? " disabled" : "") + (hasText ? " text" : "");
        return (
            <button type="button" id={props.id} className={buttonClasses} title={this.props.title} onClick={this.handleClick}><span className="icon" aria-hidden="true" />{hasText && <br />}{this.props.text}</button>
        );
    }
}

export interface IToolAddFileButtonProp {
    stopPropagation?: boolean;
    text?: string;
    title: string;
    classes: string;
    enabled: boolean;
    fileInputName: string;
    onChange: (fileList: FileList) => void;
}

export class ToolAddFileButton extends React.Component<IToolAddFileButtonProp, {}> {
    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!this.props.enabled) return;
        if (!Base.isNullOrUndefined(this.props.stopPropagation) && this.props.stopPropagation) {
            e.stopPropagation();
        }
        if (Base.isNullOrUndefined(e.target.files) || e.target.files.length < 1) return;
        this.props.onChange(e.target.files);
    };

    render() {
        const props = this.props;
        const hasText = !!props.text;
        const buttonClasses = "btn btn-tool" + (props.classes ? " " + props.classes : "") + (!props.enabled ? " disabled" : "") + (hasText ? " text" : "");
        if (props.enabled) {
            return (
                <label className={buttonClasses} title={props.title}>
                    <span className="icon" aria-hidden="true">
                        <input type="file" name={props.fileInputName} className="file" style={ { display: "none" }} multiple={true} onChange={this.handleChange}/>{hasText && <br />}{props.text}
                    </span>
                </label>
            );
        }
        return (
            <button type="button" className={buttonClasses} title={props.title}><span className="icon" aria-hidden="true" />{hasText && <br />}{props.text}</button>
        );
    }
}