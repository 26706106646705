import * as React from "react";
import { Translations } from "../../models/translations";

export interface IAcknowledgementHeaderProp {
    ownerLogo: string;
}

export interface IAcknowledgementHeaderState {
}

export class AcknowledgementHeader extends React.Component<IAcknowledgementHeaderProp, IAcknowledgementHeaderState> {
    render() {
        const ownerLogo = this.props.ownerLogo;
        return (
            <div className="appHeader">
                <div className="appLogoContainer">
                    <a className="appLogo"><img alt="logo" src={ownerLogo ? "data:image/png;base64," + ownerLogo : appConfig.ownRoot + "logo.jpg"} /></a>
                </div>
                <div className="appTitle">{Translations.ApplicationName + " - " + Translations.CustomerView}</div>
            </div>
        );
    }
}