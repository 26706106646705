import { useEffect, useMemo, useRef } from "react";

export function useDebounce<T extends((...args: any[]) => void)>(func: T, wait: number): T {
    const timeoutId = useRef<number>();

    const debounced = useMemo(() => {
        return ((...args: any[]) => {
            clearTimeout(timeoutId.current);
            timeoutId.current = window.setTimeout(() => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                func(...args);
            }, wait);
        });
    }, []);

    useEffect(() => {
        return () => clearTimeout(timeoutId.current);
    }, [timeoutId]);

    return debounced as any as T;
}