import { getApiCall, postApiCall, postFormApiCall } from "./tokenService";
import { DayBookingTypeItems } from "../models/dayBookingType/dayBookingTypeItems";
import { DayBookingTypeItemBeta } from "../models/dayBookingType/dayBookingTypeItemBeta";
import { DayBookingTypeEdit } from "../models/dayBookingType/dayBookingTypeEdit";
import { ApiSuccess, SaveSuccess } from "./baseService";

export const getDayBookingTypeItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean): Promise<DayBookingTypeItems> => {
    return getApiCall<DayBookingTypeItems>("api/daybookingtype/?page=" + (page || "") + "&pageSize=" + (pageSize || "") +
        "&sortColumn=" + (sortColumn ? encodeURIComponent(sortColumn) : "") + "&sortOrder=" + (sortOrderIsAsc ? "asc" : "desc") +
        "&filter=" + (filter ? encodeURIComponent(filter) : ""), DayBookingTypeItems);
};

export const getDayBookingTypeEdit = (id: string): Promise<DayBookingTypeEdit> => {
    return getApiCall<DayBookingTypeEdit>("api/daybookingtype/edit/?id=" + (id || ""), DayBookingTypeEdit);
};

export const saveDayBookingTypeEdit = (data: FormData): Promise<SaveSuccess> => {
    return postFormApiCall<SaveSuccess>("api/daybookingtype/save", data, SaveSuccess);
};

export const removeDayBookingTypes = (ids: string[]): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/daybookingtype/remove", ApiSuccess, JSON.stringify({ ids: ids }));
};

/* New BETA endpoint */
export const getDayBookingTypes = (): Promise<DayBookingTypeItemBeta[]> => {
    return getApiCall<DayBookingTypeItemBeta[]>("api/daybookingtypes", null);
};
