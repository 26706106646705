import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EmployeeListItemDto } from "../models/employee/employee";
import { getEmployees } from "../services/employeeServiceNew";

export interface EmployeesState {
    employees: EmployeeListItemDto[];
}

const initialState: EmployeesState = {
    employees: []
};

export const employeesSlice = createSlice({
    name: "employees",
    initialState,
    reducers: {
        setEmployees: (state, action: PayloadAction<EmployeeListItemDto[]>) => {
            state.employees = action.payload;
        }
    }
});

export const fetchEmployees = createAsyncThunk<EmployeeListItemDto[]>(
    "employees/list",
    async(_, thunkApi) => {
        const res = await getEmployees(null, thunkApi.signal);
        thunkApi.dispatch(setEmployees(res?.data));
        return res?.data;
    }
);

export const {
    setEmployees
} = employeesSlice.actions;

export const employeesReducer = employeesSlice.reducer;
