import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Translations } from '../models/translations';


export interface StorageState {
    productsTab: {
        filters: {
            selectedStorages: string[];
            selectedStorageCustomers: string [];
            searchTerm: string;
        };
    };
    storagesTab: {
        filters: {
            selectedStorages: string[];
            searchTerm: string;
        };
    };
    storageLocationsTab: {
        filters: {
            selectedStorages: string[];
            selectedStorageCustomers: string [];
        };
    };
    shoppingCart: StorgeShoppingCartState;
}

export interface StorgeShoppingCartState {
    selectedOrderId: string | null;
    validationErrors: string[];
    items: StorageShoppingCartItem[];
}

export interface StorageShoppingCartItem {
    id: string;
    productName: string;
    orderAmount: number;
    freeAmount: number;
    unit: string;
    storageName: string;
    storageLocationName: string;
    isValid: boolean;
}

const initialState: StorageState = {
    productsTab: {
        filters: {
            selectedStorages: [],
            selectedStorageCustomers: [],
            searchTerm: ""
        }
    },
    storagesTab: {
        filters: {
            selectedStorages: [],
            searchTerm: ""
        }
    },
    storageLocationsTab: {
        filters: {
            selectedStorages: [],
            selectedStorageCustomers: [],
        }
    },
    shoppingCart: {
        selectedOrderId: null,
        validationErrors: [],
        items: []
    }
};

function validateShoppingCart(cart: StorgeShoppingCartState): void {
    const errors = [];
    const totalBookedAmountForProduct: Record<string, number> = {};

    for (const item of cart.items) {
        totalBookedAmountForProduct[item.id] ??= 0;
        totalBookedAmountForProduct[item.id] += item.orderAmount;
    }
    for (const item of cart.items) {
        item.isValid = true;
        if (totalBookedAmountForProduct[item.id] > item.freeAmount) {
            item.isValid = false;
            errors.push(String.format(Translations.ErrorAmountNotEnoughWithName, item.productName, item.freeAmount));
        }
        if (item.orderAmount === 0) {
            item.isValid = false;
        }
    }

    cart.validationErrors = [...new Set(errors)];
}

export const storageSlice = createSlice({
    name: "storage",
    initialState,
    reducers: {
        updateProductFilters: (state, action: PayloadAction<Partial<StorageState["productsTab"]["filters"]>>) => {
            state.productsTab.filters = { ...state.productsTab.filters, ...action.payload };
        },
        updateStoragesFilters: (state, action: PayloadAction<Partial<StorageState["storagesTab"]["filters"]>>) => {
            state.storagesTab.filters = { ...state.storagesTab.filters, ...action.payload };
        },
        updateStorageLocationsFilters: (state, action: PayloadAction<Partial<StorageState["storageLocationsTab"]["filters"]>>) => {
            state.storageLocationsTab.filters = { ...state.storageLocationsTab.filters, ...action.payload };
        },
        updateShoppingCartSelectedWorkOrderId: (state, action: PayloadAction<string>) => {
            state.shoppingCart.selectedOrderId = action.payload;
        },
        addToStorageShoppingCart: (state, action: PayloadAction<StorageShoppingCartItem>) => {
            state.shoppingCart.items.push(action.payload);
            validateShoppingCart(state.shoppingCart);
        },
        updateCartProductAmount: (state, action: PayloadAction<{index: number, amount: number}>) => {
            const { index, amount } = action.payload;
            state.shoppingCart.items[index].orderAmount = amount;
            validateShoppingCart(state.shoppingCart);
        },
        clearShoppingCart: (state) => {
            state.shoppingCart.items = [];
            validateShoppingCart(state.shoppingCart);
        },
        removeShoppingCartItem: (state, action: PayloadAction<number>) => {
            state.shoppingCart.items.splice(action.payload, 1);
            validateShoppingCart(state.shoppingCart);
        },
        updateCartProductInventory: (state, action: PayloadAction<{storageProductId: string; freeAmount: number}[]>) => {
            for (const { storageProductId, freeAmount } of action.payload) {
                const item = state.shoppingCart.items.find(i => i.id === storageProductId);
                if (item) {
                    item.freeAmount = freeAmount;
                }
            }
            validateShoppingCart(state.shoppingCart);
        }
    },
});

export const {
    updateProductFilters,
    updateStoragesFilters,
    updateStorageLocationsFilters,
    updateShoppingCartSelectedWorkOrderId,
    addToStorageShoppingCart,
    updateCartProductAmount,
    clearShoppingCart,
    removeShoppingCartItem,
    updateCartProductInventory,
} = storageSlice.actions;

export const storageReducer = storageSlice.reducer;
