import { DependencyList, useEffect } from "react";
import { AsyncThunkAction } from "@reduxjs/toolkit";
import { useAppDispatch } from "../framework/customStore";
import { AnyAction } from "redux";

export const useAsyncThunkAction = (
    action: () => AsyncThunkAction<void, any, any>,
    {
        onSuccess,
        onError,
    }: {
        onSuccess?: (value: void) => void | PromiseLike<void>;
        onError?: (reason: any) => void | PromiseLike<void>;
    },
    deps?: DependencyList
) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const dispatchAction = action();

        if (dispatchAction) {
            // For some reason AppDispatch doesn't include AsyncThunkAction type
            const promise = dispatch(dispatchAction as unknown as AnyAction);

            promise
                .unwrap()
                .then(onSuccess)
                .catch(onError ?? console.error);

            return () => {
                promise.abort();
            };
        }
    }, deps);
};
