// WorkOrderDialogDocumentList - MODULE
// ***********************************************************************************************************************
import downloadjs from "downloadjs";
import * as React from "react";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import * as documentService from "../../services/documentService";
import * as workOrderService from "../../services/workOrderService";
import { IDocument, Document } from "../../models/document/document";
import { SaveData } from "../../framework/saveData";
import { DocumentList } from "../document/documentList";
import { DocumentType } from "../../models/common/enums";

// WorkOrderDialogDocumentList
export interface IWorkOrderDialogDocumentListProp {
    title?: string;
    titleId?: string;
    titleClass?: string;
    isReadOnly: boolean;
    documents: IDocument[];
    workOrderId: string;
    smallImage?: boolean;
    onDocumentsModified: (documents: IDocument[]) => void;
    onDocumentRemoved: (documentId: string) => void;
}

export interface IWorkOrderDialogDocumentListState {
    selectedId: string;
}

export class WorkOrderDialogDocumentList extends React.Component<IWorkOrderDialogDocumentListProp, IWorkOrderDialogDocumentListState> {
    constructor(props: IWorkOrderDialogDocumentListProp) {
        super(props);
        this.state = {
            selectedId: null
        };
    }

    // #region Documents
    saveWorkOrderNewDocuments = (fileList: FileList) => {
        const obj = this;
        const props = this.props;
        //Create savedata
        let toSaveDocumentCount = 0;
        const saveData = new SaveData();
        saveData.append("id", props.workOrderId);
        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i];
            if (!file) continue;
            saveData.append("documents[]", file, file.name);
            toSaveDocumentCount++;
        }
        if (toSaveDocumentCount < 1) return;
        //Save to db
        let selectedId = this.state.selectedId;
        store.customStore.dispatch(storeActions.fetchStart());
        workOrderService.addWorkOrderDocuments(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                Document.addFileListToDocuments(props.workOrderId, props.documents, fileList, (document: Document, index: number) => {
                    document.id = success.ids[index];
                    document.rowId = success.rowIds[index];
                    selectedId = document.id;
                }).then(documents => {
                    props.onDocumentsModified(documents);
                    obj.setState({
                        selectedId: selectedId
                    });
                });
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleDocumentsAdd = (fileList: FileList) => {
        if (!fileList || fileList.length < 1 || this.props.isReadOnly) return;
        this.saveWorkOrderNewDocuments(fileList);
    };

    handleDocumentDownload = (id: string) => {
        const props = this.props;
        if (!id) return;
        const document = props.documents.find(i => i.id === id);
        if (!document) return;
        if (document.file) {
            downloadjs(document.file, document.reference);
            return;
        }
        if (document.isNew()) return;
        documentService.getDocument(id).catch(error => {
            store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
        });
    };

    removeWorkOrderNewDocument = (id: string) => {
        const props = this.props;
        //Create savedata
        const documentIds = [id];
        const saveData = new SaveData();
        saveData.append("id", props.workOrderId);
        saveData.append("documentIds", JSON.stringify(documentIds));
        //Save to db
        store.customStore.dispatch(storeActions.fetchStart());
        workOrderService.removeWorkOrderDocuments(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                props.onDocumentRemoved(id);
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleDocumentRemove = (id: string) => {
        if (!id || this.props.isReadOnly) return;
        this.removeWorkOrderNewDocument(id);
    };

    saveWorkOrderDocument = (id: string, comment: string, documentType: DocumentType) => {
        const props = this.props;
        //Create savedata
        const saveData = new SaveData();
        saveData.append("id", props.workOrderId);
        saveData.append("documentId", id);
        saveData.append("comment", comment);
        saveData.append("documentType", documentType.toString());
        //Save to db
        store.customStore.dispatch(storeActions.fetchStart());
        workOrderService.saveWorkOrderDocument(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                const documents = props.documents.slice(0);
                const document = documents.find(i => i.id === id);
                document.comment = comment;
                document.documentType = documentType;
                props.onDocumentsModified(documents);
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleDocumentEdit = (id: string, comment: string, documentType: DocumentType) => {
        if (!id || this.props.isReadOnly) return;
        this.saveWorkOrderDocument(id, comment, documentType);
    };
    // #endregion Documents

    render() {
        const props = this.props;
        return (
            <DocumentList
                classes={"workOrderSubList"}
                relatedObjectId={props.workOrderId}
                title={props.title}
                titleId={props.titleId}
                titleClass={props.titleClass}
                selectedId={this.state.selectedId}
                documents={props.documents}
                isReadOnly={props.isReadOnly}
                canShowCamera={false}
                smallImage={props.smallImage}
                changeDocumentTypeEnabled={true}
                onEditDocument={this.handleDocumentEdit}
                onRemoveDocument={this.handleDocumentRemove}
                onDownloadDocument={this.handleDocumentDownload}
                onAddDocuments={this.handleDocumentsAdd}
                onAddPhoto={null}
            />
        );
    }
}
