import { getApiCall, postFormApiCall, postApiCall } from "./tokenService";
import { ApiSuccess, SaveSuccess } from "./baseService";
import { ProductGroupItems } from "../models/productGroup/productGroupItems";
import { ProductGroupEdit } from "../models/productGroup/productGroupEdit";
import {MainGroups, MainGroupType} from "../components/settings/ProductGroupCommon";

export const getProductGroupItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean, mainGroup?: MainGroupType): Promise<ProductGroupItems> => {
    return getApiCall<ProductGroupItems>("api/productgroup/?page=" + (page || "") + "&pageSize=" + (pageSize || "") +
        "&sortColumn=" + (sortColumn ? encodeURIComponent(sortColumn) : "") + "&sortOrder=" + (sortOrderIsAsc ? "asc" : "desc") +
        "&filter=" + (filter ? encodeURIComponent(filter) : "") + "&mainGroup=" + (mainGroup ? encodeURIComponent(mainGroup) : ""), ProductGroupItems);
};

export const getProductGroupEdit = (id: string): Promise<ProductGroupEdit> => {
    return getApiCall<ProductGroupEdit>("api/productgroup/edit/?id=" + (id || ""), ProductGroupEdit);
};

export const saveProductGroupEdit = (data: FormData): Promise<SaveSuccess> => {
    return postFormApiCall<SaveSuccess>("api/productgroup/save", data, SaveSuccess);
};

export const removeProductGroup = (id: string): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/productgroup/remove/?id=" + (id || ""), ApiSuccess);
};
