import { IAutocompleteItem } from "../../components/framework/autocomplete";
import { IRoutePointItem } from "./routePointItem";

export interface IRoutePointAutocompleteItem extends IAutocompleteItem {
    name: string;
    name2: string;
    street: string;

    getLabelTitle(): string;
}

export class RoutePointAutocompleteItem implements IRoutePointAutocompleteItem {
    id: string;
    name: string;
    name2: string;
    street: string;

    constructor();
    constructor(obj: IRoutePointItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
        this.name2 = obj && obj.name2 || "";
        this.street = obj && obj.street || "";
    }
    
    getTitle(): string {
        let title = this.name;
        if (this.name2.length > 0) {
            title += " (" + this.name2 + ")";
        }
        if (this.street.length > 0) {
            title += " - " + this.street;
        }
        return title;
    }

    getLabelTitle(): string {
        return this.street ?? this.name;
    }
}

export interface IRoutePointAutocompleteItems {
    items: IRoutePointAutocompleteItem[];
}

export class RoutePointAutocompleteItems implements IRoutePointAutocompleteItems {
    items: RoutePointAutocompleteItem[];

    constructor();
    constructor(obj: IRoutePointAutocompleteItems);
    constructor(obj?: any) {
        this.items = [];
        if (obj && obj.items) {
            for (let i = 0; i < obj.items.length; i++) {
                this.items.push(new RoutePointAutocompleteItem(obj.items[i]));
            }
        }
    }
        
    static getHash(items: IRoutePointAutocompleteItem[]): string {
        return items.map(i => i.id).join("#");
    }
}
