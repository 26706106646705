import { Base } from "../../framework/base";
import { IIdTitle, IdTitle } from "../common/idTitle";
import { IEmployeeEditCompetencyItem, EmployeeEditCompetencyItem } from "./employeeEditCompetencyItem";
import { IEmployeeEditItemEmployeeCompetencyItem, EmployeeEditItemEmployeeCompetencyItem } from "./employeeEditItemEmployeeCompetencyItem";

export interface IEmployeeCompetencyEdit {
    employeeId: string;
    employeeCompetency: IEmployeeEditItemEmployeeCompetencyItem;
    employees: IIdTitle[];
    competencies: IEmployeeEditCompetencyItem[];
}

export class EmployeeCompetencyEdit implements IEmployeeCompetencyEdit {
    employeeId: string;
    employeeCompetency: EmployeeEditItemEmployeeCompetencyItem;
    employees: IdTitle[];
    competencies: EmployeeEditCompetencyItem[];

    constructor();
    constructor(obj: IEmployeeCompetencyEdit);
    constructor(obj?: any) {
        this.employeeId = obj && obj.employeeId || "";
        this.employeeCompetency = null;
        if (obj && obj.employeeCompetency) {
            this.employeeCompetency = new EmployeeEditItemEmployeeCompetencyItem(obj.employeeCompetency);
        }
        this.employees = Base.getTypedArray<IdTitle>(!Base.isNullOrUndefined(obj) ? obj.employees : null, IdTitle);
        this.competencies = Base.getTypedArray<EmployeeEditCompetencyItem>(!Base.isNullOrUndefined(obj) ? obj.competencies : null, EmployeeEditCompetencyItem);
        if (!Base.isNullOrUndefined(this.employeeCompetency)) {
            EmployeeEditItemEmployeeCompetencyItem.setCompetencyName(this.employeeCompetency, this.competencies);
        }
    }
}