import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Filters {
    workTimeTypeIds: (string | null)[];
    noWorkTime: boolean;
}

interface MonitorViewState {
    groupMapPoints: boolean;
    filters: Filters;
}

export const initialState: MonitorViewState = {
    groupMapPoints: true,
    filters: {
        workTimeTypeIds: null,
        noWorkTime: true,
    },
};

export const monitorViewSlice = createSlice({
    name: "monitorView",
    initialState,
    reducers: {
        setGroupMapPoints: (state, action: PayloadAction<boolean>) => {
            state.groupMapPoints = action.payload;
        },
        setMapFilterNoWorkTime: (state, action: PayloadAction<boolean>) => {
            state.filters.noWorkTime = action.payload;
        },
        setMapFilterWorkTimeTypeIds: (
            state,
            action: PayloadAction<(string | null)[]>
        ) => {
            state.filters.workTimeTypeIds = action.payload;
        },
        toggleMapFilterWorkTimeTypeId: (
            state,
            action: PayloadAction<{ id: string; checked: boolean }>
        ) => {
            if (action.payload.checked) {
                state.filters.workTimeTypeIds = [
                    ...state.filters.workTimeTypeIds,
                    action.payload.id,
                ];
            } else {
                state.filters.workTimeTypeIds =
                    state.filters.workTimeTypeIds.filter(
                        (id) => id !== action.payload.id
                    );
            }
        },
    },
});

export const {
    setGroupMapPoints,
    setMapFilterNoWorkTime,
    setMapFilterWorkTimeTypeIds,
    toggleMapFilterWorkTimeTypeId,
} = monitorViewSlice.actions;
export const monitoriViewReducer = monitorViewSlice.reducer;
