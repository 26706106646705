import React from "react";
import { Box, Tooltip } from "@mui/material";
import SpeedIcon from "@mui/icons-material/Speed";
import { Translations } from "../../models/translations";
import { WorkShiftTimeSlotItem, WorkTimeEventType } from "../../models/workShitTimeSlot/workShiftTimeSlotItem";
import { Base } from "../../framework/base";
import { t } from "i18next";

interface WorkTimeautomationsIndicatorProps {
    slot: WorkShiftTimeSlotItem;
}

function getAutomationsDescription(slot: WorkShiftTimeSlotItem) {
    return (
        <div>
            {slot.workTimeEvents.map((event, i) => (
                <div key={i}>
                    {event.type === WorkTimeEventType.WorkTimeStarted && `${Base.dateStrToOriginalTimezoneTimeStr(slot.orgStartDate)}: `}
                    {event.type === WorkTimeEventType.WorkTimeEnded && `${Base.dateStrToOriginalTimezoneTimeStr(slot.orgEndDate)}: `}

                    {event.source === "VehicleDriverUpdated" && (
                        event.type === WorkTimeEventType.WorkTimeStarted
                            ? t("workTime.digiCardInserted")
                            : t("workTime.digiCardRemoved")
                    )}
                    {event.source === "VehicleDriving" && t("workTime.vehicleDriving")}
                    {event.source === "VehicleInactive" && t("workTime.vehicleInactive")}
                </div>
            ))}
        </div>
    );
}

export const WorkTimeAutomationsIndicator = (props: WorkTimeautomationsIndicatorProps) => {
    if (props.slot.workTimeEvents.length === 0) {
        return null;
    }
    return (
        <Box
            sx={{
                m: 1,
                position: "relative",
            }}
        >
            <Tooltip title={getAutomationsDescription(props.slot)}>
                <SpeedIcon color="primary" />
            </Tooltip>
        </Box>
    );
};
