import { ICustomerEditItemSiteItem, CustomerEditItemSiteItem } from "./customerEditItemSiteItem";

export interface ISiteEdit {
    site: ICustomerEditItemSiteItem;
    mapLinkTemplate: string;
}

export class SiteEdit implements ISiteEdit {
    site: CustomerEditItemSiteItem;
    mapLinkTemplate: string;

    constructor();
    constructor(obj: ISiteEdit);
    constructor(obj?: any) {
        this.site = null;
        if (obj && obj.site) {
            this.site = new CustomerEditItemSiteItem(obj.site);
        }
        this.mapLinkTemplate = obj && obj.mapLinkTemplate || "";
    }
}