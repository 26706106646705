import React from "react";
import { useTranslation } from "react-i18next";
import { FormikProps, FieldArrayRenderProps } from "formik";
import { formikFieldArrayProps } from "./formikFieldArrayProps";

import { styled } from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import MuiSelect from "../../../framework/muiSelect";

import { ParcelDetailsDto as ParcelDetails } from "../../../../models/transport/parcel";
import { ParcelForm } from "./parcelTable";
import { parcelTypeOptions } from "./parcelUtils";

export interface ParcelRowsProps {
    formikProps: FormikProps<ParcelForm>;
    arrayHelpers: FieldArrayRenderProps;
    changesDisabled?: boolean;
}

const StyledTextField = styled(TextField)(() => ({
    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
        "WebkitAppearance": "none",
        "margin": 0
    },
    "input[type=number]": {
        "MozAppearance": "textfield"
    }
}));

export const ParcelRows = ({ formikProps, arrayHelpers, changesDisabled }: ParcelRowsProps) => {
    const { t } = useTranslation();
    const submit = () => void formikProps?.submitForm();
    const handleTypeChange = (val: string, i: number) => {
        if(val) {
            void formikProps.setFieldValue(
                `rows.${i}.parcelType`,
                parseInt(val)
            );
            setTimeout(() => submit());
        }
    };

    const inputProps = {
        min: 0,
        style: {
            fontSize: "0.9rem",
        }
    };
    if(!formikProps?.values?.rows || formikProps?.values?.rows.length === 0) return;
    return (
        <>
            { formikProps?.values?.rows.map((row: ParcelDetails, i: number) => {
                return (
                    <TableRow
                        key={`parcelTable-row-${row.key}`}
                        data-testid={`parcelTable-row-${i}`}
                    >
                        <TableCell>
                            { i + 1 }
                        </TableCell>
                        <TableCell>
                            <Delete
                                fontSize={"large"}
                                color={changesDisabled ? "disabled" : "error"}
                                onClick={() => {
                                    if(!changesDisabled) {
                                        arrayHelpers.remove(i);
                                        submit();
                                    }
                                }}
                                data-testid={`parcelTable-row-delete-${i}`}
                            />
                        </TableCell>
                        <TableCell>
                            <MuiSelect
                                {...formikFieldArrayProps(formikProps, "parcelType", i, t)}
                                options={parcelTypeOptions}
                                value={parseInt(row?.parcelType) >= 0
                                    ? `${row?.parcelType}`
                                    : undefined
                                }
                                onChange={(val) => handleTypeChange(val, i)}
                                sx={{ minWidth: "175px" }}
                            />
                        </TableCell>
                        <TableCell>
                            <StyledTextField
                                {...formikFieldArrayProps(formikProps, "amount", i, t)}
                                type="number"
                                fullWidth
                                inputProps={inputProps}
                            />
                        </TableCell>
                        <TableCell>
                            <StyledTextField
                                {...formikFieldArrayProps(formikProps, "weight", i, t)}
                                type="number"
                                fullWidth
                                inputProps={inputProps}
                            />
                        </TableCell>
                        <TableCell>
                            <StyledTextField
                                {...formikFieldArrayProps(formikProps, "height", i, t)}
                                type="number"
                                fullWidth
                                inputProps={inputProps}
                            />
                        </TableCell>
                        <TableCell>
                            <StyledTextField
                                {...formikFieldArrayProps(formikProps, "width", i, t)}
                                type="number"
                                fullWidth
                                inputProps={inputProps}
                            />
                        </TableCell>
                        <TableCell>
                            <StyledTextField
                                {...formikFieldArrayProps(formikProps, "length", i, t)}
                                type="number"
                                fullWidth
                                inputProps={inputProps}
                            />
                        </TableCell>
                    </TableRow>
                );
            })}
        </>
    );
};