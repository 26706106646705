import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import Typography from "@mui/material/Typography";
import { useAppDispatch, useAppSelector } from "../../../framework/customStore";
import { Translations } from "../../../models/translations";
import { apiCall } from "../../../services/apiClient";
import { showApiError } from "../../framework/formUtils";
import { VehicleSelect } from "../components/vehicleSelect";
import { TransportPlanState } from "../../../models/transport/transportPlan";
import { setGridDataChanged } from "../../../store/transport/transportVehiclesSlice";

interface VehicleInlineEditProps {
    planId: string;
}

export const VehicleInlineEdit = (props: VehicleInlineEditProps) => {
    const plan = useAppSelector(
        (state) => state.transportVehicles.plans[props.planId]
    );
    const gridDataChanged = useAppSelector((state) => state.transportVehicles.gridDataChanged);
    const dispatch = useAppDispatch();

    const pendingTransport = plan.state !== TransportPlanState.InTransport && plan.state !== TransportPlanState.Completed;

    const [editing, setEditing] = useState<boolean>(false);
    const [vehicle, setVehicle] = useState<{
        id?: string;
        name?: string | null;
    } | null>(
        plan.vehicle && {
            id: plan.vehicle?.id,
            name: `${plan.vehicle?.brand} ${plan.vehicle?.registerNumber}`
        }
    );

    useEffect(() => {
        if (plan.vehicle) {
            setVehicle({ id: plan.vehicle.id, name: `${plan.vehicle?.brand} ${plan.vehicle?.registerNumber}` });
        } else setVehicle(null);
    }, [plan]);

    const handleChange = async(id: string | null, name?: string | null) => {
        const prevVehicle = vehicle;

        if (!id) {
            await apiCall<void>(
                `TransportPlans/${plan.id}/vehicles/Remove`,
                "PUT"
            ).catch((e) => {
                showApiError(e);
                setVehicle(prevVehicle);
            });
            setVehicle(null);
            setEditing(false);
            dispatch(setGridDataChanged(!gridDataChanged));
        } else {
            await apiCall<void>(
                `TransportPlans/${plan.id}/vehicles/${id}`,
                "PUT"
            ).catch((e) => {
                showApiError(e);
                setVehicle(prevVehicle);
            });
            setVehicle({ id, name });
            setEditing(false);
            dispatch(setGridDataChanged(!gridDataChanged));
        }
    };

    if (editing) {
        return (
            <Box mt={1}>
                <VehicleSelect
                    onChange={handleChange}
                    onBlur={() => setEditing(false)}
                    value={vehicle?.id}
                    autoFocus
                    open
                    small
                />
            </Box>
        );
    }

    return (
        <Typography
            className={pendingTransport ? "hover-effect" : ""}
            fontSize="small"
            fontWeight="600"
            onClick={(e) => {
                if (!pendingTransport) return;
                e.stopPropagation();
                setEditing(true);
            }}
        >
            {vehicle ? (
                <>
                    <LocalShippingOutlinedIcon
                        fontSize="small"
                        className="text-blue-dark mr-1"
                    />
                    {vehicle?.name}
                </>
            ) : (
                <span className="text-red">{Translations.NoVehicle}</span>
            )}
        </Typography>
    );
};
