import { IContactItem, ContactItem } from "./contactItem";

export interface IContactItems {
    items: IContactItem[];
    hasMore: boolean;
    page: number;
}

export class ContactItems implements IContactItems {
    items: ContactItem[];
    hasMore: boolean;
    page: number;

    constructor();
    constructor(obj: IContactItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = [];
        if (obj && obj.items) {
            for (let i = 0; i < obj.items.length; i++) {
                this.items.push(new ContactItem(obj.items[i]));
            }
        }
    }
}