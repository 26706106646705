import { Base } from "../../framework/base";
import { SalaryRowTypeItem, ISalaryRowTypeItem } from "../salary/salaryRowTypeItem";

export interface ISalaryRowTypeItems {
    items: ISalaryRowTypeItem[];
    hasMore: boolean;
    page: number;
}

export class SalaryRowTypeItems implements ISalaryRowTypeItems {
    items: SalaryRowTypeItem[];
    hasMore: boolean;
    page: number;

    constructor();
    constructor(obj: ISalaryRowTypeItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = Base.getTypedArray<SalaryRowTypeItem>(obj ? obj.items : null, SalaryRowTypeItem);
    }
}