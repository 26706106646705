export interface IVehicleGroupEditItem {
    id: string;
    name: string;
    description: string;
    rowId: string;
    vehicleIds: string[];

    isNew():boolean;
}

export class VehicleGroupEditItem implements IVehicleGroupEditItem {
    id: string;
    name: string;
    description: string;
    rowId: string;
    vehicleIds: string[];

    constructor();
    constructor(obj: IVehicleGroupEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
        this.description = obj && obj.description || "";
        this.rowId = obj && obj.rowId || "";
        this.vehicleIds = obj && obj.vehicleIds || [];
    }

    isNew():boolean {
        return !this.rowId;
    }
}