import { getApiCall, makeApiCallWithAny, postApiCall } from "./tokenService";
import { DayBookingItemBeta } from "../models/dayBooking/dayBookingItemBeta";
import { DayBookingSaveData } from "../models/dayBooking/dayBookingSaveData";
import { ApiSuccess } from "./baseService";

export const getDayBookingItems = (startDate: string, endDate: string, employeeIds: string | string[], signal?: AbortSignal): Promise<DayBookingItemBeta[]> => {
    return getApiCall<DayBookingItemBeta[]>("api/daybooking/?startDate=" + (startDate || "") + "&endDate=" + (endDate || "") +
        ([employeeIds].flat().map(id => `&employeeIds=${encodeURIComponent(id)}`).join("")), Array, signal).then((data) => {
        return data.flat(1).map((item) => new DayBookingItemBeta(item));
    });
};

export const saveDayBooking = (
    data: DayBookingSaveData
): Promise<DayBookingItemBeta> => {
    return postApiCall<DayBookingItemBeta>(
        "api/daybooking/save",
        DayBookingItemBeta,
        JSON.stringify(data)
    );
};

export const getDayBookingDuration = (
    data: DayBookingSaveData,
    signal?: AbortSignal
): Promise<number> => {
    return makeApiCallWithAny<number>(
        "api/daybooking/duration",
        "POST",
        data,
        null,
        signal
    );
};

export const lockDayBookings = (ids: string[]): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/daybooking/lock", ApiSuccess, JSON.stringify({
        ids: ids
    }));
};

export const unLockDayBookings = (ids: string[]): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/daybooking/unlock", ApiSuccess, JSON.stringify({
        ids: ids
    }));
};

export const deleteDayBooking = (dayBookingId: string): Promise<boolean> => {
    return makeApiCallWithAny<boolean>(
        `api/daybooking/delete/${encodeURIComponent(dayBookingId)}`,
        "DELETE",
        null,
        null
    );
};