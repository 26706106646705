import { Base } from "../../framework/base";

export interface ICustomerEditItemCustomerContactItem {
    id: string;
    contactId: string;
    name: string;
    email: string;
    phone: string;
    comment: string;
    isNew: boolean;
}

export class CustomerEditItemCustomerContactItem implements ICustomerEditItemCustomerContactItem {
    id: string;
    contactId: string;
    name: string;
    email: string;
    phone: string;
    comment: string;
    isNew: boolean;

    constructor();
    constructor(obj: ICustomerEditItemCustomerContactItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.contactId = obj && obj.contactId || "";
        this.name = obj && obj.name || "";
        this.email = obj && obj.email || "";
        this.phone = obj && obj.phone || "";
        this.comment = obj && obj.comment || "";
        this.isNew = !this.id;
    }

    static sortCustomerEditItemCustomerContactItems(items: ICustomerEditItemCustomerContactItem[], column: string, asc: boolean) {
        if (Base.isNullOrUndefined(items) || items.length < 2) return;
        const sortFunc = (a: ICustomerEditItemCustomerContactItem, b: ICustomerEditItemCustomerContactItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.name, b.name)); };
        items.sort(sortFunc);
    }
}