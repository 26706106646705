import { Base } from "../../framework/base";
import { IDocument, Document } from "../document/document";
import { IWorkOrderEditItemHourBooking, WorkOrderEditItemHourBooking } from "./workOrderEditItemHourBooking";
import { IWorkOrderEditItemRideBooking, WorkOrderEditItemRideBooking } from "./workOrderEditItemRideBooking";
import { IWorkOrderEditItemWorkOrderTask, WorkOrderEditItemWorkOrderTask } from "./workOrderEditItemWorkOrderTask";
import { WorkOrderCategory, EnumHelper, WorkOrderState, RecurrenceType } from "../common/enums";
import { IWorkTimeListItem, WorkTimeListItem } from "../workTime/workTimeListItem";
import { IWorkOrderEditItemProductBooking, WorkOrderEditItemProductBooking } from "./workOrderEditItemProductBooking";
import { AppUtils } from "../common/appUtils";
import { IRoutePointItem, RoutePointItem } from "../routePoint/routePointItem";
import { Translations } from "../translations";
import { IWorkOrderEditItemRoutePointBooking, WorkOrderEditItemRoutePointBooking } from "./workOrderEditItemRoutePointBooking";
import { IStorageProductBooking } from "../storage/storageProductBooking";

export interface IWorkOrderEditItem {
    id: string;
    customerId: string;
    siteId: string;
    contactId: string;
    employeeIds: string[];
    vehicleIds: string[];
    workOrderTypeId: string;
    workOrderAcknowledgementTypeId: string;
    number: number;
    name: string;
    description: string;
    startTime: number;
    endTime: number;
    state: number;
    category: number;
    fullDay: boolean;
    canceled: boolean;
    template: boolean;
    comment: string;
    acknowledgementSent: boolean;
    estimatedHours: number;
    rowId: string;
    documents: IDocument[];
    hourBookings: IWorkOrderEditItemHourBooking[];
    productBookings: IWorkOrderEditItemProductBooking[];
    rideBookings: IWorkOrderEditItemRideBooking[];
    workTimes: IWorkTimeListItem[];
    routePoints: IRoutePointItem[];
    routePointBookings: IWorkOrderEditItemRoutePointBooking[];
    sortOrder: number;
    storageProductBookings: IStorageProductBooking[];
    hasSequentialTasks: boolean;
    showCollectionListInMobile: boolean;
    // Recurrence
    recurrenceType: number;
    recurrenceInterval: number;
    recurrenceIntervalDetail: number;
    recurrenceOccurrenceAmount: number;
    recurrenceEndDate: number;
    // Invoice
    invoiceId: string;
    invoiceNumber: number;
    // Project fields
    projectTasks: IWorkOrderEditItemWorkOrderTask[];
    // Task fields
    parentId: string;
    parentNumber: number;
    parentName: string;
    parentState: number;
    parentTaskCount: number;
    parentRecurrenceType: number;

    isPreliminary(): boolean;
    isPlanned(): boolean;
    isInProgress(): boolean;
    isDone(): boolean;
    isChecked(): boolean;
    hasBeenTransferred(): boolean;
    isNew(): boolean;
    isProject(): boolean;
    isWork(): boolean;
    isTask(): boolean;
    isRecurring(): boolean;
    isRecurringTask(): boolean;
    getDurationStr(): string;
    getProjectTime(): { startTime: number, endTime: number, fullDay: boolean };

    getUiTitle(): string;
}

export class WorkOrderEditItem implements IWorkOrderEditItem {
    id: string;
    customerId: string;
    siteId: string;
    contactId: string;
    employeeIds: string[];
    vehicleIds: string[];
    workOrderTypeId: string;
    workOrderAcknowledgementTypeId: string;
    number: number;
    name: string;
    description: string;
    startTime: number;
    endTime: number;
    state: number;
    category: number;
    fullDay: boolean;
    canceled: boolean;
    template: boolean;
    comment: string;
    acknowledgementSent: boolean;
    estimatedHours: number;
    rowId: string;
    documents: Document[];
    hourBookings: WorkOrderEditItemHourBooking[];
    productBookings: WorkOrderEditItemProductBooking[];
    rideBookings: WorkOrderEditItemRideBooking[];
    workTimes: WorkTimeListItem[];
    routePoints: RoutePointItem[];
    routePointBookings: WorkOrderEditItemRoutePointBooking[];
    sortOrder: number;
    hasSequentialTasks: boolean;
    showCollectionListInMobile: boolean;
    storageProductBookings: IStorageProductBooking[];
    // Recurrence
    recurrenceType: number;
    recurrenceInterval: number;
    recurrenceIntervalDetail: number;
    recurrenceOccurrenceAmount: number;
    recurrenceEndDate: number;
    // Invoice
    invoiceId: string;
    invoiceNumber: number;
    // Project fields
    projectTasks: WorkOrderEditItemWorkOrderTask[];
    // Task fields
    parentId: string;
    parentNumber: number;
    parentName: string;
    parentState: number;
    parentTaskCount: number;
    parentRecurrenceType: number;

    constructor();
    constructor(obj: IWorkOrderEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.customerId = obj && obj.customerId || "";
        this.siteId = obj && obj.siteId || "";
        this.contactId = obj && obj.contactId || "";
        this.employeeIds = obj && obj.employeeIds || [];
        this.vehicleIds = obj && obj.vehicleIds || [];
        this.workOrderTypeId = obj && obj.workOrderTypeId || "";
        this.workOrderAcknowledgementTypeId = obj && obj.workOrderAcknowledgementTypeId || "";
        this.number = obj && obj.number || 0;
        this.name = obj && obj.name || "";
        this.description = obj && obj.description || "";
        this.startTime = obj && obj.startTime || null;
        this.endTime = obj && obj.endTime || null;
        this.state = obj && obj.state || 0;
        this.category = obj && obj.category || WorkOrderCategory.Work;
        this.fullDay = obj && obj.fullDay || false;
        this.canceled = obj && obj.canceled || false;
        this.template = obj && obj.canceled || false;
        this.comment = obj && obj.comment || "";
        this.acknowledgementSent = obj && obj.acknowledgementSent || false;
        this.estimatedHours = obj && obj.estimatedHours || 0;
        this.rowId = obj && obj.rowId || "";
        this.documents = Base.getTypedArray<Document>(obj ? obj.documents : null, Document);
        this.hourBookings = Base.getTypedArray<WorkOrderEditItemHourBooking>(obj ? obj.hourBookings : null, WorkOrderEditItemHourBooking);
        this.productBookings = Base.getTypedArray<WorkOrderEditItemProductBooking>(obj ? obj.productBookings : null, WorkOrderEditItemProductBooking);
        this.rideBookings = Base.getTypedArray<WorkOrderEditItemRideBooking>(obj ? obj.rideBookings : null, WorkOrderEditItemRideBooking);
        this.workTimes = Base.getTypedArray<WorkTimeListItem>(obj ? obj.workTimes : null, WorkTimeListItem);
        this.routePoints = Base.getTypedArray<RoutePointItem>(obj ? obj.routePoints : null, RoutePointItem);
        this.routePointBookings = Base.getTypedArray<WorkOrderEditItemRoutePointBooking>(obj ? obj.routePointBookings : null, WorkOrderEditItemRoutePointBooking);
        this.sortOrder = obj && obj.sortOrder || 0;
        this.hasSequentialTasks = obj && obj.hasSequentialTasks || false;
        this.showCollectionListInMobile = obj && obj.showCollectionListInMobile || false;
        this.storageProductBookings = obj.storageProductBookings;
        // Recurrence
        this.recurrenceType = obj && obj.recurrenceType || 0;
        this.recurrenceInterval = obj && obj.recurrenceInterval || 1;
        this.recurrenceIntervalDetail = obj && obj.recurrenceIntervalDetail || 0;
        this.recurrenceOccurrenceAmount = obj && obj.recurrenceOccurrenceAmount || 1;
        this.recurrenceEndDate = obj && obj.recurrenceEndDate || null;
        // Invoice
        this.invoiceId = obj && obj.invoiceId || "";
        this.invoiceNumber = obj && obj.invoiceNumber || 0;
        // Project fields
        this.projectTasks = Base.getTypedArray<WorkOrderEditItemWorkOrderTask>(obj ? obj.projectTasks : null, WorkOrderEditItemWorkOrderTask);
        // Task fields
        this.parentId = obj && obj.parentId || "";
        this.parentNumber = obj && obj.parentNumber || 0;
        this.parentName = obj && obj.parentName || "";
        this.parentState = obj && obj.parentState || 0;
        this.parentTaskCount = obj && obj.parentTaskCount || 0;
        this.parentRecurrenceType = obj && obj.parentRecurrenceType || 0;
    }

    isNew(): boolean {
        return !this.rowId;
    }

    isPreliminary(): boolean {
        return EnumHelper.isEqual(this.state, WorkOrderState.Preliminary);
    }

    isPlanned(): boolean {
        return EnumHelper.isEqual(this.state, WorkOrderState.Planned);
    }

    isInProgress(): boolean {
        return EnumHelper.isEqual(this.state, WorkOrderState.InProgress);
    }

    isDone(): boolean {
        return EnumHelper.isEqual(this.state, WorkOrderState.Done);
    }

    isChecked(): boolean {
        return EnumHelper.isEqual(this.state, WorkOrderState.Checked);
    }

    hasBeenTransferred(): boolean {
        return EnumHelper.isGreaterOrEqual(this.state, WorkOrderState.Transferred);
    }

    isProject(): boolean {
        return EnumHelper.isEqual(this.category, WorkOrderCategory.Project);
    }

    isWork(): boolean {
        return EnumHelper.isEqual(this.category, WorkOrderCategory.Work);
    }

    isTask(): boolean {
        return EnumHelper.isEqual(this.category, WorkOrderCategory.Task);
    }

    isRecurring(): boolean {
        return EnumHelper.isGreaterThan(this.recurrenceType, RecurrenceType.NoRecurrence);
    }

    isRecurringTask(): boolean {
        return this.isTask() && EnumHelper.isGreaterThan(this.parentRecurrenceType, RecurrenceType.NoRecurrence);
    }

    getDurationStr(): string {
        return AppUtils.getDurationStr(this.startTime, this.endTime);
    }

    static getProjectTime(projectTasks: WorkOrderEditItemWorkOrderTask[]): { startTime: number, endTime: number, fullDay: boolean } {
        const result = {
            startTime: 0,
            endTime: 0,
            fullDay: false
        };
        for (const projectTask of projectTasks) {
            if (!projectTask.startTime) continue;
            if (result.startTime <= 1 || projectTask.startTime < result.startTime) {
                result.startTime = projectTask.startTime;
            }
            if (!projectTask.endTime) continue;
            if (result.endTime <= 1 || projectTask.endTime > result.endTime) {
                result.endTime = projectTask.endTime;
                result.fullDay = projectTask.fullDay;
            }
        }
        return result;
    }

    getProjectTime(): { startTime: number, endTime: number, fullDay: boolean } {
        return WorkOrderEditItem.getProjectTime(this.projectTasks);
    }

    static getUiTitleStatic(isNew: boolean, isProject: boolean, isRecurring: boolean, isWork: boolean, number: number, parentNumber: number, name: string, canceled: boolean, setPrefix: boolean = true, template: boolean = false): string {
        return (template
            ? Translations.WorkOrderTemplate + " - "
            : "") +
            (setPrefix
                ? (isProject
                    ? (isRecurring ? Translations.RecurringWork : Translations.WorkOrderProject)
                    : (isWork ? Translations.WorkOrderWork : Translations.WorkOrderTask))
                : "") + " - " +
            (isNew ? Translations.New : number.toString(10)) +
            (parentNumber ? " (" + parentNumber.toString(10) + ")" : "") +
            (isNew ? "" : " " + name) +
            (canceled ? " (" + Translations.Canceled.toLowerCase() + ")" : "");
    }

    getUiTitle(): string {
        return WorkOrderEditItem.getUiTitleStatic(this.isNew(), this.isProject(), this.isRecurring(), this.isWork(), this.number, this.parentNumber, this.name, this.canceled, true, this.template);
    }
}
