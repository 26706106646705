/* global JSX */
import * as React from "react";
import { Button } from "./button";
import { ToolTitle } from "./toolTitle";

export interface IDrawerViewProp {
    classes?: string;
    headerClasses?: string;
    title: string;
    content: JSX.Element;
    expanded: boolean;
    onChangeMode: (expanded: boolean) => void;
    onTitleClick?: () => void;
}

export class DrawerView extends React.Component<IDrawerViewProp, {}> {
    handleModeChange = () => {
        this.props.onChangeMode(!this.props.expanded);
    };

    render() {
        const props = this.props;
        return (
            <div className={"drawerView " + (props.classes ? " " + props.classes : "")}>
                {props.expanded &&
                    <div className={"commandRow main" + (props.headerClasses ? " " + props.headerClasses : "")}>
                        <ToolTitle
                            title={props.title}
                        />
                        <Button
                            title=""
                            enabled={true}
                            onClick={this.handleModeChange}
                            icon="close"
                        />
                    </div>
                }
                <div className={props.expanded ? "" : "hiddenContent"}>
                    {props.content}
                </div>
                {!props.expanded &&
                    <Button
                        title=""
                        enabled={true}
                        onClick={this.handleModeChange}
                        icon="previous"
                    />
                }
            </div>
        );
    }
}