import { Base } from "../../framework/base";
import { Translations } from "../translations";
import { IEmployeeItem } from "../employee/employeeItem";
import { IVehicleItem } from "../vehicle/vehicleItem";

export interface IWorkOrderEditItemWorkOrderTask {
    id: string;
    number: number;
    name: string;
    description: string;
    employeeIds: string[];
    vehicleIds: string[];
    startTime: number;
    endTime: number;
    fullDay: boolean;
    invoiceNumber: number;
    invoiceTransferred: boolean;
    stateStr: string;
    state: number;
    estimatedHours: number;
    rowId: string;
    sortOrder: number;

    getTaskVehicles(allVehicles: IVehicleItem[]): IVehicleItem[];
    getTaskEmployees(allEmployees: IEmployeeItem[]): IEmployeeItem[];
    getTooltip(customerName: string, siteName: string, siteAddress: string, allEmployees: IEmployeeItem[], allVehicles: IVehicleItem[]): string;
}

export class WorkOrderEditItemWorkOrderTask implements IWorkOrderEditItemWorkOrderTask {
    id: string;
    number: number;
    name: string;
    description: string;
    employeeIds: string[];
    vehicleIds: string[];
    startTime: number;
    endTime: number;
    fullDay: boolean;
    invoiceNumber: number;
    invoiceTransferred: boolean;
    stateStr: string;
    state: number;
    estimatedHours: number;
    rowId: string;
    sortOrder: number;

    constructor();
    constructor(obj: IWorkOrderEditItemWorkOrderTask);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.name = obj && obj.name || "";
        this.description = obj && obj.description || "";
        this.startTime = obj && obj.startTime || null;
        this.endTime = obj && obj.endTime || null;
        this.employeeIds = obj && obj.employeeIds || [];
        this.vehicleIds = obj && obj.vehicleIds || [];
        this.fullDay = obj && obj.fullDay || false;
        this.invoiceNumber = obj && obj.invoiceNumber || 0;
        this.invoiceTransferred = obj && obj.invoiceTransferred || false;
        this.state = obj && obj.state || 0;
        this.stateStr = obj && obj.stateStr || "";
        this.estimatedHours = obj && obj.estimatedHours || 0;
        this.rowId = obj && obj.rowId || "";
        this.sortOrder = obj && obj.sortOrder || 0;
    }

    getTaskVehicles(allVehicles: IVehicleItem[]): IVehicleItem[] {
        return allVehicles.filter(i => this.vehicleIds.indexOf(i.id) > -1);
    }

    getTaskEmployees(allEmployees: IEmployeeItem[]): IEmployeeItem[] {
        return allEmployees.filter(i => this.employeeIds.indexOf(i.id) > -1);
    }

    getTooltip(customerName: string, siteName: string, siteAddress: string, allEmployees: IEmployeeItem[], allVehicles: IVehicleItem[]): string {
        return Base.getStringWithSeparators([Translations.WorkOrderTask + " " + this.number.toString(10), customerName, this.name, siteName, siteAddress,
            this.getTaskEmployees(allEmployees).map(i => i.getTitle()).join(", "), this.getTaskVehicles(allVehicles).map(i => i.getTitle()).join(", "), Base.timeToDateStr(this.startTime)], "\n");
    }

    static sortWorkOrderEditItemWorkOrderTasks(items: IWorkOrderEditItemWorkOrderTask[]) {
        if (!items || items.length < 2) return;
        items.sort((a: IWorkOrderEditItemWorkOrderTask, b: IWorkOrderEditItemWorkOrderTask) => {
            if (!a || !b || !a.startTime || !b.startTime) {
                return 0;
            }
            return Base.numberCompare(a.startTime, b.startTime);
        });
    }
}
