export type ParcelListItemDto = ParcelDetailsDto;

export interface ParcelDetailsDto {
    id?: string | null;
    key: string | null;
    transportOrderId?: string | null;
    name?: string;
    amount?: number;
    width?: number;
    length?: number;
    height?: number;
    weight?: number;
    volume?: number;
    stackable?: boolean;
    loadingMeter?: number;
    parcelType?: ParcelTypes;
    hazardousMaterialClass: HazardousMaterialClass;
}

export type ParcelParameter = Capitalize<keyof ParcelDetailsDto>;

export interface ParcelTypeDto {
    parcelType: ParcelType;
    typeName: string;
    requiredParameters: ParcelParameter[];
}

export enum ParcelType {
    EURlava = 0,
    FINlava = 1,
    Teholava = 2,
    Muulava = 3,
    Rullakko = 4,
    Irtokolli = 5,
    IBCKontti = 6,
    Häkki = 7,
    Nippu = 8,
    Muu = 9,
    Irtoneste = 10
}

export enum ParcelTypes {
    EURlava = "EURLava",
    FINlava = "FINLava",
    Teholava = "Teholava",
    Muulava = "Muulava",
    Rullakko = "Rullakko",
    Irtokolli = "Irtokolli",
    IBCkontti = "IBCKontti",
    Häkki = "Häkki",
    Nippu = "Nippu",
    Muu = "Muu",
    Irtoneste = "Irtoneste"
}

export enum HazardousMaterialClass
{
    Class1,
    Class2,
    Class3,
    Class4_1,
    Class4_2,
    Class4_3,
    Class5_1,
    Class5_2,
    Class6_1,
    Class6_2,
    Class7,
    Class8,
    Class9
}
