import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { Translations } from "../../models/translations";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import { Base } from "../../framework/base";
import { useEventListener } from "usehooks-ts";
import {
    WorkShiftTimeSlotItem,
    findNextWorkShiftTimeSlotItem,
    findPreviousWorkShiftTimeSlotItem,
} from "../../models/workShitTimeSlot/workShiftTimeSlotItem";
import {
    AddBreakValueProps,
    WorkTimeAddBreakDialog,
} from "./workTimeAddBreakDialog";

interface WorkTimeTimelineMenuProps {
    timelineRef: React.MutableRefObject<HTMLDivElement>;
    timeZones: string[];
    timelineStart: number;
    timelineEnd: number;
    timeSlotItems: WorkShiftTimeSlotItem[];
    reloadList: () => void;
}

export const WorkTimeTimelineMenu = ({
    timelineRef,
    timeZones,
    timelineStart,
    timelineEnd,
    timeSlotItems,
    reloadList,
}: WorkTimeTimelineMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorPosition, setAnchorPosition] = useState<{
        top: number;
        left: number;
    } | null>(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date>();
    const [defaultValues, setDefaultValues] =
        useState<AddBreakValueProps | null>();
    const [isLocked, setIsLocked] = useState(false);

    const { t } = useTranslation();

    const handleAddBreak = () => {
        setOpenDialog(true);
    };

    const handleTimeLineClick = (e) => {
        if (
            e.target.classList.contains("worktimeline-item") ||
            e.target.closest(".worktimeline-item")
        ) {
            // Skip if clicked inside timeline item.
            return;
        }

        setIsLocked(false);
        const clickX = e.clientX;
        const divRect = e.currentTarget.getBoundingClientRect();
        const clickXRelativeToDiv = e.clientX - divRect.left;
        const divWidth = divRect.width;
        const clickPercentage = (clickXRelativeToDiv / divWidth) * 100;
        if (!anchorEl) {
            setAnchorEl(e.currentTarget as HTMLElement);
            setAnchorPosition({ top: e.clientY, left: clickX });
        }
        setSelectedDate(
            Base.roundDateToNearestMinutes(
                new Date(
                    timelineStart +
                    (clickPercentage / 100) * (timelineEnd - timelineStart)
                ),
                15
            )
        );
    };

    const getDefaultValues = () => {
        let startDate;
        let timeZone;
        for (const item of timeSlotItems) {
            if (Base.isBetween(selectedDate, item.startDate, item.endDate) || Base.isSameMinute(selectedDate, item.startDate)) {
                startDate = Base.dateToTz(item.timeZoneName, selectedDate);
                timeZone = item.timeZoneName;
                const locked = item.isLocked();
                if (locked) {
                    setIsLocked(true);
                    break;
                }
            }
        }
        if (!startDate) {
            let prevOrNext =
                findPreviousWorkShiftTimeSlotItem(
                    timeSlotItems,
                    selectedDate
                ) || findNextWorkShiftTimeSlotItem(timeSlotItems, selectedDate);

            startDate = Base.dateToTz(prevOrNext?.timeZoneName, selectedDate);
            timeZone = prevOrNext?.timeZoneName;
        }
        setDefaultValues({
            startDate: Base.dayjsToJsonDateTime(startDate),
            timeZoneName: timeZone,
        });
    };

    useEffect(() => {
        if (!!selectedDate) {
            getDefaultValues();
        } else {
            setDefaultValues(null);
        }
    }, [selectedDate]);

    useEventListener("click", handleTimeLineClick, timelineRef);

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                anchorReference="anchorPosition"
                anchorPosition={anchorPosition}
                onClose={() => setAnchorEl(null)}
                onClick={() => setAnchorEl(null)}
            >
                <Grid2 container alignItems="center">
                    <MenuItem
                        disabled={isLocked}
                        onClick={() => handleAddBreak()}
                    >
                        <Typography variant="subtitle2" color="primary">
                            {Translations.AddBreak}
                        </Typography>
                        <Typography pl={1} variant="subtitle2">
                            {Base.dateStrToOriginalTimezoneTimeStr(
                                defaultValues?.startDate
                            )}
                        </Typography>
                    </MenuItem>
                    {isLocked && (
                        <Grid2 ml={-1} mr={2}>
                            <Tooltip
                                title={t("workTime.breakNotAddableWhenLocked")}
                            >
                                <ErrorOutlineIcon color="primary" />
                            </Tooltip>
                        </Grid2>
                    )}
                </Grid2>
            </Menu>
            {openDialog && !!defaultValues?.startDate ? (
                <WorkTimeAddBreakDialog
                    items={timeSlotItems}
                    defaultValues={defaultValues}
                    timeZones={timeZones}
                    setOpenDialog={setOpenDialog}
                    reloadList={reloadList}
                />
            ) : null}
        </>
    );
};
