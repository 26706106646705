import { IOperationLogEditItem, OperationLogEditItem } from "./operationLogEditItem";

export interface IOperationLogEdit {
    operationLog: IOperationLogEditItem;
}

export class OperationLogEdit implements IOperationLogEdit {
    operationLog: OperationLogEditItem;

    constructor();
    constructor(obj: IOperationLogEdit);
    constructor(obj?: any) {
        this.operationLog = null;
        if (obj && obj.operationLog) {
            this.operationLog = new OperationLogEditItem(obj.operationLog);
        }
    }
}