import { Base } from "../../framework/base";
import { IReportListItem, ReportListItem } from "./reportListItem";

export interface IReportListItems {
    items: IReportListItem[];
}

export class ReportListItems implements IReportListItems {
    items: ReportListItem[];

    constructor();
    constructor(obj: IReportListItems);
    constructor(obj?: any) {
        this.items = Base.getTypedArray<ReportListItem>(obj ? obj.items : null, ReportListItem);
    }
}