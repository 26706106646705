import React, { useEffect, useState } from "react";
import { MainLayout } from "../../layout/mainLayout";
import { TransportOrderListItemDto } from "../../../models/transport/transportOrder";
import { RootState, useAppSelector } from "../../../framework/customStore";
import { InvoicingTransportOrderList } from "./InvoicingTransportOrderList";
import { TransportOrderInvoiceSelectedOrders } from "./TransportOrderInvoiceSelectedOrders";
import { TransportOrderFilters } from "./TransportOrderFilters";

export const InvoicingTransportOrdersMain = () => {
    const [orderItems, setOrderItems] = useState<TransportOrderListItemDto[]>([]);
    const state = useAppSelector((state: RootState) => state.transportOrders);
    const [selectedOrders, setSelectedOrders] = useState<TransportOrderListItemDto[]>([]);
    const [selectedCustomerId, setSelectedCustomerId] = useState<string>("");
    const [showOnlyInvoiceable, setShowOnlyInvoiceable] = useState<boolean>(true);

    useEffect(() => {
        if (selectedOrders.length > 0) {
            setSelectedCustomerId(selectedOrders[0]?.billingCustomerId);
        } else {
            setSelectedCustomerId("");
        }
    }, [selectedOrders]);

    useEffect(() => {
        if (selectedOrders.length > 0 && selectedCustomerId !== state.billingCustomer && state.billingCustomer) {
            setSelectedOrders([]);
        }
    }, [state.billingCustomer]);

    const handleToggleState = () => {
        setShowOnlyInvoiceable(!showOnlyInvoiceable);
    };

    return (
        <MainLayout
            className="colored-sidebar"
            sideComponent={
                <TransportOrderInvoiceSelectedOrders
                    selectedOrders={selectedOrders}
                />
            }
            topComponent={(
                <TransportOrderFilters
                    setOrderItems={setOrderItems}
                    showOnlyInvoiceable={showOnlyInvoiceable}
                />
            )}
        >
            <InvoicingTransportOrderList
                orderItems={orderItems}
                handleToggleState={handleToggleState}
                showOnlyInvoiceable={showOnlyInvoiceable}
                selectedOrders={selectedOrders}
                setSelectedOrders={setSelectedOrders}
            />
        </MainLayout>
    );
};