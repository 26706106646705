import { Base } from "../../framework/base";
import { ICompetencyEditItem, CompetencyEditItem } from "./competencyEditItem";
import { IIdTitle, IdTitle } from "../common/idTitle";

export interface ICompetencyEdit {
    competency: ICompetencyEditItem;
    competencyTypes: IIdTitle[];
}

export class CompetencyEdit implements ICompetencyEdit {
    competency: CompetencyEditItem;
    competencyTypes: IdTitle[];

    constructor();
    constructor(obj: ICompetencyEdit);
    constructor(obj?: any) {
        this.competency = null;
        if (obj && obj.competency) {
            this.competency = new CompetencyEditItem(obj.competency);
        }
        this.competencyTypes = Base.getTypedArray<IdTitle>(!Base.isNullOrUndefined(obj) ? obj.competencyTypes : null, IdTitle);
    }
}