import domtoimage from 'dom-to-image-more';
import jsPDF from "jspdf";

export const exportElementByIdToPdf = (elementId: string, parentElementId: string, title: string = "export") => {
    const element = document.getElementById(elementId);
    // set element max height to 100% to avoid scrollbars
    if (element) {
        element.style.maxHeight = "100%";
        element.style.maxWidth = "100%";
        element.style.overflow = "visible";
    }
    // set element parent max height to 100% to avoid scrollbars
    const parentElement = document.getElementById(parentElementId);
    if (parentElement) {
        parentElement.style.maxHeight = "100%";
    }

    const margin = 2;

    if (element) {
        const table = element.querySelector("table");

        if (table) {
            const scrollHeight = table.scrollHeight;

            // Set table to fit page
            const childElement = element.firstElementChild;
            childElement.setAttribute("style", "overflow: hidden");
            const ths = table.querySelectorAll("th");
            ths[0].style.width = "180px";
            ths[1].style.width = "4px";
            ths[2].style.width = "4px";
            table.style.zoom = "0.9";
            table.style.tableLayout = "fixed";
            ths.forEach(th => {
                th.style.whiteSpace = "normal";
            });
            const tds = table.querySelectorAll("td");
            tds.forEach(td => {
                td.style.whiteSpace = "normal";
            });
            const openLockIcons = table.querySelectorAll('[data-testid="LockOpenIcon"]');
            openLockIcons.forEach(lockIcon => {
                lockIcon.setAttribute("style", "visibility: hidden");
            });
            const lockIcons = table.querySelectorAll('[data-testid="LockIcon"]');
            lockIcons.forEach(lockIcon => {
                lockIcon.setAttribute("style", "visibility: hidden");
            });
            const commentIcons = table.querySelectorAll('[data-testid="MessageIcon"]');
            commentIcons.forEach(commentIcon => {
                commentIcon.setAttribute("style", "visibility: hidden");
            });
            const panel = table.getElementsByClassName("tool-panel");
            panel[0].setAttribute("style", "visibility: hidden");

            var options = {
                bgcolor: "white",
                quality: 0.95,
            };

            domtoimage
                .toJpeg(element, options)
                .then(function (dataUrl) {
                    const pdf = new jsPDF("l", "mm", "a4");

                    // Calculate image dimensions
                    const imgWidth = pdf.internal.pageSize.getWidth() - 2 * margin;
                    const imgHeight = (scrollHeight * imgWidth) / table.scrollWidth;

                    let position = 0;
                    const pageHeight = pdf.internal.pageSize.getHeight() - 2 * margin;

                    // Loop through the table rows and add page breaks
                    while (position < imgHeight) {
                        pdf.addImage(dataUrl, "PNG", margin, margin - position, imgWidth, 0, "", "FAST");
                        position += pageHeight;
                        if (position < imgHeight) {
                            pdf.addPage();
                        }
                    }

                    pdf.save(title + ".pdf");
                })
                .catch(function (error) {
                    console.error('Something went wrong!', error);
                })
                .finally(() => {
                    // revert element max height
                    if (element) {
                        element.style.maxHeight = "";
                        element.style.overflow = "";
                    }
                    // revert element parent max height
                    if (parentElement) {
                        parentElement.style.maxHeight = "";
                    }

                    // revert table changes
                    ths[0].style.removeProperty("width");
                    ths[1].style.removeProperty("width");
                    ths[2].style.removeProperty("width");
                    ths.forEach(th => {
                        th.style.removeProperty("white-space");
                    });
                    tds.forEach(td => {
                        td.style.removeProperty("white-space");
                    });
                    openLockIcons.forEach(lockIcon => {
                        lockIcon.removeAttribute("style");
                    });
                    lockIcons.forEach(lockIcon => {
                        lockIcon.removeAttribute("style");
                    });
                    commentIcons.forEach(commentIcon => {
                        commentIcon.removeAttribute("style");
                    });
                    panel[0].setAttribute("style", "visibility: visible");
                    childElement.removeAttribute("style");
                    table.style.removeProperty("zoom");
                    table.style.removeProperty("table-layout");
                });
        }
    }
};
