import dayjs from "dayjs";
import { PayloadAction, createAsyncThunk, createSlice, createSelector } from "@reduxjs/toolkit";
import { RootState } from "../framework/customStore";
import { InvoiceDetailsDto } from "../models/transportOrderInvoice/invoiceDetailsDto";
import { TransportOrderInvoiceState as InvoiceEnumState } from "../models/transportOrderInvoice/transportOrderInvoice";
import { InvoiceListItemDto, InvoiceListQueryParameters } from "../models/transportOrderInvoice/invoiceListItemDto";
import { getInvoice, getInvoices } from "../services/newInvoiceService";
import { showApiError } from "../components/framework/formUtils";
import { Translations } from "../models/translations";

export interface IInvoiceListFilters {
    displaySent: boolean;
    invoiceNumber: number | null;
    customerId: string;
    timeRange: {
        start: Date | null;
        end: Date | null;
    };
}

export interface TransportOrderInvoiceState {
    transportOrderInvoices: InvoiceListItemDto[];
    selectedInvoice?: InvoiceDetailsDto | null;
    filters: IInvoiceListFilters;
}

export const DEFAULT_TIMERANGE = 3;
export const DEFAULT_START = dayjs().startOf("date").subtract(DEFAULT_TIMERANGE, "months").toDate();
export const DEFAULT_END = dayjs().endOf("date").toDate();

const filtersInitial: IInvoiceListFilters = {
    displaySent: false,
    invoiceNumber: null,
    customerId: "",
    timeRange: {
        start: DEFAULT_START,
        end: DEFAULT_END
    }
};

const initialState: TransportOrderInvoiceState = {
    transportOrderInvoices: [],
    selectedInvoice: null,
    filters: filtersInitial
};

export const transportOrderInvoiceSlice = createSlice({
    name: "transportOrderInvoice",
    initialState,
    reducers: {
        setTransportOrderInvoices: (state, action: PayloadAction<InvoiceListItemDto[]>) => {
            state.transportOrderInvoices = action.payload;
        },
        setSelectedInvoice: (state, action: PayloadAction<InvoiceDetailsDto | null>) => {
            state.selectedInvoice = action.payload;
        },
        setFilterTimeRange: (state, action: PayloadAction< {start: Date | null; end: Date | null} >) => {
            state.filters.timeRange = action.payload;
        },
        setFilterDisplaySent: (state, action: PayloadAction<boolean>) => {
            state.filters.displaySent = action.payload;
        },
        setFilterCustomerId: (state, action: PayloadAction<string>) => {
            state.filters.customerId = action.payload;
        },
        setFilterInvoiceNumber: (state, action: PayloadAction<number>) => {
            state.filters.invoiceNumber = action.payload;
        }
    }
});

export const fetchTransportOrderInvoices = createAsyncThunk(
    "transportOrderInvoice/list",
    async(qsp: InvoiceListQueryParameters, thunkApi) => {
        try {
            const res = await getInvoices(qsp);
            if(res?.data) {
                thunkApi.dispatch(setTransportOrderInvoices(res.data));
                return res.data;
            }
        } catch(e) {
            showApiError(`${Translations.TransportOrderInvoicesFetchFail}`);
        }
    }
);

export const fetchTransportOrderInvoice = createAsyncThunk(
    "transportOrderInvoice/get",
    async(id: string, thunkApi) => {
        try {
            const res = await getInvoice(id);
            if(res?.data) {
                thunkApi.dispatch(setSelectedInvoice(res.data));
                return res.data;
            }
        } catch(e) {
            showApiError(`${Translations.TransportOrderInvoiceFetchFail}`);
        }
    }
);

export const selectEditEnabled = createSelector(
    [(state: RootState) => state.transportOrderInvoice.selectedInvoice],
    (selectedInvoice) => {
        return selectedInvoice?.state === InvoiceEnumState.NotApproved;
    }
);

export const {
    setTransportOrderInvoices,
    setSelectedInvoice,
    setFilterTimeRange,
    setFilterDisplaySent,
    setFilterCustomerId,
    setFilterInvoiceNumber
} = transportOrderInvoiceSlice.actions;

export const transportOrderInvoiceReducer = transportOrderInvoiceSlice.reducer;
