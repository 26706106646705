import Grid2 from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import { Translations } from "../../models/translations";
import { StorageState, updateStorageLocationsFilters } from "../../store/storageSlice";
import { useDebounce } from "../hooks/useDebounce";
import { MainLayout } from "../layout/mainLayout";
import { Alert, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { StorageLocationEditItem } from "../../models/storage/storageLocation";
import { StorageLocationDialog } from "./storageLocationsPage/storageLocationDialog";
import { StorageCustomerSelect } from "./components/storageCustomerSelect";
import { StorageSelect } from "./components/storageSelect";
import { STORAGE_SEARCH_DELAY } from "./storageUtils";
import { StorageLocationList, StorageTableData } from "./storages/storageLocationList";
import { getStorageLocation, getStorageLocationList } from "../../services/storageService";
import { extraFilterValue } from "./storageProductsMain";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";

export const StorageLocationsMain = () => {
    const [storageLocations, setStorageLocations] = useState<StorageTableData[]>([]);
    const [showStorageDialog, setShowStorageDialog] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<StorageLocationEditItem | null>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const state = useAppSelector(state => state.storage);
    const filters = state.storageLocationsTab.filters;
    const dispatch = useAppDispatch();

    const openCreateDialog = () => {
        setSelectedItem(new StorageLocationEditItem());
        setShowStorageDialog(true);
    };

    const handleShowDetails = (id: string) => {
        void getStorageLocation(id).then(item => {
            setSelectedItem(item);
            setShowStorageDialog(true);
        });
    };

    const handleSearch = useDebounce((filters: StorageState["storageLocationsTab"]["filters"]) => {
        store.customStore.dispatch(storeActions.fetchStart());
        getStorageLocationList({
            extraFilterObject: {
                storage: extraFilterValue(filters.selectedStorages),
                customer: extraFilterValue(filters.selectedStorageCustomers)
            }
        }).then(res => {
            setError(null);
            setStorageLocations(res);
        }).catch((err: string) => {
            setError(err);
        }).finally(() => {
            setLoading(false);
            store.customStore.dispatch(storeActions.fetchEnd());
        });
    }, STORAGE_SEARCH_DELAY);

    const handleClose = () => {
        setSelectedItem(null);
        setShowStorageDialog(false);
        handleSearch(filters);
    };

    const handleRemove = () => handleClose();

    useEffect(() => {
        setLoading(true);
        handleSearch(filters);
    }, [filters, handleSearch]);

    return (
        <MainLayout
            className="storage-locations"
            topComponent={<div>
                <Typography variant="h3">{Translations.SearchConditions}</Typography>
                <Grid2 container spacing={2}>
                    <Grid2>
                        <StorageSelect value={filters.selectedStorages} onChange={(val) => dispatch(updateStorageLocationsFilters({ selectedStorages: val }))}/>
                    </Grid2>
                    <Grid2>
                        <StorageCustomerSelect value={filters.selectedStorageCustomers} onChange={(val) => dispatch(updateStorageLocationsFilters({ selectedStorageCustomers: val }))}/>
                    </Grid2>
                </Grid2>
            </div>}
        >
            <>
                <Grid2 container justifyContent="space-between" mb={1}>
                    <Typography variant="h3">{Translations.StorageLocations}</Typography>

                    <span>
                        <Button
                            variant="save" startIcon={<AddIcon/>}
                            onClick={openCreateDialog}
                        >{Translations.CreateNewStorageLocation}
                        </Button>
                    </span>
                </Grid2>

                <StorageLocationList
                    onShowDetails={handleShowDetails}
                    storageLocations={storageLocations}
                />

                {error &&
                    <Alert severity="error">{Translations.FetchFailed}</Alert>
                }
                {(!loading && !error && storageLocations.length === 0) &&
                    <Alert severity="info">{Translations.NoMatchesFound}</Alert>
                }

                {showStorageDialog &&
                    <StorageLocationDialog
                        item={selectedItem}
                        open={showStorageDialog}
                        onClose={handleClose}
                        onRemove={handleRemove}
                    />
                }
            </>
        </MainLayout>
    );
};
