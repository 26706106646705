import * as CustomStore from "../../framework/customStore";
import * as StoreActions from "../store/storeActions";
import * as WorkOrderService from "../../services/workOrderService";
import * as BaseService from "../../services/baseService";
import { ConfirmationDialogType } from "../store/storeTypes";
import { Translations } from "../translations";
import { Base } from "../../framework/base";
import { WorkOrderEdit } from "./workOrderEdit";
import { IWorkOrderSetValuesData, WorkOrderSetValuesDialog } from "../../components/work/workOrderSetValuesDialog";

export class WorkOrderOperations {
    // #region Edit
    static getWorkOrderEdit(id: string, sourceId: string, category: number, parentId: string): Promise<WorkOrderEdit> {
        CustomStore.customStore.dispatch(StoreActions.fetchStart());
        return WorkOrderService.getWorkOrderEdit(id, sourceId, category, parentId)
            .then(editItem => {
                return editItem;
            })
            .catch(error => {
                CustomStore.customStore.dispatch(StoreActions.showErrorMessage(BaseService.getErrorMessageFromError(error)));
                return Base.getPromiseResult(null);
            })
            .finally(() => CustomStore.customStore.dispatch(StoreActions.fetchEnd()));
    }
    // #endregion Edit

    // #region Remove
    static removeWorkOrderSub(ids: string[]): Promise<boolean> {
        if (ids.length < 1) return Base.getPromiseResult(true);
        CustomStore.customStore.dispatch(StoreActions.fetchStart());
        return WorkOrderService.removeWorkOrder(ids)
            .then(success => {
                CustomStore.customStore.dispatch(StoreActions.showSuccessMessage(success.message));
                return Base.getPromiseResult(true);
            })
            .catch(error => {
                CustomStore.customStore.dispatch(StoreActions.showErrorMessage(BaseService.getErrorMessageFromError(error)));
                return Base.getPromiseResult(false);
            })
            .finally(() => CustomStore.customStore.dispatch(StoreActions.fetchEnd()));
    }

    static removeWorkOrder(isProject: boolean, ids: string[], number: number, hasChildren: boolean, acknowledgementSent: boolean, successCallback: () => void) {
        if (!ids || ids.length < 1) return;
        if (isProject && hasChildren) {
            CustomStore.customStore.dispatch(StoreActions.showInfoMessage(Translations.RemoveWorkOrderProjectTasksBeforeRemovingTheProject));
        } else {
            CustomStore.customStore.dispatch(StoreActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, ids.length < 2
                ? (String.format(isProject
                    ? Translations.WorkOrderProjectRemoveConfirmation
                    : Translations.WorkOrderRemoveConfirmation, number))
                : String.format(Translations.WorkOrdersRemoveConfirmation, ids.length), () => {
                CustomStore.customStore.dispatch(StoreActions.clearConfirmation());
                if (acknowledgementSent) {
                    CustomStore.customStore.dispatch(StoreActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, String.format(Translations.WorkOrderRemoveConfirmationAcknowledgementSent, number), () => {
                        CustomStore.customStore.dispatch(StoreActions.clearConfirmation());
                        WorkOrderOperations.removeWorkOrderSub(ids)
                            .then(success => {
                                if (success) {
                                    successCallback();
                                }
                            });
                    }, () => {
                        CustomStore.customStore.dispatch(StoreActions.clearConfirmation());
                    }));
                } else {
                    WorkOrderOperations.removeWorkOrderSub(ids)
                        .then(success => {
                            if (success) {
                                successCallback();
                            }
                        });
                }
            }, () => {
                CustomStore.customStore.dispatch(StoreActions.clearConfirmation());
            }));
        }
    }
    // #endregion Remove

    // #region Converting to and from Project
    private static convertToProjectSub(id: string): Promise<string> {
        CustomStore.customStore.dispatch(StoreActions.fetchStart());
        return WorkOrderService.convertWorkOrderToProject(id)
            .then(success => {
                CustomStore.customStore.dispatch(StoreActions.showSuccessMessage(success.message));
                return Base.getPromiseResult(success.id);
            })
            .catch(error => {
                CustomStore.customStore.dispatch(StoreActions.showErrorMessage(BaseService.getErrorMessageFromError(error)));
                return Base.getPromiseResult("");
            })
            .finally(() => CustomStore.customStore.dispatch(StoreActions.fetchEnd()));
    }

    static convertToProject(isWork: boolean, isNew: boolean, id: string, successCallback: (newId: string) => void) {
        if (!isWork || isNew || !id) return;
        CustomStore.customStore.dispatch(StoreActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.DoYouReallyWantToConvertWorkOrderWorkToWorkOrderProject,
            () => {
                CustomStore.customStore.dispatch(StoreActions.clearConfirmation());
                WorkOrderOperations.convertToProjectSub(id)
                    .then(newId => {
                        if (newId) {
                            successCallback(newId);
                        }
                    });
            },
            () => {
                CustomStore.customStore.dispatch(StoreActions.clearConfirmation());
            }));
    }

    private static detachFromProjectSub(parentId: string, ids: string[], removeParent: boolean): Promise<boolean> {
        CustomStore.customStore.dispatch(StoreActions.fetchStart());
        return WorkOrderService.detachWorkOrderFromProject(parentId, ids, false, removeParent)
            .then(success => {
                CustomStore.customStore.dispatch(StoreActions.showSuccessMessage(success.message));
                return Base.getPromiseResult(true);
            })
            .catch(error => {
                CustomStore.customStore.dispatch(StoreActions.showErrorMessage(BaseService.getErrorMessageFromError(error)));
                return Base.getPromiseResult(false);
            })
            .finally(() => CustomStore.customStore.dispatch(StoreActions.fetchEnd()));
    }

    static detachFromProject(isTask: boolean, isNew: boolean, parentId: string, id: string, parentTaskCount: number, successCallback: () => void) {
        if (!isTask || isNew || !id || !parentId) return;
        CustomStore.customStore.dispatch(StoreActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.WorkOrderChildDetachConfirmation,
            () => {
                CustomStore.customStore.dispatch(StoreActions.clearConfirmation());
                if (parentTaskCount === 1) {
                    CustomStore.customStore.dispatch(StoreActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.WorkOrderChildDetachRemoveConfirmation,
                        () => {
                            CustomStore.customStore.dispatch(StoreActions.clearConfirmation());
                            WorkOrderOperations.detachFromProjectSub(parentId, [id], true)
                                .then(success => {
                                    if (success) {
                                        successCallback();
                                    }
                                });
                        },
                        () => {
                            CustomStore.customStore.dispatch(StoreActions.clearConfirmation());
                            WorkOrderOperations.detachFromProjectSub(parentId, [id], false)
                                .then(success => {
                                    if (success) {
                                        successCallback();
                                    }
                                });
                        }));
                } else {
                    WorkOrderOperations.detachFromProjectSub(parentId, [id], false)
                        .then(success => {
                            if (success) {
                                successCallback();
                            }
                        });
                }
            },
            () => {
                CustomStore.customStore.dispatch(StoreActions.clearConfirmation());
            }));
    }
    // #endregion Converting to and from Project

    // #region Canceling
    private static cancelWorkOrderSub(id: string): Promise<boolean> {
        CustomStore.customStore.dispatch(StoreActions.fetchStart());
        return WorkOrderService.cancelWorkOrder(id)
            .then(success => {
                CustomStore.customStore.dispatch(StoreActions.showSuccessMessage(success.message));
                return Base.getPromiseResult(true);
            })
            .catch(error => {
                CustomStore.customStore.dispatch(StoreActions.showErrorMessage(BaseService.getErrorMessageFromError(error)));
                return Base.getPromiseResult(false);
            })
            .finally(() => CustomStore.customStore.dispatch(StoreActions.fetchEnd()));
    }

    private static restoreWorkOrderSub(id: string): Promise<boolean> {
        CustomStore.customStore.dispatch(StoreActions.fetchStart());
        return WorkOrderService.restoreWorkOrder(id)
            .then(success => {
                CustomStore.customStore.dispatch(StoreActions.showSuccessMessage(success.message));
                return Base.getPromiseResult(true);
            })
            .catch(error => {
                CustomStore.customStore.dispatch(StoreActions.showErrorMessage(BaseService.getErrorMessageFromError(error)));
                return Base.getPromiseResult(false);
            })
            .finally(() => CustomStore.customStore.dispatch(StoreActions.fetchEnd()));
    }

    static cancelWorkOrder(canceled: boolean, id: string, successCallback: () => void) {
        if (canceled || !id) return;
        CustomStore.customStore.dispatch(StoreActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.WorkOrderCancelConfirmation,
            () => {
                CustomStore.customStore.dispatch(StoreActions.clearConfirmation());
                WorkOrderOperations.cancelWorkOrderSub(id)
                    .then(success => {
                        if (success) {
                            successCallback();
                        }
                    });
            },
            () => {
                CustomStore.customStore.dispatch(StoreActions.clearConfirmation());
            }));
    }

    static restoreWorkOrder(canceled: boolean, id: string, successCallback: () => void) {
        if (!canceled || !id) return;
        CustomStore.customStore.dispatch(StoreActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.WorkOrderRestoreConfirmation,
            () => {
                CustomStore.customStore.dispatch(StoreActions.clearConfirmation());
                WorkOrderOperations.restoreWorkOrderSub(id)
                    .then(success => {
                        if (success) {
                            successCallback();
                        }
                    });
            },
            () => {
                CustomStore.customStore.dispatch(StoreActions.clearConfirmation());
            }));
    }
    // #endregion Canceling

    // #region SetState
    private static setWorkOrderStateSub(ids: string[], state: number): Promise<boolean> {
        CustomStore.customStore.dispatch(StoreActions.fetchStart());
        return WorkOrderService.setWorkOrderState(ids, state)
            .then(success => {
                CustomStore.customStore.dispatch(StoreActions.showSuccessMessage(success.message));
                return Base.getPromiseResult(true);
            })
            .catch(error => {
                CustomStore.customStore.dispatch(StoreActions.showErrorMessage(BaseService.getErrorMessageFromError(error)));
                return Base.getPromiseResult(false);
            })
            .finally(() => CustomStore.customStore.dispatch(StoreActions.fetchEnd()));
    }

    static setWorkOrderState(ids: string[], state: number, successCallback: () => void) {
        if (!ids || ids.length < 1) return;
        WorkOrderOperations.setWorkOrderStateSub(ids, state)
            .then(success => {
                if (success) {
                    successCallback();
                }
            });
    }
    // #endregion SetState

    // #region SetValues
    private static setWorkOrderValues(data: IWorkOrderSetValuesData): Promise<boolean> {
        return WorkOrderSetValuesDialog.setWorkOrderValues(data)
            .then(success => {
                return Base.getPromiseResult(success);
            });
    }
        
    static setWorkOrderDuration(ids: string[], estimatedHours: number, successCallback: () => void) {
        if (!ids || ids.length < 1) return;
        const data = WorkOrderSetValuesDialog.getWorkOrderSetValuesData(ids, null, null, null, true, null, null, null, estimatedHours);
        WorkOrderOperations.setWorkOrderValues(data)
            .then(success => {
                if (success) {
                    successCallback();
                }
            });
    }
    // #endregion SetValues

    // #region Print
    static printWorkOrder(id: string) {
        if (!id) return;
        CustomStore.customStore.dispatch(StoreActions.fetchStart());
        WorkOrderService.getWorkOrderPrint(id)
            .catch(error => {
                CustomStore.customStore.dispatch(StoreActions.showErrorMessage(BaseService.getErrorMessageFromError(error)));
            })
            .finally(() => CustomStore.customStore.dispatch(StoreActions.fetchEnd()));
    }
    // #endregion Print
}
