/* global JSX */
import * as React from "react";
import Datetime from "react-datetime";
import { Base } from "../../framework/base";
import { Translations } from "../../models/translations";
import moment from "moment";

// InlineDatePicker
// ***********************************************************************************************************************
export interface IInlineDatePickerProp {
    classes?: string;
    disabled?: boolean;
    value: string;
    rangeStartDate?: number;
    rangeEndDate?: number;
    onChange(value: string);
}

export class InlineDatePicker extends React.Component<IInlineDatePickerProp, {}> {
    handleChange = (value: moment.Moment | string) => {
        if (this.props.disabled) return;
        this.props.onChange(typeof value === "string"
            ? (value ?? "")
            : Base.timeToDateStr(value));
    };

    handleRenderDay = (props: any, currentDate: any): JSX.Element => {
        const date: Date = currentDate;
        const isRangeDate = currentDate && this.props.rangeStartDate && this.props.rangeEndDate ? currentDate.isBetween(moment(this.props.rangeStartDate), moment(this.props.rangeEndDate), undefined, "[)") : false;
        if (Base.isFirstDayOfWeekLocal(date)) {
            const { key, className, ...rest } = props;
            return <td className={className} key={key} data-wn="wnc"><div className="wn" onClick={(e) => { e.stopPropagation(); }}>{Base.getWeekNumber(date).toString(10)}</div><div {...rest} className="wnd" data-rngd={isRangeDate ? "rngd" : ""}>{currentDate.date()}</div></td>;
        } else {
            return <td {...props} data-rngd={isRangeDate ? "rngd" : ""}>{currentDate.date()}</td>;
        }
    };

    render() {
        const props = this.props;
        return (
            <div className={"datePicker" + (props.classes ? " " + props.classes : "")}>
                <Datetime
                    locale={appConfig.culture}
                    className={"roWhite"}
                    value={props.value}
                    dateFormat={"D.M.YYYY"}
                    input={false}
                    timeFormat={false}
                    onChange={this.handleChange}
                    renderDay={this.handleRenderDay}
                />
            </div>
        );
    }
}

// DatePicker
// ***********************************************************************************************************************
export interface IDatePickerProp {
    autoFocus?: boolean;
    classes?: string;
    disabled?: boolean;
    required?: boolean;
    title?: string | JSX.Element;
    titleClasses?: string;
    value: string;
    onChange(value: string);
    onBlur(value: string);
}

export class DatePicker extends React.Component<IDatePickerProp, {}> {
    handleChange = (value: moment.Moment | string) => {
        this.props.onChange(typeof value === "string"
            ? (value ?? "")
            : Base.timeToDateStr(value));
    };

    handleBlur = (value: moment.Moment | string) => {
        this.props.onBlur(Base.timeToDateStr(typeof value === "string"
            ? value.toDate().getTime()
            : value));
    };

    handleRenderDay = (props: any, currentDate: any): JSX.Element => {
        const date: Date = currentDate;
        if (Base.isFirstDayOfWeekLocal(date)) {
            const { key, className, ...rest } = props;
            return <td key={key} className={className} {...props} data-wn="wnc"><div className="wn" onClick={(e) => { e.stopPropagation(); }}>{Base.getWeekNumber(date).toString(10)}</div><div className="wnd" {...rest}>{currentDate.date()}</div></td>;
        } else {
            return <td {...props}>{currentDate.date()}</td>;
        }
    };

    render() {
        const props = this.props;
        return (
            <div className={"datePicker" + (props.classes ? " " + props.classes : "") + (props.disabled ? " disabled" : "")}>
                {props.title &&
                    <label className={"control-label" + (props.titleClasses ? " " + props.titleClasses : "")}>{props.title}</label>
                }
                {props.disabled &&
                    <div>{props.value}</div>
                }
                {!props.disabled &&
                    <Datetime
                        locale={appConfig.culture}
                        className={"roWhite"}
                        value={props.value}
                        dateFormat={"D.M.YYYY"}
                        timeFormat={false}
                        closeOnSelect={true}
                        inputProps={{ maxLength: 10, readOnly: props.disabled, disabled: props.disabled, autoFocus: props.autoFocus }}
                        onChange={this.handleChange}
                        onClose={this.handleBlur}
                        renderDay={this.handleRenderDay}
                        renderInput={(dtProps) => {
                            function clear() {
                                if (props.disabled) return;
                                dtProps.onChange({ target: { value: "" } });
                            }
                            return (
                                <div className="input-group">
                                    <input {...dtProps} />
                                    {!props.required &&
                                        <div className="input-group-append">
                                            <button className={"btn" + (props.disabled ? " disabled" : "")} type="button" title={Translations.Clear} onClick={clear}>
                                                <span className="icon close" />
                                            </button>
                                        </div>
                                    }
                                </div>
                            );
                        }}
                    />
                }
            </div>
        );
    }
}
