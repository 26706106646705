import React, { ChangeEvent, memo } from "react";
import { FormikConfig, getIn, useFormik } from "formik";
import * as store from "../../framework/customStore";
import * as StoreActions from "../../models/store/storeActions";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import { ConfirmationDialogType } from "../../models/store/storeTypes";

export const defaultFormikConfig: Partial<FormikConfig<any>> = {
    validateOnBlur: true,
    validateOnMount: true,
};

export interface FormikFieldProps {
    name: string;
    value: string | string[];
    onChange: (e: any) => void;
    onBlur: (e: any) => void;
    onFocus?: (e: ChangeEvent) => void;
    error: boolean;
    helperText?: string;
}
export interface FormikValidationProps {
    name: string;
    error: boolean;
    helperText: string;
    onBlur: (e: any) => void;
}

export type FormikForm<T = any> = ReturnType<typeof useFormik<T>>;

// Used for formik form fields with validations. If the field has no validation rules, set only "name" instead.
export const formikFieldProps = (formik: FormikForm, field: string): FormikFieldProps => ({
    name: field,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    value: getIn(formik.values, field),
    onChange: (val) => {
        if (val?.target) {
            // argument propably an event, let formik handle it.
            formik.handleChange(val);
        } else {
            void formik.setFieldValue(field, val);
        }
    },
    onBlur: formik.handleBlur,
    error: getIn(formik.touched, field) && Boolean(getIn(formik.errors, field)),
    helperText: getIn(formik.touched, field) && getIn(formik.errors, field) as string,
});

export const formikValidationProps = (formik: FormikForm, field: string): FormikValidationProps => ({
    name: field,
    error: getIn(formik.touched, field) && Boolean(getIn(formik.errors, field)),
    helperText: getIn(formik.touched, field) && getIn(formik.errors, field) as string,
    onBlur: formik.handleBlur
});

// Replace number surrounded by curly braces in string with corresponding value. Similar to string.Format in C#.
export const formatString = (text: string, ...replacingValues: (string | number)[]) => {
    replacingValues.forEach((value, i) => {
        text = text.replace(`{${i}}`, value.toString());
    });
    return text;
};

// Makes it easier to show error messages returned from APIs.
export const showApiError = (error) => {
    store.customStore.dispatch(StoreActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
};

export const showApiSuccess = (msg: string) => {
    store.customStore.dispatch(StoreActions.showSuccessMessage(msg));
};

export const showConfirm = (title: string, onYes: () => void, onNo?: () => void, onCancel?: () => void) => {
    store.customStore.dispatch(storeActions.setConfirmation(
        ConfirmationDialogType.Warning,
        title,
        null, // Text is always empty to keep this as simple as possible. Set title instead.
        onYes,
        onNo ?? null,
        onCancel ?? (() => null),
    ));
};

/**
 * Force a linebreak in a grid. Useful to setup rows in forms etc.
 */
export const GridBreak = memo(() => <div style={{ width: "100%" }} />);
