import { IEmployeeGroupEditItem, EmployeeGroupEditItem } from "./employeeGroupEditItem";
import { IIdTitle, IdTitle } from "../common/idTitle";
import { Base } from "../../framework/base";
import { ISalaryPeriodTypeItem, SalaryPeriodTypeItem } from "../salaryPeriodType/salaryPeriodTypeItem";
import { CollectiveAgreementItem, ICollectiveAgreementItem } from "../collectiveAgreement/collectiveAgreementItem";
import { WorkTimeType } from "../workShitTimeSlot/workTimeType";

export interface IEmployeeGroupEdit {
    employeeGroup: IEmployeeGroupEditItem;
    employments: IIdTitle[];
    salaryPeriodTypes: ISalaryPeriodTypeItem[];
    collectiveAgreements: ICollectiveAgreementItem[];
    workTimeTypes: WorkTimeType[];
}

export class EmployeeGroupEdit implements IEmployeeGroupEdit {
    employeeGroup: EmployeeGroupEditItem;
    employments: IdTitle[];
    salaryPeriodTypes: SalaryPeriodTypeItem[];
    collectiveAgreements: CollectiveAgreementItem[];
    workTimeTypes: WorkTimeType[];

    constructor();
    constructor(obj: IEmployeeGroupEdit);
    constructor(obj?: any) {
        this.employeeGroup = null;
        if (obj && obj.employeeGroup) {
            this.employeeGroup = new EmployeeGroupEditItem(obj.employeeGroup);
        }
        this.employments = Base.getTypedArray<IdTitle>(obj ? obj.employments : null, IdTitle);
        this.salaryPeriodTypes = Base.getTypedArray<SalaryPeriodTypeItem>(obj ? obj.salaryPeriodTypes : null, SalaryPeriodTypeItem);
        this.collectiveAgreements = Base.getTypedArray<CollectiveAgreementItem>(obj ? obj.collectiveAgreements : null, CollectiveAgreementItem);
        this.workTimeTypes = Base.getTypedArray<WorkTimeType>(obj ? obj.workTimeTypes : null, WorkTimeType);
    }
}
