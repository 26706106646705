import React, { useCallback, useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import {
    AdvancedMarker,
    AdvancedMarkerRef,
    useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { MapPointProps } from "./map";

interface MarkerChipProps {
    point: MapPointProps;
    onSelect: (id: string) => void;
    setMarkerRef?: (m: AdvancedMarkerRef | null) => void;
    className?: string;
}

export const MarkerChip = ({
    point,
    setMarkerRef,
    onSelect,
    className = "",
}: MarkerChipProps) => {
    const [hovered, setHovered] = useState(false);

    const {
        coords,
        icon,
        text,
        fullText,
        textColorClass,
        textBgColorClass,
        id,
    } = point;

    const onClick = useCallback(() => {
        if (typeof onSelect === "function") {
            onSelect(id);
        }
    }, [onSelect, id]);

    return (
        <AdvancedMarker
            position={coords}
            ref={setMarkerRef}
            onClick={onClick}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <Chip
                data-id={id}
                icon={icon}
                label={hovered ? fullText ?? text : text}
                className={[
                    "marker-tag",
                    !!textColorClass ? `text-${textColorClass}` : null,
                    !!textBgColorClass ? `bg-${textBgColorClass}` : null,
                    className,
                ]
                    .filter(Boolean)
                    .join(" ")}
            />
        </AdvancedMarker>
    );
};

interface ClusteredMarkerChipOwnProps {
    clusterer: MarkerClusterer;
}

type ClusteredMarkerChipProps = ClusteredMarkerChipOwnProps & MarkerChipProps;

export const ClusteredMarkerChip = ({
    clusterer,
    ...rest
}: ClusteredMarkerChipProps) => {
    const [markerRef, marker] = useAdvancedMarkerRef();

    useEffect(() => {
        if (!marker) return;

        clusterer?.addMarker(marker);

        return () => {
            clusterer?.removeMarker(marker);
        };
    }, [marker, clusterer]);

    return <MarkerChip setMarkerRef={markerRef} {...rest} />;
};
