import downloadjs from "downloadjs";
import * as React from "react";
import { Translations } from "../../models/translations";
import { ToolButton } from "../framework/toolButton";

export interface IDocumentPreviewProp {
    classes?: string;
    file: string;
    fileName: string;
    fileMimeType: string;
    thumbnails: string[];
    thumbnailIndex: number;
}

interface IDocumentPreviewState {
    thumbnailIndex: number;
}

export class DocumentPreview extends React.Component<IDocumentPreviewProp, IDocumentPreviewState> {
    constructor(props) {
        super(props);
        this.state = {
            thumbnailIndex: props.thumbnailIndex
        };
    }

    componentDidUpdate(prevProps: IDocumentPreviewProp, prevState: IDocumentPreviewState): void {
        const props = this.props;
        if (prevProps.thumbnailIndex === this.props.thumbnailIndex) return;
        this.setState({
            thumbnailIndex: props.thumbnailIndex
        });
    }

    handlePreviousPrintPage = () => {
        const props = this.props;
        const state = this.state;
        if (props.thumbnails.length < 1 || state.thumbnailIndex < 1) return;
        this.setState({
            thumbnailIndex: state.thumbnailIndex - 1,
        });
    };

    handleNextPrintPage = () => {
        const props = this.props;
        const state = this.state;
        if (props.thumbnails.length < 1 || state.thumbnailIndex >= props.thumbnails.length - 1) return;
        this.setState({
            thumbnailIndex: state.thumbnailIndex + 1,
        });
    };

    handleDownloadPrint = () => {
        const props = this.props;
        if (!props.file) return;
        downloadjs("data:" + props.fileMimeType + ";base64," + props.file, props.fileName, props.fileMimeType);
    };

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <div className={"documentPreview " + (props.classes ? " " + props.classes : "")}>
                <div className="preview">
                    {(props.thumbnails && props.thumbnails.length > 0) &&
                        <img src={props.thumbnails[0]} />
                    }
                </div>
                <div className="buttonbar">
                    {(props.thumbnails && props.thumbnails.length > 0) &&
                        <div className="buttons">
                            <ToolButton
                                title={Translations.Previous}
                                enabled={props.thumbnails.length > 1 && state.thumbnailIndex > 0}
                                classes={"round left previous"}
                                onClick={this.handlePreviousPrintPage}
                            />
                            <ToolButton
                                title={Translations.Next}
                                enabled={props.thumbnails.length > 1 && state.thumbnailIndex < props.thumbnails.length - 1}
                                classes={"round right next"}
                                onClick={this.handleNextPrintPage}
                            />
                            <ToolButton
                                title={Translations.Download}
                                enabled={!!props.file}
                                classes={"round center print"}
                                onClick={this.handleDownloadPrint}
                            />
                        </div>
                    }
                </div>
            </div>
        );
    }
}
