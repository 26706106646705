// InvoiceDialogSelectWorkOrderDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as store from "../../framework/customStore";
import * as StoreActions from "../../models/store/storeActions";
import * as workOrderService from "../../services/workOrderService";
import { Translations } from "../../models/translations";
import { PropertyDialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { ListHeaderColumn, ListHeaderCheckBoxColumn } from "../framework/listHeaderColumn";
import { IWorkOrderListItem, WorkOrderListItem } from "../../models/work/workOrderListItem";
import { IIdTitle } from "../../models/common/idTitle";
import { infiniteListPageSize } from "../../models/common/consts";
import { handleApiError } from "../../models/store/storeEffects";
import { WorkOrderState } from "../../models/common/enums";

// InvoiceDialogSelectWorkOrderDialogListHeader
export interface IInvoiceDialogSelectWorkOrderDialogListHeaderProp {
    multiSelect: boolean;
    sortColumn: string;
    sortOrderIsAsc: boolean;
    onColumnClick: (column: string) => void;
    onColumnCheckboxChange: (column: string, checked: boolean) => void;
}

export class InvoiceDialogSelectWorkOrderDialogListHeader extends React.Component<IInvoiceDialogSelectWorkOrderDialogListHeaderProp, {}> {
    handleColumnClick = (column: string) => {
        this.props.onColumnClick(column);
    };

    handleColumnCheckboxChange = (column: string, checked: boolean) => {
        this.props.onColumnCheckboxChange(column, checked);
    };

    render() {
        const props = this.props;
        return (
            <div className="row title">
                {props.multiSelect &&
                    <ListHeaderCheckBoxColumn
                        key="number"
                        title={Translations.AbrNumber}
                        column="number"
                        classes="col-1"
                        sortColumn={props.sortColumn}
                        sortOrderIsAsc={props.sortOrderIsAsc}
                        onClick={this.handleColumnClick}
                        onCheckboxChange={this.handleColumnCheckboxChange}
                    />
                }
                {!props.multiSelect &&
                    <ListHeaderColumn
                        title={Translations.AbrNumber}
                        column="number"
                        classes="col-1"
                        sortColumn={props.sortColumn}
                        sortOrderIsAsc={props.sortOrderIsAsc}
                        onClick={this.handleColumnClick}
                    />
                }
                <ListHeaderColumn
                    title={Translations.State}
                    column="state"
                    classes="col-2"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.StartDate}
                    column="startTime"
                    classes="col-2"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.EndDate}
                    column="endTime"
                    classes="col-2"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Name}
                    column="name"
                    classes="col-3"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Site}
                    column="siteName"
                    classes="col-2"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
            </div>
        );
    }
}

// InvoiceDialogSelectWorkOrderDialogListLine
export interface IInvoiceDialogSelectWorkOrderDialogListLineProp {
    checked: boolean;
    item: IWorkOrderListItem;
    multiSelect: boolean;
    selectedId: string;
    onClick: (id: string) => void;
    onCheckboxChange: (id: string, checked: boolean) => void;
}

export class InvoiceDialogSelectWorkOrderDialogListLine extends React.Component<IInvoiceDialogSelectWorkOrderDialogListLineProp, {}> {
    handleCheckBoxClick = (e) => {
        e.stopPropagation();
        this.props.onCheckboxChange(this.props.item.id, !this.props.checked);
    };

    render() {
        const props = this.props;
        const item = props.item;
        return (
            <div className={"row line" + (" state" + item.state) + (item.id === props.selectedId ? " selected" : "")} onClick={() => { props.onClick(item.id); }} >
                <div className="col-1">
                    {props.multiSelect &&
                        <input type="checkbox" checked={props.checked} onClick={this.handleCheckBoxClick} value="" />
                    }
                    {item.number.toString(10) + (item.isTask() ? " (" + item.parentNumber.toString(10) + ")" : "")}
                </div>
                <div className="col-2">{item.stateStr}</div>
                <div className="col-2">{Base.utcTimeToDateStr(item.startTime)}</div>
                <div className="col-2">{Base.utcTimeToDateStr(item.endTime)}</div>
                <div className="col-3">{item.name}</div>
                <div className="col-2">{item.siteName}</div>
            </div>
        );
    }
}

// InvoiceDialogSelectWorkOrderDialog
export interface IInvoiceDialogSelectWorkOrderDialogProp {
    classes?: string;
    ignoreWorkOrderIds?: string[];
    multiSelect?: boolean;
    customerId: string;
    customerName: string;
    workOrderTypeId: string;
    startDate?: number;
    endDate?: number;
    onOk: (workOrders: IWorkOrderListItem[]) => void;
    onCancel: () => void;
}

export interface IInvoiceDialogSelectWorkOrderDialogState {
    isLoading: boolean;
    pageSize: number;
    page: number;
    hasMore: boolean;
    filter: string;
    sortColumn: string;
    sortOrderIsAsc: boolean;
    items: IWorkOrderListItem[];
    workOrderStates: IIdTitle[];
    state: number;
    workOrderTypes: IIdTitle[];
    workOrderTypeId: string;
    selectedId: string;
    checkedIds: string[];
}

export class InvoiceDialogSelectWorkOrderDialog extends React.Component<IInvoiceDialogSelectWorkOrderDialogProp, IInvoiceDialogSelectWorkOrderDialogState> {
    private containerDiv: HTMLDivElement;
    private listDiv: HTMLDivElement;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            pageSize: infiniteListPageSize,
            page: 1,
            hasMore: false,
            filter: props.filter ? props.filter : "",
            sortColumn: "number",
            sortOrderIsAsc: true,
            items: [],
            workOrderStates: [],
            state: WorkOrderState.Checked,
            workOrderTypes: [],
            workOrderTypeId: props.workOrderTypeId ? props.workOrderTypeId : "",
            selectedId: null,
            checkedIds: []
        };
    }

    searchItems = async(pageSize: number, page: number, filter: string, workOrderState: number, workOrderTypeId: string, sortColumn: string, sortOrderIsAsc: boolean, resetItems: boolean, refreshList: boolean): Promise<void> => {
        const props = this.props;
        const state = this.state;
        this.setState({ isLoading: true });
        store.customStore.dispatch(StoreActions.fetchStart());
        try {
            const dbItems = await workOrderService.getWorkOrderListItems(!refreshList ? pageSize : (page * infiniteListPageSize), !refreshList ? page : 1, filter, [workOrderState], [],
                sortColumn, sortOrderIsAsc, null, props.ignoreWorkOrderIds, [workOrderTypeId], props.startDate, props.endDate, false, props.customerId, false);
            const items = Base.getListItems<WorkOrderListItem>(state.items, state.selectedId, state.checkedIds, dbItems.items, resetItems, refreshList);
            this.setState({
                page: !refreshList ? dbItems.page : page,
                hasMore: dbItems.hasMore,
                filter: filter,
                sortColumn: sortColumn,
                sortOrderIsAsc: sortOrderIsAsc,
                items: items.items,
                state: workOrderState,
                workOrderTypeId: workOrderTypeId,
                workOrderStates: dbItems.workOrderStates,
                workOrderTypes: dbItems.workOrderTypes,
                selectedId: items.items.findIndex(i => i.id === state.selectedId) > -1 ? state.selectedId : "",
                checkedIds: items.items.filter(i => state.checkedIds.indexOf(i.id) > -1).map(i => i.id)
            });
        } catch (e) {
            handleApiError(e, store.customStore.dispatch);
        } finally {
            store.customStore.dispatch(StoreActions.fetchEnd());
            this.setState({ isLoading: false });
        }
    };

    refreshList = async(): Promise<void> => {
        const state = this.state;
        await this.searchItems(state.pageSize, state.page, state.filter, state.state, state.workOrderTypeId, state.sortColumn, state.sortOrderIsAsc, false, true);
    };

    handleScrollSub = Base.debounce((obj: InvoiceDialogSelectWorkOrderDialog) => {
        const state = obj.state;
        if (state.isLoading || !state.hasMore) return;
        if (obj.listDiv.offsetHeight - (obj.containerDiv.clientHeight + obj.containerDiv.scrollTop) < 5) {
            obj.searchItems(state.pageSize, state.page + 1, state.filter, state.state, state.workOrderTypeId, state.sortColumn, state.sortOrderIsAsc, false, false);
        }
    }, 100);

    handleScroll = (event) => {
        this.handleScrollSub(this);
    };

    componentDidMount(): void {
        const state = this.state;
        this.containerDiv.addEventListener("scroll", this.handleScroll);
        this.searchItems(state.pageSize, state.page, state.filter, state.state, state.workOrderTypeId, state.sortColumn, state.sortOrderIsAsc, false, false);
    }

    componentWillUnmount(): void {
        this.containerDiv.removeEventListener("scroll", this.handleScroll);
    }

    changeSortColumn = (sortColumn: string) => {
        const state = this.state;
        const oldSortColumn = state.sortColumn;
        const sortOrderIsAsc = oldSortColumn === sortColumn ? !state.sortOrderIsAsc : true;
        this.searchItems(state.pageSize, 1, state.filter, state.state, state.workOrderTypeId, sortColumn, sortOrderIsAsc, true, false);
    };

    handleChange = (event) => {
        const state = this.state;
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "state") {
            const newState = value.toInteger(true, -1);
            this.setState({ state: newState });
            this.searchItems(state.pageSize, state.page, state.filter, newState, state.workOrderTypeId, state.sortColumn, state.sortOrderIsAsc, true, false);
        } else if (name === "workOrderTypeId") {
            this.setState({ workOrderTypeId: value });
            this.searchItems(state.pageSize, state.page, state.filter, state.state, value, state.sortColumn, state.sortOrderIsAsc, true, false);
        }
    };

    handleColumnCheckboxChange = (column: string, checked: boolean) => {
        const result = Base.getIdsOnColumnCheckboxChange(this.state.items, checked);
        this.setState({ selectedId: result.selectedId, checkedIds: result.checkedIds });
    };

    handleLineCheckboxChange = (id: string, checked: boolean) => {
        const result = Base.getIdsOnLineCheckboxChange(this.state.selectedId, this.state.checkedIds, id, checked);
        this.setState({ selectedId: result.selectedId, checkedIds: result.checkedIds });
    };

    handleClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleOkClick = () => {
        const state = this.state;
        const selectedIds = Base.getSelectedIds(state.selectedId, state.checkedIds);
        if (selectedIds.length < 1) return;
        const workOrders = state.items.filter(i => selectedIds.indexOf(i.id) > -1);
        if (workOrders.length < 1) return;
        this.props.onOk(workOrders);
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "selectWorkOrder px1000" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.AddWorkOrdersToInvoice}
                    show={true}
                    body={
                        <div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.Customer}</label>
                                        <div>{props.customerName}</div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.WorkOrderType}</label>
                                        <select className="custom-select" name="workOrderTypeId" title={Translations.WorkOrderType} value={state.workOrderTypeId} onChange={this.handleChange}>
                                            {state.workOrderTypes.map((workOrderType) =>
                                                <option key={workOrderType.id} value={workOrderType.id}>{workOrderType.title}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.State}</label>
                                        <select className="custom-select" name="state" title={Translations.State} value={state.state} onChange={this.handleChange}>
                                            {state.workOrderStates.map((workOrderState) =>
                                                <option key={workOrderState.id} value={workOrderState.id}>{workOrderState.title}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <InvoiceDialogSelectWorkOrderDialogListHeader
                                    multiSelect={props.multiSelect}
                                    sortColumn={state.sortColumn}
                                    sortOrderIsAsc={state.sortOrderIsAsc}
                                    onColumnClick={this.changeSortColumn}
                                    onColumnCheckboxChange={this.handleColumnCheckboxChange}
                                />
                            </div>
                            <div className="listContainer workOrdersContainer">
                                <div className="list workOrders striped" ref={(elem) => { this.containerDiv = elem; }}>
                                    <div className="lineContainer" ref={(elem) => { this.listDiv = elem; }}>
                                        {state.items.map((item) =>
                                            <InvoiceDialogSelectWorkOrderDialogListLine
                                                key={item.id}
                                                checked={state.checkedIds.indexOf(item.id) > -1}
                                                item={item}
                                                multiSelect={props.multiSelect}
                                                selectedId={state.selectedId}
                                                onClick={this.handleClick}
                                                onCheckboxChange={this.handleLineCheckboxChange}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    buttons={[
                        { title: Translations.OK, classes: "btn-primary", enabled: Base.getSelectedIds(state.selectedId, state.checkedIds).length > 0, onClick: this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}