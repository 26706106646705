// WorkOrderDialogWorkTimeList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "../../framework/base";
import { AppUtils } from "../../models/common/appUtils";
import { IWorkTimeListItem } from "../../models/workTime/workTimeListItem";
import { TimeFormat } from "../../models/common/enums";

// WorkOrderDialogWorkTimeListLine
export interface IWorkOrderDialogHourBookingListLineProp {
    workTimeFormat: TimeFormat;
    workTime: IWorkTimeListItem;
    selectedId: string;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

export class WorkOrderDialogHourBookingListLine extends React.Component<IWorkOrderDialogHourBookingListLineProp, {}> {
    render() {
        const props = this.props;
        const item = props.workTime;
        return (
            <div className={"row line" + (item.id === props.selectedId ? " selected" : "")} onClick={() => { props.onClick(item.id); }} onDoubleClick={() => { this.props.onDoubleClick(item.id); }}>
                <div className="col-2">{Base.timeToDateTimeStr(item.startTime)}</div>
                <div className="col-2">{Base.timeToDateTimeStr(item.endTime)}</div>
                <div className="col-3">{item.employeeName}</div>
                <div className="col-2">{item.getTaskName()}</div>
                <div className="col-2">{item.stateStr}</div>
                <div className="col-1 ra">{AppUtils.getWorkTimeStr(props.workTimeFormat, item.getDurationMin() / 60)}</div>
            </div>
        );
    }
}

// WorkOrderDialogWorkTimeList
export interface IWorkOrderDialogWorkTimeListProp {
    workTimeFormat: TimeFormat;
    title?: string;
    titleId?: string;
    titleClass?: string;
    workTimes: IWorkTimeListItem[];
}

interface IWorkOrderDialogWorkTimeListState {
    selectedId: string;
}

export class WorkOrderDialogWorkTimeList extends React.Component<IWorkOrderDialogWorkTimeListProp, IWorkOrderDialogWorkTimeListState> {
    constructor(props) {
        super(props);
        this.state = { selectedId: null };
    }

    handleLineClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleLineDoubleClick = (id: string) => {
    };

    render() {
        const props = this.props;
        const state = this.state;
        const workTimes = props.workTimes;
        return (
            <div className="workOrderSubList">
                <div className="commandRow">
                    {!!props.title &&
                        <label id={props.titleId} className={"control-label listTitle" + (props.titleClass ? " " + props.titleClass : "")}>{props.title}</label>
                    }
                </div>
                <div className="listContainer workTimesContainer">
                    {workTimes.length > 0 &&
                        <div className="list workTimes">
                            {workTimes.map((workTime) =>
                                <WorkOrderDialogHourBookingListLine
                                    key={workTime.id}
                                    workTimeFormat={props.workTimeFormat}
                                    workTime={workTime}
                                    selectedId={state.selectedId}
                                    onClick={this.handleLineClick}
                                    onDoubleClick={this.handleLineDoubleClick}
                                />
                            )}
                        </div>
                    }
                </div>
            </div>
        );
    }
}