import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { MainLayout } from "../../layout/mainLayout";
import { Translations } from "../../../models/translations";
import { Button } from "@mui/material";
import { customHistory } from "../../../framework/customHistory";
import { TransportOrderInvoiceList } from "./TransportOrderInvoiceList";
import { InvoiceListFilters } from "./InvoiceListFilters";

export const TransportOrderInvoicesMain = () => {

    return (
        <MainLayout topComponent={
            <Grid2 container style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Grid2>
                    <InvoiceListFilters />
                </Grid2>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => customHistory.push(
                        "/invoicingbeta/transportorderinvoice/new",
                        { from: "/invoicingbeta/transportorderinvoices" }
                    )}
                >
                    {Translations.CreateNewInvoice}
                </Button>
            </Grid2>
        }
        >
            <TransportOrderInvoiceList />
        </MainLayout>
    );
};
