import { getApiCall, postFormApiCall, postApiCall } from "./tokenService";
import { CostCenterItems } from "../models/costCenter/costCenterItems";
import { CostCenterEdit } from "../models/costCenter/costCenterEdit";
import { SaveSuccess, ApiSuccess } from "./baseService";

export const getCostCenterItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean, showNotInUse: boolean): Promise<CostCenterItems> => {
    return getApiCall<CostCenterItems>("api/costcenter/?page=" + (page || "") + "&pageSize=" + (pageSize || "") +
        "&sortColumn=" + (sortColumn ? encodeURIComponent(sortColumn) : "") + "&sortOrder=" + (sortOrderIsAsc ? "asc" : "desc") +
        "&filter=" + (filter ? encodeURIComponent(filter) : "") + "&showNotInUse=" + (showNotInUse ? "1" : "0"), CostCenterItems);
};

export const getCostCenterEdit = (id: string): Promise<CostCenterEdit> => {
    return getApiCall<CostCenterEdit>("api/costcenter/edit/?id=" + (id || ""), CostCenterEdit);
};

export const saveCostCenterEdit = (data: FormData): Promise<SaveSuccess> => {
    return postFormApiCall<SaveSuccess>("api/costcenter/save", data, SaveSuccess);
};

export const removeCostCenter = (id: string): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/costcenter/remove/?id=" + (id || ""), ApiSuccess);
};