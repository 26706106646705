// SettingsCustomerDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "../../framework/base";
import * as store from "../../framework/customStore";
import { SaveData } from "../../framework/saveData";
import { ICustomerEdit } from "../../models/customer/customerEdit";
import { CustomerEditItemCustomerContactItem, ICustomerEditItemCustomerContactItem } from "../../models/customer/customerEditItemCustomerContactItem";
import { CustomerEditItemSaveSiteItem, CustomerEditItemSiteItem, ICustomerEditItemSiteItem } from "../../models/customer/customerEditItemSiteItem";
import { CustomerItem } from "../../models/customer/customerItem";
import { CustomerPriceEditItem, ICustomerPriceEditItem, SaveCustomerPriceEditItem } from "../../models/customerPrice/customerPriceEditItem";
import { IRoutePointItem, SaveRoutePointItem } from "../../models/routePoint/routePointItem";
import { SaveRoutePointCheckEditItem } from "../../models/routePointCheck/routePointCheckEditItem";
import * as baseService from "../../services/baseService";
import * as customerService from "../../services/customerService";
import * as storeActions from "../../models/store/storeActions";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { Translations } from "../../models/translations";
import { CheckBox } from "../framework/checkbox";
import { PropertyDialog } from "../framework/dialog";
import { ImageSelector } from "../framework/imageSelector";
import { SettingsCustomerDialogContactList } from "./settingsCustomerDialogContactList";
import { SettingsCustomerPriceList } from "./settingsCustomerPriceList";
import { SettingsCustomerDialogSiteList } from "./settingsCustomerDialogSiteList";
import { IProductItem } from "../../models/product/productItem";
import { UserParameters } from "../../models/employee/userParameters";

// SettingsCustomerDialog
// ***********************************************************************************************************************
export interface ISettingsCustomerDialogProp {
    classes?: string;
    sitesReadOnly?: boolean;
    contactsReadOnly?: boolean;
    customerPricesReadOnly?: boolean;
    customerEdit: ICustomerEdit;
    onOk: (customerId: string, name: string) => void;
    onCancel: () => void;
}

export interface ISettingsCustomerDialogState {
    customerTypeId: string;
    name: string;
    erp: string;
    workOrderAcknowledgementTypeId: string;
    activeState: number;
    logo: string;
    logoFile: File;
    invoiceStreet: string;
    invoicePostalCode: string;
    invoiceCity: string;
    invoiceCountryCode: string;
    invoicePeriodId: string;
    invoiceEmail: string;
    eInvoiceAddress: string;
    eInvoiceOperator: string;
    businessIdentityCode: string;
    vatCode: string;
    sites: ICustomerEditItemSiteItem[];
    removedSiteIds: string[];
    savedSiteIds: string[];
    customerContacts: ICustomerEditItemCustomerContactItem[];
    removedCustomerContactIds: string[];
    savedCustomerContactIds: string[];
    removedRoutePointIds: string[];
    savedSiteRoutePointIds: Map<string, string[]>; //SiteId => value
    removedRoutePointCheckIds: string[];
    savedRoutePointCheckIds: Map<string, Map<string, string[]>>; //SiteId => RoutePointId => value
    customerPrices: ICustomerPriceEditItem[];
    availableCustomerPriceProducts: IProductItem[];
    usedCustomerPriceProducts: IProductItem[];
    removedCustomerPriceIds: string[];
    savedCustomerPriceIds: string[];
}

export class SettingsCustomerDialog extends React.Component<ISettingsCustomerDialogProp, ISettingsCustomerDialogState> {
    private static orgStateHash: string = "";

    getCustomerPriceProducts = (customerPrices: ICustomerPriceEditItem[], allProducts: IProductItem[]): { availableProducts: IProductItem[]; usedProducts: IProductItem[] } => {
        const customerPriceProductIds = customerPrices.map(i => i.productId);
        return {
            availableProducts: allProducts.filter(i => customerPriceProductIds.indexOf(i.id) < 0),
            usedProducts: allProducts.filter(i => customerPriceProductIds.indexOf(i.id) > -1)
        };
    };

    constructor(props: ISettingsCustomerDialogProp) {
        super(props);
        const customer = props.customerEdit.customer;
        const routePoints = new Map<string, IRoutePointItem[]>();
        for (let i = 0; i < customer.sites.length; i++) {
            const site = customer.sites[i];
            routePoints.set(site.id, site.routePoints.slice(0));
        }
        const customerPriceProducts = this.getCustomerPriceProducts(customer.customerPrices, props.customerEdit.products);
        this.state = {
            customerTypeId: customer.customerTypeId,
            name: customer.name,
            erp: customer.erp,
            activeState: customer.activeState,
            logo: customer.logo,
            logoFile: null,
            invoiceStreet: customer.invoiceStreet,
            invoicePostalCode: customer.invoicePostalCode,
            invoiceCity: customer.invoiceCity,
            invoiceCountryCode: customer.invoiceCountryCode,
            invoicePeriodId: customer.invoicePeriodId,
            invoiceEmail: customer.invoiceEmail,
            eInvoiceAddress: customer.eInvoiceAddress,
            eInvoiceOperator: customer.eInvoiceOperator,
            businessIdentityCode: customer.businessIdentityCode,
            vatCode: customer.vatCode,
            workOrderAcknowledgementTypeId: customer.workOrderAcknowledgementTypeId,
            sites: customer.sites.slice(0),
            removedSiteIds: [],
            savedSiteIds: [],
            customerContacts: customer.customerContacts.slice(0),
            removedCustomerContactIds: [],
            savedCustomerContactIds: [],
            removedRoutePointIds: [],
            savedSiteRoutePointIds: new Map<string, string[]>(),
            removedRoutePointCheckIds: [],
            savedRoutePointCheckIds: new Map<string, Map<string, string[]>>(),
            customerPrices: customer.customerPrices.slice(0),
            availableCustomerPriceProducts: customerPriceProducts.availableProducts,
            usedCustomerPriceProducts: customerPriceProducts.usedProducts,
            removedCustomerPriceIds: [],
            savedCustomerPriceIds: [],
        };
        const saveData = SettingsCustomerDialog.getSaveDataFromState(props, this.state);
        SettingsCustomerDialog.orgStateHash = saveData.hash;
    }

    // #region General
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "Nm") {
            this.setState({ name: value });
        } else if (name === "customerTypeId") {
            this.setState({ customerTypeId: value });
        } else if (name === "erp") {
            this.setState({ erp: value });
        } else if (name === "workOrderAcknowledgementType") {
            this.setState({ workOrderAcknowledgementTypeId: value });
        } else if (name === "invoiceAds") {
            this.setState({ invoiceStreet: value });
        } else if (name === "invoicepC") {
            this.setState({ invoicePostalCode: value });
        } else if (name === "invoiceCty") {
            this.setState({ invoiceCity: value });
        } else if (name === "invoiceCc") {
            this.setState({ invoiceCountryCode: value });
        } else if (name === "invoicePeriodId") {
            this.setState({ invoicePeriodId: value });
        } else if (name === "invoiceEmail") {
            this.setState({ invoiceEmail: value });
        } else if (name === "eInvoiceAddress") {
            this.setState({ eInvoiceAddress: value });
        } else if (name === "eInvoiceOperator") {
            this.setState({ eInvoiceOperator: value });
        } else if (name === "businessIdentityCode") {
            this.setState({ businessIdentityCode: value });
        } else if (name === "vatCode") {
            this.setState({ vatCode: value });
        }
    };

    handleActiveStateChange = (newActiveState: number) => {
        this.setState({ activeState: newActiveState });
    };

    handleSetImage = (file: File) => {
        if (Base.isNullOrUndefined(file)) return;
        const obj = this;
        Base.blobToBase64(file).then(base64Image => {
            obj.setState({
                logoFile: file,
                logo: base64Image
            });
        });
    };
    // #endregion General

    // #region Sites
    handleSiteSave = (item: ICustomerEditItemSiteItem, removedRoutePointIds: string[], savedRoutePointIds: string[], removedRoutePointCheckIds: string[], savedRoutePointCheckIds: Map<string, string[]>) => {
        const state = this.state;
        if (!item || !item.id) return;
        //Site
        const sites = state.sites.filter(i => i.id !== item.id);
        const savedSiteIds = this.state.savedSiteIds.filter(i => i !== item.id);
        sites.push(item);
        savedSiteIds.push(item.id);
        CustomerEditItemSiteItem.sortCustomerEditItemSiteItems(sites, true);
        //RoutePoints
        const newSavedRoutePointIds = new Map<string, string[]>(state.savedSiteRoutePointIds);
        newSavedRoutePointIds.set(item.id, savedRoutePointIds);
        const newRemovedRoutePointIds = state.removedRoutePointIds.filter(i => removedRoutePointIds.indexOf(i) < 0);
        //RoutePointChecks
        const newSavedRoutePointCheckIds = new Map<string, Map<string, string[]>>(state.savedRoutePointCheckIds);
        newSavedRoutePointCheckIds.set(item.id, savedRoutePointCheckIds);
        const newRemovedRoutePointCheckIds = state.removedRoutePointCheckIds.filter(i => removedRoutePointCheckIds.indexOf(i) < 0);
        this.setState({
            sites: sites,
            savedSiteIds: savedSiteIds,
            savedSiteRoutePointIds: newSavedRoutePointIds,
            removedRoutePointIds: newRemovedRoutePointIds.concat(removedRoutePointIds),
            savedRoutePointCheckIds: newSavedRoutePointCheckIds,
            removedRoutePointCheckIds: newRemovedRoutePointCheckIds.concat(removedRoutePointCheckIds)
        });
    };

    handleSiteRemove = (id: string) => {
        const state = this.state;
        if (!id) return;
        //Site
        const item = state.sites.find(i => i.id === id);
        if (!item) return;
        const removedSiteIds = state.removedSiteIds.filter(i => i !== id);
        //RoutePoints
        const savedSiteRoutePointIds = new Map<string, string[]>(state.savedSiteRoutePointIds);
        savedSiteRoutePointIds.set(id, []);
        const removedRoutePointIds = state.removedRoutePointIds.filter(i => i !== id);
        //RoutePointChecks
        const savedRoutePointCheckIds = new Map<string, Map<string, string[]>>(state.savedRoutePointCheckIds);
        savedRoutePointCheckIds.set(id, new Map<string, string[]>());
        const removedRoutePointCheckIds = state.removedRoutePointCheckIds.slice(0);
        //Removal
        if (!item.isNew()) {
            removedSiteIds.push(id);
            for (let i = 0; i < item.routePoints.length; i++) {
                const routePoint = item.routePoints[i];
                if (!routePoint.isNew()) {
                    removedRoutePointIds.push(routePoint.id);
                }
                for (let j = 0; j < routePoint.checks.length; j++) {
                    const check = routePoint.checks[i];
                    if (check.isNew()) continue;
                    removedRoutePointCheckIds.push(check.id);
                }
            }
        }
        this.setState({
            sites: state.sites.filter(i => i.id !== id),
            savedSiteIds: state.savedSiteIds.filter(i => i !== id),
            removedSiteIds: removedSiteIds,
            savedSiteRoutePointIds: savedSiteRoutePointIds,
            removedRoutePointIds: removedRoutePointIds,
            savedRoutePointCheckIds: savedRoutePointCheckIds,
            removedRoutePointCheckIds: removedRoutePointCheckIds
        });
    };
    // #endregion Sites

    // #region Contacts
    handleCustomerContactAdd = (contactId: string, name: string) => {
        const customerContacts = this.state.customerContacts.slice(0);
        const savedCustomerContactIds = this.state.savedCustomerContactIds.slice(0);
        const item = new CustomerEditItemCustomerContactItem();
        item.id = Base.getGuid();
        item.contactId = contactId;
        item.name = name;
        item.isNew = true;
        customerContacts.push(item);
        savedCustomerContactIds.push(item.id);
        CustomerEditItemCustomerContactItem.sortCustomerEditItemCustomerContactItems(customerContacts, "title", true);
        this.setState({ customerContacts: customerContacts, savedCustomerContactIds: savedCustomerContactIds });
    };

    handleCustomerContactEdit = (contactId: string, name: string) => {
        const customerContacts = this.state.customerContacts.slice(0);
        const item = customerContacts.find(i => i.contactId === contactId);
        item.id = Base.getGuid();
        item.contactId = contactId;
        item.name = name;
        item.isNew = true;
        CustomerEditItemCustomerContactItem.sortCustomerEditItemCustomerContactItems(customerContacts, "title", true);
        this.setState({ customerContacts: customerContacts });
    };

    handleCustomerContactRemove = (id: string) => {
        if (!id) return;
        const item = this.state.customerContacts.find(i => i.id === id);
        if (Base.isNullOrUndefined(item)) return;
        const removedCustomerContactIds = this.state.removedCustomerContactIds.filter(i => i !== id);
        if (!item.isNew) {
            removedCustomerContactIds.push(id);
        }
        this.setState({ customerContacts: this.state.customerContacts.filter(i => i.id !== id), savedCustomerContactIds: this.state.savedCustomerContactIds.filter(i => i !== id), removedCustomerContactIds: removedCustomerContactIds });
    };
    // #endregion Contacts

    // #region CustomerPrice
    handleCustomerPriceAdd = (productId: string, unitPrice: number) => {
        const props = this.props;
        const state = this.state;
        const customerPrices = state.customerPrices.slice(0);
        const savedCustomerPriceIds = state.savedCustomerPriceIds.slice(0);
        const item = new CustomerPriceEditItem();
        item.id = Base.getGuid();
        item.productId = productId;
        item.customerId = props.customerEdit.customer.id;
        item.unitPrice = unitPrice;
        customerPrices.push(item);
        savedCustomerPriceIds.push(item.id);
        CustomerPriceEditItem.setSortValuesByProduct(customerPrices, props.customerEdit.products);
        CustomerPriceEditItem.sortCustomerPriceEditItems(customerPrices, true);
        const customerPriceProducts = this.getCustomerPriceProducts(customerPrices, props.customerEdit.products);
        this.setState({
            customerPrices: customerPrices,
            savedCustomerPriceIds: savedCustomerPriceIds,
            availableCustomerPriceProducts: customerPriceProducts.availableProducts,
            usedCustomerPriceProducts: customerPriceProducts.usedProducts
        });
    };

    handleCustomerPriceEdit = (productId: string, unitPrice: number) => {
        const state = this.state;
        const customerPrices = state.customerPrices.slice(0);
        const item = customerPrices.find(i => i.productId === productId);
        if (!item) return;
        item.unitPrice = unitPrice;
        const savedCustomerPrices = state.savedCustomerPriceIds.filter(i => i !== item.id);
        savedCustomerPrices.push(item.id);
        this.setState({
            customerPrices: customerPrices,
            savedCustomerPriceIds: savedCustomerPrices
        });
    };

    handleCustomerPriceRemove = (id: string) => {
        const props = this.props;
        const state = this.state;
        const customerPrices = state.customerPrices.filter(c => c.id !== id);
        const savedCustomerPrices = state.savedCustomerPriceIds.filter(i => i !== id);
        const removedCustomerPrices = state.removedCustomerPriceIds.slice(0);
        removedCustomerPrices.push(id);
        const customerPriceProducts = this.getCustomerPriceProducts(customerPrices, props.customerEdit.products);
        this.setState({
            customerPrices: customerPrices,
            removedCustomerPriceIds: removedCustomerPrices,
            savedCustomerPriceIds: savedCustomerPrices,
            availableCustomerPriceProducts: customerPriceProducts.availableProducts,
            usedCustomerPriceProducts: customerPriceProducts.usedProducts
        });
    };
    // #endregion CustomerPrice

    private static validate = (state: ISettingsCustomerDialogState): boolean => {
        if (!state.name) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.NameMustBeDefined));
            return false;
        }
        return true;
    };

    private static getSaveDataFromState = (props: ISettingsCustomerDialogProp, state: ISettingsCustomerDialogState): SaveData => {
        const data = new SaveData();
        const customer = props.customerEdit.customer;
        // Common
        data.append("id", customer.id);
        data.append("rowId", customer.rowId);
        // General
        data.append("customerTypeId", state.customerTypeId);
        data.append("name", state.name);
        data.append("workOrderAcknowledgementTypeId", state.workOrderAcknowledgementTypeId);
        data.append("invoicePeriodId", state.invoicePeriodId);
        if (state.logoFile) {
            data.append("logo[]", state.logoFile, state.logoFile.name);
        }
        data.append("erp", state.erp);
        data.append("invoiceStreet", state.invoiceStreet);
        data.append("invoicePostalCode", state.invoicePostalCode);
        data.append("invoiceCity", state.invoiceCity);
        data.append("invoiceCountryCode", state.invoiceCountryCode);
        data.append("invoiceEmail", state.invoiceEmail);
        data.append("eInvoiceAddress", state.eInvoiceAddress);
        data.append("eInvoiceOperator", state.eInvoiceOperator);
        data.append("businessIdentityCode", state.businessIdentityCode);
        data.append("vatCode", state.vatCode);
        data.append("activeState", state.activeState.toString(10));
        // Sites
        data.append("sites", JSON.stringify(state.sites.reduce((result, i) => {
            if (state.savedSiteIds.indexOf(i.id) >= 0) {
                result.push(new CustomerEditItemSaveSiteItem(i));
            }
            return result;
        }, [])));
        data.append("removedSiteIds", JSON.stringify(state.removedSiteIds));
        // RoutePoints and RoutePointChecks
        const routePoints: SaveRoutePointItem[] = [];
        const routePointChecks: SaveRoutePointCheckEditItem[] = [];
        for (let i = 0; i < state.sites.length; i++) {
            const site = state.sites[i];
            const siteSavedRoutePointIds = state.savedSiteRoutePointIds.get(site.id) ?? [];
            const siteSavedRoutePointCheckIds = state.savedRoutePointCheckIds.get(site.id) ?? new Map<string, string[]>();
            for (let j = 0; j < site.routePoints.length; j++) {
                const siteRoutePoint = site.routePoints[j];
                if (siteSavedRoutePointIds.indexOf(siteRoutePoint.id) >= 0) {
                    routePoints.push(SaveRoutePointItem.createSaveRoutePointItemForSite(site.id, siteRoutePoint));
                }
                const routePointSavedRoutePointCheckIds = siteSavedRoutePointCheckIds.get(siteRoutePoint.id) ?? [];
                for (let k = 0; k < siteRoutePoint.checks.length; k++) {
                    const siteRoutePointCheck = siteRoutePoint.checks[k];
                    if (routePointSavedRoutePointCheckIds.indexOf(siteRoutePointCheck.id) >= 0) {
                        routePointChecks.push(SaveRoutePointCheckEditItem.createSaveRoutePointCheckEditItem(siteRoutePoint.id, siteRoutePointCheck));
                    }
                }
            }
        }
        data.append("routePoints", JSON.stringify(routePoints));
        data.append("routePointChecks", JSON.stringify(routePointChecks));
        data.append("removedRoutePointIds", JSON.stringify(state.removedRoutePointIds));
        data.append("removedRoutePointCheckIds", JSON.stringify(state.removedRoutePointCheckIds));
        // CustomerContacts
        data.append("addedCustomerContactContactIds", JSON.stringify(state.customerContacts.reduce((result, i) => {
            if (state.savedCustomerContactIds.indexOf(i.id) >= 0) {
                result.push(i.contactId);
            }
            return result;
        }, [])));
        data.append("removedCustomerContactIds", JSON.stringify(state.removedCustomerContactIds));
        // CustomerPrices
        data.append("customerPrices", JSON.stringify(state.customerPrices.reduce((result, i) => {
            if (state.savedCustomerPriceIds.indexOf(i.id) >= 0) {
                result.push(new SaveCustomerPriceEditItem(i));
            }
            return result;
        }, [])));
        data.append("removedCustomerPriceIds", JSON.stringify(state.removedCustomerPriceIds));
        return data;
    };

    saveCustomer = () => {
        const obj = this;
        if (!SettingsCustomerDialog.validate(this.state)) return;
        const saveData = SettingsCustomerDialog.getSaveDataFromState(this.props, this.state);
        if (Base.isNullOrUndefined(saveData)) return;
        // Call server
        store.customStore.dispatch(storeActions.fetchStart());
        customerService.saveCustomerEdit(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                obj.props.onOk(success.id, obj.state.name);
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleOkClick = () => {
        this.saveCustomer();
    };

    handleCancelClick = () => {
        const obj = this;
        const saveData = SettingsCustomerDialog.getSaveDataFromState(this.props, this.state);
        if (!Base.isNullOrUndefined(saveData) && saveData.hash !== SettingsCustomerDialog.orgStateHash) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.saveCustomer();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.props.onCancel();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));
        } else {
            obj.props.onCancel();
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        const customerEdit = props.customerEdit;
        const dialogClasses = "settings customer px800" + (props.classes ? " " + props.classes : "");
        const customer = new CustomerItem();
        customer.id = customerEdit.customer.id;
        customer.name = customerEdit.customer.name;
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.Customer + " - " + (!customerEdit.customer.isNew() ? customerEdit.customer.number.toString(10) + " " + customerEdit.customer.name : Translations.New)}
                    show={true}
                    body={<div>
                        <div className="customerContainer">
                            <div className="row">
                                <div className="col-3">
                                    <ImageSelector
                                        fileInputName="logo"
                                        image={state.logo}
                                        aspectRatio={null}
                                        onChange={this.handleSetImage}
                                        onError={(message) => { store.customStore.dispatch(storeActions.showErrorMessage(message)); }}
                                    />
                                </div>
                                <div className="col-9">
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="form-group required">
                                                <label className="control-label smallFont">{Translations.Number}</label>
                                                <input type="text" className="form-control" name="number" title={Translations.Number} value={customerEdit.customer.isNew() ? Translations.New : customerEdit.customer.number.toString(10)} readOnly={true} disabled={true} />
                                            </div>
                                        </div>
                                        <div className="col-9">
                                            <div className="form-group required">
                                                <label className="control-label smallFont">{Translations.Name}</label>
                                                <input type="text" className="form-control" name="Nm" title={Translations.Name} value={state.name} onChange={this.handleChange} maxLength={50} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-9">
                                            <div className="form-group required">
                                                <label className="control-label smallFont">{Translations.CustomerType}</label>
                                                <select className="custom-select" name="customerTypeId" title={Translations.Product} value={state.customerTypeId} onChange={this.handleChange}>
                                                    {customerEdit.customerTypes.map((customerType) =>
                                                        <option key={customerType.id} value={customerType.id}>{customerType.code + " " + customerType.name}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="control-label smallFont">&nbsp;</label>
                                                <div>
                                                    <CheckBox
                                                        title={Translations.InUse}
                                                        enabled={true}
                                                        checked={state.activeState > 0}
                                                        onCheckboxClickBoolean={(checked: boolean) => { this.handleActiveStateChange(checked ? 1 : 0); }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group required">
                                                <label className="control-label smallFont">{Translations.WorkOrderAcknowledgementType}</label>
                                                <select className="custom-select" name="workOrderAcknowledgementType" title={Translations.WorkOrderAcknowledgementType} value={state.workOrderAcknowledgementTypeId} onChange={this.handleChange}>
                                                    {customerEdit.workOrderAcknowledgementTypes.map((workOrderAcknowledgementType) =>
                                                        <option key={workOrderAcknowledgementType.id} value={workOrderAcknowledgementType.id}>{workOrderAcknowledgementType.title}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="control-label smallFont">{Translations.ErpReference}</label>
                                                <input type="text" className="form-control" name="erp" title={Translations.ErpReference} value={state.erp} onChange={this.handleChange} maxLength={50} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="control-label smallFont">{Translations.BusinessIdentityCode}</label>
                                                <input type="text" className="form-control" name="businessIdentityCode" title={Translations.BusinessIdentityCode} value={state.businessIdentityCode} onChange={this.handleChange} maxLength={10} />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="control-label smallFont">{Translations.VatCode}</label>
                                                <input type="text" className="form-control" name="vatCode" title={Translations.VatCode} value={state.vatCode} onChange={this.handleChange} maxLength={50} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="title">{Translations.InvoiceAddress}</div>
                            <div className="row">
                                <div className="col-8">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.StreetAddress}</label>
                                        <input type="text" className="form-control" name="invoiceAds" title={Translations.StreetAddress} value={state.invoiceStreet} onChange={this.handleChange} maxLength={50} />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.InvoicePeriod}</label>
                                        <select className="custom-select" name="invoicePeriodId" title={Translations.InvoicePeriod} value={state.invoicePeriodId} onChange={this.handleChange}>
                                            {customerEdit.invoicePeriods.map((invoicePeriod) =>
                                                <option key={invoicePeriod.id} value={invoicePeriod.id}>{invoicePeriod.title}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.PostalCode}</label>
                                        <input type="text" className="form-control" name="invoicepC" title={Translations.PostalCode} value={state.invoicePostalCode} onChange={this.handleChange} maxLength={10} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.CityAddress}</label>
                                        <input type="text" className="form-control" name="invoiceCty" title={Translations.CityAddress} value={state.invoiceCity} onChange={this.handleChange} maxLength={50} />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.CountryCode}</label>
                                        <input type="text" className="form-control" name="invoiceCc" title={Translations.CountryCode} value={state.invoiceCountryCode} onChange={this.handleChange} maxLength={50} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.Email}</label>
                                        <input type="text" className="form-control" name="invoiceEmail" title={Translations.Email} value={state.invoiceEmail} onChange={this.handleChange} maxLength={50} />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.EInvoiceAddress}</label>
                                        <input type="text" className="form-control" name="eInvoiceAddress" title={Translations.EInvoiceAddress} value={state.eInvoiceAddress} onChange={this.handleChange} maxLength={255} />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.EInvoiceOperator}</label>
                                        <input type="text" className="form-control" name="eInvoiceOperator" title={Translations.EInvoiceOperator} value={state.eInvoiceOperator} onChange={this.handleChange} maxLength={50} />
                                    </div>
                                </div>
                            </div>
                            <SettingsCustomerDialogSiteList
                                title={Translations.Sites}
                                readOnly={props.sitesReadOnly}
                                items={state.sites}
                                removedRoutePointIds={state.removedRoutePointIds}
                                savedSiteRoutePointIds={state.savedSiteRoutePointIds}
                                removedRoutePointCheckIds={state.removedRoutePointCheckIds}
                                savedRoutePointCheckIds={state.savedRoutePointCheckIds}
                                mapLinkTemplate={props.customerEdit.mapLinkTemplate}
                                onRemoveSite={this.handleSiteRemove}
                                onAddSite={this.handleSiteSave}
                                onEditSite={this.handleSiteSave}
                            />
                            <SettingsCustomerDialogContactList
                                title={Translations.Contacts}
                                readOnly={props.contactsReadOnly}
                                items={state.customerContacts}
                                onRemoveContact={this.handleCustomerContactRemove}
                                onAddContact={this.handleCustomerContactAdd}
                                onEditContact={this.handleCustomerContactEdit}
                            />
                            {UserParameters.isOwnerAdmin() &&
                                <SettingsCustomerPriceList
                                    inCustomerDialog={true}
                                    customer={customer}
                                    availableProducts={state.availableCustomerPriceProducts}
                                    usedProducts={state.usedCustomerPriceProducts}
                                    availableCustomers={[customer]}
                                    usedCustomers={[customer]}
                                    title={Translations.CustomerPrices}
                                    readOnly={props.customerPricesReadOnly}
                                    items={state.customerPrices}
                                    onRemoveCustomerPrice={this.handleCustomerPriceRemove}
                                    onAddCustomerPrice={this.handleCustomerPriceAdd}
                                    onEditCustomerPrice={this.handleCustomerPriceEdit}
                                />
                            }
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: this.handleOkClick },
                    ]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}
