// SettingsCustomerDialogSiteList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as locationService from "../../services/locationService";
import { Translations } from "../../models/translations";
import { PropertyDialog } from "../framework/dialog";
import { ToolButton } from "../framework/toolButton";
import { Base } from "../../framework/base";
import { ICustomerEditItemSiteItem, CustomerEditItemSiteItem } from "../../models/customer/customerEditItemSiteItem";
import { CheckBox } from "../framework/checkbox";
import { ICustomerEditItemSiteItemEmployeeItem } from "../../models/customer/customerEditItemSiteItemEmpoyeeItem";
import { SettingsSiteDialogRoutePointList } from "./settingsSiteDialogRoutePointList";
import { IRoutePointItem, RoutePointItem } from "../../models/routePoint/routePointItem";
import { SaveData } from "../../framework/saveData";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { SettingsSiteRouteDialog } from "./settingsSiteRouteDialog";
import { RadioButton } from "../framework/radio";
import { EnumHelper, SiteType } from "../../models/common/enums";
import { GeocodeResult } from "../../framework/geocodeResult";
import { LocationMap } from "../framework/locationMap";

// SettingsCustomerDialogSiteDialogEmployeeListLine
export interface ISettingsCustomerDialogSiteDialogEmployeeListLineProp {
    item: ICustomerEditItemSiteItemEmployeeItem;
    selectedId: string;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

export class SettingsCustomerDialogSiteDialogEmployeeListLine extends React.Component<ISettingsCustomerDialogSiteDialogEmployeeListLineProp, {}> {
    render() {
        const item = this.props.item;
        return (
            <div className={"row line" + (item.id === this.props.selectedId ? " selected" : "")} onClick={() => { this.props.onClick(item.id); }} onDoubleClick={() => { this.props.onDoubleClick(item.id); }} title={item.name + ", " + Base.utcTimeToDateStr(item.lastWorkDate)} >
                <div className="col-8">{item.name}</div>
                <div className="col-4 ra">{Base.utcTimeToDateStr(item.lastWorkDate)}</div>
            </div>
        );
    }
}

// SettingsCustomerDialogSiteDialogEmployeeList
export interface ISettingsCustomerDialogSiteDialogEmployeeListProp {
    title?: string;
    items: ICustomerEditItemSiteItemEmployeeItem[];
}

interface ISettingsCustomerDialogSiteDialogEmployeeListState {
    selectedId: string;
    selectedItem: ICustomerEditItemSiteItemEmployeeItem;
}

export class SettingsCustomerDialogSiteDialogEmployeeList extends React.Component<ISettingsCustomerDialogSiteDialogEmployeeListProp, ISettingsCustomerDialogSiteDialogEmployeeListState> {
    constructor(props) {
        super(props);
        this.state = { selectedId: null, selectedItem: null };
    }

    handleLineClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleLineDoubleClick = (id: string) => {
    };

    render() {
        const props = this.props;
        const items = props.items;
        return (
            <div>
                <div className="commandRow">
                    {props.title &&
                        <label className="control-label listTitle">{props.title}</label>
                    }
                </div>
                <div className="listContainer siteEmployeesContainer">
                    {items.length > 0 &&
                        <div className="list siteEmployees">
                            {items.map((item) =>
                                <SettingsCustomerDialogSiteDialogEmployeeListLine
                                    key={item.id}
                                    item={item}
                                    selectedId={this.state.selectedId}
                                    onClick={this.handleLineClick}
                                    onDoubleClick={this.handleLineDoubleClick}
                                />
                            )}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

// SettingsCustomerDialogSiteDialog
// ***********************************************************************************************************************
export interface ISettingsCustomerDialogSiteDialogProp {
    classes?: string;
    readOnly: boolean;
    item: ICustomerEditItemSiteItem;
    removedRoutePointIds: string[];
    savedRoutePointIds: string[];
    removedRoutePointCheckIds: string[];
    savedRoutePointCheckIds: Map<string, string[]>;
    mapLinkTemplate: string;
    isNew: boolean;
    onOk: (item: ICustomerEditItemSiteItem, removedRoutePointIds: string[], savedRoutePointIds: string[], removedRoutePointCheckIds: string[], savedRoutePointCheckIds: Map<string, string[]>) => void;
    onCancel: () => void;
}

export interface ISettingsCustomerDialogSiteDialogState {
    siteType: number;
    siteNumber: string;
    name: string;
    name2: string;
    street: string;
    postalCode: string;
    city: string;
    countryCode: string;
    mapLink: string;
    longitude: number;
    latitude: number;
    distanceStr: string;
    rideBookingRequired: boolean;
    familiarizationType: number;
    showAdditionalInformation: boolean;
    routePoints: IRoutePointItem[];
    removedRoutePointIds: string[];
    savedRoutePointIds: string[];
    removedRoutePointCheckIds: string[];
    savedRoutePointCheckIds: Map<string, string[]>;
    showRouteDialog: boolean;
}

export class SettingsCustomerDialogSiteDialog extends React.Component<ISettingsCustomerDialogSiteDialogProp, ISettingsCustomerDialogSiteDialogState> {
    private static orgStateHash: string = "";

    private static getHashDataFromState = (state: ISettingsCustomerDialogSiteDialogState): string => {
        const data = new SaveData();
        data.append("siteType", state.siteType.toString(10));
        data.append("name", state.name);
        data.append("name2", state.name2);
        data.append("street", state.street);
        data.append("postalCode", state.postalCode);
        data.append("city", state.city);
        data.append("countryCode", state.countryCode);
        data.append("mapLink", state.mapLink);
        data.append("longitude", state.longitude.toString(10));
        data.append("latitude", state.latitude.toString(10));
        data.append("distanceStr", state.distanceStr);
        data.append("rideBookingRequired", state.rideBookingRequired ? "1" : "0");
        data.append("rideBookingRequired", state.familiarizationType.toString(10));
        data.append("routePoints", JSON.stringify(state.routePoints));
        data.append("removedRoutePointIds", JSON.stringify(state.removedRoutePointIds));
        data.append("savedRoutePointIds", JSON.stringify(state.savedRoutePointIds));
        data.append("removedRoutePointCheckIds", JSON.stringify(state.removedRoutePointCheckIds));
        data.append("savedRoutePointCheckIds", JSON.stringify(state.savedRoutePointCheckIds));
        return data.hash;
    };

    constructor(props: ISettingsCustomerDialogSiteDialogProp) {
        super(props);
        const item = props.item;
        const routePoint = item.routePoints[0];
        this.state = {
            siteType: item.siteType,
            siteNumber: item.siteNumber,
            name: item.name,
            name2: item.name2,
            street: routePoint.street,
            postalCode: routePoint.postalCode,
            city: routePoint.city,
            countryCode: routePoint.countryCode,
            mapLink: routePoint.mapLink,
            longitude: routePoint.longitude,
            latitude: routePoint.latitude,
            distanceStr: item.distance.toString(10),
            rideBookingRequired: item.rideBookingRequired,
            familiarizationType: item.familiarizationType,
            showAdditionalInformation: false,
            routePoints: item.routePoints.slice(0),
            removedRoutePointIds: props.removedRoutePointIds.slice(0),
            savedRoutePointIds: props.savedRoutePointIds.slice(0),
            removedRoutePointCheckIds: props.removedRoutePointCheckIds.slice(0),
            savedRoutePointCheckIds: new Map<string, string[]>(props.savedRoutePointCheckIds),
            showRouteDialog: false
        };
        SettingsCustomerDialogSiteDialog.orgStateHash = SettingsCustomerDialogSiteDialog.getHashDataFromState(this.state);
    }

    // #region General
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "siteNumber") {
            this.setState({ siteNumber: value });
        } else if (name === "Nm") {
            this.setState({ name: value });
        } else if (name === "Nm2") {
            this.setState({ name2: value });
        } else if (name === "Ads") {
            this.setState({ street: value });
        } else if (name === "pC") {
            this.setState({ postalCode: value });
        } else if (name === "cty") {
            this.setState({ city: value });
        } else if (name === "cntrCd") {
            this.setState({ countryCode: value });
        } else if (name === "distance") {
            this.setState({ distanceStr: value });
        }
    };

    handleBlur = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "distance") {
            this.setState({ distanceStr: value.toDecimal().toString(10) });
        }
    };

    handleChangeSiteType = (siteType: SiteType) => {
        this.setState({ siteType: siteType });
    };

    handleRideBookingRequiredChange = (rideBookingRequired: boolean) => {
        this.setState({ rideBookingRequired: rideBookingRequired });
    };

    handleFamiliarizationTypeChange = (familiarizationType: number) => {
        this.setState({ familiarizationType: familiarizationType });
    };

    handleCreateMapLinkClick = () => {
        const obj = this;
        const state = this.state;
        const props = this.props;
        locationService.geocode(state.street, state.postalCode, state.city, "")
            .then(result => {
                obj.setState({
                    latitude: result.latitude,
                    longitude: result.longitude,
                    mapLink: Base.generateMapLink(props.mapLinkTemplate, result.latitude.toFixed(6), result.longitude.toFixed(6), null)
                });
            })
            .catch(error => {
                console.log(error);
                const mapLink = state.latitude && state.longitude
                    ? Base.generateMapLink(props.mapLinkTemplate, state.latitude.toFixed(6), state.longitude.toFixed(6), "")
                    : Base.generateMapLink(props.mapLinkTemplate, state.street, state.postalCode, state.city);
                if (!mapLink) return;
                this.setState({ mapLink: mapLink });
            });
    };

    handleToggleShowAdditionalInformation = (e) => {
        e.preventDefault();
        this.setState({ showAdditionalInformation: !this.state.showAdditionalInformation });
    };
    // #endregion General

    // #region RoutePoint
    handleRemoveRoutePoint = (id: string) => {
        const state = this.state;
        if (!id) return;
        const item = state.routePoints.find(i => i.id === id);
        if (!item) return;
        const removedRoutePointIds = state.removedRoutePointIds.filter(i => i !== id);
        const removedRoutePointCheckIds = state.removedRoutePointCheckIds.slice(0);
        const savedRoutePointCheckIds = new Map<string, string[]>(state.savedRoutePointCheckIds);
        savedRoutePointCheckIds.set(id, []);
        if (!item.isNew()) {
            removedRoutePointIds.push(id);
            for (let i = 0; i < item.checks.length; i++) {
                const check = item.checks[i];
                if (check.isNew()) continue;
                removedRoutePointCheckIds.push(check.id);
            }
        }
        this.setState({
            routePoints: state.routePoints.filter(i => i.id !== id),
            savedRoutePointIds: state.savedRoutePointIds.filter(i => i !== id),
            removedRoutePointIds: removedRoutePointIds,
            savedRoutePointCheckIds: savedRoutePointCheckIds,
            removedRoutePointCheckIds: removedRoutePointCheckIds
        });
    };

    handleSaveRoutePoint = (item: IRoutePointItem, removedCheckIds: string[], savedCheckIds: string[]) => {
        const state = this.state;
        if (!item || !item.id) return;
        //RoutePoints
        const routePoints = state.routePoints.filter(i => i.id !== item.id);
        const savedRoutePointIds = state.savedRoutePointIds.filter(i => i !== item.id);
        routePoints.push(item);
        savedRoutePointIds.push(item.id);
        //RoutePointChecks
        const savedRoutePointCheckIds = new Map<string, string[]>(state.savedRoutePointCheckIds);
        savedRoutePointCheckIds.set(item.id, savedCheckIds);
        const removedRoutePointCheckIds = state.removedRoutePointCheckIds.filter(i => removedCheckIds.indexOf(i) < 0);
        RoutePointItem.sortRoutePointItems(routePoints);
        this.setState({
            routePoints: routePoints,
            savedRoutePointIds: savedRoutePointIds,
            savedRoutePointCheckIds: savedRoutePointCheckIds,
            removedRoutePointCheckIds: removedRoutePointCheckIds.concat(removedCheckIds)
        });
    };

    handleMoveRoutePoint = (id: string, step: number) => {
        const state = this.state;
        if (!id) return;
        //RoutePoints
        const routePoints = state.routePoints.slice(0);
        const index = routePoints.findIndex(i => i.id === id);
        if (index < 0 || index + step < 0 || index + step > routePoints.length - 1) return;
        const item = routePoints[index];
        const id2 = routePoints[index + step].id;
        routePoints[index] = routePoints[index + step];
        routePoints[index + step] = item;
        routePoints[index].number = index + 1;
        routePoints[index + step].number = index + step + 1;
        const savedRoutePointIds = state.savedRoutePointIds.filter(i => i !== id && i !== id2);
        savedRoutePointIds.push(id);
        savedRoutePointIds.push(id2);
        RoutePointItem.sortRoutePointItems(routePoints);
        this.setState({
            routePoints: routePoints,
            savedRoutePointIds: savedRoutePointIds
        });
    };
    // #endregion RoutePoint

    handleEditRoute = () => {
        this.setState({ showRouteDialog: true });
    };

    handleSettingsSiteRouteDialogCancel = () => {
        this.setState({ showRouteDialog: false });
    };

    private static validate = (state: ISettingsCustomerDialogSiteDialogState): boolean => {
        if (!state.name) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.NameMustBeDefined));
            return false;
        }
        return true;
    };

    geocodeAddress = (routePoint: IRoutePointItem): Promise<GeocodeResult> => {
        const state = this.state;
        if ((routePoint.street === state.street && routePoint.postalCode === state.postalCode && routePoint.city === state.city && routePoint.countryCode === state.countryCode) || (!state.street && !state.postalCode && !state.city && !state.countryCode)) {
            return new Promise<GeocodeResult>((resolve) => { resolve(null); });
        }
        return locationService.geocode(state.street, state.postalCode, state.city, state.countryCode)
            .then(result => {
                return result;
            })
            .catch(error => {
                console.log(error);
                return new Promise<GeocodeResult>((resolve) => { resolve(null); });
            });
    };

    handleOkClick = () => {
        const props = this.props;
        const state = this.state;
        if (!SettingsCustomerDialogSiteDialog.validate(state)) return;
        const item = new CustomerEditItemSiteItem(this.props.item);
        item.siteType = state.siteType;
        item.siteNumber = state.siteNumber;
        item.name = state.name;
        item.name2 = state.name2;
        item.distance = state.distanceStr.toDecimal();
        item.rideBookingRequired = state.rideBookingRequired;
        item.familiarizationType = state.familiarizationType;
        item.routePoints = state.routePoints.slice(0);
        let savedRoutePointIds = state.savedRoutePointIds.slice(0);
        const isRoute = EnumHelper.isEqual(state.siteType, SiteType.Route);
        if (!isRoute) {
            const routePoint = item.routePoints[0];
            const orgRoutePoint = new RoutePointItem(routePoint);
            routePoint.name = state.name;
            routePoint.name2 = state.name2;
            routePoint.street = state.street;
            routePoint.postalCode = state.postalCode;
            routePoint.city = state.city;
            routePoint.countryCode = state.countryCode;
            routePoint.mapLink = state.mapLink;
            routePoint.latitude = state.latitude;
            routePoint.longitude = state.longitude;
            savedRoutePointIds = savedRoutePointIds.filter(i => i !== routePoint.id);
            savedRoutePointIds.push(routePoint.id);
            this.geocodeAddress(orgRoutePoint).then(geocodeResult => {
                if (geocodeResult) {
                    routePoint.latitude = geocodeResult.latitude;
                    routePoint.longitude = geocodeResult.longitude;
                    if (!routePoint.street) {
                        routePoint.street = geocodeResult.street;
                    }
                    if (!routePoint.postalCode) {
                        routePoint.postalCode = geocodeResult.postalCode;
                    }
                    if (!routePoint.city) {
                        routePoint.city = geocodeResult.city;
                    }
                    if (!routePoint.countryCode) {
                        routePoint.countryCode = geocodeResult.countryCode;
                    }
                    routePoint.mapLink = Base.generateMapLink(props.mapLinkTemplate, geocodeResult.latitude.toFixed(6), geocodeResult.longitude.toFixed(6), null);
                } else {
                    routePoint.mapLink = Base.generateMapLink(props.mapLinkTemplate, state.street, state.postalCode, state.city);
                }
                this.props.onOk(item, state.removedRoutePointIds, savedRoutePointIds, state.removedRoutePointCheckIds, state.savedRoutePointCheckIds);
            });
        } else {
            this.props.onOk(item, state.removedRoutePointIds, savedRoutePointIds, state.removedRoutePointCheckIds, state.savedRoutePointCheckIds);
        }
    };

    handleCancelClick = () => {
        const obj = this;
        const hashData = SettingsCustomerDialogSiteDialog.getHashDataFromState(this.state);
        if (hashData !== SettingsCustomerDialogSiteDialog.orgStateHash) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.handleOkClick();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.props.onCancel();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));
        } else {
            obj.props.onCancel();
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "customerSite px800" + (props.classes ? " " + props.classes : "");
        const isRoute = EnumHelper.isEqual(state.siteType, SiteType.Route);
        const isPoint = EnumHelper.isEqual(state.siteType, SiteType.Point);
        const routePoint = state.routePoints[0];
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.Site + " - " + (!props.isNew ? props.item.name : Translations.New)}
                    initialHeightOffset={!isRoute ? 200 : 0}
                    show={true}
                    body={<div>
                        <div className="maxHeightContent">
                            <div className="row">
                                <div className="col-7">
                                    <label className="control-label smallFont required">{Translations.Name}</label>
                                    <input type="text" className="form-control" name="Nm" title={Translations.Name} value={state.name} onChange={this.handleChange} maxLength={50} />
                                </div>
                                <div className="col-5">
                                    <div>
                                        <label className="control-label smallFont">{Translations.AType}</label>
                                    </div>
                                    <div className="btn-group btn-group-toggle states">
                                        <RadioButton
                                            classes={"btn-secondary state1 siteType"}
                                            title={Translations.SiteTypeNoLocation}
                                            enabled={true}
                                            checked={EnumHelper.isEqual(state.siteType, SiteType.NoLocation)}
                                            onRadioClick={() => this.handleChangeSiteType(SiteType.NoLocation)}
                                        />
                                        <RadioButton
                                            classes={"btn-secondary state1 siteType"}
                                            title={Translations.SiteTypePoint}
                                            enabled={true}
                                            checked={isPoint}
                                            onRadioClick={() => this.handleChangeSiteType(SiteType.Point)}
                                        />
                                        <RadioButton
                                            classes={"btn-secondary state2 siteType"}
                                            title={Translations.SiteTypeRoute}
                                            enabled={true}
                                            checked={isRoute}
                                            onRadioClick={() => this.handleChangeSiteType(SiteType.Route)}
                                        />

                                    </div>
                                </div>
                            </div>
                            {isRoute &&
                                <SettingsSiteDialogRoutePointList
                                    title={Translations.Route}
                                    isReadOnly={props.readOnly}
                                    mapLinkTemplate={props.mapLinkTemplate}
                                    routePoints={state.routePoints}
                                    removedRoutePointCheckIds={state.removedRoutePointCheckIds}
                                    savedRoutePointCheckIds={state.savedRoutePointCheckIds}
                                    onRemoveRoutePoint={this.handleRemoveRoutePoint}
                                    onAddRoutePoint={this.handleSaveRoutePoint}
                                    onEditRoutePoint={this.handleSaveRoutePoint}
                                    onMoveRoutePoint={this.handleMoveRoutePoint}
                                />
                            }
                            {isPoint &&
                                <div>
                                    <div className="sectionTitle">{Translations.Address}
                                        <div className="right">
                                            {!!routePoint.latitude && !!routePoint.longitude &&
                                                <div><div className="mapMarker green smallMarker left" /><span>{Translations.LocationSuccess}</span></div>
                                            }
                                            {(!routePoint.latitude || !routePoint.longitude) &&
                                                <div><div className="mapMarker red smallMarker left" /><span>{Translations.LocationError}</span></div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="control-label smallFont">{Translations.StreetAddress}</label>
                                                <input type="text" className="form-control" name="Ads" title={Translations.StreetAddress} value={state.street} onChange={this.handleChange} maxLength={50} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="control-label smallFont">{Translations.PostalCode}</label>
                                                <input type="text" className="form-control" name="pC" title={Translations.PostalCode} value={state.postalCode} onChange={this.handleChange} maxLength={10} />
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <div className="form-group">
                                                <label className="control-label smallFont">{Translations.CityAddress}</label>
                                                <input type="text" className="form-control" name="cty" title={Translations.CityAddress} value={state.city} onChange={this.handleChange} maxLength={50} />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="control-label smallFont">{Translations.CountryCode}</label>
                                                <input type="text" className="form-control" name="cntrCd" title={Translations.CountryCode} value={state.countryCode} onChange={this.handleChange} maxLength={50} />
                                            </div>
                                        </div>
                                    </div>
                                    {routePoint.hasLocation() &&
                                        <LocationMap
                                            classes="site routePoints"
                                            points={[routePoint]}
                                            selectedIds={[]}
                                            onSetSelectedIds={null}
                                        />
                                    }
                                </div>
                            }
                            {(isRoute || isPoint) &&
                                <div><a className="readOnlyLink" onClick={this.handleToggleShowAdditionalInformation}>{state.showAdditionalInformation ? Translations.HideAdditionalInformation : Translations.ShowAdditionalInformation}</a></div>
                            }
                            {(state.showAdditionalInformation || (!isRoute && !isPoint)) &&
                                <div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="control-label smallFont">{Translations.Specifier}</label>
                                                <input type="text" className="form-control" name="Nm2" title={Translations.Specifier} value={state.name2} onChange={this.handleChange} maxLength={50} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="control-label smallFont">{Translations.Distance}</label>
                                                <input type="text" className="form-control" name="distance" title={Translations.Distance} value={state.distanceStr} onChange={this.handleChange} onBlur={this.handleBlur} maxLength={4} />
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <div className="form-group">
                                                <label className="control-label smallFont">{Translations.CustomerSiteNumber}</label>
                                                <input type="text" className="form-control" name="siteNumber" title={Translations.CustomerSiteNumber} value={state.siteNumber} onChange={this.handleChange} maxLength={50} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <div>
                                                    <CheckBox
                                                        title={Translations.RideBookingRequired}
                                                        enabled={true}
                                                        checked={state.rideBookingRequired}
                                                        onCheckboxClickBoolean={(checked: boolean) => { this.handleRideBookingRequiredChange(checked); }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <div>
                                                    <CheckBox
                                                        title={Translations.SiteFamiliarizationRequired}
                                                        enabled={true}
                                                        checked={state.familiarizationType > 0}
                                                        onCheckboxClickBoolean={(checked: boolean) => { this.handleFamiliarizationTypeChange(checked ? 1 : 0); }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {props.item.employees.length > 0 &&
                                        <div className="row">
                                            <div className="col-12">
                                                <SettingsCustomerDialogSiteDialogEmployeeList
                                                    title={Translations.EmployeesThatHaveWorkedInSite}
                                                    items={props.item.employees}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        {state.showRouteDialog &&
                            <SettingsSiteRouteDialog
                                mapLinkTemplate={props.mapLinkTemplate}
                                siteName={state.name}
                                readOnly={props.readOnly}
                                items={state.routePoints}
                                removedRoutePointCheckIds={state.removedRoutePointCheckIds}
                                savedRoutePointCheckIds={state.savedRoutePointCheckIds}
                                onRemoveRoutePoint={this.handleRemoveRoutePoint}
                                onAddRoutePoint={this.handleSaveRoutePoint}
                                onEditRoutePoint={this.handleSaveRoutePoint}
                                onMoveRoutePoint={this.handleMoveRoutePoint}
                                onCancel={this.handleSettingsSiteRouteDialogCancel}
                            />
                        }
                    </div>}
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: this.handleOkClick },
                        { title: Translations.CreateMapLink, classes: "btn-default mr-auto left", enabled: true, onClick: !isRoute ? this.handleCreateMapLinkClick : null },
                    ]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}

// SettingsCustomerDialogSiteListLine
export interface ISettingsCustomerDialogSiteListLineProp {
    item: ICustomerEditItemSiteItem;
    selectedId: string;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

export class SettingsCustomerDialogSiteListLine extends React.Component<ISettingsCustomerDialogSiteListLineProp, {}> {
    render() {
        const item = this.props.item;
        return (
            <div className={"row line" + (item.id === this.props.selectedId ? " selected" : "")} onClick={() => { this.props.onClick(item.id); }} onDoubleClick={() => { this.props.onDoubleClick(item.id); }}
                title={Base.getStringWithSeparators([item.name, item.name2, item.siteNumber.toString()], "\n")}
            >
                <div className="col-8">{item.name + (item.name2 ? ", " + item.name2 : "") + (item.familiarizationType > 0 ? Translations.SiteSamiliarizationType1Suffix : "")}</div>
                <div className="col-4">{item.siteNumber}</div>
            </div>
        );
    }
}

// SettingsCustomerDialogSiteList
export interface ISettingsCustomerDialogSiteListProp {
    title?: string;
    readOnly: boolean;
    items: ICustomerEditItemSiteItem[];
    removedRoutePointIds: string[];
    savedSiteRoutePointIds: Map<string, string[]>;
    removedRoutePointCheckIds: string[];
    savedRoutePointCheckIds: Map<string, Map<string, string[]>>;
    mapLinkTemplate: string;
    onRemoveSite: (id: string) => void;
    onAddSite: (item: ICustomerEditItemSiteItem, removedRoutePointIds: string[], savedRoutePointIds: string[], removedRoutePointCheckIds: string[], savedRoutePointCheckIds: Map<string, string[]>) => void;
    onEditSite: (item: ICustomerEditItemSiteItem, removedRoutePointIds: string[], savedRoutePointIds: string[], removedRoutePointCheckIds: string[], savedRoutePointCheckIds: Map<string, string[]>) => void;
}

interface ISettingsCustomerDialogSiteListState {
    selectedId: string;
    selectedItem: ICustomerEditItemSiteItem;
    showItemDialog: boolean;
    showItemDialogForNew: boolean;
}

export class SettingsCustomerDialogSiteList extends React.Component<ISettingsCustomerDialogSiteListProp, ISettingsCustomerDialogSiteListState> {
    constructor(props) {
        super(props);
        this.state = { selectedId: null, selectedItem: null, showItemDialog: false, showItemDialogForNew: false };
    }

    handleAdd = () => {
        if (this.props.readOnly) return;
        const selectedItem = new CustomerEditItemSiteItem();
        selectedItem.id = Base.getGuid();
        selectedItem.siteType = SiteType.Point;
        selectedItem.rideBookingRequired = false;
        const routePoint = new RoutePointItem();
        routePoint.id = Base.getGuid();
        routePoint.number = 1;
        selectedItem.routePoints.push(routePoint);
        this.setState({
            selectedItem: selectedItem,
            showItemDialog: true,
            showItemDialogForNew: true
        });
    };

    handleEdit = () => {
        if (this.props.readOnly) return;
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        const selectedItem = this.props.items.find(i => i.id === selectedId);
        if (!selectedItem) return;
        this.setState({
            selectedItem: selectedItem,
            showItemDialog: true,
            showItemDialogForNew: false
        });
    };

    handleSiteDialogOk = (item: ICustomerEditItemSiteItem, removedRoutePointIds: string[], savedRoutePointIds: string[], removedRoutePointCheckIds: string[], savedRoutePointCheckIds: Map<string, string[]>) => {
        const selectedItem = this.state.selectedItem;
        const showItemDialogForNew = this.state.showItemDialogForNew;
        this.setState({
            selectedId: selectedItem.id,
            showItemDialog: false,
            showItemDialogForNew: false
        });
        if (showItemDialogForNew) {
            this.props.onAddSite(item, removedRoutePointIds, savedRoutePointIds, removedRoutePointCheckIds, savedRoutePointCheckIds);
        } else {
            this.props.onEditSite(item, removedRoutePointIds, savedRoutePointIds, removedRoutePointCheckIds, savedRoutePointCheckIds);
        }
    };

    handleSiteDialogCancel = () => {
        this.setState({
            showItemDialog: false
        });
    };

    handleRemove = () => {
        if (this.props.readOnly) return;
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        this.props.onRemoveSite(selectedId);
    };

    handleLineClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleLineDoubleClick = (id: string) => {
        this.handleEdit();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const items = props.items;
        return (
            <div>
                <div className="commandRow">
                    {props.title &&
                        <label className="control-label listTitle">{props.title}</label>
                    }
                    <ToolButton
                        title={Translations.Remove}
                        enabled={!props.readOnly && !!state.selectedId}
                        classes={"round right remove"}
                        onClick={this.handleRemove}
                    />
                    <ToolButton
                        title={Translations.Edit}
                        enabled={!props.readOnly && !!state.selectedId}
                        classes={"round right edit"}
                        onClick={this.handleEdit}
                    />
                    <ToolButton
                        title={Translations.Add}
                        enabled={!props.readOnly}
                        classes={"round right add"}
                        onClick={this.handleAdd}
                    />
                </div>
                <div className="listContainer sitesContainer">
                    {items.length > 0 &&
                        <div className="list sites">
                            {items.map((item) =>
                                <SettingsCustomerDialogSiteListLine
                                    key={item.id}
                                    item={item}
                                    selectedId={state.selectedId}
                                    onClick={this.handleLineClick}
                                    onDoubleClick={this.handleLineDoubleClick}
                                />
                            )}
                        </div>
                    }
                </div>
                {state.showItemDialog && state.selectedItem &&
                    <SettingsCustomerDialogSiteDialog
                        readOnly={props.readOnly}
                        item={state.selectedItem}
                        removedRoutePointIds={props.removedRoutePointIds}
                        savedRoutePointIds={props.savedSiteRoutePointIds.get(state.selectedItem.id) ?? []}
                        removedRoutePointCheckIds={props.removedRoutePointCheckIds}
                        savedRoutePointCheckIds={props.savedRoutePointCheckIds.get(state.selectedItem.id) ?? new Map<string, string[]>()}
                        mapLinkTemplate={props.mapLinkTemplate}
                        isNew={state.showItemDialogForNew}
                        onOk={this.handleSiteDialogOk}
                        onCancel={this.handleSiteDialogCancel}
                    />
                }
            </div>
        );
    }
}
