// SettingsEmployeeDialogWeeklyPrintDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import Datetime from "react-datetime";
import { Translations } from "../../models/translations";
import { Dialog } from "../framework/dialog";
import { Base } from "../../framework/base";

export interface ISettingsEmployeeDialogWeeklyPrintDialogProp {
    classes?: string;
    onOk: (startDate: number, endDate: number) => void;
    onCancel: () => void;
}

export interface ISettingsEmployeeDialogWeeklyPrintDialogState {
    startDateStr: string;
    endDateStr: string;
}

export class SettingsEmployeeDialogWeeklyPrintDialog extends React.Component<ISettingsEmployeeDialogWeeklyPrintDialogProp, ISettingsEmployeeDialogWeeklyPrintDialogState> {
    constructor(props) {
        super(props);
        const startDate = Base.getFirstDayOfWeek(Base.getNowUtcDate());
        this.state = {
            startDateStr: Base.utcTimeToDateStr(startDate.getTime()), endDateStr: Base.utcTimeToDateStr(startDate.addDays(6).getTime())
        };
    }

    // #region General
    handleStartDateChange = (value: moment.Moment | string) => {
        if (!(typeof value === "string")) {
            this.setState({ startDateStr: Base.utcTimeToDateStr(value) });
        }
    };

    handleStartDateBlur = (value: moment.Moment | string) => {
        if (typeof value === "string") {
            const startDate = value.toUtcDate();
            this.setState({ startDateStr: Base.utcTimeToDateStr(startDate.getTime()), endDateStr: Base.utcTimeToDateStr(startDate.addDays(6).getTime()) });
        } else {
            const startDate = value;
            const endDate = startDate.clone().add(6, "d");
            this.setState({ startDateStr: Base.utcTimeToDateStr(startDate), endDateStr: Base.utcTimeToDateStr(endDate) });
        }
    };

    handleEndDateChange = (value: moment.Moment | string) => {
        if (!(typeof value === "string")) {
            this.setState({ endDateStr: Base.utcTimeToDateStr(value) });
        }
    };

    handleEndDateBlur = (value: moment.Moment | string) => {
        if (typeof value === "string") {
            this.setState({ endDateStr: Base.utcTimeToDateStr(value.toUtcDate().getTime()) });
        } else {
            this.setState({ endDateStr: Base.utcTimeToDateStr(value) });
        }
    };

    handleOkClick = () => {
        this.props.onOk(this.state.startDateStr.toUtcDate().getTime(), this.state.endDateStr.toUtcDate().addDays(1).getTime());
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const dialogClasses = "employeeWeeklyPrint px300" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <Dialog
                    classes={dialogClasses}
                    title={Translations.SelectDateRange}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.DateRangeStart}</label>
                                    <Datetime
                                        locale={appConfig.culture}
                                        className={"roWhite"}
                                        value={this.state.startDateStr}
                                        dateFormat={"D.M.YYYY"}
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        inputProps={{ maxLength: 10, readOnly: false, disabled: false }}
                                        onChange={this.handleStartDateChange}
                                        onClose={this.handleStartDateBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.DateRangeEnd}</label>
                                    <Datetime
                                        locale={appConfig.culture}
                                        className={"roWhite"}
                                        value={this.state.endDateStr}
                                        dateFormat={"D.M.YYYY"}
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        inputProps={{ maxLength: 10, readOnly: false, disabled: false }}
                                        onChange={this.handleEndDateChange}
                                        onClose={this.handleEndDateBlur}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.OK, classes: "btn-primary", enabled: true, onClick: this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}
