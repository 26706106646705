import { SessionState, ISessionState } from "./sessionState";
import { IndexedDbManager } from "../indexedDb/indexedDbManager";

// Session and SessionState is used in client and service worker, so do not import anything extra
export class Session {
    private static indexedDbAvailable: boolean = null;
    private static localStorageAvailable: boolean = null;
    private static firstLoad = true;

    private static getSessionKey(): string {
        return appConfig.clientDatabaseName + "_session";
    }

    private static isNullOrUndefined(test: any): boolean {
        return test === null || test === undefined;
    }

    private static isNullOrEmpty(test: string): boolean {
        return test === null || test === undefined || test === "";
    }

    static setIndexedDbAvailable(value: boolean) {
        Session.indexedDbAvailable = value;
        if (!value) {
            console.log("indexedDB IS NOT available");
        }
    }

    private static getIndexedDbAvailable(): boolean {
        if (!Session.isNullOrUndefined(Session.indexedDbAvailable)) return Session.indexedDbAvailable;
        try {
            Session.indexedDbAvailable = window !== null && window !== undefined && window.indexedDB !== null && window.indexedDB !== undefined;
        } catch (e) {
            Session.indexedDbAvailable = false;
        }
        return Session.indexedDbAvailable;
    }

    private static getLocalStorageAvailable(): boolean {
        if (!Session.isNullOrUndefined(Session.localStorageAvailable)) return Session.localStorageAvailable;
        try {
            Session.localStorageAvailable = localStorage !== null && localStorage !== undefined;
        } catch (e) {
            Session.localStorageAvailable = false;
        }
        return Session.localStorageAvailable;
    }

    static setSessionState(sessionState: SessionState): Promise<void> {
        if (!Session.isNullOrUndefined(sessionState)) {
            sessionState.refreshTime = (new Date()).getTime();
            Session.firstLoad = false;
        }
        if (Session.getIndexedDbAvailable()) {
            return IndexedDbManager.storeSessionState(sessionState);
        } else if (Session.getLocalStorageAvailable()) {
            localStorage.setItem(Session.getSessionKey(), !Session.isNullOrUndefined(sessionState) ? JSON.stringify(sessionState) : null);
            return new Promise<void>((resolve) => { resolve(); });
        } else {
            return new Promise<void>((resolve) => { resolve(); });
        }
    }

    static getSessionState(): Promise<ISessionState> {
        if (Session.getIndexedDbAvailable()) {
            return IndexedDbManager.getSessionState();
        } else if (Session.getLocalStorageAvailable()) {
            const str = localStorage.getItem(Session.getSessionKey());
            const result = !Session.isNullOrEmpty(str) ? new SessionState(JSON.parse(str)) : null;
            return new Promise<ISessionState>((resolve) => { resolve(result); });
        }
        return new Promise<ISessionState>((resolve) => { resolve(null); });
    }

    static getAuthenticatedUserName(): Promise<string> {
        return Session.getSessionState().then((sessionState) => {
            return new Promise<string>((resolve) => { resolve(!Session.isNullOrUndefined(sessionState) ? sessionState.userName : ""); });
        });
    }

    static getOwnerLogo(): Promise<string> {
        return Session.getSessionState().then((sessionState) => {
            return new Promise<string>((resolve) => { resolve(!Session.isNullOrUndefined(sessionState) ? sessionState.ownerLogo : ""); });
        });
    }

    static getAccessToken(): Promise<string> {
        return Session.getSessionState().then(i => {
            if (Session.isNullOrUndefined(i)) return null;
            return i.accessToken;
        });
    }

    static getRefreshToken(): Promise<string> {
        return Session.getSessionState().then(i => {
            if (Session.isNullOrUndefined(i)) return null;
            return i.refreshToken;
        });
    }

    static tokenNeedsRefresh(): Promise<boolean> {
        return Session.getSessionState().then(i => {
            if (Session.isNullOrUndefined(i)) return <boolean>false;
            if (Session.firstLoad) {
                Session.firstLoad = false;
                return <boolean>true;
            }
            const currentTime = (new Date()).getTime();
            const durationMin = (currentTime - i.refreshTime) / (60 * 1000);
            return durationMin > 60; // Refresh token once in hour
        });
    }

    static tokenIsExpired(): Promise<boolean> {
        return Session.getSessionState().then(i => {
            if (Session.isNullOrUndefined(i)) return <boolean>true;
            const currentTime = (new Date()).getTime();
            return currentTime > i.accessTokenExpireTime;
        });
    }
}