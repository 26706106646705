import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Button, Typography } from "@mui/material";
import { Translations } from "../../../models/translations";
import { TableDef, TableWrapper } from "../../framework/tableWrapper";
import { TransportOrderInvoicingState, TransportOrderListItemDto, TransportOrderState, transportOrderStates } from "../../../models/transport/transportOrder";
import { TransportOrderStateIndicator } from "../components/transportUtils";
import { MuiSwitch } from "../../framework/muiSwitch";
import { RootState, useAppSelector } from "../../../framework/customStore";
import { MultiSelectButton } from "./MultiSelectButton";
import { Base } from "../../../framework/base";


export interface InvoicingTransportOrderListProps {
    orderItems: TransportOrderListItemDto[];
    handleToggleState?: () => void;
    showOnlyInvoiceable?: boolean;
    selectedOrders: TransportOrderListItemDto[];
    setSelectedOrders: Dispatch<SetStateAction<TransportOrderListItemDto[]>>;
}

export const InvoicingTransportOrderList = ({ orderItems, handleToggleState, showOnlyInvoiceable, selectedOrders, setSelectedOrders }: InvoicingTransportOrderListProps) => {
    const state = useAppSelector((state: RootState) => state.transportOrders);
    const stateInvoiceable = transportOrderStates[TransportOrderInvoicingState.Invoiceable];
    const [selectedCustomerId, setSelectedCustomerId] = useState<string>();
    const formatDate = (time: string) => Base.timeToDateTimeStr(new Date(time).getTime());

    useEffect(() => {
        if (selectedOrders) {
            setSelectedCustomerId(selectedOrders[0]?.billingCustomerId);
        } else {
            setSelectedCustomerId("");
        }
    }, [selectedOrders]);

    useEffect(() => {
        if (selectedOrders.length > 0 && selectedCustomerId !== state.billingCustomer && state.billingCustomer) {
            setSelectedOrders([]);
        }
    }, [state.billingCustomer]);

    const handleSelectOrderClick = (item: TransportOrderListItemDto) => {
        setSelectedOrders(
            [...selectedOrders, item]
        );
    };

    const handleDeleteItem = (item: TransportOrderListItemDto) => {
        setSelectedOrders(selectedOrders.filter(i => i.id !== item.id));
    };

    const table = useMemo<TableDef<TransportOrderListItemDto>>(() => ({
        columns: [
            {
                label: Translations.State,
                accessor: "state",
                renderCell: (val: TransportOrderState) => {
                    return (
                        <TransportOrderStateIndicator state={val} />
                    );
                }
            },
            {
                label: Translations.OrderNumber,
                accessor: "orderNumber",
                width: "7%"
            },
            {
                label: Translations.WaybillNo,
                accessor: "waybillNumber",
                width: "7%"
            },
            {
                label: Translations.Definition,
                accessor: "name",
            },
            {
                label: Translations.BillingCustomer,
                accessor: "billingCustomerName",
            },
            {
                label: Translations.DeliveryTime,
                disabledSort: true,
                accessor: row => formatDate(row.actualDeliveryDateTime) || "",
            },
            {
                label: `${Translations.Price} (EUR)`,
                disabledSort: true,
                accessor: row => row?.fixedPrice && (row.fixedPrice).toFixed(2) || "0.00",
            },
            {
                label: "",
                width: "10%",
                renderCell: (_, row) => (
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        {row.invoicingState === TransportOrderInvoicingState.Invoiceable &&
                            <MultiSelectButton
                                row={row}
                                onClick={handleSelectOrderClick}
                                onRemove={handleDeleteItem}
                                disabled={selectedOrders.length > 0 && selectedCustomerId !== row.billingCustomerId}
                                selected={selectedOrders.some(i => i.id === row.id)}
                            />
                        }
                        {row.state !== TransportOrderState.Delivered &&
                            <Button
                                fullWidth
                                disabled
                                size="small"
                                color="primary"
                            > {Translations.NotDelivered}
                            </Button>}
                        {(row.invoicingState === TransportOrderInvoicingState.AddedToInvoice ||
                            row.invoicingState === TransportOrderInvoicingState.InvoicedInternally ||
                            row.invoicingState === TransportOrderInvoicingState.InvoicedExternally) &&
                            <Button
                                fullWidth
                                disabled
                                size="small"
                                color="primary"
                            >
                                {row.invoicingState === TransportOrderInvoicingState.AddedToInvoice as any
                                    ? Translations.OnTheInvoice
                                    : Translations.Invoiced}
                            </Button>
                        }
                    </div>
                ),
            },
        ],
    }), [selectedCustomerId, selectedOrders.length]);

    return (
        <div style={{ padding: "1rem" }}>
            <Grid2 container justifyContent="space-between">
                <Grid2 xs={3}>
                    <Typography variant="h3">{Translations.TransportOrdersInvoices}</Typography>
                </Grid2>
                {showOnlyInvoiceable !== undefined &&
                    <Grid2>
                        <MuiSwitch
                            key={stateInvoiceable.value}
                            checked={!showOnlyInvoiceable}
                            onChange={() => handleToggleState()}
                            color="primary"
                            label={Translations.ShowAllTransportOrders}
                        />
                    </Grid2>
                }
            </Grid2>
            <Grid2>
                <TableWrapper tableDef={table} data={orderItems} />
            </Grid2>
            {/* //TODO */}
            {/* <Grid2 xs={12}container alignItems="center" justifyContent="flex-end">
                    <Button
                        color="secondary"
                        sx={{ color: theme.palette.common.white }}
                        variant={"contained"}
                        //TODO
                        // onClick={}
                    >
                        {Translations.SelectAllDelivered}
                    </Button>
                </Grid2> */}
        </div>
    );
};