import React, { useEffect, useState } from "react";
import { Link, Stack, Typography } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { t } from "i18next";

import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import { fetchServiceDeskArticles } from "../../services/serviceDeskService";

import { LoadingIndicator } from "../framework/loadingIndicatorNew";
import { MainLayout } from "../layout/mainLayout";
import styles from "./frontPageMain.module.css";

export const FrontPageMain = () => {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getArticles = async() => {
            setLoading(true);
            const data = await fetchServiceDeskArticles();
            setArticles(data);
        };
        getArticles()
            .catch((err) => {
                store.customStore.dispatch(
                    storeActions.showErrorMessage(err?.title)
                );
                return null;
            })
            .finally(() => setLoading(false));
    }, []);

    return (
        <MainLayout>
            <div style={{ display: "block", margin: "0 auto", maxWidth: "1500px" }}>
                <LoadingIndicator loading={loading} />
                <Stack>

                    <Stack spacing={1} alignSelf="end" alignItems="end">
                        <div>
                            <Link href="https://easyopp.atlassian.net/servicedesk/" >
                                <Typography variant="h3" m="0" sx={{ fontStyle: "italic", color: "primary.dark" }}>
                                    <HelpIcon sx={{ mb: "3px" }} /> {t("supportPortal")}
                                </Typography>
                            </Link>
                        </div>
                        <div>
                            <Link href="mailto:asiakaspalvelu@easyopp.fi" >
                                <Typography variant="h3" m="0" sx={{ color: "primary.dark" }}>
                                    asiakaspalvelu@easyopp.fi
                                </Typography>
                            </Link>
                        </div>
                    </Stack>

                    <Stack spacing={5} alignSelf="center" width="100%" maxWidth="700px">
                        <img
                            src={appConfig.ownRoot + "EasyOpp.svg"}
                            alt="EasyOpp - Decorative logo"
                        />
                        <Typography
                            variant="h3"
                            fontSize="32px"
                            sx={{
                                width: "100%",
                                pl: "23%",
                                textAlign: "center",
                                fontStyle: "italic",
                                color: "primary.dark" }}
                        >
                            {t("greeting")}
                        </Typography>
                    </Stack>

                    { articles.length > 0 && (
                        <div>
                            <div className={styles.changelog}>
                                {articles.map((article, index) => (
                                    <div key={index} className={styles.article}>
                                        <h2 dangerouslySetInnerHTML={{ __html: article?.title }} />
                                        <p dangerouslySetInnerHTML={{ __html: article?.content }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                </Stack>
            </div>
        </MainLayout>
    );
};