import React, { useState } from "react";
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { TransportOrderInvoiceSumRow } from "./TransportOrderInvoiceSumRow";
import { Translations } from "../../../models/translations";
import { apiCall } from "../../../services/apiClient";
import { showApiError, showApiSuccess, showConfirm } from "../../framework/formUtils";
import { RootState, useAppDispatch, useAppSelector } from "../../../framework/customStore";
import { fetchTransportOrderInvoice } from "../../../store/transportOrderInvoiceSlice";
import { useParams } from "react-router-dom";
import { TransportOrderInvoiceRowDialog } from "./TransportOrderInvoiceRowDialog";

interface ITransportOrderInvoiceDetailsProps {
    editEnabled?: boolean;
    selectedCustomer?: string;
}

export const TransportOrderInvoiceDetails = ({ editEnabled, selectedCustomer }: ITransportOrderInvoiceDetailsProps) => {
    const theme = useTheme();
    const titleColor = { color: theme.palette.primary.dark };
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();
    const [showAddRowDialog, setShowAddRowDialog] = useState<boolean>(false);
    const selectedInvoice = useAppSelector((state: RootState) => state.transportOrderInvoice?.selectedInvoice);
    const transportOrderRows = selectedInvoice?.transportOrderRows || [];
    const invoiceCustomer = selectedInvoice?.customerId || selectedCustomer;

    const handleRemoveOrderFromInvoice = (orderId: string) => {

        const submitRemove = () => {
            apiCall(`TransportOrders/${orderId}/InvoiceRows`, "DELETE")
                .then(() => {
                    showApiSuccess(Translations.DeleteWasSuccess);
                    dispatch(fetchTransportOrderInvoice(id) as any);
                })
                .catch((error) => {
                    showApiError(error.message);
                });
        };

        showConfirm(Translations.AreYouSureWantDelete, submitRemove);
    };

    const handleAddNewRowClick = () => {
        setShowAddRowDialog(true);
    };

    const handleClose = () => {
        setShowAddRowDialog(false);
    };

    return (
        <>
            <Box style={{ display: "flex", justifyContent: "space-between" }} >
                <div />
                {(editEnabled || id === "new") &&
                    <Button onClick={handleAddNewRowClick} variant="contained" color="primary" style={{ marginBottom: 30 }}>
                        {Translations.AddTransportToInvoice}
                    </Button>}
            </Box>
            {showAddRowDialog &&
                <TransportOrderInvoiceRowDialog
                    title={Translations.AddTransportToInvoice}
                    open={showAddRowDialog}
                    onClose={handleClose}
                    selectedInvoiceCustomerId={invoiceCustomer}
                />
            }
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><Typography variant="h4" color="success.main">{Translations.Transports}</Typography></TableCell>
                        <TableCell style={titleColor}>{Translations.Definition}</TableCell>
                        {/* TODO: product number to invoiceDetails
                        <TableCell style={ titleColor }>{Translations.ProductNumber}</TableCell> */}
                        <TableCell style={titleColor}>{Translations.Amount}</TableCell>
                        <TableCell style={titleColor}>{Translations.Unit}</TableCell>
                        <TableCell style={titleColor}>{Translations.UnitPriceEUR}</TableCell>
                        <TableCell style={titleColor}>{Translations.TaxFree}</TableCell>
                        <TableCell style={titleColor}>{Translations.TaxInclusive}</TableCell>
                        <TableCell style={titleColor}>{Translations.VatRate} %</TableCell>
                    </TableRow>
                </TableHead>
                {transportOrderRows.length === 0 ? (
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={9}>
                                <i>{Translations.NoInvoiceRows}</i>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                        {transportOrderRows.map((transportOrder) => (
                            <React.Fragment key={transportOrder.orderNumber}>
                                {/* Title row */}
                                <TableRow style={{ backgroundColor: "#f0f0f0", height: "15px" }}>
                                    <TableCell >
                                        <Typography color="primary.dark" fontWeight="bold">{transportOrder.orderNumber}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography color="success.main" fontWeight="bold">{transportOrder.name}</Typography>
                                    </TableCell>
                                    <TableCell colSpan={7} align="right">
                                        {editEnabled &&
                                            <Button onClick={() => handleRemoveOrderFromInvoice(transportOrder?.orderId)}>
                                                {Translations.RemoveOrder}
                                            </Button>}
                                    </TableCell>
                                </TableRow>
                                {/* Data rows */}
                                {transportOrder.rows.map((row) => (
                                    <TableRow key={row.id}>
                                        {row.rowType === 1 &&
                                            <>
                                                <TableCell />
                                                <TableCell style={{ fontWeight: "bold" }}>{row.description}</TableCell>
                                                {/* TODO: Add product number, this requires a change in the service */}
                                                {/* <TableCell>{row.productNumber}</TableCell> */}
                                                <TableCell>{row.amount}</TableCell>
                                                <TableCell>{Translations.MeasureUnitPcs}</TableCell>
                                                <TableCell>{row.unitPrice?.toFixed(2)}</TableCell>
                                                <TableCell>{(row.amount * row.unitPrice)?.toFixed(2)}</TableCell>
                                                <TableCell>{(row.amount * row.unitPrice * (1 + row.vat / 100))?.toFixed(2)}</TableCell>
                                                <TableCell>{`${row.vat?.toFixed(0)} %`}</TableCell>
                                            </>}
                                        {row.rowType === 2 && row.description &&
                                            <>
                                                <TableCell />
                                                <TableCell colSpan={8}><i>{row.description}</i></TableCell>
                                            </>}
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        ))}
                    </TableBody>
                )}
            </Table>
            {transportOrderRows.length > 0 && id !== "new" &&

                <div style={{ marginTop: "40px" }}>
                    <TransportOrderInvoiceSumRow title={`${Translations.Total} EUR`} value={selectedInvoice.totalVatExclusiveSum} />
                    <TransportOrderInvoiceSumRow title={Translations.VatRate} value={selectedInvoice.totalVatSum} />
                    <TransportOrderInvoiceSumRow title={Translations.TaxInclusiveTotalEUR} value={selectedInvoice.totalVatInclusiveSum} />
                </div>
            }
        </>
    );
};
