import { InvoiceEditItemWorkOrder, IInvoiceEditItemWorkOrder } from "./invoiceEditItemWorkOrder";
import { Base } from "../../framework/base";
import { EnumHelper, InvoiceState } from "../common/enums";

export interface IInvoiceEditItem {
    id: string;
    number: number;
    customerId: string;
    customerName: string;
    customerErpReference: string;
    workOrderTypeId: string;
    workOrderTypeName: string;
    state: number;
    stateStr: string;
    invoiceDataDate: number;
    rowId: string;
    workOrders: IInvoiceEditItemWorkOrder[];

    hasBeenTransferred(): boolean;
}

export class InvoiceEditItem implements IInvoiceEditItem {
    id: string;
    number: number;
    customerId: string;
    customerName: string;
    customerErpReference: string;
    workOrderTypeId: string;
    workOrderTypeName: string;
    state: number;
    stateStr: string;
    invoiceDataDate: number;
    rowId: string;
    workOrders: InvoiceEditItemWorkOrder[];

    constructor();
    constructor(obj: IInvoiceEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.customerId = obj && obj.customerId || "";
        this.customerName = obj && obj.customerName || "";
        this.customerErpReference = obj && obj.customerErpReference || "";
        this.workOrderTypeId = obj && obj.workOrderTypeId || "";
        this.workOrderTypeName = obj && obj.workOrderTypeName || "";
        this.state = obj && obj.state || 0;
        this.stateStr = obj && obj.stateStr || "";
        this.invoiceDataDate = obj && obj.invoiceDataDate || 0;
        this.rowId = obj && obj.rowId || "";
        this.workOrders = Base.getTypedArray<InvoiceEditItemWorkOrder>(!Base.isNullOrUndefined(obj) ? obj.workOrders : null, InvoiceEditItemWorkOrder);
    }

    hasBeenTransferred(): boolean {
        return EnumHelper.isGreaterOrEqual(this.state, InvoiceState.Transferred);
    }
}