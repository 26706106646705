import React from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { FormikErrors } from "formik";
import Typography from "@mui/material/Typography";
import { ParcelForm } from "./parcelTable";


interface ParcelErrorsProps {
    errors: FormikErrors<ParcelForm>;
}

const ParcelErrorRow = (errors: [string, any], t: TFunction) => {
    const rowNumber = parseInt(errors[0]) + 1;
    const rowErrors = errors[1];
    if(!rowErrors) return "";
    return (
        <div key={`error-row-${rowNumber}`}>
            <ul>
                <li>{t("transport.parcel.row")} {rowNumber}: </li>
                <ul>
                    { Object.values(rowErrors).map((err, i) => {
                        return <li key={`error-${i}`}>{ err }</li>;
                    })}
                </ul>
            </ul>
        </div>
    );
};

export const ParcelErrors = ({ errors }: ParcelErrorsProps) => {
    const { t } = useTranslation();
    if(!errors?.rows) return;
    return (
        <>
            <Typography color={"error"} marginLeft={1}>{ t("transport.parcel.fixErrors") }</Typography>
            { Object.entries(errors?.rows).map(e => ParcelErrorRow(e, t)) }
        </>
    );
};
