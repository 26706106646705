export interface IRoutePointDragData {
    id: string;
    number: number;
}

export class RoutePointDragData implements IRoutePointDragData {
    id: string;
    number: number;

    constructor();
    constructor(obj: IRoutePointDragData);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
    }

    static setRoutePointDragData(ev: any, id: string, number: number) {
        ev.dataTransfer.setData("dragItemId", id);
        ev.dataTransfer.setData("dragItemNumber", number.toString(10));
    }

    static getRoutePointDragData(ev: any): IRoutePointDragData {
        const result = new RoutePointDragData();
        result.id = ev.dataTransfer.getData("dragItemId") as string;
        result.number = parseInt((ev.dataTransfer.getData("dragItemNumber") as string) || "0", 10);
        return result;
    }
}