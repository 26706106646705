// WorkOrderDialogInvoicedRecordsList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import * as workOrderService from "../../services/workOrderService";
import { ToolButton } from "../framework/toolButton";
import { Base } from "../../framework/base";
import { IIdTitle } from "../../models/common/idTitle";
import { DocumentInfoSave, IDocument, IDocumentInfoSave } from "../../models/document/document";
import { SaveData } from "../../framework/saveData";
import { IWorkOrderEditItemProductBooking, WorkOrderEditItemProductBooking, WorkOrderEditItemSaveProductBooking } from "../../models/work/workOrderEditItemProductBooking";
import { IWorkOrderEditProductItem, WorkOrderEditProductItem } from "../../models/work/workOrderEditProductItem";
import { WorkOrderDialogProductBookingDialog, WorkOrderDialogProductBookingListLine } from "./workOrderDialogProductBooking";
import { IEmployeeItem } from "../../models/employee/employeeItem";
import { UserParameters } from "../../models/employee/userParameters";

// WorkOrderDialogInvoicedRecordsList
export interface IWorkOrderDialogInvoicedRecordsListProp {
    title?: string;
    titleId?: string;
    titleClass?: string;
    isReadOnly: boolean;
    employeeIds: string[];
    workOrderId: string;
    //ProductBooking
    productBookings: IWorkOrderEditItemProductBooking[];
    products: IWorkOrderEditProductItem[];
    measureUnits: IIdTitle[];
    employees: IEmployeeItem[];
    //ProductBooking
    onProductBookingModified: (productBooking: IWorkOrderEditItemProductBooking) => void;
    onProductBookingRemoved: (id: string) => void;
}

interface IWorkOrderDialogInvoicedRecordsListState {
    selectedId: string;
    employees: IEmployeeItem[];
    //ProductBooking
    productBookingDialogProductBooking: IWorkOrderEditItemProductBooking;
    showProductBookingDialog: boolean;
}

export class WorkOrderDialogInvoicedRecordsList extends React.Component<IWorkOrderDialogInvoicedRecordsListProp, IWorkOrderDialogInvoicedRecordsListState> {
    getOrderedListOfEmployees = (props: IWorkOrderDialogInvoicedRecordsListProp): IEmployeeItem[] => {
        const userParameters = new UserParameters();
        const employeeId = userParameters.getEmployeeId();
        const primaryEmployees = props.employees.filter(i => i.id === employeeId || props.employeeIds.indexOf(i.id) > -1);
        const primaryEmployeeIds = primaryEmployees.map(i => i.id);
        const result = props.employees.filter(i => primaryEmployeeIds.indexOf(i.id) < 0);
        result.unshift(...primaryEmployees.filter(i => i.id !== employeeId));
        const employee = props.employees.find(i => i.id === userParameters.getEmployeeId());
        if (employee) {
            result.unshift(employee);
        }
        return result;
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedId: null,
            employees: this.getOrderedListOfEmployees(props),
            productBookingDialogProductBooking: null,
            showProductBookingDialog: false,
        };
    }

    componentDidUpdate(prevProps: IWorkOrderDialogInvoicedRecordsListProp, prevState: IWorkOrderDialogInvoicedRecordsListState): void {
        const props = this.props;
        if (prevProps.employeeIds.map(i => i).join("#") + prevProps.employees.map(i => i.id + "," + i.getTitle()).join("#") === props.employeeIds.map(i => i).join("#") + props.employees.map(i => i.id + "," + i.getTitle()).join("#")) return;
        this.setState({
            employees: this.getOrderedListOfEmployees(props)
        });
    }

    // #region ProductBooking
    getProductBookingDialogProducts = (): IWorkOrderEditProductItem[] => {
        const props = this.props;
        const productBookings = props.productBookings;
        const resultA = props.products.reduce((result, i) => {
            const productBooking = productBookings.find(j => j.productId === i.id);
            if (productBooking) {
                const k = new WorkOrderEditProductItem(i);
                k.unitPrice = productBooking.unitPrice;
                k.unit = productBooking.unit;
                result.push(k);
            } else {
                result.push(i);
            }
            return result;
        }, []);
        return resultA;
    };

    productBookingEdit = (productBooking: IWorkOrderEditItemProductBooking) => {
        if (!productBooking) return;
        this.setState({
            productBookingDialogProductBooking: productBooking,
            showProductBookingDialog: true,
        });
    };

    handleProductBookingAdd = () => {
        const props = this.props;
        const state = this.state;
        if (props.isReadOnly) return;
        const product = props.products[0];
        const productBooking = new WorkOrderEditItemProductBooking();
        productBooking.id = Base.getGuid();
        productBooking.productId = product.id;
        const oldProductBooking = props.productBookings.find(i => i.productId === product.id);
        if (oldProductBooking) {
            productBooking.unitPrice = oldProductBooking.unitPrice;
            productBooking.unit = oldProductBooking.unit;
        } else {
            productBooking.unitPrice = product.unitPrice;
            productBooking.unit = product.unit;
        }
        productBooking.amount = 0;
        productBooking.cost = 0;
        productBooking.employeeId = state.employees.length > 0 ? state.employees[0].id : "";
        this.productBookingEdit(productBooking);
    };

    saveProductBooking = (productBooking: IWorkOrderEditItemProductBooking, removedDocumentIds: string[]) => {
        const obj = this;
        const props = this.props;
        const oldProductBooking = props.productBookings.find(i => i.id === productBooking.id);
        const oldDocuments = oldProductBooking ? oldProductBooking.documents.slice(0) : [];
        //Create savedata
        const productBookings = [productBooking];
        const saveData = new SaveData();
        saveData.append("id", this.props.workOrderId);
        saveData.append("productBookings", JSON.stringify(productBookings.reduce((result, i) => {
            result.push(new WorkOrderEditItemSaveProductBooking(i));
            return result;
        }, [])));
        saveData.append("removedDocumentIds", JSON.stringify(removedDocumentIds));
        saveData.append("removedDocumentProductBookingIds", JSON.stringify(removedDocumentIds.map(i => productBooking.id)));
        const documentAdds: IDocumentInfoSave[] = [];
        const documentUpdates: IDocumentInfoSave[] = [];
        const productBookingDocumentIds: string[] = [];
        for (let i = 0; i < productBooking.documents.length; i++) {
            const document = productBooking.documents[i];
            const file = document.file;
            const isOld = Base.isNullOrUndefined(file);
            if (isOld) {
                const oldDocument = oldDocuments.find(j => j.id === document.id);
                if (!oldDocument || oldDocument.comment === document.comment) continue;
                const documentSave = new DocumentInfoSave(document);
                documentSave.relatedObjectId = productBooking.id;
                documentUpdates.push(documentSave);
            } else {
                productBookingDocumentIds.push(productBooking.id);
                saveData.append("documents[]", file, file.name);
                const documentAdd = new DocumentInfoSave(document);
                documentAdd.relatedObjectId = productBooking.id;
                documentAdds.push(documentAdd);
            }
        }
        saveData.append("documentProductBookingIds", JSON.stringify(productBookingDocumentIds));
        saveData.append("documentAdds", JSON.stringify(documentAdds));
        saveData.append("documentUpdates", JSON.stringify(documentUpdates));
        //Save to db
        store.customStore.dispatch(storeActions.fetchStart());
        workOrderService.saveWorkOrderProductBookings(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                obj.setState({
                    selectedId: success.ids[0],
                    showProductBookingDialog: false
                });
                productBooking.id = success.ids[0];
                productBooking.rowId = success.rowIds[0];
                for (let i = 0; i < productBooking.documents.length; i++) {
                    const document = productBooking.documents[i];
                    const newDocumentRowId = success.documentRowIds[document.id];
                    if (!newDocumentRowId) {
                        document.rowId = newDocumentRowId;
                    }
                    const newDocumentId = success.documentIds[document.id];
                    if (!newDocumentId) {
                        document.id = newDocumentId;
                    }
                    if (!document.file) continue;
                    document.file = null;
                }
                props.onProductBookingModified(productBooking);
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleProductBookingDialogOk = (emplyeeId: string, productId: string, amount: number, unitPrice: number, unit: number, cost: number, comment: string, documents: IDocument[], removedDocumentIds: string[]) => {
        const props = this.props;
        if (props.isReadOnly) return;
        const productBooking = this.state.productBookingDialogProductBooking;
        productBooking.employeeId = emplyeeId;
        productBooking.productId = productId;
        productBooking.amount = amount;
        productBooking.unitPrice = unitPrice;
        productBooking.unit = unit;
        productBooking.cost = cost;
        productBooking.comment = comment;
        productBooking.documents = documents.slice(0);
        this.saveProductBooking(productBooking, removedDocumentIds);
    };

    handleProductBookingDialogCancel = () => {
        this.setState({
            showProductBookingDialog: false
        });
    };

    removeProductBooking = (id: string) => {
        const props = this.props;
        //Create savedata
        const productBookingIds = [id];
        const saveData = new SaveData();
        saveData.append("id", props.workOrderId);
        saveData.append("productBookingIds", JSON.stringify(productBookingIds));
        //Save to db
        store.customStore.dispatch(storeActions.fetchStart());
        workOrderService.removeWorkOrderProductBookings(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                props.onProductBookingRemoved(id);
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };
    // #endregion ProductBooking

    handleEdit = () => {
        const props = this.props;
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        const productBooking = props.productBookings.find(i => i.id === selectedId);
        if (productBooking) {
            this.productBookingEdit(productBooking);
        }
    };

    handleRemove = () => {
        const props = this.props;
        if (props.isReadOnly) return;
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        const productBooking = props.productBookings.find(i => i.id === selectedId);
        if (productBooking) {
            this.removeProductBooking(selectedId);
        }
    };

    handleLineClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleLineDoubleClick = (id: string) => {
        this.handleEdit();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const productBookings = props.productBookings;
        return (
            <div className="workOrderSubList">
                <div className="commandRow">
                    {!!props.title &&
                        <label id={props.titleId} className={"control-label listTitle" + (props.titleClass ? " " + props.titleClass : "")}>{props.title}</label>
                    }
                    {!props.isReadOnly &&
                        <ToolButton
                            title={Translations.Remove}
                            enabled={!!state.selectedId}
                            classes={"round right remove"}
                            onClick={this.handleRemove}
                        />
                    }
                    <ToolButton
                        title={Translations.Edit}
                        enabled={!!state.selectedId}
                        classes={"round right edit"}
                        onClick={this.handleEdit}
                    />
                    {!props.isReadOnly &&
                        <ToolButton
                            title={Translations.Add}
                            enabled={true}
                            classes={"round right add"}
                            onClick={this.handleProductBookingAdd}
                        />
                    }
                </div>
                <div className="listContainer invoicedRecordsContainer">
                    {productBookings.length > 0 &&
                        <div className="list invoicedRecords">
                            {productBookings.map((productBooking) =>
                                <WorkOrderDialogProductBookingListLine
                                    key={productBooking.id}
                                    employees={state.employees}
                                    productBooking={productBooking}
                                    products={props.products}
                                    measureUnits={props.measureUnits}
                                    selectedId={state.selectedId}
                                    onClick={this.handleLineClick}
                                    onDoubleClick={this.handleLineDoubleClick}
                                />
                            )}
                        </div>
                    }
                </div>
                {state.showProductBookingDialog && !!state.productBookingDialogProductBooking &&
                    <WorkOrderDialogProductBookingDialog
                        isReadOnly={props.isReadOnly}
                        productBooking={state.productBookingDialogProductBooking}
                        employees={state.employees}
                        products={this.getProductBookingDialogProducts()}
                        measureUnits={props.measureUnits}
                        onOk={this.handleProductBookingDialogOk}
                        onCancel={this.handleProductBookingDialogCancel}
                    />
                }
            </div>
        );
    }
}
