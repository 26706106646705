import React from "react";

export class ErrorBoundary extends React.Component<{children?: React.ReactNode}> {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      console.log({ error, errorInfo });
    }
  
    render() {
      return this.props.children; 
    }
  }