export interface ISalaryRowTypeItem {
    id: string;
    code: string;
    name: string;
    measureUnit: string;
    decimals: number;
    readOnly: boolean;
    hourBookingCategories: number[];
    hourBookingCategoryNames: string[];
    salaryRowTypeKindCode: string;
}

export class SalaryRowTypeItem implements ISalaryRowTypeItem {
    id: string;
    code: string;
    name: string;
    measureUnit: string;
    decimals: number;
    readOnly: boolean;
    hourBookingCategories: number[];
    hourBookingCategoryNames: string[];
    salaryRowTypeKindCode: string;

    constructor();
    constructor(obj: ISalaryRowTypeItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.code = obj && obj.code || "";
        this.name = obj && obj.name || "";
        this.measureUnit = obj && obj.measureUnit || "";
        this.decimals = obj && obj.decimals || 0;
        this.readOnly = obj && obj.readOnly || false;
        this.hourBookingCategories = obj && obj.hourBookingCategories || [];
        this.hourBookingCategoryNames = obj && obj.hourBookingCategoryNames || [];
        this.salaryRowTypeKindCode = obj && obj.salaryRowTypeKindCode || "";
    }
}

export enum SalaryRowTypeKindCode {
    Absence = "a",
    Hourbooking = "h",
    Vacation = "v",
}