export interface ISalaryWorkTimePeriod {
    id: string;
    periodTypeId: string;
    startDate: number;
    endDate: number;
    previousId: string;
    nextId: string;
}

export class SalaryWorkTimePeriod implements ISalaryWorkTimePeriod {
    id: string;
    periodTypeId: string;
    startDate: number;
    endDate: number;
    previousId: string;
    nextId: string;

    constructor();
    constructor(obj: ISalaryWorkTimePeriod);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.periodTypeId = obj && obj.periodTypeId || "";
        this.startDate = obj && obj.startDate || 0;
        this.endDate = obj && obj.endDate || 0;
        this.previousId = obj && obj.previousId || "";
        this.nextId = obj && obj.nextId || "";
    }
}
