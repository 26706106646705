export interface ISalaryRowTypeEditItem {
    id: string;
    salaryRowTypeKindId: string;
    code: string;
    name: string;
    measureUnit: string;
    rowId: string;
    hourBookingCategories: string[];
    isUsedByDayBookingType: boolean;

    isNew():boolean;
}

export class SalaryRowTypeEditItem implements ISalaryRowTypeEditItem {
    id: string;
    salaryRowTypeKindId: string;
    code: string;
    name: string;
    measureUnit: string;
    rowId: string;
    hourBookingCategories: string[];
    isUsedByDayBookingType: boolean;

    constructor();
    constructor(obj: ISalaryRowTypeEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.salaryRowTypeKindId = obj && obj.salaryRowTypeKindId || "";
        this.code = obj && obj.code || "";
        this.name = obj && obj.name || "";
        this.measureUnit = obj && obj.measureUnit || "";
        this.rowId = obj && obj.rowId || "";
        this.hourBookingCategories = obj && obj.hourBookingCategories || [];
        this.isUsedByDayBookingType = obj && obj.isUsedByDayBookingType;
    }

    isNew():boolean {
        return !this.rowId;
    }
}
