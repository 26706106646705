// SettingsProductDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "../../framework/base";
import * as store from "../../framework/customStore";
import { SaveData } from "../../framework/saveData";
import { CustomerPriceEditItem, ICustomerPriceEditItem, SaveCustomerPriceEditItem } from "../../models/customerPrice/customerPriceEditItem";
import { UserParameters } from "../../models/employee/userParameters";
import { IProductEdit } from "../../models/product/productEdit";
import { ProductItem } from "../../models/product/productItem";
import * as baseService from "../../services/baseService";
import * as productService from "../../services/productService";
import * as storeActions from "../../models/store/storeActions";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { Translations } from "../../models/translations";
import { CheckBox } from "../framework/checkbox";
import { PropertyDialog } from "../framework/dialog";
import { PricePerUnit } from "../framework/pricePerUnit";
import { SettingsCustomerPriceList } from "./settingsCustomerPriceList";
import { ICustomerItem } from "../../models/customer/customerItem";
import { AutocompleteOption } from "../framework/muiAutocomplete";

// SettingsProductDialog
// ***********************************************************************************************************************
export interface ISettingsProductDialogProp {
    classes?: string;
    editItem: IProductEdit;
    onOk: (item: AutocompleteOption) => void;
    onCancel: () => void;
}

export interface ISettingsProductDialogState {
    productGroupId: string;
    name: string;
    description: string;
    unitStr: string;
    unitPriceStr: string;
    erp: string;
    comment: string;
    activeState: number;
    customerPrices: ICustomerPriceEditItem[];
    availableCustomerPriceCustomers: ICustomerItem[];
    usedCustomerPriceCustomers: ICustomerItem[];
    removedCustomerPriceIds: string[];
    savedCustomerPriceIds: string[];
}

export class SettingsProductDialog extends React.Component<ISettingsProductDialogProp, ISettingsProductDialogState> {
    private static orgStateHash: string = "";

    getCustomerPriceCustomers = (customerPrices: ICustomerPriceEditItem[], allCustomers: ICustomerItem[]): { availableProducts: ICustomerItem[]; usedProducts: ICustomerItem[] } => {
        const customerPriceCustomerIds = customerPrices.map(i => i.customerId);
        return {
            availableProducts: allCustomers.filter(i => customerPriceCustomerIds.indexOf(i.id) < 0),
            usedProducts: allCustomers.filter(i => customerPriceCustomerIds.indexOf(i.id) > -1)
        };
    };

    constructor(props) {
        super(props);
        const product = props.editItem.product;
        const customerPriceCustomers = this.getCustomerPriceCustomers(product.customerPrices, props.editItem.customers);
        this.state = {
            productGroupId: product.productGroupId,
            name: product.name,
            description: product.description,
            unitStr: product.unit.toString(10),
            unitPriceStr: product.unitPrice.toLocaleFixed(Base.getDecimalAmountForPrice(product.unitPrice)),
            erp: product.erp,
            comment: product.comment,
            activeState: product.activeState,
            customerPrices: product.customerPrices,
            availableCustomerPriceCustomers: customerPriceCustomers.availableProducts,
            usedCustomerPriceCustomers: customerPriceCustomers.usedProducts,
            removedCustomerPriceIds: [],
            savedCustomerPriceIds: [],
        };
        const saveData = SettingsProductDialog.getSaveDataFromState(props, this.state);
        SettingsProductDialog.orgStateHash = saveData.hash;
    }

    // #region General
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "Nm") {
            this.setState({ name: value });
        } else if (name === "productGroupId") {
            this.setState({ productGroupId: value });
        } else if (name === "unit") {
            this.setState({ unitStr: value });
        } else if (name === "unitPrice") {
            this.setState({ unitPriceStr: value });
        } else if (name === "erp") {
            this.setState({ erp: value });
        } else if (name === "description") {
            this.setState({ description: value });
        } else if (name === "comment") {
            this.setState({ comment: value });
        }
    };

    handleBlur = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "unitPrice") {
            this.setState({ unitPriceStr: value.toDecimal().toLocaleFixed(Base.getDecimalAmountForPrice(value.toDecimal())) });
        }
    };

    handleActiveStateChange = (newActiveState: number) => {
        this.setState({ activeState: newActiveState });
    };
    // #endregion General

    // #region CustomerPrice
    handleCustomerPriceAdd = (customerId: string, unitPrice: number) => {
        const props = this.props;
        const state = this.state;
        const customerPrices = state.customerPrices.slice(0);
        const savedCustomerPriceIds = state.savedCustomerPriceIds.slice(0);
        const item = new CustomerPriceEditItem();
        item.id = Base.getGuid();
        item.productId = props.editItem.product.id;
        item.customerId = customerId;
        item.unitPrice = unitPrice;
        customerPrices.push(item);
        savedCustomerPriceIds.push(item.id);
        CustomerPriceEditItem.setSortValuesByCustomer(customerPrices, props.editItem.customers);
        CustomerPriceEditItem.sortCustomerPriceEditItems(customerPrices, true);
        const customerPriceCustomers = this.getCustomerPriceCustomers(customerPrices, props.editItem.customers);
        this.setState({
            customerPrices: customerPrices,
            savedCustomerPriceIds: savedCustomerPriceIds,
            availableCustomerPriceCustomers: customerPriceCustomers.availableProducts,
            usedCustomerPriceCustomers: customerPriceCustomers.usedProducts
        });
    };

    handleCustomerPriceEdit = (customerId: string, unitPrice: number) => {
        const state = this.state;
        const customerPrices = state.customerPrices.slice(0);
        const item = customerPrices.find(i => i.customerId === customerId);
        if (!item) return;
        item.unitPrice = unitPrice;
        const savedCustomerPriceIds = state.savedCustomerPriceIds.filter(i => i !== item.id);
        savedCustomerPriceIds.push(item.id);
        this.setState({
            customerPrices: customerPrices,
            savedCustomerPriceIds: savedCustomerPriceIds
        });
    };

    handleCustomerPriceRemove = (id: string) => {
        const props = this.props;
        const state = this.state;
        const customerPrices = state.customerPrices.filter(c => c.id !== id);
        const savedCustomerPriceIds = state.savedCustomerPriceIds.filter(i => i !== id);
        const removedCustomerPrices = state.removedCustomerPriceIds.slice(0);
        removedCustomerPrices.push(id);
        const customerPriceCustomers = this.getCustomerPriceCustomers(customerPrices, props.editItem.customers);
        this.setState({
            customerPrices: customerPrices,
            removedCustomerPriceIds: removedCustomerPrices,
            savedCustomerPriceIds: savedCustomerPriceIds,
            availableCustomerPriceCustomers: customerPriceCustomers.availableProducts,
            usedCustomerPriceCustomers: customerPriceCustomers.usedProducts
        });
    };
    // #endregion CustomerPrice

    private static validate = (state: ISettingsProductDialogState): boolean => {
        if (!state.name) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.NameMustBeDefined));
            return false;
        }
        return true;
    };

    private static getSaveDataFromState = (props: ISettingsProductDialogProp, state: ISettingsProductDialogState): SaveData => {
        const data = new SaveData();
        const product = props.editItem.product;
        // Common
        data.append("id", product.id);
        data.append("rowId", product.rowId);
        // General
        data.append("productGroupId", state.productGroupId);
        data.append("name", state.name);
        data.append("description", state.description);
        data.append("unit", state.unitStr.toInteger().toString(10));
        data.append("unitPrice", state.unitPriceStr);
        data.append("erp", state.erp);
        data.append("comment", state.comment);
        data.append("activeState", state.activeState.toString(10));
        // CustomerPrices
        data.append("customerPrices", JSON.stringify(state.customerPrices.reduce((result, i) => {
            if (state.savedCustomerPriceIds.indexOf(i.id) >= 0) {
                result.push(new SaveCustomerPriceEditItem(i));
            }
            return result;
        }, [])));
        data.append("removedCustomerPriceIds", JSON.stringify(state.removedCustomerPriceIds));
        return data;
    };

    saveEditItem = () => {
        const obj = this;
        if (!SettingsProductDialog.validate(this.state)) return;
        const saveData = SettingsProductDialog.getSaveDataFromState(this.props, this.state);
        if (Base.isNullOrUndefined(saveData)) return;
        // Call server
        store.customStore.dispatch(storeActions.fetchStart());
        productService.saveProductEdit(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                obj.props.onOk({ id: success.id, title: this.state.name });
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleOkClick = () => {
        this.saveEditItem();
    };

    handleCancelClick = () => {
        const obj = this;
        const saveData = SettingsProductDialog.getSaveDataFromState(this.props, this.state);
        if (!Base.isNullOrUndefined(saveData) && saveData.hash !== SettingsProductDialog.orgStateHash) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.saveEditItem();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.props.onCancel();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));
        } else {
            obj.props.onCancel();
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        const editItem = props.editItem;
        const dialogClasses = "settings product px800" + (props.classes ? " " + props.classes : "");
        const priceUnit = editItem.measureUnits.find(m => m.id === this.state.unitStr)?.title;
        const product = new ProductItem();
        product.id = editItem.product.id;
        product.name = this.state.name;
        product.unit = editItem.measureUnits.find(m => m.id === this.state.unitStr)?.title;
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.Product + " - " + (!editItem.product.isNew() ? editItem.product.number.toString(10) + " " + editItem.product.name : Translations.New)}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-2">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.Number}</label>
                                    <input type="text" className="form-control" name="number" title={Translations.Number} value={editItem.product.isNew() ? Translations.New : editItem.product.number.toString(10)} readOnly={true} disabled={true} />
                                </div>
                            </div>
                            <div className="col-10">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.ProductGroup}</label>
                                    <select className="custom-select" name="productGroupId" title={Translations.Product} value={this.state.productGroupId} onChange={this.handleChange}>
                                        {editItem.productGroups.map((productGroup) =>
                                            <option key={productGroup.id} value={productGroup.id}>{productGroup.code + " " + productGroup.name}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-10">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.Name}</label>
                                    <input type="text" className="form-control" name="Nm" title={Translations.Name} value={this.state.name} onChange={this.handleChange} maxLength={50} />
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="form-group">
                                    <label className="control-label smallFont">&nbsp;</label>
                                    <div>
                                        <CheckBox
                                            title={Translations.InUse}
                                            enabled={true}
                                            checked={this.state.activeState > 0}
                                            onCheckboxClickBoolean={(checked: boolean) => { this.handleActiveStateChange(checked ? 1 : 0); }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Description}</label>
                                    <textarea className="form-control" name="description" title={Translations.Description} value={this.state.description} onChange={this.handleChange} maxLength={1000} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Comment}</label>
                                    <textarea className="form-control" name="comment" title={Translations.Comment} value={this.state.comment} onChange={this.handleChange} maxLength={1000} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className={UserParameters.isOwnerAdmin() ? "col-4" : "col-8"}>
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.ErpReference}</label>
                                    <input type="text" className="form-control" name="erp" title={Translations.ErpReference} value={this.state.erp} onChange={this.handleChange} maxLength={50} />
                                </div>
                            </div>
                            {UserParameters.isOwnerAdmin() &&
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className="control-label smallFont">
                                            {Translations.UnitPrice}&nbsp;
                                            <PricePerUnit unit={priceUnit} wrapInParenthesis={true} />
                                        </label>
                                        <input type="text" className="form-control" name="unitPrice" title={Translations.UnitPrice} value={this.state.unitPriceStr} onChange={this.handleChange} onBlur={this.handleBlur} maxLength={6} />
                                    </div>
                                </div>
                            }
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.MeasureUnit}</label>
                                    <select className="form-control" name="unit" title={Translations.MeasureUnit} value={this.state.unitStr} onChange={this.handleChange}>
                                        {editItem.measureUnits.map((measureUnit) =>
                                            <option key={measureUnit.id} value={measureUnit.id}>{measureUnit.title}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <SettingsCustomerPriceList
                                        inCustomerDialog={false}
                                        product={product}
                                        availableProducts={[product]}
                                        usedProducts={[product]}
                                        availableCustomers={state.availableCustomerPriceCustomers}
                                        usedCustomers={state.usedCustomerPriceCustomers}
                                        title={Translations.CustomerPrices}
                                        readOnly={false}
                                        items={this.state.customerPrices}
                                        onRemoveCustomerPrice={this.handleCustomerPriceRemove}
                                        onAddCustomerPrice={this.handleCustomerPriceAdd}
                                        onEditCustomerPrice={this.handleCustomerPriceEdit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}
