import { Base } from "../../framework/base";

export interface IWorkOrderEditItemRoutePointCheckBooking {
    id: string;
    number: number;
    checkName: string;
    checkRef: string;
    comment: string;
    bookingTime: number;
    rowId: string;

    isNew():boolean;
}

export class WorkOrderEditItemRoutePointCheckBooking implements IWorkOrderEditItemRoutePointCheckBooking {
    id: string;
    number: number;
    checkName: string;
    checkRef: string;
    comment: string;
    bookingTime: number;
    rowId: string;

    constructor();
    constructor(obj: IWorkOrderEditItemRoutePointCheckBooking);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.checkName = obj && obj.checkName || "";
        this.checkRef = obj && obj.checkRef || "";
        this.comment = obj && obj.comment || "";
        this.bookingTime = obj && obj.bookingTime || 0;
        this.rowId = obj && obj.rowId || "";
    }

    isNew():boolean {
        return !this.rowId;
    }

    static sortWorkOrderEditItemRoutePointCheckBookings(items: WorkOrderEditItemRoutePointCheckBooking[], column: string, asc: boolean) {
        if (Base.isNullOrUndefined(items) || items.length < 2) return;
        const sortFunc = (a: WorkOrderEditItemRoutePointCheckBooking, b: WorkOrderEditItemRoutePointCheckBooking) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.number, b.number)); };
        items.sort(sortFunc);
    }
}

export class WorkOrderEditItemSaveRoutePointCheckBooking {
    id: string;
    number: number;
    checkName: string;
    checkRef: string;
    comment: string;
    bookingTime: number;
    rowId: string;

    constructor();
    constructor(obj: IWorkOrderEditItemRoutePointCheckBooking);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.checkName = obj && obj.checkName || "";
        this.checkRef = obj && obj.checkRef || "";
        this.comment = obj && obj.comment || "";
        this.bookingTime = obj && obj.bookingTime || 0;
        this.rowId = obj && obj.rowId || "";
    }
}