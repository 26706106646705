import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Box, Button, Typography } from "@mui/material";
import { Translations } from "../../../models/translations";
import { TransportOrderSelectedOrderCard } from "./TransportOrderSelectedOrderCard";
import { TransportOrderListItemDto } from "../../../models/transport/transportOrder";
import { apiCall } from "../../../services/apiClient";
import { InvoiceCreateDto } from "../../../models/transportOrderInvoice/invoiceCreateDto";
import { showErrorMessage, showSuccessMessage } from "../../../models/store/storeActions";
import { useAppDispatch } from "../../../framework/customStore";
import { customHistory } from "../../../framework/customHistory";
import { showApiError } from "../../framework/formUtils";

export interface TransportOrderInvoiceSelectedOrdersProps {
    selectedOrders: TransportOrderListItemDto[];
}

export const TransportOrderInvoiceSelectedOrders = (
    { selectedOrders }: TransportOrderInvoiceSelectedOrdersProps) => {
    const dispatch = useAppDispatch();

    const newInvoiceData: InvoiceCreateDto = {
        reference: "",
        customerId: selectedOrders[0]?.billingCustomerId,
        invoiceMessage: "",
    };

    const fetchInvoices = () => {
        const transportOrders = selectedOrders.map(i => i.id);
        apiCall("invoices",
            "POST",
            newInvoiceData
        ).then(res => {
            const idFromResponse: string = res.data.id;
            if (res) {
                void apiCall(`invoices/${idFromResponse}/transportorders`,
                    "PUT",
                    transportOrders)
                    .then(response => {
                        if (response) {
                            dispatch(showSuccessMessage(Translations.CreateInvoiceSuccess));
                            customHistory.push(
                                `/invoicingbeta/transportorderinvoice/${idFromResponse}`,
                                { from: "/invoicingbeta/transportorders" }
                            );
                        } else {
                            dispatch(showErrorMessage(Translations.FailedToCreateInvoice));
                        }
                    }).catch(() => { dispatch(showErrorMessage(Translations.FailedToCreateInvoice)); });
            } else {
                dispatch(showErrorMessage(Translations.FailedToCreateInvoice));
            }
        }).catch(showApiError);
    };

    const handleCreateNewInvoice = () => {
        if (selectedOrders.length >= 1) {
            fetchInvoices();
        } else {
            customHistory.push(
                "/invoicingbeta/transportorderinvoice/new",
                { from: "/invoicingbeta/transportorders" }
            );
        }
    };

    return (
        <Box p={1} minWidth={400}>

            <Grid2 container alignItems="center" marginBottom={4}>
                <Grid2 xs={6}>
                    <Typography variant="h3" sx={{ marginBottom: 0 }}>{Translations.SelectedOrders}</Typography>
                </Grid2>
                <Grid2 xs={6} container justifyContent="flex-end">
                    <Button
                        color="primary"
                        variant={"contained"}
                        onClick={() => handleCreateNewInvoice()}
                    >
                        {Translations.CreateNewInvoice}
                    </Button>
                </Grid2>
            </Grid2>
            {selectedOrders?.length > 0 ? (
                selectedOrders.map((item) => (
                    <TransportOrderSelectedOrderCard
                        key={item.id}
                        item={item}
                    />
                ))
            ) : (
                <div><i>{Translations.NoSelectedOrders}</i></div>
            )}
        </Box>
    );
};

