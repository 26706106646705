import { Base } from "../../framework/base";
import { IIdTitle, IdTitle } from "../common/idTitle";
import { IDayBookingEditItem, DayBookingEditItem } from "./dayBookingEditItem";

export interface IDayBookingEdit {
    dayBooking: IDayBookingEditItem;
    dayBookingTypes: IIdTitle[];
}

export class DayBookingEdit implements IDayBookingEdit {
    dayBooking: DayBookingEditItem;
    dayBookingTypes: IdTitle[];

    constructor();
    constructor(obj: IDayBookingEdit);
    constructor(obj?: any) {
        this.dayBooking = null;
        if (obj && obj.dayBooking) {
            this.dayBooking = new DayBookingEditItem(obj.dayBooking);
        }
        this.dayBookingTypes = Base.getTypedArray<IdTitle>(obj ? obj.dayBookingTypes : null, IdTitle);
    }
}