// LocationMapDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import { SelectDialog } from "./dialog";
import { ILocationPoint } from "../../models/common/locationPoint";
import { LocationMap } from "./locationMap";
import { LocationOn as LocationOnIcon } from "@mui/icons-material";

// LocationMapDialog
export interface ILocationMapDialogProps {
    classes?: string;
    title?: string;
    points: ILocationPoint[];
    selectedIds: string[];
    onClose: () => void;
}

export class LocationMapDialog extends React.Component<ILocationMapDialogProps, {}> {
    render() {
        const props = this.props;
        const dialogClasses = "locationMapDialog px600" + (props.classes ? " " + props.classes : "");
        return (
            <SelectDialog
                classes={dialogClasses}
                title={props.title}
                show={true}
                body={
                    <LocationMap
                        points={props.points}
                        selectedIds={props.selectedIds}
                    />
                }
                buttons={[{ title: Translations.Close, classes: "btn-default", enabled: true, onClick: props.onClose }]}
                onClose={props.onClose}
            />
        );
    }
}

interface ILocationIconProps {
    title: string;
    latitude: number;
    longitude: number;
    className?: string;
    handleClick: (locationName: string, latitude: number, longitude: number) => void;
}

export const LocationIcon = ({ title, latitude, longitude, className = "", handleClick }: ILocationIconProps) => {
    if (!latitude || !longitude) {
        return null;
    }

    return <LocationOnIcon color="primary" className={`cursor-pointer ${className}`} onClick={() => handleClick(title, latitude, longitude)} />;
};