/* global DisplaceJSObject, JSX */
// Dialog
// ***********************************************************************************************************************
import * as React from "react";
import displace from "displacejs";
import { Button } from "./button";
import { Base } from "../../framework/base";
import { ButtonDropdown, IButtonDropdownAction } from "./dropdown";

export interface IDialogButton {
    title?: any;
    classes: string;
    enabled: boolean;
    onClick: (id?: string) => void;
    icon?: string;
    mainButton?: boolean;
}

export interface IDialogProp {
    classes?: string;
    title: string | JSX.Element;
    show: boolean;
    body: string | JSX.Element;
    buttons?: IDialogButton[];
    initialHeightOffset?: number;
    onClose?: () => void;
}

export class Dialog extends React.Component<IDialogProp, {}> {
    private dialogContainer: HTMLDivElement;
    private dialogDiv: HTMLDivElement;
    private headerDiv: HTMLDivElement;
    private displaceJs: DisplaceJSObject;

    constructor(props) {
        super(props);
        this.state = { show: props.show };
    }

    handleButtonClick = (index?: number) => {
        const i = index !== null ? index : 0;
        if (i < 0 || i >= this.props.buttons.length) return;
        if (!this.props.buttons[i].enabled) return;
        this.props.buttons[i].onClick();
    };

    // ReSharper disable UnusedParameter
    handleCloseClick = (e) => {
        if (!Base.isNullOrUndefined(this.props.onClose)) {
            this.props.onClose();
        }
    };
    // ReSharper restore UnusedParameter

    componentDidMount(): void {
        const containerRect = this.dialogContainer.getBoundingClientRect();
        const dialogRect = this.dialogDiv.getBoundingClientRect();
        this.dialogDiv.style.left = Math.floor(Math.max((containerRect.width - dialogRect.width) / 2, 0)).toString(10) + "px";
        this.dialogDiv.style.top = Math.floor(Math.min(Math.max((containerRect.height - (dialogRect.height + (this.props.initialHeightOffset ?? 0))) / 2, 0), containerRect.height / 5)).toString(10) + "px";
        this.displaceJs = displace(this.dialogDiv, { handle: this.headerDiv, constrain: true, relativeTo: this.dialogContainer });
    }

    componentWillUnmount(): void {
        if (Base.isNullOrUndefined(this.displaceJs)) return;
        this.displaceJs.destroy();
    }

    render() {
        const props = this.props;
        // Render nothing if the "show" prop is false
        if (!props.show) {
            return null;
        }
        const titleTooltip = typeof props.title === "string"
            ? props.title
            : null;
        // Create button
        const dialogButtons: JSX.Element[] = [];

        if (props.buttons) {
            for (let i = 0; i < props.buttons.length; i++) {
                if (props.buttons[i].onClick === null) continue;
                dialogButtons.push(<Button key={i} index={i} classes={props.buttons[i].classes} title={props.buttons[i].title} enabled={props.buttons[i].enabled} onClick={this.handleButtonClick} />);
            }
        }

        const dialogClasses = "jj-dialog modal fade in show" + (props.classes ? " " + props.classes : "");
        const hideFooter = {
            display: "none"
        };

        return (
            <div className={dialogClasses} ref={(div) => { this.dialogContainer = div; }} >
                <div className="modal-dialog" ref={(div) => { this.dialogDiv = div; }} >
                    <div className="modal-content">
                        <div className="modal-header draggable" ref={(div) => { this.headerDiv = div; }} >
                            <h5 className="modal-title" title={titleTooltip}>{props.title}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseClick}>
                                <span className="icon close" aria-hidden="true" />
                            </button>
                        </div>

                        <div className="modal-body">
                            {props.body}
                        </div>
                        <div className="modal-footer" style={props.buttons ? {} : hideFooter }>
                            {dialogButtons}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// PropertyDialog
// ***********************************************************************************************************************
export class PropertyDialog extends React.Component<IDialogProp, {}> {
    private dialogContainer: HTMLDivElement;
    private dialogDiv: HTMLDivElement;
    private headerDiv: HTMLDivElement;
    private displaceJs: DisplaceJSObject;

    constructor(props) {
        super(props);
        this.state = { show: props.show };
    }

    handleButtonClick = (dialogButton: IDialogButton) => {
        if (!dialogButton || !dialogButton.enabled) return;
        dialogButton.onClick();
    };

    // ReSharper disable UnusedParameter
    handleCloseClick = (e) => {
        if (!Base.isNullOrUndefined(this.props.onClose)) {
            this.props.onClose();
        }
    };
    // ReSharper restore UnusedParameter

    componentDidMount(): void {
        const containerRect = this.dialogContainer.getBoundingClientRect();
        const dialogRect = this.dialogDiv.getBoundingClientRect();
        this.dialogDiv.style.left = Math.floor(Math.max((containerRect.width - dialogRect.width) / 2, 0)).toString(10) + "px";
        this.dialogDiv.style.top = Math.floor(Math.min(Math.max((containerRect.height - (dialogRect.height + (this.props.initialHeightOffset ?? 0))) / 2, 0), containerRect.height / 5)).toString(10) + "px";
        this.displaceJs = displace(this.dialogDiv, { handle: this.headerDiv, constrain: true, relativeTo: this.dialogContainer });
    }

    componentWillUnmount(): void {
        if (Base.isNullOrUndefined(this.displaceJs)) return;
        this.displaceJs.destroy();
    }

    createReactButton = (key: any, dialogButton: IDialogButton): JSX.Element => {
        return <Button key={key} classes={dialogButton.classes} title={dialogButton.title} enabled={dialogButton.enabled} onClick={() => { this.handleButtonClick(dialogButton); }} icon={dialogButton.icon ? dialogButton.icon : ""} />;
    };

    render() {
        const props = this.props;
        // Render nothing if the "show" prop is false
        if (!props.show) {
            return null;
        }
        const titleTooltip = typeof props.title === "string"
            ? props.title
            : null;
        // Create button
        const enabledButtons = props.buttons.filter(i => !!i.onClick);
        const firstButton = enabledButtons.length > 0 ? this.createReactButton(0, enabledButtons[0]) : null;
        const secondButton = enabledButtons.length > 1 && enabledButtons[1].mainButton ? this.createReactButton(1, enabledButtons[1]) : null;
        const dialogButtons: IButtonDropdownAction[] = [];
        for (let i = secondButton ? 2 : 1; i < enabledButtons.length; i++) {
            dialogButtons.push({ title: enabledButtons[i].title, onClick: enabledButtons[i].onClick, disabled: !enabledButtons[i].enabled });
        }
        const dialogClasses = "jj-dialog modal fade in show property" + (props.classes ? " " + props.classes : "");
        return (
            <div className={dialogClasses} ref={(div) => { this.dialogContainer = div; }} >
                <div className="modal-dialog" ref={(div) => { this.dialogDiv = div; }} >
                    <div className="modal-content">
                        <div className="modal-header draggable" ref={(div) => { this.headerDiv = div; }} >
                            <h5 className="modal-title" title={titleTooltip}>{props.title}</h5>
                            <div className="modal-toolbar">
                                {firstButton}
                                {secondButton}
                                {dialogButtons.length > 0 &&
                                    <ButtonDropdown
                                        selectedTitle={""}
                                        classes={"property"}
                                        actions={dialogButtons}
                                    />
                                }
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleCloseClick}>
                                    <span className="icon close" aria-hidden="true" />
                                </button>
                            </div>
                        </div>

                        <div className="modal-body">
                            {props.body}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// SelectDialog
// ***********************************************************************************************************************
export class SelectDialog extends React.Component<IDialogProp, {}> {
    render() {
        const props = this.props;
        return (
            <Dialog
                classes={"select" + (props.classes ? " " + props.classes : "")}
                title={props.title}
                show={props.show}
                body={props.body}
                buttons={props.buttons}
                onClose={props.onClose}
            />
        );
    }
}
