import * as React from "react";
import { Base } from "../../framework/base";
import { WorkMainPortlet } from "./workMainPortlet";
import { IWorkOrderEdit } from "../../models/work/workOrderEdit";
import {
    WorkOrderDetailsContentConnected,
    WorkOrderDetailsViewMode
} from "./workOrderDetailsView";
import { EmployeeParametersHelper, IEmployeeParameters } from "../../models/employee/employeeIParameters";
import { employeeParameterWorkOrderCalendarWorkDetailsExpanded } from "../../models/common/consts";
import { TimeFormat } from "../../models/common/enums";

// WorOrderCalendarItemDetailsView
// ***********************************************************************************************************************
export interface IWorkOrderCalendarItemDetailsViewProp {
    classes: string;
    workTimeFormat: TimeFormat;
    employeeParameters: IEmployeeParameters;
    title: string;
    item: IWorkOrderEdit;
    isLoading: boolean;
    onOpenWorkOrder: (id: string) => void;
    onRefresh: () => void;
    onRemove: () => void;
    onPrint: () => void;
    onCopyWorkOrder: () => void;
    convertToProject: () => void;
    detachFromProject: () => void;
    onCancelWorkOrder: () => void;
    onRestoreWorkOrder: () => void;
    onChangeExpanded: (listExpandedKey: string, expanded: boolean) => void;
    onStateChange: (state: number) => void;
    onAddToFavorites: () => void;
    onRemoveFromFavorites: () => void;
    onSetDurationByEstimatedHours: () => void;
    onAddEmployeeToWorkOrder: (id: string, employeeId: string) => void;
    onAddVehicleToWorkOrder: (id: string, employeeId: string) => void;
    setEmployeeParameters: (parameters: IEmployeeParameters, saveToDb: boolean) => void;
}

export interface IWorkOrderCalendarItemDetailsViewState {
    isInitiallyExpanded: boolean;
}

export class WorkOrderCalendarItemDetailsView extends React.Component<IWorkOrderCalendarItemDetailsViewProp, IWorkOrderCalendarItemDetailsViewState> {
    constructor(props) {
        super(props);
        const expanded = props.employeeParameters
            ? props.employeeParameters[employeeParameterWorkOrderCalendarWorkDetailsExpanded]
            : null;
        this.state = { isInitiallyExpanded: !Base.isNullOrUndefined(expanded) ? expanded === "1" : null };
    }

    componentDidUpdate(prevProps: IWorkOrderCalendarItemDetailsViewProp, prevState: IWorkOrderCalendarItemDetailsViewState): void {
        if (!Base.isNullOrUndefined(prevState.isInitiallyExpanded)) return;
        const expanded = this.props.employeeParameters
            ? this.props.employeeParameters[employeeParameterWorkOrderCalendarWorkDetailsExpanded]
            : null;
        const isInitiallyExpanded = !Base.isNullOrUndefined(expanded) ? expanded === "1" : null;
        if (prevState.isInitiallyExpanded === isInitiallyExpanded) return;
        this.setState({
            isInitiallyExpanded: isInitiallyExpanded
        });
    }

    render() {
        const props = this.props;
        const workOrderId = props.item ? props.item.workOrder.id : "";
        return (
            <WorkMainPortlet
                classes={"pWorkList" + (props.classes ? " " + props.classes : "")}
                expanded={this.state.isInitiallyExpanded}
                title={props.title}
                titleClasses={"workOrderState" + (props.item ? " state" + props.item.workOrder.state.toString(10) : "")}
                content={
                    <div className="pContent detailsView">
                        <WorkOrderDetailsContentConnected
                            workTimeFormat={props.workTimeFormat}
                            item={props.item}
                            isFavorite={props.item && EmployeeParametersHelper.isFavoriteWorkOrder(props.employeeParameters, workOrderId)}
                            isLoading={props.isLoading}
                            viewMode={WorkOrderDetailsViewMode.WorkOrder}
                            onOpenWorkOrder={props.onOpenWorkOrder}
                            onRefresh={props.onRefresh}
                            onRemove={props.onRemove}
                            onPrint={props.onPrint}
                            onCopyWorkOrder={props.onCopyWorkOrder}
                            convertToProject={props.convertToProject}
                            detachFromProject={props.detachFromProject}
                            onCancelWorkOrder={props.onCancelWorkOrder}
                            onRestoreWorkOrder={props.onRestoreWorkOrder}
                            onStateChange={props.onStateChange}
                            onAddToFavorites={props.onAddToFavorites}
                            onRemoveFromFavorites={props.onRemoveFromFavorites}
                            onSetDurationByEstimatedHours={props.onSetDurationByEstimatedHours}
                            onAddEmployeeToWorkOrder={props.onAddEmployeeToWorkOrder}
                            onAddVehicleToWorkOrder={props.onAddVehicleToWorkOrder}
                            setEmployeeParameters={props.setEmployeeParameters}
                        />
                    </div>}
                replaceContent={true}
                onTitleClick={() => props.onOpenWorkOrder(workOrderId)}
                onChangeExpanded={(expanded: boolean) => { props.onChangeExpanded(employeeParameterWorkOrderCalendarWorkDetailsExpanded, expanded); }}
            />
        );
    }
}