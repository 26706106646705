import { Base } from "../../framework/base";
import { IProductGroupEditItem, ProductGroupEditItem } from "./productGroupEditItem";
import { IProductItem, ProductItem } from "../product/productItem";

export interface IProductGroupEdit {
    productGroup: IProductGroupEditItem;
    products: IProductItem[];
}

export class ProductGroupEdit implements IProductGroupEdit {
    productGroup: ProductGroupEditItem;
    products: ProductItem[];

    constructor();
    constructor(obj: IProductGroupEdit);
    constructor(obj?: any) {
        this.productGroup = new ProductGroupEditItem(obj ? obj.productGroup : null);
        this.products = Base.getTypedArray<ProductItem>(obj ? obj.products : null, ProductItem);
    }
}