import { Base } from "../../framework/base";
import { ISalaryRowCalculated, SalaryRowCalculated } from "./salaryRowCalculated";

export interface ISalaryRowsCalculated {
    rows: ISalaryRowCalculated[];
}

export class SalaryRowsCalculated implements ISalaryRowsCalculated {
    rows: SalaryRowCalculated[];

    constructor();
    constructor(obj: ISalaryRowsCalculated);
    constructor(obj?: any) {
        this.rows = Base.getTypedArray<SalaryRowCalculated>(obj ? obj.rows : null, SalaryRowCalculated);
    }
}