export interface IEndlessList<T> {
    hasMore: boolean;
    page: number;
    items: T[];
}

export class EndlessList<T> implements IEndlessList<T> {
    private type: any;
    hasMore: boolean;
    page: number;
    items: T[];

    constructor();
    constructor(obj: IEndlessList<T>, type?: new () => T);
    constructor(obj?: any, type?: new () => T) {
        this.type = type;
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = [];
        if (obj && obj.items) {
            for (let i = 0; i < obj.items.length; i++) {
                /* eslint-disable new-cap */
                this.items.push(new this.type(obj.items[i]));
                /* eslint-enable new-cap */
            }
        }
    }
}