import { createEntityAdapter, createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getCalendarDays } from "../services/calendarDayService";

export interface CalendarDayItem {
    day: string;
    dayType: number;
    description: string;
    isHoliday: boolean;
}

const calendarDayAdapter = createEntityAdapter<CalendarDayItem>({
    selectId: (d) => d.day,
    sortComparer: (a, b) => a.day.localeCompare(b.day)
});

export const calendarSlice = createSlice({
    name: "calendar",
    initialState: calendarDayAdapter.getInitialState(),
    reducers: {
        calendarDaysReceived: (
            state,
            action: PayloadAction<CalendarDayItem[]>
        ) => {
            calendarDayAdapter.upsertMany(state, action.payload);
        }
    },
});

const {
    calendarDaysReceived,
} = calendarSlice.actions;

export const fetchCalendarDays = createAsyncThunk(
    "calendardays",
    async({ startDate, endDate }: { startDate: string; endDate: string }, thunkApi) => {
        const calendarDays = await getCalendarDays(startDate, endDate);
        thunkApi.dispatch(calendarDaysReceived(calendarDays));
    }
);

export const calendarReducer = calendarSlice.reducer;

export const calendarDaySelectors = calendarDayAdapter.getSelectors();
