import React, { useEffect } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { TextField, debounce } from "@mui/material";
import { Translations } from "../../../models/translations";
import { RootState, useAppDispatch, useAppSelector } from "../../../framework/customStore";
import { MuiDateRangePicker } from "../../framework/muiDatepicker";
import { CustomerSelect } from "../components/customerSelect";

import {
    IInvoiceListFilters,
    fetchTransportOrderInvoices,
    setFilterCustomerId,
    setFilterTimeRange,
    setFilterInvoiceNumber,
    DEFAULT_START,
    DEFAULT_END,
} from "../../../store/transportOrderInvoiceSlice";

import { TransportOrderInvoiceState } from "../../../models/transportOrderInvoice/transportOrderInvoice";

export const formInvoiceQueryParams = ({ displaySent, invoiceNumber, customerId, timeRange }: IInvoiceListFilters) => {
    let qsp = {
        ...(timeRange?.start) && { startDate: timeRange.start.toISOString() },
        ...(timeRange?.end) && { endDate: timeRange.end.toISOString() },
        ...(invoiceNumber && { invoiceNumber }),
        ...(customerId && { customerId }),
        ...{ state: [TransportOrderInvoiceState.NotApproved, TransportOrderInvoiceState.Approved] }
    };
    if(displaySent) {
        qsp = {
            ...qsp,
            ...{ startDate: timeRange?.start?.toISOString() ?? DEFAULT_START.toISOString() },
            ...{ endDate: timeRange?.end?.toISOString() ?? DEFAULT_END.toISOString() },
            ...(displaySent && { state: [TransportOrderInvoiceState.SentToIntegration] })
        };
    }
    return qsp;
};

export const InvoiceListFilters = () => {
    const dispatch = useAppDispatch();
    const { filters } = useAppSelector((state: RootState) => state.transportOrderInvoice);
    const debouncedSetInvoiceNumber = debounce((value: number) => {
        dispatch(setFilterInvoiceNumber(value));
    }, 1000);

    useEffect(() => {
        dispatch(
            fetchTransportOrderInvoices(
                formInvoiceQueryParams({ ...filters})) as any)
    }, [filters])

    return (
        <div>
            <Grid2 container spacing={2}>
                <Grid2>
                    <MuiDateRangePicker
                        labels={[Translations.DateRangeStart, Translations.DateRangeEnd]}
                        value={[filters?.timeRange?.start, filters?.timeRange?.end]}
                        onChange={(val: [Date | null, Date | null]) =>
                            dispatch(setFilterTimeRange({ start: val[0], end: val[1] }))
                        }
                    />
                </Grid2>
                <Grid2>
                    <CustomerSelect
                        value={filters?.customerId}
                        onChange={(val: string) => dispatch(setFilterCustomerId(val))}
                    />
                </Grid2>
                <Grid2>
                    <TextField
                        label={Translations.SearchByInvoiceNumber}
                        onChange={(e) => debouncedSetInvoiceNumber(parseInt(e?.currentTarget?.value))}
                    />
                </Grid2>
            </Grid2>
        </div>
    );
};
