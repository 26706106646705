import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getWorkShift, WorkShift } from "../models/workShift/workShift";
import { getWorkShifts } from "../services/workShiftService";
import { setWorkShiftTimeSlots } from "./workShiftTimeSlotSlice";
import { WorkShiftTimeSlotItems } from "../models/workShitTimeSlot/workShitTimeSlotItems";
import { Base } from "../framework/base";
import { FetchState } from "../models/common/enums";

export interface WorkShiftState {
    items: WorkShift[];
    fetchState: FetchState;
}

const initialState: WorkShiftState = {
    items: [],
    fetchState: FetchState.None,
};

export const workShiftSlice = createSlice({
    name: "workShift",
    initialState,
    reducers: {
        setWorkShifts: (state, action: PayloadAction<WorkShift[]>) => {
            state.items = action.payload;
            state.fetchState = FetchState.Done;
        },
        setWorkShiftsLoading: (state, action: PayloadAction<FetchState>) => {
            state.fetchState = action.payload;
        },
    },
});

export const { setWorkShifts, setWorkShiftsLoading } = workShiftSlice.actions;

export const fetchWorkShifts = createAsyncThunk(
    "workShift/fetch",
    async (
        {
            employeeIds,
            startDate,
            endDate,
        }: {
            employeeIds: string | string[];
            startDate: string;
            endDate: string;
        },
        thunkApi
    ) => {
        thunkApi.dispatch(setWorkShiftsLoading(FetchState.Fetching));

        const dtos = await getWorkShifts(
            employeeIds,
            startDate,
            endDate,
            true,
            thunkApi.signal
        );

        const workShifts = dtos?.map(getWorkShift);

        // Update WorkShiftTimeSlots
        thunkApi.dispatch(
            setWorkShiftTimeSlots(
                new WorkShiftTimeSlotItems({
                    items: dtos.flatMap((d) => d.workShiftTimeSlots),
                    hasMore: false,
                    page: 1,
                })
            )
        );

        thunkApi.dispatch(setWorkShifts(workShifts));
    }
);

export const workShiftReducer = workShiftSlice.reducer;

export const makeSelectWorkShiftsByDate = () =>
    createSelector(
        (state: WorkShiftState) => state.items,
        (_, employeeId: string) => employeeId,
        (_, _employeeId, rangeStart: string) => rangeStart,
        (_, _employeeId, _rangeStart, rangeEnd: string) => rangeEnd,
        (
            items: WorkShift[],
            employeeId: string,
            rangeStart: string,
            rangeEnd: string
        ) => {
            if (rangeStart && rangeEnd) {
                return items.filter(
                    (i) =>
                        i.employeeId === employeeId &&
                        Base.isBetween(
                            i.effectiveDate,
                            rangeStart,
                            rangeEnd,
                            "day",
                            "[]"
                        )
                );
            }
        }
    );

export const makeSelectWorkShiftTimeSlotIdsByWorkShiftDate = () =>
    createSelector([makeSelectWorkShiftsByDate()], (items) =>
        items?.flatMap((i) => i.workShiftTimeSlotIds)
    );
