import { useEffect } from "react";
import { useTheme } from "@mui/material";
import { useMap } from "@vis.gl/react-google-maps";
import { MapRouteProps } from "./map";

interface PolylineProps {
    route: MapRouteProps;
}

export const Polyline = ({ route }: PolylineProps) => {
    const theme = useTheme();
    const map = useMap();

    const { coords, color, highlight } = route;

    useEffect(() => {
        if (!map) return;

        const polyline = new google.maps.Polyline({
            path: coords,
            strokeColor: highlight
                ? theme.palette.error.main
                : color ?? theme.palette.primary.main,
            strokeWeight: highlight ? 5 : 3,
        });

        polyline.setMap(map);

        return () => {
            polyline.setMap(null);
        };
    }, [map, coords, color, highlight]);

    return null;
};
