export interface IAcknowledgementApproval {
    message: string;
    approvalDate: number;
}

export class AcknowledgementApproval implements IAcknowledgementApproval {
    message: string;
    approvalDate: number;

    constructor();
    constructor(obj: IAcknowledgementApproval);
    constructor(obj?: any) {
        this.message = obj && obj.message || "";
        this.approvalDate = obj && obj.approvalDate || 0;
    }
}