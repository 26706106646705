import { combineReducers } from "redux";
import * as storeTypes from "./storeTypes";
import { AcknowledgementEditItem } from "../acknowledgement/acknowledgementEditItem";
import { TimeFormat } from "../common/enums";
import { IOwnerState, IUserState } from "./storeTypes";
import { Base } from "../../framework/base";
import { TypedOption } from "../common/typedOption";
import { IRoutePointAutocompleteItems } from "../routePoint/routePointAutocompleteItems";
import { Report } from "@mui/icons-material";

/**
 * IMPORTANT: This is legacy code. Define new reducers using @reduxjs/toolkit (see: timelinePlannerSlice.ts) and add the reducer to store.ts.
 */

// Initial state
const initialInstallState: storeTypes.IInstallState = {
    proposalEvent: null
};

const initialAlertState: storeTypes.IAlertState = {
    message: null,
    info: null,
    error: null
};

const initialConfirmationState: storeTypes.IConfirmationState = {
    type: storeTypes.ConfirmationDialogType.Warning,
    title: null,
    text: null,
    show: false,
    yesTitle: null,
    yesClasses: null,
    noTitle: null,
    noClasses: null,
    onYes: null,
    onNo: null,
    onCancel: null
};

const initialOwnerState: storeTypes.IOwnerState = {
    name: "",
    name2: "",
    style: "",
    parameters: []
};

const initialUserState: storeTypes.IUserState = {
    authenticated: false,
    ownerAdmin: false,
    employeeId: "",
    parameters: {},
    ssoIntegrations: []
};

const initialAcknowledgementMainState: storeTypes.IAcknowledgementMainState = {
    ownerLogo: "",
    canSendMessage: false,
    isLoading: false,
    acknowledgement: null
};

const initialWorkMainState: storeTypes.IWorkMainState = {
    dataId: 0,
    workTimeFormat: TimeFormat.DecimalFormat,
    startDate: 0,
    endDate: 0,
    calendarLines: [],
    workOrders: [],
    workOrderTemplates: [],
    favoriteWorkOrders: [],
    showOnMapVehicles: [],
    calendarDays: [],
    dayBookings: [],
    dayBookingTypes: [],
    workOrderStates: [],
    workOrderClasses: [],
    employeeGroups: [],
    vehicleGroups: []
};

const initialOwnerRoutePointsState: IRoutePointAutocompleteItems = {
    items: []
};

const initialShoppingCartState: storeTypes.IShoppingCartState = {
    items: [],
    selectedOrderId: null,
};

const initialReportingState: storeTypes.IReportResponseState = {
    response: null
}



// IMPORTANT: Don't set initial state here for new components. Use initialState property within createSlice instead. (see: timelinePlannerSlice.ts)
export const initialState: storeTypes.IApplicationState = {
    // Fetch
    fetchCount: 0,
    // Install
    install: initialInstallState,
    // Alert
    alert: initialAlertState,
    // Confirmation
    confirmation: initialConfirmationState,
    // Owner
    owner: {
        ...initialOwnerState,
        parameters: Base.getTypedArray<TypedOption>(appConfig ? appConfig.ownerParameters : null, TypedOption)
    },
    // Token
    user: initialUserState,
    // Acknowledgement
    acknowledgementMain: initialAcknowledgementMainState,
    // Work
    workMain: initialWorkMainState,
    // Owner route points
    ownerRoutePoints: initialOwnerRoutePointsState,
    // Shopping cart items
    shoppingCart: initialShoppingCartState,
};

/* eslint-disable no-case-declarations */
// Fetch
export function fetchCount(state: number = 0, action: storeTypes.FetchAction) {
    switch (action.type) {
    case storeTypes.FETCH_START:
        return state + 1;
    case storeTypes.FETCH_END:
        return state - 1;
    default:
            return state;
    }
}

// Install
export function install(state: storeTypes.IInstallState = { proposalEvent: null }, action: storeTypes.InstallAction) {
    switch (action.type) {
    case storeTypes.INSTALL_SET:
        return { ...state, proposalEvent: (action as storeTypes.IInstallSet).event };
    case storeTypes.INSTALL_CLEAR:
        return { ...state, proposalEvent: null };
    default:
        return state;
    }
}

// Alert
export function alert(state: storeTypes.IAlertState = { message: "", info: "", error: "" }, action: storeTypes.AlertAction) {
    switch (action.type) {
    case storeTypes.ALERT_SUCCESS:
        return { ...state, message: (action as storeTypes.IAlertSuccess).message };
    case storeTypes.ALERT_INFO:
        return { ...state, info: (action as storeTypes.IAlertInfo).message };
    case storeTypes.ALERT_ERROR:
        return { ...state, error: (action as storeTypes.IAlertError).message };
    case storeTypes.ALERT_CLEAR:
        return { message: null, info: null, error: null };
    case storeTypes.ALERT_SUCCESS_CLEAR:
        return { ...state, message: null };
    case storeTypes.ALERT_INFO_CLEAR:
        return { ...state, info: null };
    default:
        return state;
    }
}

// Confirmation
export function confirmation(state: storeTypes.IConfirmationState = { type: storeTypes.ConfirmationDialogType.Warning, title: "", text: "", show: false, onYes: null, onNo: null, onCancel: null, yesTitle: null, yesClasses: null, noTitle: null, noClasses: null }, action: storeTypes.ConfirmationAction) {
    switch (action.type) {
    case storeTypes.CONFIRMATION_SET:
        const confirmation = action;
        return {
            ...state,
            type: confirmation.dialogType,
            title: confirmation.title,
            content: confirmation.content,
            text: confirmation.text,
            show: confirmation.show,
            onYes: confirmation.onYes,
            onNo: confirmation.onNo,
            onCancel: confirmation.onCancel,
            yesTitle: confirmation.yesTitle,
            yesClasses: confirmation.yesClasses,
            noTitle: confirmation.noTitle,
            noClasses: confirmation.noClasses
        };
    case storeTypes.CONFIRMATION_CLEAR:
        return { ...state, show: false };
    default:
        return state;
    }
}

// Owner
export function owner(state: IOwnerState = null, action: storeTypes.OwnerAction) {
    switch (action.type) {
    case storeTypes.OWNER_INITIALIZE:
        return initialOwnerState;
    case storeTypes.OWNER_SET_STATE:
        const setState = (action as storeTypes.IOwnerSetState);
        return { ...state, name: setState.name, name2: setState.name2, parameters: setState.parameters };
    default:
            return state;
    }
}

// User
export function user(state: IUserState = null, action: storeTypes.LoginAction) {
    switch (action.type) {
    case storeTypes.LOGIN_SUCCESS:
        return { ...state, authenticated: true };
    case storeTypes.LOGIN_FAILURE:
        return state;
    case storeTypes.LOGOUT_SUCCESS:
        return initialUserState;
    case storeTypes.USER_SET_STATE:
        const setUserState = (action as storeTypes.IUserSetState);
        return { ...state, employeeId: setUserState.employeeId, ownerAdmin: setUserState.ownerAdmin, ssoIntegrations: setUserState.ssoIntegrations };
    case storeTypes.USER_SET_PARAMETERS:
        const setUserParameters = (action as storeTypes.IUserSetParameters);
        return { ...state, parameters: { ...state.parameters, ...setUserParameters.parameters } };
    default:
        return state;
    }
}

// Acknowledgement
export function acknowledgementMain(state: storeTypes.IAcknowledgementMainState = null, action: storeTypes.AcknowledgementAction) {
    switch (action.type) {
    case storeTypes.ACKNOWLEDGEMENT_INITIALIZE:
        return initialAcknowledgementMainState;
    case storeTypes.ACKNOWLEDGEMENT_LOAD_START:
        return { ...state, isLoading: true };
    case storeTypes.ACKNOWLEDGEMENT_MAIN_SUCCESS:
        const acknowledgementMainSuccess = action as storeTypes.IAcknowledgementMainSuccess;
        return { ...state, ownerLogo: acknowledgementMainSuccess.ownerLogo, canSendMessage: acknowledgementMainSuccess.canSendMessage, acknowledgement: acknowledgementMainSuccess.acknowledgement, isLoading: false };
    case storeTypes.ACKNOWLEDGEMENT_MAIN_FAILURE:
        return { ...state, isLoading: false };
    case storeTypes.ACKNOWLEDGEMENT_APPROVAL_SUCCESS:
        const acknowledgementApprovalSuccess = action as storeTypes.IAcknowledgementApprovalSuccess;
        const acknowledgement = new AcknowledgementEditItem(state.acknowledgement);
        acknowledgement.approvalDate = acknowledgementApprovalSuccess.approvalDate;
        return { ...state, acknowledgement: acknowledgement };
    default:
        return state;
    }
}

// Work
export function workMain(state: storeTypes.IWorkMainState = null, action: storeTypes.WorkAction) {
    switch (action.type) {
    case storeTypes.WORK_MAIN_INITIALIZE:
        return initialWorkMainState;
    case storeTypes.WORK_MAIN_SUCCESS:
        const workMainSuccess = action as storeTypes.IWorkMainSuccess;
        return {
            ...state,
            dataId: state.dataId + 1,
            workTimeFormat: workMainSuccess.workTimeFormat,
            workOrders: workMainSuccess.workOrders,
            workOrderTemplates: workMainSuccess.workOrderTemplates,
            favoriteWorkOrders: workMainSuccess.favoriteWorkOrders,
            showOnMapVehicles: workMainSuccess.showOnMapVehicles,
            calendarLines: workMainSuccess.calendarLines,
            calendarDays: workMainSuccess.calendarDays,
            dayBookings: workMainSuccess.dayBookings,
            dayBookingTypes: workMainSuccess.dayBookingTypes,
            workOrderStates: workMainSuccess.workOrderStates,
            workOrderClasses: workMainSuccess.workOrderClasses,
            employeeGroups: workMainSuccess.employeeGroups,
            vehicleGroups: workMainSuccess.vehicleGroups,
            startDate: workMainSuccess.startDate,
            endDate: workMainSuccess.endDate
        };
    case storeTypes.WORK_MAIN_FAILURE:
        return { ...state };
    case storeTypes.WORK_MAIN_SET_CALENDAR_ACCURACY:
        const setCalendarAccuracy = action as storeTypes.IWorkMainSetCalendarAccuracy;
        return { ...state, calendarAccuracy: setCalendarAccuracy.calendarAccuracy };
    case storeTypes.WORK_ITEMS_SUCCESS:
        const workItemsSuccess = action as storeTypes.IWorkItemsSuccess;
        return {
            ...state,
            dataId: state.dataId + 1,
            workOrders: workItemsSuccess.workOrders,
            workOrderTemplates: workItemsSuccess.workOrderTemplates,
            favoriteWorkOrders: workItemsSuccess.favoriteWorkOrders,
            showOnMapVehicles: workItemsSuccess.showOnMapVehicles,
            calendarLines: workItemsSuccess.calendarLines,
            calendarDays: workItemsSuccess.calendarDays,
            dayBookings: workItemsSuccess.dayBookings,
            dayBookingTypes: workItemsSuccess.dayBookingTypes,
            startDate: workItemsSuccess.startDate,
            endDate: workItemsSuccess.endDate
        };
    case storeTypes.WORK_ITEMS_FAILURE:
        return { ...state };
    case storeTypes.WORK_TEMPLATES_SUCCESS:
        const workTemplatesSuccess = action as storeTypes.IWorkTemplatesSuccess;
        return {
            ...state,
            workOrderTemplates: workTemplatesSuccess.workOrderTemplates
        };
    default:
        return state;
    }
}

// Route points
export function ownerRoutePoints(state: IRoutePointAutocompleteItems = null, action: storeTypes.OwnerRoutePointsAction) {
    switch (action.type) {
    case storeTypes.OWNER_ROUTE_POINTS_INITIALIZE:
        return initialOwnerRoutePointsState;
    case storeTypes.GET_OWNER_ROUTE_POINTS_SUCCESS:
        const ownerRoutePointsSuccess = action as storeTypes.IGetOwnerRoutePointsSuccess;
        return { ...state, items: ownerRoutePointsSuccess.ownerRoutePoints.items };
    }
    return state;
}

// Shopping cart
export function shoppingCart(state: storeTypes.IShoppingCartState = null, action: storeTypes.ShoppingCartAction) {
    switch (action.type) {
    case storeTypes.SHOPPING_CART_INITIALIZE:
        return initialShoppingCartState;
    case storeTypes.SHOPPING_CART_UPDATE:
        const updateShoppingCart = action as storeTypes.IShoppingCartUpdate;
        return { ...state, items: updateShoppingCart.items };
    case storeTypes.SHOPPING_CART_SELECT_ORDER:
        return { ...state, selectedOrderId: (action as storeTypes.IShoppingCartSelectOrder).selectedOrderId };
    }
    return state;
}

export function reportResponse(state: storeTypes.IReportResponseState = null, action: storeTypes.IGetReportingAction) {

    switch (action.type) {
        case storeTypes.REPORTING_INITIALIZE:
            return initialReportingState;
        case storeTypes.REPORTING_UPDATE:
            const getReporting = action as storeTypes.IGetReportingUpdate;
            return { ...state, response: getReporting.response };
    }

    return state
}

/* eslint-enable no-case-declarations */

