import { styled, TableCell } from "@mui/material";

export const TableCellHeader = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
}));

export const TableCellSecondaryHeader = styled(TableCell)(({ theme }) => ({
    backgroundColor: "var(--palette-grayBlue-main)",
    color: theme.palette.common.white,
    borderBottom: "1px solid var(--palette-grayBlue-main)"
}));

export const StickyTableCellRight = styled(TableCell)(({ theme }) => ({
    position: "sticky",
    right: 0,
    zIndex: 1,
    backgroundColor: "inherit"
}));

export const StickyTableCellHeaderRight = styled(TableCell)(({ theme }) => ({
    position: "sticky",
    right: 0,
    zIndex: 2,
    background: theme.palette.primary.dark,
}));

export const StickyTableCellLeft = styled(TableCell)(({ theme }) => ({
    position: "sticky",
    left: 0,
    zIndex: 1,
    backgroundColor: "inherit"
}));

export const StickyTableCellHeaderLeft = styled(TableCell)(({ theme }) => ({
    position: "sticky",
    left: 0,
    zIndex: 2,
    background: "inherit",
}));
