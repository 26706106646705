import { EnumHelper, WorkShiftTimeSlotState, WorkTimeTypeType } from "../common/enums";
import { Base, maxBy, minBy } from "../../framework/base";
import { AppUtils } from "../common/appUtils";
import dayjs from "dayjs";
import { WorkTimeSaveData } from "./workTimeSaveData";
import { getWorkTimeTypeColorClass, getWorkTimeTypeContrastColorClass } from "./workTimeType";

export enum WorkTimeEventType {
    WorkTimeStarted = 1,
    WorkTimeEnded = 2
}

export class WorkShiftTimeSlotItem {
    id: string;
    rowId: string;
    employeeId: string;
    workOrderId: string | null;
    vehicleId: string | null;
    costCenterId: string | null;
    workTimeTypeId: string | null;
    workTimeTypeName: string;
    workTimeTypeType: number | null;
    orgStartDate: string;
    orgEndDate: string;
    startDate: string | null;
    endDate: string | null;
    startLocationName: string;
    state: number;
    endLocationName: string;
    comment: string;
    employeeName: string;
    workOrderNumber: number;
    workOrderName: string;
    workOrderCustomerName: string;
    costCenterName: string;
    customerName: string;
    vehicleRegisterNumber: string;
    vehicleBrand: string;
    startLatitude: number;
    startLongitude: number;
    endLatitude: number;
    endLongitude: number;
    automatic: boolean;
    edited: boolean;
    timeZoneName: string;
    timeZoneDisplayName: string;
    salaryRowTypeId: string;
    salaryRowTypeName: string;
    workTimeEvents?: {
        type: WorkTimeEventType;
        source: string;
        time: string;
    }[];
    isCustomType: boolean;
    ignoredCategories: number[];

    constructor();
    constructor(obj: WorkShiftTimeSlotItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || null;
        this.rowId = obj && obj.rowId || null;
        this.employeeId = obj && obj.employeeId || null;
        this.workOrderId = obj && obj.workOrderId || null;
        this.vehicleId = obj && obj.vehicleId || null;
        this.costCenterId = obj && obj.costCenterId || null;
        this.workTimeTypeId = obj && obj.workTimeTypeId || null;
        this.workTimeTypeName = obj && obj.workTimeTypeName || null;
        this.workTimeTypeType = (obj && obj.workTimeTypeType) ?? null;
        this.orgStartDate = obj && obj.orgStartDate;
        this.orgEndDate = obj && obj.orgEndDate;
        this.startDate = obj && obj.startDate;
        this.startLocationName = obj && obj.startLocationName || null;
        this.endDate = obj && obj.endDate;
        this.state = obj && obj.state || 0;
        this.endLocationName = obj && obj.endLocationName || null;
        this.comment = obj && obj.comment || null;
        this.employeeName = obj && obj.employeeName || null;
        this.workOrderNumber = obj && obj.workOrderNumber || 0;
        this.workOrderName = obj && obj.workOrderName || null;
        this.workOrderCustomerName = obj && obj.workOrderCustomerName || null;
        this.costCenterName = obj && obj.costCenterName || null;
        this.customerName = obj && obj.customerName || null;
        this.vehicleRegisterNumber = obj && obj.vehicleRegisterNumber || null;
        this.vehicleBrand = obj && obj.vehicleBrand || null;
        this.startLatitude = obj && obj.startLatitude || 0;
        this.startLongitude = obj && obj.startLongitude || 0;
        this.endLatitude = obj && obj.endLatitude || 0;
        this.endLongitude = obj && obj.endLongitude || 0;
        this.automatic = obj && obj.automatic || false;
        this.edited = obj && obj.edited || false;
        this.timeZoneName = obj && obj.timeZoneName || null;
        this.timeZoneDisplayName = obj && obj.timeZoneDisplayName || null;
        this.salaryRowTypeId = obj && obj.salaryRowTypeId || null;
        this.salaryRowTypeName = obj && obj.salaryRowTypeName || null;
        this.workTimeEvents = obj && obj.workTimeEvents || [];
        this.isCustomType = obj && obj.isCustomType;
        this.ignoredCategories = obj && obj.ignoredCategories || [];
    }

    isWork(): boolean {
        return !this.isBreak();
    }

    isBreak(): boolean {
        return this.workTimeTypeType === WorkTimeTypeType.Break;
    }


    isLocked(): boolean {
        return EnumHelper.isEqual(this.state, WorkShiftTimeSlotState.Locked);
    }

    isInProgress() : boolean {
        return EnumHelper.isEqual(this.state, WorkShiftTimeSlotState.InProgress);
    }

    toWorkTimeSaveData(): WorkTimeSaveData {
        return {
            id: this.id,
            rowId: this.rowId,
            employeeId: this.employeeId,
            vehicleId: this.vehicleId,
            workOrderId: this.workOrderId,
            workTimeTypeId: this.workTimeTypeId,
            salaryRowTypeId: this.salaryRowTypeId,
            costCenterId: this.costCenterId,
            comment: this.comment,
            startDate: Base.dateStrToOriginalTimezoneJsonDateTime(this.startDate),
            endDate: Base.dateStrToOriginalTimezoneJsonDateTime(this.endDate),
            timeZoneName: this.timeZoneName,
            isCustomType: this.isCustomType,
        };
    }

    // Duration minutes with minute accuracy
    getDurationMinutes(): number {
        return Base.dayjsDiffInMinutes(Base.dayjsToMinuteAccuracy(this.startDate), Base.dayjsToMinuteAccuracy(this.endDate));
    }

    // Duration minutes string
    getDurationStr(): string {
        return AppUtils.getDurationStrByDurationMinShort(this.getDurationMinutes(), true);
    }

    getMapToolTipText(): string {
        return [
            Base.dateStrToOriginalTimezoneTimeStr(this.startDate),
            this.getWorkTimeTypeName(),
            this.vehicleRegisterNumber,
        ]
            .filter(Boolean)
            .join(" - ");
    }

    getWorkTimeTypeName(): string {
        return this.workTimeTypeName;
    }

    getColorClass(): string {
        return getWorkTimeTypeColorClass(this.workTimeTypeType);
    }

    getContrastColorClass(): string {
        return getWorkTimeTypeContrastColorClass(this.workTimeTypeType);
    }
}

export function getDutyStartingWorktimes(workShiftTimeSlotItems: WorkShiftTimeSlotItem[], workShiftSplitDuration: number):string[] {
    const uniqueStarttimes = [];
    return workShiftTimeSlotItems.map((worktime) => {
        const workTimeStart = dayjs(worktime.startDate).subtract(workShiftSplitDuration, "minutes");
        if (workShiftTimeSlotItems.find(x => {
            return x.id !== worktime.id && Base.isOverlappingDate(x.startDate, x.endDate, workTimeStart, worktime.startDate);
        }) === undefined
            && uniqueStarttimes.find(y => Base.isSameDate(y, worktime.startDate)) === undefined
        ) {
            uniqueStarttimes.push(worktime.startDate);
            return worktime.id;
        }
    }).filter(x => x !== undefined);
}
export function getDutyEndingWorktimes(workShiftTimeSlotItems: WorkShiftTimeSlotItem[], workShiftSplitDuration: number):string[] {
    return workShiftTimeSlotItems.map((worktime) => {

        const workTimeEnd = dayjs(worktime.endDate).add(workShiftSplitDuration, "minutes");
        if(workShiftTimeSlotItems.find(x => {
            return x.id !== worktime.id && Base.isOverlappingDate(x.startDate, x.endDate, worktime.endDate, workTimeEnd);
        }) === undefined)
        {
            return worktime.id;
        }
    }).filter(x => x !== undefined);
}
export function getDutyWorkShiftTimeSlots(duty: WorkShiftTimeSlotItem, workShiftTimeSlotItems: WorkShiftTimeSlotItem[], workShiftSplitDuration: number):WorkShiftTimeSlotItem[] {
    if (!duty.endDate) return [duty];
    const now = dayjs();
    let dutyStart = dayjs(duty.startDate).subtract(workShiftSplitDuration, "minutes");
    let dutyEnd = dayjs(duty.endDate || now).add(workShiftSplitDuration, "minutes");
    let shouldLoop = true;
    let previousResultCount = 0;
    let results: WorkShiftTimeSlotItem[] = [];
    while (shouldLoop) {
        results = workShiftTimeSlotItems.filter(x => {
            return duty.id === x.id || (Base.isSameOrBefore(duty.startDate, x.startDate) && (x.endDate ? Base.isOverlappingDate(x.startDate, x.endDate, dutyStart, dutyEnd) : Base.isBetween(x.startDate, dutyStart, dutyEnd)));
        });
        if (results.length === previousResultCount) {
            shouldLoop = false;
        }
        dutyStart = dayjs(results.reduce((a, b) => dayjs(a.startDate).isBefore(b.startDate) ? a : b).startDate).subtract(workShiftSplitDuration, "minutes");
        dutyEnd = dayjs(results.reduce((a, b) => dayjs(a.endDate || now).isAfter(b.endDate || now) ? a : b).endDate || now).add(workShiftSplitDuration, "minutes");
        previousResultCount = results.length;
    }
    return results;



}

export const calculateWorkMinutesFromWorkShiftTimeSlotItems = (
    workShiftTimeSlotItems: WorkShiftTimeSlotItem[]
): number =>
    workShiftTimeSlotItems
        .map((i) => i.getDurationMinutes())
        .reduce((acc, cur) => acc + cur, 0);

export const findPreviousWorkShiftTimeSlotItem = (
    items: WorkShiftTimeSlotItem[],
    date: Date | string | number
) => {
    return maxBy(
        items.filter((i) => Base.isSameOrBefore(i.endDate, date)),
        (i) => new Date(i.endDate).valueOf()
    );
};

export const findNextWorkShiftTimeSlotItem = (
    items: WorkShiftTimeSlotItem[],
    date: Date | string | number
) => {
    return minBy(
        items.filter((i) => Base.isSameOrAfter(i.startDate, date)),
        (i) => new Date(i.startDate).valueOf()
    );
};