import React from "react";
import { TransportOrderInvoicingState, TransportOrderListItemDto, TransportOrderState } from "../../../models/transport/transportOrder";
import { Button } from "@mui/material";
import { updateTransportOrderInvoicingState } from "../../../services/transportOrderService";
import { showApiError, showApiSuccess, showConfirm } from "../../framework/formUtils";
import { useAppDispatch, useAppSelector } from "../../../framework/customStore";
import { setGridDataChanged, setSideBarOrderInvoicingState } from "../../../store/transport/transportVehiclesSlice";
import { Translations } from "../../../models/translations";

interface TransportOrderInvoicingStateButtonProps {
    order: TransportOrderListItemDto;
    transportOrdersView: boolean;
}

export const TransportOrderInvoicingStateButton = ({ order, transportOrdersView }: TransportOrderInvoicingStateButtonProps) => {
    const dispatch = useAppDispatch();
    const gridDataChanged = useAppSelector((state) => state.transportVehicles.gridDataChanged as boolean);

    const handleChangeInvoicingState = () => {
        const invoiceable = order.invoicingState === TransportOrderInvoicingState.Invoiceable;
        const submitChange = () => {
            const newInvoicingState = {
                invoicingState: invoiceable ? TransportOrderInvoicingState.InvoicedExternally : TransportOrderInvoicingState.Invoiceable
            };
            updateTransportOrderInvoicingState(order.id, newInvoicingState)
                .then(() => {
                    showApiSuccess(Translations.ChangeInInvoicingStateWasSuccessful);
                    if (!transportOrdersView) dispatch(setGridDataChanged(!gridDataChanged));
                    dispatch(setSideBarOrderInvoicingState(newInvoicingState.invoicingState as TransportOrderInvoicingState));
                })
                .catch(() => {
                    showApiError(Translations.InvoicingStateChangeFailed);
                });
        };
        showConfirm(
            String.format(Translations.TransportOrderInvoicingStateConfirmation, invoiceable ? Translations.Invoiced : Translations.Invoiceable),
            submitChange
        );
    };

    if (order?.state !== TransportOrderState.Delivered ||
        (order.invoicingState !== TransportOrderInvoicingState.Invoiceable &&
         order.invoicingState !== TransportOrderInvoicingState.InvoicedExternally)) {
        return null;
    }

    return (
        <Button variant="outlined" size="small" onClick={handleChangeInvoicingState}>
            {(order.invoicingState === TransportOrderInvoicingState.Invoiceable) && Translations.MarkAsInvoiced}
            {(order.invoicingState === TransportOrderInvoicingState.InvoicedExternally) && Translations.ReturnToInvoiceable}
        </Button>
    );
};
