import React, { useEffect, useMemo, useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import Tooltip from "@mui/material/Tooltip";
import { Translations } from "../../../models/translations";
import { TransportPlanListItemDto, TransportPlanState, transportPlanStates } from "../../../models/transport/transportPlan";
import { TableDef, TableWrapper } from "../../framework/tableWrapper";
import { transportDateTimeRange } from "../components/transportUtils";
import { useAppSelector } from "../../../framework/customStore";
import { getTransportPlans } from "../../../services/transportPlanService";
import { showErrorMessage } from "../../../models/store/storeActions";
import { Typography } from "@mui/material";

interface TransportPlansTableProps {
    onRowClick: (row: TransportPlanListItemDto) => void;
}

export const TransportPlansTable = (props: TransportPlansTableProps) => {
    const [plans, setPlans] = useState<TransportPlanListItemDto[]>([]);
    const { timelineStart, selectedVehicles, selectedEmployees, selectedPlanStates } = useAppSelector(state => state.transportVehicles);
    const timelineEnd = useMemo(() => {
        const endDate = new Date(timelineStart);
        endDate.setMonth(endDate.getMonth() + 3);
        return endDate;
    }, [timelineStart]);

    const queryParameters = {
        timeFrameStartDate: timelineStart?.toISOString(),
        timeFrameEndDate: timelineEnd.toISOString(),
        vehicleIds: selectedVehicles,
        employeeIds: selectedEmployees,
        states: Object.entries(selectedPlanStates)
            .filter(([, val]) => val)
            .map(([key]) => key)
    };

    const fetchPlans = async() => {
        try {
            const fetchedPlans = await getTransportPlans(queryParameters);
            setPlans(fetchedPlans);
        } catch (error) {
            showErrorMessage(Translations.TransportPlansFetchFail);
        }
    };

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        fetchPlans();
    }, [timelineStart, timelineEnd, selectedVehicles, selectedEmployees, selectedPlanStates]);

    const table = useMemo<TableDef<TransportPlanListItemDto>>(() => ({
        columns: [
            {
                label: "Tila",
                accessor: "state",
                renderCell: (val: TransportPlanState) => {
                    const state = transportPlanStates[val];
                    return (
                        <Tooltip title={state.name} disableInteractive>
                            <CircleIcon fontSize="small" htmlColor={state.color} />
                        </Tooltip>
                    );
                }
            },
            {
                label: Translations.Name,
                accessor: "name"
            },
            {
                label: "Aika",
                disabledSort: true,
                accessor: "scheduledStartDateTime",
                renderCell: (_, row) => transportDateTimeRange(row.scheduledStartDateTime, row.scheduledEndDateTime),
            },
            {
                label: "Kuljettaja",
                accessor: (row) => {
                    if (!row.employee) return "";
                    return `${row.employee.firstName} ${row.employee.lastName}`;
                }
            },
            {
                label: "Kalusto",
                accessor: (row) => {
                    if (!row.vehicle) return "";
                    return `${row.vehicle.brand} ${row.vehicle.registerNumber}`;
                }
            },
            {
                label: "Tilaukset",
                accessor: (row) => `${row.orders?.length ?? 0} kpl`
            }

        ],
        onRowClick: (row) => props.onRowClick(row)

    }), []);

    return (
        <>
            <Typography variant="subtitle2"><i>{Translations.ThreeMonthTransportPlanQuery}</i></Typography>
            <TableWrapper tableDef={table} data={plans} />
        </>
    );
};
