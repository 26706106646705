import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export interface IMuiRadioGroupOption {
    label: string;
    value: string;
}

interface IMuiRadioGroupProps {
  label?: string;
  name?: string;
  options: IMuiRadioGroupOption[];
  defaultValue?: string;
  value?: string;
  onChange?: (val: string) => void;
  row?: boolean;
}

type MuiRadioGroupProps = IMuiRadioGroupProps & FormControlProps;

export default function MuiRadioGroup({
    label,
    defaultValue,
    value,
    name,
    options,
    onChange,
    row,
    ...rest
}: MuiRadioGroupProps) {
    const [selected, setSelected] = useState<string>(value || defaultValue || "");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected((event.target as HTMLInputElement).value);
    };

    useEffect(() => {
        if (onChange && value !== selected) {
            onChange(selected);
        }
    }, [selected]);

    useEffect(() => {
        if (value !== undefined) {
            setSelected(value);
        }
    }, [value]);

    return (
        <FormControl {...rest}>
            {label ? <FormLabel>{label}</FormLabel> : null}
            <RadioGroup
                value={selected}
                name={name}
                onChange={handleChange}
                row={row}
            >
                {options.map((o: IMuiRadioGroupOption) => (
                    <FormControlLabel
                        key={o.value}
                        value={o.value}
                        control={<Radio />}
                        label={o.label}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
}