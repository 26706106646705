// SalaryTransferToSystemDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "../../framework/base";
import { SaveData } from "../../framework/saveData";
import { AppUtils } from "../../models/common/appUtils";
import { ISalaryListItem, SalaryListItem } from "../../models/salary/salaryListItem";
import * as salaryService from "../../services/salaryService";
import { Translations } from "../../models/translations";
import { CheckBox } from "../framework/checkbox";
import { Dialog } from "../framework/dialog";
import { ListHeaderColumn } from "../framework/listHeaderColumn";
import { ButtonDropdown, IButtonDropdownAction } from "../framework/dropdown";
import { Button } from "../framework/button";

// SalaryTransferToSystemDialogListHeader
export interface ISalaryTransferToSystemDialogListHeaderProp {
    sortColumn: string;
    sortOrderIsAsc: boolean;
    onColumnClick: (column: string) => void;
}

export class SalaryTransferToSystemDialogListHeader extends React.Component<ISalaryTransferToSystemDialogListHeaderProp, {}> {
    render() {
        const props = this.props;
        return (
            <div className="row title">
                <ListHeaderColumn
                    title={Translations.Date}
                    column="date"
                    classes="col-1"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={props.onColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Employee}
                    column="employeeName"
                    classes="col-3"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={props.onColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Comment}
                    column="comment"
                    classes="col-6"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={props.onColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Transferred}
                    column="type"
                    classes="col-2"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={props.onColumnClick}
                />
            </div>
        );
    }
}

// SalaryTransferToSystemDialogListLine
export interface ISalaryTransferToSystemDialogListLineProp {
    item: ISalaryListItem;
}

export class SalaryTransferToSystemDialogListLine extends React.Component<ISalaryTransferToSystemDialogListLineProp, {}> {
    render() {
        const item = this.props.item;
        return (
            <div className="row line">
                <div className="col-1">{Base.utcTimeToDateStr(item.date)}</div>
                <div className="col-3">{item.employeeName}</div>
                <div className="col-6">{item.comment}</div>
                <div className="col-2">{Base.timeToDateStr(item.transferDate)}</div>
            </div>
        );
    }
}

// SalaryTransferToSystemDialog
// ***********************************************************************************************************************
export interface ISalaryTransferToSystemDialogProp {
    classes?: string;
    salaryTransferEnabled: boolean;
    salaryDownloadEnabled: boolean;
    showTransferred: boolean;
    items: ISalaryListItem[];
    onSetTransferDataToSalaries: (salaryIds: string[]) => void;
    onCancel: () => void;
}

export interface ISalaryTransferToSystemDialogState {
    items: ISalaryListItem[];
    sortColumn: string;
    sortOrderIsAsc: boolean;
    setToTransferred: boolean;
    showTransferred: boolean;
    selectedErp: string;
    targetErps: IButtonDropdownAction[];
}

export class SalaryTransferToSystemDialog extends React.Component<ISalaryTransferToSystemDialogProp, ISalaryTransferToSystemDialogState> {
    constructor(props: ISalaryTransferToSystemDialogProp) {
        super(props);
        const sortColumn = "employeeName";
        const sortOrderIsAsc = true;
        const items = props.items.filter(i => props.showTransferred || !i.transferDate);
        SalaryListItem.sortSalaryListItems(items, sortColumn, sortOrderIsAsc);
        this.state = {
            items,
            sortColumn,
            sortOrderIsAsc,
            setToTransferred: true,
            showTransferred: props.showTransferred,
            selectedErp: "",
            targetErps: []
        };
    }

    componentDidMount(): void {
        this.getTargetsErps();
    }

    changeSortColumn = (sortColumn: string) => {
        const oldSortColumn = this.state.sortColumn;
        const sortOrderIsAsc = oldSortColumn === sortColumn ? !this.state.sortOrderIsAsc : true;
        const items = this.state.items.slice(0);
        SalaryListItem.sortSalaryListItems(items, sortColumn, sortOrderIsAsc);
        this.setState({ items: items, sortColumn: sortColumn, sortOrderIsAsc: sortOrderIsAsc });
    };

    setToTransferredChange = (value: boolean) => {
        this.setState({ setToTransferred: value });
    };

    showTransferredChange = (value: boolean) => {
        const props = this.props;
        const state = this.state;
        const items = props.items.filter(i => value || !i.transferDate);
        SalaryListItem.sortSalaryListItems(items, state.sortColumn, state.sortOrderIsAsc);
        this.setState({ showTransferred: value, items });
    };

    onSelectTargetErp = (targetErp: string) => {
        this.sendSalaryToServer(targetErp);
    };

    getTargetsErps = () => {
        salaryService.targetErps().then(resp => {
            const targetErps = resp;
            const actionButtons: IButtonDropdownAction[] = targetErps.map((targetErp) => {
                return {
                    title: targetErp,
                    classes: "w100 text-overflow-ellipsis",
                    onClick: () => this.onSelectTargetErp(targetErp)
                };
            });
            this.setState({
                targetErps: actionButtons
            });
        });
    };
    // #region Saving
    private checkErrors = (): Promise<boolean> => {
        return new Promise<boolean>((resolve) => {
            //Add error checking in here
            return resolve(true);
        });
    };

    private getWarningMessage = (): Promise<string> => {
        return new Promise<string>((resolve) => {
            return resolve("");
        });
    };

    private static getSaveDataFromState = (state: ISalaryTransferToSystemDialogState): SaveData => {
        const data = new SaveData();
        data.append("setToTransferred", state.setToTransferred.toString());
        data.append("ids", JSON.stringify(state.items.map(i => i.id)));
        return data;
    };

    setSalaryTransferDate = () => {
        if (!this.state.setToTransferred) { return; }
        if (!AppUtils.validate(this.checkErrors, this.getWarningMessage)) { return; }
        const props = this.props;
        const nowTime = new Date().getTime();
        for (const item of props.items) {
            item.transferDate = nowTime;
        }
        props.onSetTransferDataToSalaries(props.items.map(i => i.id));
    };

    downloadSalaryFile = async() => {
        if (!await AppUtils.validate(this.checkErrors, this.getWarningMessage)) { return; }
        const saveData = SalaryTransferToSystemDialog.getSaveDataFromState(this.state);
        await AppUtils.callService(() => salaryService.getSalaryFile(saveData.formData));
        this.setSalaryTransferDate();
    };

    sendSalaryToServer = async(targetErp) => {
        if (!await AppUtils.validate(this.checkErrors, this.getWarningMessage)) { return; }
        const saveData = SalaryTransferToSystemDialog.getSaveDataFromState(this.state);
        await AppUtils.callService(() => salaryService.sendSalary(saveData.formData, targetErp));
        this.setSalaryTransferDate();
    };
    // #endregion Saving

    handleDownloadClick = () => {
        this.downloadSalaryFile();
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const items = state.items;
        const dialogClasses = "settings salaryTransferToSystem px1000" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <Dialog
                    classes={dialogClasses}
                    title={Translations.TransferToBePaid}
                    show={true}
                    body={
                        <div>
                            <div className="row">
                                <div className="col-12">
                                    <div>
                                        <SalaryTransferToSystemDialogListHeader
                                            sortColumn={state.sortColumn}
                                            sortOrderIsAsc={state.sortOrderIsAsc}
                                            onColumnClick={this.changeSortColumn}
                                        />
                                    </div>
                                    <div className="listContainer noTopMargin">
                                        <div className="list striped">
                                            <div className="lineContainer">
                                                {items.map((item) =>
                                                    <SalaryTransferToSystemDialogListLine
                                                        key={item.id}
                                                        item={item}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <CheckBox
                                            title={Translations.SetSalariesToTransferredState}
                                            enabled={true}
                                            checked={state.setToTransferred}
                                            onCheckboxClickBoolean={this.setToTransferredChange}
                                        />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group">
                                        <CheckBox
                                            title={Translations.IncludeTransferredSalaries}
                                            enabled={true}
                                            checked={state.showTransferred}
                                            onCheckboxClickBoolean={this.showTransferredChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <Button
                                    title={Translations.ExportToTransferFile }
                                    classes="btn-primary"
                                    onClick={this.handleDownloadClick}
                                    enabled={props.salaryDownloadEnabled && items.length > 0}
                                />
                                <ButtonDropdown
                                    disabled={!props.salaryTransferEnabled || props.salaryTransferEnabled === null}
                                    removeDefaultButtonClasses={true}
                                    buttonIconClasses="hidden"
                                    buttonClasses="btn btn-primary dropdown-toggle"
                                    dropdownMenuClasses="w100 dropdown-menu-box"
                                    selectedTitle={Translations.ExportToSalarySystem}
                                    actions={this.state.targetErps}
                                />
                            </div>
                        </div>}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}
