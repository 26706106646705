export enum HourBookingCategory {
    Normal = 0,
    PeriodOvertime50 = 1,
    PeriodOvertime100 = 2,
    WeekOvertime50 = 3,
    WeekOvertime100 = 4,
    Evening = 5,
    Night = 6,
    Saturday = 7,
    SundayOrHoliday = 8,
    AlternativeSunday = 9,
    PaidBreak = 10,
    CustomNormalType = 11,
    MidWeekHoliday = 12,
    GrandHoliday = 13,
    MidWeekHoliday200 = 17,
    SalaryInSickTime = 16,// this is in database
    WorkTimeCut = 15, // this in database
    HalfDailyAllowance = 21,
    DailyAllowance = 22,
    DailyOvertime50 = 33,
    DailyOvertime100 = 34,
    MonthlyOvertime50 = 35,
    SecondaryEvening = 40,
    SecondaryNight = 41,
    OvertimeBase = 50,
    Break = 100,
    PaidWaiting = 101,
    UnPaidWaiting = 102,
    CustomAnyType = 110, // Custom category for anything other than normal work.
    CustomAddition = 111,
    MealBreak = 1001,
    PaidMealBreak = 1002,
    OverTimeCalculation = 2000,
    DayBookingNormal = 2001,
    DayBookingOther = 2002
}

export interface IWorkHoursItem {
    employeeId: string;
    workShiftId?: string;
    workShiftTimeSlotId?: string;
    dayBookingId?: string;
    workOrderId: string;
    costCenterId: string;
    vehicleId?: string;
    workTimeTypeId?: string;
    category: string;
    categoryId: string; // salaryRowTypeId or category
    categoryName?: string;
    salaryRowTypeId: string;
    salaryRowTypeName?: string;
    dateStr: string;
    date: number;
    amount: number;
    dayHoursRatio?: number;
    comment?: string;
    startTime?: string;
    endTime?: string;
}

export const 
normalWorkPaidCategories = () =>
    [
        HourBookingCategory.Normal,
        HourBookingCategory.PaidBreak,
        HourBookingCategory.PaidWaiting,
    ].map((c) => c.toString());

export const paidHourBookingCategories = () =>
    [
        HourBookingCategory.Normal,
        HourBookingCategory.CustomNormalType,
        HourBookingCategory.PaidBreak,
        HourBookingCategory.PaidWaiting,
        HourBookingCategory.DayBookingNormal,
        HourBookingCategory.OvertimeBase,
    ].map((c) => c.toString());

export const deductionHourBookingCategories = () =>
    [
        HourBookingCategory.Break,
        HourBookingCategory.UnPaidWaiting,
        HourBookingCategory.MealBreak,
    ].map((c) => c.toString());

export const dailyAllowanceCategories = () =>
    [
        HourBookingCategory.HalfDailyAllowance,
        HourBookingCategory.DailyAllowance,
    ].map((c) => c.toString());

export const deletableCategories = () =>
    [
        HourBookingCategory.Evening,
        HourBookingCategory.Night,
        HourBookingCategory.Saturday,
        HourBookingCategory.SundayOrHoliday,
        HourBookingCategory.PaidBreak,
        HourBookingCategory.GrandHoliday,
        HourBookingCategory.HalfDailyAllowance,
        HourBookingCategory.DailyAllowance,
        HourBookingCategory.PaidWaiting,
        HourBookingCategory.MealBreak,
        HourBookingCategory.PaidMealBreak,
        HourBookingCategory.OverTimeCalculation
    ].map((c) => c.toString());
