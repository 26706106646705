// AcknowledgementMessageDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as baseService from "../../services/baseService";
import * as storeActions from "../../models/store/storeActions";
import * as acknowledgementService from "../../services/acknowledgementService";
import { Dialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { SaveData } from "../../framework/saveData";

// AcknowledgementMessageDialog
// ***********************************************************************************************************************
export interface IAcknowledgementMessageDialogProp {
    classes?: string;
    acknowledgementId: string;
    workOrderNumber: number;
    workOrderDescription: string;
    onOk: () => void;
    onCancel: () => void;
}

export interface IAcknowledgementMessageDialogState {
    subject: string;
    body: string;
}

export class AcknowledgementMessageDialog extends React.Component<IAcknowledgementMessageDialogProp, IAcknowledgementMessageDialogState> {
    constructor(props) {
        super(props);
        this.state = { subject: Translations.WorkOrder + " " + props.workOrderNumber.toString(10) + " " + props.workOrderDescription, body: "" };
    }

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "subject") {
            this.setState({ subject: value });
        } else if (name === "body") {
            this.setState({ body: value });
        }
    };

    private static validate = (state: IAcknowledgementMessageDialogState): boolean => {
        if (!state.subject) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.MessageSubjectMustBeDefined));
            return false;
        }
        if (!state.body) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.MessageContentMustBeDefined));
            return false;
        }
        return true;
    };

    private static getSaveDataFromState = (props: IAcknowledgementMessageDialogProp, state: IAcknowledgementMessageDialogState): SaveData => {
        const data = new SaveData();
        data.append("workOrderAcknowledgementId", props.acknowledgementId);
        data.append("subject", state.subject);
        data.append("body", state.body);
        return data;
    };

    saveContact = () => {
        const obj = this;
        const props = this.props;
        const state = this.state;
        if (!AcknowledgementMessageDialog.validate(state)) return;
        const saveData = AcknowledgementMessageDialog.getSaveDataFromState(props, state);
        if (Base.isNullOrUndefined(saveData)) return;
        // Call server
        store.customStore.dispatch(storeActions.fetchStart());
        acknowledgementService.sendMessage(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                obj.props.onOk();
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleOkClick = () => {
        this.saveContact();
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const dialogClasses = "acknowledgementMessage px600" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <Dialog
                    classes={dialogClasses}
                    title={Translations.SendMessage}
                    show={true}
                    body={<div>
                        <div className="form-group required">
                            <label className="control-label smallFont">{Translations.MessageSubject}</label>
                            <input type="text" className="form-control" name="subject" title={Translations.MessageSubject} value={this.state.subject} onChange={this.handleChange} />
                        </div>
                        <div className="form-group required">
                            <label className="control-label smallFont">{Translations.MessageContent}</label>
                            <textarea className="form-control" name="body" title={Translations.MessageContent} value={this.state.body} onChange={this.handleChange} maxLength={5000} />
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.Send, classes: "btn-primary", enabled: true, onClick: this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}