import { postApiCall } from "./tokenService";
import { GeocodeResult } from "../models/../framework/geocodeResult";
import { RouteResult } from "../models/../framework/routeResult";
import { ReverseGeocodeResult } from "../models/../framework/reverseGeocodeResult";

export const geocode = (street: string, postalCode: string, city: string, countryCode: string): Promise<GeocodeResult> => {
    return postApiCall<GeocodeResult>("api/location/geocode", GeocodeResult, JSON.stringify({
        street: street || "",
        postalCode: postalCode || "",
        city: city || "",
        countryCode: countryCode || "",
        freeText: null
    }));
};

export const geocodeFreeText = (freeText: string): Promise<GeocodeResult> => {
    return postApiCall<GeocodeResult>("api/location/geocode", GeocodeResult, JSON.stringify({
        street: null,
        postalCode: null,
        city: null,
        countryCode: null,
        freeText: freeText || ""
    }));
};

export const reverseGeocode = (latitude: number, longitude: number): Promise<ReverseGeocodeResult> => {
    return postApiCall<ReverseGeocodeResult>("api/location/reversegeocode", ReverseGeocodeResult, JSON.stringify({
        latitude: latitude,
        longitude: longitude
    }));
};

export const route = (originLatitude: number, originLongitude: number, destinationLatitude: number, destinationLongitude: number): Promise<RouteResult> => {
    return postApiCall<RouteResult>("api/location/route", RouteResult, JSON.stringify({
        origin: {
            latitude: originLatitude,
            longitude: originLongitude
        },
        destination: {
            latitude: destinationLatitude,
            longitude: destinationLongitude
        }
    }));
};
