// WorkOrderDialogBookingsList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import * as workOrderService from "../../services/workOrderService";
import { ToolButton } from "../framework/toolButton";
import { Base } from "../../framework/base";
import { IWorkOrderEditItemHourBooking, WorkOrderEditItemHourBooking, WorkOrderEditItemSaveHourBooking } from "../../models/work/workOrderEditItemHourBooking";
import { IWorkOrderEditItemRideBooking, WorkOrderEditItemRideBooking, WorkOrderEditItemSaveRideBooking } from "../../models/work/workOrderEditItemRideBooking";
import { WorkOrderDialogHourBookingListLine, WorkOrderDialogHourBookingDialog } from "./workOrderDialogHourBooking";
import { WorkOrderDialogRideBookingListLine, WorkOrderDialogRideBookingDialog } from "./workOrderDialogRideBooking";
import { IWorkOrderEditSite } from "../../models/work/workOrderEditSite";
import { SaveData } from "../../framework/saveData";
import { ButtonDropdown, IButtonDropdownAction } from "../framework/dropdown";
import { TimeFormat } from "../../models/common/enums";
import { IVehicleItem, VehicleItem } from "../../models/vehicle/vehicleItem";
import { IEmployeeItem } from "../../models/employee/employeeItem";
import { UserParameters } from "../../models/employee/userParameters";

// WorkOrderDialogBookingsList
export interface IWorkOrderDialogBookingsListProp {
    title?: string;
    titleId?: string;
    titleClass?: string;
    isReadOnly: boolean;
    employeeIds: string[];
    workOrderId: string;
    workOrderStartTime: number;
    workTimeFormat: TimeFormat;
    //HourBooking
    hourBookings: IWorkOrderEditItemHourBooking[];
    showHourBookingInvoiceHours: boolean;
    //RideBooking
    site: IWorkOrderEditSite;
    rideBookings: IWorkOrderEditItemRideBooking[];
    employees: IEmployeeItem[];
    vehicles: IVehicleItem[];
    //HourBooking
    onHourBookingModified: (hourBooking: IWorkOrderEditItemHourBooking) => void;
    onHourBookingRemoved: (id: string) => void;
    //RideBooking
    onRideBookingModified: (rideBooking: IWorkOrderEditItemRideBooking) => void;
    onRideBookingRemoved: (id: string) => void;
}

interface IWorkOrderDialogBookingsListState {
    selectedId: string;
    employees: IEmployeeItem[];
    //HourBooking
    hourBookingDialogHourBooking: IWorkOrderEditItemHourBooking;
    showHourBookingDialog: boolean;
    //RideBooking
    rideBookingDialogRideBooking: IWorkOrderEditItemRideBooking;
    showRideBookingDialog: boolean;
    //UI
    stateButtons: IButtonDropdownAction[];
}

export class WorkOrderDialogBookingsList extends React.Component<IWorkOrderDialogBookingsListProp, IWorkOrderDialogBookingsListState> {
    getOrderedListOfEmployees = (props: IWorkOrderDialogBookingsListProp): IEmployeeItem[] => {
        const userParameters = new UserParameters();
        const employeeId = userParameters.getEmployeeId();
        const primaryEmployees = props.employees.filter(i => i.id === employeeId || props.employeeIds.indexOf(i.id) > -1);
        const primaryEmployeeIds = primaryEmployees.map(i => i.id);
        const result = props.employees.filter(i => primaryEmployeeIds.indexOf(i.id) < 0);
        result.unshift(...primaryEmployees.filter(i => i.id !== employeeId));
        const employee = props.employees.find(i => i.id === userParameters.getEmployeeId());
        if (employee) {
            result.unshift(employee);
        }
        return result;
    };

    constructor(props) {
        super(props);
        const stateButtons: IButtonDropdownAction[] = [];
        stateButtons.push({ title: Translations.AddHourBooking, onClick: this.handleHourBookingAdd });
        stateButtons.push({ title: Translations.AddRideBooking, onClick: this.handleRideBookingAdd });
        this.state = {
            stateButtons: stateButtons,
            selectedId: null,
            employees: this.getOrderedListOfEmployees(props),
            hourBookingDialogHourBooking: null,
            showHourBookingDialog: false,
            rideBookingDialogRideBooking: null,
            showRideBookingDialog: false,
        };
    }

    componentDidUpdate(prevProps: IWorkOrderDialogBookingsListProp, prevState: IWorkOrderDialogBookingsListState): void {
        const props = this.props;
        if (prevProps.employeeIds.map(i => i).join("#") + prevProps.employees.map(i => i.id + "," + i.getTitle()).join("#") === props.employeeIds.map(i => i).join("#") + props.employees.map(i => i.id + "," + i.getTitle()).join("#")) return;
        this.setState({
            employees: this.getOrderedListOfEmployees(props)
        });
    }

    // #region HourBooking
    hourBookingEdit = (hourBooking: IWorkOrderEditItemHourBooking) => {
        if (!hourBooking) return;
        this.setState({
            hourBookingDialogHourBooking: hourBooking,
            showHourBookingDialog: true,
        });
    };

    handleHourBookingAdd = () => {
        const props = this.props;
        const state = this.state;
        if (props.isReadOnly) return;
        const hourBooking = new WorkOrderEditItemHourBooking();
        hourBooking.id = Base.getGuid();
        hourBooking.date = Base.timeToDateStr(props.workOrderStartTime).toUtcDate().getTime();
        hourBooking.amount = 0;
        hourBooking.employeeId = state.employees.length > 0 ? state.employees[0].id : "";
        this.hourBookingEdit(hourBooking);
    };

    saveHourBooking = (hourBooking: IWorkOrderEditItemHourBooking) => {
        const obj = this;
        const props = this.props;
        //Create savedata
        const hourBookings = [hourBooking];
        const saveData = new SaveData();
        saveData.append("id", this.props.workOrderId);
        saveData.append("hourBookings", JSON.stringify(hourBookings.reduce((result, i) => {
            result.push(new WorkOrderEditItemSaveHourBooking(i));
            return result;
        }, [])));
        //Save to db
        store.customStore.dispatch(storeActions.fetchStart());
        workOrderService.saveWorkOrderHourBookings(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                obj.setState({
                    selectedId: success.ids[0],
                    showHourBookingDialog: false
                });
                hourBooking.id = success.ids[0];
                hourBooking.rowId = success.rowIds[0];
                props.onHourBookingModified(hourBooking);
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleHourBookingDialogOk = (emplyeeId: string, date: number, amount: number, invoice: number, comment: string) => {
        if (this.props.isReadOnly) return;
        const hourBooking = this.state.hourBookingDialogHourBooking;
        hourBooking.employeeId = emplyeeId;
        hourBooking.date = date;
        hourBooking.amount = amount;
        hourBooking.invoice = invoice;
        hourBooking.comment = comment;
        this.saveHourBooking(hourBooking);
    };

    handleHourBookingDialogCancel = () => {
        this.setState({
            showHourBookingDialog: false
        });
    };

    removeHourBooking = (id: string) => {
        const props = this.props;
        //Create savedata
        const hourBookingIds = [id];
        const saveData = new SaveData();
        saveData.append("id", props.workOrderId);
        saveData.append("hourBookingIds", JSON.stringify(hourBookingIds));
        //Save to db
        store.customStore.dispatch(storeActions.fetchStart());
        workOrderService.removeWorkOrderHourBookings(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                props.onHourBookingRemoved(id);
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };
    // #endregion HourBooking

    // #region RideBooking
    rideBookingEdit = (rideBooking: IWorkOrderEditItemRideBooking) => {
        if (!rideBooking) return;
        this.setState({
            rideBookingDialogRideBooking: rideBooking,
            showRideBookingDialog: true,
        });
    };

    handleRideBookingAdd = () => {
        const props = this.props;
        const state = this.state;
        if (props.isReadOnly) return;
        const rideBooking = new WorkOrderEditItemRideBooking();
        rideBooking.id = Base.getGuid();
        rideBooking.date = Base.timeToDateStr(props.workOrderStartTime).toUtcDate().getTime();
        rideBooking.amount = props.site ? props.site.distance : 0;
        rideBooking.employeeId = state.employees.length > 0 ? state.employees[0].id : "";
        rideBooking.vehicleId = rideBooking.employeeId ? VehicleItem.getFirstVehicleIdByEmployeeId(props.vehicles, rideBooking.employeeId) : "";
        if (!rideBooking.vehicleId && props.vehicles.length > 0) {
            rideBooking.vehicleId = props.vehicles[0].id;
        }
        this.rideBookingEdit(rideBooking);
    };

    saveRideBooking = (rideBooking: IWorkOrderEditItemRideBooking) => {
        const obj = this;
        const props = this.props;
        //Create savedata
        const rideBookings = [rideBooking];
        const saveData = new SaveData();
        saveData.append("id", this.props.workOrderId);
        saveData.append("rideBookings", JSON.stringify(rideBookings.reduce((result, i) => {
            result.push(new WorkOrderEditItemSaveRideBooking(i));
            return result;
        }, [])));
        //Save to db
        store.customStore.dispatch(storeActions.fetchStart());
        workOrderService.saveWorkOrderRideBookings(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                obj.setState({
                    selectedId: success.ids[0],
                    showRideBookingDialog: false
                });
                rideBooking.id = success.ids[0];
                rideBooking.rowId = success.rowIds[0];
                props.onRideBookingModified(rideBooking);
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleRideBookingDialogOk = (emplyeeId: string, date: number, vehicleId: string, amount: number, comment: string) => {
        const props = this.props;
        if (props.isReadOnly) return;
        const rideBooking = this.state.rideBookingDialogRideBooking;
        rideBooking.employeeId = emplyeeId;
        rideBooking.date = date;
        rideBooking.vehicleId = vehicleId;
        rideBooking.amount = amount;
        rideBooking.comment = comment;
        this.saveRideBooking(rideBooking);
    };

    handleRideBookingDialogCancel = () => {
        this.setState({
            showRideBookingDialog: false
        });
    };

    removeRideBooking = (id: string) => {
        const props = this.props;
        //Create savedata
        const rideBookingIds = [id];
        const saveData = new SaveData();
        saveData.append("id", props.workOrderId);
        saveData.append("rideBookingIds", JSON.stringify(rideBookingIds));
        //Save to db
        store.customStore.dispatch(storeActions.fetchStart());
        workOrderService.removeWorkOrderRideBookings(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                props.onRideBookingRemoved(id);
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };
    // #endregion RideBooking

    handleEdit = () => {
        const props = this.props;
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        const hourBooking = props.hourBookings.find(i => i.id === selectedId);
        if (hourBooking) {
            this.hourBookingEdit(hourBooking);
        }
        const rideBooking = props.rideBookings.find(i => i.id === selectedId);
        if (rideBooking) {
            this.rideBookingEdit(rideBooking);
        }
    };

    handleRemove = () => {
        const props = this.props;
        if (props.isReadOnly) return;
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        const hourBooking = props.hourBookings.find(i => i.id === selectedId);
        if (hourBooking) {
            this.removeHourBooking(selectedId);
        }
        const rideBooking = props.rideBookings.find(i => i.id === selectedId);
        if (rideBooking) {
            this.removeRideBooking(selectedId);
        }
    };

    handleLineClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleLineDoubleClick = (id: string) => {
        this.handleEdit();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const hourBookings = props.hourBookings;
        const rideBookings = props.rideBookings;
        return (
            <div className="workOrderSubList">
                <div className="commandRow">
                    {!!props.title &&
                        <label id={props.titleId} className={"control-label listTitle" + (props.titleClass ? " " + props.titleClass : "")}>{props.title}</label>
                    }
                    {!props.isReadOnly &&
                        <ToolButton
                            title={Translations.Remove}
                            enabled={!!state.selectedId}
                            classes={"round right remove"}
                            onClick={this.handleRemove}
                        />
                    }
                    <ToolButton
                        title={Translations.Edit}
                        enabled={!!state.selectedId}
                        classes={"round right edit"}
                        onClick={this.handleEdit}
                    />
                    {!props.isReadOnly &&
                        <ButtonDropdown
                            menuRight={true}
                            classes={"right"}
                            buttonClasses={"btn-tool add"}
                            buttonIconClasses={""}
                            actions={state.stateButtons}
                        />
                    }
                </div>
                <div className="listContainer bookingsContainer">
                    {(hourBookings.length > 0 || rideBookings.length > 0) &&
                        <div className="list bookings">
                            {hourBookings.map((hourBooking) =>
                                <WorkOrderDialogHourBookingListLine
                                    key={hourBooking.id}
                                    showHourBookingInvoiceHours={props.showHourBookingInvoiceHours}
                                    workTimeFormat={props.workTimeFormat}
                                    hourBooking={hourBooking}
                                    employees={state.employees}
                                    selectedId={state.selectedId}
                                    onClick={this.handleLineClick}
                                    onDoubleClick={this.handleLineDoubleClick}
                                />
                            )}
                            {rideBookings.map((rideBooking) =>
                                <WorkOrderDialogRideBookingListLine
                                    key={rideBooking.id}
                                    rideBooking={rideBooking}
                                    employees={state.employees}
                                    vehicles={props.vehicles}
                                    selectedId={state.selectedId}
                                    onClick={this.handleLineClick}
                                    onDoubleClick={this.handleLineDoubleClick}
                                />
                            )}
                        </div>
                    }
                </div>
                {state.showHourBookingDialog && !!state.hourBookingDialogHourBooking &&
                    <WorkOrderDialogHourBookingDialog
                        workTimeFormat={props.workTimeFormat}
                        isReadOnly={props.isReadOnly}
                        hourBooking={state.hourBookingDialogHourBooking}
                        employees={state.employees}
                        onOk={this.handleHourBookingDialogOk}
                        onCancel={this.handleHourBookingDialogCancel}
                    />
                }
                {state.showRideBookingDialog && !!state.rideBookingDialogRideBooking &&
                    <WorkOrderDialogRideBookingDialog
                        isReadOnly={props.isReadOnly}
                        rideBooking={state.rideBookingDialogRideBooking}
                        employees={state.employees}
                        vehicles={props.vehicles}
                        onOk={this.handleRideBookingDialogOk}
                        onCancel={this.handleRideBookingDialogCancel}
                    />
                }
            </div>
        );
    }
}
