import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IdTitle } from "../models/common/idTitle";
import { IGetReportResponse, ReportingComponentType } from "../models/reporting/getReportingResponse";
import { ReportingItems } from "../models/reporting/ReportingQueryResponse";

export interface ReportingState {
    reports: IdTitle[];
    filters: IGetReportResponse;
    selectedFilters: {
        [ReportingComponentType.TimePeriod]: string;
        [ReportingComponentType.Employee]: [];
        [ReportingComponentType.EmployeeGroup]: [];
        [ReportingComponentType.ShowCustomers]: number;
        [ReportingComponentType.ShowOrders]: number;
        [ReportingComponentType.ShowCostCenters]: number;
    };
    rows: ReportingItems[];
    columns: [];
}

const initialState: ReportingState = {
    reports: null,
    filters: null,
    selectedFilters: {
        [ReportingComponentType.TimePeriod]: "",
        [ReportingComponentType.Employee]: [],
        [ReportingComponentType.EmployeeGroup]: [],
        [ReportingComponentType.ShowCustomers]: 0,
        [ReportingComponentType.ShowOrders]: 0,
        [ReportingComponentType.ShowCostCenters]: 0,
    },
    rows: [],
    columns: []
};

export const reportingSlice = createSlice({
    name: "reporting",
    initialState,
    reducers: {
        setReports: (state, action: PayloadAction<IdTitle[]>) => {
            state.reports = action.payload;
        },
        setFilters: (state, action: PayloadAction<IGetReportResponse>) => {
            state.filters = action.payload;
        },
        updateSelectedFilters: (state, action: PayloadAction<{ type: string; items: string[] }>) => {
            const { type, items } = action.payload;
            if(items.length === 0) {
                state.selectedFilters[type] = [];
            } else {
                if(items.length === 1) state.selectedFilters[type] = items;
                const existing = state.selectedFilters[type].some((e: string) => items.map(p => p === e));
                if(!existing) {
                    state.selectedFilters[type] = [...state.selectedFilters[type], ...items];
                } else {
                    const tmp = [...state.selectedFilters[type], ...items];
                    const newArr = items.filter((item) => !tmp.includes((i: string) => i === item));
                    state.selectedFilters[type] = newArr;
                }
            }
        },
        setCheckBoxFilter: (state, action: PayloadAction<{ type: string; value: number }>) => {
            const { type, value } = action.payload;
            state.selectedFilters[type] = value;
        },
        setTimePeriod: (state, action: PayloadAction<string>) => {
            state.selectedFilters[ReportingComponentType.TimePeriod] = action.payload;
        },
        setRows: (state, action: PayloadAction<ReportingItems[]>) => {
            state.rows = action.payload;
        },
        setColumns: (state, action: PayloadAction<[]>) => {
            state.columns = action.payload;
        }
    }
});

export const {
    setReports,
    setFilters,
    updateSelectedFilters,
    setCheckBoxFilter,
    setTimePeriod,
    setRows,
    setColumns
} = reportingSlice.actions;

export const reportingReducer = reportingSlice.reducer;
