export interface ICodeTitle {
    code: string;
    title: string;
}

export class CodeTitle implements ICodeTitle {
    code: string;
    title: string;

    constructor();
    constructor(obj: ICodeTitle);
    constructor(obj?: any) {
        this.code = obj && obj.code || "";
        this.title = obj && obj.title || "";
    }
}