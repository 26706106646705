export interface INumberTitle {
    number: number;
    title: string;
}

export class NumberTitle implements INumberTitle {
    number: number;
    title: string;

    constructor();
    constructor(obj: INumberTitle);
    constructor(obj?: any) {
        this.number = obj && obj.number || 0;
        this.title = obj && obj.title || "";
    }

    static createIdTitle(number: number, title: string): INumberTitle {
        const result = new NumberTitle();
        result.number = number;
        result.title = title;
        return result;
    }
    
    static getTitleByNumber(items: INumberTitle[], number: number): string {
        if (!items || number === null || number === undefined || items.length < 1) return "";
        const item = items.find(i => i.number + 0.0001 > number && i.number - 0.0001 < number);
        if (!item) return "";
        return item.title;
    }
}