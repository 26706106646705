import { ISessionState, SessionState } from "../session/sessionState";

interface IPushSubscription {
    userCode: string;
}

interface IAppInstallPrompt {
    date: number;
}

export class IndexedDbManager {
    private static sessionStore = "sessionState";
    private static sessionKey = "session";
    private static pushSubscriptionStore = "pushSubscription";
    private static appInstallStore = "appInstall";
    private static promptKey = "prompt";

    private static isNullOrEmpty(test: string): boolean {
        return test === null || test === undefined || test === "";
    }

    private static isEmptyArray(test: string[]): boolean {
        return test === null || test === undefined || test.length < 1;
    }

    private static openDb(): Promise<IDBDatabase> {
        return new Promise<IDBDatabase>((resolve, reject) => {
            try {
                const request = indexedDB.open(appConfig.clientDatabaseName, 3);
                request.onsuccess = () => {
                    return resolve(request.result);
                };
                request.onerror = (event) => {
                    console.log(event);
                    return reject(new Error("Opening IndexedDb failed!"));
                };
                request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
                    const db = <IDBDatabase>(<any>event).target.result;
                    if (event.oldVersion < 1) {
                        db.createObjectStore(IndexedDbManager.sessionStore);
                    }
                    if (event.oldVersion < 2) {
                        const pushSubscriptionStore = db.createObjectStore(IndexedDbManager.pushSubscriptionStore);
                        pushSubscriptionStore.createIndex("iUserCode", "userCode");
                    }
                    if (event.oldVersion < 3) {
                        db.createObjectStore(IndexedDbManager.appInstallStore);
                    }
                    const tx = <IDBTransaction>(<any>event).target.transaction;
                    tx.oncomplete = () => {
                        return resolve(db);
                    };
                };
            } catch (e) {
                console.log(e, appConfig.clientDatabaseName, 2);
                return reject(e);
            }
        });
    }

    private static getAllKeysFromIndex(db: IDBDatabase, storeName: string, indexName: string, indexValue: string): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
            const result: string[] = [];
            try {
                const tx = db.transaction([storeName], "readonly");
                const store = tx.objectStore(storeName);
                const index = store.index(indexName);
                const keyRange = IDBKeyRange.only(indexValue);
                const request = index.openCursor(keyRange);
                request.onsuccess = e => {
                    const cursor = <IDBCursor>(<any>e).target.result;
                    if (cursor) {
                        // number | string | Date | BufferSource | IDBArrayKey
                        result.push(<string>cursor.primaryKey);
                        cursor.continue();
                    } else {
                        resolve(result);
                    }
                };
                request.onerror = (event) => {
                    console.log(event);
                    return reject(new Error("IndexedDb openCursor failed!"));
                };
            } catch (e) {
                console.log(e, [storeName], "readonly");
                return reject(e);
            }
        });
    }

    private static get<T>(db: IDBDatabase, storeName: string, key: string): Promise<T> {
        if (this.isNullOrEmpty(key)) return new Promise<T>((resolve) => { resolve(null); });
        return new Promise<undefined>((resolve, reject) => {
            try {
                const tx = db.transaction(storeName, "readonly");
                tx.onerror = (event) => {
                    console.log("IndexedDb get transaction failed!", event);
                };
                const store = tx.objectStore(storeName);
                const request = store.get(key);
                request.onerror = (event) => {
                    console.log(event);
                    return reject(new Error("IndexedDb get failed!"));
                };
                request.onsuccess = () => {
                    return resolve(request.result);
                };
            } catch (e) {
                console.log(e, storeName, "readonly");
                return reject(e);
            }
        });
    }

    private static put<T>(db: IDBDatabase, storeName: string, value: T, key: string): Promise<void> {
        if (this.isNullOrEmpty(key)) return new Promise<void>((resolve) => { resolve(); });
        return new Promise<void>((resolve, reject) => {
            try {
                const tx = db.transaction(storeName, "readwrite");
                tx.oncomplete = () => {
                    return resolve();
                };
                tx.onerror = (event) => {
                    console.log(event);
                    return reject(new Error("IndexedDb put transaction failed!"));
                };
                const store = tx.objectStore(storeName);
                const request = store.put(value, key);
                request.onerror = (event) => {
                    console.log("IndexedDb put failed!", event);
                };
            } catch (e) {
                console.log(e, storeName, "readwrite");
                return reject(e);
            }
        });
    }

    private static remove(db: IDBDatabase, storeName: string, key: string): Promise<void> {
        if (this.isNullOrEmpty(key)) return new Promise<void>((resolve) => { resolve(); });
        return new Promise<void>((resolve, reject) => {
            try {
                const tx = db.transaction(storeName, "readwrite");
                tx.oncomplete = () => {
                    return resolve();
                };
                tx.onerror = (event) => {
                    console.log(event);
                    return reject(new Error("IndexedDb remove transaction failed!"));
                };
                const store = tx.objectStore(storeName);
                const request = store.delete(key);
                request.onerror = (event) => {
                    console.log(event);
                    return reject(new Error("IndexedDb remove failed!"));
                };
                request.onsuccess = () => {
                    return resolve(request.result);
                };
            } catch (e) {
                console.log(e, storeName, "readwrite");
                return reject(e);
            }
        });
    }

    static storeSessionState(sessionState: ISessionState): Promise<void> {
        return IndexedDbManager.openDb().then((db) => {
            return IndexedDbManager.put<ISessionState>(db, IndexedDbManager.sessionStore, sessionState, IndexedDbManager.sessionKey).then(() => {
                return new Promise<void>((resolve) => { resolve(); });
            });
        });
    }

    static getSessionState(): Promise<ISessionState> {
        return IndexedDbManager.openDb().then((db) => {
            return IndexedDbManager.get<ISessionState>(db, IndexedDbManager.sessionStore, IndexedDbManager.sessionKey).then((sessionState) => {
                return sessionState !== null && sessionState !== undefined ? new SessionState(sessionState) : null;
            });
        }).catch(reason => {
            return null;
        });
    }

    static storePushSubscription(pushSubscriptionId: string, userCode: string): Promise<void> {
        return IndexedDbManager.openDb().then((db) => {
            return IndexedDbManager.put<IPushSubscription>(db, IndexedDbManager.pushSubscriptionStore, { userCode: userCode }, pushSubscriptionId).then(() => {
                return new Promise<void>((resolve) => { resolve(); });
            });
        });
    }

    static getPushSubscriptionIds(userCode: string): Promise<string[]> {
        if (this.isNullOrEmpty(userCode)) return new Promise<string[]>((resolve) => { resolve([]); });
        return IndexedDbManager.openDb().then((db) => {
            return IndexedDbManager.getAllKeysFromIndex(db, IndexedDbManager.pushSubscriptionStore, "iUserCode", userCode);
        }).catch(reason => {
            return [];
        });
    }

    private static removePushSubscriptionsFromDbByIds(db: IDBDatabase, subscriptionIds: string[]): Promise<void> {
        if (this.isEmptyArray(subscriptionIds)) return new Promise<void>((resolve) => { resolve(); });
        return new Promise<void>((resolve, reject) => {
            try {
                const tx = db.transaction(IndexedDbManager.pushSubscriptionStore, "readwrite");
                tx.oncomplete = () => {
                    return resolve();
                };
                tx.onerror = (event) => {
                    console.log(event);
                    return reject(new Error("Removing subscriptions failed!"));
                };
                const store = tx.objectStore(IndexedDbManager.pushSubscriptionStore);
                for (let i = 0; i < subscriptionIds.length; i++) {
                    const subscriptionId = subscriptionIds[i];
                    const request = store.delete(IDBKeyRange.only(subscriptionId));
                    request.onerror = (event) => {
                        console.log("Removing subscription '" + subscriptionId + "' failed!", event);
                    };
                }
            } catch (e) {
                console.log(e);
                return reject(e);
            }
        });
    }

    static removePushSubscriptions(subscriptionIds: string[]): Promise<void> {
        if (this.isEmptyArray(subscriptionIds)) return new Promise<void>((resolve) => { resolve(); });
        return IndexedDbManager.openDb().then((db) => {
            return IndexedDbManager.removePushSubscriptionsFromDbByIds(db, subscriptionIds);
        });
    }

    static removeUserPushSubscriptions(userCode: string): Promise<string[]> {
        if (this.isNullOrEmpty(userCode)) return new Promise<string[]>((resolve) => { resolve([]); });
        return IndexedDbManager.openDb().then((db) => {
            return IndexedDbManager.getAllKeysFromIndex(db, IndexedDbManager.pushSubscriptionStore, "iUserCode", userCode).then((keys) => {
                return IndexedDbManager.removePushSubscriptionsFromDbByIds(db, keys).then(() => {
                    db.close();
                    return new Promise<string[]>((resolve) => { resolve(keys); });
                });
            });
        });
    }

    static getAppInstallPromptDate(): Promise<IAppInstallPrompt> {
        return IndexedDbManager.openDb().then((db) => {
            return IndexedDbManager.get<IAppInstallPrompt>(db, IndexedDbManager.appInstallStore, IndexedDbManager.promptKey).then((value) => {
                return value;
            });
        }).catch(reason => {
            return null;
        });
    }

    static storeAppInstallPromptDate(): Promise<void> {
        return IndexedDbManager.openDb().then((db) => {
            return IndexedDbManager.put<IAppInstallPrompt>(db, IndexedDbManager.appInstallStore, { date: new Date().getTime() }, IndexedDbManager.promptKey).then(() => {
                return new Promise<void>((resolve) => { resolve(); });
            });
        });
    }
}