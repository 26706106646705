import * as yup from "yup";
import { AnyObject } from "yup/lib/types";
import i18n, { TFunction } from "i18next";

import { ParcelTypes } from "../../../../models/transport/parcel";
import { ParcelDetailsDto as ParcelDetails } from "../../../../models/transport/parcel";
import { deleteParcel, postParcel, updateParcel } from "../../../../store/parcelSlice";
import { ParcelForm } from "./parcelTable";
import { AppDispatch } from "../../../../framework/customStore";

export const parcelTableSx = {
    sx: {
        marginBottom: 2,
        ".MuiTableCell-root": {
            minWidth: "57px",
            p: 0.1,
            fontSize: "0.8rem",
            border: "none"
        },
        ".MuiTableCell-root:nth-of-type(1)": {
            minWidth: "10px"
        },
        ".MuiTableCell-root:nth-of-type(2)": {
            minWidth: "10px"
        }
    }
};

export const emptyRow: ParcelDetails = {
    id: null,
    name: "",
    transportOrderId: null,
    amount: 1,
    width: 0,
    length: 0,
    height: 0,
    weight: 0,
    volume: 0,
    stackable: false,
    loadingMeter: 0,
    parcelType: undefined,
    hazardousMaterialClass: 0
};

export const parcelTypeOptions = Object.keys(ParcelTypes)
    .map((p, i) => { return { value: `${i}`, label: p }; });

interface ValidateProps {
    context: yup.TestContext<AnyObject>;
    value?: number;
    field: string;
    validateSet?: boolean;
    validateNegative?: boolean;
    validateLimits?: boolean;
    validateInteger?: boolean;
}

const validate = ({ value, field, context, validateSet, validateNegative, validateLimits, validateInteger }: ValidateProps) => {
    const MIN = 0;
    const MAX = 999999;
    if(validateSet && value === undefined || value === null) {
        return context.createError({
            message: i18n.t("validation.fieldIsRequired", { field })
        });
    }
    if(validateNegative && value && value < 0) {
        return context.createError({
            message: i18n.t("validation.fieldMinValue", { field, value: MIN })
        });
    }
    if(validateLimits && value && value > MAX) {
        return context.createError({
            message: i18n.t("validation.fieldMaxValue", { field, value: MAX })
        });
    }
    if(validateInteger && value && !Number.isInteger(value)) {
        return context.createError({
            message: i18n.t("validation.fieldIntValue", { field })
        });
    }
    return true;
};

export const validationSchema = yup.object({
    rows: yup.array().of(yup.object().shape({
        parcelType: yup.number().test((value, context) =>
            validate({ context, value, field: i18n.t("transport.parcel.type"), validateSet: true })
        ),
        amount: yup.number().test((value, context) =>
            validate({ context, value, field: i18n.t("transport.parcel.amount"), validateSet: true, validateInteger: true, validateNegative: true, validateLimits: true })
        ),
        weight: yup.number().test((value, context) =>
            validate({ context, value, field: i18n.t("transport.parcel.weight"), validateSet: true, validateNegative: true, validateLimits: true })
        ),
        height: yup.number().test((value, context) =>
            validate({ context, value, field: i18n.t("transport.parcel.height"), validateSet: true, validateNegative: true, validateLimits: true })
        ),
        width: yup.number().test((value, context) =>
            validate({ context, value, field: i18n.t("transport.parcel.width"), validateSet: true, validateNegative: true, validateLimits: true })
        ),
        length: yup.number().test((value, context) =>
            validate({ context, value, field: i18n.t("transport.parcel.length"), validateSet: true, validateNegative: true, validateLimits: true })
        )
    }))
});

export const emptyStrsToNulls = (obj: ParcelDetails | undefined) => JSON.parse(JSON.stringify(obj, (_, v: ParcelDetails) => (v === "" ? null : v))) as ParcelDetails;

const getChangedRows = <T extends Record<string, any>>(values: T, initialValues: T) => {
    return Object.entries(values).reduce((acc: Partial<T>, [key, value]) => {
        const hasChanged = initialValues[key as keyof T] !== value;
        if (hasChanged) acc[key as keyof T] = value;
        return acc;
    }, {});
};

interface ParcelUpdate {
    created: ParcelDetails[];
    changed: ParcelDetails[];
    deleted: ParcelDetails[];
}

export const getChangedParcelData = (formData: ParcelForm, originalRows: ParcelDetails[]) => {
    const originalRowsDeletedExcluded = originalRows.filter(r => formData?.rows?.some(p => p.id === r.id));
    const changedRows = Object.values(getChangedRows(formData?.rows, originalRowsDeletedExcluded));
    const normalized = changedRows.map((r => emptyStrsToNulls(r)));
    return {
        created: normalized.filter(p => !p.id),
        changed: normalized.filter(p => p.id),
        deleted: originalRows.filter(r => !formData?.rows.some(p => p.id === r.id))
    };
};

export const handleParcelReqs = (orderId: string, { created, changed, deleted }: ParcelUpdate, dispatch: AppDispatch, t: TFunction) => {
    created.forEach((parcelData) => dispatch(postParcel({ orderId, parcelData, t })));
    deleted.forEach((parcelData) => dispatch(deleteParcel({ parcelId: parcelData?.id, t })));
    changed.forEach((parcelData) => dispatch(updateParcel({ parcelId: parcelData?.id, parcelData, t })));
};
