import React, { useCallback, useMemo, useState } from "react";
import _groupBy from "lodash/groupBy";
import _map from "lodash/map";
import { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid-premium";

import { Base } from "../../../framework/base";
import { AppUtils } from "../../../models/common/appUtils";
import { SalaryRowTypeItem } from "../../../models/salary/salaryRowTypeItem";
import { IWorkHoursItem } from "../../../models/workTime/workHoursItem";
import { VehicleListItemDto } from "../../../models/transport/vehicle";
import { WorkTimeType } from "../../../models/workShitTimeSlot/workTimeType";

import { VehicleDetailsTable } from "../vehicle/vehicleDetailsTable";
import { EmployeeListItemDto } from "../../../models/employee/employee";
import { Translations } from "../../../models/translations";
import { WorkShiftDto } from "../../../models/workShift/workShift";
import { WorkShiftTimeSlotItem } from "../../../models/workShitTimeSlot/workShiftTimeSlotItem";
import { useTranslation } from "react-i18next";
import { GroupedDataGrid } from "../../framework/groupedDataGrid/groupedDataGrid";
import { ICostCenterItem } from "../../../models/costCenter/costCenterItem";
import MuiSelect from "../../framework/muiSelect";
import { MuiSwitch } from "../../framework/muiSwitch";
import { GroupingValue, setReportGrouping } from "../../../store/reportOptionsSlice";
import { useAppDispatch, useAppSelector } from "../../../framework/customStore";
import Grid2 from "@mui/material/Unstable_Grid2";

export interface GridRow {
    id: string;
    hierarchy: string[];
    workHourItems: IWorkHoursItem[];
    salaryRowTypes: SalaryRowTypeItem[];
    employees: EmployeeListItemDto[];
    costCenters: ICostCenterItem[];
    workTimeTypes: WorkTimeType[];
    date: string;
    totals: Record<string, number>;
}

interface GroupableWorkTimeGridProps {
    vehicles: VehicleListItemDto[];
    employees: EmployeeListItemDto[];
    salaryRowTypes: SalaryRowTypeItem[];
    costCenters: ICostCenterItem[];
    workTimeTypes: WorkTimeType[];
    workHours: IWorkHoursItem[];
    workShifts: WorkShiftDto[];
    filterByVehicles: (val: string) => boolean;
    filterByCostCenters: (val: string) => boolean;
    filterByEmployees: (val: string) => boolean;
}



export const GroupableWorkTimeGrid = ({
    vehicles,
    employees,
    salaryRowTypes,
    costCenters,
    workTimeTypes,
    workHours,
    workShifts,
    filterByVehicles,
    filterByCostCenters,
    filterByEmployees,
}: GroupableWorkTimeGridProps) => {
    const grouping = useAppSelector((state) => state.reportOptions.grouping);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const groupingOptions = useMemo(
        () => [
            {
                value: GroupingValue.Vehicle,
                label: t("reporting.fleet"),
            },
            {
                value: GroupingValue.Employee,
                label: t("reporting.employees"),
            },
        ],
        []
    );

    const displayedWorkHours = useMemo(() => {
        return workHours.filter(
            (w) =>
                filterByVehicles(w?.vehicleId) &&
                filterByCostCenters(w?.costCenterId) &&
                filterByEmployees(w?.employeeId)
        );
    }, [workHours, filterByVehicles, filterByCostCenters, filterByEmployees]);

    const workTimeTypeData = useMemo(
        () =>
            workShifts.flatMap((s) =>
                s.workShiftTimeSlots
                    .filter(
                        (slot) =>
                            filterByVehicles(slot?.vehicleId) &&
                            filterByCostCenters(slot?.costCenterId) &&
                            filterByEmployees(slot?.employeeId)
                    )
                    .map((slot) => ({
                        id: grouping.map((g) => slot[g]).join("_"),
                        workHourItem: null,
                        workSlotItem: {
                            employeeId: slot.employeeId,
                            vehicleId: slot.vehicleId,
                            workTimeTypeId: slot.workTimeTypeId,
                            amount: new WorkShiftTimeSlotItem(
                                slot
                            ).getDurationMinutes(),
                            dateStr: s.effectiveDate,
                        },
                    }))
            ),
        [
            workShifts,
            filterByVehicles,
            filterByCostCenters,
            filterByEmployees,
            grouping,
        ]
    );

    const baseData = displayedWorkHours.map((w) => {
        return {
            id: grouping.map((g) => w[g]).join("_"),
            workHourItem: w,
            workSlotItem: null,
        };
    });

    // Group workHours by date
    const groupedData = _groupBy(
        [...baseData, ...workTimeTypeData],
        (w) => `${w.id}_${(w.workHourItem ?? w.workSlotItem).dateStr}`
    );

    // Create grid rows, calculate totals for each group
    const gridRows: GridRow[] = _map(groupedData, (value, key) => {
        const item = value[0].workHourItem ?? value[0].workSlotItem;
        const date = item.dateStr;
        return {
            id: key,
            hierarchy: [...grouping.map((g) => item[g]), date],
            workHourItems: value.map((v) => v.workHourItem).filter(Boolean),
            employees: employees,
            salaryRowTypes: salaryRowTypes,
            costCenters: costCenters,
            workTimeTypes: workTimeTypes,
            date,
            totals: value.reduce(
                (acc, curr) => {
                    if (!!curr.workHourItem) {
                        acc[curr.workHourItem.salaryRowTypeId] =
                            (acc[curr.workHourItem.salaryRowTypeId] ?? 0) +
                            curr.workHourItem.amount;
                    }

                    return acc;
                },
                value.reduce((acc, curr) => {
                    if (!!curr.workSlotItem) {
                        acc[curr.workSlotItem.workTimeTypeId ?? "wtt_null"] =
                            (acc[
                                curr.workSlotItem.workTimeTypeId ?? "wtt_null"
                            ] ?? 0) + curr.workSlotItem.amount;

                        acc["wtt_total"] =
                            (acc["wtt_total"] ?? 0) + curr.workSlotItem.amount;
                    }

                    return acc;
                }, {})
            ),
        };
    }).sort((a, b) => Base.stringCompare(a.date, b.date));

    const gridDef: GridColDef<GridRow>[] = useMemo(
        () => [
            {
                field: "wtt_total",
                headerName: Translations.Total,
                description: t("vehicle.workTimeTypeTotal"),
                groupable: false,
                type: "number",
                minWidth: 100,
                flex: 1,
                valueGetter(params) {
                    return (params.row?.totals?.wtt_total ?? 0) / 60;
                },
                valueFormatter: (params: GridValueFormatterParams<number>) => {
                    const value = params.value;
                    if (!value) return "-";
                    return value.toLocaleFixed(2, "fi-Fi");
                },
            },
            ...salaryRowTypes.map(
                (srt): GridColDef<GridRow> => ({
                    field: srt.id,
                    headerName: `${srt.name} (${srt.measureUnit})`,
                    description: `${srt.name} (${srt.measureUnit})`,
                    groupable: false,
                    type: "number",
                    minWidth: 100,
                    flex: 1,
                    valueGetter(params) {
                        const divisor = AppUtils.isTimeUnit(srt.measureUnit)
                            ? 60
                            : 1;
                        return (params.row?.totals?.[srt.id] ?? 0) / divisor;
                    },
                    valueFormatter: (
                        params: GridValueFormatterParams<number>
                    ) => {
                        const value = params.value;
                        if (!value) return "-";
                        return value.toLocaleFixed(srt.decimals ?? 2, "fi-Fi");
                    },
                })
            ),
            ...workTimeTypes.map(
                (wtt): GridColDef<GridRow> => ({
                    field: wtt.workTimeTypeId,
                    headerName: wtt.name,
                    description: wtt.name,
                    groupable: false,
                    type: "number",
                    minWidth: 100,
                    flex: 1,
                    valueGetter(params) {
                        return (
                            (params.row?.totals?.[wtt.workTimeTypeId] ?? 0) / 60
                        );
                    },
                    valueFormatter: (
                        params: GridValueFormatterParams<number>
                    ) => {
                        const value = params.value;
                        if (!value) return "-";
                        return value.toLocaleFixed(2, "fi-Fi");
                    },
                })
            ),
        ],
        [salaryRowTypes, workTimeTypes]
    );

    const pinnedColumns = useMemo(() => ["wtt_total"], []);

    const getGroupingColLabel = useCallback(
        (id: string, depth: number) => {
            if (
                (depth === 0 && grouping[0] === GroupingValue.Employee) ||
                (depth > 0 && grouping[0] !== GroupingValue.Employee)
            ) {
                return employees?.find((e) => e.id === id)?.fullName ?? "";
            }

            if (!id) {
                return t("reporting.noFleet");
            }

            const vehicle = vehicles?.find((v) => v.id === id);
            return `${vehicle?.registerNumber} - ${vehicle?.brand}`;
        },
        [vehicles, employees, grouping]
    );

    return (
        <Grid2 container rowSpacing={2} pt={1}>
            <Grid2 xs={6} lg={2}>
                <MuiSelect
                    label="Ryhmittely"
                    value={grouping[0]}
                    onChange={(val) => dispatch(setReportGrouping([val]))}
                    options={groupingOptions}
                />
            </Grid2>
            <Grid2 xs={6} alignContent="center">
                <MuiSwitch
                    label={t(
                        `reporting.${
                            grouping[0] === GroupingValue.Vehicle
                                ? "additionalGroupByEmployee"
                                : "additionalGroupByFleet"
                        }`
                    )}
                    value={!!grouping[1]}
                    onChange={(_, checked) =>
                        dispatch(
                            setReportGrouping([
                                grouping[0],
                                checked
                                    ? grouping[0] === GroupingValue.Vehicle
                                        ? GroupingValue.Employee
                                        : GroupingValue.Vehicle
                                    : null,
                            ])
                        )
                    }
                    sx={{ ml: 2 }}
                />
            </Grid2>
            <Grid2 xs={12}>
                <GroupedDataGrid
                    noRowsText={t("reporting.noWorkTimes")}
                    detailsComponent={VehicleDetailsTable}
                    columns={gridDef}
                    rows={gridRows}
                    groupingColDef={{
                        headerName:
                            grouping[0] === GroupingValue.Vehicle
                                ? t("reporting.fleet")
                                : t("reporting.employee"),
                        valueFormatter: (data) => {
                            // for exports
                            const value = data?.value ?? Translations.Total;
                            if (value.match(/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/)) {
                                // val is UUID
                                return getGroupingColLabel(
                                    value,
                                    (data?.id as string).split("/").length - 2
                                );
                            } else {
                                // val is date
                                return value;
                            }
                        },
                    }}
                    getGroupLabel={getGroupingColLabel}
                    persistStateKey="GroupableWorkTimeGridState"
                    pinnedColumns={pinnedColumns}
                    enableColumnSelector
                />
            </Grid2>
        </Grid2>
    );
};
