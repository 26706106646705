import * as React from "react";
import * as pdfobject from "pdfobject";
import { RouteComponentProps } from "react-router";
import { Translations } from "../../models/translations";
import { Base } from "../../framework/base";
import { AcknowledgementHeader } from "./acknowledgementHeader";
import { IAcknowledgementEditItem } from "../../models/acknowledgement/acknowledgementEditItem";
import { Button } from "../framework/button";
import { AcknowledgementMessageDialog } from "./acknowledgementMessageDialog";
import * as CustomStore from "../../framework/customStore";
import * as StoreActions from "../../models/store/storeActions";
import * as AcknowledgementService from "../../services/acknowledgementService";
import * as BaseService from "../../services/baseService";

export interface IAcknowledgementMainOwnProps {
}

export interface IAcknowledgementMainStateProps {
    ownerLogo: string;
    canSendMessage: boolean;
    isLoading: boolean;
    acknowledgement: IAcknowledgementEditItem;
}

export interface IAcknowledgementMainDispatchProps {
    loadAcknowledgement: (id: string) => void;
}

export interface IAcknowledgementMainPathProps {
    acknowledgementId: string;
}

type AcknowledgementMainProp = IAcknowledgementMainOwnProps & IAcknowledgementMainStateProps & IAcknowledgementMainDispatchProps & RouteComponentProps<IAcknowledgementMainPathProps>;

interface IAcknowledgementMainState {
    pdfSupported: boolean;
    showAcknowledgementMessageDialog: boolean;
}

export class AcknowledgementMain extends React.Component<AcknowledgementMainProp, IAcknowledgementMainState> {
    constructor(props: AcknowledgementMainProp) {
        super(props);
        this.state = { pdfSupported: pdfobject.supportsPDFs, showAcknowledgementMessageDialog: false };
    }

    componentDidMount(): void {
        this.props.loadAcknowledgement(this.props.match.params.acknowledgementId);
    }

    handleApproveToBeInvoiced = (index?: number) => {
        const obj = this;
        // Call server
        CustomStore.customStore.dispatch(StoreActions.fetchStart());
        AcknowledgementService.approve(obj.props.acknowledgement.id)
            .then(success => {
                CustomStore.customStore.dispatch(StoreActions.showSuccessMessage(success.message));
                CustomStore.customStore.dispatch(StoreActions.acknowledgementApprovalSuccess(success.approvalDate));
            })
            .catch(error => {
                CustomStore.customStore.dispatch(StoreActions.showErrorMessage(BaseService.getErrorMessageFromError(error)));
            })
            .finally(() => CustomStore.customStore.dispatch(StoreActions.fetchEnd()));
    };

    handleSendMessage = (index?: number) => {
        this.setState({ showAcknowledgementMessageDialog: true });
    };

    handleAcknowledgementMessageDialogOk = () => {
        this.setState({ showAcknowledgementMessageDialog: false });
    };

    handleAcknowledgementMessageDialogCancel = () => {
        this.setState({ showAcknowledgementMessageDialog: false });
    };

    render() {
        const props = this.props;
        const state = this.state;
        const pdfStyle = state.pdfSupported ? { margin: 0, padding: 0, width: 100 + "%", height: 100 + "%" } : { };
        return (
            <div>
                <div className="row mainContent acknowledgementMain">
                    <div className="col-12">
                        {props.isLoading &&
                            <div className="loadingAcknowledgement"><div className="alert alert-info" role="alert">{Translations.LoadingAcknowledgement}</div></div>
                        }
                        {!Base.isNullOrUndefined(props.acknowledgement) &&
                            <div className="acknowledgementContainer">
                                <label className="control-label">{Translations.WorkOrder + " " + props.acknowledgement.workOrderNumber + " " + props.acknowledgement.workOrderDescription}</label>
                                <div className="row">
                                    <div className={"col-md-9 col-12 pdfContainer" + (state.pdfSupported ? "" : "NoPdfSupport")}>
                                        <object id="pdfObject" style={pdfStyle} data={props.acknowledgement.pdfFilePath + "#navpanes=0#view=FitV"} type="application/pdf" width={state.pdfSupported ? "100%" : null} height={state.pdfSupported ? "100%" : null}>
                                            <div id="pdfMessage" className="noPdfSupportMessage">{Translations.ThisBrowserDoesNotSupportPdfsPleaseDownloadPdfToViewIt}</div>
                                        </object>
                                    </div>
                                    <div className="col-md-3 col-12 buttonContainer">
                                        {props.acknowledgement.approvalDate > 0 &&
                                            <div className="approvedDate"><div className="alert alert-success" role="alert">{Translations.ApprovedToBeInvoiced + " " + Base.timeToDateTimeStr(props.acknowledgement.approvalDate)}</div></div>
                                        }
                                        <a className="btn btn-primary" href={props.acknowledgement.pdfFilePath} download={props.acknowledgement.pdfFileName}>{Translations.Download}</a>
                                        {props.acknowledgement.approvalDate < 1 &&
                                            <Button
                                                title={Translations.ApproveToBeInvoiced}
                                                classes={"btn-primary"}
                                                enabled={true}
                                                onClick={this.handleApproveToBeInvoiced}
                                            />
                                        }
                                        {props.canSendMessage &&
                                            <Button
                                                title={Translations.SendMessage}
                                                classes={"btn-primary"}
                                                enabled={true}
                                                onClick={this.handleSendMessage}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <AcknowledgementHeader
                    ownerLogo={props.ownerLogo}
                />
                {state.showAcknowledgementMessageDialog &&
                    <AcknowledgementMessageDialog
                        acknowledgementId={props.acknowledgement.id}
                        workOrderNumber={props.acknowledgement.workOrderNumber}
                        workOrderDescription={props.acknowledgement.workOrderDescription}
                        onOk={this.handleAcknowledgementMessageDialogOk}
                        onCancel={this.handleAcknowledgementMessageDialogCancel}
                    />
                }
            </div>
        );
    }
}
