import * as storeTypes from "./storeTypes";
import { IWorkOrderCalendarLineItem } from "../work/workOrderCalendarLineItem";
import { IWorkOrderItem } from "../work/workOrderItem";
import { ICalendarDayItem } from "../calendarDay/calendarDayItem";
import { IDayBookingItem } from "../dayBooking/dayBookingItem";
import { INumberTitle } from "../common/numberTitle";
import { IAcknowledgementMain } from "../acknowledgement/acknowledgementMain";
import { CalendarAccuracyType, TimeFormat } from "../common/enums";
import { IEmployeeParameters } from "../employee/employeeIParameters";
import { IVehicleItem } from "../vehicle/vehicleItem";
import { ISessionStateSsoIntegration } from "../session/sessionStateSsoIntegration";
import { ITypedOption } from "../common/typedOption";
import { Action } from "redux";
import { IRoutePointAutocompleteItems, IRoutePointAutocompleteItem } from "../routePoint/routePointAutocompleteItems";
import { IGetOwnerRoutePointsSuccess } from "./storeTypes";
import { IEmployeeGroupItem } from "../employeeGroup/employeeGroupItem";
import { IVehicleGroupItem } from "../vehicleGroup/vehicleGroupItem";
import { StorageProductShoppingCartItem } from "../storage/storageProductShoppingCartItem";
import {IGetReportResponse } from "../reporting/getReportingResponse";

// Action creators

// Fetch
export const fetchStart = (): storeTypes.IFetchStart => ({
    type: storeTypes.FETCH_START
});

export const fetchEnd = (): storeTypes.IFetchEnd => ({
    type: storeTypes.FETCH_END
});

// Install
export const setInstallProposalEvent = (event: storeTypes.IBeforeInstallPromptEvent): storeTypes.IInstallSet => ({
    type: storeTypes.INSTALL_SET,
    event: event
});

export const clearInstallProposalEvent = (): storeTypes.IInstallClear => ({
    type: storeTypes.INSTALL_CLEAR
});

// Alert
export const showSuccessMessage = (message: string): storeTypes.IAlertSuccess => ({
    type: storeTypes.ALERT_SUCCESS,
    message: message
});

export const showInfoMessage = (message: string): storeTypes.IAlertInfo => ({
    type: storeTypes.ALERT_INFO,
    message: message
});

export const showErrorMessage = (message: string): storeTypes.IAlertError => ({
    type: storeTypes.ALERT_ERROR,
    message: message
});

export const clearMessages = (): storeTypes.IAlertClear => ({
    type: storeTypes.ALERT_CLEAR
});

export const clearSuccessMessage = (): storeTypes.IAlertSuccessClear => ({
    type: storeTypes.ALERT_SUCCESS_CLEAR
});

export const clearInfoMessage = (): storeTypes.IAlertInfoClear => ({
    type: storeTypes.ALERT_INFO_CLEAR
});

interface ConfirmationArgs {
    dialogType: storeTypes.ConfirmationDialogType;
    title: string;
    text?: string;
    content?: React.ReactNode;
    yesTitle?: string;
    yesClasses?: string;
    onYes: storeTypes.IConfirmationCallback;
    noTitle?: string;
    noClasses?: string;
    onNo?: storeTypes.IConfirmationCallback;
}

export const setConfirmationArgs = (args: Omit<storeTypes.IConfirmationSet, "type" | "show">): storeTypes.IConfirmationSet => ({
    type: storeTypes.CONFIRMATION_SET,
    show: true,
    ...args
});

export const setConfirmation = (type: storeTypes.ConfirmationDialogType, title: string, text: string, onYes: storeTypes.IConfirmationCallback, onNo: storeTypes.IConfirmationCallback = null, onCancel: storeTypes.IConfirmationCallback = null,
    yesTitle: string = null, yesClasses: string = null, noTitle: string = null, noClasses: string = null): storeTypes.IConfirmationSet => ({
    type: storeTypes.CONFIRMATION_SET,
    dialogType: type,
    title: title,
    text: text,
    show: true,
    yesTitle: yesTitle,
    yesClasses: yesClasses,
    noTitle: noTitle,
    noClasses: noClasses,
    onYes: onYes,
    onNo: onNo,
    onCancel: onCancel
});


export const clearConfirmation = (): storeTypes.IConfirmationClear => ({
    type: storeTypes.CONFIRMATION_CLEAR
});

// Owner
export const ownerInitialize = (): storeTypes.IOwnerInitialize => ({
    type: storeTypes.OWNER_INITIALIZE,
});

export const setOwnerState = (name: string, name2: string, parameters: ITypedOption[]): storeTypes.IOwnerSetState => ({
    type: storeTypes.OWNER_SET_STATE,
    name: name,
    name2: name2,
    parameters: parameters
});

// Token
export const loginSuccess = (): storeTypes.ILoginSuccess => ({
    type: storeTypes.LOGIN_SUCCESS
});

export const loginFailure = (): storeTypes.ILoginFailure => ({
    type: storeTypes.LOGIN_FAILURE
});

export const logoutSuccess = (): storeTypes.ILogoutSuccess => ({
    type: storeTypes.LOGOUT_SUCCESS
});

export const setUserParameters = (parameters: IEmployeeParameters): storeTypes.IUserSetParameters => ({
    type: storeTypes.USER_SET_PARAMETERS,
    parameters: parameters,
});

export const setUserState = (employeeId: string, ownerAdmin: boolean, ssoIntegrations: ISessionStateSsoIntegration[]): storeTypes.IUserSetState => ({
    type: storeTypes.USER_SET_STATE,
    employeeId: employeeId,
    ownerAdmin: ownerAdmin,
    ssoIntegrations: ssoIntegrations,
});

// Acknowledgement
export const acknowledgementInitialize = (): storeTypes.IAcknowledgementInitialize => ({
    type: storeTypes.ACKNOWLEDGEMENT_INITIALIZE
});

export const acknowledgementLoadStart = (): storeTypes.IAcknowledgementLoadStart => ({
    type: storeTypes.ACKNOWLEDGEMENT_LOAD_START
});

export const acknowledgementMainSuccess = (acknowledgementMain: IAcknowledgementMain): storeTypes.IAcknowledgementMainSuccess => ({
    type: storeTypes.ACKNOWLEDGEMENT_MAIN_SUCCESS,
    ownerLogo: acknowledgementMain.ownerLogo,
    canSendMessage: acknowledgementMain.canSendMessage,
    acknowledgement: acknowledgementMain.acknowledgement
});

export const acknowledgementMainFailure = (): storeTypes.IAcknowledgementMainFailure => ({
    type: storeTypes.ACKNOWLEDGEMENT_MAIN_FAILURE
});

export const acknowledgementApprovalSuccess = (approvalDate: number): storeTypes.IAcknowledgementApprovalSuccess => ({
    type: storeTypes.ACKNOWLEDGEMENT_APPROVAL_SUCCESS,
    approvalDate: approvalDate
});

// Work
export const workMainInitialize = (): storeTypes.IWorkMainInitialize => ({
    type: storeTypes.WORK_MAIN_INITIALIZE
});

export const workMainSuccess = (workOrders: IWorkOrderItem[], workOrderTemplates: IWorkOrderItem[], favoriteWorkOrders: IWorkOrderItem[], showOnMapVehicles: IVehicleItem[],
    calendarLines: IWorkOrderCalendarLineItem[], calendarDays: ICalendarDayItem[], dayBookings: IDayBookingItem[], dayBookingTypes: INumberTitle[], workOrderStates: INumberTitle[],
    workOrderClasses: INumberTitle[], employeeGroups: IEmployeeGroupItem[], vehicleGroups: IVehicleGroupItem[], startDate: number, endDate: number, workTimeFormat: TimeFormat): storeTypes.IWorkMainSuccess => ({
    type: storeTypes.WORK_MAIN_SUCCESS,
    workTimeFormat: workTimeFormat,
    startDate: startDate,
    endDate: endDate,
    workOrders: workOrders,
    workOrderTemplates: workOrderTemplates,
    favoriteWorkOrders: favoriteWorkOrders,
    showOnMapVehicles: showOnMapVehicles,
    calendarLines: calendarLines,
    calendarDays: calendarDays,
    dayBookings: dayBookings,
    dayBookingTypes: dayBookingTypes,
    workOrderStates: workOrderStates,
    workOrderClasses: workOrderClasses,
    employeeGroups: employeeGroups,
    vehicleGroups: vehicleGroups
});

export const workMainFailure = (): storeTypes.IWorkMainFailure => ({
    type: storeTypes.WORK_MAIN_FAILURE
});

export const workMainSetCalendarAccuracy = (calendarAccuracy: CalendarAccuracyType): storeTypes.IWorkMainSetCalendarAccuracy => ({
    type: storeTypes.WORK_MAIN_SET_CALENDAR_ACCURACY,
    calendarAccuracy: calendarAccuracy,
});

export const workItemsSuccess = (workOrders: IWorkOrderItem[], workOrderTemplates: IWorkOrderItem[], favoriteWorkOrders: IWorkOrderItem[], showOnMapVehicles: IVehicleItem[],
    calendarLines: IWorkOrderCalendarLineItem[], calendarDays: ICalendarDayItem[], dayBookings: IDayBookingItem[], dayBookingTypes: INumberTitle[],
    startDate: number, endDate: number): storeTypes.IWorkItemsSuccess => ({
    type: storeTypes.WORK_ITEMS_SUCCESS,
    startDate: startDate,
    endDate: endDate,
    workOrders: workOrders,
    workOrderTemplates: workOrderTemplates,
    favoriteWorkOrders: favoriteWorkOrders,
    showOnMapVehicles: showOnMapVehicles,
    calendarLines: calendarLines,
    calendarDays: calendarDays,
    dayBookings: dayBookings,
    dayBookingTypes: dayBookingTypes
});

export const workItemsFailure = (): storeTypes.IWorkItemsFailure => ({
    type: storeTypes.WORK_ITEMS_FAILURE
});

export const workOrderTemplatesSuccess = (workOrderTemplates: IWorkOrderItem[]): storeTypes.IWorkTemplatesSuccess => ({
    type: storeTypes.WORK_TEMPLATES_SUCCESS,
    workOrderTemplates: workOrderTemplates,
});

// Route points
export const ownerRoutePointsInitialize = (): storeTypes.IOwnerRoutePointsInitialize => ({
    type: storeTypes.OWNER_ROUTE_POINTS_INITIALIZE
});

export const getOwnerRoutePointsSuccess = (routePoints: IRoutePointAutocompleteItems): IGetOwnerRoutePointsSuccess => ({
    type: storeTypes.GET_OWNER_ROUTE_POINTS_SUCCESS,
    ownerRoutePoints: routePoints,
});

export const getOwnerRoutePointsFailure = (): Action => ({
    type: storeTypes.GET_OWNER_ROUTE_POINTS_FAILURE,
});

export const ownerRoutePointsAdd = (routePoint: IRoutePointAutocompleteItem): storeTypes.IOwnerRoutePointsAdd => ({
    type: storeTypes.OWNER_ROUTE_POINTS_ADD,
    routePoint: routePoint
});

// Shopping cart
export const shoppingCartInitialize = (): storeTypes.IShoppingCartInitialize => ({
    type: storeTypes.SHOPPING_CART_INITIALIZE
});

export const shoppingCartUpdate = (shoppingCartItems: StorageProductShoppingCartItem[]): storeTypes.IShoppingCartUpdate => ({
    type: storeTypes.SHOPPING_CART_UPDATE,
    items: shoppingCartItems
});

export const shoppingCartSelectOrder = (selectedOrderId: string): storeTypes.IShoppingCartSelectOrder => ({
    type: storeTypes.SHOPPING_CART_SELECT_ORDER,
    selectedOrderId
})

export const reportingInitialResponse = (): storeTypes.IReportingInitial => ({
    type: storeTypes.REPORTING_INITIALIZE
});

export const reportingUpdateResponse = (response: IGetReportResponse): storeTypes.IGetReportingUpdate => ({
    type: storeTypes.REPORTING_UPDATE,
    response
});
