import { Base } from "../../framework/base";
import { IEditItem, EditItem } from "../common/editItem";
import { ISalaryWorkTimePeriodItem, SalaryWorkTimePeriodItem } from "../salaryPeriod/salaryWorkTimePeriodItem";

export interface ISalaryPeriodTypeEditItem extends IEditItem {
    name: string;
    activeState: number;
    salaryPeriods: ISalaryWorkTimePeriodItem[];

    getTitle(): string;
}

export class SalaryPeriodTypeEditItem extends EditItem implements ISalaryPeriodTypeEditItem {
    name: string;
    activeState: number;
    salaryPeriods: SalaryWorkTimePeriodItem[];

    constructor();
    constructor(obj: ISalaryPeriodTypeEditItem);
    constructor(obj?: any) {
        super(obj);
        this.name = obj && obj.name || "";
        this.activeState = obj && obj.activeState || 0;
        this.salaryPeriods = Base.getTypedArray<SalaryWorkTimePeriodItem>(obj ? obj.salaryPeriods : null, SalaryWorkTimePeriodItem);
    }
    
    getTitle(): string {
        return this.name;
    }
}
