export class StorageLocationProduct {
    id: string = null;
    rowId: string = null;
    productId = "";
    storageAmount = 0;
    bookedAmount = 0;
    freeAmount = 0;
    unit: string = null;
}

export class StorageLocationEditItem {
    id: string = null;
    storageId: string = null;
    productId: string = null;
    customerId: string = null;
    storageLocationCode = "";
    storageLocationName = "";
    description = "";
    storageProducts: StorageLocationProduct[] = [];
    removedStorageProductIds: string[] = [];
}
