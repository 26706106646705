export const objectToUrlParamsRecursive = (params: object, searchParam = new URLSearchParams()): string =>{
    if (!params) return;
    Object.keys(params).forEach(key => {
        if (params[key] !== null && typeof params[key] === "object") {
            objectToUrlParamsRecursive(params[key], searchParam);
        } else {
            searchParam.append(key, params[key]);
        }
    });
    return searchParam.toString();
}

export const objectToUrlParamsWithoutRecursion = (params: object, searchParam = new URLSearchParams()): string =>{
    if (!params) return;
    Object.keys(params).forEach(key => {
        if (params[key] !== null && typeof params[key] === "object") {
            const obj = params[key];
            searchParam.append(key, JSON.stringify(obj));
        } else {
            searchParam.append(key, params[key]);
        }
    });
    return searchParam.toString();
}

export const objectToUrlParams = (params: object, addQuestionMark: boolean, useRecursion = false): string => {
    let answer = "";
    if (!useRecursion) {
        answer = objectToUrlParamsWithoutRecursion(params);
    }
    else {
        answer = objectToUrlParamsRecursive(params);//TODO is this needed?
    }

    if (addQuestionMark) {
        answer = "?" + answer;
    }
    return answer;
}

export const objectToUrlParamsWithRecursion =(params: object, addQuestionMark: boolean): string  =>{
    return addQuestionMark ? "?" : "" + objectToUrlParamsRecursive(params);
}
export const objectToList = (params: any[], paramName: string): string => {
    const send = params.join("&" + paramName + "=");
    return "?" + paramName + "=" + send;
}
