import { ISalaryRowTypeEditItem, SalaryRowTypeEditItem } from "../salary/salaryRowTypeEditItem";
import { ISalaryRowTypeKindItem, SalaryRowTypeKindItem } from "../salary/salaryRowTypeKindItem";
import { Base } from "../../framework/base";

export interface ISalaryRowTypeEdit {
    salaryRowType: ISalaryRowTypeEditItem;
    salaryRowTypeKind: ISalaryRowTypeKindItem[];
}

export class SalaryRowTypeEdit implements ISalaryRowTypeEdit {
    salaryRowTypeKind: ISalaryRowTypeKindItem[];
    salaryRowType: SalaryRowTypeEditItem;

    constructor();
    constructor(obj: ISalaryRowTypeEdit);
    constructor(obj?: any) {
        this.salaryRowType = new SalaryRowTypeEditItem(obj ? obj.salaryRowType : null);
        this.salaryRowTypeKind = Base.getTypedArray<SalaryRowTypeKindItem>(obj ? obj.salaryRowTypeKind : null, SalaryRowTypeKindItem);
    }
}
