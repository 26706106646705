// TextareaEditor
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "../../framework/base";
import { AppUtils } from "../../models/common/appUtils";
import { ConfirmationDialogResult } from "../../models/common/enums";
import { Translations } from "../../models/translations";
import { Button } from "./button";

export interface ITextareaEditorProp {
    value: string;
    tooltip?: string;
    classes?: string;
    disabled?: boolean;
    maxLength?: number;
    onSave: (value: string) => void;
}

export interface ITextareaEditorState {
    editMode: boolean;
    value: string;
}

export class TextareaEditor extends React.Component<ITextareaEditorProp, ITextareaEditorState> {
    private editorId: string;
    private orgStateHash: string = "";

    private getHashFromState = (state: ITextareaEditorState): string => {
        return JSON.stringify({
            value: state.value
        });
    };

    constructor(props: ITextareaEditorProp) {
        super(props);
        this.editorId = Base.getGuid();
        this.state = { editMode: false, value: props.value ?? "" };
        this.orgStateHash = JSON.stringify(this.getHashFromState(this.state));
    }

    componentDidUpdate(prevProps: ITextareaEditorProp, prevState: ITextareaEditorState): void {
        const props = this.props;
        if ((prevProps.value ?? "") === (props.value ?? "")) return;
        this.setState({
            value: props.value ?? ""
        });
    }

    handleEdit = (ev: any) => {
        ev.stopPropagation();
        this.setState({
            editMode: true
        });
        this.orgStateHash = this.getHashFromState(this.state);
    };

    handleClose = async() => {
        if (this.state.editMode) {
            const cancelResult = await AppUtils.cancel(this.getHashFromState(this.state), this.orgStateHash);
            if (cancelResult === ConfirmationDialogResult.Cancel) return;
            if (cancelResult === ConfirmationDialogResult.Yes) {
                this.handleSave();
                return;
            }
        }
        this.setState({
            editMode: false,
            value: this.props.value ?? ""
        });
    };

    editorClickEvent = (ev: MouseEvent) => {
        const element = ev.target as HTMLElement;
        const editor = element.closest("[data-id='" + this.editorId + "']");
        if (!editor) {
            this.handleClose();
        }
    };

    componentDidMount(): void {
        window.addEventListener("click", this.editorClickEvent, true);
    }

    componentWillUnmount(): void {
        window.removeEventListener("click", this.editorClickEvent);
    }

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        this.setState({ value: value });
    };

    handleSave = () => {
        this.props.onSave(this.state.value);
        this.setState({
            editMode: false
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <div data-id={this.editorId}>
                {!state.editMode &&
                    <div className="textArea editable" onClick={this.handleEdit}>{state.value}</div>
                }
                {state.editMode &&
                    <div className="editableRegion">
                        <textarea className={"form-control" + (props.classes ? " " + props.classes : "") + (props.disabled ? " disabled" : "")} title={props.tooltip} value={state.value} onChange={this.handleChange} maxLength={props.maxLength} disabled={props.disabled} autoFocus={true} />
                        <div className="editableRegionButtons text-right">
                            <Button
                                title={Translations.Cancel}
                                classes="btn-default rnd"
                                enabled={true}
                                onClick={this.handleClose}
                            />
                            <Button
                                title={Translations.Save}
                                classes="btn-primary rnd"
                                enabled={true}
                                onClick={this.handleSave}
                            />
                        </div>
                    </div>
                }
            </div>
        );
    }
}
