import React, { useCallback, useMemo, useState } from "react";
import { MainLayout } from "../layout/mainLayout";
import { LoadingIndicator } from "../framework/loadingIndicatorNew";
import { VehiclePositionInfo } from "../../models/vehicle/vehiclePosition";
import { MonitorViewMap } from "./monitorViewMap";
import { WorkShiftDto } from "../../models/workShift/workShift";
import { MonitorViewWorkShiftSidebar } from "./monitorViewWorkShiftSidebar";
import { MonitorViewFilters } from "./monitorViewFilters";
import { MonitorViewVehiclesSidebar } from "./monitorViewVehiclesSidebar";
import { Base } from "../../framework/base";
import { useAppSelector } from "../../framework/customStore";
import { Position } from "../framework/map/map";

interface MonitorViewMainLayoutProps {
    positions: VehiclePositionInfo[];
    loading: boolean;
    workShift: WorkShiftDto;
    selectedVehicleId: string;
    selectedWorkShiftTimeSlotId: string;
    reloadKey: number;
    onRefresh: (showLoadingIndicator: boolean) => void;
    setSelectedVehicleId: (id: string) => void;
    setSelectedWorkShiftTimeSlot: (id: string) => void;
}

export interface VehicleData {
    vehicleId: string;
    coords: Position;
    registerNumber: string;
    employeeName: string | null;
    workTimeTypeName: string | null;
    workTimeTypeType: number | null;
}

export const MonitorViewMainLayout = ({
    positions,
    loading,
    workShift,
    selectedVehicleId,
    selectedWorkShiftTimeSlotId,
    reloadKey,
    onRefresh,
    setSelectedVehicleId,
    setSelectedWorkShiftTimeSlot,
}: MonitorViewMainLayoutProps) => {
    const [hoveredWorkShiftTimeSlotId, setHoveredWorkShiftTimeSlotId] =
        useState<string>();

    const showNoWorkTime = useAppSelector(
        (state) => state.monitorView.filters.noWorkTime
    );

    const selectedWorkTimeTypeIds = useAppSelector(
        (state) => state.monitorView.filters.workTimeTypeIds
    );

    const filterByWorkTimeType = useCallback(
        (p: VehiclePositionInfo) =>
            !selectedWorkTimeTypeIds ||
            p.employeeWorkTimeTypes.some((w) =>
                selectedWorkTimeTypeIds.includes(w.workTimeTypeId)
            ),
        [selectedWorkTimeTypeIds]
    );

    const filteredVehicleData = useMemo(
        () =>
            positions
                ?.filter(
                    (p) =>
                        // If vehicles with no workTime are not shown,
                        // Only filter by WorkTimeType
                        (!showNoWorkTime && filterByWorkTimeType(p)) ||
                        // Or if vehicles with no workTime are shown
                        (showNoWorkTime &&
                            // find vehicles with no workTimes
                            p.employeeWorkTimeTypes.length === 0) ||
                        // Or by WorkTimeType
                        filterByWorkTimeType(p)
                )
                .map(
                    (p) =>
                        ({
                            vehicleId: p.vehicleId,
                            coords: { lat: p.latitude, lng: p.longitude },
                            registerNumber: p.registerNumber,
                            employeeName:
                                p.employeeWorkTimeTypes[0]?.employeeName,
                            workTimeTypeName:
                                p.employeeWorkTimeTypes[0]?.workTimeTypeName,
                            workTimeTypeType:
                                p.employeeWorkTimeTypes[0]?.workTimeTypeType,
                        } as VehicleData)
                )
                .sort((a, b) =>
                    Base.stringCompare(a.registerNumber, b.registerNumber)
                )
                .sort((a, b) =>
                    Base.stringCompare(a.workTimeTypeName, b.workTimeTypeName)
                ),
        [positions, showNoWorkTime, filterByWorkTimeType]
    );

    const countByWorkTimeType = useMemo(
        () =>
            positions
                ? Object.fromEntries(
                      Object.values(
                          Base.groupArray(
                              positions.map((p) => ({
                                  id: p.employeeWorkTimeTypes[0]
                                      ?.workTimeTypeId,
                              })),
                              "id"
                          )
                      ).map((v) => [v[0].id, v.length])
                  )
                : {},
        [positions]
    );

    return (
        <MainLayout
            noPadding
            sideComponentSide="left"
            topComponent={
                <MonitorViewFilters countByWorkTimeType={countByWorkTimeType} />
            }
            sideComponent={
                !workShift ? (
                    <MonitorViewVehiclesSidebar
                        vehicleData={filteredVehicleData}
                        selectedVehicleId={selectedVehicleId}
                        setSelectedVehicleId={setSelectedVehicleId}
                    />
                ) : (
                    <MonitorViewWorkShiftSidebar
                        workShift={workShift}
                        onClose={() => setSelectedVehicleId(null)}
                        selectedWorkShiftTimeSlotId={
                            selectedWorkShiftTimeSlotId
                        }
                        setSelectedWorkShiftTimeSlotId={
                            setSelectedWorkShiftTimeSlot
                        }
                        setHoveredWorkShiftTimeSlotId={(id) =>
                            setHoveredWorkShiftTimeSlotId(id)
                        }
                    />
                )
            }
            sideOverFlowHidden
        >
            <>
                <LoadingIndicator loading={loading} />
                <MonitorViewMap
                    loading={loading}
                    vehicleData={filteredVehicleData}
                    onRefresh={() => onRefresh(true)}
                    selectedVehicleId={selectedVehicleId}
                    setSelectedVehicle={setSelectedVehicleId}
                    workShift={workShift}
                    reloadKey={reloadKey}
                    selectedWorkShiftTimeSlotId={selectedWorkShiftTimeSlotId}
                    hoveredWorkShiftTimeSlotId={hoveredWorkShiftTimeSlotId}
                    setSelectedWorkShiftTimeSlotId={
                        setSelectedWorkShiftTimeSlot
                    }
                />
            </>
        </MainLayout>
    );
};
