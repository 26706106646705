import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Translations } from "../../models/translations";
import { Base } from "../../framework/base";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";


interface ConfirmationDialogProps {
    show: boolean;
    title: string;
    onYes: () => void;
    onCancel?: () => void;
    yesTitle?: string;
    noTitle?: string;
    yesClasses?: string;
    noClasses?: string;
    text?: string;
    content?: React.ReactNode;
    onNo?: () => void;
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
    const { show, title, onYes, onCancel, yesTitle, noTitle, yesClasses, noClasses, text, content, onNo } = props;
    const withClearConfirmation = (callback: () => void) => {
        store.customStore.dispatch(storeActions.clearConfirmation());
        if (callback) {
            callback();
        }
    };

    return (
        <Dialog
            open={show}
            onBackdropClick={() => withClearConfirmation(onCancel)}
        >
            <DialogTitle variant="h3" marginBottom={0}>
                {title}
            </DialogTitle>
            {content &&
                <DialogContent>
                    {content}
                </DialogContent>
            }
            {text &&
                <DialogContent>
                    {!Base.isFormattedText(text)
                        ? text
                        : <div dangerouslySetInnerHTML={{ __html: Base.getFormattedText(text) }}/>
                    }
                </DialogContent>
            }
            <DialogActions>
                <Button variant="contained" autoFocus onClick={() => withClearConfirmation(onCancel)}>{Translations.Cancel}</Button>
                {onNo &&
                    <Button variant="contained" onClick={() => withClearConfirmation(onNo)} classes={noClasses}>{noTitle ?? Translations.No}</Button>
                }
                <Button onClick={() => withClearConfirmation(onYes)} variant="primary" className={yesClasses}>{yesTitle ?? Translations.OK}</Button>
            </DialogActions>
        </Dialog>
    );
};
