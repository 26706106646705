export interface IEventLogItem {
    id: string;
    time: number;
    objectType: string;
    number: number;
    entryType: string;
    message: string;
    textData: string;
    user: string;
}

export class EventLogItem implements IEventLogItem {
    id: string;
    time: number;
    objectType: string;
    number: number;
    entryType: string;
    message: string;
    textData: string;
    user: string;

    constructor();
    constructor(obj: IEventLogItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.time = obj && obj.time || 0;
        this.objectType = obj && obj.objectType || "";
        this.number = obj && obj.number || 0;
        this.entryType = obj && obj.entryType || "";
        this.message = obj && obj.message || "";
        this.textData = obj && obj.textData || "";
        this.user = obj && obj.user || "";
    }
}