// WorkOrderCopyDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "../../framework/base";
import * as store from "../../framework/customStore";
import * as baseService from "../../services/baseService";
import * as workOrderService from "../../services/workOrderService";
import * as storeActions from "../../models/store/storeActions";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { Translations } from "../../models/translations";
import { IWorkOrderEdit } from "../../models/work/workOrderEdit";
import { WorkOrderEditItem } from "../../models/work/workOrderEditItem";
import { CheckBox } from "../framework/checkbox";
import { Dialog } from "../framework/dialog";

export interface IWorkOrderCopyDialogProp {
    classes?: string;
    workOrderId: string;
    number: number;
    parentNumber: number;
    name: string;
    isProject: boolean;
    isRecurring: boolean;
    isWork: boolean;
    hasEmployees: boolean;
    hasVehicles: boolean;
    hasRoute: boolean;
    hasDocuments: boolean;
    hasBookings: boolean;
    hasTasks: boolean;
    onOk: (workOrderEdit: IWorkOrderEdit) => void;
    onCancel: () => void;
}

export interface IWorkOrderCopyDialogState {
    employees: boolean;
    vehicles: boolean;
    route: boolean;
    documents: boolean;
    bookings: boolean;
    projectTasks: boolean;
}

export class WorkOrderCopyDialog extends React.Component<IWorkOrderCopyDialogProp, IWorkOrderCopyDialogState> {
    constructor(props: IWorkOrderCopyDialogProp) {
        super(props);
        this.state = {
            employees: false,
            vehicles: false,
            route: false,
            documents: false,
            bookings: false,
            projectTasks: false
        };
    }

    private static checkErrors = (props: IWorkOrderCopyDialogProp, state: IWorkOrderCopyDialogState): Promise<boolean> => {
        return new Promise<boolean>((resolve) => {
            //Add error checking in here
            //if (!state.name) {
            //    store.customStore.dispatch(storeActions.showErrorMessage(Translations.NameMustBeDefined));
            //    return resolve(false);
            //}
            return resolve(true);
        });
    };

    private static getWarningMessage = (props: IWorkOrderCopyDialogProp, state: IWorkOrderCopyDialogState): Promise<string> => {
        return new Promise<string>((resolve) => {
            //let warnings = "";
            //Add warning checking in here
            //if (!state.someField) {
            //    warnings = warnings + Translations.SomeFieldIsNotDefined + Base.lf;
            //}
            //return resolve(warnings);
            return resolve("");
        });
    };

    private static validate = (props: IWorkOrderCopyDialogProp, state: IWorkOrderCopyDialogState): Promise<boolean> => {
        return WorkOrderCopyDialog.checkErrors(props, state).then(success => {
            if (success) {
                return WorkOrderCopyDialog.getWarningMessage(props, state)
                    .then(warnings => {
                        if (!warnings) {
                            return Base.getPromiseResult(true);
                        }
                        return new Promise<boolean>((resolve) => {
                            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, warnings + Base.lf + Translations.DoYouReallyWantToSaveData,
                                () => {
                                    store.customStore.dispatch(storeActions.clearConfirmation());
                                    resolve(true);
                                },
                                () => {
                                    store.customStore.dispatch(storeActions.clearConfirmation());
                                    resolve(false);
                                }));
                        });
                    });
            } else {
                return Base.getPromiseResult(false);
            }
        });
    };

    copyWorkOrder = () => {
        const obj = this;
        const props = this.props;
        const state = this.state;
        WorkOrderCopyDialog.validate(props, state)
            .then(success => {
                if (success) {
                    // Call server
                    store.customStore.dispatch(storeActions.fetchStart());
                    workOrderService.copyWorkOrder(props.workOrderId, state.employees, state.vehicles, state.route, state.documents, state.bookings, state.projectTasks)
                        .then(workOrderEdit => {
                            obj.props.onOk(workOrderEdit);
                        })
                        .catch(error => {
                            store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                        })
                        .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
                }
            });
    };

    handleOkClick = () => {
        this.copyWorkOrder();
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "copyWorkOrder px400" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <Dialog
                    classes={dialogClasses}
                    title={props.isProject ? (props.isRecurring ? Translations.CopyRecurringWork : Translations.CopyWorkOrderProject) : (props.isWork ? Translations.CopyWorkOrderWork : Translations.CopyWorkOrderTask) + WorkOrderEditItem.getUiTitleStatic(false, props.isProject, props.isRecurring, props.isWork, props.number, props.parentNumber, props.name, false, false)}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-12">
                                <label className="control-label smallFont noMargin">{Translations.DataToBeCopied}</label>
                            </div>
                            <div className="col-12">
                                <CheckBox
                                    title={Translations.Employees}
                                    enabled={props.hasEmployees || props.isProject}
                                    checked={state.employees}
                                    onCheckboxClickBoolean={(value: boolean) => { this.setState({ employees: value }); }}
                                />
                            </div>
                            <div className="col-12">
                                <CheckBox
                                    title={Translations.Vehicles}
                                    enabled={props.hasVehicles || props.isProject}
                                    checked={state.vehicles}
                                    onCheckboxClickBoolean={(value: boolean) => { this.setState({ vehicles: value }); }}
                                />
                            </div>
                            <div className="col-12">
                                <CheckBox
                                    title={Translations.Route}
                                    enabled={props.hasRoute}
                                    checked={state.route}
                                    onCheckboxClickBoolean={(value: boolean) => { this.setState({ route: value }); }}
                                />
                            </div>
                            <div className="col-12">
                                <CheckBox
                                    title={Translations.Attachments}
                                    enabled={props.hasDocuments || props.isProject}
                                    checked={state.documents}
                                    onCheckboxClickBoolean={(value: boolean) => { this.setState({ documents: value }); }}
                                />
                            </div>
                            <div className="col-12">
                                <CheckBox
                                    title={Translations.ToBeInvoicedRecords}
                                    enabled={props.hasBookings || props.isProject}
                                    checked={state.bookings}
                                    onCheckboxClickBoolean={(value: boolean) => { this.setState({ bookings: value }); }}
                                />
                            </div>
                            {(props.hasTasks && !props.isRecurring) &&
                                <div className="col-12">
                                    <CheckBox
                                        title={Translations.WorkOrderTasks}
                                        enabled={true}
                                        checked={state.projectTasks}
                                        onCheckboxClickBoolean={(value: boolean) => { this.setState({ projectTasks: value }); }}
                                    />
                                </div>
                            }
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.Copy, classes: "btn-primary", enabled: true, onClick: this.handleOkClick }
                    ]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}