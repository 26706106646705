import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SessionState {
    userId: string;
}

export const initialState: SessionState = {
    userId: null,
};

export const sessionSlice = createSlice({
    name: "session",
    initialState,
    reducers: {
        setUserId: (state, action: PayloadAction<string>) => {
            state.userId = action.payload;
        },
    },
});

export const { setUserId } = sessionSlice.actions;

export const sessionReducer = sessionSlice.reducer;
