export interface ICustomerTypeEditItem {
    id: string;
    code: string;
    name: string;
    rowId: string;
    customerIds: string[];

    isNew():boolean;
}

export class CustomerTypeEditItem implements ICustomerTypeEditItem {
    id: string;
    code: string;
    name: string;
    rowId: string;
    customerIds: string[];

    constructor();
    constructor(obj: ICustomerTypeEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.code = obj && obj.code || "";
        this.name = obj && obj.name || "";
        this.rowId = obj && obj.rowId || "";
        this.customerIds = obj && obj.customerIds || [];
    }

    isNew():boolean {
        return !this.rowId;
    }
}