import { Base } from "../../framework/base";
import { DayBookingUsageType, EnumHelper } from "../common/enums";
import { Translations } from "../translations";
import { INumberTitle } from "../common/numberTitle";

export interface IDayBookingItem {
    id: string;
    employeeId: string;
    dayBookingTypeId: string;
    startDate: number;
    endDate: number;
    duration: number;
    bookingType: number;
    dayUsageType: number;
    comment: string;

    uiTitle: string;
    setUiTitle(dayBookingTypes: INumberTitle[]);

    getClassName(): string;
    getComment(): string;
}

export class DayBookingItem implements IDayBookingItem {
    id: string;
    employeeId: string;
    dayBookingTypeId: string;
    startDate: number;
    endDate: number;
    duration: number;
    bookingType: number;
    dayUsageType: number;
    comment: string;

    uiTitle: string;

    constructor();
    constructor(obj: IDayBookingItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.employeeId = obj && obj.employeeId || "";
        this.dayBookingTypeId = obj && obj.dayBookingTypeId || "";
        this.startDate = obj && obj.startDate || 0;
        this.endDate = obj && obj.endDate || 0;
        this.duration = obj && obj.duration || 0;
        this.bookingType = obj && obj.bookingType || 0;
        this.dayUsageType = obj && obj.dayUsageType || 0;
        this.comment = obj && obj.comment || "";
        //const startDate = new Date(this.startDate);
        //startDate.setHours(0, 0, 0, 0);
        //this.startDate = startDate.getTime();
        //const endDate = new Date(this.endDate);
        //endDate.setHours(0, 0, 0, 0);
        //this.endDate = endDate.getTime();

        this.uiTitle = obj && obj.uiTitle || "";
    }

    getClassName(): string {
        return " dbt" + this.bookingType + " dbu" + this.dayUsageType;
    }

    getComment(): string {
        return this.comment ? this.comment + (EnumHelper.isEqual(this.dayUsageType, DayBookingUsageType.FullDay) ? "" : (" (" + Translations.NotFullDay.toLowerCase() + ")")) : "";
    }

    static getClassNames(dayBookings: IDayBookingItem[]): string {
        if (Base.isNullOrUndefined(dayBookings) || dayBookings.length < 1) return "";
        let result = "";
        for (let i = 0; i < dayBookings.length; i++) {
            result = result + dayBookings[i].getClassName();
        }
        return result;
    }

    static getComments(dayBookings: IDayBookingItem[], defaultValue: string): string {
        if (Base.isNullOrUndefined(dayBookings) || dayBookings.length < 1) return defaultValue;
        const result: string[] = [];
        for (let i = 0; i < dayBookings.length; i++) {
            if (!dayBookings[i].comment) continue;
            result.push(dayBookings[i].getComment());
        }
        if (result.length < 1) return defaultValue;
        return Base.getStringWithSeparators(result, "\n");
    }

    static sortDayBookingItems(items: IDayBookingItem[]) {
        if (!items || items.length < 2) return;
        items.sort((a: IDayBookingItem, b: IDayBookingItem) => {
            if (!a || !b) {
                return 0;
            }
            return Base.numberCompare(a.startDate, b.startDate);
        });
    }

    setUiTitle(dayBookingTypes: INumberTitle[]) {
        const dayBookingType = dayBookingTypes.find(i => Base.isEqualInteger(i.number, this.bookingType));
        if (!dayBookingType) return;
        this.uiTitle = dayBookingType.title;
    }
}
