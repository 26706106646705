import * as store from "../../framework/customStore";
import { IUserState } from "../store/storeTypes";

export interface IUserParameters {
    getEmployeeId(): string;
    getIsOwnerAdmin(): boolean;
}

export class UserParameters implements IUserParameters {
    getUserState(): IUserState {
        const appState = store.customStore.getState();
        if (!appState || !appState.user) return null;
        return appState.user;
    }

    getEmployeeId(): string {
        const state = this.getUserState();
        return state ? state.employeeId : "";
    }

    getIsOwnerAdmin(): boolean {
        const state = this.getUserState();
        return state ? state.ownerAdmin : false;
    }

    static employeeId(): string {
        const userParameters = new UserParameters();
        return userParameters.getEmployeeId();
    }

    static isOwnerAdmin(): boolean {
        const userParameters = new UserParameters();
        return userParameters.getIsOwnerAdmin();
    }
}