import { Base } from "../../framework/base";

export interface IWorkOrderEditItemHourBooking {
    id: string;
    employeeId: string;
    date: number;
    amount: number;
    invoice: number;
    calculated: number;
    comment: string;
    rowId: string;

    isNew():boolean;
}

export class WorkOrderEditItemHourBooking implements IWorkOrderEditItemHourBooking {
    id: string;
    employeeId: string;
    date: number;
    amount: number;
    invoice: number;
    calculated: number;
    comment: string;
    rowId: string;

    constructor();
    constructor(obj: IWorkOrderEditItemHourBooking);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.employeeId = obj && obj.employeeId || "";
        this.date = obj && obj.date || 0;
        this.amount = obj && obj.amount || 0;
        this.invoice = obj && obj.invoice || null;
        this.calculated = obj && obj.calculated || null;
        this.comment = obj && obj.comment || "";
        this.rowId = obj && obj.rowId || "";
    }

    isNew():boolean {
        return !this.rowId;
    }

    static sortWorkOrderEditItemHourBookings(items: WorkOrderEditItemHourBooking[], column: string, asc: boolean) {
        if (Base.isNullOrUndefined(items) || items.length < 2) return;
        let sortFunc: (a: WorkOrderEditItemHourBooking, b: WorkOrderEditItemHourBooking) => number;
        if (column === "amount") sortFunc = (a: WorkOrderEditItemHourBooking, b: WorkOrderEditItemHourBooking) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.amount, b.amount)); };
        else if (column === "comment") sortFunc = (a: WorkOrderEditItemHourBooking, b: WorkOrderEditItemHourBooking) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.comment, b.comment)); };
        else sortFunc = (a: WorkOrderEditItemHourBooking, b: WorkOrderEditItemHourBooking) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.date, b.date)); };
        items.sort(sortFunc);
    }
}

export class WorkOrderEditItemSaveHourBooking {
    id: string;
    employeeId: string;
    date: number;
    amount: number;
    invoice: number;
    comment: string;
    rowId: string;

    constructor();
    constructor(obj: IWorkOrderEditItemHourBooking);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.employeeId = obj && obj.employeeId || "";
        this.date = obj && obj.date || 0;
        this.amount = obj && obj.amount || 0;
        this.invoice = obj && obj.invoice || 0;
        this.comment = obj && obj.comment || "";
        this.rowId = obj && obj.rowId || "";
    }
}