// WorkListLineSimple
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "../../framework/base";
import { AppUtils } from "../../models/common/appUtils";
import { IEmployeeItem } from "../../models/employee/employeeItem";
import { IVehicleItem } from "../../models/vehicle/vehicleItem";
import { WorkOrderDragData, WorkOrderDragDataType } from "../../models/work/workOrderDragData";
import { UseSingleAndDoubleClick } from "../hooks/useSingleAndDoubleClick";
import { AvatarImage } from "../framework/avatarImage";

// WorkListLineSimple
export interface IWorkListLineSimpleProp {
    classes?: string;
    id: string;
    number: number;
    parentNumber: number;
    name: string;
    state: number;
    tooltip: string;
    startTime?: number;
    endTime?: number;
    employees?: IEmployeeItem[];
    vehicles?: IVehicleItem[];
    isSelected: boolean;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
    onEditEmployee?: (id: string) => void;
    onEditVehicle?: (id: string) => void;
}

export class WorkListLineSimple extends React.Component<IWorkListLineSimpleProp, {}> {
    handleClick = () => {
        const props = this.props;
        if (!props.onClick) return;
        props.onClick(props.id);
    };

    handleDoubleClick = () => {
        const props = this.props;
        props.onDoubleClick(props.id);
    };

    onDragStart = (ev, id: string, state: number, category: number, employeeId: string, vehicleId: string, startTime: number, hasChildren: boolean) => {
        WorkOrderDragData.setWorkOrderDragData(ev, WorkOrderDragDataType.WorkOrder, id, state, category, employeeId, vehicleId, startTime, hasChildren, 0);
    };

    render() {
        const props = this.props;
        const rowClass = "workListLineSimple state" + props.state + (props.classes ? " " + props.classes : "") + (props.isSelected ? " selected" : "");
        let tooltip = props.tooltip ?? "";
        let timeStr = "";
        if (props.startTime && props.endTime) {
            const durationMin = Base.dateDiffInMinutes(props.startTime, props.endTime);
            timeStr = Base.timeToDateStr(props.startTime) + " " + Base.timeToTimeStr(props.startTime) + (durationMin ? " (" + AppUtils.getDurationStrByDurationMin(durationMin) + ")" : "");
            if (tooltip) {
                tooltip = tooltip + " " + Base.timeToTimeStr(props.startTime) + (durationMin ? " (" + AppUtils.getDurationStrByDurationMin(durationMin) + ")" : "");
            }
        }
        return (
            <UseSingleAndDoubleClick onClick={this.handleClick} onDoubleClick={this.handleDoubleClick}>
                <div className={rowClass} title={tooltip}>
                    <div>
                        <div className="workOrderStateEndContainer" onClick={() => props.onDoubleClick(props.id)}>
                            <div className={"stateEnd active state" + props.state.toString(10)}>
                                <span className={"icon state" + props.state.toString(10)} aria-hidden="true" />
                            </div>
                        </div>
                        <div className="textDataContainer">
                            <div className="row">
                                <div className="col-12 listLineTitle">
                                    <div>{(props.number.toString(10) + (props.parentNumber ? " (" + props.parentNumber.toString(10) + ")" : "")) + " " + props.name}</div>
                                    <div>{timeStr}
                                        {props.employees && props.employees.map((employee, index) =>
                                            <AvatarImage
                                                key={employee.id}
                                                classes={"small inline" + (index < 1 ? " first" : "")}
                                                image={employee.picture}
                                                fullText={!!employee.abbreviation}
                                                name={employee.getAvatarText()}
                                                tooltip={employee.name}
                                                onClick={props.onEditEmployee ? () => props.onEditEmployee(employee.id) : null}
                                            />
                                        )}
                                        {props.vehicles && props.vehicles.map((vehicle, index) =>
                                            <AvatarImage
                                                key={vehicle.id}
                                                classes={"small inline oval" + (props.employees.length + index < 1 ? " first" : "")}
                                                image={vehicle.picture}
                                                fullText={true}
                                                name={vehicle.getAvatarText()}
                                                tooltip={vehicle.getTitle()}
                                                onClick={props.onEditVehicle ? () => props.onEditVehicle(vehicle.id) : null}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </UseSingleAndDoubleClick>
        );
    }
}
