import { SaveSuccess, ApiSuccess } from "./baseService";
import { postFormApiCall, getApiCall, postApiCall } from "./tokenService";
import { SiteEdit } from "../models/customer/siteEdit";
import { CustomerSiteItems } from "../models/customer/customerSiteItems";

export const getCustomerSiteItems = (
    pageSize: number,
    page: number,
    filter: string,
    sortColumn: string,
    sortOrderIsAsc: boolean,
    type: number
): Promise<CustomerSiteItems> => {
    return getApiCall<CustomerSiteItems>("api/site/?page=" + (page || "") + "&pageSize=" + (pageSize || "") +
        "&sortColumn=" + (sortColumn ? encodeURIComponent(sortColumn) : "") + "&sortOrder=" + (sortOrderIsAsc ? "asc" : "desc") +
        "&filter=" + (filter ? encodeURIComponent(filter) : "" +
        "&type="+ (type ?? "")
        ), CustomerSiteItems);
};

export const getSiteEdit = (id: string): Promise<SiteEdit> => {
    return getApiCall<SiteEdit>("api/site/edit/?id=" + (id || ""), SiteEdit);
};

export const saveSiteEdit = (data: FormData): Promise<SaveSuccess> => {
    return postFormApiCall<SaveSuccess>("api/site/save", data, SaveSuccess);
};

export const removeSites = (ids: string[]): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/site/remove", ApiSuccess, JSON.stringify({ ids: ids }));
};

export const updateMapLinks = (ids: string[]): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/site/updatemaplink", ApiSuccess, JSON.stringify({ ids: ids }));
};
