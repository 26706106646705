import * as React from "react";
import { Translations } from "../../models/translations";
import { TrackingEventLogList } from "./trackingEventLogList";
import { TrackingOperationLogList } from "./trackingOperationLogList";
import { IOwnerState, IUserState } from "../../models/store/storeTypes";

export interface ITrackingMainOwnProps {
}

export interface ITrackingMainStateProps {
    owner: IOwnerState;
    user: IUserState;
}

type TrackingMainProp = ITrackingMainOwnProps & ITrackingMainStateProps;

interface ITrackingMainState {
    activeSettingId: string;
}

export class TrackingMain extends React.Component<TrackingMainProp, ITrackingMainState> {
    private static settingIdEventLog = "1";
    private static settingIdOperationLog = "2";

    constructor(props) {
        super(props);
        this.state = {
            activeSettingId: TrackingMain.settingIdEventLog,
        };
    }

    handleMenuItemClick = (settingId: string) => {
        this.setState({ activeSettingId: settingId });
    };

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <div>
                <div className="row mainContent trackingMain">
                    <div className="col-2">
                        <div className="backgroundBoard fullHeight">
                            <div className="panel verticalMenu">
                                <div className={"menuItem" + (state.activeSettingId === TrackingMain.settingIdEventLog ? " active" : "")} onClick={() => { this.handleMenuItemClick(TrackingMain.settingIdEventLog); }}>{Translations.EventLog}</div>
                                <div className={"menuItem" + (state.activeSettingId === TrackingMain.settingIdOperationLog ? " active" : "")} onClick={() => { this.handleMenuItemClick(TrackingMain.settingIdOperationLog); }}>{Translations.OperationLog}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-10">
                        <div className="backgroundBoard">
                            <div className="panel">
                                {state.activeSettingId === TrackingMain.settingIdEventLog &&
                                    <TrackingEventLogList />
                                }
                                {state.activeSettingId === TrackingMain.settingIdOperationLog &&
                                    <TrackingOperationLogList />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
