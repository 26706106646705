import React, { useEffect, useState } from "react";
import { Translations } from "../../../models/translations";
import { getStorageLocationCustomers } from "../../../services/storageService";
import { AutocompleteOption, AutocompleteWrapper } from "../../framework/muiAutocomplete";

interface Props {
    value: string[];
    onChange: (val: string[]) => void;
}

export const StorageCustomerSelect = (props: Props) => {
    const [storageCustomerOptions, setStorageCustomerOptions] = useState<AutocompleteOption[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getStorageLocationCustomers()
            .then((val) => {
                setStorageCustomerOptions(val.map(v => ({ id: v.id, title: v.title })));
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const onChange = (val: AutocompleteOption[]) => {
        props.onChange(val.map(v => v.id));
    };

    return (
        <AutocompleteWrapper
            label={Translations.ChooseCustomer}
            value={props.value}
            options={storageCustomerOptions}
            onChange={onChange}
            loading={loading}
            multiple
            size="small"
        />
    );
};
