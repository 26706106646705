import { Base, FileType } from "../../framework/base";
import { DocumentType } from "../common/enums";

export interface IDocument {
    id: string;
    relatedObjectId: string;
    fileType: number;
    title: string;
    reference: string;
    comment: string;
    size: number;
    modifyDate: number;
    rowId: string;
    thumbnail: string;
    file: File;
    documentType: DocumentType;

    isNew():boolean;
}

export class Document implements IDocument {
    id: string;
    relatedObjectId: string;
    fileType: number;
    title: string;
    reference: string;
    comment: string;
    size: number;
    modifyDate: number;
    rowId: string;
    thumbnail: string;
    file: File;
    documentType: DocumentType;

    constructor();
    constructor(obj: IDocument);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.relatedObjectId = obj && obj.relatedObjectId || "";
        this.fileType = obj && obj.fileType || 0;
        this.title = obj && obj.title || "";
        this.reference = obj && obj.reference || "";
        this.comment = obj && obj.comment || "";
        this.fileType = Base.getFileType(this.reference);
        this.size = obj && obj.size || 0;
        this.modifyDate = obj && obj.modifyDate || 0;
        this.rowId = obj && obj.rowId || "";
        this.thumbnail = obj && obj.thumbnail || "";
        this.file = null;
        this.documentType = obj && obj.documentType || DocumentType.Attachment;
    }

    isNew():boolean {
        return !this.rowId;
    }

    private static fileToDocument(relatedObjectId: string, file: File): Promise<Document> {
        if (Base.isNullOrUndefined(file)) return null;
        const document = new Document();
        document.id = Base.getGuid();
        document.relatedObjectId = relatedObjectId;
        document.fileType = Base.getFileType(file.name);
        document.title = file.name;
        document.reference = file.name;
        document.comment = "";
        document.size = file.size;
        document.modifyDate = (new Date()).getTime();
        document.thumbnail = null;
        document.file = file;
        document.documentType = DocumentType.Attachment;
        if (document.fileType === FileType.Image) {
            return Base.blobToBase64(file).then(base64Picture => {
                document.thumbnail = base64Picture;
                return document;
            });
        } else {
            return new Promise<Document>((resolve) => { resolve(document); });
        }
    }

    static getFirstDocumentFromFileList(relatedObjectId: string, fileList: FileList): Promise<Document> {
        if (fileList.length < 1) return new Promise<Document>((resolve) => { resolve(null); });
        return Document.fileToDocument(relatedObjectId, fileList[0]);
    }

    static addFileListToDocuments(relatedObjectId: string, documents: Document[], fileList: FileList, newDocumentAddCallback: (document: Document, index: number) => void = null): Promise<Document[]> {
        const promises: Promise<Document>[] = [];
        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i];
            if (!file) continue;
            promises.push(Document.fileToDocument(relatedObjectId, file));
        }
        const result = documents.slice(0);
        return Promise.all(promises)
            .then((newDocuments) => {
                for (let i = 0; i < newDocuments.length; i++) {
                    if (Base.isNullOrUndefined(newDocuments[i])) continue;
                    if (newDocumentAddCallback) {
                        newDocumentAddCallback(newDocuments[i], i);
                    }
                    result.push(newDocuments[i]);
                }
                return result;
            });
    }

    static sortDocuments(items: IDocument[], column: string, asc: boolean) {
        if (Base.isNullOrUndefined(items) || items.length < 2) return;
        let sortFunc: (a: IDocument, b: IDocument) => number;
        if (column === "title") sortFunc = (a: IDocument, b: IDocument) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.title, b.title)); };
        else if (column === "comment") sortFunc = (a: IDocument, b: IDocument) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.comment, b.comment)); };
        else if (column === "reference") sortFunc = (a: IDocument, b: IDocument) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.reference, b.reference)); };
        else if (column === "fileType") sortFunc = (a: IDocument, b: IDocument) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.fileType, b.fileType)); };
        else if (column === "dayUsageType") sortFunc = (a: IDocument, b: IDocument) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.size, b.size)); };
        else sortFunc = (a: IDocument, b: IDocument) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.modifyDate, b.modifyDate)); };
        items.sort(sortFunc);
    }
}

export interface IDocumentInfoSave {
    id: string;
    relatedObjectId: string;
    comment: string;
    documentType: DocumentType;
}

export class DocumentInfoSave implements IDocumentInfoSave {
    id: string;
    relatedObjectId: string;
    comment: string;
    documentType: DocumentType;

    constructor();
    constructor(obj: IDocument);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.relatedObjectId = obj && obj.relatedObjectId || "";
        this.comment = obj && obj.comment || "";
        this.documentType = obj && obj.documentType || DocumentType.Attachment;
    }
}
