import { Divider } from "@mui/material";
import React from "react";

interface Props {
    orientation: "vertical" | "horizontal";
}

/**
 * Divider that doesn't break grids
 */
export const GridDivider = (props: Props) => {
    return <Divider orientation={props.orientation} flexItem sx={{ mr: "-1px", my: 3, width: props.orientation === "horizontal" ? "100%" : 0 }} />;
};
