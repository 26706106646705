import { useCallback, useEffect, useRef } from "react";
import {
    MapPointProps,
    MapRouteProps,
} from "../../components/framework/map/map";
import { useDebouncedEventListener } from "../../components/hooks/useDebouncedEventListener";
import { useMap } from "@vis.gl/react-google-maps";

const fitMapDelay = 300;

// Handles fitting map to bounds
export const useFitMap = (
    routes: MapRouteProps[],
    points: MapPointProps[],
    autoZoom: boolean
): [boolean, () => boolean] => {
    const hasZoomed = useRef(false);
    const map = useMap();

    const fitMap = useCallback(() => {
        if (!map) return false;

        let bounds: google.maps.LatLngBounds;

        if (routes?.filter((r) => r.coords?.length > 0).length > 0) {
            // Calculate the bounds of all polylines
            bounds = routes.reduce(
                (acc, r) => r.coords?.reduce((a, c) => a.extend(c), acc),
                new google.maps.LatLngBounds()
            );
        }

        if (points?.filter((r) => r.coords).length > 0) {
            // Get the bounds from all points
            bounds = points.reduce(
                (acc, r) => (r.coords ? acc.extend(r.coords) : acc),
                bounds || new google.maps.LatLngBounds()
            );
        }

        if (bounds) {
            // Fit the map to the calculated bounds
            map.fitBounds(bounds);

            return true;
        }
    }, [map, routes, points]);

    useEffect(() => {
        // Zoom on inital render when autozoom is off
        if (autoZoom || !hasZoomed.current) {
            hasZoomed.current = fitMap();
        }
    }, [fitMap, autoZoom]);

    useDebouncedEventListener("resize", fitMap, fitMapDelay);

    return [hasZoomed.current, fitMap];
};
