import * as React from "react";

export interface IPricePerUnitProp {
    unit: string;
    wrapInParenthesis?: boolean;
    currency?: string;
}

export class PricePerUnit extends React.Component<IPricePerUnitProp, {}> {
    render() {
        const currency = this.props.currency ?? "€";
        const wrap = this.props.wrapInParenthesis ?? false;
        if (!this.props.unit) {
            return null;
        }
        return (
            <span>{(wrap ? "(" : "") + currency}/{this.props.unit + (wrap ? ")" : "")}</span>
        );
    }
}
