import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CartIcon from "@mui/icons-material/ShoppingCart";
import { Alert, Badge, Button, IconButton, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { Base } from "../../../../framework/base";
import * as store from "../../../../framework/customStore";
import * as StoreActions from "../../../../models/store/storeActions";
import { Translations } from "../../../../models/translations";
import { WorkOrderEdit } from "../../../../models/work/workOrderEdit";
import { WorkOrderOperations } from "../../../../models/work/workOrderOperations";
import * as workOrderService from "../../../../services/workOrderService";
import { addProductsToWorkOrder } from "../../../../services/workOrderService";
import { clearShoppingCart, removeShoppingCartItem, updateCartProductAmount, updateCartProductInventory, updateShoppingCartSelectedWorkOrderId } from "../../../../store/storageSlice";
import { WorkOrderDialogConnected } from "../../../work/workOrderDialog";
import { CartWorkOrderSelect } from "../cartWorkOrderSelect";
import ShoppingCartItemList from "./shoppingCartItemList";
import { showError } from "../../storageUtils";

interface ShoppingCartButtonProps {
    onClick: () => void;
}

export const ShoppingCartButton = (props: ShoppingCartButtonProps) => {
    const items = store.useAppSelector(state => state.storage.shoppingCart.items);
    return (
        <IconButton sx={{ marginTop: "-6px" }} onClick={props.onClick}>
            <Badge badgeContent={items.length} color="secondary">
                <CartIcon />
            </Badge>
        </IconButton>
    );
};

interface ShoppingCartProps {
    refresh?: () => void;
    onClose?: () => void;
}

export const ShoppingCart = (props: ShoppingCartProps) => {

    const state = store.useAppSelector(state => state.storage.shoppingCart);
    const items = store.useAppSelector(state => state.storage.shoppingCart.items);
    const dispatch = store.useAppDispatch();

    const [workOrderEdit, setWorkOrderEdit] = useState<WorkOrderEdit>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleRemoveFromShoppingCart = (index: number) => {
        dispatch(removeShoppingCartItem(index));
    };

    const handleAmountChange = (index: number, amount: number) => {
        dispatch(updateCartProductAmount({ index, amount }));
    };

    const handleAddToOrder = (workOrderId?: string) => {
        setIsSubmitting(true);

        // Manually create a new work order before adding products
        if (workOrderId === null) {
            void WorkOrderOperations.getWorkOrderEdit(Base.emptyGuid, null, null, null)
                .then(workOrderEdit => {
                    store.customStore.dispatch(StoreActions.showInfoMessage(Translations.ProductsWillBeAddedAfterSave));
                    setWorkOrderEdit(workOrderEdit);
                    setIsSubmitting(false);
                });
            return;
        }

        addProductsToWorkOrder(workOrderId, items)
            .then(() => {
                void workOrderService
                    .getWorkOrderEdit(workOrderId, null, null, null)
                    .then((workOrder) => {
                        store.customStore.dispatch(StoreActions.clearMessages());
                        store.customStore.dispatch(StoreActions.showSuccessMessage(Translations.ProductsAddedToWorkOrder));
                        setWorkOrderEdit(workOrder);
                        setIsSubmitting(false);
                    });
            })
            .catch(response => {
                setIsSubmitting(false);
                try {
                    // TODO proper error handling with typing for different responses etc.
                    const result = JSON.parse(response.message);
                    if (result?.[0]?.storageProductId && result?.[0]?.freeAmount) {
                        dispatch(updateCartProductInventory(result));
                    }
                } catch (e) {
                    if (response?.message) {
                        showError(response.message);
                    }
                }
            });
    };

    const onWorkOrderDialogClose = () => {
        setWorkOrderEdit(null);
        handleClear();
        handleSelectOrder(null);
        props.refresh?.();
    };

    const handleClear = () => {
        dispatch(clearShoppingCart());
    };

    const handleSelectOrder = (selected: string | null) => {
        dispatch(updateShoppingCartSelectedWorkOrderId(selected));
    };

    const onWorkOrderCreate = (workOrderId: string) => {
        // Dialog is hidden temporarily, because of concurrency issues
        // TODO still relevant?
        setWorkOrderEdit(null);
        handleAddToOrder(workOrderId);
    };

    const isValid = state.validationErrors.length === 0 && state.items.every(item => item.isValid);
    return (
        <div>
            <Stack direction="row" alignItems="start" mb={2}>
                <Typography flexGrow="1" variant="h3" m={0}>{Translations.ShoppingCart}</Typography>
                {props.onClose && <Button sx={{ marginTop: "-6px" }} endIcon={<CloseIcon/>} onClick={props.onClose}>Piilota</Button>}
            </Stack>
            <form onSubmit={(e) => { e.preventDefault(); handleAddToOrder(state.selectedOrderId); }}>
                <CartWorkOrderSelect value={state.selectedOrderId} onChange={(val) => handleSelectOrder(val) }/>
                {items.length > 0 &&
                    <>
                        <Typography variant="h3" sx={{ mt: 3, mb: 0 }}>{Translations.ShoppingCartProductListTitle}</Typography>

                        <Stack direction="row" justifyContent="end">
                            <Button title={Translations.Clear} onClick={handleClear}>{Translations.Clear}</Button>
                        </Stack>

                        <ShoppingCartItemList
                            addedStorageProducts={items}
                            onRemoveFromShoppingCart={handleRemoveFromShoppingCart}
                            onAmountChange={handleAmountChange}
                        />

                        <Stack mt={2} mb={2} alignItems="start" spacing={1}>
                            {state.selectedOrderId !== null &&
                                <Button variant="primary"
                                    type="submit"
                                    disabled={!isValid || isSubmitting}
                                >{Translations.AddToOrder}
                                </Button>
                            }
                            {state.selectedOrderId === null &&
                                <Button variant="save" type="submit" startIcon={<AddIcon/>} disabled={!isValid || isSubmitting}>
                                    {Translations.CreateNewOrder}
                                </Button>
                            }
                            {state.validationErrors.map((err, i) => <Alert key={i} severity="error">{err}</Alert>)}
                        </Stack>
                    </>
                }
            </form>

            {workOrderEdit !== null &&
                <WorkOrderDialogConnected
                    workOrderEdit={workOrderEdit}
                    makeProductBookingsOnOpen={false}
                    enabledStates={workOrderEdit.workOrder.isNew() ? [0] : null}
                    onOk={null}
                    onCreated={onWorkOrderCreate}
                    onCopied={null}
                    onCancel={onWorkOrderDialogClose}
                />
            }
        </div>
    );
};
