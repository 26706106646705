// OwnerSettingsDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import { PropertyDialog } from "../framework/dialog";
import { IdTitle, IIdTitle } from "../../models/common/idTitle";
import { OwnerSettingsWorkOrderTypeList } from "./ownerSettingsWorkOrderTypeList";
import { OwnerSettingsWorkOrderAcknowledgementTypeList } from "./ownerSettingsWorkOrderAcknowledgementTypeList";
import { OwnerSettingsCompetencyTypeList } from "./ownerSettingsCompetencyTypeList";
import { OwnerSettingsDayBookingTypeList } from "./ownerSettingsDayBookingTypeList";
import { OwnerSettingsUserGroupList } from "./ownerSettingsUserGroupList";
import { OwnerSettingsOwnerEdit } from "./ownerSettingsOwnerEdit";
import { INamedCallback, NamedCallback } from "../../models/common/namedCallback";
import { OwnerSettingsSalaryPeriodTypeList } from "./ownerSettingsSalaryPeriodTypeList";
import { OwnerSettingsSalaryRowTypeList } from "./ownerSettingsSalaryRowTypeList";


/* eslint-disable no-unused-vars */
const enum OwnerSettingsMenuItem {
    Company = "1",
    CompetencyTypes = "2",
    DayBookingTypes = "5",
    SalaryPeriodType = "8",
    SalaryRowTypes = "9",
    UserGroups = "11",
    WorkOrderAcknowledgementTypes = "12",
    WorkOrderTypes = "13"
}
/* eslint-enable no-unused-vars */

// OwnerSettingsDialog
// ***********************************************************************************************************************
export interface IOwnerSettingsDialogProp {
    classes?: string;
    onCancel: () => void;
}

export interface IOwnerSettingsDialogState {
    activeMenuItem: OwnerSettingsMenuItem;
    menuItems: IdTitle[];
}

export class OwnerSettingsDialog extends React.Component<IOwnerSettingsDialogProp, IOwnerSettingsDialogState> {
    navigationCallbacks: INamedCallback[] = [];

    constructor(props) {
        super(props);
        const menuItems: IIdTitle[] = [
            IdTitle.createIdTitle(OwnerSettingsMenuItem.Company, Translations.Company),
            IdTitle.createIdTitle(OwnerSettingsMenuItem.CompetencyTypes, Translations.CompetencyTypes),
            IdTitle.createIdTitle(OwnerSettingsMenuItem.DayBookingTypes, Translations.DayBookingTypes),
            IdTitle.createIdTitle(OwnerSettingsMenuItem.SalaryPeriodType, Translations.SalaryPeriodTypes),
            IdTitle.createIdTitle(OwnerSettingsMenuItem.SalaryRowTypes, Translations.SalaryRowTypes),
            IdTitle.createIdTitle(OwnerSettingsMenuItem.UserGroups, Translations.UserGroups),
            IdTitle.createIdTitle(OwnerSettingsMenuItem.WorkOrderAcknowledgementTypes, Translations.WorkOrderAcknowledgementTypes),
            IdTitle.createIdTitle(OwnerSettingsMenuItem.WorkOrderTypes, Translations.WorkOrderTypes)
        ];
        IdTitle.sortIdTitles(menuItems, "title", true);
        this.state = {
            activeMenuItem: menuItems[0].id as OwnerSettingsMenuItem,
            menuItems: menuItems
        };
    }

    handleSetNavigationCallback = (name: string, callback: () => Promise<boolean>) => {
        this.navigationCallbacks = this.navigationCallbacks.filter(i => i.name !== name);
        if (!callback) return;
        this.navigationCallbacks.push(NamedCallback.createNamedCallback(name, callback));
    };

    handleMenuItemClick = (menuItem: OwnerSettingsMenuItem) => {
        const obj = this;
        NamedCallback.executeCallbacks(this.navigationCallbacks.filter(i => !!i.callback).map(i => i.callback)).then(result => {
            if (result) {
                obj.setState({ activeMenuItem: menuItem });
            }
        });
    };

    handleCancel = () => {
        const obj = this;
        NamedCallback.executeCallbacks(this.navigationCallbacks.filter(i => !!i.callback).map(i => i.callback)).then(result => {
            if (result) {
                obj.props.onCancel();
            }
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "ownerSettings px1000" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.CompanySettings}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-3">
                                <div className="panel verticalMenu">
                                    {state.menuItems.map((menuItem) =>
                                        <div key={menuItem.id} className={"menuItem" + (state.activeMenuItem === menuItem.id ? " active" : "")} onClick={() => { this.handleMenuItemClick(menuItem.id as OwnerSettingsMenuItem); }}>{menuItem.title}</div>
                                    )}
                                </div>
                            </div>
                            <div className="col-9 ">
                                {state.activeMenuItem === OwnerSettingsMenuItem.Company &&
                                    <OwnerSettingsOwnerEdit
                                        setNavigationCallback={this.handleSetNavigationCallback}
                                    />
                                }
                                {state.activeMenuItem === OwnerSettingsMenuItem.CompetencyTypes &&
                                    <OwnerSettingsCompetencyTypeList />
                                }
                                {state.activeMenuItem === OwnerSettingsMenuItem.DayBookingTypes &&
                                    <OwnerSettingsDayBookingTypeList />
                                }
                                {state.activeMenuItem === OwnerSettingsMenuItem.SalaryPeriodType &&
                                    <OwnerSettingsSalaryPeriodTypeList />
                                }
                                {state.activeMenuItem === OwnerSettingsMenuItem.SalaryRowTypes &&
                                    <OwnerSettingsSalaryRowTypeList/>
                                }
                                {state.activeMenuItem === OwnerSettingsMenuItem.UserGroups &&
                                    <OwnerSettingsUserGroupList />
                                }
                                {state.activeMenuItem === OwnerSettingsMenuItem.WorkOrderAcknowledgementTypes &&
                                    <OwnerSettingsWorkOrderAcknowledgementTypeList />
                                }
                                {state.activeMenuItem === OwnerSettingsMenuItem.WorkOrderTypes &&
                                    <OwnerSettingsWorkOrderTypeList />
                                }
                            </div>
                        </div>
                    </div>}
                    buttons={[]}
                    onClose={this.handleCancel}
                />
            </div>
        );
    }
}
