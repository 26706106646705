import moment from "moment";
import * as yup from "yup";

export const transportPlanValidationSchema = (isRecurring: boolean) => yup.object({
    name: yup
        .string()
        .required("Nimi on pakollinen tieto")
        .min(3, "Nimen tulee olla vähintään 3 merkkiä pitkä")
        .max(50, "Nimen tulee olla korkeintaan 50 merkkiä pitkä"),
    scheduledStartDateTime: yup
        .string()
        .when([], {
            is: () => !isRecurring,
            then: yup
                .string()
                .nullable()
                .required("Suunniteltu alkamisaika on pakollinen tieto")
                .test(
                    "start-time-date-range",
                    "Aloituksen on oltava ennen lopetusta.",
                    (value, context) =>
                        moment(value).isBefore(
                            context.resolve(yup.ref("scheduledEndDateTime"))
                        )
                ),
            otherwise: yup.string().nullable(),
        }),
    scheduledEndDateTime: yup
        .string()
        .when([], {
            is: () => !isRecurring,
            then: yup
                .string()
                .nullable()
                .required("Suunniteltu loppumisaika on pakollinen tieto")
                .test(
                    "end-time-date-range",
                    "Lopetuksen on oltava aloituksen jälkeen.",
                    (value, context) =>
                        moment(value).isAfter(
                            context.resolve(yup.ref("scheduledStartDateTime"))
                        )
                ),
            otherwise: yup.string().nullable(),
        }),
    repeatEnabledDays: yup
        .number()
        .when([], {
            is: () => isRecurring,
            then: yup
                .number()
                .test(
                    "check-repeatEnabledDays",
                    "",
                    function(value) {
                        return value > 0 ? true : false;
                    }),
        }),
    scheduledStartTime: yup
        .string()
        .when([], {
            is: () => isRecurring,
            then: yup
                .string()
                .required("Aloitusaika on pakollinen tieto"),
            otherwise: yup.string(),
        }),
    repeatStartDate: yup
        .string()
        .when([], {
            is: () => isRecurring,
            then: yup
                .string()
                .nullable()
                .required("Suunniteltu alkamisaika on pakollinen tieto")
                .test(
                    "start-date",
                    "Aloitus päivämäärä ei voi olla menneisyydessä",
                    value => {
                        const now = new Date();
                        now.setHours(0, 0, 0, 0);
                        const selectedDate = new Date(value);
                        return selectedDate >= now;
                    }
                )
                .test(
                    "start-date-range",
                    "Aloituksen on oltava ennen lopetusta.",
                    (value, context) =>
                        moment(value).isBefore(
                            context.resolve(yup.ref("repeatEndDate"))
                        )
                )
                .test(
                    "start-date-range-270-days",
                    "Aloituksen ja lopetuksen väli voi olla enintään 270 päivää.",
                    function(value) {
                        const endDate = this.resolve(yup.ref("repeatEndDate"));
                        if (!value || !endDate) {
                            return true;
                        }

                        const startDate = moment(value);
                        const differenceInDays = moment(endDate).diff(startDate, "days");
                        return differenceInDays <= 270;
                    }
                ),
            otherwise: yup.string().nullable(),
        }),
    repeatEndDate: yup
        .string()
        .when([], {
            is: () => isRecurring,
            then: yup
                .string()
                .nullable()
                .required("Suunniteltu loppumisaika on pakollinen tieto")
                .test(
                    "end-date",
                    "Päättymis päivämäärä ei voi olla menneisyydessä",
                    value => {
                        const now = new Date();
                        now.setHours(0, 0, 0, 0);
                        const selectedDate = new Date(value);
                        return selectedDate >= now;
                    }
                )
                .test(
                    "end-date-range",
                    "Lopetuksen on oltava aloituksen jälkeen.",
                    (value, context) =>
                        moment(value).isAfter(
                            context.resolve(yup.ref("repeatStartDate"))
                        )
                )
                .test(
                    "end-date-range-270-days",
                    "Aloituksen ja lopetuksen väli voi olla enintään 270 päivää.",
                    function(value) {
                        const startDate = this.resolve(yup.ref("repeatStartDate"));
                        if (!value || !startDate) {
                            return true;
                        }

                        const endDate = moment(value);
                        const differenceInDays = moment(endDate).diff(startDate, "days");
                        return differenceInDays <= 270;
                    }
                ),
            otherwise: yup.string().nullable(),
        }),
    duration: yup
        .number()
        .when([], {
            is: () => isRecurring,
            then: yup
                .number()
                .nullable()
                .required()
                .test(
                    "check-duration",
                    "Kesto on pakollinen tieto",
                    function(value) {
                        return value > 0 ? true : false;
                    }),
        }),
    durationDay: yup
        .number(),
    durationHours: yup
        .number(),
    durationMinutes: yup
        .number(),
});