import React from "react";
import { InvoiceDetailsDto } from "../../../models/transportOrderInvoice/invoiceDetailsDto";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { TransportOrderInvoiceEdit } from "./TransportOrderInvoiceEdit";
import { useAppDispatch, useAppSelector } from "../../../framework/customStore";
import { fetchTransportOrderInvoice, selectEditEnabled } from "../../../store/transportOrderInvoiceSlice";
import { TransportOrderInvoiceState } from "../../../models/transportOrderInvoice/transportOrderInvoice";
import { Translations } from "../../../models/translations";
import Grid2 from "@mui/material/Unstable_Grid2";
import { sendInvoiceToIntegration } from "../../../services/newInvoiceService";
import { showErrorMessage, showSuccessMessage } from "../../../models/store/storeActions";

interface IInvoiceDetailsModalProps {
    id: string;
    invoiceDetails: InvoiceDetailsDto;
    open: boolean;
    handleClose: () => void;
    handleRemoveInvoice: (id: string) => void;
    handleApproveInvoice: (id: string, invoiceState: number) => void;
}

export const InvoiceDetailsModal = ({ id, invoiceDetails, handleClose, open, handleRemoveInvoice, handleApproveInvoice }: IInvoiceDetailsModalProps) => {
    const editEnabled = useAppSelector(selectEditEnabled);
    const dispatch = useAppDispatch();
    const sendToIntegration = (id: string) => {
        sendInvoiceToIntegration(id)
            .then((res) => {
                if(res) dispatch(showSuccessMessage(Translations.InvoiceSent));
            })
            .catch(() => {
                dispatch(showErrorMessage(Translations.InvoiceSendFailed));
            });
    };

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="xl" fullWidth>
            <Grid2 container style={{ margin: 25 }}>
                <Grid2 xs={10} >
                    <DialogTitle>{Translations.InvoiceDetails}</DialogTitle>
                </Grid2>
                {invoiceDetails.state === TransportOrderInvoiceState.NotApproved &&
                    <Grid2 xs={2} style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", padding: 8 }}>
                        <Button
                            onClick={() => {
                                handleRemoveInvoice(id);
                                fetchTransportOrderInvoice(id);
                            }}
                            variant="contained"
                            color="error"
                            style={{ marginBottom: "6px" }}
                        >
                            {Translations.RemoveInvoice}
                        </Button>
                    </Grid2>
                }
            </Grid2>
            <DialogContent>
                <TransportOrderInvoiceEdit id={id} disableEdit={true} />
            </DialogContent>
            <DialogActions style={{ margin: 25 }}>
                {editEnabled &&
                    <Button
                        onClick={() => {
                            handleApproveInvoice(id, TransportOrderInvoiceState.Approved);
                        }}
                        size="large"
                        color="success"
                        variant="contained"
                        disabled={!invoiceDetails?.transportOrderRows}
                    >{Translations.ApproveInvoice}
                    </Button>}

                {invoiceDetails.state === TransportOrderInvoiceState.Approved &&
                    <Button
                        size="large"
                        color="success"
                        variant="contained"
                        onClick={() => sendToIntegration(id)}
                    >
                        {Translations.SendInvoice}
                    </Button>}

                <Button size="large" variant="contained" onClick={handleClose}>{Translations.Close}</Button>
            </DialogActions>
        </Dialog >
    );
};
