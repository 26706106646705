import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import React from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import {
    TransportPlanState,
    transportPlanStates,
} from "../../models/transport/transportPlan";
import { togglePlanStateSelected } from "../../store/transport/transportVehiclesSlice";
import { DragContext } from "../framework/drag-n-drop";
import { MuiSwitch } from "../framework/muiSwitch";
import { MainLayout } from "../layout/mainLayout";
import { TransportSidebarContainer } from "./components/sidebar/transportSidebarContainer";
import { TransportVehiclesTimelineGrid } from "./transportVehiclesTimeline/TransportVehiclesTimelineGrid";
import { Translations } from "../../models/translations";
import { TransportTimelineFilters } from "./transportTimeline/transportTimelineFilters";

export const TransportVehiclesMain = () => {
    const selectedPlanStates = useAppSelector(
        (state) => state.transportVehicles.selectedPlanStates
    );

    const dispatch = useAppDispatch();

    function toggleState(toggledState: TransportPlanState) {
        dispatch(togglePlanStateSelected(toggledState));
    }

    return (
        <MainLayout topComponent={<TransportTimelineFilters vehicleGroupsEnabled vehiclesEnabled />}>
            <DragContext>
                <Grid2 container sx={{ height: "100%" }}>
                    <Grid2
                        xs={9}
                        sx={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyItems: "space-between",
                                marginBottom: 8,
                            }}
                        >
                            <Grid2 container flexGrow={1}>
                                {transportPlanStates.map((tps) => (
                                    <MuiSwitch
                                        key={tps.value}
                                        checked={selectedPlanStates[tps.value]}
                                        onChange={() => toggleState(tps.value)}
                                        color={tps.colorName}
                                        label={tps.name}
                                    />
                                ))}
                            </Grid2>
                            <Grid2>
                                <Button
                                    variant="save"
                                    startIcon={<AddIcon />}
                                    component={Link}
                                    to={{
                                        pathname: "/transport/plans/plan/new",
                                        state: {
                                            fromPath: "/transport/vehicles",
                                        },
                                    }}
                                >
                                    {Translations.NewTransportPlan}
                                </Button>
                            </Grid2>
                        </div>

                        <TransportVehiclesTimelineGrid />
                    </Grid2>

                    <Grid2 xs={3} style={{ height: "100%" }} pl={2}>
                        <TransportSidebarContainer />
                    </Grid2>
                </Grid2>
            </DragContext>
        </MainLayout>
    );
};
