import * as React from "react";
import { Box, Typography } from "@mui/material";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    selectedTab: number;
    noPadding?: boolean;
}

export function TabPanel(props: TabPanelProps) {
    const { children, selectedTab, index, noPadding, ...other } = props;

    const isSelected = selectedTab === index;

    return (
        <div
            className={!isSelected && "hidden"}
            role="tabpanel"
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            <Box sx={noPadding ? null : { p: 3 }}>
                {children}
            </Box>
        </div>
    );
}
