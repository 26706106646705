import { EnumHelper, UserGroupPermission } from "../common/enums";
import { Translations } from "../translations";

export interface IUserGroupItem {
    id: string;
    name: string;
    web: number;
    mobile: number;

    getWebPermissionStr(): string;
    getMobilePermissionStr(): string;
    getWebIsOwnerAdmin(): boolean;
    getMobileIsOwnerAdmin(): boolean;
}

export class UserGroupItem implements IUserGroupItem {
    id: string;
    name: string;
    web: number;
    mobile: number;

    constructor();
    constructor(obj: IUserGroupItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
        this.web = obj && obj.web || 0;
        this.mobile = obj && obj.mobile || 0;
    }

    getWebPermissionStr(): string {
        return EnumHelper.isEqual(this.web, UserGroupPermission.Admin)
            ? Translations.UserGroupPermissionAdmin
            : (EnumHelper.isEqual(this.web, UserGroupPermission.Edit)
                ? Translations.UserGroupPermissionEdit
                : "");
    }

    getMobilePermissionStr(): string {
        return EnumHelper.isEqual(this.mobile, UserGroupPermission.Admin)
            ? Translations.UserGroupPermissionAdmin
            : (EnumHelper.isEqual(this.mobile, UserGroupPermission.Edit)
                ? Translations.UserGroupPermissionEdit
                : "");
    }

    getWebIsOwnerAdmin(): boolean {
        return EnumHelper.isGreaterOrEqual(this.web, UserGroupPermission.Admin);
    }

    getMobileIsOwnerAdmin(): boolean {
        return EnumHelper.isGreaterOrEqual(this.web, UserGroupPermission.Admin);
    }
}