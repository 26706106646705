import { Base } from "../../framework/base";
import { ICustomerEditItemSiteItem, CustomerEditItemSiteItem } from "./customerEditItemSiteItem";
import { ICustomerEditItemCustomerContactItem, CustomerEditItemCustomerContactItem } from "./customerEditItemCustomerContactItem";
import { CustomerPriceEditItem, ICustomerPriceEditItem } from "../customerPrice/customerPriceEditItem";

export interface ICustomerEditItem {
    id: string;
    customerTypeId: string;
    workOrderAcknowledgementTypeId: string;
    invoicePeriodId: string;
    number: number;
    name: string;
    logo: string;
    erp: string;
    invoiceStreet: string;
    invoicePostalCode: string;
    invoiceCity: string;
    invoiceCountryCode: string;
    invoiceEmail: string;
    eInvoiceAddress: string;
    eInvoiceOperator: string;
    businessIdentityCode: string;
    vatCode: string;
    activeState: number;
    rowId: string;
    sites: ICustomerEditItemSiteItem[];
    customerContacts: ICustomerEditItemCustomerContactItem[];
    customerPrices: ICustomerPriceEditItem[];

    isNew(): boolean;
}

export class CustomerEditItem implements ICustomerEditItem {
    id: string;
    customerTypeId: string;
    workOrderAcknowledgementTypeId: string;
    invoicePeriodId: string;
    number: number;
    name: string;
    logo: string;
    erp: string;
    invoiceStreet: string;
    invoicePostalCode: string;
    invoiceCity: string;
    invoiceCountryCode: string;
    invoiceEmail: string;
    eInvoiceAddress: string;
    eInvoiceOperator: string;
    businessIdentityCode: string;
    vatCode: string;
    activeState: number;
    rowId: string;
    sites: CustomerEditItemSiteItem[];
    customerContacts: CustomerEditItemCustomerContactItem[];
    customerPrices: CustomerPriceEditItem[];

    constructor();
    constructor(obj: ICustomerEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.customerTypeId = obj && obj.customerTypeId || "";
        this.workOrderAcknowledgementTypeId = obj && obj.workOrderAcknowledgementTypeId || "";
        this.invoicePeriodId = obj && obj.invoicePeriodId || "";
        this.number = obj && obj.number || 0;
        this.name = obj && obj.name || "";
        this.logo = obj && obj.logo || "";
        this.erp = obj && obj.erp || "";
        this.invoiceStreet = obj && obj.invoiceStreet || "";
        this.invoicePostalCode = obj && obj.invoicePostalCode || "";
        this.invoiceCity = obj && obj.invoiceCity || "";
        this.invoiceCountryCode = obj && obj.invoiceCountryCode || "";
        this.invoiceEmail = obj && obj.invoiceEmail || "";
        this.eInvoiceAddress = obj && obj.eInvoiceAddress || "";
        this.eInvoiceOperator = obj && obj.eInvoiceOperator || "";
        this.businessIdentityCode = obj && obj.businessIdentityCode || "";
        this.vatCode = obj && obj.vatCode || "";
        this.activeState = obj && obj.activeState || 0;
        this.rowId = obj && obj.rowId || "";
        this.sites = Base.getTypedArray<CustomerEditItemSiteItem>(!Base.isNullOrUndefined(obj) ? obj.sites : null, CustomerEditItemSiteItem);
        this.customerContacts = Base.getTypedArray<CustomerEditItemCustomerContactItem>(!Base.isNullOrUndefined(obj) ? obj.customerContacts : null, CustomerEditItemCustomerContactItem);
        this.customerPrices = Base.getTypedArray<CustomerPriceEditItem>(!Base.isNullOrUndefined(obj) ? obj.customerPrices : null, CustomerPriceEditItem);
    }

    isNew(): boolean {
        return !this.rowId;
    }
}