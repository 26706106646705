export interface IObjectEventLogItem {
    id: string;
    time: number;
    message: string;
    user: string;
}

export class ObjectEventLogItem implements IObjectEventLogItem {
    id: string;
    time: number;
    message: string;
    user: string;

    constructor();
    constructor(obj: IObjectEventLogItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.time = obj && obj.time || 0;
        this.message = obj && obj.message || "";
        this.user = obj && obj.user || "";
    }
}