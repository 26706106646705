import * as React from "react";
import * as store from "../../framework/customStore";
import * as StoreActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import { InvoiceMainPreliminaryInvoiceList } from "./invoiceMainPreliminaryInvoiceList";
import { IInvoiceEdit } from "../../models/invoice/invoiceEdit";
import * as invoiceService from "../../services/invoiceService";
import { Translations } from "../../models/translations";
import { Base } from "../../framework/base";
import { IInvoiceListItem } from "../../models/invoice/invoiceListItem";
import { InvoiceDialog } from "./invoiceDialog";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { EnumHelper, InvoiceState, WorkOrderState } from "../../models/common/enums";
import { handleApiError } from "../../models/store/storeEffects";
import { infiniteListPageSize } from "../../models/common/consts";
import { InvoiceTakeToInvoicingDialog } from "./invoiceTakeToInvoicingDialog";
import { IWorkOrderEdit } from "../../models/work/workOrderEdit";
import { WorkOrderDialog } from "../work/workOrderDialog";
import { InvoiceDetailsView } from "./invoiceDetailsView";
import { WorkOrderOperations } from "../../models/work/workOrderOperations";
import { InvoiceMainTransferredInvoiceList } from "./invoiceMainTransferredInvoiceList";
import { IInvoiceListItemWorkOrder } from "../../models/invoice/invoiceListItemWorkOrder";
import { InvoiceCreateInvoicesDialog } from "./invoiceCreateInvoicesDialog";
import { SettingsCustomerDialog } from "../settings/settingsCustomerDialog";
import { CustomerOperations } from "../../models/customer/customerOperations";
import { ICustomerEdit } from "../../models/customer/customerEdit";

export interface IInvoiceMainProps {
}

interface IInvoiceMainState {
    pageSize: number;
    isLoading: boolean;
    showCustomerDialog: boolean;
    customerEdit: ICustomerEdit;

    // Preliminary
    preliminaryItems: IInvoiceListItem[];
    preliminaryPage: number;
    preliminaryHasMore: boolean;
    preliminaryFilter: string;
    preliminarySortColumn: string;
    preliminarySortOrderIsAsc: boolean;
    preliminarySelectedId: string;
    preliminaryCheckedIds: string[];

    // Transferred
    transferredItems: IInvoiceListItem[];
    transferredPage: number;
    transferredHasMore: boolean;
    transferredFilter: string;
    transferredSortColumn: string;
    transferredSortOrderIsAsc: boolean;
    transferredSelectedId: string;
    transferredCheckedIds: string[];

    // DetailsView
    detailsViewExpanded: boolean;
    detailsItem: IInvoiceEdit;
    detailsLoading: boolean;

    //WorkOrder
    workOrder: IWorkOrderEdit;
    showWorkOrderDialog: boolean;
    workOrderEditDialogOpenInEditMode: boolean;

    // Invoice
    showCreateInvoiceDialog: boolean;
    createInvoiceWorkOrders: IInvoiceListItemWorkOrder[];
    showInvoiceTakeToInvoicingDialog: boolean;
    invoiceItems: IInvoiceListItem[];

    // Edit
    editItem: IInvoiceEdit;
    showEditDialog: boolean;
}

export class InvoiceMain extends React.Component<IInvoiceMainProps, IInvoiceMainState> {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: infiniteListPageSize,
            isLoading: false,
            preliminaryItems: [],
            preliminaryPage: 1,
            preliminaryHasMore: false,
            preliminaryFilter: "",
            preliminarySortColumn: "number",
            preliminarySortOrderIsAsc: true,
            preliminarySelectedId: null,
            preliminaryCheckedIds: [],
            transferredItems: [],
            transferredPage: 1,
            transferredHasMore: false,
            transferredFilter: "",
            transferredSortColumn: "",
            transferredSortOrderIsAsc: true,
            transferredSelectedId: null,
            transferredCheckedIds: [],
            detailsViewExpanded: true,
            detailsItem: null,
            detailsLoading: false,
            showCreateInvoiceDialog: false,
            createInvoiceWorkOrders: [],
            showInvoiceTakeToInvoicingDialog: false,
            invoiceItems: [],
            editItem: null,
            showEditDialog: false,
            workOrder: null,
            showWorkOrderDialog: false,
            workOrderEditDialogOpenInEditMode: false,
            showCustomerDialog: false,
            customerEdit: null,
        };
    }

    componentDidMount(): void {
        const state = this.state;
        this.searchInvoices(state.pageSize, state.preliminaryPage, state.preliminaryFilter, InvoiceState.Preliminary, state.preliminarySortColumn, state.preliminarySortOrderIsAsc, false, false);
        this.searchInvoices(state.pageSize, state.transferredPage, state.transferredFilter, InvoiceState.Transferred, state.transferredSortColumn, state.transferredSortOrderIsAsc, false, false);
    }

    // #region Details && Edit Load
    refreshInvoiceDetails = (id: string) => {
        const obj = this;
        if (!id) return;
        this.setState({
            detailsLoading: true
        });
        invoiceService.getInvoiceEdit(id)
            .then(invoiceEdit => {
                obj.setState({
                    detailsItem: invoiceEdit
                });
            })
            .catch(error => {
                store.customStore.dispatch(StoreActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            })
            .finally(() => {
                this.setState({
                    detailsLoading: false
                });
            });
    };

    getEditItem = (id: string) => {
        const obj = this;
        if (!id) return;
        invoiceService.getInvoiceEdit(id)
            .then(editItem => {
                obj.setState({
                    showEditDialog: true,
                    editItem: editItem
                });
            })
            .catch(error => {
                store.customStore.dispatch(StoreActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            });
    };
    // #endregion Details && Edit Load

    // #region List
    searchInvoices = (pageSize: number, page: number, filter: string, invoiceState: InvoiceState, sortColumn: string, sortOrderIsAsc: boolean, resetItems: boolean, refreshList: boolean, successCallback: () => void = null): Promise<void> => {
        const obj = this;
        obj.setState({
            isLoading: true
        });
        store.customStore.dispatch(StoreActions.fetchStart());
        return invoiceService.getInvoiceListItems(!refreshList ? pageSize : (page * infiniteListPageSize), !refreshList ? page : 1, filter, [invoiceState], sortColumn, sortOrderIsAsc)
            .then(invoiceListItems => {
                if (EnumHelper.isEqual(invoiceState, InvoiceState.Preliminary)) {
                    const listItems = Base.getListItems<IInvoiceListItem>(obj.state.preliminaryItems, obj.state.preliminarySelectedId, obj.state.preliminaryCheckedIds, invoiceListItems.items, resetItems, refreshList);
                    obj.setState({
                        preliminaryPage: !refreshList ? invoiceListItems.page : page,
                        preliminaryHasMore: invoiceListItems.hasMore,
                        preliminaryFilter: filter,
                        preliminarySortColumn: sortColumn,
                        preliminarySortOrderIsAsc: sortOrderIsAsc,
                        preliminaryItems: listItems.items,
                        preliminarySelectedId: listItems.selectedId,
                        preliminaryCheckedIds: listItems.checkedIds
                    });
                } else {
                    const listItems = Base.getListItems<IInvoiceListItem>(obj.state.transferredItems, obj.state.transferredSelectedId, obj.state.transferredCheckedIds, invoiceListItems.items, resetItems, refreshList);
                    obj.setState({
                        transferredPage: !refreshList ? invoiceListItems.page : page,
                        transferredHasMore: invoiceListItems.hasMore,
                        transferredFilter: filter,
                        transferredSortColumn: sortColumn,
                        transferredSortOrderIsAsc: sortOrderIsAsc,
                        transferredItems: listItems.items,
                        transferredSelectedId: listItems.selectedId,
                        transferredCheckedIds: listItems.checkedIds
                    });
                }
                if (!Base.isNullOrUndefined(successCallback)) {
                    successCallback();
                }
            },
            error => {
                handleApiError(error, store.customStore.dispatch);
            }).finally(() => {
                obj.setState({
                    isLoading: false
                });
                store.customStore.dispatch(StoreActions.fetchEnd());
            });
    };

    refreshLists = (invoiceStates: InvoiceState[]): Promise<void> => {
        const state = this.state;
        const promises: Promise<void>[] = [];
        for (const invoiceState of invoiceStates) {
            if (EnumHelper.isEqual(invoiceState, InvoiceState.Preliminary)) {
                promises.push(this.searchInvoices(state.pageSize, state.preliminaryPage, state.preliminaryFilter, invoiceState, state.preliminarySortColumn, state.preliminarySortOrderIsAsc, false, true));
            } else {
                promises.push(this.searchInvoices(state.pageSize, state.transferredPage, state.transferredFilter, invoiceState, state.transferredSortColumn, state.transferredSortOrderIsAsc, false, true));
            }
        }
        return Promise.all(promises).then();
    };

    handleChangeFilter = (filter: string, invoiceState: InvoiceState) => {
        const state = this.state;
        const sortColumn = EnumHelper.isEqual(invoiceState, InvoiceState.Preliminary) ? state.preliminarySortColumn : state.transferredSortColumn;
        const sortOrderIsAsc = EnumHelper.isEqual(invoiceState, WorkOrderState.Preliminary) ? state.preliminarySortOrderIsAsc : state.transferredSortOrderIsAsc;
        this.searchInvoices(state.pageSize, 1, filter, invoiceState, sortColumn, sortOrderIsAsc, true, false);
    };

    handleChangeSortColumn = (sortColumn: string, invoiceState: InvoiceState) => {
        const state = this.state;
        const filter = EnumHelper.isEqual(invoiceState, InvoiceState.Preliminary) ? state.preliminaryFilter : state.transferredFilter;
        const oldSortColumn = EnumHelper.isEqual(invoiceState, InvoiceState.Preliminary) ? state.preliminarySortColumn : state.transferredSortColumn;
        const oldSortOrderIsAsc = EnumHelper.isEqual(invoiceState, WorkOrderState.Preliminary) ? state.preliminarySortOrderIsAsc : state.transferredSortOrderIsAsc;
        const sortOrderIsAsc = oldSortColumn === sortColumn ? !oldSortOrderIsAsc : true;
        this.searchInvoices(state.pageSize, 1, filter, invoiceState, sortColumn, sortOrderIsAsc, true, false);
    };

    handleChangeColumnCheckbox = (column: string, checked: boolean, invoiceState: InvoiceState) => {
        const state = this.state;
        if (EnumHelper.isEqual(invoiceState, InvoiceState.Preliminary)) {
            const result = Base.getIdsOnColumnCheckboxChange(state.preliminaryItems, checked);
            this.setState({
                preliminarySelectedId: result.selectedId,
                preliminaryCheckedIds: result.checkedIds
            });
        } else {
            const result = Base.getIdsOnColumnCheckboxChange(state.transferredItems, checked);
            this.setState({
                transferredSelectedId: result.selectedId,
                transferredCheckedIds: result.checkedIds,
            });
        }
    };

    handleChangeLineCheckbox = (id: string, checked: boolean, invoiceState: InvoiceState) => {
        const state = this.state;
        if (EnumHelper.isEqual(invoiceState, InvoiceState.Preliminary)) {
            const result = Base.getIdsOnLineCheckboxChange(state.preliminarySelectedId, state.preliminaryCheckedIds, id, checked);
            this.setState({
                preliminarySelectedId: result.selectedId,
                preliminaryCheckedIds: result.checkedIds
            });
        } else {
            const result = Base.getIdsOnLineCheckboxChange(state.transferredSelectedId, state.transferredCheckedIds, id, checked);
            this.setState({
                transferredSelectedId: result.selectedId,
                transferredCheckedIds: result.checkedIds,
            });
        }
    };

    handleLineClick = (id: string, invoiceState: InvoiceState) => {
        if (!id) return;
        if (EnumHelper.isEqual(invoiceState, InvoiceState.Preliminary)) {
            this.setState({
                preliminarySelectedId: id
            });
        } else {
            this.setState({
                transferredSelectedId: id
            });
        }
        this.refreshInvoiceDetails(id);
    };

    handleLineDoubleClick = (id: string) => {
        this.getEditItem(id);
    };

    handleEditInvoiceFromDetails = (id: string) => {
        this.getEditItem(id);
    };
    // #endregion List

    // #region Details and Lists
    refreshByIds = (ids: string[], refreshDetails: boolean = true, refreshListInvoiceStates: InvoiceState[] = []): Promise<void> => {
        const state = this.state;
        if (ids.length < 1) return;
        //Details
        if (refreshDetails) {
            const detailsId = state.detailsItem && state.detailsItem.invoice ? state.detailsItem.invoice.id : "";
            if (detailsId && ids.indexOf(detailsId) > -1) {
                this.refreshInvoiceDetails(detailsId);
            }
        }
        //Lists
        const invoiceStates: InvoiceState[] = refreshListInvoiceStates ? refreshListInvoiceStates.slice(0) : [];
        if (invoiceStates.indexOf(InvoiceState.Preliminary) < 0 && state.preliminaryItems.filter(i => ids.indexOf(i.id) > -1).length) {
            invoiceStates.push(InvoiceState.Preliminary);
        }
        if (invoiceStates.indexOf(InvoiceState.Transferred) < 0 && state.transferredItems.filter(i => ids.indexOf(i.id) > -1).length) {
            invoiceStates.push(InvoiceState.Transferred);
        }
        this.refreshLists(invoiceStates); // TODO UPDATE ONLY CHANGED CUSTOMER DATA
    };

    refreshViewFromDetails = () => {
        const state = this.state;
        if (!state.detailsItem || !state.detailsItem.invoice) return;
        this.refreshByIds([state.detailsItem.invoice.id]);
    };
    // #endregion Details and Lists

    // #region Operations
    print = (ids: string[]) => {
        if (!ids || !ids.length) return;
        store.customStore.dispatch(StoreActions.fetchStart());
        invoiceService.getInvoicePrint(ids)
            .catch(error => {
                store.customStore.dispatch(StoreActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => store.customStore.dispatch(StoreActions.fetchEnd()));
    };

    handlePrint = (invoiceState: InvoiceState) => {
        const state = this.state;
        this.print(EnumHelper.isEqual(invoiceState, InvoiceState.Preliminary)
            ? Base.getSelectedIds(state.preliminarySelectedId, state.preliminaryCheckedIds)
            : Base.getSelectedIds(state.transferredSelectedId, state.transferredCheckedIds));
    };

    handlePrintFromDetails = () => {
        const state = this.state;
        if (!state.detailsItem || !state.detailsItem.invoice) return;
        this.print([state.detailsItem.invoice.id]);
    };

    takeToInvoicing = (ids: string[]) => {
        const state = this.state;
        if (!ids || !ids.length) return;
        const invoiceItems = state.preliminaryItems.filter(i => ids.indexOf(i.id) > -1);
        if (invoiceItems.length < 1) return;
        this.setState({
            showInvoiceTakeToInvoicingDialog: true,
            invoiceItems: invoiceItems
        });
    };

    handleTakeToInvoicing = () => {
        const state = this.state;
        this.takeToInvoicing(Base.getSelectedIds(state.preliminarySelectedId, state.preliminaryCheckedIds));
    };

    handleTakeToInvoicingFromDetails = () => {
        const state = this.state;
        if (!state.detailsItem || !state.detailsItem.invoice) return;
        this.takeToInvoicing([state.detailsItem.invoice.id]);
    };

    handleInvoiceTakeToInvoicingDialogCancel = () => {
        this.setState({
            showInvoiceTakeToInvoicingDialog: false
        });
        this.refreshByIds(this.state.invoiceItems.map(i => i.id), true, [InvoiceState.Transferred]);
    };

    handleEditInvoice = (invoiceState: InvoiceState) => {
        const state = this.state;
        const invoiceId = EnumHelper.isEqual(invoiceState, InvoiceState.Preliminary) ? state.preliminarySelectedId : state.transferredSelectedId;
        if (!invoiceId) return;
        this.getEditItem(invoiceId);
    };

    handleEditDialogCancel = () => {
        const state = this.state;
        this.setState({
            showEditDialog: false
        });
        const editId = state.editItem && state.editItem.invoice ? state.editItem.invoice.id : "";
        if (!editId) return;
        this.refreshByIds([editId]);
    };

    removeInvoice = (ids: string[]) => {
        const obj = this;
        const state = this.state;
        if (ids.length < 1) return;
        const items = state.preliminaryItems.filter(i => ids.indexOf(i.id) > -1).concat(state.transferredItems.filter(i => ids.indexOf(i.id) > -1));
        if (items.length < 1) return;
        store.customStore.dispatch(StoreActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning,
            items.length < 2
                ? String.format(Translations.InvoiceRemoveConfirmation, items[0].number)
                : String.format(Translations.InvoicesRemoveConfirmation, ids.length),
            () => {
                store.customStore.dispatch(StoreActions.clearConfirmation());
                // Call server
                store.customStore.dispatch(StoreActions.fetchStart());
                invoiceService.removeInvoice(ids)
                    .then(success => {
                        store.customStore.dispatch(StoreActions.showSuccessMessage(success.message));
                        obj.refreshByIds(ids, false);
                        obj.setState({
                            detailsItem: null
                        });
                    })
                    .catch(error => {
                        store.customStore.dispatch(StoreActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                        return null;
                    })
                    .finally(() => store.customStore.dispatch(StoreActions.fetchEnd()));
            }, () => {
                store.customStore.dispatch(StoreActions.clearConfirmation());
            }));
    };

    handleRemoveInvoice = (invoiceState: InvoiceState) => {
        const state = this.state;
        this.removeInvoice(EnumHelper.isEqual(invoiceState, InvoiceState.Preliminary)
            ? Base.getSelectedIds(state.preliminarySelectedId, state.preliminaryCheckedIds)
            : Base.getSelectedIds(state.transferredSelectedId, state.transferredCheckedIds));
    };

    handleRemoveInvoiceFromDetails = () => {
        const state = this.state;
        this.removeInvoice(state.detailsItem && state.detailsItem.invoice ? [state.detailsItem.invoice.id] : []);
    };

    returnInvoiceToPreliminary = (ids: string[]) => {
        const obj = this;
        const state = this.state;
        if (ids.length < 1) return;
        const items = state.transferredItems.filter(i => ids.indexOf(i.id) > -1);
        if (items.length < 1) return;
        let workOrders: IInvoiceListItemWorkOrder[] = [];
        for (const item of items) {
            workOrders = workOrders.concat(item.workOrders);
        }
        store.customStore.dispatch(StoreActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning,
            items.length < 2
                ? String.format(Translations.InvoiceReturnToPreliminaryConfirmation, items[0].number)
                : String.format(Translations.InvoicesReturnToPreliminaryConfirmation, ids.length),
            () => {
                store.customStore.dispatch(StoreActions.clearConfirmation());
                // Call server
                store.customStore.dispatch(StoreActions.fetchStart());
                invoiceService.removeInvoice(ids)
                    .then(success => {
                        store.customStore.dispatch(StoreActions.showSuccessMessage(success.message));
                        obj.refreshByIds(ids, false);
                        obj.setState({
                            detailsItem: null,
                            showCreateInvoiceDialog: workOrders.length > 0,
                            createInvoiceWorkOrders: workOrders
                        });
                    })
                    .catch(error => {
                        store.customStore.dispatch(StoreActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                        return null;
                    })
                    .finally(() => store.customStore.dispatch(StoreActions.fetchEnd()));
            }, () => {
                store.customStore.dispatch(StoreActions.clearConfirmation());
            }));
    };

    handleReturnInvoiceToPreliminary = () => {
        const state = this.state;
        this.returnInvoiceToPreliminary(Base.getSelectedIds(state.transferredSelectedId, state.transferredCheckedIds));
    };

    handleInvoiceCreateInvoicesDialogCancel = () => {
        this.setState({
            showCreateInvoiceDialog: false
        });
        this.refreshLists([InvoiceState.Preliminary, InvoiceState.Preliminary]);
    };
    // #endregion Operations

    // #endregion WorkOrder Operations
    handleOpenWorkOrder = (id: string) => {
        const obj = this;
        if (!id) return;
        WorkOrderOperations.getWorkOrderEdit(id, null, null, null).then(workOrderEdit => {
            obj.setState({
                workOrder: workOrderEdit,
                showWorkOrderDialog: true,
                workOrderEditDialogOpenInEditMode: false
            });
        });
    };

    handleEditWorkOrderDialogOk = () => {
        this.setState({
            showWorkOrderDialog: false
        });
    };

    handleEditWorkOrderDialogCopied = (workOrderEdit: IWorkOrderEdit) => {
        const obj = this;
        this.setState({
            showWorkOrderDialog: false
        });
        window.setTimeout(() => {
            obj.setState({
                workOrder: workOrderEdit,
                showWorkOrderDialog: true,
                workOrderEditDialogOpenInEditMode: true
            });
        }, 100);
    };

    handleEditWorkOrderDialogCancel = () => {
        this.setState({
            showWorkOrderDialog: false
        });
    };
    // #endregion WorkOrder Operations

    //edit customer
    getCustomerEditItem = (id: string) => {
        const obj = this;
        CustomerOperations.getCustomerEdit(id)
            .then(editItem => {
                obj.setState({
                    showCustomerDialog: true,
                    customerEdit: editItem
                });
            });
    };

    handleEditCustomer = (id: string) => {
        this.getCustomerEditItem(id);
    };

    handleSettingsCustomerDialogDialogOk = (customerId: string, name: string) => {
        const state = this.state;
        const detailsId = state.detailsItem?.invoice?.id;
        this.setState({
            showCustomerDialog: false,
            customerEdit: null
        });
        this.refreshLists([InvoiceState.Preliminary, InvoiceState.Preliminary]);
        this.refreshInvoiceDetails(detailsId);
    };

    handleSettingsCustomerDialogDialogCancel = () => {
        this.setState({
            showCustomerDialog: false
        });
    };
    //end edit customer


    handleChangeExpansionMode = (expanded: boolean) => {
        this.setState({
            detailsViewExpanded: expanded
        });
    };

    render() {
        const state = this.state;
        return (
            <div>
                <div className="row invoiceMain">
                    <div className={state.detailsViewExpanded ? "col-9" : "col-12 withCollapsedDrawer"}>
                        <div className="invoice backgroundBoard">
                            <div className="backgroundContent">
                                <InvoiceMainPreliminaryInvoiceList
                                    pageSize={state.pageSize}
                                    page={state.preliminaryPage}
                                    hasMore={state.preliminaryHasMore}
                                    filter={state.preliminaryFilter}
                                    sortColumn={state.preliminarySortColumn}
                                    sortOrderIsAsc={state.preliminarySortOrderIsAsc}
                                    items={state.preliminaryItems}
                                    state={InvoiceState.Preliminary}
                                    selectedId={state.preliminarySelectedId}
                                    checkedIds={state.preliminaryCheckedIds}
                                    isLoading={state.isLoading}
                                    searchItems={this.searchInvoices}
                                    onChangeFilter={this.handleChangeFilter}
                                    onChangeSortColumn={this.handleChangeSortColumn}
                                    onChangeColumnCheckbox={this.handleChangeColumnCheckbox}
                                    onChangeLineCheckbox={this.handleChangeLineCheckbox}
                                    onLineClick={this.handleLineClick}
                                    onLineDoubleClick={this.handleLineDoubleClick}
                                    onPrintInvoices={this.handlePrint}
                                    onTakeToInvoicing={this.handleTakeToInvoicing}
                                    onEditInvoice={this.handleEditInvoice}
                                    onRemoveInvoice={this.handleRemoveInvoice}
                                    onOpenWorkOrder={this.handleOpenWorkOrder}
                                />
                                <InvoiceMainTransferredInvoiceList
                                    pageSize={state.pageSize}
                                    page={state.transferredPage}
                                    hasMore={state.transferredHasMore}
                                    filter={state.transferredFilter}
                                    sortColumn={state.transferredSortColumn}
                                    sortOrderIsAsc={state.transferredSortOrderIsAsc}
                                    items={state.transferredItems}
                                    state={InvoiceState.Transferred}
                                    selectedId={state.transferredSelectedId}
                                    checkedIds={state.transferredCheckedIds}
                                    isLoading={state.isLoading}
                                    searchItems={this.searchInvoices}
                                    onChangeFilter={this.handleChangeFilter}
                                    onChangeSortColumn={this.handleChangeSortColumn}
                                    onChangeColumnCheckbox={this.handleChangeColumnCheckbox}
                                    onChangeLineCheckbox={this.handleChangeLineCheckbox}
                                    onLineClick={this.handleLineClick}
                                    onLineDoubleClick={this.handleLineDoubleClick}
                                    onPrintInvoices={this.handlePrint}
                                    onEditInvoice={this.handleEditInvoice}
                                    onRemoveInvoice={this.handleRemoveInvoice}
                                    onOpenWorkOrder={this.handleOpenWorkOrder}
                                    onReturnInvoiceToPreliminary={this.handleReturnInvoiceToPreliminary}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={state.detailsViewExpanded ? "col-3" : "collapsedDrawer"}>
                        <div className="backgroundBoard">
                            <InvoiceDetailsView
                                classes={"lower"}
                                title={Translations.SelectedInvoice}
                                item={state.detailsItem}
                                isLoading={state.detailsLoading}
                                expanded={state.detailsViewExpanded}
                                onOpenWorkOrder={this.handleOpenWorkOrder}
                                onPrintInvoices={this.handlePrintFromDetails}
                                onEditInvoice={this.handleEditInvoiceFromDetails}
                                onRemoveInvoice={this.handleRemoveInvoiceFromDetails}
                                onTakeToInvoicing={this.handleTakeToInvoicingFromDetails}
                                onChangeExpansionMode={this.handleChangeExpansionMode}
                                onEditCustomer={this.handleEditCustomer}
                            />
                        </div>
                    </div>
                </div>
                {state.showCreateInvoiceDialog &&
                    <InvoiceCreateInvoicesDialog
                        items={state.createInvoiceWorkOrders}
                        sortColumn={null}
                        sortOrderIsAsc={true}
                        onCancel={this.handleInvoiceCreateInvoicesDialogCancel}
                    />
                }
                {state.showInvoiceTakeToInvoicingDialog &&
                    <InvoiceTakeToInvoicingDialog
                        items={state.invoiceItems}
                        sortColumn={state.preliminarySortColumn}
                        sortOrderIsAsc={state.preliminarySortOrderIsAsc}
                        onCancel={this.handleInvoiceTakeToInvoicingDialogCancel}
                    />
                }
                {state.showEditDialog &&
                    <InvoiceDialog
                        invoiceEdit={state.editItem}
                        onCancel={this.handleEditDialogCancel}
                    />
                }
                {state.showWorkOrderDialog &&
                    <WorkOrderDialog
                        workOrderEdit={state.workOrder}
                        makeProductBookingsOnOpen={false}
                        onOk={this.handleEditWorkOrderDialogOk}
                        onCopied={this.handleEditWorkOrderDialogCopied}
                        onCancel={this.handleEditWorkOrderDialogCancel}
                    />
                }
                {state.showCustomerDialog &&
                    <SettingsCustomerDialog
                        customerEdit={state.customerEdit}
                        onOk={this.handleSettingsCustomerDialogDialogOk}
                        onCancel={this.handleSettingsCustomerDialogDialogCancel}
                    />
                }
            </div>
        );
    }
}
