import * as React from "react";
import { Translations } from "../../models/translations";
import * as tokenService from "../../services/tokenService";

export interface ILoginPageProp {
    onLogin: (username: string, password: string) => void;
    onLogout: () => void;
}

export interface ILoginPageState {
    username: string;
    password: string;
    submitted: boolean;
}

export class LoginPage extends React.Component<ILoginPageProp, ILoginPageState> {
    constructor(props) {
        super(props);
        this.state = { username: "", password: "", submitted: false };
    }

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const obj = this;
        event.preventDefault();
        const state = this.state;
        this.setState({ submitted: true });
        if (!!state.username && !!state.password) {
            tokenService.logout().then(i => {
                obj.props.onLogin(state.username, state.password);
            });
        }
    };

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "username") {
            this.setState({ username: value });
        } else if (name === "password") {
            this.setState({ password: value });
        }
    };

    render() {
        return (
            <div className="loginPage">
                <div className="loginImage" style={{ backgroundImage: "url("+appConfig.ownRoot + "ImageLogin-EasyOpp.jpg)" }} />
                <div className="loginForm">
                    <div className="login-wrapper">
                        {!!appConfig.environmentName &&
                            <div className="environmentName" style={{ backgroundColor: appConfig.environmentColor }}>{appConfig.environmentName}</div>
                        }
                        <div className="loginLogo">
                            <img
                                src={appConfig.ownRoot + "EasyOpp.svg"}
                                alt="EasyOpp - Decorative logo"
                                style={{ height: 72 }}
                            />
                        </div>
                        <div className="loginContainer">
                            <form name="loginForm" onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <input className="form-control" id="UserName" name="username" placeholder={Translations.Username} value={this.state.username} type="text" autoComplete="username" onChange={this.handleChange} />
                                </div>
                                <div className="form-group">
                                    <input className="form-control" name="password" placeholder={Translations.Password} value={this.state.password} type="password" autoComplete="current-password" onChange={this.handleChange} />
                                </div>
                                <div className="form-group text-center">
                                    <input className="btn btn-primary loginButton" type="submit" value={Translations.SignIn} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
