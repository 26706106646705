export interface IEmployeeGroupItem {
    id: string;
    salaryPeriodTypeId: string;
    name: string;
    description: string;
    collectiveAgreementId: string;

    getTitle(): string;
}

export class EmployeeGroupItem implements IEmployeeGroupItem {
    id: string;
    salaryPeriodTypeId: string;
    name: string;
    description: string;
    collectiveAgreementId: string;

    constructor();
    constructor(obj: IEmployeeGroupItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.salaryPeriodTypeId = obj && obj.salaryPeriodTypeId || "";
        this.name = obj && obj.name || "";
        this.description = obj && obj.description || "";
        this.collectiveAgreementId = obj && obj.collectiveAgreementId || "";
    }

    getTitle() {
        return this.name;
    }
}
