// SettingsCustomerPriceList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "../../framework/base";
import { ICustomerItem } from "../../models/customer/customerItem";
import { CustomerPriceEditItem, ICustomerPriceEditItem } from "../../models/customerPrice/customerPriceEditItem";
import { UserParameters } from "../../models/employee/userParameters";
import { IProductItem } from "../../models/product/productItem";
import { Translations } from "../../models/translations";
import { PricePerUnit } from "../framework/pricePerUnit";
import { ToolButton } from "../framework/toolButton";
import { SettingsCustomerPriceDialog } from "./settingsCustomerPriceDialog";

// SettingsCustomerPriceListLine
export interface ISettingsCustomerPriceListLineProp {
    item: ICustomerPriceEditItem;
    inCustomerDialog: boolean;
    customers: ICustomerItem[];
    products: IProductItem[];
    selectedId: string;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

export class SettingsCustomerPriceListLine extends React.Component<ISettingsCustomerPriceListLineProp, {}> {
    render() {
        const props = this.props;
        const item = props.item;
        const product = props.products.find(p => p.id === item.productId);
        const customer = props.customers.find(c => c.id === item.customerId);
        const name = props.inCustomerDialog ? product?.name : customer?.name;
        return (
            <div className={"row line" + (item.id === this.props.selectedId ? " selected" : "")} onClick={() => { this.props.onClick(item.id); }} onDoubleClick={() => { this.props.onDoubleClick(item.id); }}>
                <div className={UserParameters.isOwnerAdmin() ? "col-8" : "col-12"}>{name}</div>
                {UserParameters.isOwnerAdmin() &&
                    <div className="col-4 ra">
                        {item.unitPrice.toLocaleFixed(Base.getDecimalAmountForPrice(item.unitPrice))}&nbsp;
                        <PricePerUnit unit={product?.unit} />
                    </div>
                }
            </div>
        );
    }
}

// SettingsCustomerPriceList
export interface ISettingsCustomerPriceListProp {
    inCustomerDialog: boolean;
    customer?: ICustomerItem;
    product?: IProductItem;
    usedCustomers: ICustomerItem[];
    availableCustomers: ICustomerItem[];
    usedProducts: IProductItem[];
    availableProducts: IProductItem[];
    title?: string;
    readOnly: boolean;
    items: ICustomerPriceEditItem[];
    onAddCustomerPrice: (akId: string, unitPrice: number) => void;
    onEditCustomerPrice: (akId: string, unitPrice: number) => void;
    onRemoveCustomerPrice: (customerPriceId: string) => void;
}

interface ISettingsCustomerPriceListState {
    selectedId: string;
    showCustomerPriceDialog: boolean;
    showCustomerPriceDialogForNew: boolean;
    customerPrice: ICustomerPriceEditItem;
}

export class SettingsCustomerPriceList extends React.Component<ISettingsCustomerPriceListProp, ISettingsCustomerPriceListState> {
    constructor(props) {
        super(props);
        this.state = {
            selectedId: null,
            showCustomerPriceDialog: false,
            showCustomerPriceDialogForNew: false,
            customerPrice: null
        };
    }

    handleRemove = () => {
        const state = this.state;
        const selectedId = state.selectedId;
        if (!selectedId) return;
        const item = this.props.items.find(i => i.id === selectedId);
        if (!item) return;
        this.props.onRemoveCustomerPrice(item.id);
        this.setState({
            selectedId: null
        });
    };

    getEditItem = (id: string) => {
        if (!UserParameters.isOwnerAdmin()) return;
        if (!id) return;
        const item = this.props.items.find(i => i.id === id);
        if (!item) return;
        this.setState({
            showCustomerPriceDialog: true,
            showCustomerPriceDialogForNew: false,
            customerPrice: item
        });
    };

    handleEdit = () => {
        this.getEditItem(this.state.selectedId);
    };

    handleAdd = () => {
        if (!UserParameters.isOwnerAdmin()) return;
        const props = this.props;
        if (props.readOnly) return;
        if (!props.inCustomerDialog && props.availableCustomers.length < 1) return;
        if (props.inCustomerDialog && props.availableProducts.length < 1) return;
        const item = new CustomerPriceEditItem();
        item.id = Base.getGuid();
        item.customerId = props.inCustomerDialog ? props.customer.id : props.availableCustomers[0].id;
        item.productId = !props.inCustomerDialog ? props.product.id : props.availableProducts[0].id;
        this.setState({
            showCustomerPriceDialog: true,
            showCustomerPriceDialogForNew: true,
            customerPrice: item
        });
    };

    handleLineClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleLineDoubleClick = (id: string) => {
        this.getEditItem(id);
    };

    handleCustomerPriceDialogOk = (item: ICustomerPriceEditItem) => {
        if (!item) return;
        const props = this.props;
        const state = this.state;
        if (state.showCustomerPriceDialogForNew) {
            props.onAddCustomerPrice(props.inCustomerDialog ? item.productId : item.customerId, item.unitPrice);
        } else {
            props.onEditCustomerPrice(props.inCustomerDialog ? item.productId : item.customerId, item.unitPrice);
        }
        this.setState({
            showCustomerPriceDialog: false
        });
    };

    handleCustomerPriceDialogCancel = () => {
        this.setState({
            showCustomerPriceDialog: false
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        const items = props.items;
        const isOwnerAdmin = UserParameters.isOwnerAdmin();
        return (
            <div>
                {UserParameters.isOwnerAdmin() &&
                    <div className="commandRow">
                        {!!props.title &&
                            <label className="control-label listTitle">{props.title}</label>
                        }
                        <ToolButton
                            title={Translations.Remove}
                            enabled={!props.readOnly && !!state.selectedId && isOwnerAdmin}
                            classes={"round right remove"}
                            onClick={this.handleRemove}
                        />
                        <ToolButton
                            title={Translations.Edit}
                            enabled={!props.readOnly && !!state.selectedId && isOwnerAdmin}
                            classes={"round right edit"}
                            onClick={this.handleEdit}
                        />
                        <ToolButton
                            title={Translations.Add}
                            enabled={!props.readOnly && isOwnerAdmin}
                            classes={"round right add"}
                            onClick={this.handleAdd}
                        />
                    </div>}
                <div className="listContainer">
                    {items.length > 0 &&
                        <div className="list">
                            {items.map((item) =>
                                <SettingsCustomerPriceListLine
                                    inCustomerDialog={props.inCustomerDialog}
                                    key={item.id}
                                    item={item}
                                    products={props.usedProducts}
                                    customers={props.usedCustomers}
                                    selectedId={state.selectedId}
                                    onClick={this.handleLineClick}
                                    onDoubleClick={this.handleLineDoubleClick}
                                />
                            )}
                        </div>
                    }
                </div>
                {state.showCustomerPriceDialog &&
                    <SettingsCustomerPriceDialog
                        customers={state.showCustomerPriceDialogForNew ? props.availableCustomers : props.usedCustomers}
                        products={state.showCustomerPriceDialogForNew ? props.availableProducts : props.usedProducts}
                        forNew={state.showCustomerPriceDialogForNew}
                        customerReadOnly={props.inCustomerDialog}
                        productReadOnly={!props.inCustomerDialog}
                        customerPrice={state.customerPrice}
                        onOk={this.handleCustomerPriceDialogOk}
                        onCancel={this.handleCustomerPriceDialogCancel}
                    />
                }
            </div>
        );
    }
}
