// WorkOrderDialogRoutePointList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as storeActions from "../../models/store/storeActions";
import * as storeEffects from "../../models/store/storeEffects";
import { connect } from "react-redux";
import { IRoutePointItem } from "../../models/routePoint/routePointItem";
import { IApplicationState } from "../../models/store/storeTypes";
import { IIdTitle } from "../../models/common/idTitle";
import { IWorkOrderEditContact } from "../../models/work/workOrderEditContact";
import { LocationMap } from "../framework/locationMap";
import { IWorkShiftTimeSlotType } from "../../models/workShiftTimeSlotType/workShiftTimeSlotType";
import { IEmployeeItem } from "../../models/employee/employeeItem";
import { IVehicleItem } from "../../models/vehicle/vehicleItem";
import { IStorageProductBooking } from "../../models/storage/storageProductBooking";
import { WorkOrderEditRouteEditor } from "./workOrderEditRouteEditor";
import { IRoutePointAutocompleteItem } from "../../models/routePoint/routePointAutocompleteItems";

// WorkOrderDialogRoutePointList
export interface IWorkOrderDialogRoutePointListDispatchProps {
    onOwnerRoutePointsAdd: (routePoint: IRoutePointAutocompleteItem) => void;
}

export interface IWorkOrderDialogRoutePointListStateProps {
    classes?: string;
    title?: string;
    titleClass?: string;
    isReadOnly: boolean;
    detailsView?: boolean;
    plannedRoute: boolean;
    mapLinkTemplate: string;
    directionsMapLinkTemplate: string;
    customerId: string;
    employees: IEmployeeItem[];
    vehicles: IVehicleItem[];
    workOrderId: string;
    workOrderIsProject: boolean;
    routePointTypes: IIdTitle[];
    contacts: IWorkOrderEditContact[];
    routePointWorkShiftTimeSlotTypes: IWorkShiftTimeSlotType[];
    routePoints: IRoutePointItem[];
    productBookings: IStorageProductBooking[];
    ownerRoutePoints: IRoutePointAutocompleteItem[];
    onAddContact: (customerId: string, name: string, callback: (contactId: string) => void) => void;
    onAddContactFromRoutePointCopy: (contactId: string) => void;
    onAttachContact: (customerId: string, name: string, callback: (contactId: string) => void) => void;
    onEditContact: (customerId: string, siteId: string) => void;
    onRoutePointsModified: (routePoints: IRoutePointItem[], removedRoutePointIds: string[]) => void;
}

type IWorkOrderDialogRoutePointListProp = IWorkOrderDialogRoutePointListStateProps & IWorkOrderDialogRoutePointListDispatchProps;

interface IWorkOrderDialogRoutePointListState {
    selectedId: string;
    mapAutoFitToBoundsId: number;
    hasLocationRoutePoints: boolean;
}

export class WorkOrderDialogRoutePointList extends React.Component<IWorkOrderDialogRoutePointListProp, IWorkOrderDialogRoutePointListState> {
    constructor(props: IWorkOrderDialogRoutePointListProp) {
        super(props);
        this.state = {
            selectedId: "",
            mapAutoFitToBoundsId: 0,
            hasLocationRoutePoints: props.routePoints.map(i => i.hasLocation()).length > 0,
        };
    }

    componentDidUpdate(prevProps: IWorkOrderDialogRoutePointListProp, prevState: IWorkOrderDialogRoutePointListState): void {
        const props = this.props;
        const state = this.state;
        const hasLocationRoutePoints = props.routePoints.map(i => i.hasLocation()).length > 0;
        if (state.hasLocationRoutePoints === hasLocationRoutePoints) return;
        this.setState({
            hasLocationRoutePoints: hasLocationRoutePoints
        });
    }

    handleSetSelectedIds = (ids: string[]) => {
        if (!ids || ids.length < 1) return;
        this.setState({
            selectedId: ids[0]
        });
    };

    handleSetSelectedRoutePoint = (id: string) => {
        if (!id) return;
        this.setState({
            selectedId: id,
            mapAutoFitToBoundsId: this.state.mapAutoFitToBoundsId + 1
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <div className={"workOrderSubList workOrderRoute" + (props.classes ? " " + props.classes : "")}>
                <div className="row">
                    <div className="col workOrderRoutePointsContainer">
                        {!!props.title &&
                            <label className={"control-label" + (props.titleClass ? " " + props.titleClass : "")}>{props.title}</label>
                        }
                        <WorkOrderEditRouteEditor
                            classes="web"
                            plannedRoute={props.plannedRoute}
                            detailsView={props.detailsView}
                            isReadOnly={props.isReadOnly}
                            workOrderIsProject={props.workOrderIsProject}
                            customerId={props.customerId}
                            workOrderId={props.workOrderId}
                            mapLinkTemplate={props.mapLinkTemplate}
                            directionsMapLinkTemplate={props.directionsMapLinkTemplate}
                            routePointTypes={props.routePointTypes}
                            employees={props.employees}
                            vehicles={props.vehicles}
                            contacts={props.contacts}
                            routePoints={props.routePoints}
                            productBookings={props.productBookings}
                            ownerRoutePoints={props.ownerRoutePoints}
                            routePointWorkShiftTimeSlotTypes={props.routePointWorkShiftTimeSlotTypes}
                            onOwnerRoutePointsAdd={props.onOwnerRoutePointsAdd}
                            onSetSelectedRoutePoint={this.handleSetSelectedRoutePoint}
                            onRoutePointsModified={props.onRoutePointsModified}
                            onAddContact={props.onAddContact}
                            onAttachContact={props.onAttachContact}
                            onAddContactFromRoutePointCopy={props.onAddContactFromRoutePointCopy}
                            onEditContact={props.onEditContact}
                        />
                    </div>
                    <div className="col workOrderRouteMapContainer">
                        {state.hasLocationRoutePoints &&
                            <LocationMap
                                classes="workOrder routePoints"
                                points={props.routePoints}
                                autoFitToBoundsId={state.mapAutoFitToBoundsId}
                                selectedIds={[state.selectedId]}
                                onSetSelectedIds={this.handleSetSelectedIds}
                            />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: IApplicationState) {
    return {
        ownerRoutePoints: state.ownerRoutePoints.items,
    };
}

function mapDispatchToProps(dispatch: storeEffects.OwnerRoutePointsThunkDispatch): IWorkOrderDialogRoutePointListDispatchProps {
    return {
        onOwnerRoutePointsAdd: (routePoint: IRoutePointAutocompleteItem) => dispatch(storeActions.ownerRoutePointsAdd(routePoint)),
    };
}

export const WorkOrderDialogRoutePointListConnected = connect(mapStateToProps, mapDispatchToProps)(WorkOrderDialogRoutePointList);
