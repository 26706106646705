import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { Translations } from "../../models/translations";

interface ISubmitButtonProps {
    label?: string;
    submitting?: boolean;
}

type SubmitButtonProps = ISubmitButtonProps & ButtonProps;

function SubmitButton({ label = Translations.Save, submitting = false, disabled, ...rest }: SubmitButtonProps) {
    return <Button type="submit" variant="contained" disabled={submitting || disabled} {...rest}>{submitting ? Translations.Loading : label}</Button>;
}

export { SubmitButton };