import { Base } from "../../framework/base";
import { IDocument, Document } from "../document/document";
import { EmployeeEditItemEmployeeCompetencyItem, IEmployeeEditItemEmployeeCompetencyItem } from "./employeeEditItemEmployeeCompetencyItem";
import { IDayBookingEditItem, DayBookingEditItem } from "../dayBooking/dayBookingEditItem";
import { IEmployeeEditItemSsoIntegration, EmployeeEditItemSsoIntegration } from "./employeeEditItemSsoIntegration";
import { IEmployeeEditEmployeeGroupItem, EmployeeEditEmployeeGroupItem } from "./employeeEditEmployeeGroupItem";

export interface IEmployeeEditItem {
    id: string;
    number: number;
    firstName: string;
    lastName: string;
    abbreviation: string;
    picture: string;
    phone: string;
    email: string;
    erp: string;
    employeeGroupId: string;
    salaryEmployeeGroupId: string;
    vehicleId: string;
    costCenterId: string;
    userGroupId: string;
    salaryTypeId: string;
    username: string;
    defaultCulture: string;
    mapOnId: number;
    workTimeAccountInUse: boolean;
    activeState: number;
    rowId: string;
    dayBookings: IDayBookingEditItem[];
    competencies: IEmployeeEditItemEmployeeCompetencyItem[];
    documents: IDocument[];
    ssoIntegrations: IEmployeeEditItemSsoIntegration[];
    employeeEmployeeGroups: IEmployeeEditEmployeeGroupItem[];
    postalCodeRangeStart: string;
    postalCodeRangeEnd: string;

    isNew():boolean;
}

export class EmployeeEditItem implements IEmployeeEditItem {
    id: string;
    number: number;
    firstName: string;
    lastName: string;
    abbreviation: string;
    picture: string;
    phone: string;
    email: string;
    erp: string;
    employeeGroupId: string;
    salaryEmployeeGroupId: string;
    vehicleId: string;
    costCenterId: string;
    userGroupId: string;
    salaryTypeId: string;
    username: string;
    defaultCulture: string;
    mapOnId: number;
    workTimeAccountInUse: boolean;
    activeState: number;
    rowId: string;
    dayBookings: DayBookingEditItem[];
    competencies: EmployeeEditItemEmployeeCompetencyItem[];
    documents: Document[];
    ssoIntegrations: EmployeeEditItemSsoIntegration[];
    postalCodeRangeStart: string;
    postalCodeRangeEnd: string;
    employeeEmployeeGroups: EmployeeEditEmployeeGroupItem[];

    constructor();
    constructor(obj: IEmployeeEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.lastName = obj && obj.lastName || "";
        this.firstName = obj && obj.firstName || "";
        this.abbreviation = obj && obj.abbreviation || "";
        this.picture = obj && obj.picture || "test";
        this.phone = obj && obj.phone || "";
        this.email = obj && obj.email || "";
        this.erp = obj && obj.erp || "";
        this.employeeGroupId = obj && obj.employeeGroupId || "";
        this.salaryEmployeeGroupId = obj && obj.salaryEmployeeGroupId || "";
        this.vehicleId = obj && obj.vehicleId || "";
        this.costCenterId = obj && obj.costCenterId || "";
        this.userGroupId = obj && obj.userGroupId || "";
        this.salaryTypeId = obj && obj.salaryTypeId || "";
        this.username = obj && obj.username || "";
        this.defaultCulture = obj && obj.defaultCulture || "";
        this.mapOnId = obj && obj.mapOnId || 0;
        this.activeState = obj && obj.activeState || 0;
        this.workTimeAccountInUse = obj && obj.workTimeAccountInUse || false;
        this.rowId = obj && obj.rowId || "";
        this.dayBookings = Base.getTypedArray<DayBookingEditItem>(obj ? obj.dayBookings : null, DayBookingEditItem);
        DayBookingEditItem.sortDayBookingEditItems(this.dayBookings, "date", false);
        this.competencies = Base.getTypedArray<EmployeeEditItemEmployeeCompetencyItem>(obj ? obj.competencies : null, EmployeeEditItemEmployeeCompetencyItem);
        EmployeeEditItemEmployeeCompetencyItem.sortEmployeeEditItemEmployeeCompetencyItems(this.competencies, "name", true);
        this.documents = Base.getTypedArray<Document>(obj ? obj.documents : null, Document);
        Document.sortDocuments(this.documents, "title", true);
        this.ssoIntegrations = Base.getTypedArray<EmployeeEditItemSsoIntegration>(obj ? obj.ssoIntegrations : null, EmployeeEditItemSsoIntegration);
        this.postalCodeRangeStart = obj && obj.postalCodeRangeStart || "";
        this.postalCodeRangeEnd = obj && obj.postalCodeRangeEnd || "";
        this.employeeEmployeeGroups = Base.getTypedArray<EmployeeEditEmployeeGroupItem>(obj ? obj.employeeEmployeeGroups : null, EmployeeEditEmployeeGroupItem);
    }

    isNew():boolean {
        return !this.rowId;
    }
}
