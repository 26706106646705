import { IDayBookingTypeEditItem, DayBookingTypeEditItem } from "./dayBookingTypeEditItem";
import { Base } from "../../framework/base";
import { ISalaryRowTypeItem, SalaryRowTypeItem } from "../salary/salaryRowTypeItem";

export interface IDayBookingTypeEdit {
    dayBookingType: IDayBookingTypeEditItem;
    salaryRowTypes: ISalaryRowTypeItem[];
}

export class DayBookingTypeEdit implements IDayBookingTypeEdit {
    dayBookingType: DayBookingTypeEditItem;
    salaryRowTypes: SalaryRowTypeItem[];

    constructor();
    constructor(obj: IDayBookingTypeEdit);
    constructor(obj?: any) {
        this.dayBookingType = new DayBookingTypeEditItem(obj ? obj.dayBookingType : null);
        this.salaryRowTypes = Base.getTypedArray<SalaryRowTypeItem>(obj ? obj.salaryRowTypes : null, SalaryRowTypeItem);
    }
}
