import { Base } from "../../framework/base";
import { IProductEditItem, ProductEditItem } from "./productEditItem";
import { IProductGroup, ProductGroup } from "./productGroup";
import { ICustomerItem, CustomerItem } from "../customer/customerItem";
import { IIdTitle, IdTitle } from "../common/idTitle";

export interface IProductEdit {
    product: IProductEditItem;
    productGroups: IProductGroup[];
    customers: ICustomerItem[];
    measureUnits: IIdTitle[];
}

export class ProductEdit implements IProductEdit {
    product: ProductEditItem;
    productGroups: ProductGroup[];
    customers: CustomerItem[];
    measureUnits: IdTitle[];

    constructor();
    constructor(obj: IProductEdit);
    constructor(obj?: any) {
        this.product = null;
        if (obj && obj.product) {
            this.product = new ProductEditItem(obj.product);
        }
        this.productGroups = Base.getTypedArray<ProductGroup>(obj ? obj.productGroups : null, ProductGroup);
        this.customers = Base.getTypedArray<CustomerItem>(obj ? obj.customers : null, CustomerItem);
        this.measureUnits = Base.getTypedArray<IdTitle>(obj ? obj.measureUnits : null, IdTitle);
    }
}
