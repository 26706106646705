import { connect } from "react-redux";
import { IApplicationState } from "../../models/store/storeTypes";
import * as storeEffects from "../../models/store/storeEffects";
import { Base } from "../../framework/base";
import { IWorkMainOwnProps, IWorkMainStateProps, IWorkMainDispatchProps, WorkMain } from "../../components/work/workMain";
import { CalendarAccuracyType, TimeFormat } from "../../models/common/enums";
import { IEmployeeParameters } from "../../models/employee/employeeIParameters";

export function mapStateToProps(applicationState: IApplicationState): IWorkMainStateProps {
    if (Base.isNullOrUndefined(applicationState) || Base.isNullOrUndefined(applicationState.workMain)) {
        return {
            owner: null,
            user: null,
            dataId: -1,
            workTimeFormat: TimeFormat.DecimalFormat,
            startDate: null,
            endDate: null,
            workOrders: [],
            workOrderTemplates: [],
            favoriteWorkOrders: [],
            showOnMapVehicles: [],
            calendarLines: [],
            calendarDays: [],
            dayBookings: [],
            dayBookingTypes: [],
            workOrderStates: [],
            workOrderClasses: [],
            vehicleGroups: [],
            employeeGroups: []
        };
    }
    const workMain = applicationState.workMain;
    return {
        owner: applicationState.owner,
        user: applicationState.user,
        dataId: workMain.dataId,
        workTimeFormat: workMain.workTimeFormat,
        startDate: workMain.startDate,
        endDate: workMain.endDate,
        workOrders: workMain.workOrders,
        workOrderTemplates: workMain.workOrderTemplates,
        favoriteWorkOrders: workMain.favoriteWorkOrders,
        showOnMapVehicles: workMain.showOnMapVehicles,
        calendarLines: workMain.calendarLines,
        calendarDays: workMain.calendarDays,
        dayBookings: workMain.dayBookings,
        dayBookingTypes: workMain.dayBookingTypes,
        workOrderStates: workMain.workOrderStates,
        workOrderClasses: workMain.workOrderClasses,
        vehicleGroups: workMain.vehicleGroups,
        employeeGroups: workMain.employeeGroups
    };
}

export function mapDispatchToProps(dispatch: storeEffects.WorkThunkDispatch): IWorkMainDispatchProps {
    return {
        loadWorkOrderMain: () => dispatch(storeEffects.getWorkOrderMain()),
        loadWorkOrderItems: (startDate: number, endDate: number, calendarAccuracy: CalendarAccuracyType) => dispatch(storeEffects.getWorkOrderItems(startDate, endDate, calendarAccuracy)),
        loadWorkOrderTemplates: () => dispatch(storeEffects.getWorkOrderTemplates()),
        setEmployeeParameters: (parameters: IEmployeeParameters, saveToDb: boolean) => dispatch(storeEffects.setEmployeeParameters(parameters, saveToDb)),
        onLogout: () => dispatch(storeEffects.logout())
    };
}

export default connect<IWorkMainStateProps, IWorkMainDispatchProps, IWorkMainOwnProps>(mapStateToProps, mapDispatchToProps)(WorkMain);
