import { Base } from "../../framework/base";
import { Translations } from "../../models/translations";
import { IAutocompleteItem } from "../framework/autocomplete";

export enum MainGroupType {
    Common,
    ProductLocationInvoicing,
    Storage,
    Extranet
}

export class ProductMainGroup implements IAutocompleteItem {

    constructor(id, databaseId, name) {
        this.id = id;
        this.databaseId = databaseId;
        this.name = name;
    }

    id: string;
    databaseId: number;
    name: string;

    getTitle(): string {
        return this.name;
    }

}

export  class MainGroups {
    public static common = new ProductMainGroup(Base.getGuid(), MainGroupType.Common, Translations.CommonType);
    public static invoicing = new ProductMainGroup(Base.getGuid(), MainGroupType.ProductLocationInvoicing, Translations.StorageInvoice);
    public static storage = new ProductMainGroup(Base.getGuid(), MainGroupType.Storage, Translations.StorageProduct);
    public static extranet = new ProductMainGroup(Base.getGuid(), MainGroupType.Extranet, Translations.Extranet);
}
