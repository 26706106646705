export interface IWorkOrderListItemInvoice {
    id: string;
    number: number;
}

export class WorkOrderListItemInvoice implements IWorkOrderListItemInvoice {
    id: string;
    number: number;

    constructor();
    constructor(obj: IWorkOrderListItemInvoice);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
    }
}