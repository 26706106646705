import React, { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";

interface IMuiTimePickerProps {
    value: string;
    label?: string;
    onChange(newValue: string): void;
    maxTime?: string;
}

export const MuiTimePicker = ({
    value,
    label,
    onChange,
    maxTime
}: IMuiTimePickerProps) => {
    const [selected, setSelected] = useState<Dayjs>(dayjs(value, "HH:mm"));

    useEffect(() => {
        if (value !== selected?.format("HH:mm")) {
            setSelected(value ? dayjs(value, "HH:mm") : null);
        }
    }, [value]);

    useEffect(() => {
        const newValue = selected ? selected.format("HH:mm") : "";

        if (value !== newValue) {
            onChange(newValue);
        }
    }, [selected]);

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="fi"
        >
            <DesktopTimePicker
                label={label}
                value={selected}
                ampm={false}
                onChange={(newValue) => setSelected(newValue.isValid() ? newValue : null)}
                className="w-100"
                maxTime={dayjs(maxTime, "HH:mm")}
            />
        </LocalizationProvider>
    );
};