import { IIdTitle, IdTitle } from "../common/idTitle";
import { Base } from "../../framework/base";
import { IEmployeeItem, EmployeeItem } from "../employee/employeeItem";
import { IVehicleItem, VehicleItem } from "../vehicle/vehicleItem";
import { TimeFormat } from "../common/enums";

export interface IWorkOrderPageMain {
    workTimeFormat: TimeFormat;
    workOrderStates: IIdTitle[];
    workOrderTypes: IIdTitle[];
    employees: IEmployeeItem[];
    resources: IVehicleItem[];
    invoiceStates: IIdTitle[];
}

export class WorkOrderPageMain implements IWorkOrderPageMain {
    workTimeFormat: TimeFormat;
    workOrderStates: IdTitle[];
    workOrderTypes: IdTitle[];
    employees: EmployeeItem[];
    resources: VehicleItem[];
    invoiceStates: IdTitle[];

    constructor();
    constructor(obj: IWorkOrderPageMain);
    constructor(obj?: any) {
        this.workTimeFormat = obj && obj.workTimeFormat || TimeFormat.DecimalFormat;
        this.workOrderStates = Base.getTypedArray<IdTitle>(!Base.isNullOrUndefined(obj) ? obj.workOrderStates : null, IdTitle);
        this.workOrderTypes = Base.getTypedArray<IdTitle>(!Base.isNullOrUndefined(obj) ? obj.workOrderTypes : null, IdTitle);
        this.employees = Base.getTypedArray<EmployeeItem>(!Base.isNullOrUndefined(obj) ? obj.employees : null, EmployeeItem);
        this.resources = Base.getTypedArray<VehicleItem>(!Base.isNullOrUndefined(obj) ? obj.resources : null, VehicleItem);
        this.invoiceStates = Base.getTypedArray<IdTitle>(!Base.isNullOrUndefined(obj) ? obj.invoiceStates : null, IdTitle);
    }
}