import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Translations } from "../../../models/translations";
import { EditDialog, EditDialogProps, ExposedFunctions } from "../../framework/editDialog";
import {
    defaultFormikConfig,
    formatString,
    formikFieldProps,
    showApiError,
    showConfirm
} from "../../framework/formUtils";
import { StorageEditItem } from "../../../models/storage/storage";
import { Button, Divider, IconButton, InputAdornment, TextField } from "@mui/material";
import {
    saveStorageLocation,
    removeStorageLocation,
    getStorageIdTitles, getStorage
} from "../../../services/storageService";
import * as store from "../../../framework/customStore";
import * as StoreActions from "../../../models/store/storeActions";
import * as baseService from "../../../services/baseService";
import Grid2 from "@mui/material/Unstable_Grid2";
import { STYLE_CONSTANTS } from "../../../framework/theme";
import { FormikErrors, useFormik } from "formik";
import * as yup from "yup";
import { StorageLocationEditItem, StorageLocationProduct } from "../../../models/storage/storageLocation";
import AddIcon from "@mui/icons-material/Add";
import { getProductItems } from "../../../services/productService";
import { getCustomerIdTitles } from "../../../services/customerService";
import { AutocompleteOption, AutocompleteWrapper } from "../../framework/muiAutocomplete";
import { IdTitle } from "../../../models/common/idTitle";
import { StorageDialog } from "../storages/storageDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import { MainGroupType } from "../../settings/ProductGroupCommon";
import { AutocompleteItem } from "../../framework/autocomplete";
import { showError } from "../storageUtils";
import { StorageProductDialog } from "../StorageProduct/storageProductDialog";
import { ProductEditItem } from "../../../models/storage/storageProduct";
import { getSingleProduct } from "../../../services/storageService";
import { TextFieldWrapper } from "../../framework/TextFieldWrapper";

export const StorageLocationDialog = ({ item, open, onSave, onClose, onRemove }: EditDialogProps<StorageLocationEditItem>) => {
    const [billingProductItems, setBillingProductItems] = useState<AutocompleteOption[]>([]);
    const [productItems, setProductItems] = useState<AutocompleteOption[]>([]);
    const [storageItems, setStorageItems] = useState<AutocompleteOption[]>([]);
    const [customerItems, setCustomerItems] = useState<AutocompleteOption[]>([]);
    const [storageEditItem, setStorageEditItem] = useState<StorageEditItem | null>(null);
    const [productEditItem, setProductEditItem] = useState<ProductEditItem>(null);
    const [productEditItemIndex, setProductEditItemIndex] = useState<number | null>(null);
    const [productMainGroup, setProductMainGroup] = useState<MainGroupType>();
    const dialogRef = useRef<ExposedFunctions>();

    useEffect(() => {
        getProductItems(null, null, "", "Name", true, false, "", MainGroupType.ProductLocationInvoicing)
            .then(response => setBillingProductItems(response.items.map(i => IdTitle.createIdTitle(i.id, i.name))))
            .catch(showError);

        getProductItems(null, null, "", "Name", true, false, "", MainGroupType.Storage)
            .then(response => setProductItems(response.items.map(i => IdTitle.createIdTitle(i.id, i.name))))
            .catch(showError);

        getCustomerIdTitles([])
            .then(response => setCustomerItems(response.items))
            .catch(showError);

        getStorageIdTitles()
            .then(setStorageItems)
            .catch(showError);
    }, []);

    const handleSubmit = (formData: StorageLocationEditItem) => {
        const onSubmitSuccess = (item: StorageLocationEditItem) => {
            formik.setSubmitting(false);
            void formik.setValues(item);
            dialogRef.current.setInitialFormDataFromForm();
            store.customStore.dispatch(StoreActions.showSuccessMessage(Translations.SaveSuccess));

            if (onSave) {
                onSave(dialogRef.current.getInitialFormData() as StorageLocationEditItem);
            }
        };

        const onSubmitFailure = (error) => {
            showError(baseService.getErrorMessageFromError(error));
            formik.setSubmitting(false);
        };

        saveStorageLocation(formData)
            .then(onSubmitSuccess)
            .catch(onSubmitFailure);
    };

    const formik = useFormik<StorageLocationEditItem>({
        ...defaultFormikConfig,
        initialValues: item ?? new StorageLocationEditItem(),
        validationSchema: yup.object({
            storageLocationCode: yup.string()
                .required(formatString(Translations.FieldIsRequired, Translations.Code))
                .max(50, formatString(Translations.FieldMaxLengthError, Translations.Code.toLowerCase(), 50)),
            storageLocationName: yup.string()
                .required(formatString(Translations.FieldIsRequired, Translations.Name))
                .max(50, formatString(Translations.FieldMaxLengthError, Translations.Name.toLowerCase(), 50)),
            storageId: yup.string().nullable()
                .uuid(Translations.InvalidValue)
                .required(formatString(Translations.FieldIsRequired, Translations.Storage)),
            description: yup.string()
                .max(500, formatString(Translations.FieldMaxLengthError, Translations.AdditionalInformation.toLowerCase())),
            customerId: yup.string().nullable()
                .uuid(Translations.InvalidValue),
            productId: yup.string().nullable()
                .uuid(Translations.InvalidValue),
            storageProducts: yup.array(
                yup.object({
                    productId: yup.string()
                        .required(formatString(Translations.FieldIsRequired, Translations.Product)),
                    freeAmount: yup.number()
                        .required(Translations.InvalidAmountError)
                        .min(0, Translations.InvalidAmountError)
                        .typeError(Translations.InvalidValue)
                        .required(formatString(Translations.FieldIsRequired, Translations.Amount)),
                })
            ),
        }),
        onSubmit: handleSubmit,
    });

    const handleRemove = () => {
        const storageProducts = (dialogRef.current.getInitialFormData() as StorageLocationEditItem).storageProducts;

        if (storageProducts.length > 0) {
            showError(Translations.CannotDeleteStorageLocationWithProducts);
            return;
        }

        const submitRemove = () => void removeStorageLocation(formik.values.id)
            .then(() => {
                store.customStore.dispatch(StoreActions.showSuccessMessage(Translations.DeleteWasSuccess));
                onRemove();
            })
            .catch(showApiError);

        showConfirm(Translations.AreYouSureWantDelete, submitRemove);
    };

    const addProduct = () => {
        formik.setFieldValue("storageProducts", [...formik.values.storageProducts, new StorageLocationProduct()], false);
    };

    const removeProduct = (index: number, id: string) => {
        formik.setFieldValue("storageProducts", formik.values.storageProducts.filter((_, i) => i !== index), false);

        if (id) {
            let removedIds = formik.values.removedStorageProductIds;
            if (!removedIds) {
                removedIds = [];
            }
            removedIds.push(id);
            formik.setFieldValue("removedStorageProductIds", removedIds, false);
        }
    };

    const handleSaveStorage = (item: StorageEditItem) => {
        formik.setFieldValue("storageId", item.id);
        const items = storageItems.filter(s => s.id !== item.id);
        setStorageItems([...items, { id: item.id, title: item.name }]);
        setStorageEditItem(null);
    };

    const handleRemoveStorage = (item: StorageEditItem) => {
        formik.setFieldValue("storageId", null);
        setStorageEditItem(null);
        setStorageItems(storageItems.filter(s => s.id !== item.id));
    };

    const fetchProductEditItem = (id: string) => {
        void getSingleProduct(id).then(setProductEditItem);
    };

    const handleSaveProduct = (item: ProductEditItem) => {
        if (productEditItemIndex === null) {
            formik.setFieldValue("productId", item.productId);
            const items = billingProductItems.filter(s => s.id !== item.productId);
            setBillingProductItems([...items, new AutocompleteItem({ id: item.productId, title: item.name })]);
            setProductEditItem(null);
        } else {
            formik.setFieldValue(`storageProducts[${productEditItemIndex}].productId`, item.productId);
            const items = productItems.filter(s => s.id !== item.productId);
            setProductItems([...items, new AutocompleteItem({ id: item.productId, title: item.name })]);
            setProductEditItem(null);
        }
    };

    const handleEditStorage = (storageId: string) => {
        void getStorage(storageId).then(item => {
            setStorageEditItem(item);
        });
    };

    const primaryActions = [
        <Button variant="save" disabled={formik.isSubmitting} onClick={() => void formik.submitForm()} key="btn-save">
            {Translations.Save}
        </Button>,
    ];

    if (formik.values.id !== null) {
        primaryActions.push(
            <Button variant="outlined" disabled={formik.isSubmitting} color="error" onClick={handleRemove} key="btn-remove">
                {Translations.Remove}
            </Button>
        );
    }

    return (
        <>
            <EditDialog
                open={open}
                title={formik.values.id === null ? Translations.CreateNewStorageLocation : Translations.EditStorageLocation}
                formik={formik}
                onClose={onClose}
                onRemove={onClose}
                primaryActions={primaryActions}
                ref={dialogRef}
                body={
                    <Grid2 container direction="column" spacing={STYLE_CONSTANTS.formSpacing} sx={{ width: { xs: "100%", md: "auto" } }}>
                        <Grid2 container>
                            <Grid2>
                                <TextFieldWrapper
                                    name="storageLocationCode"
                                    fieldProps={{
                                        label: Translations.StorageLocationCode,
                                        required: true,
                                    }}
                                    formik={formik}
                                />
                            </Grid2>

                            <Grid2>
                                <TextFieldWrapper
                                    name="storageLocationName"
                                    fieldProps={{
                                        label: Translations.Name,
                                        required: true,
                                    }}
                                    formik={formik}
                                />
                            </Grid2>

                            <Grid2>
                                <TextFieldWrapper
                                    name="description"
                                    fieldProps={{
                                        label: Translations.AdditionalInformation,
                                        className: "field-width-big",
                                    }}
                                    formik={formik}
                                />
                            </Grid2>
                        </Grid2>

                        <Grid2 container direction="row">
                            <Grid2>
                                <AutocompleteWrapper
                                    options={storageItems}
                                    label={Translations.Storage}
                                    required
                                    value={formik.values.storageId}
                                    onChange={(value: AutocompleteOption) => formik.setFieldValue("storageId", value?.id)}
                                    textFieldProps={formikFieldProps(formik, "storageId")}
                                    onAddNew={() => setStorageEditItem(new StorageEditItem())}
                                    onTagClick={(item: AutocompleteItem) => handleEditStorage(item.id)}
                                />
                            </Grid2>

                            <Grid2>
                                <AutocompleteWrapper
                                    options={customerItems}
                                    label={Translations.Customer}
                                    value={formik.values.customerId}
                                    onChange={(value: AutocompleteOption) => formik.setFieldValue("customerId", value?.id)}
                                    textFieldProps={formikFieldProps(formik, "customerId")}
                                />
                            </Grid2>

                            <Grid2>
                                <AutocompleteWrapper
                                    options={billingProductItems}
                                    label={Translations.BillingProduct}
                                    value={formik.values.productId}
                                    onChange={(value: AutocompleteOption) => formik.setFieldValue("productId", value?.id)}
                                    textFieldProps={formikFieldProps(formik, "productId")}
                                    onAddNew={() => {
                                        setProductEditItem(new ProductEditItem());
                                        setProductMainGroup(MainGroupType.ProductLocationInvoicing);
                                        setProductEditItemIndex(null);
                                    }}
                                    onTagClick={(option) => {
                                        fetchProductEditItem(option.id);
                                        setProductMainGroup(MainGroupType.ProductLocationInvoicing);
                                        setProductEditItemIndex(null);
                                    }}
                                />
                            </Grid2>
                        </Grid2>

                        <Divider className="my-3"/>

                        {formik.values.storageProducts.map((product, i) => (
                            <Grid2 container direction="row" key={i}>
                                <Grid2>
                                    <AutocompleteWrapper
                                        options={productItems}
                                        label={Translations.Product}
                                        required
                                        value={product.productId}
                                        onChange={(value: AutocompleteOption) => formik.setFieldValue(`storageProducts[${i}].productId`, value?.id)}
                                        textFieldProps={{
                                            name: `storageProducts[${i}].productId`,
                                            value: product.productId,
                                            onChange: formik.handleChange,
                                            onBlur: formik.handleBlur,
                                            error: Boolean((formik.errors.storageProducts as FormikErrors<StorageLocationProduct>[])?.[i]?.productId),
                                            helperText: formik.touched.storageProducts?.[i]?.productId && (formik.errors.storageProducts as FormikErrors<StorageLocationProduct>[])?.[i]?.productId,
                                            className: "field-width-big",
                                        }}
                                        onAddNew={() => {
                                            setProductEditItem(new ProductEditItem());
                                            setProductMainGroup(MainGroupType.Storage);
                                            setProductEditItemIndex(i);
                                        }}
                                        onTagClick={(option) => {
                                            fetchProductEditItem(option.id);
                                            setProductMainGroup(MainGroupType.Storage);
                                            setProductEditItemIndex(i);
                                        }}
                                    />
                                </Grid2>

                                <Grid2>
                                    <TextField
                                        label={Translations.FreeAmount}
                                        type="number"
                                        required
                                        className="field-width-small"
                                        name={`storageProducts[${i}].freeAmount`}
                                        value={product.freeAmount}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={Boolean((formik.errors.storageProducts as FormikErrors<StorageLocationProduct>[])?.[i]?.freeAmount)}
                                        helperText={formik.touched.storageProducts?.[i]?.freeAmount && (formik.errors.storageProducts as FormikErrors<StorageLocationProduct>[])?.[i]?.freeAmount}
                                        InputProps={{
                                            endAdornment: product ? <InputAdornment position="end">{product.unit}</InputAdornment> : undefined
                                        }}
                                    />
                                </Grid2>

                                <Grid2>
                                    <TextField
                                        label={Translations.BookedAmount}
                                        disabled
                                        variant="standard"
                                        className="field-width-small"
                                        value={`${product.bookedAmount} ${product.unit ?? ""}`}
                                    />
                                </Grid2>

                                <Grid2>
                                    <IconButton className="mt-1" onClick={() => removeProduct(i, product.id)}>
                                        <DeleteIcon color="error"/>
                                    </IconButton>
                                </Grid2>
                            </Grid2>
                        ))}

                        <Grid2 mt={1}>
                            <Button color="primary" startIcon={<AddIcon/>} onClick={addProduct}>
                                {Translations.AddProduct}
                            </Button>
                        </Grid2>
                    </Grid2>
                }
            />

            {productEditItem &&
                <StorageProductDialog
                    item={productEditItem}
                    open={Boolean(productEditItem)}
                    productMainGroup={productMainGroup}
                    onSave={(item) => handleSaveProduct(item)}
                    onClose={() => setProductEditItem(null)}
                />
            }

            {storageEditItem &&
                <StorageDialog
                    item={storageEditItem}
                    hasStorageLocations={storageEditItem?.hasStorageLocations}
                    open={true}
                    onSave={handleSaveStorage}
                    onClose={() => setStorageEditItem(null)}
                    onRemove={() => handleRemoveStorage(storageEditItem)}
                />
            }
        </>
    );
};
