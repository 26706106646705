export interface IAcknowledgementEditItem {
    id: string;
    customerId: string;
    contactId: string;
    approvalDate: number;
    workOrderNumber: number;
    workOrderDescription: string;
    customerNumber: number;
    customerName: string;
    contactNumber: number;
    contactName: string;
    contactEmail: string;
    pdfFileName: string;
    pdfFilePath: string;
}

export class AcknowledgementEditItem implements IAcknowledgementEditItem {
    id: string;
    customerId: string;
    contactId: string;
    approvalDate: number;
    workOrderNumber: number;
    workOrderDescription: string;
    customerNumber: number;
    customerName: string;
    contactNumber: number;
    contactName: string;
    contactEmail: string;
    pdfFileName: string;
    pdfFilePath: string;

    constructor();
    constructor(obj: IAcknowledgementEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.customerId = obj && obj.customerId || "";
        this.contactId = obj && obj.contactId || "";
        this.approvalDate = obj && obj.approvalDate || 0;
        this.workOrderNumber = obj && obj.workOrderNumber || 0;
        this.workOrderDescription = obj && obj.workOrderDescription || "";
        this.customerNumber = obj && obj.customerNumber || 0;
        this.customerName = obj && obj.customerName || "";
        this.contactNumber = obj && obj.contactNumber || 0;
        this.contactName = obj && obj.contactName || "";
        this.contactEmail = obj && obj.contactEmail || "";
        this.pdfFileName = obj && obj.pdfFileName || "";
        this.pdfFilePath = obj && obj.pdfFilePath || "";
    }
}