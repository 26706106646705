import { IWorkOrderEditContact, WorkOrderEditContact } from "./workOrderEditContact";

export interface IWorkOrderEditContacts {
    items: IWorkOrderEditContact[];
}

export class WorkOrderEditContacts implements IWorkOrderEditContacts {
    items: WorkOrderEditContact[];

    constructor();
    constructor(obj: IWorkOrderEditContacts);
    constructor(obj?: any) {
        this.items = [];
        if (obj && obj.items) {
            for (let i = 0; i < obj.items.length; i++) {
                this.items.push(new WorkOrderEditContact(obj.items[i]));
            }
        }
    }
}