// WorkMainOnMapWorkList
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "../../framework/base";
import { WorkMainPortlet } from "./workMainPortlet";
import { IEmployeeParameters } from "../../models/employee/employeeIParameters";
import { employeeParameterWorkOrderCalendarWorkListExpanded } from "../../models/common/consts";
import { Translations } from "../../models/translations";
import { WorkOrderStateMultiSelect } from "./workOrderDialogState";
import { INumberTitle } from "../../models/common/numberTitle";
import { RadioButton } from "../framework/radio";
import { ToolButton } from "../framework/toolButton";
import { WorkOrderClassMultiSelect } from "./workOrderClassSelect";
import { CheckBox } from "../framework/checkbox";

// WorkMainWorkListFilter
// ***********************************************************************************************************************
export interface IWorkMainWorkListFilterProp {
    classes?: string;
    employeeParameters: IEmployeeParameters;
    workOrderStates: INumberTitle[];
    workOrderClasses: INumberTitle[];
    listCode: string;
    title: string;
    filter: string;
    filterTime: number;
    filterStates: number[];
    filterClasses: number[];
    filterUsingFavorites: boolean;
    filterShowFiltered: boolean;
    onChangeExpanded: (listExpandedKey: string, expanded: boolean) => void;
    onToggleFilterState: (state: number) => void;
    onToggleFilterClass: (workOrderClass: number) => void;
    onChangeFilterTime: (filterTime: number) => void;
    onChangeFilter: (filter: string) => void;
    onFilterUsingFavorites: (value: boolean) => void;
    onToggleShowFiltered: (value: boolean) => void;
    onClearFilters: () => void;
}

export interface IWorkMainWorkListFilterState {
    isDragged: boolean;
    isInitiallyExpanded: boolean;
}

export class WorkMainWorkListFilter extends React.Component<IWorkMainWorkListFilterProp, IWorkMainWorkListFilterState> {
    static getListExpandedKey = (props: IWorkMainWorkListFilterProp): string => {
        return employeeParameterWorkOrderCalendarWorkListExpanded + props.listCode;
    };

    constructor(props: IWorkMainWorkListFilterProp) {
        super(props);
        const expanded = props.employeeParameters
            ? props.employeeParameters[WorkMainWorkListFilter.getListExpandedKey(props)]
            : null;
        this.state = { isDragged: false, isInitiallyExpanded: !Base.isNullOrUndefined(expanded) ? expanded === "1" : null };
    }

    componentDidUpdate(prevProps: IWorkMainWorkListFilterProp, prevState: IWorkMainWorkListFilterState): void {
        if (!Base.isNullOrUndefined(prevState.isInitiallyExpanded)) return;
        const props = this.props;
        const expanded = props.employeeParameters
            ? props.employeeParameters[WorkMainWorkListFilter.getListExpandedKey(props)]
            : null;
        const isInitiallyExpanded = !Base.isNullOrUndefined(expanded) ? expanded === "1" : null;
        if (prevState.isInitiallyExpanded === isInitiallyExpanded) return;
        this.setState({
            isInitiallyExpanded: isInitiallyExpanded
        });
    }

    handleKeyPress = (e) => {
        const props = this.props;
        if (e.key === "Enter") {
            props.onChangeFilter(props.filter);
        }
    };

    handleChange = (event) => {
        const props = this.props;
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "filter") {
            props.onChangeFilter(value);
        }
    };

    handleClearFilters = (event: React.MouseEvent) => {
        event.preventDefault();
        this.props.onClearFilters();
    };

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <WorkMainPortlet
                classes={"pWorkList" + (props.classes ? " " + props.classes : "")}
                expanded={state.isInitiallyExpanded}
                title={props.title}
                content={<div className="pContent filterList">
                    <div className="filterPanel">
                        <div className="filterLine">
                            <label className="control-label smallFont">{Translations.State}</label>
                            <WorkOrderStateMultiSelect
                                classes={"small"}
                                states={props.filterStates}
                                workOrderStates={props.workOrderStates}
                                onToggleState={props.onToggleFilterState}
                            />
                        </div>
                        <div className="filterLine">
                            <label className="control-label smallFont">{Translations.WorkOrderClass}</label>
                            <WorkOrderClassMultiSelect
                                classes={"small"}
                                selectedWorkOrderClasses={props.filterClasses}
                                workOrderClasses={props.workOrderClasses}
                                onToggleClass={props.onToggleFilterClass}
                            />
                        </div>
                        <div className="filterLine">
                            <label className="control-label smallFont">{Translations.SearchCondition}</label>
                            <div className={props.filter ? "input-group" : ""}>
                                <input type="text" className="form-control" name="filter" title={Translations.SearchCondition} value={props.filter} maxLength={100} onChange={this.handleChange} onKeyPress={this.handleKeyPress}/>
                                {!!props.filter &&
                                    <span className="input-group-append">
                                        <ToolButton
                                            removeDefaultClasses={true}
                                            title={Translations.Clear}
                                            classes="btn withIcon xCross gray"
                                            enabled={true}
                                            onClick={() => props.onChangeFilter("")}
                                        />
                                    </span>
                                }
                            </div>
                        </div>
                        <div className="filterLine">
                            <label className="control-label smallFont">{Translations.TimeFilter}</label>
                            <div className="btn-group btn-group-toggle states timeFilter">
                                <RadioButton
                                    key={0}
                                    classes={"btn-secondary"}
                                    enabled={true}
                                    title={Translations.SelectedPeriod}
                                    checked={props.filterTime < 0.5}
                                    onRadioClick={() => props.onChangeFilterTime(0)}
                                />
                                <RadioButton
                                    key={1}
                                    classes={"btn-secondary"}
                                    enabled={true}
                                    title={Translations.SelectedDay}
                                    checked={props.filterTime > 0.5}
                                    onRadioClick={() => props.onChangeFilterTime(1)}
                                />
                            </div>
                        </div>
                        <div className="filterLine withMargin">
                            <CheckBox
                                enabled={true}
                                title={Translations.MyWorkList}
                                checked={props.filterUsingFavorites}
                                onCheckboxClickBoolean={props.onFilterUsingFavorites}
                            />
                        </div>
                        <div className="filterLine withMargin">
                            <CheckBox
                                enabled={true}
                                title={Translations.ShowFilteredWorkOrders}
                                checked={props.filterShowFiltered}
                                onCheckboxClickBoolean={props.onToggleShowFiltered}
                            />
                        </div>
                        <div className="filterLine link">
                            <a className="readOnlyLink" onClick={this.handleClearFilters}>{Translations.ClearFilters}</a>
                        </div>
                    </div>
                </div>}
                replaceContent={true}
                onChangeExpanded={(expanded: boolean) => { props.onChangeExpanded(WorkMainWorkListFilter.getListExpandedKey(props), expanded); }}
            />
        );
    }
}