export interface IToolItem {
    id: string;
    number: number;
    name: string;
    description: string;
    amount: number;
    activeState: number;
}

export class ToolItem implements IToolItem {
    id: string;
    number: number;
    name: string;
    description: string;
    amount: number;
    activeState: number;

    constructor();
    constructor(obj: IToolItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.name = obj && obj.name || "";
        this.description = obj && obj.description || "";
        this.amount = obj && obj.amount || 0;
        this.activeState = obj && obj.activeState || 0;
    }
}