// WorkOrderEditRouteEditorWorkShiftTimeSlotDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "../../framework/base";
import { Translations } from "../../models/translations";
import { IdTitle } from "../../models/common/idTitle";
import { IWorkShiftTimeSlotType } from "../../models/workShiftTimeSlotType/workShiftTimeSlotType";
import { IRoutePointWorkShiftTimeSlotItem } from "../../models/routePoint/routePointWorkShiftTimeSlotItem";
import { PropertyDialog } from "../framework/dialog";
import { DatePicker } from "../framework/datePicker";
import { AppUtils } from "../../models/common/appUtils";
import { SaveData } from "../../framework/saveData";
import { IEmployeeItem } from "../../models/employee/employeeItem";
import { TimeSelector } from "../framework/timeSelector";
import { ConfirmationDialogResult } from "../../models/common/enums";

// WorkOrderEditRouteTimelineRoutePointDialogWorkShiftTimeSlotsListItem
export interface IWorkOrderEditRouteTimelineRoutePointDialogWorkShiftTimeSlotsListItem {
    id: string;
    workShiftTimeSlotTypeId: string;
    employeeId: string;
    employeeName: string;
    routePointId: string;
    vehicleId: string;
    title: string;
    startTime: number;
    endTime: number;
    description: string;
    durationMin: number;
    rowId: string;
    category: number;
    hasOverlap: boolean;
}

export class WorkOrderEditRouteTimelineRoutePointDialogWorkShiftTimeSlotsListItem implements IWorkOrderEditRouteTimelineRoutePointDialogWorkShiftTimeSlotsListItem {
    id: string;
    workShiftTimeSlotTypeId: string;
    employeeId: string;
    employeeName: string;
    routePointId: string;
    vehicleId: string;
    title: string;
    startTime: number;
    endTime: number;
    description: string;
    durationMin: number;
    rowId: string;
    sortValue: number;
    category: number;
    hasOverlap: boolean;

    constructor();
    constructor(obj: IRoutePointWorkShiftTimeSlotItem);
    constructor(obj: IWorkOrderEditRouteTimelineRoutePointDialogWorkShiftTimeSlotsListItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.workShiftTimeSlotTypeId = obj && obj.workShiftTimeSlotTypeId || "";
        this.employeeId = obj && obj.employeeId || "";
        this.employeeName = obj && obj.employeeName || "";
        this.routePointId = obj && obj.routePointId || "";
        this.vehicleId = obj && obj.vehicleId || "";
        this.title = obj && obj.title || "";
        this.startTime = obj && obj.startTime || 0;
        this.endTime = obj && obj.endTime || 0;
        this.vehicleId = obj && obj.vehicleId || "";
        this.description = obj && obj.description || 0;
        this.rowId = obj && obj.rowId || "";
        this.category = obj && obj.category || 0;
        this.hasOverlap = obj && obj.hasOverlap || false;
    }

    static sortWorkTimeListItems(items: IWorkOrderEditRouteTimelineRoutePointDialogWorkShiftTimeSlotsListItem[]) {
        if (!items || items.length < 2) return;
        items.sort((a: IWorkOrderEditRouteTimelineRoutePointDialogWorkShiftTimeSlotsListItem, b: IWorkOrderEditRouteTimelineRoutePointDialogWorkShiftTimeSlotsListItem) => {
            if (!a || !b) {
                return 0;
            }
            const result = Base.numberCompare(a.startTime, b.startTime);
            if (result !== 0) return result;
            return Base.numberCompare(a.category, b.category);
        });
    }
}

// WorkOrderEditRouteEditorWorkShiftTimeSlotDialog
export interface IWorkOrderEditRouteEditorWorkShiftTimeSlotDialogProp {
    isReadOnly: boolean;
    workShiftTimeSlotTypes: IWorkShiftTimeSlotType[];
    employees: IEmployeeItem[];
    workShiftTimeSlot: IWorkOrderEditRouteTimelineRoutePointDialogWorkShiftTimeSlotsListItem;
    onOk: (workShiftTimeSlot: IWorkOrderEditRouteTimelineRoutePointDialogWorkShiftTimeSlotsListItem) => void;
    onCancel: () => void;
}

interface IWorkOrderEditRouteEditorWorkShiftTimeSlotDialogState {
    workShiftTimeSlotTypeId: string;
    employeeId: string;
    startDateStr: string;
    startTimeStr: string;
    endDateStr: string;
    endTimeStr: string;
    duration: number;
}

export class WorkOrderEditRouteEditorWorkShiftTimeSlotDialog extends React.Component<IWorkOrderEditRouteEditorWorkShiftTimeSlotDialogProp, IWorkOrderEditRouteEditorWorkShiftTimeSlotDialogState> {
    private orgStateHash: string = "";

    private static getHashDataFromState = (state: IWorkOrderEditRouteEditorWorkShiftTimeSlotDialogState): string => {
        const data = new SaveData();
        data.append("workShiftTimeSlotTypeId", state.workShiftTimeSlotTypeId);
        data.append("employeeId", state.employeeId);
        data.append("startDateStr", state.startDateStr ? state.startDateStr : String(null));
        data.append("startTimeStr", state.startTimeStr ? state.startTimeStr : String(null));
        data.append("endDateStr", state.endDateStr ? state.endDateStr : String(null));
        data.append("endTimeStr", state.endTimeStr ? state.endTimeStr : String(null));
        return data.hash;
    };

    constructor(props: IWorkOrderEditRouteEditorWorkShiftTimeSlotDialogProp) {
        super(props);
        const workShiftTimeSlot = props.workShiftTimeSlot;
        this.state = {
            workShiftTimeSlotTypeId: workShiftTimeSlot.workShiftTimeSlotTypeId,
            employeeId: workShiftTimeSlot.employeeId,
            startDateStr: Base.timeToDateStr(workShiftTimeSlot.startTime),
            startTimeStr: Base.timeToTimeStr(workShiftTimeSlot.startTime),
            endDateStr: Base.timeToDateStr(workShiftTimeSlot.endTime),
            endTimeStr: Base.timeToTimeStr(workShiftTimeSlot.endTime),
            duration: workShiftTimeSlot.endTime ? Math.max(0, Base.dateDiffInMinutes(workShiftTimeSlot.startTime, workShiftTimeSlot.endTime)) : 0,
        };
        this.orgStateHash = WorkOrderEditRouteEditorWorkShiftTimeSlotDialog.getHashDataFromState(this.state);
    }

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "workShiftTimeSlotTypeId") {
            this.setState({ workShiftTimeSlotTypeId: value });
        } else if (name === "employeeId") {
            this.setState({ employeeId: value });
        }
    };

    startDateTimeChange = (startDateTime: Date, endDateTime: Date, duration: number) => {
        if (!endDateTime) {
            endDateTime = startDateTime;
        } else if (endDateTime < startDateTime) {
            if (startDateTime) {
                endDateTime = startDateTime.addMinutes(duration);
            }
        }
        this.setState({ startDateStr: Base.dateToDateStr(startDateTime), startTimeStr: Base.dateToTimeStr(startDateTime), endDateStr: Base.dateToDateStr(endDateTime), endTimeStr: Base.dateToTimeStr(endDateTime), duration: Math.max(0, Base.dateDiffInMinutes(startDateTime, endDateTime)) });
    };

    handleStartDateChange = (value: string) => {
        this.setState({ startDateStr: value });
    };

    handleStartDateBlur = (value: string) => {
        const state = this.state;
        this.startDateTimeChange(AppUtils.getDateTime(value, state.startTimeStr, false), AppUtils.getDateTime(state.endDateStr, state.endTimeStr, false), state.duration);
    };

    handleStartTimeChange = (value: string) => {
        const state = this.state;
        this.startDateTimeChange(AppUtils.getDateTime(state.startDateStr, value, false), AppUtils.getDateTime(state.endDateStr, state.endTimeStr, false), state.duration);
    };

    handleEndDateChange = (value: string) => {
        this.setState({ endDateStr: value });
    };

    handleEndDateBlur = (value: string) => {
        const state = this.state;
        const endDate = AppUtils.getDateTime(value, state.endTimeStr, false);
        this.setState({ endDateStr: endDate ? Base.dateToDateStr(endDate) : "" });
    };

    handleEndTimeChange = (value: string) => {
        this.setState({ endTimeStr: value });
    };

    static getWorkShiftTimeSlotValidationError = (startTime: number, endTime: number): string => {
        if (!startTime) {
            return Translations.StartDateMustBeDefined;
        }
        if (!!endTime && endTime < startTime) {
            return Translations.InvalidEndTime;
        }
        return "";
    };

    private checkErrors = (): Promise<boolean> => {
        const state = this.state;
        return new Promise<boolean>((resolve) => {
            const validationError = WorkOrderEditRouteEditorWorkShiftTimeSlotDialog.getWorkShiftTimeSlotValidationError(
                AppUtils.getDateTime(state.startDateStr, state.startTimeStr, false)?.getTime(),
                AppUtils.getDateTime(state.endDateStr, state.endTimeStr, false)?.getTime());
            if (validationError) {
                AppUtils.showErrorMessage(validationError);
                return resolve(false);
            }
            return resolve(true);
        });
    };

    private getWarningMessage = (): Promise<string> => {
        return new Promise<string>((resolve) => {
            //Add warning checking in here
            return resolve("");
        });
    };

    handleOkClick = async() => {
        const props = this.props;
        const state = this.state;
        if (!await AppUtils.validate(this.checkErrors, this.getWarningMessage)) return;
        const workShiftTimeSlot = new WorkOrderEditRouteTimelineRoutePointDialogWorkShiftTimeSlotsListItem(props.workShiftTimeSlot);
        const workShiftTimeSlotType = props.workShiftTimeSlotTypes.find(i => i.id === state.workShiftTimeSlotTypeId);
        const startTime = AppUtils.getDateTime(state.startDateStr, state.startTimeStr, false);
        const endDateTime = AppUtils.getDateTime(state.endDateStr, state.endTimeStr, false);
        workShiftTimeSlot.workShiftTimeSlotTypeId = workShiftTimeSlotType.id;
        workShiftTimeSlot.title = workShiftTimeSlotType.getTitle();
        workShiftTimeSlot.employeeId = state.employeeId;
        workShiftTimeSlot.employeeName = IdTitle.getTitleById(props.employees, props.workShiftTimeSlot.employeeId);
        workShiftTimeSlot.startTime = startTime.getTime();
        workShiftTimeSlot.endTime = endDateTime ? endDateTime.getTime() : 0;
        workShiftTimeSlot.durationMin = Base.dateDiffInMinutes(startTime, endDateTime ?? new Date());
        props.onOk(workShiftTimeSlot);
    };

    handleCancelClick = async() => {
        const props = this.props;
        const cancelResult = await AppUtils.cancel(WorkOrderEditRouteEditorWorkShiftTimeSlotDialog.getHashDataFromState(this.state), this.orgStateHash);
        if (cancelResult === ConfirmationDialogResult.Cancel) return;
        if (cancelResult === ConfirmationDialogResult.Yes) {
            this.handleOkClick();
        } else {
            props.onCancel();
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <PropertyDialog
                classes="workOrderEditRouteTimelineWorkShiftTimeSlot px400"
                title={Translations.RoutePointBooking}
                show={true}
                body={<div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group required">
                                <label className="control-label smallFont">{Translations.State}</label>
                                <select className="form-control" name="workShiftTimeSlotTypeId" title={Translations.State} value={state.workShiftTimeSlotTypeId} onChange={this.handleChange} disabled={props.isReadOnly}>
                                    {props.workShiftTimeSlotTypes.map((workShiftTimeSlotType) =>
                                        <option key={workShiftTimeSlotType.id} value={workShiftTimeSlotType.id}>{workShiftTimeSlotType.getTitle()}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group required">
                                <label className="control-label smallFont">{Translations.Employee}</label>
                                <select className="form-control" name="employeeId" title={Translations.Employee} value={state.employeeId} onChange={this.handleChange} disabled={props.isReadOnly}>
                                    {props.employees.map((employee) =>
                                        <option key={employee.id} value={employee.id}>{employee.getTitle()}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group required">
                                <label className="control-label smallFont">{Translations.StartTime}</label>
                                <DatePicker
                                    disabled={props.isReadOnly}
                                    titleClasses={"smallFont"}
                                    value={state.startDateStr}
                                    required={true}
                                    onChange={this.handleStartDateChange}
                                    onBlur={this.handleStartDateBlur}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label className="control-label smallFont">&nbsp;</label>
                                <TimeSelector
                                    disabled={props.isReadOnly}
                                    required={true}
                                    date={state.startDateStr}
                                    value={state.startTimeStr}
                                    disabledValue={state.startTimeStr}
                                    onChange={this.handleStartTimeChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.EndTime}</label>
                                <DatePicker
                                    disabled={props.isReadOnly}
                                    titleClasses={"smallFont"}
                                    required={false}
                                    value={state.endDateStr}
                                    onChange={this.handleEndDateChange}
                                    onBlur={this.handleEndDateBlur}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label className="control-label smallFont">&nbsp;</label>
                                <TimeSelector
                                    disabled={props.isReadOnly}
                                    date={state.endDateStr}
                                    required={false}
                                    startDate={state.startDateStr}
                                    startTime={state.startTimeStr}
                                    value={state.endTimeStr}
                                    disabledValue={state.startTimeStr}
                                    onChange={this.handleEndTimeChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>}
                buttons={[
                    { title: Translations.Save, classes: "btn-primary", enabled: !props.isReadOnly, onClick: props.isReadOnly ? null : this.handleOkClick }
                ]}
                onClose={this.handleCancelClick}
            />
        );
    }
}
