// InvoiceDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as invoiceService from "../../services/invoiceService";
import { PropertyDialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { IInvoiceEdit } from "../../models/invoice/invoiceEdit";
import { InvoiceEditItemWorkOrder, IInvoiceEditItemWorkOrder } from "../../models/invoice/invoiceEditItemWorkOrder";
import { InvoiceDialogWorkerOrderList } from "./invoiceDialogWorkerOrderList";
import { SaveData } from "../../framework/saveData";
import { AppUtils } from "../../models/common/appUtils";
import { ConfirmationDialogResult } from "../../models/common/enums";
import { DocumentList } from "../document/documentList";
import downloadjs from "downloadjs";
import * as documentService from "../../services/documentService";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import { IDocument } from "../../models/document/document";

// InvoiceDialog
// ***********************************************************************************************************************
export interface IInvoiceDialogProp {
    classes?: string;
    invoiceEdit: IInvoiceEdit;
    onCancel: () => void;
}

export interface IInvoiceDialogState {
    isReadOnly: boolean;
    workOrderTypeId: string;
    sortColumn: string;
    sortOrderIsAsc: boolean;
    workOrders: IInvoiceEditItemWorkOrder[];
    documents: IDocument[];
}

export class InvoiceDialog extends React.Component<IInvoiceDialogProp, IInvoiceDialogState> {
    private orgStateHash: string = "";

    constructor(props: IInvoiceDialogProp) {
        super(props);
        const invoice = props.invoiceEdit.invoice;
        this.state = {
            isReadOnly: invoice.hasBeenTransferred(),
            workOrderTypeId: invoice.workOrderTypeId,
            sortColumn: "number",
            sortOrderIsAsc: true,
            workOrders: invoice.workOrders.slice(0),
            documents: this.loadDocumentData(),
        };
        const saveData = InvoiceDialog.getSaveDataFromState(props, this.state);
        this.orgStateHash = saveData.hash;
    }

    loadDocumentData = () => {
        const documents = [];
        this.props.invoiceEdit.invoice.workOrders.forEach(wo => {
            wo.documents.forEach(doc => documents.push(doc));
        });
        return documents;
    };

    // #region General
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "workOrderTypeId") {
            this.setState({ workOrderTypeId: value });
        }
    };
    // #endregion General

    // #region WorkOrders
    handleChangeSortColumn = (sortColumn: string) => {
        const oldSortColumn = this.state.sortColumn;
        const sortOrderIsAsc = oldSortColumn === sortColumn ? !this.state.sortOrderIsAsc : true;
        const workOrders = this.state.workOrders.slice(0);
        InvoiceEditItemWorkOrder.sortInvoiceEditItemWorkOrders(workOrders, sortColumn, sortOrderIsAsc);
        this.setState({ workOrders: workOrders, sortColumn: sortColumn, sortOrderIsAsc: sortOrderIsAsc });
    };

    refreshList = async() => {
        const result = await AppUtils.callService(() => invoiceService.getInvoiceEdit(this.props.invoiceEdit.invoice.id), false);
        if (!result) return;
        this.setState({
            workOrders: result.invoice.workOrders.slice(0)
        });
    };

    handleAddWorkOrders = async(ids: string[]) => {
        if (!ids || ids.length < 1) return;
        const newIds = ids.filter(i => this.state.workOrders.findIndex(j => j.id === i) < 0);
        if (newIds.length < 1) return;
        // Call server
        const result = await AppUtils.callService(() => invoiceService.addWorkOrdersToInvoice(this.props.invoiceEdit.invoice.id, newIds));
        if (!result) return;
        await this.refreshList();
    };

    handleRemoveWorkOrder = async(id: string) => {
        if (!id) return;
        const state = this.state;
        const item = state.workOrders.find(i => i.id === id);
        if (Base.isNullOrUndefined(item)) return;
        const confirmationResult = await AppUtils.showConfirmationDialog(String.format(Translations.WorkOrderRemoveFromInvoiceConfirmation, item.number));
        if (confirmationResult !== ConfirmationDialogResult.Yes) return;
        // Call server
        const result = await AppUtils.callService(() => invoiceService.removeWorkOrderFromInvoice(this.props.invoiceEdit.invoice.id, id));
        if (!result) return;
        this.setState({ workOrders: state.workOrders.filter(i => i.id !== id) });
    };
    // #endregion WorkOrders

    // #region Saving
    private checkErrors = (): Promise<boolean> => {
        return new Promise<boolean>((resolve) => {
            //Add error checking in here
            return resolve(true);
        });
    };

    private getWarningMessage = (): Promise<string> => {
        return new Promise<string>((resolve) => {
            //let warnings = "";
            //Add warning checking in here
            //if (!state.someField) {
            //    warnings = warnings + Translations.SomeFieldIsNotDefined + Base.lf;
            //}
            //return resolve(warnings);
            return resolve("");
        });
    };

    private static getSaveDataFromState = (props: IInvoiceDialogProp, state: IInvoiceDialogState): SaveData => {
        const data = new SaveData();
        const invoice = props.invoiceEdit.invoice;
        // Common - Data that does not change
        data.append("id", invoice.id);
        data.append("rowId", invoice.rowId);
        // General - Data that changes
        data.append("workOrderTypeId", state.workOrderTypeId);
        return data;
    };

    saveEditItem = async() => {
        const props = this.props;
        const state = this.state;
        if (!await AppUtils.validate(this.checkErrors, this.getWarningMessage)) return;
        const saveData = InvoiceDialog.getSaveDataFromState(props, state);
        //Call server
        const result = await AppUtils.callService(() => invoiceService.saveInvoiceEdit(saveData.formData));
        if (!result) return;
        props.onCancel();
    };

    handleOkClick = () => {
        this.saveEditItem();
    };

    handleCancelClick = async() => {
        const props = this.props;
        const state = this.state;
        const cancelResult = await AppUtils.cancel(InvoiceDialog.getSaveDataFromState(props, state).hash, this.orgStateHash);
        if (cancelResult === ConfirmationDialogResult.Cancel) return;
        if (cancelResult === ConfirmationDialogResult.Yes) {
            this.saveEditItem();
        } else {
            props.onCancel();
        }
    };
    // #endregion Saving

    handlePrintClick = async() => {
        await AppUtils.callService(() => invoiceService.getInvoicePrint([this.props.invoiceEdit.invoice.id]), false);
    };

    handleDocumentDownload = (id: string) => {
        if (!id) return;
        const document = this.state.documents.find(i => i.id === id);
        if (!document) return;
        if (document.file) {
            downloadjs(document.file, document.reference);
        }
        documentService.getDocument(id).catch(error => {
            store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        const invoice = props.invoiceEdit.invoice;
        const dialogClasses = "settings invoice px1000" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.Invoice + " - " + invoice.number.toString(10)}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-1">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Number}</label>
                                    <div>{invoice.number}</div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Customer}</label>
                                    <div>{invoice.customerName}</div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.CustomerErpReference}</label>
                                    <div>{invoice.customerErpReference}</div>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.State}</label>
                                    <div>{invoice.stateStr}</div>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.InvoiceDataDate}</label>
                                    <div>{Base.timeToDateStr(invoice.invoiceDataDate)}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.InvoiceDataFromWorkOrderType}</label>
                                    {state.isReadOnly &&
                                        <div>{invoice.workOrderTypeName}</div>
                                    }
                                    {!state.isReadOnly &&
                                        <select className="custom-select" name="workOrderTypeId" title={Translations.WorkOrderType} value={state.workOrderTypeId} onChange={this.handleChange}>
                                            {props.invoiceEdit.workOrderTypes.map((workOrderType) =>
                                                <option key={workOrderType.id} value={workOrderType.id}>{workOrderType.title}</option>
                                            )}
                                        </select>
                                    }
                                </div>
                            </div>
                        </div>
                        <InvoiceDialogWorkerOrderList
                            isReadOnly={state.isReadOnly}
                            customerId={invoice.customerId}
                            customerName={invoice.customerName}
                            workOrderTypeId={invoice.workOrderTypeId}
                            sortColumn={state.sortColumn}
                            sortOrderIsAsc={state.sortOrderIsAsc}
                            items={state.workOrders}
                            onChangeSortColumn={this.handleChangeSortColumn}
                            onAddWorkOrders={this.handleAddWorkOrders}
                            onRemoveWorkOrder={this.handleRemoveWorkOrder}
                        />
                        {state.documents.length > 0 &&
                            <DocumentList
                                relatedObjectId={""}
                                title={Translations.Documents}
                                titleClass={"control-label smallFont"}
                                selectedId={""}
                                documents={state.documents}
                                isReadOnly={true}
                                canShowCamera={false}
                                smallImage={true}
                                changeDocumentTypeEnabled={false}
                                onDownloadDocument={this.handleDocumentDownload}
                            />
                        }
                    </div>}
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: this.handleOkClick },
                        { title: (Translations.Print), classes: "btn btn-default mr-auto left", enabled: true, onClick: this.handlePrintClick }
                    ]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}
