import { WorkOrderListItem, IWorkOrderListItem } from "./workOrderListItem";
import { IIdTitle, IdTitle } from "../common/idTitle";
import { Base } from "../../framework/base";

export interface IWorkOrderListItems {
    items: IWorkOrderListItem[];
    hasMore: boolean;
    page: number;
    workOrderStates: IIdTitle[];
    workOrderTypes: IIdTitle[];
}

export class WorkOrderListItems implements IWorkOrderListItems {
    items: WorkOrderListItem[];
    hasMore: boolean;
    page: number;
    workOrderStates: IdTitle[];
    workOrderTypes: IdTitle[];

    constructor();
    constructor(obj: IWorkOrderListItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = Base.getTypedArray<WorkOrderListItem>(!Base.isNullOrUndefined(obj) ? obj.items : null, WorkOrderListItem);
        this.workOrderStates = Base.getTypedArray<IdTitle>(!Base.isNullOrUndefined(obj) ? obj.workOrderStates : null, IdTitle);
        this.workOrderTypes = Base.getTypedArray<IdTitle>(!Base.isNullOrUndefined(obj) ? obj.workOrderTypes : null, IdTitle);
    }
}