import React, { useCallback, useEffect, useMemo, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { GridInitialStatePremium } from "@mui/x-data-grid-premium/models/gridStatePremium";
import {
    gridClasses,
    GridColDef,
    GridEventListener,
    GridInitialState,
    GridRenderCellParams,
    GridRowParams,
    GRID_DETAIL_PANEL_TOGGLE_FIELD,
    GRID_TREE_DATA_GROUPING_FIELD,
    useGridApiRef,
    GridGroupingColDefOverride,
    GridRowsProp,
} from "@mui/x-data-grid-premium";

import useEmployeeUiState from "../../../hooks/useEmployeeUiState";
import { Base } from "../../../framework/base";
import { StripedDataGrid } from "../muiDataGrid";
import { Translations } from "../../../models/translations";
import { CustomToolbar } from "./customToolbar";

export interface GridRow {
    id: string;
    hierarchy: string[];
    date: string;
    totals: Record<string, number>;
}

interface DataGridProps {
    noRowsText: string;
    detailsComponent: (props: any) => JSX.Element;
    columns: GridColDef[];
    rows: GridRowsProp;
    groupingColDef: GridGroupingColDefOverride;
    getGroupLabel?: (id: string, depth?: number) => string;
    persistStateKey?: string;
    pinnedColumns?: string[];
    enableColumnSelector?: boolean;
}

interface GroupedDataGridState {
    hideEmptyColumns: boolean;
    hiddenColumns: string[];
}

export const GroupedDataGrid = ({
    noRowsText,
    detailsComponent: DetailsComponent,
    columns,
    rows,
    groupingColDef,
    getGroupLabel,
    persistStateKey,
    pinnedColumns,
    enableColumnSelector = false,
}: DataGridProps) => {
    const {
        value: persistedGridState,
        setValue: setPersistedGridState,
        initialized: gridStateInitialized,
    } = useEmployeeUiState<GridInitialState & GroupedDataGridState>(
        persistStateKey,
        { hideEmptyColumns: true, hiddenColumns: [] }
    );

    useEffect(() => {
        if (gridStateInitialized) {
            if (persistedGridState?.current.hideEmptyColumns !== undefined) {
                setHideEmptyColumns(
                    persistedGridState?.current.hideEmptyColumns
                );
            }

            setHiddenColumns(persistedGridState?.current.hiddenColumns ?? []);
        }
    }, [gridStateInitialized]);

    const [hideEmptyColumns, setHideEmptyColumns] = useState(true);
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

    const apiRef = useGridApiRef();

    const leafMaxDepth = useMemo(
        () => Math.max(...rows.map((r) => r.hierarchy?.length)) - 1,
        [rows]
    );

    const persistGridState = useCallback(() => {
        if (apiRef?.current?.exportState) {
            const currentState = apiRef.current.exportState();
            setPersistedGridState({
                columns: {
                    orderedFields: currentState.columns.orderedFields,
                },
                hideEmptyColumns,
                hiddenColumns,
            });
        }
    }, [apiRef, hideEmptyColumns, hiddenColumns]);

    useEffect(() => {
        persistGridState();
    }, [persistGridState]);

    const emptyColumns = useMemo(
        () =>
            columns
                ?.filter((c) => !rows.some((r) => r.totals[c.field]))
                .map((c) => c.field) ?? [],
        [rows, columns]
    );

    const columnVisibility = useMemo(
        () =>
            Object.fromEntries(
                columns.map((c) => [
                    c.field,
                    hiddenColumns.includes(c.field)
                        ? false
                        : !hideEmptyColumns || !emptyColumns.includes(c.field),
                ])
            ),
        [columns, hideEmptyColumns, emptyColumns, hiddenColumns]
    );

    const columnVisibilityModel = {
        [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
        ...columnVisibility,
    };

    const aggregationModel = useMemo(
        () => Object.fromEntries([...columns.map((c) => [c.field, "sum"])]),
        [columns]
    );

    const initialState: GridInitialStatePremium = {
        aggregation: {
            model: aggregationModel,
        },
        columns: {
            ...persistedGridState?.current?.columns,
            columnVisibilityModel,
        },
    };

    const onRowClick: GridEventListener<"rowClick"> = (params) => {
        const rowNode = apiRef.current.getRowNode(params.id);
        if (!rowNode) return;

        if (rowNode.type === "group") {
            apiRef.current.setRowChildrenExpansion(
                params.id,
                !rowNode.childrenExpanded
            );
        } else if (rowNode.type === "leaf") {
            apiRef.current.toggleDetailPanel(params.id);
        }
    };

    const getDetailPanelContent = useCallback(
        (params: GridRowParams) => {
            return <DetailsComponent row={params.row} />;
        },
        [DetailsComponent]
    );

    if (!gridStateInitialized) return null;

    return (
        <StripedDataGrid
            columnVisibilityModel={columnVisibilityModel}
            onColumnOrderChange={persistGridState}
            initialState={initialState}
            slots={{
                noRowsOverlay: () => (
                    <Alert severity="info" sx={{ p: 2 }}>
                        {noRowsText}
                    </Alert>
                ),
                toolbar: CustomToolbar,
            }}
            slotProps={{
                toolbar: {
                    hideEmptyColumns,
                    setHideEmptyColumns: (val) => setHideEmptyColumns(val),
                    emptyColumns,
                    columns,
                    hiddenColumns,
                    setHiddenColumns: (cols) => setHiddenColumns(cols),
                    enableColumnSelector,
                },
            }}
            apiRef={apiRef}
            onRowClick={onRowClick}
            disableColumnMenu
            rowSelection={false}
            density="compact"
            hideFooter
            columns={columns}
            rows={rows}
            disableColumnResize
            treeData
            getTreeDataPath={(row: GridRow) => row.hierarchy}
            groupingColDef={{
                hideDescendantCount: true,
                valueFormatter: (data) => {
                    // for exports
                    const value = data?.value ?? Translations.Total;
                    return value;
                },
                renderCell: (params: GridRenderCellParams<GridRow>) => (
                    <GroupingCol
                        params={params}
                        getGroupLabel={getGroupLabel}
                        leafDepth={leafMaxDepth}
                    />
                ),
                ...groupingColDef,
            }}
            pinnedColumns={{
                left: [GRID_TREE_DATA_GROUPING_FIELD, ...(pinnedColumns ?? [])],
            }}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={() => "auto"}
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            sx={{
                width: "100%",
                // Hide the "sum" label on headers
                [`& .${gridClasses.aggregationColumnHeaderLabel}`]: {
                    display: "none",
                },
                // disable cell selection style
                ".MuiDataGrid-cell:focus": {
                    outline: "none",
                },
                "& .MuiDataGrid-row:hover": {
                    cursor: "pointer",
                },
                // This is needed to make sticky positioning work for the detail panel
                "& .MuiDataGrid-detailPanel": {
                    overflow: "visible",
                },
                // Needed to display "no rows" overlay correctly
                ".MuiDataGrid-overlayWrapper": {
                    height: "auto !important",
                },
                ".MuiDataGrid-overlayWrapperInner": {
                    height: "auto !important",
                },
            }}
        />
    );
};

interface GroupingColProps {
    params: GridRenderCellParams<GridRow>;
    getGroupLabel: (id: string, depth: number) => string;
    leafDepth?: number;
}

const GroupingCol = (props: GroupingColProps) => {
    const { params, getGroupLabel, leafDepth = 1 } = props;
    const { value } = params;

    if (params?.rowNode?.type === "group") {
        const open = params.rowNode?.childrenExpanded ?? false;
        const depth = params?.rowNode?.depth;
        const marginLeft = depth * 1;
        return (
            <div>
                {open ? (
                    <KeyboardArrowDownIcon sx={{ marginLeft }} />
                ) : (
                    <KeyboardArrowRightIcon sx={{ marginLeft }} />
                )}
                {getGroupLabel ? getGroupLabel(value, depth) : ""}
            </div>
        );
    } else if (params?.rowNode?.type === "leaf") {
        return (
            <Box ml={leafDepth * 2}>
                {Base.dayjsToDateStrWithWeekday(params?.row?.date)}
            </Box>
        );
    } else if (params?.rowNode?.type === "pinnedRow") {
        return <div className="font-weight-bold">{Translations.Total}</div>;
    }
};
