import { ApiSuccess, SaveSuccess, SaveSuccessItems } from "./baseService";
import { getApiCall, postFormApiCall, postApiCall, getFileApiCall, makeApiCallWithAny, ApiCall } from "./tokenService";
import { WorkOrderMain } from "../models/work/workOrderMain";
import { WorkOrderItems } from "../models/work/workOrderItems";
import { WorkOrderEdit } from "../models/work/workOrderEdit";
import { WorkOrderEditCustomerData } from "../models/work/workOrderEditCustomerData";
import { WorkOrderListItems } from "../models/work/workOrderListItems";
import { Base } from "../models/../framework/base";
import { WorkOrderListMain } from "../models/work/workOrderListMain";
import { CalendarAccuracyType } from "../models/common/enums";
import { WorkOrderPageMain } from "../models/work/workOrderPageMain";
import { WorkOrderInvoiceDataListItems } from "../models/work/workOrderInvoiceDataListItems";
import { IStringDictionary } from "../models/common/stringDictionary";
import { RoutePointItem } from "../models/routePoint/routePointItem";
import { IRoutePointAutocompleteItems, RoutePointAutocompleteItems } from "../models/routePoint/routePointAutocompleteItems";
import { WorkOrderTemplates } from "../models/work/workOrderTemplates";
import { IWorkOrderEditItemWorkOrderTask } from "../models/work/workOrderEditItemWorkOrderTask";
import { AutocompleteItem } from "../components/framework/autocomplete";
import { StorageProductShoppingCartApiItem } from "../models/storage/storageProductShoppingCartItem";
import { StorageProductBookingResponse } from "../models/work/StorageProductBooking/StorageProductBookingResponse";
import { IStorageProductSave } from "../models/work/StorageProductBooking/StorageProductSave";
import { objectToList } from "./Helpers/serviceHelper";
import { AutocompleteOption } from '../components/framework/muiAutocomplete';

export interface ISaveWorkOrderProductBookings {
    message: string;
    ids: string[];
    rowIds: string[];
    documentIds: IStringDictionary;
    documentRowIds: IStringDictionary;
}

export class SaveWorkOrderProductBookings implements ISaveWorkOrderProductBookings {
    message: string;
    ids: string[];
    rowIds: string[];
    documentIds: IStringDictionary;
    documentRowIds: IStringDictionary;

    constructor();
    constructor(obj: ISaveWorkOrderProductBookings);
    constructor(obj?: any) {
        this.message = obj && obj.message || "";
        this.ids = obj && obj.ids || [];
        this.rowIds = obj && obj.rowIds || [];
        this.documentIds = obj && obj.documentIds || {};
        this.documentRowIds = obj && obj.documentRowIds || {};
    }
}

export const getWorkOrderMain = (): Promise<WorkOrderMain> => {
    return getApiCall<WorkOrderMain>("api/workorder/workmain", WorkOrderMain);
};

export const getWorkOrderItems = (startDate: number, endDate: number, calendarAccuracy: CalendarAccuracyType): Promise<WorkOrderItems> => {
    return getApiCall<WorkOrderItems>("api/workorder/?startDate=" + (!Base.isNullOrUndefined(startDate) ? startDate : "") + "&endDate=" + (!Base.isNullOrUndefined(endDate) ? endDate : "") +
        "&calendarAccuracy=" + (calendarAccuracy || CalendarAccuracyType.DayTwoWeeks), WorkOrderItems);
};

export const getWorkOrderEdit = (id: string, sourceId: string, category: number, parentId: string): Promise<WorkOrderEdit> => {
    return getApiCall<WorkOrderEdit>("api/workorder/edit/?id=" + (id || "") + (sourceId ? "&sourceId=" + sourceId : "") +
        (category ? "&category=" + category.toString(10) : "") + (parentId ? "&parentId=" + parentId : ""), WorkOrderEdit);
};

export const getOwnerRoutePoints = (): Promise<IRoutePointAutocompleteItems> => {
    return getApiCall<IRoutePointAutocompleteItems>("api/workorder/routepointidentifierdata", RoutePointAutocompleteItems);
};

export const getRoutePointCopy = (id: string): Promise<RoutePointItem> => {
    return getApiCall<RoutePointItem>("api/workorder/routepointcopy?id=" + id, RoutePointItem);
};

export const copyWorkOrder = (id: string, copyEmployees: boolean, copyVehicles: boolean, copyRoute: boolean, copyDocuments: boolean, copyBookings: boolean, copyProjectTasks: boolean): Promise<WorkOrderEdit> => {
    return getApiCall<WorkOrderEdit>("api/workorder/copy/?id=" + (id || "") + "&copyEmployees=" + copyEmployees.toString() + "&copyVehicles=" + copyVehicles.toString() + "&copyRoute=" + copyRoute.toString() +
        "&copyDocuments=" + copyDocuments.toString() + "&copyBookings=" + copyBookings.toString() + "&copyProjectTasks=" + copyProjectTasks.toString(), WorkOrderEdit);
};

export const getCustomerData = (customerId: string): Promise<WorkOrderEditCustomerData> => {
    return getApiCall<WorkOrderEditCustomerData>("api/workorder/customerdata/?id=" + (customerId || ""), WorkOrderEditCustomerData);
};

export const saveWorkOrderGeneral = (data: FormData): Promise<SaveSuccess> => {
    return postFormApiCall<SaveSuccess>("api/workorder/savegeneral", data, SaveSuccess);
};

export const saveWorkOrderDetails = (data: FormData): Promise<SaveSuccess> => {
    return postFormApiCall<SaveSuccess>("api/workorder/savedetails", data, SaveSuccess);
};

export const setShowCollectionListInMobile = (id: string, value: boolean): Promise<SaveSuccess> => {
    return postApiCall("api/workorder/setshowcollectionlistinmobile/?id=" + (id || "") + "&value=" + value.toString(), SaveSuccess);
};

export const addWorkOrderDocuments = (data: FormData): Promise<SaveSuccessItems> => {
    return postFormApiCall<SaveSuccessItems>("api/workorder/adddocuments", data, SaveSuccessItems);
};

export const addRecurringTasks = (data: FormData): Promise<SaveSuccess> => {
    return postFormApiCall<SaveSuccess>("api/workorder/addrecurringtasks", data, SaveSuccess);
};

export const setValues = (data: FormData): Promise<SaveSuccessItems> => {
    return postFormApiCall<SaveSuccessItems>("api/workorder/setvalues", data, SaveSuccessItems);
};

export const saveWorkOrderDocument = (data: FormData): Promise<ApiSuccess> => {
    return postFormApiCall<ApiSuccess>("api/workorder/savedocument", data, ApiSuccess);
};

export const removeWorkOrderDocuments = (data: FormData): Promise<ApiSuccess> => {
    return postFormApiCall<ApiSuccess>("api/workorder/removedocuments", data, ApiSuccess);
};

export const saveWorkOrderRoutePoints = (data: FormData): Promise<SaveSuccessItems> => {
    return postFormApiCall<SaveSuccessItems>("api/workorder/saveroutepoints", data, SaveSuccessItems);
};

export const removeWorkOrderRoutePoints = (data: FormData): Promise<ApiSuccess> => {
    return postFormApiCall<ApiSuccess>("api/workorder/removeroutepoints", data, ApiSuccess);
};

export const saveRoutePointWorkShiftTimeSlots = (data: FormData): Promise<SaveSuccessItems> => {
    return postFormApiCall<SaveSuccessItems>("api/workorder/saveroutepointworkshifttimeslots", data, SaveSuccessItems);
};

export const removeRoutePointWorkShiftTimeSlots = (data: FormData): Promise<ApiSuccess> => {
    return postFormApiCall<ApiSuccess>("api/workorder/removeroutepointworkshifttimeslots", data, ApiSuccess);
};

export const saveWorkOrderHourBookings = (data: FormData): Promise<SaveSuccessItems> => {
    return postFormApiCall<SaveSuccessItems>("api/workorder/savehourbookings", data, SaveSuccessItems);
};

export const removeWorkOrderHourBookings = (data: FormData): Promise<ApiSuccess> => {
    return postFormApiCall<ApiSuccess>("api/workorder/removehourbookings", data, ApiSuccess);
};

export const saveWorkOrderProductBookings = (data: FormData): Promise<SaveWorkOrderProductBookings> => {
    return postFormApiCall<SaveWorkOrderProductBookings>("api/workorder/saveproductbookings", data, SaveWorkOrderProductBookings);
};

export const removeWorkOrderProductBookings = (data: FormData): Promise<ApiSuccess> => {
    return postFormApiCall<ApiSuccess>("api/workorder/removeproductbookings", data, ApiSuccess);
};

export const saveWorkOrderRideBookings = (data: FormData): Promise<SaveSuccessItems> => {
    return postFormApiCall<SaveSuccessItems>("api/workorder/saveridebookings", data, SaveSuccessItems);
};

export const removeWorkOrderRideBookings = (data: FormData): Promise<ApiSuccess> => {
    return postFormApiCall<ApiSuccess>("api/workorder/removeridebookings", data, ApiSuccess);
};

export const removeWorkOrder = (ids: string[]): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/workorder/remove", ApiSuccess, JSON.stringify({ ids: ids }));
};

export const setWorkOrderState = (ids: string[], state: number): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/workorder/setstate", ApiSuccess, JSON.stringify({ ids: ids, state: state }));
};

export const setWorkOrderStartTime = (id: string, startTime: number): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/workorder/setstarttime/?id=" + (id || "") + "&startTime=" + startTime, ApiSuccess);
};

export const setWorkOrderEmployee = (id: string, addEmployeeId: string, removeEmployeeId: string, startTime: number): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/workorder/setemployee/?id=" + (id || "") + "&addEmployeeId=" + (addEmployeeId || "") + "&removeEmployeeId=" + (removeEmployeeId || "") + "&startTime=" + (startTime || ""), ApiSuccess);
};

export const setWorkOrderVehicle = (id: string, addVehicleId: string, removeVehicleId: string, startTime: number): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/workorder/setvehicle/?id=" + (id || "") + "&addVehicleId=" + (addVehicleId || "") + "&removeVehicleId=" + (removeVehicleId || "") + "&startTime=" + (startTime || ""), ApiSuccess);
};

export const setWorkOrderSortOrder = (taskList: IWorkOrderEditItemWorkOrderTask[]): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/workorder/sortOrder", ApiSuccess, JSON.stringify(taskList));
};

export const moveProjectWorkOrder = (id: string, startTime: number): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/workorder/setprojectstarttime/?id=" + (id || "") + "&startTime=" + startTime, ApiSuccess);
};

export const getWorkOrderPageMain = (): Promise<WorkOrderPageMain> => {
    return getApiCall<WorkOrderPageMain>("api/workorder/workpagemain", WorkOrderPageMain);
};

export const getWorkOrderListMain = (pageSize: number, page: number, filter: string, states: number[], classFilter: number[], sortColumn: string, sortOrderIsAsc: boolean, categories: number[], ignoreIds: string[], workOrderTypeIds: string[]): Promise<WorkOrderListMain> => {
    return postApiCall<WorkOrderListMain>("api/workorder/worklistmain", WorkOrderListMain, JSON.stringify({
        page: page,
        pageSize: pageSize,
        sortColumn: sortColumn,
        sortOrder: sortOrderIsAsc ? "asc" : "desc",
        filter: filter || "",
        states: states || [],
        classFilter: classFilter || [],
        categories: categories || [],
        ignoreIds: ignoreIds || [],
        workOrderTypeIds: workOrderTypeIds || []
    }));
};

export const getWorkOrderListItems = (pageSize: number, page: number, filter: string, states: number[], classFilter: number[], sortColumn: string, sortOrderIsAsc: boolean, categories: number[], ignoreIds: string[], workOrderTypeIds: string[],
    startDate: number = null, endDate: number = null, favorites: boolean = false, customerId: string = null, isOnInvoice: boolean = null): Promise<WorkOrderListItems> => {
        const typeIds: string[] = workOrderTypeIds?.length
            ? workOrderTypeIds.filter(i => i && i !== '' && i !== 'null') // guid should not to be empty string or string with null text
            : []; 
        return postApiCall<WorkOrderListItems>("api/workorder/list", WorkOrderListItems, JSON.stringify({
        page: page,
        pageSize: pageSize,
        sortColumn: sortColumn,
        sortOrder: sortOrderIsAsc ? "asc" : "desc",
        filter: filter || "",
        states: states || [],
        classFilter: classFilter || [],
        categories: categories || [],
        ignoreIds: ignoreIds || [],
        workOrderTypeIds: typeIds,
        startDate: startDate,
        endDate: endDate,
        favorites: favorites || false,
        customerId: customerId,
        isOnInvoice: isOnInvoice
    }));
};

export const getWorkOrderPrint = (id: string): Promise<string> => {
    return getFileApiCall("api/workorder/print?id=" + (id || ""));
};

export const createInvoices = (data: FormData): Promise<ApiSuccess> => {
    return postFormApiCall<ApiSuccess>("api/workorder/createinvoices", data, ApiSuccess);
};

export const convertWorkOrderToProject = (id: string): Promise<SaveSuccess> => {
    return postApiCall<SaveSuccess>("api/workorder/converttoproject?id=" + (id || ""), SaveSuccess);
};

export const attachWorkOrdersToProject = (parentWorkOrderId: string, ids: string[]): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/workorder/attachtoproject", ApiSuccess, JSON.stringify({ parentWorkOrderId: parentWorkOrderId, ids: ids }));
};

export const detachWorkOrderFromProject = (parentWorkOrderId: string, ids: string[], remove: boolean, removeParent: boolean): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/workorder/detachfromproject", ApiSuccess, JSON.stringify({ parentWorkOrderId: parentWorkOrderId, ids: ids, remove: remove, removeParent: removeParent }));
};

export const getWorkOrderInvoiceDataListItems = (pageSize: number, page: number, filter: string, states: number[], sortColumn: string, sortOrderIsAsc: boolean, isOnInvoice: boolean): Promise<WorkOrderInvoiceDataListItems> => {
    return postApiCall<WorkOrderInvoiceDataListItems>("api/workorder/invoicedatalist", WorkOrderInvoiceDataListItems, JSON.stringify({
        page: page,
        pageSize: pageSize,
        sortColumn: sortColumn,
        sortOrder: sortOrderIsAsc ? "asc" : "desc",
        filter: filter || "",
        states: states || [],
        isOnInvoice: isOnInvoice
    }));
};

export const cancelWorkOrder = (id: string): Promise<SaveSuccess> => {
    return postApiCall<SaveSuccess>("api/workorder/cancel?id=" + (id || ""), SaveSuccess);
};

export const restoreWorkOrder = (id: string): Promise<SaveSuccess> => {
    return postApiCall<SaveSuccess>("api/workorder/restore?id=" + (id || ""), SaveSuccess);
};

export const getShoppingCartWorkOrders = (): Promise<AutocompleteOption[]> => {
    return getApiCall("api/workorder/forshoppingcart", null);
};

export const addProductsToWorkOrder = (workOrderId: string, storageProducts: StorageProductShoppingCartApiItem[]): Promise<ApiSuccess> => {
    return postApiCall("api/workorder/addproducts", ApiSuccess, JSON.stringify({ workOrderId, storageProducts }));
};

// #region WorkOrderTemplates
export const getWorkOrderTemplates = (): Promise<WorkOrderTemplates> => {
    return getApiCall<WorkOrderTemplates>("api/workorder/templates", WorkOrderTemplates);
};

export const saveWorkOrderAsTemplate = (data: FormData): Promise<SaveSuccess> => {
    return postFormApiCall<SaveSuccess>("api/workorder/saveastemplate", data, SaveSuccess);
};

export const removeWorkOrderTemplate = (ids: string[]): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/workorder/removetemplate", ApiSuccess, JSON.stringify({ ids: ids }));
};

export const saveStorageBooking = (data: IStorageProductSave): Promise<StorageProductBookingResponse> => {
    return makeApiCallWithAny("api/workorder/savestorageproductbooking", "POST", data, StorageProductBookingResponse);
};

export const removeStorageBookings = (ids: []): Promise<StorageProductBookingResponse> => {
    return ApiCall<StorageProductBookingResponse>("api/workorder/removestorageproductbookings" + objectToList(ids, "guids"), "DELETE", null);
};

// #endregion WorkOrderTemplates
