import { ApiSuccess, SaveSuccess } from "./baseService";
import { getApiCall, postFormApiCall, postApiCall } from "./tokenService";
import { apiCall } from "./apiClient";
import { CustomerItems } from "../models/customer/customerItems";
import { CustomerEdit } from "../models/customer/customerEdit";
import { IdTitleItems } from "../models/common/idTitleItems";
import { ICustomerDetails } from "../models/customer/customerDetails";
import { ICustomerListItem } from "../models/customer/customerListItem";

export const getCustomerItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean, showNotInUse: boolean): Promise<CustomerItems> => {
    return getApiCall<CustomerItems>("api/customer/?page=" + (page || "") + "&pageSize=" + (pageSize || "") +
        "&sortColumn=" + (sortColumn ? encodeURIComponent(sortColumn) : "") + "&sortOrder=" + (sortOrderIsAsc ? "asc" : "desc") +
        "&filter=" + (filter ? encodeURIComponent(filter) : "") + "&showNotInUse=" + (showNotInUse ? "1" : "0"), CustomerItems);
};

export const getCustomerEdit = (id: string): Promise<CustomerEdit> => {
    return getApiCall<CustomerEdit>("api/customer/edit/?id=" + (id || ""), CustomerEdit);
};

export const saveCustomerEdit = (data: FormData): Promise<SaveSuccess> => {
    return postFormApiCall<SaveSuccess>("api/customer/save", data, SaveSuccess);
};

export const removeCustomer = (id: string): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/customer/remove/?id=" + (id || ""), ApiSuccess);
};

export const addCustomerContact = (id: string, contactId: string): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/customer/addcontact/?id=" + (id || "") + "&contactId=" + (contactId || ""), ApiSuccess);
};

export const getCustomerIdTitles = (ignoreIds?: string[]): Promise<IdTitleItems> => {
    return postApiCall<IdTitleItems>("api/customer/idtitle", IdTitleItems, JSON.stringify(ignoreIds));
};

export const getCustomerList = () => {
    return apiCall<ICustomerListItem[]>("customers/", "GET");
};

export const getCustomerDetails = (id: string) => {
    return apiCall<ICustomerDetails>(`customers/${id}/details`, "GET");
};