import { IContactEditItem, ContactEditItem } from "./contactEditItem";

export interface IContactEdit {
    contact: IContactEditItem;
}

export class ContactEdit implements IContactEdit {
    contact: ContactEditItem;

    constructor();
    constructor(obj: IContactEdit);
    constructor(obj?: any) {
        this.contact = null;
        if (obj && obj.contact) {
            this.contact = new ContactEditItem(obj.contact);
        }
    }
}