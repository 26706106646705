import React from "react";
import Alert from "@mui/material/Alert";
import CircleIcon from "@mui/icons-material/Circle";
import { getWorkTimeTypeColorClass } from "../../models/workShitTimeSlot/workTimeType";
import { useTranslation } from "react-i18next";
import { VehicleData } from "./monitorViewMainLayout";
import { MonitorViewSidebar } from "./monitorViewSidebar";
import { MuiSelectableList } from "../framework/muiSelectableList";

interface MonitorViewVehiclesSidebarProps {
    vehicleData: VehicleData[];
    selectedVehicleId: string;
    setSelectedVehicleId: (id: string) => void;
}

export const MonitorViewVehiclesSidebar = ({
    vehicleData,
    selectedVehicleId,
    setSelectedVehicleId,
}: MonitorViewVehiclesSidebarProps) => {
    const { t } = useTranslation();

    return (
        <MonitorViewSidebar
            header={t("vehicle.vehicles")}
            loading={!vehicleData}
        >
            {vehicleData?.length > 0 ? (
                <MuiSelectableList
                    selected={selectedVehicleId}
                    items={vehicleData?.map((v) => ({
                        id: v.vehicleId,
                        icon: (
                            <CircleIcon
                                className={`text-${getWorkTimeTypeColorClass(
                                    v.workTimeTypeType
                                )}`}
                            />
                        ),
                        text: [
                            v.registerNumber,
                            v.employeeName,
                            v.workTimeTypeName,
                        ]
                            .filter(Boolean)
                            .join(" - "),
                        onSelect: setSelectedVehicleId,
                    }))}
                />
            ) : (
                <Alert variant="standard" severity="info">
                    {t("vehicle.noRows")}
                </Alert>
            )}
        </MonitorViewSidebar>
    );
};
