import React, { memo } from "react";
import { TransportOrderInvoiceState, transportOrderInvoiceStates } from "../../../models/transportOrderInvoice/transportOrderInvoice";
import CircleIcon from "@mui/icons-material/Circle";
import { Tooltip } from "@mui/material";

export const InvoiceStateIndicator = memo((props: {state: TransportOrderInvoiceState}) => {
    const state = transportOrderInvoiceStates[props.state];
    return (
        <Tooltip title={state.name} disableInteractive>
            <CircleIcon fontSize="small" htmlColor={state.color} />
        </Tooltip>
    );
});
