import { Base } from "../../framework/base";
import { ISessionStateSsoIntegration, SessionStateSsoIntegration } from "./sessionStateSsoIntegration";
import { ITypedOption, TypedOption } from "../common/typedOption";

export interface ISessionState {
    refreshTime: number;
    id: string;
    userName: string;
    fullName: string;
    culture: string;
    isOwnerAdmin: boolean;
    ownerName: string;
    ownerName2: string;
    ownerLogo: string;
    ownerParameters: ITypedOption[];
    accessToken: string;
    refreshToken: string;
    accessTokenExpireTime: number;
    ssoIntegrations: ISessionStateSsoIntegration[];
}

export class SessionState implements ISessionState {
    refreshTime: number;
    id: string;
    userName: string;
    fullName: string;
    culture: string;
    isOwnerAdmin: boolean;
    ownerName: string;
    ownerName2: string;
    ownerStyle: string;
    ownerLogo: string;
    ownerParameters: TypedOption[];
    accessToken: string;
    refreshToken: string;
    accessTokenExpireTime: number;
    ssoIntegrations: SessionStateSsoIntegration[];

    constructor();
    constructor(obj: ISessionState);
    constructor(obj?: any) {
        this.refreshTime = obj && obj.refreshTime || 0;
        this.id = obj && obj.id || "";
        this.userName = obj && obj.userName || "";
        this.fullName = obj && obj.fullName || "";
        this.culture = obj && obj.culture || "";
        this.isOwnerAdmin = obj && obj.isOwnerAdmin || false;
        this.ownerName = obj && obj.ownerName || "";
        this.ownerName2 = obj && obj.ownerName2 || "";
        this.ownerLogo = obj && obj.ownerLogo || "";
        this.ownerParameters = Base.getTypedArray<TypedOption>(obj ? obj.ownerParameters : null, TypedOption);
        this.accessToken = obj && obj.accessToken || "";
        this.refreshToken = obj && obj.refreshToken || "";
        this.accessTokenExpireTime = obj && obj.accessTokenExpireTime || "";
        this.ssoIntegrations = Base.getTypedArray<SessionStateSsoIntegration>(obj ? obj.ssoIntegrations : null, SessionStateSsoIntegration);
    }
}
