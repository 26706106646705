import AddToCartIcon from "@mui/icons-material/AddCircle";
import { Alert, Button, IconButton, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import React, { useEffect, useMemo, useState } from "react";
import * as store from "../../framework/customStore";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import { StorageProductListItem } from "../../models/storage/storageProductListItem";
import * as StoreActions from "../../models/store/storeActions";
import { Translations } from "../../models/translations";
import { deleteStorage, getSingleProduct, getStorageProductList } from "../../services/storageService";
import { addToStorageShoppingCart, StorageState, updateProductFilters } from "../../store/storageSlice";
import { useDebounce } from "../hooks/useDebounce";
import { MainLayout } from "../layout/mainLayout";
import { ShoppingCart, ShoppingCartButton } from "./components/shoppingCart/shoppingCart";
import { ShoppingCartFreeAmount } from "./components/shoppingCart/shoppingCartFreeAmount";
import { StorageCustomerSelect } from "./components/storageCustomerSelect";
import { StorageSearchField } from "./components/storageSearchField";
import { StorageSelect } from "./components/storageSelect";
import { STORAGE_SEARCH_DELAY } from "./storageUtils";
import { StorageProductDialog } from "./StorageProduct/storageProductDialog";
import { ProductEditItem } from "../../models/storage/storageProduct";
import { showApiError, showConfirm } from "../framework/formUtils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { MainGroupType } from "../settings/ProductGroupCommon";
import { TableDef, TableWrapper } from "../framework/tableWrapper";
import * as storeActions from "../../models/store/storeActions";

export function extraFilterValue(val: string[]): string | undefined {
    if (val.length > 0) {
        return val.join(",");
    }
}

export const StorageProductsMain = () => {
    const [selectedItem, setSelectedItem] = useState<ProductEditItem | null>();
    const [showStorageProductDialog, setShowStorageProductDialog] = useState<boolean>(false);
    const [data, setData] = useState<StorageProductListItem[]>([]);
    const state = useAppSelector(state => state.storage);
    const filters = state.productsTab.filters;
    const dispatch = useAppDispatch();
    const [cartOpen, setCartOpen] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);

    const handleSearch = useDebounce((filters: StorageState["productsTab"]["filters"]) => {
        store.customStore.dispatch(storeActions.fetchStart());
        getStorageProductList({
            filter: filters.searchTerm,
            extraFilterObject: {
                storage: extraFilterValue(filters.selectedStorages),
                customer: extraFilterValue(filters.selectedStorageCustomers)
            }
        }).then(res => {
            setError(null);
            setData(res);
        }).catch((err: string) => {
            setError(err);
        }).finally(() => {
            setLoading(false);
            store.customStore.dispatch(storeActions.fetchEnd());
        });
    }, STORAGE_SEARCH_DELAY);

    const openCreateDialog = () => {
        setSelectedItem(new ProductEditItem());
        setShowStorageProductDialog(true);
    };

    const handleClose = () => {
        setSelectedItem(null);
        setShowStorageProductDialog(false);
        handleSearch(filters);
    };

    const handleRemove = (productId: string) => {
        const submitRemove = () => void deleteStorage(productId)
            .then(() => {
                store.customStore.dispatch(StoreActions.showSuccessMessage(Translations.DeleteWasSuccess));
            })
            .catch(showApiError);

        showConfirm(Translations.AreYouSureWantDeleteStorageProduct, submitRemove);
    };

    const handleShowDetails = (productId: string) => {
        void getSingleProduct(productId).then(data => {
            setSelectedItem(data);
            setShowStorageProductDialog(true);
        });
    };

    useEffect(() => {
        setLoading(true);
        handleSearch(filters);
    }, [filters, handleSearch]);

    const table = useMemo<TableDef<StorageProductListItem>>(() => ({
        columns: [{
            label: Translations.Name,
            accessor: "productName"
        }, {
            label: Translations.ProductGroup,
            accessor: "productGroupCode"
        }, {
            label: Translations.Storage,
            accessor: "storageName"
        }, {
            label: Translations.StorageLocation,
            accessor: "storageLocationName",
        }, {
            label: Translations.Customer,
            accessor: "customerName"
        }, {
            label: Translations.FreeAmount,
            accessor: "freeAmount",
            renderCell(val: number, row) {
                return <ShoppingCartFreeAmount amount={val} unit={row.unit}/>;
            }
        }, {
            accessor: "productId",
            disabledSort: true,
            renderCell: (productId: string) => (
                <>
                    <Button
                        variant="primary" className="rounded" startIcon={<VisibilityIcon/>}
                        onClick={() => handleShowDetails(productId)}
                    >
                        {Translations.ShowDetails}
                    </Button>
                </>
            ),
        }, {
            accessor: "id",
            disabledSort: true,
            renderCell: (id: string) => (
                <>
                    <Button
                        variant="primary" className="rounded" startIcon={<DeleteIcon/>}
                        onClick={() => handleRemove(id)}
                    >
                        {Translations.Remove}
                    </Button>
                </>
            )
        }, {
            disabledSort: true,
            renderCell(_, row) {
                const addToCart = (p: StorageProductListItem) => {
                    dispatch(addToStorageShoppingCart({
                        id: p.id,
                        freeAmount: p.freeAmount,
                        isValid: true,
                        orderAmount: 1,
                        productName: p.productName,
                        storageLocationName: p.storageLocationName,
                        storageName: p.storageName,
                        unit: p.unit,
                    }));
                    store.customStore.dispatch(StoreActions.showSuccessMessage(Translations.ProductAddedToCart));
                    setCartOpen(true);
                };
                return (
                    <IconButton onClick={() => addToCart(row)} color="primary">
                        <AddToCartIcon/>
                    </IconButton>
                );
            },
        }]
    }), []);

    return (
        <MainLayout
            className="storage-products"
            topComponent={<div>
                <Typography variant="h3">{Translations.SearchConditions}</Typography>
                <Grid2 container spacing={2}>
                    <Grid2>
                        <StorageSelect value={filters.selectedStorages}
                            onChange={(val) => dispatch(updateProductFilters({ selectedStorages: val }))}
                        />
                    </Grid2>
                    <Grid2>
                        <StorageCustomerSelect value={filters.selectedStorageCustomers}
                            onChange={(val) => dispatch(updateProductFilters({ selectedStorageCustomers: val }))}
                        />
                    </Grid2>
                    <Grid2>
                        <StorageSearchField value={filters.searchTerm}
                            onChange={(val) => dispatch(updateProductFilters({ searchTerm: val }))}
                        />
                    </Grid2>
                </Grid2>
            </div>}
        >
            <>
                <Grid2 container>
                    <Grid2 xs={cartOpen ? 8 : 12}>
                        <Grid2 direction="row">
                            <Grid2 container justifyContent="space-between" mb={1}>
                                <Typography variant="h3">{Translations.StorageProducts}</Typography>
                                <span>
                                    <ShoppingCartButton onClick={() => setCartOpen(!cartOpen)}/>
                                    <Button
                                        variant="save" startIcon={<AddIcon/>}
                                        onClick={openCreateDialog}
                                        sx={{ marginLeft: 3 }}
                                    >{Translations.CreateNewStorageProduct}
                                    </Button>
                                </span>
                            </Grid2>
                        </Grid2>

                        {error &&
                            <Alert severity="error">{Translations.FetchFailed}</Alert>
                        }
                        {!loading && !error && data.length === 0 &&
                            <Alert severity="info">{Translations.NoMatchesFound}</Alert>
                        }

                        {!error && data.length > 0 &&
                            <TableWrapper tableDef={table} data={data}/>
                        }
                    </Grid2>

                    {cartOpen &&
                        <Grid2 xs={4} pl={4}>
                            <ShoppingCart refresh={() => handleSearch(filters)} onClose={() => setCartOpen(false)}/>
                        </Grid2>
                    }
                </Grid2>

                {showStorageProductDialog &&
                    <StorageProductDialog
                        item={selectedItem}
                        open={showStorageProductDialog}
                        productMainGroup={MainGroupType.Storage}
                        onClose={handleClose}
                    />
                }
            </>
        </MainLayout>
    );
};
