import { Base } from "../../framework/base";
import { INumberTitle, NumberTitle } from "../common/numberTitle";
import { ICalendarDayItem, CalendarDayItem } from "../calendarDay/calendarDayItem";
import { IHourBookingCategoryItem, HourBookingCategoryItem } from "../hourBookingType/hourBookingTypeItem";
import { ISalaryRowTypeItem, SalaryRowTypeItem } from "../salary/salaryRowTypeItem";
import { TimeFormat } from "../common/enums";
import { ISalaryWorkTimePeriod, SalaryWorkTimePeriod } from "../salary/salaryWorkTimePeriod";
import { IEmployeeItem, EmployeeItem } from "../employee/employeeItem";
import { IEmployeeGroupItem, EmployeeGroupItem } from "../employeeGroup/employeeGroupItem";

export interface IWorkTimePageMain {
    workTimeFormat: TimeFormat;
    workTimePeriod: ISalaryWorkTimePeriod;
    salaryPeriods: ISalaryWorkTimePeriod[];
    employeeGroups: IEmployeeGroupItem[];
    employees: IEmployeeItem[];
    calendarDays: ICalendarDayItem[];
    hourBookingTypes: IHourBookingCategoryItem[];
    salaryRowTypes: ISalaryRowTypeItem[];
    dayBookingTypes: INumberTitle[];
    salaryTransferEnabled: boolean;
}

export class WorkTimePageMain implements IWorkTimePageMain {
    workTimeFormat: TimeFormat;
    workTimePeriod: SalaryWorkTimePeriod;
    salaryPeriods: SalaryWorkTimePeriod[];
    employeeGroups: EmployeeGroupItem[];
    employees: EmployeeItem[];
    calendarDays: CalendarDayItem[];
    hourBookingTypes: HourBookingCategoryItem[];
    salaryRowTypes: SalaryRowTypeItem[];
    dayBookingTypes: NumberTitle[];
    salaryTransferEnabled: boolean;

    constructor();
    constructor(obj: IWorkTimePageMain);
    constructor(obj?: any) {
        this.workTimeFormat = obj && obj.workTimeFormat || TimeFormat.DecimalFormat;
        this.workTimePeriod = new SalaryWorkTimePeriod();
        if (obj && obj.workTimePeriod) {
            this.workTimePeriod = new SalaryWorkTimePeriod(obj.workTimePeriod);
        }
        this.salaryPeriods = Base.getTypedArray<SalaryWorkTimePeriod>(obj ? obj.salaryPeriods : null, SalaryWorkTimePeriod);
        this.employeeGroups = Base.getTypedArray<EmployeeGroupItem>(obj ? obj.employeeGroups : null, EmployeeGroupItem);
        this.employees = Base.getTypedArray<EmployeeItem>(obj ? obj.employees : null, EmployeeItem);
        this.calendarDays = Base.getTypedArray<CalendarDayItem>(obj ? obj.calendarDays : null, CalendarDayItem);
        this.hourBookingTypes = Base.getTypedArray<HourBookingCategoryItem>(obj ? obj.hourBookingTypes : null, HourBookingCategoryItem);
        this.salaryRowTypes = Base.getTypedArray<SalaryRowTypeItem>(obj ? obj.salaryRowTypes : null, SalaryRowTypeItem);
        this.dayBookingTypes = Base.getTypedArray<NumberTitle>(obj ? obj.dayBookingTypes : null, NumberTitle);
        this.salaryTransferEnabled = obj && obj.salaryTransferEnabled || false;
    }
}
