// ListBox
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import { IIdTitle } from "../../models/common/idTitle";
import { ToolButton } from "./toolButton";

//ListBox
export interface IListBoxProp {
    autoFocus?: boolean;
    classes?: string;
    disabled?: boolean;
    filter: string;
    items: IIdTitle[];
    size?: number;
    onChange: (item: IIdTitle) => void;
}

export interface IListBoxState {
    filter: string;
    items: IIdTitle[];
    selectedId: string;
}

export class ListBox extends React.Component<IListBoxProp, IListBoxState> {
    private input: HTMLInputElement;

    constructor(props) {
        super(props);
        this.state = { filter: props.filter, items: props.filter ? props.items.filter(i => i.title.toLowerCase().indexOf(props.filter.toLowerCase()) > -1) : props.items.slice(0), selectedId: "" };
    }

    componentDidUpdate(prevProps: IListBoxProp, prevState: IListBoxState): void {
        const props = this.props;
        if (prevProps.items.map(i => i.id).join() === props.items.map(i => i.id).join() && prevProps.filter === props.filter) return;
        this.setState({ filter: props.filter, items: props.filter ? props.items.filter(i => i.title.toLowerCase().indexOf(props.filter.toLowerCase()) > -1) : props.items.slice(0), selectedId: "" });
    }

    handleChange = (event) => {
        const props = this.props;
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "filter") {
            if (value === this.state.filter) return;
            const items = value ? props.items.filter(i => i.title.toLowerCase().indexOf(value.toLowerCase()) > -1) : props.items.slice(0);
            const selectedId = items.length ? items[0].id : "";
            this.setState({
                filter: value,
                items: items,
                selectedId: selectedId
            });
            props.onChange(props.items.find(i => i.id === selectedId));
        } else if (name === "select") {
            this.setState({ selectedId: value });
            props.onChange(props.items.find(i => i.id === value));
        }
    };

    handleClearClick = () => {
        const props = this.props;
        this.input.focus();
        this.setState({
            filter: "",
            items: props.items.slice(0)
        });
    };

    handleLineClick = (id: string) => {
        if (!id) return;
        this.setState({
            selectedId: id
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <div className={"listbox" + (props.classes ? " " + props.classes : "") + (props.disabled ? " disabled" : "")}>
                <div className="input-group">
                    <input name="filter" ref={(input) => { this.input = input; }} type="text" className="form-control" placeholder={Translations.SearchCondition} value={state.filter} onChange={this.handleChange} autoFocus={props.autoFocus} />
                    {!props.disabled && state.filter &&
                        <span className="input-group-append">
                            <ToolButton
                                removeDefaultClasses={true}
                                title={Translations.Clear}
                                classes="btn withIcon xCross gray"
                                enabled={true}
                                onClick={this.handleClearClick}
                            />
                        </span>
                    }
                </div>
                <select name="select" className="form-control" value={state.selectedId} onChange={this.handleChange} size={props.size ?? 10}>
                    {state.items.map(item => (
                        <option key={item.id} className="dropdown-item" value={item.id}>{item.title}</option>
                    ))}
                </select>
            </div>
        );
    }
}