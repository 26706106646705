import { apiCall } from "./apiClient";
import { ParcelDetailsDto as ParcelDetails } from "../models/transport/parcel";

export const getParcelsForOrder = (
    parcelId: string,
    signal?: AbortSignal
) => {
    return apiCall<ParcelDetails[]>(
        `transportOrders/${parcelId}/parcels`,
        "GET",
        null,
        signal
    );
};

export const postParcel = (
    orderId: string,
    parcelData: ParcelDetails,
    signal?: AbortSignal
) => {
    return apiCall<ParcelDetails>(
        `transportOrders/${orderId}/parcels`,
        "POST",
        parcelData,
        signal
    );
};

export const updateParcel = (
    parcelId: string,
    parcelData: ParcelDetails,
    signal?: AbortSignal
) => {
    return apiCall<ParcelDetails>(
        `parcels/${parcelId}`,
        "PUT",
        parcelData,
        signal
    );
};

export const deleteParcel = (
    parcelId: string,
    signal?: AbortSignal
) => {
    return apiCall<ParcelDetails[]>(
        `parcels/${parcelId}`,
        "DELETE",
        null,
        signal
    );
};
