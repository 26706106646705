// SelectWorkOrderTemplateDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as workOrderService from "../../services/workOrderService";
import { Translations } from "../../models/translations";
import { PropertyDialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { IWorkOrderItem } from "../../models/work/workOrderItem";
import { WorkListLineShort } from "./workListLineShort";
import { AppUtils } from "../../models/common/appUtils";
import { ConfirmationDialogResult } from "../../models/common/enums";

// SelectWorkOrderTemplateDialog
export interface ISelectWorkOrderTemplateDialogProp {
    classes?: string;
    workOrderTemplates: IWorkOrderItem[];
    onLoadWorkOrderTemplates: () => void;
    onOk: (workOrderId: string) => void;
    onCancel: () => void;
}

export interface ISelectWorkOrderTemplateDialogState {
    workOrderTemplates: IWorkOrderItem[];
    selectedId: string;
    projectTemplates: boolean;
}

export class SelectWorkOrderTemplateDialog extends React.Component<ISelectWorkOrderTemplateDialogProp, ISelectWorkOrderTemplateDialogState> {
    constructor(props: ISelectWorkOrderTemplateDialogProp) {
        super(props);
        this.state = {
            workOrderTemplates: props.workOrderTemplates.slice(0),
            selectedId: "",
            projectTemplates: props.workOrderTemplates.length > 0 ? props.workOrderTemplates[0].isProject() : false
        };
    }

    handleLineClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleRemoveWorkOrderTemplate = async(workOrderId: string) => {
        if (!workOrderId) return;
        const workOrder = this.props.workOrderTemplates.find(i => i.id === workOrderId);
        if (!workOrder) return;
        const confirmationResult = await AppUtils.showConfirmationDialog(String.format(Translations.WorkOrderTemplateRemoveConfirmation, workOrder.name));
        if (confirmationResult !== ConfirmationDialogResult.Yes) return Base.getPromiseResult(false);
        const result = await AppUtils.callService(() => workOrderService.removeWorkOrderTemplate([workOrderId]));
        if (!result) return;
        this.setState({
            workOrderTemplates: this.state.workOrderTemplates.filter(i => i.id !== workOrderId)
        });
        this.props.onLoadWorkOrderTemplates();
    };

    handleOkClick = () => {
        const state = this.state;
        if (!state.selectedId) return;
        this.props.onOk(state.selectedId);
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "selectWorkOrderTemplate px600" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={state.projectTemplates ? Translations.NewProjectBasedOnWorkOrderTemplate : Translations.NewWorkBasedOnWorkOrderTemplate}
                    show={true}
                    body={<div>
                        <div>
                            <div className="form-group">
                                <label className="control-label">{Translations.SelectWorkOrderTemplate}</label>
                            </div>
                        </div>
                        <div className="listContent">
                            {state.workOrderTemplates.map((workOrderTemplate) =>
                                <WorkListLineShort
                                    key={workOrderTemplate.id}
                                    item={workOrderTemplate}
                                    employeeId={workOrderTemplate.employees.length > 0 ? workOrderTemplate.employees[0].id : ""}
                                    vehicleId={workOrderTemplate.vehicles.length > 0 ? workOrderTemplate.vehicles[0].id : ""}
                                    isSelected={state.selectedId === workOrderTemplate.id}
                                    removeTitle={Translations.Remove}
                                    onClick={this.handleLineClick}
                                    onDoubleClick={null}
                                    onRemoveFromFavorites={this.handleRemoveWorkOrderTemplate}
                                />
                            )}
                            {state.workOrderTemplates.length < 1 &&
                                <div className="alert alert-info" role="alert">{Translations.NoWorkOrderTemplates}</div>
                            }
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.OK, classes: "btn-primary", enabled: !!state.selectedId, onClick: this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}
