import { EnumHelper, WorkOrderCategory } from "../common/enums";

export interface IInvoiceListItemWorkOrder {
    id: string;
    number: number;
    parentNumber: number;
    name: string;
    startTime: number;
    endTime: number;
    category: number;
    state: number;
    stateStr: string;
    customerId: string;
    customerName: string;
    siteName: string;
    siteNumber: string;

    isProject(): boolean;
    isWork(): boolean;
    isTask(): boolean;
}

export class InvoiceListItemWorkOrder implements IInvoiceListItemWorkOrder {
    id: string;
    number: number;
    parentNumber: number;
    name: string;
    startTime: number;
    endTime: number;
    category: number;
    state: number;
    stateStr: string;
    customerId: string;
    customerName: string;
    siteName: string;
    siteNumber: string;

    constructor();
    constructor(obj: IInvoiceListItemWorkOrder);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.parentNumber = obj && obj.parentNumber || 0;
        this.name = obj && obj.name || "";
        this.startTime = obj && obj.startTime || 0;
        this.endTime = obj && obj.endTime || 0;
        this.category = obj && obj.category || 0;
        this.state = obj && obj.state || 0;
        this.stateStr = obj && obj.stateStr || "";
        this.customerId = obj && obj.customerId || 0;
        this.customerName = obj && obj.customerName || 0;
        this.siteName = obj && obj.siteName || "";
        this.siteNumber = obj && obj.siteNumber || "";
    }

    isProject(): boolean {
        return EnumHelper.isEqual(this.category, WorkOrderCategory.Project);
    }

    isWork(): boolean {
        return EnumHelper.isEqual(this.category, WorkOrderCategory.Work);
    }

    isTask(): boolean {
        return EnumHelper.isEqual(this.category, WorkOrderCategory.Task);
    }
}