export class DayBookingSaveData {
    id?: string;
    employeeId?: string;
    rowId?: string;
    dayBookingTypeId: string;
    day: string;
    amount: number;
    dayUsageType?: number;
    comment?: string;
}
