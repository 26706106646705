export interface INamedCallback {
    name: string;
    callback: () => Promise<boolean>;
}

export class NamedCallback implements INamedCallback {
    name: string;
    callback: () => Promise<boolean>;

    constructor();
    constructor(obj: INamedCallback);
    constructor(obj?: any) {
        this.name = obj && obj.name || "";
        this.callback = obj && obj.callback || null;
    }

    static createNamedCallback(name: string, callback: () => Promise<boolean>): NamedCallback {
        const result = new NamedCallback();
        result.name = name;
        result.callback = callback;
        return result;
    }

    static async executeCallbacks(callbacks: (() => Promise<boolean>)[]): Promise<boolean> {
        for (const callback of callbacks) {
            if (!await callback()) {
                return false;
            }
        }
        return true;
    }
}