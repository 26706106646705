import React, { useState } from "react";
import {
    Button,
    TextField,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { apiCall } from "../../../services/apiClient";
import { Translations } from "../../../models/translations";
import { RootState, useAppDispatch, useAppSelector } from "../../../framework/customStore";
import { fetchEnd, fetchStart, showErrorMessage, showSuccessMessage } from "../../../models/store/storeActions";
import { showApiError } from "../../framework/formUtils";
import { fetchTransportOrderInvoice, selectEditEnabled } from "../../../store/transportOrderInvoiceSlice";
import { ICustomerDetails } from "../../../models/customer/customerDetails";
import { InvoiceUpdateDto } from "../../../models/transportOrderInvoice/invoiceUpdateDto";
import { CustomerDetails } from "./customerDetails";
import { TransportOrderInvoiceDetails } from "./TransportOrderInvoiceDetails";

interface ITransportOrderInvoiceEditProps {
    id: string;
    disableEdit?: boolean;
}

export const TransportOrderInvoiceEdit = ({ id, disableEdit }: ITransportOrderInvoiceEditProps) => {
    const invoiceDetails = useAppSelector((state: RootState) => state.transportOrderInvoice.selectedInvoice);
    const [reference, setReference] = useState(invoiceDetails?.reference || "");
    const [message, setMessage] = useState(invoiceDetails?.invoiceMessage || "");
    const [customerDetails, setCustomerDetails] = useState<ICustomerDetails>();
    const dispatch = useAppDispatch();
    const handleReferenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReference(event.target.value);
    };
    const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(event.target.value);
    };
    const updatedInvoiceData: InvoiceUpdateDto = {
        reference: reference,
        invoiceMessage: message,
    };
    const disableButton = invoiceDetails?.invoiceMessage === message && invoiceDetails?.reference === reference;
    // Use Redux state for editEnabled unless overridden by the disableEdit prop.
    const isEditEnabled = useAppSelector(selectEditEnabled);
    const editEnabled = disableEdit ? false : isEditEnabled;

    const handleUpdateInvoice = () => {
        dispatch(fetchStart());
        apiCall<InvoiceUpdateDto>(`Invoices/${id}`, "PUT", updatedInvoiceData)
            .then((response) => {
                if (response.status === 204 || response.status === 200) {
                    dispatch(showSuccessMessage(Translations.InvoiceInformationSaveSuccess));
                    dispatch(fetchTransportOrderInvoice(id) as any);
                } else {
                    dispatch(showErrorMessage(Translations.InvoiceInformationSaveFailed));
                }
            })
            .catch((error) => {
                showApiError(error.message);
            })
            .finally(() => {
                dispatch(fetchEnd());
            });
    };

    const handleCustomerDetailsCall = (customerDetails: ICustomerDetails) => setCustomerDetails(customerDetails);

    return (
        <div style={{ marginLeft: 32, marginRight: 32 }}>
            <Grid2 container py={2} spacing={7}>
                <Grid2 container xs={5} minWidth={500}>
                    <Grid2 xs={3}>
                        <TextField
                            value={invoiceDetails?.invoiceNumber || ""}
                            label={Translations.InvoiceNumber}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            disabled={!editEnabled}
                        />
                    </Grid2>
                    <Grid2 xs={9}>
                        <TextField
                            value={customerDetails?.name || ""}
                            label={Translations.Customer}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid2>
                    <Grid2 xs={12}>
                        <TextField
                            value={reference}
                            onChange={handleReferenceChange}
                            label={Translations.ReferenceInformation}
                            fullWidth
                            inputProps={{
                                readOnly: !editEnabled
                            }}
                            disabled={!editEnabled}
                        />
                    </Grid2>
                </Grid2>

                <Grid2 container xs={5} minWidth={500}>
                    <Grid2 xs={12}>
                        <TextField
                            value={message}
                            onChange={handleMessageChange}
                            label={Translations.Message}
                            fullWidth
                            multiline rows={6}
                            inputProps={{
                                readOnly: !editEnabled
                            }}
                            disabled={!editEnabled}
                        />
                    </Grid2>
                </Grid2>

                {editEnabled &&
                    <Grid2 container xs={2} >
                        <Grid2 xs={12} style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                            <Button
                                variant="contained"
                                onClick={() => void handleUpdateInvoice()}
                                disabled={disableButton}
                            >
                                {Translations.SaveInvoiceInformation}
                            </Button>
                        </Grid2>
                    </Grid2>}
            </Grid2>
            <Grid2 xs={12} pt={0} mb={2}>
                <CustomerDetails
                    customerId={invoiceDetails?.customerId}
                    handleCustomerDetailsCall={handleCustomerDetailsCall}
                />
            </Grid2>
            <TransportOrderInvoiceDetails editEnabled={editEnabled} />
        </div>
    );
};
