// InvoiceDialogWorkerOrderList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import { ToolButton } from "../framework/toolButton";
import { ListHeaderColumn } from "../framework/listHeaderColumn";
import { IInvoiceEditItemWorkOrder } from "../../models/invoice/invoiceEditItemWorkOrder";
import { IWorkOrderListItem } from "../../models/work/workOrderListItem";
import { InvoiceDialogSelectWorkOrderDialog } from "./invoiceDialogSelectWorkOrderDialog";
import { AppUtils } from "../../models/common/appUtils";
import { ConfirmationDialogResult } from "../../models/common/enums";
import { Base } from "../../framework/base";

// InvoiceDialogWorkerOrderListHeader
export interface IInvoiceDialogWorkerOrderListHeaderProp {
    sortColumn: string;
    sortOrderIsAsc: boolean;
    onColumnClick: (column: string) => void;
}

export class InvoiceDialogWorkerOrderListHeader extends React.Component<IInvoiceDialogWorkerOrderListHeaderProp, {}> {
    handleColumnClick = (column: string) => {
        this.props.onColumnClick(column);
    };

    render() {
        const props = this.props;
        return (
            <div className="row title">
                <ListHeaderColumn
                    title={Translations.State}
                    column="state"
                    classes="col-1"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title="#"
                    column="number"
                    classes="col-1"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Customer}
                    column="customerName"
                    classes="col-3"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Site}
                    column="siteName"
                    classes="col-3"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.WorkTitle}
                    column="name"
                    classes="col-3"
                    sortColumn={props.sortColumn}
                    sortOrderIsAsc={props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Time}
                    column="startTime"
                    classes="col-1"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
            </div>
        );
    }
}

export interface IInvoiceDialogWorkerOrderListLineItem {
    id: string;
    number: number;
    parentNumber: number;
    name: string;
    startTime: number;
    category: number;
    state: number;
    stateStr: string;
    customerName: string;
    siteName: string;
    siteNumber: string;

    isTask(): boolean;
    isProject(): boolean;
}

// WorkInvoiceDataMainReadyWorkOrkdersListLine
export interface IInvoiceDialogWorkerOrderListLineProp {
    item: IInvoiceDialogWorkerOrderListLineItem;
    selectedId: string;
    onClick: (id: string) => void;
}

export class InvoiceDialogWorkerOrderListLine extends React.Component<IInvoiceDialogWorkerOrderListLineProp, {}> {
    render() {
        const props = this.props;
        const item = props.item;
        return (
            <div className={"row line state" + item.state.toString(10) + (item.id === props.selectedId ? " selected" : "")} onClick={props.onClick ? () => { props.onClick(item.id); } : null}>
                <div className="col-1 workOrderStateEndContainer" title={item.stateStr}>
                    <div className={"stateEnd active state" + item.state.toString(10)}>
                        <span className={"icon state" + item.state.toString(10)} aria-hidden="true" />
                    </div>
                </div>
                <div className="col-1 number">{item.number.toString(10) + (item.parentNumber ? " (" + item.parentNumber.toString(10) + ")" : "")}</div>
                <div className="col-3">{item.customerName}</div>
                <div className="col-3">{item.siteName + (item.siteNumber ? " (" + item.siteNumber + ")" : "")}</div>
                <div className="col-3">{item.name}</div>
                <div className="col-1">{Base.timeToDateStr(item.startTime)}</div>
            </div>
        );
    }
}

// InvoiceDialogWorkerOrderList
export interface IInvoiceDialogWorkerOrderListProp {
    isReadOnly: boolean;
    sortColumn: string;
    sortOrderIsAsc: boolean;
    customerId: string;
    customerName: string;
    workOrderTypeId: string;
    items: IInvoiceEditItemWorkOrder[];
    onChangeSortColumn: (sortColumn: string) => void;
    onAddWorkOrders: (ids: string[]) => void;
    onRemoveWorkOrder: (id: string) => void;
}

interface IInvoiceDialogWorkerOrderListState {
    selectedId: string;
    showSelectWorkOrderDialog: boolean;
}

export class InvoiceDialogWorkerOrderList extends React.Component<IInvoiceDialogWorkerOrderListProp, IInvoiceDialogWorkerOrderListState> {
    constructor(props) {
        super(props);
        this.state = { selectedId: null, showSelectWorkOrderDialog: false };
    }

    handleRemove = () => {
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        this.props.onRemoveWorkOrder(selectedId);
    };

    handleAdd = () => {
        this.setState({
            showSelectWorkOrderDialog: true,
        });
    };

    handleSelectWorkOrderDialogOk = async(workOrders: IWorkOrderListItem[]) => {
        const props = this.props;
        if (!workOrders || workOrders.length < 1) return;
        if (workOrders.filter(i => i.workOrderTypeId !== props.workOrderTypeId).length > 0) {
            if (await AppUtils.showConfirmationDialog(Translations.InvoiceAddWorkOrdersDifferentWorkOrderTypeConfirmation) !== ConfirmationDialogResult.Yes) {
                return;
            }
        }
        props.onAddWorkOrders(workOrders.map(i => i.id));
        this.setState({
            showSelectWorkOrderDialog: false
        });
    };

    handleSelectWorkOrderDialogCancel = () => {
        this.setState({
            showSelectWorkOrderDialog: false
        });
    };

    handleLineClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <div>
                <div className="commandRow left noBorder">
                    <label className="control-label listTitle">{Translations.WorkOrders}</label>
                    <ToolButton
                        title={Translations.Remove}
                        enabled={!props.isReadOnly && !!state.selectedId}
                        classes={"round right remove"}
                        onClick={this.handleRemove}
                    />
                    <ToolButton
                        title={Translations.Add}
                        enabled={!props.isReadOnly}
                        classes={"round right add"}
                        onClick={this.handleAdd}
                    />
                </div>
                <div>
                    <InvoiceDialogWorkerOrderListHeader
                        sortColumn={props.sortColumn}
                        sortOrderIsAsc={props.sortOrderIsAsc}
                        onColumnClick={props.onChangeSortColumn}
                    />
                </div>
                <div className="listContainer invoiceWorkOrdersContainer">
                    {props.items.length > 0 &&
                        <div className="list striped invoiceWorkOrders">
                            {props.items.map((item) =>
                                <InvoiceDialogWorkerOrderListLine
                                    key={item.id}
                                    item={item}
                                    selectedId={state.selectedId}
                                    onClick={this.handleLineClick}
                                />
                            )}
                        </div>
                    }
                </div>
                {state.showSelectWorkOrderDialog &&
                    <InvoiceDialogSelectWorkOrderDialog
                        multiSelect={true}
                        ignoreWorkOrderIds={props.items.map(i => i.id)}
                        customerId={props.customerId}
                        customerName={props.customerName}
                        workOrderTypeId={props.workOrderTypeId}
                        onOk={this.handleSelectWorkOrderDialogOk}
                        onCancel={this.handleSelectWorkOrderDialogCancel}
                    />
                }
            </div>
        );
    }
}