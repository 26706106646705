import { Base } from "../../framework/base";
import { EnumHelper, WorkShiftTimeSlotState } from "../common/enums";
import { AppUtils } from "../common/appUtils";
import { IIdTitleDescription } from "../common/idTitleDescription";

export interface IWorkTimeListItem {
    id: string;
    employeeId: string;
    workOrderId: string;
    costCenterId: string;
    vehicleId: string;
    type: number;
    typeStr: string;
    state: number;
    stateStr: string;
    effectiveDay: number;
    orgStartTime: number;
    orgEndTime: number;
    startLatitude: number;
    startLongitude: number;
    startLocationName: string;
    startTime: number;
    endTime: number;
    endLatitude: number;
    endLongitude: number;
    endLocationName: string;
    description: string;
    employeeName: number;
    workOrderNumber: number;
    workOrderParentNumber: number;
    workOrderState: number;
    workOrderName: string;
    workOrderDescription: string;
    workOrderStartTime: number;
    workOrderCustomerName: string;
    workOrderSiteName: string;
    workOrderSiteAddress: string;
    costCenterName: string;
    costCenterDescription: string;
    vehicleRegisterNumber: string;
    vehicleBrand: string;
    automatic: boolean;

    edited: boolean;
    uiTitle: string;
    uiTooltip: string;
    setUiTitle(workOrders: IIdTitleDescription[], costCenters: IIdTitleDescription[]);

    getTaskName(): string;
    isWork(): boolean;
    isLocked(): boolean;
    getDurationMin(): number;
    getDurationStr(): string;
}

export class WorkTimeListItem implements IWorkTimeListItem {
    id: string;
    employeeId: string;
    workOrderId: string;
    costCenterId: string;
    vehicleId: string;
    type: number;
    typeStr: string;
    state: number;
    stateStr: string;
    effectiveDay: number;
    orgStartTime: number;
    orgEndTime: number;
    startTime: number;
    startLatitude: number;
    startLongitude: number;
    startLocationName: string;
    endTime: number;
    endLatitude: number;
    endLongitude: number;
    endLocationName: string;
    description: string;
    employeeName: number;
    workOrderNumber: number;
    workOrderState: number;
    workOrderParentNumber: number;
    workOrderName: string;
    workOrderDescription: string;
    workOrderStartTime: number;
    workOrderCustomerName: string;
    workOrderSiteName: string;
    workOrderSiteAddress: string;
    costCenterName: string;
    costCenterDescription: string;
    vehicleRegisterNumber: string;
    vehicleBrand: string;
    automatic: boolean;
    edited: boolean;
    uiTitle: string;
    uiTooltip: string;

    constructor();
    constructor(obj: IWorkTimeListItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.employeeId = obj && obj.employeeId || "";
        this.workOrderId = obj && obj.workOrderId || "";
        this.costCenterId = obj && obj.costCenterId || "";
        this.vehicleId = obj && obj.vehicleId || "";
        this.type = obj && obj.type || 0;
        this.typeStr = obj && obj.typeStr || "";
        this.state = obj && obj.state || 0;
        this.stateStr = obj && obj.stateStr || "";
        this.effectiveDay = obj && obj.effectiveDay || null;
        this.orgStartTime = obj && obj.orgStartTime || null;
        this.orgEndTime = obj && obj.orgEndTime || null;
        this.startTime = obj && obj.startTime || null;
        this.startLatitude = obj && obj.startLatitude || 0;
        this.startLongitude = obj && obj.startLongitude || 0;
        this.startLocationName = obj && obj.startLocationName || "";
        this.endTime = obj && obj.endTime || null;
        this.endLatitude = obj && obj.endLatitude || 0;
        this.endLongitude = obj && obj.endLongitude || 0;
        this.endLocationName = obj && obj.endLocationName || "";
        this.description = obj && obj.description || "";
        this.employeeName = obj && obj.employeeName || "";
        this.workOrderNumber = obj && obj.workOrderNumber || 0;
        this.workOrderParentNumber = obj && obj.workOrderParentNumber || 0;
        this.workOrderState = obj && obj.workOrderState || 0;
        this.workOrderName = obj && obj.workOrderName || "";
        this.workOrderDescription = obj && obj.workOrderDescription || "";
        this.workOrderStartTime = obj && obj.workOrderStartTime || 0;
        this.workOrderCustomerName = obj && obj.workOrderCustomerName || "";
        this.workOrderSiteName = obj && obj.workOrderSiteName || "";
        this.workOrderSiteAddress = obj && obj.workOrderSiteAddress || "";
        this.costCenterName = obj && obj.costCenterName || "";
        this.costCenterDescription = obj && obj.costCenterDescription || "";
        this.vehicleRegisterNumber = obj && obj.vehicleRegisterNumber || "";
        this.vehicleBrand = obj && obj.vehicleBrand || "";
        this.automatic = obj && obj.automatic || false;
        this.edited = obj && obj.edited || false;

        this.uiTitle = obj && obj.uiTitle || "";
        this.uiTooltip = obj && obj.uiTooltip || "";
    }

    isWork(): boolean {
        return !!this.workOrderId || !!this.costCenterId;
    }

    isLocked(): boolean {
        return EnumHelper.isEqual(this.state, WorkShiftTimeSlotState.Locked);
    }

    static getTaskNameByParameters(workOrderId: string, costCenterId: string, workOrderNumber: number, workOrderName: string, costCenterName: string, typeTitle: string): string {
        return workOrderId
            ? workOrderNumber.toString(10) + " " + workOrderName
            : (costCenterId
                ? costCenterName
                : typeTitle);
    }

    getTaskName(): string {
        return WorkTimeListItem.getTaskNameByParameters(this.workOrderId, this.costCenterId, this.workOrderNumber, this.workOrderName, this.costCenterName, this.typeStr);
    }

    setUiTitle(workOrders: IIdTitleDescription[], costCenters: IIdTitleDescription[]) {
        this.uiTitle = this.getTaskName();
        this.uiTooltip = this.uiTitle;
        if (workOrders && this.workOrderId) {
            const workOrder = workOrders.find(i => i.id === this.workOrderId);
            if (!workOrder) return;
            this.uiTitle = workOrder.title;
            this.uiTooltip = workOrder.description;
        } else if (costCenters && this.costCenterId) {
            const costCenter = costCenters.find(i => i.id === this.costCenterId);
            if (!costCenter) return;
            this.uiTitle = costCenter.title;
            this.uiTooltip = costCenter.description;
        }
    }

    getDurationMin(): number {
        if (!this.startTime || !this.endTime) return 0;
        return Base.dateDiffInMinutes(new Date(this.startTime), new Date(this.endTime));
    }

    getDurationStr(): string {
        return AppUtils.getDurationStrByDurationMin(this.getDurationMin());
    }

    static sortWorkTimeListItems(items: IWorkTimeListItem[]) {
        if (!items || items.length < 2) return;
        items.sort((a: IWorkTimeListItem, b: IWorkTimeListItem) => {
            if (!a || !b) {
                return 0;
            }
            return Base.numberCompare(a.startTime, b.startTime);
        });
    }
}
