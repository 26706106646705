import { getApiCall } from "./tokenService";
import { CompetencyTypeItems } from "../models/competencyType/competencyTypeItems";

export const getCompetencyTypeItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean, showNotInUse: boolean): Promise<CompetencyTypeItems> => {
    return getApiCall<CompetencyTypeItems>("api/competencytype/?page=" + (page || "") + "&pageSize=" + (pageSize || "") +
        "&sortColumn=" + (sortColumn ? encodeURIComponent(sortColumn) : "") + "&sortOrder=" + (sortOrderIsAsc ? "asc" : "desc") +
        "&filter=" + (filter ? encodeURIComponent(filter) : "") + "&showNotInUse=" + (showNotInUse ? "1" : "0"), CompetencyTypeItems);
};

//export const getToolEdit = (id: string): Promise<ToolEdit> => {
//    return getApiCall<ToolEdit>("api/competencytype/edit/?id=" + (id || ""), ToolEdit);
//};

//export const saveToolEdit = (data: FormData): Promise<ApiSuccess> => {
//    return postFormApiCall<ApiSuccess>("api/competencytype/save", data, ApiSuccess);
//};

//export const removeTool = (id: string): Promise<ApiSuccess> => {
//    return postApiCall<ApiSuccess>("api/competencytype/remove/?id=" + (id || ""), ApiSuccess);
//};