import React, { useEffect } from "react";
import { Translations } from "../../models/translations";
import { ObjectEventLogList } from "../eventLog/objectEventLogList";
import { useState } from "react";
import { Modal, Box, Typography } from "@mui/material";
import { IObjectEventLogItem } from "../../models/eventLog/objectEventLogItem";
import { AppUtils } from "../../models/common/appUtils";
import { Base } from "../../framework/base";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import { getWorkShiftTimeSlotEventLog } from "../../services/workTimeBetaService";
import { LoadingIndicator } from "../framework/loadingIndicatorNew";

interface IWorkTimeEventLogProps {
  originalStartTime: string;
  originalEndTime: string;
  baseLabel: string;
  id: string;
}

export const WorkTimeEventLog = ({
    originalStartTime,
    originalEndTime,
    baseLabel,
    id,
}: IWorkTimeEventLogProps) => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <div>
            <span
                onClick={handleOpenModal}
                title={Translations.WorkTimeDifferentFromOriginal}
                className="InfoMark"
            >
                {baseLabel}*
            </span>

            <Modal
                className="work-time-modal-container"
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="work-time-modal-box">
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {String.format(
                            Translations.OrginalTimeParameter,
                            AppUtils.getTimeDurationStr(
                                Base.dateStrToDayjsIgnoreTimezone(originalStartTime).valueOf(),
                                Base.dateStrToDayjsIgnoreTimezone(originalEndTime).valueOf(),
                                false,
                                true,
                                false
                            )
                        )}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} component="div">
                        {openModal ? <ModalContent id={id} /> : null}
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
};

const ModalContent = ({ id }: { id: string }) => {
    const [eventLog, setEventLog] = useState<IObjectEventLogItem[]>();
    const [loading, setLoading] = useState(false);

    const getEventLog = async () => {
        setLoading(true);
        try {
            const eventLogs = await getWorkShiftTimeSlotEventLog(id);
            setEventLog(eventLogs);
        } catch (error) {
            store.customStore.dispatch(
                storeActions.showErrorMessage(
                    baseService.getErrorMessageFromError(error)
                )
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getEventLog();
    }, [id]);

    if (loading) return <LoadingIndicator />;

    if (!eventLog) return null;

    return (
        <ObjectEventLogList
            classes={"workOrderSubList"}
            title={Translations.Events}
            items={eventLog}
        />
    );
};