import { IOwnerEditItem, OwnerEditItem } from "./ownerEditItem";

export interface IOwnerEdit {
    owner: IOwnerEditItem;
}

export class OwnerEdit implements IOwnerEdit {
    owner: OwnerEditItem;

    constructor();
    constructor(obj: IOwnerEdit);
    constructor(obj?: any) {
        this.owner = null;
        if (obj && obj.owner) {
            this.owner = new OwnerEditItem(obj.owner);
        }
    }
}
