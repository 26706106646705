import {
    createEntityAdapter,
    createSelector,
    createSlice,
    Dictionary,
    EntityState,
    PayloadAction,
} from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { WorkShiftTimeSlotItems } from "../models/workShitTimeSlot/workShitTimeSlotItems";
import { WorkShiftTimeSlotItem } from "../models/workShitTimeSlot/workShiftTimeSlotItem";
import { Base } from "../framework/base";
import {
    getWorkShiftTimeSlotItems,
    getWorkTimeTypes,
    removeWorkTime,
} from "../services/workTimeBetaService";
import { WorkTimeType } from "../models/workShitTimeSlot/workTimeType";

interface WorkShiftTimeSlotState {
    editId: string;
    selectedId: string;
    workTimeTypes: WorkTimeType[];
}

const initialState: WorkShiftTimeSlotState = {
    editId: null,
    selectedId: null,
    workTimeTypes: null,
};

const sortComparer = (a, b) =>
    Base.dayjsCompare(a.startDate, b.startDate) ||
    Base.dayjsCompare(a.endDate, b.endDate);

const workShiftTimeSlotAdapter = createEntityAdapter<WorkShiftTimeSlotItem>({
    sortComparer,
});

export const workShiftTimeSlotSlice = createSlice({
    name: "workShiftTimeSlot",
    initialState: workShiftTimeSlotAdapter.getInitialState(initialState),
    reducers: {
        setWorkShiftTimeSlots: (
            state,
            action: PayloadAction<WorkShiftTimeSlotItems>
        ) => {
            // setAll instead of upsertMany to avoid clogging the browser with a lot of slots.
            workShiftTimeSlotAdapter.setAll(state, action.payload.items);
        },
        workShiftTimeSlotReceived: (
            state,
            action: PayloadAction<WorkShiftTimeSlotItem>
        ) => {
            if (action.payload)
                workShiftTimeSlotAdapter.upsertOne(state, action.payload);
        },
        removeWorkShiftTimeSlot: (state, action: PayloadAction<string>) => {
            workShiftTimeSlotAdapter.removeOne(state, action.payload);
        },
        setEditId: (state, action: PayloadAction<string>) => {
            state.editId = action.payload;
        },
        toggleSelectedId: (state, action: PayloadAction<string>) => {
            state.selectedId =
                action.payload === state.selectedId ? null : action.payload;
        },
        setWorkTimeTypes: (state, action: PayloadAction<WorkTimeType[]>) => {
            state.workTimeTypes = action.payload;
        },
    },
});

export const {
    setWorkShiftTimeSlots,
    workShiftTimeSlotReceived,
    removeWorkShiftTimeSlot,
    setEditId,
    toggleSelectedId,
    setWorkTimeTypes,
} = workShiftTimeSlotSlice.actions;

export const fetchWorkShiftTimeSlotItems = createAsyncThunk(
    "workShiftTimeSlot/list",
    async(
        {
            startDate,
            endDate,
            employeeIds,
        }: {
            startDate: string;
            endDate: string;
            employeeIds: string | string[];
        },
        thunkApi
    ) => {
        const workShiftTimeSlotItems = await getWorkShiftTimeSlotItems(
            startDate,
            endDate,
            employeeIds,
            thunkApi.signal
        );
        thunkApi.dispatch(setWorkShiftTimeSlots(workShiftTimeSlotItems));
    }
);

export const deleteWorkShiftTimeSlot = createAsyncThunk(
    "workShiftTimeSlot/delete",
    async(id: string, thunkApi) => {
        await removeWorkTime(id);
        thunkApi.dispatch(removeWorkShiftTimeSlot(id));
    }
);

export const fetchWorkTimeTypes = createAsyncThunk(
    "workShiftTimeSlot/workTimeTypes",
    async(_, thunkApi) => {
        const workTimeTypes = await getWorkTimeTypes(thunkApi.signal);
        thunkApi.dispatch(setWorkTimeTypes(workTimeTypes));
    }
);

export const workShiftTimeSlotReducer = workShiftTimeSlotSlice.reducer;

export const workShiftTimeSlotSelector =
    workShiftTimeSlotAdapter.getSelectors();

export const makeSelectWorkShiftTimeSlotsByDateRange = () =>
    createSelector(
        (state: EntityState<WorkShiftTimeSlotItem>) =>
            workShiftTimeSlotSelector.selectAll(state),
        (_, employeeId: string) => employeeId,
        (_, _employeeId, rangeStart: string) => rangeStart,
        (_, _employeeId, _rangeStart, rangeEnd: string) => rangeEnd,
        (
            slots: WorkShiftTimeSlotItem[],
            employeeId: string,
            rangeStart: string,
            rangeEnd: string
        ) =>
            slots.filter(
                (s) =>
                    s.employeeId === employeeId &&
                    Base.isOverlappingDate(
                        Base.dateStrToDayjsIgnoreTimezone(s.startDate),
                        Base.dateStrToDayjsIgnoreTimezone(s.endDate) ||
                            new Date(),
                        rangeStart,
                        rangeEnd
                    )
            )
    );

export const makeSelectWorkShiftTimeSlotsByIds = () =>
    createSelector(
        (state: EntityState<WorkShiftTimeSlotItem> & WorkShiftTimeSlotState) =>
            workShiftTimeSlotSelector.selectEntities(state),
        (_, ids: string[]) => Base.getUniqueStringItems(ids),
        (dict: Dictionary<WorkShiftTimeSlotItem>, ids: string[]) =>
            ids
                ?.map((id) => dict[id])
                .filter(Boolean)
                .sort(sortComparer)
    );
