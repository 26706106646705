import { useMemo } from "react";
import { useAppSelector } from "../../framework/customStore";
import {
    dailyAllowanceCategories,
    paidHourBookingCategories,
} from "../../models/workTime/workHoursItem";
import {
    makeSelectCalculatedWorkHoursByDate,
    makeSelectNormalWorkHoursAmountByDate,
} from "../../store/workHoursSlice";

export const useSelectDailyAllowancesByDate = (
    employeeId: string,
    rangeStart: string,
    rangeEnd: string
) => {
    const state = useAppSelector((state) => state.workHours);
    const categories = useMemo(() => dailyAllowanceCategories(), []);
    const selectWorkHoursByDate = useMemo(
        makeSelectCalculatedWorkHoursByDate,
        []
    );

    return selectWorkHoursByDate(
        state,
        employeeId,
        rangeStart,
        rangeEnd,
        categories
    );
};

export const useSelectCalculatedWorkHoursByDate = (
    employeeId: string,
    rangeStart: string,
    rangeEnd: string
) => {
    const state = useAppSelector((state) => state.workHours);

    const selectWorkHoursByDate = useMemo(
        makeSelectCalculatedWorkHoursByDate,
        []
    );

    return selectWorkHoursByDate(state, employeeId, rangeStart, rangeEnd);
};

export const useSelectPaidWorkHoursAmountByDate = (
    employeeId: string,
    rangeStart: string,
    rangeEnd: string
) => {
    const state = useAppSelector((state) => state.workHours);

    const categories = useMemo(() => paidHourBookingCategories(), []);
    const selectWorkHoursAmountByDate = useMemo(
        makeSelectNormalWorkHoursAmountByDate,
        []
    );
    return selectWorkHoursAmountByDate(
        state,
        employeeId,
        rangeStart,
        rangeEnd,
        categories
    );
};
