// SettingsEmployeeDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import * as employeeService from "../../services/employeeService";
import { Translations } from "../../models/translations";
import { PropertyDialog } from "../framework/dialog";
import { IEmployeeEdit } from "../../models/employee/employeeEdit";
import { IdTitle } from "../../models/common/idTitle";
import { SettingsEmployeeDialogEditView } from "./settingsEmployeeDialogEditView";
import { SettingsEmployeeDialogReadOnlyView } from "./settingsEmployeeDialogReadOnlyView";
import { SettingsEmployeeDialogWeeklyPrintDialog } from "./settingsEmployeeDialogWeeklyPrintDialog";
import { DayBookingEditItem, IDayBookingEditItem } from "../../models/dayBooking/dayBookingEditItem";
import { IEmployeeEditItemSsoIntegrationAttribute } from "../../models/employee/employeeEditItemSsoIntegrationAttribute";
import { IEmployeeEditItemSsoIntegration } from "../../models/employee/employeeEditItemSsoIntegration";
import { UserParameters } from "../../models/employee/userParameters";
import { IEmployeeParameters } from "../../models/employee/employeeIParameters";
import { IWorkTimeAccountTransaction } from "../../models/workTimeAccount/workTimeAccountTransaction";
import * as workTimeAccountService from "../../services/workTimeAccountService";
import { handleApiError } from "../../models/store/storeEffects";
import { EmployeeEditEmployeeGroupItem, IEmployeeEditEmployeeGroupItem } from "../../models/employee/employeeEditEmployeeGroupItem";

// SettingsEmployeeDialog
// ***********************************************************************************************************************
export interface ISettingsEmployeeDialogProp {
    classes?: string;
    employeeEdit: IEmployeeEdit;
    onOk: () => void;
    onCancel: (hasBeenChanged: boolean) => void;
    showEditView?: boolean;
    setEmployeeParameters: (parameters: IEmployeeParameters, saveToDb: boolean) => void;
}

export interface ISettingsEmployeeDialogState {
    userIsOwnerAdmin: boolean;
    isReadOnly: boolean;
    showEditView: boolean;
    dayBookings: IDayBookingEditItem[];
    employeeEmployeeGroups: IEmployeeEditEmployeeGroupItem[];
    ssoIntegrations: IEmployeeEditItemSsoIntegration[];
    showWeeklyReportPrintDialog: boolean;
    workHourTransactions: IWorkTimeAccountTransaction[];
    workHourBalance: number;
}

export class SettingsEmployeeDialog extends React.Component<ISettingsEmployeeDialogProp, ISettingsEmployeeDialogState> {
    private settingsEmployeeEditView = React.createRef<SettingsEmployeeDialogEditView>();

    constructor(props: ISettingsEmployeeDialogProp) {
        super(props);
        const employee = props.employeeEdit.employee;
        const showEditView = employee.isNew() || this.props.showEditView;
        const userIsOwnerAdmin = UserParameters.isOwnerAdmin();
        let isReadOnly = false;
        if (!employee.isNew()) {
            const userGroup = props.employeeEdit.userGroups.find(i => i.id === employee.userGroupId);
            if (userGroup) {
                isReadOnly = !userIsOwnerAdmin && userGroup.getWebIsOwnerAdmin();
            }
        }
        //const isReadOnly
        this.state = {
            isReadOnly: isReadOnly,
            userIsOwnerAdmin: userIsOwnerAdmin,
            showEditView: showEditView,
            dayBookings: DayBookingEditItem.setDayBookingTypeNames(employee.dayBookings.slice(0), props.employeeEdit.dayBookingTypes),
            employeeEmployeeGroups: employee.employeeEmployeeGroups.slice(0),
            ssoIntegrations: employee.ssoIntegrations.slice(0),
            showWeeklyReportPrintDialog: false,
            workHourTransactions: [],
            workHourBalance: 0
        };
    }

    componentDidMount() {
        this.getWorkHourTransactions();
        this.getWorkHourBalance();
    }

    // #region DayBookings
    handleDayBookingAdd = (id: string, dayBookingTypeId: string, date: number, duration: number, dayUsageType: number, comment: string, rowId: string) => {
        const dayBookings = this.state.dayBookings.slice(0);
        const item = new DayBookingEditItem();
        item.id = id;
        item.dayBookingTypeId = dayBookingTypeId;
        item.dayBookingTypeName = IdTitle.getTitleById(this.props.employeeEdit.dayBookingTypes, dayBookingTypeId);
        item.date = date;
        item.duration = duration;
        item.dayUsageType = dayUsageType;
        item.comment = comment;
        item.rowId = rowId;
        dayBookings.push(item);
        DayBookingEditItem.sortDayBookingEditItems(dayBookings, "date", false);
        this.setState({ dayBookings: dayBookings });
    };

    handleDayBookingEdit = (id: string, dayBookingTypeId: string, date: number, duration: number, dayUsageType: number, comment: string, rowId: string) => {
        if (!id) return;
        const dayBookings = this.state.dayBookings.slice(0);
        const index = dayBookings.findIndex(i => i.id === id);
        if (index < 0) return;
        dayBookings[index].dayBookingTypeId = dayBookingTypeId;
        dayBookings[index].dayBookingTypeName = IdTitle.getTitleById(this.props.employeeEdit.dayBookingTypes, dayBookingTypeId);
        dayBookings[index].date = date;
        dayBookings[index].duration = duration;
        dayBookings[index].dayUsageType = dayUsageType;
        dayBookings[index].comment = comment;
        dayBookings[index].rowId = rowId;
        DayBookingEditItem.sortDayBookingEditItems(dayBookings, "date", false);
        this.setState({ dayBookings: dayBookings });
    };

    handleDayBookingRemoved = (id: string) => {
        if (!id) return;
        const item = this.state.dayBookings.find(i => i.id === id);
        if (!item) return;
        this.setState({ dayBookings: this.state.dayBookings.filter(i => i.id !== id) });
    };
    // #endregion DayBookings

    // #region employeeEmployeeGroups
    handleEmployeeEmployeeGroupAdd = (id: string, employeeGroupId: string, groupName: string) => {
        const employeeEmployeeGroups = this.state.employeeEmployeeGroups.filter(i => i.id !== id);
        const item = new EmployeeEditEmployeeGroupItem();
        item.id = id;
        item.employeeGroupId = employeeGroupId;
        item.groupName = groupName;
        employeeEmployeeGroups.push(item);
        EmployeeEditEmployeeGroupItem.sortIdTitles(employeeEmployeeGroups, "groupName", true);
        this.setState({ employeeEmployeeGroups });
    };

    handleEmployeeEmployeeGroupRemove = (id: string) => {
        if (!id) return;
        const employeeEmployeeGroups = this.state.employeeEmployeeGroups.filter(i => i.id !== id);
        this.setState({ employeeEmployeeGroups });
    };
    // #endregion employeeEmployeeGroups

    // #region SsoIntegrations
    handleEditSsoIntegration = (serviceName: string, attributes: IEmployeeEditItemSsoIntegrationAttribute[]) => {
        if (!serviceName) return;
        const ssoIntegrations = this.state.ssoIntegrations.slice(0);
        const ssoIntegration = ssoIntegrations.find(i => i.serviceName === serviceName);
        if (!ssoIntegration) return;
        ssoIntegration.attributes = attributes.slice(0);
        this.setState({ ssoIntegrations: ssoIntegrations });
    };
    // #endregion SsoIntegrations

    getWorkHourTransactions = () => {
        store.customStore.dispatch(storeActions.fetchStart());
        workTimeAccountService.getWorkTimeAccountTransactions(this.props.employeeEdit.employee.id).then(result => {
            this.setState({
                workHourTransactions: result.items
            });
        }, error => {
            handleApiError(error, store.customStore.dispatch);
        }).finally(() => {
            store.customStore.dispatch(storeActions.fetchEnd());
        });
    };

    getWorkHourBalance = () => {
        store.customStore.dispatch(storeActions.fetchStart());
        workTimeAccountService.getWorkTimeAccountBalance(this.props.employeeEdit.employee.id).then(result => {
            this.setState({
                workHourBalance: result.balance
            });
        }, error => {
            handleApiError(error, store.customStore.dispatch);
        }).finally(() => {
            store.customStore.dispatch(storeActions.fetchEnd());
        });
    };

    handleCancelAndCloseDialog = () => {
        const obj = this;
        if (!this.state.showEditView) {
            obj.props.onCancel(!this.props.employeeEdit.employee.isNew());
            return;
        }
        if (!this.settingsEmployeeEditView) return;
        this.settingsEmployeeEditView.current.cancelEmployeeEdit((saved: boolean, employeeId: string) => {
            if (saved) {
                obj.props.onOk();
            } else {
                obj.props.onCancel(true);
            }
        });
    };

    handleEditClick = () => {
        this.setState({
            showEditView: true
        });
    };

    handleWeeklyReportPrintDialogOk = (startDate: number, endDate: number) => {
        const employee = this.props.employeeEdit.employee;
        this.setState({
            showWeeklyReportPrintDialog: false
        });
        store.customStore.dispatch(storeActions.fetchStart());
        employeeService.geEmployeePrint(employee.id, startDate, endDate)
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleWeeklyReportPrintDialogCancel = () => {
        this.setState({
            showWeeklyReportPrintDialog: false
        });
    };

    handlePrintClick = () => {
        this.setState({
            showWeeklyReportPrintDialog: true
        });
    };

    handleSaveAndCloseEdit = () => {
        const obj = this;
        if (!this.settingsEmployeeEditView) return;
        this.settingsEmployeeEditView.current.saveEmployee((saved: boolean, employeeId: string) => {
            obj.props.onOk();
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        const showEditView = state.showEditView;
        const employeeEdit = props.employeeEdit;
        const employee = employeeEdit.employee;
        const dialogClasses = "settings employee px1000" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.Employee + " - " + (!employee.isNew() ? employee.number.toString(10) + " " + (employee.firstName + " " + employee.lastName).trim() : Translations.New)}
                    show={true}
                    body={
                        (showEditView
                            ? <SettingsEmployeeDialogEditView
                                    ref={this.settingsEmployeeEditView}
                                    employeeEdit={employeeEdit}
                                    dayBookings={state.dayBookings}
                                    employeeEmployeeGroups={state.employeeEmployeeGroups}
                                    userGroups={state.userIsOwnerAdmin ? props.employeeEdit.userGroups : props.employeeEdit.userGroups.filter(i => !i.getWebIsOwnerAdmin())}
                                    ssoIntegrations={state.ssoIntegrations}
                                    onDayBookingRemoved={this.handleDayBookingRemoved}
                                    onAddDayBooking={this.handleDayBookingAdd}
                                    onEditDayBooking={this.handleDayBookingEdit}
                                    onEmployeeEmployeeGroupRemoved={this.handleEmployeeEmployeeGroupRemove}
                                    onAddEmployeeEmployeeGroup={this.handleEmployeeEmployeeGroupAdd}
                                    onEditSsoIntegration={this.handleEditSsoIntegration}
                                    workHourTransactions={state.workHourTransactions}
                                    workHourBalance={state.workHourBalance}
                              />
                            : <SettingsEmployeeDialogReadOnlyView
                                    employeeEdit={employeeEdit}
                                    dayBookings={state.dayBookings}
                                    employeeEmployeeGroups={state.employeeEmployeeGroups}
                                    onDayBookingRemoved={this.handleDayBookingRemoved}
                                    onAddDayBooking={this.handleDayBookingAdd}
                                    onEditDayBooking={this.handleDayBookingEdit}
                                    onEmployeeEmployeeGroupRemoved={this.handleEmployeeEmployeeGroupRemove}
                                    onAddEmployeeEmployeeGroup={this.handleEmployeeEmployeeGroupAdd}
                                    workHourTransactions={state.workHourTransactions}
                                    workHourBalance={state.workHourBalance}
                              />)}
                    buttons={(showEditView
                        ? [
                            { title: (Translations.Save), classes: "btn-primary", enabled: true, onClick: this.handleSaveAndCloseEdit },
                            { title: (Translations.Print), classes: "btn-default", enabled: true, onClick: this.handlePrintClick },
                        ]
                        : [
                            { title: (Translations.Edit), classes: "btn-primary", enabled: true, onClick: state.isReadOnly ? null : this.handleEditClick },
                            { title: (Translations.Print), classes: "btn-default", enabled: true, onClick: this.handlePrintClick },
                        ]
                    )}
                    onClose={this.handleCancelAndCloseDialog}
                />
                {state.showWeeklyReportPrintDialog &&
                    <SettingsEmployeeDialogWeeklyPrintDialog
                        onOk={this.handleWeeklyReportPrintDialogOk}
                        onCancel={this.handleWeeklyReportPrintDialogCancel}
                    />
                }
            </div>
        );
    }
}
