import { Base } from "../../framework/base";

export interface IWorkOrderEditProductItem {
    id: string;
    title: string;
    description: string;
    comment: string;
    unit: number;
    unitPrice: number;
    productGroupCode: string;
    productGroupName: string;

    getOptionTitle(): string;
}

export class WorkOrderEditProductItem implements IWorkOrderEditProductItem {
    id: string;
    title: string;
    description: string;
    comment: string;
    unit: number;
    unitPrice: number;
    productGroupCode: string;
    productGroupName: string;

    constructor();
    constructor(obj: IWorkOrderEditProductItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.title = obj && obj.title || "";
        this.description = obj && obj.description || "";
        this.comment = obj && obj.comment || "";
        this.unit = obj && obj.unit || 0;
        this.unitPrice = obj && obj.unitPrice || 0;
        this.productGroupCode = obj && obj.productGroupCode || "";
        this.productGroupName = obj && obj.productGroupName || "";
    }

    getOptionTitle(): string {
        return this.title + (this.description ? ", " + this.description : "");
    }

    static getTitleById(items: IWorkOrderEditProductItem[], id: string): string {
        if (Base.isNullOrUndefined(items) || !id || items.length < 1) return "";
        const item = items.find(i => i.id === id);
        if (!Base.isNullOrUndefined(item)) {
            return item.title;
        }
        return "";
    }

    static getUnitPriceById(items: IWorkOrderEditProductItem[], id: string): number {
        if (Base.isNullOrUndefined(items) || !id || items.length < 1) return 0;
        const item = items.find(i => i.id === id);
        if (!Base.isNullOrUndefined(item)) {
            return item.unitPrice;
        }
        return 0;
    }
}