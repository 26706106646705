import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { useAppDispatch, useAppSelector } from "../../../framework/customStore";
import { TransportPlanState, transportPlanStates } from "../../../models/transport/transportPlan";
import { TransportPlanStateIndicator } from "../components/transportUtils";
import { showApiError } from "../../framework/formUtils";
import { updatePlanState } from "../../../services/transportPlanService";
import { setGridDataChanged } from "../../../store/transport/transportVehiclesSlice";
import { TransportState } from "../../../models/transport/transport";
import { updateTransportState } from "../../../services/transportService";
import { TransportOrderState } from "../../../models/transport/transportOrder";
import { Divider } from "@mui/material";

interface IPlanStateEditMenuProps {
    planId: string;
}

export const PlanStateEditMenu = ({ planId }: IPlanStateEditMenuProps) => {
    const plan = useAppSelector(
        (state) => state.transportVehicles.plans[planId]
    );
    const gridDataChanged = useAppSelector((state) => state.transportVehicles.gridDataChanged);
    const dispatch = useAppDispatch();
    const noOrders = plan.orders.length === 0;
    const allOrdersCompleted = plan.orders.every(order => order.state === TransportOrderState.Delivered);
    const isPlanInEarlyState = [TransportPlanState.Todo, TransportPlanState.Planning, TransportPlanState.Planned].includes(plan.state);
    const hasOrdersInTransit = plan.orders.some(order => order.state === TransportOrderState.PickedUp || order.state === TransportOrderState.Delivered);

    const canChangeState = (newState) => {
        if (noOrders) {
            // No orders, allow all state changes
            return true;
        } else if (allOrdersCompleted) {
            // Only changes between Completed or InTransport are allowed
            return newState === TransportPlanState.InTransport || newState === TransportPlanState.Completed;
        } else if (isPlanInEarlyState) {
            // Only changes between Todo, Planning or Planned are allowed
            return [TransportPlanState.Todo, TransportPlanState.Planning, TransportPlanState.Planned].includes(newState);
        } else {
            // Disallow if any order is PickedUp or Delivered
            return !hasOrdersInTransit;
        }
    };

    const handleClick = (newState: TransportPlanState) => {
        if (plan.state === newState ||
        (!noOrders && !allOrdersCompleted && (newState === TransportPlanState.InTransport || newState === TransportPlanState.Completed))) {
            return;
        }

        const updateState = async() => {
            await updatePlanState({
                planId: planId,
                originalState: plan.state,
                newState,
            });

            if ((newState === TransportPlanState.InTransport || newState === TransportPlanState.Completed) && plan.transportId) {
                const transportNewState = newState === TransportPlanState.InTransport ? TransportState.InTransport : TransportState.Completed;
                await updateTransportState(plan.transportId, transportNewState);
            }
        };

        updateState()
            .then(() => dispatch(setGridDataChanged(!gridDataChanged)))
            .catch(() => showApiError("Suunnitelman tilan päivitys epäonnistui"));
    };

    if (hasOrdersInTransit && !allOrdersCompleted) return null;

    return (
        <>
            <Divider />
            {transportPlanStates.filter(tps => canChangeState(tps.value)).map((tps) => (
                <MenuItem
                    onClick={() => handleClick(tps.value)}
                    dense
                    key={tps.value}
                    selected={plan.state === tps.value}
                >
                    <TransportPlanStateIndicator state={tps.value} />
                    {tps.name}
                </MenuItem>
            ))}
        </>
    );
};
