import * as React from "react";
import { WorkTimeDetailsMain } from "./workTimeDetailsMain";
import { PrivateRoute } from "../app/privateRoute";
import { Redirect, RouteComponentProps, Switch, generatePath, useParams } from "react-router-dom";
import { Base } from "../../framework/base";
import { useAppSelector } from "../../framework/customStore";
import { Tab } from "./workTimeDetailsFormTab";

export interface IWorkTimeDetailsPage {
    employeeId: string;
    date: string;
    tab: Tab;
}

export interface IWorkTimeDetailsPageMainProps {
    backPath: string;
}
export function WorkTimeDetailsPageMain(
    props: RouteComponentProps & IWorkTimeDetailsPageMainProps
) {
    const { employeeId, date } = useParams<IWorkTimeDetailsPage>();
    const { employeeId: userEmployeeId } = useAppSelector((state) => state.user);

    if (!date || !employeeId) {
        const newDate = date || Base.dayjsToJsonDate();
        const selectedEmployeeId = employeeId || userEmployeeId;

        if (newDate && selectedEmployeeId) {
            return (
                <Redirect
                    to={generatePath(props.match.path, {
                        employeeId: selectedEmployeeId,
                        date: newDate,
                    })}
                />
            );
        }
    }
    return (
        <Switch>
            <PrivateRoute
                path={`${props.match.path}/:tab?`}
                component={WorkTimeDetailsMain}
                componentProps={{ backPath: props.backPath }}
            />
        </Switch>
    );
}
