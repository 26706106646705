/* global JSX */
// WorkOrderEditRouteEditorRoutePhases - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { IRoutePointItem } from "../../models/routePoint/routePointItem";
import { IWorkShiftTimeSlotType } from "../../models/workShiftTimeSlotType/workShiftTimeSlotType";
import { Base } from "../../framework/base";
import { AppUtils } from "../../models/common/appUtils";
import { Translations } from "../../models/translations";
import { EditItem } from "../../models/common/editItem";

interface IRoutePhase {
    id: string;
    name: string;
    category: number;
    durationMin: number;
}

class RoutePhase implements IRoutePhase {
    id: string;
    name: string;
    category: number;
    durationMin: number;

    constructor();
    constructor(obj: IWorkShiftTimeSlotType);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
        this.category = obj && obj.category || 0;
        this.durationMin = obj && obj.durationMin || 0;
    }

    static sortRoutePhases(items: IRoutePhase[]) {
        if (Base.isNullOrUndefined(items) || items.length < 2) return;
        items.sort((a: IRoutePhase, b: IRoutePhase) => { return (!a || !b) ? 0 : Base.numberCompare(a.category, b.category); });
    }
}

export interface IWorkOrderEditRouteEditorRoutePhaseTitleProp {
    graph?: JSX.Element;
    value: string;
}

export class WorkOrderEditRouteEditorRoutePhaseTitle extends React.Component<IWorkOrderEditRouteEditorRoutePhaseTitleProp, {}> {
    render() {
        const props = this.props;
        return (
            <div className="routePhase title">
                <div className="graph">{props.graph}</div>
                <div className="content">
                    <span className="name">{Translations.Total}</span>
                    <span className="duration">{props.value}</span>
                </div>
            </div>
        );
    }
}

export interface IWorkOrderEditRouteEditorRoutePhaseProp {
    totalDurationMin: number;
    routePhase: IRoutePhase;
}

export class WorkOrderEditRouteEditorRoutePhase extends React.Component<IWorkOrderEditRouteEditorRoutePhaseProp, {}> {
    render() {
        const props = this.props;
        return (
            <div className="routePhase">
                <div className="graph" />
                <div className="content">
                    <span className="name">{props.routePhase.name}</span>
                    <span className="duration">{Base.getStringWithSeparators([AppUtils.getDurationStrByDurationMin(props.routePhase.durationMin), (props.routePhase.durationMin > 0 && props.totalDurationMin > 0
                        ? "(" + (100 * props.routePhase.durationMin / props.totalDurationMin).toLocaleFixed(0) + " %)"
                        : "")], " ")}
                    </span>
                </div>
            </div>
        );
    }
}

export interface IWorkOrderEditRouteEditorRoutePhasesProp {
    classes?: string;
    routePoints: IRoutePointItem[];
    routePointWorkShiftTimeSlotTypes: IWorkShiftTimeSlotType[];
}

export interface IWorkOrderEditRouteEditorRoutePhasesState {
    totalDurationMin: number;
    routePhases: IRoutePhase[];
}

export class WorkOrderEditRouteEditorRoutePhases extends React.Component<IWorkOrderEditRouteEditorRoutePhasesProp, IWorkOrderEditRouteEditorRoutePhasesState> {
    static getStateFromProps = (props: IWorkOrderEditRouteEditorRoutePhasesProp): IWorkOrderEditRouteEditorRoutePhasesState => {
        const result: IWorkOrderEditRouteEditorRoutePhasesState = {
            totalDurationMin: 0,
            routePhases: []
        };
        const nowTime = new Date().getTime();
        for (const routePoint of props.routePoints) {
            for (const workShiftTimeSlot of routePoint.workShiftTimeSlots) {
                let routePhase = result.routePhases.find(i => i.id === workShiftTimeSlot.workShiftTimeSlotTypeId);
                if (!routePhase) {
                    const routePointWorkShiftTimeSlotType = props.routePointWorkShiftTimeSlotTypes.find(i => i.id === workShiftTimeSlot.workShiftTimeSlotTypeId);
                    if (!routePointWorkShiftTimeSlotType) continue;
                    routePhase = new RoutePhase();
                    routePhase.id = workShiftTimeSlot.workShiftTimeSlotTypeId;
                    routePhase.name = routePointWorkShiftTimeSlotType.getTitle();
                    routePhase.category = routePointWorkShiftTimeSlotType.category;
                    result.routePhases.push(routePhase);
                    RoutePhase.sortRoutePhases(result.routePhases);
                }
                const durationMin = Math.max(0, Base.dateDiffInMinutes(workShiftTimeSlot.startTime, workShiftTimeSlot.endTime ? workShiftTimeSlot.endTime : nowTime));
                routePhase.durationMin = routePhase.durationMin + durationMin;
                result.totalDurationMin = result.totalDurationMin + durationMin;
            }
        }
        return result;
    };

    constructor(props: IWorkOrderEditRouteEditorRoutePhasesProp) {
        super(props);
        this.state = WorkOrderEditRouteEditorRoutePhases.getStateFromProps(props);
    }

    componentDidUpdate(prevProps: IWorkOrderEditRouteEditorRoutePhasesProp, prevState: IWorkOrderEditRouteEditorRoutePhasesState): void {
        const props = this.props;
        if (EditItem.getHash(prevProps.routePoints) === EditItem.getHash(props.routePoints)) return;
        this.setState(WorkOrderEditRouteEditorRoutePhases.getStateFromProps(props));
    }

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <div className={"routePhasesContainer" + (props.classes ? " " + props.classes : "")}>
                <WorkOrderEditRouteEditorRoutePhaseTitle
                    value={AppUtils.getDurationStrByDurationMin(state.totalDurationMin)}
                />
                {state.routePhases.map((routePhase) =>
                    <WorkOrderEditRouteEditorRoutePhase
                        key={routePhase.id}
                        totalDurationMin={state.totalDurationMin}
                        routePhase={routePhase}
                    />
                )}
            </div>
        );
    }
}

export interface IWorkOrderEditRouteEditorPlannedRoutePhasesProp {
    classes?: string;
    routePoints: IRoutePointItem[];
    mapLinkTemplate: string;
    directionsMapLinkTemplate: string;
}

export interface IWorkOrderEditRouteEditorPlannedRoutePhasesState {
    totalDistanceM: number;
    totalDurationS: number;
    directionsMapLink: string;
}

export class WorkOrderEditRouteEditorPlannedRoutePhases extends React.Component<IWorkOrderEditRouteEditorPlannedRoutePhasesProp, IWorkOrderEditRouteEditorPlannedRoutePhasesState> {
    static getStateFromProps = (props: IWorkOrderEditRouteEditorPlannedRoutePhasesProp): IWorkOrderEditRouteEditorPlannedRoutePhasesState => {
        const result: IWorkOrderEditRouteEditorPlannedRoutePhasesState = {
            totalDistanceM: 0,
            totalDurationS: 0,
            directionsMapLink: ""
        };
        for (const routePoint of props.routePoints.slice(1)) {
            result.totalDistanceM = result.totalDistanceM + routePoint.fromPreviousDistanceM;
            result.totalDurationS = result.totalDurationS + routePoint.fromPreviousDurationS;
        }
        result.directionsMapLink = AppUtils.generateDirectionsMapLink(props.directionsMapLinkTemplate, props.mapLinkTemplate, props.routePoints);
        return result;
    };

    constructor(props: IWorkOrderEditRouteEditorPlannedRoutePhasesProp) {
        super(props);
        this.state = WorkOrderEditRouteEditorPlannedRoutePhases.getStateFromProps(props);
    }

    componentDidUpdate(prevProps: IWorkOrderEditRouteEditorPlannedRoutePhasesProp, prevState: IWorkOrderEditRouteEditorPlannedRoutePhasesState): void {
        const props = this.props;
        if (EditItem.getHash(prevProps.routePoints) === EditItem.getHash(props.routePoints)) return;
        this.setState(WorkOrderEditRouteEditorPlannedRoutePhases.getStateFromProps(props));
    }

    handleOpenMapLink = (mapLink: string) => {
        Base.openMapLinkInMaps(mapLink);
    };

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <div className={"routePhasesContainer" + (props.classes ? " " + props.classes : "")}>
                <WorkOrderEditRouteEditorRoutePhaseTitle
                    graph={
                        <div className="mapMarker smallMarker green" title={Translations.OpenMapLink} onClick={() => this.handleOpenMapLink(state.directionsMapLink)} />
                    }
                    value={Base.getStringWithSeparators([state.totalDistanceM ? (state.totalDistanceM / 1000).toFixed(1) + " " + Translations.MeasureUnitKm : "", AppUtils.getDurationStrByDurationMin(state.totalDurationS / 60)], ", ")}
                />
            </div>
        );
    }
}
