import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import { Button, ButtonProps, ClickAwayListener, Typography, buttonClasses, inputBaseClasses, inputClasses, styled, svgIconClasses } from "@mui/material";
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid-premium";
import React, { useRef, useState } from "react";

interface CustomToolbarProps {
    setFilterButtonEl: any;
    title: string;
    customToolbarButtons: ButtonProps[];
}

const StyledQuickFilter = styled(GridToolbarQuickFilter)(({ theme }) => ({
    [`& .${inputClasses.underline}:before`]: {
        borderBottom: `1px solid ${theme.palette.grayLight.main} !important`
    },
    [`& .${inputClasses.underline}:after`]: {
        display: "none"
    },
    [`& .${inputBaseClasses.root}`]: {
        background: "transparent",
        color: "white",
    },
    [`& .${svgIconClasses.root}`]: {
        color: "white",
    },

}));

export function CustomToolbar({ setFilterButtonEl, title, customToolbarButtons }: CustomToolbarProps) {
    const [quickSearchActive, setQuickSearchActive] = useState(false);
    const quickSearchInput = useRef<HTMLInputElement>();

    return (
        <GridToolbarContainer
            sx={{
                display: "flex",
                justifyContent: "space-between",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
                alignItems: "center",
                backgroundColor: "primary.dark",
                marginBottom: "-0.2rem",
                height: "40px",
                [`.${buttonClasses.startIcon}` ]: {
                    margin: 0
                },
                [`& .${buttonClasses.root}`]: {
                    minWidth: "40px"
                },
            }}
        >
            {title ? (
                <Typography sx={{
                    flexGrow: 1,
                    color: "primary.contrastText",
                    padding: "0 0 0 0.3rem",
                }} variant="h6" component="div"
                >
                    {title}
                </Typography>
            ) : <div />}

            {customToolbarButtons?.map(b => (
                <CustomToolbarButton key={b.key} {...b} />
            ))}

            {quickSearchActive &&
                <ClickAwayListener onClickAway={() => {
                    if (quickSearchInput.current?.value === "") {
                        setQuickSearchActive(false);
                    }
                }}
                >
                    <div><StyledQuickFilter autoFocus inputRef={quickSearchInput}/></div>
                </ClickAwayListener>
            }

            {!quickSearchActive &&
                <Button
                    onClick={() => setQuickSearchActive(true)}
                    startIcon={<SearchIcon fontSize="small" color="white" />}
                />
            }
            <GridToolbarFilterButton
                ref={setFilterButtonEl}
                componentsProps={{
                    button: {
                        color: "white"
                    }
                }}
            />
            <GridToolbarColumnsButton
                ref={setFilterButtonEl}
                startIcon={
                    <SettingsIcon
                        sx={{
                            color: "primary.contrastText",
                            backgroundColor: "primary.dark",
                        }}
                    />
                }
            />
        </GridToolbarContainer>
    );
}


export const CustomToolbarButton = (props: ButtonProps) => {
    return <Button color="white" variant="outlined" size="small" {...props} />;
};