import { IWorkOrderItem, WorkOrderItem } from "./workOrderItem";
import { Base } from "../../framework/base";

export interface IWorkOrderTemplates {
    items: IWorkOrderItem[];
}

export class WorkOrderTemplates implements IWorkOrderTemplates {
    items: WorkOrderItem[];

    constructor();
    constructor(obj: IWorkOrderTemplates);
    constructor(obj?: any) {
        this.items = Base.getTypedArray<WorkOrderItem>(obj ? obj.items : null, WorkOrderItem);
    }
}
