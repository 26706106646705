import { TextFieldProps, TextField } from "@mui/material";
import { FormikProps } from "formik";
import React, { useState } from "react";

interface Props {
    formik: FormikProps<unknown>;
    name: string;
    fieldProps: TextFieldProps;
};

/**
 * Updates formik value onBlur instead of onChange, which reduces re-rendering of the form unnecessarily.
 */
export const TextFieldWrapper = ({ formik, name, fieldProps }: Props) => {
    const [value, setValue] = useState<string>(formik.values[name]);

    // TODO if this isn't fast enough, memoize and check if touched or errors has changed

    return (
        <TextField
            {...fieldProps}
            name={name}
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
            onBlur={(e) => {
                // Set touched first without validation, then set value and validate. Otherwise the error status is not updated correctly.
                // See issue: https://github.com/jaredpalmer/formik/issues/2083#issuecomment-884831583
                formik.setFieldTouched(name, true, false);
                formik.setFieldValue(name, value, true);
            }}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
        />
    );
};
