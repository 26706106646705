import { TransportDetailsDto, TransportSaveDto, TransportState, TransportTaskType } from "../models/transport/transport";
import { apiCall } from "./apiClient";

export const createTransport = async(dto: TransportSaveDto) => {
    const response = await apiCall<TransportDetailsDto>("Transports", "POST", dto);
    return response.data;
};

export const createTransportTask = async(transportId: string, transportOrderId: string, taskType: TransportTaskType) => {
    const response = await apiCall<void>(`Transports/${transportId}/TransportTasks`, "POST", { transportOrderId, taskType });
    return response.data;
};

export const updateTransportState = async(id: string, newState: TransportState) => {
    const response = await apiCall<void>(`Transports/${id}/State/${newState}`, "PUT");
    return response.data;
};

export const cancelTransport = async(transportId: string) => {
    const response = await apiCall<void>(`Transports/${transportId}/CancelTransport`, "POST", transportId);
    return response.data;
};
