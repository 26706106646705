import * as React from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Button, IconButton, Typography, useTheme } from "@mui/material";
import { Translations } from "../../models/translations";
import { TableDef, TableWrapper } from "../framework/tableWrapper";
import { useEffect, useMemo, useState } from "react";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as workTimeTypeService from "../../services/workTimeTypeService";
import { handleApiError } from "../../models/store/storeEffects";
import { WorkTimeTypeListItem } from "../../models/workTimeType/workTimeTypeListItem";
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
    Add as AddIcon
} from "@mui/icons-material";
import { SettingsWorkTimeTypeDialog } from "./settingsWorkTimeTypeDialog";
import { WorkTimeTypeEditItem } from "../../models/workTimeType/workTimeTypeEditItem";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";

export function SettingsWorkTimeTypeList() {
    const [items, setItems] = useState<WorkTimeTypeListItem[]>([]);
    const [item, setItem] = useState<WorkTimeTypeEditItem | null>();
    const [defaultValuesOnly, setDefaultValuesOnly] = useState<boolean>(false);

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const dispatch = store.customStore.dispatch;
    const theme = useTheme();
    const { t } = useTranslation();

    const openCreateDialog = () => {
        setItem(new WorkTimeTypeEditItem());
        setShowDialog(true);
    };

    const handleClose = () => {
        setItem(null);
        setShowDialog(false);
        fetchItems();
    };
    const openEditDialog = (workTimeType: WorkTimeTypeListItem) => {
        setItem(new WorkTimeTypeEditItem(workTimeType));
        setShowDialog(true);
    };


    const fetchItems = () => {
        dispatch(storeActions.fetchStart());
        workTimeTypeService.getWorkTimeTypes()
            .then(result => {
                setItems(result);
            },
            error => {
                handleApiError(error, dispatch);
            })
            .finally(() => dispatch(storeActions.fetchEnd()));
    };

    useEffect(fetchItems, []);

    useEffect(() => {
        if (!items){
            return;
        }
        const defaultValuesOnly = items.every(x => x.ownerId === null);
        setDefaultValuesOnly(defaultValuesOnly);

    }, [items]);

    const handleDelete = (workTimeTypeId: string, workTimeTypeName: string) => {
        dispatch(
            storeActions.setConfirmation(
                ConfirmationDialogType.Warning,
                Translations.Warning,
                Translations.WorkTimeTypeRemoveConfirmation
                    .replace("{0}", workTimeTypeName),
                () => {
                    dispatch(storeActions.fetchStart());

                    workTimeTypeService
                        .deleteWorkTimeType(workTimeTypeId)
                        .then(
                            () => {
                                fetchItems();
                                dispatch(
                                    storeActions.showSuccessMessage(
                                        Translations.WorkTimeTypeRemoveSuccess
                                            .replace("{0}", workTimeTypeName)
                                    )
                                );
                            },
                            (error) => {
                                dispatch(
                                    storeActions.showErrorMessage(
                                        Translations.WorkTimeTypeRemoveFailedErrorParameter
                                            .replace("{0}", workTimeTypeName)
                                            .replace("{1}", error)
                                    )
                                );
                            }
                        )
                        .finally(() => {
                            dispatch(storeActions.fetchEnd());
                            dispatch(storeActions.clearConfirmation());
                        });
                }
            )
        );
    };

    const table = useMemo<TableDef<WorkTimeTypeListItem>>(
        () => ({
            columns: [
                {
                    label: Translations.WorkTimeType,
                    accessor: "name",
                },
                {
                    label: t("workTimeType.type"),
                    accessor: "typeName",
                },
                {
                    label: t("workTimeType.calculateOvertime"),
                    renderCell: (_, row) =>
                        row.calculateOvertime ? <CheckIcon color="success" /> : null,
                },
                {
                    label: "",
                    renderCell: (_, row) =>
                        !row.ownerId && <div>{Translations.Default}</div>,
                },
                {
                    label: "",
                    renderCell: (_, row) =>
                        row.ownerId && (
                            <Grid2 container justifyContent="flex-end">
                                <IconButton
                                    size="small"
                                    onClick={() => openEditDialog(row)}
                                >
                                    <EditIcon color="primary" />
                                </IconButton>
                                <IconButton
                                    size="small"
                                    onClick={() =>
                                        handleDelete(
                                            row.workTimeTypeId,
                                            row.name
                                        )
                                    }
                                >
                                    <DeleteIcon color="error" />
                                </IconButton>
                            </Grid2>
                        ),
                },
            ],
        }),
        []
    );

    const initializeWorkTimeTypes = () => {
        dispatch(
            storeActions.setConfirmation(
                ConfirmationDialogType.Warning,
                Translations.Warning,
                Translations.InitializeWorkTimeTypesConfirmation
                ,
                () => {
                    dispatch(storeActions.fetchStart());

                    workTimeTypeService
                        .initializeWorkTimeTypes()
                        .then(
                            (result) => {
                                setItems(result);
                                dispatch(
                                    storeActions.showSuccessMessage(
                                        Translations.InitializeWorkTimeTypesSuccess
                                    )
                                );
                            },
                            (error) => {
                                dispatch(
                                    storeActions.showErrorMessage(
                                        Translations.InitializeWorkTimeTypesFailedErrorParameter
                                            .replace("{0}", error)
                                    )
                                );
                            }
                        )
                        .finally(() => {
                            dispatch(storeActions.fetchEnd());
                            dispatch(storeActions.clearConfirmation());
                        });
                }
            )
        );
    };
    return (
        <Grid2 sx={{ padding: "1rem" }}>
            <Typography variant="h3">
                {Translations.WorkTimeTypes}
            </Typography>
            <Grid2
                xs={12}
                container
                spacing={2}
                alignItems="center"
                justifyContent="flex-end"
            >
                {defaultValuesOnly &&
                    <Button
                        color="secondary"
                        sx={{ color: theme.palette.common.white }}
                        variant={"contained"}
                        onClick={initializeWorkTimeTypes }
                    >
                        <AddIcon/>{Translations.InitializeWorkTimeTypes}
                    </Button>
                }
                {!defaultValuesOnly &&
                    <Button
                        color="secondary"
                        sx={{ color: theme.palette.common.white }}
                        variant={"contained"}
                        onClick={openCreateDialog}
                    >
                        <AddIcon/>{Translations.AddNew}
                    </Button>
                }
            </Grid2>
            <TableWrapper tableDef={table} data={items} />
            {showDialog &&
                <SettingsWorkTimeTypeDialog
                    item={item}
                    open={showDialog}
                    onClose={handleClose}
                />
            }
        </Grid2>
    );
}
