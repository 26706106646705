import { Base } from "../../framework/base";
import { WorkShiftTimeSlotItem } from "../workShitTimeSlot/workShiftTimeSlotItem";

export class WorkShiftDto {
    workShiftId?: string;
    employeeId: string;
    effectiveDate: string;
    startTime: string;
    endTime: string;
    workShiftTimeSlots: WorkShiftTimeSlotItem[];
    startTimeZoneName: string;
    endTimeZoneName: string;

    constructor();
    constructor(obj: WorkShiftDto);
    constructor(obj?: any) {
        this.workShiftId = (obj && obj.workShiftId) || null;
        this.employeeId = (obj && obj.employeeId) || null;
        this.effectiveDate = obj && obj.effectiveDate;
        this.startTime = obj && obj.startTime;
        this.endTime = obj && obj.endTime;
        this.startTimeZoneName = obj && obj.startTimeZoneName;
        this.endTimeZoneName = obj && obj.endTimeZoneName;
        this.workShiftTimeSlots = obj?.workShiftTimeSlots
            ? Base.getTypedArray(obj.workShiftTimeSlots, WorkShiftTimeSlotItem)
            : [];
    }
}

export interface WorkShift {
    workShiftId?: string;
    employeeId: string;
    effectiveDate: string;
    startTime: string;
    endTime: string;
    workShiftTimeSlotIds: string[];
    startTimeZoneName: string;
    endTimeZoneName: string;
}

export const getWorkShift = ({
    workShiftId,
    employeeId,
    effectiveDate,
    startTime,
    endTime,
    workShiftTimeSlots,
    startTimeZoneName,
    endTimeZoneName,
}: WorkShiftDto) => ({
    workShiftId,
    employeeId,
    effectiveDate,
    startTime,
    endTime,
    workShiftTimeSlotIds: workShiftTimeSlots.map((s) => s.id),
    startTimeZoneName,
    endTimeZoneName,
});
