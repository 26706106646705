import { Base } from "../../framework/base";
import { IEmployeeItem, EmployeeItem } from "../employee/employeeItem";
import { IEmployeeGroupItem, EmployeeGroupItem } from "../employeeGroup/employeeGroupItem";

export interface IWorkTimePageMainEmployeesAndGroups {
    employeeGroups: IEmployeeGroupItem[];
    employees: IEmployeeItem[];
}

export class WorkTimePageMainEmployeesAndGroups implements IWorkTimePageMainEmployeesAndGroups {
    employeeGroups: EmployeeGroupItem[];
    employees: EmployeeItem[];

    constructor();
    constructor(obj: IWorkTimePageMainEmployeesAndGroups);
    constructor(obj?: any) {
        this.employeeGroups = Base.getTypedArray<EmployeeGroupItem>(obj ? obj.employeeGroups : null, EmployeeGroupItem);
        this.employees = Base.getTypedArray<EmployeeItem>(obj ? obj.employees : null, EmployeeItem);
    }
}