// SettingsEmployeeCompetencyList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import * as employeeService from "../../services/employeeService";
import { ToolButton } from "../framework/toolButton";
import { Base } from "../../framework/base";
import { ListSearchFilter } from "../framework/listSearchFilter";
import { ListHeaderColumn } from "../framework/listHeaderColumn";
import { ToolTitle } from "../framework/toolTitle";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { infiniteListPageSize } from "../../models/common/consts";
import { handleApiError } from "../../models/store/storeEffects";
import { IEmployeeCompetencyItem } from "../../models/employee/employeeCompetencyItem";
import { IEmployeeCompetencyEdit } from "../../models/employee/employeeCompetencyEdit";
import { SettingsEmployeeDialogEmployeeCompetencyDialog } from "./settingsEmployeeDialogEmployeeCompetencyList";
import { IDocument, Document, IDocumentInfoSave, DocumentInfoSave } from "../../models/document/document";
import { SaveData } from "../../framework/saveData";
import { AppUtils } from "../../models/common/appUtils";

// SettingsEmployeeCompetencyListLineHeader
export interface ISettingsEmployeeCompetencyListLineHeaderProp {
    sortColumn: string;
    sortOrderIsAsc: boolean;
    onColumnClick: (column: string) => void;
}

export class SettingsEmployeeCompetencyListLineHeader extends React.Component<ISettingsEmployeeCompetencyListLineHeaderProp, {}> {
    handleColumnClick = (column: string) => {
        this.props.onColumnClick(column);
    };

    render() {
        return (
            <div className="row title">
                <ListHeaderColumn
                    title={Translations.Employee}
                    column="employeeName"
                    classes="col-2"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.CompetencyType}
                    column="competencyTypeName"
                    classes="col-2"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Competency}
                    column="competencyName"
                    classes="col-2"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.GrantDateAbrv}
                    column="date"
                    classes="col-1"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.ExpirationDateAbrv}
                    column="expireDate"
                    classes="col-1"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Comment}
                    column="comment"
                    classes="col-4"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
            </div>
        );
    }
}

// SettingsEmployeeCompetencyListLine
export interface ISettingsEmployeeCompetencyListLineProp {
    item: IEmployeeCompetencyItem;
    selectedId: string;
    competencyWarningLimitInDays: number;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

export class SettingsEmployeeCompetencyListLine extends React.Component<ISettingsEmployeeCompetencyListLineProp, {}> {
    handleNumberClick = (e) => {
        this.props.onDoubleClick(this.props.item.id);
    };

    render() {
        const props = this.props;
        const item = props.item;
        let expireInDays = -1000;
        if (!Base.isNullOrUndefined(item.expireDate)) {
            const nowDate = Base.getNowUtcDate().getTime();
            if (item.expireDate < nowDate) {
                expireInDays = -1;
            } else {
                expireInDays = (item.expireDate - nowDate) / (1000 * 60 * 60 * 24);
            }
        }
        return (
            <div className={"row line" + (item.id === props.selectedId ? " selected" : "") + (expireInDays >= -2 && expireInDays <= props.competencyWarningLimitInDays ? " expireLevel" + (expireInDays < 0 ? 1 : 2).toString(10) : "")} onClick={() => { props.onClick(item.id); }} onDoubleClick={() => { props.onDoubleClick(item.id); }} >
                <div className="col-2 number" onClick={this.handleNumberClick}>{item.employeeName}</div>
                <div className="col-2">{item.competencyTypeName}</div>
                <div className="col-2">{item.competencyName}</div>
                <div className="col-1">{Base.utcTimeToDateStr(item.date)}</div>
                <div className="col-1 expireDate">{Base.utcTimeToDateStr(item.expireDate)}</div>
                <div className="col-4">{item.comment}</div>
            </div>
        );
    }
}

// SettingsEmployeeCompetencyList
export interface ISettingsEmployeeCompetencyListProp {
}

interface ISettingsEmployeeCompetencyListState {
    // List
    pageSize: number;
    page: number;
    hasMore: boolean;
    filter: string;
    sortColumn: string;
    sortOrderIsAsc: boolean;
    items: IEmployeeCompetencyItem[];
    competencyWarningLimitInDays: number;

    // Edit
    selectedId: string;
    editItem: IEmployeeCompetencyEdit;
    orgDocuments: IDocument[];
    showEditDialog: boolean;
}

export class SettingsEmployeeCompetencyList extends React.Component<ISettingsEmployeeCompetencyListProp, ISettingsEmployeeCompetencyListState> {
    private containerDiv: HTMLDivElement;
    private listDiv: HTMLDivElement;
    private isLoading: boolean;

    constructor(props) {
        super(props);
        this.isLoading = false;
        this.state = {
            pageSize: infiniteListPageSize, page: 1, hasMore: false, filter: "", sortColumn: "number", sortOrderIsAsc: true, items: [], competencyWarningLimitInDays: 30, selectedId: null, editItem: null, orgDocuments: [], showEditDialog: false
        };
    }

    searchItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean, resetItems: boolean, refreshList: boolean, successCallback: () => void = null) => {
        const obj = this;
        obj.isLoading = true;
        store.customStore.dispatch(storeActions.fetchStart());
        employeeService.getEmployeeCompetencyItems(!refreshList ? pageSize : (page * infiniteListPageSize), !refreshList ? page : 1, filter, sortColumn, sortOrderIsAsc).then(employeeCompetencyItems => {
            let items: IEmployeeCompetencyItem[];
            if (!resetItems && !refreshList) {
                const oldIds = {};
                for (let j = 0; j < obj.state.items.length; j++) {
                    oldIds[obj.state.items[j].id] = true;
                }
                const oldItems = obj.state.items.slice(0);
                const newItems = employeeCompetencyItems.items.filter(i => Object.prototype.isPrototypeOf.call(oldIds, i.id) ? false : (oldIds[i.id] = true));
                items = [...oldItems, ...newItems];
            } else {
                items = employeeCompetencyItems.items;
            }
            obj.setState({
                page: !refreshList ? employeeCompetencyItems.page : page,
                hasMore: employeeCompetencyItems.hasMore,
                filter: filter,
                sortColumn: sortColumn,
                sortOrderIsAsc: sortOrderIsAsc,
                items: items,
                competencyWarningLimitInDays: employeeCompetencyItems.competencyWarningLimitInDays
            });
            if (!Base.isNullOrUndefined(successCallback)) {
                successCallback();
            }
        },
        error => {
            handleApiError(error, store.customStore.dispatch);
        }).finally(() => {
            obj.isLoading = false;
            store.customStore.dispatch(storeActions.fetchEnd());
        });
    };

    refreshList = () => {
        this.searchItems(this.state.pageSize, this.state.page, this.state.filter, this.state.sortColumn, this.state.sortOrderIsAsc, false, true);
    };

    handleScrollSub = Base.debounce((obj: SettingsEmployeeCompetencyList) => {
        if (obj.isLoading || !obj.state.hasMore) return;
        if (obj.listDiv.offsetHeight - (obj.containerDiv.clientHeight + obj.containerDiv.scrollTop) < 5) {
            obj.searchItems(obj.state.pageSize, obj.state.page + 1, obj.state.filter, obj.state.sortColumn, obj.state.sortOrderIsAsc, false, false);
        }
    }, 100);

    handleScroll = (event) => {
        this.handleScrollSub(this);
    };

    componentDidMount(): void {
        this.containerDiv.addEventListener("scroll", this.handleScroll);
        this.searchItems(this.state.pageSize, this.state.page, this.state.filter, this.state.sortColumn, this.state.sortOrderIsAsc, false, false);
    }

    componentWillUnmount(): void {
        this.containerDiv.removeEventListener("scroll", this.handleScroll);
    }

    changeFilter = (filter: string) => {
        this.searchItems(this.state.pageSize, 1, filter, this.state.sortColumn, this.state.sortOrderIsAsc, true, false);
    };

    changeSortColumn = (sortColumn: string) => {
        const oldSortColumn = this.state.sortColumn;
        const sortOrderIsAsc = oldSortColumn === sortColumn ? !this.state.sortOrderIsAsc : true;
        this.searchItems(this.state.pageSize, 1, this.state.filter, sortColumn, sortOrderIsAsc, true, false);
    };

    getEditItem = (id: string) => {
        const obj = this;
        employeeService.getEmployeeCompetencyEdit(id).then(editItem => {
            obj.setState({
                showEditDialog: true,
                editItem: editItem,
                orgDocuments: editItem.employeeCompetency.documents.map(i => new Document(i))
            });
        })
            .catch(e => {
                if (e.status && e.message) {
                    AppUtils.showErrorMessage(e.message);
                }
            });
    };

    handleAdd = () => {
        this.getEditItem(Base.emptyGuid);
    };

    handleEdit = () => {
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        this.getEditItem(selectedId);
    };

    private static getSaveData = (orgDocuments: IDocument[], employeeCompetencyId: string, rowId: string, employeeId: string, competencyId:string, date: number, expireDate: number, comment: string, documents: IDocument[], removedDocumentIds: string[]): SaveData => {
        const data = new SaveData();
        // Common
        data.append("id", employeeCompetencyId);
        data.append("rowId", rowId);
        // General
        data.append("employeeId", employeeId);
        data.append("competencyId", competencyId);
        data.append("date", !Base.isNullOrUndefined(date) ? Base.utcTimeToDateStr(date) : "");
        data.append("expireDate", !Base.isNullOrUndefined(expireDate) ? Base.utcTimeToDateStr(expireDate) : "");
        data.append("comment", comment);
        // Documents
        const documentUpdates: IDocumentInfoSave[] = [];
        const documentAdds: IDocumentInfoSave[] = [];
        for (let i = 0; i < documents.length; i++) {
            const document = documents[i];
            const file = document.file;
            const isOld = Base.isNullOrUndefined(file);
            if (isOld) {
                const oldDocument = orgDocuments.find(j => j.id === document.id);
                if (!oldDocument || oldDocument.comment === document.comment) continue;
                const documentSave = new DocumentInfoSave(document);
                documentSave.relatedObjectId = employeeCompetencyId;
                documentUpdates.push(documentSave);
            } else {
                data.append("documents[]", file, file.name);
                const documentAdd = new DocumentInfoSave(document);
                documentAdd.relatedObjectId = employeeCompetencyId;
                documentAdds.push(documentAdd);
            }
        }
        data.append("removedDocumentIds", JSON.stringify(removedDocumentIds));
        data.append("documentAdds", JSON.stringify(documentAdds));
        data.append("documentUpdates", JSON.stringify(documentUpdates));
        return data;
    };

    private static validate = (employeeCompetencyId: string, rowId: string, employeeId: string, competencyId:string, date: number, expireDate: number, comment: string, documents: IDocument[], removedDocumentIds: string[], saveCallback: () => void): Promise<void> => {
        saveCallback();
        return new Promise<void>((resolve) => { resolve(); });
    };

    saveEmployee = (employeeCompetencyId: string, rowId: string, employeeId: string, competencyId:string, date: number, expireDate: number, comment: string, documents: IDocument[], removedDocumentIds: string[], okCallback: () => void) => {
        SettingsEmployeeCompetencyList.validate(employeeCompetencyId, rowId, employeeId, competencyId, date, expireDate, comment, documents, removedDocumentIds, () => {
            const saveData = SettingsEmployeeCompetencyList.getSaveData(this.state.orgDocuments, employeeCompetencyId, rowId, employeeId, competencyId, date, expireDate, comment, documents, removedDocumentIds);
            if (Base.isNullOrUndefined(saveData)) return;
            // Call server
            store.customStore.dispatch(storeActions.fetchStart());
            employeeService.saveEmployeeCompetencyEdit(saveData.formData)
                .then(success => {
                    store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                    okCallback();
                })
                .catch(error => {
                    store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                    return null;
                })
                .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
        });
    };

    handleEditDialogOk = (employeeId:string, competencyId:string, date: number, expireDate: number, comment: string, documents: IDocument[], removedDocumentIds: string[]) => {
        const obj = this;
        const employeeCompetency = this.state.editItem.employeeCompetency;
        this.saveEmployee(employeeCompetency.id, employeeCompetency.rowId, employeeId, competencyId, date, expireDate, comment, documents, removedDocumentIds, () => {
            this.setState({
                showEditDialog: false
            });
            obj.refreshList();
        });
    };

    handleEditDialogCancel = () => {
        this.setState({
            showEditDialog: false
        });
    };

    handleRemove = () => {
        const obj = this;
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        const item = this.state.items.find(i => i.id === selectedId);
        if (Base.isNullOrUndefined(item)) return;
        store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, String.format(Translations.EmployeeCompetencyRemoveConfirmation, item.employeeName, item.competencyName), () => {
            store.customStore.dispatch(storeActions.clearConfirmation());
            // Call server
            store.customStore.dispatch(storeActions.fetchStart());
            employeeService.removeEmployeeCompetency(selectedId)
                .then(success => {
                    store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                    obj.refreshList(); // TODO UPDATE ONLY CHANGED DATA
                })
                .catch(error => {
                    store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                    return null;
                })
                .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
        }, () => {
            store.customStore.dispatch(storeActions.clearConfirmation());
        }));
    };

    handleClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleDoubleClick = (id: string) => {
        this.getEditItem(id);
    };

    render() {
        const state = this.state;
        const items = state.items;
        return (
            <div className="backgroundContent">
                <div className="row commandRow main">
                    <ToolTitle
                        title={Translations.Competencies}
                    />
                    <div className="col">
                        <ToolButton
                            title={Translations.Add}
                            enabled={true}
                            classes={"round left add"}
                            onClick={this.handleAdd}
                        />
                        <ToolButton
                            title={Translations.Edit}
                            enabled={!!this.state.selectedId}
                            classes={"round left edit"}
                            onClick={this.handleEdit}
                        />
                        <ToolButton
                            title={Translations.Remove}
                            enabled={!!this.state.selectedId}
                            classes={"round left remove"}
                            onClick={this.handleRemove}
                        />
                    </div>
                    <div className="col-auto right">
                        <ListSearchFilter
                            searchFilter={this.state.filter}
                            onSearchClick={this.changeFilter}
                        />
                    </div>
                </div>
                <div>
                    <SettingsEmployeeCompetencyListLineHeader
                        sortColumn={state.sortColumn}
                        sortOrderIsAsc={state.sortOrderIsAsc}
                        onColumnClick={this.changeSortColumn}
                    />
                </div>
                <div className="listContainer main employeeCompetenciesContainer" ref={(elem) => { this.containerDiv = elem; }}>
                    <div className="list employeeCompetencies striped">
                        <div className="lineContainer" ref={(elem) => { this.listDiv = elem; }}>
                            {items.map((item) =>
                                <SettingsEmployeeCompetencyListLine
                                    key={item.id}
                                    item={item}
                                    selectedId={state.selectedId}
                                    competencyWarningLimitInDays={state.competencyWarningLimitInDays}
                                    onClick={this.handleClick}
                                    onDoubleClick={this.handleDoubleClick}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {this.state.showEditDialog && !Base.isNullOrUndefined(state.editItem) &&
                    <SettingsEmployeeDialogEmployeeCompetencyDialog
                        employeeId={state.editItem.employeeId}
                        employees={state.editItem.employees}
                        competencies={state.editItem.competencies}
                        item={state.editItem.employeeCompetency}
                        isNew={state.editItem.employeeCompetency.id === Base.emptyGuid}
                        onOk={this.handleEditDialogOk}
                        onCancel={this.handleEditDialogCancel}
                    />
                }
            </div>
        );
    }
}