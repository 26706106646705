import { getApiCall, postFormApiCall } from "./tokenService";
import { SaveSuccessItems, ApiSuccess } from "./baseService";
import { ISalaryPeriodItem } from "../models/salaryPeriod/salaryPeriodItem";
import { EventLogItem } from "../models/eventLog/eventLogItem";

export const saveSalaryPeriods = (data: FormData): Promise<SaveSuccessItems> => {
    return postFormApiCall<SaveSuccessItems>("api/salaryperiod/save", data, SaveSuccessItems);
};

export const removeSalaryPeriods = (data: FormData): Promise<ApiSuccess> => {
    return postFormApiCall<ApiSuccess>("api/salaryperiod/remove", data, ApiSuccess);
};
export const getSalaryPeriodItems = (salaryPeriodTypeId: string): Promise<ISalaryPeriodItem[]> => {
    return getApiCall<ISalaryPeriodItem[]>("api/salaryperiod?salaryPeriodTypeId=" + salaryPeriodTypeId, null);
};

export const getSalaryPeriodEvents = (salaryPeriodId: string): Promise<EventLogItem[]> => {
    return getApiCall<EventLogItem[]>("api/salaryperiod/events?salaryPeriodId=" + salaryPeriodId, null);
};
