import React from "react";
import {
    LunchBreakOption,
    TESCalculationParameters,
} from "../../models/calculation/TESCalculationParameters";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Typography, Tooltip, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import MuiSelect from "../framework/muiSelect";
import { NumberInput } from "../framework/numberInput";

interface LunchBreakProps {
    translate: (str: string) => string;
    handleChange: (attr: string, value: any) => void;
    settings: TESCalculationParameters;
    setSettings: (
        value: React.SetStateAction<TESCalculationParameters>
    ) => void;
}

export const LunchBreak = ({
    translate,
    handleChange,
    settings,
    setSettings,
}: LunchBreakProps) => {
    const { dailyBreakDuration, stdShiftDuration, lunchBreakOption } =
        settings || {};

    const handleOptionChange = (value: LunchBreakOption) => {
        const newSettings = { ...settings };
        newSettings.lunchBreakOption = value;
        newSettings.isPaidLunchBreakOn = value === LunchBreakOption.Paid;

        if (value === LunchBreakOption.None) {
            newSettings.dailyBreakDuration = 0;
            newSettings.stdShiftDuration = 0;
        }

        setSettings(newSettings);
    };

    return (
        <Grid2 container direction="column" spacing={2} p={3}>
            <Grid2>
                <Typography variant="h3">{translate("lunchBreak")}</Typography>
            </Grid2>
            <Grid2 container className="field-width-normal">
                <Grid2 flex={12}>
                    <MuiSelect
                        fullWidth={true}
                        value={lunchBreakOption}
                        onChange={handleOptionChange}
                        options={Object.keys(LunchBreakOption).map((key) => ({
                            value: LunchBreakOption[key],
                            label: translate(
                                `lunchBreakOption.${LunchBreakOption[key]}`
                            ),
                        }))}
                    />
                </Grid2>

                <Grid2 mt={1} xs={true}>
                    <Tooltip
                        title={translate(
                            `lunchBreakHelper.${lunchBreakOption}`
                        )}
                    >
                        <IconButton disableRipple>
                            <InfoIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                </Grid2>
            </Grid2>
            {lunchBreakOption !== LunchBreakOption.None ? (
                <>
                    <Grid2 className="field-width-normal">
                        <NumberInput
                            id="dailyBreakDuration"
                            value={dailyBreakDuration}
                            label={translate("DAILYBREAKDURATION")}
                            onChange={(val) =>
                                handleChange("dailyBreakDuration", val)
                            }
                            endAdornment="min"
                        />
                    </Grid2>
                    <Grid2 className="field-width-normal">
                        <NumberInput
                            id="stdShiftDuration"
                            value={stdShiftDuration}
                            label={translate("STDSHIFTDURATION")}
                            onChange={(val) =>
                                handleChange("stdShiftDuration", val)
                            }
                            endAdornment="min"
                        />
                    </Grid2>
                </>
            ) : null}
        </Grid2>
    );
};
