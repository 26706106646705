import * as React from "react";
import { useEffect, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Translations } from "../../models/translations";
import { WorkTimeDetailsList } from "./workTimeDetailsList";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { customHistory } from "../../framework/customHistory";
import MuiSelect from "../framework/muiSelect";
import { generatePath, RouteComponentProps, useParams } from "react-router-dom";
import { WorkHoursPerWeek } from "./workHoursPerWeek";
import { WorkHoursPerSalaryPeriod } from "./workHoursPerSalaryPeriod";
import { WorkTimeDetailsDayBookingList } from "./workTimeDetailsDayBookingList";
import { IWorkTimeDetailsPage } from "./workTimeBetaDetailsPageMain";
import { DayBookingItemBeta } from "../../models/dayBooking/dayBookingItemBeta";
import { WorkTimeDetailsFormTab } from "./workTimeDetailsFormTab";
import { useReload } from "../../hooks";
import { WorkTimeDetailsCalendar } from "./workTimeDetailsCalendar";
import { useAppDispatch } from "../../framework/customStore";
import { useAsyncThunkAction } from "../../hooks/useAsyncThunkAction";
import { fetchWorkTimeTypes } from "../../store/workShiftTimeSlotSlice";
import { Base } from "../../framework/base";
import { handleApiError } from "../../models/store/storeEffects";
import { WorkTimeDetailsMap } from "./workTimeDetailsMap";
import { WorkTimeDetailsLayout } from "../layout/workTimeDetailsLayout";
import { WorkTimeTimeline } from "./workTimeTimeline";
import { getDayBookingItems } from "../../services/dayBookingService";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import { SalaryPeriodItem } from "../../models/salaryPeriod/salaryPeriodItem";
import { calculatedWorkHours, normalWorkHours } from "../../store/workHoursSlice";
import { fetchWorkShifts } from "../../store/workShiftSlice.";
import { useSelectWorkShiftTimeSlotsByWorkShiftDate } from "../../hooks/workTime/useSelectWorkShiftTimeSlots";
import { useSelectWorkShiftsFetched } from "../../hooks/workTime/useSelectWorkShifts";
import * as dayBookingTypeService from "../../services/dayBookingTypeService";
import { DayBookingTypeItemBeta } from "../../models/dayBookingType/dayBookingTypeItemBeta";
import * as store from "../../framework/customStore";
import { DayAllowanceSelect } from "./dayAllowanceSelect";
import { PrivateRoute } from "../app/privateRoute";
import { ISalaryRowTypeItem } from "../../models/salary/salaryRowTypeItem";
import { getSalaryRowTypes } from "../../services/workTimeBetaService";
import { EmployeeListItemDto } from "../../models/employee/employee";
import { useFetch } from "../../hooks/useFetch";
import { getEmployees } from "../../services/employeeServiceNew";

interface IWorkTimeDetailsMainProps {
    backPath: string;
}

export function WorkTimeDetailsMain(
    props: RouteComponentProps & IWorkTimeDetailsMainProps
) {
    const [workShiftsLoaded, setWorkShiftsLoaded] = React.useState<boolean>(false);
    const [loadingDayBookings, setLoadingDayBookings] = React.useState<boolean>(false);
    const { employeeId, date } = useParams<IWorkTimeDetailsPage>();
    const [allEmployeeItems, setAllEmployeeItems] =
        React.useState<EmployeeListItemDto[]>([]);
    const [reloadListKey, reloadList] = useReload();
    const [editDayBookingItem, setEditDayBookingItem] =
        React.useState<DayBookingItemBeta>();
    const [dayBookingItems, setDayBookingItems] = React.useState<DayBookingItemBeta[]>();
    const [selectedSalaryPeriodItem, setSelectedSalaryPeriodItem] =
        React.useState<SalaryPeriodItem>();
    const dispatch = useAppDispatch();
    const [dayBookingTypes, setDayBookingTypes] = useState<DayBookingTypeItemBeta[]>();
    const [salaryRowTypes, setSalaryRowTypes] = useState<ISalaryRowTypeItem[]>();

    const workShiftTimeSlots = useSelectWorkShiftTimeSlotsByWorkShiftDate(
        employeeId,
        date,
        date
    );

    const workShiftsFetched = useSelectWorkShiftsFetched();

    useEffect(() => {
        // Set workShifts as loaded when they have been fetched the first time.
        // This is done to prevent the form from closing once the first fetch is complete.
        if (workShiftsFetched) setWorkShiftsLoaded(workShiftsFetched);
    }, [workShiftsFetched]);

    useFetch(
        (signal) => getEmployees(null, signal),
        {
            onSuccess: (res) => setAllEmployeeItems(res.data),
        },
        []
    );

    useAsyncThunkAction(
        () => {
            if (date && employeeId) {
                return fetchWorkShifts({
                    employeeIds: employeeId,
                    startDate: date,
                    endDate: date,
                });
            }
        },
        {
            onError: (e) => handleApiError(e, dispatch),
        },
        [employeeId, date, reloadListKey]
    );

    const fetchStartDate = Base.dayjsToJsonDate(
        Base.minDate([
            Base.dateStartOf(Base.dateStartOf(date, "month"), "week"),
            selectedSalaryPeriodItem?.startDate,
        ])
    );

    const fetchEndDate = Base.dayjsToJsonDate(
        Base.maxDate([
            Base.dateEndOf(Base.dateEndOf(date, "month"), "week"),
            selectedSalaryPeriodItem?.endDate,
        ])
    );

    useAsyncThunkAction(
        () =>
            normalWorkHours({
                employeeId,
                startDate: fetchStartDate,
                endDate: fetchEndDate,
            }),
        {},
        [employeeId, fetchStartDate, fetchStartDate, reloadListKey]
    );

    useAsyncThunkAction(
        () => {
            if (
                selectedSalaryPeriodItem?.startDate &&
                selectedSalaryPeriodItem?.endDate
            ) {
                return calculatedWorkHours({
                    employeeId,
                    startDate: selectedSalaryPeriodItem?.startDate,
                    endDate: selectedSalaryPeriodItem?.endDate,
                });
            }
        },
        {},
        [
            employeeId,
            selectedSalaryPeriodItem?.startDate,
            selectedSalaryPeriodItem?.endDate,
            reloadListKey,
        ]
    );

    useAsyncThunkAction(
        // Used in form and timeline
        () => fetchWorkTimeTypes(),
        { onError: (e) => handleApiError(e, dispatch) },
        []
    );

    const loadDayBookings = async() => {
        setLoadingDayBookings(true);
        try {
            const result = await getDayBookingItems(
                date,
                date,
                employeeId
            );
            setDayBookingItems(result);
        } catch (error) {
            dispatch(
                storeActions.showErrorMessage(
                    baseService.getErrorMessageFromError(error)
                )
            );
        } finally {
            setLoadingDayBookings(false);
        }
    };

    const loadDayBookingTypes = async() => {
        try {
            const result = await dayBookingTypeService.getDayBookingTypes();
            setDayBookingTypes(result);
        } catch (error) {
            store.customStore.dispatch(
                storeActions.showErrorMessage(
                    baseService.getErrorMessageFromError(error)
                )
            );
        }
    };

    const loadSalaryRowTypes = () => {
        getSalaryRowTypes().then(
            (result) => {
                setSalaryRowTypes(result);
            },
            (error) => {
                handleApiError(error, store.customStore.dispatch);
            }
        );
    };

    useEffect(() => {
        void loadDayBookingTypes();
        void loadSalaryRowTypes();
    }, []);

    useEffect(() => {
        if (date) {
            void loadDayBookings();
        }
    }, [employeeId, date, reloadListKey]);

    const noWorkTimes = workShiftTimeSlots.length === 0;
    const noDayBookings = dayBookingItems?.length === 0;
    const allWorkTimesLocked = !noWorkTimes && workShiftTimeSlots.every(slot => slot.state === 2); // WorkShiftTimeSlotState.Locked

    return date && employeeId && dayBookingItems && dayBookingTypes ? (
        <WorkTimeDetailsLayout
            calendarComponent={
                <WorkTimeDetailsCalendar
                    path={props.match.path}
                    reloadKey={reloadListKey}
                />
            }
            mapComponent={
                noWorkTimes ? null : (
                    <WorkTimeDetailsMap
                        workShiftTimeSlots={workShiftTimeSlots}
                    />
                )
            }
            topComponent={
                <>
                    <Grid2 container columns={36} alignItems="center">
                        <Grid2 xs={1}>
                            <IconButton
                                style={{ backgroundColor: "transparent" }}
                                onClick={() =>
                                    customHistory.push(props.backPath)
                                }
                            >
                                <ArrowBackIcon color="primary" />
                            </IconButton>
                        </Grid2>
                        <Grid2 xs={9} paddingLeft={3} minWidth={300}>
                            <MuiSelect
                                value={employeeId}
                                options={allEmployeeItems.map((i) => ({
                                    value: i.id,
                                    label: i.fullName,
                                    group: i.salaryGroupName,
                                }))}
                                onChange={(value: string) => {
                                    if (value && value !== employeeId) {
                                        customHistory.push(
                                            generatePath(props.match.path, {
                                                employeeId: value,
                                                date,
                                            })
                                        );
                                    }
                                }}
                            />
                        </Grid2>
                        <Grid2
                            container
                            gap={1}
                            direction="column"
                            xsOffset="auto"
                            alignItems="end"
                        >
                            <Grid2>
                                <WorkHoursPerSalaryPeriod
                                    allEmployeeItems={allEmployeeItems}
                                    selectedSalaryPeriodItem={
                                        selectedSalaryPeriodItem
                                    }
                                    setSelectedSalaryPeriodItem={
                                        setSelectedSalaryPeriodItem
                                    }
                                />
                            </Grid2>
                            <Grid2>
                                <WorkHoursPerWeek />
                            </Grid2>
                        </Grid2>
                    </Grid2>
                </>
            }
            formComponent={
                <PrivateRoute
                    component={WorkTimeDetailsFormTab}
                    componentProps={{
                        afterSave: reloadList,
                        editDayBookingItem: editDayBookingItem,
                        setEditDayBookingItem: setEditDayBookingItem,
                        workShiftTimeSlotItems: workShiftTimeSlots,
                        open: workShiftsLoaded && noWorkTimes,
                        salaryRowTypes: salaryRowTypes,
                    }}
                />
            }
            radioButtonComponent={
                noWorkTimes && noDayBookings ? null : (
                    <DayAllowanceSelect
                        key={`${date}-${employeeId}`}
                        reloadList={reloadList}
                        dayBookingTypes={dayBookingTypes}
                        dayBookingItems={dayBookingItems}
                        allWorkTimesLocked={allWorkTimesLocked}
                        loadingDayBookings={loadingDayBookings}
                    />
                )
            }
            timelineComponent={
                noWorkTimes ? null : (
                    <WorkTimeTimeline
                        reloadList={reloadList}
                        workShiftTimeSlotItems={workShiftTimeSlots}
                    />
                )
            }
            mainComponent={
                noWorkTimes ? null : (
                    <WorkTimeDetailsList
                        reloadList={reloadList}
                        workShiftTimeSlotItems={workShiftTimeSlots}
                        salaryRowTypes={salaryRowTypes}
                    />
                )
            }
            bottomComponent={
                noDayBookings ? null : (
                    <WorkTimeDetailsDayBookingList
                        reloadList={reloadList}
                        dayBookingItems={dayBookingItems}
                        setEditDayBookingItem={setEditDayBookingItem}
                        dayBookingTypes={dayBookingTypes}
                    />
                )
            }
        />
    ) : (
        <div>{Translations.Loading}...</div>
    );
}
