/* global JSX */
// WorkOrderEditRouteEditorRoutePointTimeLine - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Divider, Grid } from "@mui/material";
import LocalShippingOutlined from "@mui/icons-material/LocalShipping";
import WarehouseRounded from "@mui/icons-material/WarehouseRounded";
import { Translations } from "../../models/translations";
import { AppUtils } from "../../models/common/appUtils";
import { ToolButton } from "../framework/toolButton";
import { Base } from "../../framework/base";
import { IStorageProductBooking } from "../../models/storage/storageProductBooking";
import { IRoutePointWorkShiftTimeSlotItem, RoutePointWorkShiftTimeSlotItem } from "../../models/routePoint/routePointWorkShiftTimeSlotItem";
import { IRoutePointItem, RoutePointItem } from "../../models/routePoint/routePointItem";
import { IWorkOrderEditContact, WorkOrderEditContact } from "../../models/work/workOrderEditContact";
import { EditItem } from "../../models/common/editItem";
import { IdTitle, IIdTitle } from "../../models/common/idTitle";
import { IRouteResult } from "../../framework/routeResult";

// RoutePointRoutingData
export interface IRoutePointRoutingData {
    previousRoutePoint: IRoutePointItem;
    routePoint: IRoutePointItem;
}

export interface IRoutePointRoutingResultData {
    requestData: IRoutePointRoutingData;
    resultData: IRouteResult;
}

// RouteTimelineWorkShiftTimeSlot
export interface IRouteTimelineWorkShiftTimeSlot {
    workShiftTimeSlot: IRoutePointWorkShiftTimeSlotItem;
    workShiftTimeSlotTypeName: string;
    durationMin: number;
    active: boolean;
}

export class RouteTimelineWorkShiftTimeSlot implements IRouteTimelineWorkShiftTimeSlot {
    workShiftTimeSlot: RoutePointWorkShiftTimeSlotItem;
    workShiftTimeSlotTypeName: string;
    durationMin: number;
    active: boolean;

    constructor();
    constructor(obj: IRouteTimelineRoutePoint);
    constructor(obj?: any) {
        this.workShiftTimeSlot = new RoutePointWorkShiftTimeSlotItem(obj ? obj.workShiftTimeSlot : null);
        this.workShiftTimeSlotTypeName = obj && obj.workShiftTimeSlotTypeName || "";
        this.durationMin = obj && obj.durationMin || 0;
        this.active = obj && obj.active || false;
    }
}

// RouteTimelineRoutePoint
export interface IRouteTimelineRoutePoint {
    id: string;
    routePoint: IRoutePointItem;
    routePointTypeName: string;
    name: string;
    address: string;
    begin: boolean;
    planned: boolean;
    visited: boolean;
    active: boolean;
    contact: IWorkOrderEditContact;
    contactInto: string;
    fromPreviousDistanceM: number;
    fromPreviousDurationS: number;
    timelineWorkShiftTimeSlots: IRouteTimelineWorkShiftTimeSlot[];
    productsToFetch: IStorageProductBooking[];
    productsToDeliver: IStorageProductBooking[];

    clearPlannedData();
    getHash(): string;
}

export class RouteTimelineRoutePoint implements IRouteTimelineRoutePoint {
    id: string;
    routePoint: RoutePointItem;
    routePointTypeName: string;
    name: string;
    address: string;
    begin: boolean;
    planned: boolean;
    visited: boolean;
    active: boolean;
    contact: WorkOrderEditContact;
    contactInto: string;
    fromPreviousDistanceM: number;
    fromPreviousDurationS: number;
    timelineWorkShiftTimeSlots: RouteTimelineWorkShiftTimeSlot[];
    productsToFetch: IStorageProductBooking[];
    productsToDeliver: IStorageProductBooking[];

    constructor();
    constructor(obj: IRouteTimelineRoutePoint);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.routePoint = new RoutePointItem(obj ? obj.routePoint : null);
        this.routePointTypeName = obj && obj.routePointTypeName || "";
        this.name = obj && obj.name || "";
        this.address = obj && obj.address || "";
        this.begin = obj && obj.begin || false;
        this.planned = obj && obj.planned || false;
        this.visited = obj && obj.visited || false;
        this.active = obj && obj.active || false;
        this.contact = null;
        if (obj && obj.contact) {
            this.contact = new WorkOrderEditContact(obj ? obj.contact : null);
        }
        this.contactInto = obj && obj.contactInto || "";
        this.fromPreviousDistanceM = obj && obj.fromPreviousDistanceM || 0;
        this.fromPreviousDurationS = obj && obj.fromPreviousDurationS || 0;
        this.timelineWorkShiftTimeSlots = Base.getTypedArray<RouteTimelineWorkShiftTimeSlot>(obj ? obj.timelineWorkShiftTimeSlots : null, RouteTimelineWorkShiftTimeSlot);
        this.productsToFetch = obj?.productsToFetch;
        this.productsToDeliver = obj?.productsToDeliver;
    }

    clearPlannedData() {
        this.fromPreviousDistanceM = 0;
        this.fromPreviousDurationS = 0;
    }

    getHash(): string {
        return this.id + "#" + this.routePoint.getHash() + "#" + this.routePoint.contactId + "#" + EditItem.getHash(this.timelineWorkShiftTimeSlots.map(i => i.workShiftTimeSlot));
    }

    static createRouteTimelineRoutePoint(routePoint: IRoutePointItem, routePointTypes: IIdTitle[], contacts: IWorkOrderEditContact[]): IRouteTimelineRoutePoint {
        const result = new RouteTimelineRoutePoint();
        result.routePoint = routePoint;
        result.id = Base.getGuid();
        result.routePointTypeName = IdTitle.getTitleById(routePointTypes, routePoint.routePointTypeId);
        result.name = routePoint.getName();
        result.address = routePoint.getAddress();
        result.fromPreviousDistanceM = routePoint.fromPreviousDistanceM;
        result.fromPreviousDurationS = routePoint.fromPreviousDurationS;
        const contact = Base.getItemById(contacts, routePoint.contactId);
        result.contact = contact;
        result.contactInto = contact ? Base.getStringWithSeparators([contact.name, contact.phone, contact.email], ", ") : "";
        return result;
    }
}

// RouteTimeline
export interface IRouteTimeline {
    timelineRoutePoints: IRouteTimelineRoutePoint[];
    transferWorkShiftTimeSlotTypeIds: string[];
    activeWorkShiftTimeSlotTypeId: string;
    activeWorkShiftTimeSlot: IRoutePointWorkShiftTimeSlotItem;
}

export class RouteTimeline implements IRouteTimeline {
    timelineRoutePoints: RouteTimelineRoutePoint[];
    transferWorkShiftTimeSlotTypeIds: string[];
    activeWorkShiftTimeSlotTypeId: string;
    activeWorkShiftTimeSlot: RoutePointWorkShiftTimeSlotItem;

    constructor();
    constructor(obj: IRouteTimelineRoutePoint);
    constructor(obj?: any) {
        this.activeWorkShiftTimeSlotTypeId = obj && obj.activeWorkShiftTimeSlotTypeId || "";
        this.activeWorkShiftTimeSlot = obj && obj.activeWorkShiftTimeSlot || null;
        this.transferWorkShiftTimeSlotTypeIds = obj && obj.transferWorkShiftTimeSlotTypeIds || [];
        this.timelineRoutePoints = Base.getTypedArray<RouteTimelineRoutePoint>(obj ? obj.timelineWorkShiftTimeSlots : null, RouteTimelineRoutePoint);
    }
}

// WorkOrderEditRouteEditorRoutePointWorkShiftTimeSlot
export interface IWorkOrderEditRouteEditorRoutePointWorkShiftTimeSlotProp {
    classes?: string;
    timelineWorkShiftTimeSlot: IRouteTimelineWorkShiftTimeSlot;
}

export class WorkOrderEditRouteEditorRoutePointWorkShiftTimeSlot extends React.Component<IWorkOrderEditRouteEditorRoutePointWorkShiftTimeSlotProp, {}> {
    render() {
        const props = this.props;
        const timelineWorkShiftTimeSlot = props.timelineWorkShiftTimeSlot;
        const workShiftTimeSlot = timelineWorkShiftTimeSlot.workShiftTimeSlot;
        return (
            <tr className={"timelineWorkShiftTimeSlot" + (props.classes ? " " + props.classes : "")}>
                <td>
                    {timelineWorkShiftTimeSlot.active &&
                        <svg className="clock"/>
                    }
                    <span className="workShiftTimeSlotType">{timelineWorkShiftTimeSlot.workShiftTimeSlotTypeName}</span>
                </td>
                <td>
                    <span className="time">{Base.timeToTimeStr(workShiftTimeSlot.startTime) + (workShiftTimeSlot.endTime ? " - " + (!timelineWorkShiftTimeSlot.active && timelineWorkShiftTimeSlot.durationMin >= 0 ? Base.timeToTimeStr(workShiftTimeSlot.endTime) : "") : " -")}</span>
                </td>
            </tr>
        );
    }
}

// WorkOrderEditRouteEditorRoutePointWorkShiftTimeSlots
export interface IWorkOrderEditRouteEditorRoutePointWorkShiftTimeSlotsProp {
    timelineWorkShiftTimeSlots: IRouteTimelineWorkShiftTimeSlot[];
}

export class WorkOrderEditRouteEditorRoutePointWorkShiftTimeSlots extends React.Component<IWorkOrderEditRouteEditorRoutePointWorkShiftTimeSlotsProp, {}> {
    render() {
        const props = this.props;
        return (
            <table className="timelineWorkShiftTimeSlots">
                {props.timelineWorkShiftTimeSlots.map(timelineWorkShiftTimeSlot =>
                    <WorkOrderEditRouteEditorRoutePointWorkShiftTimeSlot
                        key={timelineWorkShiftTimeSlot.workShiftTimeSlot.id}
                        timelineWorkShiftTimeSlot={timelineWorkShiftTimeSlot}
                    />
                )}
            </table>
        );
    }
}

// WorkOrderEditRouteEditorRoutePointStorageProductBooking
export interface IWorkOrderEditRouteEditorRoutePointStorageProductBookingProp {
    storageProductBooking: IStorageProductBooking;
}

export class WorkOrderEditRouteEditorRoutePointStorageProductBooking extends React.Component<IWorkOrderEditRouteEditorRoutePointStorageProductBookingProp, {}> {
    render() {
        const props = this.props;
        const storageProductBooking = props.storageProductBooking;
        return (
            <tr className="storageProductBooking">
                <td>
                    <span className="overflow-ellipsis mr-3">{storageProductBooking.productName}</span>
                </td>
                <td>
                    <span className="overflow-hidden">{storageProductBooking.bookedAmount} {storageProductBooking.unit}</span>
                </td>
            </tr>
        );
    }
}

// WorkOrderEditRouteEditorRoutePointStorageProductBookings
export interface IWorkOrderEditRouteEditorRoutePointStorageProductBookingsProp {
    tooltip: string;
    icon: JSX.Element;
    storageProductBookings: IStorageProductBooking[];
}

export class WorkOrderEditRouteEditorRoutePointStorageProductBookings extends React.Component<IWorkOrderEditRouteEditorRoutePointStorageProductBookingsProp, {}> {
    render() {
        const props = this.props;
        return (
            <div className="storageProductBookings">
                <Divider className="mt-2 mb-2"/>
                <Grid container className="title">
                    <Grid item className="icon-container mr-2" title={props.tooltip}>
                        {props.icon}
                    </Grid>
                    <Grid item>
                        <table>
                            {props.storageProductBookings.map(storageProductBooking =>
                                <WorkOrderEditRouteEditorRoutePointStorageProductBooking
                                    key={storageProductBooking.id}
                                    storageProductBooking={storageProductBooking}
                                />
                            )}
                        </table>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

// WorkOrderEditRouteEditorRoutePointPrefixTimeLine
export interface IWorkOrderEditRouteEditorRoutePointPrefixTimeLineProp {
    routePointTransferWorkShiftTimeSlotTypeIds: string[];
    timelineRoutePoint: IRouteTimelineRoutePoint;
    hasPreviousRoutePoint: boolean;
    plannedRoute: boolean;
}

export class WorkOrderEditRouteEditorRoutePointPrefixTimeLine extends React.Component<IWorkOrderEditRouteEditorRoutePointPrefixTimeLineProp, {}> {
    render() {
        const props = this.props;
        const timelineRoutePoint = props.timelineRoutePoint;
        const transferSlots = timelineRoutePoint.timelineWorkShiftTimeSlots.filter(i => props.routePointTransferWorkShiftTimeSlotTypeIds.indexOf(i.workShiftTimeSlot.workShiftTimeSlotTypeId) > -1);
        if (!props.hasPreviousRoutePoint && (props.plannedRoute || transferSlots.length < 1)) return null;
        const activeTransferWorkShiftTimeSlot = transferSlots.find(i => !i.workShiftTimeSlot.endTime);
        const routePoint = timelineRoutePoint.routePoint;
        return (
            <div>
                <div className={"dataPoint" + (!props.hasPreviousRoutePoint ? " start" : "")}>
                    <div className="graph">
                        <div className="line" />
                        <div className={"circle" + (activeTransferWorkShiftTimeSlot ? " active" : "") + (transferSlots.length > 0 && !activeTransferWorkShiftTimeSlot ? " visited" : "")} />
                    </div>
                    <div className="content">
                        {(props.plannedRoute
                            ? <div className="plannedData">
                                {!!routePoint.fromPreviousDistanceM &&
                                    <span className="distance">{Math.round(timelineRoutePoint.fromPreviousDistanceM / 1000).toString(10) + " " + Translations.MeasureUnitKm}</span>
                                }
                                {!!routePoint.fromPreviousDurationS &&
                                    <span className="duration">{AppUtils.getDurationStrByDurationMin(timelineRoutePoint.fromPreviousDurationS / 60)}</span>
                                }
                            </div>
                            : <WorkOrderEditRouteEditorRoutePointWorkShiftTimeSlots
                                    timelineWorkShiftTimeSlots={transferSlots}
                              />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

// WorkOrderEditRouteEditorRoutePointTimeLine
export interface IWorkOrderEditRouteEditorRoutePointTimeLineProp {
    routePointTransferWorkShiftTimeSlotTypeIds: string[];
    selectedRoutePointId?: string;
    timelineRoutePoint: IRouteTimelineRoutePoint;
    hasPreviousRoutePoint: boolean;
    hasNextRoutePoint: boolean;
    plannedRoute: boolean;
    onEditRoutePoint: () => void;
}

export class WorkOrderEditRouteEditorRoutePointTimeLine extends React.Component<IWorkOrderEditRouteEditorRoutePointTimeLineProp, {}> {
    render() {
        const props = this.props;
        const timelineRoutePoint = props.timelineRoutePoint;
        const normalSlots = timelineRoutePoint.timelineWorkShiftTimeSlots.filter(i => props.routePointTransferWorkShiftTimeSlotTypeIds.indexOf(i.workShiftTimeSlot.workShiftTimeSlotTypeId) < 0);
        const activeNormalWorkShiftTimeSlot = normalSlots.find(i => !i.workShiftTimeSlot.endTime);
        return (
            <div className="timelineRoutePoint">
                <WorkOrderEditRouteEditorRoutePointPrefixTimeLine
                    routePointTransferWorkShiftTimeSlotTypeIds={props.routePointTransferWorkShiftTimeSlotTypeIds}
                    timelineRoutePoint={props.timelineRoutePoint}
                    hasPreviousRoutePoint={props.hasPreviousRoutePoint}
                    plannedRoute={props.plannedRoute}
                />
                <div className={"valueGroup routePoint" + (!timelineRoutePoint.begin && timelineRoutePoint.planned ? " planned" : "") + (activeNormalWorkShiftTimeSlot ? " active" : "") +
                    ((normalSlots.length > 0 || timelineRoutePoint.begin) && !activeNormalWorkShiftTimeSlot ? " visited" : "") + (props.selectedRoutePointId === timelineRoutePoint.routePoint.id ? " selected" : "")}
                >
                    <Grid container>
                        <Grid item className="icon-container mr-2">
                            <div className="circle" />
                        </Grid>
                        <Grid item className="content-container">
                            <div className="title">
                                <span className="routePointType">{timelineRoutePoint.routePointTypeName}</span>
                                <span className="ml-3">{timelineRoutePoint.name}</span>
                            </div>
                            <div className="address">{timelineRoutePoint.address}</div>
                            {!!timelineRoutePoint.contactInto &&
                                <div className="contact">{timelineRoutePoint.contactInto}</div>
                            }
                        </Grid>
                        <Grid item className="action-container">
                            <ToolButton
                                title={Translations.Edit}
                                enabled={true}
                                classes="edit"
                                onClick={props.onEditRoutePoint}
                            />
                        </Grid>
                    </Grid>

                    {timelineRoutePoint.productsToFetch?.length > 0 &&
                        <WorkOrderEditRouteEditorRoutePointStorageProductBookings
                            icon={<WarehouseRounded/>}
                            tooltip={Translations.ProductsToFetch}
                            storageProductBookings={timelineRoutePoint.productsToFetch}
                        />
                    }

                    {timelineRoutePoint.productsToDeliver?.length > 0 &&
                        <WorkOrderEditRouteEditorRoutePointStorageProductBookings
                            icon={<LocalShippingOutlined/>}
                            tooltip={Translations.ProductsToDeliver}
                            storageProductBookings={timelineRoutePoint.productsToDeliver}
                        />
                    }

                    {(!timelineRoutePoint.planned && normalSlots.length > 0) &&
                        <div>
                            <Divider className="mt-2 mb-2"/>
                            <WorkOrderEditRouteEditorRoutePointWorkShiftTimeSlots
                                timelineWorkShiftTimeSlots={normalSlots}
                            />
                        </div>
                    }
                </div>
            </div>
        );
    }
}
