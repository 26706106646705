import { DndContext, DragEndEvent, DragOverlay, UniqueIdentifier, rectIntersection, useDndMonitor, useDraggable, useDroppable } from "@dnd-kit/core";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { Chip } from "@mui/material";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../framework/customStore";
import { setActiveDraggableId } from "../../store/transport/transportVehiclesSlice";
import { useRectIntersection } from "../hooks/useRectIntersection";
import { useCustomPointerWithin } from "../hooks/useCustomPointerWithin";

// See guide: https://docs.dndkit.com/introduction/getting-started
// TODO should work on touch screens

/*

Usage:
<DragContext>
    <Droppable id="drop1">
        <div>drop here</div>
    </Droppable>
    <Draggable>
        <div>drag here</div>
    </Draggable>
</DragContext>
*/

interface DraggableContext {
    draggedNode: React.ReactNode | null;
    setDraggedNode: (node: React.ReactNode | null) => void;
}

const DraggableContext = createContext<DraggableContext | null>(null);

interface DndItem<T> {
    /**
     * Identifier inside a given DragContext.
     */
    id: UniqueIdentifier;
    data?: T;
}

function getDropHandlerArgs(event: DragEndEvent) {
    return {
        draggable: {
            id: event.active.id,
            data: event.active.data.current?.rawData
        },
        droppable: {
            id: event.over.id,
            data: event.over.data.current?.rawData
        }
    };
}

export type dndDropHandler<TDroppable = unknown, TDraggable = unknown> = (args: {draggable: DndItem<TDraggable>; droppable: DndItem<TDroppable>}) => void;

/*
DragOverlay is needed to allow dragging from scrollable containers or virtual lists.
We need this extra context wiring, so draggables can set the element that is currently dragged
and we can then render it in the DragOverlay.
*/

interface DragContextProps<TDroppable, TDraggable> {
    onDrop?: dndDropHandler<TDroppable, TDraggable>;
    children?: React.ReactNode;
}

export const DragContext = <TDroppable, TDraggable>(props: DragContextProps<TDroppable, TDraggable>) => {
    const [currentDraggedNode, setCurrentDraggedNode] = useState(null);

    const context: DraggableContext = {
        draggedNode: null,
        setDraggedNode: (node) => setCurrentDraggedNode(node)
    };

    const handleDragEnd = (event: DragEndEvent) => {
        context.setDraggedNode(null);
        if (!event.over) return;

        props.onDrop?.(getDropHandlerArgs(event));
    };

    return (
        <DraggableContext.Provider value={context} >
            <DndContext onDragEnd={handleDragEnd} autoScroll={false} collisionDetection={useCustomPointerWithin}>
                {props.children}
                <DragOverlay>
                    {currentDraggedNode && currentDraggedNode}
                </DragOverlay>
            </DndContext>
        </DraggableContext.Provider>
    );
};

interface DroppableProps<TDroppable, TDraggable> extends DndItem<TDroppable> {
    children?: React.ReactNode;
    onDrop?: dndDropHandler<TDroppable, TDraggable>;
    shouldHighlight?: boolean;
}

export const Droppable = <TDroppable, TDraggable>(props: DroppableProps<TDroppable, TDraggable>) => {
    const dispatch = useAppDispatch();

    const { setNodeRef, isOver, active } = useDroppable({
        id: props.id,
        data: {
            rawData: props.data
        }
    });

    useDndMonitor({
        onDragEnd: (event) => {
            if (!event.over) return;
            if (event.over.id === props.id) {
                props?.onDrop?.(getDropHandlerArgs(event));
            }
        },
        onDragStart: (event) => {
            dispatch(setActiveDraggableId(event.active.id));
        }
    });

    return (
        <div className={"droppable" + (active ? " droppable-active" : "") + (isOver && props.shouldHighlight ? " droppable-over" : "")} ref={setNodeRef}>
            {props.children}
            {active && <div className={"droppable-overlay" + (isOver && props.shouldHighlight ? " droppable-overlay-active" : "")} />}
        </div>
    );
};

interface DraggableProps<TDraggable, TDroppable> extends DndItem<TDraggable> {
    /**
     * Children should be very simple stateless components
     */
    children?: React.ReactNode;
    onDrop?: dndDropHandler<TDroppable, TDraggable>;
}

export const Draggable = <TDraggable, TDroppable>(props: DraggableProps<TDraggable, TDroppable>) => {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: props.id,
        data: {
            rawData: props.data
        }
    });

    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    } : undefined;

    const draggableContext = useContext(DraggableContext);

    useDndMonitor({
        onDragEnd: (event) => {
            draggableContext.setDraggedNode(null);
            if (!event.over) return;
            if (event.active.id === props.id) {
                props.onDrop?.(getDropHandlerArgs(event));
            }
        },
        onDragStart: (event) => {
            if (event.active.id === props.id) {
                draggableContext.setDraggedNode(props.children);
            }
        }
    });

    return (
        // TODO allow setting the base element, so unnecessary divs are not created
        <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
            {props.children}
        </div>
    );
};

export const DragHandle = React.memo(() => <DragHandleIcon />);

interface UseDragHandleArgs<TDraggable, TDroppable> extends DndItem<TDraggable> {
    onDrop?: dndDropHandler<TDroppable, TDraggable>;
}

/**
 * Returns the necessary properties for handle and container, so they can be freely placed within a component.
 */
export const useDragHandle = <TDraggable, TDroppable>(args: UseDragHandleArgs<TDraggable, TDroppable>) => {
    const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform } = useDraggable({
        id: args.id,
        data: {
            rawData: args.data
        }
    });

    const handleProps = useMemo(() => {
        return {
            ref: setActivatorNodeRef,
            ...attributes,
            ...listeners
        };
    }, []);

    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    } : undefined;

    const containerProps = useMemo(() => {
        return {
            ref: setNodeRef,
            style: style
        };
    }, [style]);

    useDndMonitor({
        onDragEnd: (event) => {
            if (!event.over) return;
            if (event.active.id === args.id) {
                args.onDrop?.(getDropHandlerArgs(event));
            }
        }
    });

    return { handleProps, containerProps };
};

export const ExampleComponentWithDragHandle = () => {

    const {
        handleProps, containerProps
    } = useDragHandle({
        id: "draggableWithHandle",
        data: {
            foo: "bar"
        },
        onDrop: (args) => alert(`Dragged with handle:\n ${JSON.stringify(args, null, 2)}`)
    });

    return (
        <div {...containerProps}>
            <span {...handleProps}><DragHandle /></span>
            <Chip label="Draggable with handle" />
        </div>
    );
};
