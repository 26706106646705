import React, { memo } from "react";
import Box from "@mui/material/Box";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import Stack from "@mui/material/Stack";
import { TimelineGridRow } from "../../timelinePlanner/timelinePlannerGrid";
import { Translations } from "../../../models/translations";
import { TransportPlanListItemDto } from "../../../models/transport/transportPlan";
import { DateActions } from "../transportTimeline/DateActions";
import { TransportTimelineCell } from "../transportTimeline/TransportTimelineCell";


interface TransportPlansWithoutVehicleTimelineRowProps {
    items: TransportPlanListItemDto[];
}

export const TransportPlansWithoutVehicleTimelineRow = memo((props: TransportPlansWithoutVehicleTimelineRowProps) => {
    return (
        <>
            <TimelineGridRow
                renderColumnHeader={(date: Date) => (
                    <DateActions date={date} vehicleId="" />
                )}
                infoColumn={
                    <Box sx={{ fontSize: "small", pt: 1, pb: 1 }}>
                        <Stack direction="row" justifyContent="space-between">
                            <div className="bold flex align-items-center overflow-break">
                                <LocalShippingOutlinedIcon
                                    sx={{ mr: 1, color: "error.main" }}
                                    fontSize="small"
                                />
                                <span>
                                    {Translations.NoVehicle}
                                </span>
                            </div>
                        </Stack>
                    </Box>
                }
            >
                {props.items &&
                    props.items.map((item) => (
                        <TransportTimelineCell planId={item.id} key={item.id} editEmployeeEnabled editVehicleEnabled />
                    ))}
            </TimelineGridRow>
        </>
    );
});