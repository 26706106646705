// SettingsSiteDialogRoutePointList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as locationService from "../../services/locationService";
import { Translations } from "../../models/translations";
import { ToolButton } from "../framework/toolButton";
import { Base } from "../../framework/base";
import { IRoutePointItem, RoutePointItem } from "../../models/routePoint/routePointItem";
import { SettingsRoutePointDialogRoutePointCheckList } from "./settingsRoutePointDialogRoutePointCheckList";
import { IRoutePointCheckEditItem, RoutePointCheckEditItem } from "../../models/routePointCheck/routePointCheckEditItem";
import { SaveData } from "../../framework/saveData";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { GeocodeResult } from "../../framework/geocodeResult";
import { Button } from "../framework/button";
import { LocationMap } from "../framework/locationMap";
import { RadioButton } from "../framework/radio";

// SettingsSiteDialogRoutePointListLine
export interface ISettingsSiteDialogRoutePointListLineProp {
    showAdditionalInformation: boolean;
    routePoint: IRoutePointItem;
    isReadOnly: boolean;
    selected: boolean;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

export class SettingsSiteDialogRoutePointListLine extends React.Component<ISettingsSiteDialogRoutePointListLineProp, {}> {
    render() {
        const props = this.props;
        const routePoint = props.routePoint;
        return (
            <div className={"row line routePoint view" + (props.selected ? " selected" : "") + (props.showAdditionalInformation ? " expanded" : "")} onClick={() => props.onClick(routePoint.id)} onDoubleClick={() => props.onDoubleClick(routePoint.id)}>
                {!props.showAdditionalInformation &&
                    <div className="col-12">
                        <div className="right">
                            {!!props.routePoint.latitude && !!props.routePoint.longitude &&
                                <div className="mapMarker green smallMarker left" title={Translations.LocationSuccess} />
                            }
                            {(!props.routePoint.latitude || !props.routePoint.longitude) &&
                                <div className="mapMarker red smallMarker left" title={Translations.LocationError} />
                            }
                        </div>
                        {props.routePoint.name}
                    </div>
                }
                {props.showAdditionalInformation &&
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <label className="control-label smallFont">{Translations.Name}</label>
                                <div className="readOnlyText">{props.routePoint.name}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <label className="control-label smallFont">{Translations.Specifier}</label>
                                <div className="readOnlyText">{props.routePoint.name2}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div>
                                    <div className="right">
                                        {!!props.routePoint.latitude && !!props.routePoint.longitude &&
                                            <div><div className="mapMarker green smallMarker left" /><span>{Translations.LocationSuccess}</span></div>
                                        }
                                        {(!props.routePoint.latitude || !props.routePoint.longitude) &&
                                            <div><div className="mapMarker red smallMarker left" /><span>{Translations.LocationError}</span></div>
                                        }
                                    </div>
                                    <label className="control-label smallFont">{Translations.Address}</label>
                                </div>
                                <div className="readOnlyText">{Base.getStringWithSeparators([props.routePoint.street, Base.getStringWithSeparators([props.routePoint.postalCode, props.routePoint.city], " "), props.routePoint.countryCode], ", ")}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <SettingsRoutePointDialogRoutePointCheckList
                                    title={Translations.RoutePointChecks}
                                    titleStyles={"smallFont"}
                                    readOnly={true}
                                    hideButtons={true}
                                    items={props.routePoint.checks}
                                    onRemoveRoutePointCheck={null}
                                    onAddRoutePointCheck={null}
                                    onEditRoutePointCheck={null}
                                    onMoveRoutePointCheck={null}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

// SettingsSiteDialogRoutePointListEdit
export interface ISettingsSiteDialogRoutePointListEditProp {
    mapLinkTemplate: string;
    routePoint: IRoutePointItem;
    removedCheckIds: string[];
    savedCheckIds: string[];
    onOk: (item: IRoutePointItem, removedCheckIds: string[], savedCheckIds: string[]) => void;
    onCancel: () => void;
}

interface ISettingsSiteDialogRoutePointListEditState {
    name: string;
    name2: string;
    street: string;
    postalCode: string;
    city: string;
    countryCode: string;
    mapLink: string;
    checks: IRoutePointCheckEditItem[];
    removedCheckIds: string[];
    savedCheckIds: string[];
}

export class SettingsSiteDialogRoutePointListEdit extends React.Component<ISettingsSiteDialogRoutePointListEditProp, ISettingsSiteDialogRoutePointListEditState> {
    private static orgStateHash: string = "";

    private static getHashDataFromState = (state: ISettingsSiteDialogRoutePointListEditState): string => {
        const data = new SaveData();
        data.append("name", state.name);
        data.append("name2", state.name2);
        data.append("street", state.street);
        data.append("postalCode", state.postalCode);
        data.append("city", state.city);
        data.append("countryCode", state.countryCode);
        data.append("mapLink", state.mapLink);
        data.append("checks", JSON.stringify(state.checks));
        data.append("removedCheckIds", JSON.stringify(state.removedCheckIds));
        data.append("savedCheckIds", JSON.stringify(state.savedCheckIds));
        return data.hash;
    };

    constructor(props: ISettingsSiteDialogRoutePointListEditProp) {
        super(props);
        const routePoint = props.routePoint;
        this.state = {
            name: routePoint.name,
            name2: routePoint.name2,
            street: routePoint.street,
            postalCode: routePoint.postalCode,
            city: routePoint.city,
            countryCode: routePoint.countryCode,
            mapLink: routePoint.mapLink,
            checks: routePoint.checks.slice(0),
            removedCheckIds: props.removedCheckIds.slice(0),
            savedCheckIds: props.savedCheckIds.slice(0),
        };
        SettingsSiteDialogRoutePointListEdit.orgStateHash = SettingsSiteDialogRoutePointListEdit.getHashDataFromState(this.state);
    }

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "Nm") {
            this.setState({ name: value });
        } else if (name === "Nm2") {
            this.setState({ name2: value });
        } else if (name === "Ads") {
            this.setState({ street: value });
        } else if (name === "pC") {
            this.setState({ postalCode: value });
        } else if (name === "cty") {
            this.setState({ city: value });
        } else if (name === "cntrCd") {
            this.setState({ countryCode: value });
        }
    };

    // #region RoutePointChecks
    handleRemoveRoutePointCheck = (id: string) => {
        const state = this.state;
        if (!id) return;
        const item = state.checks.find(i => i.id === id);
        if (!item) return;
        const removedCheckIds = state.removedCheckIds.filter(i => i !== id);
        if (!item.isNew()) {
            removedCheckIds.push(id);
        }
        this.setState({ checks: state.checks.filter(i => i.id !== id), savedCheckIds: state.savedCheckIds.filter(i => i !== id), removedCheckIds: removedCheckIds });
    };

    handleSaveRoutePointCheck = (item: IRoutePointCheckEditItem) => {
        const state = this.state;
        if (!item || !item.id) return;
        const checks = state.checks.filter(i => i.id !== item.id);
        const savedCheckIds = state.savedCheckIds.filter(i => i !== item.id);
        checks.push(item);
        savedCheckIds.push(item.id);
        RoutePointCheckEditItem.sortRoutePointCheckEditItems(checks, "number", true);
        this.setState({ checks: checks, savedCheckIds: savedCheckIds });
    };

    handleMoveRoutePointCheck = (id: string, step: number) => {
        const state = this.state;
        if (!id) return;
        //RoutePoints
        const checks = state.checks.slice(0);
        const index = checks.findIndex(i => i.id === id);
        if (index < 0 || index + step < 0 || index + step > checks.length - 1) return;
        const item = checks[index];
        const id2 = checks[index + step].id;
        checks[index] = checks[index + step];
        checks[index + step] = item;
        checks[index].number = index + 1;
        checks[index + step].number = index + step + 1;
        const savedCheckIds = state.savedCheckIds.filter(i => i !== id && i !== id2);
        savedCheckIds.push(id);
        savedCheckIds.push(id2);
        RoutePointCheckEditItem.sortRoutePointCheckEditItems(checks, "number", true);
        this.setState({
            checks: checks,
            savedCheckIds: savedCheckIds
        });
    };
    // #endregion RoutePointChecks

    private static validate = (state: ISettingsSiteDialogRoutePointListEditState): boolean => {
        if (!state.name) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.NameMustBeDefined));
            return false;
        }
        return true;
    };

    geocodeAddress = (): Promise<GeocodeResult> => {
        const state = this.state;
        const props = this.props;
        const item = props.routePoint;
        if ((item.street === state.street && item.postalCode === state.postalCode && item.city === state.city && item.countryCode === state.countryCode) || (!state.street && !state.postalCode && !state.city && !state.countryCode)) {
            return new Promise<GeocodeResult>((resolve) => { resolve(null); });
        }
        return locationService.geocode(state.street, state.postalCode, state.city, state.countryCode)
            .then(result => {
                return result;
            })
            .catch(error => {
                console.log(error);
                return new Promise<GeocodeResult>((resolve) => { resolve(null); });
            });
    };

    handleOkClick = () => {
        const state = this.state;
        const props = this.props;
        if (!SettingsSiteDialogRoutePointListEdit.validate(state)) return;
        const item = new RoutePointItem(props.routePoint);
        item.name = state.name;
        item.name2 = state.name2;
        item.street = state.street;
        item.postalCode = state.postalCode;
        item.city = state.city;
        item.countryCode = state.countryCode;
        item.mapLink = state.mapLink;
        item.checks = state.checks.slice(0);
        this.geocodeAddress().then(geocodeResult => {
            if (geocodeResult) {
                item.latitude = geocodeResult.latitude;
                item.longitude = geocodeResult.longitude;
                if (!item.street) {
                    item.street = geocodeResult.street;
                }
                if (!item.postalCode) {
                    item.postalCode = geocodeResult.postalCode;
                }
                if (!item.city) {
                    item.city = geocodeResult.city;
                }
                if (!item.countryCode) {
                    item.countryCode = geocodeResult.countryCode;
                }
                item.mapLink = Base.generateMapLink(props.mapLinkTemplate, geocodeResult.latitude.toFixed(6), geocodeResult.longitude.toFixed(6), null);
            } else {
                item.mapLink = Base.generateMapLink(props.mapLinkTemplate, state.street, state.postalCode, state.city);
            }
            this.props.onOk(item, state.removedCheckIds, state.savedCheckIds);
        });
    };

    handleCancelClick = () => {
        const obj = this;
        const hashData = SettingsSiteDialogRoutePointListEdit.getHashDataFromState(this.state);
        if (hashData !== SettingsSiteDialogRoutePointListEdit.orgStateHash) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.handleOkClick();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.props.onCancel();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));
        } else {
            obj.props.onCancel();
        }
    };

    render() {
        const state = this.state;
        return (
            <div className="row line routePoint edit">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12">
                            <Button
                                classes={"btn-default right"}
                                title={Translations.Cancel}
                                enabled={true}
                                onClick={this.handleCancelClick}
                            />
                            <Button
                                classes={"btn-primary right"}
                                title={Translations.OK}
                                enabled={true}
                                onClick={this.handleOkClick}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group required">
                                <label className="control-label smallFont">{Translations.Name}</label>
                                <input type="text" className="form-control" name="Nm" title={Translations.Name} value={state.name} onChange={this.handleChange} maxLength={50} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.Specifier}</label>
                                <input type="text" className="form-control" name="Nm2" title={Translations.Specifier} value={state.name2} onChange={this.handleChange} maxLength={50} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.StreetAddress}</label>
                                <input type="text" className="form-control" name="Ads" title={Translations.StreetAddress} value={state.street} onChange={this.handleChange} maxLength={50} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.PostalCode}</label>
                                <input type="text" className="form-control" name="pC" title={Translations.PostalCode} value={state.postalCode} onChange={this.handleChange} maxLength={10} />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.CityAddress}</label>
                                <input type="text" className="form-control" name="cty" title={Translations.CityAddress} value={state.city} onChange={this.handleChange} maxLength={50} />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <label className="control-label smallFont">{Translations.CountryCode}</label>
                                <input type="text" className="form-control" name="cntrCd" title={Translations.CountryCode} value={state.countryCode} onChange={this.handleChange} maxLength={50} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <SettingsRoutePointDialogRoutePointCheckList
                                title={Translations.RoutePointChecks}
                                titleStyles={"smallFont"}
                                readOnly={false}
                                items={state.checks}
                                onRemoveRoutePointCheck={this.handleRemoveRoutePointCheck}
                                onAddRoutePointCheck={this.handleSaveRoutePointCheck}
                                onEditRoutePointCheck={this.handleSaveRoutePointCheck}
                                onMoveRoutePointCheck={this.handleMoveRoutePointCheck}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// SettingsSiteDialogRoutePointListAdd
export interface ISettingsSiteDialogRoutePointListAddProp {
    mapLinkTemplate: string;
    routePoint: IRoutePointItem;
    onOk: (routePoint: IRoutePointItem) => void;
    onCancel: () => void;
}

interface ISettingsSiteDialogRoutePointListAddState {
    value: string;
}

export class SettingsSiteDialogRoutePointListAdd extends React.Component<ISettingsSiteDialogRoutePointListAddProp, ISettingsSiteDialogRoutePointListAddState> {
    constructor(props: ISettingsSiteDialogRoutePointListAddProp) {
        super(props);
        this.state = {
            value: ""
        };
    }

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "value") {
            this.setState({ value: value });
        }
    };

    private static validate = (state: ISettingsSiteDialogRoutePointListAddState): boolean => {
        if (!state.value) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.NameMustBeDefined));
            return false;
        }
        return true;
    };

    geocodeFreeText = (text: string): Promise<GeocodeResult> => {
        return locationService.geocodeFreeText(text)
            .then(result => {
                return result;
            })
            .catch(error => {
                console.log(error);
                return new Promise<GeocodeResult>((resolve) => { resolve(null); });
            });
    };

    handleOkClick = () => {
        const obj = this;
        const props = this.props;
        const state = this.state;
        if (!SettingsSiteDialogRoutePointListAdd.validate(state)) return;
        const routePoint = new RoutePointItem(props.routePoint);
        routePoint.name = state.value;
        routePoint.name2 = "";
        routePoint.street = "";
        routePoint.postalCode = "";
        routePoint.city = "";
        routePoint.countryCode = "";
        routePoint.mapLink = "";
        routePoint.latitude = null;
        routePoint.longitude = null;
        this.geocodeFreeText(state.value).then(geocodeResult => {
            if (geocodeResult) {
                routePoint.latitude = geocodeResult.latitude;
                routePoint.longitude = geocodeResult.longitude;
                routePoint.street = geocodeResult.street;
                routePoint.postalCode = geocodeResult.postalCode;
                routePoint.city = geocodeResult.city;
                routePoint.countryCode = geocodeResult.countryCode;
                routePoint.mapLink = Base.generateMapLink(props.mapLinkTemplate, geocodeResult.latitude.toFixed(6), geocodeResult.longitude.toFixed(6), null);
            } else if (!!routePoint.street || !!routePoint.postalCode || !!routePoint.city) {
                routePoint.mapLink = Base.generateMapLink(props.mapLinkTemplate, routePoint.street, routePoint.postalCode, routePoint.city);
            }
            obj.props.onOk(routePoint);
        });
    };

    handleCancelClick = () => {
        const obj = this;
        if (this.state.value) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.handleOkClick();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.props.onCancel();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));
        } else {
            obj.props.onCancel();
        }
    };

    handleKeyUp = (e) => {
        if (e.keyCode === 13) {
            this.handleOkClick();
            window.getSelection().removeAllRanges();
            e.preventDefault();
        }
    };

    render() {
        const state = this.state;
        return (
            <div id="routePointAdd" className="routePoint add">
                <div className="form-group required">
                    <label className="control-label smallFont">{Translations.Address}</label>
                    <div className="input-group">
                        <input className="form-control" type="text" name="value" title={Translations.Address} value={state.value} autoFocus={true} onKeyUp={this.handleKeyUp} onChange={this.handleChange} maxLength={250} />
                        <div className="input-group-append">
                            <button className="btn" type="button" onClick={this.handleOkClick} title={Translations.Save}>{Translations.Save}</button>
                        </div>
                    </div>
                </div>
                <ToolButton
                    classes={"close smallButton"}
                    enabled={true}
                    stopPropagation={true}
                    title={Translations.Cancel}
                    onClick={this.handleCancelClick}
                />
            </div>
        );
    }
}

// SettingsSiteDialogRoutePointList
export interface ISettingsSiteDialogRoutePointListProp {
    title?: string;
    titleId?: string;
    titleClass?: string;
    isReadOnly?: boolean;
    mapLinkTemplate: string;
    routePoints: IRoutePointItem[];
    removedRoutePointCheckIds: string[];
    savedRoutePointCheckIds: Map<string, string[]>;
    onRemoveRoutePoint: (id: string) => void;
    onAddRoutePoint: (item: IRoutePointItem, removedCheckIds: string[], savedCheckIds: string[]) => void;
    onEditRoutePoint: (item: IRoutePointItem, removedCheckIds: string[], savedCheckIds: string[]) => void;
    onMoveRoutePoint: (id: string, step: number) => void;
}

interface ISettingsSiteDialogRoutePointListState {
    editId: string;
    selectedIds: string[];
    showAdditionalInformation: boolean;
    showAddNewRoutePoint: boolean;
    newRoutePoint: IRoutePointItem;
}

export class SettingsSiteDialogRoutePointList extends React.Component<ISettingsSiteDialogRoutePointListProp, ISettingsSiteDialogRoutePointListState> {
    constructor(props) {
        super(props);
        this.state = {
            editId: "",
            selectedIds: [],
            showAdditionalInformation: false,
            showAddNewRoutePoint: false,
            newRoutePoint: null
        };
    }

    handleToggleShowAdditionalInformation = (e) => {
        e.preventDefault();
        this.setState({ showAdditionalInformation: !this.state.showAdditionalInformation });
    };

    handleMoveUp = (id: string) => {
        const props = this.props;
        if (!id || props.isReadOnly) return;
        props.onMoveRoutePoint(id, -1);
    };

    handleMoveDown = (id: string) => {
        const props = this.props;
        if (!id || props.isReadOnly) return;
        props.onMoveRoutePoint(id, 1);
    };

    handleRemove = (id: string) => {
        const props = this.props;
        if (!id || props.isReadOnly) return;
        const selectedItem = this.props.routePoints.find(i => i.id === id);
        if (!selectedItem) return;
        store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, String.format(Translations.RoutePointRemoveConfirmation, selectedItem.name), () => {
            store.customStore.dispatch(storeActions.clearConfirmation());
            props.onRemoveRoutePoint(id);
        }, () => {
            store.customStore.dispatch(storeActions.clearConfirmation());
        }));
    };

    handleEdit = (id: string) => {
        const props = this.props;
        if (!id || props.isReadOnly) return;
        this.setState({ editId: id, selectedIds: [id] });
    };

    handleRoutePointEditOk = (item: IRoutePointItem, removedCheckIds: string[], savedCheckIds: string[]) => {
        const props = this.props;
        const editId = this.state.editId;
        if (!editId) return;
        const editItem = props.routePoints.find(i => i.id === editId);
        if (!editItem) return;
        this.setState({
            editId: ""
        });
        props.onEditRoutePoint(item, removedCheckIds, savedCheckIds);
    };

    handleRoutePointEditCancel = () => {
        this.setState({
            editId: ""
        });
    };

    handleAdd = () => {
        const props = this.props;
        if (props.isReadOnly) return;
        const newRoutePoint = new RoutePointItem();
        newRoutePoint.id = Base.getGuid();
        newRoutePoint.number = props.routePoints.length > 0 ? props.routePoints.reduce((a, b) => { return Math.max(a, b.number); }, 0) + 1 : 1;
        this.setState({
            showAddNewRoutePoint: true,
            newRoutePoint: newRoutePoint
        });
    };

    handleRoutePointAddOk = (item: IRoutePointItem) => {
        const props = this.props;
        const newRoutePoint = this.state.newRoutePoint;
        if (!newRoutePoint) return;
        this.setState({
            showAddNewRoutePoint: false,
        });
        props.onAddRoutePoint(item, [], []);
    };

    handleRoutePointAddCancel = () => {
        this.setState({
            showAddNewRoutePoint: false,
        });
    };

    handleLineClick = (id: string) => {
        if (!id) return;
        this.setState({
            selectedIds: [id]
        });
    };

    handleLineDoubleClick = (id: string) => {
        this.handleEdit(id);
    };

    handleSetSelectedIds = (ids: string[]) => {
        this.setState({
            selectedIds: ids
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        const routePoints = props.routePoints;
        const views = [
            { onClick: this.handleToggleShowAdditionalInformation, showAdditionalInformation: false, classes: " list" },
            { onClick: this.handleToggleShowAdditionalInformation, showAdditionalInformation: true, classes: " grid" },
        ];
        return (
            <div>
                <div className="row">
                    <div className="col-7">
                        <div className="commandRow">
                            {!!props.title &&
                                <label id={props.titleId} className={"control-label listTitle" + (props.titleClass ? " " + props.titleClass : "")}>{props.title}</label>
                            }
                            {!props.isReadOnly &&
                                <ToolButton
                                    title={Translations.Remove}
                                    enabled={routePoints.length > 1 && state.selectedIds.length > 0}
                                    classes={"round right remove"}
                                    onClick={() => this.handleRemove(state.selectedIds[0])}
                                />
                            }
                            {!props.isReadOnly &&
                                <ToolButton
                                    title={Translations.Edit}
                                    enabled={state.selectedIds.length > 0}
                                    classes={"round right edit"}
                                    onClick={() => this.handleEdit(state.selectedIds[0])}
                                />
                            }
                            {!props.isReadOnly &&
                                <ToolButton
                                    title={Translations.Add}
                                    enabled={true}
                                    classes={"round right add"}
                                    onClick={this.handleAdd}
                                />
                            }
                            {!props.isReadOnly &&
                                <ToolButton
                                    title={Translations.MoveUp}
                                    enabled={routePoints.length > 1 && state.selectedIds.length > 0}
                                    classes={"round right up"}
                                    onClick={() => this.handleMoveUp(state.selectedIds[0])}
                                />
                            }
                            {!props.isReadOnly &&
                                <ToolButton
                                    title={Translations.MoveDown}
                                    enabled={routePoints.length > 1 && state.selectedIds.length > 0}
                                    classes={"round right down"}
                                    onClick={() => this.handleMoveDown(state.selectedIds[0])}
                                />
                            }
                            <div className="btn-group tool btn-group-toggle states right">
                                {views.map((view) =>
                                    <RadioButton
                                        key={view.showAdditionalInformation.toString()}
                                        classes={"btn-secondary state" + view.showAdditionalInformation.toString() + view.classes}
                                        enabled={true}
                                        checked={state.showAdditionalInformation === view.showAdditionalInformation}
                                        onRadioClick={view.onClick}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="listContainer routePointsContainer">
                            <div className="list routePoints">
                                {routePoints.map((routePoint) =>
                                    (routePoint.id !== state.editId
                                        ? <SettingsSiteDialogRoutePointListLine
                                                key={routePoint.id}
                                                isReadOnly={props.isReadOnly}
                                                showAdditionalInformation={state.showAdditionalInformation}
                                                selected={state.selectedIds.indexOf(routePoint.id) > -1}
                                                routePoint={routePoint}
                                                onClick={this.handleLineClick}
                                                onDoubleClick={this.handleLineDoubleClick}
                                          />
                                        : <SettingsSiteDialogRoutePointListEdit
                                                key={routePoint.id}
                                                mapLinkTemplate={props.mapLinkTemplate}
                                                routePoint={routePoint}
                                                removedCheckIds={props.removedRoutePointCheckIds}
                                                savedCheckIds={props.savedRoutePointCheckIds.get(state.editId) ?? []}
                                                onOk={this.handleRoutePointEditOk}
                                                onCancel={this.handleRoutePointEditCancel}
                                          />
                                    )
                                )}
                                {state.showAddNewRoutePoint &&
                                    <SettingsSiteDialogRoutePointListAdd
                                        mapLinkTemplate={props.mapLinkTemplate}
                                        routePoint={state.newRoutePoint}
                                        onOk={this.handleRoutePointAddOk}
                                        onCancel={this.handleRoutePointAddCancel}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-5">
                        <LocationMap
                            classes="site route routePoints"
                            points={props.routePoints}
                            selectedIds={state.selectedIds}
                            onSetSelectedIds={this.handleSetSelectedIds}
                        />
                    </div>
                </div>
            </div>
        );
    }
}