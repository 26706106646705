import { Base } from "../../framework/base";
import { IProductGroupItem, ProductGroupItem } from "./productGroupItem";

export interface IProductGroupItems {
    items: IProductGroupItem[];
    hasMore: boolean;
    page: number;
}

export class ProductGroupItems implements IProductGroupItems {
    items: ProductGroupItem[];
    hasMore: boolean;
    page: number;

    constructor();
    constructor(obj: IProductGroupItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = Base.getTypedArray<ProductGroupItem>(obj ? obj.items : null, ProductGroupItem);
    }
}