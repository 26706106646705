import { getApiCall, postFormApiCall, postApiCall } from "./tokenService";
import { WorkOrderTypeListItems } from "../models/workOrderType/workOrderTypeListItems";
import { WorkOrderTypeEdit } from "../models/workOrderType/workOrderTypeEdit";
import { ApiSuccess, SaveSuccess, SaveSuccessItems } from "./baseService";

export const getWorkOrderTypeListItems = (pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean): Promise<WorkOrderTypeListItems> => {
    return getApiCall<WorkOrderTypeListItems>("api/workordertype/?page=" + (page || "") + "&pageSize=" + (pageSize || "") +
        "&sortColumn=" + (sortColumn ? encodeURIComponent(sortColumn) : "") + "&sortOrder=" + (sortOrderIsAsc ? "asc" : "desc") +
        "&filter=" + (filter ? encodeURIComponent(filter) : ""), WorkOrderTypeListItems);
};

export const getWorkOrderTypeEdit = (id: string): Promise<WorkOrderTypeEdit> => {
    return getApiCall<WorkOrderTypeEdit>("api/workordertype/edit/?id=" + (id || ""), WorkOrderTypeEdit);
};

export const saveWorkOrderTypeEdit = (data: FormData): Promise<SaveSuccess> => {
    return postFormApiCall<SaveSuccess>("api/workordertype/save", data, SaveSuccess);
};

export const removeWorkOrderTypes = (ids: string[]): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/workordertype/remove", ApiSuccess, JSON.stringify({ ids: ids }));
};

export const saveWorkOrderTypeWorkShiftTimeSlotTypes = (data: FormData): Promise<SaveSuccessItems> => {
    return postFormApiCall<SaveSuccessItems>("api/workordertype/saveworkordertypeworkshifttimeslottypes", data, SaveSuccessItems);
};

export const removeWorkOrderTypeWorkShiftTimeSlotTypes = (data: FormData): Promise<ApiSuccess> => {
    return postFormApiCall<ApiSuccess>("api/workordertype/removeworkordertypeworkshifttimeslottypes", data, ApiSuccess);
};