export interface ICustomerItem {
    id: string;
    number: number;
    name: string;
    erp: string;
    activeState: number;
    customerTypeCode: string;
    customerTypeName: string;
}

export class CustomerItem implements ICustomerItem {
    id: string;
    number: number;
    name: string;
    erp: string;
    activeState: number;
    customerTypeCode: string;
    customerTypeName: string;

    constructor();
    constructor(obj: ICustomerItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.name = obj && obj.name || "";
        this.erp = obj && obj.erp || "";
        this.activeState = obj && obj.activeState || 0;
        this.customerTypeCode = obj && obj.customerTypeCode || "";
        this.customerTypeName = obj && obj.customerTypeName || "";
    }
}