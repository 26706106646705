import { DependencyList, useEffect } from "react";
import { useAppDispatch } from "../framework/customStore";
import { handleApiError } from "../models/store/storeEffects";
import * as storeActions from "../models/store/storeActions";

export const useFetch = <T>(
    promiseCb: (signal: AbortSignal) => Promise<T>,
    {
        onSuccess,
        withLoadingIndicator = true,
    }: {
        onSuccess?: (value: T) => void | PromiseLike<void>;
        withLoadingIndicator?: boolean;
    },
    deps?: DependencyList
) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const abortController = new AbortController();
        const promise = promiseCb(abortController.signal);

        if (promise) {
            if (withLoadingIndicator) {
                dispatch(storeActions.fetchStart());
            }

            promise
                .then(onSuccess)
                .catch((e) => {
                    if (!abortController.signal.aborted) {
                        handleApiError(e, dispatch);
                    }
                })
                .finally(() => {
                    if (withLoadingIndicator) {
                        dispatch(storeActions.fetchEnd());
                    }
                });
        }

        return () => {
            abortController.abort();
        };
    }, deps);
};
