import { StrMeasureUnit } from "../common/enums";

export class DayBookingTypeItemBeta {
    id: string;
    name: string;
    number: number;
    allowEntryInDays: boolean;
    allowEntryInHours: boolean;
    dayHoursRatio: number;
    salaryRowTypeId: string;
    salaryRowTypeMeasureUnit: string;
    salaryRowTypeDecimals: number;
    salaryRowTypeKindCode: string;
    category: number;
    calculateNormal: boolean;

    constructor();
    constructor(obj: DayBookingTypeItemBeta);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
        this.number = obj && obj.number || 0;
        this.allowEntryInDays = obj && obj.allowEntryInDays || true;
        this.allowEntryInHours = obj && obj.allowEntryInHours || true;
        this.dayHoursRatio = obj && obj.dayHoursRatio || 8;
        this.salaryRowTypeId = obj && obj.salaryRowTypeId || "";
        this.salaryRowTypeMeasureUnit = obj && obj.salaryRowTypeMeasureUnit || StrMeasureUnit.Day; // Default to day
        this.salaryRowTypeDecimals = obj && obj.salaryRowTypeDecimals || 0;
        this.salaryRowTypeKindCode = obj && obj.salaryRowTypeKindCode || "";
        this.category = obj && obj.number || 0;
        this.calculateNormal = obj && obj.calculateNormal;
    }
}
