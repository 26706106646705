// SettingsVehicleDialogEmployeeList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as employeeService from "../../services/employeeService";
import { Translations } from "../../models/translations";
import { ToolButton } from "../framework/toolButton";
import { SelectIdTitleDialog } from "../framework/selectIdTitleDialog";
import { IEndlessList, EndlessList } from "../../models/common/endlessList";
import { IdTitle, IIdTitle } from "../../models/common/idTitle";

// SettingsVehicleDialogEmployeeListLine
export interface ISettingsVehicleDialogEmployeeListLineProp {
    item: IIdTitle;
    selectedId: string;
    onClick: (id: string) => void;
}

export class SettingsVehicleDialogEmployeeListLine extends React.Component<ISettingsVehicleDialogEmployeeListLineProp, {}> {
    render() {
        const item = this.props.item;
        return (
            <div className={"row line" + (item.id === this.props.selectedId ? " selected" : "")} onClick={() => { this.props.onClick(item.id); }} title={item.title } >
                <div className="col-12">{item.title}</div>
            </div>
        );
    }
}

// SettingsVehicleDialogEmployeeList
export interface ISettingsVehicleDialogEmployeeListProp {
    title?: string;
    items: IIdTitle[];
    onRemoveEmployee: (id: string) => void;
    onAddEmployee: (id: string, title: string) => void;
}

interface ISettingsVehicleDialogEmployeeListState {
    selectedId: string;
    showSelectEmployeeDialog: boolean;
    selectEmployeeDialogItems: IIdTitle[];
}

export class SettingsVehicleDialogEmployeeList extends React.Component<ISettingsVehicleDialogEmployeeListProp, ISettingsVehicleDialogEmployeeListState> {
    constructor(props) {
        super(props);
        this.state = { selectedId: null, showSelectEmployeeDialog: false, selectEmployeeDialogItems: [] };
    }

    handleAdd = () => {
        const obj = this;
        employeeService.getEmployeeIdTitles(obj.props.items.map(i => i.id)).then(idTitleItems => {
            obj.setState({
                showSelectEmployeeDialog: true,
                selectEmployeeDialogItems: idTitleItems.items
            });
        });
    };

    handleLoadEmployees = (page: number, filter: string): Promise<IEndlessList<IIdTitle>> => {
        const obj = this;
        const lowFilter = filter.toLowerCase();
        return new Promise<IEndlessList<IdTitle>>((resolve, reject) => {
            const result = new EndlessList<IdTitle>(null, IdTitle);
            result.items = !lowFilter ? obj.state.selectEmployeeDialogItems : obj.state.selectEmployeeDialogItems.filter(i => i.title.toLowerCase().indexOf(lowFilter) > -1);
            result.page = 1;
            result.hasMore = false;
            resolve(result);
        });
    };

    handleSelectEmployeeDialogOk = (employeeId: string, title: string) => {
        const obj = this;
        if (!employeeId) return;
        this.props.onAddEmployee(employeeId, title);
        obj.setState({
            showSelectEmployeeDialog: false
        });
    };

    handleSelectEmployeeDialogCancel = () => {
        this.setState({
            showSelectEmployeeDialog: false
        });
    };

    handleRemove = () => {
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        this.props.onRemoveEmployee(selectedId);
    };

    handleLineClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    render() {
        const props = this.props;
        const items = props.items;
        return (
            <div>
                <div className="commandRow">
                    {!!props.title &&
                        <label className="control-label listTitle">{props.title}</label>
                    }
                    <ToolButton
                        title={Translations.Remove}
                        enabled={!!this.state.selectedId}
                        classes={"round right remove"}
                        onClick={this.handleRemove}
                    />
                    <ToolButton
                        title={Translations.Add}
                        enabled={true}
                        classes={"round right add"}
                        onClick={this.handleAdd}
                    />
                </div>
                <div className="listContainer vehicleEmployeesContainer">
                    {items.length > 0 &&
                        <div className="list vehicleEmployees">
                            {items.map((item) =>
                                <SettingsVehicleDialogEmployeeListLine
                                    key={item.id}
                                    item={item}
                                    selectedId={this.state.selectedId}
                                    onClick={this.handleLineClick}
                                />
                            )}
                        </div>
                    }
                </div>
                {this.state.showSelectEmployeeDialog &&
                    <SelectIdTitleDialog
                        title={Translations.SelectEmployee}
                        selectedId={null}
                        listbox={true}
                        loadIdCodes={this.handleLoadEmployees}
                        onOk={this.handleSelectEmployeeDialogOk}
                        onCancel={this.handleSelectEmployeeDialogCancel}
                    />
                }
            </div>
        );
    }
}
