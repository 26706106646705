/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Box, Checkbox, Tooltip, Typography } from "@mui/material";
import { TESCalculationParameters } from "../../models/calculation/TESCalculationParameters";
import MuiSelect, { IMuiSelectOption } from "../framework/muiSelect";
import InfoIcon from "@mui/icons-material/Info";
import i18n from "i18next";

interface IRoundingProps {
    translate: (str: string) => string;
    handleChange: (attr: string, value: any) => void;
    settings: TESCalculationParameters;
    setSettings: React.Dispatch<React.SetStateAction<TESCalculationParameters>>;
}

export const Rounding = ({ translate, handleChange, settings, setSettings }: IRoundingProps) => {
    const [selectedResultRoundingMinutes, setSelectedResultRoundingMinutes] = useState<number>((settings.resultRoundingMinutes));
    const [selectedShiftRoundingMinutes, setSelectedShiftRoundingMinutes] = useState<number>((settings.shiftRoundingMinutes));

    const roundingOptions: IMuiSelectOption[] = [
        { label: "5 minuuttia", value: 5 },
        { label: "10 minuuttia", value: 10 },
        { label: "15 minuuttia", value: 15 },
        { label: "30 minuuttia", value: 30 },
        { label: "60 minuuttia", value: 60 },
    ];

    const handleCheckboxChange = (param: string) => {
        const currentValue = settings[param];
        const newValue = !currentValue;
        const newSettings = { ...settings };
        if (param === "isResultRoundingMinutesOn") {
            newSettings.isResultRoundingMinutesOn = newValue;
        } else if (param === "isShiftRoundingMinutesOn") {
            newSettings.isShiftRoundingMinutesOn = newValue;
        }
        setSettings(newSettings);
    };

    const handleSelectChange = (param: string, value: number) => {
        handleChange(param, value);
        if (param === "resultRoundingMinutes") {
            setSelectedResultRoundingMinutes(value);
        } else if (param === "shiftRoundingMinutes") {
            setSelectedShiftRoundingMinutes(value);
        }
    };

    return (
        <Grid2 container direction="column" spacing={2} p={3}>
            <Grid2>
                <Typography variant="h3">
                    {translate("rounding")}
                </Typography>
            </Grid2>

            <Grid2>
                <Box sx={{ width: 300 }}>
                    <Grid2
                        container
                        rowSpacing={2}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        alignItems="center"
                    >

                        <Grid2 xs={2}>
                            <Checkbox
                                id="RESULTROUNDINGMINUTESON"
                                checked={settings.isResultRoundingMinutesOn}
                                onClick={() => handleCheckboxChange("isResultRoundingMinutesOn")}
                            />
                        </Grid2>

                        {settings.isResultRoundingMinutesOn ? (
                            <Grid2 xs={10}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <MuiSelect
                                        label={translate("resultRounding")}
                                        value={settings.resultRoundingMinutes}
                                        options={roundingOptions}
                                        onChange={(value) => handleSelectChange("resultRoundingMinutes", Number(value))}
                                    />
                                    <Box marginLeft={2}>
                                        <Tooltip title={i18n.t("resultRoundingInfo", { selectedResultRoundingMinutes })}>
                                            <InfoIcon color="primary" />
                                        </Tooltip>
                                    </Box>
                                </Box>
                            </Grid2>
                        ) : (
                            <Grid2 xs={10}>
                                {translate("roundResult")}
                            </Grid2>
                        )}

                        <Grid2 xs={2}>
                            <Checkbox
                                id="SHIFTROUNDINGMINUTESON"
                                checked={settings.isShiftRoundingMinutesOn}
                                onClick={() => handleCheckboxChange("isShiftRoundingMinutesOn")}
                            />
                        </Grid2>

                        {settings.isShiftRoundingMinutesOn ? (
                            <Grid2 xs={10}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <MuiSelect
                                        label={translate("shiftRounding")}
                                        value={settings.shiftRoundingMinutes}
                                        options={roundingOptions}
                                        onChange={(value) => handleSelectChange("shiftRoundingMinutes", Number(value))}
                                    />
                                    <Box marginLeft={2}>
                                        <Tooltip title={i18n.t("shiftRoundingInfo", { selectedShiftRoundingMinutes })}>
                                            <InfoIcon color="primary" />
                                        </Tooltip>
                                    </Box>
                                </Box>
                            </Grid2>
                        ) : (
                            <Grid2 xs={10}>
                                {translate("roundShift")}
                            </Grid2>
                        )}

                    </Grid2>
                </Box>
            </Grid2>
        </Grid2>
    );
};
