// InvoiceTakeToInvoicingDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Base } from "../../framework/base";
import * as store from "../../framework/customStore";
import { SaveData } from "../../framework/saveData";
import { IInvoiceListItem, InvoiceListItem } from "../../models/invoice/invoiceListItem";
import * as baseService from "../../services/baseService";
import * as invoiceService from "../../services/invoiceService";
import * as storeActions from "../../models/store/storeActions";
import { Translations } from "../../models/translations";
import { CheckBox } from "../framework/checkbox";
import { Dialog } from "../framework/dialog";
import { ListHeaderColumn } from "../framework/listHeaderColumn";
import { ProgressBar } from "../framework/progressBar";
import { ButtonDropdown, IButtonDropdownAction } from "../framework/dropdown";
import { Button } from "../framework/button";

// InvoiceTakeToInvoicingDialogListHeader
export interface IInvoiceTakeToInvoicingDialogListHeaderProp {
    sortColumn: string;
    sortOrderIsAsc: boolean;
    onColumnClick: (column: string) => void;
}

export class InvoiceTakeToInvoicingDialogListHeader extends React.Component<IInvoiceTakeToInvoicingDialogListHeaderProp, {}> {
    handleColumnClick = (column: string) => {
        this.props.onColumnClick(column);
    };

    render() {
        return (
            <div className="row title">
                <ListHeaderColumn
                    key="number"
                    title={Translations.AbrNumber}
                    column="number"
                    classes="col-1"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.State}
                    column="state"
                    classes="col-2"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Customer}
                    column="customer"
                    classes="col-3"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.WorkOrders}
                    column="workOrders"
                    classes="col-3"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.CustomerSiteNumbers}
                    column="workOrders"
                    classes="col-3"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
            </div>
        );
    }
}

// InvoiceTakeToInvoicingDialogListLine
export interface IInvoiceTakeToInvoicingDialogListLineProp {
    item: IInvoiceListItem;
}

export class InvoiceTakeToInvoicingDialogListLine extends React.Component<IInvoiceTakeToInvoicingDialogListLineProp, {}> {
    render() {
        const item = this.props.item;
        return (
            <div className={"row line" + (" invoiceState" + item.state)}>
                <div className="col-1 number">{item.number.toString(10)}</div>
                <div className="col-2">{item.stateStr}</div>
                <div className="col-3">{item.customerName}</div>
                <div className="col-3">{Base.getStringWithSeparatorsInt(item.workOrderNumbers, ", ")}</div>
                <div className="col-3">{Base.getStringWithSeparators(item.siteNumbers, ", ")}</div>
            </div>
        );
    }
}

// InvoiceTakeToInvoicingDialog
// ***********************************************************************************************************************
export interface IInvoiceTakeToInvoicingDialogProp {
    classes?: string;
    items: IInvoiceListItem[];
    sortColumn: string;
    sortOrderIsAsc: boolean;
    onCancel: () => void;
}

export interface IInvoiceTakeToInvoicingDialogState {
    items: IInvoiceListItem[];
    sortColumn: string;
    sortOrderIsAsc: boolean;
    setToTransferredState: boolean;
    combineCustomerWorkOrders: boolean;
    showProgressBar: boolean;
    sentInvoicesCount: number;
    isInvoiceServiceEnabled: boolean;
    selectedErp: string;
    targetErps: IButtonDropdownAction[];
}

export class InvoiceTakeToInvoicingDialog extends React.Component<IInvoiceTakeToInvoicingDialogProp, IInvoiceTakeToInvoicingDialogState> {
    constructor(props) {
        super(props);
        this.state = {
            items: props.items.slice(0),
            sortColumn: props.sortColumn,
            sortOrderIsAsc: props.sortOrderIsAsc,
            setToTransferredState: true,
            combineCustomerWorkOrders: false,
            showProgressBar: false,
            sentInvoicesCount: 0,
            isInvoiceServiceEnabled: false,
            selectedErp: "",
            targetErps: []
        };
    }

    componentDidMount(): void {
        this.getTargetsErps();
        invoiceService.isPinjaInvoiceFileEnabled()
            .then(resp =>
                this.setState({
                    isInvoiceServiceEnabled: resp.message as unknown as boolean
                })
            );
    }

    changeSortColumn = (sortColumn: string) => {
        const oldSortColumn = this.state.sortColumn;
        const sortOrderIsAsc = oldSortColumn === sortColumn ? !this.state.sortOrderIsAsc : true;
        const items = this.state.items.slice(0);
        InvoiceListItem.sortInvoiceListItems(items, sortColumn, sortOrderIsAsc);
        this.setState({ items: items, sortColumn: sortColumn, sortOrderIsAsc: sortOrderIsAsc });
    };

    handleSetToTransferredStateChange = (value: boolean) => {
        this.setState({ setToTransferredState: value });
    };

    handleCombineCustomerWorkOrdersChange = (value: boolean) => {
        this.setState({ combineCustomerWorkOrders: value });
    };

    private static validate = (state: IInvoiceTakeToInvoicingDialogState): boolean => {
        // if (!state.registerNumber) {
        //     store.customStore.dispatch(storeActions.showErrorMessage(Translations.RegisterNumberMustBeDefined));
        //     return false;
        // }
        return true;
    };

    private static getSaveDataFromState = (state: IInvoiceTakeToInvoicingDialogState, invoiceIds: string[]): SaveData => {
        const data = new SaveData();
        data.append("setToTransferredState", state.setToTransferredState ? "1" : "0");
        data.append("invoiceIds", JSON.stringify(invoiceIds));
        return data;
    };

    downloadInvoiceFile = () => {
        if (!InvoiceTakeToInvoicingDialog.validate(this.state)) return;
        const saveData = InvoiceTakeToInvoicingDialog.getSaveDataFromState(this.state, this.props.items.map(i => i.id));
        if (Base.isNullOrUndefined(saveData)) return;
        if (!Base.isNullOrUndefined(this.props.items.find(i => !i.isPreliminary()))) {
            store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(String.format(Translations.StateOfAllInvoicesMustBeParameter, Translations.InvoiceStatePreliminary))));
            return;
        }
        // Call server
        store.customStore.dispatch(storeActions.fetchStart());
        invoiceService.getInvoiceFile(saveData.formData)
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    sendInvoiceFileToServer = (saveData: SaveData, selectedErp: string): Promise<baseService.ApiSuccess> => {
        const obj = this;
        store.customStore.dispatch(storeActions.fetchStart());
        return invoiceService.sendInvoiceFile(saveData.formData, selectedErp)
            .then((response: baseService.ApiSuccess) => {
                obj.setState({
                    sentInvoicesCount: obj.state.sentInvoicesCount + 1
                });
                return response;
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    sendInvoiceFile = (targetErp: string) => {
        const obj = this;
        const props = this.props;
        if (!InvoiceTakeToInvoicingDialog.validate(this.state)) return;
        if (!Base.isNullOrUndefined(props.items.find(i => !i.isPreliminary()))) {
            store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(String.format(Translations.StateOfAllInvoicesMustBeParameter, Translations.InvoiceStatePreliminary))));
            return;
        }
        const invoiceIds = props.items.map(i => i.id);
        this.setState({
            showProgressBar: true,
            sentInvoicesCount: 0,
            selectedErp: targetErp
        });
        invoiceIds.map(i => InvoiceTakeToInvoicingDialog.getSaveDataFromState(this.state, [i]))
            .reduce((promise, saveData) => promise.then((success) => {
                if (success) {
                    return obj.sendInvoiceFileToServer(saveData, this.state.selectedErp);
                }
                Promise.reject(new Error("Operation failed!"));
            }), Promise.resolve(new baseService.ApiSuccess()))
            .then((success: baseService.ApiSuccess) => {
                if (!success) return;
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                window.setTimeout(() => {
                    obj.props.onCancel();
                }, 300); //300 ms to display progress bar before closing the dialog
            });
    };

    handleDownloadClick = () => {
        this.downloadInvoiceFile();
    };

    onSelectTargetErp = (targetErp: string) => {
        this.sendInvoiceFile(targetErp);
    };

    handlePrintClick = () => {
        store.customStore.dispatch(storeActions.fetchStart());
        invoiceService.getInvoicePrint(this.props.items.map(i => i.id))
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    onSelectTarget(targetErp: string) {
        this.sendInvoiceFile(targetErp);
    }

    getTargetsErps = () => {
        invoiceService.targetErps().then(resp => {
            const targetErps = resp;
            const actionButtons: IButtonDropdownAction[] = targetErps.map((targetErp) => {
                return {
                    title: targetErp,
                    classes: "w100 text-overflow-ellipsis",
                    onClick: () => this.onSelectTargetErp(targetErp)
                };
            });
            this.setState({
                targetErps: actionButtons
            });
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        const items = state.items;

        const dialogClasses = "settings invoiceTakeToInvoicing px1000" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <Dialog
                    classes={dialogClasses}
                    title={Translations.TakeToInvoicing}
                    show={true}
                    body=
                        {
                            <div>
                                <div className="row">
                                    <div className="col-12">
                                        <div>
                                            <InvoiceTakeToInvoicingDialogListHeader
                                                sortColumn={state.sortColumn}
                                                sortOrderIsAsc={state.sortOrderIsAsc}
                                                onColumnClick={this.changeSortColumn}
                                            />
                                        </div>
                                        <div className="listContainer noTopMargin">
                                            <div className="list striped">
                                                <div className="lineContainer">
                                                    {items.map((item) =>
                                                        <InvoiceTakeToInvoicingDialogListLine
                                                            key={item.id}
                                                            item={item}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <CheckBox
                                                title={Translations.SetWorkOrdersAndInvoicesToTransferredState}
                                                enabled={true}
                                                checked={state.setToTransferredState}
                                                onCheckboxClickBoolean={this.handleSetToTransferredStateChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {state.showProgressBar &&
                                    <ProgressBar
                                        maxValue={items.length}
                                        value={state.sentInvoicesCount}
                                    />
                                }
                                <div className="modal-footer">
                                    <Button
                                        title={Translations.Print}
                                        classes="btn-default mr-auto"
                                        onClick={this.handlePrintClick}
                                        enabled={true}
                                    />
                                    <Button
                                        title={Translations.ExportToTransferFile}
                                        classes="btn-primary"
                                        onClick={this.handleDownloadClick}
                                        enabled={true}
                                    />
                                    <ButtonDropdown
                                        disabled={state.isInvoiceServiceEnabled}
                                        removeDefaultButtonClasses={true}
                                        buttonIconClasses="hidden"
                                        buttonClasses="btn btn-primary dropdown-toggle"
                                        dropdownMenuClasses="w100 dropdown-menu-box"
                                        selectedTitle={Translations.ExportToInvoicingSystem}
                                        actions={this.state.targetErps}
                                    />
                                </div>
                            </div>
                        }
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}
