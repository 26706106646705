import { ApiSuccess, SaveSuccess } from "./baseService";
import { getApiCall, makeApiCall, postApiCall, postFileApiCall, postFormApiCall, postFormDataFileApiCall } from "./tokenService";
import { InvoiceListItems } from "../models/invoice/invoiceListItems";
import { InvoiceEdit } from "../models/invoice/invoiceEdit";
import { Base64FileWithThumbnail } from "../models/common/base64FileWithThumbnail";

export const getInvoiceListItems = (pageSize: number, page: number, filter: string, states: number[], sortColumn: string, sortOrderIsAsc: boolean): Promise<InvoiceListItems> => {
    return postApiCall<InvoiceListItems>("api/invoice/list", InvoiceListItems, JSON.stringify({
        page: page,
        pageSize: pageSize,
        sortColumn: sortColumn,
        sortOrder: sortOrderIsAsc ? "asc" : "desc",
        filter: filter || "",
        states: states || []
    }));
};

export const getInvoiceEdit = (id: string): Promise<InvoiceEdit> => {
    return getApiCall<InvoiceEdit>("api/invoice/edit/?id=" + (id || ""), InvoiceEdit);
};

export const saveInvoiceEdit = (data: FormData): Promise<SaveSuccess> => {
    return postFormApiCall<SaveSuccess>("api/invoice/save", data, SaveSuccess);
};

export const removeInvoice = (ids: string[]): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/invoice/remove", ApiSuccess, JSON.stringify({ ids: ids }));
};

export const addWorkOrdersToInvoice = (id: string, workOrderIds: string[]): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/invoice/addworkorders", ApiSuccess, JSON.stringify({ id: id, workOrderIds: workOrderIds }));
};

export const removeWorkOrderFromInvoice = (id: string, workOrderId: string): Promise<ApiSuccess> => {
    return postApiCall<ApiSuccess>("api/invoice/removeworkorder/?id=" + (id || "") + "&workOrderId=" + (workOrderId || ""), ApiSuccess);
};

export const getInvoicePrint = (ids: string[]): Promise<string> => {
    return postFileApiCall("api/invoice/invoiceprint", JSON.stringify(ids));
};

export const getInvoicePrintWithThumbnail = (ids: string[]): Promise<Base64FileWithThumbnail> => {
    return postApiCall<Base64FileWithThumbnail>("api/invoice/invoiceprintpictures", Base64FileWithThumbnail, JSON.stringify(ids));
};

export const getInvoiceFile = (data: FormData): Promise<string> => {
    return postFormDataFileApiCall("api/invoice/invoicefile", data);
};

export const isPinjaInvoiceFileEnabled = (): Promise<ApiSuccess> => {
    return getApiCall<ApiSuccess>("api/invoice/pinjainvoice", ApiSuccess);
};

export const targetErps = (): Promise<string[]> => {
    return makeApiCall<string[]>("api/invoice/targeterps", "get", null);
};

export const sendInvoiceFile = (data: FormData, selectedErp: string): Promise<ApiSuccess> => {
    return postFormApiCall("api/invoice/pinjainvoicefile/?selectedErp= " + (selectedErp || ""), data, ApiSuccess);
};
