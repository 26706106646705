import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { SectionHeader } from "../layout/sectionHeader";

interface ActionButton {
    key: string;
    icon: JSX.Element;
    onClick: () => void;
}

interface MonitorViewSidebarProps {
    header: string | JSX.Element | JSX.Element[];
    children: JSX.Element | JSX.Element[];
    buttons?: ActionButton[];
    loading?: boolean;
}

export const MonitorViewSidebar = ({
    header,
    children,
    buttons,
    loading = false,
}: MonitorViewSidebarProps) => {
    return (
        <Box minWidth={350} height="100%" display="flex" flexDirection="column">
            <SectionHeader position="sticky">
                <>
                    <Typography
                        component="div"
                        sx={{ flexGrow: 1 }}
                        className="section-header text-truncate"
                    >
                        {header}
                    </Typography>
                    {buttons?.map((b) => (
                        <IconButton
                            key={b.key}
                            color="inherit"
                            onClick={b.onClick}
                        >
                            {b.icon}
                        </IconButton>
                    ))}
                </>
            </SectionHeader>
            {loading ? (
                <Box display="flex" justifyContent="center" p={2}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box
                    sx={{
                        overflowY: "auto",
                    }}
                >
                    {children}
                </Box>
            )}
        </Box>
    );
};
