import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./en.json";
import fi from "./fi-FI.json";

const defaultLanguage = "fi";

export const defaultNamespace = "default";

export const resources = {
    "en": {
        [defaultNamespace]: en,
    },
    "fi": {
        [defaultNamespace]: fi,
    },
};

void i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        defaultNS: defaultNamespace,
        ns: [defaultNamespace],
        resources,
        lng: defaultLanguage,
        fallbackLng: defaultLanguage,
        interpolation: {
            escapeValue: false,
        },
    });