// WorkInvoiceDataMainToBeInvoicedWorkOrkdersList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import { Base } from "../../framework/base";
import { ToolTitle } from "../framework/toolTitle";
import { IIdTitle } from "../../models/common/idTitle";
import { ButtonDropdown } from "../framework/dropdown";
import { IWorkOrderInvoiceDataListItem } from "../../models/work/workOrderInvoiceDataListItem";
import { ListMode, ListModeSelector } from "../framework/listModeSelector";
import { Button } from "../framework/button";
import { TimeFormat } from "../../models/common/enums";
import { WorkInvoiceDataMainReadyWorkOrkdersListLineHeader, WorkInvoiceDataMainReadyWorkOrkdersListLine, WorkInvoiceDataMainReadyWorkOrkdersListCard } from "./workInvoiceDataMainReadyWorkOrkdersList";

// WorkInvoiceDataMainToBeInvoicedWorkOrkdersList
export interface IWorkInvoiceDataMainToBeInvoicedWorkOrkdersListProp {
    workTimeFormat: TimeFormat;
    pageSize: number;
    page: number;
    hasMore: boolean;
    filter: string;
    sortColumn: string;
    sortOrderIsAsc: boolean;
    items: IWorkOrderInvoiceDataListItem[];
    workOrderStates: IIdTitle[];
    state: number;
    selectedId: string;
    checkedIds: string[];
    selectedState: number;
    isLoading: boolean;
    searchItems: (pageSize: number, page: number, filter: string, state: number, sortColumn: string, sortOrderIsAsc: boolean, resetItems: boolean, refreshList: boolean, successCallback: () => void) => Promise<void>;
    onChangeFilter: (filter: string, state: number) => void;
    onChangeSortColumn: (sortColumn: string, state: number) => void;
    onChangeColumnCheckbox: (column: string, checked: boolean, state: number) => void;
    onChangeLineCheckbox: (id: string, checked: boolean, state: number) => void;
    onLineClick: (id: string, state: number) => void;
    onLineDoubleClick: (id: string) => void;
    onEdit: (state: number) => void;
    onPrint: (state: number) => void;
    onInvoice: () => void;
}

export interface IWorkInvoiceDataMainToBeInvoicedWorkOrkdersListState {
    listMode: ListMode;
    showButtons: boolean;
}

export class WorkInvoiceDataMainToBeInvoicedWorkOrkdersList extends React.Component<IWorkInvoiceDataMainToBeInvoicedWorkOrkdersListProp, IWorkInvoiceDataMainToBeInvoicedWorkOrkdersListState> {
    private commandRowDivWidthThreshold = 930;
    private commandRowDiv: HTMLDivElement;
    private containerDiv: HTMLDivElement;
    private listDiv: HTMLDivElement;

    constructor(props) {
        super(props);
        this.state = {
            listMode: ListMode.List,
            showButtons: true
        };
    }

    handleScrollSub = Base.debounce((obj: WorkInvoiceDataMainToBeInvoicedWorkOrkdersList) => {
        const props = obj.props;
        if (props.isLoading || !props.hasMore) return;
        if (obj.listDiv.offsetHeight - (obj.containerDiv.clientHeight + obj.containerDiv.scrollTop) < 5) {
            props.searchItems(props.pageSize, props.page + 1, props.filter, props.state, props.sortColumn, props.sortOrderIsAsc, false, false, null);
        }
    }, 100);

    handleScroll = (event) => {
        this.handleScrollSub(this);
    };

    handleWindowsResize = () => {
        if (!this.commandRowDiv) return;
        const showButtons = this.commandRowDiv.clientWidth > this.commandRowDivWidthThreshold;
        if (showButtons === this.state.showButtons) return;
        this.setState({
            showButtons: showButtons
        });
    };

    componentDidMount(): void {
        this.containerDiv.addEventListener("scroll", this.handleScroll);
        window.addEventListener("resize", this.handleWindowsResize);
        this.handleWindowsResize();
    }

    componentWillUnmount(): void {
        this.containerDiv.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("resize", this.handleWindowsResize);
    }

    handleChangeListMode = (listMode: ListMode) => {
        this.setState({
            listMode: listMode
        });
    };

    render() {
        const props = this.props;
        const state = this.state;
        const items = props.items;
        return (
            <div className="row pt-2">
                <div className="col-12">
                    <div className="row commandRow main" ref={(elem) => { this.commandRowDiv = elem; }}>
                        <ToolTitle
                            classes="smaller"
                            title={Translations.ToBeInvoicedWorkOrders}
                        />
                        {/*{state.showButtons*/}
                        {/*    ? <div className="col">*/}
                        {/*        <Button*/}
                        {/*            classes="blue upper left"*/}
                        {/*            title={Translations.Approve}*/}
                        {/*            tooltip={Translations.ApproveToBeInvoiced}*/}
                        {/*            enabled={!!props.selectedId || props.checkedIds.length > 0}*/}
                        {/*            onClick={props.onApproveToBeInvoiced}*/}
                        {/*        />*/}
                        {/*        <ToolButton*/}
                        {/*            title={Translations.Edit}*/}
                        {/*            enabled={!!props.selectedId}*/}
                        {/*            classes={"round left edit"}*/}
                        {/*            onClick={props.onEdit}*/}
                        {/*        />*/}
                        {/*        <ToolButton*/}
                        {/*            title={Translations.Print}*/}
                        {/*            enabled={!!props.selectedId || props.checkedIds.length > 0}*/}
                        {/*            classes={"round left print"}*/}
                        {/*            onClick={props.onPrint}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*    : <div className="col">*/}
                        {/*        <Button*/}
                        {/*            classes="blue upper left"*/}
                        {/*            title={Translations.Approve}*/}
                        {/*            tooltip={Translations.ApproveToBeInvoiced}*/}
                        {/*            enabled={!!props.selectedId || props.checkedIds.length > 0}*/}
                        {/*            onClick={props.onApproveToBeInvoiced}*/}
                        {/*        />*/}
                        {/*        <ButtonDropdown*/}
                        {/*            menuRight*/}
                        {/*            classes={"dropdown-tool"}*/}
                        {/*            disabled={!props.selectedId && props.checkedIds.length < 1}*/}
                        {/*            actions={[*/}
                        {/*                { title: (Translations.Edit), onClick: props.onEdit, disabled: !props.selectedId },*/}
                        {/*                { title: (Translations.Print), onClick: props.onPrint, disabled: !props.selectedId && props.checkedIds.length < 1 },*/}
                        {/*            ]}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*}*/}
                        <div className="col">
                            <ListModeSelector
                                listMode={state.listMode}
                                onChangeListMode={this.handleChangeListMode}
                            />
                        </div>
                        <div className="col-auto right">
                            <Button
                                classes={"right btn-primary"}
                                title={Translations.CreateInvoices}
                                tooltip={Translations.CreateInvoices}
                                enabled={!!props.selectedId || props.checkedIds.length > 0}
                                onClick={props.onInvoice}
                            />
                            <ButtonDropdown
                                menuRight
                                classes={"right dropdown-tool"}
                                disabled={!props.selectedId && props.checkedIds.length < 1}
                                actions={[
                                    { title: (Translations.Edit), onClick: () => props.onEdit(props.state), disabled: !props.selectedId },
                                    { title: (Translations.Print), onClick: () => props.onPrint(props.state), disabled: !props.selectedId && props.checkedIds.length < 1 },
                                ]}
                            />
                        </div>
                        {/*    <div className="col-auto right">*/}
                        {/*        <ListSearchFilter*/}
                        {/*            searchFilter={props.filter}*/}
                        {/*            onSearchClick={props.onChangeFilter} />*/}
                        {/*    </div>*/}
                    </div>
                    {state.listMode === ListMode.List &&
                        <div>
                            <WorkInvoiceDataMainReadyWorkOrkdersListLineHeader
                                sortColumn={props.sortColumn}
                                sortOrderIsAsc={props.sortOrderIsAsc}
                                onColumnClick={(column: string) => props.onChangeSortColumn(column, props.state)}
                                onColumnCheckboxChange={(column: string, checked: boolean) => props.onChangeColumnCheckbox(column, checked, props.state)}
                            />
                        </div>
                    }
                    <div className={"listContainer main workOrdersContainer halfHeight" + (state.listMode === ListMode.Card ? " card" : "")} ref={(elem) => { this.containerDiv = elem; }}>
                        <div className="list workOrders striped">
                            {state.listMode === ListMode.List
                                ? <div className="lineContainer" ref={(elem) => { this.listDiv = elem; }}>
                                    {items.map((item) =>
                                        <WorkInvoiceDataMainReadyWorkOrkdersListLine
                                            key={item.id}
                                            workTimeFormat={props.workTimeFormat}
                                            item={item}
                                            selectedId={props.selectedId}
                                            checked={props.checkedIds.indexOf(item.id) > -1}
                                            onClick={(id: string) => props.onLineClick(id, props.state)}
                                            onDoubleClick={props.onLineDoubleClick}
                                            onCheckboxChange={(id: string, checked: boolean) => props.onChangeLineCheckbox(id, checked, props.state)}
                                        />
                                    )}
                                </div>
                                : <div className="cardContainer" ref={(elem) => { this.listDiv = elem; }}>
                                    <div className="row">
                                        {items.map((item) =>
                                            <div className="col-4 card" key={item.id}>
                                                <WorkInvoiceDataMainReadyWorkOrkdersListCard
                                                    key={item.id}
                                                    workTimeFormat={props.workTimeFormat}
                                                    item={item}
                                                    selectedId={props.selectedId}
                                                    checked={props.checkedIds.indexOf(item.id) > -1}
                                                    onClick={(id: string) => props.onLineClick(id, props.state)}
                                                    onDoubleClick={props.onLineDoubleClick}
                                                    onCheckboxChange={(id: string, checked: boolean) => props.onChangeLineCheckbox(id, checked, props.state)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}