export class WorkTimeSaveData {
    id: string;
    rowId: string;
    employeeId: string;
    vehicleId: string;
    workTimeTypeId: string;
    salaryRowTypeId: string;
    costCenterId: string;
    workOrderId: string;
    comment: string;
    startDate: string;
    endDate: string;
    timeZoneName: string;
    isCustomType?: boolean;
}
