import * as React from "react";
import { Translations } from "../../models/translations";
import { DocumentPreview } from "../framework/documentPreview";
import { ToolTitle } from "../framework/toolTitle";

export interface IReportViewPreviewProp {
    file: string;
    fileName: string;
    fileMimeType: string;
    thumbnails: string[];
    thumbnailIndex: number;
}

export class ReportViewPreview extends React.Component<IReportViewPreviewProp, {}> {
    render() {
        const props = this.props;
        return (
            <div className="backgroundContent">
                <div className="row commandRow">
                    <ToolTitle
                        title={Translations.Preview}
                    />
                </div>
                <DocumentPreview
                    file={props.file}
                    fileName={props.fileName}
                    fileMimeType={props.fileMimeType}
                    thumbnails={props.thumbnails}
                    thumbnailIndex={props.thumbnailIndex}
                />
            </div>
        );
    }
}