import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { RootState, useAppSelector } from "../../../framework/customStore";
import { showApiError } from "../../framework/formUtils";
import { getCustomerDetails } from "../../../services/customerService";
import { ICustomerDetails } from "../../../models/customer/customerDetails";

interface CustomerDetailProps {
    customerId?: string;
    handleCustomerDetailsCall?: (details: ICustomerDetails) => void;
}

export const CustomerDetails = ({ customerId, handleCustomerDetailsCall }: CustomerDetailProps) => {
    const { t } = useTranslation();
    const transportOrderState = useAppSelector((state: RootState) => state.transportOrders);
    const [customerDetails, setCustomerDetails] = useState<ICustomerDetails>();
    const id = customerId || transportOrderState?.billingCustomer;

    useEffect(() => {
        let shouldFetch = true;
        const fetchDetails = async(id: string) => {
            const res = await getCustomerDetails(id);
            if (shouldFetch) {
                shouldFetch = false;
                setCustomerDetails(res?.data);
                if(handleCustomerDetailsCall) handleCustomerDetailsCall(res?.data);
            }
        };
        if(id) {
            fetchDetails(id)
                .catch((e) => showApiError(t("customer.fetchFailed")));
        }
        setCustomerDetails(null);
    }, [id]);

    return (
        customerDetails &&
        <>
            <Typography variant="h6" color="primary.dark">{t("transport.invoicing.invoiceAddress")}</Typography>
            <Typography color="primary.dark" variant="h4"> { customerDetails?.streetAddress } </Typography>
            <Typography color="primary.dark" variant="h4"> { customerDetails?.postalCode } { customerDetails?.city } </Typography>
        </>
    );
};