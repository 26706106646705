// OwnerSettingsWorkOrderTypeDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as baseService from "../../services/baseService";
import * as storeActions from "../../models/store/storeActions";
import * as workOrderTypeService from "../../services/workOrderTypeService";
import { PropertyDialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { SaveData } from "../../framework/saveData";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { IWorkOrderTypeEdit } from "../../models/workOrderType/workOrderTypeEdit";
import { WorkOrderTypeEmployeeUsageType, WorkOrderTypeHoursInvoicingType, WorkOrderTypeKmsInvoicingType, WorkOrderTypeLocationUsageType, WorkOrderTypeSiteRoutePointUsageType, WorkOrderTypeVehicleUsageType } from "../../models/common/enums";
import { CheckBox } from "../framework/checkbox";
import { IWorkOrderTypeEditItemWorkShiftTimeSlotType, WorkOrderTypeEditItemWorkShiftTimeSlotType } from "../../models/workOrderType/workOrderTypeEditItemWorkShiftTimeSlotType";
import { OwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotList } from "./ownerSettingsWorkOrderTypeDialogWorkShiftTimeSlotList";

// OwnerSettingsWorkOrderTypeDialog
// ***********************************************************************************************************************
export interface IOwnerSettingsWorkOrderTypeDialogProp {
    classes?: string;
    edit: IWorkOrderTypeEdit;
    onOk: () => void;
    onCancel: () => void;
}

export interface IOwnerSettingsWorkOrderTypeDialogState {
    title: string;
    vatRate: string;
    siteRoutePointUsageType: WorkOrderTypeSiteRoutePointUsageType;
    employeeUsageType: WorkOrderTypeEmployeeUsageType;
    vehicleUsageType: WorkOrderTypeVehicleUsageType;
    locationUsageType: WorkOrderTypeLocationUsageType;
    hoursInvoicingType: WorkOrderTypeHoursInvoicingType;
    hoursInvoicingProductId: string;
    kmsInvoicingType: WorkOrderTypeKmsInvoicingType;
    kmsInvoicingProductId: string;
    showAttachments: boolean;
    showComment: boolean;
    showDescription: boolean;
    showMap: boolean;
    showRoutePointBookings: boolean;
    showWorkOrderAcknowledgementType: boolean;
    showEstimatedHours: boolean;
    showWorkOrderTypeInWorkQueue: boolean;
    locationDataUpdateIntervalS: number;
    canBeCreatedInMobile: boolean;
    workOrderTypeWorkShiftTimeSlotTypes: IWorkOrderTypeEditItemWorkShiftTimeSlotType[];
}

export class OwnerSettingsWorkOrderTypeDialog extends React.Component<IOwnerSettingsWorkOrderTypeDialogProp, IOwnerSettingsWorkOrderTypeDialogState> {
    private orgStateHash: string = "";

    constructor(props: IOwnerSettingsWorkOrderTypeDialogProp) {
        super(props);
        const workOrderType = props.edit.workOrderType;
        this.state = {
            title: workOrderType.title,
            vatRate: workOrderType.vatRate.toLocaleFixed(2),
            siteRoutePointUsageType: workOrderType.siteRoutePointUsageType,
            employeeUsageType: workOrderType.employeeUsageType,
            vehicleUsageType: workOrderType.vehicleUsageType,
            locationUsageType: workOrderType.locationUsageType,
            hoursInvoicingType: workOrderType.hoursInvoicingType,
            hoursInvoicingProductId: workOrderType.hoursInvoicingProductId,
            kmsInvoicingType: workOrderType.kmsInvoicingType,
            kmsInvoicingProductId: workOrderType.kmsInvoicingProductId,
            showAttachments: workOrderType.showAttachments,
            showComment: workOrderType.showComment,
            showDescription: workOrderType.showDescription,
            showMap: workOrderType.showMap,
            showRoutePointBookings: workOrderType.showRoutePointBookings,
            showWorkOrderAcknowledgementType: workOrderType.showWorkOrderAcknowledgementType,
            showEstimatedHours: workOrderType.showEstimatedHours,
            locationDataUpdateIntervalS: workOrderType.locationDataUpdateIntervalS,
            workOrderTypeWorkShiftTimeSlotTypes: workOrderType.workOrderTypeWorkShiftTimeSlotTypes.slice(0),
            showWorkOrderTypeInWorkQueue: workOrderType.showWorkOrderTypeInWorkQueue,
            canBeCreatedInMobile: workOrderType.canBeCreatedInMobile
        };
        const saveData = OwnerSettingsWorkOrderTypeDialog.getSaveDataFromState(props, this.state);
        this.orgStateHash = saveData.hash;
    }

    // #region General
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "title") {
            this.setState({ title: value });
        } else if (name === "vatRate") {
            this.setState({ vatRate: value });
        } else if (name === "siteRoutePointUsageType") {
            this.setState({ siteRoutePointUsageType: value.toInteger() });
        } else if (name === "employeeUsageType") {
            this.setState({ employeeUsageType: value.toInteger() });
        } else if (name === "vehicleUsageType") {
            this.setState({ vehicleUsageType: value.toInteger() });
        } else if (name === "hoursInvoicingType") {
            this.setState({ hoursInvoicingType: value.toInteger() });
        } else if (name === "hoursInvoicingProductId") {
            this.setState({ hoursInvoicingProductId: value });
        } else if (name === "kmsInvoicingType") {
            this.setState({ kmsInvoicingType: value.toInteger() });
        } else if (name === "kmsInvoicingProductId") {
            this.setState({ kmsInvoicingProductId: value });
        } else if (name === "locationDataUpdateIntervalS") {
            this.setState({ locationDataUpdateIntervalS: value.toInteger() });
        }
    };

    handleBlur = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "vatRate") {
            this.setState({ vatRate: value.toDecimal().toLocaleFixed(2) });
        }
    };

    handleChangeBoolean = (name: string, value: boolean) => {
        if (name === "showAttachments") {
            this.setState({ showAttachments: value });
        } else if (name === "showComment") {
            this.setState({ showComment: value });
        } else if (name === "showDescription") {
            this.setState({ showDescription: value });
        } else if (name === "showMap") {
            this.setState({ showMap: value });
        } else if (name === "showRoutePointBookings") {
            this.setState({ showRoutePointBookings: value });
        } else if (name === "showEstimatedHours") {
            this.setState({ showEstimatedHours: value });
        } else if (name === "showWorkOrderAcknowledgementType") {
            this.setState({ showWorkOrderAcknowledgementType: value });
        } else if (name === "showWorkOrderTypeInWorkQueue") {
            this.setState({ showWorkOrderTypeInWorkQueue: value });
        } else if (name === "locationUsageType") {
            this.setState({ locationUsageType: value ? 1 : 0 });
        } else if (name === "canBeCreatedInMobile") {
            this.setState({ canBeCreatedInMobile: value });
        }
    };
    // #endregion General

    private static checkErrors = (props: IOwnerSettingsWorkOrderTypeDialogProp, state: IOwnerSettingsWorkOrderTypeDialogState): Promise<boolean> => {
        return new Promise<boolean>((resolve) => {
            //Add error checking in here
            if (!state.title) {
                store.customStore.dispatch(storeActions.showErrorMessage(Translations.NameMustBeDefined));
                return resolve(false);
            }
            if (!state.vatRate) {
                store.customStore.dispatch(storeActions.showErrorMessage(Translations.VatRateMustBeDefined));
                return resolve(false);
            }
            return resolve(true);
        });
    };

    private static getWarningMessage = (props: IOwnerSettingsWorkOrderTypeDialogProp, state: IOwnerSettingsWorkOrderTypeDialogState): Promise<string> => {
        return new Promise<string>((resolve) => {
            //let warnings = "";
            //Add warning checking in here
            //if (!state.someField) {
            //    warnings = warnings + Translations.SomeFieldIsNotDefined + Base.lf;
            //}
            //return resolve(warnings);
            return resolve("");
        });
    };

    private static validate = (props: IOwnerSettingsWorkOrderTypeDialogProp, state: IOwnerSettingsWorkOrderTypeDialogState): Promise<boolean> => {
        return OwnerSettingsWorkOrderTypeDialog.checkErrors(props, state).then(success => {
            if (success) {
                return OwnerSettingsWorkOrderTypeDialog.getWarningMessage(props, state)
                    .then(warnings => {
                        if (!warnings) {
                            return Base.getPromiseResult(true);
                        }
                        return new Promise<boolean>((resolve) => {
                            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, warnings + Base.lf + Translations.DoYouReallyWantToSaveData,
                                () => {
                                    store.customStore.dispatch(storeActions.clearConfirmation());
                                    resolve(true);
                                },
                                () => {
                                    store.customStore.dispatch(storeActions.clearConfirmation());
                                    resolve(false);
                                }));
                        });
                    });
            } else {
                return Base.getPromiseResult(false);
            }
        });
    };

    private static getSaveDataFromState = (props: IOwnerSettingsWorkOrderTypeDialogProp, state: IOwnerSettingsWorkOrderTypeDialogState): SaveData => {
        const data = new SaveData();
        const workOrderType = props.edit.workOrderType;
        // Common - Data that does not change
        data.append("id", workOrderType.id);
        data.append("rowId", workOrderType.rowId);
        // General - Data that changes
        data.append("title", state.title);
        data.append("vatRate", state.vatRate.toDecimal().toFixed(2));
        data.append("siteRoutePointUsageType", state.siteRoutePointUsageType.toString(10));
        data.append("employeeUsageType", state.employeeUsageType.toString(10));
        data.append("vehicleUsageType", state.vehicleUsageType.toString(10));
        data.append("locationUsageType", state.locationUsageType.toString(10));
        data.append("hoursInvoicingType", state.hoursInvoicingType.toString(10));
        data.append("hoursInvoicingProductId", state.hoursInvoicingProductId);
        data.append("kmsInvoicingType", state.kmsInvoicingType.toString(10));
        data.append("kmsInvoicingProductId", state.kmsInvoicingProductId);
        data.append("showAttachments", state.showAttachments.toString());
        data.append("showComment", state.showComment.toString());
        data.append("showDescription", state.showDescription.toString());
        data.append("showMap", state.showMap.toString());
        data.append("showRoutePointBookings", state.showRoutePointBookings.toString());
        data.append("showEstimatedHours", state.showEstimatedHours.toString());
        data.append("showWorkOrderAcknowledgementType", state.showWorkOrderAcknowledgementType.toString());
        data.append("showWorkOrderTypeInWorkQueue", state.showWorkOrderTypeInWorkQueue.toString());
        data.append("locationDataUpdateIntervalS", state.locationDataUpdateIntervalS.toString());
        data.append("canBeCreatedInMobile", state.canBeCreatedInMobile.toString());
        return data;
    };

    saveEditItem = () => {
        const obj = this;
        const props = this.props;
        const state = this.state;
        OwnerSettingsWorkOrderTypeDialog.validate(props, state)
            .then(success => {
                if (success) {
                    const saveData = OwnerSettingsWorkOrderTypeDialog.getSaveDataFromState(props, state);
                    if (!saveData) return;
                    // Call server
                    store.customStore.dispatch(storeActions.fetchStart());
                    workOrderTypeService.saveWorkOrderTypeEdit(saveData.formData)
                        .then(success => {
                            store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                            obj.props.onOk();
                        })
                        .catch(error => {
                            store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                        })
                        .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
                }
            });
    };

    // #region WorkOrderTypeWorkShiftTimeSlotTypes
    handleWorkOrderTypeWorkShiftTimeSlotTypeModified = (workOrderTypeWorkShiftTimeSlotType: IWorkOrderTypeEditItemWorkShiftTimeSlotType) => {
        if (!workOrderTypeWorkShiftTimeSlotType) return;
        const workOrderTypeWorkShiftTimeSlotTypes = this.state.workOrderTypeWorkShiftTimeSlotTypes.filter(i => i.id !== workOrderTypeWorkShiftTimeSlotType.id);
        workOrderTypeWorkShiftTimeSlotTypes.push(workOrderTypeWorkShiftTimeSlotType);
        workOrderTypeWorkShiftTimeSlotType.setSortValue(this.props.edit.workShiftTimeSlotTypes);
        WorkOrderTypeEditItemWorkShiftTimeSlotType.sortWorkOrderTypeEditItemWorkShiftTimeSlotTypes(workOrderTypeWorkShiftTimeSlotTypes);
        this.setState({ workOrderTypeWorkShiftTimeSlotTypes: workOrderTypeWorkShiftTimeSlotTypes });
    };

    handleWorkOrderTypeWorkShiftTimeSlotTypeRemoved = (workOrderTypeWorkShiftTimeSlotTypeId: string) => {
        if (!workOrderTypeWorkShiftTimeSlotTypeId) return;
        this.setState({ workOrderTypeWorkShiftTimeSlotTypes: this.state.workOrderTypeWorkShiftTimeSlotTypes.filter(i => i.id !== workOrderTypeWorkShiftTimeSlotTypeId) });
    };
    // #endregion WorkOrderTypeWorkShiftTimeSlotTypes

    handleOkClick = () => {
        this.saveEditItem();
    };

    handleCancelClick = () => {
        const obj = this;
        const saveData = OwnerSettingsWorkOrderTypeDialog.getSaveDataFromState(this.props, this.state);
        if (!Base.isNullOrUndefined(saveData) && saveData.hash !== this.orgStateHash) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.saveEditItem();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.props.onCancel();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));
        } else {
            obj.props.onCancel();
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        const edit = props.edit;
        const workOrderType = edit.workOrderType;
        const dialogClasses = "settings workOrderType px800" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.WorkOrderType + " - " + (!workOrderType.isNew() ? workOrderType.title : Translations.New)}
                    show={true}
                    body={
                        <div className="workOrderTypeContainer">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group required">
                                        <label className="control-label smallFont">{Translations.Name}</label>
                                        <input type="text" className="form-control" name="title" title={Translations.Name} value={state.title} onChange={this.handleChange} maxLength={50}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.EmployeeUsageType}</label>
                                        <select className="custom-select" name="employeeUsageType" title={Translations.EmployeeUsageType} value={state.employeeUsageType.toString(10)} onChange={this.handleChange}>
                                            <option value={WorkOrderTypeEmployeeUsageType.NotInUse.toString(10)}>{Translations.NotInUse}</option>
                                            <option value={WorkOrderTypeEmployeeUsageType.One.toString(10)}>{Translations.EmployeeUsageTypeOne}</option>
                                            <option value={WorkOrderTypeEmployeeUsageType.Multiple.toString(10)}>{Translations.EmployeeUsageTypeMultiple}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.VehicleUsageType}</label>
                                        <select className="custom-select" name="vehicleUsageType" title={Translations.VehicleUsageType} value={state.vehicleUsageType.toString(10)} onChange={this.handleChange}>
                                            <option value={WorkOrderTypeVehicleUsageType.NotInUse.toString(10)}>{Translations.NotInUse}</option>
                                            <option value={WorkOrderTypeVehicleUsageType.One.toString(10)}>{Translations.VehicleUsageTypeOne}</option>
                                            <option value={WorkOrderTypeVehicleUsageType.Multiple.toString(10)}>{Translations.VehicleUsageTypeMultiple}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="control-label smallFont">&nbsp;</label>
                                        <CheckBox
                                            title={Translations.LocationDataSavingInUse}
                                            enabled={true}
                                            checked={state.locationUsageType > 0.5}
                                            onCheckboxClickBoolean={(checked: boolean) => { this.handleChangeBoolean("locationUsageType", checked); }}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.LocationDataSavingInterval}</label>
                                        <select className="custom-select" name="locationDataUpdateIntervalS" title={Translations.LocationDataSavingInterval} value={state.locationDataUpdateIntervalS.toString(10)} onChange={this.handleChange} disabled={!(state.locationUsageType > 0.5)}>
                                            <option value={60}>1 {Translations.AbrMins}</option>
                                            <option value={120}>2 {Translations.AbrMins}</option>
                                            <option value={300}>5 {Translations.AbrMins}</option>
                                            <option value={600}>10 {Translations.AbrMins}</option>
                                            <option value={1800}>30 {Translations.AbrMins}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <CheckBox
                                            title={Translations.WorkOrderIsShownInWorkQueue}
                                            enabled={true}
                                            checked={state.showWorkOrderTypeInWorkQueue}
                                            onCheckboxClickBoolean={(checked: boolean) => { this.handleChangeBoolean("showWorkOrderTypeInWorkQueue", checked); }}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <CheckBox
                                            title={Translations.WorkOrderCanBeCreatedInMobileApp}
                                            enabled={true}
                                            checked={state.canBeCreatedInMobile}
                                            onCheckboxClickBoolean={(checked: boolean) => { this.handleChangeBoolean("canBeCreatedInMobile", checked); }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="commandRow">
                                <label className="control-label listTitle title">{Translations.Invoicing}</label>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <div className="form-group required">
                                        <label className="control-label smallFont">{Translations.VatRate}</label>
                                        <input type="text" className="form-control" name="vatRate" title={Translations.VatRate} value={state.vatRate} onChange={this.handleChange} onBlur={this.handleBlur} maxLength={10}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.HoursInvoicingType}</label>
                                        <select className="custom-select" name="hoursInvoicingType" title={Translations.HoursInvoicingType} value={state.hoursInvoicingType.toString(10)} onChange={this.handleChange}>
                                            <option value={WorkOrderTypeHoursInvoicingType.NotInUse.toString(10)}>{Translations.HoursInvoicingTypeNoNotInUse}</option>
                                            <option value={WorkOrderTypeHoursInvoicingType.Realized.toString(10)}>{Translations.HoursInvoicingTypeRealized}</option>
                                            <option value={WorkOrderTypeHoursInvoicingType.Planned.toString(10)}>{Translations.HoursInvoicingTypePlanned}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.HoursInvoicingProduct}</label>
                                        <select className="custom-select" name="hoursInvoicingProductId" title={Translations.HoursInvoicingProduct} value={state.hoursInvoicingProductId} onChange={this.handleChange}>
                                            <option key="noinuse" value="">{"'" + Translations.NotSelected + "'"}</option>
                                            {edit.products.map((product) =>
                                                <option key={product.id} value={product.id}>{product.name}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.KmsInvoicingType}</label>
                                        <select className="custom-select" name="kmsInvoicingType" title={Translations.KmsInvoicingType} value={state.kmsInvoicingType.toString(10)} onChange={this.handleChange}>
                                            <option value={WorkOrderTypeKmsInvoicingType.NotInUse.toString(10)}>{Translations.KmsInvoicingTypeNotInUse}</option>
                                            <option value={WorkOrderTypeKmsInvoicingType.Realized.toString(10)}>{Translations.KmsInvoicingTypeRealized}</option>
                                            <option value={WorkOrderTypeKmsInvoicingType.Planned.toString(10)}>{Translations.KmsInvoicingTypePlanned}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.KmsInvoicingProduct}</label>
                                        <select className="custom-select" name="kmsInvoicingProductId" title={Translations.KmsInvoicingProduct} value={state.kmsInvoicingProductId} onChange={this.handleChange}>
                                            <option key="noinuse" value="">{"'" + Translations.NotSelected + "'"}</option>
                                            {edit.products.map((product) =>
                                                <option key={product.id} value={product.id}>{product.name}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="commandRow">
                                <label className="control-label listTitle title">{Translations.UserInterfaceSettings}</label>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <CheckBox
                                            title={Translations.DescriptionIsVisible}
                                            enabled={true}
                                            checked={state.showDescription}
                                            onCheckboxClickBoolean={(checked: boolean) => { this.handleChangeBoolean("showDescription", checked); }}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <CheckBox
                                            title={Translations.CommentIsVisible}
                                            enabled={true}
                                            checked={state.showComment}
                                            onCheckboxClickBoolean={(checked: boolean) => { this.handleChangeBoolean("showComment", checked); }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <CheckBox
                                            title={Translations.MapIsVisible}
                                            enabled={true}
                                            checked={state.showMap}
                                            onCheckboxClickBoolean={(checked: boolean) => { this.handleChangeBoolean("showMap", checked); }}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <CheckBox
                                            title={Translations.AttachmentsAreVisible}
                                            enabled={true}
                                            checked={state.showAttachments}
                                            onCheckboxClickBoolean={(checked: boolean) => { this.handleChangeBoolean("showAttachments", checked); }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <CheckBox
                                            title={Translations.ToBeInvoicedRecordsAreVisible}
                                            enabled={true}
                                            checked={state.showRoutePointBookings}
                                            onCheckboxClickBoolean={(checked: boolean) => { this.handleChangeBoolean("showRoutePointBookings", checked); }}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <CheckBox
                                            title={Translations.EstimatedHoursAreVisible}
                                            enabled={true}
                                            checked={state.showEstimatedHours}
                                            onCheckboxClickBoolean={(checked: boolean) => { this.handleChangeBoolean("showEstimatedHours", checked); }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <CheckBox
                                            title={Translations.WorkOrderAcknowledgementTypeIsVisible}
                                            enabled={true}
                                            checked={state.showWorkOrderAcknowledgementType}
                                            onCheckboxClickBoolean={(checked: boolean) => { this.handleChangeBoolean("showWorkOrderAcknowledgementType", checked); }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="commandRow">
                                <label className="control-label listTitle title">{Translations.RoutePointUsage}</label>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="control-label smallFont">{Translations.SiteRoutePointUsageType}</label>
                                        <select className="custom-select" name="siteRoutePointUsageType" title={Translations.SiteRoutePointUsageType} value={state.siteRoutePointUsageType.toString(10)} onChange={this.handleChange}>
                                            <option value={WorkOrderTypeSiteRoutePointUsageType.Site.toString(10)}>{Translations.SiteRoutePointUsageTypeSite}</option>
                                            <option value={WorkOrderTypeSiteRoutePointUsageType.RoutePoint.toString(10)}>{Translations.SiteRoutePointUsageTypeRoutePoint}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {state.siteRoutePointUsageType === WorkOrderTypeSiteRoutePointUsageType.RoutePoint &&
                                <OwnerSettingsWorkOrderTypeDialogWorkShiftTimeSlotList
                                    isReadOnly={workOrderType.isNew()}
                                    workOrderTypeId={workOrderType.id}
                                    workOrderTypeWorkShiftTimeSlotTypes={state.workOrderTypeWorkShiftTimeSlotTypes}
                                    workShiftTimeSlotTypes={props.edit.workShiftTimeSlotTypes}
                                    products={props.edit.products}
                                    onModified={this.handleWorkOrderTypeWorkShiftTimeSlotTypeModified}
                                    onRemoved={this.handleWorkOrderTypeWorkShiftTimeSlotTypeRemoved}
                                />
                            }
                        </div>
                    }
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: this.handleOkClick }
                    ]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}
