import { styled, tableCellClasses, TableHead } from "@mui/material";

export const StickyFooter = styled(TableHead)(() => ({
    [`& .${tableCellClasses.root}`]: {
        left: 0,
        zIndex: 2,
        bottom: 0,
        position: "sticky",
        borderTop: "1px solid var(--palette-TableCell-border)",
    },
    [`& .sticky-header-cell-left`]: {
        zIndex: 3,
    },
}));
