import * as CustomStore from "../../framework/customStore";
import * as StoreActions from "../store/storeActions";
import * as customerService from "../../services/customerService";
import * as BaseService from "../../services/baseService";
import { Base } from "../../framework/base";
import { ICustomerEdit } from "./customerEdit";
import ApiSuccess = BaseService.ApiSuccess;

export class CustomerOperations {
    // #region Edit
    static getCustomerEdit(id: string): Promise<ICustomerEdit> {
        CustomStore.customStore.dispatch(StoreActions.fetchStart());
        return customerService.getCustomerEdit(id)
            .then(editItem => {
                return editItem;
            })
            .catch(error => {
                CustomStore.customStore.dispatch(StoreActions.showErrorMessage(BaseService.getErrorMessageFromError(error)));
                return Base.getPromiseResult(null);
            })
            .finally(() => CustomStore.customStore.dispatch(StoreActions.fetchEnd()));
    }

    static addCustomerContact = (customerId: string, contactId: string): Promise<ApiSuccess> => {
        CustomStore.customStore.dispatch(StoreActions.fetchStart());
        return customerService.addCustomerContact(customerId, contactId)
            .then(success => {
                return success;
            })
            .catch(error => {
                CustomStore.customStore.dispatch(StoreActions.showErrorMessage(BaseService.getErrorMessageFromError(error)));
                return Base.getPromiseResult(null);
            })
            .finally(() => CustomStore.customStore.dispatch(StoreActions.fetchEnd()));
    }
    // #endregion Edit
}