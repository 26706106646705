import { Base } from "../../framework/base";
import { IDocument, Document } from "../document/document";
import { IWorkOrderEditProductItem, WorkOrderEditProductItem } from "./workOrderEditProductItem";

export interface IWorkOrderEditItemProductBooking {
    id: string;
    employeeId: string;
    productId: string;
    amount: number;
    unitPrice: number;
    cost: number;
    unit: number;
    comment: string;
    rowId: string;
    documents: IDocument[];
    sortValue: string;
    isStorageBooking: boolean;

    isNew():boolean;
}

export class WorkOrderEditItemProductBooking implements IWorkOrderEditItemProductBooking {
    id = "";
    employeeId = "";
    productId = "";
    amount = 0;
    unitPrice = 0;
    cost = 0;
    unit = 0;
    comment = "";
    rowId = "";
    documents: Document[] = null;
    sortValue = "";
    isStorageBooking = false;

    constructor();
    constructor(obj: IWorkOrderEditItemProductBooking);
    constructor(obj?: any) {
        if (obj) {
            this.id = obj.id;
            this.employeeId = obj.employeeId;
            this.productId = obj.productId;
            this.amount = obj.amount;
            this.unitPrice = obj.unitPrice;
            this.cost = obj.cost;
            this.unit = obj.unit;
            this.comment = obj.comment;
            this.rowId = obj.rowId;
            this.sortValue = obj.sortValue;
            this.documents = Base.getTypedArray<Document>(obj.documents, Document);
            this.isStorageBooking = obj.isStorageBooking;
        } else {
            this.documents = Base.getTypedArray<Document>(null, Document);
        }
    }

    isNew():boolean {
        return !this.rowId;
    }

    static sortWorkOrderEditItemProductBookings(items: WorkOrderEditItemProductBooking[], products: IWorkOrderEditProductItem[], column: string, asc: boolean) {
        if (Base.isNullOrUndefined(items) || items.length < 2) return;
        let sortFunc: (a: WorkOrderEditItemProductBooking, b: WorkOrderEditItemProductBooking) => number;
        if (column === "amount") sortFunc = (a: WorkOrderEditItemProductBooking, b: WorkOrderEditItemProductBooking) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.amount, b.amount)); };
        else if (column === "unitPrice") sortFunc = (a: WorkOrderEditItemProductBooking, b: WorkOrderEditItemProductBooking) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.unitPrice, b.unitPrice)); };
        else if (column === "cost") sortFunc = (a: WorkOrderEditItemProductBooking, b: WorkOrderEditItemProductBooking) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.cost, b.cost)); };
        else if (column === "comment") sortFunc = (a: WorkOrderEditItemProductBooking, b: WorkOrderEditItemProductBooking) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.comment, b.comment)); };
        else {
            for (let i = 0; i < items.length; i++) {
                items[i].sortValue = WorkOrderEditProductItem.getTitleById(products, items[i].productId);
            }
            sortFunc = (a: WorkOrderEditItemProductBooking, b: WorkOrderEditItemProductBooking) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.sortValue, b.sortValue)); };
        }
        items.sort(sortFunc);
    }
}

export class WorkOrderEditItemSaveProductBooking {
    id: string;
    employeeId: string;
    productId: string;
    amount: number;
    unitPrice: number;
    cost: number;
    unit: number;
    comment: string;
    rowId: string;

    constructor();
    constructor(obj: IWorkOrderEditItemProductBooking);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.employeeId = obj && obj.employeeId || "";
        this.productId = obj && obj.productId || "";
        this.amount = obj && obj.amount || 0;
        this.unitPrice = obj && obj.unitPrice || 0;
        this.cost = obj && obj.cost || 0;
        this.unit = obj && obj.unit || 0;
        this.comment = obj && obj.comment || "";
        this.rowId = obj && obj.rowId || "";
    }
}
