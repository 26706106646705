import { Base } from "../../framework/base";
import { ICustomerTypeEditItem, CustomerTypeEditItem } from "./customerTypeEditItem";
import { ICustomerItem, CustomerItem } from "../customer/customerItem";

export interface ICustomerTypeEdit {
    customerType: ICustomerTypeEditItem;
    customers: ICustomerItem[];
}

export class CustomerTypeEdit implements ICustomerTypeEdit {
    customerType: CustomerTypeEditItem;
    customers: CustomerItem[];

    constructor();
    constructor(obj: ICustomerTypeEdit);
    constructor(obj?: any) {
        this.customerType = new CustomerTypeEditItem(obj ? obj.customerType : null);
        this.customers = Base.getTypedArray<CustomerItem>(obj ? obj.customers : null, CustomerItem);
    }
}