import React, { useEffect, useState } from "react";
import { Translations } from "../../../models/translations";
import { getStorages } from "../../../services/storageService";
import { AutocompleteOption, AutocompleteWrapper } from "../../framework/muiAutocomplete";

interface Props {
    value: string[];
    onChange: (val: string[]) => void;
}

export const StorageSelect = (props: Props) => {
    const [storageOptions, setStorageOptions] = useState<AutocompleteOption[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getStorages({})
            .then((val) => {
                setStorageOptions(val.map(v => ({ id: v.id, title: v.name })));
            })
            .finally(() => setLoading(false));
    }, []);

    const onChange = (val: AutocompleteOption[]) => {
        props.onChange(val.map(v => v.id));
    };

    return <AutocompleteWrapper
        label={Translations.ChooseStorage}
        value={props.value}
        options={storageOptions}
        onChange={onChange}
        loading={loading}
        multiple
        size="small"
           />;
};
