import { Base } from "../../framework/base";
import { CodeTitle, ICodeTitle } from "../common/codeTitle";
import { ReportParameterType } from "../common/enums";
import { ICodeTitleNode, CodeTitleNode } from "../common/codeTitleNode";

export interface IReportListItemParameter {
    name: string;
    parentTitle: string;
    title: string;
    type: ReportParameterType;
    required: boolean;
    valueList: ICodeTitle[];
    hierarchialValueList: ICodeTitleNode[];
    //Ui set field and backend set default value
    value: string;
}

export class ReportListItemParameter implements IReportListItemParameter {
    name: string;
    parentTitle: string;
    title: string;
    type: ReportParameterType;
    required: boolean;
    valueList: CodeTitle[];
    hierarchialValueList: CodeTitleNode[];
    //Ui set field and backend set default value
    value: string;

    constructor();
    constructor(obj: IReportListItemParameter);
    constructor(obj?: any) {
        this.name = obj && obj.name || "";
        this.parentTitle = obj && obj.parentTitle || "";
        this.title = obj && obj.title || "";
        this.type = obj && obj.type || ReportParameterType.Undefined;
        this.required = obj && obj.required || false;
        this.valueList = Base.getTypedArray<CodeTitle>(obj ? obj.valueList : null, CodeTitle);
        this.hierarchialValueList = Base.getTypedArray<CodeTitleNode>(obj ? obj.hierarchialValueList : null, CodeTitleNode);
        this.value = obj && obj.value || "";
    }
}

export class ReportParameterGenerate {
    name: string;
    value: string;

    constructor();
    constructor(obj: IReportListItemParameter);
    constructor(obj?: any) {
        this.name = obj && obj.name || "";
        this.value = obj && obj.value || null;
    }
}
