import { Base } from "../../framework/base";
import { IIdTitle, IdTitle } from "../common/idTitle";
import { ILocationPoint, LocationPoint } from "../common/locationPoint";
import { IVehicleEditItemIntegration, VehicleEditItemIntegration } from "./vehicleEditItemIntegration";

export interface IVehicleEditItem extends ILocationPoint {
    vehicleGroupId: string;
    costCenterId: string;
    number: number;
    registerNumber: string;
    brand: string;
    abbreviation: string;
    picture: string;
    comment: string;
    activeState: number;
    inspectionDate: number;
    latitude: number;
    longitude: number;
    locationTime: number;
    rowId: string;
    code: string;
    employees: IIdTitle[];
    tools: IIdTitle[];
    integrations: IVehicleEditItemIntegration[];

    isNew():boolean;
}

export class VehicleEditItem extends LocationPoint implements IVehicleEditItem {
    vehicleGroupId: string;
    costCenterId: string;
    number: number;
    registerNumber: string;
    brand: string;
    abbreviation: string;
    picture: string;
    comment: string;
    activeState: number;
    inspectionDate: number;
    latitude: number;
    longitude: number;
    locationTime: number;
    rowId: string;
    code: string;
    employees: IdTitle[];
    tools: IdTitle[];
    integrations: VehicleEditItemIntegration[];

    constructor();
    constructor(obj: IVehicleEditItem);
    constructor(obj?: any) {
        super(obj);
        this.vehicleGroupId = obj && obj.vehicleGroupId || "";
        this.costCenterId = obj && obj.costCenterId || "";
        this.number = obj && obj.number || 0;
        this.registerNumber = obj && obj.registerNumber || "";
        this.brand = obj && obj.brand || "";
        this.abbreviation = obj && obj.abbreviation || "";
        this.picture = obj && obj.picture || "";
        this.comment = obj && obj.comment || "";
        this.activeState = obj && obj.activeState || 0;
        this.inspectionDate = obj && obj.inspectionDate || 0;
        this.longitude = obj && obj.longitude || 0;
        this.latitude = obj && obj.latitude || 0;
        this.locationTime = obj && obj.locationTime || 0;
        this.rowId = obj && obj.rowId || "";
        this.code = obj && obj.code || "";
        this.employees = Base.getTypedArray<IdTitle>(obj ? obj.employees : null, IdTitle);
        this.tools = Base.getTypedArray<IdTitle>(obj ? obj.tools : null, IdTitle);
        this.integrations = Base.getTypedArray<VehicleEditItemIntegration>(obj ? obj.integrations : null, VehicleEditItemIntegration);
    }

    isNew():boolean {
        return !this.rowId;
    }
}