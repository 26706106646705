// TrackingOperationLogDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import { Dialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { IOperationLogEdit } from "../../models/operationLog/operationLogEdit";

// TrackingOperationLogDialog
// ***********************************************************************************************************************
export interface ITrackingOperationLogDialogProp {
    classes?: string;
    editItem: IOperationLogEdit;
    onCancel: () => void;
}

export interface ITrackingOperationLogDialogState {
}

export class TrackingOperationLogDialog extends React.Component<ITrackingOperationLogDialogProp, ITrackingOperationLogDialogState> {
    handleCancelClick = () => {
        const obj = this;
        obj.props.onCancel();
    };

    render() {
        const props = this.props;
        const editItem = props.editItem;
        const dialogClasses = "tracking operationLog px800 property" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <Dialog
                    classes={dialogClasses}
                    title={Translations.OperationLog + " - " + Base.timeToDateTimeStr(editItem.operationLog.time)}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-3">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Time}</label>
                                    <div className="roValue">{Base.timeToDateTimeStr(editItem.operationLog.time)}</div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.IpAddress}</label>
                                    <div className="roValue">{editItem.operationLog.ip}</div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.LogEntryType}</label>
                                    <div className="roValue">{editItem.operationLog.entryType}</div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.SoftwareVersion}</label>
                                    <div className="roValue">{editItem.operationLog.version}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Username}</label>
                                    <div className="roValue">{editItem.operationLog.user}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Operation}</label>
                                    <div className="roValue longValue">{editItem.operationLog.operation}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Parameters}</label>
                                    <div className="roValue longValue">{editItem.operationLog.parameters}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="control-label smallFont">{Translations.Message}</label>
                                    <div className="roValue longValue">{editItem.operationLog.message}</div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}