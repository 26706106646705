import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { TransportOrderListItemDto, TransportOrderState } from "../../models/transport/transportOrder";

const DEFAULT_TIMERANGE = 14;

interface TransportOrdersState {
    timeRange: [Date, Date];
    billingCustomer: string | null;
    senderCustomer: string | null;
    receiverCustomer: string | null;
    selectedOrder: TransportOrderListItemDto | null;
    keyword: string;
    selectedOrderStates: Record<TransportOrderState, boolean>;
    ordersDataChanged: boolean;
}

const initialState: TransportOrdersState = {
    timeRange: [
        moment().startOf("week").toDate(),
        moment().startOf("week").add(DEFAULT_TIMERANGE, "days").toDate(),
    ],
    billingCustomer: null,
    senderCustomer: null,
    receiverCustomer: null,
    selectedOrder: null,
    keyword: "",
    selectedOrderStates: {
        [TransportOrderState.OrderReceived]: true,
        [TransportOrderState.OrderCreated]: true,
        [TransportOrderState.Planned]: true,
        [TransportOrderState.PickedUp]: true,
        [TransportOrderState.Delivered]: true,
    },
    ordersDataChanged: false,
};

export const transportOrdersSlice = createSlice({
    name: "transportOrders",
    initialState,
    reducers: {
        setTimeRange(state, action: PayloadAction<[Date, Date]>) {
            state.timeRange = action.payload;
        },
        setBillingCustomer(state, action: PayloadAction<string>) {
            state.billingCustomer = action.payload;
        },
        setSenderCustomer(state, action: PayloadAction<string>) {
            state.senderCustomer = action.payload;
        },
        setReceiverCustomer(state, action: PayloadAction<string>) {
            state.receiverCustomer = action.payload;
        },
        setKeyword(state, action: PayloadAction<string>) {
            state.keyword = action.payload;
        },
        toggleOrderStateSelected(state, action: PayloadAction<TransportOrderState>) {
            state.selectedOrderStates[action.payload] = !state.selectedOrderStates[action.payload];
        },
        setOrdersDataChanged(state, action: PayloadAction<boolean>) {
            state.ordersDataChanged = action.payload;
        },
        updateOrderState(state, action: PayloadAction<TransportOrderState>) {
            if (state.selectedOrder) {
                state.selectedOrder.state = action.payload;
            }
        }
    },
});

export const {
    setTimeRange,
    setBillingCustomer,
    setSenderCustomer,
    setReceiverCustomer,
    setKeyword,
    toggleOrderStateSelected,
    setOrdersDataChanged,
    updateOrderState,
} = transportOrdersSlice.actions;

export const transportOrdersReducer = transportOrdersSlice.reducer;
