import { useMemo } from "react";
import { useAppSelector } from "../../framework/customStore";
import {
    makeSelectWorkShiftTimeSlotIdsByWorkShiftDate,
    makeSelectWorkShiftsByDate,
} from "../../store/workShiftSlice.";
import { FetchState } from "../../models/common/enums";

export const useSelectWorkShiftsByDate = (
    employeeId: string,
    rangeStart: string,
    rangeEnd: string
) => {
    const state = useAppSelector((state) => state.workShift);

    const selectWorkShiftsByDate = useMemo(makeSelectWorkShiftsByDate, []);
    return selectWorkShiftsByDate(state, employeeId, rangeStart, rangeEnd);
};

export const useSelectWorkShiftsTimeSlotIdsByWorkShiftDate = (
    employeeId: string,
    rangeStart: string,
    rangeEnd: string
) => {
    const state = useAppSelector((state) => state.workShift);

    const selectWorkShiftTimeSlotIdsByShiftDate = useMemo(
        makeSelectWorkShiftTimeSlotIdsByWorkShiftDate,
        []
    );
    return selectWorkShiftTimeSlotIdsByShiftDate(
        state,
        employeeId,
        rangeStart,
        rangeEnd
    );
};

export const useSelectWorkShiftsFetchState = () =>
    useAppSelector((state) => state.workShift.fetchState);

export const useSelectWorkShiftsFetched = () =>
    useSelectWorkShiftsFetchState() === FetchState.Done;
