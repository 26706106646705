import ExcelJS from "exceljs";

export const exportCsvToExcel = (csv: string, title = "export") => {

    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet("Sheet1");

    const rows = csv.split("\n");
    rows.forEach((row) => {
        ws.addRow(row.split(","));
    });

    download(title, wb);
};

export const exportWorkTimeListToExcel = (elementId: string, title: string = "export") => {

    // variables
    const commentColumnIndex = 3;
    const worksheetName = "Sheet1";
    const headerFgColor = "EFEFEF";


    // get table by elementid
    const element = document.getElementById(elementId);
    const table = element.querySelector("table");

    // get header rows
    const headerRows = table.querySelectorAll("thead tr");
    const headerNames = Array.from(headerRows).map((row) => {
            const cells = row.querySelectorAll("th");
            return Array.from(cells).map((cell) => cell.innerText);
        }
    );

    // Define header style
    const headerStyle = {
        font: { bold: true },
        fill: {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: headerFgColor },
        },
    };

    const wb = new ExcelJS.Workbook();
    const worksheet = wb.addWorksheet(worksheetName);

    for (let i = 0; i < headerNames.length; i ++) {
        if (i === 0) continue;
        const headerRow = worksheet.addRow(headerNames[i]);
        headerRow.eachCell((cell) => {
            Object.assign(cell, headerStyle);
        });
    }

    // get table rows
    const rows = table.querySelectorAll("tbody tr");
    let previousName = null;
    for (let i = 0; i < rows.length; i ++) {
        // if first contains span with class initials bold text

        const row = rows[i];
        const cells = row.querySelectorAll("td");
        //row inner text without span with clas initials
        let nameRow = 0;
        const rowValues = Array.from(cells).map((cell, index) => {
                // check if contains div with class "avatar"
                const div = cell.querySelector("div");
                if (div) {
                    const divClass = div.getAttribute("class");
                    if (divClass && divClass.includes("avatar")) {
                        nameRow = 1;
                        const span = cell.querySelector("span");
                        if (span) {
                            const spanClass = span.getAttribute("class");
                            if (spanClass && spanClass.includes("initials")) {
                                const result = cell.innerText.replace(span.innerText, "");
                                previousName = result;
                                return result;
                            }
                            else {
                                previousName = cell.innerText;
                                return cell.innerText;
                            }
                        }
                    }
                } else if (index === commentColumnIndex - 1) {
                    return cell.getAttribute("data-comments")?.replace(/\n/g, "\r\n");
                }
                return cell.innerText;
            }
        );
        const rowExcel = worksheet.addRow(rowValues);
        if (nameRow === 1) {
            rowExcel.font = { bold: true };
        }

        let c = 0;
        worksheet.columns.forEach((column) => {
                c ++;
                if (c === commentColumnIndex) {
                    rowExcel.getCell(commentColumnIndex).alignment = { wrapText: true };
                }

                let maxLength = 0;
                column.eachCell((cell) => {
                    const columnLength = cell.value ? cell.value.toString().length : 10;
                    if (columnLength > maxLength) {
                        maxLength = columnLength;
                    }
                });
                column.width = maxLength < 10 ? 10 : maxLength;
            }
        );

    }


    // save workbook
    download(title, wb);
};

const download = (title: string, wb: ExcelJS.Workbook) => {
    wb.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = title + ".xlsx";
        a.click();
    }).catch((error) => {
        console.error(error);
    });
};
