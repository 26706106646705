import React, { useEffect, useState } from "react";
import { getIn, useFormik } from "formik";
import { Link, useLocation } from "react-router-dom";
import * as yup from "yup";
import {
    Box,
    Button,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputAdornment,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HelpIcon from "@mui/icons-material/Help";
import Grid2 from "@mui/material/Unstable_Grid2";
import { customHistory } from "../../../framework/customHistory";
import { STYLE_CONSTANTS } from "../../../framework/theme";
import { Translations } from "../../../models/translations";
import { TransportOrderDetailsDto, TransportOrderInvoicingState, TransportOrderState } from "../../../models/transport/transportOrder";
import { apiCall } from "../../../services/apiClient";
import {
    GridBreak,
    defaultFormikConfig,
    formikFieldProps,
    showApiError,
    showApiSuccess,
    showConfirm,
    formatString
} from "../../framework/formUtils";
import { GridDivider } from "../../framework/gridDivider";
import { LoadingIndicator } from "../../framework/loadingIndicator";
import { MuiDateTimeRangePicker } from "../../framework/muiDatepicker";
import { CustomerSelect } from "../components/customerSelect";
import { getCustomerDetails } from "../../../services/customerService";
import { ICustomerDetails } from "../../../models/customer/customerDetails";
import { useAppDispatch, useAppSelector } from "../../../framework/customStore";
import { deleteTransportOrder, setSideBarOrderDetails } from "../../../store/transport/transportVehiclesSlice";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { createTransportOrder } from "../../../services/transportOrderService";
import { getParcelsForOrder, postParcel } from "../../../services/parcelService";
import { getCopyDataToTransportOrder } from "../transportTimeline/Utils";
import { ParcelTable } from "./parcelTable/parcelTable";

interface ITransportOrderFormProps {
    order: TransportOrderDetailsDto;
}

const convertStrToNum = (value: string) => +value.replace(/,/, ".");

export const TransportOrderForm = (props: ITransportOrderFormProps) => {
    const [id, setId] = useState<string | null>(props.order.id);
    const [otherPayerToggled, setOtherPayerToggled] = useState<boolean>(false);
    const location = useLocation<{ fromPath: string }>();
    const fromPath = location.state?.fromPath ?? "/transport/orders";
    const dispatch = useAppDispatch();
    const employeeId = useAppSelector(state => state.user.employeeId);

    const handleSubmit = (formData: TransportOrderDetailsDto) => {
        formik.setSubmitting(true);
        if (id === null) {
            apiCall<TransportOrderDetailsDto, TransportOrderDetailsDto>("TransportOrders", "POST",
                {
                    ...formData,
                    fixedPrice: convertStrToNum(`${formData.fixedPrice}`),
                    createdEmployeeId: employeeId
                })
                .then(res => {
                    setId(res.data.id);
                    void formik.setValues(res.data);
                    customHistory.replace(`/transport/orders/${res.data.id}`);
                    showApiSuccess("Uusi tilaus tallennettu");
                    dispatch(setSideBarOrderDetails(res.data));
                })
                .catch(() => showApiError("Tilauksen luonti epäonnistui"))
                .finally(() => formik.setSubmitting(false));
        } else {
            apiCall<TransportOrderDetailsDto, TransportOrderDetailsDto>(`TransportOrders/${id}`, "PUT",
                {
                    ...formData,
                    fixedPrice: convertStrToNum(`${formData.fixedPrice}`),
                    modifiedEmployeeId: employeeId
                })
                .then(res => {
                    void formik.setValues(res.data);
                    dispatch(setSideBarOrderDetails(res.data));
                    showApiSuccess("Muutokset tallennettu");
                    customHistory.push(fromPath);
                })
                .catch(() => showApiError("Muutoksien tallennus epäonnistui"))
                .finally(() => formik.setSubmitting(false));
        }
    };

    const handleRemove = () => {
        const submitRemove = async() => {
            try {
                await dispatch(deleteTransportOrder(id)).unwrap();
                dispatch(setSideBarOrderDetails(null));
                showApiSuccess(Translations.DeleteWasSuccess);
                customHistory.push(fromPath);
            } catch (error) {
                showApiError("Kuljetustilauksen poisto epäonnistui");
            }
        };
        showConfirm(Translations.AreYouSureWantDelete, submitRemove);
    };

    const handleCreateCopy = () => {
        const copyData = getCopyDataToTransportOrder(formik.values);
        createTransportOrder({ ...copyData, createdEmployeeId: employeeId })
            .then(res => {
                if (res) {
                    const newId = res.data.id;
                    void formik.setValues({
                        ...copyData,
                        id: newId,
                        orderNumber: res?.data?.orderNumber,
                        waybillNumber: ""
                    });
                    dispatch(setSideBarOrderDetails(res.data));
                    getParcelsForOrder(id)
                        .then(res => {
                            if (res.data) {
                                res.data.map(parcel => {
                                    const { id, transportOrderId, ...rest } = parcel;
                                    postParcel(newId, rest).catch(() => showApiError("Kopion luominen epäonnistui."));
                                });
                            }
                            customHistory.replace(`/transport/orders/${newId}`);
                            showApiSuccess("Kopion luominen onnistui");
                        }).finally(() => {
                            setId(newId);
                        })
                        .catch(showApiError);
                }
            })
            .catch(() => showApiError("Kopion luominen epäonnistui."));
    };

    const formik = useFormik<TransportOrderDetailsDto>({
        ...defaultFormikConfig,
        enableReinitialize: true,
        initialValues: props.order,
        onSubmit: handleSubmit,
        validationSchema: yup.object({
            name: yup.string()
                .required(formatString(Translations.FieldIsRequired, "Kuljetustilauksen nimi"))
                .min(3, "Nimen tulee olla vähintään 3 merkkiä pitkä")
                .max(50, "Nimen tulee olla korkeintaan 50 merkkiä pitkä"),
            fixedPrice: yup.number()
                .transform((_, value: string) => {
                    if(typeof value === "number") return value;
                    return +value.replace(/,/, ".");
                })
                .min(0, Translations.InvalidPriceError)
                .typeError(Translations.InvalidValue),
            billingCustomerId: yup.string().nullable()
                .uuid(Translations.InvalidValue)
                .required(formatString(Translations.FieldIsRequired, "Maksaja")),
        })
    });

    const setFormikCustomerDetails = (prefix: string, customer: ICustomerDetails | null) => {
        const customerDetails = {
            [`${prefix}Address`]: customer?.streetAddress || "",
            [`${prefix}City`]: customer?.city || "",
            [`${prefix}ZipCode`]: customer?.postalCode || "",
            [`${prefix}Country`]: "Suomi"
        };
        if(!customer ||
            !formik.values?.[`${prefix}Address`] ||
            !formik.values?.[`${prefix}City`] ||
            !formik.values?.[`${prefix}ZipCode`] ||
            !formik.values?.[`${prefix}Country`]
        ) void formik.setValues({ ...formik.values, ...customerDetails });
    };

    useEffect(() => {
        const prefix = "pickUp";
        let senderFetched = false;
        const getSender = async(id: string) => {
            const res = await getCustomerDetails(id);
            const customerDetails: ICustomerDetails = res?.data;
            setFormikCustomerDetails(prefix, customerDetails);
            senderFetched = true;
        };
        if(formik.values?.senderCustomerId && !senderFetched) {
            getSender(formik.values.senderCustomerId).catch(() => showApiError(Translations.FetchFailed));
        } else {
            setFormikCustomerDetails(prefix, null);
        }
    }, [formik.values.senderCustomerId]);

    useEffect(() => {
        const prefix = "delivery";
        let receiverFetched = false;
        const getReceiver = async(id: string) => {
            const res = await getCustomerDetails(id);
            const customerDetails: ICustomerDetails = res?.data;
            setFormikCustomerDetails(prefix, customerDetails);
            receiverFetched = true;
        };
        if(formik.values?.receiverCustomerId && !receiverFetched) {
            getReceiver(formik.values.receiverCustomerId).catch(() => showApiError(Translations.FetchFailed));
        } else {
            setFormikCustomerDetails(prefix, null);
        }
    }, [formik.values.receiverCustomerId]);

    const fieldProps = (field: keyof TransportOrderDetailsDto) => formikFieldProps(formik, field);

    const isNew = id === null;
    const billingCustomerIsNotSenderOrReceiver = formik.values.billingCustomerId !== formik.values.senderCustomerId && formik.values.billingCustomerId !== formik.values.receiverCustomerId;
    const orderIsOnInvoice = formik.values.invoicingState === TransportOrderInvoicingState.AddedToInvoice;
    const senderIsBillingCustomer = formik.values.billingCustomerId === formik.values.senderCustomerId;
    const receiverIsBillingCustomer = formik.values.billingCustomerId === formik.values.receiverCustomerId;

    useEffect(() => {
        if(billingCustomerIsNotSenderOrReceiver) setOtherPayerToggled(true);
    }, [billingCustomerIsNotSenderOrReceiver]);

    return (
        <>
            <form>
                <IconButton
                    component={Link}
                    to={fromPath}
                    color="primary"
                >
                    <ArrowBackIcon />
                </IconButton>

                <Grid2 container marginLeft={4} mt={2}>
                    <Typography variant="h2" color="primary.dark">
                        {id === null ? "Luo uusi kuljetustilaus" : "Muokkaa kuljetustilausta"}
                    </Typography>

                    <Grid2>
                        <Typography variant="h3" mt={3} color="primary.dark">Perustiedot</Typography>
                        <Stack spacing={STYLE_CONSTANTS.formSpacing}>
                            {formik.values.orderNumber &&
                                <div className="font-weight-bold">
                                    Tilausnumero #{formik.values.orderNumber}
                                </div>
                            }
                            <Grid2 container>
                                <TextField
                                    className="field-width-big"
                                    label="Kuljetustilauksen nimi"
                                    required
                                    {...fieldProps("name")}
                                />
                            </Grid2>
                            <Grid2 container>
                                <TextField
                                    label="Rahtikirjan numero"
                                    {...fieldProps("waybillNumber")}
                                />
                            </Grid2>
                            <Grid2 container>
                                <TextField
                                    label="Kiinteä hinta"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">€</InputAdornment>
                                    }}
                                    {...fieldProps("fixedPrice")}
                                    value={`${formik.values.fixedPrice}`.replace(/[.]/g, ",")}
                                    onChange={(e) => {
                                        void formik.setFieldValue("fixedPrice", e.target.value);
                                    }}
                                    onFocus={(e) => e?.target.select()}
                                />
                            </Grid2>
                            {props.order.driverComments && (
                                <Grid2>
                                    <Typography variant="subtitle1" fontWeight="bold" mt={2} mb={1}>Kuljettajan kommentit</Typography>
                                    <Typography color="gray.main" py={1}>
                                        <i>{props.order.driverComments}</i>
                                    </Typography>
                                </Grid2>
                            )}
                        </Stack>

                        <Typography variant="h3" mt={4}>Lähettäjä</Typography>
                        <Grid2 container>
                            <Grid2>
                                <CustomerSelect
                                    label="Lähettäjä"
                                    {...fieldProps("senderCustomerId")}
                                    disabled={senderIsBillingCustomer && orderIsOnInvoice}
                                />
                            </Grid2>
                        </Grid2>

                        <Typography variant="h3" mt={4}>Vastaanottaja</Typography>
                        <Grid2 container>
                            <Grid2>
                                <CustomerSelect
                                    label="Vastaanottaja"
                                    {...fieldProps("receiverCustomerId")}
                                    disabled={receiverIsBillingCustomer && orderIsOnInvoice}
                                />
                            </Grid2>
                        </Grid2>
                        <Box display="flex" alignItems="center">
                            <Typography variant="h3" mt={4} mr={1}>Maksaja</Typography>
                            {orderIsOnInvoice &&
                                <Tooltip title={<Typography variant="subtitle2">Laskulla olevan kuljetustilauksen maksajatietoja ei voi muuttaa</Typography>}><HelpIcon color="primary" fontSize="small"/></Tooltip>
                            }
                        </Box>
                        <Stack spacing={STYLE_CONSTANTS.formSpacing}>
                            <Grid2>
                                <RadioGroup row {...fieldProps("billingCustomerId")} name="billingCustomerId" value="">
                                    <FormControlLabel
                                        label="Lähettäjä"
                                        value={formik.values.senderCustomerId ?? ""}
                                        onChange={() => setOtherPayerToggled(false)}
                                        disabled={!formik.values.senderCustomerId || orderIsOnInvoice}
                                        control={<Radio checked={!otherPayerToggled && formik.values.senderCustomerId === formik.values.billingCustomerId} />}
                                    />
                                    <FormControlLabel
                                        label="Vastaanottaja"
                                        value={formik.values.receiverCustomerId ?? ""}
                                        onChange={() => setOtherPayerToggled(false)}
                                        disabled={!formik.values.receiverCustomerId || orderIsOnInvoice}
                                        control={<Radio checked={!otherPayerToggled && formik.values.receiverCustomerId === formik.values.billingCustomerId} />}
                                    />
                                    <FormControlLabel
                                        label="Muu"
                                        value={""}
                                        onChange={() => setOtherPayerToggled(true)}
                                        control={<Radio checked={otherPayerToggled} />}
                                        disabled={orderIsOnInvoice}
                                    />
                                </RadioGroup>

                                {/*RadioGroup does not support errorText so show it manually */}
                                {!otherPayerToggled && getIn(formik.touched, "billingCustomerId") && Boolean(getIn(formik.errors, "billingCustomerId")) &&
                                    <FormHelperText error>{getIn(formik.touched, "billingCustomerId") && getIn(formik.errors, "billingCustomerId")}</FormHelperText>
                                }
                            </Grid2>

                            {otherPayerToggled &&
                                <Grid2>
                                    <CustomerSelect
                                        label="Muu maksaja"
                                        required
                                        {...fieldProps("billingCustomerId")}
                                        onChange={(val) => {
                                            if (val === null) {
                                                fieldProps("billingCustomerId").onChange("");
                                            } else {
                                                fieldProps("billingCustomerId").onChange(val);
                                            }
                                        }}
                                        disabled={otherPayerToggled && orderIsOnInvoice}
                                    />
                                </Grid2>
                            }
                        </Stack>

                        {orderIsOnInvoice && (
                            <Grid2>
                                <Typography variant="subtitle1" fontWeight="bold" mt={2} mb={1}>Laskun tiedot</Typography>
                                <Box display="flex" alignItems="center">
                                    <Typography color="gray.main" py={1} mr={2}>
                                        <i>Kuljetustilaus on {props.order.invoicingState === TransportOrderInvoicingState.AddedToInvoice ? "lisätty laskulle" : "laskutettu"}. </i>
                                    </Typography>
                                    <Button variant="outlined" size="small" onClick={() => customHistory.push(
                                        `/invoicingbeta/transportorderinvoice/${props.order.invoiceNewId}`,
                                        { from: `/transport/orders/${props.order.id}` }
                                    )}
                                    >
                                        Siirry laskulle
                                    </Button>
                                </Box>
                            </Grid2>
                        )}

                        <Typography variant="h3" mt={4}>Noutopiste</Typography>
                        <Grid2 container spacing={STYLE_CONSTANTS.formSpacing}>
                            <Grid2>
                                <TextField
                                    label="Osoite"
                                    {...fieldProps("pickUpAddress")}
                                />
                            </Grid2>
                            <Grid2>
                                <TextField
                                    label="Postinumero"
                                    {...fieldProps("pickUpZipCode")}
                                />
                            </Grid2>
                            <Grid2>
                                <TextField
                                    label="Kaupunki"
                                    {...fieldProps("pickUpCity")}
                                />
                            </Grid2>
                            <Grid2>
                                <TextField
                                    label="Maa"
                                    {...fieldProps("pickUpCountry")}
                                />
                            </Grid2>
                            <GridBreak />
                            <Grid2>
                                <MuiDateTimeRangePicker
                                    labels={["Noutoikkuna alkaa", "Noutoikkuna loppuu"]}
                                    value={[
                                        formik.values.pickUpStartDateTime ? new Date(formik.values.pickUpStartDateTime) : null,
                                        formik.values.pickUpEndDateTime ? new Date(formik.values.pickUpEndDateTime) : null,
                                    ]}
                                    onChange={(val) => {
                                        void formik.setFieldValue("pickUpStartDateTime", val[0]);
                                        void formik.setFieldValue("pickUpEndDateTime", val[1]);
                                    }}
                                />
                            </Grid2>
                            <GridBreak />
                            <Grid2 xs={12}>
                                <TextField
                                    className="field-width-big"
                                    label={Translations.PickupDetails}
                                    {...fieldProps("pickUpDetails")}
                                    multiline
                                    rows={3}
                                />
                            </Grid2>
                        </Grid2>

                        <Typography variant="h3" mt={4}>Toimituspiste</Typography>
                        <Grid2 container spacing={STYLE_CONSTANTS.formSpacing}>
                            <Grid2>
                                <TextField
                                    label="Osoite"
                                    {...fieldProps("deliveryAddress")}
                                />
                            </Grid2>
                            <Grid2>
                                <TextField
                                    label="Postinumero"
                                    {...fieldProps("deliveryZipCode")}
                                />
                            </Grid2>
                            <Grid2>
                                <TextField
                                    label="Kaupunki"
                                    {...fieldProps("deliveryCity")}
                                />
                            </Grid2>
                            <Grid2>
                                <TextField
                                    label="Maa"
                                    {...fieldProps("deliveryCountry")}
                                />
                            </Grid2>
                            <GridBreak />
                            <Grid2>
                                <MuiDateTimeRangePicker
                                    labels={["Toimitusikkuna alkaa", "Toimitusikkuna loppuu"]}
                                    value={[
                                        formik.values.deliveryStartDateTime ? new Date(formik.values.deliveryStartDateTime) : null,
                                        formik.values.deliveryEndDateTime ? new Date(formik.values.deliveryEndDateTime) : null,
                                    ]}
                                    onChange={(val) => {
                                        void formik.setFieldValue("deliveryStartDateTime", val[0]);
                                        void formik.setFieldValue("deliveryEndDateTime", val[1]);
                                    }}
                                />
                            </Grid2>
                            <GridBreak />
                            <Grid2 xs={12}>
                                <TextField
                                    className="field-width-big"
                                    label={Translations.DeliveryDetails}
                                    {...fieldProps("deliveryDetails")}
                                    multiline
                                    rows={3}
                                />
                            </Grid2>
                        </Grid2>
                    </Grid2>
                </Grid2>

                <Grid2 container marginLeft={4} mt={2}>
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            color="success"
                            size="large"
                            disabled={!formik.dirty ||
                                !formik.isValid ||
                                formik.isSubmitting ||
                                formik.values.invoicingState === TransportOrderInvoicingState.InvoicedInternally ||
                                formik.values.invoicingState === TransportOrderInvoicingState.InvoicedExternally
                            }
                            onClick={() => void formik.submitForm()}
                        >
                            {Translations.Save}
                        </Button>
                        {!isNew && (
                            <Grid2>
                                <Button
                                    variant="contained"
                                    disabled={
                                        formik.isSubmitting ||
                                        formik.values.invoicingState === TransportOrderInvoicingState.Invoiceable ||
                                        formik.values.invoicingState === TransportOrderInvoicingState.InvoicedInternally ||
                                        formik.values.invoicingState === TransportOrderInvoicingState.InvoicedExternally ||
                                        formik.values.invoicingState === TransportOrderInvoicingState.AddedToInvoice ||
                                        formik.values.state === TransportOrderState.PickedUp ||
                                        formik.values.state === TransportOrderState.Delivered}
                                    color="error"
                                    size="large"
                                    onClick={handleRemove}
                                    sx={{ marginRight: "12px" }}

                                >
                                    {Translations.Remove}
                                </Button>
                                <Tooltip title={"Kopioi uudeksi kuljetustilaukseksi"} >
                                    <IconButton disabled={formik.isSubmitting} onClick={handleCreateCopy} key="btn-copy">
                                        <ContentCopyOutlinedIcon color="primary" />
                                    </IconButton>
                                </Tooltip>
                            </Grid2>
                        )}
                    </Stack>

                    {formik.isSubmitting && <LoadingIndicator />}
                </Grid2>
            </form>

            <Grid2 container>
                <GridDivider orientation="horizontal"/>
                <Grid2 container marginLeft={4}>
                    <Grid2>
                        { !id && "Tallenna tilauksen perustiedot ennen kuljetusyksiköiden lisäystä" }
                        { id &&
                            <ParcelTable
                                orderId={id}
                                changesDisabled={
                                    formik.values.invoicingState === TransportOrderInvoicingState.InvoicedInternally ||
                                    formik.values.invoicingState === TransportOrderInvoicingState.InvoicedExternally
                                }
                            />
                        }
                    </Grid2>
                </Grid2>
            </Grid2>
        </>
    );
};
