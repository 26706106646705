import { Base } from "../../framework/base";
import { EnumHelper, InvoiceState } from "../common/enums";
import { IInvoiceListItemWorkOrder, InvoiceListItemWorkOrder } from "./invoiceListItemWorkOrder";

export interface IInvoiceListItem {
    id: string;
    number: number;
    customerName: string;
    state: number;
    stateStr: string;
    workOrderTypeName: string;
    workOrders: IInvoiceListItemWorkOrder[];
    // calculated fields
    workOrderNumbers: number[];
    siteNumbers: string[];

    isPreliminary(): boolean;
}

export class InvoiceListItem implements IInvoiceListItem {
    id: string;
    number: number;
    customerName: string;
    state: number;
    stateStr: string;
    workOrderTypeName: string;
    workOrders: InvoiceListItemWorkOrder[];
    // calculated fields
    workOrderNumbers: number[];
    siteNumbers: string[];

    constructor();
    constructor(obj: IInvoiceListItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.customerName = obj && obj.customerName || "";
        this.state = obj && obj.state || 0;
        this.stateStr = obj && obj.stateStr || "";
        this.workOrderTypeName = obj && obj.workOrderTypeName || "";
        this.workOrders = Base.getTypedArray<InvoiceListItemWorkOrder>(!Base.isNullOrUndefined(obj) ? obj.workOrders : null, InvoiceListItemWorkOrder);
        this.workOrderNumbers = this.workOrders.map(i => i.number).filter((x, i, a) => a.indexOf(x) === i).sort((a: number, b: number) => { return Base.numberCompare(a, b); });
        this.siteNumbers = this.workOrders.map(i => i.siteNumber).filter((x, i, a) => a.indexOf(x) === i).sort((a: string, b: string) => { return Base.stringCompare(a, b); });
    }

    isPreliminary(): boolean {
        return EnumHelper.isEqual(this.state, InvoiceState.Preliminary);
    }

    static sortInvoiceListItems(items: IInvoiceListItem[], column: string, asc: boolean) {
        if (Base.isNullOrUndefined(items) || items.length < 2) return;
        let sortFunc: (a: IInvoiceListItem, b: IInvoiceListItem) => number;
        if (column === "customer") sortFunc = (a: IInvoiceListItem, b: IInvoiceListItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.customerName, b.customerName)); };
        else if (column === "workOrderNumbers") sortFunc = (a: IInvoiceListItem, b: IInvoiceListItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.workOrderNumbers.length > 0 ? a.workOrderNumbers[0] : 0, b.workOrderNumbers.length > 0 ? b.workOrderNumbers[0] : 0)); };
        else if (column === "siteNumbers") sortFunc = (a: IInvoiceListItem, b: IInvoiceListItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.siteNumbers.length > 0 ? a.siteNumbers[0] : "", b.siteNumbers.length > 0 ? b.siteNumbers[0] : "")); };
        else if (column === "state") sortFunc = (a: IInvoiceListItem, b: IInvoiceListItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.state, b.state)); };
        else if (column === "workOrderTypeName") sortFunc = (a: IInvoiceListItem, b: IInvoiceListItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.workOrderTypeName, b.workOrderTypeName)); };
        else sortFunc = (a: IInvoiceListItem, b: IInvoiceListItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.number, b.number)); };
        items.sort(sortFunc);
    }
}