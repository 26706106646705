import { Base } from "../../framework/base";

export interface INameCompetencyName {
    name: string;
    competencyTypeName: string;
}

export interface IEmployeeEditCompetencyItem {
    id: string;
    name: string;
    competencyTypeName: string;
}

export class EmployeeEditCompetencyItem implements IEmployeeEditCompetencyItem {
    id: string;
    name: string;
    competencyTypeName: string;

    constructor();
    constructor(obj: IEmployeeEditCompetencyItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
        this.competencyTypeName = obj && obj.competencyTypeName || "";
    }

    static getNameAndCompetencyNameById(items: IEmployeeEditCompetencyItem[], id: string): INameCompetencyName {
        if (Base.isNullOrUndefined(items) || !id || items.length < 1) return { name: "", competencyTypeName: "" };
        const item = items.find(i => i.id === id);
        if (!Base.isNullOrUndefined(item)) {
            return { name: item.name, competencyTypeName: item.competencyTypeName };
        }
        return { name: "", competencyTypeName: "" };
    }
}