// SettingsContactDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as baseService from "../../services/baseService";
import * as storeActions from "../../models/store/storeActions";
import * as contactService from "../../services/contactService";
import { PropertyDialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { SaveData } from "../../framework/saveData";
import { CheckBox } from "../framework/checkbox";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { IContactEdit } from "../../models/contact/contactEdit";
import { IContactEditItemCustomerContactItem, ContactEditItemCustomerContactItem } from "../../models/contact/contactEditItemCustomerContactItem";
import { SettingsContactDialogCustomerList } from "./settingsContactDialogCustomerList";

// SettingsContactDialog
// ***********************************************************************************************************************
export interface ISettingsContactDialogProp {
    classes?: string;
    customersReadOnly?: boolean;
    editItem: IContactEdit;
    onOk: (id: string, name: string, phone: string, email: string, comment: string) => void;
    onCancel: () => void;
}

export interface ISettingsContactDialogState {
    name: string;
    phone: string;
    email: string;
    comment: string;
    activeState: number;
    customerContacts: IContactEditItemCustomerContactItem[];
    removedCustomerContactIds: string[];
    savedCustomerContactIds: string[];
}

export class SettingsContactDialog extends React.Component<ISettingsContactDialogProp, ISettingsContactDialogState> {
    private static orgStateHash: string = "";

    constructor(props) {
        super(props);
        const contact = props.editItem.contact;
        this.state = {
            name: contact.name,
            phone: contact.phone,
            email: contact.email,
            comment: contact.comment,
            activeState: contact.activeState,
            customerContacts: contact.customerContacts.slice(0),
            removedCustomerContactIds: [],
            savedCustomerContactIds: []
        };
        const saveData = SettingsContactDialog.getSaveDataFromState(props, this.state);
        SettingsContactDialog.orgStateHash = saveData.hash;
    }

    // #region General
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "Nm") {
            this.setState({ name: value });
        } else if (name === "Phn") {
            this.setState({ phone: value });
        } else if (name === "Eml") {
            this.setState({ email: value });
        } else if (name === "comment") {
            this.setState({ comment: value });
        }
    };

    handleActiveStateChange = (newActiveState: number) => {
        this.setState({ activeState: newActiveState });
    };
    // #endregion General

    // #region Customers
    handleCustomerContactAdd = (customerId: string, title: string) => {
        const customerContacts = this.state.customerContacts.slice(0);
        const savedCustomerContactIds = this.state.savedCustomerContactIds.slice(0);
        const item = new ContactEditItemCustomerContactItem();
        item.id = Base.getGuid();
        item.customerId = customerId;
        item.title = title;
        item.isNew = true;
        customerContacts.push(item);
        savedCustomerContactIds.push(item.id);
        ContactEditItemCustomerContactItem.sortContactEditItemCustomerContactItems(customerContacts, "title", true);
        this.setState({ customerContacts: customerContacts, savedCustomerContactIds: savedCustomerContactIds });
    };

    handleCustomerContactRemove = (id: string) => {
        if (!id) return;
        const item = this.state.customerContacts.find(i => i.id === id);
        if (Base.isNullOrUndefined(item)) return;
        const removedCustomerContactIds = this.state.removedCustomerContactIds.filter(i => i !== id);
        if (!item.isNew) {
            removedCustomerContactIds.push(id);
        }
        this.setState({ customerContacts: this.state.customerContacts.filter(i => i.id !== id), savedCustomerContactIds: this.state.savedCustomerContactIds.filter(i => i !== id), removedCustomerContactIds: removedCustomerContactIds });
    };
    // #endregion Customers

    private static validate = (state: ISettingsContactDialogState): boolean => {
        if (!state.name) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.NameMustBeDefined));
            return false;
        }
        return true;
    };

    private static getSaveDataFromState = (props: ISettingsContactDialogProp, state: ISettingsContactDialogState): SaveData => {
        const data = new SaveData();
        const contact = props.editItem.contact;
        // Common
        data.append("id", contact.id);
        data.append("rowId", contact.rowId);
        // General
        data.append("name", state.name);
        data.append("phone", state.phone);
        data.append("email", state.email);
        data.append("comment", state.comment);
        data.append("activeState", state.activeState.toString(10));
        // CustomerContacts
        data.append("addedCustomerContactCustomerIds", JSON.stringify(state.customerContacts.reduce((result, i) => {
            if (state.savedCustomerContactIds.indexOf(i.id) >= 0) {
                result.push(i.customerId);
            }
            return result;
        }, [])));
        data.append("removedCustomerContactIds", JSON.stringify(state.removedCustomerContactIds));
        return data;
    };

    saveContact = () => {
        const obj = this;
        const props = this.props;
        const state = this.state;
        if (!SettingsContactDialog.validate(state)) return;
        const saveData = SettingsContactDialog.getSaveDataFromState(props, state);
        if (Base.isNullOrUndefined(saveData)) return;
        // Call server
        store.customStore.dispatch(storeActions.fetchStart());
        contactService.saveContactEdit(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                obj.props.onOk(success.id, state.name, state.phone, state.email, state.comment);
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleOkClick = () => {
        this.saveContact();
    };

    handleCancelClick = () => {
        const obj = this;
        const saveData = SettingsContactDialog.getSaveDataFromState(this.props, this.state);
        if (!Base.isNullOrUndefined(saveData) && saveData.hash !== SettingsContactDialog.orgStateHash) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.saveContact();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.props.onCancel();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));
        } else {
            obj.props.onCancel();
        }
    };

    render() {
        const props = this.props;
        const editItem = props.editItem;
        const dialogClasses = "settings contact px800" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.Contact + " - " + (!editItem.contact.isNew() ? editItem.contact.number.toString(10) + " " + editItem.contact.name : Translations.New)}
                    show={true}
                    body={<div>
                        <div className="">
                            <div>
                                <div>
                                    <div className="row">
                                        <div className="col-2">
                                            <div className="form-group required">
                                                <label className="control-label smallFont">{Translations.Number}</label>
                                                <input type="text" className="form-control" name="number" title={Translations.Number} value={editItem.contact.isNew() ? Translations.New : editItem.contact.number.toString(10)} readOnly={true} disabled={true} />
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <div className="form-group required">
                                                <label className="control-label smallFont">{Translations.Name}</label>
                                                <input type="text" className="form-control" name="Nm" title={Translations.Name} value={this.state.name} onChange={this.handleChange} maxLength={50} />
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group">
                                                <label className="control-label smallFont">&nbsp;</label>
                                                <div>
                                                    <CheckBox
                                                        title={Translations.InUse}
                                                        enabled={true}
                                                        checked={this.state.activeState > 0}
                                                        onCheckboxClickBoolean={(checked: boolean) => { this.handleActiveStateChange(checked ? 1 : 0); }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="control-label smallFont">{Translations.Phone}</label>
                                                <input type="text" className="form-control" name="Phn" title={Translations.Phone} value={this.state.phone} onChange={this.handleChange} maxLength={50} />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="control-label smallFont">{Translations.Email}</label>
                                                <input type="text" className="form-control" name="Eml" title={Translations.Email} value={this.state.email} onChange={this.handleChange} maxLength={50} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="control-label smallFont">{Translations.Comment}</label>
                                                <textarea className="form-control" name="comment" title={Translations.Comment} value={this.state.comment} onChange={this.handleChange} maxLength={1000} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <SettingsContactDialogCustomerList
                                        title={Translations.Customers}
                                        readOnly={!Base.isNullOrUndefined(props.customersReadOnly) && props.customersReadOnly}
                                        items={this.state.customerContacts}
                                        onRemoveCustomer={this.handleCustomerContactRemove}
                                        onAddCustomer={this.handleCustomerContactAdd}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}