import { Base } from "../../framework/base";

export interface ISalaryListItem {
    id: string;
    date: number;
    comment: string;
    employeeId: string;
    employeeName: string;
    transferDate: number;
}

export class SalaryListItem implements ISalaryListItem {
    id: string;
    date: number;
    comment: string;
    employeeId: string;
    employeeName: string;
    transferDate: number;

    constructor();
    constructor(obj: ISalaryListItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.date = obj && obj.date || 0;
        this.comment = obj && obj.comment || "";
        this.employeeId = obj && obj.employeeId || "";
        this.employeeName = obj && obj.employeeName || "";
        this.transferDate = obj && obj.transferDate || 0;
    }
    
    static sortSalaryListItems(items: ISalaryListItem[], column: string, asc: boolean) {
        if (Base.isNullOrUndefined(items) || items.length < 2) return;
        let sortFunc: (a: ISalaryListItem, b: ISalaryListItem) => number;
        if (column === "date") sortFunc = (a: ISalaryListItem, b: ISalaryListItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.date, b.date)); };
        else if (column === "transferDate") sortFunc = (a: ISalaryListItem, b: ISalaryListItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.numberCompare(a.transferDate, b.transferDate)); };
        else if (column === "comment") sortFunc = (a: ISalaryListItem, b: ISalaryListItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.comment, b.comment)); };
        else sortFunc = (a: ISalaryListItem, b: ISalaryListItem) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.employeeName, b.employeeName)); };
        items.sort(sortFunc);
    }
}
