import { postApiCall,makeApiCall, postFormApiCall, getApiCall, postFormDataFileApiCall, postDynamicFormApiCall } from "./tokenService";
import { SalaryEdit } from "../models/salary/salaryEdit";
import { SalaryRowsCalculated } from "../models/salary/salaryRowsCalculated";
import { ApiSuccess } from "./baseService";
import { SalaryListItems } from "../models/salary/salaryListItems";
import { SalaryWorkTimePeriod } from "../models/salary/salaryWorkTimePeriod";
import { apiCall, ApiResponse } from "./apiClient";

export const getSalaryPeriod = (id: string): Promise<SalaryWorkTimePeriod> => {
    return getApiCall<SalaryWorkTimePeriod>("api/salary/salaryperiod/?id=" + (id || ""), SalaryWorkTimePeriod);
};

export const getSalaryListItems = (salaryPeriodId: string, pageSize: number, page: number, filter: string, sortColumn: string, sortOrderIsAsc: boolean, employeeIds: string[]): Promise<SalaryListItems> => {
    return postApiCall<SalaryListItems>("api/salary/list", SalaryListItems, JSON.stringify({
        salaryPeriodId: salaryPeriodId,
        pageSize: pageSize,
        page: page,
        filter: filter ?? "",
        sortColumn: sortColumn ?? "",
        sortOrder: sortOrderIsAsc ? "asc" : "desc",
        employeeIds: employeeIds,
    }));
};

export const getSalaryEdit = (employeeIds: string[], salaryPeriodId: string): Promise<SalaryEdit> => {
    return postApiCall<SalaryEdit>("api/salary/edit", SalaryEdit, JSON.stringify({
        employeeIds: employeeIds,
        salaryPeriodId: salaryPeriodId
    }));
};

export const saveSalaryEdit = (data: FormData): Promise<ApiSuccess> => {
    return postFormApiCall<ApiSuccess>("api/salary/save", data, ApiSuccess);
};

export const calculateSalary = (employeeIds: string[], startDate: number, endDate: number): Promise<SalaryRowsCalculated> => {
    return postApiCall<SalaryRowsCalculated>("api/salary/calculate", SalaryRowsCalculated, JSON.stringify({
        employeeIds: employeeIds,
        startDate: startDate,
        endDate: endDate
    }));
};

export const getSalaryFile = (data: FormData): Promise<string> => {
    return postFormDataFileApiCall("api/salary/salaryfile", data);
};

export const sendSalary = (data: FormData, selectedErp: string): Promise<ApiSuccess | string> => {
    return postDynamicFormApiCall<ApiSuccess>("api/salary/sendsalary/?targetErp=" + (selectedErp || ""), data, ApiSuccess);
};

export const targetErps = (): Promise<string[]> => {
    return makeApiCall<string[]>("api/salary/targeterps", "get", null);
};
