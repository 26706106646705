/* global JSX */
// CheckBox
// ***********************************************************************************************************************
import * as React from "react";

export interface ICheckBoxProp {
    autoFocus?: boolean;
    title: string | JSX.Element;
    classes?: string;
    name?: string;
    inline?: boolean;
    enabled: boolean;
    checked: boolean;
    onCheckboxClick?: (e: any) => void;
    onCheckboxClickBoolean?: (checked: boolean) => void;
}

export class CheckBox extends React.Component<ICheckBoxProp, {}> {
    handleCheckBoxClick = (e) => {
        e.stopPropagation();
        if (!this.props.enabled || !this.props.onCheckboxClickBoolean) return;
        this.props.onCheckboxClickBoolean(e.target.checked);
    };

    // ReSharper disable UnusedParameter
    handleCheckboxChange = (e) => {
    };
    // ReSharper restore UnusedParameter

    render() {
        const props = this.props;
        const name = props.name ? props.name : "";
        const checkboxClasses = (props.inline ? "checkbox-inline" : "checkbox") + (props.classes ? " " + props.classes : "") + (!props.enabled ? " disabled" : "");
        const clickHandler = props.onCheckboxClick ? props.onCheckboxClick : this.handleCheckBoxClick;
        return (
            <div className={checkboxClasses}>
                <label>
                    <input type="checkbox" name={name} checked={this.props.checked} onClick={clickHandler} onChange={this.handleCheckboxChange} value="" autoFocus={props.autoFocus} disabled={!props.enabled} /> {this.props.title}
                </label>
            </div>
        );
    }
}

// CheckBoxList
// ***********************************************************************************************************************
export interface ICheckBoxListItem {
    classes?: string;
    disabled?: boolean;
    id: string;
    getTitle(): string;
}

export interface ICheckBoxListProp {
    autoFocus?: boolean;
    classes?: string;
    disabled?: boolean;
    selectedIds: string[];
    values: ICheckBoxListItem[];
    onChange?: (selectedIds: string[]) => void;
    onChangeSingle?: (id: string, checked: boolean) => void;
}

export class CheckBoxList extends React.Component<ICheckBoxListProp, {}> {
    handleCheckboxClickBoolean = (id: string, checked: boolean) => {
        const props = this.props;
        const selectedIds = props.selectedIds.filter(i => i !== id);
        if (checked) {
            selectedIds.push(id);
        }
        props.onChange(selectedIds);
    };

    render() {
        const props = this.props;
        return (
            <div className={"checkboxList" + (!props.disabled ? "" : " disabled")}>
                {props.values.map((value, index) =>
                    <CheckBox
                        key={value.id}
                        autoFocus={index < 1 && props.autoFocus}
                        classes={"checkboxListItem" + (value.classes ? " " + value.classes : "") + (value.disabled ? " disabled" : "")}
                        enabled={!props.disabled}
                        checked={props.selectedIds.indexOf(value.id) > -1}
                        title={value.getTitle()}
                        onCheckboxClickBoolean={(checked: boolean) => {
                            props.onChange
                                ? this.handleCheckboxClickBoolean(value.id, checked)
                                : props.onChangeSingle(value.id, checked);
                        }}
                    />
                )}
            </div>
        );
    }
}
