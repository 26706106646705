import React, { useEffect } from "react";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import PersonIcon from "@mui/icons-material/Person";
import { Chip } from "@mui/material";
import { EmployeeListItemDto } from "../../../../models/employee/employee";
import { TransportOrderListItemDto } from "../../../../models/transport/transportOrder";
import { TransportPlanListItemDto } from "../../../../models/transport/transportPlan";
import { VehicleListItemDto } from "../../../../models/transport/vehicle";
import { dndDropHandler, Draggable } from "../../../framework/drag-n-drop";
import { RootState, useAppDispatch, useAppSelector } from "../../../../framework/customStore";
import { setSideBarOrderDetails } from "../../../../store/transport/transportVehiclesSlice";
import InfoIcon from "@mui/icons-material/Info";

export interface DriverDraggableItem {
    type: "driver";
    item: EmployeeListItemDto;
}

export interface VehicleDraggableItem {
    type: "vehicle";
    item: VehicleListItemDto;
}

export interface TransportOrderDraggableItem {
    type: "transportOrder";
    item: TransportOrderListItemDto;
}

export interface TransportPlanDraggableItem {
    type: "transportPlan";
    item: TransportPlanListItemDto;
}

export type TransportDraggable =
| DriverDraggableItem
| VehicleDraggableItem
| TransportOrderDraggableItem
| TransportPlanDraggableItem;

export const VehicleDraggable = (props: {vehicle: VehicleListItemDto}) => {
    const { vehicle } = props;
    const item: VehicleDraggableItem = {
        type: "vehicle",
        item: props.vehicle
    };
    return (
        <Draggable id={vehicle.id} data={item}>
            <Chip
                icon={<LocalShippingOutlinedIcon color="white" />}
                label={`${vehicle.brand} ${vehicle.registerNumber}` }
                sx={{ color: "white.main", backgroundColor: "purple.main", touchAction: "none" }}
            />
        </Draggable>
    );
};

export const DriverDraggable = (props: {driver: EmployeeListItemDto}) => {
    const { driver } = props;
    const item: DriverDraggableItem = {
        type: "driver",
        item: props.driver
    };
    return (
        <Draggable id={driver.id} data={item}>
            <Chip
                icon={<PersonIcon color="white" />}
                label={`${driver.firstName} ${driver.lastName}`}
                sx={{ color: "white.main", backgroundColor: "blue.main", touchAction: "none" }}
            />
        </Draggable>
    );
};

export const TransportOrderDraggable = (props: {order: TransportOrderListItemDto; onDrop?: dndDropHandler<unknown, TransportOrderDraggableItem>}) => {
    const dispatch = useAppDispatch();

    const activeDraggableId = useAppSelector(
        (state: RootState) => state.transportVehicles.activeDraggableId
    );

    const sideBarOrder = useAppSelector((state: RootState) => state.transportVehicles.sideBarOrderDetails);
    const isSelected = props.order.id === sideBarOrder?.id;

    useEffect(() => {
        let normalizedActiveId = activeDraggableId;
        if (typeof activeDraggableId === "string") {
            normalizedActiveId = activeDraggableId.replace("order-", "");
        }
        if (normalizedActiveId === props.order.id) {
            dispatch(setSideBarOrderDetails(props.order));
        }
    }, [activeDraggableId, props.order]);

    const { order, onDrop } = props;
    const item: TransportOrderDraggableItem = {
        type: "transportOrder",
        item: props.order
    };
    return (
        <Draggable id={`order-${order.id}`} data={item} onDrop={onDrop}>
            <Chip
                label={`${order.orderNumber} ${order.name}`}
                sx={{ color: "white.main", backgroundColor: isSelected ? "orange.dark" : "orange.main", touchAction: "none" }}
                deleteIcon={
                    <InfoIcon
                        fontSize="large"
                        style={{ color: "white" }}
                    />
                }
                onDelete={() => {}}
            />
        </Draggable>
    );
};

export const PlanDraggable = (props: {plan: TransportPlanListItemDto}) => {
    const { plan } = props;
    return (
        <Draggable id={plan.id}>
            <Chip
                icon={<AssignmentOutlinedIcon color="white"/>}
                label={plan.name}
                sx={{ color: "white.main", backgroundColor: "brown.main", touchAction: "none" }}
            />
        </Draggable>
    );
};
