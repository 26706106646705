// WorkOrderSearchWeekDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import Datetime from "react-datetime";
import { Translations } from "../../models/translations";
import { Dialog } from "../framework/dialog";
import { Base } from "../../framework/base";

export interface IWorkOrderSearchWeekDialogProp {
    classes?: string;
    date: number;
    onOk: (date: number) => void;
    onCancel: () => void;
}

export interface IWorkOrderSearchWeekDialogState {
    dateStr: string;
}

export class WorkOrderSearchWeekDialog extends React.Component<IWorkOrderSearchWeekDialogProp, IWorkOrderSearchWeekDialogState> {
    constructor(props) {
        super(props);
        this.state = {
            dateStr: Base.utcTimeToDateStr(new Date(props.date).addDays(1).getTime())
        };
    }

    handleDateChange = (value: moment.Moment | string) => {
        if (!(typeof value === "string")) {
            this.setState({ dateStr: Base.utcTimeToDateStr(value) });
        }
    };

    handleDateBlur = (value: moment.Moment | string) => {
        if (typeof value === "string") {
            this.setState({ dateStr: Base.utcTimeToDateStr(value.toUtcDate().getTime()) });
        } else {
            this.setState({ dateStr: Base.utcTimeToDateStr(value) });
        }
    };

    handleOkClick = () => {
        this.props.onOk(this.state.dateStr.toUtcDate().getTime());
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const dialogClasses = "selectDate px300" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <Dialog
                    classes={dialogClasses}
                    title={Translations.SelectDate}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group required">
                                    <Datetime
                                        locale={appConfig.culture}
                                        className={"roWhite"}
                                        value={this.state.dateStr}
                                        dateFormat={"D.M.YYYY"}
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        inputProps={{ maxLength: 10, readOnly: false, disabled: false }}
                                        input={false}
                                        open={true}
                                        onChange={this.handleDateChange}
                                        onClose={this.handleDateBlur}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.OK, classes: "btn-primary", enabled: true, onClick: this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}
