// WorkOrderEditRouteEditorRoutePointEdit - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as locationService from "../../services/locationService";
import * as workOrderService from "../../services/workOrderService";
import { Tab, Tabs } from "@mui/material";
import { TabPanel } from "../framework/tabPanel";
import { Base } from "../../framework/base";
import { IIdTitle } from "../../models/common/idTitle";
import { IRoutePointItem, RoutePointItem, } from "../../models/routePoint/routePointItem";
import { IWorkOrderEditContact } from "../../models/work/workOrderEditContact";
import { Translations } from "../../models/translations";
import { ILocationPoint, LocationPoint } from "../../models/common/locationPoint";
import { SaveData } from "../../framework/saveData";
import { Autocomplete } from "../framework/autocomplete";
import { RoutePointAutocompleteItem, RoutePointAutocompleteItems, IRoutePointAutocompleteItem } from "../../models/routePoint/routePointAutocompleteItems";
import { LocationMap } from "../framework/locationMap";
import { Button } from "../framework/button";
import { ReverseGeocodeResult } from "../../framework/reverseGeocodeResult";
import { ToolButton } from "../framework/toolButton";
import { GeocodeResult } from "../../framework/geocodeResult";
import { IRouteResult } from "../../framework/routeResult";
import { AppUtils } from "../../models/common/appUtils";
import { ConfirmationDialogResult } from "../../models/common/enums";
import { IRouteTimelineRoutePoint, WorkOrderEditRouteEditorRoutePointPrefixTimeLine } from "./workOrderEditRouteEditorRoutePointTimeLine";
import { IWorkShiftTimeSlotType } from "../../models/workShiftTimeSlotType/workShiftTimeSlotType";
import { WorkOrderEditRouteEditorWorkShiftTimeSlotList } from "./workOrderEditRouteEditorWorkShiftTimeSlotList";
import { IEmployeeItem } from "../../models/employee/employeeItem";
import { IVehicleItem } from "../../models/vehicle/vehicleItem";
import { IRoutePointWorkShiftTimeSlotItem } from "../../models/routePoint/routePointWorkShiftTimeSlotItem";

export interface IRoutePointRoutingData {
    previousRoutePoint: IRoutePointItem;
    routePoint: IRoutePointItem;
}

export interface IRoutePointRoutingResultData {
    requestData: IRoutePointRoutingData;
    resultData: IRouteResult;
}

/* eslint-disable no-unused-vars */
const enum EditorTab {
    General = 0,
    Map = 1,
    WorkShiftTimeSlots = 2
}

const enum EditorViewMode {
    Normal = "Normal",
    Narrow = "Narrow",
    Wide = "Wide"
}
/* eslint-enable no-unused-vars */

// WorkOrderEditRouteEditorRoutePointEdit
interface IWorkOrderEditRouteEditorRoutePointEditProp {
    workOrderId: string;
    customerId: string;
    containerWidth: number;
    isReadOnly: boolean;
    workOrderIsProject: boolean;
    plannedRoute: boolean;
    timelineRoutePoint: IRouteTimelineRoutePoint;
    transferWorkShiftTimeSlotTypeIds: string[];
    workShiftTimeSlotTypes: IWorkShiftTimeSlotType[];
    ownerRoutePoints: IRoutePointAutocompleteItem[];
    mapLinkTemplate: string;
    routePointTypes: IIdTitle[];
    contacts: IWorkOrderEditContact[];
    employees: IEmployeeItem[];
    vehicles: IVehicleItem[];
    hasPreviousRoutePoint: boolean;
    hasNextRoutePoint: boolean;
    onOwnerRoutePointsAdd: (routePoint: IRoutePointAutocompleteItem) => void;
    onRemoveRoutePoint: (id: string) => void;
    onSaveRoutePoint: (routePoint: IRoutePointItem) => Promise<boolean>;
    onWorkShiftTimeSlotsModified: (workShiftTimeSlots: IRoutePointWorkShiftTimeSlotItem[], removedWorkShiftTimeSlotIds: string[]) => void;
    onAddContact: (customerId: string, name: string, callback: (contactId: string) => void) => void;
    onAttachContact: (customerId: string, name: string, callback: (contactId: string) => void) => void;
    onAddContactFromRoutePointCopy: (contactId: string) => void;
    onEditContact: (customerId: string, siteId: string) => void;
    onClose: () => void;
}

interface IWorkOrderEditRouteEditorRoutePointEditState {
    newMode: boolean;
    selectedTab: EditorTab;
    setLocationMode: boolean;
    routePointTypeId: string;
    routePointTypeCode: number;
    contactId: string;
    number: number;
    name: string;
    name2: string;
    street: string;
    postalCode: string;
    city: string;
    countryCode: string;
    mapLink: string;
    longitudeStr: string;
    latitudeStr: string;
    locationPoint: ILocationPoint;
    locationPointSetManually: boolean;
    selectedOwnerRoutePointId: string;
}

export class WorkOrderEditRouteEditorRoutePointEdit extends React.Component<IWorkOrderEditRouteEditorRoutePointEditProp, IWorkOrderEditRouteEditorRoutePointEditState> {
    private orgStateHash: string = "";
    private autoCompleteEventRunningInstances = 0;

    getEditorViewMode = (): EditorViewMode => {
        const props = this.props;
        if (!props.containerWidth || props.containerWidth < 0) return EditorViewMode.Normal;
        if (props.containerWidth <= 300) return EditorViewMode.Narrow;
        if (props.containerWidth >= 500) return EditorViewMode.Wide;
        return EditorViewMode.Normal;
    };

    getHashDataFromState = (state: IWorkOrderEditRouteEditorRoutePointEditState): string => {
        const data = new SaveData();
        data.append("routePointTypeId", state.routePointTypeId ? state.routePointTypeId : String(null));
        data.append("routePointTypeCode", state.routePointTypeCode ? String(state.routePointTypeCode) : String(null));
        data.append("contactId", state.contactId ? state.contactId : String(null));
        data.append("number", state.number.toString(10));
        data.append("name", state.name);
        data.append("name2", state.name2);
        data.append("street", state.street);
        data.append("postalCode", state.postalCode);
        data.append("city", state.city);
        data.append("countryCode", state.countryCode);
        data.append("mapLink", state.mapLink);
        data.append("longitudeStr", state.longitudeStr);
        data.append("latitudeStr", state.latitudeStr);
        return data.hash;
    };

    private getMatchingAutoCompleteItem = (name: string, name2: string, street: string): RoutePointAutocompleteItem => {
        if (!street) return null;
        const filteredItems = this.props.ownerRoutePoints.filter(i => i.street.equalIgnoreCase(street));
        if (!filteredItems.length) return null;
        let result: RoutePointAutocompleteItem = null;
        if (name && name2) {
            result = filteredItems.find(i => i.name.equalIgnoreCase(name) && i.name2.equalIgnoreCase(name2));
        }
        if (!result && name) {
            result = filteredItems.find(i => i.name.equalIgnoreCase(name));
        }
        if (!result) {
            result = filteredItems[0];
        }
        return result;
    };

    private getStateFromRoutePoint = (routePoint: IRoutePointItem, newMode: boolean = null): IWorkOrderEditRouteEditorRoutePointEditState => {
        const result = {
            newMode: Base.isNullOrUndefined(newMode) ? this.state.newMode : newMode,
            selectedTab: Base.isNullOrUndefined(newMode) ? this.state.selectedTab : EditorTab.General,
            routePointTypeId: routePoint.routePointTypeId,
            routePointTypeCode: routePoint.routePointTypeCode,
            contactId: routePoint.contactId,
            number: routePoint.number,
            name: routePoint.name,
            name2: routePoint.name2,
            street: routePoint.street,
            postalCode: routePoint.postalCode,
            city: routePoint.city,
            countryCode: routePoint.countryCode,
            mapLink: routePoint.mapLink,
            longitudeStr: routePoint.longitude ? routePoint.longitude.toLocaleFixed(6) : "",
            latitudeStr: routePoint.latitude ? routePoint.latitude.toLocaleFixed(6) : "",
            locationPoint: LocationPoint.createLocationPoint(Base.getGuid(),
                routePoint.latitude,
                routePoint.longitude,
                0),
            setLocationMode: false,
            locationPointSetManually: false,
            selectedOwnerRoutePointId: this.getMatchingAutoCompleteItem(routePoint.name, routePoint.name2, routePoint.street)?.id ?? "",
        };
        return result;
    };

    private getStateFromProps(props: IWorkOrderEditRouteEditorRoutePointEditProp, newMode: boolean = null): IWorkOrderEditRouteEditorRoutePointEditState {
        const routePoint = new RoutePointItem(props.timelineRoutePoint.routePoint);
        return this.getStateFromRoutePoint(routePoint, newMode);
    }

    hasUnsavedData = (): boolean => {
        return this.getHashDataFromState(this.state) !== this.orgStateHash;
    };

    constructor(props: IWorkOrderEditRouteEditorRoutePointEditProp) {
        super(props);
        this.state = this.getStateFromProps(props, props.timelineRoutePoint.routePoint.isNew());
        this.orgStateHash = this.getHashDataFromState(this.state);
    }

    componentDidMount(): void {
        window.addEventListener("click", this.editorClickEvent, { capture: true, passive: false });
    }

    componentWillUnmount(): void {
        window.removeEventListener("click", this.editorClickEvent);
    }

    componentDidUpdate(prevProps: IWorkOrderEditRouteEditorRoutePointEditProp, prevState: IWorkOrderEditRouteEditorRoutePointEditState): void {
        const props = this.props;
        const state = this.state;
        if (prevProps.timelineRoutePoint.getHash() === props.timelineRoutePoint.getHash()) {
            if (state.street && RoutePointAutocompleteItems.getHash(prevProps.ownerRoutePoints) !== RoutePointAutocompleteItems.getHash(props.ownerRoutePoints)) {
                const selectedOwnerRoutePoint = this.getMatchingAutoCompleteItem(state.name, state.name2, state.street);
                this.setState({ selectedOwnerRoutePointId: selectedOwnerRoutePoint?.id ?? "" });
            }
            return;
        }
        this.setState(this.getStateFromProps(props));
    }

    // #region EditMode
    editorClickEvent = (ev: MouseEvent) => {
        const element = ev.target as HTMLElement;
        //Ignore clicks in confirmation dialog
        if (element.closest(".jj-dialog.confirmation")) {
            return;
        }
        //Remove listener if editor does not exist
        const lineSelector = "[editor-routepoint-id='" + this.props.timelineRoutePoint.id + "']";
        const lineElement = document.querySelector(lineSelector);
        if (!lineElement) {
            window.removeEventListener("click", this.editorClickEvent);
            return;
        }
        //Close if clicked outside editor
        const editor = element.closest(lineSelector);
        if (!editor) {
            if (this.hasUnsavedData()) {
                ev.preventDefault();
                ev.stopPropagation();
            }
            this.handleCancel();
        }
    };

    handleExpandCollapse = () => {
        const state = this.state;
        this.setState({
            newMode: !state.newMode
        });
    };
    // #endregion EditMode

    // #region RoutePoint
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "routePointTypeId") {
            const routepointtype = this.props.routePointTypes.find(r => r.id === value);
            this.setState({ routePointTypeId: value, routePointTypeCode: routepointtype.typeField });
        } else if (name === "Nm") {
            this.setState({ name: value });
        } else if (name === "Nm2") {
            this.setState({ name2: value });
        } else if (name === "Ads") {
            this.setState({ street: value });
        } else if (name === "pC") {
            this.setState({ postalCode: value });
        } else if (name === "cty") {
            this.setState({ city: value });
        } else if (name === "cntrCd") {
            this.setState({ countryCode: value });
        } else if (name === "number") {
            this.setState({ number: value.toInteger() });
        }
    };
    // #endregion RoutePoint

    // #region New RoutePoint
    setNonLocationRoutePointDataFromState = (routePoint: IRoutePointItem) => {
        const state = this.state;
        routePoint.name = state.name;
        routePoint.name2 = state.name2;
        routePoint.routePointTypeId = state.routePointTypeId;
        routePoint.routePointTypeCode = state.routePointTypeCode;
    };

    setStateFromAutoCompleteEventRoutePoint = (routePoint: IRoutePointItem) => {
        this.autoCompleteEventRunningInstances++;
        this.setState(this.getStateFromRoutePoint(routePoint), () => {
            this.autoCompleteEventRunningInstances--;
        });
    };

    handleAddressSearchFromAutoComplete = async(value: string, selectedIds: string[]) => {
        if (selectedIds.length > 0) return; //Do not search if existing route point is selected from autocomplete!
        this.autoCompleteEventRunningInstances++;
        try {
            const routePoint = new RoutePointItem(this.props.timelineRoutePoint.routePoint);
            this.setNonLocationRoutePointDataFromState(routePoint);
            await this.setRoutePointDataByGeocoding(value, routePoint);
            const selectedOwnerRoutePoint = this.getMatchingAutoCompleteItem(routePoint.name, routePoint.name2, routePoint.street);
            this.setStateFromAutoCompleteEventRoutePoint(routePoint);
            if (!selectedOwnerRoutePoint) {
                const newOwnerRoutePoint = new RoutePointItem(routePoint);
                newOwnerRoutePoint.id = Base.getGuid();
                this.props.onOwnerRoutePointsAdd(newOwnerRoutePoint);
            }
        } finally {
            this.autoCompleteEventRunningInstances--;
        }
    };

    handleSelectRoutePointFromAutoComplete = async(ids: string[], isClearing?: boolean) => {
        this.autoCompleteEventRunningInstances++;
        try {
            const state = this.state;
            let routePoint: IRoutePointItem;
            if (isClearing) {
                routePoint = new RoutePointItem();
                this.setNonLocationRoutePointDataFromState(routePoint);
                this.setStateFromAutoCompleteEventRoutePoint(routePoint);
            }
            if (ids.length === 0) return;
            const routePointId = ids[0];
            const selectedOwnerRoutePoint = this.props.ownerRoutePoints.find(i => i.id === routePointId);
            if (!selectedOwnerRoutePoint) return;
            if (selectedOwnerRoutePoint instanceof RoutePointItem) {
                routePoint = selectedOwnerRoutePoint;
            } else {
                routePoint = await workOrderService.getRoutePointCopy(routePointId);
            }
            if (!routePoint) return;
            routePoint.number = state.number;
            routePoint.routePointTypeId = state.routePointTypeId;
            routePoint.routePointTypeCode = state.routePointTypeCode;
            this.setStateFromAutoCompleteEventRoutePoint(routePoint);
        } finally {
            this.autoCompleteEventRunningInstances--;
        }
    };

    handleEnterPressRoutePointFromAutoComplete = async(value: string, selectedIds: string[]) => {
        await this.handleSelectRoutePointFromAutoComplete(selectedIds);
    };
    // #endregion New RoutePoint

    // #region Location and routing
    handleChangeContactAutoComplete = (ids: string[]) => {
        const id = !ids || ids.length < 1 ? "" : ids[0];
        this.setState({
            contactId: id
        });
    };

    handleSetContactId = (contactId: string) => {
        this.setState({
            contactId: contactId
        });
    };

    handleEditContact = (contactId: string) => {
        const props = this.props;
        props.onEditContact(props.customerId, contactId);
    };

    handleAddContact = (name: string) => {
        const props = this.props;
        props.onAddContact(props.customerId, name, this.handleSetContactId);
    };

    handleAttachContact = (name: string) => {
        const props = this.props;
        props.onAttachContact(props.customerId, name, this.handleSetContactId);
    };
    // #endregion Contact

    // #region Location
    geocodeFreeText = async(text: string): Promise<GeocodeResult> => {
        const geocodeResult = await locationService.geocodeFreeText(text);
        if (!geocodeResult?.longitude) return null;
        return geocodeResult;
    };

    setRoutePointDataByGeocoding = async(text: string, routePoint: IRoutePointItem) => {
        if (!text) return;
        const props = this.props;
        routePoint.name = Base.capitalizeFirstChar(text);
        const geocodeResult = await this.geocodeFreeText(text);
        if (!geocodeResult) {
            routePoint.latitude = null;
            routePoint.longitude = null;
            return;
        }
        if (!routePoint.name) {
            routePoint.name = geocodeResult.street;
        }
        routePoint.latitude = geocodeResult.latitude;
        routePoint.longitude = geocodeResult.longitude;
        routePoint.street = geocodeResult.street;
        routePoint.postalCode = geocodeResult.postalCode;
        routePoint.city = geocodeResult.city;
        routePoint.countryCode = geocodeResult.countryCode;
        routePoint.mapLink = Base.generateMapLink(props.mapLinkTemplate, geocodeResult.latitude.toFixed(6), geocodeResult.longitude.toFixed(6), null);
    };

    reverseGeocode = (latitude: number, longitude: number): Promise<ReverseGeocodeResult> => {
        if (!latitude || !longitude) {
            return new Promise<ReverseGeocodeResult>((resolve) => { resolve(null); });
        }
        return locationService.reverseGeocode(latitude, longitude)
            .then(result => {
                return result;
            })
            .catch(error => {
                console.log(error);
                return new Promise<ReverseGeocodeResult>((resolve) => { resolve(null); });
            });
    };
    // #endregion Location and routing

    // #region Set location from map
    handleSelectLocation = () => {
        this.setState({
            setLocationMode: !this.state.setLocationMode
        });
    };

    handleSetClickedLocation = (latitude: number, longitude: number) => {
        const obj = this;
        const props = this.props;
        if (!latitude || !longitude) return;
        const locationPoint = new LocationPoint(this.state.locationPoint);
        locationPoint.latitude = latitude;
        locationPoint.longitude = longitude;
        this.setState({
            setLocationMode: false,
            locationPoint: locationPoint,
            locationPointSetManually: true,
            latitudeStr: latitude.toFixed(6),
            longitudeStr: longitude.toFixed(6),
            mapLink: Base.generateMapLink(props.mapLinkTemplate, latitude.toFixed(6), longitude.toFixed(6), null)
        });
        this.reverseGeocode(latitude, longitude)
            .then(result => {
                if (result) {
                    obj.setState({
                        street: result.street,
                        postalCode: result.postalCode,
                        city: result.city,
                        countryCode: result.countryCode,
                    });
                }
            });
    };
    // #endregion Set location from map

    // #region Saving
    getRoutePointFromState = (): IRoutePointItem => {
        const props = this.props;
        const state = this.state;
        const routePoint = new RoutePointItem(props.timelineRoutePoint.routePoint);
        routePoint.routePointTypeId = state.routePointTypeId;
        routePoint.routePointTypeCode = state.routePointTypeCode;
        routePoint.contactId = state.contactId;
        routePoint.name = state.name;
        routePoint.name2 = state.name2;
        routePoint.street = state.street;
        routePoint.postalCode = state.postalCode;
        routePoint.city = state.city;
        routePoint.countryCode = state.countryCode;
        routePoint.mapLink = state.mapLink;
        routePoint.latitude = state.locationPoint.latitude;
        routePoint.longitude = state.locationPoint.longitude;
        return routePoint;
    };

    private checkErrors = (): Promise<boolean> => {
        const state = this.state;
        return new Promise<boolean>((resolve) => {
            if (!state.name) {
                AppUtils.showErrorMessage(Translations.NameMustBeDefined);
                return resolve(false);
            }
            //NO WORK SHIFT TIME SLOT EDITING => NO VALIDATION
            return resolve(true);
        });
    };

    excuteAfterAutoCompleteEventsFinished = (callback: () => void) => {
        if (this.autoCompleteEventRunningInstances < 1) return;
        window.setTimeout(() => {
            if (this.autoCompleteEventRunningInstances < 1) {
                callback();
            } else {
                this.excuteAfterAutoCompleteEventsFinished(callback);
            }
        }, 50);
    };

    waitUntilAutoCompleteEventsFinished = async() => {
        while (this.autoCompleteEventRunningInstances > 0) {
            await new Promise(resolve => setTimeout(resolve, 50));
        }
    };

    save = async(): Promise<boolean> => {
        const props = this.props;
        if (!await AppUtils.validate(this.checkErrors)) return;
        const routePoint = this.getRoutePointFromState();
        if (!routePoint.latitude && !routePoint.longitude &&
            (routePoint.street || routePoint.postalCode || routePoint.city)) {
            routePoint.mapLink = Base.generateMapLink(props.mapLinkTemplate, routePoint.street, routePoint.postalCode, routePoint.city);
        }
        const result = await props.onSaveRoutePoint(routePoint);
        if (result && routePoint.contactId && !props.contacts.find(i => i.id)) {
            props.onAddContactFromRoutePointCopy(routePoint.contactId);
        }
        return result;
    };

    handleSave = async(close: boolean = true) => {
        await this.waitUntilAutoCompleteEventsFinished();
        const saveResult = await this.save();
        if (!saveResult) return;
        if (!close) {
            this.orgStateHash = this.getHashDataFromState(this.state);
            return;
        }
        this.props.onClose();
    };
    // #endregion Saving

    // #region DragAndDrop
    getStyle = (isDragging: boolean, draggableStyle: any) => {
        return {
            // some basic styles to make the items look a bit nicer
            userSelect: "none",
            // styles we need to apply on draggables
            ...draggableStyle
        };
    };
    // #endregion DragAndDrop

    handleTabChange = async(event: React.SyntheticEvent, selectedTab: number) => {
        if (this.hasUnsavedData() && selectedTab === EditorTab.WorkShiftTimeSlots) {
            await this.handleSave(false);
        }
        this.setState({ selectedTab });
    };

    handleRemove = () => {
        const props = this.props;
        props.onRemoveRoutePoint(props.timelineRoutePoint.routePoint.id);
    };

    handleCancel = async() => {
        const cancelResult = await AppUtils.cancel(this.getHashDataFromState(this.state), this.orgStateHash);
        if (cancelResult === ConfirmationDialogResult.Cancel) return;
        if (cancelResult === ConfirmationDialogResult.Yes) {
            this.handleSave();
        } else {
            if (!this.props.timelineRoutePoint.routePoint.isNew()) {
                this.props.onClose();
            } else {
                this.handleRemove();
            }
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        const contact = Base.getItemById(props.contacts, state.contactId);
        const editorViewMode = this.getEditorViewMode();
        const isNew = props.timelineRoutePoint.routePoint.isNew();
        const isReadOnly = props.isReadOnly || props.timelineRoutePoint.routePoint.workShiftTimeSlots.length > 0;
        return (
            <div className="editorRoutePoint" editor-routepoint-id={props.timelineRoutePoint.id}>
                <WorkOrderEditRouteEditorRoutePointPrefixTimeLine
                    routePointTransferWorkShiftTimeSlotTypeIds={props.transferWorkShiftTimeSlotTypeIds}
                    timelineRoutePoint={props.timelineRoutePoint}
                    hasPreviousRoutePoint={props.hasPreviousRoutePoint}
                    plannedRoute={props.plannedRoute}
                />
                <div className="valueGroup workOrderRouteEditorRoutePoint">
                    {state.newMode &&
                        <div className="row">
                            <div className="col-12">
                                <div className="firstRow">
                                    <div className="valueContainer">
                                        <div className="row">
                                            <div className={editorViewMode === EditorViewMode.Wide ? "col-4" : "col-12"}>
                                                <div className="form-group">
                                                    <label className="control-label">{Translations.AType}</label>
                                                    <select className="custom-select" name="routePointTypeId" title={Translations.AType} value={state.routePointTypeId} onChange={this.handleChange} autoFocus={true} disabled={isReadOnly}>
                                                        {props.routePointTypes.map((routePointType) =>
                                                            <option key={routePointType.id} value={routePointType.id}>{routePointType.title}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className={editorViewMode === EditorViewMode.Wide ? "col-8" : "col-12"}>
                                                <div className="form-group required">
                                                    <label className="control-label">{Translations.StreetAddress}</label>
                                                    <Autocomplete
                                                        autoFocus={!state.street}
                                                        classes={"routePointAutocomplete"}
                                                        disabled={isReadOnly}
                                                        doNotClearOnOpen={true}
                                                        placeholder={Translations.StreetAddress}
                                                        selectedItems={props.ownerRoutePoints.filter(i => i.id === state.selectedOwnerRoutePointId)}
                                                        items={props.ownerRoutePoints}
                                                        onChange={this.handleSelectRoutePointFromAutoComplete}
                                                        onEnterPress={this.handleEnterPressRoutePointFromAutoComplete}
                                                        onBlur={this.handleAddressSearchFromAutoComplete}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="expandCollapseContainer">
                                        <ToolButton
                                            title={state.newMode ? Translations.Expand : Translations.Collapse}
                                            enabled={true}
                                            classes={state.newMode ? "doExpand" : "doCollapse"}
                                            onClick={this.handleExpandCollapse}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {!state.newMode &&
                        <div>
                            <Tabs
                                variant="standard"
                                value={state.selectedTab}
                                onChange={this.handleTabChange}
                            >
                                <Tab label={Translations.General} value={EditorTab.General}/>
                                <Tab label={Translations.Map} value={EditorTab.Map}/>
                                {!props.workOrderIsProject && props.workShiftTimeSlotTypes.length > 0 &&
                                    <Tab label={Translations.RoutePointBookings} value={EditorTab.WorkShiftTimeSlots}/>
                                }
                            </Tabs>
                            <ToolButton
                                title={Translations.Close}
                                enabled={true}
                                classes="close"
                                onClick={this.handleCancel}
                            />
                            <TabPanel index={0} selectedTab={state.selectedTab} noPadding={true}>
                                <div className="row">
                                    <div className={editorViewMode === EditorViewMode.Narrow ? "col-12" : "col-6"}>
                                        <div className="form-group">
                                            <label className="control-label">{Translations.AType}</label>
                                            <select className="custom-select" name="routePointTypeId" title={Translations.AType} value={state.routePointTypeId} onChange={this.handleChange} autoFocus={true} disabled={isReadOnly}>
                                                {props.routePointTypes.map((routePointType) =>
                                                    <option key={routePointType.id} value={routePointType.id}>{routePointType.title}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={editorViewMode === EditorViewMode.Narrow ? "col-12" : "col-8"}>
                                        <div className="form-group required">
                                            <label className="control-label">{Translations.Name}</label>
                                            <input type="text" className="form-control" name="Nm" title={Translations.Name} value={state.name} onChange={this.handleChange} maxLength={50} readOnly={isReadOnly} disabled={isReadOnly}/>
                                        </div>
                                    </div>
                                    <div className={editorViewMode === EditorViewMode.Narrow ? "col-12" : "col-4"}>
                                        <div className="form-group">
                                            <label className="control-label">{Translations.Specifier}</label>
                                            <input type="text" className="form-control" name="Nm2" title={Translations.Specifier} value={state.name2} onChange={this.handleChange} maxLength={50} readOnly={isReadOnly} disabled={isReadOnly}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="control-label">{Translations.StreetAddress}</label>
                                            <input type="text" className="form-control" name="Ads" title={Translations.StreetAddress} value={state.street} onChange={this.handleChange} maxLength={50} readOnly={isReadOnly} disabled={isReadOnly}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={editorViewMode === EditorViewMode.Narrow ? "col-12" : "col-4"}>
                                        <div className="form-group">
                                            <label className="control-label">{Translations.PostalCode}</label>
                                            <input type="text" className="form-control" name="pC" title={Translations.PostalCode} value={state.postalCode} onChange={this.handleChange} maxLength={10} readOnly={isReadOnly} disabled={isReadOnly}/>
                                        </div>
                                    </div>
                                    <div className={editorViewMode === EditorViewMode.Narrow ? "col-12" : "col-8"}>
                                        <div className="form-group">
                                            <label className="control-label">{Translations.CityAddress}</label>
                                            <input type="text" className="form-control" name="cty" title={Translations.CityAddress} value={state.city} onChange={this.handleChange} maxLength={50} readOnly={isReadOnly} disabled={isReadOnly}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={editorViewMode === EditorViewMode.Narrow ? "col-12" : "col-3"}>
                                        <div className="form-group">
                                            <label className="control-label">{Translations.CountryCode}</label>
                                            <input type="text" className="form-control" name="cntrCd" title={Translations.CountryCode} value={state.countryCode} onChange={this.handleChange} maxLength={50} readOnly={isReadOnly} disabled={isReadOnly}/>
                                        </div>
                                    </div>
                                    {props.contacts.length > 0 &&
                                        <div className={editorViewMode === EditorViewMode.Narrow ? "col-12" : "col-9"}>
                                            <div className="form-group">
                                                <label className="control-label">{Translations.Contact}</label>
                                                <Autocomplete
                                                    disabled={isReadOnly}
                                                    dropup={true}
                                                    placeholder={Translations.Contact}
                                                    selectedItems={props.contacts.filter(i => i.id === state.contactId)}
                                                    items={props.contacts}
                                                    onAddClick={props.onAddContact ? this.handleAddContact : null}
                                                    onAttachClick={props.onAttachContact ? this.handleAttachContact : null}
                                                    onEditClick={props.onEditContact ? this.handleEditContact : null}
                                                    onChange={this.handleChangeContactAutoComplete}
                                                />
                                                {contact &&
                                                    <div className="contactDetails">
                                                        {!!contact.phone &&
                                                            <a href={"tel:" + contact.phone.replace(/ /gi, "-")}>{contact.phone}</a>
                                                        }
                                                        {!!contact.phone && !!contact.email &&
                                                            <span>{", "}</span>
                                                        }
                                                        {contact.email &&
                                                            <span>{contact.email}</span>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </TabPanel>
                            <TabPanel index={1} selectedTab={state.selectedTab} noPadding={true}>
                                {state.selectedTab === EditorTab.Map &&
                                    <LocationMap
                                        classes="plannedRoutePoint locationMap"
                                        points={[state.locationPoint]}
                                        selectedIds={[state.locationPoint.id]}
                                        setLocationMode={state.setLocationMode}
                                        onSetClickedLocation={this.handleSetClickedLocation}
                                    />
                                }
                                {!isReadOnly &&
                                    <Button
                                        classes="rnd btn-default selectLocation"
                                        title={state.setLocationMode ? Translations.CancelSetLocationFromMap : Translations.SetLocationFromMap}
                                        enabled={true}
                                        onClick={this.handleSelectLocation}
                                    />
                                }
                            </TabPanel>
                            {props.workShiftTimeSlotTypes.length > 0 &&
                                <TabPanel index={2} selectedTab={state.selectedTab} noPadding={true}>
                                    <div className="workShiftTimeSlotsContainer">
                                        <WorkOrderEditRouteEditorWorkShiftTimeSlotList
                                            isReadOnly={props.isReadOnly}
                                            workOrderId={props.workOrderId}
                                            routePointId={props.timelineRoutePoint.routePoint.id}
                                            workShiftTimeSlotTypes={props.workShiftTimeSlotTypes}
                                            employeeId={null}
                                            employees={props.employees}
                                            vehicles={props.vehicles}
                                            workShiftTimeSlots={props.timelineRoutePoint.routePoint.workShiftTimeSlots}
                                            onWorkShiftTimeSlotsModified={props.onWorkShiftTimeSlotsModified}
                                        />
                                    </div>
                                </TabPanel>
                            }
                        </div>
                    }
                    {!isReadOnly &&
                        <div className={"buttonRow" + (editorViewMode === EditorViewMode.Narrow ? " narrowView" : "")}>
                            <Button
                                title={Translations.Save}
                                classes="btn-primary rnd"
                                enabled={true}
                                onClick={() => this.handleSave()}
                            />
                            {!isNew &&
                                <Button
                                    title={Translations.Remove}
                                    classes="btn-danger rnd red"
                                    enabled={true}
                                    onClick={this.handleRemove}
                                />
                            }
                            {isNew &&
                                <Button
                                    title={Translations.Cancel}
                                    classes="btn-default rnd"
                                    enabled={true}
                                    onClick={this.handleRemove}
                                />
                            }
                        </div>
                    }
                </div>
            </div>);
    }
}
