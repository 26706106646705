import * as React from "react";
import { Translations } from "../../models/translations";
import { RadioButton } from "./radio";
// ListModeSelector
// ***********************************************************************************************************************

/* eslint-disable no-unused-vars */
export const enum ListMode {
    List = "0",
    Card = "1",
}
/* eslint-enable no-unused-vars */

export interface IListModeSelectorProp {
    classes?: string;
    disabled?: boolean;
    listMode: ListMode;
    onChangeListMode: (listMode: ListMode) => void;
}

export class ListModeSelector extends React.Component<IListModeSelectorProp, {}> {
    listModes = [
        { title: Translations.Card, mode: ListMode.Card, classes: " grid" },
        { title: Translations.List, mode: ListMode.List, classes: " list" }
    ];

    render() {
        const props = this.props;
        return (
            <div className={"btn-group btn-group-toggle states" + (props.classes ? " " + props.classes : "")}>
                {this.listModes.map((listMode) =>
                    <RadioButton
                        key={listMode.mode}
                        classes={"btn-secondary state" + listMode.mode + listMode.classes}
                        enabled={true}
                        checked={props.listMode === listMode.mode}
                        onRadioClick={() => { props.onChangeListMode(listMode.mode); }}
                    />
                )}
            </div>
        );
    }
}