import { IVehicleEditItem, VehicleEditItem } from "./vehicleEditItem";
import { IIdTitle, IdTitle } from "../common/idTitle";
import { Base } from "../../framework/base";
import { IIdTitleDescription, IdTitleDescription } from "../common/idTitleDescription";

export interface IVehicleEdit {
    vehicle: IVehicleEditItem;
    vehicleGroups: IIdTitle[];
    costCenters: IIdTitleDescription[];
}

export class VehicleEdit implements IVehicleEdit {
    vehicle: VehicleEditItem;
    vehicleGroups: IdTitle[];
    costCenters: IdTitleDescription[];

    constructor();
    constructor(obj: IVehicleEdit);
    constructor(obj?: any) {
        this.vehicle = null;
        if (obj && obj.vehicle) {
            this.vehicle = new VehicleEditItem(obj.vehicle);
        }
        this.vehicleGroups = Base.getTypedArray<IdTitle>(obj ? obj.vehicleGroups : null, IdTitle);
        this.costCenters = Base.getTypedArray<IdTitleDescription>(obj ? obj.costCenters : null, IdTitleDescription);
    }
}