import { LocationPointType } from "./enums";

export interface ILocationPoint {
    id: string;
    latitude: number;
    longitude: number;
    state: number;
    locationPointType: LocationPointType;
    locationPointCategory: number;
    locationName: string;

    hasLocation(): boolean;
    getLocationHash(): string;
    getMapTooltip(): string;
    getState(): number;
    getLocationCategory(): number;
}

export class LocationPoint implements ILocationPoint {
    id: string;
    latitude: number;
    longitude: number;
    state: number;
    locationPointType: LocationPointType;
    locationPointCategory: number;
    locationName: string;

    constructor();
    constructor(obj: ILocationPoint);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.latitude = obj && obj.latitude || 0;
        this.longitude = obj && obj.longitude || 0;
        this.state = obj && obj.state || null;
        this.locationPointType = obj && obj.locationPointType || LocationPointType.General;
        this.locationPointCategory = obj && obj.locationPointCategory || 0;
        this.locationName = obj && obj.locationName || "";
    }

    hasLocation(): boolean {
        return !!this.latitude && !!this.longitude;
    }

    getLocationHash(): string {
        return (this.latitude ? this.latitude.toFixed(6) : "") + "," + (this.longitude ? this.longitude.toFixed(6) : "");
    }

    getMapTooltip(): string {
        return this.locationName;
    }

    getState(): number {
        return this.state;
    }

    getLocationCategory(): number {
        return 0;
    }

    static createLocationPoint(id: string, latitude: number, longitude: number, state: number, locationName: string = ""): LocationPoint {
        const result = new LocationPoint();
        result.id = id;
        result.latitude = latitude;
        result.longitude = longitude;
        result.state = state;
        result.locationName = locationName;
        return result;
    }
}
