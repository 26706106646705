// WorkOrderDialogWorkerOrderTaskList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import * as workOrderService from "../../services/workOrderService";
import { Dialog } from "../framework/dialog";
import { ToolButton } from "../framework/toolButton";
import { Base } from "../../framework/base";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { IWorkOrderEditItemWorkOrderTask } from "../../models/work/workOrderEditItemWorkOrderTask";
import { infiniteListPageSize } from "../../models/common/consts";
import { IWorkOrderListItem } from "../../models/work/workOrderListItem";
import { handleApiError } from "../../models/store/storeEffects";
import { IIdTitle } from "../../models/common/idTitle";
import { ListSearchFilter } from "../framework/listSearchFilter";
import { ToolTitle } from "../framework/toolTitle";
import { ListHeaderColumn } from "../framework/listHeaderColumn";
import { WorkOrderCategory } from "../../models/common/enums";
import { AppUtils } from "../../models/common/appUtils";
import { SaveData } from "../../framework/saveData";
import { WorkOrderDialogRecurrenceDialog } from "./workOrderDialogRecurrenceDialog";
import { ButtonDropdown, IButtonDropdownAction } from "../framework/dropdown";
import { WorkOrderSetValuesDialog } from "./workOrderSetValuesDialog";
import { INumberTitle } from "../../models/common/numberTitle";
import { IVehicleItem } from "../../models/vehicle/vehicleItem";
import { IEmployeeItem } from "../../models/employee/employeeItem";
import { DragDropContext, Draggable, DragUpdate, Droppable, DropResult, ResponderProvided } from "react-beautiful-dnd";

// WorkOrderDialogAttachWorkToProjectDialogListLineHeader
export interface IWorkOrderDialogAttachWorkToProjectDialogListLineHeaderProp {
    sortColumn: string;
    sortOrderIsAsc: boolean;
    onColumnClick: (column: string) => void;
    onColumnCheckboxChange: (column: string, checked: boolean) => void;
}

export class WorkOrderDialogAttachWorkToProjectDialogListLineHeader extends React.Component<IWorkOrderDialogAttachWorkToProjectDialogListLineHeaderProp, {}> {
    handleColumnClick = (column: string) => {
        this.props.onColumnClick(column);
    };

    handleColumnCheckboxChange = (column: string, checked: boolean) => {
        this.props.onColumnCheckboxChange(column, checked);
    };

    render() {
        return (
            <div className="row title">
                <ListHeaderColumn
                    title={Translations.AbrNumber}
                    column="number"
                    classes="col-1"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Customer}
                    column="customer"
                    classes="col-4"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Site}
                    column="siteName"
                    classes="col-3"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.WorkTitle}
                    column="name"
                    classes="col-4"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
            </div>
        );
    }
}

// WorkOrderDialogAttachWorkToProjectDialogListLine
export interface IWorkOrderDialogAttachWorkToProjectDialogListLineProp {
    item: IWorkOrderListItem;
    selectedId: string;
    checked: boolean;
    onClick: (id: string) => void;
    onCheckboxChange: (id: string, checked: boolean) => void;
}

export class WorkOrderDialogAttachWorkToProjectDialogListLine extends React.Component<IWorkOrderDialogAttachWorkToProjectDialogListLineProp, {}> {
    handleCheckBoxClick = (e) => {
        e.stopPropagation();
        this.props.onCheckboxChange(this.props.item.id, !this.props.checked);
    };

    render() {
        const item = this.props.item;
        return (
            <div className={"row line" + (" state" + item.state) + (item.id === this.props.selectedId ? " selected" : "")} onClick={() => { this.props.onClick(item.id); }} >
                <div className="col-1 number"><input type="checkbox" checked={this.props.checked} onClick={this.handleCheckBoxClick} value="" />{item.number.toString(10)}</div>
                <div className="col-4">{item.customerName}</div>
                <div className="col-3">{item.siteName}</div>
                <div className="col-4">{item.name}</div>
            </div>
        );
    }
}

// WorkOrderDialogAttachWorkToProjectDialog
// ***********************************************************************************************************************
export interface IWorkOrderDialogAttachWorkToProjectDialogProp {
    classes?: string;
    customerName: string;
    title: string;
    onOk: (ids: string[]) => void;
    onCancel: () => void;
}

export interface IWorkOrderDialogAttachWorkToProjectDialogState {
    page: number;
    hasMore: boolean;
    filter: string;
    sortColumn: string;
    sortOrderIsAsc: boolean;
    items: IWorkOrderListItem[];
    workOrderStates: IIdTitle[];
    state: number;
    selectedId: string;
    checkedIds: string[];
}

export class WorkOrderDialogAttachWorkToProjectDialog extends React.Component<IWorkOrderDialogAttachWorkToProjectDialogProp, IWorkOrderDialogAttachWorkToProjectDialogState> {
    private containerDiv: HTMLDivElement;
    private listDiv: HTMLDivElement;
    private isLoading: boolean;

    constructor(props) {
        super(props);
        this.isLoading = false;
        this.state = {
            page: 1, hasMore: false, filter: props.customerName, sortColumn: "number", sortOrderIsAsc: true, items: [], workOrderStates: [], state: 0, selectedId: null, checkedIds: [],
        };
    }

    searchItems = (page: number, filter: string, state: number, sortColumn: string, sortOrderIsAsc: boolean, resetItems: boolean, refreshList: boolean, successCallback: () => void = null): Promise<void> => {
        const obj = this;
        obj.isLoading = true;
        store.customStore.dispatch(storeActions.fetchStart());
        return workOrderService.getWorkOrderListItems(!refreshList ? infiniteListPageSize : page * infiniteListPageSize, !refreshList ? page : 1, filter, [state], [], sortColumn, sortOrderIsAsc, [WorkOrderCategory.Work], null, null).then(workOrderItems => {
            let items: IWorkOrderListItem[];
            if (!resetItems && !refreshList) {
                const oldIds = {};
                for (let j = 0; j < obj.state.items.length; j++) {
                    oldIds[obj.state.items[j].id] = true;
                }
                const oldItems = obj.state.items.slice(0);
                const newItems = workOrderItems.items.filter(i => Object.prototype.hasOwnProperty.call(oldIds, i.id) ? false : (oldIds[i.id] = true));
                items = [...oldItems, ...newItems];
            } else {
                items = workOrderItems.items;
            }
            const selectedId = items.findIndex(i => i.id === obj.state.selectedId) > -1 ? obj.state.selectedId : "";
            const checkedIds = items.filter(i => obj.state.checkedIds.indexOf(i.id) > -1).map(i => i.id);
            obj.setState({
                page: !refreshList ? workOrderItems.page : page,
                hasMore: workOrderItems.hasMore,
                filter: filter,
                sortColumn: sortColumn,
                sortOrderIsAsc: sortOrderIsAsc,
                items: items,
                workOrderStates: workOrderItems.workOrderStates,
                selectedId: selectedId,
                checkedIds: checkedIds
            });
            if (!Base.isNullOrUndefined(successCallback)) {
                successCallback();
            }
        },
        error => {
            handleApiError(error, store.customStore.dispatch);
        }).finally(() => {
            obj.isLoading = false;
            store.customStore.dispatch(storeActions.fetchEnd());
        });
    };

    handleScrollSub = Base.debounce((obj: WorkOrderDialogAttachWorkToProjectDialog) => {
        if (obj.isLoading || !obj.state.hasMore) return;
        if (obj.listDiv.offsetHeight - (obj.containerDiv.clientHeight + obj.containerDiv.scrollTop) < 5) {
            obj.searchItems(obj.state.page + 1, obj.state.filter, obj.state.state, obj.state.sortColumn, obj.state.sortOrderIsAsc, false, false);
        }
    }, 100);

    handleScroll = (event) => {
        this.handleScrollSub(this);
    };

    componentDidMount(): void {
        this.containerDiv.addEventListener("scroll", this.handleScroll);
        this.searchItems(this.state.page, this.state.filter, this.state.state, this.state.sortColumn, this.state.sortOrderIsAsc, false, false);
    }

    componentWillUnmount(): void {
        this.containerDiv.removeEventListener("scroll", this.handleScroll);
    }

    changeFilter = (filter: string) => {
        this.searchItems(1, filter, this.state.state, this.state.sortColumn, this.state.sortOrderIsAsc, true, false);
    };

    changeSortColumn = (sortColumn: string) => {
        const oldSortColumn = this.state.sortColumn;
        const sortOrderIsAsc = oldSortColumn === sortColumn ? !this.state.sortOrderIsAsc : true;
        this.searchItems(1, this.state.filter, this.state.state, sortColumn, sortOrderIsAsc, true, false);
    };

    handleChange = (event) => {
        const state = this.state;
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "state") {
            const stateValue = value.toInteger(true, -1);
            this.searchItems(state.page, state.filter, stateValue, state.sortColumn, state.sortOrderIsAsc, true, false);
            this.setState({ state: stateValue });
        }
    };

    handleColumnCheckboxChange = (column: string, checked: boolean) => {
        const result = Base.getIdsOnColumnCheckboxChange(this.state.items, checked);
        this.setState({ selectedId: result.selectedId, checkedIds: result.checkedIds });
    };

    handleLineCheckboxChange = (id: string, checked: boolean) => {
        const result = Base.getIdsOnLineCheckboxChange(this.state.selectedId, this.state.checkedIds, id, checked);
        this.setState({ selectedId: result.selectedId, checkedIds: result.checkedIds });
    };

    handleClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleOkClick = () => {
        const selectedIds = Base.getSelectedIds(this.state.selectedId, this.state.checkedIds);
        if (selectedIds.length < 1) return;
        const items = this.state.items.filter(i => selectedIds.indexOf(i.id) > -1);
        if (items.length < 1) return;
        this.props.onOk(items.map(i => i.id));
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "connectWorkOrderToProject px800" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <Dialog
                    classes={dialogClasses}
                    title={props.title}
                    show={true}
                    body={<div>
                        <div className="commandRow main">
                            <ToolTitle
                                title={Translations.WorkOrders}
                            />
                            <div className="col">
                                <select className="custom-select tool stateContainer" name="state" title={Translations.State} value={state.state} onChange={this.handleChange}>
                                    {state.workOrderStates.map((workOrderState) =>
                                        <option key={workOrderState.id} value={workOrderState.id}>{workOrderState.title}</option>
                                    )}
                                </select>
                            </div>
                            <div className="col-auto right">
                                <ListSearchFilter
                                    searchFilter={state.filter}
                                    onSearchClick={this.changeFilter}
                                />
                            </div>
                        </div>
                        <div>
                            <WorkOrderDialogAttachWorkToProjectDialogListLineHeader
                                sortColumn={state.sortColumn}
                                sortOrderIsAsc={state.sortOrderIsAsc}
                                onColumnClick={this.changeSortColumn}
                                onColumnCheckboxChange={this.handleColumnCheckboxChange}
                            />
                        </div>
                        <div className="listContainer main workOrdersContainer">
                            <div className="list workOrders striped" ref={(elem) => { this.containerDiv = elem; }}>
                                <div className="lineContainer" ref={(elem) => { this.listDiv = elem; }}>
                                    {state.items.map((item) =>
                                        <WorkOrderDialogAttachWorkToProjectDialogListLine
                                            key={item.id}
                                            item={item}
                                            selectedId={state.selectedId}
                                            checked={state.checkedIds.indexOf(item.id) > -1}
                                            onClick={this.handleClick}
                                            onCheckboxChange={this.handleLineCheckboxChange}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.OK, classes: "btn-primary", enabled: true, onClick: this.handleOkClick },
                        { title: (Translations.Close), classes: "btn-default", enabled: true, onClick: this.handleCancelClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}

// WorkOrderDialogWorkerOrderTaskListLine
export interface IWorkOrderDialogWorkerOrderTaskListLineProp {
    employees: IEmployeeItem[];
    vehicles: IVehicleItem[];
    task: IWorkOrderEditItemWorkOrderTask;
    selectedId: string;
    checked: boolean;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
    onCheckboxChange: (id: string, checked: boolean) => void;
    dragIndex: number;
    allowedDroppableId: string;
    isDragDisabled: boolean;
}

export class WorkOrderDialogWorkerOrderTaskListLine extends React.Component<IWorkOrderDialogWorkerOrderTaskListLineProp, {}> {
    handleCheckBoxClick = (e) => {
        const props = this.props;
        e.stopPropagation();
        this.props.onCheckboxChange(props.task.id, !props.checked);
    };

    render() {
        const props = this.props;
        const task = props.task;
        const isDisabled = props.isDragDisabled || task.state > 0;
        const durationMin = Base.dateDiffInMinutes(task.startTime, task.endTime);
        const employeesStr = Base.getStringWithSeparators(task.getTaskEmployees(props.employees).map(i => i.getTitle()), ", ");
        const timeStr = Base.timeToDateStr(task.startTime) + " " + Base.timeToTimeStr(task.startTime) + (durationMin ? " (" + AppUtils.getDurationStrByDurationMin(durationMin) + ")" : "");
        const titleStr = task.number.toString(10) + " " + task.name;
        return task.state > 0
            ? (<div
                    className={"row line" + (task.id === props.selectedId ? " selected" : "")}
                    onClick={() => { props.onClick(task.id); }}
                    onDoubleClick={() => { props.onDoubleClick(task.id); }}
               >
                <div className="col-4 number" onClick={() => { this.props.onDoubleClick(task.id); }} title={titleStr}><input type="checkbox" checked={props.checked} onClick={this.handleCheckBoxClick} value="" />{titleStr}</div>
                <div className="col-3" title={timeStr}>{timeStr}</div>
                <div className="col-2" title={employeesStr}>{employeesStr}</div>
                <div className="col-2" title={task.stateStr}>{task.stateStr}</div>
                <div
                    className="col-1"
                    style={{ display: "grid", placeItems: "center" }}
                >
                    <i
                        className="icon bi bi-grip-vertical"
                        style={{ padding: 0, color: isDisabled ? "rgb(0 0 0 / 0.1)" : "black" }}
                    />
                </div>
                {/*<div className={"col-1" + (task.invoiceTransferred ? " transfered" : "")} title={invoiceNumberStr}>{invoiceNumberStr}</div>*/}
            </div>)
            : (<Draggable draggableId={task.id} index={props.dragIndex} isDragDisabled={isDisabled}>
                {(provided) => (
                    <div
                        className={"row line" + (task.id === props.selectedId ? " selected" : "")}
                        onClick={() => { props.onClick(task.id); }}
                        onDoubleClick={() => { props.onDoubleClick(task.id); }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                    >
                        <div className="col-4 number" onClick={() => { this.props.onDoubleClick(task.id); }} title={titleStr}><input type="checkbox" checked={props.checked} onClick={this.handleCheckBoxClick} value="" />{titleStr}</div>
                        <div className="col-3" title={timeStr}>{timeStr}</div>
                        <div className="col-2" title={employeesStr}>{employeesStr}</div>
                        <div className="col-2" title={task.stateStr}>{task.stateStr}</div>
                        <div
                            className="col-1"
                            style={{ display: "grid", placeItems: "center" }}
                            {...(!isDisabled && provided.dragHandleProps)}
                        >
                            <i
                                className="icon bi bi-grip-vertical"
                                style={{ padding: 0, color: isDisabled ? "rgb(0 0 0 / 0.1)" : "black" }}
                            />
                        </div>
                        {/*<div className={"col-1" + (task.invoiceTransferred ? " transfered" : "")} title={invoiceNumberStr}>{invoiceNumberStr}</div>*/}
                    </div>
                )}
            </Draggable >);
    }
}

// WorkOrderDialogWorkerOrderTaskListLineReadOnlyView
export interface IWorkOrderDialogWorkerOrderTaskListLineReadOnlyViewProp {
    employees: IEmployeeItem[];
    vehicles: IVehicleItem[];
    task: IWorkOrderEditItemWorkOrderTask;
    selectedId: string;
}

export class WorkOrderDialogWorkerOrderTaskListLineReadOnlyView extends React.Component<IWorkOrderDialogWorkerOrderTaskListLineReadOnlyViewProp, {}> {
    render() {
        const props = this.props;
        const task = props.task;
        const durationMin = Base.dateDiffInMinutes(task.startTime, task.endTime);
        const employeesStr = Base.getStringWithSeparators(task.getTaskEmployees(props.employees).map(i => i.getTitle()), ", ");
        const timeStr = Base.timeToDateStr(task.startTime) + " " + Base.timeToTimeStr(task.startTime) + (durationMin ? " (" + AppUtils.getDurationStrByDurationMin(durationMin) + ")" : "");
        const titleStr = task.number.toString(10) + " " + task.name;
        return (
            <div className={"row line" + (task.id === this.props.selectedId ? " selected" : "")} >
                <div className="col-4 number" title={titleStr}>{titleStr}</div>
                <div className="col-3" title={timeStr}>{timeStr}</div>
                <div className="col-2" title={employeesStr}>{employeesStr}</div>
                <div className="col-2" title={task.stateStr}>{task.stateStr}</div>
                <div className="col-1" style={{ display: "grid", placeItems: "center" }}><i className="icon bi bi-grip-vertical" style={{ padding: 0, color: "rgb(0 0 0 / 0.1)" }} /></div>
                {/*<div className={"col-1" + (task.invoiceTransferred ? " transfered" : "")} title={invoiceNumberStr}>{invoiceNumberStr}</div>*/}
            </div>
        );
    }
}

// WorkOrderDialogWorkerOrderTaskList
export interface IWorkOrderDialogWorkerOrderTaskListProp {
    // dialog state
    isSaving: boolean;
    //For adding recurring tasks
    employees: IEmployeeItem[];
    vehicles: IVehicleItem[];
    employeeIds: string[];
    vehicleIds: string[];
    name: string;
    description: string;
    fullDay: boolean;
    startTime: number;
    endTime: number;
    estimatedHours: number;
    showEstimatedHours: boolean;
    recurrenceType: number;
    recurrenceInterval: number;
    recurrenceIntervalDetail: number;
    recurrenceOccurrenceAmount: number;
    recurrenceEndDate: number;
    //For changing multiple records
    workOrderStates: INumberTitle[];
    //Base data
    title?: string;
    titleId?: string;
    titleClass?: string;
    tasks: IWorkOrderEditItemWorkOrderTask[];
    customerName: string;
    workOrderId: string;
    workOrderIsRecurring: boolean;
    isReadOnly: boolean;
    onAddTask: () => void;
    onEditTask: (id: string) => void;
    onTaskRemoved: () => void;
    onTasksConnected: () => void;
    onTasksAdded: () => void;
    onTasksModified: () => void;
    onReorderTasks: (tasks: IWorkOrderEditItemWorkOrderTask[]) => void;
    onSwitchHasSequentialTasks: (newState: boolean) => void;
    hasSequentialTasks: boolean;
    isProject: boolean;
    // Refs
    hasSequentialTasksSwitchRef: React.RefObject<HTMLInputElement>;
}

interface IWorkOrderDialogWorkerOrderTaskListState {
    selectedId: string;
    checkedIds: string[];
    addActions: IButtonDropdownAction[];
    menuActions: IButtonDropdownAction[];
    draggingTaskIsStarting: boolean;
    draggingTask: boolean;
    draggedTaskDestinationInvalid: boolean;
    //AttachWorkToProjectDialog
    showWorkOrderDialogAttachWorkToProjectDialog: boolean;
    //RecurrenceDialog
    showRecurrenceDialog: boolean;
    recurrenceDialogSourceIsTask: boolean;
    recurrenceDialogName: string;
    recurrenceDialogDescription: string;
    recurrenceDialogEmployeeIds: string[];
    recurrenceDialogVehicleIds: string[];
    recurrenceDialogFullDay: boolean;
    recurrenceDialogStartDateStr: string;
    recurrenceDialogStartTimeStr: string;
    recurrenceDialogEndDateStr: string;
    recurrenceDialogEndTimeStr: string;
    recurrenceDialogRecurrenceEndDate: number;
    //SetWorkOrderValuesDialog
    showSetWorkOrderValuesDialog: boolean;
    workOrderValuesDialogSetEmployees: boolean;
    workOrderValuesDialogSetVehicles: boolean;
    workOrderValuesDialogSetState: boolean;
    workOrderValuesDialogSetDuration: boolean;
    workOrderValuesDialogWorkOrderIds: string[];
    workOrderValuesDialogEmployeeIds: string[];
    workOrderValuesDialogVehicleIds: string[];
    workOrderValuesDialogState: number;
    workOrderValuesDialogEstimatedHours: number;
}

export class WorkOrderDialogWorkerOrderTaskList extends React.Component<IWorkOrderDialogWorkerOrderTaskListProp, IWorkOrderDialogWorkerOrderTaskListState> {
    private mainDiv: HTMLDivElement;
    private dragContainerHeight = 0;

    getMenuActions = (props: IWorkOrderDialogWorkerOrderTaskListProp): IButtonDropdownAction[] => {
        const menuActions: IButtonDropdownAction[] = [];
        menuActions.push({ title: Translations.SetEmployees, onClick: () => this.handleSetValues(true, false, false, false) });
        menuActions.push({ title: Translations.SetVehicles, onClick: () => this.handleSetValues(false, true, false, false) });
        menuActions.push({ title: Translations.SetState, onClick: () => this.handleSetValues(false, false, true, false) });
        if (props.showEstimatedHours) {
            menuActions.push({ title: Translations.SetDurationByEstimatedHours, onClick: () => this.handleSetValues(false, false, false, true) });
        }
        return menuActions;
    };

    constructor(props) {
        super(props);
        const addActions: IButtonDropdownAction[] = [];
        addActions.push({ title: Translations.AddTask, onClick: props.onAddTask });
        if (props.workOrderIsRecurring) {
            addActions.push({ title: Translations.AddRecurringTasks, onClick: this.handleAddRecurringTasks });
        }
        addActions.push({ title: Translations.AddExistingWorkOrderWork, onClick: this.handleAttach });
        this.state = {
            selectedId: null,
            checkedIds: [],
            addActions: addActions,
            menuActions: this.getMenuActions(props),
            showWorkOrderDialogAttachWorkToProjectDialog: false,
            draggingTaskIsStarting: false,
            draggingTask: false,
            draggedTaskDestinationInvalid: false,
            //RecurrenceDialog
            showRecurrenceDialog: false,
            recurrenceDialogSourceIsTask: false,
            recurrenceDialogName: "",
            recurrenceDialogDescription: "",
            recurrenceDialogEmployeeIds: [],
            recurrenceDialogVehicleIds: [],
            recurrenceDialogFullDay: false,
            recurrenceDialogStartDateStr: "",
            recurrenceDialogStartTimeStr: "",
            recurrenceDialogEndDateStr: "",
            recurrenceDialogEndTimeStr: "",
            recurrenceDialogRecurrenceEndDate: 0,
            //SetWorkOrderValuesDialog
            showSetWorkOrderValuesDialog: false,
            workOrderValuesDialogSetEmployees: false,
            workOrderValuesDialogSetVehicles: false,
            workOrderValuesDialogSetState: false,
            workOrderValuesDialogSetDuration: false,
            workOrderValuesDialogWorkOrderIds: [],
            workOrderValuesDialogEmployeeIds: [],
            workOrderValuesDialogVehicleIds: [],
            workOrderValuesDialogState: 0,
            workOrderValuesDialogEstimatedHours: 0
        };
    }

    componentDidUpdate(prevProps: IWorkOrderDialogWorkerOrderTaskListProp, prevState: IWorkOrderDialogWorkerOrderTaskListState): void {
        const props = this.props;
        if (prevProps.showEstimatedHours === props.showEstimatedHours) return;
        this.setState({
            menuActions: this.getMenuActions(props)
        });
    }

    //General operations
    // #region General operations
    handleAttach = () => {
        this.setState({
            showWorkOrderDialogAttachWorkToProjectDialog: true,
        });
    };

    handleEdit = (id?: string) => {
        const selectedId = id || this.state.selectedId;
        if (!selectedId) return;
        this.props.onEditTask(selectedId);
    };

    remove = (ids: string[], remove: boolean) => {
        const obj = this;
        store.customStore.dispatch(storeActions.fetchStart());
        workOrderService.detachWorkOrderFromProject(this.props.workOrderId, ids, remove, false)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                obj.props.onTaskRemoved();
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleRemove = () => {
        const obj = this;
        const props = this.props;
        const state = this.state;
        const selectedIds = Base.getSelectedIds(state.selectedId, state.checkedIds);
        if (selectedIds.length < 1) return;
        const task = selectedIds.length < 2 ? props.tasks.find(i => i.id === selectedIds[0]) : null;
        if (selectedIds.length < 2 && !task) return;
        store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, selectedIds.length < 2
            ? (String.format(props.workOrderIsRecurring
                ? Translations.WorkOrderDetachConfirmationRecurring
                : Translations.WorkOrderDetachConfirmation, task.number))
            : (props.workOrderIsRecurring
                ? Translations.WorkOrdersDetachConfirmationRecurring
                : Translations.WorkOrdersDetachConfirmation),
        () => {
            store.customStore.dispatch(storeActions.clearConfirmation());
            obj.remove(selectedIds, true);
        }, () => {
            store.customStore.dispatch(storeActions.clearConfirmation());
            obj.remove(selectedIds, false);
        }, () => {
            store.customStore.dispatch(storeActions.clearConfirmation());
        },
        Translations.RemoveTasks, null, Translations.DetachTasks, "btn-primary"));
    };

    handleWorkOrderDialogAttachWorkToProjectDialogDialogOk = (ids: string[]) => {
        if (Base.isNullOrUndefined(ids) || ids.length < 1) return;
        const obj = this;
        store.customStore.dispatch(storeActions.fetchStart());
        workOrderService.attachWorkOrdersToProject(this.props.workOrderId, ids)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                obj.setState({ showWorkOrderDialogAttachWorkToProjectDialog: false });
                obj.props.onTasksConnected();
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleWorkOrderDialogAttachWorkToProjectDialogDialogCancel = () => {
        this.setState({
            showWorkOrderDialogAttachWorkToProjectDialog: false
        });
    };
    // #endregion General operations

    //Setting values
    // #region Setting values
    handleSetValues = (setEmployees: boolean, setVehicles: boolean, setState: boolean, setDuration: boolean) => {
        const props = this.props;
        const state = this.state;
        const selectedIds = Base.getSelectedIds(state.selectedId, state.checkedIds);
        if (selectedIds.length < 1) return;
        const selectedTask = props.tasks.find(i => i.id === selectedIds[0]);
        if (!selectedTask) return;
        this.setState({
            showSetWorkOrderValuesDialog: setEmployees || setVehicles || setState || setDuration,
            workOrderValuesDialogSetEmployees: setEmployees,
            workOrderValuesDialogSetVehicles: setVehicles,
            workOrderValuesDialogSetState: setState,
            workOrderValuesDialogSetDuration: setDuration,
            workOrderValuesDialogWorkOrderIds: selectedIds,
            workOrderValuesDialogEmployeeIds: selectedTask.employeeIds,
            workOrderValuesDialogVehicleIds: selectedTask.vehicleIds,
            workOrderValuesDialogState: selectedTask.state,
            workOrderValuesDialogEstimatedHours: selectedIds.length > 1 || !selectedTask.estimatedHours ? props.estimatedHours : selectedTask.estimatedHours,
        });
    };

    handleWorkOrderValuesDialogOk = () => {
        this.setState({
            showSetWorkOrderValuesDialog: false
        });
        this.props.onTasksModified();
    };

    handleWorkOrderValuesDialogCancel = () => {
        this.setState({
            showSetWorkOrderValuesDialog: false
        });
    };
    // #endregion Setting values

    //Adding recurrence
    // #region Adding recurrence
    private static getAddRecurringTasksSaveData = (workOrderId: string, employeeIds: string[], vehicleIds: string[], startDateTime: Date, endDateTime: Date, name: string, description: string, fullDay: boolean,
        recurrenceType: number, recurrenceInterval: number, recurrenceIntervalDetail: number, recurrenceOccurrenceAmount: number, recurrenceEndDate: number): SaveData => {
        const data = new SaveData();
        // Common
        data.append("id", workOrderId);
        // General
        data.append("name", name);
        data.append("description", description);
        data.append("fullDay", fullDay ? "1" : "0");
        data.append("employeeIds", JSON.stringify(employeeIds));
        data.append("vehicleIds", JSON.stringify(vehicleIds));
        data.append("startTime", startDateTime ? startDateTime.getTime().toString(10) : "");
        data.append("endTime", endDateTime ? endDateTime.getTime().toString(10) : "");
        // Recurrence
        data.append("recurrenceType", recurrenceType.toString(10));
        data.append("recurrenceInterval", recurrenceInterval.toString(10));
        data.append("recurrenceIntervalDetail", recurrenceIntervalDetail.toString(10));
        data.append("recurrenceOccurrenceAmount", recurrenceOccurrenceAmount.toString(10));
        data.append("recurrenceEndDate", recurrenceEndDate ? recurrenceEndDate.toString(10) : "");
        return data;
    };

    private static checkErrors = (employeeIds: string[], startDateTime: Date, endDateTime: Date): Promise<boolean> => {
        return new Promise<boolean>((resolve) => {
            return resolve(true);
        });
    };

    private static getWarningMessage = (employeeIds: string[], startDateTime: Date, endDateTime: Date): Promise<string> => {
        return new Promise<string>((resolve) => {
            let warnings: string = "";
            // StartTime
            const nowTime = Base.getNowDate();
            if (startDateTime < nowTime) {
                warnings = warnings + Translations.StartDateIsInThePast + Base.lf;
            }
            return resolve(warnings);
        });
    };

    private static validate = (employeeIds: string[], startDateTime: Date, endDateTime: Date, saveCallback: () => void): Promise<void> => {
        return WorkOrderDialogWorkerOrderTaskList.checkErrors(employeeIds, startDateTime, endDateTime).then(success => {
            if (success) {
                return WorkOrderDialogWorkerOrderTaskList.getWarningMessage(employeeIds, startDateTime, endDateTime).then(warnings => {
                    if (!warnings) {
                        saveCallback();
                        return new Promise<void>((resolve) => { resolve(); });
                    }
                    return new Promise<void>((resolve) => {
                        store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, warnings + Base.lf + Translations.DoYouReallyWantToAddRecurringTasks,
                            () => {
                                store.customStore.dispatch(storeActions.clearConfirmation());
                                saveCallback();
                                resolve();
                            },
                            () => {
                                store.customStore.dispatch(storeActions.clearConfirmation());
                                resolve();
                            }, null));
                    });
                });
            } else {
                return new Promise<void>((resolve) => { resolve(); });
            }
        });
    };

    addRecurringTasks = (workOrderId: string, employeeIds: string[], vehicleIds: string[], startDateTime: Date, endDateTime: Date, name: string, description: string, fullDay: boolean,
        recurrenceType: number, recurrenceInterval: number, recurrenceIntervalDetail: number, recurrenceOccurrenceAmount: number, recurrenceEndDate: number) => {
        const obj = this;
        WorkOrderDialogWorkerOrderTaskList.validate(employeeIds, startDateTime, endDateTime, () => {
            //Set SaveData
            const saveData = WorkOrderDialogWorkerOrderTaskList.getAddRecurringTasksSaveData(workOrderId, employeeIds, vehicleIds, startDateTime, endDateTime, name, description, fullDay,
                recurrenceType, recurrenceInterval, recurrenceIntervalDetail, recurrenceOccurrenceAmount, recurrenceEndDate);
            if (!saveData) return;
            // Call server
            store.customStore.dispatch(storeActions.fetchStart());
            workOrderService.addRecurringTasks(saveData.formData)
                .then(success => {
                    store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                    obj.setState({
                        showRecurrenceDialog: false
                    });
                    obj.props.onTasksAdded();
                })
                .catch(error => {
                    store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                    return null;
                })
                .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
        });
    };

    reorderTasks = (taskList: IWorkOrderEditItemWorkOrderTask[], sourceIdx: number, targetIdx: number) => {
        const result = Array.from(taskList);
        const [removed] = result.splice(sourceIdx, 1);
        result.splice(targetIdx, 0, removed);

        return result.map((item, idx) => {
            // Clone objects so that it's possible to revert the change if something goes wrong. Should also make this function slightly more immutable.
            const clone = Base.cloneClassObject(item);
            clone.sortOrder = ++idx;
            return clone;
        });
    };

    handleAddRecurringTasks = () => {
        const props = this.props;
        const state = this.state;
        const selectedTask = state.selectedId ? props.tasks.find(i => i.id === state.selectedId) : null;
        //Schedule new tasks to the end of current tasks
        let startTime = selectedTask ? selectedTask.startTime : props.startTime;
        if (!startTime) {
            startTime = new Date().getTime();
        }
        let endTime = selectedTask ? selectedTask.endTime : props.endTime;
        if (!endTime) {
            endTime = new Date(startTime).addMinutes(60).getTime();
        }
        const duration = Base.dateDiffInMinutes(startTime, endTime);
        if (props.tasks.length > 0) {
            const orgStartTime = new Date(startTime);
            const maxTaskEndTime = new Date(props.tasks[props.tasks.length - 1].endTime).addDays(1);
            const newStartTime = new Date(maxTaskEndTime.getFullYear(), maxTaskEndTime.getMonth(), maxTaskEndTime.getDate(), orgStartTime.getHours(), orgStartTime.getMinutes());
            startTime = newStartTime.getTime();
            endTime = newStartTime.addMinutes(duration).getTime();
        }
        let recurrenceDialogRecurrenceEndDate = props.recurrenceEndDate;
        if (recurrenceDialogRecurrenceEndDate) {
            recurrenceDialogRecurrenceEndDate = new Date(startTime).addDays(30).getTime();
        }
        //Show dialog
        this.setState({
            showRecurrenceDialog: true,
            recurrenceDialogSourceIsTask: !!selectedTask,
            recurrenceDialogName: selectedTask ? selectedTask.name : props.name,
            recurrenceDialogDescription: selectedTask ? selectedTask.description : props.description,
            recurrenceDialogEmployeeIds: selectedTask ? selectedTask.employeeIds : props.employeeIds,
            recurrenceDialogVehicleIds: selectedTask ? selectedTask.vehicleIds : props.vehicleIds,
            recurrenceDialogFullDay: selectedTask ? selectedTask.fullDay : props.fullDay,
            recurrenceDialogStartDateStr: Base.timeToDateStr(startTime),
            recurrenceDialogStartTimeStr: Base.timeToTimeStr(startTime),
            recurrenceDialogEndDateStr: Base.timeToDateStr(endTime),
            recurrenceDialogEndTimeStr: Base.timeToTimeStr(endTime),
            recurrenceDialogRecurrenceEndDate: recurrenceDialogRecurrenceEndDate
        });
    };

    handleRecurrenceDialogOk = (employeeIds: string[], vehicleIds: string[], name: string, description: string, fullDay: boolean, startDateStr: string, startTimeStr: string, endDateStr: string, endTimeStr: string,
        recurrenceType: number, recurrenceInterval: number, recurrenceIntervalDetail: number, recurrenceOccurrenceAmount: number, recurrenceEndDate: number) => {
        const props = this.props;
        const startDateTime = AppUtils.getDateTime(startDateStr, startTimeStr, fullDay);
        let endDateTime = AppUtils.getDateTime(endDateStr, endTimeStr, fullDay);
        if (fullDay) {
            endDateTime = endDateTime.addDays();
        }
        this.addRecurringTasks(props.workOrderId, employeeIds, vehicleIds, startDateTime, endDateTime, name, description, fullDay, recurrenceType, recurrenceInterval, recurrenceIntervalDetail, recurrenceOccurrenceAmount, recurrenceEndDate);
    };

    handleRecurrenceDialogCancel = () => {
        this.setState({
            showRecurrenceDialog: false
        });
    };
    // #endregion Adding recurrence

    handleChangeColumnCheckbox = (e) => {
        e.stopPropagation();
        const checked: boolean = e.target.checked;
        const props = this.props;
        const result = Base.getIdsOnColumnCheckboxChange(props.tasks, checked);
        this.setState({
            selectedId: result.selectedId,
            checkedIds: result.checkedIds
        });
    };

    handleChangeLineCheckbox = (id: string, checked: boolean) => {
        const state = this.state;
        const result = Base.getIdsOnLineCheckboxChange(state.selectedId, state.checkedIds, id, checked);
        this.setState({
            selectedId: result.selectedId,
            checkedIds: result.checkedIds
        });
    };

    handleLineClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleLineDoubleClick = (id: string) => {
        this.setState({
            selectedId: id
        }, () => {
            this.handleEdit(id);
        });
    };

    handleDragTaskBeforeCapture = () => {
        if (this.mainDiv) {
            const mainDivRect = this.mainDiv.getBoundingClientRect();
            this.dragContainerHeight = mainDivRect.height;
        }
        this.setState({
            draggingTaskIsStarting: true
        });
    };

    handleDragTaskStart = () => {
        this.setState({
            draggingTask: true,
            draggedTaskDestinationInvalid: false
        });
    };

    handleDragTaskOver = (result: DropResult) => {
        // dropped outside the list or indices are the same
        if (!result.destination || result.source.index === result.destination.index) {
            return undefined;
        }
        Base.setNotAllowedCursor(false);

        const { tasks, onReorderTasks } = this.props;
        const items = this.reorderTasks(
            tasks,
            result.source.index,
            result.destination.index
        );

        onReorderTasks(items);
    };

    handleDragTaskUpdate = (initial: DragUpdate, provided: ResponderProvided) => {
        const state = this.state;
        const oldDraggedTaskDestinationInvalid = state.draggedTaskDestinationInvalid;
        const draggedTaskDestinationInvalid = state.draggingTask && !initial.destination;
        if (oldDraggedTaskDestinationInvalid === draggedTaskDestinationInvalid) return;
        this.setState({
            draggedTaskDestinationInvalid: draggedTaskDestinationInvalid
        });
        Base.setNotAllowedCursor(draggedTaskDestinationInvalid);
    };

    render() {
        const props = this.props;
        const state = this.state;

        const tasks = props.tasks;

        return (
            <div className="workOrderSubList">
                <div className="commandRow">
                    <input type="checkbox" onClick={this.handleChangeColumnCheckbox} value="" />
                    {!!props.title &&
                        <label id={props.titleId} className={"control-label listTitle" + (props.titleClass ? " " + props.titleClass : "")}>{props.title}</label>
                    }
                    {!props.isReadOnly &&
                        <ButtonDropdown
                            classes={"right"}
                            buttonClasses={"btn btn-tool round"}
                            buttonIconClasses={"menu"}
                            disabled={!(!!state.selectedId || state.checkedIds.length > 0)}
                            menuRight={true}
                            removeDefaultButtonClasses={true}
                            tooltip={Translations.Set}
                            actions={state.menuActions}
                        />
                    }
                    {!props.isReadOnly &&
                        <ToolButton
                            title={Translations.Remove}
                            enabled={!!state.selectedId || state.checkedIds.length > 0}
                            classes={"round right remove"}
                            onClick={this.handleRemove}
                        />
                    }
                    {!props.isReadOnly &&
                        <ToolButton
                            title={Translations.Edit}
                            enabled={!!state.selectedId}
                            classes={"round right edit"}
                            onClick={this.handleEdit}
                        />
                    }
                    {!props.isReadOnly &&
                        <ButtonDropdown
                            classes={"right"}
                            buttonClasses={"btn btn-tool round add"}
                            buttonIconClasses={"add"}
                            menuRight={true}
                            removeDefaultButtonClasses={true}
                            tooltip={Translations.Add}
                            actions={state.addActions}
                        />
                    }
                    {props.isProject && (
                        <label
                            className={"right control-label mr-3"}
                            htmlFor="hasSequentialTasks"
                            title={props.hasSequentialTasks ? Translations.WorkOrderSequentialTaskExecution : Translations.WorkOrderSequentialTaskExecutionNon}
                        >
                            <input
                                id="hasSequentialTasks"
                                ref={props.hasSequentialTasksSwitchRef}
                                type="checkbox"
                                name="hasSequentialTasks"
                                checked={props.hasSequentialTasks}
                                onChange={(e) => props.onSwitchHasSequentialTasks(e.target.checked)}
                            />
                            {Translations.WorkOrderSequentialTaskExecution}
                        </label>
                    )}
                </div>
                <DragDropContext onBeforeCapture={this.handleDragTaskBeforeCapture} onDragStart={this.handleDragTaskStart} onDragEnd={this.handleDragTaskOver} onDragUpdate={this.handleDragTaskUpdate}>
                    <div className="listContainer tasksContainer" ref={(div) => { this.mainDiv = div; }}>
                        {tasks.length > 0 &&
                            <Droppable droppableId="droppableTaskArea" type="workOrderDialogTaskList">
                                {(provided) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className="list tasks"
                                        style={{ position: "relative" }}
                                    >
                                        {tasks.map((task, idx) => (
                                            <WorkOrderDialogWorkerOrderTaskListLine
                                                key={task.id}
                                                employees={props.employees}
                                                vehicles={props.vehicles}
                                                task={task}
                                                selectedId={state.selectedId}
                                                checked={state.checkedIds.indexOf(task.id) > -1}
                                                onClick={this.handleLineClick}
                                                onDoubleClick={this.handleLineDoubleClick}
                                                onCheckboxChange={this.handleChangeLineCheckbox}
                                                dragIndex={idx}
                                                allowedDroppableId="droppableTaskArea"
                                                isDragDisabled={props.isSaving || !props.hasSequentialTasks}
                                            />
                                        ))}
                                        {provided.placeholder}
                                        <div className="loadingBackground" style={{ display: props.isSaving ? "grid" : "none", zIndex: props.isSaving ? 100000 : -1 }}>
                                            <img className="loadingIndicator" alt="Loading..." src={appConfig.ownRoot + "spinner-loader.gif"} />
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                        }
                    </div>
                </DragDropContext>
                {state.showWorkOrderDialogAttachWorkToProjectDialog &&
                    <WorkOrderDialogAttachWorkToProjectDialog
                        title={props.workOrderIsRecurring ? Translations.AttachWorkToRecurringWork : Translations.AttachWorkToProject}
                        customerName={props.customerName}
                        onOk={this.handleWorkOrderDialogAttachWorkToProjectDialogDialogOk}
                        onCancel={this.handleWorkOrderDialogAttachWorkToProjectDialogDialogCancel}
                    />
                }
                {state.showRecurrenceDialog &&
                    <WorkOrderDialogRecurrenceDialog
                        addMode={true}
                        addSourceIsTask={state.recurrenceDialogSourceIsTask}
                        name={state.recurrenceDialogName}
                        description={state.recurrenceDialogDescription}
                        employees={props.employees}
                        vehicles={props.vehicles}
                        employeeIds={state.recurrenceDialogEmployeeIds}
                        vehicleIds={state.recurrenceDialogVehicleIds}
                        fullDay={state.recurrenceDialogFullDay}
                        startDateStr={state.recurrenceDialogStartDateStr}
                        startTimeStr={state.recurrenceDialogStartTimeStr}
                        endDateStr={state.recurrenceDialogEndDateStr}
                        endTimeStr={state.recurrenceDialogEndTimeStr}
                        interval={30}
                        recurrenceType={props.recurrenceType}
                        recurrenceInterval={props.recurrenceInterval}
                        recurrenceIntervalDetail={props.recurrenceIntervalDetail}
                        recurrenceOccurrenceAmount={props.recurrenceOccurrenceAmount}
                        recurrenceEndDate={state.recurrenceDialogRecurrenceEndDate}
                        onOk={this.handleRecurrenceDialogOk}
                        onCancel={this.handleRecurrenceDialogCancel}
                    />
                }
                {state.showSetWorkOrderValuesDialog &&
                    <WorkOrderSetValuesDialog
                        workOrderStates={props.workOrderStates}
                        employees={props.employees}
                        vehicles={props.vehicles}
                        workOrderIds={state.workOrderValuesDialogWorkOrderIds}
                        employeeIds={state.workOrderValuesDialogEmployeeIds}
                        vehicleIds={state.workOrderValuesDialogVehicleIds}
                        state={state.workOrderValuesDialogState}
                        estimatedHours={state.workOrderValuesDialogEstimatedHours}
                        setEmployees={state.workOrderValuesDialogSetEmployees}
                        setVehicles={state.workOrderValuesDialogSetVehicles}
                        setState={state.workOrderValuesDialogSetState}
                        setDuration={state.workOrderValuesDialogSetDuration}
                        onOk={this.handleWorkOrderValuesDialogOk}
                        onCancel={this.handleWorkOrderValuesDialogCancel}
                    />
                }
            </div>
        );
    }
}
