// HintMark
// ***********************************************************************************************************************
import * as React from "react";

export interface IHintMarkProp {
    hint: string;
    classes?: string;
}

export class HintMark extends React.Component<IHintMarkProp, {}> {
    render() {
        const props = this.props;
        return (
            <span className={"hint" + (props.classes ? " " + props.classes : "")} title={props.hint}>
                <span className="icon" aria-hidden="true" />
            </span>
        );
    }
}