import * as React from "react";
import * as baseService from "../../services/baseService";
import * as reportService from "../../services/reportService";
import * as storeActions from "../../models/store/storeActions";
import * as customStore from "../../framework/customStore";
import { Base } from "../../framework/base";
import { Translations } from "../../models/translations";
import { ReportParameterType } from "../../models/common/enums";
import { IReportListItem, ReportGenerate } from "../../models/report/reportListItem";
import { ReportViewParameters } from "./reportViewParameters";
import { ReportViewPreview } from "./reportViewPreview";

export interface IReportViewProp {
    selected: IReportListItem;
    reportType: string;
    onChangeParameterValue: (name: string, value: string) => void;
}

interface IReportViewState {
    // Report
    reportFile: string;
    reportFileName: string;
    reportFileMimeType: string;
    reportThumbnails: string[];
    reportThumbnailIndex: number;
}

export class ReportView extends React.Component<IReportViewProp, IReportViewState> {
    constructor(props) {
        super(props);
        this.state = {
            reportFile: "",
            reportFileName: "",
            reportFileMimeType: "",
            reportThumbnails: [],
            reportThumbnailIndex: -1
        };
    }

    // #region Parameters
    handleCreateReport = () => {
        const selected = this.props.selected;
        if (!selected) return;
        // Validate parameters
        for (let i = 0; i < selected.parameters.length; i++) {
            const parameter = selected.parameters[i];
            if (parameter.required && !parameter.value) {
                customStore.customStore.dispatch(storeActions.showErrorMessage(String.format(Translations.FieldParameterValueMustBeDefined, parameter.title)));
                return;
            }
            if (parameter.type === ReportParameterType.Date) {
                parameter.value = Base.dateToDateStr(parameter.value.toDate(new Date()));
            } else if (parameter.type === ReportParameterType.HierarchialValueList) {
                let values = parameter.value ? JSON.parse(parameter.value) : [];
                const groupCodes = parameter.hierarchialValueList.map(i => i.code);
                values = values.filter(i => groupCodes.indexOf(i) < 0);
                parameter.value = JSON.stringify(values);
            } else if (parameter.type === ReportParameterType.LookupValueList) {
                parameter.value = parameter.value ?? "";
            }
            if (!parameter.required && !parameter.value) {
                parameter.value = null;
            }
        }
        // Call server
        const reportGenerate = new ReportGenerate(selected);
        reportGenerate.type = this.props.reportType;
        customStore.customStore.dispatch(storeActions.fetchStart());
        reportService.generateReport(reportGenerate)
            .then(fileWithThumbnail => {
                this.setState({
                    reportFile: fileWithThumbnail.file,
                    reportFileName: fileWithThumbnail.fileName,
                    reportFileMimeType: "application/" + (fileWithThumbnail.fileName.endsWith("pdf") ? "pdf" : (fileWithThumbnail.fileName.endsWith("xlsx") ? "xlsx" : "docx")),
                    reportThumbnails: fileWithThumbnail.thumbnails,
                    reportThumbnailIndex: fileWithThumbnail.thumbnails.length > 0 ? 0 : -1,
                });
            })
            .catch(error => {
                customStore.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => customStore.customStore.dispatch(storeActions.fetchEnd()));
    };
    // #endregion Parameters

    render() {
        const props = this.props;
        const state = this.state;
        return (
            <div className="row">
                <div className="col-3">
                    <div className="backgroundBoard">
                        <ReportViewParameters
                            item={props.selected}
                            onChangeValue={props.onChangeParameterValue}
                            onCreateReport={this.handleCreateReport}
                        />
                    </div>
                </div>
                <div className="col-9">
                    <div className="backgroundBoard">
                        <ReportViewPreview
                            file={state.reportFile}
                            fileName={state.reportFileName}
                            fileMimeType={state.reportFileMimeType}
                            thumbnails={state.reportThumbnails}
                            thumbnailIndex={state.reportThumbnailIndex}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
