import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Card, IconButton, OutlinedInput, Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import * as React from "react";
import { Translations } from "../../../../models/translations";
import { StorageShoppingCartItem } from "../../../../store/storageSlice";
import { ShoppingCartFreeAmount } from "./shoppingCartFreeAmount";

type Props = {
    index: number;
    item: StorageShoppingCartItem;
    onRemoveFromShoppingCart: (index: number) => void;
    onAmountChange: (index: number, amount: any) => void;
}

const ShoppingCartListItem = ({ index, item, onRemoveFromShoppingCart, onAmountChange }: Props) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.replace(",", ".");
        const num = Number(value);
        onAmountChange(index, num || 0);
    };

    return (
        <Card>
            <Grid2 container spacing={2} p={2}>

                <Grid2 xs={12} flexGrow={1}>
                    <Stack direction="row">
                        <Stack direction="column" flexGrow={1}>
                            <Typography variant="h4">{item.productName}</Typography>
                            <Typography variant="h5">{item.storageName} - {item.storageLocationName}</Typography>
                        </Stack>
                        <IconButton size="small" sx={{ alignSelf: "start", marginTop: "-8px", marginRight: "-8px" }} onClick={() => onRemoveFromShoppingCart(index)} aria-label="delete" color="error" title={Translations.RemoveProductFromCart}>
                            <DeleteIcon />
                        </IconButton>
                    </Stack>
                </Grid2>

                <Grid2>
                    <Box sx={{ display: "grid", columnGap: 2, gridTemplateColumns: "repeat(2, auto)", alignItems: "center" }}>
                        <Typography variant="h5" pl={1}>{Translations.Amount}</Typography>
                        <Typography variant="h5" pl={1}>{Translations.FreeAmount}</Typography>

                        <OutlinedInput
                            sx={{ width: "70px", height: "30px" }}
                            size="small"
                            value={item.orderAmount}
                            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                            onChange={handleChange}
                            error={!item.isValid}
                        />
                        <ShoppingCartFreeAmount amount={item.freeAmount} unit={item.unit} />
                    </Box>

                </Grid2>

            </Grid2>
        </Card>
    );
};

export default ShoppingCartListItem;
