import * as React from "react";
import { useMemo } from "react";
import { Translations } from "../../../models/translations";
import { Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { TableDef, TableWrapper } from "../../framework/tableWrapper";

export interface StorageTableData {
    id: string;
    storageLocationName: string;
    storageName: string;
    customerName: string;
}

interface Props {
    storageLocations: StorageTableData[];
    onShowDetails: (id: string) => void;
}

export const StorageLocationList = ({ onShowDetails, storageLocations }: Props) => {
    const tableDef: TableDef<StorageTableData> = useMemo(() => ({
        columns: [
            {
                label: Translations.StorageLocationCode,
                accessor: "storageLocationName",
            },
            {
                label: Translations.Storage,
                accessor: "storageName",
            },
            {
                label: Translations.Customer,
                accessor: "customerName",
            },
            {
                disabledSort: true,
                renderCell: (_, row) => (
                    <>
                        <Button
                            variant="primary" className="rounded" startIcon={<VisibilityIcon/>}
                            onClick={() => onShowDetails(row.id)}
                        >
                            {Translations.ShowDetails}
                        </Button>
                    </>
                ),
            },
        ]
    }), []);

    return (
        <>
            {storageLocations.length > 0 &&
                <TableWrapper tableDef={tableDef} data={storageLocations} />
            }
        </>
    );
};

