import React, { memo } from "react";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import Stack from "@mui/material/Stack";
import { TimelineGridRow } from "../../timelinePlanner/timelinePlannerGrid";
import { Translations } from "../../../models/translations";
import { TransportPlanListItemDto } from "../../../models/transport/transportPlan";
import { DateActions } from "../transportTimeline/DateActions";
import { TransportTimelineCell } from "../transportTimeline/TransportTimelineCell";


interface TransportPlansWithoutEmployeeRowProps {
    plans: TransportPlanListItemDto[];
}

export const TransportPlansWithoutEmployeeRow = memo(({ plans }: TransportPlansWithoutEmployeeRowProps) => {
    return (
        <>
            <TimelineGridRow
                renderColumnHeader={(date: Date) => (
                    <DateActions date={date} vehicleId="" />
                )}
                infoColumn={
                    <Box sx={{ fontSize: "small", pt: 1, pb: 1 }}>
                        <Stack direction="row" justifyContent="space-between">
                            <div className="bold flex align-items-center overflow-break">
                                <PersonIcon
                                    sx={{ mr: 1, color: "primary.dark" }}
                                    fontSize="small"
                                />
                                <span>
                                    {Translations.NoEmployee}
                                </span>
                            </div>
                        </Stack>
                    </Box>
                }
            >
                {plans &&
                    plans.map((item) => (
                        <TransportTimelineCell
                            planId={item.id}
                            key={item.id}
                            editReturnPath={"/transport/employees"}
                            editEmployeeEnabled
                            editVehicleEnabled
                        />
                    ))}
            </TimelineGridRow>
        </>
    );
});
