import React, { useEffect, useMemo } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Translations } from "../../models/translations";
import { useTranslation } from "react-i18next";
import * as store from "../../framework/customStore";
import { WorkTimeTypeType } from "../../models/common/enums";
import { useFormik } from "formik";
import {
    defaultFormikConfig,
    formikFieldProps,
    formikValidationProps,
    showApiError,
    showApiSuccess,
} from "../framework/formUtils";
import { Base } from "../../framework/base";
import {
    DesktopTimePicker,
    LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { overwritingBreakSave } from "../../services/workTimeBetaService";
import dayjs from "dayjs";
import * as yup from "yup";
import MuiSelect from "../framework/muiSelect";
import {
    WorkShiftTimeSlotItem,
    findNextWorkShiftTimeSlotItem,
    findPreviousWorkShiftTimeSlotItem,
} from "../../models/workShitTimeSlot/workShiftTimeSlotItem";
import { Checkbox, FormControlLabel, Tooltip, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { EditDialog } from "../framework/editDialog";

interface WorkTimeAddBreakFormItem {
    checked: boolean;
    startDate: string;
    duration: number;
    workTimeTypeId?: string;
    timeZoneName: string;
}

export interface AddBreakValueProps {
    startDate: string;
    timeZoneName: string;
}

export interface NonOverlappingWorkShiftTimeSlotSaveData {
    startDate: string;
    endDate: string;
    workTimeTypeId?: string;
    employeeId: string;
    timeZoneName: string;
}

interface WorkTimeAddBreakDialogProps {
    items?: WorkShiftTimeSlotItem[];
    defaultValues: AddBreakValueProps;
    timeZones: string[];
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
    reloadList: () => void;
}
export const WorkTimeAddBreakDialog = ({
    items,
    defaultValues,
    timeZones,
    setOpenDialog,
    reloadList,
}: WorkTimeAddBreakDialogProps) => {
    const workTimeTypes = store.useAppSelector(
        (state) => state.workShiftTimeSlot?.workTimeTypes
    );
    const fieldProps = (field: keyof WorkTimeAddBreakFormItem) =>
        formikFieldProps(formik, field);
    const breakWorkTimeTypes = workTimeTypes?.filter(
        (t) => t.type === (WorkTimeTypeType.Break as number)
    );
    const [selectedStartDate, setSelectedStartDate] = React.useState<string>();
    const [closestItems, setClosestItems] = React.useState<{
        prev: WorkShiftTimeSlotItem | null;
        next: WorkShiftTimeSlotItem | null;
    }>(null);
    const [hasOverlapping, setHasOverlapping] = React.useState<boolean>(false);
    const [isLocked, setIsLocked] = React.useState(false);
    const [overlappingWithCurrent, setOverlappingWithCurrent] =
        React.useState(false);
    const { t } = useTranslation();

    const allSameTimeZoneName = timeZones.length === 1;

    // Get present timezone and sort by offset string.
    const timeZoneOptions = useMemo(
        () =>
            timeZones.map((option) => ({
                code: option,
                title: items.find((item) => item.timeZoneName === option)!
                    .timeZoneDisplayName,
            })),
        [timeZones, items]
    );

    const formItem: WorkTimeAddBreakFormItem = {
        checked: false,
        startDate: defaultValues.startDate,
        duration: 30,
        workTimeTypeId: breakWorkTimeTypes[0].workTimeTypeId,
        timeZoneName: defaultValues.timeZoneName,
    };

    const handleSubmit = (formData: WorkTimeAddBreakFormItem) => {
        formik.setSubmitting(true);
        const saveData = setSaveData(formData);
        overwritingBreakSave(saveData)
            .then(() => {
                showApiSuccess(Translations.SaveSuccess);
                handleCloseDialog();
                reloadList();
            })
            .catch((error) => showApiError(error));
        formik.setSubmitting(false);
    };

    const formik = useFormik<WorkTimeAddBreakFormItem>({
        ...defaultFormikConfig,
        initialValues: formItem,
        onSubmit: handleSubmit,
        validationSchema: yup.object({
            checked: yup.boolean(),
            duration: yup
                .number()
                .required(Translations.DurationMustBeDefined)
                .min(
                    1,
                    t("workTime.durationRangeError", {
                        value1: 1,
                        value2: 60,
                        unit: Translations.AbrMins,
                    })
                )
                .max(
                    60,
                    t("workTime.durationRangeError", {
                        value1: 1,
                        value2: 60,
                        unit: Translations.AbrMins,
                    })
                ),
            startDate: yup
                .date()
                .nullable()
                .required(Translations.StartDateMustBeDefined),
        }),
    });

    const setSaveData = (
        item: WorkTimeAddBreakFormItem
    ): NonOverlappingWorkShiftTimeSlotSaveData => {
        const startDate = Base.dayjsToMinuteAccuracy(item.startDate);

        return {
            startDate: Base.dayjsToJsonDateTime(startDate),
            endDate: Base.dayjsToJsonDateTime(
                startDate.add(item.duration, "minute")
            ),
            workTimeTypeId: item?.workTimeTypeId || "",
            employeeId: items[0].employeeId,
            timeZoneName: item.timeZoneName,
        };
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const findClosestItems = (): {
        prev: WorkShiftTimeSlotItem | null;
        next: WorkShiftTimeSlotItem | null;
    } => {
        if (
            items.some((i) =>
                Base.isBetween(
                    selectedStartDate,
                    i.startDate,
                    i.endDate,
                    "minute",
                    "()"
                )
            )
        ) {
            // Get closest items only if start date is not in middle of existing slots.
            return;
        }

        const prev = findPreviousWorkShiftTimeSlotItem(
            items,
            selectedStartDate
        );
        const next = findNextWorkShiftTimeSlotItem(items, selectedStartDate);

        if (!!next && !!prev) {
            const differenceInMinutes = Base.dayjsDiffInMinutes(
                prev.endDate,
                next.startDate
            );

            if (differenceInMinutes >= 1 && differenceInMinutes <= 60) {
                return {
                    prev,
                    next,
                };
            }
        }
    };

    const checkHasOverlappingAndLocked = () => {
        const selectedEndDate = Base.dateAddMinutes(
            selectedStartDate,
            formik.values?.duration
        );

        for (const item of items) {
            const overlapping = Base.isOverlappingDate(
                selectedStartDate,
                selectedEndDate,
                item.startDate,
                item.endDate,
                false,
                "minute"
            );
            const locked = item.isLocked();
            const inProgress = item.isInProgress();
            if (overlapping) {
                setHasOverlapping(true);
                if (locked) {
                    setIsLocked(true);
                    break;
                }
                if (inProgress) {
                    if (Base.isBefore(new Date(), selectedEndDate)) {
                        setOverlappingWithCurrent(true);
                    }
                    break;
                }
            }
        }
    };

    const CustomMessage = ({ color, text }) => {
        return (
            <Grid2 mb={1} px={1} xs={12} container>
                <Grid2 xs={2}>
                    <ErrorOutlineIcon color={color} />
                </Grid2>
                <Grid2 xs={10}>
                    <Typography variant="subtitle2" color={color}>
                        {text}
                    </Typography>
                </Grid2>
            </Grid2>
        );
    };

    useEffect(() => {
        if (!!formik.values.startDate && !!formik.values.timeZoneName) {
            setSelectedStartDate(
                Base.dayjsToJsonDateTimeOffset(
                    Base.dateAtTz(
                        formik.values?.timeZoneName,
                        Base.dayjsToJsonDateTime(formik.values?.startDate)
                    )
                )
            );
        }
    }, [formik.values.startDate, formik.values.timeZoneName]);

    useEffect(() => {
        if (!!selectedStartDate && !!formik.values?.duration) {
            setClosestItems(findClosestItems());
            setHasOverlapping(false);
            setIsLocked(false);
            setOverlappingWithCurrent(false);
            checkHasOverlappingAndLocked();
        }
    }, [selectedStartDate, formik.values?.duration]);

    useEffect(() => {
        if (formik.values.checked) {
            void formik.setFieldValue(
                "startDate",
                Base.dateStrToOriginalTimezoneJsonDateTime(
                    closestItems?.prev.endDate
                )
            );
            void formik.setFieldValue(
                "duration",
                Base.dayjsDiffInMinutes(
                    closestItems?.prev.endDate,
                    closestItems?.next.startDate
                )
            );
            void formik.setFieldValue(
                "timeZoneName",
                closestItems?.prev.timeZoneName
            );
        }
    }, [formik.values.checked]);

    const dialogTitle =
        Translations.AddBreak +
        ": " +
        Base.dayjsToDateStr(formik.values.startDate);

    const primaryActions = [
        <Button
            variant="contained"
            color="success"
            size="large"
            onClick={() => void formik.submitForm()}
            autoFocus
            disabled={
                !formik.isValid ||
                formik.isSubmitting ||
                isLocked ||
                overlappingWithCurrent
            }
        >
            {Translations.Save}
        </Button>,
        <Button onClick={handleCloseDialog}>{Translations.Cancel}</Button>,
    ];

    return (
        <EditDialog
            open={true}
            onClose={handleCloseDialog}
            title={dialogTitle}
            formik={formik}
            maxWidth={"xs"}
            primaryActions={primaryActions}
            body={
                <Grid2 xs={12} container spacing={2}>
                    {hasOverlapping && !isLocked && !overlappingWithCurrent && (
                        <CustomMessage
                            color="primary"
                            text={t("workTime.breakOverridesEntriesWarning")}
                        />
                    )}
                    {isLocked && (
                        <CustomMessage
                            color="error"
                            text={t("workTime.breakNotAddableWhenLocked")}
                        />
                    )}
                    {overlappingWithCurrent && (
                        <CustomMessage
                            color="error"
                            text={t(
                                "workTime.workShiftInProgressCannotBeSaved"
                            )}
                        />
                    )}
                    {!!closestItems && (
                        <Grid2>
                            <FormControlLabel
                                control={<Checkbox />}
                                label={
                                    <Typography
                                        variant="subtitle2"
                                        color={"primary.dark"}
                                        pt={0.5}
                                    >
                                        {t("workTime.insertBetween")} (
                                        {Base.dateStrToOriginalTimezoneTimeStr(
                                            closestItems?.prev.endDate
                                        )}{" "}
                                        -{" "}
                                        {Base.dateStrToOriginalTimezoneTimeStr(
                                            closestItems?.next.startDate
                                        )}
                                        )
                                    </Typography>
                                }
                                checked={formik.values.checked}
                                onChange={() => {
                                    void formik.setFieldValue(
                                        "checked",
                                        !formik.values.checked
                                    );
                                }}
                            />
                        </Grid2>
                    )}
                    <Grid2 xs={12} mt={1}>
                        {breakWorkTimeTypes.length > 1 ? (
                            <MuiSelect
                                fullWidth
                                label={Translations.WorkTimeType}
                                defaultValue={formik.values.workTimeTypeId}
                                options={breakWorkTimeTypes.map((t) => ({
                                    value: t.workTimeTypeId,
                                    label: t.name,
                                }))}
                                onChange={(val) => {
                                    void formik.setFieldValue(
                                        "workTimeTypeId",
                                        val
                                    );
                                }}
                            />
                        ) : (
                            <TextField
                                fullWidth
                                id="outlined-read-only-input"
                                label={Translations.WorkTimeType}
                                defaultValue={breakWorkTimeTypes[0].name}
                                InputProps={{
                                    readOnly: true,
                                    disabled: true,
                                }}
                            />
                        )}
                    </Grid2>
                    <Grid2 xs={12}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="fi"
                        >
                            <DesktopTimePicker
                                disabled={formik.values.checked}
                                label={Translations.StartTime}
                                value={dayjs(formik.values.startDate)}
                                onChange={(val) => {
                                    void formik.setFieldValue(
                                        "startDate",
                                        val
                                            ? Base.dayjsToJsonDateTime(val)
                                            : null
                                    );
                                }}
                                slotProps={{
                                    textField: {
                                        required: true,
                                        fullWidth: true,
                                        ...formikValidationProps(
                                            formik,
                                            "startDate"
                                        ),
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid2>
                    <Grid2 xs={12}>
                        <TextField
                            disabled={formik.values.checked}
                            type="number"
                            fullWidth
                            label={`${Translations.Duration} (${Translations.AbrMins})`}
                            value={formik.values.duration}
                            onChange={(e) => {
                                void formik.setFieldValue(
                                    "duration",
                                    e.target.value
                                );
                            }}
                            sx={{ backgroundColor: "white" }}
                            inputProps={{
                                min: 1,
                                max: 60,
                                required: true,
                            }}
                            {...fieldProps("duration")}
                        />
                    </Grid2>
                    <Grid2 xs={12}>
                        {!allSameTimeZoneName && !formik.values.checked && (
                            <MuiSelect
                                fullWidth
                                label={Translations.TimeZone}
                                value={formik.values.timeZoneName}
                                options={timeZoneOptions?.map((t) => ({
                                    value: t.code,
                                    label: t.title,
                                }))}
                                onChange={(val) => {
                                    void formik.setFieldValue(
                                        "timeZoneName",
                                        val
                                    );
                                }}
                            />
                        )}
                        {formik.values.checked && (
                            <Tooltip
                                title={
                                    timeZoneOptions?.find(
                                        (t) =>
                                            t.code ===
                                            formik.values.timeZoneName
                                    ).title
                                }
                            >
                                <TextField
                                    fullWidth
                                    id="outlined-read-only-input"
                                    label={Translations.TimeZone}
                                    defaultValue={
                                        timeZoneOptions?.find(
                                            (t) =>
                                                t.code ===
                                                formik.values.timeZoneName
                                        ).title
                                    }
                                    InputProps={{
                                        readOnly: true,
                                        disabled: true,
                                    }}
                                />
                            </Tooltip>
                        )}
                    </Grid2>
                </Grid2>
            }
        />
    );
};
