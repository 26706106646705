export interface IWorkOrderAcknowledgementTypeItem {
    id: string;
    number: number;
    name: string;
    sendingType: number;
    sendingTypeStr: string;
}

export class WorkOrderAcknowledgementTypeItem implements IWorkOrderAcknowledgementTypeItem {
    id: string;
    number: number;
    name: string;
    sendingType: number;
    sendingTypeStr: string;

    constructor();
    constructor(obj: IWorkOrderAcknowledgementTypeItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.name = obj && obj.name || "";
        this.sendingType = obj && obj.sendingType || 0;
        this.sendingTypeStr = obj && obj.sendingTypeStr || "";
    }
}