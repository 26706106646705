// WorkOrderDialogRideBooking - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import Datetime from "react-datetime";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import { PropertyDialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { IWorkOrderEditItemRideBooking } from "../../models/work/workOrderEditItemRideBooking";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { IdTitle } from "../../models/common/idTitle";
import { IVehicleItem, VehicleItem } from "../../models/vehicle/vehicleItem";
import { IEmployeeItem } from "../../models/employee/employeeItem";

// WorkOrderDialogRideBookingDialog
// ***********************************************************************************************************************
export interface IWorkOrderDialogRideBookingDialogProp {
    classes?: string;
    isReadOnly: boolean;
    rideBooking: IWorkOrderEditItemRideBooking;
    employees: IEmployeeItem[];
    vehicles: IVehicleItem[];
    onOk: (employeeId: string, date: number, vehicleId: string, amount: number, comment: string) => void;
    onCancel: () => void;
}

interface IWorkOrderDialogRideBookingDialogState {
    employeeId: string;
    dateStr: string;
    amountStr: string;
    vehicleId: string;
    vehicleIdHasBeenChanged: boolean;
    comment: string;
}

export class WorkOrderDialogRideBookingDialog extends React.Component<IWorkOrderDialogRideBookingDialogProp, IWorkOrderDialogRideBookingDialogState> {
    constructor(props: IWorkOrderDialogRideBookingDialogProp) {
        super(props);
        this.state = {
            employeeId: props.rideBooking.employeeId,
            dateStr: Base.utcTimeToDateStr(props.rideBooking.date),
            amountStr: props.rideBooking.amount.toString(10),
            vehicleId: props.rideBooking.vehicleId,
            comment: props.rideBooking.comment,
            vehicleIdHasBeenChanged: false
        };
    }

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        const state = this.state;
        if (name === "amount") {
            this.setState({ amountStr: value });
        } else if (name === "employeeId") {
            let vehicleId = state.vehicleId;
            if (!state.vehicleIdHasBeenChanged && state.employeeId !== value) {
                vehicleId = VehicleItem.getFirstVehicleIdByEmployeeId(this.props.vehicles, value);
            }
            this.setState({ employeeId: value, vehicleId: vehicleId });
        } else if (name === "vehicle") {
            this.setState({ vehicleId: value, vehicleIdHasBeenChanged: state.vehicleIdHasBeenChanged || state.vehicleId !== value });
        } else if (name === "comment") {
            this.setState({ comment: value });
        }
    };

    handleBlur = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "amount") {
            this.setState({ amountStr: value.toInteger().toString(10) });
        }
    };

    handleDateChange = (value: moment.Moment | string) => {
        if (!(typeof value === "string")) {
            this.setState({ dateStr: Base.utcTimeToDateStr(value) });
        }
    };

    handleDateBlur = (value: moment.Moment | string) => {
        if (typeof value === "string") {
            this.setState({ dateStr: Base.utcTimeToDateStr(value.toUtcDate().getTime()) });
        } else {
            this.setState({ dateStr: Base.utcTimeToDateStr(value) });
        }
    };

    private static checkErrors = (state: IWorkOrderDialogRideBookingDialogState): Promise<boolean> => {
        return new Promise<boolean>((resolve) => {
            if (!state.employeeId) {
                store.customStore.dispatch(storeActions.showErrorMessage(Translations.EmployeeMustBeDefined));
                return resolve(false);
            }
            if (!state.vehicleId) {
                store.customStore.dispatch(storeActions.showErrorMessage(Translations.RideBookingVehicleMustBeDefined));
                return resolve(false);
            }
            return resolve(true);
        });
    };

    private static getWarningMessage = (state: IWorkOrderDialogRideBookingDialogState): Promise<string> => {
        return new Promise<string>((resolve) => {
            return resolve("");
        });
    };

    private static validate = (state: IWorkOrderDialogRideBookingDialogState, saveCallback: () => void): Promise<void> => {
        return WorkOrderDialogRideBookingDialog.checkErrors(state).then(success => {
            if (success) {
                return WorkOrderDialogRideBookingDialog.getWarningMessage(state).then(warnings => {
                    if (!warnings) {
                        saveCallback();
                        return new Promise<void>((resolve) => { resolve(); });
                    }
                    return new Promise<void>((resolve) => {
                        store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, warnings + Base.lf + Translations.DoYouReallyWantToSaveData,
                            () => {
                                store.customStore.dispatch(storeActions.clearConfirmation());
                                saveCallback();
                                resolve();
                            },
                            () => {
                                store.customStore.dispatch(storeActions.clearConfirmation());
                                resolve();
                            }, null));
                    });
                });
            } else {
                return new Promise<void>((resolve) => { resolve(); });
            }
        });
    };

    handleOkClick = () => {
        const obj = this;
        const state = this.state;
        WorkOrderDialogRideBookingDialog.validate(state, () => {
            obj.props.onOk(state.employeeId, state.dateStr.toUtcDate().getTime(), state.vehicleId, state.amountStr.toDecimal(), state.comment);
        });
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "hourBooking px600" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.RideBooking + " - " + (!props.rideBooking.isNew() ? Base.utcTimeToDateStr(props.rideBooking.date) : Translations.New)}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-3">
                                <div className="form-group required">
                                    <label className="control-label">{Translations.Date}</label>
                                    <Datetime
                                        locale={appConfig.culture}
                                        className={"roWhite"}
                                        value={state.dateStr}
                                        dateFormat={"D.M.YYYY"}
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        inputProps={{ maxLength: 10, readOnly: props.isReadOnly, disabled: props.isReadOnly }}
                                        onChange={this.handleDateChange}
                                        onClose={this.handleDateBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group required">
                                    <label className="control-label">{Translations.Employee}</label>
                                    <select className="custom-select" name="employeeId" title={Translations.Employee} value={state.employeeId} disabled={props.isReadOnly} onChange={this.handleChange}>
                                        {props.employees.map((employee) =>
                                            <option key={employee.id} value={employee.id}>{employee.name}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="col-5">
                                <div className="form-group required">
                                    <label className="control-label">{Translations.Vehicle}</label>
                                    <select className="custom-select" name="vehicle" title={Translations.Vehicle} value={state.vehicleId} disabled={props.isReadOnly} onChange={this.handleChange}>
                                        {props.vehicles.map((vehicle) =>
                                            <option key={vehicle.id} value={vehicle.id}>{vehicle.getTitle()}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <div className="form-group required">
                                    <label className="control-label">{Translations.AmountKm}</label>
                                    <input type="text" className="form-control" name="amount" title={Translations.AmountKm} value={state.amountStr} readOnly={props.isReadOnly} disabled={props.isReadOnly} onChange={this.handleChange} onBlur={this.handleBlur} maxLength={6} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="control-label">{Translations.Comment}</label>
                                    <textarea className="form-control" name="comment" title={Translations.Comment} value={state.comment} readOnly={props.isReadOnly} disabled={props.isReadOnly} onChange={this.handleChange} maxLength={1000} />
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[{ title: Translations.Save, classes: "btn-primary" + (props.isReadOnly ? " d-none" : ""), enabled: !props.isReadOnly, onClick: this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}

// WorkOrderDialogRideBookingListLine
export interface IWorkOrderDialogRideBookingListLineProp {
    rideBooking: IWorkOrderEditItemRideBooking;
    employees: IEmployeeItem[];
    vehicles: IVehicleItem[];
    selectedId: string;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

export class WorkOrderDialogRideBookingListLine extends React.Component<IWorkOrderDialogRideBookingListLineProp, {}> {
    render() {
        const rideBooking = this.props.rideBooking;
        const employeeName = IdTitle.getTitleById(this.props.employees, rideBooking.employeeId);
        const vehicleRegisterNumber = VehicleItem.getRegisterNumberById(this.props.vehicles, rideBooking.vehicleId);
        const employeeAndVehicle = Base.getStringWithSeparators([employeeName, vehicleRegisterNumber], " / ");
        const amount = rideBooking.amount.toString(10) + " " + Translations.MeasureUnitKm;
        return (
            <div className={"row line" + (rideBooking.id === this.props.selectedId ? " selected" : "")} onClick={() => { this.props.onClick(rideBooking.id); }} onDoubleClick={() => { this.props.onDoubleClick(rideBooking.id); }}
                title={Base.getStringWithSeparators([Base.utcTimeToDateStr(rideBooking.date) + " " + employeeAndVehicle, amount, rideBooking.comment], ", ")}
            >
                <div className="col-2">{Translations.RideBooking}</div>
                <div className="col-7">{Base.utcTimeToDateStr(rideBooking.date) + " " + employeeAndVehicle}</div>
                <div className="col-3 text-right">{amount}</div>
            </div>
        );
    }
}
