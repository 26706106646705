import { IdTitle } from "../common/idTitle";

export interface IGetReportResponse {
    reportTemplateId: string;
    name: string;
    components: IComponentFilterDefination[];
}


export class GetReportResponse implements IGetReportResponse {
    reportTemplateId: string;
    name: string;
    components: IComponentFilterDefination[];

    constructor();
    constructor(obj: IGetReportResponse);
    constructor(obj?: any) {
        if (obj) {
            this.reportTemplateId = obj.reportTemplateId;
            this.name = obj.name;
            this.components = JSON.parse(JSON.stringify(obj.components));//clone
        }
    }
}

export enum ComponentType {
    ComboBox,
    Text,
    CheckBox,
    MultiSelecBox,
    Period,
    Empty,
    OwnerId
}

export enum ReportingComponentType {
    Employee = "Employee",
    EmployeeGroup = "EmployeeGroup",
    ShowCustomers = "ShowCustomers",
    ShowOrders = "ShowOrders",
    ShowCostCenters = "ShowCostCenters",
    Period = "Period",
    CostCenter = "CostCenter",
    TimePeriod = "TimePeriod",
    Empty = "Empty",
    SalaryTypes = "SalaryTypes",
    Customers = "Customers",
    OwnerId = "OwnerId"
}

export interface IComponentFilterDefination {
    label: string;
    componentType: ComponentType;
    componentItems: IdTitle[];
    componentTypeName: ReportingComponentType;

    grid: number;
    isRequired: boolean;
}

export class ComponentFilterDefination implements IComponentFilterDefination {
    label = "";
    componentType = ComponentType.Text;
    componentItems = [];
    componentTypeName = ReportingComponentType.Empty;

    grid = 0;
    isRequired = false;

    constructor();
    constructor(obj: IComponentFilterDefination);
    constructor(obj?: any) {
        if (obj) {
            this.label = obj.label;
            this.componentType = obj.componentType;
            this.componentItems = obj.componentItems;
            this.grid = obj.grid;
            this.isRequired = obj.isRequired;
        }
    }
}
