import { EditDialog, EditDialogProps, ExposedFunctions } from "../../framework/editDialog";
import { CustomerPriceEditItem, ProductEditItem, StorageProductEditItem } from "../../../models/storage/storageProduct";
import { FormikForm, defaultFormikConfig, formatString, formikFieldProps } from "../../framework/formUtils";
import { Translations } from "../../../models/translations";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
    Button,
    Checkbox,
    FormControlLabel,
    MenuItem,
    TextField,
    InputAdornment, IconButton, Alert, Divider
} from "@mui/material";
import { STYLE_CONSTANTS } from "../../../framework/theme";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { FormikProps, getIn, useFormik } from "formik";
import * as yup from "yup";
import { getProductGroupItems } from "../../../services/productGroupService";
import { getStorageIdTitles, getStorageLocationOptions } from "../../../services/storageService";
import { ProductGroupItems } from "../../../models/productGroup/productGroupItems";
import * as store from "../../../framework/customStore";
import * as StoreActions from "../../../models/store/storeActions";
import { IApiError } from "../../../services/baseService";
import * as baseService from "../../../services/baseService";
import { saveStorageProduct } from "../../../services/storageService";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { MainGroupType } from "../../settings/ProductGroupCommon";
import { AutocompleteOption, AutocompleteWrapper } from "../../framework/muiAutocomplete";
import { getCustomerIdTitles } from "../../../services/customerService";
import { showError } from "../storageUtils";
import { Units } from "../../../services/units";
import { TextFieldWrapper } from "../../framework/TextFieldWrapper";

interface Props extends EditDialogProps<ProductEditItem> {
    productMainGroup: MainGroupType;
}

export const StorageProductDialog = ({ item, open, productMainGroup, onClose, onSave }: Props) => {
    const dialogRef = useRef<ExposedFunctions>();

    const handleSubmit = (formData: ProductEditItem) => {
        const onSubmitSuccess = (item: ProductEditItem) => {
            formik.setSubmitting(false);
            formik.setValues(item);
            dialogRef.current.setInitialFormDataFromForm();
            store.customStore.dispatch(StoreActions.showSuccessMessage(Translations.SaveSuccess));

            if (onSave) {
                onSave(dialogRef.current.getInitialFormData() as ProductEditItem);
            }
        };

        const onSubmitFailure = (error: IApiError) => {
            store.customStore.dispatch(StoreActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
            formik.setSubmitting(false);
        };

        saveStorageProduct(formData)
            .then(onSubmitSuccess)
            .catch(onSubmitFailure);
    };

    const formik = useFormik<ProductEditItem>({
        ...defaultFormikConfig,
        initialValues: item ?? new ProductEditItem(),
        validationSchema: yup.object({
            productGroupId: yup.string().nullable()
                .required(formatString(Translations.FieldIsRequired, Translations.ProductGroup)),
            name: yup.string()
                .required(formatString(Translations.FieldIsRequired, Translations.ProductName))
                .max(50, formatString(Translations.FieldMaxLengthError, Translations.ProductName.toLowerCase(), 50)),
            description: yup.string()
                .max(500, formatString(Translations.FieldMaxLengthError, Translations.ProductDescription.toLowerCase())),
            comment: yup.string()
                .max(500, formatString(Translations.FieldMaxLengthError, Translations.Comment.toLowerCase())),
            activeState: yup.boolean(),
            unitPrice: yup.number()
                .min(0, Translations.InvalidAmountError)
                .typeError(Translations.EnterPositiveNumber),
            storageProducts: yup.array(
                yup.object({
                    storageLocationId: yup.string().nullable()
                        .required(formatString(Translations.FieldIsRequired, Translations.StorageLocation)),
                    freeAmount: yup.number()
                        .required(Translations.InvalidAmountError)
                        .min(0, Translations.InvalidAmountError)
                        .typeError(Translations.InvalidValue),
                })
            ),
            customerPrices: yup.array(
                yup.object({
                    customerId: yup.string().nullable()
                        .required(formatString(Translations.FieldIsRequired, Translations.Customer)),
                    unitPrice: yup.number()
                        .required(Translations.InvalidPriceError)
                        .min(0, Translations.InvalidPriceError)
                        .typeError(Translations.InvalidValue),
                })
            ),
        }),
        onSubmit: handleSubmit,
    });

    const primaryActions = [
        <Button variant="save" disabled={formik.isSubmitting} onClick={() => void formik.submitForm()} key="btn-save">
            {Translations.Save}
        </Button>,
    ];

    return (
        <EditDialog
            open={open}
            title={item.productId === null ? Translations.CreateNewProduct : Translations.EditProduct}
            formik={formik}
            onClose={onClose}
            onRemove={onClose}
            primaryActions={primaryActions}
            tabs={[
                Translations.General,
                Translations.CustomerPrices,
            ]}
            ref={dialogRef}
            body={[
                <GeneralTab formik={formik} productMainGroup={productMainGroup} key="general-tab"/>,
                <CustomerPriceTab formik={formik} key="customer-price-tab"/>
            ]}
        />
    );
};

interface GeneralTabProps {
    formik: FormikForm<ProductEditItem>;
    productMainGroup: MainGroupType;
}

const GeneralTab = ({ formik, productMainGroup }: GeneralTabProps) => {
    const [productGroups, setProductGroups] = useState<ProductGroupItems>(new ProductGroupItems());
    const [storageLocations, setStorageLocations] = useState<AutocompleteOption[]>([]);
    const [storages, setStorages] = useState<AutocompleteOption[]>([]);

    useEffect(() => {
        void getProductGroupItems(null, null, null, "code", true, productMainGroup)
            .then((data) => {
                setProductGroups(data);
            });

        void getStorageLocationOptions().then(setStorageLocations);

        void getStorageIdTitles().then(setStorages);
    }, []);

    const handleRemoveStorageProduct = (index: number, id: string) => {
        formik.setFieldValue("storageProducts", formik.values.storageProducts.filter((_, i) => i !== index), false);

        if (id) {
            let removedIds = formik.values.removedStorageProductIds;
            if (!removedIds) {
                removedIds = [];
            }
            removedIds.push(id);
            formik.setFieldValue("removedStorageProductIds", removedIds, false);
        }
    };

    const handleAddStorageProduct = () => {
        const storageProduct = new StorageProductEditItem();
        const storageProducts = [...formik.values.storageProducts, storageProduct];
        formik.setFieldValue("storageProducts", storageProducts, false);
    };

    return (
        <Grid2 container direction="column" spacing={STYLE_CONSTANTS.formSpacing} sx={{ width: { xs: "100%", md: "auto" } }}>
            <Grid2 container>
                <Grid2>
                    <TextField
                        label={Translations.ProductNumber}
                        className="field-width-small"
                        disabled
                        type="number"
                        variant="standard"
                        value={formik.values.productNumber}
                    />
                </Grid2>
                <Grid2>
                    <TextField
                        label={Translations.ProductGroup}
                        className="field-width-big"
                        {...formikFieldProps(formik, "productGroupId")}
                        select
                        required
                    >
                        {productGroups.items.map(group => (
                            <MenuItem key={group.code} value={group.id}>{group.code + " " + group.name}</MenuItem>
                        ))}
                    </TextField>
                </Grid2>
            </Grid2>
            <Grid2 container>
                <Grid2>
                    <TextFieldWrapper
                        name="name"
                        fieldProps={{
                            label: Translations.ProductName,
                            required: true,
                            className: "field-width-big",
                        }}
                        formik={formik}
                    />
                </Grid2>
                <Grid2>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formik.values.activeState === 1}
                                onChange={() => formik.setFieldValue("activeState", formik.values.activeState === 0 ? 1 : 0)}
                            />
                        }
                        label={Translations.InUse}
                    />
                </Grid2>
            </Grid2>
            <Grid2>
                <TextFieldWrapper
                    name="description"
                    fieldProps={{
                        label: Translations.ProductDescription,
                        multiline: true,
                        className: "field-width-big",
                    }}
                    formik={formik}
                />
            </Grid2>
            <Grid2>
                <TextFieldWrapper
                    name="comment"
                    fieldProps={{
                        label: Translations.Comment,
                        className: "field-width-big",
                    }}
                    formik={formik}
                />
            </Grid2>
            <Grid2 container>
                <Grid2>
                    <TextFieldWrapper
                        name="erpReference"
                        fieldProps={{
                            label: Translations.ErpReference,
                        }}
                        formik={formik}
                    />
                </Grid2>
                <Grid2>
                    <TextField
                        label={Translations.MeasureUnit}
                        className="field-width-normal"
                        value={formik.values.unit}
                        onChange={formik.handleChange}
                        name="unit"
                        select
                    >
                        {Units.map((unit, i) => (
                            <MenuItem value={i} key={i}>
                                {unit}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid2>
                <Grid2>
                    <TextFieldWrapper
                        name="unitPrice"
                        fieldProps={{
                            label: Translations.UnitPrice,
                            className: "field-width-small",
                            InputProps: {
                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                            }
                        }}
                        formik={formik}
                    />
                </Grid2>
            </Grid2>

            {productMainGroup === MainGroupType.Storage &&
                <>
                    <Divider className="my-3"/>

                    {formik.values.storageProducts.map((storageProduct, i) => (
                        <Grid2 container direction="row" spacing={STYLE_CONSTANTS.formSpacing} key={i}>
                            <Grid2>
                                <AutocompleteWrapper
                                    label={Translations.Storage}
                                    options={storages}
                                    value={storageProduct.storageId}
                                    onChange={(value: AutocompleteOption) => {
                                        formik.setFieldValue(`storageProducts[${i}].storageId`, value?.id);
                                        if (storageProduct.storageLocationId) {
                                            formik.setFieldValue(`storageProducts[${i}].storageLocationId`, null, false);
                                        }
                                    }}
                                    textFieldProps={{
                                        name: `storageProducts[${i}].storageId`,
                                        value: storageProduct.storageId,
                                        onChange: formik.handleChange,
                                        onBlur: formik.handleBlur,
                                        error: Boolean(getIn(formik.errors.storageProducts?.[i], "storageId")),
                                        helperText: formik.touched.storageProducts?.[i]?.storageId && getIn(formik.errors.storageProducts?.[i], "storageId"),
                                    }}
                                />
                            </Grid2>
                            <Grid2>
                                <AutocompleteWrapper
                                    label={Translations.StorageLocation}
                                    options={
                                        storageProduct.storageId
                                            ? storageLocations.filter(sl => sl.extra === storageProduct.storageId)
                                            : storageLocations
                                    }
                                    required
                                    value={storageProduct.storageLocationId}
                                    onChange={(value: AutocompleteOption) => {
                                        formik.setFieldValue(`storageProducts[${i}].storageLocationId`, value?.id);
                                        if (!storageProduct.storageId) {
                                            formik.setFieldValue(`storageProducts[${i}].storageId`, value?.extra);
                                        }
                                    }}
                                    textFieldProps={{
                                        name: `storageProducts[${i}].storageLocationId`,
                                        value: storageProduct.storageLocationId,
                                        onChange: formik.handleChange,
                                        onBlur: formik.handleBlur,
                                        error: Boolean(getIn(formik.errors.storageProducts?.[i], "storageLocationId")),
                                        helperText: formik.touched.storageProducts?.[i]?.storageLocationId && getIn(formik.errors.storageProducts?.[i], "storageLocationId"),
                                    }}
                                />
                            </Grid2>
                            <Grid2>
                                <TextField
                                    label={Translations.FreeAmount}
                                    type="number"
                                    required
                                    className="field-width-small"
                                    name={`storageProducts[${i}].freeAmount`}
                                    value={storageProduct.freeAmount}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={Boolean(getIn(formik.errors.storageProducts?.[i], "freeAmount"))}
                                    helperText={formik.touched.storageProducts?.[i]?.freeAmount && getIn(formik.errors.storageProducts?.[i], "freeAmount")}
                                    InputProps={{
                                        endAdornment: <InputAdornment
                                            position="end"
                                                      >{Units[formik.values.unit]}
                                        </InputAdornment>
                                    }}
                                />
                            </Grid2>
                            <Grid2>
                                <TextField
                                    label={Translations.BookedAmount}
                                    type="number"
                                    className="field-width-small"
                                    value={storageProduct.bookedAmount}
                                    variant="standard"
                                    disabled
                                    InputProps={{
                                        endAdornment: <InputAdornment
                                            position="end"
                                                      >{Units[formik.values.unit]}
                                        </InputAdornment>
                                    }}
                                />
                            </Grid2>
                            <Grid2>
                                <IconButton onClick={() => handleRemoveStorageProduct(i, storageProduct.id)}>
                                    <DeleteIcon color="error"/>
                                </IconButton>
                            </Grid2>
                        </Grid2>
                    ))}
                    <Grid2 mt={1}>
                        <Button color="primary" startIcon={<AddIcon/>} onClick={() => handleAddStorageProduct()}>
                            {Translations.NewStorageLocationRow}
                        </Button>
                    </Grid2>
                </>
            }
        </Grid2>
    );
};

interface CustomerPriceTabProps {
    formik: FormikProps<ProductEditItem>;
}

const CustomerPriceTab = ({ formik }: CustomerPriceTabProps) => {
    const [customers, setCustomers] = useState<AutocompleteOption[]>([]);

    useEffect(() => {
        void getCustomerIdTitles()
            .then((res) => {
                setCustomers(res.items);
            });
    }, []);

    const removeCustomerPrice = (index: number, id?: string) => {
        formik.setFieldValue("customerPrices", formik.values.customerPrices.filter((_, i) => i !== index), false);

        if (id) {
            let removedIds = formik.values.removedCustomerPriceIds;
            if (!removedIds) {
                removedIds = [];
            }
            removedIds.push(id);
            formik.setFieldValue("removedCustomerPriceIds", removedIds, false);
        }
    };

    const addCustomerPrice = () => {
        formik.setFieldValue("customerPrices", [...formik.values.customerPrices, new CustomerPriceEditItem()], false);
    };

    const handleCustomerChange = (index: number, value: AutocompleteOption) => {
        if (value?.id && formik.values.customerPrices.some(customerPrice => customerPrice.customerId === value.id)) {
            showError(Translations.CustomerPriceAlreadyExists);
            return;
        }

        formik.setFieldValue(`customerPrices[${index}].customerId`, value?.id);
    };

    return (
        <Grid2 container direction="column" spacing={STYLE_CONSTANTS.formSpacing}
            sx={{ width: { xs: "100%", md: "auto" } }}
        >
            {formik.values.customerPrices.length === 0 &&
                <Alert severity="info">{Translations.NoCustomerPrices}</Alert>
            }

            {formik.values.customerPrices.map((customerPrice, i) => (
                <Grid2 container direction="row" key={i}>
                    <Grid2>
                        <AutocompleteWrapper
                            options={customers}
                            label={Translations.Customer}
                            required
                            value={customerPrice.customerId}
                            onChange={(value: AutocompleteOption) => handleCustomerChange(i, value)}
                            textFieldProps={{
                                name: `customerPrices[${i}].customerId`,
                                value: customerPrice.customerId,
                                onChange: formik.handleChange,
                                onBlur: formik.handleBlur,
                                error: Boolean(getIn(formik.errors.customerPrices?.[i], "customerId")),
                                helperText: formik.touched.customerPrices?.[i]?.customerId && getIn(formik.errors.customerPrices?.[i], "customerId"),
                                className: "field-width-big",
                            }}
                        />
                    </Grid2>

                    <Grid2>
                        <TextField
                            label={Translations.UnitPrice}
                            className="field-width-small"
                            name={`customerPrices[${i}].unitPrice`}
                            value={customerPrice.unitPrice}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(getIn(formik.errors.customerPrices?.[i], "unitPrice"))}
                            helperText={getIn(formik.touched.customerPrices?.[i], "unitPrice") && getIn(formik.errors.customerPrices?.[i], "unitPrice")}
                            InputProps={{
                                endAdornment: customerPrice ?
                                    <InputAdornment position="end">€</InputAdornment> : undefined
                            }}
                        />
                    </Grid2>

                    <Grid2>
                        <IconButton className="mt-1" onClick={() => removeCustomerPrice(i, customerPrice.id)}>
                            <DeleteIcon color="error"/>
                        </IconButton>
                    </Grid2>
                </Grid2>
            ))}

            <Grid2 mt={1}>
                <Button color="primary" startIcon={<AddIcon/>} onClick={addCustomerPrice}>
                    {Translations.Add}
                </Button>
            </Grid2>
        </Grid2>
    );
};
