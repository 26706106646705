import { PayloadAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { TransportPlanTemplateListItemDto } from "../../models/transport/transportPlanTemplate";
import { TransportPlanListItemDto, TransportPlanListQueryParameters } from "../../models/transport/transportPlan";
import { fetchTransportPlans } from "./transportVehiclesSlice";
import { showApiError } from "../../components/framework/formUtils";
import { RootState } from "../../framework/customStore";
import * as transportPlanTemplateService from "../../services/transportPlanTemplateService";

interface TransportPlanTemplatesState {
    transportPlanTemplates: TransportPlanTemplateListItemDto[];
}

const initialState: TransportPlanTemplatesState = {
    transportPlanTemplates: []
};

export const fetchTransportPlansAndTemplates = createAsyncThunk(
    "transportPlanTemplates/getTransportPlansAndTemplates",
    async(args: TransportPlanListQueryParameters, thunkApi) => {
        try {
            await thunkApi.dispatch(fetchTransportPlans(args));
            const planTemplates = await transportPlanTemplateService.getTransportPlanTemplates();
            return planTemplates.data;
        } catch {
            showApiError("Toistuvien suunnitelmien lataus epäonnistui");
        }
    }
);

export const transportPlanTemplatesSlice = createSlice({
    name: "transportPlanTemplates",
    initialState,
    reducers: {
        setTransportPlanTemplates(state, action: PayloadAction<TransportPlanTemplateListItemDto[]>) {
            state.transportPlanTemplates = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTransportPlansAndTemplates.fulfilled, (state, action) => {
            if(action?.payload) state.transportPlanTemplates = action.payload;
        });
    }
});

export const {
    setTransportPlanTemplates
} = transportPlanTemplatesSlice.actions;

export const transportPlanTemplatesReducer = transportPlanTemplatesSlice.reducer;

export interface RepeatingTransportPlan extends TransportPlanTemplateListItemDto {
    plans: TransportPlanListItemDto[];
}

export interface RepeatingAndNonRepeatingPlans {
    repeatingPlans: RepeatingTransportPlan[];
    nonRepeatingPlans: TransportPlanListItemDto[];
}

export const selectTimelinePlans = createSelector(
    [
        (state: RootState) => state.transportVehicles.plans,
        (state: RootState) => state.transportPlanTemplates.transportPlanTemplates,
    ],
    (plansSelector, transportPlanTemplates): RepeatingAndNonRepeatingPlans => {
        const plansWithoutTemplate = Object.values(plansSelector).filter(p => !p?.transportPlanTemplateId);
        const plansWithTemplate = Object.values(plansSelector).filter(plan => plan?.transportPlanTemplateId);
        const repeatingPlans = transportPlanTemplates.map((template) => {
            return {
                ...template,
                plans: plansWithTemplate
                    .filter(plan => plan.transportPlanTemplateId === template.id)
                    .sort((a, b) => a?.scheduledStartDateTime > b?.scheduledStartDateTime ? 1 : -1)
            };
        });
        return {
            repeatingPlans: repeatingPlans,
            nonRepeatingPlans: plansWithoutTemplate
        };
    }
);
