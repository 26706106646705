// WorkNoBox
// ***********************************************************************************************************************
import * as React from "react";

export interface IWorkNoBoxProp {
    classes?: string;
    id: string;
    state: number;
    number: number;
    parentNumber: number;
    stopPropagation?: boolean;
    onClick: (id: string) => void;
}

export class WorkNoBox extends React.Component<IWorkNoBoxProp, {}> {
    handleClick = (e) => {
        const props = this.props;
        if (!props.onClick) return;
        if (props.stopPropagation) {
            e.preventDefault();
            e.stopPropagation();
        }
        props.onClick(props.id);
    };

    render() {
        const props = this.props;
        return (
            <div className={"workNo state" + props.state + (props.classes ? " " + props.classes : "")}><span className="link" onClick={this.handleClick}>{props.number.toString(10) + (props.parentNumber ? " (" + props.parentNumber.toString(10) + ")" : "")}</span></div>
        );
    }
}