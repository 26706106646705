import { Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { Translations } from "../../../models/translations";

interface Props {
    value: string;
    onChange: (val: string) => void;
}

export const StorageSearchField = (props: Props) => {
    return (
        <TextField
            value={props.value}
            onChange={(evt) => props.onChange(evt.target.value)}
            placeholder={Translations.SearchCondition}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                ),
            }}
            size="small"
        />
    );
};
