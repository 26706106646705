// SalaryMain - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { ISalaryRowTypeItem } from "../../models/salary/salaryRowTypeItem";
import { SalaryEdit } from "./salaryEdit";
import { ISalaryWorkTimePeriod } from "../../models/salary/salaryWorkTimePeriod";
import { IEmployeeItem } from "../../models/employee/employeeItem";
import { IEmployeeGroupItem } from "../../models/employeeGroup/employeeGroupItem";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import { Translations } from "../../models/translations";

interface ISalaryMainProps {
    employeeGroup: IEmployeeGroupItem;
    employees: IEmployeeItem[];
    salaryPeriod: ISalaryWorkTimePeriod;
    salaryRowTypes: ISalaryRowTypeItem[]; //USE THIS
    salaryTransferEnabled: boolean;
    onChangeEmployee: (employeeId: string) => void;
    onChangeSalaryPeriod: (salaryPeriodId: string) => void;
}

export class SalaryMain extends React.Component<ISalaryMainProps, {}> {
    private salaryEditRef = React.createRef<SalaryEdit>();

    cancelSalary = (proceedCallback: () => void) => {
        if (this.salaryEditRef && this.salaryEditRef.current) {
            this.salaryEditRef.current.cancelSalary(proceedCallback);
        } else {
            proceedCallback();
        }
    };

    refreshView = () => {
        if (this.salaryEditRef && this.salaryEditRef.current) {
            this.salaryEditRef.current.refreshView();
        }
    };

    componentDidUpdate() {
        if (!this.props.salaryPeriod) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.SalaryPeriodMissing));
        }
    }

    render() {
        const props = this.props;

        if (!props.salaryPeriod) {
            return;
        }
        return (
            <div>
                <div className="row salaryMain">
                    <div className="col-12">
                        <div className="backgroundBoard">
                            <div className="backgroundContent">
                                <SalaryEdit
                                    ref={this.salaryEditRef}
                                    employeeGroup={props.employeeGroup}
                                    employees={props.employees}
                                    salaryPeriod={props.salaryPeriod}
                                    salaryRowTypes={props.salaryRowTypes}
                                    salaryTransferEnabled={props.salaryTransferEnabled}
                                    onChangeEmployee={props.onChangeEmployee}
                                    onChangeSalaryPeriod={props.onChangeSalaryPeriod}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
