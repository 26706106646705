import React, { useEffect } from "react";
import {
    Box,
    Button,
    Chip,
    IconButton,
    Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { MainLayout } from "../../layout/mainLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { customHistory } from "../../../framework/customHistory";
import { Translations } from "../../../models/translations";
import { RootState, useAppDispatch, useAppSelector } from "../../../framework/customStore";
import { fetchTransportOrderInvoice, selectEditEnabled, setSelectedInvoice } from "../../../store/transportOrderInvoiceSlice";
import { TransportOrderInvoiceNew } from "./TransportOrderInvoiceNew";
import { TransportOrderInvoiceEdit } from "./TransportOrderInvoiceEdit";
import { LoadingIndicator } from "../../framework/loadingIndicator";
import { TransportOrderInvoiceState, transportOrderInvoiceStates } from "../../../models/transportOrderInvoice/transportOrderInvoice";
import { showApiError, showConfirm } from "../../framework/formUtils";
import { approveInvoice, removeInvoice } from "../../../services/newInvoiceService";
import { showSuccessMessage } from "../../../models/store/storeActions";

export const TransportOrderInvoiceContainer = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useAppDispatch();
    const invoiceDetails = useAppSelector((state: RootState) => state.transportOrderInvoice.selectedInvoice);
    const editEnabled = useAppSelector(selectEditEnabled);
    const currentState = transportOrderInvoiceStates[invoiceDetails?.state];
    const backPath = (customHistory.location.state as any)?.from || "/invoicingbeta/transportorders";

    useEffect(() => {
        if (id !== "new") {
            dispatch(fetchTransportOrderInvoice(id) as any);
        }
        // Clear selectedInvoice on dismount
        return () => {
            dispatch(setSelectedInvoice(null));
        };
    }, [id, dispatch]);

    if (id !== "new" && !invoiceDetails) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
            >
                <LoadingIndicator />
            </Box>
        );
    }

    const handleApproveInvoice = () => {

        approveInvoice(id, TransportOrderInvoiceState.Approved)
            .then(() => {
                dispatch(fetchTransportOrderInvoice(id) as any);
            })
            .catch((error) => {
                showApiError(error.message);
            });
    };

    const handleRemoveInvoice = (id: string) => {
        const submitRemove = () => {
            removeInvoice(id)
                .then(() => {
                    dispatch(showSuccessMessage(Translations.DeleteWasSuccess));
                    customHistory.push(
                        "/invoicingbeta/transportorderinvoices",
                        { from: "/invoicingbeta/transportorderinvoice/new" }
                    );
                })
                .catch(showApiError);
        };
        showConfirm(Translations.AreYouSureWantDelete, submitRemove);
    };

    return (
        <MainLayout>
            <>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <IconButton
                        style={{ backgroundColor: "transparent" }}
                        onClick={() => customHistory.push(backPath, { from: `/invoicingbeta/transportorderinvoice/${id}` })}
                    >
                        <ArrowBackIcon color="primary" />
                    </IconButton>
                    {editEnabled && id !== "new" &&
                        <div>
                            <Button
                                onClick={handleApproveInvoice}
                                variant="contained"
                                color="success"
                                disabled={!invoiceDetails?.transportOrderRows}
                            >
                                {Translations.ApproveInvoice}
                            </Button>
                            <Button
                                onClick={() => handleRemoveInvoice(id)}
                                variant="contained"
                                color="error"
                                style={{ marginLeft: "15px" }}
                                disabled={invoiceDetails.state !== TransportOrderInvoiceState.NotApproved}
                            >
                                {Translations.RemoveInvoice}
                            </Button>
                        </div>
                    }
                    {
                        !editEnabled && currentState &&
                        <Chip
                            label={currentState?.name}
                            style={{ color: "white", backgroundColor: currentState.color }}
                        />
                    }
                </div>
                <div style={{ maxWidth: "1700px", margin: "0 auto" }}>
                    <div style={{ margin: 32 }}>
                        <Typography variant="h6" marginBottom="20">{id === "new" ? Translations.CreateNewInvoice : Translations.EditInvoice}</Typography>
                    </div>
                    <div style={{ marginLeft: 32, marginBottom: 10 }}>
                        <Typography variant="h4" color="success.main">{Translations.InvoiceInformation}</Typography>
                    </div>
                    {id === "new" ?
                        <TransportOrderInvoiceNew />
                        :
                        <TransportOrderInvoiceEdit
                            id={id}
                        />}
                </div>
            </>
        </MainLayout>
    );
};
