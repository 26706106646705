import { Logout, Policy, Settings, Work } from "@mui/icons-material";
import { Avatar, Box, Button, Divider, Link, ListItemIcon, ListItemText, Menu, MenuItem, Slide, Tab, Tabs, Toolbar } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import * as React from "react";
import { memo, SyntheticEvent, useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { Base } from "../../framework/base";
import { customHistory } from "../../framework/customHistory";
import { IEmployeeEdit } from "../../models/employee/employeeEdit";
import { IEmployeeParameters } from "../../models/employee/employeeIParameters";
import { OwnerParameters } from "../../models/owner/ownerParameters";
import { Session } from "../../models/session/session";
import { ISessionState } from "../../models/session/sessionState";
import { Translations } from "../../models/translations";
import * as employeeService from "../../services/employeeService";
import { OwnerSettingsDialog } from "../owner/ownerSettingsDialog";
import { OwnSettingsDialog } from "../user/ownSettingsDialog";
import i18n from "i18next";
import { Link as ReactLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Page {
    index: number;
    title: string;
    path: string;
    exact?: boolean;
    isVisible?: () => boolean;
    children?: Page[];
    target?: string;
}

export interface Props {
    setEmployeeParameters: (parameters: IEmployeeParameters, saveToDb: boolean) => void;
    onLogout: () => void;
    showMenu?: boolean;
}

export const Header = memo(({ setEmployeeParameters, onLogout }: Props) => {
    const [sessionState, setSessionState] = useState<ISessionState>();
    const [userNames, setUserNames] = useState<string[]>([]);
    const [pages, setPages] = useState<Page[]>();
    const [hasPrivacyPolicy, setHasPrivacyPolicy] = useState<boolean>(false);
    const [employeeEdit, setEmployeeEdit] = useState<IEmployeeEdit>();
    const [showOwnSettingsDialog, setShowOwnSettingsDialog] = useState<boolean>(false);
    const [showOwnerSettingsDialog, setShowOwnerSettingsDialog] = useState<boolean>(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
    const [activePage, setActivePage] = useState<Page | null>();
    const [hoveringPage, setHoveringPage] = useState<Page>();
    const [activeChildPage, setActiveChildPage] = useState<Page>();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const location = useLocation();
    const { t } = useTranslation();

    // Set state after fetching session to avoid UI popping issues
    const getInitialStateFromSessionState = () => {

        Session.getSessionState().then((sessionState) => {

            if (!sessionState) {
                return;
            }

            const ownerParameters = new OwnerParameters();

            const pages: Page[] = [
                { index: 0, title: t("monitor"), path: "/monitor", isVisible: () => ownerParameters.getOptionMonitorViewEnabled() },
                { index: 1, title: Translations.WorkCalendarTitle, path: "/work", isVisible: () => ownerParameters.getOwnerHasWorkOrdersEnabled() },
                {
                    index: 2, title: Translations.MenuStorage, path: "/storage", isVisible: () => ownerParameters.getOwnerHasStorageEnabled(),
                    children: [
                        { index: 0, title: Translations.StorageProducts, path: "/storage/product" },
                        { index: 1, title: Translations.Storages, path: "/storage/storage" },
                        { index: 2, title: Translations.StorageLocations, path: "/storage/location" },
                    ],
                },
                {
                    index: 3, title: Translations.WorkTimeTitle, path: "/worktimepage",
                    children: [
                        {
                            index: 0,
                            title: Translations.WorkTimeTitle,
                            path: "/worktimepage",
                            exact: true

                        },
                        {
                            index: 1,
                            title: Translations.WorkTimeDetailsHeaderLabel,
                            path: "/worktimepage/details",
                        },
                        {
                            index: 2,
                            title: Translations.Salaries,
                            path: "/worktimepage/salaries",
                        },
                        {
                            index: 3,
                            title: i18n.t("CALCULATIONSETTINGS"),
                            path: "/worktimepage/calculation",
                            isVisible: () => ownerParameters.getOptionIsFunctionsAreInUse()
                        }
                    ]
                },
                { index: 4, title: Translations.WorkListTitle, path: "/invoicing/workpage", isVisible: () => ownerParameters.getOwnerHasWorkOrdersEnabled() },
                {
                    index: 5, title: Translations.InvoicingBetaTitle, path: "/invoicingbeta", isVisible: () => ownerParameters.getOwnerHasTransportInvoicingEnabled(),
                    children: [
                        { index: 0, title: Translations.TransportOrdersInvoices, path: "/invoicingbeta/transportorders" },
                        { index: 1, title: Translations.Invoices, path: "/invoicingbeta/transportorderinvoices" },
                    ]
                },
                { index: 6, title: Translations.Maintenance, path: "/settings" },
                { index: 7, title: Translations.Tracking, path: "/tracking" },
                { index: 8, title: Translations.Telematics, path: "/telematics", isVisible: () => sessionState.ssoIntegrations.length > 0 },
                {
                    index: 9, title: "Kuljetukset", path: "/transport", isVisible: () => ownerParameters.getOwnerHasTransportEnabled(),
                    children: [
                        { index: 0, title: "Kalusto", path: "/transport/vehicles" },
                        { index: 1, title: "Kuljetussuunnitelmat", path: "/transport/plans/grid" },
                        { index: 2, title: "Kuljettajat", path: "/transport/employees" },
                        { index: 3, title: "Kuljetustilaukset", path: "/transport/orders" },
                    ]
                },
                {
                    index: 10,
                    title: Translations.Reporting,
                    path: "/reporting",
                    isVisible: () => ownerParameters.getOwnerHasReportingEnabled(),
                    children: [
                        {
                            index: 0,
                            title: i18n.t("reporting.worktime"),
                            path: "/reporting/worktime",
                        },
                        {
                            index: 1,
                            title: i18n.t("reporting.fleet"),
                            path: "/reporting/vehicles",
                            isVisible: () =>
                                ownerParameters.getOptionWorkTimeVehiclesEnabled(),
                        },
                        {
                            index: 2,
                            title: i18n.t("reporting.employees"),
                            path: "/reporting/employees",
                            isVisible: () =>
                                ownerParameters.getOptionWorkTimeEmployeeVehiclesEnabled(),
                        },
                        {
                            index: 3,
                            title: i18n.t("reporting.groupableWorkTime"),
                            path: "/reporting/groupable",
                            isVisible: () =>
                                ownerParameters.getOptionWorkTimeGroupableReportEnabled(),
                        },
                    ],
                },
                {
                    index: 11,
                    title: t("fuusorReporting"),
                    path: "https://app.fuusor.fi",
                    target: "_blank",
                    isVisible: () => ownerParameters.getOptionFuusorReportingEnabled(),
                },
            ];

            setPages(pages);
            setSessionState(sessionState);
            setUserNames(sessionState.fullName.trim().split(" "));
            setHasPrivacyPolicy(!!ownerParameters.getPrivacyPolicy());
        }).catch(err => console.error(err));
    };

    useEffect(() => {
        if (location.pathname === "/login") {
            setIsVisible(false);
            return;
        } else if (!isVisible) {
            getInitialStateFromSessionState();
            setIsVisible(true);
        }

        if (!pages) {
            return;
        }

        const activePage = pages.find(({ path, exact }) => matchPath(location.pathname, { path, exact })) ?? null;

        setActivePage(activePage);

        if (activePage?.children) {
            setActiveChildPage(activePage.children.find(({ path, exact }) => matchPath(location.pathname, { path, exact })) ?? null);
        } else {
            setActiveChildPage(null);
        }
    }, [location, pages]);

    const handleOwnSettingsClick = () => {
        void employeeService.getOwnSettings().then(editItem => {
            setEmployeeEdit(editItem);
            setShowOwnSettingsDialog(true);
        }).catch(console.error);
    };

    const handlePrivacyPolicyClick = () => {
        if (hasPrivacyPolicy) {
            window.open(new OwnerParameters().getPrivacyPolicy(), "_blank");
        }
    };

    const handleLogOutClick = () => {
        if (!Base.isNullOrUndefined(onLogout)) {
            onLogout();
        }
    };

    const navigate = (e: SyntheticEvent, path: string) => {
        e.preventDefault(); // Prevent link navigation on normal click
        customHistory.push(path);
    };

    const onTabMouseEnter = (page: Page) => {
        setHoveringPage(page);
    };

    if (!isVisible) {
        return null;
    }

    return (
        <>
            <AppBar
                position="fixed"
                sx={{ bgcolor: "primary.dark", zIndex: 1001, px: 3 }}
                onMouseLeave={() => {
                    // Since mouseLeave doesn't trigger on the secondary AppBar when there are no children
                    if (!hoveringPage?.children) setHoveringPage(null);
                }}
            >
                <Toolbar disableGutters variant="dense" sx={{ height: "64px" }}>
                    <Box mr={3} >
                        <ReactLink to="/">
                            {sessionState?.ownerLogo ? (
                                <img src={"data:image/*;base64," + sessionState.ownerLogo} alt="Decorative logo" />
                            ) : (
                                <img
                                    src={appConfig.ownRoot + "EasyOpp_nega.svg"}
                                    alt="EasyOpp - Decorative logo"
                                    style={{ marginTop: 8, marginBottom: 16, height: 40 }}
                                />
                            )}
                        </ReactLink>
                    </Box>

                    {pages && (
                        <Tabs
                            className="text-decoration-none transition-color"
                            value={activePage?.index ?? false}
                            sx={{ flexGrow: 1 }}
                            variant="scrollable"
                        >
                            {pages.map(page =>
                                !page.isVisible || page.isVisible()
                                    ? <Tab
                                            key={page.index}
                                            sx={{ opacity: 1, color: "primary.contrastText" }}
                                            disableRipple
                                            label={page.title}
                                            value={page.index}
                                            onClick={!!page.target ? undefined : (e: SyntheticEvent) => navigate(e, page.path)}
                                            component={Link}
                                            href={page.path}
                                            onMouseEnter={() => onTabMouseEnter(page)}
                                            target={page.target}
                                      />
                                    : null
                            )}
                        </Tabs>
                    )}
                    <Button
                        className="flex"
                        onClick={(e) => !menuAnchorEl ? setMenuAnchorEl(e.currentTarget) : setMenuAnchorEl(null)}
                    >
                        <Avatar
                            sx={{ fontWeight: "bold", color: "primary.dark", bgcolor: "white.main", height: "30px", width: "30px", fontSize: "15px" }}
                        >
                            {`${userNames?.[0]?.[0] ?? ""}${userNames?.[1]?.[0] ?? ""}`}
                        </Avatar>
                        <Box ml={1} sx={{ display: { xs: "none", md: "block" }, textTransform: "initial" }}>
                            {`${userNames?.[0] ?? ""} ${userNames?.[1] ?? ""}`}
                        </Box>

                        <Menu
                            anchorEl={menuAnchorEl}
                            open={Boolean(menuAnchorEl)}
                            onClose={() => setMenuAnchorEl(null)}
                        >
                            <MenuItem onClick={() => setShowOwnerSettingsDialog(true)}>
                                <ListItemIcon>
                                    <Work fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>{sessionState?.ownerName}</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={handleOwnSettingsClick}>
                                <ListItemIcon>
                                    <Settings fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>{Translations.OwnSettings}</ListItemText>
                            </MenuItem>
                            {hasPrivacyPolicy &&
                                <MenuItem onClick={handlePrivacyPolicyClick}>
                                    <ListItemIcon>
                                        <Policy fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>{Translations.PrivacyPolicy}</ListItemText>
                                </MenuItem>
                            }
                            <Divider />
                            <MenuItem onClick={handleLogOutClick}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>{Translations.SignOut}</ListItemText>
                            </MenuItem>
                        </Menu>
                    </Button>

                    {showOwnSettingsDialog &&
                        <OwnSettingsDialog
                            employeeEdit={employeeEdit}
                            setEmployeeParameters={setEmployeeParameters}
                            onOk={() => setShowOwnSettingsDialog(false)}
                            onCancel={() => setShowOwnSettingsDialog(false)}
                        />
                    }
                    {showOwnerSettingsDialog &&
                        <OwnerSettingsDialog
                            onCancel={() => setShowOwnerSettingsDialog(false)}
                        />
                    }
                </Toolbar>
            </AppBar>

            <SecondaryHeader
                page={hoveringPage ? hoveringPage : activePage}
                activeChildPage={activeChildPage}
                navigate={navigate}
                onMouseLeave={() => setHoveringPage(null)}
            />
        </>
    );
}, () => {
    // Never re-render on prop change, because App.tsx renders constantly for no reason
    return true;
});

interface SecondaryHeaderProps {
    page: Page | null;
    activeChildPage: Page | null;
    navigate: (e: SyntheticEvent, route: string) => void;
    onMouseLeave: () => void;
}

const SecondaryHeader = ({ page, activeChildPage, navigate, onMouseLeave }: SecondaryHeaderProps) => {
    const getTabsValue = () => {
        if (activeChildPage === null) {
            return false;
        }
        return page.children?.find(c => c.path === activeChildPage.path)?.path ?? false;
    };

    return (
        <Slide
            in={true}
            direction="down"
        >
            <AppBar
                position="fixed"
                sx={{ top: 64, bgcolor: "white.main", zIndex: 1000, px: 3 }}
                onMouseLeave={onMouseLeave}
            >
                {page &&
                    <Toolbar disableGutters className="navbar-secondary">
                        <Tabs
                            value={getTabsValue()}
                            variant="scrollable"
                        >
                            {page.children?.map(page =>
                                !page.isVisible || page.isVisible() ? (
                                    <Tab
                                        disableRipple
                                        className="text-decoration-none transition-color"
                                        key={page.index}
                                        label={page.title}
                                        value={page.path}
                                        onClick={(e: SyntheticEvent) => navigate(e, page.path)}
                                        component={Link}
                                        href={page.path}
                                    />
                                ) : null
                            )}
                        </Tabs>
                    </Toolbar>
                }
            </AppBar>
        </Slide>
    );
};
