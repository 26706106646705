export interface IWorkOrderListItemWorkOrderTask {
    id: string;
    number: number;
    name: string;
    stateStr: string;
    state: number;
}

export class WorkOrderListItemWorkOrderTask implements IWorkOrderListItemWorkOrderTask {
    id: string;
    number: number;
    name: string;
    stateStr: string;
    state: number;

    constructor();
    constructor(obj: IWorkOrderListItemWorkOrderTask);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.name = obj && obj.name || "";
        this.state = obj && obj.state || 0;
        this.stateStr = obj && obj.stateStr || "";
    }
}