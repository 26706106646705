import React, { useEffect, useState } from "react";
import { Translations } from "../../../models/translations";
import { apiCall } from "../../../services/apiClient";
import { FormikFieldProps } from "../../framework/formUtils";
import {
    AutocompleteOption,
    AutocompleteWrapper,
} from "../../framework/muiAutocomplete";
import { VehicleGroupListItemDto } from "../../../models/transport/vehicleGroup";

interface VehicleGroupSelectProps extends Partial<FormikFieldProps> {
    small?: boolean;
    required?: boolean;
    multiple?: boolean;
}

export const VehicleGroupSelect = (props: VehicleGroupSelectProps) => {
    const [vehicleGroupOptions, setVehicleGroupOptions] = useState<
        AutocompleteOption[]
    >([]);
    const [loading, setLoading] = useState(false);

    const { value, small, onChange, multiple, ...rest } = props;
    useEffect(() => {
        setLoading(true);
        void apiCall<VehicleGroupListItemDto[]>("VehicleGroups", "GET")
            .then((res) => {
                setVehicleGroupOptions(
                    res.data.map((v) => ({
                        id: v.id,
                        title: v.name,
                    }))
                );
            })
            .catch((err) => {
                console.error("err", err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const handleChange = (
        val: AutocompleteOption | AutocompleteOption[] | null
    ) => {
        if (Array.isArray(val)) {
            if(onChange) onChange(val.map((v) => v.id));
        } else {
            if(onChange) onChange(val?.id ?? null);
        }
    };

    return (
        <AutocompleteWrapper
            label={Translations.VehicleGroup}
            value={value}
            size={small ? "small" : "medium"}
            options={vehicleGroupOptions}
            onChange={handleChange}
            loading={loading}
            required={props.required}
            multiple={multiple}
            textFieldProps={{ ...rest }}
        />
    );
};
