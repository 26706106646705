import React, { useCallback, useMemo } from "react";
import _groupBy from "lodash/groupBy";
import _map from "lodash/map";
import Typography from "@mui/material/Typography";
import { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid-premium";

import { Base } from "../../../framework/base";
import { AppUtils } from "../../../models/common/appUtils";
import { SalaryRowTypeItem } from "../../../models/salary/salaryRowTypeItem";
import { IWorkHoursItem } from "../../../models/workTime/workHoursItem";
import { VehicleListItemDto } from "../../../models/transport/vehicle";
import { WorkTimeType } from "../../../models/workShitTimeSlot/workTimeType";

import { VehicleDetailsTable } from "./vehicleDetailsTable";
import { EmployeeListItemDto } from "../../../models/employee/employee";
import { Translations } from "../../../models/translations";
import { WorkShiftDto } from "../../../models/workShift/workShift";
import { WorkShiftTimeSlotItem } from "../../../models/workShitTimeSlot/workShiftTimeSlotItem";
import { useTranslation } from "react-i18next";
import { GroupedDataGrid } from "../../framework/groupedDataGrid/groupedDataGrid";
import { ICostCenterItem } from "../../../models/costCenter/costCenterItem";

export interface GridRow {
    id: string;
    hierarchy: string[];
    workHourItems: IWorkHoursItem[];
    salaryRowTypes: SalaryRowTypeItem[];
    employees: EmployeeListItemDto[];
    costCenters: ICostCenterItem[];
    workTimeTypes: WorkTimeType[];
    date: string;
    totals: Record<string, number>;
}

interface VehicleWorkTimeGridProps {
    vehicles: VehicleListItemDto[];
    employees: EmployeeListItemDto[];
    salaryRowTypes: SalaryRowTypeItem[];
    costCenters: ICostCenterItem[];
    workTimeTypes: WorkTimeType[];
    workHours: IWorkHoursItem[];
    workShifts: WorkShiftDto[];
    filterByVehicles: (val: string) => boolean;
    filterByCostCenters: (val: string) => boolean;
    filterByEmployees: (val: string) => boolean;
}

export const VehiclesWorkTimeGrid = ({
    vehicles,
    employees,
    salaryRowTypes,
    costCenters,
    workTimeTypes,
    workHours,
    workShifts,
    filterByVehicles,
    filterByCostCenters,
    filterByEmployees,
}: VehicleWorkTimeGridProps) => {
    const { t } = useTranslation();

    const displayedWorkHours = useMemo(() => {
        return workHours.filter(
            (w) =>
                !!w.vehicleId &&
                filterByVehicles(w?.vehicleId) &&
                filterByCostCenters(w?.costCenterId) &&
                filterByEmployees(w?.employeeId)
        );
    }, [workHours, filterByVehicles, filterByCostCenters, filterByEmployees]);

    const workTimeTypeData = useMemo(
        () =>
            workShifts.flatMap((s) =>
                s.workShiftTimeSlots
                    .filter(
                        (slot) =>
                            !!slot.vehicleId &&
                            filterByVehicles(slot?.vehicleId) &&
                            filterByCostCenters(slot?.costCenterId) &&
                            filterByEmployees(slot?.employeeId)
                    )
                    .map((slot) => ({
                        id: slot.vehicleId,
                        workHourItem: null,
                        workSlotItem: {
                            vehicleId: slot.vehicleId,
                            workTimeTypeId: slot.workTimeTypeId,
                            amount: new WorkShiftTimeSlotItem(
                                slot
                            ).getDurationMinutes(),
                            dateStr: s.effectiveDate,
                        },
                    }))
            ),
        [workShifts, filterByVehicles, filterByCostCenters, filterByEmployees]
    );

    const baseData = displayedWorkHours.map((w) => {
        return {
            id: `${w.vehicleId}`,
            workHourItem: w,
            workSlotItem: null,
        };
    });

    // Group workHours by date
    const groupedData = _groupBy(
        [...baseData, ...workTimeTypeData],
        (w) => `${w.id}_${(w.workHourItem ?? w.workSlotItem).dateStr}`
    );

    // Create grid rows, calculate totals for each group
    const gridRows: GridRow[] = _map(groupedData, (value, key) => {
        const workHour = value[0].workHourItem;
        const workSlot = value[0].workSlotItem;
        const date = (workHour ?? workSlot).dateStr;
        return {
            id: key,
            hierarchy: [(workHour ?? workSlot).vehicleId, date],
            workHourItems: value.map((v) => v.workHourItem).filter(Boolean),
            employees: employees,
            salaryRowTypes: salaryRowTypes,
            costCenters: costCenters,
            workTimeTypes: workTimeTypes,
            date,
            totals: value.reduce(
                (acc, curr) => {
                    if (!!curr.workHourItem) {
                        acc[curr.workHourItem.salaryRowTypeId] =
                            (acc[curr.workHourItem.salaryRowTypeId] ?? 0) +
                            curr.workHourItem.amount;
                    }

                    return acc;
                },
                value.reduce((acc, curr) => {
                    if (!!curr.workSlotItem) {
                        acc[curr.workSlotItem.workTimeTypeId ?? "wtt_null"] =
                            (acc[
                                curr.workSlotItem.workTimeTypeId ?? "wtt_null"
                            ] ?? 0) + curr.workSlotItem.amount;

                        acc["wtt_total"] =
                            (acc["wtt_total"] ?? 0) + curr.workSlotItem.amount;
                    }

                    return acc;
                }, {})
            ),
        };
    }).sort((a, b) => Base.stringCompare(a.date, b.date));

    const gridDef: GridColDef<GridRow>[] = useMemo(
        () => [
            {
                field: "wtt_total",
                headerName: Translations.Total,
                description: t("vehicle.workTimeTypeTotal"),
                groupable: false,
                type: "number",
                minWidth: 100,
                flex: 1,
                valueGetter(params) {
                    return (params.row?.totals?.wtt_total ?? 0) / 60;
                },
                valueFormatter: (params: GridValueFormatterParams<number>) => {
                    const value = params.value;
                    if (!value) return "-";
                    return value.toLocaleFixed(2, "fi-Fi");
                },
            },
            ...salaryRowTypes.map(
                (srt): GridColDef<GridRow> => ({
                    field: srt.id,
                    headerName: `${srt.name} (${srt.measureUnit})`,
                    description: `${srt.name} (${srt.measureUnit})`,
                    groupable: false,
                    type: "number",
                    minWidth: 100,
                    flex: 1,
                    valueGetter(params) {
                        const divisor = AppUtils.isTimeUnit(srt.measureUnit)
                            ? 60
                            : 1;
                        return (params.row?.totals?.[srt.id] ?? 0) / divisor;
                    },
                    valueFormatter: (
                        params: GridValueFormatterParams<number>
                    ) => {
                        const value = params.value;
                        if (!value) return "-";
                        return value.toLocaleFixed(srt.decimals ?? 2, "fi-Fi");
                    },
                })
            ),
            ...workTimeTypes.map(
                (wtt): GridColDef<GridRow> => ({
                    field: wtt.workTimeTypeId,
                    headerName: wtt.name,
                    description: wtt.name,
                    groupable: false,
                    type: "number",
                    minWidth: 100,
                    flex: 1,
                    valueGetter(params) {
                        return (
                            (params.row?.totals?.[wtt.workTimeTypeId] ?? 0) / 60
                        );
                    },
                    valueFormatter: (
                        params: GridValueFormatterParams<number>
                    ) => {
                        const value = params.value;
                        if (!value) return "-";
                        return value.toLocaleFixed(2, "fi-Fi");
                    },
                })
            ),
        ],
        [salaryRowTypes, workTimeTypes]
    );

    const pinnedColumns = useMemo(() => ["wtt_total"], []);

    const getVehicleLabel = useCallback(
        (vehicleId: string) => {
            const vehicle = vehicles?.find((v) => v.id === vehicleId);
            return `${vehicle?.registerNumber} - ${vehicle?.brand}`;
        },
        [vehicles]
    );

    return (
        <div style={{ paddingTop: "1rem" }} id="vehicles-container">
            <Typography pl={1} variant="h3">{t("reporting.fleet")}</Typography>
            <GroupedDataGrid
                noRowsText={t("vehicle.noRows")}
                detailsComponent={VehicleDetailsTable}
                columns={gridDef}
                rows={gridRows}
                groupingColDef={{
                    headerName: t("vehicle.vehicle"),
                    valueFormatter: (data) => {
                        // for exports
                        const value = data?.value ?? Translations.Total;
                        if (value.match(/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/)) {
                            // val is vehicleId
                            return getVehicleLabel(value);
                        } else {
                            // val is date
                            return value;
                        }
                    },
                }}
                getGroupLabel={getVehicleLabel}
                persistStateKey="VehiclesWorkTimeGridState"
                pinnedColumns={pinnedColumns}
            />
        </div>
    );
};
