import React, { useEffect, useMemo, useState } from "react";
import {
    getWorkTimeTypeColor,
    WorkTimeType,
} from "../../models/workShitTimeSlot/workTimeType";
import { useFetch } from "../../hooks/useFetch";
import { getWorkTimeTypes } from "../../services/workTimeBetaService";
import Box from "@mui/material/Box";
import { MuiSwitch } from "../framework/muiSwitch";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import {
    setMapFilterNoWorkTime,
    setMapFilterWorkTimeTypeIds,
    toggleMapFilterWorkTimeTypeId,
} from "../../store/monitorViewSlice";
import { Base } from "../../framework/base";

interface MonitorViewFiltersProps {
    countByWorkTimeType: { [id: string]: number };
}

export const MonitorViewFilters = ({
    countByWorkTimeType,
}: MonitorViewFiltersProps) => {
    const [workTimeTypes, setWorkTimeTypes] = useState<WorkTimeType[]>();
    const { t } = useTranslation();

    const selectedWorkTimeTypeIds = useAppSelector(
        (state) => state.monitorView.filters.workTimeTypeIds
    );

    const showNoWorkTime = useAppSelector(
        (state) => state.monitorView.filters.noWorkTime
    );
    const dispatch = useAppDispatch();

    useFetch(
        (signal) => getWorkTimeTypes(signal),
        {
            onSuccess: (res) => setWorkTimeTypes(res),
            withLoadingIndicator: false,
        },
        []
    );

    const typeFilters = useMemo(
        () =>
            workTimeTypes
                ? [
                      ...workTimeTypes.sort((a, b) =>
                          Base.stringCompare(a.name, b.name)
                      ),
                      new WorkTimeType({
                          workTimeTypeId: null,
                          type: null,
                          name: t("workTimeType.noWorkTimeType"),
                      }),
                  ]
                : null,
        [workTimeTypes]
    );

    useEffect(() => {
        if (!selectedWorkTimeTypeIds && !!typeFilters) {
            dispatch(
                setMapFilterWorkTimeTypeIds(
                    typeFilters.map((f) => f.workTimeTypeId)
                )
            );
        }
    }, [selectedWorkTimeTypeIds, typeFilters]);

    return (
        <Box>
            {!workTimeTypes || !selectedWorkTimeTypeIds ? (
                <CircularProgress />
            ) : (
                <>
                    {typeFilters.map((w) => (
                        <MuiSwitch
                            key={w.workTimeTypeId}
                            checked={selectedWorkTimeTypeIds.includes(
                                w.workTimeTypeId
                            )}
                            label={`${w.name} (${
                                countByWorkTimeType[w.workTimeTypeId] ?? 0
                            })`}
                            color={getWorkTimeTypeColor(w.type)}
                            onChange={(_, checked) =>
                                dispatch(
                                    toggleMapFilterWorkTimeTypeId({
                                        id: w.workTimeTypeId,
                                        checked,
                                    })
                                )
                            }
                        />
                    ))}
                    <MuiSwitch
                        checked={showNoWorkTime}
                        label={`${t("map.noWorkTime")} (${
                            countByWorkTimeType["undefined"] ?? 0
                        })`}
                        color={getWorkTimeTypeColor(null)}
                        onChange={(_, checked) =>
                            dispatch(setMapFilterNoWorkTime(checked))
                        }
                    />
                </>
            )}
        </Box>
    );
};
