// WorkMainListWorkOrderList - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import { Base } from "../../framework/base";
import { ListHeaderColumn, ListHeaderCheckBoxColumn } from "../framework/listHeaderColumn";
import { WorkNoBox } from "./workNoBox";
import { AppUtils } from "../../models/common/appUtils";
import { IWorkOrderItem, WorkOrderItem } from "../../models/work/workOrderItem";
import { WorkOrderDragData, WorkOrderDragDataType } from "../../models/work/workOrderDragData";
import { UseSingleAndDoubleClick } from "../hooks/useSingleAndDoubleClick";

// WorkMainListWorkOrderListLineHeader
export interface IWorkMainListWorkOrderListLineHeaderProp {
    sortColumn: string;
    sortOrderIsAsc: boolean;
    onColumnClick: (column: string) => void;
    onColumnCheckboxChange: (column: string, checked: boolean) => void;
}

export class WorkMainListWorkOrderListLineHeader extends React.Component<IWorkMainListWorkOrderListLineHeaderProp, {}> {
    handleColumnClick = (column: string) => {
        this.props.onColumnClick(column);
    };

    handleColumnCheckboxChange = (column: string, checked: boolean) => {
        this.props.onColumnCheckboxChange(column, checked);
    };

    render() {
        return (
            <div className="row title">
                <ListHeaderCheckBoxColumn
                    title=""
                    column="state"
                    classes="col-1 withStateEnd"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                    onCheckboxChange={this.handleColumnCheckboxChange}
                />
                <ListHeaderColumn
                    title={Translations.Number}
                    column="number"
                    classes="col-1"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Time}
                    column="startTime"
                    classes="col-2"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Name}
                    column="name"
                    classes="col-3"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Customer}
                    column="customer"
                    classes="col-2"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
                <ListHeaderColumn
                    title={Translations.Site}
                    column="siteName"
                    classes="col-3"
                    sortColumn={this.props.sortColumn}
                    sortOrderIsAsc={this.props.sortOrderIsAsc}
                    onClick={this.handleColumnClick}
                />
            </div>
        );
    }
}

// WorkMainListWorkOrderListLine
export interface IWorkMainListWorkOrderListLineItem {
    id: string;
    number: number;
    category: number;
    parentNumber: number;
    state: number;
    startTime: number;
    endTime: number;
    fullDay: boolean;
    name: string;
    canceled: boolean;
    customerName: string;
    siteName: string;
    siteAddress: string;
    workOrderTypeName: string;
    getEmployeeNames(): string;
    hasChildren: boolean;
}

export interface IWorkMainListWorkOrderListLineProp {
    item: IWorkMainListWorkOrderListLineItem;
    selectedId: string;
    checked: boolean;
    draggingEnabled: boolean;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
    onCheckboxChange: (id: string, checked: boolean) => void;
}

export class WorkMainListWorkOrderListLine extends React.Component<IWorkMainListWorkOrderListLineProp, {}> {
    handleCheckBoxClick = (e) => {
        e.stopPropagation();
        this.props.onCheckboxChange(this.props.item.id, !this.props.checked);
    };

    onDragStart = (ev) => {
        const props = this.props;
        const item = props.item;
        WorkOrderDragData.setWorkOrderDragData(ev, WorkOrderDragDataType.WorkOrder, item.id, item.state, item.category, "", "", item.startTime, item.hasChildren, 0);
    };

    render() {
        const props = this.props;
        const item = props.item;
        return (
            <UseSingleAndDoubleClick onClick={() => props.onClick(props.item.id)} onDoubleClick={() => props.onDoubleClick(props.item.id)}>
                <div className={"row line state" + item.state.toString(10) + (item.id === props.selectedId ? " selected" : "") + (item.canceled ? " canceled" : "")}
                    onDragStart={props.draggingEnabled ? this.onDragStart : null} draggable={props.draggingEnabled}
                >
                    <div className="col-1 workOrderStateEndContainer">
                        <div className={"stateEnd active state" + item.state.toString(10)}>
                            <span className={"icon state" + item.state.toString(10)} aria-hidden="true" />
                        </div>
                        <input type="checkbox" checked={props.checked} onClick={this.handleCheckBoxClick} value="" />
                    </div>
                    <div className="col-1 number" onClick={() => props.onDoubleClick(item.id)}>{item.number.toString(10) + (item.parentNumber ? " (" + item.parentNumber.toString(10) + ")" : "")}</div>
                    <div className="col-2">{AppUtils.getTimeDurationStr(item.startTime, item.endTime, item.fullDay)}</div>
                    <div className="col-3">{item.name}</div>
                    <div className="col-2">{item.customerName}</div>
                    <div className="col-3">{item.siteName}</div>
                </div>
            </UseSingleAndDoubleClick>
        );
    }
}

// WorkMainListWorkOrderListCard
export class WorkMainListWorkOrderListCard extends React.Component<IWorkMainListWorkOrderListLineProp, {}> {
    onDragStart = (ev) => {
        const props = this.props;
        const item = props.item;
        WorkOrderDragData.setWorkOrderDragData(ev, WorkOrderDragDataType.WorkOrder, item.id, item.state, item.category, "", "", item.startTime, item.hasChildren, 0);
    };

    render() {
        const props = this.props;
        const item = props.item;
        return (
            <div className={"cardContent state" + item.state.toString(10) + (item.id === props.selectedId ? " selected" : "") + (item.canceled ? " canceled" : "")} onClick={() => { props.onClick(item.id); }} onDoubleClick={() => { props.onDoubleClick(item.id); }}
                onDragStart={props.draggingEnabled ? this.onDragStart : null} draggable={props.draggingEnabled}
            >
                <div className="item">
                    <WorkNoBox
                        id={item.id}
                        state={item.state}
                        number={item.number}
                        parentNumber={item.parentNumber}
                        stopPropagation={true}
                        onClick={props.onDoubleClick}
                    />
                    <div className="cardTitle">{item.customerName}</div>
                </div>
                <div className="cardLineContainer">
                    <div className="cardLine right">{item.workOrderTypeName}</div>
                    <div className="cardLine">{item.name}</div>
                    <div className="cardLine">{Base.getStringWithSeparators([item.siteName, item.siteAddress], ", ")}</div>
                    <div className="cardLine right">{item.getEmployeeNames()}</div>
                    <div className="cardLine">{AppUtils.getTimeDurationStr(item.startTime, item.endTime, true)}</div>
                    {/*    {item.isProject() &&*/}
                    {/*        <div>*/}
                    {/*            <div className="row taskComtainer">*/}
                    {/*                {item.projectTasks.map((i) =>*/}
                    {/*                    <WorkNoBox*/}
                    {/*                        key={i.id}*/}
                    {/*                        classes="left mb-1"*/}
                    {/*                        id={i.id}*/}
                    {/*                        state={i.state}*/}
                    {/*                        number={i.number}*/}
                    {/*                        parentNumber={0}*/}
                    {/*                        stopPropagation={true}*/}
                    {/*                        onClick={props.onDoubleClick}*/}
                    {/*                    />*/}
                    {/*                )}*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    }*/}
                </div>
            </div>
        );
    }
}

// WorkMainListWorkOrderList
export interface IWorkMainListWorkOrderListProp {
    style?: React.CSSProperties;
    viewType: number;
    workOrders: IWorkOrderItem[];
    selectedId: string;
    checkedIds: string[];
    onSetSelectedIdAndCheckedIds: (selectedId: string, checkedIds: string[]) => void;
    onOpenWorkOrder: (id: string) => void;
}

export interface IWorkMainListWorkOrderListState {
    workOrders: IWorkOrderItem[];
    sortColumn: string;
    sortOrderIsAsc: boolean;
}

export class WorkMainListWorkOrderList extends React.Component<IWorkMainListWorkOrderListProp, IWorkMainListWorkOrderListState> {
    constructor(props: IWorkMainListWorkOrderListProp) {
        super(props);
        const workOrders = props.workOrders.slice(0);
        const sortColumn = "number";
        const sortOrderIsAsc = true;
        WorkOrderItem.sortWorkOrderItems(workOrders, sortColumn, sortOrderIsAsc);
        this.state = {
            workOrders: workOrders,
            sortColumn: sortColumn,
            sortOrderIsAsc: sortOrderIsAsc,
        };
    }

    getWorkOrdersHash = (workOrders: IWorkOrderItem[]): string => {
        if (!workOrders) return "";
        return workOrders.map(i => i.id + "_" + i.rowId).join("#");
    };

    componentDidUpdate(prevProps: IWorkMainListWorkOrderListProp, prevState: IWorkMainListWorkOrderListState): void {
        const props = this.props;
        const state = this.state;
        if (this.getWorkOrdersHash(prevProps.workOrders) === this.getWorkOrdersHash(props.workOrders)) return;
        const workOrders = props.workOrders.slice(0);
        WorkOrderItem.sortWorkOrderItems(workOrders, state.sortColumn, state.sortOrderIsAsc);
        this.setState({
            workOrders: workOrders
        });
    }

    handleChangeWorkOrdersSortColumn = (sortColumn: string) => {
        const state = this.state;
        const oldSortColumn = state.sortColumn;
        const sortOrderIsAsc = oldSortColumn === sortColumn ? !state.sortOrderIsAsc : true;
        const workOrders = state.workOrders.slice(0);
        WorkOrderItem.sortWorkOrderItems(workOrders, sortColumn, sortOrderIsAsc);
        this.setState({
            sortOrderIsAsc: sortOrderIsAsc,
            sortColumn: sortColumn,
            workOrders: workOrders,
        });
    };

    handleChangeWorkOrdersColumnCheckbox = (column: string, checked: boolean) => {
        const state = this.state;
        const result = Base.getIdsOnColumnCheckboxChange(state.workOrders, checked);
        this.props.onSetSelectedIdAndCheckedIds(result.selectedId, result.checkedIds);
    };

    handleWorkOrderLineClick = (id: string) => {
        if (!id) return;
        this.props.onSetSelectedIdAndCheckedIds(id, this.props.checkedIds);
    };

    handleWorkOrderLineDoubleClick = (id: string) => {
        if (!id) return;
        const props = this.props;
        props.onOpenWorkOrder(id);
    };

    handleChangeWorkOrdersLineCheckbox = (id: string, checked: boolean) => {
        const props = this.props;
        const result = Base.getIdsOnLineCheckboxChange(props.selectedId, props.checkedIds, id, checked);
        this.props.onSetSelectedIdAndCheckedIds(result.selectedId, result.checkedIds);
    };

    render() {
        const props = this.props;
        const state = this.state;
        const items = state.workOrders;
        items.sort((a, b) => a.sortOrder - b.sortOrder);
        return (
            <div className="workOrderList" style={props.style}>
                {!!props.viewType &&
                    <div>
                        <WorkMainListWorkOrderListLineHeader
                            sortColumn={state.sortColumn}
                            sortOrderIsAsc={state.sortOrderIsAsc}
                            onColumnClick={this.handleChangeWorkOrdersSortColumn}
                            onColumnCheckboxChange={this.handleChangeWorkOrdersColumnCheckbox}
                        />
                    </div>
                }
                <div className={"listContainer workOrdersContainer" + (!props.viewType ? " cardMode" : "")}>
                    <div className="list workOrders striped">
                        {props.viewType
                            ? <div className="lineContainer">
                                {items.map((item) =>
                                    <WorkMainListWorkOrderListLine
                                        key={item.id}
                                        item={item}
                                        selectedId={props.selectedId}
                                        checked={props.checkedIds.indexOf(item.id) > -1}
                                        draggingEnabled={true}
                                        onClick={this.handleWorkOrderLineClick}
                                        onDoubleClick={this.handleWorkOrderLineDoubleClick}
                                        onCheckboxChange={this.handleChangeWorkOrdersLineCheckbox}
                                    />
                                )}
                            </div>
                            : <div className="cardContainer">
                                <div className="row">
                                    {items.map((item) =>
                                        <div className="col-md-6 col-xl-3 card" key={item.id}>
                                            <WorkMainListWorkOrderListCard
                                                key={item.id}
                                                item={item}
                                                selectedId={props.selectedId}
                                                checked={props.checkedIds.indexOf(item.id) > -1}
                                                draggingEnabled={true}
                                                onClick={this.handleWorkOrderLineClick}
                                                onDoubleClick={this.handleWorkOrderLineDoubleClick}
                                                onCheckboxChange={this.handleChangeWorkOrdersLineCheckbox}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        );
    }
}
