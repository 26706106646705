import { Base } from "../../framework/base";
import { IVehicleItem, VehicleItem } from "./vehicleItem";

export interface IVehicleItems {
    items: IVehicleItem[];
    hasMore: boolean;
    page: number;
}

export class VehicleItems implements IVehicleItems {
    items: VehicleItem[];
    hasMore: boolean;
    page: number;

    constructor();
    constructor(obj: IVehicleItems);
    constructor(obj?: any) {
        this.hasMore = obj && obj.hasMore || false;
        this.page = obj && obj.page || 1;
        this.items = Base.getTypedArray<VehicleItem>(obj ? obj.items : null, VehicleItem);
    }
}