export interface ICostCenterEditItem {
    id: string;
    number: number;
    name: string;
    code: string;
    comment: string;
    activeState: number;
    rowId: string;

    isNew():boolean;
}

export class CostCenterEditItem implements ICostCenterEditItem {
    id: string;
    number: number;
    name: string;
    code: string;
    comment: string;
    activeState: number;
    rowId: string;

    constructor();
    constructor(obj: ICostCenterEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.name = obj && obj.name || "";
        this.code = obj && obj.code || "";
        this.comment = obj && obj.comment || "";
        this.activeState = obj && obj.activeState || 0;
        this.rowId = obj && obj.rowId || "";
    }

    isNew():boolean {
        return !this.rowId;
    }
}