// SettingsEmployeeDialogChangeUsernameDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import * as employeeService from "../../services/employeeService";
import { Translations } from "../../models/translations";
import { Dialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { SaveData } from "../../framework/saveData";

// SettingsEmployeeDialogChangeUsernameDialog
// ***********************************************************************************************************************
export interface ISettingsEmployeeDialogChangeUsernameDialogProp {
    classes?: string;
    employeeId: string;
    employeeRowId: string;
    username: string;
    onOk: (username: string, rowId: string) => void;
    onCancel: () => void;
}

export interface ISettingsEmployeeDialogChangeUsernameDialogState {
    username: string;
}

export class SettingsEmployeeDialogChangeUsernameDialog extends React.Component<ISettingsEmployeeDialogChangeUsernameDialogProp, ISettingsEmployeeDialogChangeUsernameDialogState> {
    constructor(props) {
        super(props);
        this.state = {
            username: ""
        };
    }

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "uNm") {
            this.setState({ username: value });
        }
    };

    private static validate = (state: ISettingsEmployeeDialogChangeUsernameDialogState): boolean => {
        if (!state.username) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.UsernameMustBeDefined));
            return false;
        }
        if (!Base.isValidUsername(state.username)) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.UsernameMustMeetComplexityRequirements));
            return false;
        }
        return true;
    };

    private static getSaveDataFromState = (props: ISettingsEmployeeDialogChangeUsernameDialogProp, state: ISettingsEmployeeDialogChangeUsernameDialogState): SaveData => {
        const data = new SaveData();
        data.append("id", props.employeeId);
        data.append("rowId", props.employeeRowId);
        data.append("username", state.username);
        return data;
    };

    handleOkClick = () => {
        const obj = this;
        if (!SettingsEmployeeDialogChangeUsernameDialog.validate(this.state)) return;
        const saveData = SettingsEmployeeDialogChangeUsernameDialog.getSaveDataFromState(this.props, this.state);
        if (Base.isNullOrUndefined(saveData)) return;
        // Call server
        store.customStore.dispatch(storeActions.fetchStart());
        employeeService.changeEmployeeUsername(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                obj.props.onOk(this.state.username, success.rowId);
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const dialogClasses = "changeUsername px300" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <Dialog
                    classes={dialogClasses}
                    title={Translations.ChangeUsername}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.Username}</label>
                                    <input type="text" className="form-control" name="oldPassword" title={Translations.Username} value={props.username} readOnly={true} disabled={true} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.NewUsername}</label>
                                    <input type="text" className="form-control" name="uNm" title={Translations.NewUsername} value={this.state.username} onChange={this.handleChange} maxLength={50}/>
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.OK, classes: "btn-primary", enabled: true, onClick: this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}