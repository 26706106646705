import React from "react";
import { VehiclesWorkTimeGrid } from "./vehiclesWorkTimeGrid";
import {
    ReportData,
    ReportOptionsMapping,
} from "../../../hooks/reporting/useReportData";
import { Reporting } from "../reportingMain";

const dataInitialized = (data: ReportData) =>
    !!data.workHours &&
    !!data.workShifts &&
    !!data.salaryRowTypes &&
    !!data.workTimeTypes;

export const VehiclesMain = () => {
    return (
        <Reporting
            selectedOptions={Object.keys(ReportOptionsMapping)}
            opts={{ includeWorkHours: true }}
            dataInitialized={dataInitialized}
            component={VehiclesWorkTimeGrid}
        />
    );
};
