import { Base } from "../../framework/base";

export interface IIdGetTitle {
    id: string;
    getTitle(): string;
}

export interface IIdTitle {
    id: string;
    title: string;
    isNew: boolean;
    typeField: number;
    typeId: string;
    getTitle(): string;
}

export class IdTitle implements IIdTitle {
    id = "";
    title = "";
    typeId = ""
    isNew: boolean;
    typeField: number;


    constructor();
    constructor(obj: IIdTitle);
    constructor(obj?: any) {
        if (obj) {
            this.id = obj.id;
            this.title = obj.title;
            this.typeField = obj.typeField;
            this.typeId = obj.typeId
        }
        this.isNew = !this.id;
    }

    getTitle(): string {
        return this.title;
    }

    static createIdTitle(id: string, title: string): IdTitle {
        const result = new IdTitle();
        result.id = id;
        result.title = title;
        return result;
    }

    static getTitleById(items: IIdGetTitle[], id: string): string {
        if (!items || !id || items.length < 1) return "";
        const item = items.find(i => i.id === id);
        if (!item) return "";
        return item.getTitle();
    }

    static sortIdTitles(items: IIdTitle[], column: string, asc: boolean) {
        if (Base.isNullOrUndefined(items) || items.length < 2) return;
        const sortFunc = (a: IIdTitle, b: IIdTitle) => { return (Base.isNullOrUndefined(a) || Base.isNullOrUndefined(b)) ? 0 : ((asc ? 1 : -1) * Base.stringCompare(a.title, b.title)); };
        items.sort(sortFunc);
    }
}
