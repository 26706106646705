export interface IProductGroupEditItem {
    id: string;
    code: string;
    name: string;
    rowId: string;
    productIds: string[];
    mainGroup: number;

    isNew():boolean;
}

export class ProductGroupEditItem implements IProductGroupEditItem {
    id: string;
    code: string;
    name: string;
    rowId: string;
    productIds: string[];
    mainGroup: number;

    constructor();
    constructor(obj: IProductGroupEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.code = obj && obj.code || "";
        this.name = obj && obj.name || "";
        this.rowId = obj && obj.rowId || "";
        this.mainGroup = obj && obj.mainGroup || 0;
        this.productIds = obj && obj.productIds || [];
    }

    isNew():boolean {
        return !this.rowId;
    }
}
