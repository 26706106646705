import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import { Translations } from "../../models/translations";
import { TransportPlanListItemDto, TransportPlanState, transportPlanStates } from "../../models/transport/transportPlan";
import { MuiSwitch } from "../framework/muiSwitch";
import { MainLayout } from "../layout/mainLayout";
import { showApiError } from "../framework/formUtils";
import { fetchTransportPlansAndTemplates, selectTimelinePlans } from "../../store/transport/transportPlanTemplatesSlice";
import { DragContext } from "../framework/drag-n-drop";
import { togglePlanStateSelected } from "../../store/transport/transportVehiclesSlice";
import { TransportTimelineFilters } from "./transportTimeline/transportTimelineFilters";
import { TransportPlansTimelineGrid } from "./transportPlansTimeline/TransportPlansTimelineGrid";
import { TransportSidebarContainer as GridSidebar } from "./components/sidebar/transportSidebarContainer";
import { TransportPlansSidebar as TableSidebar } from "./transportPlansTable/transportPlansSidebar";
import { TransportPlansTable } from "./transportPlansTable/transportPlansTable";

const PlanningTabs = Object.freeze({
    grid: { name: "grid", value: 0 },
    list: { name: "list", value: 1 }
});

const DEFAULT_ACTIVE_TAB = PlanningTabs.grid;

export const TransportPlanningMain = (props: RouteComponentProps<{ tab: string }>) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const tab = props.match.params.tab;
    const { timelineStart, timelineEnd, selectedVehicles, selectedEmployees, selectedPlanStates } = useAppSelector(state => state.transportVehicles);
    const [selectedPlan, setSelectedPlan] = useState<TransportPlanListItemDto | null>(null);
    const timelinePlans = useAppSelector(selectTimelinePlans);
    const gridDataChanged = useAppSelector((state) => state.transportVehicles.gridDataChanged);

    useEffect(() => {
        const fetchData = async() => {
            await dispatch(
                    fetchTransportPlansAndTemplates({
                        timeFrameStartDate: timelineStart?.toISOString(),
                        timeFrameEndDate: timelineEnd?.toISOString(),
                        ...(selectedVehicles.length > 0 && { vehicleIds: selectedVehicles }),
                        ...(selectedEmployees.length > 0 && { employeeIds: selectedEmployees }),
                        ...(states.length > 0 && { states: states }),
                    }) as any);
        };
        let states = Object.entries(selectedPlanStates)
            .filter(([, val]) => val === true)
            .map(([state]) => state) as unknown as TransportPlanState[];

        if (states.length === 5) {
            states = [];
        }
        fetchData()
            .catch(() => showApiError(Translations.TransportPlansFetchFail));
    }, [timelineStart, timelineEnd, selectedVehicles, selectedEmployees, selectedPlanStates, gridDataChanged]);

    const displaySideBar = () => tab === PlanningTabs.list.name
        ? selectedPlan && <TableSidebar row={selectedPlan} onClose={() => setSelectedPlan(null)} />
        : <GridSidebar />;

    const displayContent = () => tab === PlanningTabs.list.name
        ? <TransportPlansTable onRowClick={(row) => setSelectedPlan(row)}/>
        : <TransportPlansTimelineGrid plans={timelinePlans} />;

    const toggle = (toggledTab) => {
        if (tab !== toggledTab) {
            history.push(`./${toggledTab}`);
        }
    };

    useEffect(() => {
        if(!tab) {
            history.push(`./${DEFAULT_ACTIVE_TAB.name}`);
        }
    }, []);

    return (
        <MainLayout topComponent={<TransportTimelineFilters vehiclesEnabled employeesEnabled />}>
            <DragContext>
                <Grid2 container spacing={2} sx={{ height: "100%" }}>
                    <Grid2 xs sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column"
                    }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyItems: "space-between",
                                marginBottom: 8
                            }}
                        >
                            <Grid2 container flexGrow={1}>
                                {transportPlanStates.map(tps => (
                                    <MuiSwitch
                                        key={tps.value}
                                        checked={selectedPlanStates[tps.value]}
                                        onChange={() => dispatch(togglePlanStateSelected(tps.value))}
                                        color={tps.colorName}
                                        label={tps.name}
                                    />
                                ))}
                            </Grid2>
                            <Grid2>
                                <Tabs
                                    variant="standard"
                                    value={tab === PlanningTabs.list.name ? PlanningTabs.list.value : DEFAULT_ACTIVE_TAB.value}
                                >
                                    <Tab label={`${Translations.TimeTable}`} value={0} onClick={() => toggle(PlanningTabs.grid.name)} />
                                    <Tab label={`${Translations.List}`} value={1} onClick={() => toggle(PlanningTabs.list.name)} />
                                </Tabs>
                            </Grid2>
                            <Grid2>
                                <Button variant="save" component={Link} startIcon={<AddIcon/>} to={{ pathname: "/transport/plans/plan/new", state: { fromPath: `/transport/plans/${tab}` } }}>
                                    {Translations.NewTransportPlan}
                                </Button>
                            </Grid2>
                        </div>

                        { displayContent() }

                    </Grid2>

                    <Grid2 xs={3} style={{ height: "100%" }} pl={2}>
                        { displaySideBar() }
                    </Grid2>

                </Grid2>
            </DragContext>
        </MainLayout>
    );
};
