import { Session } from "../models/session/session";
import { refreshAuthentication } from "./tokenService";

type method =
| "GET"
| "POST"
| "PUT"
| "DELETE"

export interface ApiResponse<T> {
    status: number;
    statusText: string;
    data: T;
}

/**
 * Light fetch wrapper for api.
 */
export async function apiCall<TResponse = unknown, TParams = unknown>(endpoint: string, method: method, data?: TParams, signal?: AbortSignal): Promise<ApiResponse<TResponse>> {
    if (!appConfig.apiRootUrl) {
        throw new Error("appConfig.apiRootUrl not defined");
    }

    // TODO data paremeter should be an options object, with body data, urlParams, etc.
    if (method === "GET" && data) {
        const urlParams = new URLSearchParams();

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const value = data[key];

                if (Array.isArray(value)) {
                    value.forEach((item: string | number | Date) => {
                        const string = item?.toString();
                        if (string.length > 0) {
                            urlParams.append(key, string);
                        }
                    });
                } else {
                    const string = value?.toString();
                    if (string?.length > 0) {
                        urlParams.append(key, string);
                    }
                }
            }
        }

        if (!urlParams.keys().next().done) {
            endpoint += "?" + urlParams.toString();
        }
    }

    await refreshAuthentication();
    const apiUrl = appConfig.apiRootUrl;
    const res = await fetch(`${apiUrl}api/${endpoint}`, {
        method,
        body: (method !== "GET" && data) ? JSON.stringify(data) : null,
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${await Session.getAccessToken()}`,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
        },
        signal,
    });

    const isJson = res.headers.get("content-type")?.includes("application/json");

    if (!res.ok) {
        throw isJson ? await res.json() : await res.text();
    }

    return {
        status: res.status,
        statusText: res.statusText,
        data: isJson ? await res.json() : await res.text()
    };
}
