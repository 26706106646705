import React, { useEffect, useState } from "react";
import { generatePath, Route, RouteComponentProps } from "react-router-dom";
import { customHistory } from "../../framework/customHistory";
import { StripedTableRow } from "../framework/styledTableRows";
import { StickyTableCellLeft } from "../framework/styledTableCells";
import { AvatarImage } from "../framework/avatarImage";
import { IconButton, TableCell, Tooltip } from "@mui/material";
import { Lock as LockIcon, LockOpen as LockOpenIcon } from "@mui/icons-material";
import { AppUtils } from "../../models/common/appUtils";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { EmployeeWorkTimesTable } from "./workTimeListEmployeeWorkTimesTable";
import {
    IEmployeeDayData,
    IOpenEmplyeeDateDetailsParameters,
    IWorkHoursGrouped,
    IWorkTimesGrouped
} from "./workTimeList";
import { Translations } from "../../models/translations";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import { toggleEmployeeOpened } from "../../store/workTimeBetaSlice";
import { WorkShiftTimeSlotItem } from "../../models/workShitTimeSlot/workShiftTimeSlotItem";
import { CalendarDay } from "../../models/calendarDay/calendarDay";
import { WorkTimeType } from "../../models/workShitTimeSlot/workTimeType";
import { WorkShiftDto } from "../../models/workShift/workShift";
import { WorkTimeListColumn } from "../../models/workTime/workTimeList";
import Box from "@mui/material/Box";
import { DayBookingItemBeta } from "../../models/dayBooking/dayBookingItemBeta";
import { EmployeeListItemDto } from "../../models/employee/employee";

export interface IWorkTimeListEmployeeRow {
    lockRows: (workTimeIds: WorkShiftTimeSlotItem[], dayBookingItems: DayBookingItemBeta[]) => void;
    unlockRows: (workTimeIds: WorkShiftTimeSlotItem[], dayBookingItems: DayBookingItemBeta[]) => void;
    employee: EmployeeListItemDto;
    columns: WorkTimeListColumn[];
    workTimeTypes: WorkTimeType[];
    shownColumns: string[];
    employeeDayDatas: IEmployeeDayData[];
    workShifts: WorkShiftDto[];
    workTimesGrouped: IWorkTimesGrouped[];
    workHoursGrouped: IWorkHoursGrouped[];
    calendarDays: CalendarDay[];
    path: string;
}
export function WorkTimeListEmployeeRow(props: IWorkTimeListEmployeeRow) {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState<boolean>(false);
    const { openedEmployees } = useAppSelector(
        (state) => state.workTimeBeta
    );

    useEffect(() => {
        if (openedEmployees.includes(props.employee.id)) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [openedEmployees, props.employee.id]);

    const { employee, employeeDayDatas, workTimesGrouped, workHoursGrouped } = props;
    const dayBookings = employeeDayDatas.flatMap((d) => d.dayBooking);

    const workShiftTimeSlotItems = props.workShifts.flatMap(
        (ws) => ws.workShiftTimeSlots
    );

    const allRowsLocked =
        workShiftTimeSlotItems.every((x) => x.isLocked()) &&
        dayBookings.every((x) => x.isLocked());

    function openDetails(
        {
            employeeId,
            date,
            shoudOpenNewTab
        }: IOpenEmplyeeDateDetailsParameters,
        { match }: RouteComponentProps
    ) {

        if (shoudOpenNewTab) {
            window.open(
                generatePath(`${match.path}/details/:employeeId/:date`, {
                    employeeId,
                    date,
                }),
                "_blank"
            );
            return;
        }

        customHistory.push(
            generatePath(`${match.path}/details/:employeeId/:date`, {
                employeeId,
                date,
            })
        );
    }

    return (
        <React.Fragment>
            <StripedTableRow
                style={{ cursor: "pointer" }}
                onClick={() => dispatch(toggleEmployeeOpened(employee.id))}
            >
                <StickyTableCellLeft
                    style={{
                        fontWeight: "bold",
                    }}
                >
                    <Box display="flex">
                        <IconButton aria-label="expand row" size="small">
                            {open ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                        <AvatarImage
                            classes="left small"
                            fullText={!!employee.abbreviation}
                            text={employee.abbreviation?.slice(0, 3)}
                            name={employee.fullName}
                            showName={true}
                            tooltip={employee.fullName}
                        />
                    </Box>
                </StickyTableCellLeft>
                <TableCell style={{ width: 40 }}>
                    <Tooltip
                        title={allRowsLocked ? null : Translations.DoLockAll}
                    >
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                if (allRowsLocked) {
                                    props.unlockRows(workShiftTimeSlotItems, dayBookings);
                                } else {
                                    props.lockRows(workShiftTimeSlotItems, dayBookings);
                                }
                            }}
                            aria-label="rows checked"
                            size="small"
                            color={allRowsLocked ? "primary" : "error"}
                        >
                            {/*TODO: check if any unchecked rows*/}
                            {allRowsLocked ? <LockIcon /> : <LockOpenIcon />}
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                {workHoursGrouped
                    ?.filter((w) => props.shownColumns.includes(w.column.id))
                    ?.map((w, i) => {
                        const tooltipTitle = w.workHoursAmountByCategory
                            ?.map(
                                (a) =>
                                    `${
                                        a.categoryName
                                    }: ${AppUtils.getDisplayedNumberBasedOnUnit(
                                        a.amount,
                                        w.column.measureUnit,
                                        w.column.decimals,
                                        true
                                    )}`
                            )
                            .join("\n");

                        const cellContent = (
                            <span style={{ fontWeight: "bold" }}>
                                {AppUtils.getDisplayedNumberBasedOnUnit(
                                    w.calculated,
                                    w.column.measureUnit,
                                    w.column.decimals,
                                    true
                                )}
                            </span>
                        );

                        return (
                            <TableCell
                                style={{
                                    borderLeft:
                                        i === 0
                                            ? "2px solid var(--palette-TableCell-border)"
                                            : "",
                                }}
                                key={w.column.id}
                            >
                                {tooltipTitle ? (
                                    <Tooltip
                                        title={
                                            <Box
                                                sx={{ whiteSpace: "pre-line" }}
                                            >
                                                {tooltipTitle}
                                            </Box>
                                        }
                                    >
                                        {cellContent}
                                    </Tooltip>
                                ) : (
                                    cellContent
                                )}
                            </TableCell>
                        );
                    })}
                {(workTimesGrouped || [])
                    .filter((group) =>
                        props.shownColumns.includes(
                            group.workTimeType.workTimeTypeId
                        )
                    )
                    .map((group, i) => {
                        return (
                            <TableCell
                                style={{
                                    borderLeft:
                                        i === 0
                                            ? "2px solid var(--palette-TableCell-border)"
                                            : "",
                                }}
                                key={group.workTimeType.workTimeTypeId}
                            >
                                <span style={{ fontWeight: "bold" }}>
                                    {group.duration
                                        ? AppUtils.getDurationStrByDurationMinShort(
                                              group.duration
                                          )
                                        : "-"}
                                </span>
                            </TableCell>
                        );
                    })}
            </StripedTableRow>
            {open && (
                <Route
                    render={(routerProps) => (
                        <EmployeeWorkTimesTable
                            employee={props.employee}
                            workTimeTypes={props.workTimeTypes}
                            openDetails={(params) =>
                                openDetails(params, routerProps)
                            }
                            columns={props.columns}
                            shownColumns={props.shownColumns}
                            employeeDayDatas={props.employeeDayDatas}
                            lockRows={props.lockRows}
                            unlockRows={props.unlockRows}
                            calendarDays={props.calendarDays}
                            path={props.path}
                            workHoursGrouped={workHoursGrouped}
                        />
                    )}
                />
            )}
        </React.Fragment>
    );
}
