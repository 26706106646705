import {
    createAsyncThunk,
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";
import { TFunction } from "i18next";
import * as parcelService from "../services/parcelService";
import { ParcelDetailsDto as ParcelDetails } from "../models/transport/parcel";
import { RootState } from "../framework/customStore";
import { showApiError, showApiSuccess } from "../components/framework/formUtils";

const initialState: ParcelDetails[] = [];

export const parcelSlice = createSlice({
    name: "parcel",
    initialState: initialState,
    reducers: {
        setParcels: (_, action: PayloadAction<ParcelDetails[]>) => {
            return [...action.payload];
        },
        addParcel: (state, action: PayloadAction<ParcelDetails>) => {
            state.push(action.payload);
        },
        modifyParcel: (state, action: PayloadAction<ParcelDetails>) => {
            return state.map((item : ParcelDetails) => {
                if (item.id !== action.payload.id) return item;
                return {
                    ...item,
                    ...action.payload
                };
            });
        },
        removeParcel: (state, action: PayloadAction<string>) => {
            return state.filter(p => p.id !== action.payload);
        }
    },
});

export const { setParcels, addParcel, modifyParcel, removeParcel } = parcelSlice.actions;

export const getParcels = createAsyncThunk(
    "parcels/list",
    async(orderId : string, thunkApi) => {
        const res = await parcelService.getParcelsForOrder(orderId, thunkApi.signal);
        thunkApi.dispatch(setParcels(res.data));
    }
);

export const postParcel = createAsyncThunk(
    "parcels/add",
    async({ orderId, parcelData, t } : { orderId: string; parcelData: ParcelDetails; t: TFunction }, thunkApi) => {
        try {
            const res = await parcelService.postParcel(orderId, parcelData, thunkApi.signal);
            if(res?.data) {
                thunkApi.dispatch(addParcel(res.data));
                showApiSuccess(t("transport.parcel.addSuccessful"));
            }
        } catch(e) {
            showApiError(t("transport.parcel.addFailed"));
        }
    }
);

export const updateParcel = createAsyncThunk(
    "parcels/update",
    async({ parcelId, parcelData, t } : { parcelId: string; parcelData: ParcelDetails; t: TFunction }, thunkApi) => {
        try {
            const res = await parcelService.updateParcel(parcelId, parcelData, thunkApi.signal);
            if(res?.data) {
                thunkApi.dispatch(modifyParcel(res.data));
                showApiSuccess(t("transport.parcel.editSuccessful"));
            }
        } catch(e) {
            showApiError(t("transport.parcel.editFailed"));
        }
    }
);

export const deleteParcel = createAsyncThunk(
    "parcels/delete",
    async({ parcelId, t }: { parcelId: string; t: TFunction }, thunkApi) => {
        try {
            const res = await parcelService.deleteParcel(parcelId, thunkApi.signal);
            if(res?.status === 200) {
                thunkApi.dispatch(removeParcel(parcelId));
                showApiSuccess(t("transport.parcel.deleteSuccessful"));
            }
        } catch(e) {
            showApiError(t("transport.parcel.deleteFailed"));
        }
    }
);

export const parcelReducer = parcelSlice.reducer;

export const selectParcels = (state: RootState) : ParcelDetails[] => state.parcel;