import { ApiSuccess } from "./baseService";
import { getApiCall, postApiCall, postFormApiCall } from "./tokenService";
import { AcknowledgementApproval } from "../models/acknowledgement/acknowledgementApproval";
import { IAcknowledgementMain, AcknowledgementMain } from "../models/acknowledgement/acknowledgementMain";

export const getAcknowledgementMain = (id: string): Promise<IAcknowledgementMain> => {
    return getApiCall<AcknowledgementMain>("api/acknowledgement/edit/?id=" + (id || ""), AcknowledgementMain);
};

export const sendMessage = (data: FormData): Promise<ApiSuccess> => {
    return postFormApiCall<ApiSuccess>("api/acknowledgement/sendmessage", data, ApiSuccess);
};

export const approve = (id: string): Promise<AcknowledgementApproval> => {
    return postApiCall<AcknowledgementApproval>("api/acknowledgement/approve/?id=" + (id || ""), AcknowledgementApproval);
};