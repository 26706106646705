export interface IHashItem {
    getHash(): string;
}

export interface IEditItem {
    id: string;
    rowId: string;

    isNew(): boolean;
    getHash(): string;
}

export class EditItem implements IEditItem {
    id: string;
    rowId: string;

    constructor();
    constructor(obj: IEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.rowId = obj && obj.rowId || "";
    }

    isNew(): boolean {
        return !this.rowId;
    }

    getHash(): string {
        return (this.id ?? "") + (this.rowId ?? "");
    }
    
    static getHash(items: IHashItem[]): string {
        if (!items || items.length < 1) return "";
        return items.map(i => i.getHash()).join("#");
    }
}