import React, { useCallback, useEffect, useMemo, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Translations } from "../../models/translations";
import { CircularProgress } from "@mui/material";
import { Base } from "../../framework/base";

export interface IMuiSelectOption {
    label: string;
    value: string;
}

interface IAsyncOptions {
    load: Function;
    onOpen?: boolean;
}

interface IMuiAutoCompleteProps<T extends IMuiSelectOption> {
    name?: string;
    label?: string;
    options?: T[];
    value?: T;
    defaultValue?: T;
    onChange?: (val: T) => void;
    async?: IAsyncOptions;
    required?: boolean;
    autocompleteProps?: Object;
}

export default function MuiAutocomplete<T extends IMuiSelectOption, >({ name, options: propOptions, label, async, value: propValue, defaultValue, onChange, required, autocompleteProps = {} }: IMuiAutoCompleteProps<T>) {
    const [open, setOpen] = useState<boolean>(false);
    const [value, setValue] = useState<T>(propValue || defaultValue || null);
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState<readonly T[]>(propOptions || [value].filter(Boolean));
    const [isLoadingOptions, setIsLoadingOptions] = useState<boolean>(false);
    const [hasLoadedAllOptions, setHasLoadedAllOptions] = useState<boolean>(false);
    const shouldLoadAllOptions = open && !hasLoadedAllOptions && async?.onOpen;
    const asyncSearch = async && !async.onOpen;

    useEffect(() => {
        if (propOptions) {
            setOptions(propOptions);
        }
    }, [propOptions]);

    const search = useMemo(
        () =>
            Base.debounce(
                (
                    request: { input: string },
                    callback: (results?: readonly IMuiSelectOption[]) => void,
                ) => {
                    setIsLoadingOptions(true);
                    async.load(
                        request,
                        callback,
                    );
                },
                300,
            ),
        [async],
    );

    const fetch = useCallback((callback: (results?: readonly IMuiSelectOption[]) => void) => {
        setIsLoadingOptions(true);
        async.load(callback);
    }, [async]);

    useEffect(() => {
        let active = true;

        if (!async) {
            return undefined;
        }

        const callback = (results?: readonly T[]) => {
            if (active) {
                let newOptions: readonly T[] = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                    // Filter duplicates,
                    // Possible when value was set before loading the options.
                    newOptions = Base.getUniqueStringItems(newOptions.map(o => o.value)).map(v => newOptions.find(opt => opt.value === v));
                }

                setHasLoadedAllOptions(true);
                setOptions(newOptions);
                setIsLoadingOptions(false);
            }
        };

        if (shouldLoadAllOptions) {
            fetch(callback);
        } else if (!async.onOpen) {
            if (inputValue === "" || inputValue.length < 2) {
                setIsLoadingOptions(false);
                setOptions(value ? [value] : []);
                return undefined;
            }

            search({ input: inputValue }, callback);
        }

        return () => {
            active = false;
        };
    }, [inputValue, fetch, search, shouldLoadAllOptions]);

    useEffect(() => {
        if (propValue !== undefined && propValue?.value !== value?.value) {
            setValue(propValue);
        }
    }, [propValue]);

    return (
        <>
            <Autocomplete
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                disablePortal
                options={options}
                renderInput={(params) => <TextField {...params} label={label} required={required} InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {isLoadingOptions ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
                                         />}
                filterOptions={asyncSearch ? (x) => x : undefined} // Override default filtering when using asyncLoadOptions
                autoComplete
                includeInputInList
                noOptionsText={isLoadingOptions ? Translations.Loading : Translations.NoOptions}
                onChange={(event: any, newValue: T | null) => {
                    onChange(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                value={value}
                isOptionEqualToValue={(o, v) => o.value === v.value}
                {...autocompleteProps}
            />
            <input type="hidden" name={name} value={value?.value || ""} />
        </>
    );
}
