// SettingsEmployeeDialogSsoIntegrationList- MODULE
// ***********************************************************************************************************************
import * as React from "react";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import { Translations } from "../../models/translations";
import { PropertyDialog } from "../framework/dialog";
import { ToolButton } from "../framework/toolButton";
import { IEmployeeEditItemSsoIntegration } from "../../models/employee/employeeEditItemSsoIntegration";
import { EmployeeEditItemSsoIntegrationAttribute, IEmployeeEditItemSsoIntegrationAttribute } from "../../models/employee/employeeEditItemSsoIntegrationAttribute";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { Base } from "../../framework/base";

// SettingsEmployeeDialogSsoIntegrationDialog
// ***********************************************************************************************************************
export interface ISettingsEmployeeDialogSsoIntegrationDialogProp {
    classes?: string;
    isReadOnly: boolean;
    item: IEmployeeEditItemSsoIntegration;
    onOk: (attributes: IEmployeeEditItemSsoIntegrationAttribute[]) => void;
    onCancel: () => void;
}

export interface ISettingsEmployeeDialogSsoIntegrationDialogState {
    attributes: IEmployeeEditItemSsoIntegrationAttribute[];
}

export class SettingsEmployeeDialogSsoIntegrationDialog extends React.Component<ISettingsEmployeeDialogSsoIntegrationDialogProp, ISettingsEmployeeDialogSsoIntegrationDialogState> {
    constructor(props) {
        super(props);
        const item = props.item;
        this.state = {
            attributes: Base.getTypedArray<EmployeeEditItemSsoIntegrationAttribute>(item.attributes, EmployeeEditItemSsoIntegrationAttribute)
        };
    }

    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        const attributes = this.state.attributes.slice(0);
        const attribute = attributes.find(i => i.name === name);
        if (!attribute) return;
        attribute.value = value;
        this.setState({ attributes: attributes });
    };

    private static checkErrors = (props: ISettingsEmployeeDialogSsoIntegrationDialogProp, state: ISettingsEmployeeDialogSsoIntegrationDialogState): Promise<boolean> => {
        return new Promise<boolean>((resolve) => {
            //for (const attribute of state.attributes) {
            //    if (attribute.required && !attribute.value) {
            //        store.customStore.dispatch(storeActions.showErrorMessage(String.format(Translations.FieldParameterValueMustBeDefined, attribute.name)));
            //        return resolve(false);
            //    }
            //}
            return resolve(true);
        });
    };

    private static getWarningMessage = (props: ISettingsEmployeeDialogSsoIntegrationDialogProp, state: ISettingsEmployeeDialogSsoIntegrationDialogState): Promise<string> => {
        return new Promise<string>((resolve) => {
            return resolve("");
        });
    };

    private static validate = (props: ISettingsEmployeeDialogSsoIntegrationDialogProp, state: ISettingsEmployeeDialogSsoIntegrationDialogState, saveCallback: () => void): Promise<void> => {
        return SettingsEmployeeDialogSsoIntegrationDialog.checkErrors(props, state).then(success => {
            if (success) {
                return SettingsEmployeeDialogSsoIntegrationDialog.getWarningMessage(props, state).then(warnings => {
                    if (!warnings) {
                        saveCallback();
                        return new Promise<void>((resolve) => { resolve(); });
                    }
                    return new Promise<void>((resolve) => {
                        store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, warnings + Base.lf + Translations.DoYouReallyWantToSaveData,
                            () => {
                                store.customStore.dispatch(storeActions.clearConfirmation());
                                saveCallback();
                                resolve();
                            },
                            () => {
                                store.customStore.dispatch(storeActions.clearConfirmation());
                                resolve();
                            }, null));
                    });
                });
            } else {
                return new Promise<void>((resolve) => { resolve(); });
            }
        });
    };

    handleOkClick = () => {
        const obj = this;
        SettingsEmployeeDialogSsoIntegrationDialog.validate(this.props, this.state, () => {
            obj.props.onOk(this.state.attributes);
        });
    };

    handleCancelClick = () => {
        this.props.onCancel();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const dialogClasses = "employeeSsoAttributes px600" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.SsoParameters + " - " + props.item.serviceName}
                    show={true}
                    body={<div>
                        <div className="row">
                            {state.attributes.map((attribute) =>
                                <div className="col-6" key={attribute.name}>
                                    <div className={"form-group" + (attribute.required ? " required" : "")}>
                                        <label className="control-label smallFont">{attribute.name}</label>
                                        <input type="text" className="form-control" name={attribute.name} title={attribute.name} value={attribute.value} disabled={!!props.isReadOnly} readOnly={!!props.isReadOnly} onChange={this.handleChange} maxLength={100} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>}
                    buttons={[{ title: Translations.OK, classes: "btn-primary", enabled: true, onClick: props.isReadOnly ? null : this.handleOkClick }]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}

// SettingsEmployeeDialogSsoIntegrationListLine
export interface ISettingsEmployeeDialogSsoIntegrationListLineProp {
    item: IEmployeeEditItemSsoIntegration;
    selectedId: string;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
}

export class SettingsEmployeeDialogSsoIntegrationListLine extends React.Component<ISettingsEmployeeDialogSsoIntegrationListLineProp, {}> {
    render() {
        const props = this.props;
        const item = props.item;
        return (
            <div className={"row line" + (item.serviceName === props.selectedId ? " selected" : "")} onClick={() => { props.onClick(item.serviceName); }} onDoubleClick={() => { props.onDoubleClick(item.serviceName); }} >
                <div className="col-2">{item.serviceName}</div>
                <div className="col-10">{Base.getStringWithSeparators(item.attributes.map(i => i.value), ", ")}</div>
            </div>
        );
    }
}

// SettingsEmployeeDialogSsoIntegrationList
export interface ISettingsEmployeeDialogSsoIntegrationListProp {
    title?: string;
    isReadOnly?: boolean;
    items: IEmployeeEditItemSsoIntegration[];
    onEditSsoIntegration: (serviceName: string, attributes: IEmployeeEditItemSsoIntegrationAttribute[]) => void;
}

interface ISettingsEmployeeDialogSsoIntegrationListState {
    selectedId: string;
    selectedItem: IEmployeeEditItemSsoIntegration;
    showSsoIntegrationDialog: boolean;
}

export class SettingsEmployeeDialogSsoIntegrationList extends React.Component<ISettingsEmployeeDialogSsoIntegrationListProp, ISettingsEmployeeDialogSsoIntegrationListState> {
    constructor(props) {
        super(props);
        this.state = { selectedId: null, selectedItem: null, showSsoIntegrationDialog: false };
    }

    handleEdit = () => {
        const selectedId = this.state.selectedId;
        if (!selectedId) return;
        const ssoIntegration = this.props.items.find(i => i.serviceName === selectedId);
        if (!ssoIntegration) return;
        this.setState({
            selectedItem: ssoIntegration,
            showSsoIntegrationDialog: true
        });
    };

    handleBookingDialogOk = (attributes: IEmployeeEditItemSsoIntegrationAttribute[]) => {
        const selectedItem = this.state.selectedItem;
        this.setState({
            selectedId: selectedItem.serviceName,
            showSsoIntegrationDialog: false
        });
        this.props.onEditSsoIntegration(selectedItem.serviceName, attributes);
    };

    handleBookingDialogCancel = () => {
        this.setState({
            showSsoIntegrationDialog: false
        });
    };

    handleLineClick = (id: string) => {
        this.setState({
            selectedId: id
        });
    };

    handleLineDoubleClick = (id: string) => {
        this.handleEdit();
    };

    render() {
        const props = this.props;
        const state = this.state;
        const items = props.items;
        return (
            <div>
                <div className="commandRow">
                    {!!props.title &&
                        <label className="control-label listTitle">{props.title}</label>
                    }
                    {!props.isReadOnly &&
                        <ToolButton
                            title={Translations.Edit}
                            enabled={!!state.selectedId}
                            classes={"round right edit"}
                            onClick={this.handleEdit}
                        />
                    }
                </div>
                <div className="listContainer employeeSsoIntegrationsContainer">
                    {items.length > 0 &&
                        <div className="list employeeSsoIntegrations">
                            {items.map((item) =>
                                <SettingsEmployeeDialogSsoIntegrationListLine
                                    key={item.serviceName}
                                    item={item}
                                    selectedId={state.selectedId}
                                    onClick={this.handleLineClick}
                                    onDoubleClick={this.handleLineDoubleClick}
                                />
                            )}
                        </div>
                    }
                </div>
                {state.showSsoIntegrationDialog &&
                    <SettingsEmployeeDialogSsoIntegrationDialog
                        isReadOnly={props.isReadOnly}
                        item={state.selectedItem}
                        onOk={this.handleBookingDialogOk}
                        onCancel={this.handleBookingDialogCancel}
                    />
                }
            </div>
        );
    }
}